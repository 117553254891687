import React from 'react';
import axios from 'axios';
import http from '../modules/api';
import {Route, Switch, NavLink, Link} from "react-router-dom";
import $ from 'jquery';

import "react-datepicker/dist/react-datepicker.css";

import ReactGA from 'react-ga';
ReactGA.initialize('UA-78553461-8');

var ga_label = sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")";

var calc_payment = false;
var pf = null;
var isPayLinkClick = false;

export class OrderAmountExpect extends React.Component {
    state = {
        tabNum : '1', // 1: 서비스 및 멤버십 이용 내역 , 2: 내 상점 방문자 수
        order_type : '',
        amount : '',            // 최종 결제금액
        total_amount: '',       // 서비스 및 멤버십 이용 내역 
        balance_amount: '',     // 잔여 보유액
        extra_amount: '',       // 추가금액
        unpaid_amount: '',      // 미납액
        data_expectList : [],
        // order_payment_amount_list : [
        //     [{shop_name:"블루리본", solution_type: "카페24", solution_url: "blueribbon.co.kr", total_banner_view_cnt: "555,555,555 명", membership:"basic", service_product_name:"메인 상단 배너", payment_type: "기본금액", payment_period: "2021-01-01  ~  2023-01-31", use_yn: "Y", amount: "150,000"}],
        //     [{shop_name:"블루리본", solution_type: "카페24", solution_url: "blueribbon.co.kr", total_banner_view_cnt: "555,555,555 명", membership:"basic", service_product_name:"메인 상단 배너", payment_type: "기본금액", payment_period: "2021-01-01  ~  2023-01-31", use_yn: "Y", amount: "150,000"}],
        // ],
        payLinkPreData: {}
    };

    componentDidMount(e) {
        // 현재 사용자의 잔여, 추가요금, 미납액 조회
        //this.fn_selectMembershipUserAmount();
        // 현재 년월 기준으로 멤버십 현황 조회
        pf = document.payForm;
        // console.log(pf);
        this.fn_selectOrderType();
        this.fn_selectMembershipExpectList();
    }

    componentDidUpdate() {
        if(isPayLinkClick) {
            isPayLinkClick = false;

            console.log(pf);
            pf = document.payForm;
        
            //var fileName = "https://apitest.payjoa.co.kr/pay/link"; //개발서버
            var fileName = "https://api.payjoa.co.kr/pay/link"; //운영서버
        
            var PAYJOA = window.open("", "PAYJOA", "width=468,height=750");
            
            pf.target = "PAYJOA";
            pf.action = fileName;
            pf.method = "post";
            pf.submit();
        }
    }

    componentWillUnmount () {

    }

    onClickTabChange = (e, tabNum) => {
        this.setState({tabNum: tabNum});
    }

    /**
     * 사용하기 클릭 이벤트
     * 1. 해당 배너에 속하는 멀티샵 세션 전환.
     * 2. /shop/orderNew 2단계로 이동
     * @param {*} shop_no 
     * @param {*} iframe_page_id 
     * @param {*} iframe_html_id 
     */
    onClickBannerOrderNew = (service_type, data) => {
        let shop_no = data.shop_no;
        let iframe_page_id = data.iframe_page_id;
        let iframe_html_id = data.iframe_html_id;
        let payment_type = data.payment_type;       /*적용 요금제 유형(샵의 요금제 유형을 리턴) */
        let order_new_yn = "N";

        // 1. 해당 배너에 속하는 멀티샵 세션 전환.
        http.get(`/rest/common/changeShop?shop_no=`+shop_no)
		.then(
			res => {
				var code = res.data.code;
				var msg = "";
				if (code == 200) {
					msg = "멀티샵 전환 성공!";
					// console.log("#### 이동 : " + mall_id, solution_type_cd, shop_no);
					// window.location.href = "/shop/cafe24/event/setting";
                    /**
                     * calc_payment : true
                     * step : 2
                     * request_type : 05001 요청 유형(신청)
                     * payment_type : 10001 사용자가 선택한 요금제 유형
                     * iframe_page_id
                     * iframe_html_id
                     * */
                    // 결제내역 페이지로 이동  this.props.history.push(`/ko/contact?email=${email}`);
                    this.props.history.push(`/shop/orderNew?service_type=${service_type}&iframe_page_id=${iframe_page_id}&iframe_html_id=${iframe_html_id}&order_new_yn=${order_new_yn}`);
				} else {
					msg = "멀티샵 전환에 실패하였습니다.\n관리자에게 문의해 주세요. (1599-3518)";
				}
                console.log(msg);
				//alert(msg);
			}
		);
    }

    onClickOrderPaymentTypeModify = (service_type, shop_no) => {

        // 1. 해당 배너에 속하는 멀티샵 세션 전환.
        http.get(`/rest/common/changeShop?service_type=`+service_type+`&shop_no=`+shop_no)
		.then(
			res => {
				var code = res.data.code;
				var msg = "";
				if (code == 200) {
					msg = "멀티샵 전환 성공!";
                    this.props.history.push(`/shop/OrderPaymentTypeModify?service_type=`+service_type+`&shop_no=`+shop_no);
				} else {
					msg = "멀티샵 전환에 실패하였습니다.\n관리자에게 문의해 주세요. (1599-3518)";
				}
				//alert(msg);
			}
		);
    }

    onClickOrderBank = (e) => {
        let today = new Date();  
        let lastDateByMonth = new Date(today.getFullYear(), today.getMonth(), 0).getDate();
        // alert(lastDateByMonth);
        if (this.state.order_type != "02002") {
            alert("결제 수단을 실시간계좌이체로 변경하셔야 실시간계좌이체가 가능합니다.");
            return false;
        }

        // if (today.getDate() != lastDateByMonth) {
        //     alert("결제 가능일은 매월 말일부터 7일동안 입니다.\n"+lastDateByMonth+"일에 다시 시도해 주세요.");
        //     return false;
        // }

        //this.fn_payLink(payment_amount_seq);
        this.fn_saveRequestMembershipOrderAmountExpect();
    }

    /**
     * 현재 설정된 결제 수단을 조회
     */
    fn_selectOrderType() {
        const config = {
            headers: {
                'content-type': 'application/json;'
            }
        }
		http.post("/rest/shop/get/orderType", null, config)
            .then(
			    resultData => {
                    const { data } = resultData;
                    console.log(data);
                    this.setState({
                        order_type: data.order_type
                    });
                }
            ).catch(
			    error => { alert("서비스 점검중 입니다."); console.log(error); }
			);
	}

    // 결제를 위한 금액 데이터 세팅
    fn_saveRequestMembershipOrderAmountExpect() {

        let order_type = this.state.order_type;
        /* 실시간 계좌이체 여부 */
        if (order_type == '02002') {
            let sendForm = {
                //std_date: '2021-02-28',
                //std_ym: '2021-02',
                // request_type: this.state.request_type,
                // payment_type: this.state.payment_type,
                // order_type : this.state.order_type,          /*결제타입 (정기결제 / 실시간결제)  [02001	정기결제, 02002	실시간계좌이체]*/
                // req_banner_list: this.state.req_bannerList,
            };
            const config = {
                headers: {
                    'content-type': 'application/json;'
                }
            }
            // 결제금액, 잔여금액, 추가금액을 계산하여 저장
            http.post("/rest/common/saveRequestMembershipOrderAmountExpect", sendForm, config)
                .then(
                    resultData => {
                        const { data } = resultData;
                        console.log(data);
                        if (data.code == 200) {
                            let payment_amount_seq = data.payment_amount_seq;
                            // 최종 요청 금액으로 결제모듈 호출
                            if (order_type == '02002') {
                                // 실시간 계좌이체
                                this.fn_payLink(payment_amount_seq);
                            }
                        } else {
                            alert(data.msg);
                        }
                    }
                ).catch(
                    error => { alert("서비스 점검중 입니다."); console.log(error); }
                );
        } else {
            alert("설정된 결제수단이 실시간 계좌이체가 아닙니다.");
        }
    }

    // [실시간 계좌이체] 최종 요청 금액으로 결제모듈 호출
    fn_payLink(payment_amount_seq) {
        let sendForm = {
            payment_amount_seq: payment_amount_seq
        };
        const config = {
            headers: {
                'content-type': 'application/json;'
            }
        }
        // 결제금액, 잔여금액, 추가금액을 계산하여 저장
        http.post("/rest/shop/get/payLinkPreData", sendForm, config)
        .then(
            resultData => {
                const { data } = resultData;
                console.log(data);
                if (data.result) {
                    // PG창 호출 이벤트
                    isPayLinkClick = true;
                    this.setState({payLinkPreData: resultData.data});
                } else {
                    alert("결제에 실패하였습니다.\n관리자에게 문의해주세요(1599-3518)");
                }
            }
        ).catch(
            error => { alert("서비스 점검중 입니다."); console.log(error); }
        );
    }

    /**
     * 현재 사용자의 잔여, 추가요금, 미납액 조회
     */
	// async fn_selectMembershipUserAmount() { 
	// 	const res = await http.get("/rest/common/selectMembershipUserAmount");
	// 	// 조회 성공시 다음 단계
    //     if (res.data != null) {
    //         console.log(res.data)
    //         this.setState({
    //             balance_amount: res.data.balance_amount,
    //             extra_amount: res.data.extra_amount,
    //             unpaid_amount: res.data.unpaid_amount,
    //         })
    //     } else if (res.data.code != 200) {
    //         let msg = res.data.msg;
    //         alert(msg);
    //     } else {
	// 		alert("서비스 점검중 입니다.");
	// 		//리다이렉트 경로
    //     }
    // }

    /**
     * 멤버십 요금제 리스트
     */
	async fn_selectMembershipExpectList() { 
		const res = await http.get("/rest/common/selectMembershipExpectList");
		// 조회 성공시 다음 단계
        if (res.data != null) {
            this.setState({
                expect_01001_list: res.data.expect_01001_list,
                expect_01002_list: res.data.expect_01002_list,
                amount: res.data.amount,
                total_amount: res.data.total_amount,       // 서비스 및 멤버십 이용 내역 
                balance_amount: res.data.balance_amount,
                extra_amount: res.data.extra_amount,
                unpaid_amount: res.data.unpaid_amount,
            })
        } 
        if (res.data.code != null) {
            if (res.data.code == "401") { 
                // 세션 만료시 로그인으로 이동
                let msg = res.data.msg;
                alert(msg);
                this.props.history.push(
                    {
                        pathname: `/login`,
                    }	
                )
            } else if (res.data.code != "200") {
                // 그 외 예외 메시지
                let msg = res.data.msg;
                alert(msg);
            }
        } else {
			alert("서비스 점검중 입니다.");
			//리다이렉트 경로
        }
    }

	render() {
        $(".pageName").text("멤버십 관리");
        let today = new Date();  
        return (
            <div id='page-wrapper' className="container orderAmountExpect_container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
                <div className="form_primary_title pc_page_title">멤버십 관리</div>
                <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"space-between"}}>
                    {
                        this.state.order_type == "02002"
                        ?   <button className="btn-auto-b" style={{height:"50px", display:"flex", padding:"15px 20px", borderRadius:"8px", marginLeft: "auto"}}
                                onClick={e => this.onClickOrderBank(e)}>
                                    <div className="icn_credit_card_w" style={{marginRight:"6.87px", lineHeight:"50px", alignSelf:"center"}}></div>실시간 계좌이체 결제
                            </button>
                        : ''
                    }
                </div>
                <div>
                    <div className="flexBox credit_card_box_area" style={{width:"100%", marginTop:"39px", padding: "37px 50px", border:"1px #EBEBED solid", borderRadius:"10px", justifyContent:"space-between"}}>
                        <div className="flexBox">
                            <div className="icn_receipt" style={{margin: "auto 10px auto 0"}}></div>
                            <div className="icn_credit_card_text" style={{flex: 1, margin: "auto 0"}}><b>{today.getFullYear()}년 {today.getMonth()+1}월 {today.getDate()}일 오늘 기준 고객님의 결제 예상 금액</b></div>
                        </div>
                        <div className="flexBox">
                            <div className="icn_credit_card_text" style={{margin: "auto 0", fontSize:"20px", color: "#10CFC9", fontWeight: "bold"}}>{this.state.amount}</div>
                            <div className="icn_credit_card_text" style={{margin: "auto 0", fontSize:"15px", color: "#333333", fontWeight:"500", lineHeight:"15px"}}>&nbsp;원&nbsp;&nbsp;</div>
                            <div className="icn_credit_card_text" style={{margin: "auto 0", fontSize:"12px", color: "#ADADAD", fontWeight:"500", lineHeight:"15px"}}>VAT 포함</div>
                        </div>
                    </div>
                    <div className="flexBox credit_card_box_area" style={{marginTop:"20px", padding: "25px 50px", border:"1px #EBEBED solid", borderRadius:"10px"}}>
                        <div style={{display:"inline", width:"100%"}}>
                            <div style={{display:"flex", marginBottom:"11px", justifyContent:"space-between"}}>
                                <div style={{flex:"1"}}>
                                    <div style={{margin: "auto 0"}}><b>서비스 및 멤버십 이용 내역</b></div>
                                </div>
                                <div style={{flex:"1"}}>
                                    <p className="card-text" style={{textAlign:"right"}}>{this.state.total_amount+"원"}</p>
                                </div>
                            </div>
                            <div style={{display:"flex", marginBottom:"11px", justifyContent:"space-between"}}>
                                <div style={{flex:"1"}}>
                                    <div style={{width:"18px", height:"18px", borderRadius:"75px", background:"#333333", color:"#FFFFFF", textAlign:"center", lineHeight:"16px", margin:"3px 0px", marginRight:"10px", float:"left"}}>+</div><b>미납액</b>
                                </div>
                                <div style={{flex:"1"}}>
                                    <p className="card-text" style={{textAlign:"right"}}>{this.state.unpaid_amount+"원"}</p>
                                </div>
                            </div>
                            {/* <div style={{display:"flex", justifyContent:"space-between"}}>
                                <div style={{flex:"1"}}>
                                    <div style={{width:"300px", margin: "auto 0"}}><b>추가요금</b></div>
                                </div>
                                <div style={{flex:"1"}}>
                                    <p className="card-text" style={{textAlign:"right"}}>{this.state.balance_amount}</p>
                                </div>
                            </div> */}
                            <div style={{display:"flex", justifyContent:"space-between"}}>
                                <div style={{flex:"1"}}>
                                    <div style={{margin: "auto 0"}}>
                                        <div style={{width:"18px", height:"18px", borderRadius:"75px", background:"#333333", color:"#FFFFFF", textAlign:"center", lineHeight:"16px", margin:"3px 0px", marginRight:"10px", float:"left"}}>-</div><b>잔여 보유액</b>
                                    </div>
                                </div>
                                <div style={{flex:"1"}}>
                                    <p className="card-text" style={{textAlign:"right"}}>{this.state.balance_amount+"원"}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div style={{display:"flex"}}>
                        {
                            this.state.tabNum == "1"
                            ?   <div className="radius_tab" style={{
                                    width: "auto",
                                    marginTop:"20px",
                                    padding: "50px", 
                                    padding: "37px 50px", 
                                    borderWidth:"1px 1px 0px 1px", 
                                    borderColor: "#EBEBED",
                                    borderStyle: "solid",
                                    borderRadius:"10px 10px 0px 0px",
                                    background:"white",
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    cursor:"pointer"
                                    }}
                                    onClick={e => this.onClickTabChange(e, "1")}>
                                        서비스 및 멤버십 이용 내역
                                </div>
                            :   <div className="radius_tab" style={{
                                    width: "auto",
                                    marginTop:"20px",
                                    marginLeft: "20px",
                                    padding: "50px", 
                                    padding: "37px 50px", 
                                    borderWidth:"0", 
                                    borderColor: "#EBEBED",
                                    borderStyle: "solid",
                                    borderRadius:"10px 10px 0px 0px",
                                    // background: "rgba(255, 255, 255, 0.5)",
                                    color: "rgba(51, 51, 51, 0.3)",
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    cursor:"pointer"
                                    }}
                                    onClick={e => this.onClickTabChange(e, "1")}>
                                        서비스 및 멤버십 이용 내역
                                </div>
                        }
                        {
                            this.state.tabNum == "2"
                            ?   <div className="radius_tab" style={{
                                    width: "auto",
                                    marginTop:"20px",
                                    padding: "50px", 
                                    padding: "37px 50px", 
                                    borderWidth:"1px 1px 0px 1px", 
                                    borderColor: "#EBEBED",
                                    borderStyle: "solid",
                                    borderRadius:"10px 10px 0px 0px",
                                    background:"white",
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    cursor:"pointer"
                                    }}
                                    onClick={e => this.onClickTabChange(e, "2")}>
                                        내 상점 방문자 수
                                </div>
                            :   <div className="radius_tab" style={{
                                    width: "auto",
                                    marginTop:"20px",
                                    marginLeft: "20px",
                                    padding: "50px", 
                                    padding: "37px 50px", 
                                    borderWidth:"0", 
                                    borderColor: "#EBEBED",
                                    borderStyle: "solid",
                                    borderRadius:"10px 10px 0px 0px",
                                    // background: "rgba(255, 255, 255, 0.5)",
                                    color: "rgba(51, 51, 51, 0.3)",
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    cursor:"pointer"
                                    }}
                                    onClick={e => this.onClickTabChange(e, "2")}>
                                        내 상점 방문자 수
                                </div>
                        }
                    </div> */}
                    {   
                        this.state.tabNum == "1"
                        ?
                            <>
                                {
                                    this.state.expect_01001_list != null && this.state.expect_01001_list.length > 0
                                    ?
                                    <div className="credit_card_box_area" style={{marginTop: "-1px", padding: "50px", padding: "37px 50px", border:"1px #EBEBED solid", borderRadius:"10px 10px 10px 10px", marginTop:"20px", background:"white"}}>
                                        <div>
                                            {
                                                this.state.expect_01001_list.map((shop, idx) =>
                                                    <>
                                                        <div className="expectTitle" style={{display:"flex", justifyContent:"space-between", marginBottom: "5px", marginTop:"48px"}}>
                                                            <div className="expectInfo" style={{display:"flex", ontSize:"18px", fontWeight: "bold", flexWrap: "wrap"}}>
                                                                <div style={{display:"flex", margin:"auto 15px 5px 0px"}}>{shop.shop_name}</div>
                                                                <div style={{display:"flex"}}>
                                                                    <div style={{display:"flex", margin:"auto 15px 5px 0px", background:"#F5F5F7", borderRadius:"10px 10px 10px 10px", padding:"4px 10px", fontSize:"13px", fontWeight:"500"}}>{'배너'}</div>
                                                                </div>
                                                                <div style={{display:"flex"}}>
                                                                    <div style={{display:"flex", margin:"auto 15px 5px 0px", background:"#F5F5F7", borderRadius:"10px 10px 10px 10px", padding:"4px 10px", fontSize:"13px", fontWeight:"500"}}>{shop.solution_type_name}</div>
                                                                </div>
                                                                <div style={{display:"flex"}}>
                                                                    <div style={{display:"flex", margin:"auto 15px 5px 0px", background:"#F5F5F7", borderRadius:"10px 10px 10px 10px", padding:"4px 10px", fontSize:"13px", fontWeight:"500"}}>{shop.base_domain}</div>
                                                                </div>
                                                                {
                                                                    shop.total_event_cnt != null 
                                                                    ? 
                                                                        <div style={{display:"flex"}}>
                                                                            <div style={{display:"flex", margin:"auto 15px 5px 0px", background:"#F5F5F7", borderRadius:"10px 10px 10px 10px", padding:"4px 10px", fontSize:"13px", fontWeight:"500"}}><div style={{color:"#10CFC9", marginRight:"5px"}}>{"이번 달 페이지 뷰"}</div>{shop.total_event_cnt}</div>
                                                                        </div>
                                                                    : ''
                                                                }
                                                            </div>
                                                            <div style={{display:"flex", fontSize:"18px", fontWeight: "bold", justifyContent:"space-between"}}>
                                                                <button className="btn-auto-b" style={{display:"flex", width: "100%", height: "42px", borderRadius: "8px", margin:"auto 0", padding:"11px 20px", marginLeft: "auto", justifyContent:"center"}}
                                                                    onClick={() => this.onClickOrderPaymentTypeModify('01001', shop.shop_no)}>
                                                                    <div style={{float:"left", paddingRight:"15px", borderRight: "1px solid rgb(255, 255, 255)"}}>{ shop.next_payment_type_name == null ? shop.payment_type_name : shop.next_payment_type_name}</div>
                                                                    <div style={{paddingLeft:"15px"}}>{"멤버십 변경"}</div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="banner_list_table_area" style={{width:"calc(95vw - 600px)", minWidth:"100%", height:"100%", overflowX:"auto", overflowY: "hidden"}}>
                                                                <div style={{display:"inline-block", height:"100%", minWidth:"1400px", width:"100%"}}>
                                                                    <div className="table" style={{marginBottom:0}}>
                                                                        <div className="table table_v3" style={{marginBottom:0}}>
                                                                            <table style={{overflowX: "visible",display: "table-row"}}>
                                                                                <colgroup>
                                                                                </colgroup>
                                                                                <thead style={{background: "#EBEBED", border:"0",height: "40px"}}>	
                                                                                    <tr style={{background: "#EBEBED", border:"0"}}>
                                                                                        <td className="user_id" onClick={this.onClickSorting} style={{width:"240px", marginLeft:"10px", border:"5px white solid"}}>
                                                                                            <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>페이지
                                                                                            </p>
                                                                                        </td>
                                                                                        <td className="corp_reg_no" onClick={this.onClickSorting} style={{width:"240px", border:"5px white solid"}}>
                                                                                            <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>배너 명칭
                                                                                            </p>
                                                                                        </td>
                                                                                        <td className="business_item" onClick={this.onClickSorting} style={{width:"100px", border:"5px white solid"}}>
                                                                                            <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>상태
                                                                                            </p>
                                                                                        </td>
                                                                                        <td className="corp_name" onClick={this.onClickSorting} style={{width:"150px", border:"5px white solid"}}>
                                                                                            <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>구분
                                                                                            </p>
                                                                                        </td>
                                                                                        <td className="business_type" onClick={this.onClickSorting} style={{width:"220px", border:"5px white solid"}}>
                                                                                            <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>이용기간
                                                                                            </p>
                                                                                        </td>
                                                                                        <td className="representative_name" onClick={this.onClickSorting} style={{width:"120px", border:"5px white solid"}}>
                                                                                            <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>
                                                                                                <span style={{margin:"auto 0"}}>금액</span>
                                                                                                {/* <span style={{color:"#B4B4B5", fontSize:"12px", fontWeight:"400", margin:"auto 0"}}>(VAT 별도 · 원)</span> */}
                                                                                            </p>
                                                                                        </td>
                                                                                        <td className="representative_name" onClick={this.onClickSorting} style={{width:"120px", border:"5px white solid"}}>
                                                                                            <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>
                                                                                                <span style={{margin:"auto 0"}}>부가세</span>
                                                                                            </p>
                                                                                        </td>
                                                                                        <td className="representative_name" onClick={this.onClickSorting} style={{width:"120px", border:"5px white solid"}}>
                                                                                            <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>
                                                                                                <span style={{margin:"auto 0"}}>합계</span>
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody style={{height:"auto", background:"#F5F5F7"}}>
                                                                                    {
                                                                                        (shop.expect_list != null && shop.expect_list.length > 0)
                                                                                        ?
                                                                                            shop.expect_list.map((data, idx) =>
                                                                                                <tr style={{marginTop: "-5px"}}>
                                                                                                    <td style={{width:"240px", border:"5px white solid"}}>
                                                                                                        <p className="card-text">{data.iframe_page_name}</p>
                                                                                                    </td>
                                                                                                    <td style={{width:"240px", border:"5px white solid"}}>
                                                                                                        <p className="card-text">{data.iframe_html_name}</p>
                                                                                                    </td>
                                                                                                    <td style={{width:"100px", border:"5px white solid", fontWeight:"400" /*color:"#10CFC9"*/}}>
                                                                                                        <p className="card-text">{data.use_state}</p>
                                                                                                    </td>
                                                                                                    <td style={{width:"150px", border:"5px white solid", fontWeight:"400"}}>
                                                                                                        <p className="card-text">{data.target_payment_mm + " " +data.use_cls}</p>
                                                                                                    </td>
                                                                                                    <td style={{width:"240px", border:"5px white solid", fontWeight:"400"}}>
                                                                                                        <p className="card-text">{data.use_date}</p>
                                                                                                    </td>
                                                                                                    {   
                                                                                                        // 결제할 금액이 없는 상태, 중지/미결제 상태인 경우 "사용하기 버튼 출력"
                                                                                                        (data.amount == null || data.amount == 0) && (data.request_type == "05003" || data.request_type == "05007")
                                                                                                        ? 
                                                                                                        <td colSpan="3" style={{width:"120px", border:"5px white solid", padding:"0px 20px"}}>
                                                                                                            <div className="icn_warning_round_blue" style={{float:"left", flex:"1"}}></div>
                                                                                                            <div style={{float:"left", marginLeft:"10px", textAlign:"left", fontWeight:"400"}}>
                                                                                                                {
                                                                                                                    data.is_finish == "0"
                                                                                                                    ?  '무료체험 상태입니다.'
                                                                                                                    :  '중지 상태입니다.'
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div style={{float:"right", fontSize:"15px", color:"#10CFC9", cursor:"pointer"}} onClick={() => this.onClickBannerOrderNew('01001', data)}>사용하기</div>
                                                                                                        </td>
                                                                                                        :
                                                                                                            <>
                                                                                                                <td style={{width:"120px", border:"5px white solid", fontWeight:"400"}}>
                                                                                                                    <p className="card-text" style={{textAlign:"right"}}>{data.amount+"원"}</p>
                                                                                                                </td>
                                                                                                                <td style={{width:"120px", border:"5px white solid", fontWeight:"400"}}>
                                                                                                                    <p className="card-text" style={{textAlign:"right"}}>{data.amount_vat+"원"}</p>
                                                                                                                </td>
                                                                                                                <td style={{width:"120px", border:"5px white solid"}}>
                                                                                                                    <p className="card-text" style={{textAlign:"right"}}>{data.total_amount+"원"}</p>
                                                                                                                </td>
                                                                                                            </>
                                                                                                    }
                                                                                                </tr>
                                                                                            )
                                                                                        : 
                                                                                        <tr style={{marginTop: "-5px"}}>
                                                                                            <td colSpan="8" style={{border:"5px white solid"}}>
                                                                                                <p className="card-text">{"조회된 데이터가 없습니다."}</p>
                                                                                            </td>
                                                                                        </tr>
                                                                                    }
                                                                                        {/* <tr style={{marginTop: "-5px"}}>
                                                                                            <td style={{width:"149px", border:"5px white solid"}}>
                                                                                                <p className="card-text">{"배너"}</p>
                                                                                            </td>
                                                                                            <td style={{width:"137px", border:"5px white solid"}}>
                                                                                                <p className="card-text">{"basic"}</p>
                                                                                            </td>
                                                                                            <td style={{width:"120px", border:"5px white solid", color:"#10CFC9"}}>
                                                                                                <p className="card-text">{"중지"}</p>
                                                                                            </td>
                                                                                            <td style={{width:"116px", border:"5px white solid", fontWeight:"400"}}>
                                                                                                <p className="card-text">{"기본금액"}</p>
                                                                                            </td>
                                                                                            <td style={{width:"250px", border:"5px white solid", fontWeight:"400"}}>
                                                                                                <p className="card-text">{"2021-01-01  ~  2023-01-31"}</p>
                                                                                            </td>
                                                                                            <td style={{width:"190px", border:"5px white solid", fontWeight:"400"}}>
                                                                                                <p className="card-text" style={{textAlign:"right"}}>{"0"}</p>
                                                                                            </td>
                                                                                            <td style={{width:"190px", border:"5px white solid", fontWeight:"400"}}>
                                                                                                <p className="card-text" style={{textAlign:"right"}}>{"0"}</p>
                                                                                            </td>
                                                                                            <td style={{width:"190px", border:"5px white solid", fontWeight:"400"}}>
                                                                                                <p className="card-text" style={{textAlign:"right"}}>{"0"}</p>
                                                                                            </td>
                                                                                        </tr> */}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    : ''
                                }

                                {
                                    this.state.expect_01002_list != null && this.state.expect_01002_list.length > 0
                                    ?
                                        <div className="credit_card_box_area" style={{marginTop: "-1px", padding: "50px", padding: "37px 50px", border:"1px #EBEBED solid", borderRadius:"10px 10px 10px 10px", marginTop:"20px", background:"white"}}>
                                            <div>
                                                {
                                                    this.state.expect_01002_list.map((shop, idx) =>
                                                        <>
                                                            <div className="expectTitle" style={{display:"flex", justifyContent:"space-between", marginBottom: "5px", marginTop:"48px"}}>
                                                                <div className="expectInfo" style={{display:"flex", ontSize:"18px", fontWeight: "bold", flexWrap: "wrap"}}>
                                                                    <div style={{display:"flex", margin:"auto 15px 5px 0px"}}>{shop.shop_name}</div>
                                                                    <div style={{display:"flex"}}>
                                                                        <div style={{display:"flex", margin:"auto 15px 5px 0px", background:"#F5F5F7", borderRadius:"10px 10px 10px 10px", padding:"4px 10px", fontSize:"13px", fontWeight:"500"}}>{'진열'}</div>
                                                                    </div>
                                                                    <div style={{display:"flex"}}>
                                                                        <div style={{display:"flex", margin:"auto 15px 5px 0px", background:"#F5F5F7", borderRadius:"10px 10px 10px 10px", padding:"4px 10px", fontSize:"13px", fontWeight:"500"}}>{shop.solution_type_name}</div>
                                                                    </div>
                                                                    <div style={{display:"flex"}}>
                                                                        <div style={{display:"flex", margin:"auto 15px 5px 0px", background:"#F5F5F7", borderRadius:"10px 10px 10px 10px", padding:"4px 10px", fontSize:"13px", fontWeight:"500"}}>{shop.base_domain}</div>
                                                                    </div>
                                                                    {
                                                                        shop.total_event_cnt != null 
                                                                        ? 
                                                                            <div style={{display:"flex"}}>
                                                                                <div style={{display:"flex", margin:"auto 15px 5px 0px", background:"#F5F5F7", borderRadius:"10px 10px 10px 10px", padding:"4px 10px", fontSize:"13px", fontWeight:"500"}}><div style={{color:"#10CFC9", marginRight:"5px"}}>{"이번 달 페이지 뷰"}</div>{shop.total_event_cnt}</div>
                                                                            </div>
                                                                        : ''
                                                                    }
                                                                </div>
                                                                <div style={{display:"flex", fontSize:"18px", fontWeight: "bold", justifyContent:"space-between"}}>
                                                                    <button className="btn-auto-b" style={{display:"flex", width: "100%", height: "42px", borderRadius: "8px", margin:"auto 0", padding:"11px 20px", marginLeft: "auto", justifyContent:"center"}}
                                                                        onClick={() => this.onClickOrderPaymentTypeModify('01002', shop.shop_no)}>
                                                                        <div style={{float:"left", paddingRight:"15px", borderRight: "1px solid rgb(255, 255, 255)"}}>{ shop.next_payment_type_name == null ? shop.payment_type_name : shop.next_payment_type_name}</div>
                                                                        <div style={{paddingLeft:"15px"}}>{"멤버십 변경"}</div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div class="banner_list_table_area" style={{width:"calc(95vw - 600px)", minWidth:"100%", height:"100%", overflowX:"auto", overflowY: "hidden"}}>
                                                                    <div style={{display:"inline-block", height:"100%", minWidth:"1400px", width:"100%"}}>
                                                                        <div className="table" style={{marginBottom:0}}>
                                                                            <div className="table table_v3" style={{marginBottom:0}}>
                                                                                <table style={{overflowX: "visible",display: "table-row"}}>
                                                                                    <colgroup>
                                                                                    </colgroup>
                                                                                    <thead style={{background: "#EBEBED", border:"0",height: "40px"}}>	
                                                                                        <tr style={{background: "#EBEBED", border:"0"}}>
                                                                                            <td className="user_id" onClick={this.onClickSorting} style={{width:"240px", marginLeft:"10px", border:"5px white solid"}}>
                                                                                                <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>페이지
                                                                                                </p>
                                                                                            </td>
                                                                                            <td className="corp_reg_no" onClick={this.onClickSorting} style={{width:"240px", border:"5px white solid"}}>
                                                                                                <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>진열 명칭
                                                                                                </p>
                                                                                            </td>
                                                                                            <td className="business_item" onClick={this.onClickSorting} style={{width:"100px", border:"5px white solid"}}>
                                                                                                <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>상태
                                                                                                </p>
                                                                                            </td>
                                                                                            <td className="corp_name" onClick={this.onClickSorting} style={{width:"150px", border:"5px white solid"}}>
                                                                                                <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>구분
                                                                                                </p>
                                                                                            </td>
                                                                                            <td className="business_type" onClick={this.onClickSorting} style={{width:"220px", border:"5px white solid"}}>
                                                                                                <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>이용기간
                                                                                                </p>
                                                                                            </td>
                                                                                            <td className="representative_name" onClick={this.onClickSorting} style={{width:"120px", border:"5px white solid"}}>
                                                                                                <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>
                                                                                                    <span style={{margin:"auto 0"}}>금액</span>
                                                                                                    {/* <span style={{color:"#B4B4B5", fontSize:"12px", fontWeight:"400", margin:"auto 0"}}>(VAT 별도 · 원)</span> */}
                                                                                                </p>
                                                                                            </td>
                                                                                            <td className="representative_name" onClick={this.onClickSorting} style={{width:"120px", border:"5px white solid"}}>
                                                                                                <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>
                                                                                                    <span style={{margin:"auto 0"}}>부가세</span>
                                                                                                </p>
                                                                                            </td>
                                                                                            <td className="representative_name" onClick={this.onClickSorting} style={{width:"120px", border:"5px white solid"}}>
                                                                                                <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>
                                                                                                    <span style={{margin:"auto 0"}}>합계</span>
                                                                                                </p>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody style={{height:"auto", background:"#F5F5F7"}}>
                                                                                        {
                                                                                            (shop.expect_list != null && shop.expect_list.length > 0)
                                                                                            ?
                                                                                                shop.expect_list.map((data, idx) =>
                                                                                                    <tr style={{marginTop: "-5px"}}>
                                                                                                        <td style={{width:"240px", border:"5px white solid"}}>
                                                                                                            <p className="card-text">{data.iframe_page_name}</p>
                                                                                                        </td>
                                                                                                        <td style={{width:"240px", border:"5px white solid"}}>
                                                                                                            <p className="card-text">{data.iframe_html_name}</p>
                                                                                                        </td>
                                                                                                        <td style={{width:"100px", border:"5px white solid", fontWeight:"400" /*color:"#10CFC9"*/}}>
                                                                                                            <p className="card-text">{data.use_state}</p>
                                                                                                        </td>
                                                                                                        <td style={{width:"150px", border:"5px white solid", fontWeight:"400"}}>
                                                                                                            <p className="card-text">{data.target_payment_mm + " " +data.use_cls}</p>
                                                                                                        </td>
                                                                                                        <td style={{width:"240px", border:"5px white solid", fontWeight:"400"}}>
                                                                                                            <p className="card-text">{data.use_date}</p>
                                                                                                        </td>
                                                                                                        {   
                                                                                                            // 결제할 금액이 없는 상태, 중지/미결제 상태인 경우 "사용하기 버튼 출력"
                                                                                                            (data.amount == null || data.amount == 0) && (data.request_type == "05003" || data.request_type == "05007")
                                                                                                            ? 
                                                                                                            <td colSpan="3" style={{width:"120px", border:"5px white solid", padding:"0px 20px"}}>
                                                                                                                <div className="icn_warning_round_blue" style={{float:"left", flex:"1"}}></div>
                                                                                                                <div style={{float:"left", marginLeft:"10px", textAlign:"left", fontWeight:"400"}}>
                                                                                                                    {
                                                                                                                        data.is_finish == "0"
                                                                                                                        ?  '무료체험 상태입니다.'
                                                                                                                        :  '중지 상태입니다.'
                                                                                                                    }
                                                                                                                </div>
                                                                                                                <div style={{float:"right", fontSize:"15px", color:"#10CFC9", cursor:"pointer"}} onClick={() => this.onClickBannerOrderNew('01002', data)}>사용하기</div>
                                                                                                            </td>
                                                                                                            :
                                                                                                                <>
                                                                                                                    <td style={{width:"120px", border:"5px white solid", fontWeight:"400"}}>
                                                                                                                        <p className="card-text" style={{textAlign:"right"}}>{data.amount+"원"}</p>
                                                                                                                    </td>
                                                                                                                    <td style={{width:"120px", border:"5px white solid", fontWeight:"400"}}>
                                                                                                                        <p className="card-text" style={{textAlign:"right"}}>{data.amount_vat+"원"}</p>
                                                                                                                    </td>
                                                                                                                    <td style={{width:"120px", border:"5px white solid"}}>
                                                                                                                        <p className="card-text" style={{textAlign:"right"}}>{data.total_amount+"원"}</p>
                                                                                                                    </td>
                                                                                                                </>
                                                                                                        }
                                                                                                    </tr>
                                                                                                )
                                                                                            : 
                                                                                            <tr style={{marginTop: "-5px"}}>
                                                                                                <td colSpan="8" style={{border:"5px white solid"}}>
                                                                                                    <p className="card-text">{"조회된 데이터가 없습니다."}</p>
                                                                                                </td>
                                                                                            </tr>
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    : ''
                                }

                                {/* <div style={{display:"flex", marginBottom: "19px", fontSize:"18px", fontWeight: "bold", justifyContent:"space-between"}}>
                                    <div style={{margin:"auto 0"}}>블루리본</div>
                                </div>
                                <div>
                                    <div class="banner_list_table_area" style={{width:"calc(95vw - 600px)", minWidth:"100%", height:"100%", overflowX:"auto", overflowY: "hidden"}}>
                                        <div style={{display:"inline-block", height:"100%", minWidth:"1080px", width:"100%"}}>
                                            <div className="table" style={{marginBottom:0}}>
                                                <div className="table table_v3" style={{marginBottom:0}}>
                                                    <table style={{overflowX: "visible",display: "table-row"}}>
                                                        <colgroup>
                                                        </colgroup>
                                                        <thead style={{background: "#EBEBED", border:"0",height: "40px"}}>	
                                                            <tr style={{background: "#EBEBED", border:"0"}}>
                                                                <td className="user_id" onClick={this.onClickSorting} style={{width:"149px", marginLeft:"10px", border:"5px white solid"}}>
                                                                    <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>서비스명
                                                                    </p>
                                                                </td>
                                                                <td className="corp_reg_no" onClick={this.onClickSorting} colSpan="2" style={{width:"250px", border:"5px white solid"}}>
                                                                    <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>멤버십
                                                                    </p>
                                                                </td>
                                                                <td className="corp_name" onClick={this.onClickSorting} style={{width:"116px", border:"5px white solid"}}>
                                                                    <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>구분
                                                                    </p>
                                                                </td>
                                                                <td className="business_type" onClick={this.onClickSorting} style={{width:"250px", border:"5px white solid"}}>
                                                                    <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>이용기간
                                                                    </p>
                                                                </td>
                                                                <td className="business_item" onClick={this.onClickSorting} style={{width:"120px", border:"5px white solid"}}>
                                                                    <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>이용정보
                                                                    </p>
                                                                </td>
                                                                <td className="representative_name" onClick={this.onClickSorting} style={{width:"190px", border:"5px white solid"}}>
                                                                    <p style={{display:"flex", justifyContent:"space-between", pointerEvents: "none", margin: "auto"}}>
                                                                        <span style={{margin:"auto 0"}}>금액</span>
                                                                        <span style={{color:"#B4B4B5", fontSize:"12px", fontWeight:"400", margin:"auto 0"}}>(VAT 별도 · 원)</span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{height:"auto", background:"#F5F5F7"}}>
                                                                <tr style={{marginTop: "-5px"}}>
                                                                    <td style={{width:"149px", border:"5px white solid"}}>
                                                                        <p className="card-text">{"배너"}</p>
                                                                    </td>
                                                                    <td style={{width:"137px", border:"5px white solid"}}>
                                                                        <p className="card-text">{"basic"}</p>
                                                                    </td>
                                                                    <td style={{width:"112px", border:"5px white solid", fontWeight:"400"}}>
                                                                        <p className="card-text">{"~10만명"}</p>
                                                                    </td>
                                                                    <td style={{width:"116px", border:"5px white solid", fontWeight:"400"}}>
                                                                        <p className="card-text">{"기본금액"}</p>
                                                                    </td>
                                                                    <td style={{width:"250px", border:"5px white solid", fontWeight:"400"}}>
                                                                        <p className="card-text">{"2021-01-01  ~  2023-01-31"}</p>
                                                                    </td>
                                                                    <td style={{width:"120px", border:"5px white solid"}}>
                                                                        <p className="card-text">{"중지"}</p>
                                                                    </td>
                                                                    <td style={{width:"190px", border:"5px white solid", fontWeight:"400"}}>
                                                                        <p className="card-text" style={{textAlign:"right"}}>{"0"}</p>
                                                                    </td>
                                                                </tr>
                                                                <tr style={{marginTop: "-5px"}}>
                                                                    <td style={{width:"149px", border:"5px white solid", verticalAlign:"middle"}} rowspan="2">
                                                                        <p className="card-text">{"배너"}</p>
                                                                    </td>
                                                                    <td style={{width:"137px", border:"5px white solid"}}>
                                                                        <p className="card-text">{"basic"}</p>
                                                                    </td>
                                                                    <td style={{width:"112px", border:"5px white solid", fontWeight:"400"}}>
                                                                        <p className="card-text">{"~10만명"}</p>
                                                                    </td>
                                                                    <td style={{width:"116px", border:"5px white solid", fontWeight:"400"}}>
                                                                        <p className="card-text">{"기본금액"}</p>
                                                                    </td>
                                                                    <td style={{width:"250px", border:"5px white solid", fontWeight:"400"}}>
                                                                        <p className="card-text">{"2021-01-01  ~  2023-01-31"}</p>
                                                                    </td>
                                                                    <td style={{width:"120px", border:"5px white solid", color:"#10CFC9"}}>
                                                                        <p className="card-text">{"이용중"}</p>
                                                                    </td>
                                                                    <td style={{width:"190px", border:"5px white solid", fontWeight:"400"}}>
                                                                        <p className="card-text" style={{textAlign:"right"}}>{"0"}</p>
                                                                    </td>
                                                                </tr>
                                                                <tr style={{marginTop: "-5px"}}>
                                                                        <td style={{width:"149px", border:"5px white solid"}} rowSpan="2">
                                                                        <p className="card-text">{"배너"}</p>
                                                                    </td> 
                                                                    <td style={{width:"137px", border:"5px white solid"}}>
                                                                        <p className="card-text">{"basic"}</p>
                                                                    </td>
                                                                    <td style={{width:"112px", border:"5px white solid", fontWeight:"400"}}>
                                                                        <p className="card-text">{"~10만명"}</p>
                                                                    </td>
                                                                    <td style={{width:"116px", border:"5px white solid", fontWeight:"400"}}>
                                                                        <p className="card-text">{"기본금액"}</p>
                                                                    </td>
                                                                    <td style={{width:"250px", border:"5px white solid", fontWeight:"400"}}>
                                                                        <p className="card-text">{"2021-01-01  ~  2023-01-31"}</p>
                                                                    </td>
                                                                    <td style={{width:"120px", border:"5px white solid", color:"#10CFC9"}}>
                                                                        <p className="card-text">{"이용중"}</p>
                                                                    </td>
                                                                    <td style={{width:"190px", border:"5px white solid", fontWeight:"400"}}>
                                                                        <p className="card-text" style={{textAlign:"right"}}>{"0"}</p>
                                                                    </td>
                                                                </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </>
                        :   
                            this.state.tabNum == "2"
                            ?   <div className="credit_card_box_area" style={{marginTop: "-1px", padding: "50px", padding: "37px 50px", border:"1px #EBEBED solid", borderRadius:"10px", background:"white"}}>
                                    <div>
                                        <div style={{display:"flex", marginBottom: "19px", fontSize:"18px", fontWeight: "bold", justifyContent:"space-between"}}>
                                            <div style={{margin:"auto 0"}}>통계</div>
                                        </div>
                                        <div className="banner_list_table_area" style={{width:"calc(95vw - 600px)", minWidth:"100%", height:"100%", overflowX:"auto", overflowY: "hidden"}}>
                                            <div style={{display:"inline-block", height:"100%", minWidth:"1080px", width:"100%"}}>
                                                <div className="table" style={{marginBottom:0}}>
                                                    <div className="table table_v3" style={{marginBottom:0}}>
                                                        <table style={{overflowX: "visible",display: "table-row"}}>
                                                            <colgroup>
                                                            </colgroup>
                                                            <thead style={{background: "#EBEBED", border:"0",height: "40px"}}>	
                                                                <tr style={{background: "#EBEBED", border:"0"}}>
                                                                    <td className="user_id" onClick={this.onClickSorting} style={{width:"200px", marginLeft:"10px", border:"5px white solid"}}>
                                                                        <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>상점명
                                                                        </p>
                                                                    </td>
                                                                    <td className="corp_reg_no" onClick={this.onClickSorting} style={{width:"130px", border:"5px white solid"}}>
                                                                        <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>전자상거래
                                                                        </p>
                                                                    </td>
                                                                    <td className="corp_name" onClick={this.onClickSorting} style={{width:"250px", border:"5px white solid"}}>
                                                                        <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>주소
                                                                        </p>
                                                                    </td>
                                                                    <td className="business_type" onClick={this.onClickSorting} style={{width:"250px", border:"5px white solid"}}>
                                                                        <p style={{display:"flex", justifyContent:"space-between", pointerEvents: "none", margin: "auto"}}>
                                                                            <span style={{margin:"auto 0"}}>이번 달 페이지뷰</span>
                                                                            <span style={{color:"#B4B4B5", fontSize:"12px", fontWeight:"400", margin:"auto 0"}}>명</span>
                                                                        </p>
                                                                    </td>
                                                                    <td className="business_item" onClick={this.onClickSorting} style={{width:"250px", border:"5px white solid"}}>
                                                                        <p style={{display:"flex", justifyContent:"space-between", pointerEvents: "none", margin: "auto"}}>
                                                                            <span style={{margin:"auto 0"}}>지난 달 페이지뷰</span>
                                                                            <span style={{color:"#B4B4B5", fontSize:"12px", fontWeight:"400", margin:"auto 0"}}>명</span>
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            </thead>
                                                            <tbody style={{height:"auto", background:"#F5F5F7"}}>
                                                                    <tr style={{marginTop: "-5px"}}>
                                                                        <td style={{width:"200px", border:"5px white solid"}}>
                                                                            <p className="card-text">{"블루리본"}</p>
                                                                        </td>
                                                                        <td style={{width:"130px", border:"5px white solid"}}>
                                                                            <p className="card-text">{"카페24"}</p>
                                                                        </td>
                                                                        <td style={{width:"250px", border:"5px white solid", fontWeight:"400"}}>
                                                                            <p className="card-text">{"blueribbon.co.kr"}</p>
                                                                        </td>
                                                                        <td style={{width:"250px", border:"5px white solid", fontWeight:"400"}}>
                                                                            <p className="card-text" style={{textAlign:"right"}}>{"123,456"}</p>
                                                                        </td>
                                                                        <td style={{width:"250px", border:"5px white solid", fontWeight:"400"}}>
                                                                            <p className="card-text" style={{textAlign:"right"}}>{"123,456"}</p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr style={{marginTop: "-5px"}}>
                                                                    <td style={{width:"200px", border:"5px white solid"}}>
                                                                            <p className="card-text">{"로코블릭"}</p>
                                                                        </td>
                                                                        <td style={{width:"130px", border:"5px white solid"}}>
                                                                            <p className="card-text">{"카페24"}</p>
                                                                        </td>
                                                                        <td style={{width:"250px", border:"5px white solid", fontWeight:"400"}}>
                                                                            <p className="card-text">{"roccoblick.com"}</p>
                                                                        </td>
                                                                        <td style={{width:"250px", border:"5px white solid", fontWeight:"400"}}>
                                                                            <p className="card-text" style={{textAlign:"right"}}>{"123,456"}</p>
                                                                        </td>
                                                                        <td style={{width:"250px", border:"5px white solid", fontWeight:"400"}}>
                                                                            <p className="card-text" style={{textAlign:"right"}}>{"123,456"}</p>
                                                                        </td>
                                                                    </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :   ""
                    }  
                </div>

                <div className="credit_card_box_area" style={{marginTop:"20px", padding: "37px 50px", border:"1px #EBEBED solid", borderRadius:"10px"}}>
                    <div className="flexBox" style={{marginTop:"11px"}}>
                        <div className="icn_round_blue" style={{width: "10px", height: "10px", margin: "auto 0", fontSize:"18px"}}></div>
                        <div className="icn_credit_card_text" style={{flex: 1, margin: "auto 0 auto 15px", fontSize:"15px", fontWeight:"500"}}>&nbsp;위 예상 금액은 어제까지 발생한 추가 페이지 뷰를 바탕으로 산정한 금액입니다.</div>
                    </div>
                    <div className="flexBox" style={{marginTop:"11px"}}>
                        <div className="icn_round_blue" style={{width: "10px", height: "10px", margin: "auto 0", fontSize:"18px"}}></div>
                        <div className="icn_credit_card_text" style={{flex: 1, margin: "auto 0 auto 15px", fontSize:"15px", fontWeight:"500"}}>&nbsp;추가요금은 전월 말일부터 당월 말일 하루 전날까지 페이지 뷰를 바탕으로 산정합니다.</div>
                    </div>
                    <div className="flexBox" style={{marginTop:"11px"}}>
                        <div className="icn_round_blue" style={{width: "10px", height: "10px", margin: "auto 0", fontSize:"18px"}}></div>
                        <div className="icn_credit_card_text" style={{flex: 1, margin: "auto 0 auto 15px", fontSize:"15px", fontWeight:"500"}}>&nbsp;실제 결제일(매달 말일)에는 최종 결제 금액이 다를 수 있습니다.</div>
                    </div>
                    <div className="flexBox" style={{marginTop:"11px"}}>
                        <div className="icn_round_blue" style={{width: "10px", height: "10px", margin: "auto 0", fontSize:"18px"}}></div>
                        <div className="icn_credit_card_text" style={{flex: 1, margin: "auto 0 auto 15px", fontSize:"15px", fontWeight:"500"}}>&nbsp;다음 결제 예상 금액 산정 시 자동으로 잔액 보유액을 차감합니다.</div>
                    </div>
                </div>

                {/* 실시간 계좌이체 FORM */}
                <form name="payForm" style={{display:"none"}} accept-charset="euc-kr">
                <table style={{width:"100%",border:"1px",cellspacing:"0", cellpadding:"0"}}>
                    <colgroup>
                        <col width="160px" />
                        <col width="*" />
                    </colgroup>
                    <tr>
                        <td></td>
                        <td>
                            {/* <input type="button" value="결제하기" onClick={this.payLink} style={{width:"63", height:"25"}}/> */}
                        </td>
                    </tr>
                </table>
                <br/>
                <br/>

                <table style={{width:"100%",border:"1px",cellspacing:0,cellpadding:0}}>
                    <colgroup>
                        <col width="160px" />
                        <col width="180px" />
                        <col width="*" />
                    </colgroup>
                    <tr> 
                        <td colspan="3"><b>----------공통항목(필수)</b></td>
                    </tr>
                    <tr> 
                        <td><b>파라미터</b></td>
                        <td><b>값</b></td>
                        <td><b>설명</b></td>
                    </tr>
                    <tr> 
                        <td>USER_SEQ</td>
                        <td><input type="text" name="RESERVEDINDEX1" value={this.state.payLinkPreData.USER_SEQ}/></td>
                        <td>사용자 일련번호</td>
                    </tr>
                    <tr> 
                        <td>SHOP_NO</td>
                        <td><input type="text" name="RESERVEDINDEX2" value={this.state.payLinkPreData.SHOP_NO}/></td>
                        <td>쇼핑몰 번호</td>
                    </tr>
                    <tr> 
                        <td>STD_YM</td>
                        <td><input type="text" name="STD_YM" value={this.state.payLinkPreData.STD_YM}/></td>
                        <td>기준 년월</td>
                    </tr>
                    <tr> 
                        <td>PAYMENT_AMOUNT_SEQ</td>
                        <td><input type="text" name="PAYMENT_AMOUNT_SEQ" value={this.state.payLinkPreData.PAYMENT_AMOUNT_SEQ}/></td>
                        <td>결제금액 일련번호</td>
                    </tr>
                    <tr> 
                        <td colspan="3"><b>----------고정항목----------</b></td>
                    </tr>
                    <tr> 
                        <td>TYPE</td>
                        <td><input type="text" name="TYPE" value="W"/></td>
                        <td>결제방식</td>
                    </tr>
                    <tr> 
                        <td>PAYMETHOD</td>
                        <td><input type="text" name="PAYMETHOD" value="BANK"/></td>
                        <td>결제수단</td>
                    </tr>
                    <tr> 
                        <td>CPID</td>
                        {/* 개발 : <td><input type="text" name="CPID" value="CTS16826"/></td> */}
                        <input type="text" name="CPID" value="CRG55686"/>
                        <td>상점아이디</td>
                    </tr>
                    <tr> 
                        <td>PRODUCTTYPE</td>
                        <td><input type="text" name="PRODUCTTYPE" value="2"/></td>
                        <td>상품구분(1: 디지털, 2: 실물)</td>
                    </tr>
                    <tr> 
                        <td colspan="3"><b>----------변동항목(DB에서 조회)----------</b></td>
                    </tr>
                    <tr> 
                        <td>ORDERNO</td>
                        <td><input type="text" name="ORDERNO" value={this.state.payLinkPreData.ORDERNO}/></td>
                        <td>주문번호</td>
                    </tr>
                    <tr> 
                        <td>AMOUNT</td>
                        <td><input type="text" name="AMOUNT" value={this.state.payLinkPreData.AMOUNT}/></td>
                        {/* <td><input type="text" name="AMOUNT" value="1000"/></td> */}
                        <td>결제금액</td>
                    </tr>
                    <tr> 
                        <td>PRODUCTNAME</td>
                        <td><input type="text" name="PRODUCTNAME" value={this.state.payLinkPreData.PRODUCT_NAME}/></td>
                        <td>상품명</td>
                    </tr>
                    <tr> 
                        <td>PRODUCTCODE</td>
                        <td><input type="text" name="PRODUCTCODE" value={this.state.payLinkPreData.PRODUCT_NO}/></td>
                        <td>상품코드</td>
                    </tr>
                    <tr> 
                        <td>USERID</td>
                        <td><input type="text" name="USERID" value={this.state.payLinkPreData.USERID}/></td>
                        <td>고객 ID</td>
                    </tr>
                    <tr> 
                        <td>EMAIL</td>
                        <td><input type="text" name="EMAIL" value={this.state.payLinkPreData.EMAIL}/></td>
                        <td>고객 이메일</td>
                    </tr>
                    <tr> 
                        <td>USERNAME</td>
                        <td><input type="text" name="USERNAME" value={this.state.payLinkPreData.USERNAME}/></td>
                        <td>고객명</td>
                    </tr>
                    <tr> 
                        <td colspan="3"><b>----------변동항목(선택)----------</b></td>
                    </tr>
                    <tr> 
                        <td>RESERVEDSTRING</td>
                        <td><input type="text" name="RESERVEDSTRING" value={this.state.payLinkPreData.PAYMENT_AMOUNT_SEQ}/></td>
                        <td>예약스트링</td>
                    </tr>
                    <tr> 
                        <td>RETURNURL</td>
                        <td><input type="text" name="RETURNURL" value="https://robomd.io/shop/order/list"/></td>
                        <td>결제 성공 후, 이동할 URL(새 창)</td>
                    </tr>
                    <tr> 
                        <td>DIRECTRESULTFLAG</td>
                        <td><input type="text" name="DIRECTRESULTFLAG" value=""/></td>
                        <td>페이조아 결제 완료 창 없이 HOMEURL로 바로 이동(Y/N)</td>
                    </tr>
                    <tr> 
                        <td>SET_LOGO</td>
                        <td><input type="text" name="SET_LOGO" value=""/></td>
                        <td>결제 창 하단 상점로고 URL</td>
                    </tr>	
                    <tr> 
                        <td colspan="3"><b>----------웹뷰결제창(필수)</b></td>
                    </tr> 
                    <tr> 
                        <td>HOMEURL</td>
                        <td><input type="text" name="HOMEURL" value="javascript:window.close()"/></td>
                        <td>결제 성공 후, 이동할 URL(결제 창에서 이동)</td>
                    </tr>
                    <tr>
                        <td>CLOSEURL</td>
                        <td><input type="text" name="CLOSEURL" value="javascript:window.close()"/></td>
                        <td>결제 창에서 취소 누를 시 이동할 URL</td>
                    </tr>
                    <tr> 
                        <td>FAILURL</td>
                        <td><input type="text" name="FAILURL" value="javascript:window.close()"/></td>
                        <td>결제실패 후, 확인버튼 입력 시 이동할 URL</td>
                    </tr>
                    <tr>
                        <td>APPURL</td>
                        <td><input type="text" name="APPURL" value=""/></td>
                        <td>인증완료 후 돌아갈 앱의 URL(CARD, CARDK, BANK 필수)</td>
                    </tr>
                    <tr> 
                        <td colspan="3"><b>----------계좌이체(선택)</b></td>
                    </tr>
                    <tr> 
                        <td>USERSSN</td>
                        <td><input type="text" name="USERSSN" value=""/></td>
                        <td>생년월일(YYMMDD)</td>
                    </tr>
                    <tr> 
                        <td>CHECKSSNYN</td>
                        <td><input type="text" name="CHECKSSNYN" value=""/></td>
                        <td>USERSSN사용여부(Y/N)(계좌이체 결제자와 명의자(USERSSN)가 동일한 경우만 결제가능)</td>
                    </tr>
                </table>
                </form>
            </div>
        )
    }
}   
import $ from 'jquery';

/**
 * textarea 영역 동적으로 변환
 * @param {*} id textarea id값 , 필수
 * @param {*} lineNum 최소 높이가 몇줄인지에 대한 정보, 없으면 한줄이 기본값
 */
 export function textareaHeightAuto(id, lineNum) {
    let scrollTop = $(document).scrollTop(); // textarea css 변경 전 스크롤 위치 추출

    lineNum = lineNum != null ? lineNum : 1;
    let paddingTop = Number($("#"+id).css("padding-top").split('px')[0]);
    let paddingBottom = Number($("#"+id).css("padding-bottom").split('px')[0]);
    let lineHeight = Number($("#"+id).css("line-height").split('px')[0]);
    let basicHeight = paddingTop+paddingBottom+lineHeight;
    let minHeight = paddingTop+paddingBottom+(lineHeight*lineNum);
    var sTextarea = document.getElementById(id);

    $("#"+id).css({"min-height":minHeight});
    $("#"+id).css({"height":basicHeight});
    $("#"+id).css({"height":sTextarea.scrollHeight});
    $("#"+id).css({"resize":"none"});
    $("#"+id).css({"overflow-y":"hidden"});

    $(document).scrollTop(scrollTop); // textarea css 변경 전 스크롤 위치 적용 (스크롤 위치가 바뀌는 현상 방지)
}

/** 스크롤 고정 */
export function onPopupNoneScroll() {
    document.body.style.overflow = 'hidden';
}

/** 스크롤 고정 해제 */
export function clearPopupNoneScroll() {
    document.body.style.overflow = 'auto';
}
import React from "react";
import { v4 as uuidv4 } from 'uuid';

/**
 * @param {*} id 컴포넌트를 식별할 수 있는 id명, null이면 기본 id 사용
 * @param {*} name 토글 name,
 * @param {*} value 토글 value,
 * @param {*} checked 체크 상태 (true or false), null이면 항상 false 적용
 * @param {*} disabled 비활성화 상태 (true or false), null이면 false 적용
 * @param {*} onClickOption 옵션 클릭 이벤트 (예: onClickOption (id)), null이면 기본 id 사용
 * @param {*} backgroundColor 토글 메인 컬러
 * @param {*} disabledColor 비활성화 컬러
 * @param {*} toggleAnimation 토글 애니메이션 활성화 여부
 * @param {*} className 클래스 명, (토글 사이즈 small 존재)
 */
class ToggleButton extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            id: '',
        }
    }

	componentDidMount = () => {
        this.setState({id : this.props.id ? this.props.id : uuidv4()});
    }

	render () {
        const {name, value, checked, disabled, backgroundColor, disabledColor, onClickOption, toggleAnimation, className} = this.props;
        const {id} = this.state;

		return (
			<>
                <div class={"toggle_button "+className}>
                    <input id={id} class="toggle_button_id" type="checkbox" name={name} value={value} checked={checked} disabled={disabled} onClick={e => onClickOption(e)}/>
                    <label class="toggle_button_btn" for={id}>
                        <div
                            class="toggle_button_btn_1"
                            for={id}
                            style={{
                                backgroundColor : disabled ? disabledColor : checked ? backgroundColor : disabledColor,
                                cursor: disabled ? "default": "pointer",
                                transition: toggleAnimation ?  '0.2s' : '0s',
                            }}
                        >
                            <span style={{transition: toggleAnimation ?  '0.2s' : '0s'}}></span>
                        </div>
                    </label>
                </div>
			</>
		);
	}
}

ToggleButton.defaultProps = {
    backgroundColor : "#00e2e6",
    disabledColor : "#eee",
    checked : false,
    disabled : false,
    toggleAnimation : true,
    className : "",
}

export default ToggleButton;
import React from 'react';
import axios from 'axios';
import http from '../modules/api';
import {Route, Switch, NavLink, Link} from "react-router-dom";
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";
import "react-datepicker/dist/react-datepicker.css";

import $ from 'jquery';

http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
export class TaxRequestList extends React.Component {
	state = {
			tax_request_list: [],
			orderGubun: "",
			sorting: "",
			currentPage: "",
			start_date: "",
			end_date: "",
			start_date_format: "",
			end_date_format: "",
			title: "",
			activePage: 1,
			totalCnt: 0,
			startIdx: 0,
			rowcnt:10,
			type:""
	};

	handleText = (e, key) => {
	    // computed property
		this.setState({[key]: e.target.value});
	}
	
	componentDidMount(e) {
		// console.log(localStorage.getItem ('state'))
		// if (localStorage.getItem ('state') != null){
		// 	new Promise( ( resolve, reject ) => {setTimeout( resolve, 200 ); })
		// 	.then( () => {
		// 		var json = JSON.parse(localStorage.getItem ('state'));
		// 		json.qna_list = [];
		// 		json.start_date = Date.parse(json.start_date_format);
		// 		json.end_date = Date.parse(json.end_date_format);
		// 		this.setState(json);
		// 		// this.setState({qna_list: []});
		// 		this.state.startIdx = parseInt(localStorage.getItem("startIdx"));
		// 		console.log(localStorage.getItem ('state'));
		// 		console.log(JSON.parse(localStorage.getItem ('state')));
		// 		console.log(this.state.title);
		// 		localStorage.clear();
		// 		this.getTaxRequestList();
		// 	});
		// } else {
			// this.getQnaTypeList();
			this.getTaxRequestList();
		// }
	}

	componentDidUpdate(e) {
		// console.log("######### componentDidUpdate ##########")
	}

	onComponentChange = e => {
		// console.log("######### onComponentChange ##########")
	}

	componentWillUnmount = e => {
		// console.log("######### componentWillUnmount ##########")
	}
	
	// 0. 질문 타입 조회
	async getQnaTypeList() {
		await http.get("/rest/common/qna/type")
		.then(res => {
			this.setState( {qna_type : res.data["list"]} );
			// console.log(res)
		})
		.catch(error => {
			// console.log (error);
		});
	}

	//1. 목록 조회
	async getTaxRequestList() {
		await http.get("/rest/shop/list/tax/request?start_date="+this.state.start_date_format+"&end_date="+this.state.end_date_format+"&title="+this.state.title
		+"&order_type="+this.state.sorting+"&order_gubun="+this.state.orderGubun+"&startidx="+this.state.startIdx+"&rowcnt="+this.state.rowcnt+"&qna_type_cd="+this.state.type)
		.then(res => {
			this.setState( {tax_request_list : res.data["list"]} );
			this.setState( {totalCnt : res.data["totalCnt"]} );
			// console.log(this.state.totalCnt)
			console.log ("===== res =====");
			console.log(res);
			// if (res.indexOf("status code 404") > 0 ) {
			// 	window.location.href = "/login"
			// } 
		})
		.catch(error => {
			// console.log ("===== error =====");
			// console.log (error);
			// console.log ("===== error request =====");
			// console.log(error.request);
		 });
    }
	
	//2. 상세 조회
	handleClick = (qna_seq) => {
		this.props.history.push(`/shop/qna/detail/${qna_seq}`);
		localStorage.setItem ( 'state' , JSON.stringify(this.state) ) ;
		localStorage.setItem ( 'startIdx' , this.state.startIdx ) ;
	};
	
	// 3. 정렬
	onClickSorting = (e) => {
		if (this.state.orderGubun == e.target.className.replace(" sorting","")) {
			if (this.state.sorting == "ASC")
			this.state.sorting = "DESC";
			else
			this.state.sorting = "ASC";
		} else {
			this.state.sorting = "DESC";
		}
		this.state.orderGubun = e.target.className.replace(" sorting","");
		this.state.currentPage = 1;
		this.getTaxRequestList()
	};

	//4. 조회 버튼 클릭
	onClickSearchListButton = () => {
		this.state.startIdx = 0;
		this.state.activePage = 1;
		this.getTaxRequestList();
	};

	//4. 달력 클릭
	handleChangeStartDate = date => {
		if (date != null) {
			this.setState({
				start_date_format: date.getFullYear() + "-"+ ("0" + parseInt(date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(date.getDate())).slice(-2),
				start_date: date
			});
		} else {
			this.setState({
				start_date_format: date,
				start_date: date
			});
		}
	};
	handleChangeEndDate = date => {
		if (date != null) {
			this.setState({
				end_date_format: date.getFullYear() + "-"+ ("0" + parseInt(date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(date.getDate())).slice(-2),
				end_date: date
			});
		} else {
			this.setState({
				end_date_format: date,
				end_date: date
			});
		}
	};

	//5. 엔터키 검색
	_handleKeyDown = e => {
		if (e.key === 'Enter') {
			this.state.startIdx = 0;
			this.state.activePage = 1;
			this.getTaxRequestList();
		}
	  }

	//6.페이징
	handlePageChange(pageNumber) {
		// console.log(`active page is ${pageNumber}`);
		this.setState({activePage: pageNumber});
		this.state.startIdx = (pageNumber * 10) - this.state.rowcnt;
		// console.log("this.state.startIdx :" + this.state.startIdx )
		this.getTaxRequestList()
	  }  

	_handleChange = (e, key) => {
	    // computed property
	    this.setState({[key]: e.target.value});
	};

	  componentWillReceiveProps(nextProps, nextContext) {
		// console.log(nextProps);
		this.getTaxRequestList(nextProps.match.params.id);
	  }

	  render() {
		
		return (
			<div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
				<div id="qna_list_component">
					<h3>[Shop] 세금계산서 신청 목록</h3>
					<br/>
					<div className="filter">
						{/* <div style={{display:"flex", marginBottom: '-12px'}}>
							<div className="input-group m-b" style={{width:"66%"}}>
								<div style={{width: "100%"}}> 
								<label>날짜</label>
									<div className="input-daterange input-group" id="datepicker">
										<DatePicker name="start_date" id="start_date"
											selected={this.state.start_date}
											onSelect={this.handleSelect} //when day is clicked
											onChange={this.handleChangeStartDate} //only when value has changed
											// showTimeSelect
											dateFormat="yyyy-MM-dd"
											autoComplete='off'
										/>
										<div className="input-group-addon" style={{width: "40px",height:"35px", lineHeight: "34px",textAlign:"center"}}>to</div>
										<DatePicker name="end_date" id="end_date"
											selected={this.state.end_date}
											onSelect={this.handleSelect} //when day is clicked
											onChange={this.handleChangeEndDate} //only when value has changed
											// showTimeSelect
											dateFormat="yyyy-MM-dd"
											autoComplete='off'
										/>	
									</div>
								</div>
							</div>
							<div className="input-group m-b" style={{width:"33%", marginLeft:"10px"}}>
								<label>종류</label>
								<select id="qna_type" style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px", marginBottom:"10px"}}
								value={this.state.type} onChange={(e)=>this._handleChange(e, 'type')} >
									<option value="">
										전체
									</option>
									{this.state.qna_type.map((qna, idx) => (	
										<option value={qna.cd_code} key={idx}>
											{qna.cd_desc}
										</option>								
									))};
								</select>
							</div>
						</div> */}
						<div style={{display:"flex", marginBottom: '12px'}}>
							<input type="text" className="border_form_radius_b_non_padding" onChange={(e) => {
								// console.log(e.target.value)
								this.setState({ title: e.target.value })
							}} placeholder="검색어를 입력하세요." style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px"}} onKeyDown={this._handleKeyDown}
							value={this.state.title}></input>
							<button className = "btn-auto-b" onClick={this.onClickSearchListButton} style={{width: "80px",height: "35px", margin: 'auto'}}>검색</button>
						</div>
					</div>
					<div className="banner_list_table_area" style={{width:"calc(95vw - 600px)", minWidth:"100%", height:"100%", overflowX:"auto", overflowY: "hidden"}}>
						<div style={{display:"inline-block", height:"100%", minWidth:"1100px", width:"100%"}}>
							<div className="table table_V2" style={{marginBottom:0}}>
								<div className="table" style={{marginBottom:0}}>
									<table>
										<colgroup>
											{/* <col width="70px" />
											<col width="70px" />
											<col width="100px" />
											<col width="70px" />
											<col width="70px" />
											<col width="100px" />
											<col width="100px" />
											<col width="100px" />
											<col width="200px" />
											<col width="100px" />
											<col width="100px" /> */}
											{/* <col width="150px" /> */}
										</colgroup>
										<thead>	
											<tr>
												<td className="user_id sorting" onClick={this.onClickSorting} style={{marginLeft:"10px"}}>
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>아이디
													{(() => {
														if (this.state.orderGubun == "user_id") {
															if(this.state.sorting == "ASC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_b"></div> 
																	</>
																)
															} else if (this.state.sorting == "DESC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																		<div className="icn_table_v2_sort_asc_arrow_d"></div>
																	</>
																)
															} else {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
																)
															}
														} else {
															return ( 
																<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
															)
														}
													})()}
													</p>
												</td>
												<td className="corp_reg_no sorting" onClick={this.onClickSorting}>
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>사업자<br/>등록번호
													{(() => {
														if (this.state.orderGubun == "corp_reg_no") {
															if(this.state.sorting == "ASC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_b"></div> 
																	</>
																)
															} else if (this.state.sorting == "DESC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																		<div className="icn_table_v2_sort_asc_arrow_d"></div>
																	</>
																)
															} else {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
																)
															}
														} else {
															return ( 
																<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
															)
														}
													})()}
													</p>
												</td>
												<td className="corp_name sorting" onClick={this.onClickSorting} >
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>상호(법인명)
													{(() => {
														if (this.state.orderGubun == "corp_name") {
															if(this.state.sorting == "ASC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_b"></div> 
																	</>
																)
															} else if (this.state.sorting == "DESC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																		<div className="icn_table_v2_sort_asc_arrow_d"></div>
																	</>
																)
															} else {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
																)
															}
														} else {
															return ( 
																<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
															)
														}
													})()}
													</p>
												</td>
												<td className="business_type sorting" onClick={this.onClickSorting}>
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>업태
													{(() => {
														if (this.state.orderGubun == "business_type") {
															if(this.state.sorting == "ASC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_b"></div> 
																	</>
																)
															} else if (this.state.sorting == "DESC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																		<div className="icn_table_v2_sort_asc_arrow_d"></div>
																	</>
																)
															} else {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
																)
															}
														} else {
															return ( 
																<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
															)
														}
													})()}
													</p>
												</td>
												<td className="business_item sorting" onClick={this.onClickSorting}>
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>업종
													{(() => {
														if (this.state.orderGubun == "business_item") {
															if(this.state.sorting == "ASC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_b"></div> 
																	</>
																)
															} else if (this.state.sorting == "DESC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																		<div className="icn_table_v2_sort_asc_arrow_d"></div>
																	</>
																)
															} else {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
																)
															}
														} else {
															return ( 
																<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
															)
														}
													})()}
													</p>
												</td>
												<td className="representative_name sorting" onClick={this.onClickSorting}>
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>대표자명
													{(() => {
														if (this.state.orderGubun == "representative_name") {
															if(this.state.sorting == "ASC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_b"></div> 
																	</>
																)
															} else if (this.state.sorting == "DESC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																		<div className="icn_table_v2_sort_asc_arrow_d"></div>
																	</>
																)
															} else {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
																)
															}
														} else {
															return ( 
																<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
															)
														}
													})()}
													</p>
												</td>
												<td className="email sorting" onClick={this.onClickSorting}>
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>이메일
													{(() => {
														if (this.state.orderGubun == "email") {
															if(this.state.sorting == "ASC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_b"></div> 
																	</>
																)
															} else if (this.state.sorting == "DESC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																		<div className="icn_table_v2_sort_asc_arrow_d"></div>
																	</>
																)
															} else {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
																)
															}
														} else {
															return ( 
																<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
															)
														}
													})()}
													</p>
												</td>
												{/* <td className="business_registration_file_url sorting" onClick={this.onClickSorting}>
													사업자등록증_파일_URL
												</td> */}
												<td className="business_registration_file_name sorting" onClick={this.onClickSorting}>
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>사업자등록증
													{(() => {
														if (this.state.orderGubun == "business_registration_file_name") {
															if(this.state.sorting == "ASC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_b"></div> 
																	</>
																)
															} else if (this.state.sorting == "DESC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																		<div className="icn_table_v2_sort_asc_arrow_d"></div>
																	</>
																)
															} else {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
																)
															}
														} else {
															return ( 
																<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
															)
														}
													})()}
													</p>
												</td>
												<td className="val sorting" onClick={this.onClickSorting}>
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>비고
													{(() => {
														if (this.state.orderGubun == "val") {
															if(this.state.sorting == "ASC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_b"></div> 
																	</>
																)
															} else if (this.state.sorting == "DESC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																		<div className="icn_table_v2_sort_asc_arrow_d"></div>
																	</>
																)
															} else {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
																)
															}
														} else {
															return ( 
																<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
															)
														}
													})()}
													</p>
												</td>
												<td className="reg_date sorting" onClick={this.onClickSorting}>
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>등록날짜
													{(() => {
														if (this.state.orderGubun == "reg_date") {
															if(this.state.sorting == "ASC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_b"></div> 
																	</>
																)
															} else if (this.state.sorting == "DESC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																		<div className="icn_table_v2_sort_asc_arrow_d"></div>
																	</>
																)
															} else {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
																)
															}
														} else {
															return ( 
																<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
															)
														}
													})()}
													</p>
												</td>
												<td className="mod_date sorting" onClick={this.onClickSorting}>
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>수정날짜
													{(() => {
														if (this.state.orderGubun == "mod_date") {
															if(this.state.sorting == "ASC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_b"></div> 
																	</>
																)
															} else if (this.state.sorting == "DESC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																		<div className="icn_table_v2_sort_asc_arrow_d"></div>
																	</>
																)
															} else {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
																)
															}
														} else {
															return ( 
																<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
															)
														}
													})()}
													</p>
												</td>
												{/* <td className="mod_date sorting" onClick={this.onClickSorting}>
													<p className="card-text">수정일자</p>
												</td> */}
											</tr>
										</thead>
										<tbody style={{height:"auto"}}>
											{this.state.tax_request_list.map((obj, idx) => (
												<tr key={idx}>
													<td>
														<p className="card-text"><a href={"/admin/userFind/"+obj.user_id}>{obj.user_id}</a></p>
													</td>
													<td>
														<p className="card-text">{obj.corp_reg_no}</p>
													</td>
													<td>
														<p className="card-text">{obj.corp_name}</p>
													</td>
													<td>
														<p className="card-text">{obj.business_type}</p>
													</td>
													<td>
														<p className="card-text">{obj.business_item}</p>
													</td>
													<td>
														<p className="card-text">{obj.representative_name}</p>
													</td>
													<td>
														<p className="card-text">{obj.email}</p>
													</td>
													<td>
														<p className="card-text"><a href={obj.business_registration_file_url}>{obj.business_registration_file_name}</a></p>
													</td>
													{/* <td>
														<p className="card-text">{obj.business_registration_file_name}</p>
													</td> */}
													<td>
														<p className="card-text">{obj.val}</p>
													</td>
													<td>
														<p className="card-text">{obj.reg_date}</p>
													</td>
													<td>
														<p className="card-text">{obj.mod_date}</p>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div style={{marginTop:"1em"}}>
						<Pagination
							activePage={this.state.activePage}
							itemsCountPerPage={this.state.rowcnt}
							totalItemsCount={this.state.totalCnt}
							pageRangeDisplayed={5}
							onChange={this.handlePageChange.bind(this)}
						/>
					</div>
				</div>
			</div>
		);
	}
}

import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {http} from '../../modules/api';
import CheckBox from '../../modules/common/CheckBox';
import SearchProductPopup from '../../modules/popup/SearchProductPopup';
import PopupFrame from "../../modules/popup/PopupFrame";
import ConfirmDialogV2 from "../../modules/dialogs/ConfirmDialogV2";
import BasicDialogV2 from "../../modules/dialogs/BasicDialogV2";
import SaveDialogV2 from "../../modules/dialogs/SaveDialogV2";

import {loadList, saveList, addItem, removeItem} from "../slice/ExcludeProductsSlice";

const ExcludeProductTableMgmt = ({iframePageId, iframeHtmlId, imageTypeCd, onClose}) => {
    const excludeList = useSelector((state) => state.excludeProductsSlice.list);
    const modifiedToggle = useSelector((state) => state.excludeProductsSlice.modified);
    const saveStatus = useSelector((state) => state.excludeProductsSlice.saveStatus);
    const [checkList, setCheckList] = useState([]);
    const [addPopupToggle, setAddPopupToggle] = useState(false);
    const [noticeDialogToggle, setNoticeDialogToggle] = useState(false);
    const [cancelDialogToggle, setCancelDialogToggle] = useState(false);
    const [saveDialogToggle, setSaveDialogToggle] = useState(false);
    const [loadingDialogToggle, setLoadingDialogToggle] = useState(false);
    const [finishDialogToggle, setFinishDialogToggle] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        // 수정 상태가 변화하면 체크 목록을 초기화 시킨다 (취소 클릭시)
        setCheckList([]);
    }, [modifiedToggle]);

    useEffect(() => {
        // 저장중 다이얼로그가 열려있는 상태
        if(loadingDialogToggle){
            if(saveStatus === "success"){
                // 저장 성공시 다이얼로그 작업
                setFinishDialogToggle(true);
                setLoadingDialogToggle(false);
            } else if(saveStatus === "fail"){
                // 저장 실패시 다이얼로그 작업
                setLoadingDialogToggle(false);
            }
        }
    }, [saveStatus]);
    

    /** 제외 상품 목록 불러오기 */
    const loadExcludeProductList = async() => {
        dispatch(loadList({pageID : iframePageId, htmlID : iframeHtmlId}));
    }

    /** 제외 상품 목록 팝업 전체 체크
     * @param {*} e 전체 체크 상태
    */
    const onChangeExcludeProductListCheckAll = useCallback((e) => {
        setCheckList(e.target.checked ? [...excludeList] : []);
    },[excludeList]);

    /** 제외 상품 목록 팝업 체크
     * @param {*} e 체크 상태
     * @param {*} idx 체크된 idx 번호
    */
    const onChangeExcludeProductListCheck = useCallback((e, idx) => {
        if (!e.currentTarget.checked) {
            setCheckList(checkList.filter((obj) => obj.product_no !== excludeList[idx].product_no));
        } else {
            setCheckList([...checkList, excludeList[idx]]);
        }
    },[excludeList, checkList]);

    const onClickAddProductbtn = (allProductList) => {
        const excludeProductNoList = new Set(excludeList.map(product => product.product_no));
        const addList = allProductList.filter(product => !excludeProductNoList.has(product.product_no));

        if(addList.length === 0){
            setNoticeDialogToggle(true);
            return;
        }

        const newAddList = addList.map(product => ({
            ...product,
            iframe_page_id: iframePageId,
            iframe_html_id: iframeHtmlId,
            create_type_cd : "22999"
        }));

        dispatch(addItem(newAddList));
        setAddPopupToggle(false);
    }

    /** 제외 상품 삭제 */
    const delExcludeProductList = () => {
        if(excludeList.length === 0) {return}

        const checkSet = new Set(checkList.map(product => product.product_no));
        dispatch(removeItem(checkSet))
        setCheckList([]);
    }

    /** 제외 상품 목록 저장 */
    const saveExcludeProductList = () => {
        dispatch(saveList({
            list : excludeList,
            iframe_page_id : iframePageId,
            iframe_html_id : iframeHtmlId,
        }));
    }

    const handleCloseMgmt = () => {
        onClose();
    }

    return (
        <>
            <PopupFrame
                title={"제외 상품 설정"}
                content={"자동 선정되는 상품에서 강제로 제외되는 상품을 추가할 수 있습니다"}
                maxWidth={"1150px"}
                onClose={handleCloseMgmt}
            >
                <div class="list_main_board_row_1 exclude_list_table_control" id="exclude_list_table_control">
                {iframeHtmlId
                ?<>
                    <button class="list_exclude_btn text_extrasmall hover_grey" onClick={delExcludeProductList}>제외 상품 삭제</button>
                    <button class="list_exclude_btn text_extrasmall hover_mint_b" onClick={() => setAddPopupToggle(true)}>제외 상품 추가</button>
                </>
                :<>
                    <button class="list_exclude_btn text_extrasmall hover_white disabled">제외 상품 삭제</button>
                    <button class="list_exclude_btn text_extrasmall hover_white disabled">제외 상품 추가</button>
                </>}
                </div>
                <div class="selected_list_table_wrap">
                    <table class="selected_list_table list_main_board_table">
                        <thead>
                            <tr>
                                <td class="list_main_td">
                                    <CheckBox
                                        checked={excludeList.length > 0 && excludeList.length == checkList.length}
                                        onChangeCheck={onChangeExcludeProductListCheckAll}
                                    />
                                </td>
                                <td class="list_main_td">순서</td>
                                <td class="list_main_td">사진</td>
                                <td class="list_main_td">상품명</td>
                                <td class="list_main_td">상품등록일</td>
                                <td class="list_main_td">조회수</td>
                                <td class="list_main_td">장바구니수</td>
                                <td class="list_main_td">판매가</td>
                                <td class="list_main_td">할인가</td>
                                <td class="list_main_td">판매수</td>
                                <td class="list_main_td">판매액</td>
                                <td class="list_main_td">리뷰수</td>
                                <td class="list_main_td">리뷰 평점</td>
                            </tr>
                        </thead>
                        
                        {excludeList.length > 0 &&
                            <tbody id="selected_list_table_tbody">
                                {excludeList.map((obj, idx) => (
                                    <tr key={obj.product_no}>
                                        <td>
                                            <CheckBox
                                                value={idx}
                                                checked={checkList.find(product => product.product_no == obj.product_no)}
                                                onChangeCheck={e => onChangeExcludeProductListCheck(e, idx)}
                                            />
                                        </td>
                                        <td>{idx+1}</td>
                                        <td>
                                            <img src={
                                                imageTypeCd === "45002"
                                                ? obj.detail_image_url
                                                : obj.image_url
                                            }/>
                                        </td>
                                        <td>
                                            <h4 class="text_extrasmall">
                                                {obj.product_name}
                                            </h4>
                                        </td>
                                        <td>{obj.created_date}</td>
                                        <td>{obj.hit_cnt}</td>
                                        <td>{obj.cart_cnt}</td>
                                        <td>{obj.price}</td>
                                        <td>{obj.pc_discount_price}</td>
                                        <td>{obj.order_cnt}</td>
                                        <td>{obj.order_price}</td>
                                        <td>{obj.review_cnt}</td>
                                        <td>{obj.review_point}</td>
                                    </tr>
                                ))}
                            </tbody>
                        }
                    </table>
                </div>
                {modifiedToggle &&
                    <div class="selected_list_btn_wrap exclude_list_btn_wrap">
                        <button class="text_extrasmall hover_white" style={{border:"1px solid #ACACAC"}} onClick={() => setCancelDialogToggle(true)}>취소</button>
                        <button class="text_extrasmall hover_mint" onClick={() => setSaveDialogToggle(true)}>저장</button>
                    </div>
                }
                {!iframeHtmlId && <div style={{textAlign:"center", padding:"50px", width:"100%"}}>진열 관리 먼저 저장해주세요.</div>}
            </PopupFrame>

            {/* 상품 추가 팝업 */}
            {addPopupToggle &&
                <SearchProductPopup
                    title = {"제외 상품 추가"}
                    selected_product_list = {excludeList}
                    image_type_cd = {imageTypeCd}
                    onClose = {() => setAddPopupToggle(false)}
                    onSubmit = {onClickAddProductbtn}
                    submit_btn_name = {"선택 상품 추가"}
                />
            }

            {/* 다이얼로그 */}
            {noticeDialogToggle &&
                <BasicDialogV2
                content={"상품을 선택해 주세요."}
                button_list={[
                    {
                        title: "확인", type: "1",
                        event_method: () => setNoticeDialogToggle(false)
                    }
                ]}
                />
            }
            {cancelDialogToggle &&
                <ConfirmDialogV2
                    content={"제외 상품 설정의 변경사항을<br/>취소 하시겠습니까?"}
                    button_list={[
                        {
                            title: "네", type: "1",
                            event_method: () => {
                                setCancelDialogToggle(false);
                                loadExcludeProductList();
                            }
                        },
                        {
                            title: "아니요", type: "0",
                            event_method: () => setCancelDialogToggle(false)
                        }
                    ]}
                    popup_close={() => setCancelDialogToggle(false)}
                />
            }
            {saveDialogToggle &&
                <ConfirmDialogV2
                    content={"변경된 제외 상품 설정을<br/>저장 하시겠습니까?"}
                    button_list={[
                        {
                            title: "네", type: "1",
                            event_method: () => {
                                setSaveDialogToggle(false);
                                setLoadingDialogToggle(true);
                                saveExcludeProductList();
                            }
                        },
                        {
                            title: "아니요", type: "0",
                            event_method: () => setSaveDialogToggle(false)
                        }
                    ]}
                    popup_close={() => setSaveDialogToggle(false)}
                />
            }
            {loadingDialogToggle &&
                <SaveDialogV2
                    content={"저장 중 입니다."}
                    button_list={[
                        {
                            title: "확인", type: "1", display_yn:"N",
                            event_method: () => {},
                        }
                    ]}
                />
            }
            {finishDialogToggle &&
                <SaveDialogV2
                    content={"저장됐습니다."}
                    button_list={[
                        {
                            title: "확인", type: "1",
                            event_method: () => setFinishDialogToggle(false)
                        }
                    ]}
                />
            }
        </>
    );
};

ExcludeProductTableMgmt.defaultProps = {
    productList : [],
    imageTypeCd : '',
    pageId : '',
};
export default React.memo(ExcludeProductTableMgmt);
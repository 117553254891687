import http from "./api"

/** TB_CAFE24_SHOPS.sync_state long polling */
export const subscribeShopDataSyncState = async (time) => {
    while (true) {
        await new Promise(resolve => setTimeout(resolve, 10000))

        /** 최초 10초 ~ 최대 한시간  */
        if ((new Date().getTime() - time) > 3600000) return "cafe24 연동 1시간 초과"
        /** 윈도우 비활성 상태일 경우 재호출 건너뜀. */
        if (document.hidden) continue

        const response = await http.get("/rest/shop/shopSyncStateAndSyncDate")

        if (!response.data) return response
        else if (response.data.sync_state === "0") return true
        else if (response.data.msg) return response.data.msg
    }
}
/**
 * API Presenter
 */
export const APIs = {
    /** 진열 */
    rp : {
        saveListSetting: async (body) => {
            return http.post("/rest/shop/rp/event/setting/v5", {
                ...body
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
        },
        /** 메인진열의 카테고리 목록 API 요청 */
        getMainCategory : async() => {
            return http.get("/rest/shop/cafe24/main/category")
        },
        /** Cafe24 쇼핑몰에 진열되어있는 메인진열 상품 목록 요청 */
        getCafe24MainProductList: async(display_group) => {
            return http.get("/rest/shop/cafe24/main/product", {
                params: {
                    display_group
                }
            })
        },
        /** 이미지 byte 변환 요청 */
        changeImageToByte: async (img_url) => {
            return http.get("/rest/common/v2/backImageLoad", {
                params: {
                    img_url
                }
            })
        },
        /** 플랫폼 API 연동 상태 조회 */
        getShopSyncStateAndSyncDate: async () => {
            return http.get("/rest/shop/shopSyncStateAndSyncDate")
        },
        /** 상품 색깔 리스트 */
        getProductColorList: async () => {
            return http.get("/rest/shop/product/colorList")
        },
        /** 메인진열 상품목록 저장 */
        saveCafe24MainProductList: async(display_group, product_no, fix_product_no, create_type_cds, fixed_sorts, iframe_page_id, iframe_html_id, manual_json) => {
            return http.post("/rest/shop/cafe24/main/product/save", {
                display_group,
                request: {
                    product_no,
                    fix_product_no
                },
                create_type_cds,
                fixed_sorts,
                iframe_page_id,
                iframe_html_id,
                manual_json
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
        },
        /** 메인진열 상품목록 저장 */
        saveCafe24MainProductListV3: async(display_group, product_no, fix_product_no, create_type_cds, fixed_sorts, iframe_page_id, iframe_html_id, manual_json) => {
            return http.post("/rest/shop/cafe24/main/product/saveV3", {
                display_group,
                request: {
                    product_no,
                    fix_product_no
                },
                create_type_cds,
                fixed_sorts,
                iframe_page_id,
                iframe_html_id,
                manual_json
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
        },
        /** 오른쪽 알고리즘 결과 저장 */
        saveRpEventResultProducts: async() => {
            return http.post("/rest/shop/cafe")
        },
        /** 알고리즘 기반 상품목록 요청 */
        getProductsByAlgorithm: async(body) => {
            return http.post("/rest/shop/rp/event/create/manual/v4", body, 
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                } 
            )
        },
        /** 알고리즘 기반 상품목록 요청 */
        getProductsByAlgorithmV5: async(body) => {
            return http.post("/rest/shop/rp/event/create/manual/v5", body, 
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                } 
            )
        },
        /** ----------------------------- */
        /** Cafe24 쇼핑몰상 카테고리 리스트 요청 */
        getCafe24Category: async (category_depth, parent_category_no) => {
            return http.get("/rest/shop/cafe24/category", {
                params: {
                    category_depth,
                    parent_category_no
                }  
            })
        },
        /** 카페24 분류별 진열 순서 적용 버튼 클릭 */
        setCafe24CategoryOrder: async (iframe_page_id, iframe_html_id, create_type_cds, category_no, request_list) => {
            return http.post("/rest/shop/cafe24/category/product/multi/update", {
                iframe_page_id,
                iframe_html_id,
                create_type_cds,
                category_no,
                request_list
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
        },
        deleteAddCafe24CategoryProduct: async(iframe_page_id, iframe_html_id, category_no, display_group, list_self_select_product_list, manual_json, cafe24_category_display_type, cafe24_product_manage_type) => {
            return http.post("/rest/shop/cafe24/category/product/deleteAdd", {
                iframe_page_id,
                iframe_html_id,
                category_no,
                display_group,
                list_self_select_product_list,
                manual_json,
                cafe24_category_display_type,
                cafe24_product_manage_type
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
        },
        deleteCafe24CategoryProduct: async (iframe_page_id, iframe_html_id, create_type_cds, category_no, display_group, product_no) => {
            return http.post("/rest/shop/cafe24/category/product/delete", {
                iframe_page_id,
                iframe_html_id,
                create_type_cds,
                category_no,
                display_group,
                product_no
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
        },
        addCafe24CategoryProduct: async (display_group, product_no, category_no, create_type_cds, iframe_page_id, iframe_html_id, manual_json) => {
            return http.post("/rest/shop/cafe24/category/product/add", {
                iframe_page_id,
                iframe_html_id,
                create_type_cds,
                category_no,
                request: {
                    display_group,
                    product_no,
                },
                manual_json,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
        },
        updateCafe24CategorySetting: async (category_no, request) => {
            return http.post("/rest/shop/cafe24/category/update", {
                category_no,
                request
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
        },
        getCafe24CategoryProductList: async (category_no, display_group) => {
            return http.get("/rest/shop/cafe24/category/products", {
                params: {
                    category_no,
                    display_group
                }
            })
        },
        /** Cafe24 카테고리 분류 설정 검사 (단일 카테고리) 요청 */
        getCheckingCafe24CategorySettingSingle: async (category_no) => {
            return http.get("/rest/shop/cafe24/single/category", {
                params: {
                    category_no
                }
            })
        },
        /** Cafe24 어드민 설정상 자동진열중인 목록 요청 */
        getCategoryAutoLayoutList: async () => {
            return http.get("/rest/shop/cafe24/api/categoryAutoLayoutList")
        },
        /** 로보MD DB 상 카테고리 목록 요청 */
        getRoboMDCategory: async (user_seq, shop_no, parent_category_no) => {
            return http.get("/rest/common/cafe24/category", {
                params: {
                    user_seq,
                    shop_no,
                    parent_category_no
                }
            })
        },
        getProductsByAnalysisStandard: async (product_no_list, analysis_standard_value, startidx, rowcnt) => {
            return http.post("/rest/shop/rp/product/byAnalysisStandard", {
                product_no_list,
                analysis_standard_value,
                startidx,
                rowcnt
            } , {
                headers: {
                'Content-Type': 'application/json',
                }
            })
        },
        /** upperCode 기반 세팅값 API 요청 */
        getSettingsByUpperCode: async (upper_code_cd, is_test_use) => {
            return http.get("/rest/common/cafe24RpEventCode", {
                params : {
                    upper_code_cd,
                    is_test_use
                }
            })
        },
        /** 무료 체험 기간 요청 */
        getCheckingFreeDatePeriod: async () => {
            return http.get("/rest/shop/rp/freeDatePeriod")
        },
        /** 왼쪽 진열 설정 세팅값 요청 */
        getRpEventSetting: async (iframe_page_id, iframe_html_id) => {
            return http.get("/rest/shop/rp/event/setting/v5", {
                params: {
                    iframe_page_id,
                    iframe_html_id
                }
            })
        },
        /** 진열 제외 설정 목록 (아직 지정 안된 목록) 요청 */
        getRpEventExceptSettingsToBe: async (iframe_page_id, iframe_html_id) => {
            return http.get("/rest/shop/rp/event/all/exceptSetting/list", {
                params: {
                    iframe_page_id,
                    iframe_html_id
                }
            })
        },
        /** 진열 제외 설정 목록 (지정 된 목록) 요청 */
        getRpEventExceptSettingsDone: async (iframe_page_id, iframe_html_id) => {
            return http.get("/rest/shop/rp/event/target/exceptSetting/list", {
                params: {
                    iframe_page_id,
                    iframe_html_id
                }
            })
        },
        /** 메인진열 관련 Cafe24 API 체킹 확인 요청 */
        getCheckingCafe24MainAPIRun: async (display_group, iframe_html_id) => {
            return http.get("/rest/shop/cafe24/main/api/run", {
                params: {
                    display_group,
                    iframe_html_id
                }
            })
        },
        /** 카테고리 진열 관련 Cafe24 API 체킹 확인 요청 */
        getCheckingCafe24CategoryAPIRun: async (category_no, display_group, run_type) => {
            return http.get("/rest/shop/cafe24/category/api/run", {
                params: {
                    category_no,
                    display_group,
                    run_type
                }
            })
        },
        /** 
         * 오른쪽 영역에 보여줄 이전 상품목록. 
         * 수동: 가장 최근에 직접 알고리즘을 통해 도출해낸 상품목록.
         * 자동: 자동 진열간에 뽑힌 상품 목록중 가장 최근 상품목록.
         * */
        getLatestRpEventResultProducts: async (iframe_page_id, iframe_html_id, display_group) => {
            return http.get("/rest/shop/rp/event/result", {
                params: {
                    iframe_html_id,
                    iframe_page_id,
                    display_group
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        },
        /**
         * 오른쪽 영역 저장시 알고리즘으로 도출된 상품목록 저장
         */
        saveLatestProductsByAlgorithm: async (user_seq, shop_no, iframe_page_id, iframe_html_id, display_group, auto_yn, product_list) => {
            return http.post("/rest/shop/rp/event/result", {
                    user_seq,
                    shop_no,
                    iframe_page_id,
                    iframe_html_id,
                    display_group,
                    auto_yn,
                    product_list
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }   
                }
            )
        }
    },
    /** TB_CAFE24_SHOPS.sync_state long polling */
    subscribeShopDataSyncState: async (time) => {
        while (true) {
            await new Promise(resolve => setTimeout(resolve, 10000))
    
            /** 최초 10초 ~ 최대 한시간  */
            if ((new Date().getTime() - time) > 3600000) return "cafe24 연동 1시간 초과"
            /** 윈도우 비활성 상태일 경우 재호출 건너뜀. */
            if (document.hidden) continue
    
            const response = await http.get("/rest/shop/shopSyncStateAndSyncDate")
    
            if (!response.data) return response
            else if (response.data.sync_state === "0") return true
            else if (response.data.msg) return response.data.msg
        }
    }
}
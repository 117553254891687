import React from 'react';
import SaveDialogV2 from '../dialogs/SaveDialogV2';
import InputDialogV2 from '../dialogs/InputDialogV2';

/**
 * 이미지 주소 업로드
 * @param {*} title 팝업 문구
 * @function onClose 팝업 닫기 함수
 * @function getImageLink 이미지 정보를 받아서 처리할 함수
 * @param {*} value 기본 입력값
 */
class UploadLinkImagePopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkingImgState : false,
            value : this.props.value,
        }
    }

    /**
     * 이미지 링크가 사용 가능한 링크인지 확인 : onload, onerror 작업이 끝난 후 action 함수 실행
     * @param {*} link 이미지 링크
     * @function loadAction 이미지 링크가 로드되었을때 실행할 함수
     * @function errorAction 이미지 링크가 에러떴을때 실행할 함수
     */
    checkImgLinkUseAble = (link, loadAction, errorAction) => {
        var img = new Image();
        img.src = link;
        if(loadAction){
            img.onload = () => { // 링크 상태 유효함
                loadAction();
            };
        }
        if(errorAction){
            img.onerror = () => {
                errorAction();
            };
        }
    }

    render() {
        const {title, onClose} = this.props;
        return (<>
                {this.state.checkingImgState
                ? <SaveDialogV2
                    content={"이미지 업로드중 입니다."}
                    button_list={[
                        {
                            title: "확인",
                            type: "1",
                            display_yn:"N",
                        }
                    ]}
                />
                :<InputDialogV2
                    title={title}
                    input_value={this.state.value}
                    button_list={[
                        {
                            title: "적용",
                            type: "1",
                            event_method: (e) => {
                                this.setState({checkingImgState : true});

                                const link = e.target.value.replaceAll("&quot;","").replaceAll("&quot","");
                                this.checkImgLinkUseAble(link,
                                    () => {
                                        this.props.getImageLink(link);
                                        this.setState({checkingImgState : false})
                                    },
                                    () => {
                                        this.setState({checkingImgState : false})
                                        alert("허용하지 않는 이미지 주소입니다.");
                                    }
                                );
                            }
                        },
                        {
                            title: "취소",
                            type: "0",
                            event_method: () => {
                                onClose();
                            }
                        }
                    ]}
                    popup_close={() => {
                        onClose();
                    }}
                />}
        </>
        );
    }
}

UploadLinkImagePopup.defaultProps = {
    getImageLink : () => {},
    onClose : () => {},
    value : "",
}

export default UploadLinkImagePopup;
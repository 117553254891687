import React from 'react';
import $ from 'jquery';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import http from '../modules/api';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import trend_calendar from '../../resources/img/trend_calendar.png'
import icon_chevron from '../../resources/img/icon-chevron.png'
import 'raf/polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-78553461-8');


// defaults.global.defaultFontSize = '12'

var pieOptions = {
    pieceLabel: {
   fontSize: 12,
 }
};

function getWeekDays(weekStart) {
    const days = [weekStart];
    for (let i = 1; i < 7; i += 1) {
      days.push(
        moment(weekStart)
          .add(i, 'days')
          .toDate()
      );
    }
    return days;
  }
  
  function getWeekRange(date) {
    return {
      from: moment(date)
        .startOf('isoWeek')
        .toDate(),
      to: moment(date)
        .endOf('isoWeek')
        .toDate(),
    };
  }
  

var userName = sessionStorage.getItem("userName");

var is_first = true;
String.prototype.replaceAll = function(org, dest) {
    return this.split(org).join(dest);
}

var category = decodeURIComponent(String(getUrlParams()["category"]));
// alert(category)
if(getUrlParams()["category"] == null) {
    category = ""
}
var keyword = decodeURIComponent(String(getUrlParams()["keyword"]));
// alert(category)
if(getUrlParams()["keyword"] == null) {
    keyword = ""
}
// console.log(category)
var paramDate = new Date(new Date(getUrlParams()["date"]).toString().replace("09:", "00:"));

var date = new Date(paramDate);
var db_date;
var nowDate = "";
var nowDateForQuery = "";

var start_date = "";
var end_date = "";
if(getUrlParams()["start_date"] != null) {
    start_date = getUrlParams()["start_date"].replaceAll("-",".");
}
if(getUrlParams()["end_date"] != null) {
    end_date = getUrlParams()["end_date"].replaceAll("-",".");
}

// console.log(getUrlParams()["date"])
// if (getUrlParams()["date"] == null) {

// console.log(nowDate);
var graphType = "";

function getUrlParams() {
    var params = {};
    window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(str, key, value) { params[key] = value; });
    return params;
}

function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

/**
 *  yyyyMMdd 포맷으로 반환
 */
function getFormatDate(date){
    var year = date.getFullYear();              //yyyy
    var month = (1 + date.getMonth());          //M
    month = month >= 10 ? month : '0' + month;  //month 두자리로 저장
    var day = date.getDate();                   //d
    day = day >= 10 ? day : '0' + day;          //day 두자리로 저장
    return  year + '.' + month + '.' + day;
}
function getFormatDate2(date){
    var year = date.getFullYear();              //yyyy
    var month = (1 + date.getMonth());          //M
    month = month >= 10 ? month : '0' + month;  //month 두자리로 저장
    var day = date.getDate();                   //d
    day = day >= 10 ? day : '0' + day;          //day 두자리로 저장
    return  month + '.' + day;
}
function getFormatDate3(date){
    var year = date.getFullYear();              //yyyy
    var month = (1 + date.getMonth());          //M
    month = month >= 10 ? month : '0' + month;  //month 두자리로 저장
    var day = date.getDate();                   //d
    day = day >= 10 ? day : '0' + day;          //day 두자리로 저장
    return  year + '-' + month + '-' + day;
}
function getFormatDate4(date){
    var year = date.getFullYear();              //yyyy
    var month = (1 + date.getMonth());          //M
    month = month >= 10 ? month : '0' + month;  //month 두자리로 저장
    var day = date.getDate();                   //d
    day = day >= 10 ? day : '0' + day;          //day 두자리로 저장
    return  String(year).substring(2,4) + '.' + month + '.' + day; // yy.mm.dd
}
function getFormatDate5(date){
    var year = date.getFullYear();              //yyyy
    var month = (1 + date.getMonth());          //M
    // month = month >= 10 ? month : '0' + month;  //month 두자리로 저장
    var day = date.getDate();                   //d
    // day = day >= 10 ? day : '0' + day;          //day 두자리로 저장
    var week = new Array('일', '월', '화', '수', '목', '금', '토');
    var dayName = week[date.getDay()];          // 요일
    return  month + '월' + day + '일' + '('+dayName+')'; // mm월dd일(요일)
}

export class TrendCurrent_keyword_detail extends React.Component {
    constructor(props){
        super(props);

        if (typeof Object.assign != 'function') {
            // Must be writable: true, enumerable: false, configurable: true
            Object.defineProperty(Object, "assign", {
              value: function assign(target, varArgs) { // .length of function is 2
                'use strict';
                if (target == null) { // TypeError if undefined or null
                  throw new TypeError('Cannot convert undefined or null to object');
                }
          
                var to = Object(target);
          
                for (var index = 1; index < arguments.length; index++) {
                  var nextSource = arguments[index];
          
                  if (nextSource != null) { // Skip over if undefined or null
                    for (var nextKey in nextSource) {
                      // Avoid bugs when hasOwnProperty is shadowed
                      if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                        to[nextKey] = nextSource[nextKey];
                      }
                    }
                  }
                }
                return to;
              },
              writable: true,
              configurable: true
            });
          }
    }

    state = {
        before_date_limit : "2020-05-09",
        goodsList: [],
        feedList: [],
        categoryList : [],
        powerKeyword : {keywordList:[]},
        newKeyword : {keywordList:[]},
        mustKeyword : {keywordList:[]},
        hashtagList: [],
        settings : {
            // variableWidth:true,
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            autoplay : true,
            responsive: [ // 반응형 웹 구현 옵션
                {  
                    breakpoint: 960, //화면 사이즈 960px
                    settings: {
                        //위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
                        slidesToShow:4 
                    } 
                },
                { 
                    breakpoint: 639, //화면 사이즈 639px
                    settings: {	
                        //위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
                        slidesToShow:4 
                    } 
                }
            ]
        },

        settingsCategory : {
            // variableWidth:true,
            dots: false,
            infinite: false,
            // speed: 500,
            slidesToShow: 8,
            slidesToScroll: 3,
            autoplay : false,
            responsive: [ // 반응형 웹 구현 옵션
                {  
                    breakpoint: 960, //화면 사이즈 960px
                    settings: {
                        //위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
                        slidesToShow:7 
                    } 
                },
                { 
                    breakpoint: 639, //화면 사이즈 639px
                    settings: {	
                        //위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
                        slidesToShow:6,
                        slidesToScroll: 6
                    } 
                }
            ]
        },

        barData : {
            labels: [],
            datasets: [
                {},
            ]
        },
        options: {
            responsive: true, 
            maintainAspectRatio: false,
            // fontSize: 100,
        },
        
        FeedCntData : {
            labels:[""],
            datasets:[]
        },

        windowSize : window.innerWidth,

        nowDate : "",
        hoverRange : "", 
        selectedDays : [],

        chartOptions: {},
        //   series: [{
        //     name: '2018',
        //     // data: [10, 40, 25, 51, 42, 80, 15]
        //     data: []
        //   }, {
        //     name: '2019',
        //     // data: [15, 35, 45, 92, 34, 62, 14]
        //     data: []
        //   },{
        //     name: '2020',
        //     // data: [17, 55, 18, 51, 62, 30, 10]
        //     data: []
        //   }
        // ],
          series: [],
    };

    handleResize = e => {
        const windowSize = window.innerWidth;
        this.setState({
            windowSize: windowSize
        });

        console.log(this.state.windowSize);
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    componentDidMount(){
        window.addEventListener('resize', this.handleResize)
        // alert(start_date);
        // alert(end_date);
        if (start_date != "" && end_date != "") {
            $(".nowDate2").hide();
            $(".weeklyDate").show();
            $(".weeklyDate").text(start_date+" ~ "+end_date);
            $("#dailyCalendar").hide();
            $("#weeklyCalendar").show();
            /* 주간 radio 버튼 클릭 */
            $(".radio_Weekly_Daily:checked").prop("checked", false); 
            $("#weekly").prop("checked", true); 
            $("#dailyCalendar").hide();
            $("#weeklyCalendar").show();
            this.setState({
                selectedDays: [
                    new Date("20"+start_date.replaceAll(".","-")),
                    addDays(new Date("20"+start_date.replaceAll(".","-")),+1),
                    addDays(new Date("20"+start_date.replaceAll(".","-")),+2),
                    addDays(new Date("20"+start_date.replaceAll(".","-")),+3),
                    addDays(new Date("20"+start_date.replaceAll(".","-")),+4),
                    addDays(new Date("20"+start_date.replaceAll(".","-")),+5),
                    new Date("20"+end_date.replaceAll(".","-"))
                ],
                weeklyDateTmp : getWeekDays(getWeekRange(new Date("20"+start_date.replaceAll(".","-"))).from)
            });
        } else {
            $(".weeklyDate").hide();
            $(".nowDate2").show();
            $(".nowDate2").text(nowDate);
            $("#weeklyCalendar").hide();
            $("#dailyCalendar").show();
            /* 일간 radio 버튼 클릭 */
            $(".radio_Weekly_Daily:checked").prop("checked", false); 
            $("#daily").prop("checked", true); 
            $("#weeklyCalendar").hide();
            $("#dailyCalendar").show();
            if (nowDate != "") {
                this.setState({
                    selectedDays: [
                        new Date(nowDate.replaceAll(".","-")),
                    ],
                    dailyDateTmp : [
                        new Date(nowDate.replaceAll(".","-")),
                    ]
                })
            }
        }

        $("#trendCalendar").hide();
        $(window).resize( function () { });
 
        /* 뒤로가기 이벤트 */
        onpopstate = () => {
            category = decodeURIComponent(getUrlParams()["category"]);
            if(getUrlParams()["category"] == null) {
                category = ""
            }
            // $("#mainKeyword").text(decodeURIComponent(category));
            if (getUrlParams()["start_date"] != null) {
                start_date = getUrlParams()["start_date"].replaceAll("-",".");
            }
            if (getUrlParams()["end_date"] != null) {
                end_date = getUrlParams()["end_date"].replaceAll("-",".");
            }
            if (start_date != "" && end_date != "") {
                $(".nowDate2").hide();
                $(".weeklyDate").show();
                $(".weeklyDate").text(start_date+" ~ "+end_date);
            } else {
                $(".weeklyDate").hide();
                $(".nowDate2").show();
                $(".nowDate2").text(nowDate);
            }
            this.loadFullData();
        }

        this.getCategoryList();
        this.loadFullData();
      }

    loadFullData() {
        paramDate = new Date(new Date(getUrlParams()["date"]).toString().replace("09:", "00:"));
        date = new Date(paramDate);
        if (getUrlParams()["date"] != null) {
            nowDateForQuery = getFormatDate3(date);
        } else {
            if (getUrlParams()["end_date"] != null) {
                nowDateForQuery = getFormatDate3(new Date(new Date("20"+getUrlParams()["end_date"]).toString().replace("09:", "00:")));
                // alert(getFormatDate3(new Date(new Date("20"+getUrlParams()["end_date"]).toString().replace("09:", "00:"))))
            } else {
                nowDateForQuery = ""; 
            }
        }
        // window.location.href=(`/shop/trend/current?date=`+beforeDate2.replaceAll(".", "-")+"&category="+ encodeURI(category));
        http.get("/rest/common/LastAnlysDate?category="+encodeURI(category)+"&date="+nowDateForQuery)
        .then(res => {
            db_date = new Date(res.data["last_anlys_date"]);
            this.setState( {before_date_limit : res.data["before_date_limit"]});
            // console.log(this.state.before_date_limit);
            if (getUrlParams()["date"] == null) {
                date = new Date(new Date(res.data["last_anlys_date"]).toString().replace("09:", "00:"));

                nowDate = getFormatDate(date);
                if (getUrlParams()["end_date"] != null) {
                    nowDateForQuery = getFormatDate3(new Date(new Date("20"+getUrlParams()["end_date"]).toString().replace("09:", "00:")));
                    // alert(getFormatDate3(new Date(new Date("20"+getUrlParams()["end_date"]).toString().replace("09:", "00:"))))
                } else {
                    nowDateForQuery = getFormatDate3(date);
                }
                $(".nowDate2").text(nowDate);
                if (start_date == "" && end_date == "") {
                    this.setState({
                        selectedDays: [
                            new Date(nowDate.replaceAll(".","-")),
                        ],
                        dailyDateTmp : [
                            new Date(nowDate.replaceAll(".","-")),
                        ]
                    })
                }
                // this.setState({nowDate: nowDate});
            } else {
                nowDate = getFormatDate(date);
                nowDateForQuery = getFormatDate3(date);

                $(".nowDate2").text(nowDate);
                if (start_date == "" && end_date == "") {
                    this.setState({
                        selectedDays: [
                            new Date(nowDate.replaceAll(".","-")),
                        ],
                        dailyDateTmp : [
                            new Date(nowDate.replaceAll(".","-")),
                        ]
                    })
                }
                // this.setState({nowDate: nowDate});
            }

            $(".nowDate2").text(res.data["now_date_format"]);
            this.setState({nowDate: res.data["now_date_format"]});

            this.getFeedList(keyword, nowDateForQuery);
            this.getGraphData($(".radio_Weekly_Daily:checked").val(), keyword, nowDateForQuery);
        })
        .catch(error => {
                // console.log (error);
        });
    }

    handleDayChange = date => {
        console.log(new Date(new Date().setDate(new Date().getDate() - new Date().getDay())));
        if (new Date(new Date().setDate(new Date().getDate() - new Date().getDay())) < new Date(date.toString().replaceAll("12:", "00:")) || new Date(date.toString().replaceAll("12:", "00:")) < new Date("2018-01-01")) {
            alert("해당 날짜를 선택 할 수 없습니다.");
            this.setState({
                selectedDays: [],
            });
            ReactGA.event({
                category: '트렌드리포트 2차버전 '+decodeURIComponent(keyword)+' 페이지',
                action:  "달력 주간 비활성화 된 날짜 선택 ("+getFormatDate(date)+")",
                label: sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")"
            });
            return false;
        }

        this.setState({
          selectedDays: getWeekDays(getWeekRange(date).from),
          weeklyDateTmp : getWeekDays(getWeekRange(date).from)
        });

        ReactGA.event({
            category: '트렌드리포트 2차버전 '+decodeURIComponent(keyword)+' 페이지',
            action:  "주간 날짜 선택 ("+getFormatDate(date)+")",
            label: sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")"
        });
      };

      handleDayChangeForDaily = date => {
        console.log(new Date(date.toString().replaceAll("12:", "00:")));
        console.log(new Date());
        if (new Date() < new Date(date.toString().replaceAll("12:", "00:")) || new Date(date.toString().replaceAll("12:", "00:")) < new Date("2018-01-01")) {
            alert("해당 날짜를 선택 할 수 없습니다.");
            this.setState({
                selectedDays: [],
            });
            ReactGA.event({
                category: '트렌드리포트 2차버전 '+decodeURIComponent(keyword)+' 페이지',
                action:  "달력 일간 비활성화 된 날짜 선택 ("+getFormatDate(date)+")",
                label: sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")"
            });
            return false;
        }

        this.setState({
          selectedDays: [date],
          dailyDateTmp : [date]
        });

        ReactGA.event({
            category: '트렌드리포트 2차버전 '+decodeURIComponent(keyword)+' 페이지',
            action:  "일간 날짜 선택 ("+getFormatDate(date)+")",
            label: sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")"
        });
      };
    
      handleDayEnter = date => {
        this.setState({
          hoverRange: getWeekRange(date),
        });
      };
    
      handleDayLeave = () => {
        this.setState({
          hoverRange: undefined,
        });
      };
    
      handleWeekClick = (weekNumber, days, e) => {
        this.setState({
          selectedDays: days,
        });
      };

    onClickCategory = e => {
        category = e.currentTarget.textContent;
        if (window.location.href.indexOf("/demo/") > 0) {
            if (getUrlParams()["start_date"] != null && getUrlParams()["start_date"] != "" && getUrlParams()["end_date"] != null && getUrlParams()["end_date"] != "") {
                // alert("/demo/trend/current/rev1?category="+category+"&start_date="+start_date.replaceAll(".", "-")+"&end_date="+end_date.replaceAll(".", "-"))
                window.location.href="/demo/trend/v2/current?category="+category+"&start_date="+start_date.replaceAll(".", "-")+"&end_date="+end_date.replaceAll(".", "-");
            } else {
                // alert("/demo/trend/current/rev1?date="+nowDate.replaceAll(".", "-")+"&category="+category)
                window.location.href="/demo/trend/v2/current?date="+nowDate.replaceAll(".", "-")+"&category="+category;
            }
            return false;
        } else {
            if (getUrlParams()["start_date"] != null && getUrlParams()["start_date"] != "" && getUrlParams()["end_date"] != null && getUrlParams()["end_date"] != "") {
                // alert("/shop/trend/current/rev1?category="+category+"&start_date="+start_date.replaceAll(".", "-")+"&end_date="+end_date.replaceAll(".", "-"))
                window.location.href="/shop/trend/v2/current?category="+category+"&start_date="+start_date.replaceAll(".", "-")+"&end_date="+end_date.replaceAll(".", "-");
            } else {
                // alert("/shop/trend/current/rev1?date="+nowDate.replaceAll(".", "-")+"&category="+category)
                window.location.href="/shop/trend/v2/current?date="+nowDate.replaceAll(".", "-")+"&category="+category;
            }
        }

        ReactGA.event({
            category: '트렌드리포트 2차버전 '+decodeURIComponent(keyword)+' 페이지',
            action: "카테고리 클릭 (" + category + ")",
            label: sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")"
        });
    }

    getFeedList(keyword, nowDateForQuery) {
		http.get("/rest/common/instagram/feed/image?category="+ encodeURI(keyword)+"&date="+nowDateForQuery)
        .then(res => {
            // if (res.data.session_error != null && res.data.session_error == "anonymousUser") {
            //     // // alert("가입 후 이용 해주세요.")
            //     return false;
            // }

            if (res.data.category != null && res.data.category != ""){
                category = res.data.category;
                // $("#mainKeyword").text(decodeURIComponent(category));
                window.history.pushState(null, null, window.location.href.split("?")[0]+`?date=`+nowDate.replaceAll(".", "-")+"&category="+ encodeURI(category)+"&keyword="+encodeURI(keyword));
                if(is_first) {
                    this.loadFullData();
                }
            }
            if (res.data.session_error != null && res.data.session_error == "anonymousUser") {
                if(window.location.href.indexOf("/shop") > 0){
                    alert("로그인 후 사용해 주세요.");
                    sessionStorage.removeItem("userName");
                    sessionStorage.removeItem("roleType");
                    sessionStorage.removeItem("redirectUrl");
                    window.location.href = "/logout";
                    return false;
                }

                alert("데모 체험판 입니다.\n가입 후 이용해 주세요.");
                is_first = false;
                return false;
            }
            is_first = false;

            this.setState( {feedList : res.data.feedList} );
            // console.log(this.state.goodsList)
            new Promise( ( resolve, reject ) => {setTimeout( resolve, 400 ); })
            .then( () => {
                $(".products-slick-box .slick-slider").show();
            })
            .catch(error => {
                console.log (error);
                });
            })
		.catch(error => {
			// console.log (error);
		 });
    }

    // getGraphData(paramCategory, nowDateForQuery, limit) {
	// 	http.get("/rest/common/state/hashtag?type="+graphType+"&category="+ encodeURI(paramCategory)+"&date="+nowDateForQuery+"&limit="+limit)
    //     .then(res => {
    //         if (res.data.session_error != null && res.data.session_error == "anonymousUser") {
    //             // alert("가입 후 이용 해주세요.")

    //             return false;
    //         }

    //     this.setState( {barData : res.data} );
    //     // console.log(this.state.barData)
	// 	})
	// 	.catch(error => {
	// 		// console.log (error);
	// 	 });
    // }

    getGraphData(graphType, keyword, date) {
		http.get("/rest/common/power/keyword/graph/percent?graph_type="+graphType+"&keyword="+ encodeURI(keyword)+"&date="+date)
        .then(res => {
            console.log(res.data);
            if (res.data.session_error != null && res.data.session_error == "anonymousUser") {
                // alert("가입 후 이용 해주세요.")

                return false;
            }
            var yearLimit = parseInt(new Date().getFullYear());
            // this.state.series = [];
            // this.state.chartOptions.xaxis.categories = [];
            var series = [];
            var categories = [];
            for (var year = 2018; year <= yearLimit;  year++){
                categories = [];
                if(res.data["graph_type"] == "w") {
                    var list = res.data[year.toString()];
                    // console.log(year.toString());
                    // console.log(list);
                    for (var idx in list) {
                        var ctg = list[idx].week.toString();
                        if (ctg == "53") {
                            ctg = "1"
                        }
                        categories.push(ctg+"주");
                    }
                    var data = [];
                    for (var idx in list) {
                        data.push(list[idx].percent);
                    }
                    // if (year == 2018) {
                    //     this.state.chartOptions.xaxis.categories=categories;
                    //     // console.log(this.state.chartOptions.xaxis.categories)
                    // } 
                    var name = year.toString()
                    series.push({
                        name: name,
                        data: data
                    });
                    // console.log(series)
                } else {
                    var list = res.data[year.toString()];
                    for (var idx in list) {
                        categories.push(list[idx].DATE_FORMAT.toString());
                    }
                    var data = [];
                    for (var idx in list) {
                        data.push(list[idx].percent);
                    }
                    // if (year == 2018) {
                    //     this.state.chartOptions.xaxis.categories=categories;
                    // } 
                    var name = year.toString()
                    series.push({
                        name: name,
                        data: data
                    });
                }
            }
            this.setState({
                series: series,
                chartOptions: {
                    dataLabels: {
                      enabled: false
                    },
                    toolbar: {
                      show: false,
                    },
                    stroke: {
                      show: true,
                      curve: 'smooth',
                      lineCap: 'butt',
                      colors: undefined,
                      width: 0,
                      dashArray: 0,      
                    },
                    xaxis: {
                        categories: categories,
                    },
                    // tooltip: {
                    //   x: {
                    //     // format: 'MM/dd'
                    //   },
                    // },
                    colors: ["#F3B415", "#00A7C6", '#A9D794', "#F27036", "#663F59", "#6A6E94", "#4E88B4", "#18D8D8",
                    '#46AF78', '#A93F55', '#8C5E58', '#2176FF', '#33A1FD', '#7A918D', '#BAFF29'],
                  },
            })
		})
		.catch(error => {
			// console.log (error);
		 });
    }

    getCategoryList() {
		http.get("/rest/common/dict/category")
        .then(res => {
            if (res.data.session_error != null && res.data.session_error == "anonymousUser") {
                // alert("가입 후 이용 해주세요.")
                return false;
            }

        this.setState( {categoryList : res.data["categoryList"]} );
        // console.log(this.state.categoryList)
		})
		.catch(error => {
			// console.log (error);
		 });
    }

    onClickNowDate = e => {
        if ($("#trendCalendar").is(':visible') == false) {
            $("#trendCalendar").show();
        } else {
            $("#trendCalendar").hide();
        }
    }

    onClickCalendarCancel = e => {
        $("#trendCalendar").hide();
        ReactGA.event({
            category: '트렌드리포트 2차버전 '+decodeURIComponent(keyword)+' 페이지',
            action:  "달력 취소 버튼 클릭",
            label: sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")"
        });
    }

    onClickCalendarApply = e => {
        if (this.state.selectedDays == null || (this.state.selectedDays != null && this.state.selectedDays.length == 0)) {
            alert("날짜를 선택해 주세요.");
            return false;
        }

        $("#trendCalendar").hide();
        if (this.state.selectedDays != null && this.state.selectedDays.length > 1) {
            nowDate = getFormatDate(this.state.selectedDays[this.state.selectedDays.length - 1]);
            nowDateForQuery = getFormatDate3(this.state.selectedDays[this.state.selectedDays.length - 1]);

            start_date = getFormatDate4(this.state.selectedDays[0])
            end_date = getFormatDate4(this.state.selectedDays[this.state.selectedDays.length - 1])

            $(".nowDate2").hide();
            $(".weeklyDate").show();
            $(".weeklyDate").text(start_date+" ~ "+end_date);
            window.history.pushState(null, null, window.location.href.split("?")[0]+"?category="+ encodeURI(category)+"&start_date="+start_date.replaceAll(".", "-")+"&end_date="+end_date.replaceAll(".", "-")+"&keyword="+encodeURI(keyword));
            ReactGA.event({
                category: '트렌드리포트 2차버전 '+decodeURIComponent(keyword)+' 페이지',
                action:  "달력 날짜 적용 ("+start_date+" ~ "+end_date+")",
                label: sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")"
            });
        } else {
            nowDate = getFormatDate(this.state.selectedDays[0]);
            nowDateForQuery = getFormatDate3(this.state.selectedDays[0]);
            $(".weeklyDate").hide();
            $(".nowDate2").show();
            $(".nowDate2").text(getFormatDate5(new Date(nowDate.replaceAll(".", "-"))));
            window.history.pushState(null, null, window.location.href.split("?")[0]+`?date=`+nowDate.replaceAll(".", "-")+"&category="+ encodeURI(category)+"&keyword="+encodeURI(keyword));
            ReactGA.event({
                category: '트렌드리포트 2차버전 '+decodeURIComponent(keyword)+' 페이지',
                action:  "달력 날짜 적용 ("+nowDate+")",
                label: sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")"
            });
        }

        this.loadFullData();
    }
    
    onClickWeekly = e => {
        if (this.state.weeklyDateTmp != null) {
            this.setState({selectedDays: this.state.weeklyDateTmp});
        } else {
            if (this.state.selectedDays != null) {
                if (new Date(new Date().setDate(new Date().getDate() - new Date().getDay())) < new Date(this.state.selectedDays[0].toString().replaceAll("12:", "00:")) || new Date(this.state.selectedDays[0].toString().replaceAll("12:", "00:")) < new Date("2018-01-01")) {
                    this.setState({
                        selectedDays: getWeekDays(getWeekRange(new Date(new Date().setDate(new Date().getDate() - new Date().getDay()))).from),
                    });
                } else {
                    this.setState({selectedDays: getWeekDays(getWeekRange(this.state.selectedDays[0]).from)});
                }
            } 
        }

        $(".radio_Weekly_Daily:checked").prop("checked", false); 
        $("#"+e.target.id).prop("checked", true); 
        $("#dailyCalendar").hide();
        $("#weeklyCalendar").show();
        // $(".DayPicker-TodayButton").click();

        ReactGA.event({
            category: '트렌드리포트 2차버전 '+decodeURIComponent(keyword)+' 페이지',
            action:  "달력 주간 radioButton 클릭",
            label: sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")"
        });
    }
    
    onClickDaily = e => {
        if (this.state.dailyDateTmp != null) {
            this.setState({selectedDays: this.state.dailyDateTmp});
        } else {
            if (this.state.selectedDays != null) {
                this.setState({selectedDays: [this.state.selectedDays[6]]});
            }
        }

        $(".radio_Weekly_Daily:checked").prop("checked", false); 
        $("#"+e.target.id).prop("checked", true); 
        $("#weeklyCalendar").hide();
        $("#dailyCalendar").show();
        // $(".DayPicker-TodayButton").click();

        ReactGA.event({
            category: '트렌드리포트 2차버전 '+decodeURIComponent(keyword)+' 페이지',
            action:  "달력 일간 radioButton 클릭",
            label: sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")"
        });
    }

    onclickFeedImg = (e, shortcode) => {
        window.open("https://www.instagram.com/p/"+shortcode);
        ReactGA.event({
            category: '트렌드리포트 2차버전 '+decodeURIComponent(keyword)+' 페이지',
            action:  "인스타 피드 이미지 클릭 ("+ shortcode +")",
            label: sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")"
        });
    }

    onClickBackButton = e => {
        window.history.go(-1);
        ReactGA.event({
            category: '트렌드리포트 2차버전 '+decodeURIComponent(keyword)+' 페이지',
            action:  "뒤로가기 버튼 클릭",
            label: sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")"
        });
    }

    render() {
        const { hoverRange, selectedDays, chartOptions} = this.state;

        const daysAreSelected = selectedDays.length > 0;
    
        const modifiers = {
          hoverRange,
          selectedRange: daysAreSelected && {
            from: selectedDays[0],
            to: selectedDays[6],
          },
          hoverRangeStart: hoverRange && hoverRange.from,
          hoverRangeEnd: hoverRange && hoverRange.to,
          selectedRangeStart: daysAreSelected && selectedDays[0],
          selectedRangeEnd: daysAreSelected && selectedDays[6],
        };

        var ua = navigator.userAgent;
        var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

        return (

            <>
            <div className="trendCategoryArea2" style={{position: "relative", height: "30px"}}>
                {
                    this.state.windowSize > 800
                    ?   <div className="mobile-top-60" style={{display:"table",right:"0", position:"absolute", marginRight:"2.5%", fontSize:"15px", marginTop:"-28px"}}>
                                {this.state.categoryList.map((ctg, idx)=> (
                                    <div className="slick-category margin-l-10" key={idx} onClick={this.onClickCategory} style={{display:"inline-block"}}>
                                    {
                                        category == ctg.category4
                                        ? <div style={{fontWeight:"bolder"}}>{ctg.category4}</div>
                                            
                                        : <div style={{color:"grey"}}>{ctg.category4}</div>
                                    }  
                                    </div>  	
                                ))}
                        </div>
                    :   <div className="trendCategoryArea" style={{paddingTop:"60px"}}>
                            <Slider {...this.state.settingsCategory}
                                    arrows={false}>
                                {this.state.categoryList.map((ctg, idx)=> (
                                    <div className="slick-category" key={idx} onClick={this.onClickCategory}>
                                        {
                                            category == ctg.category4
                                            ? <div style={{fontWeight:"bolder"}}>{ctg.category4}</div>
                                                
                                            : <div style={{color:"grey"}}>{ctg.category4}</div>
                                        }  
                                    </div>  
                                ))}
                            </Slider>
                        </div>
                }
                
            </div>
            <div className="trendCategoryArea2_line" style={{width:"100%", borderTop:"1px solid #d9d9da", margin: "-12px 0 25px 0"}}></div>

                <div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
                    <div style={{padding:"0 2% 0 2%"}}>
                        {/* <div className="flexBox" style={{position:"relative", marginBottom:"25px", justifyContent: "space-between"}}>
                            <div style={{paddingTop:"0px", fontSize:"30px", fontWeight:"bolder"}}>{decodeURIComponent(keyword)}</div>
                            <div className="nowDate margin-vertical-auto cursor-pointer" onClick={this.onClickNowDate}
                                style={{fontSize:"15px", right:"0", position:"absolute", top: "50%", transform:"translate(0, -50%)", textDecoration:"underline"}}>
                            </div>
                            <div className="weeklyDate margin-vertical-auto cursor-pointer" onClick={this.onClickNowDate}
                                style={{fontSize:"15px", right:"0", position:"absolute", top: "50%", transform:"translate(0, -50%)", textDecoration:"underline"}}>
                            </div>
                        </div> */}
                        <div className="flexBox" style={{position:"relative", marginBottom:"25px", justifyContent: "space-between"}}>
                            <div className="flexBox">
                                <img className="cursor-pointer" src={icon_chevron} style={{width:"10.27px", height:"20.75px", margin:"auto 11.27px auto 0"}} onClick={this.onClickBackButton}></img>
                                <div style={{paddingTop:"0px", fontSize:"27px", fontWeight:"bolder"}}>{decodeURIComponent(keyword)}</div>
                            </div>
                            <div style={{display:"flex"}}>
                                {/* <div className="fa fa-calendar" style={{width: "15px",height: "15px", fontSize:"15px", margin: "auto 0 auto 0", float: "left"}}></div> */}
                                <img src={trend_calendar} style={{width: "18px",height: "17px", margin: "auto 0 auto 0", float: "left"}}></img>
                                <div className="nowDate2 margin-vertical-auto cursor-pointer" onClick={this.onClickNowDate}
                                    style={{margin: "auto 0 auto 10px",  fontSize:"15px", color:"#000000", lineHeight:"16px" , borderBottom: "1px solid #000"}}>
                                </div>
                                <div className="weeklyDate margin-vertical-auto cursor-pointer" onClick={this.onClickNowDate}
                                    style={{margin: "auto 0 auto 10px", fontSize:"15px", color:"#000000", lineHeight:"16px" , borderBottom: "1px solid #000"}}>
                                </div>
                            </div>
                        </div>
                        <div className="flexBox trendWeeklyCalendarArea" style={{position:"relative", marginBottom:"25px", justifyContent: "space-between"}}>
                            <div id="trendCalendar" style={{border:"1px solid #70707033", background:"white", position:"absolute", top:"-18px", right:"0px", zIndex:"1000", boxShadow: "1px 5px 10px 0px lightgrey"}}>
                                <div style={{textAlign:"center", marginTop:"10px"}}>
                                    <input type="radio" className="radio_Weekly_Daily" name="daily" id="daily" value="d" onClick={this.onClickDaily}/> 일간
                                    &nbsp;
                                    <input type="radio" className="radio_Weekly_Daily" name="weekly" id="weekly" value="w" onClick={this.onClickWeekly}/> 주간
                                </div>
                                <div id="weeklyCalendar">
                                    <DayPicker
                                        month={selectedDays[selectedDays.length-1]} 
                                        selectedDays={selectedDays}
                                        showWeekNumbers
                                        showOutsideDays
                                        modifiers={modifiers}
                                        onDayClick={this.handleDayChange}
                                        onDayMouseEnter={this.handleDayEnter}
                                        onDayMouseLeave={this.handleDayLeave}
                                        onWeekClick={this.handleWeekClick}
                                        disabledDays={[
                                            {
                                                after:  new Date(new Date().setDate(new Date().getDate() - new Date().getDay())),
                                                before: new Date("2018-01-01")
                                            }
                                        ]}
                                        todayButton="오늘로 이동"
                                    />
                                </div>
                                <div id="dailyCalendar">
                                    <DayPicker
                                        month={selectedDays[selectedDays.length-1]} 
                                        selectedDays={selectedDays}
                                        // showWeekNumbers
                                        showOutsideDays
                                        modifiers={modifiers}
                                        onDayClick={this.handleDayChangeForDaily}
                                        onDayMouseEnter={this.handleDayEnter}
                                        onDayMouseLeave={this.handleDayLeave}
                                        onWeekClick={this.handleWeekClick}
                                        disabledDays={[
                                            {
                                                after: new Date(),
                                                before: new Date("2018-01-01")
                                            }
                                        ]}
                                        todayButton="오늘로 이동"
                                    />
                                </div>
                                <div className="flexBox cursor-pointer" style={{justifyContent: "center", marginBottom:"10px"}}>
                                    <div className="greenLineButton" style={{width: "40px", height:"30px", lineHeight:"30px", textAlign:"center"}}
                                    onClick={this.onClickCalendarCancel}>
                                        취소
                                    </div>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div className="greenButton cursor-pointer" style={{width: "40px", height:"30px", lineHeight:"30px", textAlign:"center"}}
                                    onClick={this.onClickCalendarApply}>
                                        적용
                                    </div>
                                </div>
                            </div>    
                        </div>
                        <div className="body over-hidden trendContainer flexBox">
                        <div className="width-100">
                            <div style={{position: "relative"}}>
                                <div style={{position:"absolute", top:"0px", fontSize:"15px"}}>
                                    <b>인기예측</b> 그래프
                                </div>
                                {
                                    this.state.series != null && this.state.series.length > 0 && chartOptions != {}
                                    ? is_ie != true
                                        ?<ReactApexChart options={chartOptions} series={this.state.series} type="area" height="330" />
                                        :<div style={{width:"100%", height:"330px", color:"gray", paddingTop: "30px"}}>
                                            Internet Explorer는 ApexChart를 지원 하지 않습니다.<br/>Chrome 브라우저를 이용해 주세요.
                                        </div>
                                    :<div style={{width:"100%", height:"330px"}}></div>    
                                }
                            </div>
                            <br/>
                            <br/>
                            <div style={{marginBottom:"55px"}}>
                                <label style={{fontSize:"20px"}}>
                                    <b>{decodeURIComponent(keyword)}</b> 이미지
                                </label>
                                <br/>
                                <div className="keywordFeedImgArea" style={{zIndex:"999"}}>
                                    <div className="keywordFeedImgInnerArea flexBox" style={{marginTop:"44px", width:"100%"}}>
                                    {this.state.feedList.map((feed, idx)=> (
                                        // <div key={idx} className="keywordFeedImg cursor-pointer" style={{backgroundImage:`url("`+`https://www.instagram.com/p/`+ feed.shortcode +`/media/?size=m`+`")`}} onClick={(e) => this.onclickFeedImg(e, feed.shortcode)}></div>
                                        <div key={idx} className="keywordFeedImg cursor-pointer" style={{backgroundImage:`url("`+feed.display_url+`")`}} onClick={(e) => this.onclickFeedImg(e, feed.shortcode)}></div>
                                    ))}
                                    </div>
                                </div>
                                <div className="keywordFeedImgArea" style={{zIndex:"1"}}>
                                    <div className="keywordFeedImgInnerArea flexBox" style={{marginTop:"44px", width:"100%"}}>
                                    {this.state.feedList.map((feed, idx)=> (
                                        <div key={idx} className="keywordFeedImg cursor-pointer" style={{backgroundImage:`url("`+`https://img.otdeal.net/trend/noimg.gif`+`")`}} onClick={(e) => this.onclickFeedImg(e, feed.shortcode)}></div>
                                    ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}
import React from 'react';
import axios from 'axios';
import http from '../modules/api';
import {Route, Switch, NavLink, Link} from "react-router-dom";
import $ from 'jquery';

import "react-datepicker/dist/react-datepicker.css";

import ReactGA from 'react-ga';
ReactGA.initialize('UA-78553461-8');

var ga_label = sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")";

var calc_payment = false;
var pf = null;
var isPayLinkClick = false;

export class OrderAmountExpect extends React.Component {
    state = {
        request_type : '05002',     /*서비스 요청 상태 (05002 : 정기결제)*/
        order_type : '',
        payment_amount : 0,
        requestMembershipPaymentList : [],
        data_expectList : [],
        payLinkPreData: {}
    };

    componentDidMount(e) {
        // 현재 사용자의 잔여, 추가요금, 미납액 조회
        //this.fn_selectMembershipUserAmount();
        // 현재 년월 기준으로 멤버십 현황 조회
        pf = document.payForm;
        // console.log(pf);
        this.fn_selectOrderType();
        this.fn_requestMembership();
        //this.fn_selectMembershipExpectList();
    }

    componentDidUpdate() {
        // PG창 호출 이벤트
        if(isPayLinkClick) {
            isPayLinkClick = false;

            console.log(pf);
            pf = document.payForm;
        
            //var fileName = "https://apitest.payjoa.co.kr/pay/link"; //개발서버
            var fileName = "https://api.payjoa.co.kr/pay/link"; //운영서버
            var PAYJOA = window.open("", "PAYJOA", "width=468,height=750");

            pf.target = "PAYJOA";
            pf.action = fileName;
            pf.method = "post";
            pf.submit();
        }
    }

    componentWillUnmount () {

    }

    onClickOrderBank = (e) => {
        if (this.state.order_type != "02002") {
            alert("결제 수단을 실시간계좌이체로 변경하셔야 실시간계좌이체가 가능합니다.");
            return false;
        }
        this.fn_saveRequestMembershipOrderAmountExpect();
    }

    /**
     * 현재 설정된 결제 수단을 조회
     */
    fn_selectOrderType() {
        const config = {
            headers: {
                'content-type': 'application/json;'
            }
        }
		http.post("/rest/shop/get/orderType", null, config)
            .then(
			    resultData => {
                    const { data } = resultData;
                    console.log(data);
                    this.setState({
                        order_type: data.order_type
                    });
                }
            ).catch(
			    error => { alert("서비스 점검중 입니다."); console.log(error); }
			);
	}

    /**
     * step2 : 현재 날짜 기준으로 요금 계산 조회
     */
    fn_requestMembership() {
		let sendForm = {
            request_type: this.state.request_type,
            // req_payment_type_list: this.state.req_payment_type_list,
            // req_coupon_list: this.state.req_coupon_list,
            //coupon_no: this.state.coupon_no,
        };
        const config = {
            headers: {
                'content-type': 'application/json;'
            }
        }
		http.post("/rest/shop/requestMembership", sendForm, config)
            .then(
			    resultData => {
                    const { data } = resultData;
                    //console.log(data);
                    if (data.code == 200) {
                        this.setState({
                            requestMembershipPaymentList: data.requestMembershipPaymentList,
                            std_date: data.std_date,
                            payment_amount: data.payment_amount,
                        });
                    } else {
                        alert(data.msg);
                        this.setState({
                            step: '1'
                        });
                        // this.props.history.push(
                        //     {
                        //         pathname: `/shop/orderNew`,
                        //         state: {prevPath: window.location.href}
                        //     }	
                        // )
                    }
                    //console.log(JSON.stringify(this.state))
                }
            ).catch(
			    error => { alert("서비스 점검중 입니다."); console.log(error); }
			);
	}

    // 결제를 위한 금액 데이터 세팅
    fn_saveRequestMembershipOrderAmountExpect() {
        let order_type = this.state.order_type;
        /* 실시간 계좌이체 여부 */
        if (order_type == '02002') {
            let sendForm = {
                //order_type : this.state.order_type,          /*결제타입 (정기결제 / 실시간결제)  [02001	정기결제, 02002	실시간계좌이체]*/
                //std_date: '2021-02-28',
                //std_ym: '2021-02',
                // request_type: this.state.request_type,
                // payment_type: this.state.payment_type,
                // req_banner_list: this.state.req_bannerList,
            };
            const config = {
                headers: {
                    'content-type': 'application/json;'
                }
            }
            // 결제금액, 잔여금액, 추가금액을 계산하여 저장
            http.post("/rest/shop/saveRequestMembershipOrderAmountExpect", sendForm, config)
                .then(
                    resultData => {
                        const { data } = resultData;
                        // console.log(data);
                        if (data.code == 200) {
                            //let payment_amount_seq = data.payment_amount_seq;
                            // 최종 요청 금액으로 결제모듈 호출
                            //if (order_type == '02002') {
                                // 실시간 계좌이체
                                if (data.result) {
                                    // PG창 호출 이벤트
                                    isPayLinkClick = true;
                                    this.setState({payLinkPreData: data});
                                } else {
                                    alert("결제에 실패하였습니다.\n관리자에게 문의해주세요(1599-3518)");
                                }
                                // 실시간 계좌이체
                                //this.fn_payLink(payment_amount_seq);
                            // }
                        } else {
                            alert(data.msg);
                        }
                    }
                ).catch(
                    error => { alert("서비스 점검중 입니다."); console.log(error); }
                );
        } else {
            alert("설정된 결제수단이 실시간 계좌이체가 아닙니다.");
        }
    }

    // [실시간 계좌이체] 최종 요청 금액으로 결제모듈 호출
    // fn_payLink(payment_amount_seq) {
    //     let sendForm = {
    //         payment_amount_seq: payment_amount_seq
    //     };
    //     const config = {
    //         headers: {
    //             'content-type': 'application/json;'
    //         }
    //     }
    //     // 결제금액, 잔여금액, 추가금액을 계산하여 저장
    //     http.post("/rest/shop/get/payLinkPreData", sendForm, config)
    //     .then(
    //         resultData => {
    //             const { data } = resultData;
    //             console.log(data);
    //             if (data.result) {
    //                 // PG창 호출 이벤트
    //                 isPayLinkClick = true;
    //                 this.setState({payLinkPreData: resultData.data});
    //             } else {
    //                 alert("결제에 실패하였습니다.\n관리자에게 문의해주세요(1599-3518)");
    //             }
    //         }
    //     ).catch(
    //         error => { alert("서비스 점검중 입니다."); console.log(error); }
    //     );
    // }

    /**
     * 현재 사용자의 잔여, 추가요금, 미납액 조회
     */
	// async fn_selectMembershipUserAmount() { 
	// 	const res = await http.get("/rest/common/selectMembershipUserAmount");
	// 	// 조회 성공시 다음 단계
    //     if (res.data != null) {
    //         console.log(res.data)
    //         this.setState({
    //             balance_amount: res.data.balance_amount,
    //             extra_amount: res.data.extra_amount,
    //             unpaid_amount: res.data.unpaid_amount,
    //         })
    //     } else if (res.data.code != 200) {
    //         let msg = res.data.msg;
    //         alert(msg);
    //     } else {
	// 		alert("서비스 점검중 입니다.");
	// 		//리다이렉트 경로
    //     }
    // }

	render() {
        const { requestMembershipPaymentList } = this.state;
        $(".pageName").text("멤버십 관리");

        const input_bg_color = this.state.is_use == "Y" ? "#f7f7f7" : "white";

        return (
            <div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
                <div className="form_primary_title pc_page_title" style={{fontSize:"27px", marginBottom:"0px"}}>멤버십 관리</div>
                <div>
                    { 
                        /*  step: 2 */
                        (requestMembershipPaymentList != null && requestMembershipPaymentList.length > 0) ?
                            <>
                                <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"space-between"}}>
                                    {
                                        this.state.order_type == "02002"
                                        ?   <button className="btn-auto-b" style={{height:"50px", display:"flex", padding:"15px 20px", borderRadius:"8px", marginLeft: "auto"}}
                                                onClick={e => this.onClickOrderBank(e)}>
                                                    <div className="icn_credit_card_w" style={{marginRight:"6.87px", lineHeight:"50px", alignSelf:"center"}}></div>실시간 계좌이체 결제
                                            </button>
                                        : ''
                                    }
                                </div>
                                <div className="flexBox credit_card_box_area" style={{width:"100%", marginTop:"39px", padding: "37px 50px", border:"1px #EBEBED solid", borderRadius:"10px", justifyContent:"space-between"}}>
                                    <div className="flexBox">
                                        <div className="icn_receipt" style={{margin: "auto 10px auto 0"}}></div>
                                        <div className="icn_credit_card_text" style={{flex: 1, margin: "auto 10px"}}><b>{this.state.std_date} 기준 고객님의 결제 예상 금액</b></div>
                                    </div>
                                    <div className="flexBox">
                                        <div className="icn_credit_card_text" style={{margin: "auto 0", fontSize:"20px", color: "#10CFC9", fontWeight: "bold"}}>{this.state.payment_amount}</div>
                                        <div className="icn_credit_card_text" style={{margin: "auto 0", fontSize:"15px", color: "#333333", fontWeight:"500", lineHeight:"15px"}}>&nbsp;원&nbsp;&nbsp;</div>
                                        <div className="icn_credit_card_text" style={{margin: "auto 0", fontSize:"12px", color: "#ADADAD", fontWeight:"500", lineHeight:"15px"}}>VAT 포함</div>
                                    </div>
                                </div>
                                {
                                    this.state.requestMembershipPaymentList.map((req_data, req_idx) => 
                                        <>
                                            <div className="credit_card_box_area credit_card_box_area_mobile" style={{marginTop:"20px", padding: "50px", padding: "37px 50px", border:"1px #EBEBED solid", borderRadius:"10px"}}>
                                                <div className="credit_card_box_area_flex" style={{display:"flex"}}>
                                                    <div className="credit_card_box cursor-pointer" style={{width: "calc((100% - 50px) / 6)",
                                                        height: "32vh",
                                                        border: "1px solid #10CFC9",
                                                        backgroundColor: "#10CFC9",
                                                        borderRadius: "13px",
                                                        marginTop: "15px",
                                                        marginLeft: "10px",
                                                        color: "white",
                                                        fontWeight: "bold",
                                                        maxWidth:"200px",
                                                    }}>
                                                        <div className="credit_card_box_first_area" style={{display:"flex", flexDirection:"column"}}>
                                                            <div className="payment_type_name" style={{textAlign:"center", fontSize:"25px" , margin: "3.5vh auto auto auto", height:"30px" }}>
                                                                {req_data.payment_type_name}
                                                            </div>
                                                            <div className="round_box" style={{
                                                                width: "65px",
                                                                padding: "6px 0px 5px",
                                                                textAlign:"center", 
                                                                margin: "4vh auto auto auto", 
                                                                fontSize: "12px", 
                                                                lineHeight:"16px",
                                                                border: "1px solid #FFFFFF",
                                                                borderRadius: "100px",
                                                                }}>
                                                                기본요금
                                                            </div>
                                                        </div>
                                                        <div className="credit_card_box_second_area" style={{display:"flex", flexDirection:"column"}}>
                                                            <div className="credit_card_box_second_inner_area" style={{display:"flex", flexDirection:"column"}}>
                                                                <div className="credit_card_box_second_area_txt" style={{display:"flex", justifyContent: "center", textAlign:"center", fontSize:"25px" , margin: "1vh auto auto auto", height:"30px" }}>
                                                                    <div className="credit_card_box_second_area_txt_price" style={{lineHeight:"30px"}}>
                                                                        {req_data.payment_type_price_fm}
                                                                    </div>   
                                                                    <div className="credit_card_box_second_area_txt_won" style={{fontSize:"15px", lineHeight:"34px"}}>
                                                                        원
                                                                    </div>   
                                                                </div>
                                                                <div className="credit_card_box_second_area_txt vat_area" style={{textAlign:"center", margin: "2vh auto auto auto", fontSize: "12px", lineHeight:"16px"}}>
                                                                    <div className="btn-auto-b credit_card_box_second_area_txt"
                                                                        style={{
                                                                                width: "60px",
                                                                                height: "24px",
                                                                                lineHeight: "24px",
                                                                                background: "rgb(255 255 255 / 30%)",
                                                                                borderRadius: "5px",
                                                                                fontSize: "10px",
                                                                                padding: "0px",
                                                                                margin: "0 auto",
                                                                            }}
                                                                            // onClick={e => this.onClickOpenPopup(e, 1)}
                                                                            >
                                                                            VAT 별도
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="credit_card_box_second_area_txt over_charge_standard_fm_area"  
                                                                style={{
                                                                    textAlign:"center", 
                                                                    fontSize:"12px" , 
                                                                    margin: "3vh 5.8% auto 5.8%", 
                                                                    // width:"147px",
                                                                    // height:"36px", 
                                                                    background:"white", 
                                                                    borderRadius: "5px", 
                                                                    color:"#333333",
                                                                    lineHeight: "36px",
                                                                    fontWeight: "bold",
                                                            }}>
                                                                {req_data.payment_type_target}
                                                                {/* 월 페이지뷰 {req_data.over_charge_standard}명 까지 */}
                                                            </div>
                                                        </div>
                                                        {/* <div className="credit_card_box_second_area" style={{display:"flex", flexDirection:"column"}}>
                                                            <div className="credit_card_box_second_area_txt over_charge_standard_fm_area coupon_area"
                                                                style={{
                                                                    textAlign:"center", 
                                                                    fontSize:"12px" , 
                                                                    margin: "1vh 5.8% 1vh 5.8%", 
                                                                    // width:"147px",
                                                                    // height:"36px", 
                                                                    background:input_bg_color, 
                                                                    borderRadius: "5px", 
                                                                    color:"#333333",
                                                                    lineHeight: "36px",
                                                                    fontWeight: "bold",
                                                            }}>
                                                                <input type="text" id="coupon_no"
                                                                    placeholder="쿠폰번호 입력"
                                                                    disabled={this.state.is_use == "Y" ? "disabled" : ""}
                                                                    maxLength={20}
                                                                    style={{width:"100%", height:"28px", textAlign:"center", background: input_bg_color, fontSize:"13px", border:"0px"}} onChange={e => this.onChangeInputText(e, "coupon_no")} value={this.state.coupon_no}></input>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    <div className="credit_card_box_area2" style={{padding: "4.5vh 3vw 4.5vh 3vw", flex:"1", overflow:"auto"}}>
                                                        <div style={{width: "auto", whiteSpace: "nowrap"}}>
                                                            <div className="credit_card_box_area_flex" style={{display: "flex", width: "auto",whiteSpace: "nowrap"}}>
                                                                <div className="mobile_width_100" style={{
                                                                    // width: "84px",
                                                                    fontWeight: "bold",
                                                                    fontSize: "12px",
                                                                    margin: "auto 0",
                                                                }}>
                                                                    서비스명
                                                                </div>
                                                                <div className="credit_card_box_area_flex_inner" style={{
                                                                    fontWeight: "bold",
                                                                    fontSize: "25px",
                                                                    margin: "auto 0 auto 68px",
                                                                }}>{req_data.service_type_name}</div>
                                                            </div>  
                                                            <div className="credit_card_box_area_flex" style={{display: "flex", marginTop:"2vh"}}>
                                                                <div className="mobile_width_100" style={{
                                                                    // width: "84px",
                                                                    fontWeight: "bold",
                                                                    fontSize: "12px",
                                                                    margin: "auto 0",
                                                                }}>이번 달 이용요금</div>  
                                                                <div className="credit_card_box_area_flex_inner" style={{
                                                                    fontWeight: "bold",
                                                                    fontSize: "25px",
                                                                    margin: "auto 0 auto 30px",
                                                                }}>{
                                                                    // this.state.is_coupon_check ? data_requestMembershipTotalMap.amount : data_requestMembershipTotalMap.pre_amount
                                                                    req_data.payment_amount_fm
                                                                }</div>
                                                                <div style={{
                                                                    fontWeight: "bold",
                                                                    fontSize: "15px",
                                                                    margin: "auto 0 auto 5px",
                                                                }}>원</div>
                                                                <div
                                                                style={{
                                                                    width: "60px",
                                                                    height: "24px",
                                                                    lineHeight: "24px",
                                                                    background: "#F5F5F5",
                                                                    borderRadius: "5px",
                                                                    fontSize: "10px",
                                                                    padding: "0px",
                                                                    margin: "auto 5px",
                                                                    textAlign: "center",
                                                                    color: "rgba(51, 51, 51, 0.2)",
                                                                    }}
                                                                    // onClick={e => this.onClickOpenPopup(e, 1)}
                                                                    >
                                                                    VAT 포함
                                                                </div>  
                                                            </div>
                                                            <div className="credit_card_box_area_flex" style={{display: "flex", width: "auto",whiteSpace: "nowrap", marginTop:"2vh"}}>
                                                                <div className="mobile_width_100" style={{
                                                                    // width: "45px",
                                                                    fontWeight: "bold",
                                                                    fontSize: "12px",
                                                                    margin: "auto 0",
                                                                }}>이용기간
                                                                </div>  
                                                                <div className="mobile_width_100" style={{display: "flex", width: "auto",whiteSpace: "nowrap"}}>
                                                                    <div className="credit_card_box_area_flex_inner" style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: "25px",
                                                                        margin: "auto 0 auto 69px",
                                                                    }}>{req_data.start_date_y}</div>
                                                                    <div style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: "15px",
                                                                        margin: "auto 0 auto 5px",
                                                                    }}>년</div>  
                                                                    <div style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: "25px",
                                                                        margin: "auto 0 auto 15px",
                                                                    }}>{req_data.start_date_m}</div>
                                                                    <div style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: "15px",
                                                                        margin: "auto 0 auto 5px",
                                                                    }}>월</div>  
                                                                    <div style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: "25px",
                                                                        margin: "auto 0 auto 15px",
                                                                    }}>{req_data.start_date_d}</div>
                                                                    <div style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: "15px",
                                                                        margin: "auto 0 auto 5px",
                                                                    }}>일</div>  
                                                                    <div style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: "15px",
                                                                        margin: "auto 20px auto 20px",
                                                                        color:"#C2C2C2",
                                                                    }}>~</div>  
                                                                </div>
                                                                <div className="mobile_width_100" style={{display: "flex", width: "auto",whiteSpace: "nowrap"}}>
                                                                    <div style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: "25px",
                                                                        margin: "auto 0 auto 0",
                                                                    }}>{req_data.end_date_y}</div>
                                                                    <div style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: "15px",
                                                                        margin: "auto 0 auto 5px",
                                                                    }}>년</div>  
                                                                    <div style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: "25px",
                                                                        margin: "auto 0 auto 15px",
                                                                    }}>{req_data.end_date_m}</div>
                                                                    <div style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: "15px",
                                                                        margin: "auto 0 auto 5px",
                                                                    }}>월</div>  
                                                                    <div style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: "25px",
                                                                        margin: "auto 0 auto 15px",
                                                                    }}>{req_data.end_date_d}</div>
                                                                    <div style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: "15px",
                                                                        margin: "auto 0 auto 5px",
                                                                    }}>일</div>  
                                                                </div>  
                                                            </div>  

                                                            <div className="credit_card_box_area_flex" style={{display: "flex", width: "auto",whiteSpace: "nowrap", marginTop:"2vh"}}>
                                                                <div className="mobile_width_100" style={{
                                                                    // width: "45px",
                                                                    fontWeight: "bold",
                                                                    fontSize: "12px",
                                                                    margin: "auto 0",
                                                                }}>다음 결제일
                                                                </div>  
                                                                <div className="credit_card_box_area_flex_inner" style={{
                                                                    fontWeight: "bold",
                                                                    fontSize: "25px",
                                                                    margin: "auto 0 auto 56px",
                                                                }}>{req_data.end_date_y}</div>
                                                                <div style={{
                                                                    fontWeight: "bold",
                                                                    fontSize: "15px",
                                                                    margin: "auto 0 auto 5px",
                                                                }}>년</div>  
                                                                <div style={{
                                                                    fontWeight: "bold",
                                                                    fontSize: "25px",
                                                                    margin: "auto 0 auto 15px",
                                                                }}>{req_data.end_date_m}</div>
                                                                <div style={{
                                                                    fontWeight: "bold",
                                                                    fontSize: "15px",
                                                                    margin: "auto 0 auto 5px",
                                                                }}>월</div>  
                                                                <div style={{
                                                                    fontWeight: "bold",
                                                                    fontSize: "25px",
                                                                    margin: "auto 0 auto 15px",
                                                                }}>{req_data.end_date_d}</div>
                                                                <div style={{
                                                                    fontWeight: "bold",
                                                                    fontSize: "15px",
                                                                    margin: "auto 0 auto 5px",
                                                                }}>일</div>  
                                                            </div>  
                                                            {/* <div className="credit_card_box_area_flex" style={{display: "flex", width: "auto",whiteSpace: "nowrap", marginTop:"3.1vh"}}>
                                                                <div className="mobile_width_100" style={{
                                                                    // width: "45px",
                                                                    fontWeight: "bold",
                                                                    fontSize: "12px",
                                                                    margin: "auto 0",
                                                                }}>유의사항
                                                                </div>  
                                                                <div className="credit_card_box_area_flex_inner" style={{
                                                                    fontWeight: "400",
                                                                    fontSize: "12px",
                                                                    margin: "auto 0 auto 69px",
                                                                }}><b>이용기간</b> 동안의 페이지뷰가 {req_data.over_charge_standard}명을 넘는 경우, <b>다음 결제일</b>에 기본요금과 추가요금이 결제 됩니다.</div>
                                                            </div> */}
                                                        </div>  
                                                    </div>  
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                <div>
                                    <div className="credit_card_box_area" style={{marginTop:"20px", padding: "37px 50px", border:"1px #EBEBED solid", borderRadius:"10px"}}>
                                        <div style={{display:"flex"}}>
                                            <div className="icn_round_blue" style={{width: "10px", height: "10px", margin: "auto 0", fontSize:"18px"}}></div>
                                            <div className="icn_credit_card_text" style={{margin: "auto 0 auto 15px", fontSize:"15px", fontWeight:"500", flex: "1"}}>&nbsp;로보MD는 결제 후 이용 가능합니다.</div>
                                        </div>
                                        <div style={{display:"flex", marginTop:"10px"}}>
                                            <div className="icn_round_blue" style={{width: "10px", height: "10px", margin: "auto 0", fontSize:"18px"}}></div>
                                            <div className="icn_credit_card_text" style={{margin: "auto 0 auto 15px", fontSize:"15px", fontWeight:"500", flex: "1"}}>&nbsp;결제일은 매달 말일입니다.</div>
                                        </div>
                                        {/* <div style={{display:"flex", marginTop:"10px"}}>
                                            <div className="icn_round_blue" style={{width: "10px", height: "10px", margin: "auto 0", fontSize:"18px"}}></div>
                                            <div className="icn_credit_card_text" style={{margin: "auto 0 auto 15px", fontSize:"15px", fontWeight:"500", flex: "1"}}>&nbsp;다음 달 기본 요금과 이번 달 총 페이지뷰 수가 멤버십 기준을 넘는 경우, 추가 요금이 부과 됩니다.</div>
                                        </div> */}
                                        <div style={{display:"flex", marginTop:"10px"}}>
                                            <div className="icn_round_blue" style={{width: "10px", height: "10px", margin: "auto 0", fontSize:"18px"}}></div>
                                            <div className="icn_credit_card_text" style={{margin: "auto 0 auto 15px", fontSize:"15px", fontWeight:"500", flex: "1"}}>&nbsp;결제수단이 카드는 자동 결제되며, 실시간계좌이체는 매달 말일 로보MD 웹사이트를 통해(실시간계좌이체) 해주셔야 합니다.</div>
                                        </div>
                                        <div style={{display:"flex", marginTop:"10px"}}>
                                            <div className="icn_round_blue" style={{width: "10px", height: "10px", margin: "auto 0", fontSize:"18px"}}></div>
                                            <div className="icn_credit_card_text" style={{margin: "auto 0 auto 15px", fontSize:"15px", fontWeight:"500", flex: "1"}}>&nbsp;실시간 계좌이체시 세금계산서는 요청일자에 발행해 드립니다.</div>
                                        </div>
                                        {/* <div style={{display:"flex", marginTop:"10px"}}>
                                            <div className="icn_round_blue" style={{width: "10px", height: "10px", margin: "auto 0", fontSize:"18px"}}></div>
                                            <div className="icn_credit_card_text" style={{margin: "auto 0 auto 15px", fontSize:"15px", fontWeight:"500", flex: "1"}}>&nbsp;멤버십 결제를 진행하시면 다른 상점의 무료 체험도 종료됩니다.</div>
                                        </div> */}
                                    </div>
                                </div>
                            </>
                        : 
                        <div className="flexBox credit_card_box_area credit_card_box_area_header" style={{marginTop:"50px", padding: "37px 50px", border:"1px #EBEBED solid", borderRadius:"10px"}}>
                            <div className="icn_credit_card_text" style={{margin: "auto 0", fontSize:"18px", color:"#C2C2C2"}}><b>&nbsp;이번 달에 결제할 멤버십이 없습니다.</b></div>
                        </div>
                    }          
                </div>

                {/* <div style={{display:"flex", float:"right"}}>
                    <button id="button_step_next" className="btn-auto-b" style={{padding: "18px 30px 17px", marginTop:"30px", marginBottom:"40px"}} onClick={e => this.onChangeStepNext(e)}>
                        다음
                    </button>
                </div> */}

                {/* 실시간 계좌이체 FORM */}
                <form name="payForm" style={{display:"none"}} accept-charset="euc-kr">
                <table style={{width:"100%",border:"1px",cellspacing:"0", cellpadding:"0"}}>
                    <colgroup>
                        <col width="160px" />
                        <col width="*" />
                    </colgroup>
                    <tr>
                        <td></td>
                        <td>
                            {/* <input type="button" value="결제하기" onClick={this.payLink} style={{width:"63", height:"25"}}/> */}
                        </td>
                    </tr>
                </table>
                <br/>
                <br/>

                <table style={{width:"100%",border:"1px",cellspacing:0,cellpadding:0}}>
                    <colgroup>
                        <col width="160px" />
                        <col width="180px" />
                        <col width="*" />
                    </colgroup>
                    <tr> 
                        <td colspan="3"><b>----------공통항목(필수)</b></td>
                    </tr>
                    <tr> 
                        <td><b>파라미터</b></td>
                        <td><b>값</b></td>
                        <td><b>설명</b></td>
                    </tr>
                    <tr> 
                        <td>USER_SEQ</td>
                        <td><input type="text" name="RESERVEDINDEX1" value={this.state.payLinkPreData.USER_SEQ}/></td>
                        <td>사용자 일련번호</td>
                    </tr>
                    <tr> 
                        <td>SHOP_NO</td>
                        <td><input type="text" name="RESERVEDINDEX2" value={this.state.payLinkPreData.SHOP_NO}/></td>
                        <td>쇼핑몰 번호</td>
                    </tr>
                    <tr> 
                        <td>STD_YM</td>
                        <td><input type="text" name="STD_YM" value={this.state.payLinkPreData.STD_YM}/></td>
                        <td>기준 년월</td>
                    </tr>
                    <tr> 
                        <td colspan="3"><b>----------고정항목----------</b></td>
                    </tr>
                    <tr> 
                        <td>TYPE</td>
                        <td><input type="text" name="TYPE" value="W"/></td>
                        <td>결제방식</td>
                    </tr>
                    <tr> 
                        <td>PAYMETHOD</td>
                        <td><input type="text" name="PAYMETHOD" value="BANK"/></td>
                        <td>결제수단</td>
                    </tr>
                    <tr> 
                        <td>CPID</td>
                        <td>
                            {/* 개발 : <input type="text" name="CPID" value="CTS16826"/> */}
                            <input type="text" name="CPID" value="CRG55686"/>
                        </td>
                        <td>상점아이디</td>
                    </tr>
                    <tr> 
                        <td>PRODUCTTYPE</td>
                        <td>
                            {/* 개발 : <input type="text" name="PRODUCTTYPE" value="1"/> */}
                            <input type="text" name="PRODUCTTYPE" value="2"/>
                        </td>
                        <td>상품구분(1: 디지털, 2: 실물)</td>
                    </tr>
                    <tr> 
                        <td colspan="3"><b>----------변동항목(DB에서 조회)----------</b></td>
                    </tr>
                    <tr> 
                        <td>ORDERNO</td>
                        <td><input type="text" name="ORDERNO" value={this.state.payLinkPreData.ORDERNO}/></td>
                        <td>주문번호</td>
                    </tr>
                    <tr> 
                        <td>AMOUNT</td>
                        <td><input type="text" name="AMOUNT" value={this.state.payLinkPreData.AMOUNT}/></td>
                        {/* <td><input type="text" name="AMOUNT" value="1000"/></td> */}
                        <td>결제금액</td>
                    </tr>
                    <tr> 
                        <td>PRODUCTNAME</td>
                        <td><input type="text" name="PRODUCTNAME" value={this.state.payLinkPreData.PRODUCT_NAME}/></td>
                        <td>상품명</td>
                    </tr>
                    <tr> 
                        <td>PRODUCTCODE</td>
                        <td><input type="text" name="PRODUCTCODE" value={this.state.payLinkPreData.PRODUCT_NO}/></td>
                        <td>상품코드</td>
                    </tr>
                    <tr> 
                        <td>USERID</td>
                        <td><input type="text" name="USERID" value={this.state.payLinkPreData.USERID}/></td>
                        <td>고객 ID</td>
                    </tr>
                    <tr> 
                        <td>EMAIL</td>
                        <td><input type="text" name="EMAIL" value={this.state.payLinkPreData.EMAIL}/></td>
                        <td>고객 이메일</td>
                    </tr>
                    <tr> 
                        <td>USERNAME</td>
                        <td><input type="text" name="USERNAME" value={this.state.payLinkPreData.USERNAME}/></td>
                        <td>고객명</td>
                    </tr>
                    <tr> 
                        <td colspan="3"><b>----------변동항목(선택)----------</b></td>
                    </tr>
                    <tr> 
                        <td>RESERVEDSTRING</td>
                        <td><input type="text" name="RESERVEDSTRING" value={this.state.payLinkPreData.PAYMENT_AMOUNT_SEQ}/></td>
                        <td>예약스트링</td>
                    </tr>
                    <tr> 
                        <td>RETURNURL</td>
                        <td><input type="text" name="RETURNURL" value="https://robomd.io/shop/order/list"/></td>
                        <td>결제 성공 후, 이동할 URL(새 창)</td>
                    </tr>
                    <tr> 
                        <td>DIRECTRESULTFLAG</td>
                        <td><input type="text" name="DIRECTRESULTFLAG" value=""/></td>
                        <td>페이조아 결제 완료 창 없이 HOMEURL로 바로 이동(Y/N)</td>
                    </tr>
                    <tr> 
                        <td>SET_LOGO</td>
                        <td><input type="text" name="SET_LOGO" value=""/></td>
                        <td>결제 창 하단 상점로고 URL</td>
                    </tr>	
                    <tr> 
                        <td colspan="3"><b>----------웹뷰결제창(필수)</b></td>
                    </tr> 
                    <tr> 
                        <td>HOMEURL</td>
                        <td><input type="text" name="HOMEURL" value="javascript:window.close()"/></td>
                        <td>결제 성공 후, 이동할 URL(결제 창에서 이동)</td>
                    </tr>
                    <tr>
                        <td>CLOSEURL</td>
                        <td><input type="text" name="CLOSEURL" value="javascript:window.close()"/></td>
                        <td>결제 창에서 취소 누를 시 이동할 URL</td>
                    </tr>
                    <tr> 
                        <td>FAILURL</td>
                        <td><input type="text" name="FAILURL" value="javascript:window.close()"/></td>
                        <td>결제실패 후, 확인버튼 입력 시 이동할 URL</td>
                    </tr>
                    <tr>
                        <td>APPURL</td>
                        <td><input type="text" name="APPURL" value=""/></td>
                        <td>인증완료 후 돌아갈 앱의 URL(CARD, CARDK, BANK 필수)</td>
                    </tr>
                    <tr> 
                        <td colspan="3"><b>----------계좌이체(선택)</b></td>
                    </tr>
                    <tr> 
                        <td>USERSSN</td>
                        <td><input type="text" name="USERSSN" value=""/></td>
                        <td>생년월일(YYMMDD)</td>
                    </tr>
                    <tr> 
                        <td>CHECKSSNYN</td>
                        <td><input type="text" name="CHECKSSNYN" value=""/></td>
                        <td>USERSSN사용여부(Y/N)(계좌이체 결제자와 명의자(USERSSN)가 동일한 경우만 결제가능)</td>
                    </tr>
                </table>
                </form>
            </div>
        )
    }
}   
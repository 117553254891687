import React from 'react';
// import ReactPixel from 'react-facebook-pixel';
import {ga4react, reactPixel} from './Menu'
import http from './modules/api';
import Button from "./modules/common/Button";
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
// import { pdfjsworker } from 'pdfjs-dist/es5/build/pdf.worker.entry';
import term_file_1 from '../resources/file/terms/로보엠디_개인정보제3자제공및위탁제공_0511.pdf'
import term_file_2 from '../resources/file/terms/로보엠디_개인정보처리방침_0511.pdf'
import term_file_3 from '../resources/file/terms/로보엠디_이용약관_0511.pdf'
import isThursday from 'date-fns/isThursday';
import { withTranslation } from "react-i18next";
// var agent = navigator.userAgent.toLowerCase();
// if( agent.indexOf("chrome") != -1 ){
//     pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// } else if ( agent.indexOf("safari") != -1 ) {
//     pdfjs.GlobalWorkerOptions.workerSrc = pdfjsworker;
//     // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// } else {
//     pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// }
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

var date = new Date();
var non_youth_yn = false;
var agree_check = false;

// ReactPixel.init('438227415050093');

class TermsCndtnForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            agree_yn : false,
            // list: [],

            btn_next : false,

            numPages_1: null,
            pageNumber_1: 1,

            numPages_2: null,
            pageNumber_2: 1,

            numPages_3: null,
            pageNumber_3: 1,

            third_party_consent: "N",
            privacy_policy: "N",
            terms_of_service: "N",
            all_check: "N",

            validate_list : [],

            /* 약관 동의 리스트 */
			form_agree_list: []
        }
    }

    componentWillMount() {
        this.getFormTernm('banner');
    }

    componentDidMount() {
        agree_check = false;
        this.props.onClickAgreeButton(agree_check);
        // this.getFormTernm('robomd');
    }

    //만 14세 이상 가입자 확인
    onClickNonYouthCheck (e) {
        non_youth_yn = e.target.checked;
	}

    //약관동의
    onClickAgreeCheck (e) {
        agree_check = e.target.checked;
    }

    // 모두 동의 체크
    handleTermsAllCheckOnClick = () => {
        if(this.state.all_check === "Y") {
            ga4react.event('[회원가입] Step2: 약관 동의 - 모두 동의 체크', '약관 동의 - 모두 동의 체크', '회원가입');
            reactPixel.trackCustom('[회원가입] Step2: 약관 동의 - 모두 동의 체크');
        }
    }

    // 약관 동의 체크
    handleTermsAgreeOnClick = (e) => {

        // 이용약관(필수) 체크
        if(e.target.parentElement.parentElement.lastElementChild.getAttribute("href") === "/common/FormViewer/9001"){
            ga4react.event('[회원가입] Step2: 약관 동의 - 이용약관(필수) 체크', '약관 동의 - 이용약관(필수) 체크', '회원가입');
            reactPixel.trackCustom('[회원가입] Step2: 약관 동의 - 이용약관(필수) 체크');

            // 개인정보처리방침(필수) 체크
        }else if(e.target.parentElement.parentElement.lastElementChild.getAttribute("href") === "/common/FormViewer/9002"){
            ga4react.event('[회원가입] Step2: 약관 동의 - 개인정보처리방침(필수) 체크', '약관 동의 - 개인정보처리방침(필수) 체크', '회원가입');
            reactPixel.trackCustom('[회원가입] Step2: 약관 동의 - 개인정보처리방침(필수) 체크');

        }
    }

    // 마케팅 & 업데이트 소식 수신 동의(선택) 체크
    handleTermsMarketingOnClick = (e) => {
        if(e.target.parentElement.parentElement.lastElementChild.getAttribute("href") === "/common/FormViewer/9004"){
            ga4react.event('[회원가입] Step2: 약관 동의 - 마케팅 동의(선택) 체크', '약관 동의 - 마케팅 동의(선택) 체크', '회원가입');
            reactPixel.trackCustom('[회원가입] Step2: 약관 동의 - 마케팅 동의(선택) 체크');
        }
    }

    // 약관 폼 새 창 클릭
    handleTermsFormViewerOnClick = (e) => {

        // 이용약관(필수) 새 창 클릭
        if(e.target.parentElement.getAttribute("href") === "/common/FormViewer/9001"){
            ga4react.event('[회원가입] Step2: 약관 동의 - 이용약관(필수) 새 창 클릭', '약관 동의 - 이용약관(필수) 새 창 클릭', '회원가입');
            reactPixel.trackCustom('[회원가입] Step2: 약관 동의 - 이용약관(필수) 새 창 클릭');

            // 개인정보처리방침(필수) 새 창 클릭
        }else if(e.target.parentElement.getAttribute("href") === "/common/FormViewer/9002"){
            ga4react.event('[회원가입] Step2: 약관 동의 - 개인정보처리방침(필수) 새 창 클릭', '약관 동의 - 개인정보처리방침(필수) 새 창 클릭', '회원가입');
            reactPixel.trackCustom('[회원가입] Step2: 약관 동의 - 개인정보처리방침(필수) 새 창 클릭');

        }
    }

    // 마케팅 & 업데이트 소식 수신 동의(선택) 새 창 클릭
    handleTermsMarketingFormViewerOnClick = (e) => {
        if(e.target.parentElement.getAttribute("href") === "/common/FormViewer/9004"){
            ga4react.event('[회원가입] Step2: 약관 동의 - 마케팅 동의(선택) 새 창 클릭', '약관 동의 - 마케팅 동의(선택) 새 창 클릭', '회원가입');
            reactPixel.trackCustom('[회원가입] Step2: 약관 동의 - 마케팅 동의(선택) 새 창 클릭');
        }
    }

    //약관동의 후 가입신청 이동
	onClickAgreeButton = () => {
        // if (!non_youth_yn) {
        //     alert("만 14세 이상 가입자 여부를 체크해 주세요.");
        //     return false;
		// }
		// if (!agree_check) {
        //     alert("이용약관을 동의 후 진행해 주세요.");
        //     return false;
		// }
        // const {terms_of_service, third_party_consent, privacy_policy} = this.state
        // for(var idx in this.state.list) {
        //     let agree_yn = this.state["agree_"+this.state.list[idx]["form_seq"]];
        //     let requisite_yn = this.state.list[idx]["requisite_yn"];   //필수 동의 여부
        //     //console.log(agree_yn, requisite_yn);
        //     if(agree_yn == "N" && requisite_yn == "Y") {
        //         alert(this.state.list[idx]["form_title"]+"을 동의 후 진행해 주세요.")
        //         return false;
        //     }
        // }
        // agree_check = true;

        if(this.state.btn_next) {
            this.setState({agree_yn: true});
            this.props.onClickAgreeButton(agree_check);
            window.scrollTo(0,0);
            this.props.onClickAgreeButton(agree_check, this.state.form_agree_list);

            this.props.this_parent.setState({step: Number(Number(this.props.this_parent.state.step)+1)}); // 다음 step으로 이동

            ga4react.event('[회원가입] Step2: 약관 동의 - 다음 단계로', '약관 동의 - 다음 단계로', '회원가입');
            reactPixel.trackCustom('[회원가입] Step2: 약관 동의 - 다음 단계로');
        }
    }

    // "다음 단계로" 버튼 활성화/비활성화
    goNext() {
        let consent = document.querySelectorAll(".consent_required .radio");
        let radioChk = 0;

        for(let i = 0; i < consent.length; i++) {
            if(consent[i].classList.contains("on")) {
                radioChk += 1;
            }
        }

        const btn_next = (radioChk == consent.length);
		// update 무한으로 타는것 방지
		if((!this.state.btn_next && btn_next) || (this.state.btn_next && !btn_next)){
			this.setState({btn_next : btn_next});
		}
    }

    //약관 조회
    getFormTernm(form_cls, form_type, form_seq) {
        http.get("/rest/common/formTernm",
            {
                params:{
                    form_cls : form_cls,
                    form_type : form_type,
                    form_seq : form_seq
                }
            })
	 	 	 .then(res => {
                this.setState( {list : res.data} );

                for(var idx in res.data) {
                    var name = "pageNumber_"+res.data[idx]["form_seq"]
                    var name_agree = "agree_"+res.data[idx]["form_seq"]
                    this.setState( {[name]: 1,
                    [name_agree]: "N"});
                }
	 	 	})
	 	 	 .catch(err => {
	 	 		 alert("가입약관 조회 에러");
	 	 	});
    }

    onDocumentLoadSuccess = ({ numPages, name }) => {
        var val = name + "_num_pages"
        this.setState({ [name]: numPages });
    }
    onDocumentLoadSuccess_2 = ({ numPages }) => {
        this.setState({ numPages_2: numPages });
    }
    onDocumentLoadSuccess_3 = ({ numPages }) => {
        this.setState({ numPages_3: numPages });
    }

    onClickAgree = (e, state, name) => {
        // 변수 저장
        let radioBtn = document.querySelectorAll(".terms .radio");
        let radioBtnChk = 0;

        //console.log(name,state)
        this.setState({ [name]: state });
        if (name == "all_check") {
            let list = [];
            //this.setState({form_agree_list: []});
            for(var idx in this.state.list) {
                var name_agree = "agree_"+this.state.list[idx]["form_seq"];
                this.setState( {[name_agree]: state});
                if (state == "Y") {
                    list.push(this.state.list[idx]["form_seq"]+"");
                }
            }
            this.setState({ form_agree_list: list });
        } else {
            let form_seq = name.replace("agree_", "");
            if (state == "Y") {
                this.state.form_agree_list.push(form_seq);
            } else {
                this.state.form_agree_list.splice(this.state.form_agree_list.indexOf(form_seq), 1);
                this.setState({all_check: "N"});
            }
        }
        //console.log(this.state.form_agree_list);
    }

    render () {
        const { t } = this.props;

        //if (this.props.user_join_cls == 'trend') {
            const { pageNumber_1, numPages_1 } = this.state;
            const { pageNumber_2, numPages_2 } = this.state;
            const { pageNumber_3, numPages_3 } = this.state;
            return (
                // (this.props.user_join_cls != '' && !this.state.agree_yn) ?
                    // <>
                    //     {this.state.list != null && this.state.list.map((form, idx) => (
                    //         <div key={idx}>
                    //             <h4>{form.form_title}</h4>
                    //             <div dangerouslySetInnerHTML={ {__html: form.content} }></div>
                    //             <br/>
                    //         </div>
                    //     ))}
                    //     {/* <div style={{fontWeight:"normal", fontSize:"14px"}}>
                    //         상기 내용의 계약체결을 증명하기 위해 계약서를 2통 작성하여 '갑' 과 '을' 이 기명날인한 후 각 1부씩 보관키로 한다.
                    //     </div> */}
                    //     <h3 className="text-center m-b">
                    //     <span id="todays_year"></span>&nbsp;{date.getFullYear()}년&nbsp;&nbsp;&nbsp;&nbsp;<span id="todays_mon"></span>&nbsp;{(date.getMonth()+1)}월&nbsp;&nbsp;&nbsp;&nbsp;<span id="todays_day"></span>&nbsp;{date.getDate()}일</h3>
                    //     <ul>
                    //         <li>
                    //             <span className="pull-left">만 14세 이상 가입자 입니다.</span>
                    //             <b>
                    //                 <label>확인</label>&nbsp;
                    //                 <input id="non_youth_check" type="checkbox" onChange={this.onClickNonYouthCheck}/>
                    //             </b>

                    //         </li>
                    //         <li>
                    //             <span className="pull-left">이용약관에 동의하십니까?</span>
                    //             <b>
                    //                 <label>동의함</label>&nbsp;
                    //                 <input id="agree_check" type="checkbox" onChange={this.onClickAgreeCheck}/>
                    //             </b>
                    //         </li>
                    //     </ul>
                    //     <button onClick={this.onClickAgreeButton} className="btn btn-primary block full-width m-b">다음</button>&nbsp;
                    // </>
                    this.props.step == 2
                    ?
                    <>
                        {/* <h3 style={{marginTop:"5px"}}>이용약관</h3> */}
                        <div className="terms_all">
                            <div className="redio_v2" style={{alignSelf:"center"}}>
                                        <input id="all_check" type="radio" onClick={e => {this.goNext();this.handleTermsAllCheckOnClick()}}/>
                                        {
                                            this.state.all_check == "N"
                                            ?   <label 
                                                htmlFor="all_check" 
                                                className="radio"
                                                onClick={e => this.onClickAgree(e, 'Y', "all_check")}
                                                ></label>

                                            :   <label 
                                                htmlFor="all_check" 
                                                className="radio on"
                                                onClick={e => this.onClickAgree(e, 'N', "all_check")}
                                                ></label>
                                        }
                            </div>
                            {/* <span style={{textAlign:"left", flex:"1", margin:"auto auto auto 15px"}}>로보MD
                            {this.state.list != null && this.state.list.map((form, idx) => (
                                (idx+1) != this.state.list.length
                                ? <>{" "+form.form_title+", "}</>
                                : <>{" "+form.form_title}</>
                            ))}
                            에 모두 동의합니다.</span> */}
                            <span>{t('regist.all_agree')}</span>
                        </div>
                        {/* <div style={{marginTop:"30px"}}>
                            <div style={{display:"flex", justifyContent:"space-between", marginBottom:"10px"}}>
                                <div className="redio_v2" style={{alignSelf:"center"}}>
                                    <input id="terms_of_service" type="radio"/>
                                    {
                                        this.state.terms_of_service == "N"
                                        ?   <div className="radio"
                                            onClick={e => this.onClickAgree(e, 'Y', "terms_of_service")}
                                            ></div>

                                        :   <div className="radio on"
                                            onClick={e => this.onClickAgree(e, 'N', "terms_of_service")}
                                            ></div>
                                    }
                                </div>
                                <span style={{textAlign:"left", flex:"1", margin:"auto auto auto 15px"}}>이용약관 (필수)</span>

                            </div>
                            <div style={{position:"relative"}}>
                                <Document
                                    file={term_file_3}
                                    onLoadSuccess={this.onDocumentLoadSuccess_3}>
                                    <Page pageNumber={pageNumber_3} />
                                </Document>
                                <div className="pdf_viewer_page_area" style={{position:"absolute"}}>
                                    <div style={{alignSelf: "center"}}>
                                        <span className="btn_pdf_viewer_page left" onClick={() => this.state.pageNumber_3 > 1 ?
                                            this.setState({numPages_3 : this.state.numPages_3,
                                                pageNumber_3 : this.state.pageNumber_3-1}) : null}
                                            style={{margin: "0 20px 0 0"}}>
                                        </span>
                                        <span className="pdf_viewer_page_num">{pageNumber_3} / {numPages_3}</span>
                                        <span className="btn_pdf_viewer_page right" onClick={() =>
                                            this.state.pageNumber_3 < numPages_3 ?
                                            this.setState({numPages_3 : this.state.numPages_3,
                                                pageNumber_3 : this.state.pageNumber_3+1}) : null}
                                            style={{margin: "0 0 0 20px"}}>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{marginTop:"20px"}}>
                            <div style={{display:"flex", justifyContent:"space-between", marginBottom:"10px"}}>
                                <div className="redio_v2" style={{alignSelf:"center"}}>
                                    <input id="privacy_policy" type="radio"/>
                                    {
                                        this.state.privacy_policy == "N"
                                        ?   <div className="radio"
                                            onClick={e => this.onClickAgree(e, 'Y', "privacy_policy")}
                                            ></div>

                                        :   <div className="radio on"
                                            onClick={e => this.onClickAgree(e, 'N', "privacy_policy")}
                                            ></div>
                                    }
                                </div>
                                <span style={{textAlign:"left", flex:"1", margin:"auto auto auto 15px"}}>개인정보 처리방침 (필수)</span>

                            </div>
                            <div style={{position:"relative"}}>
                                <Document
                                    file={term_file_2}
                                    onLoadSuccess={this.onDocumentLoadSuccess_2}>
                                    <Page pageNumber={pageNumber_2} />
                                </Document>
                                <div className="pdf_viewer_page_area" style={{position:"absolute"}}>
                                    <div style={{alignSelf: "center"}}>
                                        <span className="btn_pdf_viewer_page left" onClick={() => this.state.pageNumber_2 > 1 ?
                                            this.setState({numPages_2 : this.state.numPages_2,
                                                pageNumber_2 : this.state.pageNumber_2-1}) : null}
                                            style={{margin: "0 20px 0 0"}}>
                                        </span>
                                        <span className="pdf_viewer_page_num">{pageNumber_2} / {numPages_2}</span>
                                        <span className="btn_pdf_viewer_page right" onClick={() =>
                                            this.state.pageNumber_2 < numPages_2 ?
                                            this.setState({numPages_2 : this.state.numPages_2,
                                                pageNumber_2 : this.state.pageNumber_2+1}) : null}
                                            style={{margin: "0 0 0 20px"}}>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {this.state.list != null && this.state.list.map((form, idx) => (
                            <>
                                <div className="terms">
                                    <div className="terms_line">
                                    {
                                        form.form_type != "9004"
                                        ? 
                                            <>
                                            <div className="redio_v2 consent_required" style={{alignSelf:"center"}}>
                                                <input id="third_party_consent" type="radio" onClick={e => this.goNext()}/>
                                                {
                                                    this.state["agree_"+form.form_seq] == "N"
                                                    ?   <label 
                                                        htmlFor="third_party_consent" 
                                                        className="radio"
                                                        onClick={e => {this.onClickAgree(e, 'Y', "agree_"+form.form_seq);this.handleTermsAgreeOnClick(e)}}
                                                        ></label>

                                                    :   <label 
                                                        htmlFor="third_party_consent" 
                                                        className="radio on"
                                                        onClick={e => this.onClickAgree(e, 'N', "agree_"+form.form_seq)}
                                                        ></label>
                                                }
                                            </div>
                                            <a className="formTitleClickArea" href={t('regist.detail_form_url')+form.form_type} target="_blank" onClick={e => this.handleTermsFormViewerOnClick(e)}><span>{form[t('regist.termsCndtn')]}&nbsp;{ form.requisite_yn == "Y" ? t('regist.required') : t('regist.optional')}</span></a>
                                           </>
                                        :   (t('language') == "kr") && <>
                                                <div className="redio_v2" style={{alignSelf:"top"}}>
                                                    <input id="third_party_consent" type="radio"/>
                                                    {
                                                        this.state["agree_"+form.form_seq] == "N"
                                                        ?   <div className="radio"
                                                            onClick={e => {this.onClickAgree(e, 'Y', "agree_"+form.form_seq);this.handleTermsMarketingOnClick(e)}}
                                                            ></div>

                                                        :   <div className="radio on"
                                                            onClick={e => this.onClickAgree(e, 'N', "agree_"+form.form_seq)}
                                                            ></div>
                                                    }
                                                </div>
                                                <a className="formTitleClickArea" href={"/common/FormViewer/"+form.form_type} target="_blank" onClick={e => this.handleTermsMarketingFormViewerOnClick(e)}><span>{form[t('regist.termsCndtn')]}&nbsp;{ form.requisite_yn == "Y" ? t('regist.required') : t('regist.optional')}</span></a>
                                            </>
                                        }
                                        

                                    </div>
                                    {/* <div style={{position:"relative"}}>
                                        <Document
                                            file={form.s3_url}
                                            renderMode="svg"
                                            onLoadSuccess={({ numPages }) => {
                                                var name = "numPages_"+form.form_seq;
                                                this.setState({ [name]: numPages });
                                                }}>
                                            <Page pageNumber={this.state["pageNumber_"+form.form_seq]} />
                                        </Document>
                                        <div className="pdf_viewer_page_area" style={{position:"absolute"}}>
                                            <div style={{alignSelf: "center"}}>
                                                <span className="btn_pdf_viewer_page left" onClick={() =>
                                                    {
                                                        var name = "numPages_"+form.form_seq;
                                                        var name2 = "pageNumber_"+form.form_seq;
                                                        if (this.state["pageNumber_"+form.form_seq] > 1) {
                                                            this.setState({[name] : this.state["numPages_"+form.form_seq],
                                                                [name2] : this.state["pageNumber_"+form.form_seq]-1})
                                                        }
                                                    }
                                                    }
                                                    style={{margin: "0 20px 0 0"}}>
                                                </span>
                                                <span className="pdf_viewer_page_num">{this.state["pageNumber_"+form.form_seq]} / {this.state["numPages_"+form.form_seq]}</span>
                                                <span className="btn_pdf_viewer_page right" onClick={() =>
                                                    {
                                                        var name = "numPages_"+form.form_seq;
                                                        var name2 = "pageNumber_"+form.form_seq;
                                                        if (this.state["pageNumber_"+form.form_seq] < this.state["numPages_"+form.form_seq]) {
                                                            this.setState({[name] : this.state["numPages_"+form.form_seq],
                                                                [name2] : this.state["pageNumber_"+form.form_seq]+1})
                                                        }
                                                    }
                                                    }
                                                    style={{margin: "0 0 0 20px"}}>
                                                </span>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </>
                        ))}
                        {
							this.props.step < 4
							?
                                <Button
                                    className={["btn-auto-b", this.state.btn_next && "cursor-pointer-important"].join(' ')}
                                    onClick={this.onClickAgreeButton}
                                    disabled={!this.state.btn_next}
                                    width={"100%"}
                                    height={"50px"}
                                    fontSize={"16px"}
                                >
                                    {t('regist.next_step_button')}
                                </Button>
							:	""
						}
                        {/* <div style={{display:"flex", width:"100%", justifyContent:"flex-end", marginTop:"20px"}}>
                    	    <button onClick={this.onClickAgreeButton} className="btn-auto-b" style={{width:"120px", height: "55px"}}>다음</button>&nbsp;
					    </div> */}
                    </>
                    : ''
            )
        // } else if (this.props.user_join_cls == 'cafe24') {
        //     return (
        //         this.state.agree_yn ? '' :
        //             <>
        //                 <h3>자동기획전 이용약관</h3>
        //                 <div className="middle-box2 animated fadeInDown joinContract">
        //                     <div className="marginB_15px marginT_10px" style={{fontWeight:"normal", fontSize:"14px"}}>
        //                         로보MD에서 아래의 권한을 이용하는 것에 동의합니다.
        //                     </div>
        //                     <br/>
        //                     <div className="m-b-lg" style={{border:"2px solid lightgrey", background:"white", borderRadius:"5px", padding:"20px",
        //                                                     height:"350px", overflowY:"scroll", textAlign:"justify", wordBreak:"keep-all", fontSize:"14px"}}>
        //                         <b>(신규) 앱 : 읽기권한</b><br/>
        //                         앱 설치와 관련된 정보를 조회할 수 있습니다.<br/><br/>
        //                         <b>(신규) 상품 : 읽기권한</b><br/>
        //                         상품(Products) 정보를 조회할 수 있습니다.<br/><br/>
        //                         <b>(신규) 게시판 : 읽기권한</b><br/>
        //                         리뷰톡톡(Reviews), 덧글(Comments) 정보를 조회할 수 있습니다.<br/><br/>
        //                         <b>(신규) 상점 : 읽기권한</b><br/>
        //                         상점 설정 정보를 조회할 수 있습니다.<br/><br/>
        //                         <b>(신규) 판매분류 : 읽기권한</b><br/>
        //                         브랜드(Brands), 자체분류(Classification), 제조사(Manufacturers), 트렌드(Trends) 정보를 조회할 수 있습니다.<br/><br/>
        //                         <b>(신규) 개인화정보 : 읽기권한</b><br/>
        //                         좋아요(Likes), 관심상품(Wishes), 장바구니(Cart) 정보를 조회할 수 있습니다.<br/><br/>
        //                         <b>(신규) 상품분류 : 읽기권한</b><br/>
        //                         상품분류(Categories), 메인진열(Mains), 기획전(Projects) 정보를 조회할 수 있습니다.<br/><br/>
        //                         <b>(신규) 매출통계 : 읽기권한</b><br/>
        //                         매출 통계와 관련된 정보를 조회할 수 있습니다.<br/><br/>

        //                         설치를 하는 앱이 제공하는 기능을 사용하려면 쇼핑몰의 정보에 접근할 수 있도록 앱에 접근권한을 부여해야 합니다.<br/><br/>
        //                         앱의 접근권한에 대해 동의하게 되면, 아래와 같은 권한이 발생합니다. 동의하기 전에 아래 내용을 주의깊게 읽어보시기 바랍니다.<br/><br/>

        //                         앱이 API를 이용하여 쇼핑몰의 정보에 접근 가능하게 됩니다. 앱이 접근할 수 있는 쇼핑몰의 정보에 대한 상세내용은 위 접근권한 목록을 참조하시기 바랍니다.<br/>
        //                         앱의 개발사는 앱의 유지보수를 위해 필요한 경우 위 정보에 접근할 수 있게 됩니다.<br/>
        //                         앱이 쇼핑몰이 보유한 개인정보에 접근하는 경우 아래와 같은 처리가 필요합니다.<br/><br/>

        //                         <b>※ (주의) 쇼핑몰 회원의 개인정보와 관련된 사항</b><br/>
        //                         기능 상 쇼핑몰의 정보 중 쇼핑몰 회원의 일부 개인정보에 대해서도 접근권한이 필요할 수 있습니다. (앱이 접근하는 개인정보의 항목은 위 접근권한 목록 참조)<br/>
        //                         따라서 앱의 기능 및 그 사용 목적에 따라 개인정보의 처리 위탁 또는 제3자 제공이 발생할 수 있으며, 이에 대해서는 개인정보보호법 및 정보통신망법에 따라 쇼핑몰에서 개인정보처리방침에 고지해야 합니다.<br/>
        //                         또한 그 이용 목적에 따라 회원에게 처리 위탁 또는 제3자 제공에 대한 별도 동의를 받아야 할 수도 있습니다.<br/><br/>

        //                         <b>※ 앱을 설치하면, 앱의 유지보수 및 계산서 발행 등의 사유로 아래와 같은 정보가 추가로 개발사에 제공됩니다.</b><br/>
        //                         제공 정보 항목 : 쇼핑몰명, 대표자명, 고객센터전화번호, 주소, 사업자번호, 통신판매신고번호.<br/><br/>

        //                     </div>
        //                     <div style={{fontWeight:"normal", fontSize:"14px"}}>
        //                         상기 내용의 계약체결을 증명하기 위해 계약서를 2통 작성하여 '갑' 과 '을' 이 기명날인한 후 각 1부씩 보관키로 한다.
        //                     </div>
        //                     <h3 className="text-center m-t-lg m-b">
        //                     <span id="todays_year"></span>&nbsp;{date.getFullYear()}년&nbsp;&nbsp;&nbsp;&nbsp;<span id="todays_mon"></span>&nbsp;{(date.getMonth()+1)}월&nbsp;&nbsp;&nbsp;&nbsp;<span id="todays_day"></span>&nbsp;{date.getDate()}일</h3>
        //                     <span>이용약관에 동의하십니까?</span>
        //                     <div className="pull-right">
        //                         <input id="agree_check" type="checkbox" onChange={this.onClickAgreeCheck}/>
        //                         <b><label>동의함</label></b>
        //                     </div>
        //                     <br/><br/>
        //                     <button onClick={this.onClickAgreeButton} className="btn btn-primary block full-width m-b">다음</button>&nbsp;
        //                 </div>
        //             </>
        //     )
        // } else {
        //     return (<></>)
        // }

    }
}

export default withTranslation()(TermsCndtnForm);

import React from "react";
import $ from 'jquery';

/**
 * button_color : 버튼 기본 컬러, null 이면 기본값 적용
 * button_hover_color : 버튼 hover시 컬러, null 이면 기본값 적용
 * content : content2가 있으면 제목으로 사용, content2가 없으면 내용으로 사용 (폰트 굵기는 제목, 내용 상관없이 똑같음)
 * content2 : 제목과 내용을 따로 입력 해야 할 때 내용으로 사용 (content 보다 얇은 굵기의 폰트로 사용.)
 */
class SaveDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            button_color: '#00e2e6',
            button_hover_color: '#00d3e0',
        }
    }

    componentWillMount() {
        if(this.props.button_color != null && this.props.button_color != ''){
            this.setState({button_color:this.props.button_color,})
        }
        if(this.props.button_hover_color != null && this.props.button_hover_color != ''){
            this.setState({button_hover_color:this.props.button_hover_color,})
        }
    }

    componentDidMount() {
        let this_component = this;
        $(document).ready(function(){
            $("#common_alert_dialog_btn").hover(function(){
                //요소에 마우스를 올렸을 때
                $(this).css('background-color',this_component.state.button_hover_color);
            },function(){
                //요소에 마우스를 올린뒤 떨어졌을때
                $(this).css('background-color',this_component.state.button_color);
            });
        });

    }

    render () {

        return (
            <>
                {/* 저장 다이얼로그 */}
                <div class="common_dialog">
                    <div class="dialog_insert">
                        {
                            this.props.content2 != null && this.props.content2 != ""
                                ?
                                <>
                                    <div class="dialog_save_icn">
                                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="23.5" cy="23.5" r="18.5" fill="#333333"/>
                                            <path d="M23.5 4C19.6433 4 15.8731 5.14366 12.6664 7.28634C9.45963 9.42903 6.96027 12.4745 5.48436 16.0377C4.00845 19.6008 3.62228 23.5216 4.3747 27.3043C5.12711 31.0869 6.9843 34.5615 9.71143 37.2886C12.4386 40.0157 15.9131 41.8729 19.6958 42.6253C23.4784 43.3777 27.3992 42.9916 30.9623 41.5157C34.5255 40.0397 37.571 37.5404 39.7137 34.3336C41.8564 31.1269 43 27.3567 43 23.5C42.9901 18.3313 40.9325 13.3772 37.2776 9.72237C33.6228 6.06755 28.6687 4.00991 23.5 4ZM32.7813 20.0875L21.7938 30.5875C21.5097 30.8547 21.1337 31.0024 20.7438 31C20.5531 31.0027 20.3639 30.9677 20.1869 30.8969C20.0099 30.8261 19.8487 30.7209 19.7125 30.5875L14.2188 25.3375C14.0664 25.2046 13.9425 25.0422 13.8544 24.8601C13.7664 24.6781 13.7161 24.4801 13.7066 24.2781C13.697 24.0761 13.7284 23.8743 13.7988 23.6847C13.8693 23.4952 13.9773 23.3218 14.1165 23.1751C14.2556 23.0283 14.423 22.9113 14.6085 22.8309C14.7941 22.7505 14.994 22.7084 15.1962 22.7072C15.3984 22.7061 15.5988 22.7458 15.7852 22.824C15.9717 22.9023 16.1404 23.0174 16.2813 23.1625L20.7438 27.4188L30.7188 17.9125C31.0104 17.658 31.3892 17.5262 31.7758 17.5445C32.1624 17.5628 32.527 17.7298 32.7933 18.0107C33.0597 18.2915 33.2071 18.6645 33.2049 19.0515C33.2026 19.4386 33.0508 19.8098 32.7813 20.0875Z" fill="#00E2E6"/>
                                        </svg>
                                    </div>
                                    <div class="dialog_insert_txt dialog_save_txt" dangerouslySetInnerHTML={{__html: this.props.content}} style={{padding: "48px 0px 0px 0px"}}>
                                    </div>
                                    <div className="dialog_insert_content" dangerouslySetInnerHTML={{__html: this.props.content2}} style={{padding: "8px 0px 48px 0px"}}>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="dialog_save_icn">
                                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="23.5" cy="23.5" r="18.5" fill="#333333"/>
                                            <path d="M23.5 4C19.6433 4 15.8731 5.14366 12.6664 7.28634C9.45963 9.42903 6.96027 12.4745 5.48436 16.0377C4.00845 19.6008 3.62228 23.5216 4.3747 27.3043C5.12711 31.0869 6.9843 34.5615 9.71143 37.2886C12.4386 40.0157 15.9131 41.8729 19.6958 42.6253C23.4784 43.3777 27.3992 42.9916 30.9623 41.5157C34.5255 40.0397 37.571 37.5404 39.7137 34.3336C41.8564 31.1269 43 27.3567 43 23.5C42.9901 18.3313 40.9325 13.3772 37.2776 9.72237C33.6228 6.06755 28.6687 4.00991 23.5 4ZM32.7813 20.0875L21.7938 30.5875C21.5097 30.8547 21.1337 31.0024 20.7438 31C20.5531 31.0027 20.3639 30.9677 20.1869 30.8969C20.0099 30.8261 19.8487 30.7209 19.7125 30.5875L14.2188 25.3375C14.0664 25.2046 13.9425 25.0422 13.8544 24.8601C13.7664 24.6781 13.7161 24.4801 13.7066 24.2781C13.697 24.0761 13.7284 23.8743 13.7988 23.6847C13.8693 23.4952 13.9773 23.3218 14.1165 23.1751C14.2556 23.0283 14.423 22.9113 14.6085 22.8309C14.7941 22.7505 14.994 22.7084 15.1962 22.7072C15.3984 22.7061 15.5988 22.7458 15.7852 22.824C15.9717 22.9023 16.1404 23.0174 16.2813 23.1625L20.7438 27.4188L30.7188 17.9125C31.0104 17.658 31.3892 17.5262 31.7758 17.5445C32.1624 17.5628 32.527 17.7298 32.7933 18.0107C33.0597 18.2915 33.2071 18.6645 33.2049 19.0515C33.2026 19.4386 33.0508 19.8098 32.7813 20.0875Z" fill="#00E2E6"/>
                                        </svg>
                                    </div>
                                    <div className="dialog_insert_txt dialog_save_txt" dangerouslySetInnerHTML={{__html: this.props.content}}>
                                    </div>
                                </>

                        }
                        <button id="common_alert_dialog_btn" style={{backgroundColor:this.state.button_color}}
                                onClick={this.props.button_list[0].event_method} onKeyDown={e => (e.key=='Enter' ? this.props.button_list[0].event_method : "")} autoFocus={true}>
                            {this.props.button_list[0].title}
                        </button>
                    </div>
                </div>
            </>
        );
    }
}

export default SaveDialog;
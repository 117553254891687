import React from 'react';

import '../css/style.css';
//import SelectJoinForm from './SelectJoinForm';
import TermsCndtnForm from './TermsCndtnForm_v2';
import SignupForm from './SignupForm_v2';
import logo4 from "../resources/img/icn_logo_v2.svg";
import logo5 from "../resources/img/logo_v2_white.svg";
import login_rouding_background_1 from "../resources/img/login_rouding_background_1.png";
import login_rouding_background_2 from "../resources/img/login_rouding_background_2_1.png";
import Slider from "react-slick";
import * as StringUtil from './modules/utils/StringUtil';
import {Link, NavLink} from "react-router-dom";
import queryString from 'query-string';

import { withTranslation } from "react-i18next";
import {ga4react, reactPixel} from './Menu'


function getUrlParams() {
    var params = {};
    window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(str, key, value) { params[key] = value; });
    return params;
}

export class RegistUser extends React.Component {
	constructor(props) {
        super(props);
		this.state = {
			email: getUrlParams().email,
			mall_id: getUrlParams().mall_id,
			service_type: window.location.pathname.indexOf("/productDetailDesign/worldBeer/registUser") >= 0 ? "01011" : getUrlParams().service_type, // 월드비어 상세디자인 URI인 경우에는 01011
			//user_join_cls: 'cafe24',
			pass_yn : false,
			agree_yn : false,
			form_agree_list : [],
			step: 1,
			setting_login_slider : {
				// variableWidth:true,
				dots: true,
				infinite: true,
				speed: 300,
				slidesToShow: 1,
				slidesToScroll: 1,
				// autoplay : true,
				autoplay : true,
				responsive: [ // 반응형 웹 구현 옵션
					// {  
					// 	breakpoint: 960, //화면 사이즈 960px
					// 	settings: {
					// 		//위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
					// 		slidesToShow:5 
					// 	} 
					// },
					// { 
					// 	breakpoint: 639, //화면 사이즈 639px
					// 	settings: {	
					// 		//위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
					// 		slidesToShow:4,
					// 		slidesToScroll: 4
					// 	} 
					// }
				],
				customPaging: i => (
					<div className="ft-slick__dots--custom">
						<div className="login-slider-custum-dot" />
					</div>
				)
			},
			non_members_robo_img_prompt : '', // 마케팅 로보 이미지 프롬프트 : 해당 값의 유무로 회원가입 후 처리를 다르게 진행
			non_members_robo_img_design_yn : '', // 디자인 마케팅 로보 이미지 페이지 여부
		}
    }
	
	componentWillMount(){
		// 페이지 주소에 roboimage 관련 값이 들어있다면
		const roboimage_prompt = StringUtil.getUrlParams("roboimage_prompt");
		const roboimage_design_yn = StringUtil.getUrlParams("roboimage_design_yn");
		if(roboimage_prompt){
			this.setState({
				non_members_robo_img_prompt : decodeURIComponent(roboimage_prompt),
				non_members_robo_img_design_yn : roboimage_design_yn,
			})
		}
		const regist_solution_type_cd = StringUtil.getUrlParams("regist_solution_type_cd");
		this.setState({regist_solution_type_cd : regist_solution_type_cd});
	}
	
	componentDidMount() {
		// cafe24 App Store에서 접근시 중복가입 방지용
		let param_mall_id = getUrlParams().mall_id;
		console.log("param_mall_id : " + param_mall_id);

		if (param_mall_id != '' && param_mall_id != undefined) {
			sessionStorage.setItem('mall_id', param_mall_id);
		}

		// if (param_service_type == "01001") {
		// 	this.setState({user_join_cls: 'cafe24'});
		// } else if (param_service_type == "01002") {
		// 	this.setState({user_join_cls: 'rp'});
		// } else if (param_service_type == "01003") {
		// 	this.setState({user_join_cls: 'kdeal'});
		// }
	}

	//가입유형 선택
	// onClickSelectJoinButton = (user_join_cls) => {
	// 	this.setState({user_join_cls: user_join_cls});
	// 	//** 가입약관 패스 (정식 약관 적용시 삭제) */
	// 	//this.setState({agree_yn: true});
	// }
	
	//약관동의 후 가입신청 페이지 출력
	onClickAgreeButton = (agree_check, form_agree_list) => {
		this.setState({
			agree_yn: agree_check,
			form_agree_list: form_agree_list
		});
	}

	// 서비스 소개서 다운로드
	serviceDownload = () => {
		// let pdf_url = 'https://s3.ap-northeast-2.amazonaws.com/otdeal.file/221115_%EB%A1%9C%EB%B3%B4MD+%EC%84%9C%EB%B9%84%EC%8A%A4%EC%86%8C%EA%B0%9C%EC%84%9C.pdf';
		let pdf_url = this.props.t('regist.service_intro_download_file');
		window.open(pdf_url);

		ga4react.event('[회원가입] 서비스 소개서 다운로드', '서비스 소개서 다운로드', '회원가입');
		reactPixel.trackCustom('[회원가입] 서비스 소개서 다운로드');
	}

	// 회사 소개서 다운로드
	// aboutUsDownload = () => {
	// 	let pdf_url = 'https://s3.ap-northeast-2.amazonaws.com/otdeal.file/robomd_documents/2022/%EC%95%84%EC%9D%B4%ED%81%B4%EB%A0%88%EC%9D%B4%EB%B8%8C_%ED%9A%8C%EC%82%AC%EC%86%8C%EA%B0%9C%EC%84%9C.pdf';
	// 	window.open(pdf_url);

	// 	ga4react.event('[회원가입] 회사 소개서 다운로드', '회사 소개서 다운로드', '회원가입');
	// 	ReactPixel.trackCustom('[회원가입] 회사 소개서 다운로드');
	// }

	render() {
		const { t } = this.props;
		
		return (
			<div id='non-page-wrapper mobile-non-page-wrapper'>
				{/* <Bookmark className="coolClass" href="/" title="ROBOMD" /> */}
				<div className="regist_area_v2 mobile-flex-wrap middle-box middle-box-mobile text-center" style={{display:"flex", maxWidth:"100%", height:"100vh"}}>
					<div className="login-50-box slick-no-blinker regist-50-box" style={{width: window.location.pathname.indexOf("/productDetailDesign/worldBeer/registUser") >= 0 ? "100%" : null}}>
						<div className="login-container" style={{minHeight: "0vh", position: "relative"}}>
							<div className="middle-box middle-box-v2 text-center animated fadeInDown loginscreen m-t">
								{
									window.location.pathname.indexOf("/productDetailDesign/worldBeer/registUser") >= 0 
									? <div style={{textAlign: "left",marginBottom: "24px"}}><img src="https://worldbeermarket.kr/img/main/main_logo.png" alt="" style={{height:"25px",marginLeft: "-10px;"}}/></div>
									: <>
										<h3 className="mobile_display_show" style={{display:"none"}}><a href="/home.html"><img src={logo4}/></a></h3>
										<div className='mobile_display_txt'>
										{
											this.state.step == 1
											?
												<div>{t('regist.step1_title1')}<br/>{t('regist.step1_title2')}</div>
											:	this.state.step == 2
												?	
													<div>{t('regist.step2_title')}</div>
												:	this.state.step == 3
													?	
														<div>{t('regist.step3_title')}</div>
													:	this.state.step == 4
														?	
															<div>
																부가 정보는 선택 사항이에요.
															</div>
													:	""
										}
										</div>
									</>
								}
								<div className="form_line">
									{
										this.state.step >= 1
										?
											<div style={{flex:"1", height:"6px", background:"#00e2e6"}}></div>
										:	
											<div style={{flex:"1", height:"6px", background:"#e3e3e3"}}></div>
									}
									{
										this.state.step >= 2
										?
											<div style={{flex:"1", height:"6px", background:"#00e2e6", marginLeft:"10px"}}></div>
										:	
											<div style={{flex:"1", height:"6px", background:"#e3e3e3", marginLeft:"10px"}}></div>
									}
									{
										this.state.step >= 3
										?
											<div style={{flex:"1", height:"6px", background:"#00e2e6", marginLeft:"10px"}}></div>
										:	
											<div style={{flex:"1", height:"6px", background:"#e3e3e3", marginLeft:"10px"}}></div>
									}
									{/* {
										this.state.step >= 4
										?
											<div style={{flex:"1", height:"6px", background:"#10CFC9", marginLeft:"10px"}}></div>
										:	
											<div style={{flex:"1", height:"6px", background:"#dee2e6", marginLeft:"10px"}}></div>
									} */}
								</div>

								{/* ****************************가입구분**************************** */}
								{/* <SelectJoinForm user_join_cls={this.state.user_join_cls} onClickSelectJoinButton={this.onClickSelectJoinButton} /> */}
								{/* ****************************이용약관**************************** */}
								<TermsCndtnForm this_parent={this} step={this.state.step} onClickAgreeButton={this.onClickAgreeButton} />
								{/* ****************************가입신청**************************** */}
								<SignupForm this_parent={this} 
										    email={this.state.email} 
											//user_join_cls={this.state.user_join_cls} 
											step={this.state.step} 
											agree_yn={this.state.agree_yn} 
											form_agree_list={this.state.form_agree_list}
											mall_id={this.state.mall_id}
											service_type={this.state.service_type}
											regist_solution_type_cd = {this.state.regist_solution_type_cd}
											non_members_robo_img_prompt = {this.state.non_members_robo_img_prompt}
											non_members_robo_img_design_yn = {this.state.non_members_robo_img_design_yn}
								/>
								<p className="m-t">
									{/* <small>OTDEAL © 2020</small> */}
									{
										window.location.pathname.indexOf("/productDetailDesign/worldBeer/registUser") >= 0 
										? <div style={{display:"flex", justifyContent:"center"}}>{t('regist.have_account')}&nbsp;&nbsp;<NavLink to="/common/productDetailDesign/worldBeer/login"><div style={{color:"#0090ff"}}>{t('regist.login')}</div></NavLink></div>
										: <div style={{display:"flex", justifyContent:"center"}}>{t('regist.have_account')}&nbsp;&nbsp;<NavLink to="/login"><div style={{color:"#0090ff"}}>{t('regist.login')}</div></NavLink></div>
									}
								</p>
								<div className='btn_wrap btn_wrap_m'>
									<button id="service_info_download_btn" class="login_download_btn" onClick={e => this.serviceDownload()}>{t('regist.service_intro_download')}</button>
								</div>
							</div>
						</div>
					</div>
					{ window.location.pathname.indexOf("productDetailDesign/worldBeer/registUser") < 0
					?
						<div className="login-50-box mobile_display_none" style={{width:"40%", backgroundColor:"#00e2e6", position:"relative", overflow:"hidden", minHeight: "100vh"}}>
							<div style={{position:"absolute", left:"0",top:"0"}}>
								<img style={{
									width:"714.29px", 
									height:"auto",
									marginLeft: "-357.145px",
									marginTop:"-357.145px",
								}} src={login_rouding_background_2}/>
							</div>
							<div style={{position:"absolute", right:"-18%",top:"9%"}}>
								<img style={{
									width:"714.29px", 
									height:"auto",
									marginRight: "-357.145px",
									// marginTop:"-357.145px",
								}} src={login_rouding_background_2}/>
							</div>
							<a href="/home.html">
								<div className="mobile-absolute-align-centet login_logo">
									<img style={{width:"205.31px", height:"auto"}} src={logo5} />
									<div style={{
												height:"22px",
												marginLeft: "12px",
												marginTop:"-5px",
												color: "#00e2e6",
												/* border: 1px solid #2cad82; */
												backgroundColor: "#ffffff",
												padding: "3px 5px 3px 5px",
												borderBottomLeftRadius: "10px",
												borderBottomRightRadius: "10px",
												borderTopRightRadius: "10px",
												borderTopLeftRadius: "10px",
												fontSize: "11px"
												}} >
										BETA
									</div>
								</div>
							</a>
							<div className="mobile-absolute-align-centet mobile-absolute-top-27 login-slider-wrap">
								<Slider {...this.state.setting_login_slider}
									arrows={false}
									className="login-slider" 
									>
										<div className="slick-category">
											<div className='slider_lt'>
												{t('regist.side_slider_title1')}
											</div>
											<div className="mobile-margin-bottom-10 slider_st">
												{t('regist.side_slider_content1')}
											</div>
										</div>	
										<div className="slick-category">
											<div className='slider_lt'>
												{t('regist.side_slider_title2')}
											</div>
											<div className="mobile-margin-bottom-10 slider_st">
												{t('regist.side_slider_content2')}
											</div>
										</div>	
										<div className="slick-category">
											<div className='slider_lt'>
												{t('regist.side_slider_title3')}
											</div>
											<div className="mobile-margin-bottom-10 slider_st">
												{t('regist.side_slider_content3')}
											</div>
										</div>	
								</Slider>
								<div className='btn_wrap btn_wrap_p'>
									<button id="service_info_download_btn" class="login_download_btn" onClick={e => this.serviceDownload()}>{t('regist.service_intro_download')}</button>
								</div>
							</div>
							{/* <div className="btn-login-manual" style={{position:"absolute", bottom:"35px", right:"25px"}}>
								<a href="https://www.notion.so/MD-b5021d4836b84f689097c6f2350521ac" target="blank">
									<div style={{color:"white", fontWeight:"bold"}}>로보MD 이용 가이드</div>
								</a>
							</div> */}
						</div>
					: ""
					}
				</div>
			</div>
		);
	}
	
}

export default withTranslation()(RegistUser);
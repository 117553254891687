import http from '../modules/api';
import axios from 'axios';

// 선택된 멀티샵의 서비스 활성화
const shopActiveService = async () => {
    let shopData = {
    // solution_type_cd: solution_type_cd,
    // shop_no: shop_no,
    service_type: "01005"
    }
    const config = {
    headers: {
        'content-type': 'application/json;',
    },
    };
    await axios.post('/rest/shop/activeService', shopData, config);
}

// 서비스 신청 버튼 클릭
export function onClickShopActiveServiceButton() {
    let data = {
        service_type: "01005"
    };
    const config = {
        headers: {
            'content-type': 'application/json;',
        },
    };
    // 1단계 : 무료로 이용하기 (사용자 서비스 추가, 권한 생성)
    http
    .post('/rest/shop/activeUserServiceSave', data, config)
    .then((res) => {
        const { data } = res;
        if (data.code == "200") {
            // 서비스 추가
            shopActiveService();

            // 페이지로 이동
            setTimeout(
            () => {
                    window.location.href = '/fb/trendall';
            }, 500);
        } else {
            alert(
                '[code : ' +
                data.code +
                ']' +
                '\n관리자에게 문의해 주세요. (1599-3518)',
            );
        }
    })
    .catch((error) => {
        alert('서비스 요청 실패' + '\n관리자에게 문의해 주세요. (1599-3518)');
    }); 
}
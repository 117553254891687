import React, { useCallback, useState } from 'react';
import RpProductListPopup from '../modules/popup/RpProductListPopup';

import ShareLink from 'react-facebook-share-link'
import $ from 'jquery';

export class TestRpProductPopup extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
            product_list: [],
            rp_product_list_dialog_show_state : false,
            text: "열었습니다."
        }
    }

    componentWillMount() {
        // (function(d, s, id) {
        //     var js, fjs = d.getElementsByTagName(s)[0];
        //     if (d.getElementById(id)) return;
        //     js = d.createElement(s); js.id = id;
        //     js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0";
        //     fjs.parentNode.insertBefore(js, fjs);
        //     }(document, 'script', 'facebook-jssdk'));
    }

    componentDidMount() {
        
    }

    rpProductListPopupOpen(text) {
        this.setState({
            rp_product_list_dialog_show_state: true,
            text: text
        })
    }

    rpProductListPopupClose(text) {
        this.setState({
            rp_product_list_dialog_show_state: false,
            text: text
        });
    }



    render() {
        return (
            <>
                <div id='common-page-wrapper'>
                    {
                        this.state.rp_product_list_dialog_show_state
                        ?    <RpProductListPopup
                            iframe_page_id={"robomd_main_page"}
                            iframe_html_id={"robomd_rp_1"}
                            // popup_close={() => {this.setState({rp_product_list_dialog_show_state: false});}}
                            popup_close={this.rpProductListPopupClose.bind(this)}
                            />
                        :   ""
                    }
                    <button onClick={e=>this.rpProductListPopupOpen("열었습니다.")}>{this.state.text}</button>
                    <ShareLink link='https://robomd.io'>
                    {link => (
                        <img src="https://w.namu.la/s/475da918f355850479ae4c0703cf4a70e2911a4df7b96f86396a2e30f5cbd3ff29b1b4fed7cbcc2854a27ddff6258cabb8c720321c5bbdac6e0357fbb8628797f726ea6514b1089b2e95950558cd413c6859eae64f8ad1b95bf1a11edba27615" 
                        style={{width:"40px", height:"auto"}}
                        onClick={(e) => {
                            window.open(link, '_blank', 'width=800, height=600')
                        }} />
                    )}
                    </ShareLink>
                </div>
            </>
        )
    }
}

export default TestRpProductPopup;
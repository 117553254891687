import React from "react";
import $ from 'jquery';

/**
 * 도넛 차트
 * 값이 0인 경우에는 차트에 표시되지 않습니다.
 * list : List<map> 형태의 원본 리스트 그대로 사용.
 * listTextName : list에서 option의 text로 사용 할 변수명
 * listValueName : list에서 option의 값으로 사용 할 변수명 : 값은 숫자만 있어야 함
 * title : 차트 이름, null이면 해당 영역 없이 출력
 */

class DonutChart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            chart_color : ["#00e2e6","#0090ff","#996cfc","#ff6190","#00979a","#143DB4","#A00051","#D5C2FD","#7DEDA1","#8C9DAD"],
    		height: "256px",
            data : [],
            total_value : 0,
        }
    }

    componentDidUpdate = (prevProps, prevState) => { //componentDidUpdate가 props의 변과를 감지한다
        if (JSON.stringify(this.props.list) !== JSON.stringify(prevProps.list)) { // 하위컴포넌트가 받은 props값 비교
            let list = JSON.parse(JSON.stringify(this.props.list));
            for(var i=0;i<list.length;i++){
                list[i].value = list[i][this.props.listValueName];
            }

            let this_component = this;
            $(".donut_chart").find(".donut").addClass("donut_chart_zoom_out").animate({opacity:0},200,function(){
                $(this).removeClass("donut_chart_zoom_out")
                this_component.calculteDonut(list);
                $(this).addClass("donut_chart_zoom_in").animate({opacity:1},200,function(){$(this).removeClass("donut_chart_zoom_in");});
            });
        }
    };

    // 도넛 비율 계산
    calculteDonut(list){
        var r = 20, cx = 34, cy = 30;
        var text_r = r + 14;
        var perimeter = 2 * r * Math.PI;
        var total = 0;
        var data = [...list];

        data.map(d => {
            d.temp = total;
            total += Number(d.value);
        });
        data.map(d => {
            d.offset = map(d.temp, 0, total, 0, perimeter);
            d.real_value = map(d.value, 0, total, 0, perimeter);
            d.dashArray = `${d.real_value},${perimeter}`;
    
            let angleStart = -d.offset/r;
            let angleEnd = (d.offset + d.real_value)/r;
            d.angleMiddle = (angleEnd - angleStart)/2;
    
            let t = {};
            t.props = {
                x : cx + 1.35 * text_r * Math.cos(d.angleMiddle),
                y : cy + 1.0 * text_r * Math.sin(d.angleMiddle),
            }
            
            let tText = d[this.props.listTextName];
            if(tText.length >= 10){
                d[this.props.listTextName] = tText.slice(0, 10) + "...";
            } else if(t.props.x < 40){
                if(tText.length < 4){
                    if(t.props.x < 0){
                        t.props = {
                            x : cx + 1.35 * (text_r-12) * Math.cos(d.angleMiddle),
                            y : cy + 1.0 * text_r * Math.sin(d.angleMiddle),
                        }
                    } else{
                        t.props = {
                            x : cx + 1.35 * (text_r-16) * Math.cos(d.angleMiddle),
                            y : cy + 1.0 * text_r * Math.sin(d.angleMiddle),
                        }
                    }
                } else if(tText.length < 7){
                    if(t.props.x < 0){
                        t.props = {
                            x : cx + 1.35 * (text_r-6) * Math.cos(d.angleMiddle),
                            y : cy + 1.0 * text_r * Math.sin(d.angleMiddle),
                        }
                    } else{
                        t.props = {
                            x : cx + 1.35 * (text_r-10) * Math.cos(d.angleMiddle),
                            y : cy + 1.0 * text_r * Math.sin(d.angleMiddle),
                        }
                    }
                }
            }
            d.text_point = t;
            
            let l = {
                x1 : cx + r * Math.cos(d.angleMiddle),
                y1 : cy + r * Math.sin(d.angleMiddle),
                x2 : cx + .9 * text_r * Math.cos(d.angleMiddle),
                y2 : cy + .9 * text_r * Math.sin(d.angleMiddle),
            }
            d.line = l;
        });
    
        function map(n, a, b, _a, _b){
            let d = b - a;
            let _d = _b - _a;
            let u = _d / d;
            return _a + n * u;
        }

        this.setState({
            data : data,
            total_value : total,
        });
    }

	render () {
		return (
			<>
                <div class="donut_chart" style={{display: 'flex', opacity: 1, height: this.state.height}}>
                    <svg viewBox="0 0 68 68" class="donut" style={{opacity: 1}}>
                        {this.state.total_value !=0
                        ?<defs>
                            <circle id="donut" r="20" cx="34" cy="30"></circle>
                        </defs>
                        :""}
                        <g id="lines">
                        {this.state.data.map((obj, idx) => (
                            Number(obj.value) != 0
                            ?<>
                                <line x1={obj.line.x1} y1={obj.line.y1} x2={obj.line.x2} y2={obj.line.y2}></line>
                            </>
                            : ""
                        ))}
                        </g>
                        <g id="chart">
                            {this.state.data != null && this.state.data.length > 0 && this.state.data.map((obj, idx) => (
                            <>
                                <use xlinkHref="#donut" stroke-dasharray={obj.dashArray} stroke-dashoffset={-obj.offset} stroke={this.state.chart_color[idx]}></use>
                            </>
                            ))}
                        </g>
                        <g id="text">
                            {this.state.data != null && this.state.data.length > 0 && this.state.data.map((obj, idx) => (
                                Number(obj.value) != 0
                                ?<>
                                    <text x={obj.text_point.props.x} y={obj.text_point.props.y} dx="-3.5em" dy="-0.2em">{obj[this.props.listTextName]}</text>
                                </>
                                : ""
                            ))}
                        </g>
                        <g id="tspan">
                            {this.state.data != null && this.state.data.length > 0 && this.state.data.map((obj, idx) => (
                                Number(obj.value) != 0
                                ?<>
                                    <text x={obj.text_point.props.x} y={obj.text_point.props.y} dx="-3.5em" dy="1.2em">{obj[this.props.listValueName]}</text>
                                </>
                                : ""
                            ))}
                        </g>
                    </svg>
                </div>
                <h5 class="chart_title">{this.props.title}</h5>
                <div class="chart_name_area">
                    {this.state.data.map((obj, idx) => (
                    <>
                        <div class="chart_name" style={{opacity: 1}}>
                            <div style={{backgroundColor:this.state.chart_color[idx]}}></div>
                            <span>{obj[this.props.listTextName]}</span>
                        </div>
                    </>
                    ))}
                </div>
			</>
		);
	}
}

export default DonutChart;
import React from 'react';
import http from '../modules/api';
// 스타일
import styles from './targetViewList.module.css';

// 컴포넌트
import Button from '../modules/common/Button';
import CheckBox from '../modules/common/CheckBox';
import ConfirmDialogV2 from '../modules/dialogs/ConfirmDialogV2';
import Icon from '../modules/common/Icon';

export class TargetViewList extends React.Component {
    state = {
        inputVal: "", // 검색값: string
        show_del_popup_frame: false, // 선택 삭제 팝업 보기/숨기기: true/false
        show_design_popup_frame: false, // 적용중인 디자인 보기 팝업 보기/숨기기: true/false
        list: [], // 타겟 목록
        check_list: [], // 선택된 타겟 목록
        design_list: [], // 적용중인 디자인 목록
        search_title : "",
        order_gubun: "", // 순서 구분
        order_type: "", // 순서 타입
    }

    componentDidMount = () => {
        this.loadList();
    }

    componentDidUpdate = () => {
        if(this.state.show_design_popup_frame
            || this.state.show_del_popup_frame) {
            document.body.style.overflow = 'hidden'; // 스크롤 고정
        } else {
            document.body.style.overflow = 'auto'; // 스크롤 고정 해제
        }
    }

    loadList = () => {
		http.get(`/rest/shop/targetview/list?title=${this.state.search_title}&order_gubun=${this.state.order_gubun}&order_type=${this.state.order_type}`)
		.then(res => {
            if(res.data.code == "200"){
                this.setState({
                    list: res.data.target_view_list,
                    check_list : [],
                });
            }
        })
        .catch(error => {
            alert("타겟 목록을 불러오는데 실패하였습니다.");
        });
    }

    loadEventSettingList = (target_view_seq) =>{
		http.get(`/rest/shop/targetview/applyDesign/list?target_view_seq=${target_view_seq}`)
		.then(res => {
            this.setState({design_list : res.data.target_view_apply_design_list})
        })
        .catch(error => {
            alert("타겟 목록을 불러오는데 실패하였습니다.");
        });
    }

    /** 타겟뷰 세팅으로 이동 */
    goTargetViewSetting = (target_view_seq) => {
        if(target_view_seq){
            this.props.history.push(`/shop/targetview/setting?target_view_seq=${target_view_seq}`)
        } else{
            this.props.history.push("/shop/targetview/setting")
        }
    }

    // 삭제 이벤트
    handleRomove = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        let body = {
            target_view_seq_list : this.state.check_list.map(obj => obj.target_view_seq),
        }
        
        http.post("/rest/shop/targetview/list/delete", body, config)
        .then(res => {
            if(res.data.code=="200"){
                this.handleDelPopupFrame();
                this.loadList();
            }
        })
        .catch(error => {
            console.log (error);
        });
    };

    // 복제 이벤트
    handleClone = (target_view_seq) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        let body = {
            target_view_seq : target_view_seq,
        }
        
        http.post("/rest/shop/targetview/clone", body, config)
        .then(res => {
            if(res.data.code=="200"){
                alert("타겟 설정이 복제되었습니다.");
                this.loadList();
            } else{
                alert("타겟 설정 복제에 실패했습니다.");
            }
        })
        .catch(error => {
            alert("타겟 설정 복제에 실패했습니다.");
        });
    };

    /** 검색값 입력 */
    handleSearchChange = (e) => {
        this.setState({search_title: e.target.value});
    }

    /** 검색값 입력 Enter 키로 */
    handleSearchChangeEnter = (e) => {
        const key = e.key;
        if (key === "Enter") {
            this.loadList();
        }
    }

    /** 검색 버튼 클릭 */
    handleSearchClick = () => {
        this.loadList();
    }

    /** 선택 삭제 버튼 클릭 */
    handleClickOnDel = () => {
        if(this.state.check_list.length > 0){
            this.handleDelPopupFrame();
        }
    }

    /** 선택 삭제 팝업 보기 옵션 */
    handleDelPopupFrame = () => {
        this.setState({show_del_popup_frame : !this.state.show_del_popup_frame});
    }

    /** 적용중인 디자인 보기 팝업 보기 옵션 */
    handlDesignPopupFrame = (target_view_seq) => {
        if (this.state.show_design_popup_frame) {
            this.setState({
                show_design_popup_frame: !this.state.show_design_popup_frame,
                design_list : [],
            });
        } else {
            this.loadEventSettingList(target_view_seq)
            this.setState({show_design_popup_frame: !this.state.show_design_popup_frame});
        }
    }

    /** 체크박스 전체 선택 */
    allCheckboxChange = (e) => {
        let {list} = this.state;
        const checked = e.target.checked;

        if(checked){
            this.setState({ check_list : JSON.parse(JSON.stringify(list)) });
        } else{
            this.setState({ check_list : [] });
        }
    }

    /** 체크박스 선택 */
    checkboxChange = (e, value) => {
        let {list, check_list} = this.state;
        const checked = e.target.checked;

        if(checked){ // 체크되는 상태라면 기존 체크 리스트에 값 추가
            this.setState({ check_list : [...check_list, list.find(row => row.target_view_seq === value)] });
        } else{ // 체크가 해제되는 상태라면 기존 체크 리스트에서 값 제거
            this.setState({ check_list : check_list.filter(row => row.target_view_seq !== value) });
        }
    }

    handleOrder = (e) => {
        this.setState({
            order_gubun: e.target.id.split("-").shift(),
            order_type: e.target.id.split("-").pop()
        }, () => {
            this.loadList();
        });
    }

    render() {
        return (
            <>
                <div id="common-page-wrapper" style={{backgroundColor: "#F4F7F9"}}>
                    <div className={styles.wrap}>
                        <div className={styles.title}>
                            <div className={styles.title_text}>
                                <h2>타겟 목록</h2>
                                <h3>나의 주요 타겟을 한 눈에 확인하고, 간편하게 관리해 보세요.</h3>
                            </div>
                            {
                                this.state.list.length !== 0
                                ? <Button type={"hover_mint"} width={"143px"} onClick={() => this.goTargetViewSetting()}>
                                    <svg className={styles.btn_svg} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 8C14 8.13261 13.9473 8.25979 13.8536 8.35355C13.7598 8.44732 13.6326 8.5 13.5 8.5H8.5V13.5C8.5 13.6326 8.44732 13.7598 8.35355 13.8536C8.25979 13.9473 8.13261 14 8 14C7.86739 14 7.74021 13.9473 7.64645 13.8536C7.55268 13.7598 7.5 13.6326 7.5 13.5V8.5H2.5C2.36739 8.5 2.24021 8.44732 2.14645 8.35355C2.05268 8.25979 2 8.13261 2 8C2 7.86739 2.05268 7.74021 2.14645 7.64645C2.24021 7.55268 2.36739 7.5 2.5 7.5H7.5V2.5C7.5 2.36739 7.55268 2.24021 7.64645 2.14645C7.74021 2.05268 7.86739 2 8 2C8.13261 2 8.25979 2.05268 8.35355 2.14645C8.44732 2.24021 8.5 2.36739 8.5 2.5V7.5H13.5C13.6326 7.5 13.7598 7.55268 13.8536 7.64645C13.9473 7.74021 14 7.86739 14 8Z" fill="#333333"/></svg>
                                    <div className={styles.btn_txt}>타겟 설정하기</div>
                                </Button> : <></>
                            }
                        </div>
                        <div className={styles.content}>
                            <div className={styles.content_title}>
                                <div className={styles.search_bar}>
                                    <input value={this.state.search_title} onChange={this.handleSearchChange} onKeyDown={e => this.handleSearchChangeEnter(e)} type='text' id="search-by-title" placeholder='제목으로 검색해 보세요.'/>
                                    <button>
                                        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9453 17.0547L14.5625 13.6797C15.7564 12.2583 16.3555 10.4308 16.2348 8.57844C16.114 6.72607 15.2828 4.99182 13.9146 3.73738C12.5463 2.48294 10.7465 1.80518 8.89063 1.84545C7.03477 1.88571 5.2661 2.6409 3.9535 3.9535C2.6409 5.2661 1.88571 7.03477 1.84545 8.89063C1.80518 10.7465 2.48294 12.5463 3.73738 13.9146C4.99182 15.2828 6.72607 16.114 8.57844 16.2348C10.4308 16.3555 12.2583 15.7564 13.6797 14.5625L17.0547 17.9453C17.1741 18.0608 17.3338 18.1254 17.5 18.1254C17.6662 18.1254 17.8258 18.0608 17.9453 17.9453C18.0627 17.8268 18.1285 17.6668 18.1285 17.5C18.1285 17.3332 18.0627 17.1732 17.9453 17.0547ZM3.125 9.0625C3.125 7.88817 3.47323 6.74022 4.12565 5.7638C4.77807 4.78738 5.70538 4.02636 6.79032 3.57696C7.87525 3.12757 9.06909 3.00999 10.2208 3.23909C11.3726 3.46819 12.4306 4.03368 13.2609 4.86405C14.0913 5.69443 14.6568 6.75239 14.8859 7.90415C15.115 9.05591 14.9974 10.2497 14.548 11.3347C14.0986 12.4196 13.3376 13.3469 12.3612 13.9993C11.3848 14.6518 10.2368 15 9.0625 15C7.48841 14.9979 5.97938 14.3717 4.86634 13.2587C3.75329 12.1456 3.12707 10.6366 3.125 9.0625Z" fill="#cccccc"/></svg>
                                    </button>
                                </div>
                                <Button type={"hover_grey"} width={"56px"} height={"36px"} fontSize={"13px"} onClick={this.handleSearchClick}>검색</Button>
                                <Button type={"hover_white"} width={"83px"} height={"36px"} fontSize={"13px"} onClick={this.handleClickOnDel}>선택 삭제</Button>
                            </div>
                            <div className={styles.list_wrap}>
                                <table className={styles.list} style={{width:"100%"}}>
                                    <thead>
                                        <tr>
                                            <th>
                                                <CheckBox
                                                    id={"check-all-btn"}
                                                    checked={(this.state.check_list.length != 0) && (this.state.check_list.length === this.state.list.length)}
                                                    onChangeCheck={e => this.allCheckboxChange(e)}
                                                />
                                            </th>
                                            <th>번호</th>
                                            <th>
                                                <div className={styles.list_sort}>
                                                    <div>제목</div>
                                                    <input onChange={e => this.handleOrder(e)} type="radio" name="order" id="title-asc" style={{display: "none"}}/>
                                                    <label htmlFor='title-asc'>
                                                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.50001 3.37318C6.59941 3.37453 6.69471 3.41299 6.7672 3.481L10.5172 7.231C10.577 7.30386 10.6076 7.39636 10.6029 7.4905C10.5983 7.58464 10.5588 7.6737 10.4922 7.74035C10.4255 7.807 10.3365 7.84648 10.2423 7.85111C10.1482 7.85573 10.0557 7.82517 9.98283 7.76537L6.50001 4.27787L3.0172 7.76537C2.94434 7.82517 2.85184 7.85573 2.7577 7.8511C2.66355 7.84648 2.57449 7.807 2.50784 7.74035C2.44119 7.6737 2.40172 7.58464 2.39709 7.4905C2.39247 7.39635 2.42303 7.30386 2.48283 7.231L6.23283 3.481C6.30532 3.41299 6.40062 3.37453 6.50001 3.37318Z"/></svg>
                                                    </label>
                                                    <input onChange={e => this.handleOrder(e)} type="radio" name="order" id="title-desc" style={{display: "none"}}/>
                                                    <label htmlFor='title-desc'>
                                                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.49999 8.62682C6.40059 8.62547 6.30529 8.58701 6.2328 8.519L2.4828 4.769C2.423 4.69614 2.39244 4.60364 2.39706 4.5095C2.40169 4.41536 2.44116 4.3263 2.50781 4.25965C2.57447 4.193 2.66352 4.15352 2.75767 4.14889C2.85181 4.14427 2.94431 4.17483 3.01717 4.23463L6.49999 7.72213L9.9828 4.23463C10.0557 4.17483 10.1482 4.14427 10.2423 4.14889C10.3364 4.15352 10.4255 4.193 10.4922 4.25965C10.5588 4.3263 10.5983 4.41536 10.6029 4.5095C10.6075 4.60364 10.577 4.69614 10.5172 4.769L6.76717 8.519C6.69468 8.58701 6.59938 8.62547 6.49999 8.62682Z"/></svg>
                                                    </label>
                                                </div>
                                            </th>
                                            <th>
                                                <div className={styles.list_sort}>
                                                    <div>등록일</div>
                                                    <input onChange={e => this.handleOrder(e)} type="radio" name="order" id="reg_date-asc" style={{display: "none"}}/>
                                                    <label htmlFor='reg_date-asc'>
                                                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.50001 3.37318C6.59941 3.37453 6.69471 3.41299 6.7672 3.481L10.5172 7.231C10.577 7.30386 10.6076 7.39636 10.6029 7.4905C10.5983 7.58464 10.5588 7.6737 10.4922 7.74035C10.4255 7.807 10.3365 7.84648 10.2423 7.85111C10.1482 7.85573 10.0557 7.82517 9.98283 7.76537L6.50001 4.27787L3.0172 7.76537C2.94434 7.82517 2.85184 7.85573 2.7577 7.8511C2.66355 7.84648 2.57449 7.807 2.50784 7.74035C2.44119 7.6737 2.40172 7.58464 2.39709 7.4905C2.39247 7.39635 2.42303 7.30386 2.48283 7.231L6.23283 3.481C6.30532 3.41299 6.40062 3.37453 6.50001 3.37318Z"/></svg>
                                                    </label>
                                                    <input onChange={e => this.handleOrder(e)} type="radio" name="order" id="reg_date-desc" style={{display: "none"}}/>
                                                    <label htmlFor='reg_date-desc'>
                                                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.49999 8.62682C6.40059 8.62547 6.30529 8.58701 6.2328 8.519L2.4828 4.769C2.423 4.69614 2.39244 4.60364 2.39706 4.5095C2.40169 4.41536 2.44116 4.3263 2.50781 4.25965C2.57447 4.193 2.66352 4.15352 2.75767 4.14889C2.85181 4.14427 2.94431 4.17483 3.01717 4.23463L6.49999 7.72213L9.9828 4.23463C10.0557 4.17483 10.1482 4.14427 10.2423 4.14889C10.3364 4.15352 10.4255 4.193 10.4922 4.25965C10.5588 4.3263 10.5983 4.41536 10.6029 4.5095C10.6075 4.60364 10.577 4.69614 10.5172 4.769L6.76717 8.519C6.69468 8.58701 6.59938 8.62547 6.49999 8.62682Z"/></svg>
                                                    </label>
                                                </div>
                                            </th>
                                            <th>
                                                <div className={styles.list_sort}>
                                                    <div>최근 수정일</div>
                                                    <input onChange={e => this.handleOrder(e)} type="radio" name="order" id="mod_date-asc" style={{display: "none"}}/>
                                                    <label htmlFor='mod_date-asc'>
                                                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.50001 3.37318C6.59941 3.37453 6.69471 3.41299 6.7672 3.481L10.5172 7.231C10.577 7.30386 10.6076 7.39636 10.6029 7.4905C10.5983 7.58464 10.5588 7.6737 10.4922 7.74035C10.4255 7.807 10.3365 7.84648 10.2423 7.85111C10.1482 7.85573 10.0557 7.82517 9.98283 7.76537L6.50001 4.27787L3.0172 7.76537C2.94434 7.82517 2.85184 7.85573 2.7577 7.8511C2.66355 7.84648 2.57449 7.807 2.50784 7.74035C2.44119 7.6737 2.40172 7.58464 2.39709 7.4905C2.39247 7.39635 2.42303 7.30386 2.48283 7.231L6.23283 3.481C6.30532 3.41299 6.40062 3.37453 6.50001 3.37318Z"/></svg>
                                                    </label>
                                                    <input onChange={e => this.handleOrder(e)} type="radio" name="order" id="mod_date-desc" style={{display: "none"}}/>
                                                    <label htmlFor='mod_date-desc'>
                                                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.49999 8.62682C6.40059 8.62547 6.30529 8.58701 6.2328 8.519L2.4828 4.769C2.423 4.69614 2.39244 4.60364 2.39706 4.5095C2.40169 4.41536 2.44116 4.3263 2.50781 4.25965C2.57447 4.193 2.66352 4.15352 2.75767 4.14889C2.85181 4.14427 2.94431 4.17483 3.01717 4.23463L6.49999 7.72213L9.9828 4.23463C10.0557 4.17483 10.1482 4.14427 10.2423 4.14889C10.3364 4.15352 10.4255 4.193 10.4922 4.25965C10.5588 4.3263 10.5983 4.41536 10.6029 4.5095C10.6075 4.60364 10.577 4.69614 10.5172 4.769L6.76717 8.519C6.69468 8.58701 6.59938 8.62547 6.49999 8.62682Z"/></svg>
                                                    </label>
                                                </div>
                                            </th>
                                            <th>적용 상태</th>
                                            <th>복제</th>
                                            {/* <th>전일방문자</th>
                                            <th>월방문자</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.list && this.state.list.length > 0
                                            ? this.state.list.map((row) => {
                                                return (
                                                    <tr className={styles.list_row} key={row.target_view_seq}>
                                                        <td>
                                                            <CheckBox
                                                                id={`check-btn-${row.target_view_seq}`}
                                                                checked={this.state.check_list.find(obj => obj.target_view_seq === row.target_view_seq) != null}
                                                                onChangeCheck={e => this.checkboxChange(e, row.target_view_seq)}
                                                            />
                                                        </td>
                                                        <td onClick={() => this.goTargetViewSetting(row.target_view_seq)}>{row.target_view_seq}</td>
                                                        <td onClick={() => this.goTargetViewSetting(row.target_view_seq)}>{row.title}</td>
                                                        <td onClick={() => this.goTargetViewSetting(row.target_view_seq)}>{row.reg_date_format}</td>
                                                        <td onClick={() => this.goTargetViewSetting(row.target_view_seq)}>{row.mod_date_format}</td>
                                                        <td className={styles.status} onClick={() => row.apply_yn !== "Y" && this.goTargetViewSetting(row.target_view_seq)}>{
                                                            row.apply_yn === "Y" 
                                                            ? <>
                                                                <div>적용중</div>
                                                                <Button 
                                                                    onClick={() => this.handlDesignPopupFrame(row.target_view_seq)}
                                                                    type={"hover_white"} 
                                                                    width={"82px"} 
                                                                    height={"25px"} 
                                                                    fontSize={"12px"}
                                                                >
                                                                    디자인 보기
                                                                </Button>
                                                            </>
                                                            : "미적용"
                                                        }</td>
                                                        <td>
                                                            <Icon onClick={() => this.handleClone(row.target_view_seq)} text={"복제"}>
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M16.875 2.5H6.875C6.70924 2.5 6.55027 2.56585 6.43306 2.68306C6.31585 2.80027 6.25 2.95924 6.25 3.125V6.25H3.125C2.95924 6.25 2.80027 6.31585 2.68306 6.43306C2.56585 6.55027 2.5 6.70924 2.5 6.875V16.875C2.5 17.0408 2.56585 17.1997 2.68306 17.3169C2.80027 17.4342 2.95924 17.5 3.125 17.5H13.125C13.2908 17.5 13.4497 17.4342 13.5669 17.3169C13.6842 17.1997 13.75 17.0408 13.75 16.875V13.75H16.875C17.0408 13.75 17.1997 13.6842 17.3169 13.5669C17.4342 13.4497 17.5 13.2908 17.5 13.125V3.125C17.5 2.95924 17.4342 2.80027 17.3169 2.68306C17.1997 2.56585 17.0408 2.5 16.875 2.5ZM12.5 16.25H3.75V7.5H12.5V16.25ZM16.25 12.5H13.75V6.875C13.75 6.70924 13.6842 6.55027 13.5669 6.43306C13.4497 6.31585 13.2908 6.25 13.125 6.25H7.5V3.75H16.25V12.5Z" fill="#333333"/>
                                                                </svg>
                                                            </Icon>
                                                        </td>
                                                        {/* <td onClick={() => this.goTargetViewSetting(row.target_view_seq)}>{row.dau_cnt}</td>
                                                        <td onClick={() => this.goTargetViewSetting(row.target_view_seq)}>{row.mau_cnt}</td> */}
                                                    </tr>
                                                )
                                            }) 
                                            : <tr className={styles.no_list}>
                                                <td colSpan={9}>
                                                    아직 등록된 타켓이 없어요.<br/>
                                                    타겟을 설정하고 나의 콘텐츠를 노출해 보세요.
                                                    <Button type={"hover_mint"} width={"143px"} onClick={() => this.goTargetViewSetting()}>
                                                        <svg className={styles.btn_svg} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 8C14 8.13261 13.9473 8.25979 13.8536 8.35355C13.7598 8.44732 13.6326 8.5 13.5 8.5H8.5V13.5C8.5 13.6326 8.44732 13.7598 8.35355 13.8536C8.25979 13.9473 8.13261 14 8 14C7.86739 14 7.74021 13.9473 7.64645 13.8536C7.55268 13.7598 7.5 13.6326 7.5 13.5V8.5H2.5C2.36739 8.5 2.24021 8.44732 2.14645 8.35355C2.05268 8.25979 2 8.13261 2 8C2 7.86739 2.05268 7.74021 2.14645 7.64645C2.24021 7.55268 2.36739 7.5 2.5 7.5H7.5V2.5C7.5 2.36739 7.55268 2.24021 7.64645 2.14645C7.74021 2.05268 7.86739 2 8 2C8.13261 2 8.25979 2.05268 8.35355 2.14645C8.44732 2.24021 8.5 2.36739 8.5 2.5V7.5H13.5C13.6326 7.5 13.7598 7.55268 13.8536 7.64645C13.9473 7.74021 14 7.86739 14 8Z" fill="#333333"/></svg>
                                                        <div className={styles.btn_txt}>타겟 설정하기</div>
                                                    </Button>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {this.state.show_design_popup_frame &&
                        <div className={styles.design_popup_wrap}>
                            <div className={styles.design_popup}>
                                <h2>적용 중인 디자인</h2>
                                <h3>해당 타겟으로 적용되어 있는 디자인들을 확인해 보세요.</h3>
                                <button onClick={this.handlDesignPopupFrame}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.2844 18.2193C19.4252 18.3615 19.5042 18.5535 19.5042 18.7536C19.5042 18.9538 19.4252 19.1458 19.2844 19.288C19.141 19.4266 18.9494 19.5041 18.75 19.5041C18.5506 19.5041 18.359 19.4266 18.2156 19.288L12 13.063L5.78438 19.288C5.64103 19.4266 5.44942 19.5041 5.25001 19.5041C5.0506 19.5041 4.85899 19.4266 4.71563 19.288C4.5748 19.1458 4.49579 18.9538 4.49579 18.7536C4.49579 18.5535 4.5748 18.3615 4.71563 18.2193L10.9406 12.0036L4.71563 5.78801C4.59604 5.64229 4.53492 5.45729 4.54416 5.269C4.55341 5.08071 4.63236 4.90259 4.76567 4.76929C4.89897 4.63599 5.07708 4.55704 5.26537 4.54779C5.45366 4.53854 5.63866 4.59966 5.78438 4.71926L12 10.9443L18.2156 4.71926C18.3614 4.59966 18.5464 4.53854 18.7346 4.54779C18.9229 4.55704 19.101 4.63599 19.2344 4.76929C19.3677 4.90259 19.4466 5.08071 19.4559 5.269C19.4651 5.45729 19.404 5.64229 19.2844 5.78801L13.0594 12.0036L19.2844 18.2193Z" fill="#333333"/></svg>
                                </button>
                                <div className={styles.design_popup_list_wrap}>
                                    {
                                        this.state.design_list && this.state.design_list.length > 0
                                            ? this.state.design_list.map((row) => {
                                                return (
                                                    <div key={row.event_seq} className={styles.design_popup_list_row}>
                                                        <div className={styles.banner_img}>
                                                            <img src={row.capture_code_url}/>
                                                        </div>
                                                        <div className={styles.banner_type}>
                                                            {row.banner_yn === "Y" && <div className={styles.banner}>배너</div>}
                                                            {row.popup_yn === "Y" && <div className={styles.popup}>팝업</div>}
                                                        </div>
                                                        <div className={styles.banner_title}>{row.title}</div>
                                                        <div className={styles.banner_date}>타겟 등록일 {row.target_view_reg_date_format}</div>
                                                    </div>
                                                )
                                            }) : <div className={styles.no_design_list}>생성된 디자인이 없어요.<br/>디자인에 타겟을 적용하고 맞춤 노출해 보세요.</div>
                                    }
                                    <div className={styles.design_popup_list_dummy_row}></div>
                                    <div className={styles.design_popup_list_dummy_row}></div>
                                    <div className={styles.design_popup_list_dummy_row}></div>
                                    <div className={styles.design_popup_list_dummy_row}></div>
                                    <div className={styles.design_popup_list_dummy_row}></div>
                                </div>
                            </div>
                        </div>
                    }
                    {/* 선택 삭제 팝업 */}
                    {this.state.show_del_popup_frame &&
                        <ConfirmDialogV2
                            content={"선택한 타겟을 정말 삭제하시겠습니까?"}
                            button_list={[
                                {
                                    title: "확인",
                                    event_method: () => { this.handleRomove(); }
                                },
                                {
                                    title: "아니요",
                                    event_method: () => { this.handleDelPopupFrame(); }
                                }
                            ]}
                            popup_close={() => { this.handleDelPopupFrame(); }}
                        />
                    }
                </div>
            </>
        )
    }
}
export default TargetViewList;
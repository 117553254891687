import React, {useCallback, useState} from 'react';
import http from '../modules/api';
import {useDropzone} from 'react-dropzone'
import $ from 'jquery';

function MyDropzone(props) {

    //const [uploadFiles, imageArray] = useState([]);

    const onDrop = useCallback(async (acceptedFiles) => {
        //props.attachImage(acceptedFiles)
        props.this.attachImage(acceptedFiles)
    }, []);
  
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  
    const InputProps = {
        ...getInputProps(),
        multiple: true,
        accept: "image/jpg,image/png,image/jpeg,image/gif,image/bmp,image/tiff",
    };
  
    const RootProps = {
        ...getRootProps(),
    };

    return (
        <div {...getRootProps()} style={{height:"100%", display:"flex", justifyContent:"center", alignItems:"center" , fontSize:"12px"}}>
            <input type="file" {...InputProps}/>
            {
                isDragActive ?
                <h5>이제 이미지를 놓아주세요.</h5> :
                <h5>업로드할 이미지를 여기에 드래그, 또는 클릭하여 올려주세요.</h5>
            }
        </div>
    );
}

export class SnsManager extends React.Component {

    state = {
        uploadFiles: [],
        imageArray: [],
        list: [],
        checkedList: []
    };

    // 이미지 업로드 팝업 : 저장 이벤트
    _handleSubmit = e => {
        e.preventDefault();
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        const url = '/rest/shop/sns/save/orgImg';
        const formData = new FormData();
        const uploadFiles = this.state.uploadFiles;
        uploadFiles.forEach((file) => formData.append("files", file));
        
        http.post(url, formData, config)
        .then(
            res => {
                alert("이미지 업로드 완료");
                // 조회
                this.searchOrgImg();
                $("#modalBack").hide();
                $("#modal_event_display").hide();
                $("body").css("background-color", "white");
                $("#modalBack").css("background-color", "white");
                $("#page-wrapper").css("background-color", "white");
            })
        .catch(
            error => { alert(error+"\n관리자에게 문의해 주세요."); }
        );
    };

    // 이미지 업로드 팝업 : 업로드 이벤트
    _handleImageChange = e => {
        this.attachImage(e.target.files)
    };

    // 업로드된 이미지 Preview
    attachImage(acceptedFiles) {
        if (acceptedFiles) {
            /* Get files in array form */
            const files = Array.from(acceptedFiles);
            //const uploadFiles = Array.from(e.target.files);
            //const { getRootProps, getInputProps, isDragActive } = useDropzone({ files });
            /* Map each file to a promise that resolves to an array of image URI's */ 
            Promise.all(files.map(file => {
                return (new Promise((resolve,reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            }))
            .then(images => {
                /* Once all promises are resolved, update state with image URI array */
                console.log(images);
                console.log(files);
                console.log(this);
                this.setState({
                    uploadFiles : files,
                    imageArray : images 
                });
                //const {uploadFiles, imageArray} = useState(images);
                var fileNames = "";
                files.map(file => {
                    fileNames += file.name + " "
                })
                $("#attachFileName").text(fileNames);
            }, error => {        
                console.error(error);
            });
        }
    }
    
    // 이미지 체크박스 이벤트
    _handleCheckBoxChange = (e, sns_org_img_seq) => {
        if (e.target.checked) {
			this.state.checkedList.push(sns_org_img_seq);
		} else {
			this.state.checkedList.splice(this.state.checkedList.indexOf(sns_org_img_seq), 1);
		}
		console.log(this.state.checkedList)
    }

    // 필터 : 조회 조건(쇼핑몰 이미지, 직접 업로드한 이미지)
    _handleRadioImgTypeChange = e => {
        
    }

    // 조회 버튼 이벤트
    _handleSearchButtonClick = e => {
        // 조회
        this.searchOrgImg();
    }

    // 이미지 업로드 버튼 이벤트
    _handleUploadButtonClick = e => {
        this.setState({
            uploadFiles: [],
            imageArray: [],
        })
        $("#attachFileName").text("");
        $("#modalBack").show();
        $("#modal_event_display").show();
        $("body").css("background-color", "#a6a6a6");
        $("#modalBack").css("background-color", "#a6a6a6");
        $("#page-wrapper").css("background-color", "#a6a6a6");
    }

    // 이미지 업로드 팝업 : 파일첨부 열기
    handleClickBtnFileUpload  = e => { 
		e.preventDefault ();
		$("#snsFiles").click();	
	};

    // 팝업 닫기시 업로드 파일 초기화
    // _handleUploadImgClear = e => {
    //     this.setState({
    //         uploadFiles : [],
    //         imageArray : []
    //     });
    // }

    //1. 원본 이미지 조회
	async searchOrgImg() {
        await http.get("/rest/shop/sns/search/orgImg")
                    .then(res => {
                                this.setState( {list : res.data["list"]} );
                                //this.setState( {totalCnt : res.data["totalCnt"]} );
                    })
                    .catch(error => {
                        alert(error+"\n관리자에게 문의해 주세요.");
                    });
    }

    componentDidMount(e) {
        // 팝업 세팅
		$("#modalBack").css("display","none");
		$("#modal_event_display").css("display","none");
		$(".modalClose").click(function() {
            $("#modalBack").hide();
            $("#modal_event_display").hide();
            $("body").css("background-color", "white");
            $("#modalBack").css("background-color", "white");
            $("#page-wrapper").css("background-color", "white");
        });

        // 조회
        this.searchOrgImg();
    }

    componentWillUnmount () {
		$("#modalBack").hide();
		$("#modal_event_display").hide();
		$("body").css("background-color", "white");
		$("#modalBack").css("background-color", "white");
		$("#page-wrapper").css("background-color", "white");
    }
    
    render () {
        return(
            <>
                <div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
                    <div id="list_component">
                        <h3>[Shop] 인스타그램용 사진 만들기</h3>
                        <br/>
                        <div className="filter">
                            <div style={{display:"flex"}}>
                                <div style={{marginTop: "auto", marginBottom: "auto"}}>
                                    <input type="radio" name="radio_img_type" id="radio_img_type" value="shop" onClick={this._handleRadioImgTypeChange}/> 쇼핑몰 사진 &nbsp;&nbsp;
                                    <input type="radio" name="radio_img_type" id="radio_img_type" value="pc" onClick={this._handleRadioImgTypeChange}/> PC내 저장된 사진 &nbsp;&nbsp;&nbsp;
                                </div>
                                <div style={{display:"flex", marginBottom: '12px'}}>
                                    <button className = "greenButton" onClick={this._handleSearchButtonClick} style={{width: "45px",height: "35px", margin: 'auto'}}>검색</button>&nbsp;
                                    <button className = "greenButton" onClick={this._handleUploadButtonClick} style={{width: "75",height: "35px", margin: 'auto'}}>이미지 업로드</button>
                                </div>
                            </div>
                        </div>
                        {this.state.list.map((orgImg, idx) => (
                            <div id="flex-box">
                                <div id="content-card" style={{float:"left", width:"295px", height:"450px", marginRight:"15px", marginBottom:"15px", textAlign:"center", backgroundColor:"#21a777"}}>
                                    <div id="orgImg-content" style={{width:"100%", height:"88%", marginTop:"10px"}}>
                                        <input type="checkbox" id={orgImg.sns_org_img_seq} style={{position:"absolute", margin:"5px"}} onChange={e => this._handleCheckBoxChange(e, orgImg.sns_org_img_seq)} />
                                        <img className="orgImg-photo" src={orgImg.org_img_path} alt="Photo uploaded" style={{width:"94%", height:"98%", objectFit:"cover"}} />
                                        <div id="orgImg-info" style={{marginTop:"8px"}}>
                                            <div style={{fontSize:"12px", color:"#ffffff"}}>{orgImg.org_img_name}</div>
                                            <div style={{fontSize:"12px", color:"#ffffff"}}>{orgImg.reg_date}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* 진열 여부 팝업 */}
                    <div id="modal_event_display">
                        <div id="modalContent" className="shadow-box">
                            <h4>업로드 관리자</h4>
                            <form onSubmit={this._handleSubmit} enctype="multipart/form-data">
                                <div className="fileUp">
                                    <button id="btnFileUpload" onClick={this.handleClickBtnFileUpload}>파일첨부</button>
                                    <input type="file" id="snsFiles" name="snsFiles" multiple onChange={this._handleImageChange} accept="image/jpg,image/png,image/jpeg,image/gif,image/bmp,image/tiff" value="" style={{display:'none'}}/> 
                                    <div id="attachFileName" className='fileName' style={{float:"left", width:"85%"}}></div>
                                    <button type="submit" id="btnSave" className="greenButton" style={{float:"right"}}>저장</button> 
                                </div>
                            </form>
                            <div style={{height:"80px", border:"1px solid #c4c4c4", borderStyle:"dotted"}}>
                                <MyDropzone attachImage={this.attachImage}
                                            this={this}
                                />
                                {/* <Dropzone onDrop={this.state.uploadFiles}>
                                    {({getRootProps, getInputProps}) => (
                                        <section>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} style={{height:"200px", backgroundColor:"grey"}}/>
                                            <p>Drag 'n' drop some files here, or click to select files</p>
                                        </div>
                                        </section>
                                    )}
                                </Dropzone> */}
                            </div>
                            <div style={{overflow:"scroll", height:"500px"}}>
                                {/* {!$imagePreview && <img src={imagePreviewUrl} style={{width:"10%"}} />}  */}
                                {this.state.imageArray.map((imageURI, idx) => (
                                    <div id="content-card" style={{float:"left", width:"200px", height:"330px", margin:"10px 5px 10px", textAlign:"center", backgroundColor:"#21a777"}}>
                                        <div id="orgImg-content" style={{width:"200px", height:"285px", marginTop:"10px"}}>
                                            <img className="photo-uploaded" src={imageURI} alt="Photo uploaded" style={{width:"90%", height:"98%", objectFit:"cover"}} />
                                        </div>
                                        <div id="orgImg-info">
                                            <div style={{fontSize:"12px", color:"#ffffff"}}>{this.state.uploadFiles[idx].name}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="fa fa-close modalClose"></div>
                    </div>				

                    {/* 팝업창 배경 */}
                    <div id="modalBack" style={{top: "0", height:"130%"}}></div>
                </div>
            </>
        )
    }
}
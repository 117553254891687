import React from 'react';
import axios from 'axios';
import http from '../modules/api';
import {Route, Switch, NavLink, Link} from "react-router-dom";
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";

import "react-datepicker/dist/react-datepicker.css";

import $ from 'jquery';

var is_checkbox_ckick = false;
http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
export class EventList extends React.Component {
	state = {
			event_type: [],
			event_mobile_type: [],
			user_list: [],
			event_list: [],
			orderGubun: "",
			sorting: "",
			currentPage: "",
			start_date: "",
			end_date: "",
			start_date_format: "",
			end_date_format: "",
			title: "",
			activePage: 1,
			totalCnt: 0,
			startIdx: 0,
			rowcnt:10,
			event_type_cd: "",
			event_mobile_type_cd: "",
			user_seq: "",
			checkedEventList: [],
			eventDisplayYn: "Y",
	};

	handleText = (e, key) => {
	    // computed property
		this.setState({[key]: e.target.value});
	}
	
	componentDidMount(e) {
		$("#modalBack").css("display","none");
		$("#modal_event_display").css("display","none");
		$(".modalClose").click(function() {
            $("#modalBack").hide();
            $("#modal").hide();
            $("#modal_event_display").hide();
            $("body").css("background-color", "white");
            $("#modalBack").css("background-color", "white");
            $("#page-wrapper").css("background-color", "white");
        });

		// console.log(localStorage.getItem ('state'))
		if (localStorage.getItem ('state') != null){
			new Promise( ( resolve, reject ) => {setTimeout( resolve, 200 ); })
			.then( () => {
				var json = JSON.parse(localStorage.getItem ('state'));
				json.event_type = [];
				json.event_list = [];
				json.start_date = Date.parse(json.start_date_format);
				json.end_date = Date.parse(json.end_date_format);
				this.setState(json);
				this.state.startIdx = parseInt(localStorage.getItem("startIdx"));
				localStorage.clear();
				this.getQnaList();

				// var json = JSON.parse(localStorage.getItem ('state'));
				// // console.log(json);
				// json.event_type = [];
				// json.event_list = [];
				// json.start_date = Date.parse(json.start_date_format);
				// json.end_date = Date.parse(json.end_date_format);
				// this.setState(json);
				// console.log(this.state.event_type);
				// this.state.startIdx = parseInt(localStorage.getItem("startIdx"));
				// localStorage.clear();
				// this.getEventList();
			});
		} else {
			// this.getQnaTypeList();
			this.getQnaList();
		}
	}

	componentDidUpdate(e) {
		// console.log("######### componentDidUpdate ##########")
	}

	onComponentChange = e => {
		// console.log("######### onComponentChange ##########")
	}

	componentWillUnmount = e => {
		// console.log("######### componentWillUnmount ##########")
	}
	
	//1. 목록 조회
	async getQnaList() {
		// console.log($("#event_type option:selected").val());
		await http.get("/rest/common/admin/event/List?start_date="+this.state.start_date_format+"&end_date="+this.state.end_date_format+"&title="+this.state.title
		+"&order_type="+this.state.sorting+"&order_gubun="+this.state.orderGubun+"&startidx="+this.state.startIdx+"&rowcnt="+this.state.rowcnt
		+"&event_type_cd="+$("#event_type option:selected").val()
		+"&event_mobile_type_cd="+$("#event_mobile_type option:selected").val()
		+"&user_seq="+$("#user_list option:selected").val())
		.then(res => {
			if(res.data.err_code == 401) {
				window.location.href = "/logout";
			} 
			
			this.setState( {event_type : res.data["eventTypeList"] 
			, event_mobile_type: res.data["eventMobileTypeList"]} );
			if(res.data["useListrByUserJoinCls"] != null) {
				this.setState( {user_list : res.data["useListrByUserJoinCls"]} );
			}
			if(res.data["list"] != null) {
				this.setState( {event_list : res.data["list"]} );
			}
			if (res.data["totalCnt"] != null) {
				this.setState( {totalCnt : res.data["totalCnt"]} );
			} else {
				this.setState( {totalCnt : 1} );
			}
			// console.log ("===== res =====");
			// console.log(res);
		})
		.catch(error => {
			// console.log ("===== error =====");
			// console.log (error);
			// console.log ("===== error request =====");
			// console.log(error.request);
		 });
    }
	
	//2. 상세 조회
	handleClick = (event_seq, event_promotion_type_cd) => {
		if (is_checkbox_ckick) {
			is_checkbox_ckick = false;
			return false;
		}

		if (event_promotion_type_cd == "19002") {
			this.props.history.push(`/admin/cafe24/promotion/form?event_seq=`+event_seq);
		} else {
			this.props.history.push(`/admin/cafe24/event/form?event_seq=`+event_seq);
		}

		localStorage.setItem ( 'state' , JSON.stringify(this.state) ) ;
		localStorage.setItem ( 'startIdx' , this.state.startIdx ) ;
	};

	handleChangeShop = (e) => {
		this.setState({user_seq : e.target.value});
	};
	
	handleChangeType = (e) => {
		this.setState({event_type_cd : e.target.value});
	};
	
	handleChangeMobileType = (e) => {
		this.setState({event_mobile_type_cd : e.target.value});
	};
	
	// 3. 정렬
	onClickSorting = (e) => {
		if (this.state.orderGubun == e.target.className.replace(" sorting","")) {
			if (this.state.sorting == "ASC")
			this.state.sorting = "DESC";
			else
			this.state.sorting = "ASC";
		} else {
			this.state.sorting = "DESC";
		}
		this.state.orderGubun = e.target.className.replace(" sorting","");
		this.state.currentPage = 1;
		this.getQnaList()
	};

	//4. 조회 버튼 클릭
	onClickSearchListButton = () => {
		this.state.startIdx = 0;
		this.state.activePage = 1;
		this.getQnaList();
	};

	//4. 달력 클릭
	handleChangeStartDate = date => {
		if (date != null) {
			this.setState({
				start_date_format: date.getFullYear() + "-"+ ("0" + parseInt(date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(date.getDate())).slice(-2),
				start_date: date
			});
		} else {
			this.setState({
				start_date_format: date,
				start_date: date
			});
		}
	};
	handleChangeEndDate = date => {
		if (date != null) {
			this.setState({
				end_date_format: date.getFullYear() + "-"+ ("0" + parseInt(date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(date.getDate())).slice(-2),
				end_date: date
			});
		} else {
			this.setState({
				end_date_format: date,
				end_date: date
			});
		}
	};

	//5. 엔터키 검색
	_handleKeyDown = e => {
		if (e.key === 'Enter') {
			this.state.startIdx = 0;
			this.state.activePage = 1;
			this.getQnaList();
		}
	  }

	//6.페이징
	handlePageChange(pageNumber) {
		// console.log(`active page is ${pageNumber}`);
		this.setState({activePage: pageNumber
						, checkedEventList: []});
		$("input[name=select_event_all]").prop("checked", false);
		this.state.startIdx = (pageNumber * 10) - this.state.rowcnt;
		// console.log("this.state.startIdx :" + this.state.startIdx )
		this.getQnaList()
	  }  

	  componentWillReceiveProps(nextProps, nextContext) {
		// console.log(nextProps);
		this.getQnaList(nextProps.match.params.id);
	  }

	// 7.체크박스
	onClickSelectEvent = e => {
		is_checkbox_ckick =  true;
		// if (e.target.id != null && e.target.id != "" && e.target.id.indexOf("check_area_") >= 0) {
		// 	$("#"+e.target.id+" input[name=select_event]").click();
		// }
	}
	handleChangeSelectEvent = (e, event_seq) => {
		if (e.target.checked) {
			this.state.checkedEventList.push(event_seq);
		} else {
			$("input[name=select_event_all]").prop( 'checked', e.target.checked );
			this.state.checkedEventList.splice(this.state.checkedEventList.indexOf(event_seq), 1);
		}
		console.log(this.state.checkedEventList)
	}
	handleChangeSelectEventAll = e => {
		$("input[name=select_event]").prop( 'checked', e.target.checked );
		if (e.target.checked) {
			this.state.checkedEventList = [];
			for (var event in this.state.event_list) {
				this.state.checkedEventList.push(this.state.event_list[event].event_seq)
			}
		} else {
			this.state.checkedEventList = [];
		}
		console.log(this.state.checkedEventList)
	}

	  // 8.onClickBtnOpenModalEventDisplay
	  onClickBtnOpenModalEventDisplay = e => {
		if (this.state.checkedEventList.length > 0) {
			$("#modalBack").show();
            $("#modal_event_display").show();
            $("body").css("background-color", "#a6a6a6");
            $("#modalBack").css("background-color", "#a6a6a6");
            $("#page-wrapper").css("background-color", "#a6a6a6");
		} else {
			alert("기획전을 선택해 주세요.")
		}
	  }

	  // 9.handleChangeisDisplaySelectBox
	  handleChangeisDisplaySelectBox = e => {
		this.setState({eventDisplayYn : e.target.value});
	  }
	  
	  onClickApplyEventDisplay = e => {
		http.post("/rest/common/event/change/display?display_yn="+this.state.eventDisplayYn+"&event_seqs="+this.state.checkedEventList)
		.then(res => {
			
			console.log ("===== res =====");
			console.log(res);
			if (res.data.result) {
				alert("변경 되었습니다.");
				window.location.reload(true);
			}
		})
		.catch(error => {
			// console.log ("===== error =====");
			// console.log (error);
			// console.log ("===== error request =====");
			// console.log(error.request);
		 });
	  }

	  render() {
		
		return (
			<div id='page-wrapper' className="container event_list_container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
				<div id="event_list_component">
					<h3>[Admin] 기획전 목록</h3>
					<br/>
					<div className="filter">
						<div style={{display:"flex", flexWrap:"wrap", marginBottom: '-12px'}}>
							<div className="input-group m-b mobile_width_100 event_list_cal" style={{width:"40%", marginRight:"10px"}}>
								<div style={{width: "100%"}}> 
								<label className="mobile_font_size_11">날짜</label>
									<div className="input-daterange input-group" id="datepicker">
										<DatePicker name="start_date" id="start_date"
											selected={this.state.start_date}
											onSelect={this.handleSelect} //when day is clicked
											onChange={this.handleChangeStartDate} //only when value has changed
											placeholderText="시작일자"
											// showTimeSelect
											dateFormat="yyyy-MM-dd"
											autoComplete='off'
										/>
										<div className="input-group-addon" style={{width: "40px",height:"35px", lineHeight: "34px",textAlign:"center"}}>to</div>
										<DatePicker name="end_date" id="end_date"
											selected={this.state.end_date}
											onSelect={this.handleSelect} //when day is clicked
											onChange={this.handleChangeEndDate} //only when value has changed
											placeholderText="종료일자"
											// showTimeSelect
											dateFormat="yyyy-MM-dd"
											autoComplete='off'
										/>	
									</div>
								</div>
							</div>
							<div className="input-group m-b mobile_flex_1" style={{width:"20%", flex:"1"}}>
								<label className="mobile_font_size_11">기업명</label>
								<select id="user_list" style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px", marginBottom:"10px"}}
								value={this.state.user_seq} onChange={(e) => this.handleChangeShop(e)}>
									<option value="">
										전체
									</option>
									{this.state.user_list.map((user, idx) => (	
										<option value={user.user_seq} key={idx}>
											{user.corp_name}
										</option>								
									))};
								</select>
							</div>
							<div className="input-group m-b mobile_flex_1" style={{width:"20%", flex:"1", marginLeft:"10px"}}>
								<label className="mobile_font_size_11">PC 배너 유형</label>
								<select id="event_type" style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px", marginBottom:"10px"}}
								value={this.state.event_type_cd} onChange={(e) => this.handleChangeType(e)}>
									<option value="">
										전체
									</option>
									{this.state.event_type.map((qna, idx) => (	
										<option value={qna.code_cd} key={idx}>
											{qna.event_type_name}
										</option>								
									))};
								</select>
							</div>
							<div className="input-group m-b mobile_flex_1" style={{width:"20%", flex:"1", marginLeft:"10px"}}>
								<label className="mobile_font_size_11">mobile 배너 유형</label>
								<select id="event_mobile_type" style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px", marginBottom:"10px"}}
								value={this.state.event_mobile_type_cd} onChange={(e) => this.handleChangeMobileType(e)}>
									<option value="">
										전체
									</option>
									{this.state.event_mobile_type.map((qna, idx) => (	
										<option value={qna.code_cd} key={idx}>
											{qna.event_mobile_type_name}
										</option>								
									))};
								</select>
							</div>
						</div>
						<div style={{display:"flex", marginBottom: '12px'}}>
							<input type="text" onChange={(e) => {
								// console.log(e.target.value)
								this.setState({ title: e.target.value })
							}} placeholder="검색어를 입력하세요." style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px"}} onKeyDown={this._handleKeyDown}></input>
							<button className = "greenButton admin_search_btn" onClick={this.onClickSearchListButton}>검색</button>
						</div>
					</div>
					<div className="table">
						<table>
							<colgroup>
								<col width="50px" />
								<col width="50px" />
								<col width="180px" />
								<col width="80px" />
								<col width="*" />
								<col width="130px" />
								<col width="80px" />
								<col width="100px" />
								<col width="100px" />
								<col width="100px" />
								<col width="80px" />
							</colgroup>
							<thead>	
								<tr>
									<td>
										<p className="card-text"><input type="checkbox" name="select_event_all" onChange={e => this.handleChangeSelectEventAll(e)}/></p>
									</td>
									<td>
										<p className="card-text">순번</p>
									</td>
									<td>
										<p className="card-text">기업명</p>
									</td>
									<td>
										<p className="card-text">배너구분</p>
									</td>
									<td>
										<p className="card-text">제목</p>
									</td>
									<td className="user_name">
										<p className="card-text">이미지</p>
									</td>
									<td className="event_view_cnt sorting" onClick={this.onClickSorting}>
										<p className="card-text">클릭수</p>
									</td>
									<td className="start_date sorting" onClick={this.onClickSorting}>
										<p className="card-text">시작일시</p>
									</td>
									<td className="end_date sorting" onClick={this.onClickSorting}>
										<p className="card-text">종료일시</p>
									</td>
									<td className="mod_date sorting" onClick={this.onClickSorting}>
										<p className="card-text">최종수정일시</p>
									</td>
									<td className="display_yn sorting" onClick={this.onClickSorting}>
										<p className="card-text">진열 여부</p>
									</td>
								</tr>
							</thead>
							<tbody>
								{this.state.event_list.map((event, idx) => (
									<tr key={event.event_seq} className="cursor-pointer tbody-tr-hover" onClick={() => this.handleClick(event.event_seq, event.event_promotion_type_cd)}>
										<td onClick={this.onClickSelectEvent} id={"check_area_"+event.event_seq}>
											<p className="card-text"><input type="checkbox" name="select_event" onChange={e => this.handleChangeSelectEvent(e, event.event_seq)}/></p>
										</td>
										<td>
											<p className="card-text">{(this.state.totalCnt-(idx+(10*(this.state.activePage-1))))}</p>
										</td>
										<td>
											<p className="card-text">{event.corp_name}</p>
										</td>
										<td>
											<p id="title" className="card-text" style={{fontSize:"12.5px", margin:"auto 0 auto 0"}}>
												{
													event.mod_user_seq == -1
													? <>
														<span style={{color:"#10CFC9",margin:"auto 0"}}><b>자동 배너</b></span>
													</>
													: 	
														(Number(event.reg_user_seq) == -1 && Number(event.mod_user_seq) > 0)
															// || (event.reg_user_seq == null && Number(event.mod_user_seq) > 0)
														?	<>
																<span style={{color:"#000080",margin:"auto 0"}}><b>수정 로보 배너</b></span>
															</>
														: 	
															Number(event.reg_user_seq) > 0
															?	<>
																	<span style={{color:"#000080",margin:"auto 0"}}><b>직접 로보 배너</b></span>
																</>
															: 
																event.reg_user_seq == -2
																?	<>
																		<span style={{color:"#5898fe",margin:"auto 0"}}><b>샘플 배너</b></span>
																	</>
																:
																	
																	event.event_promotion_type_cd != null && event.event_promotion_type_cd == "19002"
																	?	<>
																			<span style={{color:"RGB(186 172 254)",margin:"auto 0"}}><b>마이 배너</b></span>
																		</>
																	:	<>
																			<span style={{color:"#333333",margin:"auto 0"}}><b>-</b></span>
																		</>	
												}
											</p>
										</td>
										<td>
											{(() => {
					                            if (event.display_yn == "Y") {
                                                    return (<p id="title" className="card-text"><b>{event.title}</b></p>)
                                                } else {
                                                    return (<p id="title" className="card-text">{event.title}</p>)
                                                }
                                            })()}
										</td>
										<td>
											<p className="card-text"><img src={event.img_link} width="100px"/></p>
										</td>
										<td>
											<p className="card-text">{event.event_view_cnt}</p>
										</td>
										<td>
											<p className="card-text">{event.start_date}</p>
										</td>
										<td>
											<p className="card-text">{event.end_date}</p>
										</td>
										<td>
											<p className="card-text">{event.mod_date}</p>
										</td>
										<td>
											{(() => {
					                            if (event.display_yn == "Y") {
                                                    return (<p className="card-text" style={{color:"#21a777", fontWeight:"bold"}}>진열중</p>)
                                                } else {
                                                    return (<p className="card-text">미진열</p>)
                                                }
                                            })()}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					<Pagination
						activePage={this.state.activePage}
						itemsCountPerPage={this.state.rowcnt}
						totalItemsCount={this.state.totalCnt}
						pageRangeDisplayed={5}
						onChange={this.handlePageChange.bind(this)}
					/>
				</div>
			
			<div id="BtnOpenModalEventDisplay" className="greenButton cursor-pointer event_display_edit_btn" onClick={this.onClickBtnOpenModalEventDisplay}>
				진열여부 수정
			</div>

			{/* 진열 여부 팝업 */}
				<div id="modal_event_display">
					<div id="modalContent_event_display" className="shadow-box" 
					style={{width:"auto",maxHeight: "80%",top:"25%",left: "45%", position: "fixed", background:"white", padding:"10px", zIndex: "99999"}}>
						<h4>진열 여부 수정</h4>
						<div className="mobile_font_size_11" style={{color:"dimgray"}}>(잔열 기간이 끝난 기획전은 진열로 변경해도 진열되지 않습니다.)</div>
						<select id="isDisplaySelectBox" style={{width:"100%", padding:"5px 7px 5px 7px", margin:"5px auto auto auto"}} onChange={this.handleChangeisDisplaySelectBox}>
							<option value="Y">진열</option>
							<option value="N">미진열</option>
						</select>
						<div className="greenButton cursor-pointer" 
							 style={{width:"100%", height:"30px", lineHeight:"28px", textAlign:"center", margin:"15px auto 15px auto"}} 
							 onClick={this.onClickApplyEventDisplay}>
					  	  변경
					  	</div>
					</div>
					<div className="fa fa-close modalClose modal_event_display_close" style={{top: "20%", left:"auto", top: "21%", left: "calc(452px + 42%)"}}></div>
				</div>	

				{/* 팝업창 배경 */}
                <div id="modalBack" style={{top: "0", height:"130%"}}></div>			
			</div>
		);
	}
}

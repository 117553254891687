import React from 'react';
import http from '../modules/api';
import { Helmet } from 'react-helmet';

// 스타일
import './roboimage_style.css';
import styles from './RoboImageDesignResult.module.css';
import SaveDialogV2 from '../modules/dialogs/SaveDialogV2';
import { setCookie, getCookie, deleteCookie } from "../modules/utils/CookieUtil";

import {ga4react, reactPixel} from '../Menu';

export class RoboImageDesignResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show_loading_dialog: false, // 로딩 팝업창 열기/닫기 : true/false
            prompt : '',
            width : '',
            height : '',
            event_seq: '',
            robo_img : '',
            banner_width : 0,
            banner_height : 0,
        }
        this.setHeight = this.setHeight.bind(this);
    }

    componentWillMount() {
        const non_members_robo_img_prompt = getCookie("non_members_robo_img_prompt_"+getCookie("user_id"));
        const non_members_robo_img_design_yn = getCookie("non_members_robo_img_design_yn_"+getCookie("user_id"));
        if(!non_members_robo_img_prompt || !non_members_robo_img_design_yn){
            window.location.href = "https://robomdaiimage.com/common/promo_aibanner";
            deleteCookie("non_members_robo_img_prompt_"+getCookie("user_id"));
            deleteCookie("non_members_robo_img_design_yn_"+getCookie("user_id"));
        } else{
            this.setState({
                prompt: decodeURIComponent(non_members_robo_img_prompt),
            }, () => {
                deleteCookie("non_members_robo_img_prompt_"+getCookie("user_id"));
                deleteCookie("non_members_robo_img_design_yn_"+getCookie("user_id"));
                this.changeUninstallShop();
                this.loadCommonCode(54000);
            });
        }
    }

    componentWillUnmount () {
        window.removeEventListener("resize", this.setHeight);
    }

    /** 비연동형 계정으로 전환 */
    changeUninstallShop = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        http.post("/rest/shop/uninstall/shopAdd/01001", {}, config)
        .then(res => {
            if(res.data.code != "200"){
                alert("쇼핑몰 미보유 계정으로 전환 실패하였습니다.");
                window.location.href = "/";
                return false;
            }
            // solution_type_cd 쿠키값 비연동형으로 설정
            setCookie('solution_type_cd','09900');
        })
        .catch(error => {
            alert("쇼핑몰 미보유 계정으로 전환 실패하였습니다.");
            window.location.href = "/";
        });
    }

    /** cafe24.TB_CODE 값 조회 : 세로 비율 */
    loadCommonCode = (upper_code_cd) => {
		http.get("/rest/common/cafe24Code?upper_code_cd="+upper_code_cd)
		.then(res => {
            if(res.data){
                const [width, height] = res.data[2].val1.split('*');
                this.setState({
                    width : width,
                    height : height,
                }, () =>{
                    this.getRoboImage();
                });
            }
        });
    }

    /** 이미지 만들기 */
    getRoboImage = () => {
        this.setState({show_loading_dialog : true});

        const prompt = this.state.prompt.replaceAll(/[\[\]:]/g, ""); // 프롬프트의 대괄호와 콜론을 빈문자로 바꿔서 전달
        const sendForm = {
            prompt : prompt,
            // 세로형 이미지 크기
            width : this.state.width,
            height : this.state.height,
        };
		http.get("/rest/common/stableDiffusion/stability", {params:sendForm})
		.then(res => {
            if(res.data.code == "200" && res.data.result.resCode != "500"){
                this.setState({
                    robo_img: res.data.result.url[0],
                });
                window.addEventListener("resize", this.setHeight);
                this.saveRoboImageHist(res.data.final_prompts, res.data.result.url);
                this.createRoboImgBanner(res.data.result.url[0]);
            } else{
                this.saveRoboImageHist(res.data.final_prompts, res.data.result.url);
                alert("이미지를 생성할 수 없어요.\n다시 시도해도 안되면 다른 아이디어를 입력해 주세요.");
                window.location.href = "/";
            }
        })
        .catch(error=> {
            alert("이미지를 생성할 수 없어요.\n다시 시도해도 안되면 다른 아이디어를 입력해 주세요.");
            window.location.href = "/";
        });
    }
    
    saveRoboImageHist = (final_prompts, url) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const body = {
            event_seq: '-1',
            banner_cd: '-1',
            banner_direction: '-1',
            kor_prompts: this.state.prompt,
            final_prompts: final_prompts,
            res_img: url,
        };
        http.post("/rest/common/insert/stability/call/hist", body, config);
    }

    /**
     * 배너 생성
     * @param {*} img_link 배너 생성에 사용될 로보 이미지
     */
    createRoboImgBanner = (img_link) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const body = {
            img_link : img_link,
        };
        http.post("/rest/common/roboImage/event/create/v3", body, config)
		.then(res => {
            if(res.data.code == "200"){
                this.setState({
                    event_seq: res.data.event_seq,
                }, () => {
                    this.saveRoboImgBanner();
                });
            } else{
                alert("배너 생성에 실패하였습니다.");
                window.location.href = "/";
            }
        })
        .catch(error=> {
            alert("배너 생성에 실패하였습니다.");
            window.location.href = "/";
        });
    }
    
    /**
     * 배너 저장
     */
    saveRoboImgBanner = () => {
        const sendForm = {
            event_seq : this.state.event_seq,
        };
		http.get("/rest/common/roboImage/event/save", {params:sendForm})
		.then(res => {
            if(res.data.code == "200"){
                this.setState({show_loading_dialog : false});
                var object = document.createElement('object');
                object.setAttribute("type", "text/html");
                object.setAttribute("data", 'https://robomd.io/banner_sample.html?event_seq='+this.state.event_seq+'&platform_type=p&mirror_type=18002&req_user_seq=-5');
                object.style.width = '100%';
                object.style.height = '100%';
                object.style.background = '#FFF';
                let banner = document.getElementById("robo_img_banner");
                banner.appendChild(object);

                this.setHeight();

            } else{
                alert("배너 저장에 실패하였습니다.");
                window.location.href = "/";
            }
        })
        .catch(error=> {
            alert("배너 저장에 실패하였습니다.");
            window.location.href = "/";
        });
    }

    /** 무료로 디자인 편집하기 버튼 클릭 */
    goBannerDesign = () => {
        ga4react.event('btn_click', 'btn_banner_design', 'banner_promo');
		reactPixel.trackCustom('[AI디자인] 버튼_클릭_배너등록');

        window.location.href = "/shop/cafe24/event/form?event_seq="+this.state.event_seq;
    }

    setHeight = () => {
        let banner_width;
        let banner_height;
        let img_width;
        let area_width = document.getElementById("content_area").clientWidth;

        // 1920x800으로 2.4의 비율을 가지고있는 배너
        if(window.innerWidth <= 720){
            banner_width = area_width;
            banner_height = area_width/2.4;
        } else{
            // 영역 width 길이에서 배너/여백/이미지 사이즈를 고려하여 측정한 값
            area_width -= 40; // 여백 사이즈 40
            banner_width = area_width*0.81;
            banner_height = (area_width*0.81)/2.4;

            const total_ratio_width = 2.4 + (this.state.width/this.state.height);
            banner_width = area_width * (2.4 / total_ratio_width);
            banner_height = banner_width/2.4;
            img_width = area_width * ((this.state.width/this.state.height) / total_ratio_width);
        }
        this.setState({
            banner_width : banner_width,
            banner_height : banner_height,
            img_width: img_width,
        });
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>[로보MD] AI 무료 디자인 생성</title>
                    <meta name="description" content={"아이디어 입력만으로 순식간에 저작권 걱정 없는 AI 디자인를 생성해 보세요. 지금 회원가입 시 14일 무료 체험 혜택을 드려요."} />
                    <meta name="keywords" content={"AI이미지생성, 생성형AI, 로보MD, 배경제거, 무료사진, 인공지능이미지, AI스타트업, 무료이미지, AI프로필, 배너, 이미지"} />
                    <meta property="og:title" content={"[로보MD] AI 무료 디자인 생성"} />
                    <meta property="og:url" content={"robomd.io/common/promo_aibanner/result"} />
                    <meta property="og:description" content={"아이디어 입력만으로 순식간에 저작권 걱정 없는 AI 디자인를 생성해 보세요. 지금 회원가입 시 14일 무료 체험 혜택을 드려요."} />
                    <meta property="og:image" content={"https://s3.ap-northeast-2.amazonaws.com/otdeal.file/og_image/roboimage+og.jpg"} />
                </Helmet>
                {this.state.show_loading_dialog &&
                    <SaveDialogV2
                        content={"요청하신 이미지를 만들고 있어요.<br/>조금만 기다려 주세요 :)"}
                        button_list={[{
                            title: "확인",
                            type: "1",
                            display_yn:"N",
                        }]}
                    />
                }
                {(this.state.robo_img && !this.state.show_loading_dialog) &&
                    <div className={[styles.roboimage_result, 'roboimage_style_guide'].join(' ')}>
                        <h2>
                            AI 이미지와 자동 디자인이 만들어졌어요. <br/>
                            다양한 곳에 나만의 <br className={styles.mobile_br}/>콘텐츠로 활용해 보세요.
                        </h2>
                        <div id="content_area" className={styles.content_area}>
                            <div>
                                <div className={styles.content_title} style={{width: this.state.banner_width}}>AI 디자인</div>
                                <div id="robo_img_banner" className={styles.banner} style={{width: this.state.banner_width, height : this.state.banner_height}}>
                                </div>
                            </div>
                            <div className={styles.robo_img_area}>
                                <div className={styles.content_title} style={{width: this.state.img_width}}>AI 이미지</div>
                                <img className={styles.robo_img} src={this.state.robo_img} style={{width: this.state.img_width}}/>
                            </div>
                        </div>
                        <button className={'hover_mint'} onClick={this.goBannerDesign}>무료로 디자인 편집하기</button>
                    </div>
                }
            </>
        )
    }
}
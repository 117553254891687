/**
 *  두개의 배열의 객체의 원소비교 (차집합)
 * @param {*} array1 첫번째 배열 [{aaa: "aaa", bbb: "bbb"}]
 * @param {*} array2 두번째 배열 [{aaa: "aaa", bbb: "bbb"}]
 * @param {*} objName1 첫번째 배열의 객체 중 비교대상 원소명 (문자열)
 * @param {*} objName2 두번째 배열의 객체 중 비교대상 원소명 (문자열)
 */
export function diffrentTwoArrayObject (array1, array2, objName1, objName2) {
    let filter_arrays =  array1.filter(item1 => {
        return !array2.some(item2 => item1[objName1] === item2[objName2])
    }); 
    return filter_arrays
}

/**
 *  두개의 배열의 객체의 원소비교 (합집합)
 * @param {*} array1 첫번째 배열 [{aaa: "aaa", bbb: "bbb"}]
 * @param {*} array2 두번째 배열 [{aaa: "aaa", bbb: "bbb"}]
 * @param {*} objName1 첫번째 배열의 객체 중 비교대상 원소명 (문자열)
 * @param {*} objName2 두번째 배열의 객체 중 비교대상 원소명 (문자열)
 */
export function sameTwoArrayObject (array1, array2, objName1, objName2) {
    let filter_arrays =  array1.filter(item1 => {
        return array2.some(item2 => item1[objName1] === item2[objName2])
    }); 
    return filter_arrays
}
import React from "react";
import $ from 'jquery';

/**
 * 막대 차트
 * list : List<map> 형태의 원본 리스트 그대로 사용.
 * listTextName : list에서 option의 text로 사용 할 변수명
 * listValueName : list에서 option의 %값으로 사용 할 변수명
 * listIdName : list에서 각 차트바의 id값으로 사용 할 변수명 (없으면 차트바 내용 변경시 애니메이션 없음)
 * height : 세로 높이 (예: "50px"), null 이면 기본값 적용
 * title : 차트 이름, null이면 해당 영역 없이 출력
 */

class LineChart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            list : [],
            chart_color : ["#00e2e6","#0090ff","#996cfc","#ff6190","#00979a","#143DB4","#A00051","#D5C2FD","#7DEDA1","#8C9DAD"],
    		height: "50px",
        }
    }

    componentDidMount(){
        this.setState({list : JSON.parse(JSON.stringify(this.props.list))});
    }

	componentDidUpdate = (prevProps, prevState) => { //componentDidUpdate가 props의 변과를 감지한다
        if (JSON.stringify(this.props.list) !== JSON.stringify(prevProps.list)) { // 하위컴포넌트가 받은 props값 비교

            if(this.props.listIdName != null && this.props.listIdName != ""){
                // 애니메이션 작업을 위한 단계
                // 리스트 비교 : 이전 리스트와 현재 리스트 (listIdName이 있는 경우에만 판별 가능)
                let before_list = [];
                let after_list = [];
                let add_list = []; // 추가된 값
                let del_list = []; // 제거된 값

                // 이전 리스트
                let prev_list = prevProps.list;
                for(var i=0;i<prev_list.length;i++){
                    before_list.push(prev_list[i][this.props.listIdName]);
                }
                // 현재 리스트
                let props_list = this.props.list;
                for(var i=0;i<props_list.length;i++){
                    after_list.push(props_list[i][this.props.listIdName]);
                }
                // 제거된 값
                del_list = before_list.filter(x => !after_list.includes(x));
                // 추가된 값
                add_list = after_list.filter(x => !before_list.includes(x));

                // 요소 삭제 애니메이션
                for(var i=0;i<del_list.length;i++){
                    $("#chart_bar_"+del_list[i]).animate({"height":0,"opacity":0},200);
                }
                let this_component = this;
                setTimeout(() =>
                    this.setState({list : JSON.parse(JSON.stringify(this.props.list))}, () => {
                        for(var i=0;i<this.state.list.length;i++){
                            $('#chart_bar_'+this.state.list[i][this.props.listIdName]).animate({"height":this_component.state.list[i][this_component.props.listValueName]+"%","opacity":1},0);
                        }
                        for(var i=0;i<add_list.length;i++){
                            let idx=i;
                            $('#chart_bar_'+add_list[i]).animate({"height":0,"opacity":0},0, function(){
                                for(var idx2=0;idx2<this_component.state.list.length;idx2++){
                                    if(String(this_component.state.list[idx2][this_component.props.listIdName]) == String(add_list[idx])){
                                        $('#chart_bar_'+add_list[idx]).animate({"height":this_component.state.list[idx2][this_component.props.listValueName]+"%","opacity":1},200);
                                    }
                                }
                            });
                        }
                    })
                ,200);
            } else{
                this.setState({list : JSON.parse(JSON.stringify(this.props.list))});
            }
        }
    };

	render () {
		return (
			<>
                <div class="line_chart">
                    <div class="line_chart_bar_wrap">
                        <div class="line_chart_percent">
                            <span>100%</span>
                            <span>90%</span>
                            <span>80%</span>
                            <span>70%</span>
                            <span>60%</span>
                            <span>50%</span>
                            <span>40%</span>
                            <span>30%</span>
                            <span>20%</span>
                            <span>10%</span>
                            <span>0%</span>
                        </div>
                        <div class="line_chart_con">
                            <div class="bg_line">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div class="chart">
                                {this.state.list.map((obj, idx) => (
                                <>
                                    <div class='chart_bar' id={'chart_bar_'+obj[this.props.listIdName]} name="chart_bar"
                                    style={this.props.list.length > 5 ? {opacity: 1, height:obj[this.props.listValueName]+"%", backgroundColor:this.state.chart_color[idx], margin : "0 auto"}
                                                                        : {opacity: 1, height:obj[this.props.listValueName]+"%", backgroundColor:this.state.chart_color[idx],  margin : "0 20px"}}>
                                        {Number(obj[this.props.listValueName]) <= 10
                                        ?<span style={{"transform":"translateY(-20px)","color":"#3a3a3a"}}>{obj[this.props.listValueName]+"%"}</span>
                                        :<span style={{"transform":"translateY(0px)","color":"inherit"}}>{obj[this.props.listValueName]+"%"}</span>}
                                    </div>
                                </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <h5 class="chart_title">{this.props.title}</h5>
                <div class="chart_name_area">
                    {this.state.list.map((obj, idx) => (
                    <>
                        <div class="chart_name" style={{opacity: 1}}>
                            <div style={{backgroundColor:this.state.chart_color[idx]}}></div>
                            <span>{obj[this.props.listTextName]}</span>
                        </div>
                    </>
                    ))}
                </div>
			</>
		);
	}
}

export default LineChart;
import React from 'react';
import ReactDOM from 'react-dom';
import {Root} from './Pages/Root';
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import 'raf/polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import  'core-js' ;

import * as serviceWorker from './serviceWorker';
// 페이지 번역
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationKr from "./translation/kr/translation.json";
import translationEn from "./translation/en/translation.json";

import { store } from './store'
import { Provider } from 'react-redux'

i18n.use(initReactI18next).init({
    resources: {
        kr: translationKr,
        en: translationEn,
    },
    fallbackLng: "kr",
});

ReactDOM.render(<Provider store={store}><Root i18n={i18n} /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
import React from 'react';
import http from '../modules/api';
import ListBox from 'react-listbox';
import 'react-listbox/dist/react-listbox.css';
import '../../css/style.css';

function OperatorInput(props) {

    const user_seq = props.this.state.user_seq;

    if (user_seq != null && user_seq != '') {
        return (
            <>
                <div className="form-group">
                    <label className="col-sm-2 control-label" style={{float:"left"}}>사용자 ID</label>
                    <div className="col-sm-10">
                        <input type="text" id="user_id" className="form-control" style={{width:"250px"}}
                                value={props.this.state.user_id}
                                readOnly/>
                    </div>  
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label" style={{float:"left"}}>사용자 PW</label>
                    <div className="col-sm-10">
                        <button className="greenButton margin-r-5" onClick={props.this.onInitPw}>패스워드 초기화</button>&nbsp;
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className="form-group">
                    <label className="col-sm-2 control-label" style={{float:"left"}}>사용자 ID</label>
                    <div className="col-sm-10">
                        <input type="text" id="user_id" placeholder="아이디를 입력해 주세요." className="form-control" style={{width:"250px", float:"left"}}
                            value={props.this.state.user_id} 
                            onChange={(e) => props.this.handleText(e, 'user_id')}
                            required minLength="3" maxLength="50" />
                        <span className="input-group-btn">
                            <button className="btn btn-primary" onClick={props.this.onClickIdChkButton}>아이디 확인</button>
                        </span>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label" style={{float:"left"}}>사용자 PW</label>
                    <div className="col-sm-10">
                        <input type="password" id="password" placeholder="비밀번호를 입력해 주세요." className="form-control" style={{width:"250px"}}
                            value={props.this.password} 
                            onChange={(e) => props.this.handleText(e, 'password')} 
                            required minLength="6" maxLength="15" />
                    </div>
                    {props.this.passwordRule()}
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label" style={{float:"left"}}></label>
                    <div className="col-sm-10">
                        <input type="password" id="passwordConfirm" placeholder="비밀번호를 다시 입력해 주세요." className="form-control" style={{width:"250px"}}
                            value={props.this.passwordConfirm}
                            onChange={(e) => props.this.handleText(e, 'passwordConfirm')} 
                            required minLength="6" maxLength="15" />
                    </div>
                    {props.this.passwordMatch()}
                </div>
            </>
        );
    }
}

export class Cafe24OperatorAccountManagerDetail extends React.Component {
	
	constructor(props) {
		super(props);
	    this.state = {
			mode: '',
            user_id: '',
            password: '',
			passwordConfirm: '',
			corp_reg_no : '',
			corp_name : '',
			emp_cnt : '',
			user_name : '',			
			corp_addr : '',
			corp_addr2 : '',
			tel_no : '',
			email : '',
			token : '',
			pass_yn : false,
			options : [
				{ label: '슈퍼관리자', value: 'ROLE_ADMIN' },
				{ label: '관리자', value: 'ROLE_MANAGER' },
				{ label: '운영자', value: 'ROLE_OPERATOR' },
				{ label: '자동배너', value: 'ROLE_CAFE24' },
				{ label: '트렌드 리포트', value: 'ROLE_TREND' },
				{ label: '사용자 기본권한', value: 'ROLE_USER' },
			],
			selected : ['ROLE_CAFE24', 'ROLE_USER']
        }
	};
	
	componentDidMount() {
		// uri 파라메터 얻기: this.props.match.params
        const {user_id} = this.props.match.params;
        if (user_id != null && user_id != '') {
			this.getUser(user_id);
			this.setState({mode: 'update'})
        } else {
			this.setState({mode: 'create'})
		}
        //this.getCode("2200");		//권한코드조회
        //운영자의 샵 정보 조회
	};
	
	//공통코드 조회
	// async getCode(cd_class) {
	//     const res = await http.get("/rest/common/roboMdCode?cd_class="+cd_class);
	//     this.setState({options:res.data});
	// };
	
	//사용자 상세 조회
	async getUser(id) {
        const res = await http.get(`/rest/shop/cafe24/operatorFind/${id}`);
        if (res.data.data != null) {
            var corp_addr = res.data.data.corp_addr;
            //기본, 상세주소 자르기
            if (corp_addr != null || corp_addr != '') {
                res.data.data.corp_addr = corp_addr.split('|')[0];
                res.data.data.corp_addr2 = corp_addr.split('|')[1];
            }
            this.setState(res.data.data);
        } else {
            alert("조회된 데이터가 없습니다.");
            window.location.href = "/shop/cafe24/operatorAccountManager";
        }
	};
	
	//권한 변경
	// saveRoles(role_cls) {
	// 	const user_id = this.state.user_id;
	//     http.post("/rest/admin/adminAuthoritiesModify", null, { 
	//     	params:{
	//     			 user_id : user_id
	//     			,role_cls : role_cls
	// 		}
	//     })
	// 	 .then(
	// 			 res => {
	// 				 alert(user_id + "님의 권한변경이 완료되었습니다.");
	// 				 //cafe24 인 경우
	// 				 if (role_cls == 2204) {
	// 					this.userSyncData(user_id);
	// 				 }

	// 			 })
	// 	 .catch(
	// 			 error => { alert("권한변경 오류 발생."); }
	// 	 );
	// };

	//cafe24경우 데이터 동기화 진행
	// userSyncData(user_id) {
	// 	http.post("/rest/admin/adminCafe24UserSyncData", null, { 
	//     	params:{
	//     			 user_id : user_id
	// 		}
	//     })
	// }
	
	//변경 이벤트
	handleText = (e, key) => {
	    // computed property
	    this.setState({[key]: e.target.value});
	    //저장
	    this.saveRoles(e.target.value);
    };
    
    //아이디 중복체크
	onClickIdChkButton = () => {
		
		//1. Validation Check
		if (this.state.user_id == null || this.state.user_id == "") {
			alert("아이디를 입력해 주세요.");
			return false;
		}
		http.get("/rest/common/id/check", {params:{user_id:this.state.user_id}})
	 	 	 .then(res => {
	 	 		 this.setState({pass_yn: res.data.passYn});
	 	 		 alert(res.data.retMsg);
	 	 		 //중복시 입력된 아이디 초기화
	 	 		 if (this.state.pass_yn != true) {
	 	 			this.setState({user_id: ""});
	 	 		 }
	 	 	 })
	 	 	 .catch(err => { 
	 	 		 alert("아이디 중복체크 오류"); 
	 	 	 });
		
    };
	
	//패스워드 초기화
	onInitPw = (e, key) => {
		const user_id = this.state.user_id;
		console.log("user_id : " +  user_id);
		http.post("/rest/admin/adminPwdModify", {user_id:user_id})
			 .then(function (response) {
				 alert(user_id + "님의 패스워드가 '11111'로 초기화 되었습니다.");
			 })
			 .catch(
				 error => { console.log("Data가 없습니다."); }
			 );
    };
	
	// 목록으로 이동 (뒤로가기)
	onGoList = () => {
		//this.props.history.goBack();
		this.props.history.push(`/shop/cafe24/operatorAccountManager`);
	};

	//저장
	onSave = () => {
		http.get("/rest/shop/cafe24/operatorSave")
		.then(res => {
			if (res.status == 200) {
				alert("저장 완료");
			} else {
				alert("저장 실패");
			}
        })
	};

	//수정
	onUpdate = () => {

		const sendForm = {...this.state};
		//1. Validation Check
		if (sendForm.corp_name == null || sendForm.corp_name == "") {
			alert("소속(부서명)을 입력해 주세요.");
			return false;
		}
		if (sendForm.user_name == null || sendForm.user_name == "") {
			alert("사용자명을 입력해 주세요.");
			return false;
		}
		if (sendForm.tel_no == null || sendForm.tel_no == "") {
			alert("연락처를 입력해 주세요.\n(비밀번호 분실시 카카오 알림톡을 수신 가능한 연락처)");
			return false;
		}
		if (sendForm.email == null || sendForm.email == "") {
			alert("이메일 주소를 입력해 주세요.");
			return false;
		}
		//2. 수정
		http.get("/rest/shop/myInfoModify", {params:sendForm})
			.then(
					resultData => {
						alert(resultData.data.msg);
					})
			.catch(
					//error => { console.log("사용자 저장 오류."); }
			);
	};
	
	//삭제
	onDelete = () => {

		//1. 관리자-운영자 매핑 데이터 삭제
		//2. 운영자-상점 매핑 데이터 삭제 (상점별 메뉴권한 포함)
		//3. 운영자 권한, 정보 삭제

		// http.get("/rest/shop/cafe24/operatorSave")
		// .then(res => {
		// 	if (res.status == 200) {
		// 		alert("저장 완료");
		// 	} else {
		// 		alert("저장 실패");
		// 	}
        // })
    };
    
    //input 변경
    handleText = (e, key) => {
	    // computed property
		this.setState({[key]: e.target.value});
    }
    
    //1-1. 패스워드 패턴 체크 메세지
	passwordRule() {
		const { password } = this.state;
		if (password) {
			if (!this.doesPasswordRule(password)) {
				return (	
						<h5 style={{color:'red'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 유효하지 않은 비밀번호 입니다.(6~15자리, 영문, 숫자조합)</h5>
				);
			}
		}
	}
	//1-2. 패스워드 패턴 
	doesPasswordRule(password) {
		const passRule = /^.*(?=.{6,15})(?=.*[0-9])(?=.*[a-zA-Z]).*$/;
		return passRule.test(password);
	}
	//2-1. 패스워드 확인 메세지
	passwordMatch() {
		const { password, passwordConfirm } = this.state;
		if (passwordConfirm) {
			if (!this.doesPasswordMatch(password, passwordConfirm)) {
				if (this.doesPasswordMatch(password, this.setWhiteSpaceRemove(passwordConfirm))) {
					return (
							<h5 style={{color:'red'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 패스워드가 일치하지 않습니다. (공백을 제거해 주세요.)</h5>
					);
				}
				return (
						<h5 style={{color:'red'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 패스워드가 일치하지 않습니다.</h5>
				);
			}
		}
	}
	//2-2. 패스워드 매칭 
	doesPasswordMatch(password, passwordConfirm) {
		return password === passwordConfirm;
    }
    //공백제거
	setWhiteSpaceRemove(str) {
		return str.replace(/(\s*)/g, '')
		// return str.replaceAll(' ', '')
	}
	//권한변경 listbox
	onChange = selectedValues => {
		// handle selected values here
		console.log(selectedValues)
	};
	
	render() {
		return (	
            this.state ?
			<div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
				<div className="form-horizontal m-t">
					<h3>[Shop] 부)운영자 계정목록 - 상세</h3>
					<div className="wrapper wrapper-content animated fadeInRight">
                        <OperatorInput this={this} />
						<div className="form-group">
							<label className="col-sm-2 control-label" style={{float:"left"}}>소속(부서명)</label>
							<div className="col-sm-10">
                                <input type="text" id="corp_name" className="form-control" style={{width:"250px"}}
                                   onChange={(e) => this.handleText(e, 'corp_name')} 
								   value={this.state.corp_name} />
							</div>
						</div>
						<div className="form-group">
							<label className="col-sm-2 control-label" style={{float:"left"}}>사용자명</label>
							<div className="col-sm-10">
                                <input type="text" id="user_name" className="form-control" style={{width:"250px"}}
                                   onChange={(e) => this.handleText(e, 'user_name')} 
								   value={this.state.user_name} />
							</div>
						</div>
						<div className="form-group">
							<label className="col-sm-2 control-label" style={{float:"left"}}>연락처</label>
							<div className="col-sm-10">
                                <input type="text" id="tel_no" className="form-control" style={{width:"250px"}}  placeholder="***-****-****"
                                   onChange={(e) => this.handleText(e, 'tel_no')} 
								   value={this.state.tel_no} />
							</div>
                            <h5 style={{color:'red'}}>* 비밀번호 분실시 카카오 알림톡을 수신 가능한 연락처</h5>
						</div>
						<div className="form-group">
							<label className="col-sm-2 control-label" style={{float:"left"}}>이메일</label>
							<div className="col-sm-10">
                                <input type="text" id="email" className="form-control" style={{width:"250px"}}
                                   onChange={(e) => this.handleText(e, 'email')} 
								   value={this.state.email} />
							</div>
						</div>
						<div className="form-group">
							<div>
								<label className="col-sm-2 control-label" style={{float:"left"}}>기본 주소</label>
								<div className="col-sm-10">
                                    <input type="text" id="corp_addr" className="form-control" style={{width:"250px"}}
                                        onChange={(e) => this.handleText(e, 'corp_addr')} 
										value={this.state.corp_addr} />
								</div>
							</div>
							<div className="m-t">
								<label className="col-sm-2 control-label" style={{float:"left"}}>상세 주소</label>
								<div className="col-sm-10">
                                    <input type="text" id="corp_addr2" className="form-control" style={{width:"250px"}}
                                        onChange={(e) => this.handleText(e, 'corp_addr2')} 
										value={this.state.corp_addr2} />
								</div>
							</div>
						</div>
                        {/* <div className="form-group">
							<label className="col-sm-2 control-label" style={{float:"left"}}>권한 구분</label>
							<div className="col-sm-10">
							</div>
						</div> */}
						<ListBox options={this.state.options} onChange={this.onChange} selected={this.state.selected} />
						<div className="form-group">
							<label className="col-sm-2 control-label" style={{float:"left"}}>사용자 가입 구분</label>
							<div className="col-sm-10">
								<input type="text" id="user_join_cls" className="form-control" style={{width:"250px"}}
								   value={this.state.user_join_cls} 
								   readOnly />
							</div>
						</div>
						<div className="form-group">
							<label className="col-sm-2 control-label" style={{float:"left"}}>생성일자</label>
							<div className="col-sm-10">
								<input type="text" id="reg_date" className="form-control" style={{width:"250px"}}
								   value={this.state.reg_date} 
								   readOnly />
							</div>
						</div>
						<div className="form-group">
							<label className="col-sm-2 control-label" style={{float:"left"}}>수정일자</label>
							<div className="col-sm-10">
								<input type="text" id="mod_date" className="form-control" style={{width:"250px"}}
								   value={this.state.mod_date} 
								   readOnly />
							</div>
						</div>
						<div className="form-group">
							<label className="col-sm-2 control-label" style={{float:"left"}}>마지막 로그인 일자</label>
							<div className="col-sm-10">
								<input type="text" id="last_login_date" className="form-control" style={{width:"250px"}}
								   value={this.state.last_login_date} 
								   readOnly />
							</div>
						</div>
					</div>
				</div>
		        <br/>
				<div className="flexBox margin-b-20" style={{alignItems: "center", justifyContent: "center"}}>
					<div style={{textAlign:"center"}}>
						<button className="greenButton margin-r-5" onClick={this.onGoList}>목록</button>
					</div>
					{
						/*생성 모드*/
						this.state.mode == 'create' ?
						<>
							<div id="btnDel" style={{textAlign:"center", marginLeft:"10px", cursor:"pointer"}}>
								<button className="greenButton margin-r-5" onClick={this.onSave}>저장</button>
							</div>
						</>
						: ''
					}
					{
						/*수정 모드*/
						this.state.mode == 'update' ?
						<>
							<div id="btnDel" style={{textAlign:"center", marginLeft:"10px", cursor:"pointer"}}>
								<button className="greenButton margin-r-5" onClick={this.onUpdate}>수정</button>
							</div>
							<div id="btnDel" style={{textAlign:"center", marginLeft:"10px", cursor:"pointer"}}>
								<button className="greenButton margin-r-5" onClick={this.onDelete}>삭제</button>
							</div>
						</>
						: ''
					}
				</div>
			</div>
			: 'non-data'
		);
	}
	
	componentWillReceiveProps(nextProps, nextContext) {
		this.getUser(nextProps.match.params.user_id);
	}
	
}

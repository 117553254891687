import React from "react";
import $ from 'jquery';

/* css */
import HomePageHeaderStyle from "./HomePageHeader.module.css"

/* GA, Pixel */
import {ga4react, reactPixel} from '../../Menu';

export class HomePageHeader extends React.Component {
    state = {

    }

    componentDidMount() {
        //스크롤 감지
        let scrollTop = 0;
        let prevScrollTop = 0;
        let nowScrollTop = 0;
        let header_height = 0;
        let window_scrollTop = 0;
        let window_height = 0;
        let window_encounter = 0;
        let window_scrollBottom = 0;

        function wheelDelta(){
            return prevScrollTop - nowScrollTop > 0 ? "up" : "down";
        }

        $(window).scroll(function(){
            header_height = $("#hompage_wrap header").height();
            window_scrollTop = $(window).scrollTop();
            window_height = $(window).height();
            window_encounter = window_height * 0.5;
            window_scrollBottom = window_scrollTop + window_height;
    
    
            nowScrollTop = $(this).scrollTop();
            if(wheelDelta() == "down"){
                $("#wrap header").css({
                    "top":-header_height
                });
            }
            if(wheelDelta() == "up"){
                $("#wrap header").css({
                    "top":0
                });
            }
            prevScrollTop = nowScrollTop;
    
            //헤더가 웹사이트 맨위랑 만날 때
            if(nowScrollTop == 0){
                $("#wrap header").css({
                    "top":0
                });
            }
        });

        // 모바일 헤더 클릭 시 스크롤 고정
        $("#menu_m").on("click",function() {
            scrollTop = $(window).scrollTop();

            if($(this).is(":checked")){ // 펼치기
                $("html")
                .addClass('hidden');
            }
            else{ // 접기
                $("html")
                .removeClass('hidden')
                .scrollTop(scrollTop);
            }
            return;
        });

        // 한영 사이트 전환
        $("#lang_m").change(function () {
            if (this.value == 2) {
                window.location.href = "https://robomd.io/en"
            }
        });
        $("#lang").change(function () {
            if (this.value == 2) {
                window.location.href = "https://robomd.io/en"
            }
        });
    }

    /**
     * 페이지가 이동되는 경우 실행되는 함수
     * @param {*} type 
     */
    goOtherPage = (type) => {
        // GA 및 Meta Pixel 적용
        switch(type){
            case "service":
                ga4react.event('gnb_click', 'gnb_service', 'robomd_home');
                reactPixel.trackCustom('[마케팅] GNB_클릭_서비스');
                break;
            case "aboutus":
                ga4react.event('gnb_click', 'gnb_business', 'robomd_home');
                reactPixel.trackCustom('[마케팅] GNB_클릭_회사소개');
                break;
            case "news":
                ga4react.event('gnb_click', 'gnb_news', 'robomd_home');
                reactPixel.trackCustom('[마케팅] GNB_클릭_뉴스');
                break;
            case "aibanner":
                ga4react.event('gnb_click', 'gnb_ai_design', 'robomd_home');
                reactPixel.trackCustom('[마케팅] GNB_클릭_AI디자인');
                break;
            case "login":
                ga4react.event('gnb_click', 'gnb_login', 'robomd_home');
                reactPixel.trackCustom('[마케팅] GNB_클릭_로그인');
                break;
            case "registUser":
                ga4react.event('gnb_click', 'gnb_join', 'robomd_home');
                reactPixel.trackCustom('[마케팅] GNB_클릭_회원가입');
                break;
            case "contactus":
                ga4react.event('gnb_click', 'gnb_contact', 'robomd_home');
                reactPixel.trackCustom('[마케팅] GNB_클릭_문의하기');
                break;
            default:
                return false;
        }
    }

    render () {
        const isLoginPage = ['http://127.0.0.1:3000/login', 'https://robomd.io/login'];
        const currentURL = window.location.href;
        const isLoginPageURL = isLoginPage.includes(currentURL); // 현재 URL이 특정 로그인 페이지 URL인지를 판단하는 변수

        return (
            <div className= {isLoginPageURL ? `${HomePageHeaderStyle.homepage_wrap_login}` : `${HomePageHeaderStyle.homepage_wrap}`}> 
                <header className={isLoginPageURL ? `${HomePageHeaderStyle.homepage_wrap_header_login}` : `${HomePageHeaderStyle.homepage_wrap_header}`}>
                    <div className={isLoginPageURL ? `${HomePageHeaderStyle.header_wrap_login}` : `${HomePageHeaderStyle.header_wrap}` }>
                        <div className={HomePageHeaderStyle.header_left}>
                            <h1 className={HomePageHeaderStyle.logo}>
                                <a href="https://robomd.io/home.html" onClick={this.goOtherPage}>
                                    <img src="https://robomd.io/images/home/logo.svg" alt="로고"/>
                                </a>
                            </h1>
                            <ul className={HomePageHeaderStyle.gnb}>
                                <li>
                                    <a href="https://robomd.io/sub/services.html" className={HomePageHeaderStyle.go_services} onClick={() => this.goOtherPage("service")}>서비스</a>
                                </li>
                                <li>
                                    <a href="https://robomd.io/sub/aboutus.html" className={HomePageHeaderStyle.go_aboutus} onClick={() => this.goOtherPage("aboutus")}>회사소개</a>
                                </li>
                                <li>
                                    <a href="https://robomd.io/sub/news.html" className={HomePageHeaderStyle.go_news} onClick={() => this.goOtherPage("news")}>뉴스</a>
                                </li>
                                <li>
                                    <a href="/common/promo_aibanner" className={HomePageHeaderStyle.go_roboimage} onClick={() => this.goOtherPage("aibanner")}>AI 디자인</a>
                                </li>
                            </ul>
                        </div>
                        <div className={isLoginPageURL ? `${HomePageHeaderStyle.header_right_login} ${HomePageHeaderStyle.header_right}` : `${HomePageHeaderStyle.header_right}`}>
                            {
                                isLoginPageURL
                                ? <div className={HomePageHeaderStyle.header_right_login_inner}>
                                    <select className={HomePageHeaderStyle.lang} id="lang">
                                        <option value="" disabled selected>Language</option>
                                        <option value="1">KR</option>
                                        <option value="2">EN</option>
                                    </select>
                                    <a href="https://robomd.io/login" onClick={() => this.goOtherPage("login")}>
                                        <button className={HomePageHeaderStyle.login+" "+HomePageHeaderStyle.go_login+" "+HomePageHeaderStyle.hover_white_mint}>로그인</button>
                                    </a>
                                    <a href="https://robomd.io/common/registUser" onClick={() => this.goOtherPage("registUser")}>
                                        <button className={HomePageHeaderStyle.join+" "+HomePageHeaderStyle.go_join+" "+HomePageHeaderStyle.hover_white_mint}>회원가입</button>
                                    </a>
                                    <a href="https://robomd.io/sub/contactus.html" onClick={() => this.goOtherPage("contactus")}>
                                        <button className={HomePageHeaderStyle.contact_us+" "+HomePageHeaderStyle.go_contact_us}>문의하기</button>
                                    </a>
                                </div>
                                : <>
                                    <select className={HomePageHeaderStyle.lang} id="lang">
                                        <option value="" disabled selected>Language</option>
                                        <option value="1">KR</option>
                                        <option value="2">EN</option>
                                    </select>
                                    <a href="https://robomd.io/login" onClick={() => this.goOtherPage("login")}>
                                        <button className={HomePageHeaderStyle.login+" "+HomePageHeaderStyle.go_login+" "+HomePageHeaderStyle.hover_white_mint}>로그인</button>
                                    </a>
                                    <a href="https://robomd.io/common/registUser" onClick={() => this.goOtherPage("registUser")}>
                                        <button className={HomePageHeaderStyle.join+" "+HomePageHeaderStyle.go_join+" "+HomePageHeaderStyle.hover_white_mint}>회원가입</button>
                                    </a>
                                    <a href="https://robomd.io/sub/contactus.html" onClick={() => this.goOtherPage("contactus")}>
                                        <button className={HomePageHeaderStyle.contact_us+" "+HomePageHeaderStyle.go_contact_us}>문의하기</button>
                                    </a>
                                </>
                            }
                        </div>
                        <div className={HomePageHeaderStyle.header_right_m}>
                            <input type="checkbox" id="menu_m"/>
                            <label for="menu_m">
                                <span></span>
                                <span></span>
                                <span></span>
                            </label>
                            <div className={HomePageHeaderStyle.menu_m}>
                                <ul className={HomePageHeaderStyle.gnb_m}>
                                    <li>
                                        <a href="https://robomd.io/sub/services.html" className={HomePageHeaderStyle.go_services} onClick={() => this.goOtherPage("service")}>서비스</a>
                                    </li>
                                    <li>
                                        <a href="https://robomd.io/sub/aboutus.html" className={HomePageHeaderStyle.go_aboutus} onClick={() => this.goOtherPage("aboutus")}>회사소개</a>
                                    </li>
                                    <li>
                                        <a href="https://robomd.io/sub/news.html" className={HomePageHeaderStyle.go_news} onClick={() => this.goOtherPage("news")}>뉴스</a>
                                    </li>
                                    <li>
                                        <a href="/common/promo_aibanner" className={HomePageHeaderStyle.go_roboimage} onClick={() => this.goOtherPage("aibanner")}>AI 디자인</a>
                                    </li>
                                    <li className={HomePageHeaderStyle.login_m}>
                                        <a href="https://robomd.io/login" className={HomePageHeaderStyle.go_login} onClick={() => this.goOtherPage("login")}>
                                            <span>로그인</span>
                                        </a>
                                    </li>
                                    <li className={HomePageHeaderStyle.join_m}>
                                        <a href="https://robomd.io/common/registUser" className={HomePageHeaderStyle.go_join} onClick={() => this.goOtherPage("registUser")}>
                                            <span>회원가입</span>
                                        </a>
                                    </li>
                                    <li className={HomePageHeaderStyle.login_m+" "+HomePageHeaderStyle.contact_us_m}>
                                        <a href="https://robomd.io/sub/contactus.html" className={HomePageHeaderStyle.go_contact_us} onClick={() => this.goOtherPage("contactus")}>
                                            <span>문의하기</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        )
    }
}

export default HomePageHeader;
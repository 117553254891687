import { React, Component } from 'react';
import '../../resources/fb/css/reset.css';
import '../../resources/fb/css/master.css';
import '../../resources/fb/css/bootstrap.min.css';
import '../../resources/fb/css/master.css';
import '../../resources/fb/css/trend_all.css';
import '../../resources/fb/css/responsive.css';
import axios from 'axios';
import DatePicker from "react-datepicker";

class Search extends Component {

    constructor(props) {
        super(props);

        var today = new Date();
        var date = new Date(Number(today.getFullYear()), Number(today.getMonth()), Number(today.getDate()))
        this.state = {
            from_date: date,
            to_date: date, 
            last_date: date,
            goods_name: '',
            shop_seq: '',
            category_code: '',
            min_price: '',
            max_price: '',

            shop_list: [],
            category_list: [],
            goods_list: [],
        };
    }

    componentDidMount() {
        axios.get('/rest/fb/getCategoryList').then(
            response => {
                this.setState({
                    category_list: response.data.list
                });
            }
        );

        axios.get('/rest/fb/getShopList').then(
            response => {
                this.setState({
                    shop_list: response.data.list
                });
            }
        );

        axios.get('/rest/fb/getLastDate').then(
            response => {
                var yyyyMMdd = String(response.data.lastDate);
                var yyyy = yyyyMMdd.substring(0,4);
                var mm = yyyyMMdd.substring(4,6);
                var dd = yyyyMMdd.substring(6,8);
                var date = new Date(Number(yyyy), Number(mm)-1, Number(dd))
                this.setState({
                    from_date: date,
                    to_date: date,
                });
            }
        )
    }

    get_category_list() {
        return this.state.category_list.map((item, i) => 
            <option value={item.cd_code}>{item.cd_desc}</option>
        );
    }

    get_shop_list() {
        return this.state.shop_list.map((item, i) => 
            <option value={item.shop_seq}>{item.shop_name}</option>
        );
    }

    onClickShop = (e) => {
        this.setState({
            shop_seq: e.target.value,
        });
    }

    onClickCategory = (e) => {
        this.setState({
            category_code: e.target.value,
        });
    }

    onHandleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    onSelectFromDate = (date) => {
        this.setState({
            from_date: date,
        });
    }

    onSelectToDate = (date) => {
        this.setState({
            to_date: date,
        });
    }

    convert_date = (date) => {
        var year = date.getFullYear();
        var month = (1 + date.getMonth());
        month = month >= 10 ? month : '0' + month;
        var day = date.getDate();
        day = day >= 10 ? day : '0' + day;
        var date_message = String(year) + String(month) + String(day)

        return date_message
    }

    get_list = () => {
        if( this.state.goods_name.length < 2 ) {
            alert('검색하려는 상품이름을 2자이상 입력해주세요.')
            return;
        }

        var from_date = this.convert_date(this.state.from_date);
        var to_date = this.convert_date(this.state.to_date);

        var url = '/rest/fb/getSearchGoods'
        url += '?from_date=' + from_date
        url += '&to_date=' + to_date
        url += '&goods_name=' + this.state.goods_name
        url += '&shop_seq=' + this.state.shop_seq
        url += '&min_price=' + this.state.min_price
        url += '&max_price=' + this.state.max_price
        url += '&category_code=' + this.state.category_code
		axios.get(url).then(response => {
            this.setState({
                goods_list: response.data.list,
            });
        });
    }

    make_number = (price) => {
        try {
            return Number(price).toLocaleString()
        } catch (e) {
            return "-1"
        }
    }

    make_price = (item) => {
        if( item.max_item_price === item.max_item_sale_price ) return;
        return <strike>{this.make_number(item.max_item_price)}</strike>
    }

    make_sale_percent = (item) => {
        if( item.max_item_price === item.max_item_sale_price ) return;
        return <span>{Math.round((1-(parseInt(item.max_item_sale_price)/parseInt(item.max_item_price)))*100)}%</span>
    }

    make_display = (item) => {
        return  <div class="text-area">
                    <h3>{item.shop_name}<br/>{item.goods_name}</h3>
                    <div class="price">
                        {this.make_price(item)}&nbsp;
                        {this.make_number(item.max_item_sale_price)}&nbsp;
                        {this.make_sale_percent(item)}
                    </div>
                </div>
    }

    make_goods_list = () => {
        if( this.state.goods_list.length === 0 ) return '조건에 해당하는 결과가 없습니다.'
        return this.state.goods_list.map((item, i) => 
            <li>
                <div class="cont_md">
                    <img width="100%" height="350px" src={item.img_url} alt={item.goods_name} />
                </div>
                <div class="cont_bt">
                    <p>{item.shop_name}<br/>{item.goods_name}</p>
                    <p>
                    {this.make_price(item)}&nbsp;
                    {this.make_number(item.max_item_sale_price)}&nbsp;
                    {this.make_sale_percent(item)}
                    </p>
                </div>
            </li>
        );
    }

    render() {
        const goods_list = this.make_goods_list()
        return (
        <section>
            <div class="container-fluid bg-gr">
                <div class="row">
                    {/* <div class="col-md-2 menu_area"></div> */}
                    <div class="col-md-10 contents_area search products_wrap">
                        <div class="content_area">
                            <div class="content_title">
                                <h1>상품검색</h1>
                            </div>
                            <div class="content_header">

                                <div class="category_wrap">
                                    <p style={{marginBottom: 0}}>등록일</p>
                                    <DatePicker name="from_date" id="from_date"
                                        selected={this.state.from_date}
                                        onSelect={this.onSelectFromDate}
                                        placeholderText="일자"
                                        dateFormat="yyyy-MM-dd"
                                        autoComplete="off"
                                        showYearDropdown
                                        showMonthDropdown
                                        todayButton="today"
                                    />
                                    <label for="from_date">
                                        <i></i>
                                    </label>
                                    <span>~</span>
                                    <DatePicker name="to_date" id="to_date"
                                        selected={this.state.to_date}
                                        onSelect={this.onSelectToDate}
                                        placeholderText="일자"
                                        dateFormat="yyyy-MM-dd"
                                        autoComplete="off"
                                        showYearDropdown
                                        showMonthDropdown
                                        todayButton="today"
                                    />
                                    <label for="to_date">
                                        <i></i>
                                    </label>
                                </div>
                            </div>
                            <div class="search-wrap">
                                <div class="search-area">
                                    <input type="search" name="goods_name" placeholder="상품 이름을 입력해 주세요." value={this.state.goods_name} onChange={this.onHandleChange} />
                                </div>
                                <div class="select-wrap">
                                    <label for="">
                                        <p style={{marginBottom: 0}}>쇼핑몰</p>
                                        <div>
                                            <select onChange={this.onClickShop}>
                                                <option value="">전체 쇼핑몰</option>
                                                {this.get_shop_list()}
                                            </select>
                                        </div>
                                    </label>
    
                                    <label for="">
                                        <p style={{marginBottom: 0}}>카테고리</p>
                                        <div>
                                            <select onChange={this.onClickCategory}>
                                                <option value="">전체 카테고리</option>
                                                {this.get_category_list()}
                                            </select>
                                        </div>
                                    </label>
    
                                    <label for="">
                                        <p style={{marginBottom: 0}}>가격대</p>
                                        <div class="select-conts">
                                            <input type="text" name="min_price" placeholder="최저가" value={this.state.min_price} onChange={this.onHandleChange} />~
                                            <input type="text" name="max_price" placeholder="최고가" value={this.state.max_price} onChange={this.onHandleChange} />
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div class="search-btn">
                                <button type="button" onClick={this.get_list}>위 조건에 맞게 검색하기</button>
                            </div>
                            <div>
    
    
                                <div class="products_container">
                                    <ul class="products">
                                        {goods_list}
                                    </ul>
                                    <div id="container" class="chart chart01"></div>
                                </div>
                                <div class="no-search" style={{display: 'none'}}>
                                    검색 결과가 이곳에 노출 됩니다.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        );
    }
}

export default Search;
import React from 'react';
import http from './api';
import FileDownload from 'js-file-download';

class FileDownloadComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            url: "",
            obj: [],
            val1: "",
        }
	}

    //엑셀 다운로드 테스트
    fnExcelDown = (e) => {
 
        if (this.props.val1 == null || (this.props.val1 != null && this.props.val1.length ==0)) {
            alert(this.props.msg1);
            return false;
        }

        //var fileNm = "AAA.xlsx";
        //var headerList  = [['사전키워드', '관련키워드',    '월간 PC 검색수',    '월간 모바일 검색수', 	  '월평균 PC 클릭수',   '월평균 모바일 클릭수',     '월평균 PC 클릭률',       '월평균 모바일 클릭률',        '월간노출광고수', '경쟁정도', '최초수집일',   '마지막수집일']]
        //var dataIdsList = ""; //;[['keyword', 'relKeyword', 'monthlyPcQcCnt', 'monthlyMobileQcCnt', 'monthlyAvePcCtr', 'monthlyAveMobileCtr', 'monthlyAvePcClkCnt', 'monthlyAveMobileClkCnt', 'plAvgDepth', 'compIdx', 'reg_date', 'mod_date']]

        //$(".btnAddNaverKeyword").click(function() {}); // 이벤트 제거
        http.post(this.props.url,
                {
                    list   : this.props.obj
                },
                {
                    responseType: 'arraybuffer'
                }
            )
            .then(res => {
                    var fileName = res.headers['content-disposition'].split('fileName=')[1].replace('"', '').replace(';', '');
                    var blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    FileDownload(blob, decodeURI(fileName));
                    alert("엑셀 다운로드 성공");
            })
            .catch(error => {
                console.log (error);
            });
    }

    render () {
        return (
            <>
                <button id="btnExcelDownload" className="greenButton" style={{width:"140px", height:"36px", float:"right"}} onClick={this.fnExcelDown}>
                    엑셀 다운로드
                </button>
            </>
        )
    }

}

export default FileDownloadComponent;
import React from 'react';
import axios from 'axios';
import http from './modules/api';
import { setCookie, getCookie } from "./modules/utils/CookieUtil";
import { deleteCookie } from "./modules/utils/CookieUtil";

export class Home extends React.Component {

	componentDidMount() {
	    this.checkUser();
	}

	checkUser = () => {
		axios
			.get("/userSessionType")
			.then(
				resultData => {
					this.setState({sessionType: resultData.data});
					console.log("####### sessionType : " + this.state.sessionType);
					//console.log(this.state.sessionType);
					//alert(this.state.sessionType.sessionType + " / " + sessionStorage.getItem("referrer"));
					if (this.state.sessionType.sessionType == "admin") {
						//alert("1");
						if (sessionStorage.getItem("referrer") != null && sessionStorage.getItem("referrer").indexOf("admin") > 0 
								&& sessionStorage.getItem("referrer").indexOf(document.domain) > 0) {
							//alert("2 : " + sessionStorage.getItem("referrer"));
							window.location.href = sessionStorage.getItem("referrer");
						} else {
							//alert("3 : " + this.state.sessionType.redirectUrl);
							window.location.href = this.state.sessionType.redirectUrl;
						}
					} else if (this.state.sessionType.sessionType == "shop") {
						// window.location.href = "/shop/main"
						// 마케팅(비회원 접근) 로보 이미지 프롬프트값이 쿠키에 저장되어있으면, 로보 이미지 결과 페이지로 이동
						if(getCookie("non_members_robo_img_prompt_"+resultData.data.user_id)){
							if(getCookie("non_members_robo_img_design_yn_"+resultData.data.user_id) == "Y"){
								window.location.href = '/common/promo_aibanner/result';
							} else{
								window.location.href = '/common/promo_roboimage/result';
							}
						} else if(getCookie("regist_solution_type_cd_09900_"+resultData.data.user_id) == "Y"){
							// 비연동형 계정 전환 작업
							this.changeUninstallShop(resultData.data.user_id);
						} else if (sessionStorage.getItem("referrer") != null && sessionStorage.getItem("referrer").indexOf("shop") > 0 
								&& sessionStorage.getItem("referrer").indexOf(document.domain) > 0) {
							window.location.href = sessionStorage.getItem("referrer");
						} else {
							if (resultData.data.roleType == "2204" && resultData.data.roleType == "2207" && document.referrer.indexOf("/login") > 0) { // 배너 고객이면서 login 페이지에서 넘어온 경우.
								this.isAddedShopsByUserSeq(); // 3000 -> 8092 포트에서는 실행 하지 않음 => 이유: document.referrer 체크를 제대로 할 수 없음. bootRun에서는 잘됨. (8090)
							} else {
								window.location.href = this.state.sessionType.redirectUrl;
							}
						}
					} else if (this.state.sessionType.userName == "anonymousUser") {
						//alert("#home : 로그인 후 사용해 주세요.");
						sessionStorage.removeItem("user_id");
						sessionStorage.removeItem("userName");
						sessionStorage.removeItem("roleType");
						sessionStorage.removeItem("serviceType");
						sessionStorage.removeItem("redirectUrl");
						sessionStorage.removeItem("solution_type_cd");
						sessionStorage.removeItem("RP_LIST_OPERATION_BANNER");
						deleteCookie("user_id");
						deleteCookie("solution_type_cd");
						window.location.href = "/login";
					} else if (this.state.sessionType.sessionType == "none") {
						sessionStorage.removeItem("user_id");
						sessionStorage.removeItem("userName");
						sessionStorage.removeItem("roleType");
						sessionStorage.removeItem("serviceType");
						sessionStorage.removeItem("redirectUrl");
						sessionStorage.removeItem("solution_type_cd");
						sessionStorage.removeItem("RP_LIST_OPERATION_BANNER");
						deleteCookie("user_id");
						deleteCookie("solution_type_cd");
						
						if (this.state.sessionType.user_del_yn == "Y") { 
							alert("탈퇴한 사용자 입니다.");
							window.location.href = "/logout";
						} else if (this.state.sessionType.email_auth_yn == "N") {
							// 이메일 발송 후 페이지 리다이렉트
							this.sendAuthEmail();
						} else {
							alert("관리자의 승인 후 사용이 가능합니다.");
							window.location.href = "/logout";
						}
						
					} else if (this.state.sessionType.sessionType == null) {
						alert("관리자의 승인 후 사용이 가능합니다.");
						sessionStorage.removeItem("user_id");
						sessionStorage.removeItem("userName");
						sessionStorage.removeItem("roleType");
						sessionStorage.removeItem("serviceType");
						sessionStorage.removeItem("redirectUrl");
						sessionStorage.removeItem("solution_type_cd");
						sessionStorage.removeItem("RP_LIST_OPERATION_BANNER");
						deleteCookie("user_id");
						deleteCookie("solution_type_cd");
						window.location.href = "/logout";
					}

					sessionStorage.setItem("user_id", resultData.data.user_id);
					sessionStorage.setItem("userName", resultData.data.userName);
					sessionStorage.setItem("roleType", resultData.data.roleType);
					sessionStorage.setItem("serviceType", resultData.data.service_type);
					sessionStorage.setItem("redirectUrl", resultData.data.redirectUrl);
					sessionStorage.setItem("solution_type_cd", resultData.data.solution_type_cd);
					setCookie("user_id", resultData.data.user_id);
					setCookie("solution_type_cd", resultData.data.solution_type_cd);
				})
			.catch(
				error => { 
					// console.log(error);
					if (!this.props.logged && !window.location.pathname.includes("login")) {
						sessionStorage.removeItem("user_id");
						sessionStorage.removeItem("userName");
						sessionStorage.removeItem("roleType");
						sessionStorage.removeItem("serviceType");
						sessionStorage.removeItem("redirectUrl");
						sessionStorage.removeItem("solution_type_cd");
						sessionStorage.removeItem("RP_LIST_OPERATION_BANNER");
						deleteCookie("user_id");
						deleteCookie("solution_type_cd");
						window.location.href = "/logout";
					}
				}
			);
		
//		history.push("/login");
		//window.location.href = "/login";
		
	};

	// 이메일 발송
	sendAuthEmail = () => {
		
		let body = {
            // user_id : this.state.user_id,
        }

        const config = {
            headers: {
                'content-type': 'application/json;'
            }
        }
			
        http
        .post("/rest/common/email/verify", body, config)
        .then(
            resultData => {		
                if (resultData.data.uuid != null
                    && resultData.data.uuid != "") {
                        // 이메일 인증 페이지로 이동 (회원가입 대기 페이지)
		                window.location.href = "/common/RegistConfirm?email_uid="+resultData.data.uuid;
                }
            })
        .catch(
            error => { 
                console.log(error);
            }
        );
	};

	/**
	 * 상점 추가 여부 체크 후 리다이렉트
	 */
	isAddedShopsByUserSeq () {
		axios
			.get("/rest/shop/isAddedShops")
			.then(
				resultData => {
					if(resultData.data.is_added_shops == "N") { // 상점 추가를 하지 않은 경우
						sessionStorage.setItem("redirectUrl", resultData.data.redirectUrl);
						window.location.href = resultData.data.redirectUrl; // 상점 추가로 이동
					} else { 
						window.location.href = this.state.sessionType.redirectUrl; // 메인으로 이동
					}
				});
	}

	/** 비연동형 계정으로 전환 */
    changeUninstallShop = (user_id) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        http.post("/rest/shop/uninstall/shopAdd/01001", {}, config)
        .then(res => {
            if(res.data.code != "200"){
                alert("쇼핑몰 미보유 계정으로 전환 실패하였습니다.");
                window.location.href = "/";
                return false;
            }
            // solution_type_cd 쿠키값 비연동형으로 설정
            setCookie('solution_type_cd','09900');
			deleteCookie("regist_solution_type_cd_09900_"+user_id);
			this.checkUser();
        })
        .catch(error => {
            alert("쇼핑몰 미보유 계정으로 전환 실패하였습니다.");
            window.location.href = "/";
        });
    }

	render() {
		return (
				<>
					{/* [Home] works. */}
				</>
		);
	}
	
}

import React from 'react';
import { Helmet } from 'react-helmet';

// 스타일
import styles from './RoboImage.module.css';
import * as StringUtil from '../modules/utils/StringUtil';
import HomePageHeader from '../modules/header/HomePageHeader';

// 컴포넌트
import RoboImagePopup from './component/RoboImagePopup';
import { RoboImageVideoPopup } from './component/RoboImageVideoPopup';

import {ga4react, reactPixel} from '../Menu'
const prompt_max_length = 1900; // 프롬프트 최대 길이 : 인코드 한 값이 1900자가 넘지 못하도록 제한

export class RoboImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popupDisplay: false, // 팝업창 열기/닫기 : true/false
            prompt : '',
        }
    }

    /** 무료로 이미지 제작 클릭 이벤트 */
    handleFreeStartOnClick = () => {
        ga4react.event('btn_click', 'btn_input_prompt', 'roboimage_promo');
		reactPixel.trackCustom('roboimage_promo_input prompt');

        const prompt = this.state.prompt.replaceAll(/[\[\]:]/g, ""); // 프롬프트의 대괄호와 콜론을 빈문자로 바꿔서 전달
        this.setState({prompt : prompt});
        
        if(StringUtil.checkEmptyString(prompt)){
            alert("입력하신 내용이 없습니다. 아이디어를 입력해 주세요.");
            this.setState({prompt : ""});
            return false;
        }

        // 스크롤 막기
        document.body.style.overflow = 'hidden';

        // 팝업창 열기
        this.setState({popupDisplay: true});
    }

    handleIntroService = () => {
        // 스크롤 막기
        document.body.style.overflow = 'hidden';

        // 팝업창 열기
        this.setState({videoPopupDisplay: true});
    }

    /** 팝업창 닫기 */
    handlePopupClose = (popup) => {
        // 스크롤 풀기
        document.body.style.overflow = 'auto';

        // 팝업창 닫기
        this.setState({[popup]: false});
    }

    /** 프롬프트 입력 */
    handleChangePrompt = (e) => {
        let prompt = e.target.value.substring(0, 220);
        while(encodeURIComponent(prompt).length > prompt_max_length){
            prompt = prompt.slice(0, -1);
        }

        this.setState({prompt : decodeURIComponent(prompt)});
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>[로보MD] AI 무료 이미지 생성</title>
                    <meta name="description" content={"아이디어 입력만으로 순식간에 저작권 걱정 없는 AI 이미지를 생성해 보세요. 지금 회원가입 시 14일 무료 체험 혜택을 드려요."}/>
                    <meta name="keywords" content={"AI이미지생성, 생성형AI, 로보MD, 배경제거, 무료사진, 인공지능이미지, AI스타트업, 무료이미지, AI프로필, 배너, 이미지"}/>
                    <meta property="og:title" content={"[로보MD] AI 무료 이미지 생성"} />
                    <meta property="og:url" content={"robomdaiimage.com/common/promo_roboimage"} />
                    <meta property="og:description" content={"아이디어 입력만으로 순식간에 저작권 걱정 없는 AI 이미지를 생성해 보세요. 지금 회원가입 시 14일 무료 체험 혜택을 드려요."} />
                    <meta property="og:image" content={"https://s3.ap-northeast-2.amazonaws.com/otdeal.file/og_image/roboimage+og.jpg"} />
                </Helmet>
                <HomePageHeader />
                <div className={[styles.roboimage_intro, "roboimage_style_guide"].join(' ')}>
                    <img className={styles.bg} src='/img/roboimage/intro_bg.jpg' alt='로보이미지 인트로 배경이미지'/>
                    <div className={styles.content}>
                        <img className={styles.character} src='/img/roboimage/intro_character.svg' alt='로보이미지 캐릭터'/>
                        <h2>
                            <span className={styles.regular}>아이디어만으로</span> <br/>
                            <span className={styles.bold}>AI 이미지 </span> <br className={styles.mobile_br}/>
                            <span className={styles.underline}>1분</span>
                            <span className={styles.large}> 자동 완성!</span>
                        </h2>
                        <h3>
                            <span>로보MD는 AI를 통해 각종 이미지 및 디자인을 <br className={styles.mobile_br}/>자동 제작해 드려요.</span> <br/>
                            <span>상품 이미지가 없어도, 포토샵을 할 줄 몰라도 <br className={styles.mobile_br}/>고민하실 필요 없어요.</span>
                        </h3>
                        <div>
                            <textarea className={styles.textarea} placeholder='상품, 특징, 위치, 환경, 주변 소품 등을 포함하여 &#13;&#10;자세하게 설명할수록 멋진 이미지가 나와요 :)' value={this.state.prompt} onChange={e => this.handleChangePrompt(e)}></textarea>
                            <div className={styles.textarea_info}>{"대괄호([ ])와 콜론(:)은 입력할 수 없어요."}</div>
                        </div>
                        <div className={styles.button_wrap}>
                            <button className={styles.service_intro_btn} onClick={this.handleIntroService}>
                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7473 9.41498 20.7192 6.93661 18.8913 5.10872C17.0634 3.28084 14.585 2.25273 12 2.25ZM12 20.25C10.3683 20.25 8.77326 19.7661 7.41655 18.8596C6.05984 17.9531 5.00242 16.6646 4.378 15.1571C3.75358 13.6496 3.5902 11.9908 3.90853 10.3905C4.22685 8.79016 5.01259 7.32015 6.16637 6.16637C7.32016 5.01259 8.79017 4.22685 10.3905 3.90852C11.9909 3.59019 13.6497 3.75357 15.1571 4.37799C16.6646 5.00242 17.9531 6.05984 18.8596 7.41655C19.7661 8.77325 20.25 10.3683 20.25 12C20.2475 14.1873 19.3775 16.2843 17.8309 17.8309C16.2843 19.3775 14.1873 20.2475 12 20.25ZM15.4163 11.3756L10.9163 8.37562C10.8033 8.30025 10.672 8.25696 10.5363 8.25039C10.4006 8.24382 10.2657 8.27421 10.146 8.33831C10.0263 8.40241 9.9262 8.49782 9.85645 8.61436C9.78671 8.7309 9.74992 8.86419 9.75 9V15C9.74992 15.1358 9.78671 15.2691 9.85645 15.3856C9.9262 15.5022 10.0263 15.5976 10.146 15.6617C10.2657 15.7258 10.4006 15.7562 10.5363 15.7496C10.672 15.743 10.8033 15.6998 10.9163 15.6244L15.4163 12.6244C15.5191 12.5559 15.6035 12.4631 15.6618 12.3542C15.7202 12.2452 15.7507 12.1236 15.7507 12C15.7507 11.8764 15.7202 11.7548 15.6618 11.6458C15.6035 11.5369 15.5191 11.4441 15.4163 11.3756ZM11.25 13.5984V10.4062L13.6481 12L11.25 13.5984Z" fill="#333333"/></svg>
                                서비스 소개 영상
                            </button>
                            <button onClick={this.handleFreeStartOnClick} className={[styles.free_start_btn, "hover_mint"].join(' ')}>무료로 이미지 제작</button>
                        </div>
                        {/* 애니메이션 홍보 영역 */}
                        <div className={styles.promotion}></div>
                    </div>
                </div>

                {this.state.popupDisplay
                && <RoboImagePopup
                        display={this.state.popupDisplay}
                        prompt={this.state.prompt}
                        close={() => this.handlePopupClose("popupDisplay")}
                    />
                }
                {this.state.videoPopupDisplay
                && <RoboImageVideoPopup
                        close={() => this.handlePopupClose("videoPopupDisplay")}
                    />
                }
            </>
        )
    }
}
import React from 'react';
import Slider from 'react-slick';

// 스타일
import '../../css/login_style.css';

// 이미지
import marketingImg1 from '../../resources/img/marketing_img_1.jpg';
import marketingImg2 from '../../resources/img/marketing_img_2.jpg';
import marketingImg3 from '../../resources/img/marketing_img_3.jpg';

export class MarketingArea extends React.Component {
    state = {
        sliderSetting: {
            dots: true, // 페이지 컨트롤 디자인
            arrows:false, // 화살표
            infinite: true, // 무한 루프
            autoplay: true, // 자동 재생
            speed: 500, // 전환 속도
            autoplaySpeed: 3000, // 전환 텀
            slidesToShow: 1, // 전환 개수
            slidesToScroll: 1 // 스크롤 시 전환 개수
        }
    }

    render () {
        return (
            <>
                <div className='only_marketing_area login_style_guide'>
                    <button className='sample_banner_btn hover_white'>
                        <div>FREE</div>
                        <span>샘플 배너 만들기 무료 체험</span>
                        <svg viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.9383 9.49923C12.9362 9.64832 12.8786 9.79127 12.7766 9.90001L7.15155 15.525C7.04226 15.6147 6.90351 15.6605 6.7623 15.6536C6.62108 15.6467 6.48749 15.5875 6.38752 15.4875C6.28754 15.3875 6.22833 15.2539 6.22139 15.1127C6.21445 14.9715 6.26029 14.8327 6.34999 14.7234L11.5812 9.49923L6.34999 4.27501C6.26029 4.16572 6.21446 4.02697 6.22139 3.88575C6.22833 3.74454 6.28754 3.61095 6.38752 3.51097C6.48749 3.411 6.62108 3.35178 6.7623 3.34484C6.90352 3.33791 7.04226 3.38375 7.15155 3.47345L12.7766 9.09845C12.8786 9.20719 12.9362 9.35014 12.9383 9.49923Z" fill="#515151"/></svg>
                    </button>
                    <button className='service_info_btn hover_white'>
                        <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.27344 9.53906C6.15637 9.42096 6.09101 9.26119 6.09174 9.09489C6.09248 8.9286 6.15924 8.76941 6.27734 8.65234C6.39545 8.53528 6.55522 8.46992 6.72151 8.47065C6.8878 8.47138 7.04699 8.53815 7.16406 8.65625L9.375 10.8672V3.625C9.375 3.45924 9.44085 3.30027 9.55806 3.18306C9.67527 3.06585 9.83424 3 10 3C10.1658 3 10.3247 3.06585 10.4419 3.18306C10.5592 3.30027 10.625 3.45924 10.625 3.625V10.8672L12.8359 8.65625C12.8939 8.59777 12.9628 8.55128 13.0388 8.51944C13.1147 8.48759 13.1961 8.47101 13.2785 8.47065C13.3608 8.47029 13.4424 8.48615 13.5186 8.51732C13.5949 8.5485 13.6642 8.59438 13.7227 8.65234C13.7811 8.71031 13.8276 8.77923 13.8595 8.85516C13.8913 8.93109 13.9079 9.01256 13.9083 9.09489C13.9086 9.17723 13.8928 9.25884 13.8616 9.33505C13.8304 9.41126 13.7845 9.48058 13.7266 9.53906L10.4453 12.8203C10.3258 12.9358 10.1662 13.0004 10 13.0004C9.83382 13.0004 9.67415 12.9358 9.55469 12.8203L6.27344 9.53906ZM16.875 11.75C16.7092 11.75 16.5503 11.8158 16.4331 11.9331C16.3158 12.0503 16.25 12.2092 16.25 12.375V16.75H3.75V12.375C3.75 12.2092 3.68415 12.0503 3.56694 11.9331C3.44973 11.8158 3.29076 11.75 3.125 11.75C2.95924 11.75 2.80027 11.8158 2.68306 11.9331C2.56585 12.0503 2.5 12.2092 2.5 12.375V16.75C2.5 17.0815 2.6317 17.3995 2.86612 17.6339C3.10054 17.8683 3.41848 18 3.75 18H16.25C16.5815 18 16.8995 17.8683 17.1339 17.6339C17.3683 17.3995 17.5 17.0815 17.5 16.75V12.375C17.5 12.2092 17.4342 12.0503 17.3169 11.9331C17.1997 11.8158 17.0408 11.75 16.875 11.75Z" fill="#515151"/></svg>
                        <span>서비스 소개서 다운로드</span>
                    </button>
                    <div className='marketing_area_slider'>
                        <Slider {...this.state.sliderSetting}>
                            <div><img src={marketingImg1} alt='마케팅 이미지 1'/></div>
                            <div><img src={marketingImg2} alt='마케팅 이미지 2'/></div>
                            <div><img src={marketingImg3} alt='마케팅 이미지 3'/></div>
                        </Slider>
                    </div>
                </div>
            </>
        )
    }
}
import { nodeName } from 'jquery';
import http from '../modules/api';
import axios from 'axios';
import React from 'react';
import '../../css/style.css';
import $ from 'jquery';
import * as Menu from '../Menu_v2';
import CommonPopup from '../modules/CommonPopup';

import icn_refresh from '../../resources/img/icn_refresh.svg';
import icn_refresh_spin from '../../resources/img/icn_refresh_spin.svg';

var is_stroe_loaded = false;
var is_shops_refresh = false;

/**
 * 사용자 서비스 - 상점추가 페이지
 */
export class ServiceShopAdd extends React.Component {
  state = {
    step: 0,
    solution_type_cd: '',
    solution_name: '',
    agree_yn: '' /* 디폴트 값  변경 'N' -> '' */,
    mall_id: '',
    makeshop: {
      /*메이크샵 연동을 위한 필수 정보 입력 (국적, 화폐단위, 샵 이름을 받아야 할까..)*/
      shop_key: '',
      domain: '',
      shop_name: '',
      language_code: 'ko_KR' /*언어 코드 : (기본)국문*/,
    },
    imweb: {
      api_key: '',
      secret_key: '',
      domain: '',
      shop_name: '',
      language_code: '',
    },
    godomall: {
      id: '',
      domain: '',
      shop_name: '',
      language_code: '',
    },
    finish_yn: 'N' /*최종 저장 완료 후 상태값*/,
    checkedShopList: [] /*사용자가 연동을 위해 선택한 shop 리스트*/,
    shops: [] /*cafe24 api에서 가져온 shop 리스트*/,
    platform_shop_list: [] /*최종 사용자의 플랫폼별 상점 리스트*/,
    options: {
      support_solution: [] /*code : 지원 플랫폼*/,
      language_code_list: [
        { cd_code: '-', cd_desc: '없음' },
        { cd_code: 'ko_KR', cd_desc: '국문' },
        { cd_code: 'en_US', cd_desc: '영문' },
        { cd_code: 'zh_CN', cd_desc: '중문(간체)' },
        { cd_code: 'zh_TW', cd_desc: '중문(번체)' },
        { cd_code: 'ja_JP', cd_desc: '일문' },
        { cd_code: 'vi_VN', cd_desc: '베트남어' },
      ],
    },
  };

  //공통코드 조회
  async getCode(upper_code_cd, option) {
    const res = await http.get(
      '/rest/common/cafe24Code?upper_code_cd=' + upper_code_cd,
    );
    const { options } = this.state;
    this.setState({
      options: {
        ...options,
        [option]: res.data,
      },
    });
  }

  // 1단계 : 플랫폼 선택
  selectSolution = (e, code_cd, code_name) => {
    this.setState({
      solution_type_cd: code_cd,
      solution_name: code_name,
    });

    // 1. 전체
    $('.solutionBox').css('border', '0px');

    if (e.target.style.border == '2.5px solid rgb(16, 207, 201)') {
      e.target.style.border = '0px';
    } else {
      e.target.style.border = '2.5px solid #10CFC9';
    }
  };

  // 다음 단계
  nextStep = () => {
    // alert(this.state.solution_name + "을 선택하셨습니다.");
    // if (this.state.step == 2 && this.state.agree_yn == 'N') {
    // 	alert("계정 정보 확인에 동의를 해주십시오.");
    // 	return false;
    // }

    // $('#step' + this.state.step + ' .radio').addClass('on');
    // $('#step' + this.state.step + ' .radio').css('font-size', '0px');
    if (Number(this.state.step) == 1) {
      //this.state.solution_type_cd = "09004" // 샘플 고도몰 코드
      if (this.state.solution_type_cd == '') {
        alert('플랫폼을 선택해 주세요.');
        return false;
      }
    }

    this.setState({
      step: Number(this.state.step) + 1,
    });

    if (this.state.solution_type_cd == '09999') {
      alert('[기타 플랫폼] 곧 찾아 뵙겠습니다.');
      this.setState({ step: 1 });
    }
  };

  // 2단계 : 동의
  onClickAgree = (e, value) => {
    // $(".radio_display_yn:checked").prop("checked", false);
    // $("#display_"+e.target.value).prop("checked", true);
    this.setState({ agree_yn: value });
  };

  /**
   * 2단계 : mode에 따른 "플랫폼별 상점 가져오기" 기능의 버튼
   *
   * 1. [Cafe24 API] 상점 동기화
   *   mode : '/cafe24_sync_data'
   *
   * 2. 메이크샵 상점 연동에 필요한 데이터 저장
   *   mode : 'makeshop'
   *
   * 3. 아임웹
   *   mode : 'imweb'
   */
  onClickGetStoreInfoButton(mode) {
    if (this.state.step == 2 && this.state.agree_yn != 'Y') {
      alert('계정 정보 확인에 동의를 해주십시오.');
      return false;
    }

    /**
     * 1. 입력받은 상점키 : 저장 및 조회 (해당 계정으로 연동된 멀티샵 리스트 조회)
     * 2. 저장 후 연동된 리스트(체크박스 리스트) 조회
     * 3. 연동할 샵 선택후 저장
     * 4. 연동현황(전체) 조회
     */

    //1. [Cafe24 API] 상점 동기화
    if (mode == '/cafe24_sync_data') {
      var mall_id = this.state.mall_id;
      var solution_type_cd = this.state.solution_type_cd;

      if (mall_id == null || mall_id == '') {
        // 가입신청 - Cafe24 API] 상점 동기화
        // ReactGA.event({
        //     category: "[Cafe24 API] 상점 동기화",
        //     action: "상점 아이디를 입력해 주세요. : " + mall_id,
        //     label: ga_label
        // });
        alert('쇼핑몰 아이디를 입력해 주세요.');
        return false;
      }

      // mall_id 중복체크 추가
      http
        .get('/rest/common/storeCnt?mall_id=' + this.state.mall_id)
        .then((res) => {
          const { data } = res;
          if (data.storeCnt != null) {
            if (Number(data.storeCnt) == 0) {
              let called = false;
              let url = mode + '.html?mall_id=' + mall_id;
              var g_oWindow = window.open('about:blank', '_blank');
              if (g_oWindow != null) {
                called = this.fn_asyncCall(g_oWindow, url);
                console.log('called : ', called);

                // 0.5초 마다 감지
                var g_oInterval = window.setInterval(() => {
                  try {
                    // 창이 꺼졌는지 판단
                    console.log('g_oWindow.closed : ' + g_oWindow.closed);
                    if (g_oWindow == null || g_oWindow.closed) {
                      window.clearInterval(g_oInterval);
                      g_oWindow = null;
                      this.selectShops(mall_id, solution_type_cd);
                      //$("#mall_id").attr('disabled', true);
                    }
                  } catch (e) {
                    alert(e + '\n관리자에게 문의해 주세요. (1599-3518)');
                    //this.selectShops(mall_id, solution_type_cd);
                  }
                }, 500);
                // g_oWindow.addEventListener("beforeunload", function (event) {
                // 	event.returnValue = "asdasdasd?"
                // 	// console.log("close");
                // 	// this.selectShops(mall_id, solution_type_cd);
                // });
              }
            } else {
              alert('이미 사용중인 쇼핑몰 ID 입니다.');
            }
          } else {
            alert('시스템 점검중 입니다.');
          }
        })
        .catch((error) => {
          alert(error + '\n관리자에게 문의해 주세요. (1599-3518)');
        });

      // Cafe24 로그인 팝업창 열기
      // var g_oWindow = window.open(mode+".html?mall_id="+mall_id, "카페24 데이터 동기화");

      // 가입신청 - Cafe24 API] 상점 동기화
      // ReactGA.event({
      //     category: "[Cafe24 API] 상점 동기화",
      //     action: "동기화 로그인 요청 : " + mall_id,
      //     label: ga_label
      // });
    } else if (mode == 'makeshop') {
      var solution_type_cd = this.state.solution_type_cd;
      var shop_key = this.state.makeshop.shop_key;
      var domain = this.state.makeshop.domain.replace(
        /(https?:\/\/)?((\/[^\s/\/]+)*)?(\?([^#\s]*))?/gi,
        '',
      ); //도메인 추출 (프로토콜, 하위경로, 파라미터 제거)
      //var mall_id = this.state.makeshop.shop_name; // **** cafe24 외의 플랫폼은 shop_name을 mall_id로 대체
      var shop_name = this.state.makeshop.shop_name;
      var language_code = this.state.makeshop.language_code;
      var checkedShopList = this.state.checkedShopList;

      let data = {
        //mall_id: mall_id,
        shop_key: shop_key,
        solution_type_cd: solution_type_cd,
        domain: domain,
        shop_name: shop_name,
        language_code: language_code,
        checkedShopList: checkedShopList,
      };

      const config = {
        headers: {
          'content-type': 'application/json;',
        },
      };

      // 1. 입력받은 상점키 : 저장 및 조회 (해당 계정으로 연동된 MakeShop 리스트 조회)
      http
        .post('/rest/common/makeshop/shopAdd', data, config)
        .then((res) => {
          const { data } = res;
          if (data.code == 200) {
            // 2. 저장 후 연동된 리스트(체크박스 리스트) 조회
            this.selectShops(data.mall_id, solution_type_cd);
            console.log(res.data);
          } else if (data.code == "-1") {
            alert("도메인 정보가 일치하지 않습니다."+ '\n' +'[code : ' + data.code + ']' + '\n' + data.msg);
          } else if (data.code == "9002") {
            alert("SHOP_KEY가 일치하지 않습니다."+ '\n' +'[code : ' + data.code + ']' + '\n' + data.msg);
          }else if (data.code == "9998") {
            alert("메이크샵의 상점 이용 기간이 만료되었습니다."+ '\n' +'[code : ' + data.code + ']' + '\n' + data.msg);
          } else if (data.code == "401") {
            alert('[code : ' + data.code + ']' + '\n' + data.msg);
          } else if (data.code == 500) {
            alert(
              '[code : ' +
                500 +
                ']' +
                '\n관리자에게 문의해 주세요. (1599-3518)',
            );
          }
        })
        .catch((error) => {
          alert(error + '\n관리자에게 문의해 주세요. (1599-3518)');
        });
    } else if (mode == 'imweb') {
      var solution_type_cd = this.state.solution_type_cd;
      var api_key = this.state.imweb.api_key;
      var secret_key = this.state.imweb.secret_key;
      var domain = this.state.imweb.domain.replace(
        /(https?:\/\/)?((\/[^\s/\/]+)*)?(\?([^#\s]*))?/gi,
        '',
      ); //도메인 추출 (프로토콜, 하위경로, 파라미터 제거)
      //var mall_id = this.state.imweb.shop_name;     // cafe24 외의 플랫폼은 shop_name을 mall_id로 대체 -> user_id_shop_no 조합으로 대체
      var shop_name = this.state.imweb.shop_name;
      var language_code = this.state.imweb.language_code;
      var checkedShopList = this.state.checkedShopList;

      console.log(domain);

      let data = {
        //mall_id: mall_id,
        api_key: api_key,
        secret_key: secret_key,
        solution_type_cd: solution_type_cd,
        domain: domain,
        shop_name: shop_name,
        language_code: language_code,
        checkedShopList: checkedShopList,
      };

      const config = {
        headers: {
          'content-type': 'application/json;',
        },
      };
      // 1. 입력받은 상점키 : 저장 및 조회 (해당 계정으로 연동된 MakeShop 리스트 조회)
      http
        .post('/rest/common/imweb/shopAdd', data, config)
        .then((res) => {
          const { data } = res;
          if (data.code == 200) {
            // 2. 저장 후 연동된 리스트(체크박스 리스트) 조회
            this.selectShops(data.mall_id, solution_type_cd);
            console.log(res.data);
          } else if (data.code == -1) {
            alert("API KEY 또는 SECRET KEY 정보가 일치하지 않습니다."+ '\n' +'[code : ' + data.code + ']' + '\n' + data.msg);
          } else if (data.code == 401) {
            alert('[code : ' + data.code + ']' + '\n' + data.msg);
          } else if (data.code == 500) {
            alert(
              '[code : ' +
                data.code +
                ']' +
                '\n관리자에게 문의해 주세요. (1599-3518)',
            );
          }
        })
        .catch((error) => {
          alert(error + '\n관리자에게 문의해 주세요. (1599-3518)');
        });
    } else if (mode == 'godomall') {
      // godomall: {
      //   id: '',
      //   domain: '',
      //   shop_name: '',
      //   language_code: '',
      // },

      var solution_type_cd = this.state.solution_type_cd;
      var id = this.state.godomall.id;
      var domain = this.state.godomall.domain.replace(
        /(https?:\/\/)?((\/[^\s/\/]+)*)?(\?([^#\s]*))?/gi,
        '',
      ); //도메인 추출 (프로토콜, 하위경로, 파라미터 제거)
      var shop_name = this.state.godomall.shop_name;
      var language_code = this.state.godomall.language_code;
      var checkedShopList = this.state.checkedShopList;

      let data = {
        id: id,
        solution_type_cd: solution_type_cd,
        domain: domain,
        shop_name: shop_name,
        language_code: language_code,
        checkedShopList: checkedShopList,
      };

      const config = {
        headers: {
          'content-type': 'application/json;',
        },
      };
      // 1. 입력받은 고도몰 정보 : 저장 및 조회
      http
        .post('/rest/common/godomall/shopAdd', data, config)
        .then((res) => {
          const { data } = res;
          if (data.code == 200) {
            // 2. 저장 후 연동된 리스트(체크박스 리스트) 조회
            alert("신청이 완료되었습니다.\n관리자에게 상점키 입력을 요청해 주세요.\n(1599-3518)");
            this.selectShops(data.mall_id, solution_type_cd);
            console.log(res.data);
          } else if (data.code == 500) {
            alert(
              '[code : ' +
                data.code +
                ']' +
                '\n관리자에게 문의해 주세요. (1599-3518)',
            );
          }
        })
        .catch((error) => {
          alert(error + '\n관리자에게 문의해 주세요. (1599-3518)');
        });

    } else {
      alert('[기타 플랫폼] 곧 찾아 뵙겠습니다.');
    }
  }

  // Cafe24 로그인 팝업창 준비 (Safari 대응)
  async fn_asyncCall(g_oWindow, url) {
    g_oWindow.location = url;

    return true;
  }

  // 3단계 : Radio 상점 선택 이벤트
  // onClickSelectShop = (shop_no) => {
  //     // $(".radio_display_yn:checked").prop("checked", false);
  //     // $("#display_"+e.target.value).prop("checked", true);
  // 	//console.log(shop_no)
  //     this.setState({select_shop_no : shop_no})
  // };

  handleChangeSelectShop = (e, shop_no, shop_name, state) => {
    // if (state == "on") {
    // 	this.state.checkedShopList.push(shop_no);
    // } else {
    // 	this.state.checkedShopList.splice(this.state.checkedShopList.indexOf(shop_no), 1);
    // }
    // var key = "checkbox_state_"+shop_no;
    // this.setState({[key]: state})

    if (state == 'on') {
      let shop = {
        shop_no: shop_no,
        shop_name: shop_name,
      };
      this.state.checkedShopList.push(shop);
    } else {
      const newList = this.state.checkedShopList.filter(
        (shop) => shop.shop_no !== shop_no,
      );
      this.setState({ checkedShopList: newList });
    }
    var key = 'checkbox_state_' + shop_no;
    this.setState({ [key]: state });

    console.log(this.state.checkedShopList);
  };

  // 3단계 선택한 상점 등록하기 : 선택된 shop_no 저장
  onClickSelectShopSaveButton = () => {
    var mall_id = this.state.mall_id;
    var solution_type_cd = this.state.solution_type_cd;
    var checkedShopList = this.state.checkedShopList;

    if (checkedShopList != null && checkedShopList.length > 0) {
      let shopData = {
        mall_id: mall_id,
        solution_type_cd: solution_type_cd,
        checkedShopList: checkedShopList,
      };

      const config = {
        headers: {
          'content-type': 'application/json;',
        },
      };

      /**
       * 선택된 플랫폼의 사용자 상점 (데이터 연동을 위한)저장
       */
      http
        .post('/rest/common/saveCafe24CheckedShop', shopData, config)
        .then((res) => {
          const { data } = res;
          console.log(data);
          if (data.code == 200) {
            console.log(
              '/rest/common/saveCafe24CheckedShop : 선택된 플랫폼의 사용자 쇼핑몰 (데이터 연동을 위한)저장 완료',
            );
            // alert('상점 연동이 완료 되었습니다.');
            Menu.call_onChangeIsAddShopButtonClick_for_other_component(true);
            this.setState({
              finish_yn: 'Y',
            });
            this.selectAddShop();
            //선택 멀티샵 데이터 최초 동기화
            this.firstDataSyncCheckedShop(shopData);
            // 메뉴 권한 새로 조회
            Menu.call_checkUser_for_other_component();

            // 디폴트 배너 세팅 추가
            this.insertAutoEventSettingsDefault(shopData);
          } else {
            alert(
              '[code : ' +
                data.code +
                ']' +
                '\n관리자에게 문의해 주세요. (1599-3518)',
            );
          }
        })
        .catch((error) => {
          alert(error + '\n관리자에게 문의해 주세요. (1599-3518)');
        });
    } else {
      alert('연동할 쇼핑몰을 선택해 주세요.');
    }
  };

  selectAddShop() {
    http
      .get('/rest/shop/selectShops')
      .then((res) => {
        const { data } = res;
        if (data.code == 200) {
          if(data.platform_shop_cnt > 0) {
            this.setState({
              platform_shop_list: data.platform_shop_list,
            });
          } else {
            alert("연동된 쇼핑몰이 없습니다.\n쇼핑몰 연동 페이지로 이동합니다.");
            window.location.href = '/shop/serviceShopAdd';
          }
        }
      })
      .catch((error) => {
        alert(error + '\n관리자에게 문의해 주세요. (1599-3518)');
      });
  }

  //선택 멀티샵 데이터 최초 동기화
  async firstDataSyncCheckedShop(shopData) {
    const config = {
      headers: {
        'content-type': 'application/json;',
      },
    };
    await axios.post('/rest/common/firstDataSyncCheckedShop', shopData, config);
    // const res = await http.post("/rest/common/firstDataSyncCheckedShop", shopData, config)
    // const {data} = res;
    // if (data.code != null) {
    // 	console.log("firstDataSyncCheckedShop : [code : "+data.code+"] " + data.msg);
    // }
  }

  // 디폴트 배너 설정 추가
  async insertAutoEventSettingsDefault(shopData) {
    const config = {
      headers: {
        'content-type': 'application/json;',
      },
    };
    await axios.post('/rest/shop/event/setting/default', shopData, config);
    // const res = await axios.post("/rest/shop/event/setting/default", shopData, config);
    // console.log("insertAutoEventSettingsDefault : " + res.data.message);
  }

  /**
   * [베타버전] 연동된 mall이 존재하는지 체크
   *  * 멀티샵만 지원하되 mall은 추가할 수 없다.
   *
   *  1. store 조회 (user_seq)
   *  2. 조회된 데이터에서 solution_type_cd 필드 체크.
   *  3. selectShops 조회
   */
  //사용자의 연동된 상점(store) 조회
  async selectStore() {
    is_stroe_loaded = true;
    // console.log("selectStore : [베타버전] 연동된 mall이 존재하는지 체크");
    const res = await http.get('/rest/common/selectStore');
    // 조회 성공시 다음 단계
    if (res.data != null && res.data.length > 0) {
      let mall_id = res.data[0].mall_id;
      let solution_type_cd = res.data[0].solution_type_cd;

      // console.log(mall_id + solution_type_cd);

      // 모든 샵이 추가 완료된 경우, 연동 현황 페이지로 이동

      this.selectShops(mall_id, solution_type_cd);
      this.setState({
        mall_id: mall_id,
        solution_type_cd: solution_type_cd,
      });
      // this.setState({
      // 	step: 2,
      // 	shops: res.data
      // })
      if (is_shops_refresh) {
        alert('쇼핑몰 목록을 새로 불러왔습니다.');
        is_shops_refresh = false;
      }
    } else {
      //console.log("selectStore : 연동된 상점 정보가 없습니다. 1단계도 이동")
      //alert("연동된 상점 정보가 없습니다.\n해당 플랫폼의 상점 정보를 확인해 주세요.");
      // 1단계로 이동
      this.setState({
        step: 1,
      });
    }
  }

  //mall_id에 해당하는 shops 조회
  async selectShops(mall_id, solution_type_cd) {
    const res = await http.get('/rest/common/selectShops', {
      params: {
        mall_id: mall_id,
        solution_type_cd: solution_type_cd,
      },
    });

    // 가입신청 - Cafe24 API] 상점 동기화
    //this.nextStep();
    // console.log("selectShops : mall_id에 해당하는 shops 조회");
    // console.log(res.data);

    // 조회 성공시 다음 단계
    if (res.data != null && res.data.length > 0) {
      this.setState({
        step: 3, //Number(this.state.step) + 1,
        shops: res.data,
      });
    } else {
      alert(
        '연동된 쇼핑몰 정보가 없습니다.\n해당 플랫폼의 쇼핑몰 정보를 확인해 주세요.',
      );
      this.setState({
        step: 1,
        mall_id: '',
      });
    }
  }

  // 상점 새로고침
  async refreshShops() {
    if (is_shops_refresh) {
      alert('쇼핑몰 목록을 불러오는 중입니다.\n잠시만 기다려 주세요.');
      return false;
    }

    is_shops_refresh = true;
    const res = await http.get('/rest/common/reflesh/shopList');

    // 새로고침 성공시 스토어 다시 조회
    if (res.data != null) {
      this.selectStore();
    } else {
      is_shops_refresh = false;
    }
  }

  // 3단계 : 선택된 멀티샵 세션 전환 및 배너세팅 페이지 이동
  onClickShopSettingButton = (solution_type_cd, shop_no) => {
    var mall_id = this.state.mall_id;
    http.get(`/rest/common/changeShop?shop_no=` + shop_no).then((res) => {
      var code = res.data.code;
      var msg = '';
      if (code == 200) {
        msg = '멀티샵 전환 성공!';
        console.log('#### 이동 : ' + mall_id, solution_type_cd, shop_no);
        // window.location.href = "/shop/cafe24/event/setting";
        window.location.href = '/shop/cafe24/event/setting/list';
      } else {
        msg =
          '멀티샵 전환에 실패하였습니다.\n관리자에게 문의해 주세요. (1599-3518)';
      }
      //alert(msg);
    });
  };

  // 3단계 : 선택된 플랫폼의 상품 데이터 새로 연동하기
  onClickRefreshDataSync = (solution_type_cd, shop_no) => {

    this.setState({
      common_popup1: true,
      common_popup1_content: "상품 목록을 새로 불러오시겠습니까?</br>" + 
                             "상품 수에 따라 짧게는 몇 분에서 길게는 몇 시간이 걸릴 수 있습니다.</br>" +
                             "그 동안 다른 기능들은 모두 정상적으로 이용하실 수 있습니다.",
      common_popup1_button_list: [
        {
          title:"아니요",
          type:"0",
          event_method: () => {
            //팝업 닫기
            this.setState({ common_popup1: false })
          }
        },
        {
          title: "네",
          type: "1",
          event_method: () => {
            this.refreshDataSyncConfirm(solution_type_cd, shop_no);
          }
        },
      ]
    });

  }

  // 3단계 : 선택된 플랫폼의 상품 데이터 새로 연동하기 (확인)
  refreshDataSyncConfirm = (solution_type_cd, shop_no) => {

    this.setState({
      common_popup1_content: "전체 상품 목록을 새로 불러옵니다.</br>완료 시 카카오톡으로 알림 메시지를 드립니다.",
      common_popup1_button_list: [
        {
          title:"아니요",
          type:"0",
          event_method: () => {
            //팝업 닫기
            this.setState({ common_popup1: false })
          }
        },
        {
          title: "네",
          type: "1",
          event_method: () => {
            const {platform_shop_list} = this.state;
            platform_shop_list.filter((platform) => platform.solution_type_cd == solution_type_cd).map(
              map => map.target_shops.filter((target_shop) => target_shop.shop_no == shop_no).map(
                map => map.sync_state = "1"
              )
            )
            this.fn_refreshDataSync(solution_type_cd, shop_no);

            //팝업 닫기
            this.setState({ common_popup1: false })
          }
        },
      ]
    });

  }

  async fn_refreshDataSync(solution_type_cd, shop_no) {
    // 연동 시작
    let shopData = {
      solution_type_cd: solution_type_cd,
      shop_no: shop_no,
    };

    const config = {
      headers: {
        'content-type': 'application/json;',
      },
    };
    const {data} = await axios.post('/rest/common/refreshDataSyncShop', shopData, config);
    if (data.code != "200") {
      alert(data.msg);
    }
  }

  // input text change event
  handleText = (e, key) => {
    // computed property
    //this.setState({[key]: e.target.value});
    if (key == 'mall_id') {
      this.setState({ mall_id: e.target.value });
    }
  };

  // 메이크샵 input text change event
  makeshop_handleText = (e, key) => {
    const { makeshop } = this.state;
    this.setState({
      makeshop: {
        ...makeshop,
        [key]: e.target.value,
      },
    });
  };

  // 아임웹 input text change event
  imweb_handleText = (e, key) => {
    const { imweb } = this.state;
    this.setState({
      imweb: {
        ...imweb,
        [key]: e.target.value,
      },
    });
  };

  // 고도몰 input text change event
  godomall_handleText = (e, key) => {
    const { godomall } = this.state;
    this.setState({
      godomall: {
        ...godomall,
        [key]: e.target.value,
      },
    });
  };

  // 언어코드 변경 이벤트
  onChangeSelectBoxlanguageCodeHandler = (e, mode) => {
    if (mode == 'makeshop') {
      const { makeshop } = this.state;
      this.setState({
        makeshop: {
          ...makeshop,
          language_code: e.target.value,
        },
      });
    } else if (mode == 'imweb') {
      const { imweb } = this.state;
      this.setState({
        imweb: {
          ...imweb,
          language_code: e.target.value,
        },
      });
    } else if (mode == 'godomall') {
      const { godomall } = this.state;
      this.setState({
        godomall: {
          ...godomall,
          language_code: e.target.value,
        },
      });
    }
  };

  platformImg(solution_type_cd) {
    const { support_solution } = this.state.options
    const idx = support_solution.findIndex((solution) => solution.code_cd == solution_type_cd)
    let img_url = "";
    
    if (idx >= 0) {
      img_url = support_solution[idx].val2;
    }

    return img_url;
  }

  componentDidUpdate() {
    var params = {};
    window.location.search.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (str, key, value) {
        params[key] = value;
      },
    );
    if (this.state.step != '0') {
      // alert(params.finish_yn);
      if (params.finish_yn == 'y') {
        if (params.step == '3') {
          if (this.state.finish_yn == 'N') {
            $('#page_title').text('쇼핑몰 목록');
            this.setState({ step: 3 }); // 상점 목록
            this.setState({ finish_yn: 'Y' });
            this.selectAddShop();
            is_stroe_loaded = false;
          }
        }
      } else {
        // alert(is_stroe_loaded);
        if (!is_stroe_loaded) {
          this.selectStore(); // 상점 추가
          this.setState({ finish_yn: 'N' });
        }
      }
    }
  }

  componentDidMount() {
    //쇼핑몰 플랫폼 코드 조회
    this.getCode('09000', 'support_solution');
    //언어 코드 조회 (현재 고정)
    //this.getCode("09000", "language_code_list");

    /**
     * [베타버전] 연동된 mall이 존재하는지 체크
     *  * 멀티샵만 지원하되 mall은 추가할 수 없다.
     *
     *  1. store 조회 (user_seq)
     *  2. 조회된 데이터에서 solution_type_cd 필드 체크.
     *  3. selectShops 조회
     */
    var params = {};
    window.location.search.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (str, key, value) {
        params[key] = value;
      },
    );
    if (params.step == '3') {
      $('#page_title').text('쇼핑몰 목록');
      this.setState({ step: 3 }); // 상점 목록
      this.setState({ finish_yn: 'Y' });
      this.selectAddShop();
    } else {
      this.selectStore(); // 상점 추가
    }

    //  this.setState({step: 1}); // 솔루션 종류

    // test ******************************
    // this.selectShops('nf4545', '09001');
    // this.setState({
    // 		step: 2,
    // 		mall_id: 'nf4545',
    // 		solution_type_cd: '09001'
    // });
    // test ******************************
  }

  render() {
    $('.pageName').text('쇼핑몰 연동하기');
    return (
      <>
        <div
          id="page-wrapper"
          className="container"
          style={{ backgroundColor: '#ffffff', minHeight: '100%' }}
        >
          {/* <h3>[{window.location.href.split("/")[3].charAt(0).toUpperCase() + window.location.href.split("/")[3].slice(1) }] 상점 추가하기</h3> */}
          <br />
          <br />

          <div id="page_title" className="form_primary_title pc_page_title">
            쇼핑몰 연동하기
          </div>
          <div className="m-t-43 m-l-29 shop_add_area">
            {/* 진행단계 표기 :: 사용 X */}
            {/* <div id="stepFrame" className="flexBox">
							<div id="step1" className="m-r-md" style={{
									display:"flex", flexDirection:"row", alignItems:"flex-start",
									width:"246px", height:"70px",
									background:"#FFFFFF", boxShadow:"0px 4px 10px rgba(0, 0, 0, 0.1)", borderRadius:"13px",
									flex:"none", order:"0", flexGrow:"0",
									justifyContent:"center", alignItems:"center"
									}}>
								<div className="redio_v2" style={{marginTop: "auto", marginBottom: "auto", flexDirection: "column"}}>
									<div className="flexBox radio_area" style={{marginRight:"auto"}}>
										<input type="radio" className="radio_display_yn" name="display_Y" id="display_Y" value="Y" />
										<div className="radio" style={{fontSize:"18px", textAlign:"center", lineHeight:"23px", color:"#10CFC9"}}>1</div><label className="radio_text">플랫폼 선택</label>
									</div>
								</div>
							</div>
							<div id="step2" className="m-r-md" style={{
									display:"flex", flexDirection:"row", alignItems:"flex-start",
									width:"246px", height:"70px",
									background:"#FFFFFF", boxShadow:"0px 4px 10px rgba(0, 0, 0, 0.1)", borderRadius:"13px",
									flex:"none", order:"0", flexGrow:"0",
									justifyContent:"center", alignItems:"center"
									}}>
								<div className="redio_v2" style={{marginTop: "auto", marginBottom: "auto", flexDirection: "column"}}>
									<div className="flexBox radio_area" style={{marginRight:"auto"}}>
										<input type="radio" className="radio_display_yn" name="display_Y" id="display_Y" value="Y" onClick={this.onClickDisplayYn}/>
										<div className="radio" style={{fontSize:"18px", textAlign:"center", lineHeight:"23px", color:"#10CFC9"}}>2</div><label className="radio_text">필수정보 기입</label>
									</div>
								</div>
							</div>
							<div id="step3" className="m-r-md" style={{
									display:"flex", flexDirection:"row", alignItems:"flex-start",
									width:"246px", height:"70px",
									background:"#FFFFFF", boxShadow:"0px 4px 10px rgba(0, 0, 0, 0.1)", borderRadius:"13px",
									flex:"none", order:"0", flexGrow:"0",
									justifyContent:"center", alignItems:"center"
									}}>
								<div className="redio_v2" style={{marginTop: "auto", marginBottom: "auto", flexDirection: "column"}}>
									<div className="flexBox radio_area" style={{marginRight:"auto"}}>
										<input type="radio" className="radio_display_yn" name="display_Y" id="display_Y" value="Y" onClick={this.onClickDisplayYn}/>
										<div className="radio" style={{fontSize:"18px", textAlign:"center", lineHeight:"23px", color:"#10CFC9"}}>3</div><label className="radio_text">상점추가 완료</label>
									</div>
								</div>
							</div>
						</div> */}

            {/* ----------- 1단계 화면 영역 --------------------------------------------------------------------------------------------------- */}
            {this.state.step == 1 ? (
              <>
                {/* 1단계 title */}
                <div
                  className="flexBox m-t-32"
                  style={{ marginBottom: '15px' }}
                >
                  <div
                    id="item_user_type"
                    style={{
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      fontSize: '15px',
                      fontWeight: 'bold',
                    }}
                  >
                    로보MD 배너를 적용할 쇼핑몰 플랫폼을 선택해 주세요.
                  </div>
                </div>
                {/* 지원 플랫폼 목록 */}
                <div className="flexBox m-t-32" id="solutions">
                  {this.state.options.support_solution.map(
                    (solution, index) => (
                      <div
                        key={index}
                        className="solutionBox flexBox m-r-md m-b-md div-hover"
                        id={index}
                        style={{
                          width: '260px',
                          height: '200px',
                          left: '0px',
                          top: '0px',
                          background: '#FFFFFF',
                          boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.08)',
                          borderRadius: '15px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onClick={(e) => {
                          this.selectSolution(
                            e,
                            solution.code_cd,
                            solution.code_name,
                          );
                        }}
                      >
                        <img
                          style={{ pointerEvents: 'none' }}
                          src={solution.val2}
                        ></img>
                      </div>
                    ),
                  )}
                </div>
                <div
                  className="width-100 flexBox"
                  style={{ marginTop: '50px', marginBottom: '50px' }}
                >
                  {this.state.solution != '' ? (
                    <button className="btn-175-b" onClick={this.nextStep}>
                      {this.state.solution_name} 선택하기
                    </button>
                  ) : (
                    <button
                      className="btn-175-b"
                      style={{
                        background: '#DBDDE4',
                        boxShadow: '0px 0px 0px',
                      }}
                    >
                      플랫폼을 선택해 주세요.
                    </button>
                  )}
                </div>
              </>
            ) : (
              ''
            )}

            {/* ----------- 2단계 화면 영역 --------------------------------------------------------------------------------------------------- */}
            {this.state.step == 2 ? (
              <>
                {
                  /* 09001 : Cafe24 */
                  this.state.solution_type_cd == '09001' ? (
                    <>
                      <div style={{ marginBottom: '15px' }}>
                        <div
                          className="flexBox m-t-32"
                        >
                          <div
                            id="item_user_type"
                            style={{
                              marginTop: 'auto',
                              marginBottom: 'auto',
                              marginRight: '20px',
                              fontSize: '15px',
                              fontWeight: 'bold',
                            }}
                          >
                            <b>
                              카페24 쇼핑몰 ID를 알려주시면 배너를 등록할 쇼핑몰
                              정보를 불러올 수 있습니다.
                            </b>
                          </div>
                          <div className="userGuideButton" onClick={(e) => Menu.call_userGuide('https://iclave.notion.site/24-db0f5f49d484468eb7b38bc2983a9e7d')}>
                            <span>사용자 가이드</span>
                          </div>
                        </div>
                        <div className="userGuideButtonMobile" onClick={(e) => Menu.call_userGuide('https://iclave.notion.site/24-db0f5f49d484468eb7b38bc2983a9e7d')} style={{float:'right'}}>
                            <span>?</span>
                        </div>
                      </div>
                      
                      <div className="input_text_title_d">카페24 쇼핑몰ID</div>
                      <input
                        className="border_form_radius_b"
                        type="text"
                        value={this.state.mall_id}
                        onChange={(e) => this.handleText(e, 'mall_id')}
                        placeholder="* 필수 입력 사항입니다."
                      />

                      {/*상점 가져오기 완료후 사업자 등록번호 체크
											-> result == true ? 완료 : '가입된 사업자등록번호와 일치하지 않습니다!'*/}
                      <div
                        className="width-100 flexBox"
                        style={{ marginTop: '50px', marginBottom: '50px' }}
                      >
                        {this.state.solution != '' ? (
                          <button
                            className="btn-175-b"
                            onClick={() =>
                              this.onClickGetStoreInfoButton(
                                '/cafe24_sync_data',
                              )
                            }
                          >
                            쇼핑몰 연동하기
                          </button>
                        ) : (
                          <button
                            className="btn-175-b"
                            style={{
                              background: '#DBDDE4',
                              boxShadow: '0px 0px 0px',
                            }}
                          >
                            쇼핑몰 ID를 입력해 주세요.
                          </button>
                        )}
                      </div>
                    </>
                  ) : /* -------------------------------------------------------------------------------------------------------------- */
                  /* 09002 : MakeShop */
                  this.state.solution_type_cd == '09002' ? (
                    <>
                      <div
                        className="flexBox m-t-32"
                        style={{ marginBottom: '15px' }}
                      >
                        <div
                          id="item_user_type"
                          style={{
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            marginRight: '20px',
                            fontSize: '15px',
                            fontWeight: 'bold',
                          }}
                        >
                          메이크샵 연동을 위한 정보를 입력해 주세요.
                        </div>
                        <div className="userGuideButton" onClick={(e) => Menu.call_userGuide('https://iclave.notion.site/c3deef5fe8cb404fad56ecb006aec3fe')}>
                          <span>사용자 가이드</span>
                        </div>
                        <div className="userGuideButtonMobile" onClick={(e) => Menu.call_userGuide('https://iclave.notion.site/c3deef5fe8cb404fad56ecb006aec3fe')} style={{float:'right'}}>
                          <span>?</span>
                        </div>
                      </div>
                      <div>
                        <div
                          className="input_text_title_d"
                          style={{ zIndex: '999' }}
                        >
                          SHOP KEY
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '500px',
                            maxWidth: 'calc(95% - 15px)',
                            position: 'relative',
                            marginBottom: '12px',
                          }}
                        >
                          <input
                            className="border_form_radius_b"
                            type="text"
                            value={this.state.makeshop.shop_key}
                            onChange={(e) =>
                              this.makeshop_handleText(e, 'shop_key')
                            }
                            placeholder="* 필수 입력 사항입니다."
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          className="input_text_title_d m-t-32"
                          style={{ zIndex: '999' }}
                        >
                          도메인
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '500px',
                            maxWidth: 'calc(95% - 15px)',
                            position: 'relative',
                            marginBottom: '12px',
                          }}
                        >
                          <input
                            className="border_form_radius_b"
                            type="text"
                            value={this.state.makeshop.domain}
                            onChange={(e) =>
                              this.makeshop_handleText(e, 'domain')
                            }
                            placeholder="* 필수 입력 사항입니다."
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          className="input_text_title_d m-t-32"
                          style={{ zIndex: '999' }}
                        >
                          쇼핑몰 이름
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '500px',
                            maxWidth: 'calc(95% - 15px)',
                            position: 'relative',
                            marginBottom: '12px',
                          }}
                        >
                          <input
                            className="border_form_radius_b"
                            type="text"
                            value={this.state.makeshop.shop_name}
                            onChange={(e) =>
                              this.makeshop_handleText(e, 'shop_name')
                            }
                            placeholder="* 필수 입력 사항입니다."
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          className="input_text_title_d eventSettingTitle"
                          style={{ zIndex: '999' }}
                        >
                          <label
                            style={{ marginTop: 'auto', marginBottom: 'auto' }}
                          >
                            언어 코드
                          </label>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '500px',
                            maxWidth: 'calc(95% - 15px)',
                            position: 'relative',
                            marginBottom: '12px',
                          }}
                        >
                          <select
                            id="iframe_page_id_cd"
                            className="border_form_radius_b"
                            value={this.state.makeshop.language_code}
                            onChange={(e) =>
                              this.onChangeSelectBoxlanguageCodeHandler(
                                e,
                                'makeshop',
                              )
                            }
                          >
                            <option value="" disabled>
                              언어 코드를 선택해 주세요.
                            </option>
                            {this.state.options.language_code_list.map(
                              (option, idx) => (
                                <option key={idx} value={option.cd_code}>
                                  {option.cd_desc}
                                </option>
                              ),
                            )}
                          </select>
                        </div>
                      </div>

                      <div
                        className="width-100 flexBox"
                        style={{ marginTop: '50px', marginBottom: '50px' }}
                      >
                        <button
                          className="btn-175-b"
                          onClick={() =>
                            this.onClickGetStoreInfoButton('makeshop')
                          }
                        >
                          쇼핑몰 연동하기
                        </button>
                      </div>
                    </>
                  ) : /* -------------------------------------------------------------------------------------------------------------- */
                  /* 09003 : Imweb */
                  this.state.solution_type_cd == '09003' ? (
                    <>
                      <div
                        className="flexBox m-t-32"
                        style={{ marginBottom: '15px' }}
                      >
                        <div
                          id="item_user_type"
                          style={{
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            marginRight: '20px',
                            fontSize: '15px',
                            fontWeight: 'bold',
                          }}
                        >
                          아임웹 연동을 위한 정보를 입력해 주세요.
                        </div>
                        <div className="userGuideButton" onClick={(e) => Menu.call_userGuide('https://iclave.notion.site/fe1a130d593f4ef991138f99afde4066')}>
                          <span>사용자 가이드</span>
                        </div>
                        <div className="userGuideButtonMobile" onClick={(e) => Menu.call_userGuide('https://iclave.notion.site/fe1a130d593f4ef991138f99afde4066')} style={{float:'right'}}>
                          <span>?</span>
                        </div>
                      </div>
                      <div className="input_text_title_d">API KEY</div>
                      <input
                        className="border_form_radius_b"
                        type="text"
                        value={this.state.imweb.api_key}
                        onChange={(e) => this.imweb_handleText(e, 'api_key')}
                        placeholder="* 필수 입력 사항입니다."
                      />
                      <div className="input_text_title_d m-t-32">
                        SECRET KEY
                      </div>
                      <input
                        className="border_form_radius_b"
                        type="text"
                        value={this.state.imweb.secret_key}
                        onChange={(e) => this.imweb_handleText(e, 'secret_key')}
                        placeholder="* 필수 입력 사항입니다."
                      />
                      <div>
                        <div
                          className="input_text_title_d m-t-32"
                          style={{ zIndex: '999' }}
                        >
                          도메인
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '500px',
                            maxWidth: 'calc(95% - 15px)',
                            position: 'relative',
                            marginBottom: '12px',
                          }}
                        >
                          <input
                            className="border_form_radius_b"
                            type="text"
                            value={this.state.imweb.domain}
                            onChange={(e) => this.imweb_handleText(e, 'domain')}
                            placeholder="* 필수 입력 사항입니다."
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          className="input_text_title_d m-t-32"
                          style={{ zIndex: '999' }}
                        >
                          쇼핑몰 이름
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '500px',
                            maxWidth: 'calc(95% - 15px)',
                            position: 'relative',
                            marginBottom: '12px',
                          }}
                        >
                          <input
                            className="border_form_radius_b"
                            type="text"
                            value={this.state.imweb.shop_name}
                            onChange={(e) =>
                              this.imweb_handleText(e, 'shop_name')
                            }
                            placeholder="* 필수 입력 사항입니다."
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          className="input_text_title_d eventSettingTitle"
                          style={{ zIndex: '999' }}
                        >
                          <label
                            style={{ marginTop: 'auto', marginBottom: 'auto' }}
                          >
                            언어 코드
                          </label>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '500px',
                            maxWidth: 'calc(95% - 15px)',
                            position: 'relative',
                            marginBottom: '12px',
                          }}
                        >
                          <select
                            id="iframe_page_id_cd"
                            className="border_form_radius_b"
                            value={this.state.imweb.language_code}
                            onChange={(e) =>
                              this.onChangeSelectBoxlanguageCodeHandler(
                                e,
                                'imweb',
                              )
                            }
                          >
                            <option value="" disabled>
                              언어 코드를 선택해 주세요.
                            </option>
                            {this.state.options.language_code_list.map(
                              (option, idx) => (
                                <option key={idx} value={option.cd_code}>
                                  {option.cd_desc}
                                </option>
                              ),
                            )}
                          </select>
                        </div>
                      </div>

                      <div
                        className="width-100 flexBox"
                        style={{ marginTop: '50px', marginBottom: '50px' }}
                      >
                        <button
                          className="btn-175-b"
                          onClick={() =>
                            this.onClickGetStoreInfoButton('imweb')
                          }
                        >
                          쇼핑몰 연동하기
                        </button>
                      </div>
                    </>
                  ) :  /* -------------------------------------------------------------------------------------------------------------- */
                    /* 09004 : Godomall */
                    this.state.solution_type_cd == '09004' ? (
                    <>
                      <div
                        className="flexBox m-t-32"
                        style={{ marginBottom: '15px' }}
                      >
                        <div
                          id="item_user_type"
                          style={{
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            marginRight: '20px',
                            fontSize: '15px',
                            fontWeight: 'bold',
                          }}
                        >
                          고도몰 연동을 위한 정보를 입력해 주세요.
                        </div>
                        {/* <div className="userGuideButton" onClick={(e) => Menu.call_userGuide('https://iclave.notion.site/3b52245dbcfb4892830c732b539605e4')}>
                          <span>사용자 가이드</span>
                        </div> */}
                        <div className="userGuideButtonMobile" onClick={(e) => Menu.call_userGuide('https://iclave.notion.site/3b52245dbcfb4892830c732b539605e4')} style={{float:'right'}}>
                          <span>?</span>
                        </div>
                      </div>
                      <div className="input_text_title_d" style={{ zIndex: '999' }}>고도몰 ID (nhn-commerce.com 계정 입력)</div>
                      <div
                          style={{
                            display: 'flex',
                            width: '500px',
                            maxWidth: 'calc(95% - 15px)',
                            position: 'relative',
                            marginBottom: '12px',
                          }}
                        >
                        <input
                            className="border_form_radius_b"
                            type="text"
                            value={this.state.godomall.id}
                            onChange={(e) => this.godomall_handleText(e, 'id')}
                            placeholder="* 필수 입력 사항입니다."
                          />
                          {/* <div className="btn-175-b" 
                            style={{display:"flex", justifyContent:"center", alignItems:"center", cursor:"pointer", marginLeft:"10px"}}
                            onClicl={e => this.godomall_id_check()}>
                            아이디 확인
                          </div> */}
                      </div>
                      {/* <div className="input_text_title_d m-t-32">
                        SECRET KEY
                      </div>
                      <input
                        className="border_form_radius_b"
                        type="text"
                        value={this.state.imweb.secret_key}
                        onChange={(e) => this.godomall_handleText(e, 'secret_key')}
                        placeholder="* 필수 입력 사항입니다."
                      /> */}
                      <div>
                        <div
                          className="input_text_title_d m-t-32"
                          style={{ zIndex: '999' }}
                        >
                          도메인
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '500px',
                            maxWidth: 'calc(95% - 15px)',
                            position: 'relative',
                            marginBottom: '12px',
                          }}
                        >
                          <input
                            className="border_form_radius_b"
                            type="text"
                            value={this.state.godomall.domain}
                            onChange={(e) => this.godomall_handleText(e, 'domain')}
                            placeholder="* 필수 입력 사항입니다."
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          className="input_text_title_d m-t-32"
                          style={{ zIndex: '999' }}
                        >
                          쇼핑몰 이름
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '500px',
                            maxWidth: 'calc(95% - 15px)',
                            position: 'relative',
                            marginBottom: '12px',
                          }}
                        >
                          <input
                            className="border_form_radius_b"
                            type="text"
                            value={this.state.godomall.shop_name}
                            onChange={(e) =>
                              this.godomall_handleText(e, 'shop_name')
                            }
                            placeholder="* 필수 입력 사항입니다."
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          className="input_text_title_d eventSettingTitle"
                          style={{ zIndex: '999' }}
                        >
                          <label
                            style={{ marginTop: 'auto', marginBottom: 'auto' }}
                          >
                            언어 코드
                          </label>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '500px',
                            maxWidth: 'calc(95% - 15px)',
                            position: 'relative',
                            marginBottom: '12px',
                          }}
                        >
                          <select
                            id="iframe_page_id_cd"
                            className="border_form_radius_b"
                            value={this.state.godomall.language_code}
                            onChange={(e) =>
                              this.onChangeSelectBoxlanguageCodeHandler(
                                e,
                                'godomall',
                              )
                            }
                          >
                            <option value="" disabled>
                              언어 코드를 선택해 주세요.
                            </option>
                            {this.state.options.language_code_list.map(
                              (option, idx) => (
                                <option key={idx} value={option.cd_code}>
                                  {option.cd_desc}
                                </option>
                              ),
                            )}
                          </select>
                        </div>
                      </div>

                      <div
                        className="width-100 flexBox"
                        style={{ marginTop: '50px', marginBottom: '50px' }}
                      >
                        <button
                          className="btn-175-b"
                          onClick={() =>
                            this.onClickGetStoreInfoButton('godomall')
                          }
                        >
                          쇼핑몰 연동하기
                        </button>
                      </div>
                    </>
                  ) : (
                    /* -------------------------------------------------------------------------------------------------------------- */
                    ''
                  )
                }
                {/* 계정정보 확인 동의 */}
                <div
                  className="redio_v2"
                  style={{
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    flexDirection: 'column',
                    marginBottom: '100px',
                  }}
                >
                  {this.state.agree_yn == 'Y' ? (
                    <div className="flexBox radio_area m-t-43">
                      <input
                        type="radio"
                        className="radio_display_yn"
                        name="display_Y"
                        id="display_Y"
                        value="Y"
                        checked={true}
                        onClick={this.onClickAgree}
                      />
                      <div
                        className="radio on"
                        onClick={(e) => this.onClickAgree(e, 'Y')}
                      ></div>
                      <label className="radio_text">
                        계정 정보 확인에 동의합니다.
                      </label>
                    </div>
                  ) : (
                    <div className="flexBox radio_area m-t-43">
                      <input
                        type="radio"
                        className="radio_display_yn"
                        name="display_Y"
                        id="display_Y"
                        value="Y"
                        checked={true}
                        onClick={this.onClickAgree}
                      />
                      <div
                        className="radio"
                        onClick={(e) => this.onClickAgree(e, 'Y')}
                      ></div>
                      <label className="radio_text">
                        계정 정보 확인에 동의합니다.
                      </label>
                    </div>
                  )}
                  {this.state.agree_yn == 'N' ? (
                    <div className="flexBox radio_area m-t-10">
                      <input
                        type="radio"
                        className="radio_display_yn"
                        name="display_N"
                        id="display_N"
                        value="N"
                        onClick={this.onClickAgree}
                      />
                      <div
                        className="radio on"
                        onClick={(e) => this.onClickAgree(e, 'N')}
                      ></div>
                      <label className="radio_text">
                        계정 정보 확인에 동의하지 않습니다.
                      </label>
                    </div>
                  ) : (
                    <div className="flexBox radio_area m-t-10">
                      <input
                        type="radio"
                        className="radio_display_yn"
                        name="display_N"
                        id="display_N"
                        value="N"
                        onClick={this.onClickAgree}
                      />
                      <div
                        className="radio"
                        onClick={(e) => this.onClickAgree(e, 'N')}
                      ></div>
                      <label className="radio_text">
                        계정 정보 확인에 동의하지 않습니다.
                      </label>
                    </div>
                  )}
                </div>
              </>
            ) : (
              ''
            )}

            {/* ----------- 3단계 화면 영역 --------------------------------------------------------------------------------------------------- */}
            {this.state.step == 3 ? (
              <>
                {this.state.finish_yn == 'N' ? (
                  <>
                    {/* 해당 플랫폼의 연동할 상점 선택하기 */}
                    <div id="cafe24ShopsInfo" style={{ marginTop: '80px' }}>
                      <div
                        style={{
                          display: 'flex',
                          marginBottom: '5em',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ display: 'flex' }}>
                          <img
                            style={{
                              height: '20px',
                              pointerEvents: 'none',
                              margin: 'auto 0',
                            }}
                            src={
                              // '/images/img_solution_' +
                              // this.state.solution_type_cd +
                              // '.png'
                              this.platformImg(this.state.solution_type_cd)
                            }
                          ></img>
                          <p
                            className="m-l-md"
                            style={{ margin: 'auto 0 auto 20px' }}
                          >
                            <b>
                              {this.state.mall_id.replace(/_....$/, '')} 플랫폼의 쇼핑몰 정보를 확인해 주세요.
                            </b>
                          </p>
                        </div>

                        {this.state.shops != null &&
                        this.state.shops != '' &&
                        this.state.shops.length > 0 ? (
                          this.state.shops[0].solution_type_cd == '09001' ? (
                            <>
                              {/* 선택된 상점 연동하기 버튼 */}
                              <div
                                className="flexBox"
                                style={{ margin: 'auto 0 auto 20px' }}
                              >
                                <button
                                  className="btn-auto-d"
                                  style={{ height: '50px' }}
                                  onClick={() => this.refreshShops()}
                                >
                                  쇼핑몰 목록 새로고침
                                </button>
                              </div>
                            </>
                          ) : (
                            ''
                          )
                        ) : (
                          ''
                        )}
                      </div>
                      <table
                        className="m-t-32"
                        style={{ width: 'auto', minWidth: '100%' }}
                      >
                        <tbody>
                          {this.state.shops != null &&
                          this.state.shops.length > 0 ? (
                            this.state.shops.map((shop, idx) => (
                              <tr
                                key={idx}
                                id={shop.shop_no}
                                style={{
                                  height: '53px',
                                  borderBottom: '1.5px solid #DBDDE4',
                                  borderRight: '0px',
                                  verticalAlign: 'middle',
                                  color:
                                    shop.added_type == 'Y' ? '#BFC4D6' : '',
                                }}
                              >
                                <td
                                  id={'check_area_' + shop.shop_no}
                                  style={{ width: '50px', borderLeft: '0px' }}
                                >
                                  {this.state[
                                    'checkbox_state_' + shop.shop_no
                                  ] == null ||
                                  this.state[
                                    'checkbox_state_' + shop.shop_no
                                  ] == 'off' ? (
                                    <p className="card-text checkbox_v2">
                                      <input
                                        type="checkbox"
                                        name="select_event_all"
                                        onChange={(e) =>
                                          this.handleChangeSelectShop(
                                            e,
                                            shop.shop_no,
                                            shop.shop_name,
                                            'on',
                                          )
                                        }
                                      />
                                      <div
                                        className="checkbox"
                                        onClick={(e) =>
                                          shop.added_type == 'N'
                                            ? this.handleChangeSelectShop(
                                                e,
                                                shop.shop_no,
                                                shop.shop_name,
                                                'on',
                                              )
                                            : ''
                                        }
                                        style={{
                                          background:
                                            (shop.added_type == 'Y' || shop.added_type == 'F')
                                              ? '#D7DBE8'
                                              : '',
                                          borderColor:
                                            (shop.added_type == 'Y' || shop.added_type == 'F')
                                              ? '#BFC4D6'
                                              : '',
                                        }}
                                      ></div>
                                    </p>
                                  ) : (
                                    <p className="card-text checkbox_v2">
                                      <input
                                        type="checkbox"
                                        name="select_event_all"
                                        onChange={(e) =>
                                          this.handleChangeSelectShop(
                                            e,
                                            shop.shop_no,
                                            shop.shop_name,
                                            'off',
                                          )
                                        }
                                      />
                                      <div
                                        className="checkbox on"
                                        onClick={(e) =>
                                          this.handleChangeSelectShop(
                                            e,
                                            shop.shop_no,
                                            shop.shop_name,
                                            'off',
                                          )
                                        }
                                      ></div>
                                    </p>
                                  )}
                                </td>
                                <td
                                  style={{
                                    borderLeft: '0px',
                                    textAlign: 'left',
                                  }}
                                >
                                  {shop.shop_name} {shop.primary_domain}
                                </td>
                                <td
                                  style={{
                                    borderLeft: '0px',
                                    textAlign: 'right',
                                  }}
                                >
                                  {shop.added_type == 'F' ? (
                                    <span>상점키 등록을 관리자에게 요청해 주세요.</span>
                                  ) : shop.added_type == 'N' ? (
                                    <span>지금 등록해 보세요!</span>
                                  ) : (
                                    <span>
                                      연동됨&nbsp;&nbsp;･&nbsp;&nbsp;
                                      {shop.added_date}
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr
                              style={{
                                height: '53px',
                                borderBottom: '1.5px solid #DBDDE4',
                                borderRight: '0px',
                              }}
                            >
                              <td colSpan="5" style={{ borderLeft: '0px' }}>
                                데이터가 없습니다.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {/* 선택된 상점 연동하기 버튼 */}
                    <div
                      className="width-100 flexBox"
                      style={{ marginTop: '50px', marginBottom: '50px' }}
                    >
                      <button
                        className="btn-175-b"
                        onClick={() => this.onClickSelectShopSaveButton()}
                      >
                        선택한 쇼핑몰 연동하기
                      </button>
                    </div>
                  </>
                ) : this.state.finish_yn == 'Y' ? (
                  /* 플랫폼별 연동 현황 리스트 출력 */
                  <div id="platformShopsList">
                    {this.state.platform_shop_list != null ? (
                      <>
                        {this.state.platform_shop_list.map((platform, idx) =>
                          platform.target_shops.length > 0 ? (
                            <>
                              <div
                                key={idx}
                                id={platform.solution_type_cd}
                                style={{ marginTop: '80px', overflowX: 'auto' }}
                              >
                                <img
                                  style={{
                                    height: '20px',
                                    pointerEvents: 'none',
                                  }}
                                  src={
                                    // '/images/img_solution_' +
                                    // platform.solution_type_cd +
                                    // '.png'
                                    this.platformImg(platform.solution_type_cd)
                                  }
                                ></img>
                                <table className="m-t-32">
                                  <tbody>
                                    {
                                      //(platform.target_shops != null && platform.target_shops.length > 0) ?
                                      platform.target_shops.map((shop, j) => (
                                        <tr
                                          key={j}
                                          id={shop.shop_no}
                                          style={{
                                            height: '53px',
                                            borderBottom: '1.5px solid #DBDDE4',
                                            borderRight: '0px',
                                          }}
                                        >
                                          <td
                                            style={{
                                              borderLeft: '0px',
                                              width: '400px',
                                            }}
                                          >
                                            {shop.shop_name}
                                          </td>
                                          <td
                                            style={{
                                              borderLeft: '0px',
                                              width: '600px',
                                            }}
                                          >
                                            {shop.primary_domain}
                                          </td>
                                          <td
                                            style={{
                                              borderLeft: '0px',
                                              width: '250px',
                                            }}
                                          >
                                            {shop.added_date}
                                          </td>
                                          <td
                                            style={{
                                              borderLeft: '0px',
                                              width: '300px',
                                            }}
                                          >
                                            {/* 선택된 상점 연동하기 버튼 */}
                                            <div>
                                              <button
                                                className="btn-175-b"
                                                style={{
                                                  height: '30px',
                                                }}
                                                onClick={() =>
                                                  this.onClickShopSettingButton(
                                                    platform.solution_type_cd,
                                                    shop.shop_no,
                                                  )
                                                }
                                              >자동 배너 설정 바로가기
                                              </button>
                                            </div>
                                          </td>
                                          {/* <td style={{borderLeft:"0px", textAlign:"right"}}>연동 완료시 까지 약 4시간이 소요 됩니다.</td> */}
                                          {/* <td style={{borderLeft:"0px"}}>지금 등록됨</td> */}
                                          <td
                                            style={{
                                              borderLeft: '0px',
                                              width: '250px',
                                            }}
                                          >
                                            <div id="refresh" className="tagManager-KeywordArea" style={{width:"170px", textAlign:"left"}}>
                                              {
                                                shop.sync_state == "0"
                                                ? <div onClick={() => this.onClickRefreshDataSync(platform.solution_type_cd, shop.shop_no)}>
                                                    <div style={{float:"left", width:"30px", marginRight:"10px"}}>
                                                      <img id={platform.solution_type_cd +"_"+ shop.shop_no} src="" width="30px" alt="" />
                                                    </div>
                                                    <div style={{color:"#10CFC9", fontWeight:"bold"}}>상품 목록 불러오기</div>
                                                  </div>
                                                : <div onClick={() => 
                                                    alert("상품 목록을 불러오는 중입니다. 완료될 때까지 기다려 주세요.\n"+
                                                          "상품 수에 따라 짧게는 몇 분에서 길게는 몇 시간이 걸릴 수 있습니다.\n"+
                                                          "완료 시 카카오톡으로 알림 메시지를 드립니다.")
                                                  }>
                                                    <div style={{float:"left", width:"30px", marginRight:"10px"}}>
                                                      <img id={platform.solution_type_cd +"_"+ shop.shop_no} src={icn_refresh_spin} width="30px" style={{marginRight:"10px"}} alt="" />
                                                    </div>
                                                    <div style={{fontWeight:"bold", lineHeight:"30px"}}>상품 목록 불러오는 중</div>
                                                  </div>
                                              }
                                            </div>
                                          </td>
                                          {/* <td style={{borderLeft:"0px", width:"250px"}}>무료체험중</td> */}{' '}
                                          {/*사용 요금제*/}
                                        </tr>
                                      ))
                                      //: <tr style={{height:"53px", borderBottom:"1.5px solid #DBDDE4", borderRight:"0px"}}><td colSpan="5" style={{borderLeft:"0px"}}>데이터가 없습니다.</td></tr>
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </>
                          ) : (
                            ''
                          ),
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        {/* 진열여부 설정 팝업 
          1. popup_id : "common_popup1"		(div id 부여)
          2. width : "450px" 					(최소 450px)
          3. content : "팝업 내용<br/>예제 입니다."
          4. button_list : 
            [
              {
                title: "버튼명",			  // 버튼명
                type: "1",					// 버튼 (컬러)타입 : "1" (#10CFC9), "0" (#FFFFFF) 
                event_method: () => {
                  //이벤트 함수 구현
                  ...
                }
              },
            ]
          5. button_close : 닫기 함수 구현
        */}
        {
          (this.state.common_popup1 === true)
          ? <CommonPopup 
            popup_id={"common_popup1"}
            width={"465px"}
            content={this.state.common_popup1_content}
            button_list={this.state.common_popup1_button_list}
            popup_close={() => this.setState({common_popup1: false})}
          />
          : ''
        }
      </>
    );
  }
}

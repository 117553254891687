import React from 'react';
import axios from 'axios';
import http from '../modules/api';
import {Route, Switch, NavLink, Link} from "react-router-dom";
import $ from 'jquery';

import "react-datepicker/dist/react-datepicker.css";

import queryString from 'query-string';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-78553461-8');

var ga_label = sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")";

var pf = null;
var isPayLinkClick = false;

/*
        String USER_SEQ  		= String.valueOf(obj.get("RESERVEDINDEX1"));
		String SHOP_NO  		= String.valueOf(obj.get("RESERVEDINDEX2"));
		String PAYMETHOD		= String.valueOf(obj.get("PAYMETHOD"));
		String CPID			    = String.valueOf(obj.get("CPID"));
		String DAOU_TRX		    = String.valueOf(obj.get("DAOUTRX"));
		String ORDERNO			= String.valueOf(obj.get("ORDERNO"));
		String AMOUNT			= String.valueOf(obj.get("AMOUNT"));
		String SETTDATE		    = String.valueOf(obj.get("SETTDATE"));
		String EMAIL			= String.valueOf(obj.get("EMAIL"));
		String USERID			= String.valueOf(obj.get("USERID"));
		String PRODUCTCODE		= String.valueOf(obj.get("PRODUCTCODE"));
		String PRODUCTNAME		= String.valueOf(obj.get("PRODUCTNAME"));
		String RESERVEDSTRING	= String.valueOf(obj.get("RESERVEDSTRING"));

		String USERNAME		= null;
		if (obj.get("USERNAME") != null)
			USERNAME = new String(String.valueOf(obj.get("USERNAME")).getBytes("8859_1"), "EUC-KR");
		String BANKNAME		= null;
		if (obj.get("BANKNAME") != null)
			BANKNAME = new String(String.valueOf(obj.get("BANKNAME")).getBytes("8859_1"), "EUC-KR");
 */
export class bankLinkProc extends React.Component {
    state = {
        file_name: "",
        files: null,
        corp_reg_no: "",
        corp_name: "",
        business_type: "",
        business_item: "",
        representative_name: "",
        email: "",
        val: "",

        card_map: {},
        tax_request_map: {},

        popupType: "0", // 1: 카드등록, 2: 세금계산서 신청

        orderType: "", // DB에서 조회한 결제 타입
        orderTypeTmp: "", // 화면에서 변경한 결제 타입 // 02001: 카드 정기결제, 02002: 실시간 계좌이체

        payLinkPreData: {},

        queryString: new URLSearchParams(this.props.location.search),
    };

    componentDidMount(e) {
        this.setPayLinkProc();
    }

    componentDidUpdate() {
    }

    setPayLinkProc () {
        // alert(JSON.stringify(queryString));
        let data = {
            USER_SEQ: this.state.queryString.get("RESERVEDINDEX1"),
            SHOP_NO: this.state.queryString.get("RESERVEDINDEX2"),
            //PAYMENT_AMOUNT_SEQ: this.state.queryString.get("PAYMENT_AMOUNT_SEQ"),
            PAYMETHOD: this.state.queryString.get("PAYMETHOD"),
            CPID: this.state.queryString.get("CPID"),
            DAOUTRX: this.state.queryString.get("DAOUTRX"),
            ORDERNO: this.state.queryString.get("ORDERNO"),
            AMOUNT: this.state.queryString.get("AMOUNT"),
            SETTDATE: this.state.queryString.get("SETTDATE"),
            EMAIL: this.state.queryString.get("EMAIL"),
            USERID: this.state.queryString.get("USERID"),
            PRODUCTCODE: this.state.queryString.get("PRODUCTCODE"),
            PRODUCTNAME: this.state.queryString.get("PRODUCTNAME"),
            RESERVEDSTRING: this.state.queryString.get("RESERVEDSTRING"),   //PAYMENT_AMOUNT_SEQ
            USERNAME: this.state.queryString.get("USERNAME"),
            BANKNAME: this.state.queryString.get("BANKNAME")
        }
        console.log(data);

        const config = {
            headers: {
                'content-type': 'application/json;'
            }
        }

        http.post("/rest/common/payLinkProc", data, config)
        .then(res => {
            if (res.data.result == true) {
                // alert("결제를 완료하였습니다.");
                $("RESULT").text("RESULT");

            } else {
                // alert("결제를 완료하였으나 결제 결과 저장에 실패하였습니다."+"\n"+res.data.error_msg);
            }
        })
        .catch(error => {
            // alert("결제가 완료되었으나 결제 데이터 결과 실패하였습니다."+"\n"+error);
        });

        // window.open('','_self').close();
    }

	render() {
        let codes = "<RESULT></RESULT>";
        return (
            <div>
                {codes}
            </div>
        )
    }
}   
import React from 'react';
import axios from 'axios';
import http from '../modules/api';
import {Route, Switch, NavLink, Link} from "react-router-dom";
// import {AdminUserDetail} from "./AdminUserDetail";
import $ from 'jquery';
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";

import {AdminUserDetail} from "./AdminUserDetail";

import "react-datepicker/dist/react-datepicker.css";

var is_change_select_user_seq = false;
var is_change_select_shop_no = false;

export class UserPaymentPanList extends React.Component {
	state = {
			user_payment_plan_list: [],
			orderGubun: "",
			sorting: "",
			currentPage: "",
			start_date: "",
			end_date: "",
			start_date_format: "",
			end_date_format: "",
			title: "",
			activePage: 1,
			totalCnt: 0,
			startIdx: 0,
			rowcnt:10,

			popupType: "1",

			userIdCorp_list: [],
			shop_list: [],
			select_user_seq: "",
			select_shop_no: "",
	};

	handleText = (e, key) => {
	    // computed property
		this.setState({[key]: e.target.value});
	}
	
	componentDidMount(e) {
		// console.log(localStorage.getItem ('state'))
		if (localStorage.getItem ('state') != null){
			new Promise( ( resolve, reject ) => {setTimeout( resolve, 200 ); })
			.then( () => {
				var json = JSON.parse(localStorage.getItem ('state'));
				json.notice_list = [];
				json.start_date = Date.parse(json.start_date_format);
				json.end_date = Date.parse(json.end_date_format);
				this.setState(json);
				this.state.startIdx = parseInt(localStorage.getItem("startIdx"));
				localStorage.clear();
				// this.getUserPaymentPlanList();
			});
		} else {
			// this.getUserPaymentPlanList();
			this.getUserIdCorp();
		}
	}

	componentDidUpdate(e) {
		// console.log("######### componentDidUpdate ##########")
		if (is_change_select_user_seq) {
			this.getShopList(this.state.select_user_seq);
			is_change_select_user_seq = false;
		}
	}

	onComponentChange = e => {
		// console.log("######### onComponentChange ##########")
	}

	componentWillUnmount = e => {
		// console.log("######### componentWillUnmount ##########")
	}

	//1. 사용자 목록 조회
	async getUserIdCorp() {
		await http.get("/rest/shop/userIdCorp")
		.then(res => {
			this.setState( {userIdCorp_list : res.data["list"]} );
			// console.log(this.state.totalCnt)
		})
		.catch(error => {
			// console.log (error);
		 });
	}
	//2. 쇼핑몰 목록 조회
	async getShopList(user_seq) {
		await http.get("/rest/shop/selectShopsByuserSeq?user_seq="+user_seq)
		.then(res => {
			this.setState( {shop_list : res.data["list"]} );
			// console.log(this.state.totalCnt)
		})
		.catch(error => {
			// console.log (error);
		 });
	}
	
	async getUserPaymentPlanList() {
		// await http.get("/rest/shop/list/notice?start_date="+this.state.start_date_format+"&end_date="+this.state.end_date_format+"&title="+this.state.title
		// +"&order_type="+this.state.sorting+"&order_gubun="+this.state.orderGubun+"&startidx="+this.state.startIdx+"&rowcnt="+this.state.rowcnt)
		// .then(res => {
		// 	this.setState( {notice_list : res.data["list"]} );
		// 	this.setState( {totalCnt : res.data["totalCnt"]} );
		// 	// console.log(this.state.totalCnt)
		// })
		// .catch(error => {
		// 	// console.log (error);
		//  });

		 
    }
	
	//2. 상세 조회
	handleClick = (user_seq, shop_no, payment_type) => {
		// this.props.history.push(`/admin/notice/form/${notice_seq}`);
		// localStorage.setItem ( 'state' , JSON.stringify(this.state) ) ;
		// localStorage.setItem ( 'startIdx' , this.state.startIdx ) ;
	};
	
	// 3. 정렬
	onClickSorting = (e) => {
		if (this.state.orderGubun == e.target.className.replace(" sorting","")) {
			if (this.state.sorting == "ASC")
			this.state.sorting = "DESC";
			else
			this.state.sorting = "ASC";
		} else {
			this.state.sorting = "DESC";
		}
		this.state.orderGubun = e.target.className.replace(" sorting","");
		this.state.currentPage = 1;
		this.getUserPaymentPlanList()
	};

	//4. 조회 버튼 클릭
	onClickSearchListButton = () => {
		this.state.startIdx = 0;
		this.state.activePage = 1;
		this.getUserPaymentPlanList();
	};

	//4. 달력 클릭
	handleChangeStartDate = date => {
		if (date != null) {
			this.setState({
				start_date_format: date.getFullYear() + "-"+ ("0" + parseInt(date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(date.getDate())).slice(-2),
				start_date: date
			});
		} else {
			this.setState({
				start_date_format: date,
				start_date: date
			});
		}
	};
	handleChangeEndDate = date => {
		if (date != null) {
			this.setState({
				end_date_format: date.getFullYear() + "-"+ ("0" + parseInt(date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(date.getDate())).slice(-2),
				end_date: date
			});
		} else {
			this.setState({
				end_date_format: date,
				end_date: date
			});
		}
	};

	//5. 엔터키 검색
	_handleKeyDown = e => {
		if (e.key === 'Enter') {
			this.state.startIdx = 0;
			this.state.activePage = 1;
			this.getUserPaymentPlanList();
		}
	  }

	//6.페이징
	handlePageChange(pageNumber) {
		// console.log(`active page is ${pageNumber}`);
		this.setState({activePage: pageNumber});
		this.state.startIdx = (pageNumber * 10) - this.state.rowcnt;
		// console.log("this.state.startIdx :" + this.state.startIdx )
		this.getUserPaymentPlanList()
	}  

	componentWillReceiveProps(nextProps, nextContext) {
		// console.log(nextProps);
		this.getUserPaymentPlanList(nextProps.match.params.id);
	}

	onClickAddUserPayment = (e, popupType) => {
		  this.setState({popupType: popupType});
	}

	onChangeInputText = (e, name) => {
        this.setState({[name]:e.target.value})
	}
	
	onChangeSelectUserSeq = e => {
		is_change_select_user_seq = true;
		this.setState({select_user_seq: e.target.value})
	}
	
	onChangeSelectShopNo = e => {
		// is_change_select_shop_no = true;
		this.setState({select_shop_no: e.target.value})
	}


	  render() {
		
		return (
			<div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
				<div id="notice_list_component">
					{/* <h3>[Admin] 서비스 사용 목록</h3> */}
					{/* <br/> */}
					<div className="filter">
						{/* <div style={{display:"flex", marginBottom: '-12px'}}>
							<div className="input-group m-b" style={{width:"100%"}}>
								<div style={{width: "100%"}}> 
								<label>날짜</label>
									<div className="input-daterange input-group" id="datepicker">
										<DatePicker name="start_date" id="start_date"
											selected={this.state.start_date}
											onSelect={this.handleSelect} //when day is clicked
											onChange={this.handleChangeStartDate} //only when value has changed
											// showTimeSelect
											dateFormat="yyyy-MM-dd"
											autoComplete='off'
										/>
										<div className="input-group-addon" style={{width: "40px",height:"35px", lineHeight: "34px",textAlign:"center"}}>to</div>
										<DatePicker name="end_date" id="end_date"
											selected={this.state.end_date}
											onSelect={this.handleSelect} //when day is clicked
											onChange={this.handleChangeEndDate} //only when value has changed
											// showTimeSelect
											dateFormat="yyyy-MM-dd"
											autoComplete='off'
										/>	
									</div>
								</div>
							</div> */}
							{/* <div className="input-group m-b" style={{width:"33%", marginLeft:"10px"}}>
								<label>종류</label>
								<select style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px", marginBottom:"10px"}}>
									<option value="0">
										전체
									</option>
								</select>
							</div> */}
						{/* </div> */}
						<div style={{width:"100%",height: "35px", marginBottom:"10px"}}>
							<h3 style={{float:"left"}}>[Admin] 서비스 사용 목록</h3>
							<button className = "btn-auto-b" onClick={e => this.onClickAddUserPayment(e, "1")} style={{width: "80px",height: "35px", margin: 'auto', float:"right"}}>추가 +</button>
						</div>	
						<div style={{display:"flex", marginBottom: '12px'}}>
							<input type="text" className="border_form_radius_b_non_padding" onChange={(e) => {
								// console.log(e.target.value)
								this.setState({ title: e.target.value })
							}} placeholder="검색어를 입력하세요." style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px"}} onKeyDown={this._handleKeyDown}
							value={this.state.title}></input>
							<button className = "btn-auto-b" onClick={this.onClickSearchListButton} style={{width: "80px",height: "35px", margin: 'auto'}}>검색</button>
						</div>
					</div>
					<div className="banner_list_table_area" style={{width:"calc(95vw - 600px)", minWidth:"100%", height:"100%", overflowX:"auto", overflowY: "hidden"}}>
						<div style={{display:"inline-block", height:"100%", minWidth:"1100px", width:"100%"}}>
							<div className="table table_V2" style={{marginBottom:0}}>
								<div className="table">
									<table>
										<colgroup>
											<col width="30px" />
											<col width="200px" />
											<col width="70px" />
											<col width="100px" />
											<col width="100px" />
										</colgroup>
										<thead>	
											<tr>
												<td>
													<p className="card-text">순번</p>
												</td>
												<td>
													<p className="card-text">제목</p>
												</td>
												<td className="user_name sorting" onClick={this.onClickSorting}>
													작성자
												</td>
												<td className="reg_date sorting" onClick={this.onClickSorting}>
													등록일자
												</td>
												<td className="mod_date sorting" onClick={this.onClickSorting}>
													수정일자
												</td>
											</tr>
										</thead>
										<tbody>
											{this.state.user_payment_plan_list.map((payment, idx) => (
												<tr key={payment.notice_seq}>
													{/* <td>
														<p className="card-text">{payment.notice_seq}</p>
													</td> */}
													<td>
														<p className="card-text">{(this.state.totalCnt-(idx+(10*(this.state.activePage-1))))}</p>
														{/* totalCnt: {(this.state.totalCnt)}<br/>
														idx: {(idx)}<br/>
														activePage: {(this.state.activePage)}<br/> */}
													</td>
													<td>
														<p id="title" className="card-text" onClick={() => this.handleClick(payment.notice_seq)}>{payment.title}</p>
													</td>
													<td>
														<p className="card-text">{payment.user_name}</p>
													</td>
													<td>
														<p className="card-text">{payment.reg_date}</p>
													</td>
													<td>
														<p className="card-text">{payment.mod_date}</p>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<Pagination
						activePage={this.state.activePage}
						itemsCountPerPage={this.state.rowcnt}
						totalItemsCount={this.state.totalCnt}
						pageRangeDisplayed={5}
						onChange={this.handlePageChange.bind(this)}
					/>
				</div>








				{/* 카드등록 팝업 */}
                {
                    this.state.popupType == "1"
                    ?   <div>
                            <div id="modal" style={{maxHeight:"100%"}}>
                                <div id="modalContent" className="shadow-box" style={{padding:"24px 30px",maxHeight:"80%", overflowY:"auto"}}>
                                    <div className="f-s-18 m-b-lg">
                                        {
                                            // !(Object.keys(this.state.card_map).length === 0 && this.state.card_map.constructor === Object)
                                            // ?   <b>카드 조회</b>
                                            // :   <b>카드 등록</b>
                                        }
										<b>사용자 맞춤 요금제 추가</b>
                                    </div>
									<div className="formArea">
										<div className="flexBox m-t-15">
											<div className="tax_request_input_div" style={{flex:"1", marginRight:"10px"}}>
												<font style={{color:"#6d6d6d"}}>아이디 (상호명)</font>
												<select className="border_form_radius_d_non_padding" style={{width:"100%", height:"35px"}} value={this.state.select_user_seq} onChange={this.onChangeSelectUserSeq}>
													<option value="" disabled>
														아이디 (상호명)을 선택해주세요.
													</option>
													{
														this.state.userIdCorp_list.map((obj, idx) => (
															<option key={idx} value={obj.user_seq}>
																{obj.user_id_corp_name}
															</option>
														))
													}
												</select>
											</div>
											<div className="tax_request_input_div" style={{flex:"1"}}>
												<font style={{color:"#6d6d6d"}}>쇼핑몰</font>
													{
														this.state.select_user_seq != null && this.state.select_user_seq != "" 
														? 	<select className="border_form_radius_d_non_padding" style={{width:"100%", height:"35px"}} value={this.state.select_shop_no} onChange={this.onChangeSelectShopNo}>
																<option value="" disabled>
																	쇼핑몰을 선택해주세요.
																</option>
																{
																	this.state.shop_list.map((obj, idx) => (
																		<option key={idx} value={obj.shop_no}>
																			{obj.shop_name}
																		</option>
																	))
																}
															</select>
														: 	<select className="border_form_radius_d_non_padding" style={{width:"100%", height:"35px"}} value="">
																<option value="" disabled>
																	아이디 (상호명)을 먼저 선택해주세요.
																</option>
															</select>
													}
											</div>
										</div>
										<div className="flexBox m-t-15">
											<div className="tax_request_input_div" style={{flex:"1", marginRight:"10px"}}>
												<font style={{color:"#6d6d6d"}}>기본료</font>
												<div style={{display:"flex", alignItems: "center"}}>
													<input className="border_form_radius_d_non_padding" type="number" style={{width:"100%", height:"35px", borderRadius:"8px", marginRight:"5px"}} onChange={e => this.onChangeInputText(e, "card_no")}></input>원
												</div>	
											</div>
											<div className="tax_request_input_div" style={{flex:"1"}}>
											<font style={{color:"#6d6d6d"}}>트래픽 발생 기준 (~명 이상부터 추가요금)</font>
												<div style={{display:"flex", alignItems: "center"}}>
													<input className="border_form_radius_d_non_padding" type="number" style={{width:"100%", height:"35px", borderRadius:"8px", marginRight:"5px"}} onChange={e => this.onChangeInputText(e, "card_mm")}></input>명
												</div>
											</div>
										</div>
										<div className="flexBox m-t-15">
											<div className="tax_request_input_div" style={{flex:"1"}}>
											<font style={{color:"#6d6d6d"}}>추가요금 (트래픽 1명당 요금)</font>
												<div style={{display:"flex", alignItems: "center"}}>
													<input className="border_form_radius_d_non_padding" type="number" style={{width:"100%", height:"35px", borderRadius:"8px", marginRight:"5px"}} onChange={e => this.onChangeInputText(e, "card_yy")}></input>원
												</div>
											</div>
										</div>
										<div className="flexBox m-t-32">
											<button id="btnSaveTaxRecipt" className="btn-auto-b" style={{flex:"1", height:"40px", borderRadius:"8px"}} onClick={this.saveTaxRequestFileUpload}>등록하기</button> 
										</div>
									</div>
                                </div>
                                <div className="modalClose_v2" onClick={this.closePopup}></div>
                            </div>
                            {/* 팝업창 배경 */}
                            <div id="modalBack" style={{top: "0", left:"0", height:"130%", zIndex:"999"}}></div>
                        </div>
                    : ""
                }
			</div>
		);
	}
}

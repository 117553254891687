import React from 'react';
import axios from 'axios';
import http from '../modules/api';
import {Route, Switch, NavLink, Link} from "react-router-dom";
import $ from 'jquery';

import "react-datepicker/dist/react-datepicker.css";

import ReactGA from 'react-ga';
ReactGA.initialize('UA-78553461-8');

var ga_label = sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")";

var pf = null;
var isPayLinkClick = false;

export class Order extends React.Component {
    state = {
        file_name: "",
        files: null,
        corp_reg_no: "",
        corp_name: "",
        business_type: "",
        business_item: "",
        representative_name: "",
        email: "",
        val: "",

        card_map: {},
        tax_request_map: {},

        popupType: "0", // 1: 카드등록, 2: 세금계산서 신청

        orderType: "", // DB에서 조회한 결제 타입
        orderTypeTmp: "", // 화면에서 변경한 결제 타입 // 02001: 카드 정기결제, 02002: 실시간 계좌이체

        payLinkPreData: {},

        corp_reg_no: "",      
        corp_name: "",      
        business_type: "",      
        business_item: "",      
        representative_name: "",      
        email: "",      
        tax_val: "",      
    };

    componentDidMount(e) {
        pf = document.payForm;
        console.log(pf);
        // alert(JSON.stringify(pf));

        this.loadOrderType();
        this.loadTaxRequest();
        this.loadCardKeygenProc();
    }

    componentDidUpdate() {
        if(isPayLinkClick) {
            isPayLinkClick = false;

            console.log(pf);
            pf = document.payForm;
        
            var fileName = "https://apitest.payjoa.co.kr/pay/link"; //개발서버
            //var fileName = "https://api.payjoa.co.kr/pay/link"; //운영서버
        
            var PAYJOA = window.open("", "PAYJOA", "width=468,height=750");
            
            pf.target = "PAYJOA";
            pf.action = fileName;
            pf.method = "post";
            pf.submit();
        }
    }

    componentWillUnmount () {

    }

    loadTaxRequest() {
        const url = '/rest/shop/load/tax/request';
        http.get(url)
        .then(res => {
            if (res.data != null && res.data != "") {
                this.setState({
                    tax_request_map: res.data,
                    corp_reg_no: res.data["corp_reg_no"],
                    corp_name: res.data["corp_name"],
                    business_type: res.data["business_type"],
                    business_item: res.data["business_item"],
                    representative_name: res.data["representative_name"],
                    representative_name: res.data["representative_name"],
                    email: res.data["email"],
                    file_name: res.data["business_registration_file_name"],
                    tax_val: res.data["val"],
                });
            }
        })
        .catch(error => {

        });
    }

    loadOrderType() {
        const formData = new FormData();
        formData.append('order_type', this.state.orderTypeTmp);
        const url = '/rest/shop/get/orderType';
        http.post(url, formData)
        .then(res => {
            if (res.data.order_type != null) {
                this.setState({
                    orderType: res.data.order_type,
                    orderTypeTmp: res.data.order_type
                })
            }
        })
        .catch(error => {

        });
    }

    saveOrderType() {
        if (this.state.orderTypeTmp == "02001") {
            if ((Object.keys(this.state.card_map).length === 0 && this.state.card_map.constructor === Object)) {
                alert("정기결제를 위한 체크·신용카드를 등록해 주세요.")
                this.onClickOpenPopup(null, 1);
                return false;
            }
        }

        const formData = new FormData();
        formData.append('order_type', this.state.orderTypeTmp);
        const url = '/rest/shop/set/orderType';
        http.post(url, formData)
        .then(res => {
            if (res.data.result) {
                alert("변경에 성공하였습니다.");
                window.location.reload();
            } else {
                alert("변경에 실패하였습니다.");
            }
        })
        .catch(error => {

        });
    }
    
    handleClickBtnFileUpload1  = e => { 
        e.preventDefault ();
        $("#Files1").click();	
    };

    _handleFileChange = e => {
        var target = e.target;
		
		console.log(target.files[0]);
        this.setState( {files: target.files[0]});
        
        var this_class = this;
		if (target.files != null && target.files[0] != null) {
            var fime_name = target.files[0]["name"];
			var reader = new FileReader();
			reader.onload = function (e) {
                this_class.setState({file_name: fime_name});
				// $("#attachFileName").text(target.files[0].name);
			}
			reader.readAsDataURL(target.files[0]);
		} else {
            // $("#attachFileName").text("");
            this_class.setState({file_name: ""});
		}
    }

    saveTaxRequestFileUpload = e => {
		if (this.state.corp_reg_no == null || this.state.corp_reg_no == "") {
			alert("사업자등록번호를 입력하여주세요.");
        } 
		else if (this.state.corp_name == null || this.state.corp_name == "") {
			alert("상호(법인명)을 입력하여주세요.");
        } 
		else if (this.state.business_type == null || this.state.business_type == "") {
			alert("업태를 입력하여주세요.");
        } 
		else if (this.state.business_item == null || this.state.business_item == "") {
			alert("업종을 입력하여주세요.");
        } 
		else if (this.state.representative_name == null || this.state.representative_name == "") {
			alert("대표자명을 입력하여주세요.");
        } 
		else if (this.state.email == null || this.state.email == "") {
			alert("세금계산서 발행 이메일울 입력하여주세요.");
        } 
        else if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.email))) {
            alert("올바른 이메일을 입력하여 주세요.");
        } 
		else if (this.state.files == null || this.state.files == []) {
            if (this.state.file_name == null || this.state.file_name == "") {
			    alert("사업자등록증을 첨부하여 주세요.");
            } else {
                const url = '/rest/shop/save/tax/request';
                const formData = new FormData();
                formData.append('corp_reg_no',this.state.corp_reg_no);
                formData.append('corp_name',this.state.corp_name);
                formData.append('business_type',this.state.business_type);
                formData.append('business_item',this.state.business_item);
                formData.append('representative_name',this.state.representative_name);
                formData.append('email',this.state.email);
                formData.append('val',this.state.tax_val);
                const config = {
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded; charset=utf-8'
                    }
                }
                http.post(url, formData,config)
                .then(res => {
                    alert("신청을 완료하였습니다.");
                })
                .catch(error => {
                    alert("신청에 실패하였습니다.\n"+error);
                });
            }
        } 
        else {
			const url = '/rest/shop/save/tax/request/file';
			const formData = new FormData();
			formData.append('file',this.state.files);
			formData.append('corp_reg_no',this.state.corp_reg_no);
			formData.append('corp_name',this.state.corp_name);
			formData.append('business_type',this.state.business_type);
			formData.append('business_item',this.state.business_item);
			formData.append('representative_name',this.state.representative_name);
			formData.append('email',this.state.email);
			formData.append('val',this.state.val);
			const config = {
				headers: {
					'content-type': 'application/x-www-form-urlencoded; charset=utf-8'
				}
			}
            http.post(url, formData,config)
            .then(res => {
                alert("신청을 완료하였습니다.");
            })
            .catch(error => {
                alert("신청에 실패하였습니다.\n"+error);
             });
		}
    }  
    saveCardKeygenProc = e => {
		if (this.state.CARDNO == null || this.state.CARDNO == "") {
			alert("카드번호를 입력하여주세요.");
        } 
		else if (this.state.EXPIREDT == null || this.state.EXPIREDT == "") {
			alert("카드유효기간을 입력해주세요.");
        } 
		else if (this.state.CARDAUTH == null || this.state.CARDAUTH == "") {
			alert("카드 소유자 생년월일(6자) 또는 사업자번호를 입력해주세요.");
        } 
		else if (this.state.CARDAUTH == null || this.state.CARDAUTH == "") {
			alert("카드 비밀번호 앞 2자리를 입력해주세요.");
        } 
		else if (this.state.CARDPASSWORD == null || this.state.CARDPASSWORD == "") {
			alert("대표자명을 입력해주세요.");
        } 
		else if (this.state.CARDALIAS == null || this.state.CARDALIAS == "") {
			alert("카드 별명을 입력해주세요.");
        } 
        else {
			this.insertCardKeygenProc();
		}
    }  
    onChangeInputText = (e, name) => {
        this.setState({[name]:e.target.value})
    }

    closePopup = e => {
        this.setState({popupType: "0"})
    }
    onClickOpenPopup = (e, popupType) => {
        this.setState({popupType: popupType})
        // if (popupType == 1) {
        //     this.loadCardKeygenProc();
        // }
    }

    insertCardKeygenProc () {
        let data = {
            CARDNO: this.state.CARDNO,
            EXPIREDT: this.state.EXPIREDT,
            CARDAUTH: this.state.CARDAUTH,
            CARDPASSWORD: this.state.CARDPASSWORD,
            CARDALIAS: this.state.CARDALIAS
        }

        const config = {
            headers: {
                'content-type': 'application/json;'
            }
        }
        
        http.post("/rest/shop/cardKeygenProc", data, config)
        .then(res => {
            if (res.data.result == true) {
                alert("카드가 등록되었습니다.");
                window.location.reload();
            } else {
                alert(res.data.errormessage);
            }
        })
        .catch(error => {

        });
    }

    updateCardAlias = e => {
        let data = {
            CARDALIAS: this.state.CARDALIAS
        }

        const config = {
            headers: {
                'content-type': 'application/json;'
            }
        }

        http.post("/rest/shop/update/card", data, config)
        .then(res => {
            if (res.data.result == true) {
                alert("별명이 수정되었습니다.");
                window.location.reload();
            } else {
                alert("별명이 수정에 실패하였습니다.");
            }
        })
        .catch(error => {

        });
    }

    loadCardKeygenProc () {
        http.get("/rest/shop/get/cardKeygen")
        .then(res => {
            if(res.data != null && res.data != "") {
                this.setState({card_map: res.data
                , CARDALIAS : res.data["card_alias"]});
            }
        })
        .catch(error => {

        });
    }

    onClickOrderPaymentType = (e, payType) => {
        this.setState({orderTypeTmp: payType});

        if (payType == "02001") {
            if ((Object.keys(this.state.card_map).length === 0 && this.state.card_map.constructor === Object)) {
                this.onClickOpenPopup(e, 1);
            }
        }
    }

    onClickOrderBank = (e) => {
        let today = new Date();  
        let lastDateByMonth = new Date(today.getFullYear(), today.getMonth(), 0).getDate();
        // alert(lastDateByMonth);
        if (this.state.orderType != "02002") {
            alert("결제 수단을 실시간계좌이체로 변경하셔야 실시간계좌이체가 가능합니다.");
            return false;
        }

        // if (today.getDate() != lastDateByMonth) {
        //     alert("결제 가능일은 매월 말일부터 7일동안 입니다.\n"+lastDateByMonth+"일에 다시 시도해 주세요.");
        //     return false;
        // }

        this.payLink(e);
    }
    onClickOrderCard = (e) => {
        let today = new Date();  
        let lastDateByMonth = new Date(today.getFullYear(), today.getMonth(), 0).getDate();
        // alert(lastDateByMonth);
        if (this.state.orderType != "02001") {
            alert("결제 수단을 카드로 변경하셔야 카드결제가 가능합니다.");
            return false;
        }

        // if (today.getDate() != lastDateByMonth) {
        //     alert("결제 가능일은 매월 말일부터 7일동안 입니다.\n"+lastDateByMonth+"일에 다시 시도해 주세요.");
        //     return false;
        // }

        this.cardBatch(e);
    }

    payLink = e => {
            http.get("/rest/shop/get/payLinkPreData")
            .then(res => {
                alert(JSON.stringify(res.data))
                isPayLinkClick = true;
                this.setState({payLinkPreData: res.data});
            })
            .catch(error => {
    
            });
    }

    cardBatch = e => {
            var is_pay = window.confirm("등록된 카드를 이용하여 결제를 진행하시겠습니까?");

            if(is_pay) {
                http.get("/rest/common/cardBatch")
                .then(res => {
                    alert(JSON.stringify(res.data));
                })
                .catch(error => {
        
                });
            }
    }

    onClickDeleteCard = (e) => {
        var confirm = window.confirm("등록된 카드를 정말로 삭제 하시겠습니까?\n카드를 삭제하면 반드시 재등록을 해야 미납 요금이 발생하지 않습니다.")
        if (confirm) {
            http.get("/rest/shop/delete/card")
            .then(res => {
                alert(res.data.result_msg);
                if(res.data.result == "true" || res.data.result == true) {
                    window.location.reload()
                } 
            })
            .catch(error => {
    
            });
        }
    }

	render() {
        $(".pageName").text("결제 수단 관리");
        // let today = new Date();  
        // alert(JSON.stringify(this.state.card_map))
        // alert(Object.keys(this.state.tax_request_map).length)
        // alert(this.state.tax_request_map)
        // alert(this.state.tax_request_map.constructor)

        // alert(((Object.keys(this.state.card_map).length === 0 && this.state.card_map.constructor === Object)))
        return (
            <div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
                <div className="form_primary_title pc_page_title" style={{fontSize:"27px"}}>결제수단 관리</div>
                <div>
                    <div className="flexBox credit_card_box_area" style={{marginTop:"50px", padding: "37px 50px", border:"1px #EBEBED solid", borderRadius:"10px"}}>
                        <div className="icn_credit_card" style={{margin: "auto 10px auto 0"}}></div>
                        {/* <div>{today.getFullYear()}년 {today.getMonth()+1}월 {today.getDate()}일 오늘 기준 고객님의 결제 예상 금액</div> */}
                        {
                            this.state.orderType == "02001"
                            ? <div className="icn_credit_card_text icn_credit_card_text_center" style={{margin: "auto 0", fontSize:"18px"}}><b>현재 고객님의 결제 수단은 카드 정기결제로 설정되어 있습니다.</b></div>
                            : 
                                this.state.orderType == "02002"
                                ? <div className="icn_credit_card_text icn_credit_card_text_center" style={{margin: "auto 0", fontSize:"18px"}}><b>현재 고객님의 결제 수단은 실시간 계좌이체로 설정되어 있습니다.</b></div>
                                : 
                                    this.state.orderType == "" || this.state.orderType == null
                                    ?   <div className="icn_credit_card_text" style={{margin: "auto 0", fontSize:"18px"}}><b>결제 수단을 등록해 주세요.</b></div>
                                    :   ""
                        }
                        
                    </div>
                    <div className="credit_card_box_area credit_card_box_area_mobile" style={{marginTop:"20px", padding: "50px", padding: "37px 50px", border:"1px #EBEBED solid", borderRadius:"10px"}}>
                        <div className="flexBox">
                            <div className="order_type_area" style={{width:"21vw"}}>
                                <div className="redio_v2 flexBox" style={{marginTop: "auto", flexDirection:"row"}}>
                                    <div className="flexBox radio_area" style={{margin:"auto 0", width:"auto"}}>
                                        <input type="radio"></input>
                                        {
                                            this.state.orderTypeTmp == "02001"
                                            ?   <>
                                                    <div className="radio on" onClick={
                                                        e => this.onClickOrderPaymentType(e, "02001")
                                                    }><div className="hover"></div></div><div className="radio_text"><b>체크·신용카드 {/*정기결제*/}</b>
                                                    </div>
                                                </>
                                            :   <>
                                                    <div className="radio" onClick={
                                                        e => this.onClickOrderPaymentType(e, "02001")
                                                    }><div className="hover"></div></div><div className="radio_text" style={{color:"#818283"}}>체크·신용카드 {/*정기결제*/}
                                                    </div>
                                                </>
                                        }
                                        </div>
                                        {/* {
                                        this.state.orderType == "02001"
                                        ?   <button className="btn-auto-w" 
                                                style={{
                                                    marginLeft:"20px", height: "45px", borderRadius: "10px", padding: "0 15px"
                                                }}
                                                onClick={e => this.onClickOrderCard(e)}>
                                                등록된 카드로 결제하기
                                            </button>
                                        :   <button className="btn-auto-w" 
                                                style={{
                                                    marginLeft:"20px", height: "45px", borderRadius: "10px", padding: "0 15px", background:"#efefef", color:"rgb(129, 130, 131)", borderColor:"rgb(129, 130, 131)", cursor:"not-allowed", fontWeight:"lighter"
                                                }}
                                                onClick={e => this.onClickOrderCard(e)}>
                                                등록된 카드로 결제하기
                                            </button>
                                    } */}
                                </div>
                                <div className="flexBox">
                                    {
                                        !((Object.keys(this.state.card_map).length === 0 && this.state.card_map.constructor === Object))
                                        ?   <>
                                                {
                                                    this.state.orderTypeTmp != "02001"
                                                    ?   <div className="order_type_box_position_rel order_type_box"
                                                        // className="credit_card_box" 
                                                        style={{width: "100%",
                                                        height: "30vw",
                                                        border: "1px solid #FAFAFB",
                                                        backgroundColor: "#FAFAFB",
                                                        borderRadius: "13px",
                                                        marginTop: "15px",
                                                        marginLeft: "10px",
                                                        color: "#333333",
                                                        display:"flex",
                                                        flexDirection:"column",
                                                        justifyContent:"space-between"
                                                        }}>
                                                            <div className="order_type_box_txt_flexbox pc_display_none" style={{flex:"2", justifyContent:"center", display: "flex", justifyContent: "center"}}>
                                                                <div className="order_type_box_txt order_type_box_card_no" style={{textAlign:"center", fontSize:"25px" , margin: "auto auto auto auto", height:"60px" }}>
                                                                    <b>{this.state.card_map.card_no}</b>
                                                                </div>
                                                            </div>
                                                            <div className="order_type_box_txt_flexbox pc_display_none" style={{flex:"1", justifyContent:"center", display: "flex", justifyContent: "center"}}>
                                                                <div className="order_type_box_txt order_type_box_card_alias" style={{textAlign:"center", fontSize:"25px" , margin: "auto auto auto auto", width: "115px", height:"30px", lineHeight:"25px", background:"white", borderRadius:"100px"}}>
                                                                    <b style={{
                                                                            fontSize:"16px",
                                                                            lineHeight: "12px"
                                                                        }}>{this.state.card_map.card_alias}</b>
                                                                </div>
                                                            </div>
                                                            <div className="order_type_box_txt_flexbox mobile_display_none" style={{flex:"1", justifyContent:"center", display: "flex", justifyContent: "center"}}>
                                                                <div className="order_type_box_txt" style={{
                                                                    textAlign:"center", 
                                                                    fontSize:"25px" , 
                                                                    margin: "auto auto auto auto", 
                                                                    width: "115px", 
                                                                    height:"30px", 
                                                                    lineHeight:"25px", 
                                                                    background:"white", 
                                                                    borderRadius:"100px",
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                    whiteSpace: "nowrap",}}>
                                                                    <b style={{
                                                                            fontSize:"16px",
                                                                            lineHeight: "12px"
                                                                        }}>{this.state.card_map.card_alias}</b>
                                                                </div>
                                                            </div>
                                                            <div className="order_type_box_txt_flexbox mobile_display_none" style={{flex:"2", justifyContent:"center", display: "flex", justifyContent: "center"}}>
                                                                <div className="order_type_box_txt" style={{textAlign:"center", fontSize:"25px" , margin: "auto auto auto auto", height:"60px" }}>
                                                                    <b>{this.state.card_map.card_no}</b>
                                                                </div>
                                                            </div>
                                                            <div className="order_type_box_position_abs" style={{flex:"1", justifyContent:"center", display: "flex", justifyContent: "center"}}>
                                                                <div style={{display:"flex", textAlign:"center", margin: "auto auto auto auto", fontSize: "12px", lineHeight:"16px"}}>
                                                                    <button className="btn-auto-b" 
                                                                    style={{
                                                                        width: "92px",
                                                                        height: "36px",
                                                                        background: "#10CFC9",
                                                                        borderRadius: "5px",
                                                                        fontSize: "12px",
                                                                        padding: "0px",
                                                                        display:"flex"
                                                                        }}
                                                                        onClick={e => this.onClickOpenPopup(e, 1)}>
                                                                        <div style={{margin:"auto"}}>카드 별명 변경</div>
                                                                    </button>
                                                                    <div 
                                                                    style={{
                                                                        width: "52px",
                                                                        height: "36px",
                                                                        fontSize: "12px",
                                                                        padding: "0px",
                                                                        display:"flex",
                                                                        color:"#BEBEBF",
                                                                        cursor:"pointer"
                                                                        }}
                                                                        onClick={e => this.onClickDeleteCard(e)}>
                                                                        <div style={{margin:"auto"}}>삭제</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    :   <div className="order_type_box_position_rel order_type_box"
                                                        // className="credit_card_box" 
                                                        style={{width: "100%",
                                                        height: "30vw",
                                                        border: "1px solid #10CFC9",
                                                        backgroundColor: "#10CFC9",
                                                        borderRadius: "13px",
                                                        marginTop: "15px",
                                                        marginLeft: "10px",
                                                        color: "white",
                                                        display:"flex",
                                                        flexDirection:"column",
                                                        justifyContent:"space-between"
                                                        }}>
                                                            <div className="order_type_box_txt_flexbox pc_display_none" style={{flex:"2", justifyContent:"center", display: "flex", justifyContent: "center"}}>
                                                                <div className="order_type_box_txt order_type_box_card_no active" style={{textAlign:"center", fontSize:"25px" , margin: "auto auto auto auto", height:"60px" }}>
                                                                    <b>{this.state.card_map.card_no}</b>
                                                                </div>
                                                            </div>
                                                            <div className="order_type_box_txt_flexbox pc_display_none" style={{flex:"1", justifyContent:"center", display: "flex", justifyContent: "center"}}>
                                                                <div className="order_type_box_txt order_type_box_card_alias active" style={{textAlign:"center", fontSize:"25px" , margin: "auto auto auto auto", width: "115px", height:"30px", lineHeight:"25px", background:"white", borderRadius:"100px", color:"#333333" }}>
                                                                    <b style={{
                                                                        fontSize:"16px",
                                                                        lineHeight: "12px"
                                                                    }}>{this.state.card_map.card_alias}</b>
                                                                </div>
                                                            </div>
                                                            <div className="order_type_box_txt_flexbox mobile_display_none" style={{flex:"1", justifyContent:"center", display: "flex", justifyContent: "center"}}>
                                                                <div className="order_type_box_txt active" style={{
                                                                    textAlign:"center", 
                                                                    fontSize:"25px" , 
                                                                    margin: "auto auto auto auto", 
                                                                    width: "115px", 
                                                                    height:"30px", 
                                                                    lineHeight:"25px", 
                                                                    background:"white", 
                                                                    borderRadius:"100px", 
                                                                    color:"#333333",
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                    whiteSpace: "nowrap", }}>
                                                                    <b style={{
                                                                        fontSize:"16px",
                                                                        lineHeight: "12px"
                                                                    }}>{this.state.card_map.card_alias}</b>
                                                                </div>
                                                            </div>
                                                            <div className="order_type_box_txt_flexbox mobile_display_none" style={{flex:"2", justifyContent:"center", display: "flex", justifyContent: "center"}}>
                                                                <div className="order_type_box_txt active" style={{textAlign:"center", fontSize:"25px" , margin: "auto auto auto auto", height:"60px" }}>
                                                                    <b>{this.state.card_map.card_no}</b>
                                                                </div>
                                                            </div>
                                                            <div className="order_type_box_position_abs" style={{flex:"1", justifyContent:"center", display: "flex", justifyContent: "center"}}>
                                                                <div style={{display:"flex", textAlign:"center", margin: "auto auto auto auto", fontSize: "12px", lineHeight:"16px"}}>
                                                                <button className="btn-auto-b" 
                                                                    style={{
                                                                        width: "92px",
                                                                        height: "36px",
                                                                        background: "#58DDD9",
                                                                        borderRadius: "5px",
                                                                        fontSize: "12px",
                                                                        padding: "0px",
                                                                        display:"flex"
                                                                        }}
                                                                        onClick={e => this.onClickOpenPopup(e, 1)}>
                                                                        <div style={{margin:"auto"}}>카드 별명 변경</div>
                                                                    </button>
                                                                    <div 
                                                                    style={{
                                                                        width: "52px",
                                                                        height: "36px",
                                                                        fontSize: "12px",
                                                                        padding: "0px",
                                                                        display:"flex",
                                                                        color:"#9FECE9"
                                                                        }}
                                                                        onClick={e => this.onClickDeleteCard(e)}>
                                                                        <div style={{margin:"auto"}}>삭제</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                }
                                            </>
                                        :   <>
                                            <div className="cursor-pointer order_type_box" style={{width: "100%",
                                                height: "30vw",
                                                border: "1px solid #FAFAFB",
                                                backgroundColor: "#FAFAFB",
                                                borderRadius: "13px",
                                                marginTop: "15px"}}
                                                onClick={e => this.onClickOpenPopup(e, 1)}>
                                                    <div className="icn_plus_v2_g" style={{margin: "10vw auto auto auto"}}></div>
                                                    <div style={{textAlign:"center", margin: "10vw auto auto auto", fontSize: "12px", lineHeight:"16px",}}>
                                                        <b>정기결제를 위한<br/>카드를 등록해주세요.</b>
                                                    </div>
                                                </div> 
                                            </>
                                    }
                                </div>
                            </div>
                            <div className="order_type_area" style={{marginLeft: "50px", width:"21vw"}}>
                                <div className="redio_v2 flexBox" style={{marginTop: "auto", flexDirection:"row"}}>
                                    <div className="flexBox radio_area" style={{margin:"auto 0", width:"auto"}}>
                                        <input type="radio"></input>
                                        {
                                            this.state.orderTypeTmp == "02002"
                                            ?   <>
                                                    <div className="radio on" onClick={
                                                        e => this.onClickOrderPaymentType(e, "02002")
                                                    }><div className="hover"></div></div><div className="radio_text"><b>실시간계좌이체</b>
                                                    </div>
                                                </>
                                            :   <>
                                                    <div className="radio" onClick={
                                                        e => this.onClickOrderPaymentType(e, "02002")
                                                    }><div className="hover"></div></div><div className="radio_text" style={{color:"#818283"}}>실시간계좌이체
                                                    </div>
                                                </>
                                        }
                                    </div> 
                                    {/* {
                                        this.state.orderType == "02002"
                                        ?   <button className="btn-auto-w" 
                                                style={{
                                                    marginLeft:"20px", height: "45px", borderRadius: "10px", padding: "0 15px"
                                                }}
                                                onClick={e => this.onClickOrderBank(e)}>
                                                실시간계좌이체로 결제하기
                                            </button>
                                        :   <button className="btn-auto-w" 
                                                style={{
                                                    marginLeft:"20px", height: "45px", borderRadius: "10px", padding: "0 15px", background:"#efefef", color:"rgb(129, 130, 131)", borderColor:"rgb(129, 130, 131)", cursor:"not-allowed", fontWeight:"lighter"
                                                }}
                                                onClick={e => this.onClickOrderBank(e)}>
                                                실시간계좌이체로 결제하기
                                            </button>
                                    } */}
                                </div>

                                <div className="flexBox">
                                    {
                                        !((Object.keys(this.state.tax_request_map).length === 0 && this.state.tax_request_map.constructor === Object))
                                        ?   <>
                                                {
                                                    this.state.orderTypeTmp != "02002"
                                                    ?   <div className="order_type_box_position_rel order_type_box"
                                                        // className="credit_card_box" 
                                                        style={{width: "100%",
                                                        height: "30vw",
                                                        border: "1px solid #FAFAFB",
                                                        backgroundColor: "#FAFAFB",
                                                        borderRadius: "13px",
                                                        marginTop: "15px",
                                                        marginLeft: "10px",
                                                        color: "#333333",
                                                        display:"flex",
                                                        flexDirection:"column",
                                                        justifyContent:"space-between"
                                                        }}>
                                                            <div className="order_type_box_txt_flexbox" style={{flex:"1", justifyContent:"center", display: "flex", justifyContent: "center"}}>
                                                                <div className="order_type_box_txt" style={{textAlign:"center", fontSize:"25px" , margin: "auto auto auto auto", width: "115px", height:"30px", lineHeight:"25px", background:"white", borderRadius:"100px" }}>
                                                                    <b style={{
                                                                            fontSize:"16px",
                                                                            lineHeight: "12px"
                                                                        }}>{"세금계산서 정보"}</b>
                                                                </div>
                                                            </div>
                                                            <div className="order_type_box_txt_flexbox pc_display_none" style={{flex:"1", justifyContent:"center", display: "flex", justifyContent: "center"}}>
                                                                <div className="order_type_box_txt" style={{textAlign:"center", fontSize:"25px" , margin: "auto auto auto auto", height:"30px", fontSize:"12px" }}>
                                                                    단체(법인)명&nbsp;&nbsp;
                                                                    <b>{this.state.tax_request_map["corp_name"]}</b>
                                                                </div>
                                                            </div>
                                                            <div className="order_type_box_txt_flexbox pc_display_none" style={{flex:"1", justifyContent:"center", display: "flex", justifyContent: "center"}}>
                                                                <div className="order_type_box_txt order_type_box_tax_email" style={{textAlign:"center", fontSize:"25px" , margin: "auto auto auto auto", height:"30px", fontSize:"12px" }}>
                                                                    세금계산서 발행 이메일&nbsp;&nbsp;
                                                                    <b>{this.state.tax_request_map["email"]}</b>
                                                                </div>
                                                            </div>
                                                            <div className="order_type_box_txt_flexbox mobile_display_none" style={{flex:"1", justifyContent:"center", display: "flex", justifyContent: "center"}}>
                                                                <div className="order_type_box_txt" style={{textAlign:"center", fontSize:"25px" , margin: "auto auto auto auto", height:"30px", fontSize:"12px" }}>
                                                                    단체(법인)명
                                                                    <br/>
                                                                    <b>{this.state.tax_request_map["corp_name"]}</b>
                                                                </div>
                                                            </div>
                                                            <div className="order_type_box_txt_flexbox mobile_display_none" style={{flex:"1", justifyContent:"center", display: "flex", justifyContent: "center"}}>
                                                                <div className="order_type_box_txt" style={{textAlign:"center", fontSize:"25px" , margin: "auto auto auto auto", height:"30px", fontSize:"12px" }}>
                                                                    세금계산서 발행 이메일
                                                                    <br/>
                                                                    <b>{this.state.tax_request_map["email"]}</b>
                                                                </div>
                                                            </div>
                                                            <div className="order_type_box_position_abs" style={{flex:"1", justifyContent:"center", display: "flex", justifyContent: "center"}}>
                                                                <div style={{display:"flex", textAlign:"center", margin: "auto auto auto auto", fontSize: "12px", lineHeight:"16px"}}>
                                                                    <button className="btn-auto-b" 
                                                                    style={{
                                                                        width: "92px",
                                                                        height: "36px",
                                                                        background: "#10CFC9",
                                                                        borderRadius: "5px",
                                                                        fontSize: "12px",
                                                                        padding: "0px",
                                                                        display:"flex"
                                                                        }}
                                                                        onClick={e => this.onClickOpenPopup(e, 2)}>
                                                                        <div style={{margin:"auto"}}>정보 변경하기</div>
                                                                    </button>
                                                                    {/* <div 
                                                                    style={{
                                                                        width: "52px",
                                                                        height: "36px",
                                                                        fontSize: "12px",
                                                                        padding: "0px",
                                                                        display:"flex",
                                                                        color:"#BEBEBF"
                                                                        }}
                                                                        onClick={e => this.onClickOpenPopup(e, 1)}>
                                                                        <div style={{margin:"auto"}}>삭제</div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    :   <div className="order_type_box_position_rel order_type_box"
                                                        // className="credit_card_box" 
                                                        style={{width: "100%",
                                                        height: "30vw",
                                                        border: "1px solid #10CFC9",
                                                        backgroundColor: "#10CFC9",
                                                        borderRadius: "13px",
                                                        marginTop: "15px",
                                                        marginLeft: "10px",
                                                        color: "white",
                                                        display:"flex",
                                                        flexDirection:"column",
                                                        justifyContent:"space-between"
                                                        }}>
                                                            <div className="order_type_box_txt_flexbox" style={{flex:"1", justifyContent:"center", display: "flex", justifyContent: "center"}}>
                                                                <div className="order_type_box_txt active" style={{textAlign:"center", fontSize:"25px" , margin: "auto auto auto auto", width: "115px", height:"30px", lineHeight:"25px", background:"white", borderRadius:"100px", color:"#333333" }}>
                                                                    <b style={{
                                                                        fontSize:"16px",
                                                                        lineHeight: "12px"
                                                                    }}>{"세금계산서 정보"}</b>
                                                                </div>
                                                            </div>
                                                            <div className="order_type_box_txt_flexbox pc_display_none" style={{flex:"1", justifyContent:"center", display: "flex", justifyContent: "center"}}>
                                                                <div className="order_type_box_txt active" style={{textAlign:"center", fontSize:"25px", margin: "auto auto auto auto", height:"30px", fontSize:"12px", lineHeight:"1.8" }}>
                                                                    단체(법인)명&nbsp;&nbsp;
                                                                    <b>{this.state.tax_request_map["corp_name"]}</b>
                                                                </div>
                                                            </div>
                                                            <div className="order_type_box_txt_flexbox pc_display_none" style={{flex:"1", justifyContent:"center", display: "flex", justifyContent: "center"}}>
                                                                <div className="order_type_box_txt order_type_box_tax_email active" style={{textAlign:"center", fontSize:"25px", margin: "auto auto auto auto", height:"30px", fontSize:"12px", lineHeight:"1.8" }}>
                                                                    세금계산서 발행 이메일&nbsp;&nbsp;
                                                                    <b>{this.state.tax_request_map["email"]}</b>
                                                                </div>
                                                            </div>
                                                            <div className="order_type_box_txt_flexbox mobile_display_none" style={{flex:"1", justifyContent:"center", display: "flex", justifyContent: "center"}}>
                                                                <div className="order_type_box_txt active" style={{textAlign:"center", fontSize:"25px", margin: "auto auto auto auto", height:"30px", fontSize:"12px", lineHeight:"1.8" }}>
                                                                    단체(법인)명
                                                                    <br/>
                                                                    <b>{this.state.tax_request_map["corp_name"]}</b>
                                                                </div>
                                                            </div>
                                                            <div className="order_type_box_txt_flexbox mobile_display_none" style={{flex:"1", justifyContent:"center", display: "flex", justifyContent: "center"}}>
                                                                <div className="order_type_box_txt active" style={{textAlign:"center", fontSize:"25px", margin: "auto auto auto auto", height:"30px", fontSize:"12px", lineHeight:"1.8" }}>
                                                                    세금계산서 발행 이메일
                                                                    <br/>
                                                                    <b>{this.state.tax_request_map["email"]}</b>
                                                                </div>
                                                            </div>
                                                            <div className="order_type_box_position_abs" style={{flex:"1", justifyContent:"center", display: "flex", justifyContent: "center"}}>
                                                                <div style={{display:"flex", textAlign:"center", margin: "auto auto auto auto", fontSize: "12px", lineHeight:"16px"}}>
                                                                <button className="btn-auto-b" 
                                                                    style={{
                                                                        width: "92px",
                                                                        height: "36px",
                                                                        background: "#58DDD9",
                                                                        borderRadius: "5px",
                                                                        fontSize: "12px",
                                                                        padding: "0px",
                                                                        display:"flex"
                                                                        }}
                                                                        onClick={e => this.onClickOpenPopup(e, 2)}>
                                                                        <div style={{margin:"auto"}}>정보 변경하기</div>
                                                                    </button>
                                                                    {/* <div 
                                                                    style={{
                                                                        width: "52px",
                                                                        height: "36px",
                                                                        fontSize: "12px",
                                                                        padding: "0px",
                                                                        display:"flex",
                                                                        color:"#9FECE9"
                                                                        }}
                                                                        onClick={e => this.onClickOpenPopup(e, 1)}>
                                                                        <div style={{margin:"auto"}}>삭제</div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                }
                                            </>
                                        :   <>
                                            <div className="cursor-pointer" style={{width: "100%",
                                                height: "30vw",
                                                border: "1px solid #FAFAFB",
                                                backgroundColor: "#FAFAFB",
                                                borderRadius: "13px",
                                                marginTop: "15px"}}
                                                onClick={e => this.onClickOpenPopup(e, 2)}>
                                                    <div className="icn_plus_v2_g" style={{margin: "10vw auto auto auto"}}></div>
                                                    <div style={{textAlign:"center", margin: "10vw auto auto auto", fontSize: "12px", lineHeight:"16px",}}>
                                                        <b>세금계산서 발행에 필요한<br/>정보를 기입해 주세요.</b>
                                                    </div>
                                                </div> 
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="credit_card_box_area" style={{marginTop:"20px", padding: "50px", padding: "37px 50px", border:"1px #EBEBED solid", borderRadius:"10px"}}>
                    <div className="flexBox">
                        <div className="icn_round_blue" style={{width: "10px", height: "10px", margin: "auto 0", fontSize:"18px"}}></div>
                        <div className="icn_credit_card_text" style={{flex: 1, margin: "auto 0 auto 15px", fontSize:"15px", fontWeight:"500"}}>&nbsp;카드 정보를 변경해야 하면 삭제 후 다시 등록해 주세요.</div>
                    </div>
                </div>   
                <button className="btn-auto-b" style={{padding: "18px 30px 17px", marginTop:"30px"}} onClick={this.saveOrderType.bind(this)}>
                    선택한 결제수단으로 변경
                </button>

                <div style={{width:"100%", height:"62vh", display:"none"}}>
                    <div style={{height:"100%", display:"flex", textAlign:"center", justifyContent:"center", alignItems:"center"}} > 
                        <div className="cursor-pointer" onClick={e => this.onClickOpenPopup(e, 1)}>
                            <b className="f-s-18 m-b-lg">카드 등록</b>
                            <div style={{width: "210px",
                                        height: "150px",
                                        border: "1px solid #333333",
                                        borderRadius: "13px",
                                        marginTop: "15px"}}>
                                <div className="icn_plus_v2_d" style={{margin: "30px auto"}}></div>
                                <div style={{textAlign:"center"}}>
                                    정기결제를 위한<br/>카드를 등록해주세요.
                                </div>
                            </div>
                        </div>
                        {
                            !(Object.keys(this.state.tax_request_map).length === 0 && this.state.tax_request_map.constructor === Object)
                            ?   <div className="cursor-pointer" style={{marginLeft: "20%"}} onClick={e => this.onClickOpenPopup(e, 2)}>
                                    <b className="f-s-18 m-b-lg">세금계산서 신청</b>
                                    <div style={{width: "210px",
                                                height: "150px",
                                                border: "1px solid #333333",
                                                borderRadius: "13px",
                                                marginTop: "15px"}}>
                                    <div  style={{textAlign:"left", padding:"20px"}}>
                                        <div>
                                            <font style={{color:"#cbcbcb", fontSize:"13px"}}>상호(법인명)</font>
                                            <br/>
                                            <div style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{this.state.tax_request_map["corp_name"]}</div>
                                        </div>    
                                        <div style={{marginTop:"12px"}}>
                                            <font style={{color:"#cbcbcb", fontSize:"13px"}}>이메일</font>
                                            <br/>
                                            <div style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{this.state.tax_request_map["email"]}</div>
                                        </div>    
                                    </div>
                                    </div>
                                </div>
                            :   <div className="cursor-pointer" style={{marginLeft: "20%"}} onClick={e => this.onClickOpenPopup(e, 2)}>
                                    <b className="f-s-18 m-b-lg">세금계산서 신청</b>
                                    <div style={{width: "210px",
                                                height: "150px",
                                                border: "1px solid #333333",
                                                borderRadius: "13px",
                                                marginTop: "15px"}}>
                                    <div className="icn_plus_v2_d" style={{margin: "30px auto"}}></div>
                                    <div  style={{textAlign:"center"}}>
                                        세금계산서 발행을 원하시면<br/>신청서를 작성해주세요.
                                    </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>

                {/* 카드등록 팝업 */}
                {
                    this.state.popupType == "1"
                    ?   <div>
                            <div id="modal" className="registCard" style={{maxHeight:"100%", position:"fixed", height:"100vh", top:"0"}}>
                                <div className="modal_wrap">
                                    <div className="modalClose_v2" onClick={this.closePopup}></div>
                                    <div id="modalContent" className="shadow-box" style={{padding:"50px",maxHeight:"80%", overflowY:"auto"}}>
                                        <div className="f-s-18 m-b-50">
                                            {
                                                !(Object.keys(this.state.card_map).length === 0 && this.state.card_map.constructor === Object)
                                                ?   <b>카드 조회</b>
                                                :   <>
                                                        <b>카드 등록</b>
                                                        <br/><br/><span style={{fontSize: '15px'}}>PG사 정책에 의하여 해외결제 카드는 정기결제 등록되지 않는 점 양해 부탁드립니다. 국내결제 카드로 등록해 주세요.</span>
                                                    </>
                                            }
                                        </div>
                                        {
                                            !(Object.keys(this.state.card_map).length === 0 && this.state.card_map.constructor === Object)
                                            ?   <div className="formArea">
                                                    {/* <div className="flexBox m-t-15">
                                                        <div className="tax_request_input_div" style={{flex:"1", marginRight:"10px"}}>
                                                            <font style={{color:"#6d6d6d"}}>카드 소유자명</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "user_name")} value={this.state.card_map["user_name"]}></input>
                                                        </div>
                                                        <div className="tax_request_input_div" style={{flex:"1"}}>
                                                            <font style={{color:"#6d6d6d"}}>카드 소유자 생년월일(6자) 또는 사업자번호</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "corp_reg_no")} value={this.state.card_map["corp_reg_no"]}></input>
                                                        </div>
                                                    </div> */}
                                                    <div className="flexBox m-t-15">
                                                        <div className="tax_request_input_div" style={{flex:"1", marginRight:"10px"}}>
                                                            <font style={{color:"#6d6d6d"}}>카드번호 맨 뒷 4자리</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "card_no")} value={this.state.card_map["card_no"]} disabled></input>
                                                        </div>
                                                        {/* <div className="tax_request_input_div" style={{flex:"0.5"}}>
                                                        <font style={{color:"#6d6d6d"}}>카드 유효기간 (MM)</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "card_mm")} value={this.state.card_map["card_mm"]}></input>
                                                        </div>
                                                        <div className="tax_request_input_div" style={{flex:"0.5"}}>
                                                        <font style={{color:"#6d6d6d"}}>카드 유효기간 (YY)</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "card_yy")} value={this.state.card_map["card_yy"]}></input>
                                                        </div> */}
                                                    </div>
                                                    {/* <div className="flexBox m-t-15">
                                                        <div className="tax_request_input_div" style={{flex:"1", marginRight:"10px"}}>
                                                            <font style={{color:"#6d6d6d"}}>카드 비밀번호 (앞 2자리)</font>
                                                            <input type="password" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "card_pass")} value={this.state.card_map["card_pass"]}></input>
                                                        </div>
                                                        <div className="tax_request_input_div" style={{flex:"1"}}>
                                                            <font style={{color:"#6d6d6d"}}>이메일</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "email")} value={this.state.card_map["email"]}></input>
                                                        </div>
                                                    </div> */}
                                                    <div className="flexBox m-t-15">
                                                        {/* <div className="tax_request_input_div" style={{flex:"1", marginRight:"10px"}}>
                                                            <font style={{color:"#6d6d6d"}}>휴대폰번호</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "phone_num")} value={this.state.card_map["phone_num"]}></input>
                                                        </div> */}
                                                        <div className="tax_request_input_div" style={{flex:"1"}}>
                                                            <font style={{color:"#6d6d6d"}}>카드 별명</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "CARDALIAS")} maxlength="20" value={this.state.CARDALIAS}></input>
                                                        </div>
                                                    </div>
                                                </div>
                                            :   <div className="formArea">
                                                    <div className="flexBox m-t-15">
                                                        {/* <div className="tax_request_input_div" style={{flex:"1", marginRight:"10px"}}>
                                                            <font style={{color:"#6d6d6d"}}>카드 소유자명</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "user_name")}></input>
                                                        </div> */}
                                                        <input type="password" style={{width: "0px", padding:"0", border:"0", height: "0px",}} autoComplete="new-password"></input>
                                                        <div className="tax_request_input_div" style={{flex:"1", marginRight:"10px"}}>
                                                            <font style={{color:"#6d6d6d"}}>카드번호</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "CARDNO")} maxlength="19"></input>
                                                        </div>
                                                        <div className="tax_request_input_div" style={{flex:"1"}}>
                                                            <font style={{color:"#6d6d6d"}}>카드 유효기간 (YYYYMM)</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "EXPIREDT")} maxlength="6"></input>
                                                        </div>
                                                    </div>
                                                    <div className="flexBox m-t-15">
                                                        <div className="tax_request_input_div" style={{flex:"1", marginRight:"10px"}}>
                                                            <font style={{color:"#6d6d6d"}}>카드명의자 주민/사업자번호 주민 : YYMMDD 형식, 사업자번호 : 10자리</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "CARDAUTH")} maxlength="10" autoComplete="off"></input>
                                                        </div>
                                                        <div className="tax_request_input_div" style={{flex:"1"}}>
                                                            <font style={{color:"#6d6d6d"}}>카드 비밀번호 앞 2자리</font>
                                                            <input type="password" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "CARDPASSWORD")} maxlength="2" autoComplete="off"></input>
                                                        </div>
                                                        {/* <div className="tax_request_input_div" style={{flex:"0.5"}}>
                                                        <font style={{color:"#6d6d6d"}}>카드 유효기간 (YY)</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "card_yy")}></input>
                                                        </div> */}
                                                    </div>
                                                    <div className="flexBox m-t-15">
                                                        {/* <div className="tax_request_input_div" style={{flex:"1"}}>
                                                            <font style={{color:"#6d6d6d"}}>이메일</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "email")}></input>
                                                        </div> */}
                                                    </div>
                                                    <div className="flexBox m-t-15">
                                                        {/* <div className="tax_request_input_div" style={{flex:"1", marginRight:"10px"}}>
                                                            <font style={{color:"#6d6d6d"}}>휴대폰번호</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "phone_no")}></input>
                                                        </div> */}
                                                        <div className="tax_request_input_div" style={{flex:"1"}}>
                                                            <font style={{color:"#6d6d6d"}}>카드 별명</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "CARDALIAS")} maxlength="20"></input>
                                                        </div>
                                                    </div>
                                                    {/* <div className="flexBox m-t-32">
                                                        <button id="btnSaveTaxRecipt" className="btn-auto-b" style={{flex:"1", height:"40px", borderRadius:"8px"}} onClick={this.saveTaxRequestFileUpload}>등록하기</button> 
                                                    </div> */}
                                                </div>
                                        }
                                    </div>
                                    {
                                        !(Object.keys(this.state.card_map).length === 0 && this.state.card_map.constructor === Object)
                                        ?   <div className="flexBox m-t-32 mobile_modal_btn_v2_save_tax btn_save" style={{width:"132px", right: "calc(13.5%)",position: "fixed", bottom: "calc(35vh + 0.5em)", zIndex:9999}}>
                                                <button id="btnSaveTaxRecipt" className="btn-auto-b" style={{flex:"1", height:"57px", borderRadius:"8px"}} onClick={e => this.updateCardAlias(e)}>별명 수정</button> 
                                            </div>
                                        :   <div className="flexBox mobile_modal_btn_v2_save_tax btn_save" style={{width:"132px", right: "calc(13.5%)",position: "fixed", bottom: "10em", zIndex:9999}}>
                                                <button id="btnSaveTaxRecipt" className="btn-auto-b" style={{zIndex: 999999999, width:"100%", height:"55px", borderRadius:"8px"}} onClick={this.saveCardKeygenProc}>등록하기</button> 
                                            </div>
                                    }
                                </div>
                            </div>
                            {/* 팝업창 배경 */}
                            <div id="modalBack" style={{top: "0", left:"0", height:"130%", zIndex:"999"}}></div>
                        </div>
                    : ""
                }

                {/* 세금계산서 팝업 */}
                {
                    this.state.popupType == "2"
                    ?   <div>
                            <div id="modal" style={{maxHeight:"100%", position:"relative"}}>
                                <div id="modalContent" className="shadow-box" style={{padding:"50px",maxHeight:"80%", overflowY:"auto"}}>
                                    <div className="f-s-18 m-b-50">
                                        <b>세금계산서 신청</b>
                                    </div>
                                    {
                                        !(Object.keys(this.state.tax_request_map).length === 0 && this.state.tax_request_map.constructor === Object)
                                        ?   <div className="formArea">
                                                <div className="flexBox m-t-15">
                                                    <div className="tax_request_input_div" style={{flex:"1", marginRight:"10px"}}>
                                                        <font style={{color:"#6d6d6d"}}>사업자등록번호</font>
                                                        <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "corp_reg_no")} value={this.state.corp_reg_no}></input>
                                                    </div>
                                                    <div className="tax_request_input_div" style={{flex:"1"}}>
                                                        <font style={{color:"#6d6d6d"}}>상호(법인명)</font>
                                                        <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "corp_name")} value={this.state.corp_name}></input>
                                                    </div>
                                                </div>
                                                <div className="flexBox m-t-15">
                                                    <div className="tax_request_input_div" style={{flex:"1", marginRight:"10px"}}>
                                                        <font style={{color:"#6d6d6d"}}>업태</font>
                                                        <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "business_type")} value={this.state.business_type}></input>
                                                    </div>
                                                    <div className="tax_request_input_div" style={{flex:"1"}}>
                                                    <font style={{color:"#6d6d6d"}}>업종</font>
                                                        <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "business_item")} value={this.state.business_item}></input>
                                                    </div>
                                                </div>
                                                <div className="flexBox m-t-15">
                                                    <div className="tax_request_input_div" style={{flex:"1", marginRight:"10px"}}>
                                                        <font style={{color:"#6d6d6d"}}>대표자명</font>
                                                        <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "representative_name")} value={this.state.representative_name}></input>
                                                    </div>
                                                    <div className="tax_request_input_div" style={{flex:"1"}}>
                                                        <font style={{color:"#6d6d6d"}}>세금계산서 발행 이메일</font>
                                                        <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "email")} value={this.state.email}></input>
                                                    </div>
                                                </div>
                                                <div className="flexBox m-t-15">
                                                    <div style={{flex:"1"}}>
                                                        <font style={{color:"#6d6d6d"}}>사업자등록증</font>
                                                        <div style={{flex:"1"}}>
                                                            {/* <div className="input_text_title_d">배너 제목</div> */}
                                                            <form enctype="multipart/form-data">
                                                                <div style={{display:"flex", height: "35px"}}>
                                                                    <input type="file" id="Files1" name="promotionFiles" onChange={e => this._handleFileChange(e)} accept="image/jpg,image/png,image/jpeg,image/gif,image/bmp,image/tiff,application/pdf" value="" style={{display:'none'}}/> 
                                                                    <div id="attachFileName_v3" className='fileName' style={{float:"left", flex:"1", borderRadius:"8px", textOverflow:"ellipsis"}}>{this.state.file_name}</div>
                                                                    <button className="btn-auto-d" id="btnFileUploadForTax" onClick={this.handleClickBtnFileUpload1} style={{width: "auto", padding:"0 30px" , height:"100%", marginLeft:"8px", borderRadius:"8px"}}>파일첨부</button>
                                                                    <button type="submit" id="btnSave1" className="greenButton" style={{float:"right", display:"none"}}>저장</button> 
                                                                </div>
                                                            </form>
                                                            {/* <img className="photo-uploaded" src={this.state.imageArray1 != null ? this.state.imageArray1[0] : ""} alt="Photo uploaded" style={{width:"90%", height:"98%", objectFit:"cover"}} /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flexBox m-t-15">
                                                    <div style={{flex:"1"}}>
                                                        <font style={{color:"#6d6d6d"}}>비고</font>
                                                        <input type="text" style={{width:"100%", borderRadius:"8px"}}  placeholder="추가 요청사항 (예: 매달 5일에 발급해 주세요.)" onChange={e => this.onChangeInputText(e, "tax_val")} value={this.state.tax_val}></input>
                                                        <font style={{fontSize:"11px"}}>* 전자세금계산서는 거래일이 속하는 달의 다음달 10일까지 발행입니다.</font>
                                                    </div>
                                                </div>
                                                {/* <div className="flexBox m-t-32">
                                                    <button id="btnSaveTaxRecipt" className="btn-auto-b" style={{flex:"1", height:"40px", borderRadius:"8px"}} onClick={this.saveTaxRequestFileUpload}>수정하기</button> 
                                                </div> */}
                                            </div>
                                        :   <div className="formArea">
                                                <div className="flexBox m-t-15">
                                                    <div className="tax_request_input_div" style={{flex:"1", marginRight:"10px"}}>
                                                        <font style={{color:"#6d6d6d"}}>사업자등록번호</font>
                                                        <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "corp_reg_no")}></input>
                                                    </div>
                                                    <div className="tax_request_input_div" style={{flex:"1"}}>
                                                        <font style={{color:"#6d6d6d"}}>상호(법인명)</font>
                                                        <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "corp_name")}></input>
                                                    </div>
                                                </div>
                                                <div className="flexBox m-t-15">
                                                    <div className="tax_request_input_div" style={{flex:"1", marginRight:"10px"}}>
                                                        <font style={{color:"#6d6d6d"}}>업태</font>
                                                        <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "business_type")}></input>
                                                    </div>
                                                    <div className="tax_request_input_div" style={{flex:"1"}}>
                                                    <font style={{color:"#6d6d6d"}}>업종</font>
                                                        <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "business_item")}></input>
                                                    </div>
                                                </div>
                                                <div className="flexBox m-t-15">
                                                    <div className="tax_request_input_div" style={{flex:"1", marginRight:"10px"}}>
                                                        <font style={{color:"#6d6d6d"}}>대표자명</font>
                                                        <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "representative_name")}></input>
                                                    </div>
                                                    <div className="tax_request_input_div" style={{flex:"1"}}>
                                                        <font style={{color:"#6d6d6d"}}>세금계산서 발행 이메일</font>
                                                        <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "email")}></input>
                                                    </div>
                                                </div>
                                                <div className="flexBox m-t-15">
                                                    <div style={{flex:"1"}}>
                                                        <font style={{color:"#6d6d6d"}}>사업자등록증</font>
                                                        <div style={{flex:"1"}}>
                                                            {/* <div className="input_text_title_d">배너 제목</div> */}
                                                            <form enctype="multipart/form-data">
                                                                <div style={{display:"flex", height: "35px"}}>
                                                                    <input type="file" id="Files1" name="promotionFiles" onChange={e => this._handleFileChange(e)} accept="image/jpg,image/png,image/jpeg,image/gif,image/bmp,image/tiff,application/pdf" value="" style={{display:'none'}}/> 
                                                                    <div id="attachFileName_v3" className='fileName' style={{float:"left", flex:"1", borderRadius:"8px", textOverflow:"ellipsis"}}>{this.state.file_name}</div>
                                                                    <button className="btn-auto-d" id="btnFileUploadForTax" onClick={this.handleClickBtnFileUpload1} style={{width: "auto", padding:"0 30px" , height:"100%", marginLeft:"8px", borderRadius:"8px"}}>파일첨부</button>
                                                                    <button type="submit" id="btnSave1" className="greenButton" style={{float:"right", display:"none"}}>저장</button> 
                                                                </div>
                                                            </form>
                                                            {/* <img className="photo-uploaded" src={this.state.imageArray1 != null ? this.state.imageArray1[0] : ""} alt="Photo uploaded" style={{width:"90%", height:"98%", objectFit:"cover"}} /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flexBox m-t-15">
                                                    <div style={{flex:"1"}}>
                                                        <font style={{color:"#6d6d6d"}}>비고</font>
                                                        <input type="text" style={{width:"100%", borderRadius:"8px"}}  placeholder="추가 요청사항 (예: 매달 5일에 발급해 주세요.)" onChange={e => this.onChangeInputText(e, "val")}></input>
                                                    </div>
                                                </div>
                                                {/* <div className="flexBox m-t-32">
                                                    <button id="btnSaveTaxRecipt" className="btn-auto-b" style={{flex:"1", height:"40px", borderRadius:"8px"}} onClick={this.saveTaxRequestFileUpload}>신청하기</button> 
                                                </div> */}
                                            </div>
                                    }
                                </div>
                                {
                                    !(Object.keys(this.state.tax_request_map).length === 0 && this.state.tax_request_map.constructor === Object)
                                    ?   <div className="flexBox btnSaveTaxRecipt" style={{width:"132px", right: "calc(14%)",position: "fixed", top: "calc(483px + 31%)", zIndex:9999}}>
                                            <button id="btnSaveTaxRecipt" className="btn-auto-b" style={{zIndex: 999999999, width:"100%", height:"55px", borderRadius:"8px"}} onClick={this.saveTaxRequestFileUpload}>수정하기</button> 
                                        </div>
                                    :   <div className="flexBox btnSaveTaxRecipt" style={{width:"132px", right: "calc(14%)",position: "fixed", top: "calc(483px + 31%)", zIndex:9999}}>
                                            <button id="btnSaveTaxRecipt" className="btn-auto-b" style={{zIndex: 999999999, width:"100%", height:"55px", borderRadius:"8px"}} onClick={this.saveTaxRequestFileUpload}>신청하기</button> 
                                        </div>    
                                }
                                <div className="modalClose_v2" onClick={this.closePopup}></div>
                            </div>
                            {/* 팝업창 배경 */}
                            <div id="modalBack" style={{top: "0", left:"0", height:"130%", zIndex:"999"}}></div>
                        </div>
                    : ""
                }
                

                {/* 팝업창 배경
                <div id="modalBack" style={{top: "0", left:"0", height:"130%", zIndex:"999"}}></div> */}




                {/* 실시간 계좌이체 FORM */}
                <form name="payForm" accept-charset="euc-kr" style={{display:"none"}}>
                <table style={{width:"100%",border:"1px",cellspacing:"0", cellpadding:"0"}}>
                    <colgroup>
                        <col width="160px" />
                        <col width="*" />
                    </colgroup>
                    <tr>
                        <td></td>
                        <td>
                            {/* <input type="button" value="결제하기" onClick={this.payLink} style={{width:"63", height:"25"}}/> */}
                        </td>
                    </tr>
                </table>
                <br/>
                <br/>

                <table style={{width:"100%",border:"1px",cellspacing:0,cellpadding:0}}>
                    <colgroup>
                        <col width="160px" />
                        <col width="180px" />
                        <col width="*" />
                    </colgroup>
                    <tr> 
                        <td colspan="3"><b>----------공통항목(필수)</b></td>
                    </tr>
                    <tr> 
                        <td><b>파라미터</b></td>
                        <td><b>값</b></td>
                        <td><b>설명</b></td>
                    </tr>
                    <tr> 
                        <td>USER_SEQ</td>
                        <td><input type="text" name="RESERVEDINDEX1" value={this.state.payLinkPreData.USER_SEQ}/></td>
                        <td>사용자 일련번호</td>
                    </tr>
                    <tr> 
                        <td>SHOP_NO</td>
                        <td><input type="text" name="RESERVEDINDEX2" value={this.state.payLinkPreData.SHOP_NO}/></td>
                        <td>쇼핑몰 번호</td>
                    </tr>
                    <tr> 
                        <td>STD_YM</td>
                        <td><input type="text" name="STD_YM" value={this.state.payLinkPreData.STD_YM}/></td>
                        <td>기준 년월</td>
                    </tr>
                    <tr> 
                        <td colspan="3"><b>----------고정항목----------</b></td>
                    </tr>
                    <tr> 
                        <td>TYPE</td>
                        <td><input type="text" name="TYPE" value="W"/></td>
                        <td>결제방식</td>
                    </tr>
                    <tr> 
                        <td>PAYMETHOD</td>
                        <td><input type="text" name="PAYMETHOD" value="BANK"/></td>
                        <td>결제수단</td>
                    </tr>
                    <tr> 
                        <td>CPID</td>
                        <td><input type="text" name="CPID" value="CTS16826"/></td>
                        <td>상점아이디</td>
                    </tr>
                    <tr> 
                        <td>PRODUCTTYPE</td>
                        <td><input type="text" name="PRODUCTTYPE" value="1"/></td>
                        <td>상품구분(1: 디지털, 2: 실물)</td>
                    </tr>
                    <tr> 
                        <td colspan="3"><b>----------변동항목(DB에서 조회)----------</b></td>
                    </tr>
                    <tr> 
                        <td>ORDERNO</td>
                        <td><input type="text" name="ORDERNO" value={this.state.payLinkPreData.ORDERNO}/></td>
                        <td>주문번호</td>
                    </tr>
                    <tr> 
                        <td>AMOUNT</td>
                        <td><input type="text" name="AMOUNT" value={this.state.payLinkPreData.AMOUNT}/></td>
                        <td>결제금액</td>
                    </tr>
                    <tr> 
                        <td>PRODUCTNAME</td>
                        <td><input type="text" name="PRODUCTNAME" value={this.state.payLinkPreData.PRODUCT_NAME}/></td>
                        <td>상품명</td>
                    </tr>
                    <tr> 
                        <td>PRODUCTCODE</td>
                        <td><input type="text" name="PRODUCTCODE" value={this.state.payLinkPreData.PRODUCT_NO}/></td>
                        <td>상품코드</td>
                    </tr>
                    <tr> 
                        <td>USERID</td>
                        <td><input type="text" name="USERID" value={this.state.payLinkPreData.USERID}/></td>
                        <td>고객 ID</td>
                    </tr>
                    <tr> 
                        <td>EMAIL</td>
                        <td><input type="text" name="EMAIL" value={this.state.payLinkPreData.EMAIL}/></td>
                        <td>고객 이메일</td>
                    </tr>
                    <tr> 
                        <td>USERNAME</td>
                        <td><input type="text" name="USERNAME" value={this.state.payLinkPreData.USERNAME}/></td>
                        <td>고객명</td>
                    </tr>
                    <tr> 
                        <td colspan="3"><b>----------변동항목(선택)----------</b></td>
                    </tr>
                    <tr> 
                        <td>RESERVEDSTRING</td>
                        <td><input type="text" name="RESERVEDSTRING" value=""/></td>
                        <td>예약스트링</td>
                    </tr>
                    <tr> 
                        <td>RETURNURL</td>
                        <td><input type="text" name="RETURNURL" value=""/></td>
                        <td>결제 성공 후, 이동할 URL(새 창)</td>
                    </tr>
                    <tr> 
                        <td>DIRECTRESULTFLAG</td>
                        <td><input type="text" name="DIRECTRESULTFLAG" value=""/></td>
                        <td>페이조아 결제 완료 창 없이 HOMEURL로 바로 이동(Y/N)</td>
                    </tr>
                    <tr> 
                        <td>SET_LOGO</td>
                        <td><input type="text" name="SET_LOGO" value=""/></td>
                        <td>결제 창 하단 상점로고 URL</td>
                    </tr>	
                    <tr> 
                        <td colspan="3"><b>----------웹뷰결제창(필수)</b></td>
                    </tr> 
                    <tr> 
                        <td>HOMEURL</td>
                        <td><input type="text" name="HOMEURL" value="https://www.naver.com"/></td>
                        <td>결제 성공 후, 이동할 URL(결제 창에서 이동)</td>
                    </tr>
                    <tr>
                        <td>CLOSEURL</td>
                        <td><input type="text" name="CLOSEURL" value="https://www.daum.net"/></td>
                        <td>결제 창에서 취소 누를 시 이동할 URL</td>
                    </tr>
                    <tr> 
                        <td>FAILURL</td>
                        <td><input type="text" name="FAILURL" value="https://www.nate.com"/></td>
                        <td>결제실패 후, 확인버튼 입력 시 이동할 URL</td>
                    </tr>
                    <tr>
                        <td>APPURL</td>
                        <td><input type="text" name="APPURL" value=""/></td>
                        <td>인증완료 후 돌아갈 앱의 URL(CARD, CARDK, BANK 필수)</td>
                    </tr>
                    <tr> 
                        <td colspan="3"><b>----------계좌이체(선택)</b></td>
                    </tr>
                    <tr> 
                        <td>USERSSN</td>
                        <td><input type="text" name="USERSSN" value=""/></td>
                        <td>생년월일(YYMMDD)</td>
                    </tr>
                    <tr> 
                        <td>CHECKSSNYN</td>
                        <td><input type="text" name="CHECKSSNYN" value=""/></td>
                        <td>USERSSN사용여부(Y/N)(계좌이체 결제자와 명의자(USERSSN)가 동일한 경우만 결제가능)</td>
                    </tr>
                </table>
                </form>
            </div>
        )
    }
}   
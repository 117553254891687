import React, { useCallback, useState } from 'react';
import * as StringUtil from '../modules/utils/StringUtil';
import * as StyleUtil from '../modules/utils/StyleUtil';
import * as CurationServiceVariable from '../modules/CurationServiceVariable.js';
import SelectBoxV2 from '../modules/common/SelectBoxV2';
import BasicDialogV2 from '../modules/dialogs/BasicDialogV2';
import ConfirmDialogV2 from '../modules/dialogs/ConfirmDialogV2';
import icn_refresh from '../../resources/img/icn_refresh.svg';

import $ from 'jquery';
import http from '../modules/api';
import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider';

let window_scrollTop = 0;
let this_location; // 현재 페이지 주소값

var curation_result_list_page_url = "/shop/curation/result/list"
export class CurationResultGen extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
            result_seq: StringUtil.getUrlParams("result_seq") != undefined ? StringUtil.getUrlParams("result_seq") : null,

            curation_result_image_seq:"",
            curation_result_img_name:"",
            curation_result_img:"",
            
            curation_result_title:"",
            curation_result_desc:"",

            rp_list: [],

            /* 진열매칭 셀렉트박스 선택 값 */
            rp_select_text: "",
            rp_select_value: "",

            /* BasicDialog 변수 */
            basic_dialog_show_state: false,
            basic_dialog_content: "",
            basic_dialog_button_list: [{title:"확인", event_method:this.closeBasicDialog.bind(this)}],

            /* 변경 여부 */
            is_modify : false,
        }
    }

    componentDidMount() {
        /* 뒤로가기 또는 앞으로가기 이벤트 : state 전달 */
        window.onpopstate = (event) => { // 뒤로가기 또는 앞으로가기 이벤트
            if (window.location.href.indexOf(curation_result_list_page_url) >= 0) { // 이동 할 주소가 '/shop/curation/result/list' 인 경우
                this.replaceResultListPage(); // '/shop/curation/result/list' 페이지로 replace
            }
        };
        
        this_location = window.location.href;

        $(document).ready(function(){
            StyleUtil.textareaHeightAuto("quration_gen_info_detail", 3);
        });
        
        this.loadCurationResult(); // 큐레이션 결과 상세 조회
        this.loadRpList(); // 진열 목록 조회
    }

    // 큐레이션 결과 상세 조회
    async loadCurationResult() {
        let curation_result_seq = StringUtil.getUrlParams("result_seq");
        if (curation_result_seq == null) {
            return false;
        }

        await http.get("/rest/shop/curation_result/"+curation_result_seq)
        .then(res => {
            if (res.data.code == 200) {
                this.setState({
                    curation_result_title:  res.data.curation_result["curation_result_title"],
                    curation_result_desc:  res.data.curation_result["curation_result_desc"],
                    curation_result_img_name:  res.data.curation_result["file_org_name"],
                    curation_result_img:  res.data.file_domain + res.data.curation_result["file_path"],
                    rp_select_text:res.data.curation_result["rp_title"],
                    rp_select_value:res.data.curation_result["rp_iframe_html_id"],
                    curation_result_image_seq:res.data.curation_result["file_seq"],
                }, () => {
                    StyleUtil.textareaHeightAuto("quration_gen_info_detail", 3);
                })
            } else {
                alert(CurationServiceVariable.RESULT+" 조회에 실패하였습니다.")
                // 큐레이션 목록으로 이동
                this.goResultListPage();
            }
        })
        .catch(error => {
            // 큐레이션 목록으로 이동
            this.goResultListPage();
        });
    }

    /**
     * 진열 목록 조회
     */
    async loadRpList(is_reload) {
        await http.get("/rest/shop/curation/list/rp_event/setting")
        .then(res => {
            this.setState({
                rp_list: res.data.list
            }, () => {
                if (is_reload) {
                    window_scrollTop = StyleUtil.onPopupNoneScroll();
                    this.setState({
                        basic_dialog_show_state: true,
                        basic_dialog_content: "진열 목록을 재조회 했습니다.",
                        basic_dialog_button_list: [{title:"확인", event_method: () => this.closeBasicDialog()}],
                    });
                }
            })
        })
        .catch(error => {
            alert("진열 목록 조회에 실패하였습니다.");
        });
    }

    // 큐레이션 결과 이지미 업로드 
    uploadOrgImg(e){
        const files = Array.from(e.target.files);
        const formData = new FormData();
        // formData.append("files", files[0]);
        formData.append("file", files[0]);
        //console.log(formData);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        const url = '/rest/common/uploadImage/CURATION-RESULT';
        http
        .post(url, formData, config)
        .then(res => {
            console.log(files[0]);

            if (!StringUtil.CheckImagefiles(files[0].name)) {
                var quration_result_gen = $("#quration_result_gen");
                quration_result_gen.next(".quration_popup").css("display","flex");
                quration_result_gen.next(".quration_popup").find(".quration_gen_alert_img").css("display","flex");
            } else {
                this.setState({
                    curation_result_image_seq : res.data.file_seq, 
                    curation_result_img_name : files[0].name,
                    curation_result_img :URL.createObjectURL(files[0]),
                    is_modify : true,
                })
            }
        })
        .catch(error => {
        });
    }

    // 이미지 업로드 팝업 : 파일첨부 열기
    handleClickBtnFileUpload(e){ 
        e.preventDefault ();
        $("#quration_gen_file_input").click();	
    };

    // 이미지 업로드 팝업 : 업로드 이벤트
    _handleImageChange = (e) => {
        this.uploadOrgImg(e.target.files);
    };

    // 이미지 삭제
    removeImage (e) {
        this.setState({
            curation_result_image_seq : "",
            curation_result_img_name : "",
            curation_result_img : "",
            is_modify : true,
        })
    }

    // 진열 셀렉트박스 옵션 클릭 (onClickOpthin)
    onClickRpOption (text, value) {
        this.setState({
            rp_select_text: text,
            rp_select_value: value,
            is_modify : true,
        }, () => {
            // alert(text+"/"+value);
        })
    }

    // 제목 수정
    onChangeTitle(e) {
        this.setState({
            curation_result_title : e.target.value,
            is_modify : true,
        });
    }

    // 설명 수정
    onChangeDescription(e) {
        this.setState({
            curation_result_desc : e.target.value,
            is_modify : true,
        }, () => StyleUtil.textareaHeightAuto("quration_gen_info_detail", 3));
    }

    // 큐레이션 결과 저장
    onClickCurationResultSave(e) {
        /* 큐레이션 사용자 질문 validate 체크 */
        if(StringUtil.checkEmptyString(this.state.curation_result_title)){
            window_scrollTop = StyleUtil.onPopupNoneScroll();
            this.setState({
                basic_dialog_show_state : true, // 다이얼로그 띄우기
                basic_dialog_content : CurationServiceVariable.TITLE+"을 입력해주세요.",
                basic_dialog_button_list: [{title:"확인", event_method: () => this.closeBasicDialog("quration_gen_info_name")}],
            })
            return false;
        }       
        if(StringUtil.checkEmptyString(this.state.curation_result_desc)){
            window_scrollTop = StyleUtil.onPopupNoneScroll();
            this.setState({
                basic_dialog_show_state : true, // 다이얼로그 띄우기
                basic_dialog_content : CurationServiceVariable.DESCRIPTION+"을 입력해주세요.",
                basic_dialog_button_list: [{title:"확인", event_method: () => this.closeBasicDialog("quration_gen_info_detail")}],
            })
            return false;
        }
        if (this.state.curation_result_image_seq == null || this.state.curation_result_image_seq == "") {
            window_scrollTop = StyleUtil.onPopupNoneScroll();
            this.setState({
                basic_dialog_show_state : true, // 다이얼로그 띄우기
                basic_dialog_content : CurationServiceVariable.RESULT+" 이미지를 업로드해주세요.",
                basic_dialog_button_list: [{title:"확인", event_method: () => this.closeBasicDialog("quration_gen_info_img")}],
            })
            return false;
        }
        if (this.state.rp_select_value == null || this.state.rp_select_value == "") {
            window_scrollTop = StyleUtil.onPopupNoneScroll();
            this.setState({
                basic_dialog_show_state : true, // 다이얼로그 띄우기
                basic_dialog_content : "진열을 선택해주세요.",
                basic_dialog_button_list: [{title:"확인", event_method: () => this.closeBasicDialog("rp_select_box")}],
            })
            return false;
        }

        const result_seq = StringUtil.getUrlParams("result_seq");;
        if(result_seq != null && result_seq != ""){
            this.modifyCurationResult();
        } else{
            this.createCurationResult();
        }
    }

    createCurationResult() {
        window_scrollTop = StyleUtil.onPopupNoneScroll();
        this.setState({
            confirm_dialog_show_state: true,
            confirm_dialog_content: CurationServiceVariable.CURATION+" "+CurationServiceVariable.RESULT+"를 생성 하시겠습니까?",
            confirm_dialog_button_list: [
                {
                    title: "네",
                    type: "1",
                    event_method: () => {
        
                        const config = {
                            headers: {
                                'Content-Type': 'application/json',
                                },
                        };
                        const body = {
                            curation_result_title: this.state.curation_result_title,
                            curation_result_desc: this.state.curation_result_desc,
                            file_seq: this.state.curation_result_image_seq,
                            rp_iframe_page_id: "robomd_curation_page",
                            rp_iframe_html_id: this.state.rp_select_value,
                        }
                        const url = '/rest/shop/curation_result/create';
                        http
                        .post(url, body, config)
                        .then((res) => {
                            if (res.data.code == "200") {
                                // 링크 주소의 param값으로 curation_seq를 붙인형태로 변경한다.
                                window.history.replaceState("","",this_location+"?result_seq="+res.data.curation_result_seq);
                                this_location = this_location+"?result_seq="+res.data.curation_result_seq;

                                // 팝업 닫기
                                this.setState({ confirm_dialog_show_state: false });
                                // 스크롤 고정 해제
                                StyleUtil.clearPopupNoneScroll(window_scrollTop);
                                
                                // this.setState({
                                //     basic_dialog_show_state: true,
                                //     basic_dialog_content: "저장하였습니다",
                                //     basic_dialog_button_list: [
                                //         {
                                //             title:"확인", 
                                //             event_method:() => {
                                //                 // 새로 고침
                                //                 window.location.reload();
                                //             }
                                //         }
                                //     ]
                                // })

                                // 새로 고침
                                window.location.reload();
                                
                            } else {
                                //팝업 닫기
                                this.setState({ confirm_dialog_show_state: false });
                                // 스크롤 고정 해제
                                StyleUtil.clearPopupNoneScroll(window_scrollTop);
                            }
                        })
                        .catch((error) => {
                            this.setState({ confirm_dialog_show_state: false });
                            // 스크롤 고정 해제
                            StyleUtil.clearPopupNoneScroll(window_scrollTop);
                        });
                    }
                },
                {
                    title: "아니요",
                    type: "0",
                    event_method: () => {
                        this.setState({ confirm_dialog_show_state: false });
                        // 스크롤 고정 해제
                        StyleUtil.clearPopupNoneScroll(window_scrollTop);
                    }
                }
            ]
        });
    }

    modifyCurationResult() {
        window_scrollTop = StyleUtil.onPopupNoneScroll();

        this.setState({
            confirm_dialog_show_state: true,
            confirm_dialog_content: CurationServiceVariable.CURATION+" "+CurationServiceVariable.RESULT+"를 저장 하시겠습니까?",
            confirm_dialog_button_list: [
                {
                    title: "네",
                    type: "1",
                    event_method: () => {
                        const config = {
                            headers: {
                                'Content-Type': 'application/json',
                                },
                        };
                        const url = '/rest/shop/curation_result/modify';
                        const body = {
                            curation_result_seq: this.state.result_seq,
                            curation_result_title: this.state.curation_result_title,
                            curation_result_desc: this.state.curation_result_desc,
                            file_seq: this.state.curation_result_image_seq,
                            rp_iframe_page_id: "robomd_curation_page",
                            rp_iframe_html_id: this.state.rp_select_value,
                        }
                        http
                        .post(url, body, config)
                        .then((res) => {
                            if (res.data.code == "200") {
                                // 팝업 닫기
                                this.setState({ confirm_dialog_show_state: false });
                                // 스크롤 고정 해제
                                StyleUtil.clearPopupNoneScroll(window_scrollTop);
                                
                                // this.setState({
                                //     basic_dialog_show_state: true,
                                //     basic_dialog_content: "저장하였습니다",
                                //     basic_dialog_button_list: [
                                //         {
                                //             title:"확인", 
                                //             event_method:() => {
                                //                 // 새로 고침
                                //                 window.location.reload();
                                //             }
                                //         }
                                //     ]
                                // })

                                // 새로 고침
                                window.location.reload();
                            } else {
                                //팝업 닫기
                                this.setState({ confirm_dialog_show_state: false });
                                // 스크롤 고정 해제
                                StyleUtil.clearPopupNoneScroll(window_scrollTop);
                            }
                        })
                        .catch((error) => {
                            this.setState({ confirm_dialog_show_state: false });
                            // 스크롤 고정 해제
                            StyleUtil.clearPopupNoneScroll(window_scrollTop);
                        });
                    }
                },
                {
                    title: "아니요",
                    type: "0",
                    event_method: () => {
                        this.setState({ confirm_dialog_show_state: false });
                        // 스크롤 고정 해제
                        StyleUtil.clearPopupNoneScroll(window_scrollTop);
                    }
                }
            ]
        });
    }

    /* BasicDialog 닫기 */
    closeBasicDialog(element_id) {
        this.setState({
            basic_dialog_show_state : false,
            basic_dialog_content : ""
        }, () => {
            // 스크롤 고정 해제
            StyleUtil.clearPopupNoneScroll(window_scrollTop);

            if (element_id != null) {
                $("#"+element_id).focus();
            }
        })
    }

    /* 취소버튼 클릭 */
    onClickCancel() {
        window_scrollTop = StyleUtil.onPopupNoneScroll();
        if (this.state.is_modify == true) {
            const {result_seq} = this.state;
            let confirm_dialog_content = "";
            if(result_seq != null && result_seq != ""){
                confirm_dialog_content = CurationServiceVariable.CURATION + " " + CurationServiceVariable.RESULT + " 수정을 취소 하시겠습니까?";
            } else{
                confirm_dialog_content = CurationServiceVariable.CURATION + " " + CurationServiceVariable.RESULT + " 생성을 취소 하시겠습니까?"
            }
            this.setState({
                confirm_dialog_show_state: true,
                confirm_dialog_content: confirm_dialog_content,
                confirm_dialog_button_list: [
                    {
                        title: "네",
                        type: "1",
                        event_method: () => {
                            this.goResultListPage(); // 목록 페이지로 이동
                        }
                    },
                    {
                        title: "아니요",
                        type: "0",
                        event_method: () => {
                            this.setState({ confirm_dialog_show_state: false });
                            // 스크롤 고정 해제
                            StyleUtil.clearPopupNoneScroll(window_scrollTop);
                        }
                    }
                ]
            });
        } else {
            this.goResultListPage(); // 목록 페이지로 이동
        }
    }

    /* 목록 페이지로 이동 */
    goResultListPage() {
        const {location} = this.props; 
        this.props.history.push(
            {
                pathname: curation_result_list_page_url,
                state: {curationResultListState: (location.state != null && location.state.curationResultListState != null ? location.state.curationResultListState: null)}
            }	
        )
    }
    /* 목록 페이지로 replace */
    replaceResultListPage() {
        const {location} = this.props; 
        this.props.history.replace(
            {
                pathname: curation_result_list_page_url,
                state: {curationResultListState: (location.state != null && location.state.curationResultListState != null ? location.state.curationResultListState: null)}
            }	
        )
    }

    render() {
        return (
            <>
                <div id='common-page-wrapper'>
                <div id="wrap">
                    <section class="quration_result_gen style_guide_v2" id="quration_result_gen">
                        <div class="quration_gen_title">
                            {
                                StringUtil.getUrlParams("result_seq") != null
                                ?   <h2 class="text_20">{CurationServiceVariable.CURATION} {CurationServiceVariable.RESULT} 수정</h2>
                                :   <h2 class="text_20">{CurationServiceVariable.CURATION} {CurationServiceVariable.RESULT} 만들기</h2>
                            }
                        </div>
                        <div class="quration_gen_info_wrap">
                            <h2 class="text_16">{CurationServiceVariable.RESULT} 정보</h2>
                            <form class="quration_gen_info" method="post" action="#">
                                <div class="quration_gen_info_name">
                                    <h3 class="text_16">* {CurationServiceVariable.TITLE}</h3>
                                    <input type="text" id="quration_gen_info_name" name="quration_gen_info_name" maxlength="200" placeholder={CurationServiceVariable.TITLE + "을 입력해주세요."} value={this.state.curation_result_title} onChange={e => this.onChangeTitle(e)} tabIndex="1"/>
                                </div>
                                <div class="quration_gen_info_detail">
                                    <h3 class="text_16">*  {CurationServiceVariable.DESCRIPTION}</h3>
                                    {/* <input type="text" id="quration_gen_info_detail" name="quration_gen_info_detail" placeholder={CurationServiceVariable.DESCRIPTION +"을 입력해주세요."} value={this.state.curation_result_desc} onChange={e => this.onChangeDescription(e)}/> */}
                                    <textarea id="quration_gen_info_detail" name="quration_gen_info_detail" maxlength="2000" placeholder={CurationServiceVariable.DESCRIPTION +"을 입력해주세요."} value={this.state.curation_result_desc} onChange={e => this.onChangeDescription(e)} tabIndex="2"/>
                                </div>
                                <div class="quration_gen_info_thumbnail">
                                    <h3 class="text_16">* {CurationServiceVariable.RESULT} 이미지</h3>
                                    <div class="quration_gen_info_thumbnail_1">
                                        <div class="quration_gen_info_thumbnail_2">
                                            {/* <input type="file" id="quration_gen_file_input" onChange={e => this.uploadOrgImg(e)} accept="image/jpg,image/png,image/jpeg,image/gif,image/bmp,image/tiff" value="" style={{display:"none"}} tabindex="-1"></input> */}
                                            <input type="file" id="quration_gen_file_input" onChange={e => this.uploadOrgImg(e)} accept="image/jpg,image/png,image/jpeg,image/gif,image/bmp,image/tiff" value="" style={{display:"none"}} tabIndex="-1"></input>
                                            <div class="quration_gen_info_thumbnail_3">
                                                {/* <button type="button" for="quration_gen_file_input">이미지 업로드</button> */}
                                                <label class="quration_gen_info_img_upload_btn" for="quration_gen_file_input" tabIndex="3">이미지 업로드</label>
                                                <input type="text" id="quration_gen_info_img" name="quration_gen_info_img" placeholder="파일을 선택해주세요." value={this.state.curation_result_img_name} disabled/>
                                                {
                                                    this.state.curation_result_img != null && this.state.curation_result_img != "" 
                                                    ?   <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={e => this.removeImage(e)}>
                                                            <path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1209 7.84638 17.2635 5.78216 15.7407 4.25932C14.2178 2.73648 12.1536 1.87913 10 1.875ZM12.9453 12.0547C13.0627 12.1732 13.1285 12.3332 13.1285 12.5C13.1285 12.6668 13.0627 12.8268 12.9453 12.9453C12.8259 13.0608 12.6662 13.1254 12.5 13.1254C12.3338 13.1254 12.1742 13.0608 12.0547 12.9453L10 10.8828L7.94532 12.9453C7.82585 13.0608 7.66618 13.1254 7.5 13.1254C7.33383 13.1254 7.17415 13.0608 7.05469 12.9453C6.93733 12.8268 6.87149 12.6668 6.87149 12.5C6.87149 12.3332 6.93733 12.1732 7.05469 12.0547L9.11719 10L7.05469 7.94531C6.95503 7.82388 6.90409 7.66971 6.9118 7.51281C6.91951 7.3559 6.9853 7.20747 7.09639 7.09638C7.20747 6.9853 7.3559 6.9195 7.51281 6.9118C7.66972 6.90409 7.82388 6.95502 7.94532 7.05469L10 9.11719L12.0547 7.05469C12.1761 6.95502 12.3303 6.90409 12.4872 6.9118C12.6441 6.9195 12.7925 6.9853 12.9036 7.09638C13.0147 7.20747 13.0805 7.3559 13.0882 7.51281C13.0959 7.66971 13.045 7.82388 12.9453 7.94531L10.8828 10L12.9453 12.0547Z" fill="#D1D1D1"/>
                                                        </svg>
                                                    :   ""  
                                                }
                                            </div>
                                            <h4>
                                                <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1229 7.84575 17.2662 5.78033 15.743 4.25704C14.2197 2.73376 12.1543 1.87707 10 1.875ZM9.84375 5.625C10.0292 5.625 10.2104 5.67998 10.3646 5.783C10.5188 5.88601 10.6389 6.03243 10.7099 6.20373C10.7808 6.37504 10.7994 6.56354 10.7632 6.7454C10.7271 6.92725 10.6378 7.0943 10.5067 7.22541C10.3756 7.35652 10.2085 7.44581 10.0267 7.48199C9.84479 7.51816 9.65629 7.49959 9.48499 7.42864C9.31368 7.35768 9.16726 7.23752 9.06425 7.08335C8.96124 6.92918 8.90625 6.74792 8.90625 6.5625C8.90625 6.31386 9.00503 6.0754 9.18084 5.89959C9.35666 5.72377 9.59511 5.625 9.84375 5.625ZM10.625 14.375H10C9.83424 14.375 9.67527 14.3092 9.55806 14.1919C9.44085 14.0747 9.375 13.9158 9.375 13.75V10C9.20924 10 9.05027 9.93415 8.93306 9.81694C8.81585 9.69973 8.75 9.54076 8.75 9.375C8.75 9.20924 8.81585 9.05027 8.93306 8.93306C9.05027 8.81585 9.20924 8.75 9.375 8.75H10C10.1658 8.75 10.3247 8.81585 10.4419 8.93306C10.5592 9.05027 10.625 9.20924 10.625 9.375V13.125C10.7908 13.125 10.9497 13.1908 11.0669 13.3081C11.1842 13.4253 11.25 13.5842 11.25 13.75C11.25 13.9158 11.1842 14.0747 11.0669 14.1919C10.9497 14.3092 10.7908 14.375 10.625 14.375Z" fill="#ACACAC"/>
                                                </svg>
                                                <span>{CurationServiceVariable.RESULT} 이미지는 한장만 지정할 수 있습니다.</span>
                                            </h4>
                                        </div>
                                        {
                                            this.state.curation_result_img != null && this.state.curation_result_img != "" 
                                            ?   <div class="quration_gen_info_thumbnail_img" style={{backgroundImage:"url("+this.state.curation_result_img+")", backgroundSize:"contain", backgroundRepeat: "no-repeat", backgroundPosition: "center",  }}>
                                                </div>
                                            :   <div class="quration_gen_info_thumbnail_img">
                                                    <h5>이미지 미리보기</h5>
                                                </div>   
                                        }
                                    </div>
                                </div>
                                <div class="quration_list_matching">
                                    <h3 class="text_16">* 진열 매칭</h3>
                                    <div class="quration_list_matching_select">
                                        <button class="quration_list_matching_search_btn" type="button" style={{width: "155px", fontSize: "14px", lineHeight: "20px",paddingBottom: "1.1px"}} onClick={e => {window.open("/shop/rp/event/list")}}>
                                            {/* <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.4453 17.0547L15.0625 13.6797C16.2564 12.2583 16.8555 10.4308 16.7348 8.57844C16.614 6.72607 15.7828 4.99182 14.4146 3.73738C13.0463 2.48294 11.2465 1.80518 9.39063 1.84545C7.53477 1.88571 5.7661 2.6409 4.4535 3.9535C3.1409 5.2661 2.38571 7.03477 2.34545 8.89063C2.30518 10.7465 2.98294 12.5463 4.23738 13.9146C5.49182 15.2828 7.22607 16.114 9.07844 16.2348C10.9308 16.3555 12.7583 15.7564 14.1797 14.5625L17.5547 17.9453C17.6742 18.0608 17.8338 18.1254 18 18.1254C18.1662 18.1254 18.3258 18.0608 18.4453 17.9453C18.5627 17.8268 18.6285 17.6668 18.6285 17.5C18.6285 17.3332 18.5627 17.1732 18.4453 17.0547ZM3.625 9.0625C3.625 7.88817 3.97323 6.74022 4.62565 5.7638C5.27807 4.78738 6.20538 4.02636 7.29032 3.57696C8.37525 3.12757 9.56909 3.00999 10.7208 3.23909C11.8726 3.46819 12.9306 4.03368 13.7609 4.86405C14.5913 5.69443 15.1568 6.75239 15.3859 7.90415C15.615 9.05591 15.4974 10.2497 15.048 11.3347C14.5986 12.4196 13.8376 13.3469 12.8612 13.9994C11.8848 14.6518 10.7368 15 9.5625 15C7.98841 14.9979 6.47939 14.3717 5.36634 13.2587C4.25329 12.1456 3.62707 10.6366 3.625 9.0625Z" fill="#333333"/>
                                            </svg> */}
                                            진열 목록 열기 (새탭)
                                        </button>
                                        <div style={{marginRight:"12px"}}>
                                            <SelectBoxV2
                                                width="265px"
                                                height="40px"
                                                padding="9px 16px"
                                                list= {this.state.rp_list}
                                                optionTexttName = "display_name"
                                                optionValuetName = "iframe_html_id"
                                                selectedValue = {this.state.rp_select_value}
                                                selectedText={this.state.rp_select_text}
                                                placeHolder={"진열을 선택해주세요."}
                                                onClickOption={this.onClickRpOption.bind(this)}
                                                tabIndex="4"
                                                id="rp_select_box"
                                            />
                                        </div>
                                        <button class="quration_list_matching_search_btn" type="button" tabIndex="5" style={{width: "40px", height:"40px", fontSize: "14px", lineHeight: "20px", padding:"9px", border:"0px"}} onClick={e => this.loadRpList(true)}>
                                            <img src={icn_refresh}/>
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <div class="quration_gen_btn_wrap">
                                <button class="quration_gen_btn_Y hover_mint" type="button" onClick={() => this.onClickCurationResultSave()} tabIndex={-1}>저장</button>
                                <button class="quration_gen_btn_N hover_white" onClick={e => this.onClickCancel()} tabIndex={-1}>취소</button>
                            </div>
                        </div>
                    </section>
                    {/* <div class="quration_popup">
                        <div class="quration_popup_layer quration_result_gen_popup_layer">
                            <div class="quration_result_gen_layer_title">
                                <h2 class="text_16">진열 목록</h2>
                                <button class="quration_result_layer_cancel_btn">
                                    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.0704 15.1788C16.1878 15.2973 16.2536 15.4573 16.2536 15.6241C16.2536 15.7909 16.1878 15.9509 16.0704 16.0694C15.9509 16.1849 15.7913 16.2495 15.6251 16.2495C15.4589 16.2495 15.2992 16.1849 15.1798 16.0694L10.0001 10.8819L4.82041 16.0694C4.70095 16.1849 4.54127 16.2495 4.3751 16.2495C4.20892 16.2495 4.04925 16.1849 3.92979 16.0694C3.81242 15.9509 3.74658 15.7909 3.74658 15.6241C3.74658 15.4573 3.81242 15.2973 3.92979 15.1788L9.11728 9.99912L3.92979 4.81943C3.83012 4.698 3.77919 4.54383 3.78689 4.38693C3.7946 4.23002 3.8604 4.08159 3.97148 3.9705C4.08256 3.85942 4.231 3.79362 4.3879 3.78592C4.54481 3.77821 4.69898 3.82914 4.82041 3.92881L10.0001 9.11631L15.1798 3.92881C15.3012 3.82914 15.4554 3.77821 15.6123 3.78592C15.7692 3.79362 15.9176 3.85942 16.0287 3.9705C16.1398 4.08159 16.2056 4.23002 16.2133 4.38693C16.221 4.54383 16.1701 4.698 16.0704 4.81943L10.8829 9.99912L16.0704 15.1788Z" fill="#333333"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="quration_list_table_wrap">
                                <table class="quration_list_table">
                                    <thead>
                                        <tr class="text_13">
                                            <th>번호</th>
                                            <th>진열명칭</th>
                                            <th>진열방식</th>
                                            <th>쇼핑몰</th>
                                            <th>패이지</th>
                                            <th>상품선정 최종 일시</th>
                                            <th>상품선정수</th>
                                            <th>상품선정 기준</th>
                                            <th>자동추천</th>
                                            <th>디자인</th>
                                            <th>등록일</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="text_13">
                                            <td class="table_number">100</td>
                                            <td class="table_name"><span>합리적 소비자 합리적 소비자 합리적 소비자 합리적 소비자 합리적 소비자 합리적 소비자</span></td>
                                            <td class="table_type">나열</td>
                                            <td class="table_view_type">PC/모바일</td>
                                            <td class="table_page">메인 페이지</td>
                                            <td class="table_date">2022-01-01 01:00:00</td>
                                            <td class="table_amount">99</td>
                                            <td class="table_standard">알고리즘 선정</td>
                                            <td class="table_recommend">사용 안 함</td>
                                            <td class="table_design"><img src="http://drive.google.com/uc?export=view&id=1bUiwhLhLqqr0T0LAh8CxdupNWSogqeng"/></td>
                                            <td class="table_register_date">2022-01-01 01:00:00</td>
                                        </tr>
                                        <tr class="text_13">
                                            <td class="table_number">100</td>
                                            <td class="table_name"><span style={{textAlign: "center"}}>합리적 소비자 합리적 소비자 합리적 소비자 합리적 소비자 합리적 소비자 합리적 소비자</span></td>
                                            <td class="table_type">나열</td>
                                            <td class="table_view_type">PC/모바일</td>
                                            <td class="table_page">메인 페이지</td>
                                            <td class="table_date">2022-01-01 01:00:00</td>
                                            <td class="table_amount">99</td>
                                            <td class="table_standard">알고리즘 선정</td>
                                            <td class="table_recommend">사용 안 함</td>
                                            <td class="table_design"><img src="http://drive.google.com/uc?export=view&id=1bUiwhLhLqqr0T0LAh8CxdupNWSogqeng"/></td>
                                            <td class="table_register_date">2022-01-01 01:00:00</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="quration_table_paging_wrap">
                                <div class="before_btn">
                                    <div>
                                        <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.85624 13.3548C8.76144 13.4487 8.63341 13.5013 8.49999 13.5013C8.36657 13.5013 8.23854 13.4487 8.14374 13.3548L3.14374 8.35477C3.05133 8.2592 2.99967 8.13146 2.99967 7.99852C2.99967 7.86558 3.05133 7.73784 3.14374 7.64227L8.14374 2.64227C8.24089 2.56254 8.36422 2.52179 8.48974 2.52795C8.61527 2.53412 8.73402 2.58675 8.82288 2.67562C8.91175 2.76449 8.96439 2.88323 8.97055 3.00876C8.97672 3.13429 8.93597 3.25762 8.85624 3.35477L4.20624 7.99852L8.85624 12.6423C8.95013 12.7371 9.0028 12.8651 9.0028 12.9985C9.0028 13.1319 8.95013 13.26 8.85624 13.3548ZM8.14374 7.64227C8.05133 7.73784 7.99967 7.86558 7.99967 7.99852C7.99967 8.13146 8.05133 8.2592 8.14374 8.35477L13.1437 13.3548C13.2409 13.4345 13.3642 13.4752 13.4897 13.4691C13.6153 13.4629 13.734 13.4103 13.8229 13.3214C13.9118 13.2325 13.9644 13.1138 13.9706 12.9883C13.9767 12.8627 13.936 12.7394 13.8562 12.6423L9.20624 7.99852L13.8562 3.35477C13.936 3.25762 13.9767 3.13429 13.9706 3.00876C13.9644 2.88323 13.9118 2.76449 13.8229 2.67562C13.734 2.58675 13.6153 2.53412 13.4897 2.52795C13.3642 2.52179 13.2409 2.56253 13.1437 2.64227L8.14374 7.64227Z" fill="#8B8B8B"/>
                                        </svg>
                                    </div>
                                    <div>
                                        <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.99999 7.99852C5.00179 7.86599 5.05306 7.73893 5.14374 7.64227L10.1437 2.64227C10.2409 2.56253 10.3642 2.52179 10.4897 2.52795C10.6153 2.53412 10.734 2.58675 10.8229 2.67562C10.9118 2.76449 10.9644 2.88323 10.9706 3.00876C10.9767 3.13429 10.936 3.25762 10.8562 3.35477L6.20624 7.99852L10.8562 12.6423C10.936 12.7394 10.9767 12.8627 10.9706 12.9883C10.9644 13.1138 10.9117 13.2325 10.8229 13.3214C10.734 13.4103 10.6153 13.4629 10.4897 13.4691C10.3642 13.4752 10.2409 13.4345 10.1437 13.3548L5.14374 8.35477C5.05306 8.2581 5.00179 8.13104 4.99999 7.99852Z" fill="#8B8B8B"/>
                                        </svg>
                                    </div>
                                </div>
                                <div class="quration_table_paging">
                                    <input type="radio" id="quration_popup_table_paging_1" name="quration_popup_table_paging" checked/>
                                    <label for="quration_popup_table_paging_1">1</label>
                                    <input type="radio" id="quration_popup_table_paging_2" name="quration_popup_table_paging"/>
                                    <label for="quration_popup_table_paging_2">2</label>
                                    <input type="radio" id="quration_popup_table_paging_3" name="quration_popup_table_paging"/>
                                    <label for="quration_popup_table_paging_3">3</label>
                                    <input type="radio" id="quration_popup_table_paging_4" name="quration_popup_table_paging"/>
                                    <label for="quration_popup_table_paging_4">4</label>
                                    <input type="radio" id="quration_popup_table_paging_5" name="quration_popup_table_paging"/>
                                    <label for="quration_popup_table_paging_5">5</label>
                                </div>
                                <div class="after_btn">
                                    <div>
                                        <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 8.00148C11.9982 8.13401 11.9469 8.26107 11.8563 8.35773L6.85625 13.3577C6.75911 13.4375 6.63577 13.4782 6.51025 13.472C6.38472 13.4659 6.26598 13.4132 6.17711 13.3244C6.08824 13.2355 6.03561 13.1168 6.02944 12.9912C6.02328 12.8657 6.06402 12.7424 6.14375 12.6452L10.7938 8.00148L6.14376 3.35773C6.06402 3.26059 6.02328 3.13725 6.02944 3.01173C6.03561 2.8862 6.08824 2.76746 6.17711 2.67859C6.26598 2.58972 6.38472 2.53709 6.51025 2.53092C6.63578 2.52476 6.75911 2.5655 6.85626 2.64523L11.8563 7.64523C11.9469 7.7419 11.9982 7.86896 12 8.00148Z" fill="#8B8B8B"/>
                                        </svg>
                                    </div>
                                    <div>
                                        <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.14375 2.64523C8.23855 2.55134 8.36658 2.49867 8.5 2.49867C8.63342 2.49867 8.76145 2.55134 8.85625 2.64523L13.8562 7.64523C13.9487 7.7408 14.0003 7.86854 14.0003 8.00148C14.0003 8.13442 13.9487 8.26216 13.8562 8.35773L8.85625 13.3577C8.7591 13.4375 8.63577 13.4782 8.51024 13.472C8.38472 13.4659 8.26597 13.4132 8.17711 13.3244C8.08824 13.2355 8.0356 13.1168 8.02944 12.9912C8.02327 12.8657 8.06402 12.7424 8.14375 12.6452L12.7937 8.00148L8.14375 3.35773C8.04986 3.26294 7.99719 3.13491 7.99719 3.00148C7.99719 2.86806 8.04986 2.74003 8.14375 2.64523ZM8.85625 8.35773C8.94866 8.26216 9.00032 8.13442 9.00032 8.00148C9.00032 7.86854 8.94866 7.7408 8.85625 7.64523L3.85625 2.64523C3.7591 2.5655 3.63577 2.52476 3.51024 2.53092C3.38472 2.53709 3.26597 2.58972 3.1771 2.67859C3.08824 2.76746 3.0356 2.8862 3.02944 3.01173C3.02327 3.13725 3.06402 3.26059 3.14375 3.35773L7.79375 8.00148L3.14375 12.6452C3.06402 12.7424 3.02327 12.8657 3.02944 12.9912C3.0356 13.1168 3.08824 13.2355 3.17711 13.3244C3.26597 13.4132 3.38472 13.4659 3.51024 13.472C3.63577 13.4782 3.7591 13.4375 3.85625 13.3577L8.85625 8.35773Z" fill="#8B8B8B"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div class="quration_result_gen_popup_btn">
                                <button class="hover_mint">선택 진열 반영</button>
                            </div>
                        </div>
                        <div class="quration_alert_wrap">
                            <div class="quration_alert quration_result_gen_alert">
                                <div class="quration_alert_txt text_16">
                                    <span class="table_name_selected">
                                        상품 결과 제공을 위해<br/>
                                        진열 목록을 선택해주세요.
                                    </span>
                                </div>
                                <button class="quration_alert_confirm">확인</button>
                            </div>
                        </div>
                        <div class="quration_alert quration_gen_alert_img">
                            <div class="quration_alert_txt text_16">
                                <span class="table_name_selected">
                                    이미지 파일만 업로드 가능합니다. <br/>
                                    (이미지 확장자 .jpg, .jpeg, .png)
                                </span>
                            </div>
                            <button class="quration_alert_confirm">확인</button>
                        </div>
                    </div> */}
                </div>
                </div>
                {
					(this.state.basic_dialog_show_state === true)
					? <BasicDialogV2
						content={this.state.basic_dialog_content}
						button_list={this.state.basic_dialog_button_list}
					/>
					: ''
				}
                {/* 확인 취소 다이얼로그 */
                (this.state.confirm_dialog_show_state === true)
                ? <ConfirmDialogV2
                    content={this.state.confirm_dialog_content}
                    button_list={this.state.confirm_dialog_button_list}
                    popup_close={() => {
                        this.setState({confirm_dialog_show_state: false});
                        // 스크롤 고정 해제
                        StyleUtil.clearPopupNoneScroll(window_scrollTop);
                    }}
                />
                : ''
            }
            </>
        )
    }
}

export default CurationResultGen;
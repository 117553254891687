import React, { useCallback, useState } from 'react';
import axios from '../modules/api';
import http from '../modules/api';
import {Line} from 'react-chartjs-2';
import { NavLink, Link, Prompt } from 'react-router-dom';
import $ from 'jquery';
import Slider from "react-slick";
import plus_img from '../../resources/img/plus.svg';
import Pagination from "react-js-pagination";
import FileDownload from 'js-file-download';

import GA4React from "ga-4-react";

try {
    setTimeout(_ => {
      const ga4react = new GA4React("G-EBVEYNW6SX");
      ga4react.initialize().catch(err => console.error("ga4react"+err));
    }, 4000);
} catch (err) {
    console.error("ga4react",err);
}

var is_product_loaded = false;
var bfore_kdeal_user_name = "";

export class KdealProductListForAdmin extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
            // 무료 체험 기간 관련 변수
            remain_date: "",
            start_day_of_week: "",
            start_date_format: "",
            is_finish: "",
            end_day_of_week: "",
            end_date_format: "",

            list : [], // 진열 목록
            all_install_yn : true, // 모든 진열이 설치됐는지에 대한 여부(하나라도 설치되지 않았다면 false)

            /* 페이징 */
            activePage: 1,
			totalCnt: 0,
			startIdx: 0,
			rowcnt:10,

            kdeal_user_no: "",
            kdeal_user_name: "",
            keyword: "",

            is_downloaded: [],
        }
    }

    componentWillMount() {

    }

	componentDidMount() {
        // $(document).ready(function(){
        //     //table의 넓이 계산
        //     var list_td = document.getElementsByClassName("list_td");
        //     var list_td_sum = 0;
            
        //     for(let i = 0; i < list_td.length; i++){
        //         list_td_sum = list_td_sum + list_td[i].offsetWidth;
        //     }

        //     $(".list_home_main_row_3 table").css({
        //         "width":list_td_sum
        //     });
        // });
        if (sessionStorage.getItem("kdeal_product_list_filter") != null) {
            let kdeal_product_list_filter = JSON.parse(sessionStorage.getItem("kdeal_product_list_filter"));
            this.setState({
                activePage: kdeal_product_list_filter.activePage,
                totalCnt: kdeal_product_list_filter.totalCnt,
                startIdx: kdeal_product_list_filter.startIdx,
                rowcnt: kdeal_product_list_filter.rowcnt,
    
                kdeal_user_no: kdeal_product_list_filter.kdeal_user_no,
                keyword: kdeal_product_list_filter.keyword,
            }, () => {
                sessionStorage.removeItem("kdeal_product_list_filter")
                this.loadKdealProduct(this.state.startIdx, this.state.rowcnt, this.state.keyword, this.state.kdeal_user_no);
                this.loadKdealShopList();
            })
        } else {
            this.loadKdealProduct(this.state.startIdx, this.state.rowcnt, "", "");
            this.loadKdealShopList();
        }
        
        // this.loadFreeDatePeriod();
    }

    componentDidUpdate() {
    }

    componentWillUnmount(e) {

    }

    getUrlParams() {
        var params = {};
        window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(str, key, value) { params[key] = value; });
        return params;
    }

    // setGaEvent(action_text) {
    //     ReactGA.event({
    //          category: "베스트 서비스 (롤링 목록)",
    //          action: action_text,
    //          label: sessionStorage.getItem("user_id"),
    //      });
    // }

    // 소상공인 상품 목록 데이터 불러오기
    async loadKdealProduct(startIdx, rowcnt, keyword, kdeal_user_no) {
        let param = "?";
        param += "&startidx="+startIdx;
        param += "&rowcnt="+rowcnt;
        param += "&keyword="+keyword;
        param += "&kdeal_user_no="+kdeal_user_no;

        // const formData = new FormData();
        // formData.append('startidx',startIdx);
        // formData.append('rowcnt',rowcnt);
        // formData.append('keyword',keyword);
        is_product_loaded = false;

        await http.get("/rest/common/kdeal/product/list"+param)
		.then(res => {
            is_product_loaded = true;
            console.log(res.data);
			if (res.data != null && res.data != '') {
                let is_downloaded = [];
                for(let idx in res.data["list"]) {
                    is_downloaded[idx] = true;
                }
				this.setState({
                    list : res.data["list"],
                    totalCnt : res.data["totalCnt"],
                    is_downloaded: is_downloaded
                }, () => {
                    console.log("is_downloaded", is_downloaded);
                });
			}
		})
		.catch(error => {
		 });
    }

    // 소상공인 업체 목록 데이터 불러오기
    async loadKdealShopList() {
        await http.get("/rest/common/kdeal/user/list")
		.then(res => {
            console.log(res.data);
			if (res.data != null && res.data != '') {
				this.setState({
                    shop_list : res.data,
                    // totalCnt : res.data["totalCnt"],
                }, () => {
                    // kdeal_user_no로 kdeal_user_name 알아내기
                    let kdeal_user_obj = this.state.shop_list.filter(obj => obj.user_no == this.state.kdeal_user_no)
                    let kdeal_user_name = ""
                    if (kdeal_user_obj.length > 0) {
                        kdeal_user_name = kdeal_user_obj[0].corp_name_reg_no
                    } else {
                        kdeal_user_name = ""
                    }
                    this.setState({
                        kdeal_user_name: kdeal_user_name,
                    }, () => {
                        // alert(this.state.kdeal_user_no);
                    })
                });
			}
		})
		.catch(error => {
		 });
    }

    // 상세 페이지로 이동
    onClickProductPageLink(e, product_no) {
        // 무료 체험 기간에만 이동 가능하도록 변경
        if(this.state.is_finish == 0){
            // this.setGaEvent("목록 클릭 - "+ html_name);
            this.props.history.push(
                {
                    pathname: `/kdeal/admin/sosanggongin`,
                    search:`?product_no=`+product_no,
                }	
            )
            sessionStorage.setItem(
                "kdeal_product_list_filter", 
                JSON.stringify({
                    activePage: this.state.activePage,
                    totalCnt: this.state.totalCnt,
                    startIdx: this.state.startIdx,
                    rowcnt:this.state.rowcnt,
                    kdeal_user_no: this.state.kdeal_user_no,
                    keyword: this.state.keyword
                })
            )
        }
    }

    onClickAddList(e){
        // this.setGaEvent("진열 추가 클릭");
        this.props.history.push(
            {
                pathname: `/kdeal/admin/sosanggongin`,
            }	
        )
    }

    // 페이징
	handlePageChange(pageNumber) {
		// console.log(`active page is ${pageNumber}`);
		this.setState({activePage: pageNumber});
		this.state.startIdx = (pageNumber * 10) - this.state.rowcnt;
		// console.log("this.state.startIdx :" + this.state.startIdx )
		this.loadKdealProduct(this.state.startIdx, this.state.rowcnt, this.state.keyword, this.state.kdeal_user_no);
	}  

    // 엔터키 검색
	_handleKeyDown = e => {
        if (e.key === 'Enter') {
            this.state.startIdx = 0;
            this.state.activePage = 1;
            this.loadKdealProduct(this.state.startIdx, this.state.rowcnt, this.state.keyword, this.state.kdeal_user_no);
        }
    }

    // 이미지 다운로드 클릭
    onClickContentDownloadBtn(e, idx, product_no, product_name) {
        e.stopPropagation();

        let is_downloaded = [...this.state.is_downloaded]
        for(let i in is_downloaded) {
            if (!is_downloaded[i]) {
                alert("하나씩 다운로드 해주세요.")
                return false;
            }
        }

        is_downloaded[idx] = false;
        this.setState({
            is_downloaded: is_downloaded,
        })
        const config = {
            headers: {
                'content-type': 'application/json;'
            },
        }
        
        http.post("/rest/common/kdeal/content/capture",
            {
                "product_no" : product_no,
                "banner_width": "960",
                "view_type_cd": "2"
            },
            config
        )
        .then(res => {
            let is_downloaded = [...this.state.is_downloaded]
            is_downloaded[idx] = true;
            this.setState({
                is_downloaded: is_downloaded,
            })

            if (res.data.code == "200") {
                // 성공시 s3 파일 다운로드 요청
                this.fn_s3ImageFileDownload(res.data.capture_code, product_name);
            } else {
                alert(res.data.code + "\n" + res.data.msg);
            }
        })
        .catch(error => {
            console.log (error);
            this.setState({
                is_downloaded: false,
            })
        });
    }

    // s3 이미지 다운로드
    fn_s3ImageFileDownload(capture_code, product_name) {
        // 파일 확장자 추출
        let file_type = capture_code.substring(capture_code.lastIndexOf(".")+1, capture_code.length);
        const config = {
            headers: {
                'content-type': 'application/json;'
            },
            responseType: 'arraybuffer'
        }
        http.post("/rest/common/kdeal/content/download",
            {
                "capture_code" : capture_code,
                "file_type" : file_type
            },
            config
        )
        .then(res => {
            if (res.data != null) {
                // 상품명(제목) 미입력시 '제목없음'로 다운로드
                if (product_name == '') {
                    product_name = '제목없음';
                }
                let file_name = product_name + '.'+file_type;
                var blob = new Blob([res.data], { type: 'image/'+file_type });
                FileDownload(blob, decodeURI(file_name));
            } else {
                alert("s3 이미지 다운로드 요청 실패\n고객센터 : 1599-3518");
            }
        })
        .catch(error => {
            alert("s3 이미지 다운로드 요청 실패\n고객센터 : 1599-3518");
        });
    }

    render() {
        return (
            <>
                <div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
                    <div id="list_wrap" style={{ justifyContent: "start", alignItems: "start"}}>
                        <section className="list_home_main">
                            <div className="list_home_main_row_1" style={{display:'flex', justifyContent:"space-between", width: "1200px",maxWidth: "100%"}}>
                                <div className="mobile_margin_bottom_30px" style={{display:'flex'}}>
                                    <h2 className="text_medium" style={{
                                            alignContent: "center",
                                            display: "flex",
                                            marginBottom: 0
                                    }}>소상공인 상세디자인 등록현황</h2>
                                    <div style={{
                                            alignContent: "center",
                                            display: "flex"
                                    }}>
                                        {/* <button className="list_btn_1 hover_lightmint" onClick={e => this.onClickUserGuide(e)}>사용자 가이드</button> */}
                                        <button className="list_btn_1 hover_mint" onClick={e => this.onClickAddList(e)} style={{width: "auto",padding: "0 12px"}}>
                                            <img src={plus_img} alt="더하기 아이콘"/>상세디자인 등록
                                        </button>
                                    </div>
                                </div>
                                <div className="mobile-width-100 min801-max1314-margin-top-30px" style={{display:'flex', alignItems: "center", flexWrap:"wrap"}}>
                                    <div className="mobile-width-100" style={{
                                            alignContent: "center",
                                            display: "flex",
                                    }}>
                                        {
                                            // this.state.kdeal_user_no == ""
                                            // ? <select className="mobile-width-100 mobile-max-width-100" style={{height: "42px", maxWidth:"385px", padding:"0 15px 0 15px", fontSize: "14px", color:"#999"}} value={this.state.kdeal_user_no} onChange={(e) => {
                                            //     this.setState({
                                            //         kdeal_user_no: e.target.value
                                            //     }, () => {
                                            //         this.loadKdealProduct(this.state.startIdx, this.state.rowcnt, this.state.keyword, this.state.kdeal_user_no);
                                            //     })
                                            // }}>
                                            //     <option value="" disabled>
                                            //         업체를 선택해 보세요.
                                            //     </option>    
                                            //     {
                                            //         this.state.shop_list != null && this.state.shop_list.map((obj, idx) => (
                                            //             <option value={obj.user_no}>
                                            //                 {obj.corp_name_reg_no}
                                            //             </option>    
                                            //         ))
                                            //     }
                                            // </select>
                                            // : <select className="mobile-max-width-100" style={{height: "42px", maxWidth:"385px", padding:"0 15px 0 15px", fontSize: "14px"}} value={this.state.kdeal_user_no} onChange={(e) => {
                                            //     this.setState({
                                            //         kdeal_user_no: e.target.value
                                            //     }, () => {
                                            //         this.loadKdealProduct(this.state.startIdx, this.state.rowcnt, this.state.keyword, this.state.kdeal_user_no);
                                            //     })
                                            // }}>
                                            //     <option value="">
                                            //         전체
                                            //     </option>    
                                            //     {
                                            //         this.state.shop_list != null && this.state.shop_list.map((obj, idx) => (
                                            //             <option value={obj.user_no}>
                                            //                 {obj.corp_name_reg_no}
                                            //             </option>    
                                            //         ))
                                            //     }
                                            // </select>
                                        }
                                        <div style={{position:"relative", width:"300px", height: "42px"}}>
                                            <input id="kdeal_user_nane" type="text" 
                                            style={{position:"absolute", left:"1px", width:"300px", height: "42px", padding:"0 30px 0 15px"}} 
                                            placeholder="업체명을 검색 후 선택해 주세요."
                                            value={this.state.kdeal_user_name}
                                            onChange={(e) => {
                                                if (e.target.value != null && e.target.value != "") {
                                                    let kdeal_user_obj = this.state.shop_list.filter(obj => obj.corp_name_reg_no == e.target.value)
                                                    // alert(JSON.stringify(kdeal_user_obj));
                                                    let kdeal_user_no = ""
                                                    if (kdeal_user_obj.length > 0) {
                                                        kdeal_user_no = kdeal_user_obj[0].user_no
                                                        $("#kdeal_user_nane").blur(); // 매칭되는 업체 있으면 포커스 아웃
                                                    } else {
                                                        kdeal_user_no = ""
                                                    }
                                                    this.setState({
                                                        bfore_kdeal_user_no: this.state.kdeal_user_no,
                                                        kdeal_user_name: e.target.value,
                                                        kdeal_user_no: kdeal_user_no
                                                    }, () => {
                                                        // alert(this.state.kdeal_user_no);
                                                        if(!(this.state.kdeal_user_no == null || this.state.kdeal_user_no == "")) {
                                                            this.loadKdealProduct(this.state.startIdx, this.state.rowcnt, this.state.keyword, this.state.kdeal_user_no);
                                                        } 
                                                    })
                                                } else {
                                                    this.setState({
                                                        bfore_kdeal_user_no: this.state.kdeal_user_no,
                                                        kdeal_user_name: e.target.value,
                                                        kdeal_user_no: ""
                                                    }, () => {
                                                        // alert(this.state.kdeal_user_no);
                                                        // if(this.state.bfore_kdeal_user_no != this.state.kdeal_user_no) {
                                                        //     this.loadKdealProduct(this.state.startIdx, this.state.rowcnt, this.state.keyword, this.state.kdeal_user_no);
                                                        // } 
                                                    })
                                                }
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    // kdeal_user_no 못 찾으면 kdeal_user_name 초기화 (빈문자열)
                                                    let kdeal_user_obj = this.state.shop_list.filter(obj => obj.corp_name_reg_no == this.state.kdeal_user_name)
                                                    let kdeal_user_no = ""
                                                    let kdeal_user_name = this.state.kdeal_user_name
                                                    if (kdeal_user_obj.length > 0) {
                                                        kdeal_user_no = kdeal_user_obj[0].user_no
                                                    } else {
                                                        // mac에서 글자 입력중에 업체 선택 후 엔터 치면 뒤에 한글자 더 붙는 현상 대응
                                                        kdeal_user_name = kdeal_user_name.slice(0, -1);
                                                        let kdeal_user_obj2 = this.state.shop_list.filter(obj => obj.corp_name_reg_no == kdeal_user_name)
                                                        if (kdeal_user_obj2.length > 0) {
                                                            kdeal_user_no = kdeal_user_obj2[0].user_no
                                                        } else {
                                                            kdeal_user_no = ""
                                                        }
                                                    }
                                                    if (kdeal_user_no == "") {
                                                        kdeal_user_name = ""
                                                    }
                                                    this.setState({
                                                        bfore_kdeal_user_no: this.state.kdeal_user_no,
                                                        kdeal_user_name: kdeal_user_name,
                                                        kdeal_user_no: kdeal_user_no
                                                    })

                                                    // 재검색
                                                    this.state.startIdx = 0;
                                                    this.state.activePage = 1;
                                                    this.loadKdealProduct(this.state.startIdx, this.state.rowcnt, this.state.keyword, this.state.kdeal_user_no);
                                                }
                                            }}
                                            autoComplete="off"
                                            list="kdeal_user_no">

                                            </input>
                                            <datalist id="kdeal_user_no" style={{width: "300px", height: "42px", padding:"0 30px 0 15px", fontSize: "14px", marginBottom:"20px", marginRight: "115px", color:"#999"}} value={this.state.kdeal_user_no}>
                                                {/* <option value="" disabled>
                                                    업체명을 선택해 주세요.
                                                </option>     */}
                                                {
                                                    this.state.shop_list != null && this.state.shop_list.map((obj, idx) => (
                                                        <option value= {obj.corp_name_reg_no}>
                                                            {/* {obj.corp_name_reg_no} */}
                                                        </option>    
                                                    ))
                                                }
                                            </datalist>
                                        </div> 
                                    </div>   
                                    <div className="mobile-width-100 mobile-margin-top-10px mobile_margin_left_0" 
                                        style={{
                                            position:"relative", width:"250px", height:"auto", display:"flex", alignItems: "center",alignContent: "center",marginLeft: "20px",
                                        }}>
                                        <div class="btn_search" style={{
                                            position: "absolute",
                                            left: "20px",
                                            top: "12px",
                                        }}></div>
                                        <input type="text" value={this.state.keyword} onChange={(e) => {
                                            // console.log(e.target.value)
                                            this.setState({ keyword: e.target.value })
                                        }} placeholder="자료를 검색해 보세요." style={{width:"100%", height: "42px", padding: "0px 20px 0px 53px"}} onKeyDown={this._handleKeyDown}></input>
                                        {/* <button className = "greenButton" onClick={this.onClickSearchListButton} style={{width: "45px",height: "35px", margin: 'auto'}}>검색</button> */}
                                    </div> 
                                </div>    
                            </div>
                            <div className="list_home_main_row_3" style={{width: "1200px",maxWidth: "100%"}}>
                                <table style={{width: "100%"}}>
                                    <thead>
                                        <tr>
                                            <td className="list_td" style={{width: "80px"}}>번호</td>
                                            <td className="list_td" style={{width: "260px"}}>업체명 (작성자)</td>
                                            <td className="list_td" style={{width: "auto"}}>상품명 (상세디자인명)</td>
                                            <td className="list_td" style={{width: "200px"}}>작성일시</td>
                                            <td className="list_td" style={{width: "200px"}}>다운로드</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.state.list != null && this.state.list.length > 0
                                        ?   <>
                                            {
                                                this.state.list.map((obj, idx) => (
                                                    <tr>
                                                        <td onClick={e => this.onClickProductPageLink(e, obj.product_no)}>{(this.state.totalCnt-(idx+(10*(this.state.activePage-1))))}</td>
                                                        <td onClick={e => this.onClickProductPageLink(e, obj.product_no)}>{obj.corp_name}</td>
                                                        <td onClick={e => this.onClickProductPageLink(e, obj.product_no)}>{obj.product_name}</td>
                                                        <td onClick={e => this.onClickProductPageLink(e, obj.product_no)}>{obj.reg_date}</td>
                                                        {
                                                            this.state.is_downloaded[idx] == true
                                                            ?   <td onClick={e => this.onClickProductPageLink(e, obj.product_no)}><button type="button" className="btn-auto-w" style={{height:"45px"}} onClick={e => this.onClickContentDownloadBtn(e, idx, obj.product_no, obj.product_name)}>다운로드</button></td>
                                                            :   <td onClick={e => this.onClickProductPageLink(e, obj.product_no)}><button type="button" className="btn-auto-w" style={{height:"45px"}} >다운로드 중...</button></td>
                                                        }
                                                    </tr>
                                                ))
                                            }
                                            </>
                                        :
                                            <div style={{width:"1199px", maxWidth: "100vw"}}>
                                                {
                                                    is_product_loaded == true
                                                    ? "자료가 없습니다."
                                                    : "자료를 불러오는 중입니다."
                                                }
                                            </div>
                                        }
                                    </tbody>
                                </table>
                                <div style={{margin:"15px 0 15px 0", width:"100%"}}>
                                    {
                                        Number(this.state.totalCnt) <= 0
                                        ?   ""
                                        :   <Pagination
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={this.state.rowcnt}
                                                totalItemsCount={this.state.totalCnt}
                                                pageRangeDisplayed={5}
                                                onChange={this.handlePageChange.bind(this)}
                                            />
                                    }
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </>
        )
    };
    
}
import React from 'react';
import axios from 'axios';
import http from '../modules/api';

export class QnaDetail extends React.Component {
	
	constructor(props) {
		super(props);
	    this.state = {
		  qna: null,
		  answer: null
	    }
	}
	
	componentDidMount() {
		// uri 파라메터 얻기: this.props.match.params
	    const {qna_seq} = this.props.match.params;
	    console.log("### 1. componentDidMount" + qna_seq);
		this.getQna(qna_seq);
	}
	
	async getQna(id) {
		const res = await http.get(`/rest/shop/qna/${id}`);
		console.log(res.data)
		this.setState({qna: res.data});
	}

	async delQna(id) {
		const res = await http.post(`/rest/shop/del/qna/${id}`);
		console.log(res.data)
		if (res.data != null && res.data["result"] == "true") {
			alert("게시물이 삭제 되었습니다.")
			this.props.history.goBack();
			localStorage.clear();
		}
	}

	async setAnswer(id) {
		if (this.state.answer == null) {
			alert("답변을 적어주세요.")
			return;
		}
		const res = await http.post(`/rest/shop/answer/qna/${id}`, {
			answer: this.state.answer
		});
		console.log(res.data)
		if(typeof res.data === 'string') {
			if(res.data.indexOf("<script>") >= 0){
				alert("로그인 후 이용해 주세요.")
				window.location.href = "/login";
			}
		}

		if (res.data != null && res.data["result"] == "true") {
			alert("답변이 저장 되었습니다.")
		}
	};
	
	//3. 사용자 권한 변경
	onClickSignupButton = () => {
		
	};
	
	// 목록으로 이동 (뒤로가기)
	onGoList = () => {
		this.props.history.push(`/admin/qna/list`);
	};
	
	// 수정페이지 이동
	onGoModify = (qna_seq) => {
		this.props.history.push(`/admin/qna/form/${qna_seq}`);
	};
	
	render() {
		
		return (
			this.state.qna ?
			<div id='page-wrapper' className="container qna_detail_container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
				<h3 className="margin-b-20">[Admin] 문의사항</h3>
				<div className="shadow-box margin-r-5">
					<div style={{height:"auto"}} className="float-right-parent border-bottom-grey padding-10">
						<div className="bold-text float-left" style={{textOverflow: "ellipsis"}}>
							{this.state.qna.title}
						</div>
						<div className="color-grey text-small float-right" style={{lineHeight: "22px"}}>
							<span className="margin-r-10">{this.state.qna.company_name}</span>
							<span>{this.state.qna.reg_date}</span>
						</div>
					</div>
					{(() => {
						if (this.state.qna.file_link != null) {
							return <div>
								<div className="padding-10 border-bottom-grey text-small">
									파일 - <a className="color-black" href={this.state.qna.file_link} target="blank">{this.state.qna.file_name}</a>
								</div>
							</div>
						}	
					})()}
					<div>
						<div className="padding-10" style={{height:"40vh", overflow: "auto"}}
						dangerouslySetInnerHTML={ {__html: this.state.qna.content} }>
						</div>
					</div>
				</div>
				<div className="margin-t-10 btn_wrap"> 
		        	{/* <button className="greenButton margin-r-5" onClick={() => this.onGoModify(this.state.qna.qna_seq)}>수정</button>&nbsp; */}
		        	<button className="greenButton margin-r-5" onClick={() => this.delQna(this.state.qna.qna_seq)}>삭제</button>&nbsp;
		        	<button className="greenButton margin-r-5" onClick={this.onGoList}>목록</button>&nbsp;
				</div>
				<br/>
				<br/>
				<br/>
				<div className="margin-b-20">
					<h4>[답변 등록]</h4>
				</div>
				<div className="shadow-box margin-r-5">
					<div>
						<textarea className="padding-10" style={{width:"100%", height:"20vh", overflow: "auto", border:"0px"}} 
						onChange={(e) => {
							this.state.answer = e.target.value
							console.log(this.state.answer)
							this.setState({ answer: this.state.answer })
						}} defaultValue={this.state.qna.answer}>

						</textarea>
					</div>
				</div>
				<div className="margin-t-10 margin-b-10 btn_wrap"> 
					<button className="greenButton margin-r-5" onClick={() => this.setAnswer(this.state.qna.qna_seq)}>답변 저장</button>&nbsp;
				</div>
			</div>
			: 'non-data'
		);
	}
	
	componentWillReceiveProps(nextProps, nextContext) {
		console.log(nextProps);
		this.getUser(nextProps.match.params.qna_seq);
	}
	
}

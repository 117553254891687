import React, { useCallback, useState } from 'react';
import axios from '../modules/api';
import http from '../modules/api';
import {Line} from 'react-chartjs-2';
import { NavLink, Link, Prompt } from 'react-router-dom';
import $ from 'jquery';
import Slider from "react-slick";
import download_img from '../../resources/img/download.svg';

import ReactGA, { send } from 'react-ga';
ReactGA.initialize('UA-78553461-8', {
    titleCase: false,
});

export class TestSosanggongin extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
            tmp_product_no : "",
            product_no : "",
            event_list : "",
            
            tmp_event_seq_list : [],

            intro_first_line : "",
            intro_second_line : "",
            intro_third_line : "",
            intro_image : "",
            intro_image_name : "",
            intro_image_object : "",

            detail_one_first_line : "",
            detail_one_second_line : "",
            detail_one_third_line : "",
            detail_one_image : "",
            detail_one_image_name : "",
            detail_one_image_object : "",

            detail_two_first_line : "",
            detail_two_second_line : "",
            detail_two_third_line : "",
            detail_two_image : "",
            detail_two_image_name : "",
            detail_two_image_object : "",

            detail_three_first_line : "",
            detail_three_second_line : "",
            detail_three_third_line : "",
            detail_three_image : "",
            detail_three_image_name : "",
            detail_three_image_object : "",

            detail_info_image : "",
            detail_info_image_name : "",

            is_downloaded: true,
        }
    }

    componentWillMount() {

    }

	componentDidMount() {
        this.loadFirstEvent();
        this.loadProduct();

        $(document).ready(function(){
            // object 영역 max-height 초기화
            $("#intro_image_object").css('max-height',$("#intro_area").innerHeight());
            $("#detail_one_image_object").css('max-height',$("#detail_one_area").innerHeight());
            $("#detail_two_image_object").css('max-height',$("#detail_two_area").innerHeight());
            $("#detail_three_image_object").css('max-height',$("#detail_three_area").innerHeight());

            setTimeout(() => window.dispatchEvent(new Event('resize')), 10);
        });

        $(window).resize(function(){
            $("#footer").css('width',$("#admin_wrap").width());
            $("#footer_line").css('width',$("#admin_wrap").width());
        });
        
        // 사이드바 확장/축소 버튼 클릭 시 화면 비율 조정
        $('html').click(function(e) {
            if($(e.target).hasClass("cursor-pointer") || $(e.target).parents(".cursor-pointer").length > 0) {
                setTimeout(() => window.dispatchEvent(new Event('resize')), 10);
            }
        });
    }

    componentDidUpdate() {
        //console.log(this.state.intro_image_object);
        //console.log(this.state.intro_first_line);
        // console.log(this.state.intro_second_line);
        // console.log(this.state.intro_third_line);

        // console.log(this.state.intro_image);
        // console.log(this.state.detail_one_image);
        // console.log(this.state.detail_two_image);
        // console.log(this.state.detail_three_image);
        // console.log(this.state.detail_info_image);
    }

    componentWillUnmount(e) {

    }

    // 페이지 정보 알아내기
    getUrlParams() {
        var params = {};
        window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(str, key, value) { params[key] = value; });
        return params.product_no;
    }

    // 기존 상세페이지 정보 불러오기
    async loadProduct(){
        const product_no = this.getUrlParams();
        if(product_no != null && product_no != ""){
            await http.get("/rest/common/kdeal/product?product_no="+this.getUrlParams())
            .then(res => {
                console.log(res.data);
                if (res.data != null && res.data != '') {
                    const intro = res.data["event_list"][0]["content_list"];
                    const detail_one = res.data["event_list"][1]["content_list"];
                    const detail_two = res.data["event_list"][2]["content_list"];
                    const detail_three = res.data["event_list"][3]["content_list"];
                    const detail_info = res.data["event_list"][4]["content_list"];
                    this.setState({
                        intro_first_line : intro[0]["contents"],
                        intro_second_line : intro[1]["contents"],
                        intro_third_line : intro[2]["contents"],
                        intro_image : intro[3]["org_img_seq"],
                        intro_image_name : intro[3]["img_name"],
    
                        detail_one_first_line : detail_one[0]["contents"],
                        detail_one_second_line : detail_one[1]["contents"],
                        detail_one_third_line : detail_one[2]["contents"],
                        detail_one_image : detail_one[3]["org_img_seq"],
                        detail_one_image_name : detail_one[3]["img_name"],
    
                        detail_two_first_line : detail_two[0]["contents"],
                        detail_two_second_line : detail_two[1]["contents"],
                        detail_two_third_line : detail_two[2]["contents"],
                        detail_two_image : detail_two[3]["org_img_seq"],
                        detail_two_image_name : detail_two[3]["img_name"],
    
                        detail_three_first_line : detail_three[0]["contents"],
                        detail_three_second_line : detail_three[1]["contents"],
                        detail_three_third_line : detail_three[2]["contents"],
                        detail_three_image : detail_three[3]["org_img_seq"],
                        detail_three_image_name : detail_three[3]["img_name"],
    
                        detail_info_image : detail_info[0]["org_img_seq"],
                        detail_info_image_name : detail_info[0]["img_name"],

                        product_no : product_no,
                        event_list : res.data["event_list"],
                    }, () => {
                        if(this.state.detail_info_image != ""){
                            $("#detail_info_image").attr("src",detail_info[0]["img_url"]);
                        }
                        
                        setTimeout(() => {
                            // 포커스를 강제로 주어, textarea 내용에 맞게 오른쪽 영역도 변동되도록 한다
                            let window_scrollTop = $(window).scrollTop();
                            $("#intro_first_textarea").focus();
                            $("#intro_second_textarea").focus();
                            $("#intro_third_textarea").focus();
    
                            $("#detail_one_first_textarea").focus();
                            $("#detail_one_second_textarea").focus();
                            $("#detail_one_third_textarea").focus();
    
                            $("#detail_two_first_textarea").focus();
                            $("#detail_two_second_textarea").focus();
                            $("#detail_two_third_textarea").focus();
    
                            $("#detail_three_first_textarea").focus();
                            $("#detail_three_second_textarea").focus();
                            $("#detail_three_third_textarea").focus();
    
                            $(':focus').blur();
    
                            // 초기의 스크롤 위치로 스크롤 이동
                            $("html,body").scrollTop(window_scrollTop);

                        }, 100);

                        this.onChangeEventFormContent("intro");
                        this.onChangeEventFormContent("detail_one");
                        this.onChangeEventFormContent("detail_two");
                        this.onChangeEventFormContent("detail_three");
                        this.onChangeEventFormContent("detail_info");
                    });
                }
            })
            .catch(error => {
             });
        }
    }

    // 소상공인 최초 미리보기용 이벤트 생성
    loadFirstEvent(){
        const config = {
            headers: {
                'Content-Type': 'application/json',
                },
        };
        const url = '/rest/common/kdeal/first/event/tmp/save';
        const body = {
            event_cnt : "5"
        }
        http
        .post(url, body, config)
		.then(res => {
            console.log(res.data);
			if (res.data != null && res.data != '') {
                this.setState({
                    tmp_product_no : res.data["product_no"],
                    tmp_event_seq_list : res.data["tmp_event_seq_list"],
                    intro_image_object : "http://127.0.0.1:8092/robomd_product_detail.html?view_type_cd=0&product_no="+
                    res.data["product_no"]+"&tmp_event_seq="+res.data["tmp_event_seq_list"][0]+"&token=",
                    detail_one_image_object : "http://127.0.0.1:8092/robomd_product_detail.html?view_type_cd=0&product_no="+
                    res.data["product_no"]+"&tmp_event_seq="+res.data["tmp_event_seq_list"][1]+"&token=",
                    detail_two_image_object : "http://127.0.0.1:8092/robomd_product_detail.html?view_type_cd=0&product_no="+
                    res.data["product_no"]+"&tmp_event_seq="+res.data["tmp_event_seq_list"][2]+"&token=",
                    detail_three_image_object : "http://127.0.0.1:8092/robomd_product_detail.html?view_type_cd=0&product_no="+
                    res.data["product_no"]+"&tmp_event_seq="+res.data["tmp_event_seq_list"][3]+"&token=",
                }, () => {
                    this.onChangeEventFormContent("intro");
                    this.onChangeEventFormContent("detail_one");
                    this.onChangeEventFormContent("detail_two");
                    this.onChangeEventFormContent("detail_three");
                    this.onChangeEventFormContent("detail_info");
                });
			}
		})
		.catch(error => {
		 });
    }

    // 소상공인 이미지 업로드
    uploadOrgImg(file, image_id){
        const files = Array.from(file);
        Promise.all(files.map(file => {
            return (new Promise((resolve,reject) => {
                const reader = new FileReader();
                reader.addEventListener('load', (ev) => {
                    resolve(ev.target.result);
                });
                reader.addEventListener('error', reject);
                reader.readAsDataURL(file);
            }));
        }))
        .then(images => {
            const formData = new FormData();
            formData.append("files", files[0]);
            console.log(formData);
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    },
            };
            const url = '/rest/common/kdeal/save/orgImg';
            http
            .post(url, formData, config)
            .then(res => {
                console.log(res.data);
                if (res.data != null && res.data != '') {
                    this.setState({
                        [image_id] : res.data["kdeal_org_img_seq"],
                        [image_id+"_name"] : files[0].name,
                    }, () => {
                        if(image_id == "detail_info_image"){
                            $("#"+image_id).attr("src",images[0]);
                        }
                        // _image를 뗴고 보내기 위해 substr 사용
                        this.onChangeEventFormContent(image_id.substr(0,image_id.length-6));
                    });

                }
            })
            .catch(error => {
             });
        }, error => {
            console.error(error);
        });
    }

    // 이미지 업로드 팝업 : 파일첨부 열기
    handleClickBtnFileUpload(e, file_input_id){ 
        e.preventDefault ();
        $("#"+file_input_id).click();	
    };

    // 이미지 업로드 팝업 : 업로드 이벤트
    _handleImageChange = (e, image_id) => {
        // this.attachImageDetailInfo(e.target.files);
        this.uploadOrgImg(e.target.files, image_id);
    };

    // 이미지 삭제
    onClickRemoveImg(image_id){
        if(image_id == "detail_info_image"){
            $("#"+image_id).attr("src","");
        }
        this.setState({
            [image_id] : "",
            [image_id+"_name"] : "",
        }, () => {
            this.onChangeEventFormContent(image_id.substr(0,image_id.length-6));
        });
    }

    // textarea 내용 변경시
    onKeyUpTextarea(e, objectName, type){
        this.setState({
            [e.target.name+"_line"] : e.target.value,
        }, () => {
            // 입력한 textarea 높이 변경
            var sTextarea = document.getElementById(e.target.id);
            sTextarea.style.height = "48px"; 
            sTextarea.style.height = sTextarea.scrollHeight + 'px';

            // object 길이
            var areaHeight= $("#"+objectName+"_area").innerHeight();
            $("#"+objectName+"_image_object").css('max-height',areaHeight);

            // blur가 아닌 경우에만 변경된 내용을 저장하도록 한다
            if(type != "blur"){
                this.onChangeEventFormContent(objectName);
            }
        });
    }

    // 이벤트 폼의 내용이 변경되었을 경우
    onChangeEventFormContent(event_id){
        const content_list = [];
        let tmp_event_seq;
        let event_type_cd;
        if(event_id == "intro"){
            tmp_event_seq = this.state.tmp_event_seq_list[0];
            event_type_cd = "0100100001";

            const content1 = {};
            content1["contents"] = this.state.intro_first_line;
            content1["content_type_cd"] = "44001";
            content_list.push(content1);

            const content2 = {};
            content2["contents"] = this.state.intro_second_line;
            content2["content_type_cd"] = "44001";
            content_list.push(content2);

            const content3 = {};
            content3["contents"] = this.state.intro_third_line;
            content3["content_type_cd"] = "44001";
            content_list.push(content3);

            const content4 = {};
            content4["kdeal_org_img_seq"] = this.state.intro_image;
            content4["content_type_cd"] = "44002";
            content_list.push(content4);

        } else if(event_id == "detail_one"){
            tmp_event_seq = this.state.tmp_event_seq_list[1];
            event_type_cd = "0100100002";

            const content1 = {};
            content1["contents"] = this.state.detail_one_first_line;
            content1["content_type_cd"] = "44001";
            content_list.push(content1);

            const content2 = {};
            content2["contents"] = this.state.detail_one_second_line;
            content2["content_type_cd"] = "44001";
            content_list.push(content2);

            const content3 = {};
            content3["contents"] = this.state.detail_one_third_line;
            content3["content_type_cd"] = "44001";
            content_list.push(content3);

            const content4 = {};
            content4["kdeal_org_img_seq"] = this.state.detail_one_image;
            content4["content_type_cd"] = "44002";
            content_list.push(content4);

        } else if(event_id == "detail_two"){
            tmp_event_seq = this.state.tmp_event_seq_list[2];
            event_type_cd = "0100100002";

            const content1 = {};
            content1["contents"] = this.state.detail_two_first_line;
            content1["content_type_cd"] = "44001";
            content_list.push(content1);

            const content2 = {};
            content2["contents"] = this.state.detail_two_second_line;
            content2["content_type_cd"] = "44001";
            content_list.push(content2);

            const content3 = {};
            content3["contents"] = this.state.detail_two_third_line;
            content3["content_type_cd"] = "44001";
            content_list.push(content3);

            const content4 = {};
            content4["kdeal_org_img_seq"] = this.state.detail_two_image;
            content4["content_type_cd"] = "44002";
            content_list.push(content4);

        } else if(event_id == "detail_three"){
            tmp_event_seq = this.state.tmp_event_seq_list[3];
            event_type_cd = "0100100002";

            const content1 = {};
            content1["contents"] = this.state.detail_three_first_line;
            content1["content_type_cd"] = "44001";
            content_list.push(content1);

            const content2 = {};
            content2["contents"] = this.state.detail_three_second_line;
            content2["content_type_cd"] = "44001";
            content_list.push(content2);

            const content3 = {};
            content3["contents"] = this.state.detail_three_third_line;
            content3["content_type_cd"] = "44001";
            content_list.push(content3);

            const content4 = {};
            content4["kdeal_org_img_seq"] = this.state.detail_three_image;
            content4["content_type_cd"] = "44002";
            content_list.push(content4);
            
        } else if(event_id == "detail_info"){
            tmp_event_seq = this.state.tmp_event_seq_list[4];
            event_type_cd = "0100100003";

            const content1 = {};
            content1["kdeal_org_img_seq"] = this.state.detail_info_image;
            content1["content_type_cd"] = "44002";
            content_list.push(content1);
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
                },
        };
        const url = '/rest/common/kdeal/content/event/tmp/save';
        const body = {
            content_list : content_list,
            tmp_event_seq : tmp_event_seq,
            event_type_cd : event_type_cd,
        }
        http
        .post(url, body, config)
		.then(res => {
            console.log(res.data);
			if (res.data != null && res.data != '') {
			}
            const object_link = this.state[event_id+"_image_object"];

            // 새로고침
            $("#"+event_id+"_image_object").attr('data',object_link);
		})
		.catch(error => {
            alert(error);
		 });
    }

    // 저장 버튼 클릭
    onClickSaveForm(){
        const event_list = [];
        let content_list = [];

        let event1 = {};
        let event2 = {};
        let event3 = {};
        let event4 = {};
        let event5 = {};

        let content1 = {};
        let content2 = {};
        let content3 = {};
        let content4 = {};


        const event_list_org = this.state.event_list;
        let product_no_state = false;
        if(this.state.product_no != ""){
            product_no_state = true;
        }

        content1["contents"] = this.state.intro_first_line;
        content1["content_type_cd"] = "44001";
        if(product_no_state){
            content1["content_seq"] = event_list_org[0]["content_list"][0]["content_seq"];
        }
        content_list.push(content1);

        content2["contents"] = this.state.intro_second_line;
        content2["content_type_cd"] = "44001";
        if(product_no_state){
            content2["content_seq"] = event_list_org[0]["content_list"][1]["content_seq"];
        }
        content_list.push(content2);

        content3["contents"] = this.state.intro_third_line;
        content3["content_type_cd"] = "44001";
        if(product_no_state){
            content3["content_seq"] = event_list_org[0]["content_list"][2]["content_seq"];
        }
        content_list.push(content3);

        content4["kdeal_org_img_seq"] = this.state.intro_image;
        content4["img_name"] = this.state.intro_image_name;
        content4["content_type_cd"] = "44002";
        if(product_no_state){
            content4["content_seq"] = event_list_org[0]["content_list"][3]["content_seq"];
        }
        content_list.push(content4);

        event1["event_type_cd"] = "0100100001";
        event1["content_list"] = [...content_list];
        if(product_no_state){
            event1["event_seq"] = event_list_org[0]["content_list"][0]["event_seq"];
        }
        event_list.push(event1);
        content_list = [];
        content1 = {};
        content2 = {};
        content3 = {};
        content4 = {};


        content1["contents"] = this.state.detail_one_first_line;
        content1["content_type_cd"] = "44001";
        if(product_no_state){
            content1["content_seq"] = event_list_org[1]["content_list"][0]["content_seq"];
        }
        content_list.push(content1);

        content2["contents"] = this.state.detail_one_second_line;
        content2["content_type_cd"] = "44001";
        if(product_no_state){
            content2["content_seq"] = event_list_org[1]["content_list"][1]["content_seq"];
        }
        content_list.push(content2);

        content3["contents"] = this.state.detail_one_third_line;
        content3["content_type_cd"] = "44001";
        if(product_no_state){
            content3["content_seq"] = event_list_org[1]["content_list"][2]["content_seq"];
        }
        content_list.push(content3);

        content4["kdeal_org_img_seq"] = this.state.detail_one_image;
        content4["img_name"] = this.state.detail_one_image_name;
        content4["content_type_cd"] = "44002";
        if(product_no_state){
            content4["content_seq"] = event_list_org[1]["content_list"][3]["content_seq"];
        }
        content_list.push(content4);

        event2["event_type_cd"] = "0100100002";
        event2["content_list"] = [...content_list];
        if(product_no_state){
            event2["event_seq"] = event_list_org[1]["content_list"][0]["event_seq"];
        }
        event_list.push(event2);
        content_list = [];
        content1 = {};
        content2 = {};
        content3 = {};
        content4 = {};


        content1["contents"] = this.state.detail_two_first_line;
        content1["content_type_cd"] = "44001";
        if(product_no_state){
            content1["content_seq"] = event_list_org[2]["content_list"][0]["content_seq"];
        }
        content_list.push(content1);

        content2["contents"] = this.state.detail_two_second_line;
        content2["content_type_cd"] = "44001";
        if(product_no_state){
            content2["content_seq"] = event_list_org[2]["content_list"][1]["content_seq"];
        }
        content_list.push(content2);

        content3["contents"] = this.state.detail_two_third_line;
        content3["content_type_cd"] = "44001";
        if(product_no_state){
            content3["content_seq"] = event_list_org[2]["content_list"][2]["content_seq"];
        }
        content_list.push(content3);

        content4["kdeal_org_img_seq"] = this.state.detail_two_image;
        content4["img_name"] = this.state.detail_two_image_name;
        content4["content_type_cd"] = "44002";
        if(product_no_state){
            content4["content_seq"] = event_list_org[2]["content_list"][3]["content_seq"];
        }
        content_list.push(content4);

        event3["event_type_cd"] = "0100100002";
        event3["content_list"] = [...content_list];
        if(product_no_state){
            event3["event_seq"] = event_list_org[2]["content_list"][0]["event_seq"];
        }
        event_list.push(event3);
        content_list = [];
        content1 = {};
        content2 = {};
        content3 = {};
        content4 = {};

        content1["contents"] = this.state.detail_three_first_line;
        content1["content_type_cd"] = "44001";
        if(product_no_state){
            content1["content_seq"] = event_list_org[3]["content_list"][0]["content_seq"];
        }
        content_list.push(content1);

        content2["contents"] = this.state.detail_three_second_line;
        content2["content_type_cd"] = "44001";
        if(product_no_state){
            content2["content_seq"] = event_list_org[3]["content_list"][1]["content_seq"];
        }
        content_list.push(content2);

        content3["contents"] = this.state.detail_three_third_line;
        content3["content_type_cd"] = "44001";
        if(product_no_state){
            content3["content_seq"] = event_list_org[3]["content_list"][2]["content_seq"];
        }
        content_list.push(content3);

        content4["kdeal_org_img_seq"] = this.state.detail_three_image;
        content4["img_name"] = this.state.detail_three_image_name;
        content4["content_type_cd"] = "44002";
        if(product_no_state){
            content4["content_seq"] = event_list_org[3]["content_list"][3]["content_seq"];
        }
        content_list.push(content4);

        event4["event_type_cd"] = "0100100002";
        event4["content_list"] = [...content_list];
        if(product_no_state){
            event4["event_seq"] = event_list_org[3]["content_list"][0]["event_seq"];
        }
        event_list.push(event4);
        content_list = [];
        content1 = {};
        content2 = {};
        content3 = {};
        content4 = {};
        
        content1["kdeal_org_img_seq"] = this.state.detail_info_image;
        content1["img_name"] = this.state.detail_info_image_name;
        content1["content_type_cd"] = "44002";
        if(product_no_state){
            content1["content_seq"] = event_list_org[4]["content_list"][0]["content_seq"];
        }
        content_list.push(content1);

        event5["event_type_cd"] = "0100100003";
        event5["content_list"] = [...content_list];
        if(product_no_state){
            event5["event_seq"] = event_list_org[4]["content_list"][0]["event_seq"];
        }
        event_list.push(event5);
        content_list = [];
        content1 = {};
        content2 = {};
        content3 = {};
        content4 = {};

        const config = {
            headers: {
                'Content-Type': 'application/json',
                },
        };
        const url = '/rest/common/kdeal/product/event/save';
        const body = {
            event_list : event_list,
            // null로 검사하기 때문에 ""로 보내면 수정으로 인식
            product_no : product_no_state? this.state.product_no : null,
        }
        http
        .post(url, body, config)
		.then(res => {
            console.log(res.data);
			if (res.data != null && res.data != '') {
                this.setState({
                    product_no : res.data["product_no"],
                    event_list : res.data["event_list"],
                }, () => {
                    if(this.getUrlParams() == null || this.getUrlParams() == ""){
                        window.history.replaceState("","",window.location.href+"?product_no="+this.state.product_no);
                    }
                });
                alert("저장하였습니다.")
			}
		})
		.catch(error => {
		 });
    }

    // 취소 버튼 클릭
    onClickCancelBtn(){
        if (window.confirm("작업 내용을 취소 하시겠습니까?")) {
            $("#detail_info_image").attr("src","");
    
            if(this.state.product_no != ""){
                this.loadProduct();
            } else{
                this.setState({
                    intro_first_line : "",
                    intro_second_line : "",
                    intro_third_line : "",
                    intro_image : "",
                    intro_image_name : "",
        
                    detail_one_first_line : "",
                    detail_one_second_line : "",
                    detail_one_third_line : "",
                    detail_one_image : "",
                    detail_one_image_name : "",
        
                    detail_two_first_line : "",
                    detail_two_second_line : "",
                    detail_two_third_line : "",
                    detail_two_image : "",
                    detail_two_image_name : "",
        
                    detail_three_first_line : "",
                    detail_three_second_line : "",
                    detail_three_third_line : "",
                    detail_three_image : "",
                    detail_three_image_name : "",
        
                    detail_info_image : "",
                    detail_info_image_name : "",
                }, () => {

                    setTimeout(() => {
                        // 포커스를 강제로 주어, textarea 내용에 맞게 오른쪽 영역도 변동되도록 한다
                        let window_scrollTop = $(window).scrollTop();
                        $("#intro_first_textarea").focus();
                        $("#intro_second_textarea").focus();
                        $("#intro_third_textarea").focus();

                        $("#detail_one_first_textarea").focus();
                        $("#detail_one_second_textarea").focus();
                        $("#detail_one_third_textarea").focus();

                        $("#detail_two_first_textarea").focus();
                        $("#detail_two_second_textarea").focus();
                        $("#detail_two_third_textarea").focus();

                        $("#detail_three_first_textarea").focus();
                        $("#detail_three_second_textarea").focus();
                        $("#detail_three_third_textarea").focus();

                        $(':focus').blur();

                        // 초기의 스크롤 위치로 스크롤 이동
                        $("html,body").scrollTop(window_scrollTop);

                    }, 200);
    
                    this.onChangeEventFormContent("intro");
                    this.onChangeEventFormContent("detail_one");
                    this.onChangeEventFormContent("detail_two");
                    this.onChangeEventFormContent("detail_three");
                    this.onChangeEventFormContent("detail_info");
                });
            }
        }

    }

    // 이미지 다운로드 클릭
    onClickContentDownloadBtn() {
        this.setState({
            is_downloaded: false,
        })
        const config = {
            headers: {
                'content-type': 'application/json;'
            },
        }
        
        http.post("/rest/common/kdeal/content/download_bak",
            {
                "product_no" : this.state.tmp_product_no,
                "banner_width" : "860",
                "view_type_cd" : "1"
            },
            config
        )
        .then(res => {
            this.setState({
                is_downloaded: true,
            })

            if (res.data.code == "200") {
                const fileName = res.data.fileName;
                const fileUrl = res.data.fileUrl;
                let a = document.createElement('a');

                a.href = fileUrl;
                a.download = fileName;
                a.click();
            } else {
                alert(res.data.code + "\n" + res.data.msg);
            }
        })
        .catch(error => {
            console.log (error);
            this.setState({
                is_downloaded: false,
            })
        });
    }

    

    render() {
        return (
            <>
                <div id='sosang-page-wrapper' style={{minHeight:"100%"}}>
                    <div id='admin_wrap' class="admin_wrap" style={{overflowX:"auto"}}>
                        <div class="admin_page" style={{padding:"0 0 0 0"}}>
                            <div className="sosang_form_and_reselt" style={{marginBotton:"24px", marginTop:"40px"}}>
                                <div style={{width:"713px", marginRight:"40px", fontWeight:"500", color: "#3A3A3A", flex:"none"}}>내용 입력</div>
                                <div style={{flex:"none", fontWeight:"500", color: "#3A3A3A"}}>미리보기</div>
                            </div>
                            <div className="sosang_form_and_reselt">
                                <div id="intro_area" className="sosang_form_area">
                                    <div className="sosang_form_title_area">
                                        <h2 className="sosang_form_title_text">인트로</h2>
                                    </div>
                                    <div className="sosang_form_line_area">
                                        <h3 className="sosang_form_span_text">첫 줄</h3>
                                        <textarea id="intro_first_textarea" name="intro_first" className="sosang_form_textarea" onChange={e => this.onKeyUpTextarea(e,"intro")}
                                        onBlur={e => this.onKeyUpTextarea(e,"intro", "blur")} value={this.state.intro_first_line} placeholder="내용을 입력해 주세요." />
                                    </div>
                                    <div className="sosang_form_line_area">
                                        <h3 className="sosang_form_span_text">두번째 줄</h3>
                                        <textarea id="intro_second_textarea" name="intro_second" className="sosang_form_textarea" onChange={e => this.onKeyUpTextarea(e,"intro")}
                                        onBlur={e => this.onKeyUpTextarea(e,"intro", "blur")} value={this.state.intro_second_line} placeholder="내용을 입력해 주세요." />
                                    </div>
                                    <div className="sosang_form_line_area">
                                        <h3 className="sosang_form_span_text">세번째 줄</h3>
                                        <textarea id="intro_third_textarea" name="intro_third" className="sosang_form_textarea" onChange={e => this.onKeyUpTextarea(e,"intro")}
                                        onBlur={e => this.onKeyUpTextarea(e,"intro", "blur")} value={this.state.intro_third_line} placeholder="내용을 입력해 주세요." />
                                    </div>
                                    <h3 className="sosang_form_span_text">이미지 업로드</h3>
                                    <div className="sosang_form_line_area">
                                        <input type="file" id="introFileInput" onChange={e => this._handleImageChange(e, "intro_image")} accept="image/jpg,image/png,image/jpeg,image/gif,image/bmp,image/tiff" value="" style={{display:"none"}}></input>
                                        <button className="sosang_select_file_btn" onClick={e => this.handleClickBtnFileUpload(e, "introFileInput")}>파일 선택</button>
                                        <input className="sosang_select_file_input" id="intro_image_name" placeholder="파일을 선택해 주세요." value={this.state.intro_image_name} readOnly></input>
                                        {this.state.intro_image != ""
                                        ?<svg className="sosang_select_file_input_x_btn" onClick={() => {this.onClickRemoveImg("intro_image")}} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 0.875C7.39303 0.875 5.82214 1.35152 4.486 2.24431C3.14985 3.1371 2.10844 4.40605 1.49348 5.8907C0.87852 7.37535 0.717618 9.00901 1.03112 10.5851C1.34463 12.1612 2.11846 13.6089 3.25476 14.7452C4.39106 15.8815 5.8388 16.6554 7.4149 16.9689C8.99099 17.2824 10.6247 17.1215 12.1093 16.5065C13.594 15.8916 14.8629 14.8502 15.7557 13.514C16.6485 12.1779 17.125 10.607 17.125 9C17.1209 6.84638 16.2635 4.78216 14.7407 3.25932C13.2178 1.73648 11.1536 0.87913 9 0.875ZM11.9453 11.0547C12.0627 11.1732 12.1285 11.3332 12.1285 11.5C12.1285 11.6668 12.0627 11.8268 11.9453 11.9453C11.8259 12.0608 11.6662 12.1254 11.5 12.1254C11.3338 12.1254 11.1742 12.0608 11.0547 11.9453L9 9.88281L6.94532 11.9453C6.82585 12.0608 6.66618 12.1254 6.5 12.1254C6.33383 12.1254 6.17415 12.0608 6.05469 11.9453C5.93733 11.8268 5.87149 11.6668 5.87149 11.5C5.87149 11.3332 5.93733 11.1732 6.05469 11.0547L8.11719 9L6.05469 6.94531C5.95503 6.82388 5.90409 6.66971 5.9118 6.51281C5.91951 6.3559 5.9853 6.20747 6.09639 6.09638C6.20747 5.9853 6.3559 5.9195 6.51281 5.9118C6.66972 5.90409 6.82388 5.95502 6.94532 6.05469L9 8.11719L11.0547 6.05469C11.1761 5.95502 11.3303 5.90409 11.4872 5.9118C11.6441 5.9195 11.7925 5.9853 11.9036 6.09638C12.0147 6.20747 12.0805 6.3559 12.0882 6.51281C12.0959 6.66971 12.045 6.82388 11.9453 6.94531L9.88282 9L11.9453 11.0547Z" fill="#D1D1D1"/>
                                        </svg>
                                        :""}
                                    </div>
                                </div>
                                <object className="sosang_image_object_area" id="intro_image_object" type="text/html" data="">
                                </object>
                            </div>

                            <div className="sosang_form_and_reselt">
                                <div id="detail_one_area" className="sosang_form_area">
                                    <div className="sosang_form_title_area">
                                        <h2 className="sosang_form_title_text">상세 내용 1</h2>
                                    </div>
                                    <div className="sosang_form_line_area">
                                        <h3 className="sosang_form_span_text">첫 줄</h3>
                                        <textarea id="detail_one_first_textarea" name="detail_one_first" className="sosang_form_textarea" onChange={e => this.onKeyUpTextarea(e,"detail_one")}
                                        onBlur={e => this.onKeyUpTextarea(e,"detail_one", "blur")} value={this.state.detail_one_first_line} placeholder="내용을 입력해 주세요." />
                                    </div>
                                    <div className="sosang_form_line_area">
                                        <h3 className="sosang_form_span_text">두번째 줄</h3>
                                        <textarea id="detail_one_second_textarea" name="detail_one_second" className="sosang_form_textarea" onChange={e => this.onKeyUpTextarea(e,"detail_one")}
                                        onBlur={e => this.onKeyUpTextarea(e,"detail_one", "blur")} value={this.state.detail_one_second_line} placeholder="내용을 입력해 주세요." />
                                    </div>
                                    <div className="sosang_form_line_area">
                                        <h3 className="sosang_form_span_text">세번째 줄</h3>
                                        <textarea id="detail_one_third_textarea" name="detail_one_third" className="sosang_form_textarea" onChange={e => this.onKeyUpTextarea(e,"detail_one")}
                                        onBlur={e => this.onKeyUpTextarea(e,"detail_one", "blur")} value={this.state.detail_one_third_line} placeholder="내용을 입력해 주세요." />
                                    </div>
                                    <h3 className="sosang_form_span_text">이미지 업로드</h3>
                                    <div className="sosang_form_line_area">
                                        <input type="file" id="detailOneFileInput" onChange={e => this._handleImageChange(e, "detail_one_image")} accept="image/jpg,image/png,image/jpeg,image/gif,image/bmp,image/tiff" value="" style={{display:"none"}}></input>
                                        <button className="sosang_select_file_btn" onClick={e => this.handleClickBtnFileUpload(e, "detailOneFileInput")}>파일 선택</button>
                                        <input className="sosang_select_file_input" id="detail_one_image_name" value={this.state.detail_one_image_name} placeholder="파일을 선택해 주세요." readOnly></input>
                                        {this.state.detail_one_image != ""
                                        ?<svg className="sosang_select_file_input_x_btn" onClick={() => {this.onClickRemoveImg("detail_one_image")}} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 0.875C7.39303 0.875 5.82214 1.35152 4.486 2.24431C3.14985 3.1371 2.10844 4.40605 1.49348 5.8907C0.87852 7.37535 0.717618 9.00901 1.03112 10.5851C1.34463 12.1612 2.11846 13.6089 3.25476 14.7452C4.39106 15.8815 5.8388 16.6554 7.4149 16.9689C8.99099 17.2824 10.6247 17.1215 12.1093 16.5065C13.594 15.8916 14.8629 14.8502 15.7557 13.514C16.6485 12.1779 17.125 10.607 17.125 9C17.1209 6.84638 16.2635 4.78216 14.7407 3.25932C13.2178 1.73648 11.1536 0.87913 9 0.875ZM11.9453 11.0547C12.0627 11.1732 12.1285 11.3332 12.1285 11.5C12.1285 11.6668 12.0627 11.8268 11.9453 11.9453C11.8259 12.0608 11.6662 12.1254 11.5 12.1254C11.3338 12.1254 11.1742 12.0608 11.0547 11.9453L9 9.88281L6.94532 11.9453C6.82585 12.0608 6.66618 12.1254 6.5 12.1254C6.33383 12.1254 6.17415 12.0608 6.05469 11.9453C5.93733 11.8268 5.87149 11.6668 5.87149 11.5C5.87149 11.3332 5.93733 11.1732 6.05469 11.0547L8.11719 9L6.05469 6.94531C5.95503 6.82388 5.90409 6.66971 5.9118 6.51281C5.91951 6.3559 5.9853 6.20747 6.09639 6.09638C6.20747 5.9853 6.3559 5.9195 6.51281 5.9118C6.66972 5.90409 6.82388 5.95502 6.94532 6.05469L9 8.11719L11.0547 6.05469C11.1761 5.95502 11.3303 5.90409 11.4872 5.9118C11.6441 5.9195 11.7925 5.9853 11.9036 6.09638C12.0147 6.20747 12.0805 6.3559 12.0882 6.51281C12.0959 6.66971 12.045 6.82388 11.9453 6.94531L9.88282 9L11.9453 11.0547Z" fill="#D1D1D1"/>
                                        </svg>
                                        :""}
                                    </div>
                                </div>
                                <object className="sosang_image_object_area" id="detail_one_image_object" type="text/html" data="">
                                </object>
                            </div>

                            <div className="sosang_form_and_reselt">
                                <div id="detail_two_area" className="sosang_form_area">
                                    <div className="sosang_form_title_area">
                                        <h2 className="sosang_form_title_text">상세 내용 2</h2>
                                    </div>
                                    <div className="sosang_form_line_area">
                                        <h3 className="sosang_form_span_text">첫 줄</h3>
                                        <textarea id="detail_two_first_textarea" name="detail_two_first" className="sosang_form_textarea" onChange={e => this.onKeyUpTextarea(e,"detail_two")}
                                        onBlur={e => this.onKeyUpTextarea(e,"detail_two", "blur")} value={this.state.detail_two_first_line} placeholder="내용을 입력해 주세요." />
                                    </div>
                                    <div className="sosang_form_line_area">
                                        <h3 className="sosang_form_span_text">두번째 줄</h3>
                                        <textarea id="detail_two_second_textarea" name="detail_two_second" className="sosang_form_textarea" onChange={e => this.onKeyUpTextarea(e,"detail_two")}
                                        onBlur={e => this.onKeyUpTextarea(e,"detail_two", "blur")} value={this.state.detail_two_second_line} placeholder="내용을 입력해 주세요." />
                                    </div>
                                    <div className="sosang_form_line_area">
                                        <h3 className="sosang_form_span_text">세번째 줄</h3>
                                        <textarea id="detail_two_third_textarea" name="detail_two_third" className="sosang_form_textarea" onChange={e => this.onKeyUpTextarea(e,"detail_two")}
                                        onBlur={e => this.onKeyUpTextarea(e,"detail_two", "blur")} value={this.state.detail_two_third_line} placeholder="내용을 입력해 주세요." />
                                    </div>
                                    <h3 className="sosang_form_span_text">이미지 업로드</h3>
                                    <div className="sosang_form_line_area">
                                        <input type="file" id="detailTwoFileInput" onChange={e => this._handleImageChange(e, "detail_two_image")} accept="image/jpg,image/png,image/jpeg,image/gif,image/bmp,image/tiff" value="" style={{display:"none"}}></input>
                                        <button className="sosang_select_file_btn" onClick={e => this.handleClickBtnFileUpload(e, "detailTwoFileInput")}>파일 선택</button>
                                        <input className="sosang_select_file_input" id="detail_two_image_name" value={this.state.detail_two_image_name} placeholder="파일을 선택해 주세요." readOnly></input>
                                        {this.state.detail_two_image != ""
                                        ?<svg className="sosang_select_file_input_x_btn" onClick={() => {this.onClickRemoveImg("detail_two_image")}} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 0.875C7.39303 0.875 5.82214 1.35152 4.486 2.24431C3.14985 3.1371 2.10844 4.40605 1.49348 5.8907C0.87852 7.37535 0.717618 9.00901 1.03112 10.5851C1.34463 12.1612 2.11846 13.6089 3.25476 14.7452C4.39106 15.8815 5.8388 16.6554 7.4149 16.9689C8.99099 17.2824 10.6247 17.1215 12.1093 16.5065C13.594 15.8916 14.8629 14.8502 15.7557 13.514C16.6485 12.1779 17.125 10.607 17.125 9C17.1209 6.84638 16.2635 4.78216 14.7407 3.25932C13.2178 1.73648 11.1536 0.87913 9 0.875ZM11.9453 11.0547C12.0627 11.1732 12.1285 11.3332 12.1285 11.5C12.1285 11.6668 12.0627 11.8268 11.9453 11.9453C11.8259 12.0608 11.6662 12.1254 11.5 12.1254C11.3338 12.1254 11.1742 12.0608 11.0547 11.9453L9 9.88281L6.94532 11.9453C6.82585 12.0608 6.66618 12.1254 6.5 12.1254C6.33383 12.1254 6.17415 12.0608 6.05469 11.9453C5.93733 11.8268 5.87149 11.6668 5.87149 11.5C5.87149 11.3332 5.93733 11.1732 6.05469 11.0547L8.11719 9L6.05469 6.94531C5.95503 6.82388 5.90409 6.66971 5.9118 6.51281C5.91951 6.3559 5.9853 6.20747 6.09639 6.09638C6.20747 5.9853 6.3559 5.9195 6.51281 5.9118C6.66972 5.90409 6.82388 5.95502 6.94532 6.05469L9 8.11719L11.0547 6.05469C11.1761 5.95502 11.3303 5.90409 11.4872 5.9118C11.6441 5.9195 11.7925 5.9853 11.9036 6.09638C12.0147 6.20747 12.0805 6.3559 12.0882 6.51281C12.0959 6.66971 12.045 6.82388 11.9453 6.94531L9.88282 9L11.9453 11.0547Z" fill="#D1D1D1"/>
                                        </svg>
                                        :""}
                                    </div>
                                </div>
                                <object className="sosang_image_object_area" id="detail_two_image_object" type="text/html" data="">
                                </object>
                            </div>

                            <div className="sosang_form_and_reselt">
                                <div id="detail_three_area" className="sosang_form_area">
                                    <div className="sosang_form_title_area">
                                        <h2 className="sosang_form_title_text">상세 내용 3</h2>
                                    </div>
                                    <div className="sosang_form_line_area">
                                        <h3 className="sosang_form_span_text">첫 줄</h3>
                                        <textarea id="detail_three_first_textarea" name="detail_three_first" className="sosang_form_textarea" onChange={e => this.onKeyUpTextarea(e,"detail_three")}
                                        onBlur={e => this.onKeyUpTextarea(e,"detail_three", "blur")} value={this.state.detail_three_first_line} placeholder="내용을 입력해 주세요." />
                                    </div>
                                    <div className="sosang_form_line_area">
                                        <h3 className="sosang_form_span_text">두번째 줄</h3>
                                        <textarea id="detail_three_second_textarea" name="detail_three_second" className="sosang_form_textarea" onChange={e => this.onKeyUpTextarea(e,"detail_three")}
                                        onBlur={e => this.onKeyUpTextarea(e,"detail_three", "blur")} value={this.state.detail_three_second_line} placeholder="내용을 입력해 주세요." />
                                    </div>
                                    <div className="sosang_form_line_area">
                                        <h3 className="sosang_form_span_text">세번째 줄</h3>
                                        <textarea id="detail_three_third_textarea" name="detail_three_third" className="sosang_form_textarea" onChange={e => this.onKeyUpTextarea(e,"detail_three")}
                                        onBlur={e => this.onKeyUpTextarea(e,"detail_three", "blur")} value={this.state.detail_three_third_line} placeholder="내용을 입력해 주세요." />
                                    </div>
                                    <h3 className="sosang_form_span_text">이미지 업로드</h3>
                                    <div className="sosang_form_line_area">
                                        <input type="file" id="detailThreeFileInput" onChange={e => this._handleImageChange(e, "detail_three_image")} accept="image/jpg,image/png,image/jpeg,image/gif,image/bmp,image/tiff" value="" style={{display:"none"}}></input>
                                        <button className="sosang_select_file_btn" onClick={e => this.handleClickBtnFileUpload(e, "detailThreeFileInput")}>파일 선택</button>
                                        <input className="sosang_select_file_input" id="detail_three_image_name" value={this.state.detail_three_image_name} placeholder="파일을 선택해 주세요." readOnly></input>
                                        {this.state.detail_three_image != ""
                                        ?<svg className="sosang_select_file_input_x_btn" onClick={() => {this.onClickRemoveImg("detail_three_image")}} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 0.875C7.39303 0.875 5.82214 1.35152 4.486 2.24431C3.14985 3.1371 2.10844 4.40605 1.49348 5.8907C0.87852 7.37535 0.717618 9.00901 1.03112 10.5851C1.34463 12.1612 2.11846 13.6089 3.25476 14.7452C4.39106 15.8815 5.8388 16.6554 7.4149 16.9689C8.99099 17.2824 10.6247 17.1215 12.1093 16.5065C13.594 15.8916 14.8629 14.8502 15.7557 13.514C16.6485 12.1779 17.125 10.607 17.125 9C17.1209 6.84638 16.2635 4.78216 14.7407 3.25932C13.2178 1.73648 11.1536 0.87913 9 0.875ZM11.9453 11.0547C12.0627 11.1732 12.1285 11.3332 12.1285 11.5C12.1285 11.6668 12.0627 11.8268 11.9453 11.9453C11.8259 12.0608 11.6662 12.1254 11.5 12.1254C11.3338 12.1254 11.1742 12.0608 11.0547 11.9453L9 9.88281L6.94532 11.9453C6.82585 12.0608 6.66618 12.1254 6.5 12.1254C6.33383 12.1254 6.17415 12.0608 6.05469 11.9453C5.93733 11.8268 5.87149 11.6668 5.87149 11.5C5.87149 11.3332 5.93733 11.1732 6.05469 11.0547L8.11719 9L6.05469 6.94531C5.95503 6.82388 5.90409 6.66971 5.9118 6.51281C5.91951 6.3559 5.9853 6.20747 6.09639 6.09638C6.20747 5.9853 6.3559 5.9195 6.51281 5.9118C6.66972 5.90409 6.82388 5.95502 6.94532 6.05469L9 8.11719L11.0547 6.05469C11.1761 5.95502 11.3303 5.90409 11.4872 5.9118C11.6441 5.9195 11.7925 5.9853 11.9036 6.09638C12.0147 6.20747 12.0805 6.3559 12.0882 6.51281C12.0959 6.66971 12.045 6.82388 11.9453 6.94531L9.88282 9L11.9453 11.0547Z" fill="#D1D1D1"/>
                                        </svg>
                                        :""}
                                    </div>
                                </div>
                                <object className="sosang_image_object_area" id="detail_three_image_object" type="text/html" data="">
                                </object>
                            </div>
                            
                            <div className="sosang_form_and_reselt">
                                <div className="sosang_form_area" style={{maxHeight: "180px"}}>
                                    <div className="sosang_form_title_area">
                                        <h2 className="sosang_form_title_text">상품 정보</h2>
                                    </div>
                                    <h3 className="sosang_form_span_text">이미지 업로드</h3>
                                    <div className="sosang_form_line_area">
                                        <input type="file" id="detailInfoFileInput" onChange={e => this._handleImageChange(e, "detail_info_image")} accept="image/jpg,image/png,image/jpeg,image/gif,image/bmp,image/tiff" value="" style={{display:"none"}}></input>
                                        <button className="sosang_select_file_btn" onClick={e => this.handleClickBtnFileUpload(e, "detailInfoFileInput")}>파일 선택</button>
                                        <input className="sosang_select_file_input" id="detail_info_image_name" value={this.state.detail_info_image_name} placeholder="파일을 선택해 주세요." readOnly></input>
                                        {this.state.detail_info_image != ""
                                        ?<svg className="sosang_select_file_input_x_btn" onClick={() => {this.onClickRemoveImg("detail_info_image")}} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 0.875C7.39303 0.875 5.82214 1.35152 4.486 2.24431C3.14985 3.1371 2.10844 4.40605 1.49348 5.8907C0.87852 7.37535 0.717618 9.00901 1.03112 10.5851C1.34463 12.1612 2.11846 13.6089 3.25476 14.7452C4.39106 15.8815 5.8388 16.6554 7.4149 16.9689C8.99099 17.2824 10.6247 17.1215 12.1093 16.5065C13.594 15.8916 14.8629 14.8502 15.7557 13.514C16.6485 12.1779 17.125 10.607 17.125 9C17.1209 6.84638 16.2635 4.78216 14.7407 3.25932C13.2178 1.73648 11.1536 0.87913 9 0.875ZM11.9453 11.0547C12.0627 11.1732 12.1285 11.3332 12.1285 11.5C12.1285 11.6668 12.0627 11.8268 11.9453 11.9453C11.8259 12.0608 11.6662 12.1254 11.5 12.1254C11.3338 12.1254 11.1742 12.0608 11.0547 11.9453L9 9.88281L6.94532 11.9453C6.82585 12.0608 6.66618 12.1254 6.5 12.1254C6.33383 12.1254 6.17415 12.0608 6.05469 11.9453C5.93733 11.8268 5.87149 11.6668 5.87149 11.5C5.87149 11.3332 5.93733 11.1732 6.05469 11.0547L8.11719 9L6.05469 6.94531C5.95503 6.82388 5.90409 6.66971 5.9118 6.51281C5.91951 6.3559 5.9853 6.20747 6.09639 6.09638C6.20747 5.9853 6.3559 5.9195 6.51281 5.9118C6.66972 5.90409 6.82388 5.95502 6.94532 6.05469L9 8.11719L11.0547 6.05469C11.1761 5.95502 11.3303 5.90409 11.4872 5.9118C11.6441 5.9195 11.7925 5.9853 11.9036 6.09638C12.0147 6.20747 12.0805 6.3559 12.0882 6.51281C12.0959 6.66971 12.045 6.82388 11.9453 6.94531L9.88282 9L11.9453 11.0547Z" fill="#D1D1D1"/>
                                        </svg>
                                        :""}
                                    </div>
                                </div>
                                <div className="sosang_detail_info_image_area">
                                    <img id="detail_info_image" style={{width: "446px"}}/>
                                </div>
                            </div>

                            <div id="footer_line" style={{border: "0.4px solid #ACACAC", minWidth:"1300px"}}></div>
                            <div id="footer" style={{paddingTop:"32px", background:"#FFFFFF", minWidth:"1300px"}}>
                                <div className="sosang_form_and_reselt">
                                    <button className="sosang_form_cancel_btn" style={{color:"#3A3A3A"}} onClick={e => this.onClickCancelBtn()}>취소</button>
                                    <button className="sosang_form_save_btn" style={{marginRight:"784px", color:"#3A3A3A"}} onClick={e => this.onClickSaveForm()}>저장</button>
                                    {
                                        this.state.is_downloaded
                                        ?
                                            <button className="sosang_form_download_btn" onClick={e => this.onClickContentDownloadBtn()}>
                                                <img src={download_img}/>&nbsp;이미지 다운로드
                                            </button>
                                        :
                                            <button className="sosang_form_download_btn" style={{width:"155px"}}>
                                                다운로드 중...
                                            </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    };
    
}
import React from "react";

// 마우스 이벤트 등록
let isDrawing = false;
let brushRadius = 10;
// 브러시 크기 설정
var brushSize = 10;
var mouseX = 0, mouseY = 0;

var canvas = null;
var nukkiImage = new Image();
var ctx = null;

/**
 * nukkiImage : 누끼 이미지 URL
 */
export class NukkiEditor extends React.Component {
    state = {

    }

    componentDidMount() {
      var url1 = '/rest/common/backImageLoad?img_url=https://s3.ap-northeast-2.amazonaws.com/otdeal.file/robo_banner/2023/03/30/133/93497498-2c88-4cd5-af0a-a125a415a90c.png';

      if (this.props.nukkiImage != null) {
        url1 = '/rest/common/backImageLoad?img_url='+this.props.nukkiImage;
      }

      // Canvas 생성
      canvas = document.getElementById('origin_canvas');
      ctx = canvas.getContext('2d');

      nukkiImage.src = url1;

      let this_component= this;
      canvas.addEventListener('mousedown', function (e){ this_component.startDrawing(e, ctx) });
      canvas.addEventListener('mousemove', function (e){ this_component.draw(e, ctx) });
      canvas.addEventListener('mouseup', function (e){ this_component.stopDrawing(e) });

      nukkiImage.onload = function() {
        // Canvas 크기 설정
        canvas.width = nukkiImage.width;
        canvas.height = nukkiImage.height;
  
        // 원본 이미지 그리기
        ctx.drawImage(nukkiImage, 0, 0);
      };
    }

    startDrawing(e, ctx) {
      isDrawing = true;
      mouseX = e.offsetX;
      mouseY = e.offsetY;
      this.draw(e, ctx);
    }

    stopDrawing() {
      isDrawing = false;
    }

    draw(e, ctx) {
      if (!isDrawing) return;

      // 이전 위치와 현재 위치 비교
      var prevX = mouseX, prevY = mouseY;
      mouseX = e.offsetX;
      mouseY = e.offsetY;

      // 브러시 그리기
      var dist = this.distance(prevX, prevY, mouseX, mouseY);
      var angle = this.angleBetween(prevX, prevY, mouseX, mouseY);
      for (var i = 0; i < dist; i++) {
        var x = prevX + Math.sin(angle) * i;
        var y = prevY + Math.cos(angle) * i;
        ctx.globalCompositeOperation = 'destination-out';
        ctx.save();
        ctx.beginPath();
        ctx.arc(x, y, brushSize/2, 0, Math.PI*2);
        ctx.closePath();
        ctx.clip();
        ctx.stroke();
        ctx.restore();
      }
    }

    // 새로운 이미지 저장
    saveImage() {
      alert("이미지 저장")

      const link = document.createElement('a');
      link.download = 'new-image.png';
      link.href = canvas.toDataURL('image/png');
      link.click();
    }

    // 거리 계산 함수
    distance(x1, y1, x2, y2) {
      var dx = x2 - x1;
      var dy = y2 - y1;
      return Math.sqrt(dx*dx + dy*dy);
    }

    // 각도 계산 함수
    angleBetween(x1, y1, x2, y2) {
      return Math.atan2(x2 - x1, y2 - y1);
    }

    render () {
      return (
        <>
          <canvas style={{border: "1px solid black"}} id="origin_canvas"></canvas>
          <br/>
          <button onClick={e => this.saveImage()}>Save Image</button>
        </>
      );
	}
}

export default NukkiEditor;


import React from 'react';
import $ from 'jquery';
import Pagination from "react-js-pagination";
import http from '../modules/api';

import BasicDialogV2 from '../modules/dialogs/BasicDialogV2';
import LineChart from '../modules/charts/LineChart';
import DonutChart from '../modules/charts/DonutChart';
import * as StringUtil from '../modules/utils/StringUtil';
import * as StyleUtil from '../modules/utils/StyleUtil';
import * as CurationServiceVariable from '../modules/CurationServiceVariable';

let window_scrollTop; // 스크롤 위치

export class CurationStatus extends React.Component {

    constructor(props) {
		super(props);
		this.state = {

            /** 차트에 추가된 큐레이션 */
            target_curations : [],
            /** 타겟 큐레이션으로 추출된 분석 리포트1 */
            progress_stat : [],
            /** 타겟 큐레이션으로 추출된 분석 리포트2 */
            action_stat : [],

            /** 큐레이션 리스트 */
            curation_list : [],
            /** 큐레이션 전체 수 */
            curation_total_cnt : 0,
            /** 큐레이션 페이징의 페이지 번호 */
            curation_active_page: 1,
            /** 큐레이션 페이징시 큐레이션 시작 수 */
            curation_start_idx: 0,
            /** 큐레이션 페이징시 화면에 나올 큐레이션 수 */
		    curation_row_cnt: 5,
            /** 큐레이션 리스트 조회 여부 */
            get_curation_list: false,

            /** 상품 반응 현황 리스트 */
            product_list : [],
            /** 상품 반응 현황 전체 수 */
            product_total_cnt : 0,
            /** 상품 반응 현황 페이징의 페이지 번호 */
            product_active_page: 1,
            /** 상품 반응 현황 페이징시 목록 시작 수 */
            product_start_idx: 0,
            /** 상품 반응 현황 페이징시 화면에 나올 목록 수 */
		    product_row_cnt: 5,
            /** 상품 반응 현황 리스트 조회 여부 */
            get_product_list: false,

            /** 공유 현황 리스트 */
            share_list : [],
            /** 공유 현황 전체 수 */
            share_total_cnt : 0,
            /** 공유 현황 페이징의 페이지 번호 */
            share_active_page: 1,
            /** 공유 현황 페이징시 목록 시작 수 */
            share_start_idx: 0,
            /** 공유 현황 페이징시 화면에 나올 목록 수 */
		    share_row_cnt: 5,
            /** 공유 현황 리스트 조회 여부 */
            get_share_list: false,

            /** 기본 다이얼로그 */
            basic_dialog_show_state : false,
            basic_dialog_content: "",
            basic_dialog_button_list: [],
        }
    }

    componentWillMount(){
        this.loadCurationList(true);
        this.loadProductList();
        this.loadShareList();
    }

    componentDidMount(){
        
    }

    componentDidUpdate(){

    }

    /** 큐레이션 목록 조회
     * @param {*} target_setting 차트에 추가될 큐레이션 첫 세팅 (페이지 들어올때 한번 사용)
    */
    async loadCurationList(target_setting){
        await http.get(
                        "/rest/shop/curations"
                        +"?start_idx="+this.state.curation_start_idx
                        +"&row_cnt="+this.state.curation_row_cnt
                    )
		.then(res => {
			if (res.data.code == "200") {
                let curation_list = res.data.curation_list;

                // 첫 세팅때 차트에 큐레이션 리스트 추가
                if(target_setting){
                    let target_curations = [];
                    for(var i=0;i<curation_list.length;i++){
                        target_curations.push(curation_list[i].curation_seq);
                        curation_list[i].target_curation_yn = "Y";
                    }
                    this.setState({
                        target_curations : target_curations,
                        curation_total_cnt : res.data.total_cnt,
                        curation_list : curation_list,
                        get_curation_list : true,
                    }, () => {
                        this.loadReport1();
                        this.loadReport2();
                    })
                } else{
                    const {target_curations} = this.state;
                    for(var i=0;i<curation_list.length;i++){
                        if(target_curations.indexOf(curation_list[i].curation_seq) != -1){
                            curation_list[i].target_curation_yn = "Y";
                        } else{
                            curation_list[i].target_curation_yn = "N";
                        }
                    }
                    this.setState({
                        curation_total_cnt : res.data.total_cnt,
                        curation_list : curation_list,
                        get_curation_list : true,
                    });
                }
			} else{
                alert(CurationServiceVariable.CURATION+" "+CurationServiceVariable.LIST+" 조회에 실패하였습니다.")
            }
		})
		.catch(error => {
		 });
    }

    /** 큐레이션 목록 페이지 변경 */
    curationListPageChange(pageNumber) {

		this.setState({
            curation_active_page: pageNumber,
            curation_start_idx : (pageNumber-1) * this.state.curation_row_cnt,
        }, () => {
            this.loadCurationList();
        });
    }

    /** 진행수 (분석 리포트1) 조회
    */
    async loadReport1(){
        if(this.state.target_curations.length > 0){
            await http.get(
                            "/rest/shop/curation/statistics/progress"
                            +"?target_curation="+encodeURI(this.state.target_curations)
                        )
            .then(res => {
                if (res.data.code == "200") {
                    let progress_stat = res.data.progress_stat;
                    this.setState({
                        progress_stat : progress_stat,
                    })
                } else{
                    alert(CurationServiceVariable.REPORT+" 조회에 실패하였습니다.");
                }
            })
            .catch(error => {
             });
        } else{
            this.setState({
                progress_stat : [],
            })
        }
    }

    /** 클릭률 (분석 리포트2) 조회
    */
     async loadReport2(){
        if(this.state.target_curations.length > 0){
            await http.get(
                            "/rest/shop/curation/statistics/action"
                            +"?target_curation="+encodeURI(this.state.target_curations)
                        )
            .then(res => {
                if (res.data.code == "200") {
                    let action_stat = res.data.action_stat;
                    for(var i=0;i<action_stat.length;i++){
                        action_stat[i].action_rate = StringUtil.safeNumberDecimal(action_stat[i].action_rate * 100);
                    }
                    this.setState({
                        action_stat : action_stat,
                    })
                } else{
                    alert(CurationServiceVariable.REPORT+" 조회에 실패하였습니다.");
                }
            })
            .catch(error => {
             });
        } else{
            this.setState({
                action_stat : [],
            })
        }
    }

    /** 차트 타겟 큐레이션 추가
     * @param {*} curation_seq 추가할 큐레이션의 seq
     */
    addTargetCuration(idx, curation_seq){
        let {target_curations, curation_list} = this.state;
        if(target_curations.length >= 5){
            window_scrollTop = StyleUtil.onPopupNoneScroll();

            this.setState({
                basic_dialog_show_state: true,
                basic_dialog_content: "큐레이션 분석은 최대 5건 까지만<br/>지정 가능해요.",
                basic_dialog_button_list: [
                    {
                        title: "확인",
                        type: "1",
                        event_method: () => {
                            //팝업 닫기
                            this.setState({ basic_dialog_show_state: false });
                            // 스크롤 고정 해제
                            StyleUtil.clearPopupNoneScroll(window_scrollTop);
                        }
                    }
                ]
            });
            return false;
        }

        target_curations.push(curation_seq);
        curation_list[idx].target_curation_yn = "Y";

        this.setState({
            target_curations: target_curations,
            curation_list: curation_list,
        }, () => {
            this.loadReport1();
            this.loadReport2();
        });
    }

    /** 차트 타겟 큐레이션 제거
     * @param {*} curation_seq 제거할 큐레이션의 seq
     */
    delTargetCuration(idx, curation_seq){
        let {target_curations, curation_list} = this.state;

        const del_idx = target_curations.indexOf(curation_seq);
        target_curations.splice(del_idx, 1);
        curation_list[idx].target_curation_yn = "N";

        this.setState({
            target_curations: target_curations,
            curation_list: curation_list,
        }, () => {
            this.loadReport1();
            this.loadReport2();
        });
    }

    /** 상품 반응 현황 목록 조회
    */
    async loadProductList(){
        await http.get(
                        "/rest/shop/curation/statistics/product"
                        +"?start_idx="+this.state.product_start_idx
                        +"&row_cnt="+this.state.product_row_cnt
                    )
		.then(res => {
			if (res.data.code == "200") {
                this.setState({
                    product_total_cnt : res.data.total_cnt,
                    product_list : res.data.product_list,
                    get_product_list : true,
                });
			} else{
                alert(CurationServiceVariable.CURATION+" 상품 "+CurationServiceVariable.REACT+" "+CurationServiceVariable.STATUS+" "+CurationServiceVariable.LIST+" 조회에 실패하였습니다.")
            }
		})
		.catch(error => {
		 });
    }

    /** 상품 반응 현황 목록 페이지 변경 */
    productListPageChange(pageNumber) {

		this.setState({
            product_active_page: pageNumber,
            product_start_idx : (pageNumber-1) * this.state.product_row_cnt,
        }, () => {
            this.loadProductList();
        });
    }

    /** 공유 현황 목록 조회
    */
     async loadShareList(){
        await http.get(
                        "/rest/shop/curation/statistics/share"
                        +"?start_idx="+this.state.share_start_idx
                        +"&row_cnt="+this.state.share_row_cnt
                    )
		.then(res => {
			if (res.data.code == "200") {
                this.setState({
                    share_total_cnt : res.data.total_cnt,
                    share_list : res.data.share_list,
                    get_share_list : true,
                });
			} else{
                alert(CurationServiceVariable.CURATION+" "+CurationServiceVariable.SHARE+" "+CurationServiceVariable.REACT+" "+CurationServiceVariable.LIST+" 조회에 실패하였습니다.")
            }
		})
		.catch(error => {
		 });
    }

    /** 공유 현황 목록 페이지 변경 */
    shareListPageChange(pageNumber) {

		this.setState({
            share_active_page: pageNumber,
            share_start_idx : (pageNumber-1) * this.state.share_row_cnt,
        }, () => {
            this.loadShareList();
        });
    }

    render() {
        return (
            <>
                <script type="text/javascript" src="https://www.gstatic.com/charts/loader.js"></script>
                <div id='common-page-wrapper'>
                    <div id="wrap">
                        <section class="quration_main style_guide_v2" id="quration_main">
                            <h2 class="quration_main_title text_20">{CurationServiceVariable.CURATION+" "+CurationServiceVariable.STATUS}</h2>
                            <div class="quration_main_report_wrap">
                                <div class="quration_main_report">
                                    <div class="quration_main_report_title">
                                        <h3 class="text_16">{CurationServiceVariable.REPORT+" 1"}</h3>
                                    </div>
                                    <DonutChart
                                        list={this.state.progress_stat}
                                        listValueName={"result_cnt"}
                                        listTextName={"curation_title"}
                                        title={"참여 비중 비교"}
                                    />
                                </div>
                                <div class="quration_main_report quration_main_report_2">
                                    <div class="quration_main_report_title">
                                        <h3 class="text_16">{CurationServiceVariable.REPORT+" 2"}</h3>
                                    </div>
                                    <LineChart
                                        list={this.state.action_stat}
                                        listValueName={"action_rate"}
                                        listTextName={"curation_title"}
                                        listIdName={"curation_seq"}
                                        title={"상품 클릭률"}
                                    />
                                </div>
                            </div>

                            <div class="quration_main_list_wrap">
                                <div class="quration_main_list quration_main_list_1">
                                    <h2 class="text_16">큐레이션 목록</h2>
                                    <div class="quration_main_table_wrap">
                                        <table class="quration_main_table quration_main_table_1">
                                            <thead>
                                                <tr>
                                                    <th>제목</th>
                                                    <th>참여수</th>
                                                    <th>{CurationServiceVariable.REPORT+" 추가"}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.curation_list.length > 0
                                                ?
                                                    this.state.curation_list.map((obj, idx) => (
                                                    <>
                                                        <tr>
                                                            <td><span>{obj.curation_title}</span></td>
                                                            <td>{StringUtil.numberComma(obj.result_cnt)}</td>
                                                            <td>
                                                                <input class="report_check_btn" type="checkbox"/>
                                                                <label>
                                                                    {obj.target_curation_yn == "N"
                                                                    ?<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => this.addTargetCuration(idx, obj.curation_seq)}>
                                                                        <path d="M17.5 10C17.5 10.1658 17.4342 10.3247 17.3169 10.4419C17.1997 10.5592 17.0408 10.625 16.875 10.625H10.625V16.875C10.625 17.0408 10.5592 17.1997 10.4419 17.3169C10.3247 17.4342 10.1658 17.5 10 17.5C9.83424 17.5 9.67527 17.4342 9.55806 17.3169C9.44085 17.1997 9.375 17.0408 9.375 16.875V10.625H3.125C2.95924 10.625 2.80027 10.5592 2.68306 10.4419C2.56585 10.3247 2.5 10.1658 2.5 10C2.5 9.83424 2.56585 9.67527 2.68306 9.55806C2.80027 9.44085 2.95924 9.375 3.125 9.375H9.375V3.125C9.375 2.95924 9.44085 2.80027 9.55806 2.68306C9.67527 2.56585 9.83424 2.5 10 2.5C10.1658 2.5 10.3247 2.56585 10.4419 2.68306C10.5592 2.80027 10.625 2.95924 10.625 3.125V9.375H16.875C17.0408 9.375 17.1997 9.44085 17.3169 9.55806C17.4342 9.67527 17.5 9.83424 17.5 10Z" fill="#333333"/>
                                                                    </svg>
                                                                    :<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => this.delTargetCuration(idx, obj.curation_seq)}>
                                                                        <path d="M16.875 10.625H3.125C2.95924 10.625 2.80027 10.5592 2.68306 10.4419C2.56585 10.3247 2.5 10.1658 2.5 10C2.5 9.83424 2.56585 9.67527 2.68306 9.55806C2.80027 9.44085 2.95924 9.375 3.125 9.375H16.875C17.0408 9.375 17.1997 9.44085 17.3169 9.55806C17.4342 9.67527 17.5 9.83424 17.5 10C17.5 10.1658 17.4342 10.3247 17.3169 10.4419C17.1997 10.5592 17.0408 10.625 16.875 10.625Z" fill="#333333"/>
                                                                    </svg>}
                                                                </label>
                                                            </td>
                                                        </tr>
                                                    </>
                                                    ))
                                                : this.state.get_curation_list
                                                    ?<div class='quration_list_table_empty quration_main_table_empty'>생성된 {CurationServiceVariable.CURATION} {CurationServiceVariable.INFO}가 없습니다.<br/>{CurationServiceVariable.CURATION}을 생성해주세요.</div>
                                                    : ""}
                                            </tbody>
                                        </table>
                                    </div>
                                    {this.state.curation_list.length > 0
                                    ? <div class="common_pagination">
                                        <Pagination
                                            firstPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.35627 13.3562C8.26147 13.4501 8.13344 13.5028 8.00002 13.5028C7.8666 13.5028 7.73857 13.4501 7.64377 13.3562L2.64377 8.35623C2.55136 8.26066 2.4997 8.13292 2.4997 7.99998C2.4997 7.86704 2.55136 7.7393 2.64377 7.64373L7.64377 2.64373C7.74092 2.564 7.86425 2.52325 7.98977 2.52942C8.1153 2.53558 8.23405 2.58822 8.32291 2.67709C8.41178 2.76595 8.46442 2.8847 8.47058 3.01023C8.47675 3.13575 8.436 3.25908 8.35627 3.35623L3.70627 7.99998L8.35627 12.6437C8.45016 12.7385 8.50283 12.8666 8.50283 13C8.50283 13.1334 8.45016 13.2614 8.35627 13.3562ZM7.64377 7.64373C7.55136 7.7393 7.4997 7.86704 7.4997 7.99998C7.4997 8.13292 7.55136 8.26066 7.64377 8.35623L12.6438 13.3562C12.7409 13.436 12.8643 13.4767 12.9898 13.4705C13.1153 13.4644 13.234 13.4117 13.3229 13.3229C13.4118 13.234 13.4644 13.1153 13.4706 12.9897C13.4767 12.8642 13.436 12.7409 13.3563 12.6437L8.70627 7.99998L13.3563 3.35623C13.436 3.25908 13.4767 3.13575 13.4706 3.01023C13.4644 2.8847 13.4118 2.76595 13.3229 2.67709C13.234 2.58822 13.1153 2.53558 12.9898 2.52942C12.8642 2.52325 12.7409 2.564 12.6438 2.64373L7.64377 7.64373Z" fill="#8B8B8B"/>
                                            </svg>}
                                            prevPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.50002 7.99998C4.50182 7.86746 4.55309 7.74039 4.64377 7.64373L9.64377 2.64373C9.74092 2.564 9.86425 2.52325 9.98977 2.52942C10.1153 2.53558 10.234 2.58822 10.3229 2.67709C10.4118 2.76595 10.4644 2.8847 10.4706 3.01023C10.4767 3.13575 10.436 3.25908 10.3563 3.35623L5.70627 7.99998L10.3563 12.6437C10.436 12.7409 10.4767 12.8642 10.4706 12.9897C10.4644 13.1153 10.4118 13.234 10.3229 13.3229C10.234 13.4117 10.1153 13.4644 9.98977 13.4705C9.86425 13.4767 9.74092 13.436 9.64377 13.3562L4.64377 8.35623C4.55309 8.25957 4.50182 8.1325 4.50002 7.99998Z" fill="#8B8B8B"/>
                                            </svg>}
                                            nextPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.5 8.00002C11.4982 8.13254 11.4469 8.25961 11.3562 8.35627L6.35623 13.3563C6.25908 13.436 6.13575 13.4767 6.01023 13.4706C5.8847 13.4644 5.76595 13.4118 5.67709 13.3229C5.58822 13.234 5.53558 13.1153 5.52942 12.9898C5.52325 12.8642 5.564 12.7409 5.64373 12.6438L10.2937 8.00002L5.64373 3.35627C5.564 3.25912 5.52325 3.13579 5.52942 3.01026C5.53558 2.88474 5.58822 2.76599 5.67709 2.67712C5.76596 2.58826 5.8847 2.53562 6.01023 2.52946C6.13575 2.52329 6.25908 2.56404 6.35623 2.64377L11.3562 7.64377C11.4469 7.74043 11.4982 7.8675 11.5 8.00002Z" fill="#8B8B8B"/>
                                            </svg>}
                                            lastPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.64373 2.64377C7.73853 2.54988 7.86656 2.49721 7.99998 2.49721C8.1334 2.49721 8.26143 2.54988 8.35623 2.64377L13.3562 7.64377C13.4486 7.73934 13.5003 7.86708 13.5003 8.00002C13.5003 8.13296 13.4486 8.2607 13.3562 8.35627L8.35623 13.3563C8.25908 13.436 8.13575 13.4767 8.01023 13.4706C7.8847 13.4644 7.76595 13.4118 7.67709 13.3229C7.58822 13.234 7.53558 13.1153 7.52942 12.9898C7.52325 12.8642 7.564 12.7409 7.64373 12.6438L12.2937 8.00002L7.64373 3.35627C7.54984 3.26147 7.49717 3.13344 7.49717 3.00002C7.49717 2.8666 7.54984 2.73857 7.64373 2.64377ZM8.35623 8.35627C8.44864 8.2607 8.5003 8.13296 8.5003 8.00002C8.5003 7.86708 8.44864 7.73934 8.35623 7.64377L3.35623 2.64377C3.25908 2.56404 3.13575 2.52329 3.01022 2.52946C2.8847 2.53562 2.76595 2.58826 2.67709 2.67712C2.58822 2.76599 2.53558 2.88474 2.52942 3.01026C2.52325 3.13579 2.564 3.25912 2.64373 3.35627L7.29373 8.00002L2.64373 12.6438C2.564 12.7409 2.52325 12.8642 2.52942 12.9898C2.53558 13.1153 2.58822 13.234 2.67709 13.3229C2.76595 13.4118 2.8847 13.4644 3.01023 13.4706C3.13575 13.4767 3.25908 13.436 3.35623 13.3563L8.35623 8.35627Z" fill="#8B8B8B"/>
                                            </svg>}
                                            activePage={this.state.curation_active_page}
                                            itemsCountPerPage={this.state.curation_row_cnt}
                                            totalItemsCount={this.state.curation_total_cnt}
                                            pageRangeDisplayed={5}
                                            onChange={this.curationListPageChange.bind(this)}
                                        />
                                    </div>
                                    : ""}
                                </div>
                                <div class="quration_main_list quration_main_list_2">
                                    <h2 class="text_16">{"상품 "+CurationServiceVariable.REACT+" "+CurationServiceVariable.STATUS}</h2>
                                    <div class="quration_main_table_wrap">
                                        <table class="quration_main_table quration_main_table_2">
                                            <thead>
                                                <tr>
                                                    <th>{CurationServiceVariable.CURATION} 제목</th>
                                                    <th>상품명</th>
                                                    <th>조회수</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.product_list.length > 0
                                            ? this.state.product_list.map((obj, idx) => (
                                                <tr>
                                                    <td><span>{obj.curation_title}</span></td>
                                                    <td><span>{obj.product_name}</span></td>
                                                    <td>{StringUtil.numberComma(obj.t_hit_cnt)}</td>
                                                </tr>
                                            ))
                                            : this.state.get_product_list
                                                ?<div class='quration_list_table_empty quration_main_table_empty'>{CurationServiceVariable.CURATION+" "+CurationServiceVariable.REACT+" 내역이 없습니다"}</div>
                                                :""}
                                            </tbody>
                                        </table>
                                    </div>
                                    {this.state.product_list.length > 0
                                    ?<div class="common_pagination">
                                        <Pagination
                                            firstPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.35627 13.3562C8.26147 13.4501 8.13344 13.5028 8.00002 13.5028C7.8666 13.5028 7.73857 13.4501 7.64377 13.3562L2.64377 8.35623C2.55136 8.26066 2.4997 8.13292 2.4997 7.99998C2.4997 7.86704 2.55136 7.7393 2.64377 7.64373L7.64377 2.64373C7.74092 2.564 7.86425 2.52325 7.98977 2.52942C8.1153 2.53558 8.23405 2.58822 8.32291 2.67709C8.41178 2.76595 8.46442 2.8847 8.47058 3.01023C8.47675 3.13575 8.436 3.25908 8.35627 3.35623L3.70627 7.99998L8.35627 12.6437C8.45016 12.7385 8.50283 12.8666 8.50283 13C8.50283 13.1334 8.45016 13.2614 8.35627 13.3562ZM7.64377 7.64373C7.55136 7.7393 7.4997 7.86704 7.4997 7.99998C7.4997 8.13292 7.55136 8.26066 7.64377 8.35623L12.6438 13.3562C12.7409 13.436 12.8643 13.4767 12.9898 13.4705C13.1153 13.4644 13.234 13.4117 13.3229 13.3229C13.4118 13.234 13.4644 13.1153 13.4706 12.9897C13.4767 12.8642 13.436 12.7409 13.3563 12.6437L8.70627 7.99998L13.3563 3.35623C13.436 3.25908 13.4767 3.13575 13.4706 3.01023C13.4644 2.8847 13.4118 2.76595 13.3229 2.67709C13.234 2.58822 13.1153 2.53558 12.9898 2.52942C12.8642 2.52325 12.7409 2.564 12.6438 2.64373L7.64377 7.64373Z" fill="#8B8B8B"/>
                                            </svg>}
                                            prevPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.50002 7.99998C4.50182 7.86746 4.55309 7.74039 4.64377 7.64373L9.64377 2.64373C9.74092 2.564 9.86425 2.52325 9.98977 2.52942C10.1153 2.53558 10.234 2.58822 10.3229 2.67709C10.4118 2.76595 10.4644 2.8847 10.4706 3.01023C10.4767 3.13575 10.436 3.25908 10.3563 3.35623L5.70627 7.99998L10.3563 12.6437C10.436 12.7409 10.4767 12.8642 10.4706 12.9897C10.4644 13.1153 10.4118 13.234 10.3229 13.3229C10.234 13.4117 10.1153 13.4644 9.98977 13.4705C9.86425 13.4767 9.74092 13.436 9.64377 13.3562L4.64377 8.35623C4.55309 8.25957 4.50182 8.1325 4.50002 7.99998Z" fill="#8B8B8B"/>
                                            </svg>}
                                            nextPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.5 8.00002C11.4982 8.13254 11.4469 8.25961 11.3562 8.35627L6.35623 13.3563C6.25908 13.436 6.13575 13.4767 6.01023 13.4706C5.8847 13.4644 5.76595 13.4118 5.67709 13.3229C5.58822 13.234 5.53558 13.1153 5.52942 12.9898C5.52325 12.8642 5.564 12.7409 5.64373 12.6438L10.2937 8.00002L5.64373 3.35627C5.564 3.25912 5.52325 3.13579 5.52942 3.01026C5.53558 2.88474 5.58822 2.76599 5.67709 2.67712C5.76596 2.58826 5.8847 2.53562 6.01023 2.52946C6.13575 2.52329 6.25908 2.56404 6.35623 2.64377L11.3562 7.64377C11.4469 7.74043 11.4982 7.8675 11.5 8.00002Z" fill="#8B8B8B"/>
                                            </svg>}
                                            lastPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.64373 2.64377C7.73853 2.54988 7.86656 2.49721 7.99998 2.49721C8.1334 2.49721 8.26143 2.54988 8.35623 2.64377L13.3562 7.64377C13.4486 7.73934 13.5003 7.86708 13.5003 8.00002C13.5003 8.13296 13.4486 8.2607 13.3562 8.35627L8.35623 13.3563C8.25908 13.436 8.13575 13.4767 8.01023 13.4706C7.8847 13.4644 7.76595 13.4118 7.67709 13.3229C7.58822 13.234 7.53558 13.1153 7.52942 12.9898C7.52325 12.8642 7.564 12.7409 7.64373 12.6438L12.2937 8.00002L7.64373 3.35627C7.54984 3.26147 7.49717 3.13344 7.49717 3.00002C7.49717 2.8666 7.54984 2.73857 7.64373 2.64377ZM8.35623 8.35627C8.44864 8.2607 8.5003 8.13296 8.5003 8.00002C8.5003 7.86708 8.44864 7.73934 8.35623 7.64377L3.35623 2.64377C3.25908 2.56404 3.13575 2.52329 3.01022 2.52946C2.8847 2.53562 2.76595 2.58826 2.67709 2.67712C2.58822 2.76599 2.53558 2.88474 2.52942 3.01026C2.52325 3.13579 2.564 3.25912 2.64373 3.35627L7.29373 8.00002L2.64373 12.6438C2.564 12.7409 2.52325 12.8642 2.52942 12.9898C2.53558 13.1153 2.58822 13.234 2.67709 13.3229C2.76595 13.4118 2.8847 13.4644 3.01023 13.4706C3.13575 13.4767 3.25908 13.436 3.35623 13.3563L8.35623 8.35627Z" fill="#8B8B8B"/>
                                            </svg>}
                                            activePage={this.state.product_active_page}
                                            itemsCountPerPage={this.state.product_row_cnt}
                                            totalItemsCount={this.state.product_total_cnt}
                                            pageRangeDisplayed={5}
                                            onChange={this.productListPageChange.bind(this)}
                                        />
                                    </div>
                                    : ""}
                                </div>
                                <div class="quration_main_list quration_main_list_3">
                                    <h2 class="text_16">{CurationServiceVariable.CURATION+" "+CurationServiceVariable.SHARE+" "+CurationServiceVariable.STATUS}</h2>
                                    <div class="quration_main_table_wrap">
                                        <table class="quration_main_table quration_main_table_3">
                                            <thead>
                                                <tr>
                                                    <th>{CurationServiceVariable.CURATION} 제목</th>
                                                    <th>{CurationServiceVariable.SHARE}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.share_list.length > 0
                                            ? this.state.share_list.map((obj, idx) => (
                                                <tr>
                                                    <td><span>{obj.curation_title}</span></td>
                                                    <td>{StringUtil.safeNumberDecimal(obj.share_rate * 100,1) + "%"}</td>
                                                </tr>
                                            ))
                                            : this.state.get_share_list
                                                ?<div class='quration_list_table_empty quration_main_table_empty'>{CurationServiceVariable.CURATION+" "+CurationServiceVariable.SHARE+" 내역이 없습니다."}</div>
                                                :""}
                                            </tbody>
                                        </table>
                                    </div>
                                    {this.state.share_list.length > 0
                                    ?<div class="common_pagination">
                                        <Pagination
                                            firstPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.35627 13.3562C8.26147 13.4501 8.13344 13.5028 8.00002 13.5028C7.8666 13.5028 7.73857 13.4501 7.64377 13.3562L2.64377 8.35623C2.55136 8.26066 2.4997 8.13292 2.4997 7.99998C2.4997 7.86704 2.55136 7.7393 2.64377 7.64373L7.64377 2.64373C7.74092 2.564 7.86425 2.52325 7.98977 2.52942C8.1153 2.53558 8.23405 2.58822 8.32291 2.67709C8.41178 2.76595 8.46442 2.8847 8.47058 3.01023C8.47675 3.13575 8.436 3.25908 8.35627 3.35623L3.70627 7.99998L8.35627 12.6437C8.45016 12.7385 8.50283 12.8666 8.50283 13C8.50283 13.1334 8.45016 13.2614 8.35627 13.3562ZM7.64377 7.64373C7.55136 7.7393 7.4997 7.86704 7.4997 7.99998C7.4997 8.13292 7.55136 8.26066 7.64377 8.35623L12.6438 13.3562C12.7409 13.436 12.8643 13.4767 12.9898 13.4705C13.1153 13.4644 13.234 13.4117 13.3229 13.3229C13.4118 13.234 13.4644 13.1153 13.4706 12.9897C13.4767 12.8642 13.436 12.7409 13.3563 12.6437L8.70627 7.99998L13.3563 3.35623C13.436 3.25908 13.4767 3.13575 13.4706 3.01023C13.4644 2.8847 13.4118 2.76595 13.3229 2.67709C13.234 2.58822 13.1153 2.53558 12.9898 2.52942C12.8642 2.52325 12.7409 2.564 12.6438 2.64373L7.64377 7.64373Z" fill="#8B8B8B"/>
                                            </svg>}
                                            prevPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.50002 7.99998C4.50182 7.86746 4.55309 7.74039 4.64377 7.64373L9.64377 2.64373C9.74092 2.564 9.86425 2.52325 9.98977 2.52942C10.1153 2.53558 10.234 2.58822 10.3229 2.67709C10.4118 2.76595 10.4644 2.8847 10.4706 3.01023C10.4767 3.13575 10.436 3.25908 10.3563 3.35623L5.70627 7.99998L10.3563 12.6437C10.436 12.7409 10.4767 12.8642 10.4706 12.9897C10.4644 13.1153 10.4118 13.234 10.3229 13.3229C10.234 13.4117 10.1153 13.4644 9.98977 13.4705C9.86425 13.4767 9.74092 13.436 9.64377 13.3562L4.64377 8.35623C4.55309 8.25957 4.50182 8.1325 4.50002 7.99998Z" fill="#8B8B8B"/>
                                            </svg>}
                                            nextPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.5 8.00002C11.4982 8.13254 11.4469 8.25961 11.3562 8.35627L6.35623 13.3563C6.25908 13.436 6.13575 13.4767 6.01023 13.4706C5.8847 13.4644 5.76595 13.4118 5.67709 13.3229C5.58822 13.234 5.53558 13.1153 5.52942 12.9898C5.52325 12.8642 5.564 12.7409 5.64373 12.6438L10.2937 8.00002L5.64373 3.35627C5.564 3.25912 5.52325 3.13579 5.52942 3.01026C5.53558 2.88474 5.58822 2.76599 5.67709 2.67712C5.76596 2.58826 5.8847 2.53562 6.01023 2.52946C6.13575 2.52329 6.25908 2.56404 6.35623 2.64377L11.3562 7.64377C11.4469 7.74043 11.4982 7.8675 11.5 8.00002Z" fill="#8B8B8B"/>
                                            </svg>}
                                            lastPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.64373 2.64377C7.73853 2.54988 7.86656 2.49721 7.99998 2.49721C8.1334 2.49721 8.26143 2.54988 8.35623 2.64377L13.3562 7.64377C13.4486 7.73934 13.5003 7.86708 13.5003 8.00002C13.5003 8.13296 13.4486 8.2607 13.3562 8.35627L8.35623 13.3563C8.25908 13.436 8.13575 13.4767 8.01023 13.4706C7.8847 13.4644 7.76595 13.4118 7.67709 13.3229C7.58822 13.234 7.53558 13.1153 7.52942 12.9898C7.52325 12.8642 7.564 12.7409 7.64373 12.6438L12.2937 8.00002L7.64373 3.35627C7.54984 3.26147 7.49717 3.13344 7.49717 3.00002C7.49717 2.8666 7.54984 2.73857 7.64373 2.64377ZM8.35623 8.35627C8.44864 8.2607 8.5003 8.13296 8.5003 8.00002C8.5003 7.86708 8.44864 7.73934 8.35623 7.64377L3.35623 2.64377C3.25908 2.56404 3.13575 2.52329 3.01022 2.52946C2.8847 2.53562 2.76595 2.58826 2.67709 2.67712C2.58822 2.76599 2.53558 2.88474 2.52942 3.01026C2.52325 3.13579 2.564 3.25912 2.64373 3.35627L7.29373 8.00002L2.64373 12.6438C2.564 12.7409 2.52325 12.8642 2.52942 12.9898C2.53558 13.1153 2.58822 13.234 2.67709 13.3229C2.76595 13.4118 2.8847 13.4644 3.01023 13.4706C3.13575 13.4767 3.25908 13.436 3.35623 13.3563L8.35623 8.35627Z" fill="#8B8B8B"/>
                                            </svg>}
                                            activePage={this.state.share_active_page}
                                            itemsCountPerPage={this.state.share_row_cnt}
                                            totalItemsCount={this.state.share_total_cnt}
                                            pageRangeDisplayed={5}
                                            onChange={this.shareListPageChange.bind(this)}
                                        />
                                    </div>
                                    : ""}
                                </div>
                            </div>
                        </section>
                        {/* 기본 안내 다이얼로그 */
                            (this.state.basic_dialog_show_state === true)
                            ? <BasicDialogV2
                                content={this.state.basic_dialog_content}
                                button_list={this.state.basic_dialog_button_list}
                            />
                            : ''
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default CurationStatus;
import React from 'react';
import http from '../modules/api';
import {NavLink} from "react-router-dom";
import $ from 'jquery';
import * as Menu from '../Menu_v2'

http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export class RpEventSetting extends React.Component {
	state = {
			event_setting_list: [],
			orderGubun: "",
			sorting: "",
			currentPage: "",
			start_date: "",
			end_date: "",
			start_date_format: "",
			end_date_format: "",
			title: "",
			activePage: 1,
			totalCnt: 0,
			startIdx: 0,
			rowcnt:10,
			type:""
	};

	componentDidMount(e) {
		this.getEventSettingList();
	}

	componentDidUpdate(e) {
		// console.log("######### componentDidUpdate ##########")
	}

	onComponentChange = e => {
		// console.log("######### onComponentChange ##########")
	}

	componentWillUnmount = e => {
		// console.log("######### componentWillUnmount ##########")
	}
	
	//1. 목록 조회
	async getEventSettingList() {
		await http.get("/rest/shop/list/event/setting?start_date="+this.state.start_date_format+"&end_date="+this.state.end_date_format+"&title="+this.state.title
		+"&order_type="+this.state.sorting+"&order_gubun="+this.state.orderGubun+"&startidx="+this.state.startIdx+"&rowcnt="+this.state.rowcnt+"&qna_type_cd="+this.state.type+"&group_by_yn=Y")
		.then(res => {
			if (res.data != null && res.data != '') {
				this.setState( {event_setting_list : res.data["list"]} );
				this.setState( {totalCnt : res.data["totalCnt"]} );
				// console.log(this.state.totalCnt)
				// console.log ("===== res =====");
				// console.log(res);
				// for(var idx=0; idx<=res.data["list"].length; idx++) {
				// 	this.setState({
				// 		["auto_create_yn_"+res.data["list"][idx].iframe_page_id+"_"+res.data["list"][idx].iframe_html_id]: res.data["list"][idx].auto_create_yn
				// 	})
				// }
			}
			// if (res.indexOf("status code 404") > 0 ) {
			// 	window.location.href = "/login"
			// } 
		})
		.catch(error => {
			// console.log ("===== error =====");
			// console.log (error);
			// console.log ("===== error request =====");
			// console.log(error.request);
		 });
    }
	
	render() {
		$(".pageName").text("베스트 롤링");
		return (
			<div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
				<div id="qna_list_component mobile_bottom_button_area">
					<div style={{display:"flex", flexDirection:"column", marginBottom:"30px"}}>
						<div className="page_title_v2" style={{margin:"auto 0"}}>베스트 롤링</div>
					</div>
					
					<div class="banner_list_table_area" style={{width:"calc(95vw - 600px)", minWidth:"100%", height:"100%", overflowX:"auto", overflowY: "hidden"}}>
						<div style={{display:"inline-block", height:"100%", minWidth:"1100px", width:"100%"}}>
							<div className="table table_V2" style={{marginBottom:0}}>
								<div className="table" style={{marginBottom:0}}>
									<table>
										<colgroup>
											{/* <col width="70px" />
											<col width="70px" />
											<col width="100px" />
											<col width="70px" />
											<col width="70px" />
											<col width="100px" />
											<col width="100px" />
											<col width="100px" />
											<col width="200px" />
											<col width="100px" />
											<col width="100px" /> */}
											{/* <col width="150px" /> */}
										</colgroup>
										<thead>	
											<tr>
												<td className="user_id" onClick={this.onClickSorting} style={{marginLeft:"10px"}}>
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>번호
													{/* {(() => {
														if (this.state.orderGubun == "user_id") {
															if(this.state.sorting == "ASC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_b"></div> 
																	</>
																)
															} else if (this.state.sorting == "DESC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																		<div className="icn_table_v2_sort_asc_arrow_d"></div>
																	</>
																)
															} else {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
																)
															}
														} else {
															return ( 
																<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
															)
														}
													})()} */}
													</p>
												</td>
												<td className="corp_reg_no" onClick={this.onClickSorting}>
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>제목
													{/* {(() => {
														if (this.state.orderGubun == "corp_reg_no") {
															if(this.state.sorting == "ASC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_b"></div> 
																	</>
																)
															} else if (this.state.sorting == "DESC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																		<div className="icn_table_v2_sort_asc_arrow_d"></div>
																	</>
																)
															} else {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
																)
															}
														} else {
															return ( 
																<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
															)
														}
													})()} */}
													</p>
												</td>
												<td className="corp_name" onClick={this.onClickSorting} >
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>상태
													{/* {(() => {
														if (this.state.orderGubun == "corp_name") {
															if(this.state.sorting == "ASC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_b"></div> 
																	</>
																)
															} else if (this.state.sorting == "DESC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																		<div className="icn_table_v2_sort_asc_arrow_d"></div>
																	</>
																)
															} else {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
																)
															}
														} else {
															return ( 
																<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
															)
														}
													})()} */}
													</p>
												</td>
												<td className="business_type" onClick={this.onClickSorting}>
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>상품수
													{/* {(() => {
														if (this.state.orderGubun == "business_type") {
															if(this.state.sorting == "ASC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_b"></div> 
																	</>
																)
															} else if (this.state.sorting == "DESC") {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																		<div className="icn_table_v2_sort_asc_arrow_d"></div>
																	</>
																)
															} else {
																return ( 
																	<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
																)
															}
														} else {
															return ( 
																<>
																		<div className="icn_table_v2_sort_desc_arrow_d"></div>
																		<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																	</>
															)
														}
													})()} */}
													</p>
												</td>
											</tr>
										</thead>
										<tbody style={{height:"auto"}}>
											{
												this.state.event_setting_list.length == 0
												?	<tr style={{textAlign:"center", height:"50vh", background:"white"}}>
														<div style={{margin:"10vh 0 5vh 0", fontSize:"15px", color:"#333333"}}><b>롤링 상품 설정을 직접 추가해 보세요. (준비중)</b></div>
														{/* <NavLink
															style={{color:"white"}}
															to={{
																pathname: `/shop/cafe24/event/setting`,
																// state: {prevPath: window.location.href}
															}}>
															<button className="btn-auto-b" style={{width:"190px", height:"55px"}}>
																배너설정 추가하기
															</button>
														</NavLink> */}
													</tr>
												: 	""
											}
											{this.state.event_setting_list.map((obj, idx) => (
												// <NavLink
												// style={{color:"white"}}
												// to={{
												// 	pathname: "/shop/cafe24/event/setting",
												// 	search: "?iframe_page_id="+obj.iframe_page_id+"&iframe_html_id="+obj.iframe_html_id
												// 	// state: {prevPath: window.location.href}
												// }}>
												<tr key={idx} onClick={e=>this.handleClick(obj.iframe_page_id, obj.iframe_html_id)}>
													<td>
														<p className="card-text">{obj.no}</p>
													</td>
													<td>
														<p className="card-text">{obj.iframe_page_name}</p>
													</td>
													<td>
														<p className="card-text">{obj.auto_create_yn}</p>
													</td>
													<td>
														<p className="card-text">{obj.product_cnt}</p>
													</td>
												</tr>
												// </NavLink>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <div style={{width:"100%", display:"flex", justifyContent:"center", marginTop:"50px"}}>
					<NavLink
						style={{color:"white"}}
						to={{
							pathname: `/shop/cafe24/orderAmountExpect`,
							// state: {prevPath: window.location.href}
						}}>
						<button className="btn-auto-b" 
							style={{width:"190px", height:"55px", margin:"auto"}}>
						멤버십 결제하기
						</button>
					</NavLink>
				</div> */}
				<div style={{width:"100%", display:"flex", justifyContent:"center", marginTop:"50px"}}>
					<button className="btn-auto-b" 
						style={{width:"190px", height:"55px", margin:"auto"}}
						onClick={e => Menu.call_openChannelTalk()}>
					채팅으로 설치 요청하기
					</button>
				</div>
			</div>
		);
	}
}

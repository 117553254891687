import React from 'react';
import http from '../modules/api';
import { Helmet } from 'react-helmet';

// 스타일
import styles from './RoboImageDesign.module.css';
import * as StringUtil from '../modules/utils/StringUtil';
import HomePageHeader from '../modules/header/HomePageHeader';

// 컴포넌트
import RoboImagePopup from './component/RoboImagePopup';

import {ga4react, reactPixel} from '../Menu'
import Button from '../modules/common/Button';
import arrows_clockwise from '../../resources/img/arrows_clockwise.svg';
import arrows_clockwise_white from '../../resources/img/arrows_clockwise_white.svg';
import arrow_after from '../../resources/img/arrow_after.svg';

import { withTranslation } from "react-i18next";

const prompt_max_length = 1850; // 프롬프트 최대 길이 : 인코드 한 값이 1850자가 넘지 못하도록 제한

export class RoboImageDesign extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popupDisplay: false, // 팝업창 열기/닫기 : true/false
            before_prompt_seq : '',
            prompt : '',
            border_focus : false, // textarea의 포커스 상태
        }
        this.input_prompt = React.createRef();
        this.border_prompt = React.createRef();
    }

    componentDidMount () {
        // this.border_prompt.current.addEventListener('click', function() {
        //     this.border_prompt.current.classList.add('focus');
        //     console.log(this.input_prompt);
        //     // this.input_prompt.current.focus();
        // });
        let this_component = this;
        this.input_prompt.current.addEventListener('blur', function() {
            this_component.setState({border_focus : false,})
        });
    }

    componentWillUnmount () {
        // this.input_prompt.current.removeEventListener('focus');
    }

    focusPromptBorder = (e) =>  {
        if(e.target.tagName.toLowerCase() != 'button' && e.target.tagName.toLowerCase() != 'img'){
            this.setState({border_focus : true,})
            this.input_prompt.current.focus();
        }
    }

    /** 무료로 디자인 하기 클릭 이벤트 */
    handleFreeStartOnClick = (error_msg) => {
        ga4react.event('btn_click', 'btn_input_prompt', 'banner_promo');
		reactPixel.trackCustom('[AI디자인] 버튼_클릭_입력');

        const prompt = this.state.prompt.replaceAll(/[\[\]:]/g, ""); // 프롬프트의 대괄호와 콜론을 빈문자로 바꿔서 전달
        this.setState({prompt : prompt});
        
        if(StringUtil.checkEmptyString(prompt)){
            alert(error_msg);
            this.setState({prompt : ""});
            return false;
        }

        // 스크롤 막기
        document.body.style.overflow = 'hidden';

        // 팝업창 열기
        this.setState({popupDisplay: true});
    }

    handleIntroService = () => {
        ga4react.event('btn_click', 'btn_service', 'banner_promo');
		reactPixel.trackCustom('[AI디자인] 버튼_클릭_서비스소개');

        window.open('https://robomd.io/sub/services.html', '_blank'); // 새 창으로 열기
    }

    /** 팝업창 닫기 */
    handlePopupClose = (popup) => {
        // 스크롤 풀기
        document.body.style.overflow = 'auto';

        // 팝업창 닫기
        this.setState({[popup]: false});
    }

    /** 프롬프트 입력 */
    handleChangePrompt = (e) => {
        let prompt = e.target.value.substring(0, 220);
        while(encodeURIComponent(prompt).length > prompt_max_length){
            prompt = prompt.slice(0, -1);
        }

        this.setState({prompt : decodeURIComponent(prompt)});
    }

    /** 체험해보기 클릭 : prompt 값 랜덤으로 받기 */
    getRandomPrompt = () => {
		http.get("/rest/common/sampple/prompts?prompt_seq="+this.state.before_prompt_seq)
		.then(res => {
            if(res.data){
                let prompt = res.data.sample_prompts.substring(0, 220);
                while(encodeURIComponent(prompt).length > prompt_max_length){
                    prompt = prompt.slice(0, -1);
                }
                this.setState({
                    prompt: prompt,
                    before_prompt_seq: res.data.sample_prompt_seq,
                });
            }
        });
    }

    /* 비연동형 회원가입 */
    onClickRegister = () => {
        const language = this.props.t('language');

        ga4react.event('btn_click', 'btn_AI_design_join', 'banner_promo');
        reactPixel.trackCustom('[AI디자인] 버튼_클릭_AI 디자인 회원가입');

        // 새 창으로 열어 값을 전달할 페이지 URL과 프롬프트
        var targetURL = (language == 'en') ? 'https://robomd.io/common/registUser/en' : 'https://robomd.io/common/registUser';
        var queryParams = '?regist_solution_type_cd=09900';
        window.open(targetURL + queryParams, '_blank'); // 새 창으로 열기
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <Helmet>
                    <title>[로보MD] AI 무료 디자인 생성</title>
                    <meta name="description" content={"아이디어 입력만으로 순식간에 저작권 걱정 없는 AI 디자인를 생성해 보세요. 지금 회원가입 시 14일 무료 체험 혜택을 드려요."}/>
                    <meta name="keywords" content={"AI이미지생성, 생성형AI, 로보MD, 배경제거, 무료사진, 인공지능이미지, AI스타트업, 무료이미지, AI프로필, 배너, 이미지"}/>
                    <meta property="og:title" content={"[로보MD] AI 무료 디자인 생성"} />
                    <meta property="og:url" content={"robomdaiimage.com/common/promo_aibanner"} />
                    <meta property="og:description" content={"아이디어 입력만으로 순식간에 저작권 걱정 없는 AI 디자인를 생성해 보세요. 지금 회원가입 시 14일 무료 체험 혜택을 드려요."} />
                    <meta property="og:image" content={"https://s3.ap-northeast-2.amazonaws.com/otdeal.file/og_image/roboimage+og.jpg"} />
                </Helmet>
                <HomePageHeader />
                <div className={[styles.roboimage_intro, "roboimage_style_guide", styles[t('language')]].join(' ')}>
                    <img className={styles.bg} src='/img/roboimage/intro_bg.jpg' alt='로보이미지 인트로 배경이미지'/>
                    <div className={styles.content}>
                        <img className={styles.character} src='/img/roboimage/intro_character.svg' alt='로보이미지 캐릭터'/>
                        <h2>
                            <span className={styles.regular}>{t('roboImageDesign.intro_idea')}</span> <br/>
                            <span className={styles.bold}>{t('roboImageDesign.title1')}</span> <br className={styles.mobile_br}/>
                            {t('language') == "kr"
                            ?<>
                                <span className={styles.underline}>{t('roboImageDesign.title2')}</span>
                                <span className={styles.large}>{t('roboImageDesign.title3')}</span>
                            </>
                            :<>
                                <span className={styles.large}>{t('roboImageDesign.title2')}</span>
                                <br/>
                                <span className={styles.underline}>{t('roboImageDesign.title3')}</span>
                            </>}
                        </h2>
                        {t('language') == "kr"
                        ?<h3>
                            <span>{t('roboImageDesign.detail_content1')} <br className={styles.mobile_br}/>{t('roboImageDesign.detail_content2')}</span> <br/>
                            <span>{t('roboImageDesign.detail_content3')} <br className={styles.mobile_br}/>{t('roboImageDesign.detail_content4')}</span>
                        </h3>
                        :<h3>
                            <span>{t('roboImageDesign.detail_content')}</span>
                        </h3>}
                        <div className={styles.textarea_border} ref={this.border_prompt} style={this.state.border_focus ? {borderColor: "#00E2E6"} : {}} onClick={e => this.focusPromptBorder(e)}>
                            <textarea className={styles.textarea} ref={this.input_prompt} placeholder={t('roboImageDesign.input_placeholder')} value={this.state.prompt} onChange={e => this.handleChangePrompt(e)}></textarea>
                            {this.state.before_prompt_seq
                            ?   <Button
                                    className={[styles.textarea_btn, styles.textarea_new_btn].join(' ')}
                                    type={"hover_dark"}
                                    width={"auto"}
                                    height={"33px"}
                                    fontSize={"13px"}
                                    padding={"0px 16px"}
                                    onClick={this.getRandomPrompt}
                                >
                                    <img src={arrows_clockwise_white}/>
                                    {t('roboImageDesign.input_button2')}
                                </Button>
                            :   <Button
                                    className={styles.textarea_btn}
                                    type={"hover_dark"}
                                    width={"auto"}
                                    height={"33px"}
                                    fontSize={"13px"}
                                    padding={"0px 16px"}
                                    onClick={this.getRandomPrompt}
                                >
                                    {t('roboImageDesign.input_button')}
                                </Button>
                            }
                        </div>
                        <div className={styles.textarea_info}>{t('roboImageDesign.input_comment')}</div>
                        <div className={styles.button_wrap}>
                            <button className={styles.service_intro_btn} onClick={this.handleIntroService}>
                                {t('roboImageDesign.service_button')}
                                <img src={arrow_after}/>
                                {/* <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.49998 6.00002C6.49818 6.13254 6.44691 6.25961 6.35623 6.35627L1.35623 11.3563C1.25908 11.436 1.13575 11.4767 1.01023 11.4706C0.884699 11.4644 0.765954 11.4118 0.677086 11.3229C0.588219 11.234 0.535584 11.1153 0.529419 10.9898C0.523254 10.8642 0.564 10.7409 0.643731 10.6438L5.29373 6.00002L0.643732 1.35627C0.564001 1.25912 0.523255 1.13579 0.52942 1.01026C0.535585 0.884737 0.58822 0.765991 0.677088 0.677124C0.765955 0.588257 0.884701 0.535622 1.01023 0.529456C1.13575 0.523291 1.25908 0.564037 1.35623 0.643769L6.35623 5.64377C6.44691 5.74043 6.49818 5.8675 6.49998 6.00002Z" fill="#333333"/></svg> */}
                            </button>
                            <button onClick={() => this.handleFreeStartOnClick(t('roboImageDesign.input_error'))} className={[styles.free_start_btn, "hover_mint"].join(' ')}>{t('roboImageDesign.design_button')}</button>
                        </div>
                        <div className={styles.regist_btn}>
                            <Button width={"100%"} height={"50px"} fontSize={"16px"} onClick={() => this.onClickRegister()}>{t('roboImageDesign.regist_button')}</Button>
                        </div>
                        {/* 애니메이션 홍보 영역 */}
                        <div className={styles.promotion}></div>
                    </div>
                </div>

                {this.state.popupDisplay
                && <RoboImagePopup
                        display={this.state.popupDisplay}
                        prompt={this.state.prompt}
                        pageType={"roboimage_design"}
                        close={() => this.handlePopupClose("popupDisplay")}
                    />
                }
            </>
        )
    }
}

export default withTranslation()(RoboImageDesign);
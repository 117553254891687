import { nodeName } from 'jquery';
import http from '../modules/api';
import axios from 'axios';
import React from 'react';
import '../../css/style.css';
import $ from 'jquery';
import * as Menu from '../Menu_v2';
import CommonPopup from '../modules/CommonPopup';

import icn_data_searching from '../../resources/img/icn_data_searching.svg';
import icn_trend_analysis from '../../resources/img/icn_trend_analysis.svg';
import icn_list_display from '../../resources/img/icn_list_display.svg';
import icn_list_guide_dots from '../../resources/img/icn_list_guide_dots.svg';

import icn_list_guide_banner_1 from '../../resources/img/icn_list_guide_banner_1.svg';
import icn_list_guide_banner_2 from '../../resources/img/icn_list_guide_banner_2.svg';
import icn_list_guide_banner_3 from '../../resources/img/icn_list_guide_banner_3.svg';

import icn_list_guide_1 from '../../resources/img/icn_list_guide_1.svg';
import icn_list_guide_2 from '../../resources/img/icn_list_guide_2.svg';
import icn_list_guide_3 from '../../resources/img/icn_list_guide_3.svg';
import TargetViewIntro from '../targetView/TargetViewIntro';
import TrendInsightIntro from '../trendInsight/TrendInsightIntro';
import RankingInsightIntro from '../rankingInsight/RankingInsightIntro';

/**
 * 사용자 서비스 - 상점추가 페이지
 */
export class ServiceShopAdd_V2 extends React.Component {
  state = {
    step: 1,
    service_type: '',
    service_color: '#dffafa',
    // solution_type_cd: '',
    // solution_name: '',
    // agree_yn: '' /* 디폴트 값  변경 'N' -> '' */,
    // mall_id: '',
    // makeshop: {
    //   /*메이크샵 연동을 위한 필수 정보 입력 (국적, 화폐단위, 샵 이름을 받아야 할까..)*/
    //   shop_key: '',
    //   domain: '',
    //   shop_name: '',
    //   language_code: 'ko_KR' /*언어 코드 : (기본)국문*/,
    // },
    // imweb: {
    //   api_key: '',
    //   secret_key: '',
    //   domain: '',
    //   shop_name: '',
    //   language_code: '',
    // },
    // godomall: {
    //   id: '',
    //   domain: '',
    //   shop_name: '',
    //   language_code: '',
    // },
    //finish_yn: 'N' /*최종 저장 완료 후 상태값*/,
    checkedShopList: [] /*사용자가 연동을 위해 선택한 shop 리스트*/,
    //shops: [] /*cafe24 api에서 가져온 shop 리스트*/,
    platform_shop_list: [] /*최종 사용자의 플랫폼별 상점 리스트*/,
    options: {
      support_solution: [] /*code : 지원 플랫폼*/,
      language_code_list: [
        { cd_code: '-', cd_desc: '없음' },
        { cd_code: 'ko_KR', cd_desc: '국문' },
        { cd_code: 'en_US', cd_desc: '영문' },
        { cd_code: 'zh_CN', cd_desc: '중문(간체)' },
        { cd_code: 'zh_TW', cd_desc: '중문(번체)' },
        { cd_code: 'ja_JP', cd_desc: '일문' },
        { cd_code: 'vi_VN', cd_desc: '베트남어' },
      ],
    },
  };

  //공통코드 조회
  async getCode(upper_code_cd, option) {
    const res = await http.get(
      '/rest/common/cafe24Code?upper_code_cd=' + upper_code_cd,
    );
    const { options } = this.state;
    this.setState({
      options: {
        ...options,
        [option]: res.data,
      },
    });
  }

  // 1단계 : 플랫폼 선택
  selectSolution = (e, code_cd, code_name) => {
    this.setState({
      solution_type_cd: code_cd,
      solution_name: code_name,
    });

    // 1. 전체
    $('.solutionBox').css('border', '0px');

    if (e.target.style.border == '2.5px solid rgb(16, 207, 201)') {
      e.target.style.border = '0px';
    } else {
      e.target.style.border = '2.5px solid #10CFC9';
    }
  };

  // 다음 단계
  nextStep = () => {
    // alert(this.state.solution_name + "을 선택하셨습니다.");
    // if (this.state.step == 2 && this.state.agree_yn == 'N') {
    // 	alert("계정 정보 확인에 동의를 해주십시오.");
    // 	return false;
    // }

    // $('#step' + this.state.step + ' .radio').addClass('on');
    // $('#step' + this.state.step + ' .radio').css('font-size', '0px');
    
    // if (Number(this.state.step) == 1) {
    //   //this.state.solution_type_cd = "09004" // 샘플 고도몰 코드
    //   if (this.state.solution_type_cd == '') {
    //     alert('플랫폼을 선택해 주세요.');
    //     return false;
    //   }
    // }

    // if (sessionStorage.getItem("solution_type_cd") == "09900") {
    //   alert("쇼핑몰 미보유로 설정된 상태의 계정으로 이용할 수 없는 서비스 입니다.\n쇼핑몰 설치형 서비스 전환을 원하시면 1599-3518로 문의 주세요.");
    // }
    let shop_no = Menu.call_shop_no();
    if (shop_no == "-1") {
      alert("쇼핑몰 미보유로 설정된 상태의 계정으로 이용할 수 없는 서비스 입니다.\n쇼핑몰 설치형 서비스 전환을 원하시면 1599-3518로 문의 주세요.");
      return false;
    }
    
    if (Number(this.state.step) == 1) {
      let data = {
        service_type: this.state.service_type
      };
      const config = {
        headers: {
          'content-type': 'application/json;',
        },
      };
      // 1단계 : 무료로 이용하기 (사용자 서비스 추가, 권한 생성)
      http
        .post('/rest/shop/activeUserServiceSave', data, config)
        .then((res) => {
          const { data } = res;
          if (data.code == "200") {
            // 상점 목록
            this.selectShops(this.state.service_type);
          } else {
            alert(
              '[code : ' +
                data.code +
                ']' +
                '\n관리자에게 문의해 주세요. (1599-3518)',
            );
          }
        })
        .catch((error) => {
          alert('서비스 요청 실패' + '\n관리자에게 문의해 주세요. (1599-3518)');
        });

    } else if (Number(this.state.step) == 2) {
      //this.state.solution_type_cd = "09004" // 샘플 고도몰 코드
      if (this.stat.checkedShopList.length == 0) {
        alert('상점을 선택해 주세요.');
        return false;
      }
    }

    this.setState({
      step: Number(this.state.step) + 1,
    });

  };

  handleChangeSelectShop = (e, shop_no, shop_name, solution_type_cd) => {
    // if (state == "on") {
    // 	this.state.checkedShopList.push(shop_no);
    // } else {
    // 	this.state.checkedShopList.splice(this.state.checkedShopList.indexOf(shop_no), 1);
    // }
    // var key = "checkbox_state_"+shop_no;
    // this.setState({[key]: state})

    if (e.target.checked === true) {
      let shop = {
        shop_no: shop_no,
        shop_name: shop_name,
        solution_type_cd: solution_type_cd,
      };
      this.state.checkedShopList.push(shop);
    } else {
      const newList = this.state.checkedShopList.filter(
        (shop) => shop.shop_no !== shop_no,
      );
      this.setState({ checkedShopList: newList });
    }
    console.log(this.state.checkedShopList);
  };

  // 서비스 가이드  
  onClickUserGuide = () => {
    window.open('https://iclave.notion.site/MD-41912c6f69084ba19fab96b903b08999');
  }

  // 3단계 선택한 상점 등록하기 : 선택된 shop_no 저장
  onClickSelectShopSaveButton = () => {
    //var mall_id = this.state.mall_id;
    //var solution_type_cd = this.state.solution_type_cd;
    var service_type = this.state.service_type;
    var checkedShopList = this.state.checkedShopList;
    
    let shop_no = Menu.call_shop_no();
    if (shop_no == "-1") {
      alert("쇼핑몰 미보유로 설정된 상태의 계정으로 이용할 수 없는 서비스 입니다.\n쇼핑몰 설치형 서비스 전환을 원하시면 1599-3518로 문의 주세요.");
      return false;
    }

    if (checkedShopList != null && checkedShopList.length > 0) {
      let shopData = {
        //mall_id: mall_id,
        //solution_type_cd: solution_type_cd,
        service_type: service_type,
        checkedShopList: checkedShopList
      };

      const config = {
        headers: {
          'content-type': 'application/json;',
        },
      };

      /**
       * 선택된 플랫폼의 사용자 상점 (데이터 연동을 위한)저장
       */
      http
        .post('/rest/common/v2/saveCafe24CheckedShop', shopData, config)
        .then((res) => {
          const { data } = res;
          console.log(data);
          if (data.code == 200) {
            //선택 멀티샵 데이터 최초 동기화
            this.firstDataSyncCheckedShop(shopData);
            // 메뉴 권한 새로 조회
            Menu.call_checkUser_for_other_component();
            // alert('상점 연동이 완료 되었습니다.');
            if (service_type == "01001") {
              // 01001: 배너 신청시 안내 팝업
              //Menu.call_onChangeIsAddShopButtonClick_for_other_component(true);
              // 디폴트 배너 세팅 추가
              this.insertAutoEventSettingsDefault(shopData);
              //자동배너 설정 이동
              //window.location.href = "/shop/cafe24/event/setting/list";
            } else if (service_type == "01002") {
              /**
               * 디폴트 진열 세팅 추가 
               * 2022.09.05 : 연동 요청 직후 기본 설정 저장 / 상품 생성 백엔드에서 연동 완료후 처리
               * 2022.06.27 : 연동 요청 직후 백엔드에서 연동 완료후 처리
               */
               this.insertRpEventSettingsDefault(shopData);
               //3단계 화면 출력
               //this.setState({ step: 3 });
            }
          } else {
            alert(
              '[code : ' +
                data.code +
                ']' +
                '\n관리자에게 문의해 주세요. (1599-3518)',
            );
          }
        })
        .catch((error) => {
          alert(error + '\n관리자에게 문의해 주세요. (1599-3518)');
        });
    } else {
      alert('연동할 쇼핑몰을 선택해 주세요.');
    }
  };

  // selectAddShop() {
  //   http
  //     .get('/rest/shop/selectShops')
  //     .then((res) => {
  //       const { data } = res;
  //       if (data.code == 200) {
  //         if(data.platform_shop_cnt > 0) {
  //           this.setState({
  //             platform_shop_list: data.platform_shop_list,
  //           });
  //         } else {
  //           alert("연동된 쇼핑몰이 없습니다.\n쇼핑몰 연동 페이지로 이동합니다.");
  //           window.location.href = '/shop/serviceShopAdd';
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       alert(error + '\n관리자에게 문의해 주세요. (1599-3518)');
  //     });
  // }

  //선택 멀티샵 데이터 최초 동기화
  async firstDataSyncCheckedShop(shopData) {
    const config = {
      headers: {
        'content-type': 'application/json;',
      },
    };
    await axios.post('/rest/common/firstDataSyncCheckedShop', shopData, config);
    // const res = await http.post("/rest/common/firstDataSyncCheckedShop", shopData, config)
    // const {data} = res;
    // if (data.code != null) {
    // 	console.log("firstDataSyncCheckedShop : [code : "+data.code+"] " + data.msg);
    // }
  }

  // 디폴트 배너 설정 추가
  insertAutoEventSettingsDefault(shopData) {
    const config = {
      headers: {
        'content-type': 'application/json;',
      },
    };
    http.post('/rest/shop/event/setting/default', shopData, config)
    .then((res) => {
      const { data } = res;
      if (data.code == "200") {
        //자동배너 설정 이동
        window.location.href = "/shop/cafe24/event/setting/list";
      } else {
        alert(
          '[code : ' +
            data.code +
            ']' +
            '\n관리자에게 문의해 주세요. (1599-3518)',
        );
      }
    })
    .catch((error) => {
      alert(error + '\n관리자에게 문의해 주세요. (1599-3518)');
    });
    // const res = await axios.post("/rest/shop/event/setting/default", shopData, config);
    // console.log("insertAutoEventSettingsDefault : " + res.data.message);
  }

  // 디폴트 진열 설정 추가
  insertRpEventSettingsDefault(shopData) {
    const config = {
      headers: {
        'content-type': 'application/json;',
      },
    };
    http.post('/rest/shop/rp/setting/default', shopData, config)
    .then((res) => {
      const { data } = res;
      if (data.code == "200") {
        window.location.href = "/";
        //3단계 화면 출력
        //this.setState({ step: 3 });
        //자동배너 설정 이동
        //window.location.href = '/shop/rp/event/list';
      } else {
        alert(
          '[code : ' +
            data.code +
            ']' +
            '\n관리자에게 문의해 주세요. (1599-3518)',
        );
      }
    })
    .catch((error) => {
      alert(error + '\n관리자에게 문의해 주세요. (1599-3518)');
    });
  }

  //mall_id에 해당하는 shops 조회
  async selectShops(service_type) {
    const res = await http.get('/rest/common/v2/selectShops', {
      params: {
        service_type: service_type
      },
    });

    // 가입신청 - Cafe24 API] 상점 동기화
    //this.nextStep();
    // console.log("selectShops : mall_id에 해당하는 shops 조회");

    // 조회 성공시 다음 단계
    if (res.data != null && res.data.shopList.length > 0) {
      if (res.data.userActiveServiceList != null && res.data.userActiveServiceList.length > 0) {
        this.setState({
          step: 2,
        })
      }

      this.setState({
        //step: 3, //Number(this.state.step) + 1,
        service_type: res.data.service_type,
        shops: res.data.shopList,
      });
      
    } else {
      //alert('연동된 쇼핑몰 정보가 없습니다.\n해당 플랫폼의 쇼핑몰 정보를 확인해 주세요.');
      window.location.href = '/shop/solutionStoreAdd';
    }
  }

  // platformImg(solution_type_cd) {
  //   const { support_solution } = this.state.options
  //   const idx = support_solution.findIndex((solution) => solution.code_cd == solution_type_cd)
  //   let img_url = "";
    
  //   if (idx >= 0) {
  //     img_url = support_solution[idx].val2;
  //   }

  //   return img_url;
  // }

  componentDidUpdate() {
    // var params = {};
    // window.location.search.replace(
    //   /[?&]+([^=&]+)=([^&]*)/gi,
    //   function (str, key, value) {
    //     params[key] = value;
    //   },
    // );
    // if (this.state.step != '0') {
    //   // alert(params.finish_yn);
    //   if (params.finish_yn == 'y') {
    //     if (params.step == '3') {
    //       if (this.state.finish_yn == 'N') {
    //         $('#page_title').text('쇼핑몰 목록');
    //         this.setState({ step: 3 }); // 상점 목록
    //         this.setState({ finish_yn: 'Y' });
    //         this.selectAddShop();
    //         is_stroe_loaded = false;
    //       }
    //     }
    //   } else {
    //     // alert(is_stroe_loaded);
    //     if (!is_stroe_loaded) {
    //       this.selectStore(); // 상점 추가
    //       this.setState({ finish_yn: 'N' });
    //     }
    //   }
    // }
  }

  componentDidMount() {

    var params = {};
    window.location.search.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (str, key, value) {
        params[key] = value;
      },
    );
    //this.stroeOrSettingCnt();
    //쇼핑몰 플랫폼 코드 조회
    this.getCode('09000', 'support_solution');    

    if (params.step == '2') {
      this.setState({ step: 2 }); 
      // 상점 목록
      this.selectShops(params.service_type);
    } else if (params.step == '3') {
      this.setState({ step: 3 }); 
    }
    //  else {
    //   // 상점 목록
    //   this.selectShops(params.service_type);
    // }

    if (params.service_type == '01001') {
      this.setState({ 
        service_type: '01001',
        service_name: "배너",
        service_color: "#dffafa",
      }); 
    } else if (params.service_type == '01002') {
      this.setState({ 
        service_type: '01002',
        service_name: "진열",
        service_color: "#dffafa",
      }); 
    } else if (params.service_type == '01009') {
      this.setState({ 
        service_type: '01009',
        service_name: "타겟뷰",
        service_color: "#E1FBE8",
      }); 
    } else if (params.service_type == '01005') {
      this.setState({ 
        service_type: '01005',
        service_name: "트렌드 인사이트",
        service_color: "#F7F2FF",
      }); 
    } else if (params.service_type == '01010') {
      this.setState({ 
        service_type: '01010',
        service_name: "랭킹 인사이트",
        service_color: "#FFFCF2",
      }); 
    }

    // Cafe24 가입자 최초 연동 여부
    if (params.solution_type_cd == '09001' && params.shop_no > 0) {
      let shop = {
        shop_no: params.shop_no,
        shop_name: '',
        solution_type_cd: params.solution_type_cd,
      };
      this.setState({
        service_type: params.service_type
      }, () => {
        this.state.checkedShopList.push(shop)
        this.onClickSelectShopSaveButton()
      })
    }

  }

  stroeOrSettingCnt() {
    http.get("/rest/shop/stroeOrSettingCnt")
      .then(res => {
        if (res.data.stroeOrSettingCnt != null) {
          console.log(res.data);
          let page_redirect_type = res.data.stroeOrSettingCnt["page_redirect_type"];
          if(page_redirect_type != "10001") {
            window.location.href="/";
          }
        }
      })
      .catch(error => {
          alert(error); 
      });
  }

  render() {  
    //$('.pageName').text('쇼핑몰 연동하기 V2');
    return (
      <>
        {/* <div style={{ backgroundColor: '#dffafa' }}>
          <div
              id="page-wrapper"
              className="container"
              style={{ maxWidth:'100%', backgroundColor: '#dffafa', minHeight: '100vh', display: 'flex', justifyContent: 'center' }}
              //style={{ padding:'130px 0px 0px 220px', backgroundColor: '#dffafa', minHeight: '100%' }}
          >
            <div className="shop_add_area" style={{alignSelf:'center'}}>
              <div id="list_wrap">
                <section className="list_guide_main list_guide_main_a" style={{height:"auto"}}> */}
                  {/* ----------- 1단계 화면 영역 : 서비스 설명 화면 --------------------------------------------------------------------------------------------------- */}
                  {this.state.step == 1 ? (
                    <>
                      {this.state.service_type == "01001" ? (
                        <div style={{ backgroundColor: this.state.service_color }}>
                          <div
                            id="page-wrapper"
                            className="container"
                            style={{ maxWidth:'100%', minHeight: '100vh', display: 'flex', justifyContent: 'center' }}
                            //style={{ padding:'130px 0px 0px 220px', backgroundColor: '#dffafa', minHeight: '100%' }}
                          >
                            <div className="shop_add_area" style={{alignSelf:'center'}}>
                              <div id="list_wrap">
                                <section className="list_guide_main list_guide_main_a" style={{height:"auto"}}>
                                  <div className="list_guide_main_1">
                                      <h2 className="text_large">
                                          로보MD 배너
                                      </h2>
                                      <h3 className="text_medium">
                                          세계최초! 쇼핑몰 자동 배너 운영,<br/>
                                          인공지능 로보md에게 맡겨보세요.<br/>
                                          매출이 올라갑니다.
                                      </h3>
                                      <button className="use_btn hover_dark" onClick={()=>this.nextStep()}>
                                          무료로 이용하기
                                      </button>
                                  </div>
                                  <div className="list_guide_main_1a">
                                      <div>
                                          <h3 className="text_16">내 쇼핑몰의<br/>고객데이터를 분석하고</h3>
                                          <img src={icn_list_guide_banner_1} alt="이용데이터 분석"/>
                                      </div>
                                      <span>
                                          <div className="list_guide_dot"></div>
                                          <div className="list_guide_dot"></div>
                                          <div className="list_guide_dot"></div>
                                      </span>
                                      <div>
                                          <h3 className="text_16">인공지능으로<br/>자동으로 디자인해드리고</h3>
                                          <img src={icn_list_guide_banner_2} alt="트렌드 분석"/>
                                      </div>
                                      <span>
                                          <div className="list_guide_dot"></div>
                                          <div className="list_guide_dot"></div>
                                          <div className="list_guide_dot"></div>
                                      </span>
                                      <div>
                                          <h3 className="text_16">소비자별로 배너를<br/>쇼핑몰에 노출해드려요</h3>
                                          <img src={icn_list_guide_banner_3} alt="상품 진열"/>
                                      </div>
                                  </div>
                                  <button className="use_btn hover_dark" onClick={()=>this.nextStep()}>
                                      무료로 이용하기
                                  </button>
                                </section>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : ''}
                      {this.state.service_type == "01002" ? (
                        <div style={{ backgroundColor: this.state.service_color }}>
                          <div
                            id="page-wrapper"
                            className="container"
                            style={{ maxWidth:'100%', minHeight: '100vh', display: 'flex', justifyContent: 'center' }}
                            //style={{ padding:'130px 0px 0px 220px', backgroundColor: '#dffafa', minHeight: '100%' }}
                          >
                            <div className="shop_add_area" style={{alignSelf:'center'}}>
                              <div id="list_wrap">
                                <section className="list_guide_main list_guide_main_a" style={{height:"auto"}}>
                                  <div className="list_guide_main_1">
                                    <h2 className="text_large">
                                        로보MD 진열
                                    </h2>
                                    <h3 className="text_medium">
                                        반복되는 쇼핑몰의 상품 진열!<br/>
                                        365일 24시간, 상품데이터를 분석하여<br/>
                                        쇼핑몰 상품 진열을 대신해드립니다.
                                    </h3>
                                    <button className="use_btn hover_dark" onClick={()=>this.nextStep()}>
                                        무료로 이용하기
                                    </button>
                                  </div>
                                  <div className="list_guide_main_1a">
                                    <div>
                                        <h3 className="text_16">내 쇼핑몰의<br/>이용 데이터를 분석하고</h3>
                                        <img src={icn_list_guide_1} alt="이용데이터 분석"/>
                                    </div>
                                    <span>
                                        <div className="list_guide_dot"></div>
                                        <div className="list_guide_dot"></div>
                                        <div className="list_guide_dot"></div>
                                    </span>
                                    <div>
                                        <h3 className="text_16">다양한 빅데이터 분석<br/>진열 알고리즘을 적용해서</h3>
                                        <img src={icn_list_guide_2} alt="트렌드 분석"/>
                                    </div>
                                    <span>
                                        <div className="list_guide_dot"></div>
                                        <div className="list_guide_dot"></div>
                                        <div className="list_guide_dot"></div>
                                    </span>
                                    <div>
                                        <h3 className="text_16">원하시는 위치에<br/>선정된 상품을 진열해 드려요.</h3>
                                        <img src={icn_list_guide_3} alt="상품 진열"/>
                                    </div>
                                  </div>
                                  <button className="use_btn hover_dark" onClick={()=>this.nextStep()}>
                                      무료로 이용하기
                                  </button>
                                </section>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : ''}
                      {this.state.service_type == "01009" ? (
                        <>
                          <TargetViewIntro />
                        </>
                      ) : ''}
                      {this.state.service_type == "01005" ? (
                        <>
                          <TrendInsightIntro />
                        </>
                      ) : ''}
                      {this.state.service_type == "01010" ? (
                        <>
                          <RankingInsightIntro />
                        </>
                      ) : ''}
                    </>
                  ) : (
                    ''
                  )}

                  {/* ----------- 2단계 화면 영역 : 쇼핑몰 선택 --------------------------------------------------------------------------------------------------- */}
                  {this.state.step == 2 ? (
                    this.state.service_type != "01009"
                     ?  <div style={{ backgroundColor: this.state.service_color }}>
                          <div
                            id="page-wrapper"
                            className="container"
                            style={{ maxWidth:'100%', minHeight: '100vh', display: 'flex', justifyContent: 'center' }}
                            //style={{ padding:'130px 0px 0px 220px', backgroundColor: '#dffafa', minHeight: '100%' }}
                          >
                            <div className="shop_add_area" style={{alignSelf:'center'}}>
                              <div id="list_wrap">
                                <section className="list_guide_main list_guide_main_a" style={{height:"auto"}}>
                                  <div className="list_guide_main_2_wrap">
                                    <h2 className="text_medium">
                                      {this.state.service_name} 서비스를 이용할 쇼핑몰을 선택해주세요.
                                    </h2>
                                    <div className="list_guide_main_2">
                                      {this.state.shops != null && this.state.shops.length > 0 ? (
                                        this.state.shops.map((shop, idx) => (
                                          <>
                                            <input className="list_guide_radio" type="checkbox" id={shop.shop_no} name="list_guide_main_radio" onChange={(e) => this.handleChangeSelectShop(e, shop.shop_no, shop.shop_name, shop.solution_type_cd)}/>
                                            <label for={shop.shop_no}>
                                                <svg className="check_off" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="12" cy="12" r="12" fill="white"/>
                                                    <path d="M17.7462 8.59615C17.9143 8.77374 18.005 9.01078 17.9986 9.25524C17.9921 9.49969 17.8888 9.73158 17.7115 9.9L10.95 16.3615C10.7752 16.526 10.5438 16.6169 10.3039 16.6154C10.0674 16.6163 9.83984 16.5253 9.66923 16.3615L6.28847 13.1308C6.1947 13.049 6.11844 12.949 6.06427 12.837C6.0101 12.725 5.97915 12.6031 5.97327 12.4788C5.96739 12.3545 5.9867 12.2303 6.03005 12.1137C6.07339 11.997 6.13988 11.8903 6.22551 11.8C6.31113 11.7097 6.41413 11.6377 6.52832 11.5882C6.6425 11.5387 6.76551 11.5129 6.88995 11.5121C7.01439 11.5114 7.13769 11.5359 7.25244 11.584C7.36719 11.6322 7.47103 11.703 7.5577 11.7923L10.3039 14.4115L16.4423 8.56154C16.6199 8.39342 16.8569 8.30265 17.1014 8.30914C17.3458 8.31563 17.5777 8.41885 17.7462 8.59615ZM24 12C24 14.3734 23.2962 16.6934 21.9776 18.6668C20.6591 20.6402 18.7849 22.1783 16.5922 23.0865C14.3995 23.9948 11.9867 24.2324 9.65892 23.7694C7.33115 23.3064 5.19295 22.1635 3.51472 20.4853C1.83649 18.807 0.693605 16.6689 0.230582 14.3411C-0.232441 12.0133 0.00519936 9.60051 0.913451 7.4078C1.8217 5.21508 3.35977 3.34094 5.33316 2.02236C7.30655 0.703788 9.62663 0 12 0C15.1807 0.0060992 18.2294 1.27234 20.4785 3.52146C22.7277 5.77057 23.9939 8.81927 24 12ZM22.1538 12C22.1538 9.99176 21.5583 8.02862 20.4426 6.35882C19.3269 4.68903 17.7411 3.38759 15.8857 2.61907C14.0303 1.85055 11.9887 1.64947 10.0191 2.04126C8.04943 2.43305 6.24019 3.40011 4.82015 4.82015C3.40011 6.24019 2.43305 8.04943 2.04126 10.0191C1.64947 11.9887 1.85055 14.0303 2.61907 15.8857C3.38759 17.7411 4.68904 19.3269 6.35883 20.4426C8.02862 21.5583 9.99176 22.1538 12 22.1538C14.692 22.1508 17.2729 21.08 19.1765 19.1765C21.08 17.2729 22.1508 14.692 22.1538 12Z" fill="#D1D1D1"/>
                                                </svg> 
                                                <svg className="check_on" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="12" cy="12" r="12" fill="#333333"/>
                                                    <path d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21508 0.913451 7.4078C0.00519941 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6934 24 14.3734 24 12C23.9939 8.81927 22.7277 5.77057 20.4785 3.52146C18.2294 1.27234 15.1807 0.0060992 12 0ZM17.7115 9.9L10.95 16.3615C10.7752 16.526 10.5438 16.6169 10.3038 16.6154C10.1865 16.6171 10.0701 16.5955 9.96115 16.5519C9.85223 16.5084 9.75302 16.4436 9.66923 16.3615L6.28847 13.1308C6.1947 13.049 6.11844 12.949 6.06427 12.837C6.0101 12.725 5.97915 12.6031 5.97327 12.4788C5.96739 12.3545 5.9867 12.2303 6.03005 12.1137C6.07339 11.997 6.13988 11.8903 6.22551 11.8C6.31113 11.7097 6.41413 11.6377 6.52832 11.5882C6.6425 11.5387 6.76551 11.5129 6.88995 11.5121C7.01439 11.5114 7.13769 11.5359 7.25244 11.584C7.36719 11.6322 7.47103 11.703 7.5577 11.7923L10.3038 14.4115L16.4423 8.56154C16.6218 8.40495 16.8549 8.3238 17.0928 8.33505C17.3307 8.34631 17.5551 8.4491 17.719 8.62194C17.8829 8.79477 17.9736 9.02428 17.9722 9.26246C17.9708 9.50065 17.8774 9.72908 17.7115 9.9Z" fill="#10CFC9"/>
                                                </svg>
                                                <h3 className="text_small">
                                                  {shop.shop_name} {shop.primary_domain}
                                                </h3>
                                            </label>
                                          </>
                                        )))
                                        : ''
                                      }
                                    </div>
                                    <button className={(this.state.service_type == '01009' || this.state.service_type == '01005' || this.state.service_type == '01010') ? 'common_button hover_dark' : 'hover_mint'}
                                            style={{ width : 'fit-content', padding : '0px 20px 0px 20px'}}
                                      onClick={() => this.onClickSelectShopSaveButton()}>
                                        {this.state.service_name} 무료체험 하기
                                    </button>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </div>
                    :   <TargetViewIntro/>  
                  ) : (
                    ''
                  )}

                  {/* ----------- 3단계 화면 영역 : 연동 대기 화면 --------------------------------------------------------------------------------------------------- */}
                  {this.state.step == 3 ? (
                    <>
                      {this.state.service_type == "01002" ? (
                        <div style={{ backgroundColor: this.state.service_color }}>
                          <div
                            id="page-wrapper"
                            className="container"
                            style={{ maxWidth:'100%', minHeight: '100vh', display: 'flex', justifyContent: 'center' }}
                            //style={{ padding:'130px 0px 0px 220px', backgroundColor: '#dffafa', minHeight: '100%' }}
                          >
                            <div className="shop_add_area" style={{alignSelf:'center'}}>
                              <div id="list_wrap">
                                <section className="list_guide_main list_guide_main_a" style={{height:"auto"}}>
                                  <div className="list_guide_main_3_wrap">
                                    <h2 className="text_medium">
                                      진열 서비스를 시작하기 위해 <br/>
                                      고객님 쇼핑몰의 데이터를 분석하고 있습니다.
                                    </h2>
                                    <div className="list_guide_main_3">
                                      <div>
                                        <h3 className="text_small">
                                          {/* 분석은 5시간 정도가 걸립니다. <br/>
                                          분석이 완료되면 카카오톡 메시지로 알림 드릴게요. */}
                                          분석은 5시간 이내로 완료됩니다.<br/>
                                          분석이 완료되면 진열서비스화면이 나옵니다.<br/>
                                          화면이 나오지 않으면 새로고침 (f5)를 눌러주세요.
                                        </h3>
                                      </div>
                                    </div>
                                    <button className="list_guide_1_btn hover_mint" onClick={() => {this.onClickUserGuide()}}>
                                      서비스 이용 가이드 살펴보기
                                    </button>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : ''}
                    </>
                  ) : (
                    ''
                  )}
                {/* </section>
              </div>
            </div>
          </div>
        </div> */}
        {/* 진열여부 설정 팝업 
          1. popup_id : "common_popup1"		(div id 부여)
          2. width : "450px" 					(최소 450px)
          3. content : "팝업 내용<br/>예제 입니다."
          4. button_list : 
            [
              {
                title: "버튼명",			  // 버튼명
                type: "1",					// 버튼 (컬러)타입 : "1" (#10CFC9), "0" (#FFFFFF) 
                event_method: () => {
                  //이벤트 함수 구현
                  ...
                }
              },
            ]
          5. button_close : 닫기 함수 구현
        */}
        {
          (this.state.common_popup1 === true)
          ? <CommonPopup 
            popup_id={"common_popup1"}
            width={"465px"}
            content={this.state.common_popup1_content}
            button_list={this.state.common_popup1_button_list}
            popup_close={() => this.setState({common_popup1: false})}
          />
          : ''
        }
      </>
    );
  }
}

import React from "react";
import $ from "jquery";

let clicked_span = false;

/**
 * @param {*} width 셀렉트박스 가로 넓이 (예: "378px"), null 이면 기본값 적용
 * @param {*} height 셀렉트박스 세로 높이 (예: "50px"), null 이면 기본값 적용
 * @param {*} padding 셀렉트박스 패딩 (예: "14px 16px"), null 이면 기본값 적용
 * @param {*} pageWrapperName 셀렉트박스들이 모여있는 영역의 id 값(여러개의 selectBox 사용시 하나의 selectBox만 열려있도록 유지함), null이면 기본값 적용
 * @param {*} id 컴포넌트를 식별할 수 있는  id명, null 가능
 * @param {*} list List<map> 형태의 원본 리스트 그대로 사용
 * @param {*} disableOptions 리스트 중 비활성화 될 옵션, optionValuetName(or list)와 매칭되는 값이 리스트로 존재
 * @param {*} optionTexttName list에서 option의 text로 사용 할 키
 * @param {*} optionValuetName list에서 option의 Value로 사용 할 키
 * @param {*} onlyValue list 요소가 object 형태가 아닌 경우, null이면 false  ex) [0,1,2,3,4]
 * @param {*} onClickOption 옵션 클릭 이벤트 (예: onClickOption (text, value)), null 이면 아무 동작 하지 않음
 * @param {*} selectedText 선택 된 옵션의 text
 * @param {*} selectedValue 선택 된 옵션의 value
 * @param {*} placeHolder 아무것도 선택 하지 않았을 때 보여 줄 문구
 * @param {*} tabIndex 탭 포커싱 속성 부여(0일때 포커싱 가능)
 * @param {*} disabled 셀렉트박스 비활성화 여부, null이면 기본값 false
 * @param {*} disabledColor 비활성화시 셀렉트박스 컬러, null이면 기본값
 * @param {*} outlineColor tap 활성화시 색상, null이면 기본값
 * @param {*} selectAreaMaxHeight 셀렉트 열리는 영역 최대 높이값, null이면 기본값 적용
 * @param {*} error 에러(빨간 테두리) 활성화 여부, null이면 false
 */
class SelectBoxV2 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			is_option_open: false,
        }
    }

	componentDidMount() {

		let this_component = this;

		$(document).ready(function(){
			$('html').click(function(e) {
				if(!$(e.target).hasClass("select_area")) { // 바깥영역 클릭
					this_component.closeOption(e); // 옵션 닫힘
				}
				// 아래 코드만 사용해도 작동 됨
				// this_component.closeOption(e); // 옵션 닫힘
			});

			// 다른 셀렉트 박스는 닫기
			$('#'+this_component.props.pageWrapperName).on("click",'.select_area',function(e) {
				if (this_component.props.id != null) {
					if (!$(e.target).hasClass("select_option") && $(e.target).attr('id') !== this_component.props.id) { // select_option을 클릭하지 않았으며, 현제 클릭한 id와 넘겨 받은 id가 다른 경우
						this_component.closeOption(e); // 옵션 닫힘
					}
				} 
			});

			// 선택된 글자(span) 클릭할 경우 (옵션이 열려있을때만 클릭 된다)
			$('#'+this_component.props.pageWrapperName).on("click",'.select_txt',function(e) {
				if (this_component.state.is_option_open) {
					this_component.closeOption(e, true); // 옵션 닫힘
				}
			});
		});
	}

	componentDidUpdate() {

	}

	openToggleOption(e) {
		e.preventDefault();
		e.stopPropagation(); // 상위 클릭 이벤트 막기
		let {is_option_open} = this.state;
		if (this.state.is_option_open) {
			is_option_open = false;
		} else {
			if(!clicked_span){
				is_option_open = true;
			}
		}
		this.setState({
			is_option_open: is_option_open,
        }, () => {
			clicked_span = false;
			if(is_option_open){
				// 버튼 스크롤 중앙
				if(this.props.onlyValue){
					for(var i=0;i<this.props.list.length;i++){
						if(this.props.selectedValue != null && this.props.list[i] == this.props.selectedValue){
							let option_position = $("#select_option_area").height()/2 - 19;
							$("#select_option_area").scrollTop($("#select_option_"+i).position().top - option_position);
						}
					}
				} else{
					for(var i=0;i<this.props.list.length;i++){
						if(this.props.selectedValue != null && this.props.list[i][this.props.optionValuetName] == this.props.selectedValue){
							let option_position = $("#select_option_area").height()/2 - 19;
							$("#select_option_area").scrollTop($("#select_option_"+i).position().top - option_position);
						}
					}
				}
			}
		});
	}

	closeOption(e, span) {
		if(span){
			clicked_span = true;
		}
		this.setState({
			is_option_open: false,
        });
	}

	render () {
		const {width, height, padding, id, className, disabled, disabledColor,
			outlineColor, error, tabIndex, placeHolder,
			selectAreaMaxHeight, list, onlyValue, disableOptions,
			selectedValue, onClickOption, optionValuetName,
			optionTexttName} = this.props;
		
		const selectedText = this.props.selectedText ? this.props.selectedText : onlyValue ? selectedValue : getSelectBoxSelectIdx(list,optionTexttName,optionValuetName,selectedValue);
		return (
			<>
				<div class="style_guide_v2">
					<input class="select" type="checkbox" name="select"/>
					<div id={id} class={className + " select_area" + (disabled || error ? "" : " select_able") + (error ? " select_error":"")} name="select_area" tabIndex={tabIndex != null && !disabled ? tabIndex : ""}
						onClick={disabled ? () =>  {} : e => this.openToggleOption(e)}
						onKeyDown={disabled ? () =>  {} :
							e => {
								if (e.keyCode == 32) { // 스페이스바
									this.openToggleOption(e); // 옵션 토글 (열림 or 닫힘)
								} else if (e.keyCode == 27) { // ESC
									this.closeOption(e); // 옵션 닫힘.
								}
							}}
						style={{
							width: width,
							height: height,
							padding: padding,
							display: "flex",
							cursor: disabled ? "default" : "pointer",
							backgroundColor: disabled ? disabledColor ? disabledColor :"#f7f7f7" :"",
							outlineColor: outlineColor ? outlineColor : "aqua",
							borderColor: error ? "#FF085E" : this.state.is_option_open ? "#8B8B8B" : "",
						}}>
						{
							selectedText
							?	disabled
								? <span name="selected_text_area" class="select_txt" style={{color:"#b7bdC3", alignSelf:"center"}}>{selectedText}</span>
								:	selectedValue == null
										? <span name="selected_text_area" class="select_txt" style={{color:"#878b90", alignSelf:"center"}}>{selectedText}</span>
										: <span name="selected_text_area" class="select_txt" style={{color:"#3A3A3A", alignSelf:"center"}}>{selectedText}</span>
							: <span name="selected_text_area" class="select_txt" style={{alignSelf:"center"}}>{placeHolder}</span>
						}
						{
							this.state.is_option_open
							?
								<div id="select_option_area" class="select_option_area" style={selectAreaMaxHeight ? {top: height,maxHeight:selectAreaMaxHeight} : {top: height,}}>
									{
										list != null && list.length > 0
										?	list.map((obj, idx) => (
												onlyValue // 리스트에 원시타입의 값이 들어있어서 obj 그대로 사용 가능한 경우 (ex)[0,1,2,3,4])
												?	disableOptions != null && disableOptions.length > 0 && disableOptions.indexOf(obj) >= 0
													? <button class="select_none_option" type="button" id={"select_option_"+idx} index={idx}>{obj}</button>
													:	selectedValue != null && selectedValue == obj
														? <button key={idx} class="select_option" type="button" id={"select_option_"+idx} index={idx} style={{backgroundColor: "#f7f7f7"}} onClick={onClickOption ? e => onClickOption(obj,obj,id) : ""}>{obj}</button>
														: <button key={idx} class="select_option" type="button" id={"select_option_"+idx} index={idx} onClick={onClickOption ? e => onClickOption(obj,obj,id) : ""}>{obj}</button>
												:	disableOptions != null && disableOptions.length > 0 && disableOptions.indexOf(obj[optionValuetName]) >= 0
													? <button class="select_none_option" type="button" id={"select_option_"+idx} index={idx}>{obj[optionTexttName]}</button>
													:	selectedValue != null && selectedValue == obj[optionValuetName]
														? <button key={idx} class="select_option" type="button" id={"select_option_"+idx} index={idx} style={{backgroundColor: "#f7f7f7"}} onClick={onClickOption ? e => onClickOption(obj[optionTexttName],obj[optionValuetName],id) : ""}>{obj[optionTexttName]}</button>
														: <button key={idx} class="select_option" type="button" id={"select_option_"+idx} index={idx} onClick={onClickOption ? e => onClickOption(obj[optionTexttName],obj[optionValuetName],id) : ""}>{obj[optionTexttName]}</button>
											))
										:	<button class="select_none_option" type="button">선택할 옵션이 없습니다.</button>
									}
								</div>
							:	
								""
						}
					</div>
				</div>
			</>
		);
	}
}

SelectBoxV2.defaultProps = {
    width: "378px",
	height: "50px",
	padding: "14px 36px 14px 16px",
	pageWrapperName : "common-page-wrapper",
	optionTexttName : "",
	optionValuetName : "",
	placeHolder : "",
}

export default SelectBoxV2;

/** 셀렉트박스 선택된 값 idx
 * @param {*} list 셀렉트 박스에서 사용하는 리스트
 * @param {*} text_name 선택된 값에 대한 텍스트 필드명
 * @param {*} value_name 셀렉트 박스 선택된 값 필드명
 * @param {*} value 셀렉트 박스 선택된 값
*/
export function getSelectBoxSelectIdx(list, text_name, value_name, value){
	if(list != null && list != "" && list.length > 0){
		for(var i=0;i<list.length;i++){
			if(list[i][value_name] == value){
				return list[i][text_name];
			}
		}
	}

	return "";
}
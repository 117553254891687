import React, { useCallback, useState } from 'react';
import $ from 'jquery';
import Pagination from "react-js-pagination";
import http from '../modules/api';

import ConfirmDialogV2 from '../modules/dialogs/ConfirmDialogV2';
import BasicDialogV2 from '../modules/dialogs/BasicDialogV2';
import ToggleButton from '../modules/common/ToggleButton';
import * as StringUtil from '../modules/utils/StringUtil';
import * as StyleUtil from '../modules/utils/StyleUtil';
import * as CurationServiceVariable from '../modules/CurationServiceVariable';

let window_scrollTop; // 스크롤 위치

export class Curationlist extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
            /** 큐레이션 목록 */
            curation_list: [],
            /** 생성되어있는 큐레이션 결과 수 */
            curation_result_cnt : 0,

            /** 큐레이션 전체 수 */
            totalCnt : 0,
            /** 페이징의 페이지 번호 (하단에 페이징 버튼과 직결) */
            activePage: 1,
            /** 페이징시 큐레이션 시작 수 */
            start_idx: 0,
            /** 페이징시 화면에 나올 큐레이션 수 */
		    row_cnt: 10,

            /** 기본 다이얼로그 */
            basic_dialog_show_state : false,
            basic_dialog_content: "",
            basic_dialog_button_list: [],
            /** 노출 여부 다이얼로그 보이는 상태 */
            confirm_dialog_show_state : false,
            confirm_dialog_content : "",
            confirm_dialog_button_list : [],

            /** 토글 애니메이션 제어 */
            toggleAnimation: true,

            /** 응답 데이터 없음 상태값 */
            noResponse : false,
        }
    }

    componentWillMount(){

        const {location} = this.props; 
        if (location.state != null && location.state.curationListState != null) { 
            this.setState(location.state.curationListState, () => {
                this.loadCurationList(); // 큐레이션 목록 조회
            }); // history에 저장된 state 사용
            window.history.replaceState({}, document.title); // location.state 초기화
        } else {
            this.loadCurationList(); // 큐레이션 목록 조회
        }
    }

    componentDidMount(){
        
    }

    componentDidUpdate(){

    }

    /** 큐레이션 목록 조회 */
    async loadCurationList(){
        await http.get(
                        "/rest/shop/curations"
                        +"?start_idx="+this.state.start_idx
                        +"&row_cnt="+this.state.row_cnt
                    )
		.then(res => {
			if (res.data.code == "200") {
                this.setState({
                    totalCnt : res.data.total_cnt,
                    curation_list : res.data.curation_list,
                    noResponse: (
                        res.data.curation_list != null && res.data.curation_list.length > 0 
                        ?   false 
                        :   res.data.curation_list == null
                            ?   false
                            :   true
                    ),
                }, () => {
                    // 페이징시 토글 버튼에 애니메이션이 없도록 한것을 해제하는 부분
                    // 너무 빨리 해제하면 적용되지 않기 때문에 setTimeout을 사용
                    setTimeout(() => {
                        this.setState({
                            toggleAnimation : true,
                        });
                    }, 100);
                });
			} else{
                this.setState({
                    toggleAnimation : true,
                    noResponse: true,
                });
                alert(CurationServiceVariable.CURATION+" "+CurationServiceVariable.LIST+" 조회에 실패하였습니다.")
            }
		})
		.catch(error => {
            this.setState({
                toggleAnimation : true,
                noResponse: true,
            });
		 });

    }

    /** 큐레이션 결과 목록 조회 */
    async loadCurationResultList(){
        await http.get(
                        "/rest/shop/curation_result"
                        +"?start_idx="+ 0
                        +"&row_cnt="+ (-1)
                    )
		.then(res => {
			if (res.data.code == "200") {
                this.setState({
                    curation_result_cnt : res.data.total_cnt,
                });
			} else{
                alert(CurationServiceVariable.CURATION+" "+CurationServiceVariable.RESULT+" "+CurationServiceVariable.LIST+" 조회에 실패하였습니다.")
            }
		})
		.catch(error => {
		 });

    }

    /** 큐레이션 생성 */
    async addCurationList(){
        await http.get(
            "/rest/shop/curation_result"
            +"?start_idx="+ 0
            +"&row_cnt="+ (-1)
        )
        .then(res => {
        if (res.data.code == "200") {
            if(res.data.total_cnt>0){
                this.props.history.push(
                    {
                        pathname: `/shop/curation/info`,
                    }	
                )
            } else{
                window_scrollTop = StyleUtil.onPopupNoneScroll();
    
                this.setState({
                    basic_dialog_show_state: true,
                    basic_dialog_content: "사용할 수 있는 "+CurationServiceVariable.RESULT+" "+CurationServiceVariable.INFO+"가 없어요.<br/>"+CurationServiceVariable.CURATION+" "+CurationServiceVariable.RESULT+" "+CurationServiceVariable.INFO+"를 생성해주세요.",
                    basic_dialog_button_list: [
                        {
                            title: "확인",
                            type: "1",
                            event_method: () => {
                                //팝업 닫기
                                this.setState({ basic_dialog_show_state: false });
                                StyleUtil.clearPopupNoneScroll(window_scrollTop);
                            }
                        }
                    ]
                });
            }
        } else{
            alert(CurationServiceVariable.CURATION+" "+CurationServiceVariable.RESULT+" "+CurationServiceVariable.LIST+" 조회에 실패하였습니다.")
        }
        })
        .catch(error => {
        });
    }

    /** 큐레이션 상세 페이지로 이동 */
    onClickCurationPageLink(curation_seq) {
        this.props.history.push(
            {
                pathname: `/shop/curation/info`,
                search:`?curation_seq=`+curation_seq,
                state: {curationListState: JSON.parse(JSON.stringify(this.state))}
            }	
        )
    }

    /** 노출 여부 변경 */
    changeShowCuration(e){
        window_scrollTop = StyleUtil.onPopupNoneScroll();

        const idx = e.target.value;
        const curation = this.state.curation_list[idx];

        let confirm_dialog_content = "";
        if(curation.display_yn == "N"){
            confirm_dialog_content = CurationServiceVariable.CURATION+" "+curation.curation_title+"이(가) 홈페이지에 노출되도록 변경 하시겠어요?";
        } else{
            confirm_dialog_content = CurationServiceVariable.CURATION+" "+curation.curation_title+"이(가) 홈페이지에 노출되지 않도록 변경 하시겠어요?";
        }

        this.setState({
            confirm_dialog_show_state: true,
            confirm_dialog_content: confirm_dialog_content,
            confirm_dialog_button_list: [
                {
                    title: "네",
                    type: "1",
                    event_method: () => {

                        const config = {
                            headers: {
                                'Content-Type': 'application/json',
                                },
                        };
                        const url = '/rest/shop/curation/display';
                        const body = {
                            curation_seq :  curation.curation_seq,
                            display_yn : curation.display_yn == "N" ? "Y" : "N",
                        }
                        http
                        .post(url, body, config)
                        .then((res) => {
                            if (res.data.code == "200") {
                                let curation_list = this.state.curation_list;
                                curation_list[idx].display_yn = curation.display_yn == "N" ? "Y" : "N";
                                //팝업 닫기
                                this.setState({
                                    confirm_dialog_show_state: false,
                                    curation_list : curation_list,
                                });
                                StyleUtil.clearPopupNoneScroll(window_scrollTop);
                                
                            } else {
                                //팝업 닫기
                                this.setState({ confirm_dialog_show_state: false });
                                StyleUtil.clearPopupNoneScroll(window_scrollTop);
                            }
                        })
                        .catch((error) => {
                            this.setState({ confirm_dialog_show_state: false });
                            StyleUtil.clearPopupNoneScroll(window_scrollTop);
                        });
                    }
                },
                {
                    title: "아니요",
                    type: "0",
                    event_method: () => {
                        this.setState({ confirm_dialog_show_state: false });
                        StyleUtil.clearPopupNoneScroll(window_scrollTop);
                    }
                }
            ]
        });
    }

    /** 페이지 변경 */
    handlePageChange(pageNumber) {

		this.setState({
            activePage: pageNumber,
            start_idx : (pageNumber-1) * this.state.row_cnt,
            toggleAnimation : false,
        }, () => {
            this.loadCurationList();
        });
    }

    render() {
        return (
            <>
                <div id='common-page-wrapper'>
                    <div id="wrap">
                        <section class="quration_list style_guide_v2" id="quration_list">
                            <div class="quration_list_title">
                                <h2 class="text_20">{CurationServiceVariable.CURATION} {CurationServiceVariable.LIST}</h2>
                                <button class="text_13 hover_mint" onClick={() => {this.addCurationList()}}>{CurationServiceVariable.CURATION} {CurationServiceVariable.CREATE}</button>
                            </div>
                            <div class="quration_list_table_wrap">
                                <table class="quration_list_table">
                                    <thead>
                                        <tr class="text_13">
                                            <th>번호</th>
                                            <th>{CurationServiceVariable.CURATION} {CurationServiceVariable.TITLE}</th>
                                            <th>조회 수</th>
                                            <th>진행 수</th>
                                            <th>상품 조회수</th>
                                            <th>{CurationServiceVariable.SHARE+" 수"}</th>
                                            <th>노출여부</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.curation_list.length > 0
                                        ?
                                            this.state.curation_list.map((obj, idx) => (
                                            <>
                                                <tr class="text_13">
                                                    <td class="table_number" onClick={() => this.onClickCurationPageLink(obj.curation_seq)}>{obj.curation_seq}</td>
                                                    <td class="table_name" onClick={() => this.onClickCurationPageLink(obj.curation_seq)}><span>{obj.curation_title}</span></td>
                                                    <td class="table_click" onClick={() => this.onClickCurationPageLink(obj.curation_seq)}>{StringUtil.numberComma(obj.hit_cnt)}</td>
                                                    <td class="table_reaction" onClick={() => this.onClickCurationPageLink(obj.curation_seq)}>
                                                        {StringUtil.divPercent(obj.result_cnt,obj.hit_cnt,1)+"%("+obj.result_cnt+"회)"}
                                                    </td>
                                                    <td onClick={() => this.onClickCurationPageLink(obj.curation_seq)}>{StringUtil.numberComma(obj.product_hit_cnt)}</td>
                                                    <td class="table_share" onClick={() => this.onClickCurationPageLink(obj.curation_seq)}>{StringUtil.numberComma(obj.share_cnt)}</td>
                                                    <td class="table_show">
                                                        <ToggleButton
                                                            id={"toggle_"+idx}
                                                            value={idx}
                                                            checked={obj.display_yn == "Y"}
                                                            onClickOption={this.changeShowCuration.bind(this)}
                                                            toggleAnimation={this.state.toggleAnimation}
                                                        />
                                                    </td>
                                                </tr>
                                            </>
                                            ))
                                        : ""}
                                    </tbody>
                                </table>
                                {this.state.curation_list.length == 0 && this.state.noResponse == true
                                ?<div class='quration_list_table_empty'>생성된 {CurationServiceVariable.CURATION} {CurationServiceVariable.INFO}가 없습니다.<br/>{CurationServiceVariable.CURATION}을 생성해주세요.</div>
                                :""}
                            </div>
                            {
                                this.state.noResponse == false && Number(this.state.totalCnt) > 0
                                ?
                                <div class="common_pagination" style={{maxWidth: '1241px'}}>
                                    <Pagination
                                        firstPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.35627 13.3562C8.26147 13.4501 8.13344 13.5028 8.00002 13.5028C7.8666 13.5028 7.73857 13.4501 7.64377 13.3562L2.64377 8.35623C2.55136 8.26066 2.4997 8.13292 2.4997 7.99998C2.4997 7.86704 2.55136 7.7393 2.64377 7.64373L7.64377 2.64373C7.74092 2.564 7.86425 2.52325 7.98977 2.52942C8.1153 2.53558 8.23405 2.58822 8.32291 2.67709C8.41178 2.76595 8.46442 2.8847 8.47058 3.01023C8.47675 3.13575 8.436 3.25908 8.35627 3.35623L3.70627 7.99998L8.35627 12.6437C8.45016 12.7385 8.50283 12.8666 8.50283 13C8.50283 13.1334 8.45016 13.2614 8.35627 13.3562ZM7.64377 7.64373C7.55136 7.7393 7.4997 7.86704 7.4997 7.99998C7.4997 8.13292 7.55136 8.26066 7.64377 8.35623L12.6438 13.3562C12.7409 13.436 12.8643 13.4767 12.9898 13.4705C13.1153 13.4644 13.234 13.4117 13.3229 13.3229C13.4118 13.234 13.4644 13.1153 13.4706 12.9897C13.4767 12.8642 13.436 12.7409 13.3563 12.6437L8.70627 7.99998L13.3563 3.35623C13.436 3.25908 13.4767 3.13575 13.4706 3.01023C13.4644 2.8847 13.4118 2.76595 13.3229 2.67709C13.234 2.58822 13.1153 2.53558 12.9898 2.52942C12.8642 2.52325 12.7409 2.564 12.6438 2.64373L7.64377 7.64373Z" fill="#8B8B8B"/>
                                        </svg>}
                                        prevPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.50002 7.99998C4.50182 7.86746 4.55309 7.74039 4.64377 7.64373L9.64377 2.64373C9.74092 2.564 9.86425 2.52325 9.98977 2.52942C10.1153 2.53558 10.234 2.58822 10.3229 2.67709C10.4118 2.76595 10.4644 2.8847 10.4706 3.01023C10.4767 3.13575 10.436 3.25908 10.3563 3.35623L5.70627 7.99998L10.3563 12.6437C10.436 12.7409 10.4767 12.8642 10.4706 12.9897C10.4644 13.1153 10.4118 13.234 10.3229 13.3229C10.234 13.4117 10.1153 13.4644 9.98977 13.4705C9.86425 13.4767 9.74092 13.436 9.64377 13.3562L4.64377 8.35623C4.55309 8.25957 4.50182 8.1325 4.50002 7.99998Z" fill="#8B8B8B"/>
                                        </svg>}
                                        nextPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.5 8.00002C11.4982 8.13254 11.4469 8.25961 11.3562 8.35627L6.35623 13.3563C6.25908 13.436 6.13575 13.4767 6.01023 13.4706C5.8847 13.4644 5.76595 13.4118 5.67709 13.3229C5.58822 13.234 5.53558 13.1153 5.52942 12.9898C5.52325 12.8642 5.564 12.7409 5.64373 12.6438L10.2937 8.00002L5.64373 3.35627C5.564 3.25912 5.52325 3.13579 5.52942 3.01026C5.53558 2.88474 5.58822 2.76599 5.67709 2.67712C5.76596 2.58826 5.8847 2.53562 6.01023 2.52946C6.13575 2.52329 6.25908 2.56404 6.35623 2.64377L11.3562 7.64377C11.4469 7.74043 11.4982 7.8675 11.5 8.00002Z" fill="#8B8B8B"/>
                                        </svg>}
                                        lastPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.64373 2.64377C7.73853 2.54988 7.86656 2.49721 7.99998 2.49721C8.1334 2.49721 8.26143 2.54988 8.35623 2.64377L13.3562 7.64377C13.4486 7.73934 13.5003 7.86708 13.5003 8.00002C13.5003 8.13296 13.4486 8.2607 13.3562 8.35627L8.35623 13.3563C8.25908 13.436 8.13575 13.4767 8.01023 13.4706C7.8847 13.4644 7.76595 13.4118 7.67709 13.3229C7.58822 13.234 7.53558 13.1153 7.52942 12.9898C7.52325 12.8642 7.564 12.7409 7.64373 12.6438L12.2937 8.00002L7.64373 3.35627C7.54984 3.26147 7.49717 3.13344 7.49717 3.00002C7.49717 2.8666 7.54984 2.73857 7.64373 2.64377ZM8.35623 8.35627C8.44864 8.2607 8.5003 8.13296 8.5003 8.00002C8.5003 7.86708 8.44864 7.73934 8.35623 7.64377L3.35623 2.64377C3.25908 2.56404 3.13575 2.52329 3.01022 2.52946C2.8847 2.53562 2.76595 2.58826 2.67709 2.67712C2.58822 2.76599 2.53558 2.88474 2.52942 3.01026C2.52325 3.13579 2.564 3.25912 2.64373 3.35627L7.29373 8.00002L2.64373 12.6438C2.564 12.7409 2.52325 12.8642 2.52942 12.9898C2.53558 13.1153 2.58822 13.234 2.67709 13.3229C2.76595 13.4118 2.8847 13.4644 3.01023 13.4706C3.13575 13.4767 3.25908 13.436 3.35623 13.3563L8.35623 8.35627Z" fill="#8B8B8B"/>
                                        </svg>}
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.row_cnt}
                                        totalItemsCount={this.state.totalCnt}
                                        pageRangeDisplayed={5}
                                        onChange={this.handlePageChange.bind(this)}
                                    />
                                </div>
                                : ""
                            }
                        </section>
                        {/* 기본 안내 다이얼로그 */
                            (this.state.basic_dialog_show_state === true)
                            ? <BasicDialogV2
                                content={this.state.basic_dialog_content}
                                button_list={this.state.basic_dialog_button_list}
                            />
                            : ''
                        }
                        {/* 확인 취소 다이얼로그 */
                            (this.state.confirm_dialog_show_state === true)
                            ? <ConfirmDialogV2
                                content={this.state.confirm_dialog_content}
                                button_list={this.state.confirm_dialog_button_list}
                                popup_close={() => {
                                    this.setState({confirm_dialog_show_state: false});
                                    StyleUtil.clearPopupNoneScroll(window_scrollTop);
                                }}
                            />
                            : ''
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default Curationlist;
import React from 'react';
import axios from 'axios';
import $ from 'jquery';
import http from '../modules/api';


export class PwdMod extends React.Component {
	
	state = {
			passwordPre: '',
			passwordChg: '',
			passwordChgConfirm: ''
	}
	
	handleText = (e, key) => {
	    // computed property
		this.setState({[key]: e.target.value});
	}
	
	componentDidMount() {
		
	}
	
	//수정완료
	onClickPwdModButton = () => {
		const sendForm = {...this.state};				
		//1. 패스워드 패턴 체크
		if (!this.doesPasswordRule()) {
			alert("유효하지 않은 비밀번호 입니다.(6~15자리, 영문, 숫자조합)");
			return false;
	    }
		//2. 패스워드 체크
		if (!this.doesPasswordMatch(sendForm.password, sendForm.passwordConfirm)) {
			alert("변경할 패스워드가 일치하지 않습니다!");
			return false;
	    }
		//3. 수정
		http.post("/rest/shop/userPwdModify", {...this.state})
			 .then(function (res) {
				 alert(res.data.msg);
			 })
			 .catch(
				 error => { console.log("패스워드 변경 에러!!"); console.log(error); }
			 );
	};
	
	//1-1. 패스워드 패턴 체크 메세지
	passwordRule() {
		const { password } = this.state;
		if (password) {
			if (!this.doesPasswordRule()) {
				return (	
						<h5 style={{color:'red'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 유효하지 않은 비밀번호 입니다.(6~15자리, 영문, 숫자조합)</h5>
				);
			}
		}
	}
	
	//1-2. 패스워드 패턴 
	// doesPasswordRule(password) {
	// 	const passRule = /^.*(?=.{6,15})(?=.*[0-9])(?=.*[a-zA-Z]).*$/;
	// 	return passRule.test(password);
	// }

	doesPasswordRule() {
		// const passRule = /^.*(?=.{6,15})(?=.*[0-9])(?=.*[a-zA-Z]).*$/;
		// const passRule = /^.*(?=.{6,15})(?=.*[0-9])(?=.*[a-zA-Z]).*$/;
		// return passRule.test(password);
		const { password } = this.state;

		if (password == null || password == "") {
			$("#password").removeClass("wrong")
			$("#password_vaidate_check").removeClass("wrong")
			$("#password_vaidate_check_txt").text("8 ~ 20자 영문 대 소문자, 숫자, 특수문자를 사용하세요.");
			return false;
		}

		var num = password.search(/[0-9]/g);
		var eng = password.search(/[a-z]/ig);
		var spe = password.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);
	   
		if(password.length < 8 || password.length > 20){
			$("#password").addClass("wrong")
			$("#password_vaidate_check").addClass("wrong")
			$("#password_vaidate_check_txt").text("8자리 ~ 20자리 이내로 입력해주세요.");
			// alert("8자리 ~ 20자리 이내로 입력해주세요.");
			// return false;
		} else if(password.search(/\s/) != -1){
			if (!$("#password").hasClass("wrong")) {
				$("#password").addClass("wrong")
			}
			if (!$("#password_vaidate_check").hasClass("wrong")) {
				$("#password_vaidate_check").addClass("wrong")
			}
			$("#password_vaidate_check_txt").text("비밀번호는 공백 없이 입력해주세요.");
			// alert("비밀번호는 공백 없이 입력해주세요.");
			// return false;
		} else if(num < 0 || eng < 0 || spe < 0 ){
			if (!$("#password").hasClass("wrong")) {
				$("#password").addClass("wrong")
			}
			if (!$("#password_vaidate_check").hasClass("wrong")) {
				$("#password_vaidate_check").addClass("wrong")
			}
			$("#password_vaidate_check_txt").text("영문,숫자, 특수문자를 혼합하여 입력해주세요.");
			// alert("영문,숫자, 특수문자를 혼합하여 입력해주세요.");
			// return false;
		} else {
			$("#password").removeClass("wrong")
			$("#password_vaidate_check").removeClass("wrong")
			$("#password_vaidate_check_txt").text("사용 가능한 비밀번호 입니다.");
			// console.log("사용 가능한 비밀번호 입니다."); 
			// return true;
			return true;
		}
	}
	
	//2-1. 패스워드 확인 메세지
	passwordMatch() {
		// const { password, passwordConfirm } = this.state;
		// if (passwordConfirm) {
		// 	if (!this.doesPasswordMatch(password, passwordConfirm)) {
		// 		return (
		// 				<h5 style={{color:'red'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 패스워드가 일치하지 않습니다.</h5>
		// 		);
		//     }
		// }
		const { password, passwordConfirm } = this.state;
		if (passwordConfirm) {
			if (!this.doesPasswordMatch(password, passwordConfirm)) {
				if (this.doesPasswordMatch(password, this.setWhiteSpaceRemove(passwordConfirm))) {
					return (
							// <h5 style={{color:'red'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 패스워드가 일치하지 않습니다. (공백을 제거해 주세요.)</h5>
							<div id="passwordConfirm" className="vaidate_check wrong flexBox">
								<div className="icn_warning_round_blue" style={{marginRight: "10px"}}></div>
								<div id="passwordConfirm_txt">패스워드가 일치하지 않습니다. (공백을 제거해 주세요.)</div>
							</div>
					);
				}
				return (
						// <h5 style={{color:'red'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 패스워드가 일치하지 않습니다.</h5>
						<div id="passwordConfirm" className="vaidate_check wrong flexBox">
							<div className="icn_warning_round_blue" style={{marginRight: "10px"}}></div>
							<div id="passwordConfirm_txt">패스워드가 일치하지 않습니다.</div>
						</div>
				);
			} else {
				return (
						<div id="passwordConfirm" className="vaidate_check flexBox">
							<div className="icn_warning_round_blue" style={{marginRight: "10px"}}></div>
							<div id="passwordConfirm_txt">패스워드가 일치합니다.</div>
						</div>
				);
			}
		} 
	}
	
	//2-2. 패스워드 매칭 
	doesPasswordMatch(password, passwordConfirm) {
		return password === passwordConfirm;
	}

	//공백제거
	setWhiteSpaceRemove(str) {
		return str.replace(/(\s*)/g, '')
		// return str.replaceAll(' ', '')
	}
	
	render() {
		$(".pageName").text("비밀번호 변경");
		return (
			<div id='page-wrapper' className="container pw_mod_container" 
				style={{
					backgroundColor: '#ffffff', 
					minHeight:"100%",
					padding: sessionStorage.getItem("roleType") == "2216"
								|| sessionStorage.getItem("roleType") == "2217"
							? "0px" : null,
				}}>
				<div className="form_primary_title pc_page_title">비밀번호변경</div>
				<div className="mobile_top_margin"></div>
				<div className="form-group m-b-lg">
					<label>현재 패스워드</label><small className="text-navy"></small>
					<input type="password" id="passwordPre" placeholder="Enter passwordPre"
						   value={this.state.passwordPre}
					       onChange={(e) => this.handleText(e, 'passwordPre')} 
						   className="form-control"
					       required minLength="6" maxLength="15"
					/>
				</div>
				<div className="form-group m-b-lg">
					<label>변경할 패스워드</label><small className="text-navy"></small>
					<input type="password" id="password" placeholder="Enter Password"
						   value={this.state.password}
					       onChange={(e) => this.handleText(e, 'password')} 
						   className="form-control"
					       required minLength="6" maxLength="15"
					/>
					<div id="password_vaidate_check" className="vaidate_check flexBox">
						<div className="icn_warning_round_blue" style={{marginRight: "10px"}}></div>
						<div id="password_vaidate_check_txt">8 ~ 20자 영문 대 소문자, 숫자, 특수문자를 사용하세요.</div>
					</div>
					{this.doesPasswordRule()}
				</div>
				<div className="form-group m-b-lg">
					<label>패스워드 확인</label>
					<input type="password" id="passwordConfirm" placeholder="Enter PasswordConfirm"
						   value={this.state.passwordConfirm}
					       onChange={(e) => this.handleText(e, 'passwordConfirm')} 
						   className="form-control"
						   required minLength="6" maxLength="15"
					/>
					{this.passwordMatch()}
				</div>
		        <br/>
		        <div style={{textAlign:"center"}}>
					<button className="btn-auto-b" style={{height:"50px", borderRadius:"10px"}} onClick={this.onClickPwdModButton}>저장</button>
		        </div>
			</div>
		);
	}
	
}

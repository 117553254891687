import React, { useCallback, useState } from 'react';
import $ from 'jquery';
import http from '../modules/api';

import * as StringUtil from '../modules/utils/StringUtil';
import * as StyleUtil from '../modules/utils/StyleUtil';
import * as CurationServiceVariable from '../modules/CurationServiceVariable';

import BasicDialogV2 from '../modules/dialogs/BasicDialogV2';
import ConfirmDialogV2 from '../modules/dialogs/ConfirmDialogV2';
import SelectBoxV2 from '../modules/common/SelectBoxV2';

/** 스크롤 위치 */
let window_scrollTop;
/** 현재 페이지 주소값 */
let this_location;

export class CurationGen extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
            /** 큐레이션 상세 내용 */
            curation: {
                questions: [
                    {
                        is_open_yn : 'Y',
                        question_desc: "",
                        file_seq : '',
                        file_org_name : '',
                        file_path : '',
                        answers:[
                            {
                                answer_desc: "",
                                points: [
                                    {
                                        result_seq: '',
                                        point_val: '',
                                    }
                                ],
                            },
                        ],
                    },
                ],
            },
            /** 결과 리스트 */
            curation_result_list: [],

            /** 큐레이션 수정 여부 */
            is_modified : false,

            /** 확인,취소 다이얼로그 */
            confirm_dialog_show_state : false,
            confirm_dialog_content : "",
            confirm_dialog_button_list : [],
            /** 저장 다이얼로그 */
            save_dialog_show_state : false,
            save_dialog_content: "",
            save_dialog_button_list: [],
            /** 기본 다이얼로그 */
            basic_dialog_show_state : false,
            basic_dialog_content: "",
            basic_dialog_button_list: [],
        }
    }

    componentWillMount(){
        /* 뒤로가기 또는 앞으로가기 이벤트 : state 전달 */
        window.onpopstate = (event) => { // 뒤로가기 또는 앞으로가기 이벤트
            if (window.location.href.indexOf("/shop/curation/info/list") >= 0) {
                this.replaceCurationListPage();
            }
        };

        // 처음 href 주소값 저장
        this_location = window.location.href;

        // 큐레이션 조회를 위해 들어왔다면, 기존 데이터 불러오기
        const curation_seq = StringUtil.getUrlParams("curation_seq");
        if(curation_seq != null && curation_seq != ""){
            // 큐레이션 상세 조회
            this.loadCuration(curation_seq);
        }

        // 큐레이션 결과 목록 조회
        this.loadCurationResult();
    }

    componentDidMount(){

        $(document).ready(function(){
            // 질문 여닫기를 열린상태로 설정
            $("input[name='quration_gen_q_fold_check']").prop("checked",true);

            // 큐레이션 내용 높이 동적으로 변경
            StyleUtil.textareaHeightAuto("quration_gen_info_detail_textarea", 3);

            // 질문 내용 높이 동적으로 변경
            StyleUtil.textareaHeightAuto("quration_gen_q_content_input_0", 3);
        });

    }

    componentDidUpdate(){

    }

    /** 질문 여닫기
     * @param {*} e element 이벤트
     * @param {*} idx 질문 순서 번호
     */
    onClickQuestionFolder(e,idx){
        const target = "#"+e.target.id;

        // siblings은 형제 요소를 찾는 함수
        let q_content_height = $(target).siblings(".quration_gen_q_content").outerHeight(true);
        let q_thumbnail_height = $(target).siblings(".quration_gen_q_thumbnail").outerHeight(true);
        let q_answer_height = $(target).siblings(".quration_gen_q_answer_wrap").outerHeight(true);
        let q_unfold_height = q_content_height + q_thumbnail_height + q_answer_height + 72;

        let {curation} = this.state;
        let this_component = this;
        
        // 높이 설정
        if($(target).is(":checked")){ // 펼치기

            $(target).parent(".quration_gen_q").animate({
                "height":q_unfold_height
            },function(){
                $(target).parent(".quration_gen_q").css("overflow","visible");
                $(target).parent(".quration_gen_q").css({height: 'auto'});
                curation.questions[idx].is_open_yn = 'Y';
                this_component.setState({curation:curation});
            });
        }
        else{ // 접기

            $(target).parent(".quration_gen_q")
            .css("overflow","hidden")
            .animate({
                "height":72
            });
            curation.questions[idx].is_open_yn = 'N';
            this.setState({curation:curation});
        }
    }

    /** 큐레이션 상세 조회
     * @param {*} curation_seq 큐레이션 seq
     */
    async loadCuration(curation_seq){
        await http.get("/rest/shop/curation/"+curation_seq)
		.then(res => {
			if (res.data.code == "200") {
                const curation = res.data.curation;
                this.setState({
                    curation: curation,
                }, () => {
                    $("input[name='quration_gen_q_fold_check']").prop("checked",true);

                    // 큐레이션 내용 높이 동적으로 변경
                    StyleUtil.textareaHeightAuto("quration_gen_info_detail_textarea", 3);
                    for(var i=0;i<curation.questions.length;i++){
                        // 질문 내용 높이 동적으로 변경
                        StyleUtil.textareaHeightAuto("quration_gen_q_content_input_"+i, 3);
                    }
                });
			} else{
                alert(CurationServiceVariable.CURATION+" 조회에 실패하였습니다.")
                // 큐레이션 목록으로 이동
                this.goCurationListPage();
            }
		})
		.catch(error => {
            // 큐레이션 목록으로 이동
            this.goCurationListPage();
		 });

    }

     /** 큐레이션 결과 목록 조회 */
     async loadCurationResult(){
        await http.get("/rest/shop/curation_result"
                        +"?start_idx="+ 0
                        +"&row_cnt="+ (-1)
        )
		.then(res => {
			if (res.data.code == "200") {
                this.setState({
                    curation_result_list: res.data.curation_result_list,
                });
			} else{
                alert(CurationServiceVariable.CURATION+" "+CurationServiceVariable.RESULT+" "+CurationServiceVariable.LIST+" 조회에 실패하였습니다.")
                // 큐레이션 목록으로 이동
                this.goCurationListPage();
            }
		})
		.catch(error => {
            // 큐레이션 목록으로 이동
            this.goCurationListPage();
		 });

    }

    /** 큐레이션 제목 변경 */
    onChangeTitle(e){
        let {curation} = this.state;
        curation.curation_title = e.target.value;

        this.setState({
            curation : curation,
            is_modified : true,
        });
    }

    /** 큐레이션 내용 변경 */
    onChangeDesc(e){
        let {curation} = this.state;
        curation.curation_desc = e.target.value;

        let target_id = e.target.id;
        
        this.setState({
            curation : curation,
            is_modified : true,
        }, () => {
            // 입력한 textarea 높이 변경
            StyleUtil.textareaHeightAuto(target_id, 3);
        });
    }

    /** 큐레이션 이미지 업로드 */
    curationImgUpload(e){
        const files = Array.from(e.target.files);

        if (!StringUtil.CheckImagefiles(files[0].name)) {
            // 확장자 경고 팝업 띄우기
            window_scrollTop = StyleUtil.onPopupNoneScroll();

            this.setState({
                basic_dialog_show_state: true,
                basic_dialog_content: "이미지 파일만 업로드 가능합니다.<br/>(이미지 확장자 .jpg, .jpeg, .png)",
                basic_dialog_button_list: [
                    {
                        title: "확인",
                        type: "1",
                        event_method: () => {
                            //팝업 닫기
                            this.setState({ basic_dialog_show_state: false });
                            // 스크롤 고정 해제
                            StyleUtil.clearPopupNoneScroll(window_scrollTop);
                        }
                    }
                ]
            });

            return false;
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        const formData = new FormData();
        formData.append('file', files[0]); // 단일 파일
        const url = '/rest/common/uploadImage/CURATION';
        http
        .post(url, formData, config)
        .then(res => {
            let curation = this.state.curation;
            curation.file_seq = res.data.file_seq;
            curation.file_org_name = files[0].name;
            curation.file_path = URL.createObjectURL(files[0]);
            curation.file_now_upload = true; // true 일때는 FILE_DOMAIN없이 이미지를 보여준다
            this.setState({
                curation : curation,
                is_modified : true,
            })
        })
        .catch(error => {
            });
    }

    /** 큐레이션 이미지 삭제 */
    removeImage (){
        let curation = this.state.curation;
        curation.file_seq = '';
        curation.file_org_name = '';
        curation.file_path = '';
        this.setState({
            curation: curation,
            is_modified : true,
        })
    }

    /** 큐레이션 질문 내용 변경 */
    onChangeQuestionDesc(e,question_idx){
        let curation = this.state.curation;
        curation.questions[question_idx].question_desc = e.target.value;

        this.setState({
            curation: curation,
            is_modified : true,
        }, () => {
            // 질문 내용 높이 동적으로 변경
            StyleUtil.textareaHeightAuto("quration_gen_q_content_input_"+question_idx, 3);
        });
    }

    /** 질문 이미지 업로드 */
    questionImgUpload(e,question_idx){
        const files = Array.from(e.target.files);

        if (!StringUtil.CheckImagefiles(files[0].name)) {
            // 확장자 경고 팝업 띄우기
            window_scrollTop = StyleUtil.onPopupNoneScroll();

            this.setState({
                basic_dialog_show_state: true,
                basic_dialog_content: "이미지 파일만 업로드 가능합니다.<br/>(이미지 확장자 .jpg, .jpeg, .png)",
                basic_dialog_button_list: [
                    {
                        title: "확인",
                        type: "1",
                        event_method: () => {
                            //팝업 닫기
                            this.setState({ basic_dialog_show_state: false });
                            // 스크롤 고정 해제
                            StyleUtil.clearPopupNoneScroll(window_scrollTop);
                        }
                    }
                ]
            });

            return false;
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        const formData = new FormData();
        formData.append('file', files[0]); // 단일 파일
        const url = '/rest/common/uploadImage/QUESTION';
        http
        .post(url, formData, config)
        .then(res => {
            let curation = this.state.curation;
            curation.questions[question_idx].file_seq = res.data.file_seq;
            curation.questions[question_idx].file_org_name = files[0].name;
            curation.questions[question_idx].file_path = URL.createObjectURL(files[0]);
            curation.questions[question_idx].file_now_upload = true;
            this.setState({
                curation : curation,
                is_modified : true,
            })
        })
        .catch(error => {
            });
    }

    /** 질문 이미지 삭제 */
    removeQuestionImage(question_idx){
        let curation = this.state.curation;
        curation.questions[question_idx].file_seq = '';
        curation.questions[question_idx].file_org_name = '';
        curation.questions[question_idx].file_path = '';
        this.setState({
            curation: curation,
            is_modified : true,
        })
    }

    /** 답변 내용 변경 */
    onChaneAnswerDesc(e,question_idx,answer_idx){
        let curation = this.state.curation;
        curation.questions[question_idx].answers[answer_idx].answer_desc = e.target.value;

        this.setState({
            curation: curation,
            is_modified : true,
        });
    }

    /** 결과 진열 옵션 클릭 (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id(question_idx, answer_idx 포함)
    */
    selectResult (text, value, id) {
        const id_split = id.split("_");
        const question_idx = id_split[id_split.length-2];
        const answer_idx = id_split[id_split.length-1];

        // 포인트(결과) 정보가 없는 답변이라면 기본 객체 추가
        let curation = this.state.curation;
        if(curation.questions[question_idx].answers[answer_idx].points.length == 0){
            curation.questions[question_idx].answers[answer_idx].points.push({result_seq: '', point_val: ''});
        }
        // 선택한 결과 정보 적용
        curation.questions[question_idx].answers[answer_idx].points[0].result_seq = value;
        curation.questions[question_idx].answers[answer_idx].points[0].result_title = text;

        this.setState({
            curation: curation,
            is_modified : true,
        })
    }

    /** 답변 결과 점수 변경 */
    onChangePoint(e,question_idx,answer_idx){
        
        let curation = this.state.curation;
        // 포인트(결과) 정보가 없는 답변이라면 기본 객체 추가
        if(curation.questions[question_idx].answers[answer_idx].points.length == 0){
            curation.questions[question_idx].answers[answer_idx].points.push({result_seq: '', point_val: ''});
        }

        // 숫자만 입력되도록 변환후 적용
        curation.questions[question_idx].answers[answer_idx].points[0].point_val = StringUtil.inputOnlyNumberFormat(e.target.value);
        this.setState({
            curation: curation,
            is_modified : true,
        });
    }

    /** 질문 추가 버튼 클릭
     * @param {*} idx 질문 추가를 누른 질문 idx
    */
    addQuestion(idx){
        let curation = this.state.curation;
        curation.questions.splice(idx+1,0,{
            question_desc: "",
            file_seq : '',
            file_org_name : '',
            file_path : '',
            answers:[
                {
                    answer_desc: "",
                    points: [
                        {
                            result_seq: '',
                            point_val: '',
                        }
                    ],
                },
            ],
        });

        this.setState({
            curation: curation,
            is_modified : true,
        }, () => {
            // 질문 추가시 체크값 상태도 함께 변경
            this.onChangeQuestionsCheckedState();
            // 질문 내용 높이 동적으로 변경
            for(var i=0;i<curation.questions.length;i++){
                StyleUtil.textareaHeightAuto("quration_gen_q_content_input_"+i, 3);
            }
        });
    }

    /** 질문 삭제 버튼 클릭
     * @param {*} idx 질문 삭제를 누른 질문 idx
    */
    delQuestion(idx){

        window_scrollTop = StyleUtil.onPopupNoneScroll();

        this.setState({
            confirm_dialog_show_state: true,
            confirm_dialog_content: "해당 "+CurationServiceVariable.CURATION+" "+CurationServiceVariable.QUESTION+"을 삭제 하시겠어요?",
            confirm_dialog_button_list: [
                {
                    title: "네",
                    type: "1",
                    event_method: () => {
                        let curation = this.state.curation;
                        // 질문 삭제 리스트가 비어있다면 기본 배열로 초기화
                        if(curation.del_question_seq == null || curation.del_question_seq == ""){
                            curation.del_question_seq = [];
                        }
                        if(curation.questions[idx].question_seq != null && curation.questions[idx].question_seq != ""){
                            curation.del_question_seq.push(curation.questions[idx].question_seq);
                        }

                        curation.questions.splice(idx,1);

                        this.setState({
                            curation: curation,
                            is_modified : true,
                            confirm_dialog_show_state: false,
                        }, () => {
                            // 질문 삭제시 체크값 상태도 함께 변경
                            this.onChangeQuestionsCheckedState();
                            // 질문 내용 높이 동적으로 변경
                            for(var i=0;i<curation.questions.length;i++){
                                StyleUtil.textareaHeightAuto("quration_gen_q_content_input_"+i, 3);
                            }
                            // 스크롤 고정 해제
                            StyleUtil.clearPopupNoneScroll(window_scrollTop);
                        });
                    }
                },
                {
                    title: "아니요",
                    type: "0",
                    event_method: () => {
                        this.setState({ confirm_dialog_show_state: false });
                        // 스크롤 고정 해제
                        StyleUtil.clearPopupNoneScroll(window_scrollTop);
                    }
                }
            ]
        });
    }

    /** 질문 추가 및 삭제시 체크값 상태도 함께 변경 */
    onChangeQuestionsCheckedState(){
        const {curation} = this.state;
        for(var idx=0;idx < curation.questions.length;idx++){
            if(curation.questions[idx].is_open_yn != null && curation.questions[idx].is_open_yn == "N"){
                $("#quration_gen_q_fold_check_"+idx).prop("checked", false);
            } else{
                $("#quration_gen_q_fold_check_"+idx).prop("checked", true);
            }
        }
    }

    /** 답변 추가 버튼 클릭 */
    addAnswer(idx,idx2){
        let curation = this.state.curation;
        curation.questions[idx].answers.splice(idx2+1,0,{
            answer_desc: "",
            points: [
                {
                    result_seq: '',
                    point_val: '',
                }
            ],
        });

        this.setState({
            curation: curation,
            is_modified : true,
        });
    }

    /** 답변 삭제 버튼 클릭 */
    delAnswer(idx,idx2){
        let curation = this.state.curation;
        // 답변 삭제 리스트가 비어있다면 기본 배열로 초기화
        if(curation.del_answer_seq == null || curation.del_answer_seq == ""){
            curation.del_answer_seq = [];
        }
        if(curation.questions[idx].answers[idx2].answer_seq != null && curation.questions[idx].answers[idx2].answer_seq != ""){
            curation.del_answer_seq.push(curation.questions[idx].answers[idx2].answer_seq);
        }

        curation.questions[idx].answers.splice(idx2,1);

        this.setState({
            curation: curation,
            is_modified : true,
        });
    }

    /** 질문 입력 유효성 검사 */
    checkQuestionContent(idx){
        const {curation} = this.state;
        const questions = curation.questions;

        // 질문 내용 검사
        if(StringUtil.checkEmptyString(questions[idx].question_desc)){
            return true;
        }
        
        // 답변 내용 검사
        const answers = questions[idx].answers;
        for(var idx2=0;idx2<answers.length;idx2++){
            if(StringUtil.checkEmptyString(answers[idx2].answer_desc)){
                return true;
            }
        }

        return false;
    }

    /** 저장 버튼 클릭 */
    onClickSaveCuration(){
        let {curation} = this.state;

        // 큐레이션 제목
        if(StringUtil.checkEmptyString(curation.curation_title)){
            this.saveCheckDialog(CurationServiceVariable.CURATION+" "+CurationServiceVariable.TITLE+"을 입력해주세요.","quration_gen_info_name_input");
            return false;
        }
        // 큐레이션 설명
        if(StringUtil.checkEmptyString(curation.curation_desc)){
            this.saveCheckDialog(CurationServiceVariable.CURATION+" "+CurationServiceVariable.DESCRIPTION+"을 입력해주세요.","quration_gen_info_detail_textarea");
            return false;
        }
        // 큐레이션 썸네일
        if(curation.file_seq == null || curation.file_seq == ""){
            this.saveCheckDialog(CurationServiceVariable.CURATION+" 썸네일을 업로드해주세요.","quration_gen_file_input_disabled",true);
            return false;
        }
        let questions = curation.questions;
        for(var idx=0;idx<questions.length;idx++){
            // 질문 내용
            if(StringUtil.checkEmptyString(questions[idx].question_desc)){
                // 닫혀있는 폼일지 모르니 열어주는 작업
                curation.questions[idx].is_open_yn = "Y";
                this.setState({curation: curation});
                $("#quration_gen_q_fold_check_"+idx).prop("checked", true);
                this.saveCheckDialog(CurationServiceVariable.QUESTION+" 내용을 입력해주세요.","quration_gen_q_content_input_"+idx);
                return false;
            }
            // 답변 내용
            const answers = questions[idx].answers;
            for(var idx2=0;idx2<answers.length;idx2++){
                if(StringUtil.checkEmptyString(answers[idx2].answer_desc)){
                    curation.questions[idx].is_open_yn = "Y";
                    this.setState({curation: curation});
                    $("#quration_gen_q_fold_check_"+idx).prop("checked", true);
                    this.saveCheckDialog(CurationServiceVariable.ANSWER+" 내용을 입력해주세요.","quration_gen_q_answer_txt"+idx+"_"+idx2);
                    return false;
                }
                if(answers[idx2]["points"][0].result_seq == null || answers[idx2]["points"][0].result_seq == ""){
                    curation.questions[idx].is_open_yn = "Y";
                    this.setState({curation: curation});
                    $("#quration_gen_q_fold_check_"+idx).prop("checked", true);
                    this.saveCheckDialog(CurationServiceVariable.CURATION+" "+CurationServiceVariable.RESULT+"를 지정해주세요.","quration_gen_q_answer_txt"+idx+"_"+idx2, null, true);
                    return false;
                }
            }
        }

        // 큐레이션 생성, 수정에 따라 다르게 저장
        const curation_seq = StringUtil.getUrlParams("curation_seq");
        if(curation_seq != null && curation_seq != ""){
            this.modifyCuration();
        } else{
            this.createCuration();
        }

    }

    /** 저장시 유효성 경고 다이얼로그
     * @param {*} content 다이얼로그에 띄울 내용
     * @param {*} focus_ele 포커스 시킬 element의 id값
     * @param {*} img 이미지 여부(true일 경우 disabled 해제후 포커싱하고 다시 disabled 처리)
     * @param {*} blur 포커스 후 블러
    */
    saveCheckDialog(content, focus_ele, img, blur){
        window_scrollTop = StyleUtil.onPopupNoneScroll();

            this.setState({
                basic_dialog_show_state: true,
                basic_dialog_content: content,
                basic_dialog_button_list: [
                    {
                        title: "확인",
                        type: "1",
                        event_method: () => {
                            //팝업 닫기
                            this.setState({ basic_dialog_show_state: false });
                            // 스크롤 고정 해제
                            StyleUtil.clearPopupNoneScroll(window_scrollTop);
                            // 이미지는 잠시 disabled를 열어 포커싱후 다시 disabled 적용
                            if(img){
                                $("#"+focus_ele).prop('disabled',false);
                                $("#"+focus_ele).focus();
                                $("#"+focus_ele).prop('disabled',true);
                            } else if(blur){
                                $("#"+focus_ele).focus();
                                $("#"+focus_ele).blur();
                            } else{
                                $("#"+focus_ele).focus();
                            }
                            
                        }
                    }
                ]
            });
    }

    /** 질문과 답변 순서번호 생성 */
    setOrderNo(){
        const {curation} = this.state;
        const questions = curation.questions;
        for(var idx=0;idx<questions.length;idx++){
            curation.questions[idx].order_no = idx+1;

            const answers = questions[idx].answers;
            for(var idx2=0;idx2<answers.length;idx2++){
                curation.questions[idx].answers[idx2].order_no = idx2+1;
            }
        }

        return curation;
    }

    /** 큐레이션 생성 */
    createCuration(){
        window_scrollTop = StyleUtil.onPopupNoneScroll();

        this.setState({
            confirm_dialog_show_state: true,
            confirm_dialog_content: CurationServiceVariable.CURATION+"을 생성 하시겠습니까?",
            confirm_dialog_button_list: [
                {
                    title: "네",
                    type: "1",
                    event_method: () => {

                        // 큐레이션 질문, 답변에 순서번호 생성
                        const curation = this.setOrderNo();

                        const config = {
                            headers: {
                                'Content-Type': 'application/json',
                                },
                        };
                        const url = '/rest/shop/curation/create';
                        const body = curation;
                        http
                        .post(url, body, config)
                        .then((res) => {
                            if (res.data.code == "200") {
                                // 링크 주소의 param값으로 curation_seq를 붙인형태로 변경한다.
                                window.history.replaceState("","",this_location+"?curation_seq="+res.data.curation_seq);
                                this_location = this_location+"?curation_seq="+res.data.curation_seq;

                                // 팝업 닫기
                                this.setState({ confirm_dialog_show_state: false });
                                // 스크롤 고정 해제
                                StyleUtil.clearPopupNoneScroll(window_scrollTop);
                                // 새로 고침
                                window.location.reload();
                                
                            } else {
                                //팝업 닫기
                                this.setState({ confirm_dialog_show_state: false });
                                // 스크롤 고정 해제
                                StyleUtil.clearPopupNoneScroll(window_scrollTop);
                                alert("저장에 실패하였습니다.");
                            }
                        })
                        .catch((error) => {
                            this.setState({ confirm_dialog_show_state: false });
                            // 스크롤 고정 해제
                            StyleUtil.clearPopupNoneScroll(window_scrollTop);
                        });
                    }
                },
                {
                    title: "아니요",
                    type: "0",
                    event_method: () => {
                        this.setState({ confirm_dialog_show_state: false });
                        // 스크롤 고정 해제
                        StyleUtil.clearPopupNoneScroll(window_scrollTop);
                    }
                }
            ]
        });
    }

    /** 큐레이션 수정 */
    modifyCuration(){
        window_scrollTop = StyleUtil.onPopupNoneScroll();

        this.setState({
            confirm_dialog_show_state: true,
            confirm_dialog_content: CurationServiceVariable.CURATION+"을 저장 하시겠습니까?",
            confirm_dialog_button_list: [
                {
                    title: "네",
                    type: "1",
                    event_method: () => {
                        // 큐레이션 질문, 답변 순서번호 재정렬
                        const curation = this.setOrderNo();

                        const config = {
                            headers: {
                                'Content-Type': 'application/json',
                                },
                        };
                        const url = '/rest/shop/curation/modify';
                        const body = curation;
                        http
                        .post(url, body, config)
                        .then((res) => {
                            if (res.data.code == "200") {
                                // 팝업 닫기
                                this.setState({ confirm_dialog_show_state: false });
                                // 스크롤 고정 해제
                                StyleUtil.clearPopupNoneScroll(window_scrollTop);
                                // 새로 고침
                                window.location.reload();
                            } else {
                                //팝업 닫기
                                this.setState({ confirm_dialog_show_state: false });
                                // 스크롤 고정 해제
                                StyleUtil.clearPopupNoneScroll(window_scrollTop);
                                alert("저장에 실패하였습니다.");
                            }
                        })
                        .catch((error) => {
                            this.setState({ confirm_dialog_show_state: false });
                            // 스크롤 고정 해제
                            StyleUtil.clearPopupNoneScroll(window_scrollTop);
                        });
                    }
                },
                {
                    title: "아니요",
                    type: "0",
                    event_method: () => {
                        this.setState({ confirm_dialog_show_state: false });
                        // 스크롤 고정 해제
                        StyleUtil.clearPopupNoneScroll(window_scrollTop);
                    }
                }
            ]
        });
    }

    /** 취소 버튼 클릭 */
    onClickCancel(){
        if(this.state.is_modified){
            window_scrollTop = StyleUtil.onPopupNoneScroll();
    
            // 큐레이션 생성, 수정에 따라 다르게 저장
            const curation_seq = StringUtil.getUrlParams("curation_seq");
            let confirm_dialog_content;
            if(curation_seq != null && curation_seq != ""){
                confirm_dialog_content = CurationServiceVariable.CURATION+" 수정을 취소 하시겠습니까?";
            } else{
                confirm_dialog_content = CurationServiceVariable.CURATION+" 생성을 취소 하시겠습니까?"
            }
    
            this.setState({
                confirm_dialog_show_state: true,
                confirm_dialog_content: confirm_dialog_content,
                confirm_dialog_button_list: [
                    {
                        title: "네",
                        type: "1",
                        event_method: () => {
                            this.setState({ confirm_dialog_show_state: false });
                            // 스크롤 고정 해제
                            StyleUtil.clearPopupNoneScroll(window_scrollTop);
    
                            this.goCurationListPage();
                        }
                    },
                    {
                        title: "아니요",
                        type: "0",
                        event_method: () => {
                            this.setState({ confirm_dialog_show_state: false });
                            // 스크롤 고정 해제
                            StyleUtil.clearPopupNoneScroll(window_scrollTop);
                        }
                    }
                ]
            });
        } else{
            this.goCurationListPage();
        }
    }

    /** 큐레이션 목록 페이지로 이동 */
    goCurationListPage(){
        const {location} = this.props;
        this.props.history.push(
            {
                pathname: `/shop/curation/info/list`,
                state: {curationListState: (location.state != null && location.state.curationListState != null ? location.state.curationListState: null)},
            }	
        )
    }
    /** 목록 페이지로 replace */
    replaceCurationListPage() {
        const {location} = this.props; 
        this.props.history.replace(
            {
                pathname: `/shop/curation/info/list`,
                state: {curationListState: (location.state != null && location.state.curationListState != null ? location.state.curationListState: null)}
            }	
        )
    }

    render() {
        const curation = this.state.curation;

        return (
            <>
                <div id='common-page-wrapper'>
                    <div id="wrap">
                        <section class="quration_gen style_guide_v2" id="quration_gen">
                            <div class="quration_gen_title">
                                <h2 class="text_20">{CurationServiceVariable.CURATION} {CurationServiceVariable.CREATE}</h2>
                            </div>
                            <div class="quration_gen_info_wrap">
                                <h2 class="text_16">{CurationServiceVariable.CURATION} {CurationServiceVariable.INFO}</h2>
                                <form class="quration_gen_info" method="post" action="#">
                                    <div class="quration_gen_info_name">
                                        <h3 class="text_16">* {CurationServiceVariable.TITLE}</h3>
                                        <input type="text" id="quration_gen_info_name_input" name="quration_gen_info_name" maxlength="200" placeholder={CurationServiceVariable.TITLE+"을 입력해주세요."} value={curation.curation_title} onChange={e => {this.onChangeTitle(e)}} autocomplete="off"/>
                                    </div>
                                    <div class="quration_gen_info_detail">
                                        <h3 class="text_16">* {CurationServiceVariable.DESCRIPTION}</h3>
                                        <textarea id="quration_gen_info_detail_textarea" name="quration_gen_info_detail" maxlength="2000" placeholder={CurationServiceVariable.DESCRIPTION+"을 입력해주세요."} onChange={e => {this.onChangeDesc(e)}} value={curation.curation_desc}></textarea>
                                    </div>
                                    <div class="quration_gen_info_thumbnail">
                                        <h3 class="text_16">* 썸네일</h3>
                                        <div class="quration_gen_info_thumbnail_1">
                                            <div class="quration_gen_info_thumbnail_2">
                                                <div class="quration_gen_info_thumbnail_3">
                                                    <label class="quration_gen_info_img_upload_btn" for="quration_gen_file_input" tabIndex={0}>이미지 업로드</label>
                                                    <input type="text" id="quration_gen_file_input_disabled" name="quration_gen_info_img" placeholder="파일을 선택해주세요." value={curation.file_org_name} disabled/>
                                                    <input type="file" id="quration_gen_file_input" onChange={e => this.curationImgUpload(e)} accept="image/*" value="" style={{display:"none"}} tabindex="-1"></input>
                                                    {curation.file_path != null && curation.file_path != ""
                                                    ?<svg class="quration_gen_img_delete_btn" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => this.removeImage()}>
                                                        <path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1209 7.84638 17.2635 5.78216 15.7407 4.25932C14.2178 2.73648 12.1536 1.87913 10 1.875ZM12.9453 12.0547C13.0627 12.1732 13.1285 12.3332 13.1285 12.5C13.1285 12.6668 13.0627 12.8268 12.9453 12.9453C12.8259 13.0608 12.6662 13.1254 12.5 13.1254C12.3338 13.1254 12.1742 13.0608 12.0547 12.9453L10 10.8828L7.94532 12.9453C7.82585 13.0608 7.66618 13.1254 7.5 13.1254C7.33383 13.1254 7.17415 13.0608 7.05469 12.9453C6.93733 12.8268 6.87149 12.6668 6.87149 12.5C6.87149 12.3332 6.93733 12.1732 7.05469 12.0547L9.11719 10L7.05469 7.94531C6.95503 7.82388 6.90409 7.66971 6.9118 7.51281C6.91951 7.3559 6.9853 7.20747 7.09639 7.09638C7.20747 6.9853 7.3559 6.9195 7.51281 6.9118C7.66972 6.90409 7.82388 6.95502 7.94532 7.05469L10 9.11719L12.0547 7.05469C12.1761 6.95502 12.3303 6.90409 12.4872 6.9118C12.6441 6.9195 12.7925 6.9853 12.9036 7.09638C13.0147 7.20747 13.0805 7.3559 13.0882 7.51281C13.0959 7.66971 13.045 7.82388 12.9453 7.94531L10.8828 10L12.9453 12.0547Z" fill="#D1D1D1"/>
                                                    </svg>
                                                    :""}
                                                </div>
                                                <h4>
                                                    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1229 7.84575 17.2662 5.78033 15.743 4.25704C14.2197 2.73376 12.1543 1.87707 10 1.875ZM9.84375 5.625C10.0292 5.625 10.2104 5.67998 10.3646 5.783C10.5188 5.88601 10.6389 6.03243 10.7099 6.20373C10.7808 6.37504 10.7994 6.56354 10.7632 6.7454C10.7271 6.92725 10.6378 7.0943 10.5067 7.22541C10.3756 7.35652 10.2085 7.44581 10.0267 7.48199C9.84479 7.51816 9.65629 7.49959 9.48499 7.42864C9.31368 7.35768 9.16726 7.23752 9.06425 7.08335C8.96124 6.92918 8.90625 6.74792 8.90625 6.5625C8.90625 6.31386 9.00503 6.0754 9.18084 5.89959C9.35666 5.72377 9.59511 5.625 9.84375 5.625ZM10.625 14.375H10C9.83424 14.375 9.67527 14.3092 9.55806 14.1919C9.44085 14.0747 9.375 13.9158 9.375 13.75V10C9.20924 10 9.05027 9.93415 8.93306 9.81694C8.81585 9.69973 8.75 9.54076 8.75 9.375C8.75 9.20924 8.81585 9.05027 8.93306 8.93306C9.05027 8.81585 9.20924 8.75 9.375 8.75H10C10.1658 8.75 10.3247 8.81585 10.4419 8.93306C10.5592 9.05027 10.625 9.20924 10.625 9.375V13.125C10.7908 13.125 10.9497 13.1908 11.0669 13.3081C11.1842 13.4253 11.25 13.5842 11.25 13.75C11.25 13.9158 11.1842 14.0747 11.0669 14.1919C10.9497 14.3092 10.7908 14.375 10.625 14.375Z" fill="#ACACAC"/>
                                                    </svg>
                                                    <span>썸네일 이미지는 한장만 지정할 수 있습니다.<br/>이미지 권장 사이즈 : 500 * 500</span>
                                                </h4>
                                            </div>
                                            {curation.file_path != null && curation.file_path != "" 
                                            ?   <div class="quration_gen_info_thumbnail_img" style={curation.file_now_upload?{backgroundImage:"url("+curation.file_path+")"}:{backgroundImage:"url("+CurationServiceVariable.FILE_DOMAIN+curation.file_path+")"}}>
                                                </div>
                                            :   <div class="quration_gen_info_thumbnail_img">
                                                    <h5>이미지 미리보기</h5>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="quration_gen_info_wrap quration_gen_q_wrap">
                                <h2 class="text_16">{CurationServiceVariable.CURATION} {CurationServiceVariable.QUESTION} 정보</h2>
                                {curation.questions!=null && curation.questions.length > 0 && curation.questions.map((question, idx) => (
                                    <form class="quration_gen_info quration_gen_q" id={"quration_gen_info_form_"+idx} style={question.is_open_yn != null && question.is_open_yn=='N' ? {overflow:"hidden", height:"72px"} : {overflow:"visible", height:"auto"}}>
                                        <input type="checkbox" class="quration_gen_q_fold_check" id={"quration_gen_q_fold_check_"+idx} name="quration_gen_q_fold_check" onClick={(e) => this.onClickQuestionFolder(e,idx)}/>
                                        <label for={"quration_gen_q_fold_check_"+idx}>
                                            {question.is_open_yn != null && question.is_open_yn=='N' && this.checkQuestionContent(idx)
                                            ?<svg class="quration_gen_q_fold_alert" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.41489 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1229 7.84575 17.2662 5.78033 15.743 4.25704C14.2197 2.73376 12.1543 1.87707 10 1.875V1.875ZM9.375 6.25C9.375 6.08424 9.44085 5.92527 9.55806 5.80806C9.67527 5.69085 9.83424 5.625 10 5.625C10.1658 5.625 10.3247 5.69085 10.4419 5.80806C10.5592 5.92527 10.625 6.08424 10.625 6.25V10.625C10.625 10.7908 10.5592 10.9497 10.4419 11.0669C10.3247 11.1842 10.1658 11.25 10 11.25C9.83424 11.25 9.67527 11.1842 9.55806 11.0669C9.44085 10.9497 9.375 10.7908 9.375 10.625V6.25ZM10 14.375C9.81458 14.375 9.63333 14.32 9.47916 14.217C9.32499 14.114 9.20482 13.9676 9.13387 13.7963C9.06291 13.625 9.04434 13.4365 9.08052 13.2546C9.11669 13.0727 9.20598 12.9057 9.33709 12.7746C9.4682 12.6435 9.63525 12.5542 9.81711 12.518C9.99896 12.4818 10.1875 12.5004 10.3588 12.5714C10.5301 12.6423 10.6765 12.7625 10.7795 12.9167C10.8825 13.0708 10.9375 13.2521 10.9375 13.4375C10.9375 13.6861 10.8387 13.9246 10.6629 14.1004C10.4871 14.2762 10.2486 14.375 10 14.375Z" fill="#FF085E"/>
                                            </svg>
                                            : ""}
                                            <svg class="quration_gen_q_fold_btn" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.4375 7.49884C3.43765 7.45796 3.44603 7.41752 3.46213 7.37994C3.47824 7.34236 3.50174 7.3084 3.53125 7.28009C3.55965 7.2508 3.59365 7.22751 3.63123 7.2116C3.66881 7.1957 3.7092 7.1875 3.75 7.1875C3.7908 7.1875 3.83119 7.1957 3.86877 7.2116C3.90635 7.22751 3.94035 7.2508 3.96875 7.28009L10 13.3035L16.0312 7.2801C16.0914 7.23496 16.1659 7.21305 16.2409 7.21838C16.3159 7.22371 16.3865 7.25593 16.4397 7.30912C16.4929 7.36232 16.5251 7.43291 16.5305 7.50794C16.5358 7.58298 16.5139 7.65742 16.4687 7.7176L10.2187 13.9676C10.1903 13.9969 10.1563 14.0202 10.1188 14.0361C10.0812 14.052 10.0408 14.0602 10 14.0602C9.95919 14.0602 9.91881 14.052 9.88123 14.0361C9.84365 14.0202 9.80965 13.9969 9.78125 13.9676L3.53125 7.7176C3.50174 7.68929 3.47824 7.65533 3.46213 7.61775C3.44603 7.58017 3.43765 7.53973 3.4375 7.49884Z" fill="#333333"/>
                                            </svg>
                                            {/* {curation.questions.length > 1
                                            ?<button class="quration_gen_q_fold_remove_btn" type="button" onClick={() => {this.delQuestion(idx)}}>
                                                <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M16.875 10.625H3.125C2.95924 10.625 2.80027 10.5592 2.68306 10.4419C2.56585 10.3247 2.5 10.1658 2.5 10C2.5 9.83424 2.56585 9.67527 2.68306 9.55806C2.80027 9.44085 2.95924 9.375 3.125 9.375H16.875C17.0408 9.375 17.1997 9.44085 17.3169 9.55806C17.4342 9.67527 17.5 9.83424 17.5 10C17.5 10.1658 17.4342 10.3247 17.3169 10.4419C17.1997 10.5592 17.0408 10.625 16.875 10.625Z" fill="#333333"/>
                                                </svg>
                                            </button>
                                            :""} */}
                                        </label>
                                        <div class="quration_gen_q_cover">
                                            <h2 class="text_16" style={{paddingLeft: '16px'}}>{question.is_open_yn != null && question.is_open_yn=='N' ? question.question_desc : ''}</h2>
                                        </div>
                                        <div class="quration_gen_info_name quration_gen_q_content">
                                            <h3 class="text_16">* {CurationServiceVariable.QUESTION} 내용</h3>
                                            <textarea id={"quration_gen_q_content_input_"+idx} name="quration_gen_q_content_input" maxlength="2000" placeholder={CurationServiceVariable.QUESTION+" 내용을 입력해주세요."} value={question.question_desc} onChange={(e)=>{this.onChangeQuestionDesc(e,idx)}}></textarea>
                                        </div>
                                        <div class="quration_gen_info_thumbnail quration_gen_q_thumbnail">
                                            <h3 class="text_16">&nbsp;&nbsp;{CurationServiceVariable.QUESTION} 이미지</h3>
                                            <div class="quration_gen_info_thumbnail_1">
                                                <div class="quration_gen_info_thumbnail_2">
                                                    <div class="quration_gen_info_thumbnail_3">
                                                        <label class="quration_gen_info_img_upload_btn" for={"quration_gen_question_file_input_"+idx} tabIndex={0}>이미지 업로드</label>
                                                        <input type="text" id={"quration_gen_q_img_input_disabled_"+idx} name="quration_gen_q_img" placeholder="파일을 선택해주세요." value={question.file_org_name} disabled/>
                                                        <input type="file" id={"quration_gen_question_file_input_"+idx} onChange={e => this.questionImgUpload(e,idx)} accept="image/*" value="" style={{display:"none"}} tabindex="-1"></input>
                                                        {question.file_path != null && question.file_path != ""
                                                        ?<svg class="quration_gen_img_delete_btn" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => this.removeQuestionImage(idx)}>
                                                            <path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1209 7.84638 17.2635 5.78216 15.7407 4.25932C14.2178 2.73648 12.1536 1.87913 10 1.875ZM12.9453 12.0547C13.0627 12.1732 13.1285 12.3332 13.1285 12.5C13.1285 12.6668 13.0627 12.8268 12.9453 12.9453C12.8259 13.0608 12.6662 13.1254 12.5 13.1254C12.3338 13.1254 12.1742 13.0608 12.0547 12.9453L10 10.8828L7.94532 12.9453C7.82585 13.0608 7.66618 13.1254 7.5 13.1254C7.33383 13.1254 7.17415 13.0608 7.05469 12.9453C6.93733 12.8268 6.87149 12.6668 6.87149 12.5C6.87149 12.3332 6.93733 12.1732 7.05469 12.0547L9.11719 10L7.05469 7.94531C6.95503 7.82388 6.90409 7.66971 6.9118 7.51281C6.91951 7.3559 6.9853 7.20747 7.09639 7.09638C7.20747 6.9853 7.3559 6.9195 7.51281 6.9118C7.66972 6.90409 7.82388 6.95502 7.94532 7.05469L10 9.11719L12.0547 7.05469C12.1761 6.95502 12.3303 6.90409 12.4872 6.9118C12.6441 6.9195 12.7925 6.9853 12.9036 7.09638C13.0147 7.20747 13.0805 7.3559 13.0882 7.51281C13.0959 7.66971 13.045 7.82388 12.9453 7.94531L10.8828 10L12.9453 12.0547Z" fill="#D1D1D1"/>
                                                        </svg>
                                                        :""}
                                                    </div>
                                                </div>
                                                {question.file_path != null && question.file_path != "" 
                                                ?   <div class="quration_gen_info_thumbnail_img quration_gen_q_thumbnail_img" style={question.file_now_upload?{backgroundImage:"url("+question.file_path+")"}:{backgroundImage:"url("+CurationServiceVariable.FILE_DOMAIN+question.file_path+")"}}>
                                                    </div>
                                                :   <div class="quration_gen_info_thumbnail_img quration_gen_q_thumbnail_img">
                                                        <h5>이미지 미리보기</h5>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div class="quration_gen_info_detail quration_gen_q_answer_wrap">
                                            <h3 class="text_16">* {CurationServiceVariable.ANSWER}</h3>
                                            {question.answers!=null && question.answers.length > 0 && question.answers.map((answer, idx2) => (
                                                <div class="quration_gen_q_answer">
                                                    <input class="quration_gen_q_answer_txt" type="text" id={"quration_gen_q_answer_txt"+idx+"_"+idx2} maxlength="100" placeholder={CurationServiceVariable.ANSWER+" 내용을 입력해주세요."} value={answer.answer_desc} onChange={(e) => {this.onChaneAnswerDesc(e,idx,idx2)}} autocomplete="off"/>
                                                    <div style={{marginRight:"12px"}}>
                                                        <SelectBoxV2
                                                            id={"curation_result_select_"+idx+"_"+idx2}
                                                            tabIndex={0}
                                                            width="378px"
                                                            height="50px"
                                                            padding="14px 36px 14px 16px"
                                                            list= {this.state.curation_result_list}
                                                            optionTexttName = "curation_result_title"
                                                            optionValuetName = "curation_result_seq"
                                                            selectedValue = {answer.points != null && answer.points.length > 0 ? answer.points[0].result_seq : ""}
                                                            selectedText={answer.points != null && answer.points.length > 0 ? answer.points[0].result_title : ""}
                                                            placeHolder={CurationServiceVariable.CURATION+" "+CurationServiceVariable.RESULT+"를 지정해주세요."}
                                                            onClickOption={this.selectResult.bind(this)}
                                                        />
                                                    </div>
                                                    <input class="quration_gen_q_score" type="text" placeholder="점수" maxlength="4" value={answer.points != null && answer.points.length > 0 ? StringUtil.numberComma(answer.points[0].point_val) : ""} onChange={(e) => {this.onChangePoint(e,idx,idx2)}} autocomplete="off"/>
                                                    <div class="quration_gen_q_btn">
                                                        <button class="quration_gen_q_answer_add" type="button" style={{cursor:'default'}} tabIndex={-1}>
                                                            <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{cursor:'pointer'}} onClick={() => {this.addAnswer(idx,idx2)}}>
                                                                <path d="M17.5 10C17.5 10.1658 17.4342 10.3247 17.3169 10.4419C17.1997 10.5592 17.0408 10.625 16.875 10.625H10.625V16.875C10.625 17.0408 10.5592 17.1997 10.4419 17.3169C10.3247 17.4342 10.1658 17.5 10 17.5C9.83424 17.5 9.67527 17.4342 9.55806 17.3169C9.44085 17.1997 9.375 17.0408 9.375 16.875V10.625H3.125C2.95924 10.625 2.80027 10.5592 2.68306 10.4419C2.56585 10.3247 2.5 10.1658 2.5 10C2.5 9.83424 2.56585 9.67527 2.68306 9.55806C2.80027 9.44085 2.95924 9.375 3.125 9.375H9.375V3.125C9.375 2.95924 9.44085 2.80027 9.55806 2.68306C9.67527 2.56585 9.83424 2.5 10 2.5C10.1658 2.5 10.3247 2.56585 10.4419 2.68306C10.5592 2.80027 10.625 2.95924 10.625 3.125V9.375H16.875C17.0408 9.375 17.1997 9.44085 17.3169 9.55806C17.4342 9.67527 17.5 9.83424 17.5 10Z" fill="#333333"/>
                                                            </svg>
                                                        </button>
                                                        {question.answers.length > 1
                                                        ?<button class="quration_gen_q_answer_delete" type="button" style={{cursor:'default'}} tabIndex={-1}>
                                                            <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{cursor:'pointer'}} onClick={() => {this.delAnswer(idx,idx2)}}>
                                                                <path d="M16.875 10.625H3.125C2.95924 10.625 2.80027 10.5592 2.68306 10.4419C2.56585 10.3247 2.5 10.1658 2.5 10C2.5 9.83424 2.56585 9.67527 2.68306 9.55806C2.80027 9.44085 2.95924 9.375 3.125 9.375H16.875C17.0408 9.375 17.1997 9.44085 17.3169 9.55806C17.4342 9.67527 17.5 9.83424 17.5 10C17.5 10.1658 17.4342 10.3247 17.3169 10.4419C17.1997 10.5592 17.0408 10.625 16.875 10.625Z" fill="#333333"/>
                                                            </svg>
                                                        </button>
                                                        :""}
                                                    </div>
                                                </div>
                                            ))}
                                            <h4>
                                                <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1229 7.84575 17.2662 5.78033 15.743 4.25704C14.2197 2.73376 12.1543 1.87707 10 1.875ZM9.84375 5.625C10.0292 5.625 10.2104 5.67998 10.3646 5.783C10.5188 5.88601 10.6389 6.03243 10.7099 6.20373C10.7808 6.37504 10.7994 6.56354 10.7632 6.7454C10.7271 6.92725 10.6378 7.0943 10.5067 7.22541C10.3756 7.35652 10.2085 7.44581 10.0267 7.48199C9.84479 7.51816 9.65629 7.49959 9.48499 7.42864C9.31368 7.35768 9.16726 7.23752 9.06425 7.08335C8.96124 6.92918 8.90625 6.74792 8.90625 6.5625C8.90625 6.31386 9.00503 6.0754 9.18084 5.89959C9.35666 5.72377 9.59511 5.625 9.84375 5.625ZM10.625 14.375H10C9.83424 14.375 9.67527 14.3092 9.55806 14.1919C9.44085 14.0747 9.375 13.9158 9.375 13.75V10C9.20924 10 9.05027 9.93415 8.93306 9.81694C8.81585 9.69973 8.75 9.54076 8.75 9.375C8.75 9.20924 8.81585 9.05027 8.93306 8.93306C9.05027 8.81585 9.20924 8.75 9.375 8.75H10C10.1658 8.75 10.3247 8.81585 10.4419 8.93306C10.5592 9.05027 10.625 9.20924 10.625 9.375V13.125C10.7908 13.125 10.9497 13.1908 11.0669 13.3081C11.1842 13.4253 11.25 13.5842 11.25 13.75C11.25 13.9158 11.1842 14.0747 11.0669 14.1919C10.9497 14.3092 10.7908 14.375 10.625 14.375Z" fill="#ACACAC"/>
                                                </svg>
                                                {CurationServiceVariable.ANSWER} 내용에 따른 {CurationServiceVariable.RESULT}, 점수를 설정하면
                                                <span>고객의 {CurationServiceVariable.ANSWER} 점수 합계를 계산한 뒤 가장 높은 점수의 {CurationServiceVariable.RESULT}</span>
                                                를 보여주게 됩니다.
                                            </h4>
                                            <div class="quration_gen_q_add">
                                                <button type="button" class="quration_gen_q_add_btn hover_white" onClick={() => {this.addQuestion(idx)}} tabIndex={-1}>
                                                    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M17.5 10C17.5 10.1658 17.4342 10.3247 17.3169 10.4419C17.1997 10.5592 17.0408 10.625 16.875 10.625H10.625V16.875C10.625 17.0408 10.5592 17.1997 10.4419 17.3169C10.3247 17.4342 10.1658 17.5 10 17.5C9.83424 17.5 9.67527 17.4342 9.55806 17.3169C9.44085 17.1997 9.375 17.0408 9.375 16.875V10.625H3.125C2.95924 10.625 2.80027 10.5592 2.68306 10.4419C2.56585 10.3247 2.5 10.1658 2.5 10C2.5 9.83424 2.56585 9.67527 2.68306 9.55806C2.80027 9.44085 2.95924 9.375 3.125 9.375H9.375V3.125C9.375 2.95924 9.44085 2.80027 9.55806 2.68306C9.67527 2.56585 9.83424 2.5 10 2.5C10.1658 2.5 10.3247 2.56585 10.4419 2.68306C10.5592 2.80027 10.625 2.95924 10.625 3.125V9.375H16.875C17.0408 9.375 17.1997 9.44085 17.3169 9.55806C17.4342 9.67527 17.5 9.83424 17.5 10Z" fill="#333333"/>
                                                    </svg>
                                                    {CurationServiceVariable.QUESTION} 추가
                                                </button>
                                                {curation.questions.length > 1
                                                ?<button type="button" class="quration_gen_q_remove_btn hover_grey" onClick={() => {this.delQuestion(idx)}} tabIndex={-1}>삭제</button>
                                                :""}
                                            </div>
                                        </div>
                                    </form>
                                ))}
                            </div>
                            <div class="quration_gen_btn_wrap">
                                <button class="quration_gen_btn_Y hover_mint" onClick={() => {this.onClickSaveCuration()}} tabIndex={-1}>저장</button>
                                <button class="quration_gen_btn_N hover_white" onClick={() => {this.onClickCancel()}} tabIndex={-1}>취소</button>
                            </div>
                        </section>

                        {/* 기본 안내 다이얼로그 */
                            (this.state.basic_dialog_show_state === true)
                            ? <BasicDialogV2
                                content={this.state.basic_dialog_content}
                                button_list={this.state.basic_dialog_button_list}
                            />
                            : ''
                        }
                        {/* 확인 취소 다이얼로그 */
                            (this.state.confirm_dialog_show_state === true)
                            ? <ConfirmDialogV2
                                content={this.state.confirm_dialog_content}
                                button_list={this.state.confirm_dialog_button_list}
                                popup_close={() => {
                                    this.setState({confirm_dialog_show_state: false});
                                    // 스크롤 고정 해제
                                    StyleUtil.clearPopupNoneScroll(window_scrollTop);
                                }}
                            />
                            : ''
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default CurationGen;
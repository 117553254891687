// 스타일
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import styles from "./RpMainGrid.module.css";

/**
 * types
 * 
 * linkHref: string
 * imgSrc: string
 * imgAlt: string
 * title: string
 * content: string
 */

const RpMainGrid = ({
    type,
    linkHref,
    imgSrc,
    imgAlt,
    title,
    content,
}) => {
    return (
        <Link to={{ pathname: linkHref, state: { highlightType: type } }}  className={styles.grid}>
            <div className={styles.grid_title}>
                <img src={imgSrc} alt={imgAlt}/>
                {title}
            </div>
            <p>{content}</p>
        </Link>
    )
}

RpMainGrid.defaultProps = {
    imgAlt: "",
    title: "",
    content: "",
}

export default RpMainGrid;
import React from "react";

/** 버튼 태그 내부에 아이콘 및 텍스트 추가
 * @param {*} type 버튼 타입, ex) hover_mint or hover_dark or hover_blue or hover_white or hover_grey or hover_transparent or hover_mint_b
 * @param {*} disabled 비활성화 상태 true/false, null이면 false
 * @param {*} onClick 버튼 클릭시 실행될 함수
 * @param {*} className 클래스 명
 * @param {*} id 버튼의 id값
 * @param {*} width
 * @param {*} height
 * @param {*} fontSize
 * @param {*} padding
 * @param {*} minWidth
 */
class Button extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
        }
    }

	render () {
		const {type, children, id, disabled, onClick, className, width, height, fontSize, padding, minWidth} = this.props;
		return (
			<button
                id={id}
                className={["common_button", type, className, (disabled ? "disabled" : "")].join(' ')}
                onClick={e => !disabled ? onClick(e) : ""}
                style={{
                    width: width,
                    height: height,
                    fontSize: fontSize,
                    padding : padding,
                    minWidth : minWidth,
                }}
            >
                { children }
			</button>
		);
	}
}

Button.defaultProps = {
    type : "hover_mint",
    disabled : false,
    className : "",
    content : "",
    id : "",
    width: "100px",
    height: "40px",
    fontSize: "15px",
    onClick : function() {},
}

export default Button;
import axios from 'axios';
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

// 기본으로 관리되는 state 초기값
let initialState = {
    list:[],
    modified: false,
    loadStatus : "",
    saveStatus : "",
}

/** 제외 상품 목록을 불러오는 함수
    param = {
        pageID : ...,
        htmlID : ...,
    }
*/
export const loadList = createAsyncThunk(
    'excludeProductsSlice/loadList',
    async (param) => {
        const res = await axios.get(`/rest/shop/rp/event/exceptProduct/list?page_id=${param.pageID}&html_id=${param.htmlID}`);
        const list = res.data.list;
        const code = res.data.code;
        return {list, code};
    }
)
/** 제외 상품을 목록을 저장하는 함수
    data = {
        list : ...,
        iframe_page_id : ...,
        iframe_html_id : ...,
    }
*/
export const saveList = createAsyncThunk(
    'excludeProductsSlice/saveList',
    async (data) => {
        const res = await axios.post('/rest/shop/rp/event/exceptProduct/add',data);
        const code = res.data.code;
        return {code};
    }
)

export const excludeProductsSlice = createSlice({
    name:'excludeProductsSlice',
    initialState,
    reducers: {
        addItem: (state, action) => ({
            ...state,
            list: [...state.list, ...action.payload],
            modified : true,
        }),
        removeItem: (state, action) => ({
            ...state,
            list: state.list.filter(product => !action.payload.has(product.product_no)),
            modified : true,
        }),
        setModified : (state, action) => ({
            ...state,
            modified : action.payload
        })
    },
    // createAsyncThunk를 통해 관리되는 API 호출 상태별 작업들
    extraReducers: (builder) => {
        /* loadList */
        // 대기중
        builder.addCase(loadList.pending, (state) => {
            state.loadStatus = "loading";
        })
        // 완료
        builder.addCase(loadList.fulfilled, (state, {payload}) => {
            if(payload.code === "200") {
                state.loadStatus = "success";
                state.list = payload.list;
                state.modified = false;
            } else{
                state.loadStatus = "fail";
                alert("제외 상품 설정을 불러오는데 실패하였습니다.");
            }
        })
        // 거부
        builder.addCase(loadList.rejected, (state) => {
            state.loadStatus = "fail";
            alert("제외 상품 설정을 불러오는데 실패하였습니다.");
        })

        /* saveList */
        // 대기중
        builder.addCase(saveList.pending, (state) => {
            state.saveStatus = "loading";
        })
        // 완료
        builder.addCase(saveList.fulfilled, (state, {payload}) => {
            if(payload.code === "200") {
                state.saveStatus = "success";
                state.modified = false;
            } else{
                state.saveStatus = "fail";
                alert("제외 상품 설정 저장에 실패하였습니다.");
            }
        })
        // 거부
        builder.addCase(saveList.rejected, (state) => {
            state.saveStatus = "fail";
            alert("제외 상품 설정 저장에 실패하였습니다.");
        })
    }
});

export const { addItem, removeItem, setModified } = excludeProductsSlice.actions;
export default excludeProductsSlice.reducer;
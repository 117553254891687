import React from 'react';
import $ from 'jquery';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Doughnut} from 'react-chartjs-2';
import {HorizontalBar} from 'react-chartjs-2';
import {Line} from 'react-chartjs-2';
import { gray } from 'color-name';
import axios from 'axios';
import http from '../modules/api';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-78553461-8');

var userName = sessionStorage.getItem("userName");

var is_first = true;
String.prototype.replaceAll = function(org, dest) {
    return this.split(org).join(dest);
}

var category = decodeURI(getUrlParams()["category"]);
if(getUrlParams()["category"] == null) {
    category = ""
}
// console.log(category)
var paramDate = new Date(new Date(getUrlParams()["date"]).toString().replace("09:", "00:"));

var date = new Date(paramDate);
var db_date;
var beforeDate = "";
var beforeDate2 = "";
var nowDate = "";
var nowDateForQuery = "";
var nextDate = "";
var nextDate2 = "";

var is_before_click = true;
var is_next_click = true;
// console.log(getUrlParams()["date"])
// if (getUrlParams()["date"] == null) {

// console.log(nowDate);
var graphType = "";

function getUrlParams() {
    var params = {};
    window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(str, key, value) { params[key] = value; });
    return params;
}

function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

/**
 *  yyyyMMdd 포맷으로 반환
 */
function getFormatDate(date){
    var year = date.getFullYear();              //yyyy
    var month = (1 + date.getMonth());          //M
    month = month >= 10 ? month : '0' + month;  //month 두자리로 저장
    var day = date.getDate();                   //d
    day = day >= 10 ? day : '0' + day;          //day 두자리로 저장
    return  year + '.' + month + '.' + day;
}
function getFormatDate2(date){
    var year = date.getFullYear();              //yyyy
    var month = (1 + date.getMonth());          //M
    month = month >= 10 ? month : '0' + month;  //month 두자리로 저장
    var day = date.getDate();                   //d
    day = day >= 10 ? day : '0' + day;          //day 두자리로 저장
    return  month + '.' + day;
}
function getFormatDate3(date){
    var year = date.getFullYear();              //yyyy
    var month = (1 + date.getMonth());          //M
    month = month >= 10 ? month : '0' + month;  //month 두자리로 저장
    var day = date.getDate();                   //d
    day = day >= 10 ? day : '0' + day;          //day 두자리로 저장
    return  year + '-' + month + '-' + day;
}

export class TrendCurrentEng extends React.Component {
    constructor(props){
        super(props);

        if (typeof Object.assign != 'function') {
            // Must be writable: true, enumerable: false, configurable: true
            Object.defineProperty(Object, "assign", {
              value: function assign(target, varArgs) { // .length of function is 2
                'use strict';
                if (target == null) { // TypeError if undefined or null
                  throw new TypeError('Cannot convert undefined or null to object');
                }
          
                var to = Object(target);
          
                for (var index = 1; index < arguments.length; index++) {
                  var nextSource = arguments[index];
          
                  if (nextSource != null) { // Skip over if undefined or null
                    for (var nextKey in nextSource) {
                      // Avoid bugs when hasOwnProperty is shadowed
                      if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                        to[nextKey] = nextSource[nextKey];
                      }
                    }
                  }
                }
                return to;
              },
              writable: true,
              configurable: true
            });
          }
    }

    state = {
        before_date_limit : "2020-05-09",
        goodsList: [],
        categoryList : [],
        powerKeyword : {keywordList:[]},
        newKeyword : {keywordList:[]},
        mustKeyword : {keywordList:[]},
        hashtagList: [],
        settings : {
            // variableWidth:true,
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 2,
            autoplay : true,
            responsive: [ // 반응형 웹 구현 옵션
                {  
                    breakpoint: 960, //화면 사이즈 960px
                    settings: {
                        //위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
                        slidesToShow:3 
                    } 
                },
                { 
                    breakpoint: 639, //화면 사이즈 639px
                    settings: {	
                        //위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
                        slidesToShow:3 
                    } 
                }
            ]
        },

        settingsCategory : {
            // variableWidth:true,
            dots: false,
            infinite: false,
            // speed: 500,
            slidesToShow: 8,
            slidesToScroll: 3,
            autoplay : false,
            responsive: [ // 반응형 웹 구현 옵션
                {  
                    breakpoint: 960, //화면 사이즈 960px
                    settings: {
                        //위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
                        slidesToShow:5 
                    } 
                },
                { 
                    breakpoint: 639, //화면 사이즈 639px
                    settings: {	
                        //위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
                        slidesToShow:4,
                        slidesToScroll: 4
                    } 
                }
            ]
        },

        // colorData : {
        //     labels: [
        //         'Red',
        //         'Green',
        //         'Yellow'
        //     ],
        //     datasets: [{
        //         data: [300, 50, 100],
        //         backgroundColor: [
        //         '#FF6384',
        //         '#00bf00',
        //         '#FFCE56'
        //         ],
        //         hoverBackgroundColor: [
        //         '#FF6384',
        //         '#00bf00',
        //         '#FFCE56'
        //         ]
        //     }]
        // },
        colorData : {
            labels: [],
            datasets: [{
                data: [],
                backgroundColor: [],
                hoverBackgroundColor: []
            }]
        },

        // barData : {
        //     labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        //     datasets: [
        //         {
        //         label: '검색수 대비 클릭수',
        //         backgroundColor: 'rgba(255,99,132,0.2)',
        //         borderColor: 'rgba(255,99,132,1)',
        //         borderWidth: 1,
        //         hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        //         hoverBorderColor: 'rgba(255,99,132,1)',
        //         data: [65, 59, 80, 81, 56, 55, 40]
        //         },
        //     ]
        // },
        barData : {
            labels: [],
            datasets: [
                {},
            ]
        },
        options: {
            responsive: true, 
            maintainAspectRatio: false
        },
        
        FeedCntData : {
            labels:[""],
            datasets:[]
        },
    };

    componentDidMount(){
        $("#powerKeyword_none").hide();
        $("#newKeyword_none").hide();
        $("#mustKeyword_none").hide();
        $(".products-slick-box .slick-slider").hide();
        // var UserAgent = navigator.userAgent;
        // if (UserAgent.match(/iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i) != null || UserAgent.match(/LG|SAMSUNG|Samsung/) != null)
    	// {  } else{
		//     $("#colorCanvas").click(function () {
        //         $("#modal_canvas").show();
        //         $("#modalBack").show();
        //         $("body").css("background-color", "#a6a6a6");
        //         $("#modalBack").css("background-color", "#a6a6a6");
        //         $("#page-wrapper").css("background-color", "#a6a6a6");
        //     })
        // }
        if ($(window).width() > 800) {
            $("#colorCanvas").click(function () {
                $("#modal_canvas").show();
                $("#modalBack").show();
                $("body").css("background-color", "#a6a6a6");
                $("#modalBack").css("background-color", "#a6a6a6");
                $("#page-wrapper").css("background-color", "#a6a6a6");

                ReactGA.event({
                    category: category,
                    action: "TREND COLOR",
                    label: sessionStorage.getItem("userName")
                });
            })
        }

        $(window).resize( function () {
            if ($(window).width() > 800) {
                $("#colorCanvas").click(function () {
                    $("#modal_canvas").show();
                    $("#modalBack").show();
                    $("body").css("background-color", "#a6a6a6");
                    $("#modalBack").css("background-color", "#a6a6a6");
                    $("#page-wrapper").css("background-color", "#a6a6a6");

                    ReactGA.event({
                        category: category,
                        action: "TREND COLOR",
                        label: sessionStorage.getItem("userName")
                    });
                })
            } else {
                $("#colorCanvas").click(function () { return true });
            }
        });
 
        /* 뒤로가기 이벤트 */
        onpopstate = () => {
            category = getUrlParams()["category"];
            if(getUrlParams()["category"] == null) {
                category = ""
            }
            $("#mainKeyword").text(decodeURI(category));
            this.loadFullData();
        }

        this.getCategoryList();

        this.loadFullData();

        let slickListDiv = document.getElementsByClassName('slick-list')[0]
        slickListDiv.addEventListener('wheel', event => {
          event.preventDefault()
          event.deltaY > 0 ? this.slider.slickNext() : this.slider.slickPrev()
        })

        // 현재 키워드 색깔 변경 (인스타 색 또는 초록) 
        var mainKeyword = $("#mainKeyword").text()
        var keywordSeletor = $(".keywordRanking div")
        keywordSeletor = Array.prototype.slice.call(keywordSeletor);
        // console.log(keywordSeletor)
        keywordSeletor.map(function(elem){
            if (mainKeyword == elem.innerHTML.split(". ")[1]){
                // console.log(elem.innerHTML.split(". ")[1]);
                elem.className += "" + "  textColorGradient";
            }
        });

        $("#modalBack").hide();
        $("#modal").hide();
        $("#modal_canvas").hide();
        $("#modal_feed_cnt").hide();
        $(".keywordTypeName").click(function() {
            // $("#modalBack").show();
            // $("#modal").show();
            // $("body").css("background-color", "#a6a6a6");
            // $("#modalBack").css("background-color", "#a6a6a6");
            // $("#page-wrapper").css("background-color", "#a6a6a6");
            $("#modal h3").text($(this).text());
            graphType = $(this).text().replace(" TREND", "");
            if (graphType == "POWER")
                // $("#modal_dec").text("인스타그램에서 뽑은 오늘의 급상승 키워드")
                $("#modal_dec").text("Today's Rising Keyword from Instagram.")
            else if (graphType == "NEW")
                // $("#modal_dec").text("새롭게 순위권에 진입한 키워드 ("+nowDate+" 기준 1주)")
                $("#modal_dec").text("Newly ranked keywords. (1 week as of "+nowDate+")")
            else if (graphType == "MUST")
                // $("#modal_dec").text("꾸준히 순위권에 진입한 키워드 ("+nowDate+" 기준 1년)")
                $("#modal_dec").text("Keywords that have consistently entered the rankings. (1 week as of "+nowDate+")")

            ReactGA.event({
                category: category,
                action: $(this).text(),
                label: sessionStorage.getItem("userName")
            });
        });
        $(".modalClose").click(function() {
            $("#modalBack").hide();
            $("#modal").hide();
            $("#modal_canvas").hide();
            $("#modal_feed_cnt").hide();
            $("body").css("background-color", "white");
            $("#modalBack").css("background-color", "white");
            $("#page-wrapper").css("background-color", "white");
        });

        $("#mainKeyword").text(category)
      }

    loadFullData() {
        paramDate = new Date(new Date(getUrlParams()["date"]).toString().replace("09:", "00:"));
        date = new Date(paramDate);
        if (getUrlParams()["date"] != null) {
            nowDateForQuery = getFormatDate3(date);
        } else {
            nowDateForQuery = ""; 
        }
        // window.location.href=(`/shop/trend/current?date=`+beforeDate2.replaceAll(".", "-")+"&category="+ encodeURI(category));
        http.get("/rest/common/LastAnlysDate?category="+encodeURI(category)+"&date="+nowDateForQuery)
        .then(res => {
            db_date = new Date(res.data["last_anlys_date"]);
            this.setState( {before_date_limit : res.data["before_date_limit"]});
            // console.log(this.state.before_date_limit);
            if (getUrlParams()["date"] == null) {
                date = new Date(new Date(res.data["last_anlys_date"]).toString().replace("09:", "00:"));
                // console.log(res.data["last_anlys_date"]);
                // console.log(date);
                // console.log(addDays(new Date(), 1));

                beforeDate = getFormatDate2(addDays(date, -1));
                beforeDate2 = getFormatDate(addDays(date, -1));
                nowDate = getFormatDate(date);
                nowDateForQuery = getFormatDate3(date);
                nextDate = getFormatDate2(addDays(date, +1));
                nextDate2 = getFormatDate(addDays(date, +1));
                if (addDays(date, -1) <= db_date && addDays(date, -1) > new Date(this.state.before_date_limit)) {
                    // $("#beforeDate").text("< "+beforeDate);
                    $("#beforeDate").html('<span class="fa fa-chevron-left"></span>'+"&nbsp&nbsp&nbsp"+beforeDate);
                    $("#beforeDate").css('cursor','pointer');
                    is_before_click = true;
                    // console.log("nowDate: "+nowDate);
                } else {
                    $("#beforeDate").css('cursor','default');
                    is_before_click = false;

                    // date = new Date(new Date(this.state.before_date_limit).toString().replace("09:", "00:"));
                    // beforeDate = getFormatDate2(addDays(date, -1));
                    // beforeDate2 = getFormatDate(addDays(date, -1));
                    // nowDate = getFormatDate(date);
                    // nowDateForQuery = getFormatDate3(date);
                    // nextDate = getFormatDate2(addDays(date, +1));
                    // nextDate2 = getFormatDate(addDays(date, +1));
                    // console.log("nowDate: "+nowDate);
                }
                if (new Date() <= date) {
                    // $("#nextDate").text(nextDate + " >");
                    $("#nextDate").html(nextDate+"&nbsp&nbsp&nbsp"+'<span class="fa fa-chevron-right"></span>');
                    $("#nextDate").css('cursor','pointer');
                    is_next_click = true;
                } else {
                    $("#nextDate").html(null);
                    $("#nextDate").css('cursor','default');
                    is_next_click = false;
                }
                $(".nowDate").text(nowDate);
            } else {
                beforeDate = getFormatDate2(addDays(date, -1));
                beforeDate2 = getFormatDate(addDays(date, -1));
                nowDate = getFormatDate(date);
                nowDateForQuery = getFormatDate3(date);
                nextDate = getFormatDate2(addDays(date, +1));
                nextDate2 = getFormatDate(addDays(date, +1));

                if (addDays(date, -1) <= new Date() && addDays(date, -1) > new Date(this.state.before_date_limit)) {
                    // $("#beforeDate").text("< "+beforeDate);
                    $("#beforeDate").html('<span class="fa fa-chevron-left"></span>'+"&nbsp&nbsp&nbsp"+beforeDate);
                    $("#beforeDate").css('cursor','pointer');
                    is_before_click = true;
                } else {
                    $("#beforeDate").html(null);
                    $("#beforeDate").css('cursor','default');
                    is_before_click = false;

                    // date = new Date(new Date(this.state.before_date_limit).toString().replace("09:", "00:"));

                    // beforeDate = getFormatDate2(addDays(date, -1));
                    // beforeDate2 = getFormatDate(addDays(date, -1));
                    // nowDate = getFormatDate(date);
                    // nowDateForQuery = getFormatDate3(date);
                    // nextDate = getFormatDate2(addDays(date, +1));
                    // nextDate2 = getFormatDate(addDays(date, +1));
                    // console.log("nowDate: "+nowDate);
                }
                if (addDays(date, +1) < db_date) {
                    // $("#nextDate").text(nextDate + " >");
                    $("#nextDate").html(nextDate+"&nbsp&nbsp&nbsp"+'<span class="fa fa-chevron-right"></span>');
                    $("#nextDate").css('cursor','pointer');
                    is_next_click = true;
                } else {
                    $("#nextDate").html(null);
                    $("#nextDate").css('cursor','default');
                    is_next_click = false;
                }
                $(".nowDate").text(nowDate);
            }

            $(".nowDate").text(res.data["now_date_format"]);

            this.getHashTag(category, nowDateForQuery);
            this.getKeywordData("POWER", category, nowDateForQuery, 5);
            // this.getKeywordData("NEW", category, nowDateForQuery, 3);
            this.getKeywordData("MUST", category, nowDateForQuery, 5);
            this.getColorData(category);
            this.getGoodsList(category, nowDateForQuery);
        })
        .catch(error => {
                // console.log (error);
        });
    }
    
    onClickBeforeDate = e => {
        if (!is_before_click) {
            return false;
        }

        window.history.pushState(null, null, window.location.href.split("?")[0]+`?date=`+beforeDate2.replaceAll(".", "-")+"&category="+ encodeURI(category));
        this.loadFullData();

        ReactGA.event({
            category: category,
            action: "날짜클릭 ("+beforeDate2+")",
            label: sessionStorage.getItem("userName")
        });
    }

    onClickNextDate = e => {
        if (!is_next_click) {
            return false;
        }

        window.history.pushState(null, null, window.location.href.split("?")[0]+`?date=`+nextDate2.replaceAll(".", "-")+"&category="+ encodeURI(category));
        this.loadFullData();

        ReactGA.event({
            category: category,
            action: "날짜클릭 ("+nextDate2+")",
            label: sessionStorage.getItem("userName")
        });
    }

    onClickCategory = e => {
        category = e.currentTarget.textContent;
        $("#mainKeyword").text(category)
        window.history.pushState(null, null, window.location.href.split("?")[0]+`?date=`+nowDate.replaceAll(".", "-")+"&category="+ encodeURI(category));
        this.loadFullData();

        ReactGA.event({
            category: '카테고리 클릭',
            action: category,
            label: sessionStorage.getItem("userName")
        });
    }

    onClickKeywordTypeName = e => {
        this.getGraphData(category, nowDateForQuery, 10);
        $("#modalBack").show();
        $("#modal").show();
        $("body").css("background-color", "#a6a6a6");
        $("#modalBack").css("background-color", "#a6a6a6");
        $("#page-wrapper").css("background-color", "#a6a6a6");
    }

    onClickPowerKeyword = e => {
        this.getFeedCntData(e.target.textContent);
        $("#modalBack").show();
        $("#modal_feed_cnt").show();
        $("body").css("background-color", "#a6a6a6");
        $("#modalBack").css("background-color", "#a6a6a6");
        $("#page-wrapper").css("background-color", "#a6a6a6");

        ReactGA.event({
            category: category,
            action: "INSTAGRAM FEED (" + e.target.textContent + ")",
            label: sessionStorage.getItem("userName")
        });
    }

    getGoodsList(paramCategory, nowDateForQuery) {
		http.get("/rest/common/eng/trend/goods?category="+ encodeURI(paramCategory)+"&date="+nowDateForQuery)
        .then(res => {
            if (res.data.session_error != null && res.data.session_error == "anonymousUser") {
                // // alert("가입 후 이용 해주세요.")
                return false;
            }

            this.setState( {goodsList : res.data.list} );
            // console.log(this.state.goodsList)
            new Promise( ( resolve, reject ) => {setTimeout( resolve, 400 ); })
            .then( () => {
                $(".products-slick-box .slick-slider").show();
            })
            .catch(error => {
                console.log (error);
                });
            })
		.catch(error => {
			// console.log (error);
		 });
    }

    getHashTag(paramCategory, nowDateForQuery) {
		http.get("/rest/common/eng/naver/hashtag?category="+ encodeURI(paramCategory)+"&date="+nowDateForQuery)
        .then(res => {
            // console.log(res.data)
            if (res.data.category != null && res.data.category != ""){
                category = res.data.category;
                $("#mainKeyword").text(category);
                window.history.pushState(null, null, window.location.href.split("?")[0]+`?date=`+nowDate.replaceAll(".", "-")+"&category="+ encodeURI(category));
                if(is_first) {
                    this.loadFullData();
                }
            }
            if (res.data.session_error != null && res.data.session_error == "anonymousUser") {
                if(window.location.href.indexOf("/shop") > 0){
                    alert("로그인 후 사용해 주세요.");
                    sessionStorage.removeItem("user_id");
                    sessionStorage.removeItem("userName");
                    sessionStorage.removeItem("roleType");
                    sessionStorage.removeItem("redirectUrl");
                    window.location.href = "/logout";
                    return false;
                }

                alert("데모 체험판 입니다.\n가입 후 이용해 주세요.");
                is_first = false;
                return false;
            }
            is_first = false;
        this.setState( {hashtagList : res.data.hashtagList} );
        // console.log(this.state.hashtagList)
		})
		.catch(error => {
			// console.log (error);
		 });
    }

    getGraphData(paramCategory, nowDateForQuery, limit) {
		http.get("/rest/common/eng/state/hashtag?type="+graphType+"&category="+ encodeURI(paramCategory)+"&date="+nowDateForQuery+"&limit="+limit)
        .then(res => {
            if (res.data.session_error != null && res.data.session_error == "anonymousUser") {
                // alert("가입 후 이용 해주세요.")

                return false;
            }

        this.setState( {barData : res.data} );
        // console.log(this.state.barData)
		})
		.catch(error => {
			// console.log (error);
		 });
    }

    getColorData(paramCategory) {
		http.get("/rest/common/eng/trend/color"+"?category="+ encodeURI(paramCategory)+"&date="+nowDateForQuery)
        .then(res => {
            if (res.data.session_error != null && res.data.session_error == "anonymousUser") {
                // alert("가입 후 이용 해주세요.")
                return false;
            }

        this.setState( {colorData : res.data} );
        // console.log(this.state.colorData)
		})
		.catch(error => {
			// console.log (error);
		 });
    }

    getKeywordData(graphType, paramCategory, nowDateForQuery, limit) {
        $("#powerKeyword_none").hide();
        $("#newKeyword_none").hide();
        $("#mustKeyword_none").hide();
		http.get("/rest/common/eng/terend/keyword?type="+graphType+"&category="+ encodeURI(paramCategory)+"&date="+nowDateForQuery+"&limit="+limit)
        .then(res => {
            if (res.data.session_error != null && res.data.session_error == "anonymousUser") {
                // alert("가입 후 이용 해주세요.")
                return false;
            }

            if (graphType == "POWER") {
                if(res.data != null) {
                    this.setState( {powerKeyword : res.data} );
                    if(this.state.powerKeyword.keywordList.length <= 0) {
                        $("#powerKeyword_none").show();
                    } 
                }
            } else if (graphType == "NEW") {
                if(res.data != null) {
                    this.setState( {newKeyword : res.data} );
                    console.log(this.state.newKeyword.keywordList.length);
                    if(this.state.newKeyword.keywordList.length <= 0) {
                        $("#newKeyword_none").show();
                    }
                }
            } else if (graphType == "MUST") {
                if(res.data != null) {
                    this.setState( {mustKeyword : res.data} );
                    if(this.state.mustKeyword.keywordList.length <= 0) {
                        $("#mustKeyword_none").show();
                    }
                }
            }
        // console.log("####### "+graphType+" #######");
        // console.log(res.data);
		})
		.catch(error => {
			// console.log (error);
		 });
    }

    getCategoryList() {
		http.get("/rest/common/eng/dict/category")
        .then(res => {
            if (res.data.session_error != null && res.data.session_error == "anonymousUser") {
                // alert("가입 후 이용 해주세요.")
                return false;
            }

        this.setState( {categoryList : res.data["categoryList"]} );
        // console.log(this.state.categoryList)
		})
		.catch(error => {
			// console.log (error);
		 });
    }


    getFeedCntData(keyword) {
		http.get("/rest/common/eng/state/feedCnt?keyword="+keyword+"&date="+nowDateForQuery)
        .then(res => {
            if (res.data.session_error != null && res.data.session_error == "anonymousUser") {
                // alert("가입 후 이용 해주세요.")
                return false;
            }

            this.setState({FeedCntData : {datasets: res.data.datasets, labels: res.data.labels}});
        // console.log(this.state.categoryList)
		})
		.catch(error => {
			// console.log (error);
		 });
    }

    onClickProduct = (item_name) => {
        ReactGA.event({
            category: category,
            action: "상품클릭 (" + item_name + ")",
            label: sessionStorage.getItem("userName")
        });
	};

    render() {
        return (
            <div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
                <div className="body over-hidden trendContainer">
                    <div className="shadow-box trendCategoryArea">
                        <Slider {...this.state.settingsCategory}
                                arrows={false}>
                        {this.state.categoryList.map((category, idx)=> (
                            <div className="slick-category" key={idx} onClick={this.onClickCategory}>
                                {category.category4}
                            </div>	
                        ))}
                        {/* <div className="slick-category">바지</div>
                        <div className="slick-category">블라우스</div>
                        <div className="slick-category">스커트</div>
                        <div className="slick-category">신발</div>
                        <div className="slick-category">원피스</div>
                        <div className="slick-category">자켓</div>
                        <div className="slick-category">청바지</div>
                        <div className="slick-category">티셔츠</div> */}
                        </Slider>
                    </div>
                    <div className="height-100 float-left leftTrendBoard leftTrendBoardMobile">
                        <div className="height-100 shadow-box over-auto" style={{height: "calc(100vh - 103px)"}}>
                            <div className="text-center">
                            <div className="padding-t-15 flexBox" style={{overflow: "hidden", marginTop:"10px"}}>
                                    <div style={{fontSize:"1.6vh"}} id="beforeDate" className="float-left margin-l-20 dateclicker" style={{fontSize:"1.6vh", flex:"1", lineHeight:"28px", textAlign:"left"}} onClick={this.onClickBeforeDate}>{/*04.12*/}</div>

                                    <div className="nowDate" style={{fontSize:"2.0vh", flex:"2", lineHeight:"28px"}}>
                                        {/* 2020|04.12 - 18 */}
                                    </div>

                                    <div style={{fontSize:"1.6vh"}} id="nextDate" className="float-right margin-r-20 date dateclicker" style={{fontSize:"1.6vh", flex:"1", lineHeight:"28px", textAlign:"right"}} onClick={this.onClickNextDate}>{/*04.18*/}</div>
                                </div>
                                <div id="mainKeyword">{/*BLOUSE*/}</div>

                                <div className="borderRadiusBox margin-auto keywordTypeName cursor-pointer" style={{marginTop:"50px"}} onClick={this.onClickKeywordTypeName}>
                                    POWER <b>TREND</b>
                                    {/* <div className="keywordTypeNameInfo">
                                    (인스타그램에서 뽑은 오늘의 급상승 키워드)
                                    </div> */}
                                </div>
                                <div className="keywordArea">
                                    {this.state.powerKeyword.keywordList.map((keyword, idx)=> (
                                        <div key={idx} className="cursor-pointer" onClick={this.onClickPowerKeyword}>
                                            {keyword.keyword}
                                        </div>	
                                    ))}
                                    <div id="powerKeyword_none" style={{textAlign:"center", color:"#c1c1c1"}}>
                                        키워드가 없습니다.
                                    </div>   
                                    {/* <div>스퀘어넥블라우스</div>
                                    <div>러플블라우스</div>
                                    <div>퍼블소매블라우스</div>
                                    <div>스모크블라우스</div> */}
                                </div>
                                {/* <div className="borderRadiusBox margin-auto keywordTypeName cursor-pointer" onClick={this.onClickKeywordTypeName}>
                                    NEW <b>TREND</b>
                                </div> */}
                                {/* <div className="keywordArea">
                                    {this.state.newKeyword.keywordList.map((keyword, idx)=> (
                                        <div key={idx}>
                                            {keyword.relKeyword}
                                        </div>
                                    ))}
                                    <div id="newKeyword_none" style={{textAlign:"center", color:"#c1c1c1"}}>
                                        키워드가 없습니다.
                                    </div>   	
                                </div> */}
                                <div className="borderRadiusBox margin-auto keywordTypeName cursor-pointer" onClick={this.onClickKeywordTypeName}>
                                    MUST <b>TREND</b>
                                    {/* <div className="keywordTypeNameInfo">
                                    (꾸준히 순위권에 진입한 키워드 (오늘로 부터 1년))
                                    </div>                                 */}
                                </div>
                                <div className="keywordArea">
                                    {this.state.mustKeyword.keywordList.map((keyword, idx)=> (
                                        <div key={idx}>
                                            {keyword.keyword}
                                        </div>
                                    ))}
                                    <div id="mustKeyword_none" style={{textAlign:"center", color:"#c1c1c1"}}>
                                        키워드가 없습니다.
                                    </div>   	
                                    {/* <div>오브숄더블라우스</div>
                                    <div>루즈핏블라우스</div>
                                    <div>라운드블라우스</div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="height-100 rightTrendBoard rightTrendBoardMobile float-right">
                        <div className="products-slick-box shadow-box width-100">
                        <div className="margin-b-auto keywordTypeName" style={{margin:"22px 0 0 8px", fontSize:"2vh"}}>
                            HOT <b>CORDINATION</b>
                        </div> 
                        <div className="keywordTypeName" style={{margin:"0 0 23px 8px", fontSize:"1vh"}}>
                            {/* 최근 새로운 영감을 주는 트렌디한 코디상품 */}
                            A trendy and inspirational product of late days.
                        </div> 
                            <Slider {...this.state.settings}
                            arrows={false}
                            ref={slider => this.slider = slider && slider['innerSlider']}>
                                
                                {this.state.goodsList.map((goods, idx)=> (
                                    <div className="product" key={idx}>
                                        <div className="thumbnail">
                                            <a href={goods.goods_url} target="_blank" onClick={() => this.onClickProduct(goods.item_name)}> <img src={goods.img_src}/> </a>
                                        </div>
                                    </div>
                                ))}    
                            {/* <div>
                                <div className="product">
                                    <div className="thumbnail">
                                    <a href="" target="blank"> <img src="https://img.otdeal.net/trend/3_2_16_14369.jpg"/> </a>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="product">
                                    <div className="thumbnail">
                                    <a href="" target="blank"> <img src="https://img.otdeal.net/trend/5_2_13_86021.jpg"/></a>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="product">
                                    <div className="thumbnail">
                                    <a href="" target="blank"> <img src="https://img.otdeal.net/trend/a44a1d2ffeba348c6e918debfb044ea335f681558c25a6fabd02f11a7094.jpg"/></a>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="product">
                                    <div className="thumbnail">
                                    <a href="" target="blank"> <img src="https://img.otdeal.net/trend/3_2_30_14309.jpg"/></a>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="product">
                                    <div className="thumbnail">
                                    <a href="" target="blank"> <img src="https://img.otdeal.net/trend/5_1_21_102753.jpg"/></a>
                                    </div>
                                </div>
                            </div> */}
                            
                            </Slider>
                        </div>
                        <div className="width-100 margin-t-10 innerMiddleTrendBoard" style={{fontSize:"2vh"}}>
                        <div className="shadow-box width-50-dis5 margin-r-5 padding-10 innerLeftTrendBoard over-auto" style={{marginBottom:"3px"}}> 
                                <div className="margin-b-auto keywordTypeName" style={{margin:"16px 0 0 0", fontSize:"2vh"}}>
                                    TREND <b>COLOR</b>
                                </div> 
                                <div className="keywordTypeName" style={{margin:"0 0 28px 0", fontSize:"1vh"}}>
                                <div className="nowDate" style={{display:"none"}}>
                                    {/* 2020|04.12 - 18 */}
                                </div>
                                {/* 가장 인기있는 컬러 */}
                                The most popular color.
                                </div> 
                                <Doughnut id="colorCanvas" data={this.state.colorData} options={this.state.options}/>
                            </div>
                            <div className="innerRightTitleTrendBoard shadow-box width-50-dis5 padding-10" style={{marginBottom:"3px"}}>
                                <div style={{textAlign:"right"}}>
                                    <div className="margin-b-auto keywordTypeName width-100" style={{padding:"16px 5px 0 0", fontSize:"2vh", textAlign:"right"}}>
                                        TREND <b>TAG</b>
                                    </div> 
                                    <div className="keywordTypeName width-100" style={{padding:"3px 5px 28px 0", fontSize:"1vh", textAlign:"right"}}>
                                    <div className="nowDate" style={{display:"none"}}>
                                    </div>
                                    {/* 가장 인기있는 연관 태그 */}
                                    The most popular Associated Tags.
                                    </div> 
                                </div>
                                <div className="margin-l-5 flexBox-align-start-center over-auto" style={{fontSize:"1.5vh"}}>
                                    {this.state.hashtagList.map((hashTag, idx)=> (
                                        <div className="tag" key={idx}>
                                            #{hashTag.relKeyword}
                                        </div>	
                                    ))}
                                </div>
                            </div>
                        </div>
                        {/* <div className="shadow-box width-100 height-fit-content padding-r-10 flexBox-no-wrap over-auto">
                            <div className="product">
                                <div className="thumbnail">
                                    <img src="https://thumbnail15.coupangcdn.com/thumbnails/remote/230x230ex/image/retail/images/2020/01/10/15/5/5553e83b-e891-448a-b92c-775ba25cba8c.jpg"/>
                                </div>
                                <div className="text-center">#basic</div>
                            </div>
                            <div className="product">
                                <div className="thumbnail">
                                    <img src="https://rozley.co.kr/web/product/big/201906/c75335a551dd275ca4341d983a7de186.jpg"/>
                                </div>
                                <div className="text-center">#minimum</div>
                            </div>
                            <div className="product">
                                <div className="thumbnail">
                                    <img src="http://www.kstarfashion.com/news/photo/201706/127317_58957_4928.jpg"/>
                                </div>
                                <div className="text-center">#stylelish</div>
                            </div>
                        </div> */}
                    </div>
                    
                    {/* modal */}
                    <div id="modal">
                        <div id="modalContent" className="shadow-box">
                                <h3></h3>
                                <div id="modal_dec"></div>
                                <br/>
                                <HorizontalBar data={this.state.barData} options={this.state.options}></HorizontalBar>
                        </div>
                        <div className="fa fa-close modalClose"></div>
                    </div>
                    <div id="modalBack">
                    </div>    
                    <div id="modal_canvas">
                        <div id="modalContent" className="shadow-box">
                            <div className="margin-b-auto keywordTypeName" style={{margin:"13px 0 0 0", fontSize:"20px"}}>
                                    TREND <b>COLOR</b>
                            </div> 
                            <br/>
                            <Doughnut data={this.state.colorData} options={this.state.options}/>
                        </div>
                        <div className="fa fa-close modalClose"></div>
                    </div>
                    <div id="modal_feed_cnt">
                        <div id="modalContent" className="shadow-box">
                            <div className="margin-b-auto keywordTypeName" style={{margin:"13px 0 0 0", fontSize:"20px"}}>
                                    INSTAGRAM <b>FEED</b>
                            </div> 
                            <br/>
                            <Line key={0} data={this.state.FeedCntData} options={this.state.options}/>
                        </div>
                        <div className="fa fa-close modalClose"></div>
                    </div>
                </div>
            </div>
        )
    }
}
import React from 'react';
import axios from 'axios';
import http from '../modules/api';
import {Line} from 'react-chartjs-2';
import { Link } from 'react-router-dom';

export class Main extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			start_date_format : "",
			end_date_format : "",
			item_start_date_format : "",
            item_end_date_format : "",
            sale_start_date_format : "",
			sale_end_date_format : "",
			eventData : {
				labels:[""],
				datasets:[]
			},
			itemData : {
				labels:[""],
				datasets:[]
            },
            saleData : {
				labels:[""],
				datasets:[]
			},
            options: {maintainAspectRatio: false},
            
            notice_list: [],
			notice_start_date_format: "",
			notice_end_date_format: "",
            
            qna_list: [],
			qna_start_date_format: "",
			qna_end_date_format: "",
		}
    }

    componentWillMount() {
		let date = new Date();
		let startDate = new Date(date.getFullYear()+"-"+(date.getMonth()+1)+"-"+"01");
		this.setState({end_date_format: date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate()});
		this.setState({start_date_format: date.getFullYear()+"-"+(date.getMonth()+1)+"-"+"01"});
		this.setState({end_date: date});
		this.setState({start_date: startDate});

		this.setState({item_end_date_format: date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate()});
		this.setState({item_start_date_format: date.getFullYear()+"-"+(date.getMonth()+1)+"-"+"01"});
		this.setState({item_end_date: date});
		this.setState({item_start_date: startDate});

		this.setState({sale_end_date_format: date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate()});
		this.setState({sale_start_date_format: date.getFullYear()+"-"+(date.getMonth()+1)+"-"+"01"});
		this.setState({sale_end_date: date});
		this.setState({sale_start_date: startDate});
	}

	componentDidMount() {
//		this.getEventSates();
//		this.getEventItemSates();
//        this.getEventSaleSates();
        
        this.getQnaList();
	}
    
    getEventSates() {
		http.get(`/rest/shop/event/shop/states?start_date=`+this.state.start_date_format+"&end_date="+this.state.end_date_format).then(res => {
            console.log(res.data);
			if(typeof res.data === 'string') {
				if(res.data.indexOf("<script>") >= 0){
                    alert("로그인 후 이용해 주세요.")
                    window.location.href = "/login";
				}
			}
            this.setState({eventData : {datasets: res.data.datasets, labels: res.data.labels}});
			// this.state.eventData.datasets = res.data.datasets;
			// this.state.eventData.labels = res.data.labels;
		})
		.catch(error => {
			// console.log (error);
		})
	}

	getEventItemSates() {
		http.get(`/rest/shop/event/item/shop/states?start_date=`+this.state.item_start_date_format+"&end_date="+this.state.item_end_date_format).then(res => {
			this.setState({itemData : {datasets: res.data.datasets, labels: res.data.labels}});
			// this.state.eventData.datasets = res.data.datasets;
			// this.state.eventData.labels = res.data.labels;
		})
		.catch(error => {
			// console.log (error);
		})
    }

    getEventSaleSates() {
		http.get(`/rest/shop/event/sale/shop/states?start_date=`+this.state.sale_start_date_format+"&end_date="+this.state.sale_end_date_format).then(res => {
			this.setState({saleData : {datasets: res.data.datasets, labels: res.data.labels}});
			// this.state.eventData.datasets = res.data.datasets;
			// this.state.eventData.labels = res.data.labels;
		})
		.catch(error => {
			console.log (error);
		})
	}
    
    getNoticeList() {
		http.get("/rest/shop/list/notice?start_date="+this.state.notice_start_date_format+"&end_date="+this.state.notice_end_date_format+"&startidx="+0+"&rowcnt="+5)
		.then(res => {
            this.setState( {notice_list : res.data["list"]} );
            for (var idx in this.state.notice_list) {
                this.state.notice_list[idx].reg_date = ('0' + new Date(this.state.notice_list[idx].reg_date).getMonth ()).slice(-2) + '-'
             +  ('0' + new Date(this.state.notice_list[idx].reg_date).getDate ()).slice(-2);
            }
		})
		.catch(error => {
			// console.log (error);
		 });
    }

    getQnaList() {
		http.get("/rest/shop/list/qna?start_date="+this.state.qna_start_date_format+"&end_date="+this.state.qna_end_date_format+"&startidx="+0+"&rowcnt="+13)
		.then(res => {
            this.setState( {qna_list : res.data["list"]} );
            for (var idx in this.state.qna_list) {
                this.state.qna_list[idx].reg_date = ('0' + new Date(this.state.qna_list[idx].reg_date).getMonth ()).slice(-2) + '-'
             +  ('0' + new Date(this.state.qna_list[idx].reg_date).getDate ()).slice(-2);
            }
			// console.log(this.state.totalCnt)
		})
		.catch(error => {
			// console.log (error);
		 });
    }
    

    handleNoticeClick = (notice_seq) => {
		this.props.history.push(`/admin/notice/detail/${notice_seq}`);
    };
    
    handleQnaClick = (qna_seq) => {
		this.props.history.push(`/admin/qna/detail/${qna_seq}`);
	};

    render() {
		
        return (
            <div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
                <h4>[Admin] 메인</h4>
                <div style={{display:"flex", flexWrap: "wrap", width:"100%"}}>
                    {/* <div className="mainDashBox" style={{width:"48%", margin:"10px"}}>
                        <div style={{overflow: "auto", marginBottom:"10px"}}><div style={{float:"left"}}>공지사항</div><div style={{float:"right"}}><Link to="">더보기</Link></div></div>
                        <div className="shadow-box" style={{padding:"5px"}}>
                            {this.state.notice_list.map(notice => (
                                <div key={notice.notice_seq}>
                                    <div key={notice.notice_seq} style={{overflow: "hidden",fontSize:"13px"}}>
                                        <div style={{float: "right",display:"flex", fontSize:"13px"}}>
                                            <div style={{color:"#bbbbbb"}}>
                                                <p className="card-text">{notice.user_name}</p>
                                            </div>
                                            <div style={{marginLeft:"10px", textAlign:"right", color:"#bbbbbb", fontSize:"13px"}}> 
                                                <p className="card-text">{notice.reg_date}</p>
                                            </div>
                                        </div>
                                        <div className="mainBoardTitle">
                                            <p id="title" style={{textOverflow: "ellipsis", overflow: "hidden"}} className="card-text cursor-pointer" onClick={() => this.handleNoticeClick(notice.notice_seq)}>{notice.title}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>  */}
                    {/* <div className="mainDashBox" style={{width:"48%", margin:"10px"}}> */}
                    <div className="mainDashBox" style={{width:"96%", margin:"10px"}}>
                        <div style={{overflow: "auto", marginBottom:"10px"}}><div style={{float:"left"}}>사용자 QnA</div><div style={{float:"right"}}><Link to="/admin/qna/list">더보기</Link></div></div>
                        <div className="shadow-box" style={{padding:"5px"}}>
                            {this.state.qna_list.map(qna => (
                                <div key={qna.qna_seq}>
                                    <div key={qna.qna_seq} style={{overflow: "hidden"}}>
                                        <div style={{float: "right",display:"flex", fontSize:"13px"}}>
                                            <div style={{color:"#bbbbbb", fontSize:"13px"}}>
                                                <p className="card-text">{qna.company_name}</p>
                                            </div>
                                            <div style={{marginLeft:"10px", textAlign:"right", color:"#bbbbbb", fontSize:"13px"}}> 
                                                <p className="card-text">{qna.reg_date}</p>
                                            </div>
                                            <div style={{marginLeft:"10px", textAlign:"right", color:"#bbbbbb", fontSize:"13px"}}> 
                                            {(() => {
					                            if (qna.answer_yn == "Y") {
                                                    return (<p className="card-text">답변완료</p>)
                                                } else {
                                                    return (<p className="card-text" style={{color:"#ff5d63"}}>미답변</p>)
                                                }
                                            })()}
                                            </div>
                                        </div>
                                        <div className="mainBoardTitle">
                                            <p style={{textOverflow: "ellipsis", overflow: "hidden"}}  id="title" className="card-text cursor-pointer" onClick={() => this.handleQnaClick(qna.qna_seq)}>{qna.title}</p>
                                        </div>
                                        {/* <td>
                                            <p className="card-text">{notice.mod_date}</p>
                                        </td> */}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div> 
                    {/* <div className="mainDashBox" style={{width:"48%", margin:"10px"}}>
                        <div style={{overflow: "auto", marginBottom:"10px"}}><div style={{float:"left"}}>최근 배너 클릭 수</div><div style={{float:"right"}}><Link to="/admin/event/state">더보기</Link></div></div>
                        <div className="shadow-box mainDashBoxState" >
                        <Line key={0} data={this.state.eventData} options={this.state.options}/> 
                        </div>
                    </div> 
                    <div className="mainDashBox" style={{width:"48%", margin:"10px"}}>
                        <div style={{overflow: "auto", marginBottom:"10px"}}><div style={{float:"left"}}>최근 상품 클릭 수</div><div style={{float:"right"}}><Link to="/admin/event/state">더보기</Link></div></div>
                        <div  className="shadow-box mainDashBoxState">
                        <Line key={1} data={this.state.itemData} options={this.state.options}/> 
                        </div>
                    </div> 
                    <div className="mainDashBox" style={{width:"48%", margin:"10px"}}>
                        <div style={{overflow: "auto", marginBottom:"10px"}}><div style={{float:"left"}}>최근 상품 판매 수</div><div style={{float:"right"}}><Link to="/admin/event/state">더보기</Link></div></div>
                        <div className="shadow-box mainDashBoxState">
                        <Line key={2} data={this.state.saleData} options={this.state.options}/> 
                        </div>
                    </div>  */}
                </div>
            </div>
        )
    };
}

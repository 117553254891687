import React, { useCallback, useState, useRef } from 'react';
import axios from '../modules/api';
import http from '../modules/api';
import {Line} from 'react-chartjs-2';
import { NavLink, Link } from 'react-router-dom';
import $ from 'jquery';
import { useDropzone } from 'react-dropzone';
import ReactGA, { send } from 'react-ga';

import * as Menu from '../Menu_v2';

import logo_v2_white from '../../resources/img/logo_v2_white.svg';
import favicon_v2 from '../../resources/img/favicon_v2.svg';
import logo_mobile_white from '../../resources/img/favicon_white_v2.svg';
import { breakStatement } from '@babel/types';

ReactGA.initialize('UA-78553461-8', {
    titleCase: false,
});

function MyDropzone(props) {
    //const [uploadFiles, imageArray] = useState([]);
  
    const onDrop = useCallback(async (acceptedFiles) => {
      //props.attachImage(acceptedFiles)
      props.this.attachImage(acceptedFiles);
    }, []);
  
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  
    const InputProps = {
      ...getInputProps(),
      multiple: true,
      accept: 'image/jpg,image/png,image/jpeg,image/gif,image/bmp,image/tiff',
    };
  
    const RootProps = {
      ...getRootProps(),
    };
  
    return (
      <div
        className="demoAdMyDropzone"
        {...getRootProps()}
        style={{
          width:"450px",
          height: '280px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '15px',
          cursor: 'pointer',
          backgroundColor: 'white',
          maxWidth: '100%',
          maxHeight: '50vh',
          color:"grey",
          borderRadius:"13px"
        }}
      >
        <input type="file" {...InputProps} />
        {isDragActive ? (
          <div>이제 이미지를 놓아주세요.</div>
        ) : (
          <div>
            여기를 클릭해 파일을 선택하시거나
            <br/>
            드레그 {"&"} 드롭 해 주세요.
          </div>
        )}
      </div>
    );
  }

export class DemoCreateAd extends React.Component {
    constructor(props) {
		super(props);
        this.emailRef = React.createRef();

		this.state = {
            step: 0,

            /* state */
            is_email_validate: true,
            is_img_uploading: false,
            is_img_uploaded: false,
            is_background_color_seletor_open: false,
            is_text_insert: false,
            is_text_not_insert: false,
            is_text_direct_input: false,
            is_complain_text_area_show: false,
            is_btn_next_show: false,
            
            /* value */
            email: "",
            complain_text_value: "",
            event_seq: "",
            event_platform_type: "",
            event_seq_idx: 0,

            event_seq_list:[
                // {event_seq:"7607", platform_type:"p", event_type_name:"pc A형"},
                // {event_seq:"7606", platform_type:"m", event_type_name:"mobile A-1형"},
                // {event_seq:"7605", platform_type:"m", event_type_name:"mobile B형"},
                // {event_seq:"7604", platform_type:"m", event_type_name:"mobile B-1형"},
                // {event_seq:"7603", platform_type:"m", event_type_name:"mobile C형"},
            ],

            color_idx: 0,
            color_code: "",
            color_code_list:[
                // {bg_color_code:"#000000", text_color_code:"#FFFFFF"},
                // {bg_color_code:"#000000", text_color_code:"#FFFFFF"},
                // {bg_color_code:"#000000", text_color_code:"#FFFFFF"},
                // {bg_color_code:"#000000", text_color_code:"#FFFFFF"},
                // {bg_color_code:"#000000", text_color_code:"#FFFFFF"},
                // {bg_color_code:"#000000", text_color_code:"#FFFFFF"},
                // {bg_color_code:"#000000", text_color_code:"#FFFFFF"},
                // {bg_color_code:"#000000", text_color_code:"#FFFFFF"},
                // {bg_color_code:"#000000", text_color_code:"#FFFFFF"},
                // {bg_color_code:"#000000", text_color_code:"#FFFFFF"},
            ],

            img_url: "",
            img_dominent_color_code: "",
            img_color_code_1: "",
            img_color_code_2: "",
            img_color_code_3: "",
            img_color_code_4: "",

            bg_color_code: "",
            text_color_code: "",
            
            ad_text_list: [],
            ad_text: "",
            ad_idx: 0,
		}
    }

    componentWillMount() {
        // Menu.js 같이 배포 해야함. 이유: 해당 페이지는 메뉴 표시 안 하도록 코딩되어 있음. 해당 페이지는 권한 조회 하지 않음.
        // SecurityConfiguration.java 같이 배포 해야함. 이유: 권한 없이 호출 가능한 페이지라고 코딩 되어 있음.

        // setGaEvent 함수 category 명칭 변경 해야함 !!
        // this.state.email 초기화 해야함 !!
	}

	componentDidMount() {
        var this_component = this;

        this.backgroudPosition();
        $(window).resize(function () {
            this_component.backgroudPosition();
        })
    }

    componentDidUpdate() {
       
    }

    setGaEvent(action_text) {
       ReactGA.event({
            category: "데모 광고 서비스 (테스트)",
            // category: "데모 광고 서비스",
            action: action_text,
            label: "이메일(아이디/도메인):" + this.state.email.split("@")[0]+ "/" +this.state.email.split("@")[1]
        });
    }

    setGaEventIncorrect(action_text) {
        if (this.state.email.indexOf("@") < 0) {
            ReactGA.event({
                category: "데모 광고 서비스 (테스트- 이메일 틀리게 넣은 경우)",
                // category: "데모 광고 서비스",
                action: action_text,
                label: "이메일(아이디/도메인):" + this.state.email.split("@")[0]+ "/" + ""
            });
        } else {
            ReactGA.event({
                category: "데모 광고 서비스 (테스트- 이메일 틀리게 넣은 경우)",
                // category: "데모 광고 서비스",
                action: action_text,
                label: "이메일(아이디/도메인):" + this.state.email.split("@")[0]+ "/" +this.state.email.split("@")[1]
            });
        }
    }

    backgroudPosition () {
        if (Number(this.state.step) == 1) {
            if ($(window).width() >= $(window).height()) {
                $(".circle_purple_area").css("width",(100*($(window).height() / $(window).width()))+"vw");
                $(".circle_purple_area").css("height",(100*($(window).height() / $(window).width()))+"vw");
                $(".circle_purple_area_2").css("width",(100*($(window).height() / $(window).width()))+"vw");
                $(".circle_purple_area_2").css("height",(100*($(window).height() / $(window).width()))+"vw");
                if ($(window).height() / $(window).width() > 0.95) {
                    $(".circle_purple_area").css("top",(-55*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("bottom",(-55*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area").css("left",(-55*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("right",(-55*(($(window).height() / $(window).width())))+"vw");
                } else if ($(window).height() / $(window).width() > 0.9) {
                    $(".circle_purple_area").css("top",(-53*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("bottom",(-53*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area").css("left",(-53*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("right",(-53*(($(window).height() / $(window).width())))+"vw");
                } else if ($(window).height() / $(window).width() > 0.85) {
                    $(".circle_purple_area").css("top",(-50*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("bottom",(-50*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area").css("left",(-50*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("right",(-50*(($(window).height() / $(window).width())))+"vw");
                } else if ($(window).height() / $(window).width() > 0.8) {
                    $(".circle_purple_area").css("top",(-48*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("bottom",(-48*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area").css("left",(-48*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("right",(-48*(($(window).height() / $(window).width())))+"vw");
                } else if ($(window).height() / $(window).width() > 0.75) {
                    $(".circle_purple_area").css("top",(-45*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("bottom",(-45*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area").css("left",(-45*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("right",(-45*(($(window).height() / $(window).width())))+"vw");
                } else if ($(window).height() / $(window).width() > 0.7) {
                    $(".circle_purple_area").css("top",(-43*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("bottom",(-43*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area").css("left",(-43*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("right",(-43*(($(window).height() / $(window).width())))+"vw");
                } else if ($(window).height() / $(window).width() > 0.6) {
                    $(".circle_purple_area").css("top",(-40*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("bottom",(-40*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area").css("left",(-40*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("right",(-40*(($(window).height() / $(window).width())))+"vw");
                } else {
                    $(".circle_purple_area").css("top","-22vw");
                    $(".circle_purple_area").css("left","-22vw");
                    $(".circle_purple_area_2").css("bottom","-22vw");
                    $(".circle_purple_area_2").css("right","-22vw");

                    $(".circle_purple_area").css("width","");
                    $(".circle_purple_area").css("height","");
                    $(".circle_purple_area_2").css("width","");
                    $(".circle_purple_area_2").css("height","");
                }
            } else {
                $(".circle_purple_area").css("width",(100*($(window).width() / $(window).height()))+"vh");
                $(".circle_purple_area").css("height",(100*($(window).width() / $(window).height()))+"vh");
                $(".circle_purple_area_2").css("width",(100*($(window).width() / $(window).height()))+"vh");
                $(".circle_purple_area_2").css("height",(100*($(window).width() / $(window).height()))+"vh");
                if ($(window).width() / $(window).height() > 0.9) {
                    $(".circle_purple_area").css("top",(-42*(($(window).width() / $(window).height())))+"vh");
                    $(".circle_purple_area_2").css("bottom",(-42*(($(window).width() / $(window).height())))+"vh");
                    $(".circle_purple_area").css("left",(-42*(($(window).width() / $(window).height())))+"vh");
                    $(".circle_purple_area_2").css("right",(-42*(($(window).width() / $(window).height())))+"vh");
                } else if ($(window).width() / $(window).height() > 0.8) {
                    $(".circle_purple_area").css("top",(-50*(($(window).width() / $(window).height())))+"vh");
                    $(".circle_purple_area_2").css("bottom",(-50*(($(window).width() / $(window).height())))+"vh");
                    $(".circle_purple_area").css("left",(-50*(($(window).width() / $(window).height())))+"vh");
                    $(".circle_purple_area_2").css("right",(-50*(($(window).width() / $(window).height())))+"vh");
                } else if ($(window).width() / $(window).height() > 0.75) {
                    $(".circle_purple_area").css("top",(-45*(($(window).width() / $(window).height())))+"vh");
                    $(".circle_purple_area_2").css("bottom",(-45*(($(window).width() / $(window).height())))+"vh");
                    $(".circle_purple_area").css("left",(-45*(($(window).width() / $(window).height())))+"vh");
                    $(".circle_purple_area_2").css("right",(-45*(($(window).width() / $(window).height())))+"vh");
                } else if ($(window).width() / $(window).height() > 0.65) {
                    $(".circle_purple_area").css("top","-26vh");
                    $(".circle_purple_area").css("left","-26vh");
                    $(".circle_purple_area_2").css("bottom","-26vh");
                    $(".circle_purple_area_2").css("right","-26vh");

                    $(".circle_purple_area").css("width","");
                    $(".circle_purple_area").css("height","");
                    $(".circle_purple_area_2").css("width","");
                    $(".circle_purple_area_2").css("height","");
                } else if ($(window).width() / $(window).height() > 0.55) {
                    $(".circle_purple_area").css("top","-27vh");
                    $(".circle_purple_area").css("left","-27vh");
                    $(".circle_purple_area_2").css("bottom","-27vh");
                    $(".circle_purple_area_2").css("right","-27vh");

                    $(".circle_purple_area").css("width","");
                    $(".circle_purple_area").css("height","");
                    $(".circle_purple_area_2").css("width","");
                    $(".circle_purple_area_2").css("height","");
                } else {
                    $(".circle_purple_area").css("top","-29vh");
                    $(".circle_purple_area").css("left","-29vh");
                    $(".circle_purple_area_2").css("bottom","-29vh");
                    $(".circle_purple_area_2").css("right","-29vh");

                    $(".circle_purple_area").css("width","");
                    $(".circle_purple_area").css("height","");
                    $(".circle_purple_area_2").css("width","");
                    $(".circle_purple_area_2").css("height","");
                }
            }
        } else if (Number(this.state.step) == 2 || Number(this.state.step) == 2.5) {
            if ($(window).width() >= $(window).height()) {
                $(".circle_purple_area").css("width",(100*($(window).height() / $(window).width()))+"vw");
                $(".circle_purple_area").css("height",(100*($(window).height() / $(window).width()))+"vw");
                $(".circle_purple_area_2").css("width",(100*($(window).height() / $(window).width()))+"vw");
                $(".circle_purple_area_2").css("height",(100*($(window).height() / $(window).width()))+"vw");
                if ($(window).height() / $(window).width() > 0.95) {
                    $(".circle_purple_area").css("top",(-45*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("bottom",(-45*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area").css("left",(-45*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("right",(-45*(($(window).height() / $(window).width())))+"vw");
                } else if ($(window).height() / $(window).width() > 0.9) {
                    $(".circle_purple_area").css("top",(-43*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("bottom",(-43*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area").css("left",(-43*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("right",(-43*(($(window).height() / $(window).width())))+"vw");
                } else if ($(window).height() / $(window).width() > 0.85) {
                    $(".circle_purple_area").css("top",(-40*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("bottom",(-40*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area").css("left",(-40*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("right",(-40*(($(window).height() / $(window).width())))+"vw");
                } else if ($(window).height() / $(window).width() > 0.8) {
                    $(".circle_purple_area").css("top",(-38*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("bottom",(-38*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area").css("left",(-38*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("right",(-38*(($(window).height() / $(window).width())))+"vw");
                } else if ($(window).height() / $(window).width() > 0.75) {
                    $(".circle_purple_area").css("top",(-35*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("bottom",(-35*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area").css("left",(-35*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("right",(-35*(($(window).height() / $(window).width())))+"vw");
                } else if ($(window).height() / $(window).width() > 0.7) {
                    $(".circle_purple_area").css("top",(-33*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("bottom",(-33*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area").css("left",(-33*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("right",(-33*(($(window).height() / $(window).width())))+"vw");
                } else if ($(window).height() / $(window).width() > 0.6) {
                    $(".circle_purple_area").css("top",(-30*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("bottom",(-30*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area").css("left",(-30*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("right",(-30*(($(window).height() / $(window).width())))+"vw");
                } else {
                    $(".circle_purple_area").css("top","-15vw");
                    $(".circle_purple_area").css("left","-15vw");
                    $(".circle_purple_area_2").css("bottom","-15vw");
                    $(".circle_purple_area_2").css("right","-15vw");

                    $(".circle_purple_area").css("width","");
                    $(".circle_purple_area").css("height","");
                    $(".circle_purple_area_2").css("width","");
                    $(".circle_purple_area_2").css("height","");
                }
            } else {
                $(".circle_purple_area").css("width",(100*($(window).width() / $(window).height()))+"vh");
                $(".circle_purple_area").css("height",(100*($(window).width() / $(window).height()))+"vh");
                $(".circle_purple_area_2").css("width",(100*($(window).width() / $(window).height()))+"vh");
                $(".circle_purple_area_2").css("height",(100*($(window).width() / $(window).height()))+"vh");
                if ($(window).width() / $(window).height() > 0.9) {
                    $(".circle_purple_area").css("top",(-42*(($(window).width() / $(window).height())))+"vh");
                    $(".circle_purple_area_2").css("bottom",(-42*(($(window).width() / $(window).height())))+"vh");
                    $(".circle_purple_area").css("left",(-42*(($(window).width() / $(window).height())))+"vh");
                    $(".circle_purple_area_2").css("right",(-42*(($(window).width() / $(window).height())))+"vh");
                } else if ($(window).width() / $(window).height() > 0.8) {
                    $(".circle_purple_area").css("top",(-40*(($(window).width() / $(window).height())))+"vh");
                    $(".circle_purple_area_2").css("bottom",(-40*(($(window).width() / $(window).height())))+"vh");
                    $(".circle_purple_area").css("left",(-40*(($(window).width() / $(window).height())))+"vh");
                    $(".circle_purple_area_2").css("right",(-40*(($(window).width() / $(window).height())))+"vh");
                } else if ($(window).width() / $(window).height() > 0.75) {
                    $(".circle_purple_area").css("top",(-35*(($(window).width() / $(window).height())))+"vh");
                    $(".circle_purple_area_2").css("bottom",(-35*(($(window).width() / $(window).height())))+"vh");
                    $(".circle_purple_area").css("left",(-35*(($(window).width() / $(window).height())))+"vh");
                    $(".circle_purple_area_2").css("right",(-35*(($(window).width() / $(window).height())))+"vh");
                } else if ($(window).width() / $(window).height() > 0.65) {
                    $(".circle_purple_area").css("top","-13vh");
                    $(".circle_purple_area").css("left","-13vw");
                    $(".circle_purple_area_2").css("bottom","-13vh");
                    $(".circle_purple_area_2").css("right","-13vw");

                    $(".circle_purple_area").css("width","");
                    $(".circle_purple_area").css("height","");
                    $(".circle_purple_area_2").css("width","");
                    $(".circle_purple_area_2").css("height","");
                } else if ($(window).width() / $(window).height() > 0.55) {
                    $(".circle_purple_area").css("top","-15vh");
                    $(".circle_purple_area").css("left","-15vw");
                    $(".circle_purple_area_2").css("bottom","-15vh");
                    $(".circle_purple_area_2").css("right","-15vw");

                    $(".circle_purple_area").css("width","");
                    $(".circle_purple_area").css("height","");
                    $(".circle_purple_area_2").css("width","");
                    $(".circle_purple_area_2").css("height","");
                } else {
                    $(".circle_purple_area").css("top","-16vh");
                    $(".circle_purple_area").css("left","-16vh");
                    $(".circle_purple_area_2").css("bottom","-16vh");
                    $(".circle_purple_area_2").css("right","-16vh");

                    $(".circle_purple_area").css("width","");
                    $(".circle_purple_area").css("height","");
                    $(".circle_purple_area_2").css("width","");
                    $(".circle_purple_area_2").css("height","");
                }
            }
        } else if (Number(this.state.step) == 3) {
            if ($(window).width() >= $(window).height()) {
                $(".circle_purple_area").css("width",(100*($(window).height() / $(window).width()))+"vw");
                $(".circle_purple_area").css("height",(100*($(window).height() / $(window).width()))+"vw");
                $(".circle_purple_area_2").css("width",(100*($(window).height() / $(window).width()))+"vw");
                $(".circle_purple_area_2").css("height",(100*($(window).height() / $(window).width()))+"vw");
                if ($(window).height() / $(window).width() > 0.95) {
                    $(".circle_purple_area").css("top",(-35*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("bottom",(-35*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area").css("left",(-35*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("right",(-35*(($(window).height() / $(window).width())))+"vw");
                } else if ($(window).height() / $(window).width() > 0.9) {
                    $(".circle_purple_area").css("top",(-33*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("bottom",(-33*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area").css("left",(-33*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("right",(-33*(($(window).height() / $(window).width())))+"vw");
                } else if ($(window).height() / $(window).width() > 0.85) {
                    $(".circle_purple_area").css("top",(-30*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("bottom",(-30*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area").css("left",(-30*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("right",(-30*(($(window).height() / $(window).width())))+"vw");
                } else if ($(window).height() / $(window).width() > 0.8) {
                    $(".circle_purple_area").css("top",(-28*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("bottom",(-28*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area").css("left",(-28*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("right",(-28*(($(window).height() / $(window).width())))+"vw");
                } else if ($(window).height() / $(window).width() > 0.75) {
                    $(".circle_purple_area").css("top",(-25*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("bottom",(-25*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area").css("left",(-25*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("right",(-25*(($(window).height() / $(window).width())))+"vw");
                } else if ($(window).height() / $(window).width() > 0.7) {
                    $(".circle_purple_area").css("top",(-23*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("bottom",(-23*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area").css("left",(-23*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("right",(-23*(($(window).height() / $(window).width())))+"vw");
                } else if ($(window).height() / $(window).width() > 0.6) {
                    $(".circle_purple_area").css("top",(-20*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("bottom",(-20*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area").css("left",(-20*(($(window).height() / $(window).width())))+"vw");
                    $(".circle_purple_area_2").css("right",(-20*(($(window).height() / $(window).width())))+"vw");
                } else {
                    $(".circle_purple_area").css("top","-8vw");
                    $(".circle_purple_area").css("left","-8vw");
                    $(".circle_purple_area_2").css("bottom","-8vw");
                    $(".circle_purple_area_2").css("right","-8vw");

                    $(".circle_purple_area").css("width","");
                    $(".circle_purple_area").css("height","");
                    $(".circle_purple_area_2").css("width","");
                    $(".circle_purple_area_2").css("height","");
                }
            } else {
                $(".circle_purple_area").css("width",(100*($(window).width() / $(window).height()))+"vh");
                $(".circle_purple_area").css("height",(100*($(window).width() / $(window).height()))+"vh");
                $(".circle_purple_area_2").css("width",(100*($(window).width() / $(window).height()))+"vh");
                $(".circle_purple_area_2").css("height",(100*($(window).width() / $(window).height()))+"vh");
                if ($(window).width() / $(window).height() > 0.9) {
                    $(".circle_purple_area").css("top",(-32*(($(window).width() / $(window).height())))+"vh");
                    $(".circle_purple_area_2").css("bottom",(-32*(($(window).width() / $(window).height())))+"vh");
                    $(".circle_purple_area").css("left",(-32*(($(window).width() / $(window).height())))+"vh");
                    $(".circle_purple_area_2").css("right",(-32*(($(window).width() / $(window).height())))+"vh");
                } else if ($(window).width() / $(window).height() > 0.8) {
                    $(".circle_purple_area").css("top",(-30*(($(window).width() / $(window).height())))+"vh");
                    $(".circle_purple_area_2").css("bottom",(-30*(($(window).width() / $(window).height())))+"vh");
                    $(".circle_purple_area").css("left",(-30*(($(window).width() / $(window).height())))+"vh");
                    $(".circle_purple_area_2").css("right",(-30*(($(window).width() / $(window).height())))+"vh");
                } else if ($(window).width() / $(window).height() > 0.75) {
                    $(".circle_purple_area").css("top",(-25*(($(window).width() / $(window).height())))+"vh");
                    $(".circle_purple_area_2").css("bottom",(-25*(($(window).width() / $(window).height())))+"vh");
                    $(".circle_purple_area").css("left",(-25*(($(window).width() / $(window).height())))+"vh");
                    $(".circle_purple_area_2").css("right",(-25*(($(window).width() / $(window).height())))+"vh");
                } else if ($(window).width() / $(window).height() > 0.65) {
                    $(".circle_purple_area").css("top","-7vh");
                    $(".circle_purple_area").css("left","-7vw");
                    $(".circle_purple_area_2").css("bottom","-7vh");
                    $(".circle_purple_area_2").css("right","-7vw");

                    $(".circle_purple_area").css("width","");
                    $(".circle_purple_area").css("height","");
                    $(".circle_purple_area_2").css("width","");
                    $(".circle_purple_area_2").css("height","");
                } else if ($(window).width() / $(window).height() > 0.55) {
                    $(".circle_purple_area").css("top","-8vh");
                    $(".circle_purple_area").css("left","-8vw");
                    $(".circle_purple_area_2").css("bottom","-8vh");
                    $(".circle_purple_area_2").css("right","-8vw");

                    $(".circle_purple_area").css("width","");
                    $(".circle_purple_area").css("height","");
                    $(".circle_purple_area_2").css("width","");
                    $(".circle_purple_area_2").css("height","");
                } else {
                    $(".circle_purple_area").css("top","-9vh");
                    $(".circle_purple_area").css("left","-9vh");
                    $(".circle_purple_area_2").css("bottom","-9vh");
                    $(".circle_purple_area_2").css("right","-9vh");

                    $(".circle_purple_area").css("width","");
                    $(".circle_purple_area").css("height","");
                    $(".circle_purple_area_2").css("width","");
                    $(".circle_purple_area_2").css("height","");
                }
            }
        }
    }

    
    onClickNextStep = e => {
        if (Number(this.state.step) == 3) {
            return false;
        }

        /* validation check */


        /* GA 이벤트 (다음 클릭) */
        if ((Number(this.state.step) == 1)) {
            if (this.state.uploadFiles != null) {
                this.setGaEvent("1단계 사진 업로드 다음 클릭");
                // alert("1단계 사진 업로드 다음 클릭 : "+this.state.email);
            } else {
                this.setGaEvent("1단계 사진 업로드 다음 클릭");
                // alert("1단계 배경색 다음 클릭 : "+this.state.email);
            }
        } else if ((Number(this.state.step) == 2)) {
            if(this.state.is_text_insert) {
                if (this.state.is_text_direct_input) {
                    this.setGaEvent("2단계 직접작성 다음 클릭");
                    // alert("2단계 직접작성 다음 클릭 : "+this.state.email);
                } else {
                    this.setGaEvent("2단계 제시문구 다음 클릭");
                    // alert("2단계 제시문구 다음 클릭 : "+this.state.email);
                }
            } else {
                    this.setGaEvent("2단계 다음 클릭");
                    // alert("2단계 다음 클릭 : "+this.state.email);
            }
        }

        /* Go to next step */
        if (Number(this.state.step) != 2 && Number(this.state.step) != 2.5) {
            this.setState({
                step: Number(this.state.step) + 1,
                is_btn_next_show: false,
            }, () => {
                console.log(this.state.step)
                this.backgroudPosition();

                if (Number(this.state.step) == 3) {
                    this.setState({
                        event_seq: this.state.event_seq_list[0].event_seq,
                        event_platform_type: this.state.event_seq_list[0].platform_type,
                        event_seq_idx: this.state.event_seq_idx + 1,
                    })
                }
            })
        } else {
            this.setState({
                step: Number(this.state.step) + 0.5,
                is_btn_next_show: false,
            }, () => {
                console.log(this.state.step)
                this.backgroudPosition();

                if (this.state.step == 2.5) {
                    this.createAutoEvent();
                }
            })
        }
    } 

    onClickGoFirstStep = e => {

        if (this.state.step == 1) {
            if (this.state.uploadFiles != null) {
                this.setGaEvent("1단계 사진 업로드 다시하기 클릭");
                // alert("1단계 사진 업로드 다시하기 클릭 : "+this.state.email);
            } else if (this.state.color_code != null && this.state.color_code != "") {
                this.setGaEvent("1단계 배경색 고르기 다시하기 클릭");
                // alert("1단계 배경색 고르기 다시하기 클릭 : "+this.state.email);
            } else {
                this.setGaEvent("1단계 다시하기 클릭");
                // alert("1단계 다시하기 클릭 : "+this.state.email);
            }
        } else if (this.state.step == 2) {
            if(this.state.is_text_insert) {
                if (this.state.is_text_direct_input) {
                    this.setGaEvent("2단계 직접작성 다시하기 클릭");
                    // alert("2단계 직접작성 다시하기 클릭 : "+this.state.email);
                } else {
                    this.setGaEvent("2단계 제시문구 다시하기 클릭");
                    // alert("2단계 제시문구 다시하기 클릭 : "+this.state.email);
                }
            } else {
                    this.setGaEvent("2단계 다시하기 클릭");
                    // alert("2단계 다시하기 클릭 : "+this.state.email);
            }
        } else if (this.state.step == 3) {
            if (!this.state.is_close_click) {
                this.setState({
                    is_close_click: false
                })

                this.setGaEvent("3단계 다시하기 클릭");
                // alert("3단계 다시하기 클릭 : "+this.state.email);
            }
        }

        this.setState({
            step: 1,

            /* state */
            is_email_validate: true,
            is_img_uploading: false,
            is_img_uploaded: false,
            is_background_color_seletor_open: false,
            is_text_insert: false,
            is_text_not_insert: false,
            is_text_direct_input: false,
            is_complain_text_area_show: false,
            is_btn_next_show: false,
            
            /* value */
            complain_text_value: "",
            event_seq: "",
            event_platform_type: "",
            event_seq_idx: 0,

            event_seq_list:[],

            color_idx: 0,
            color_code: "",
            color_code_list:[],

            img_url: "",
            img_dominent_color_code: "",
            img_color_code_1: "",
            img_color_code_2: "",
            img_color_code_3: "",
            img_color_code_4: "",
            
            ad_text_list: [],
            ad_text: "",
        }, () => {
            console.log(this.state.step)
            this.backgroudPosition();
        })
    }

    onKeyDownEmailInput = e => {
        if (e.key === "Enter") {
            this.onClicSubmitEmail(e);
	    }
    }

    onClicSubmitEmail = e => {
        var email_value = this.emailRef.current.value;
        var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
        if (re.test(email_value)) {

            // 이메일 - 약관 저장
            const config = {
            headers: {
                'Content-Type': 'application/json',
                },
            };
            const url = '/rest/demo/ad-trial/emailFormSave';
            const body = {
                email : email_value,
            }
            http
            .post(url, body, config)
            .then((res) => {
                if (res.data.code == "200") {
                    this.setState({
                        is_email_validate:true,
                        email:email_value
                    },() => {
                        this.setGaEvent("이메일 잘 입력 후 '체험해보기' 클릭");
                        //alert("이메일 잘 입력 후 '체험해보기' 클릭 : "+this.state.email);
                        this.onClickNextStep();
                    })
                } else {
                    alert(res.data.code + "\n" + res.data.msg);
                    this.onClickGoFirstStep();
                }
            })
            .catch((error) => {
                alert(error + '\n광고 서비스 사용자 약관 저장 실패.\n관리자에게 문의해주세요(1599-3518)');
                this.onClickGoFirstStep();
            });
        } else {
            this.setState({
                is_email_validate:false,
                email:email_value
            },() => {
                this.setGaEventIncorrect("이메일 틀리게 입력 후 '체험해보기' 클릭");
                // alert("이메일 틀리게 입력 후 '체험해보기' 클릭 : "+this.state.email);
            })
        }
    }

  // 업로드된 이미지 Result
  attachImage(acceptedFiles) {
    if (acceptedFiles) {
        this.setState({
            is_img_uploading: true // 로딩중 이미지 띄움.
        });

      /* Get files in array form */
      const files = Array.from(acceptedFiles);

      if (files.length > 1) {
        alert('1개의 파일만 첨부해 주세요.');
        this.setState({
            is_img_uploading: false // 로딩중 이미지 없앰.
        });
        return false;
      } else {
        if (
          files[0].name.match(
            '.jpg$|.png$|.jpeg$|.gif$|.bmp$|.webp$|.JPG$|.PNG$|.JPEG$|.GIF$|.BMP$|.WEBP$',
          )
        ) {
          console.log(files[0].name);
        } else {
          alert('이미지 파일만 첨부 해 주세요.');
          this.setState({
            is_img_uploading: false // 로딩중 이미지 없앰.
           });
          return false;
        }
      }

      //const uploadFiles = Array.from(e.target.files);
      //const { getRootProps, getInputProps, isDragActive } = useDropzone({ files });
      /* Map each file to a promise that resolves to an array of image URI's */
      Promise.all(
        files.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener('load', (ev) => {
              resolve(ev.target.result);
            });
            reader.addEventListener('error', reject);
            reader.readAsDataURL(file);
          });
        }),
      ).then(
        (images) => {
          /* Once all promises are resolved, update state with image URI array */
          console.log(images);
          console.log(files);
          console.log(this);
          this.setState({
            uploadFiles: files,
            imageArray: images,
          });
          //const {uploadFiles, imageArray} = useState(images);
          var fileNames = '';
          files.map((file) => {
            fileNames += file.name + ' ';
          });
          this.getImageAnalysisResult(files, fileNames);
        },
        (error) => {
          console.error(error);
        },
      );
    }
  }

  /* 이미지 업로드 후 컬러 추출 */
  getImageAnalysisResult(files, fileNames) {
    const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const url = '/rest/demo/ad-trial/imageUpload';
      const formData = new FormData();
      const uploadFiles = this.state.uploadFiles;
    //   uploadFiles.forEach((file) => formData.append('file', file)); // 다중파일
    formData.append('file', files[0]); // 단일 파일
    formData.append('email', this.state.email);
      
      http
        .post(url, formData, config)
        .then((res) => {
            if (res.data.code != "200") {
                alert(res.data.code + "\n" + res.data.msg);
                this.setState({
                    is_img_uploading: false // 로딩중 이미지 없앰.
                });
            } else {
                var obj = res.data.img_analysis_result;
                this.setState({
                    img_url: obj.img_url,
                    img_dominent_color_code: obj.dominent_color_code,
                    img_color_code_1: obj.color_code_1,
                    img_color_code_2: obj.color_code_2,
                    img_color_code_3: obj.color_code_3,
                    img_color_code_4: obj.color_code_4,
                    is_img_uploading: false, // 로딩중 이미지 없앰.
                    is_img_uploaded: true,
                    is_btn_next_show: true,
                }, () => {
                    this.setGaEvent("이미지 업로드 - " + this.state.img_url + " (" + this.state.img_dominent_color_code +" / " + this.state.img_color_code_1 +" / "+  this.state.img_color_code_2 +" / "+  this.state.img_color_code_3 +" / "+  this.state.img_color_code_4 +")");
                    // alert("이미지 업로드 - " + this.state.img_url + " (" + this.state.img_dominent_color_code +" / " + this.state.img_color_code_1 +" / "+  this.state.img_color_code_2 +" / "+  this.state.img_color_code_3 +" / "+  this.state.img_color_code_4 +")");
                })
            }
        })
        .catch((error) => {
          alert(error + '\n광고 서비스 사용자 이미지 업로드 실패.\n관리자에게 문의해주세요(1599-3518)');
          this.setState({
            is_img_uploading: false // 로딩중 이미지 없앰.
          });
        });
  }

  /* 배경색 고르기 열고 닫기 */
  onClickToggleBackgroundColor = (e, state) => {
    if (state == "unlock") {
        this.setGaEvent("배경색 고르기 (비활성화)");
        // alert("배경색 고르기 (비활성화) : "+this.state.email);
    } else {
        if (state) {
            this.setGaEvent("배경색 고르기 (열기)");
            // alert("배경색 고르기 (열기) : "+this.state.email);
        } else {
            this.setGaEvent("배경색 고르기 (닫기)");
            // alert("배경색 고르기 (닫기) : "+this.state.email);
        }

        if (this.state.color_code_list == null 
            || (this.state.color_code_list != null && this.state.color_code_list.length == 0)) {
            http.get("/rest/demo/ad-trial/color")
            .then(res => {
                if (res.data.code != "200") {
                    alert(res.data.code + "\n" + res.data.msg);
                } else {
                    this.setState({
                        color_code_list: res.data.color_code_list,
                        is_background_color_seletor_open: state
                    })
                }
            })
            .catch(err => { 
                alert("광고 색상 테이블 조회 실패.\n관리자에게 문의해주세요(1599-3518)"); 
            });

        } else {
            this.setState({
                is_background_color_seletor_open: state
            })
        }
    }
}

  onClickColorIdx = (e, idx) => {
    this.setState({
        color_idx: idx,
        bg_color_code: this.state.color_code_list.length > 0 ? this.state.color_code_list[idx].bg_color_code : "",
        text_color_code: this.state.color_code_list.length > 0 ? this.state.color_code_list[idx].text_color_code : ""
    }, () => {
        this.setGaEvent("배경색 각각 클릭 - "+this.state.color_code);
        // alert("배경색 각각 클릭 - "+this.state.color_code+" : "+this.state.email);
    })
  }

  onClickIsTextInsert = (e, state) => {
    this.setState({
        is_text_insert: state,
        is_text_not_insert: !state
    }, () => {
        if (state) {
            this.setGaEvent("[네, 넣을게요.] 클릭");
            // alert("[네, 넣을게요.] 클릭 : "+this.state.email);
            this.loadAdTextList();
        } else {
            this.setGaEvent("[아니요, 안 넣을 거예요.] 클릭");
            // alert("[아니요, 안 넣을 거예요.] 클릭 : "+this.state.email);
            this.setState({
                is_btn_next_show: true
            }) 
        }
    })
  }

  onClickDirectInput = (e, state) => {
    this.setState({
        is_text_direct_input: state,
        is_btn_next_show: true
    })

    this.setGaEvent("[직접 작성하기] 클릭");
    // alert("[직접 작성하기] 클릭 : "+this.state.email);
  }

  onClickIsComplainTextAreaShow = (e, state) => {
    this.setState({
        is_complain_text_area_show: state
    })

    this.setGaEvent("[수정 요청] 클릭");
    // alert("[수정 요청] 클릭 : "+this.state.email);
  }

  onChangeComplainText = e => {
      this.setState({
        complain_text_value : e.target.value
      })
  }

  onClickComplainTextSend = e => {
    // alert(this.state.complain_text_value);

    // alert("[요청 하기] 클릭 : "+this.state.email);
    
    this.updateAdReqDesc();
    this.setGaEvent("[요청 하기] 클릭");
  }

  onClickAnotherText = (e, index) => {
    // this.setState({
    //     event_seq : this.state.event_seq_list[index].event_seq,
    //     event_platform_type : this.state.event_seq_list[index].platform_type,
    //     event_seq_idx : (Number(this.state.event_seq_list.length)-1) <= this.state.event_seq_idx ? 0 : this.state.event_seq_idx + 1,
    //   })

    // this.setGaEvent("[다른 문구 보기] 클릭 (큰제목/작은제목)");
    if (this.state.ad_text_list.length > 0) {
        var random_number = Menu.randomNum(0, (this.state.ad_text_list.length - 1), this.state.ad_idx);
        this.setState({
            ad_text: this.state.ad_text_list[random_number].ad_text,
            ad_idx: random_number,
            is_text_direct_input: false,
        }, () => {
            this.setGaEvent("[다른 문구 보기] 클릭 ("+this.state.ad_text+")");
            // alert("[다른 문구 보기] 클릭 ("+this.state.ad_text+") : "+this.state.email);
        })
    }
  }

  onChangeTitle = (e) => {
    this.setState({
        title : e.target.value
    })
  }

  textFocousOut = (e, index) => {
    this.setGaEvent("직접작성 포커스아웃 ("+this.state.title+")");
    // alert("직접작성 포커스아웃 ("+this.state.title+") : "+this.state.email);
  }

  onClickAnotherEvent = (e, index) => {
    this.setState({
        event_seq_idx : (Number(this.state.event_seq_list.length)-1) <= this.state.event_seq_idx ? 0 : this.state.event_seq_idx + 1,
      }, () => {
          this.setState({
              event_seq : this.state.event_seq_list[this.state.event_seq_idx].event_seq,
              event_platform_type : this.state.event_seq_list[this.state.event_seq_idx].platform_type,
              event_type_name : this.state.event_seq_list[this.state.event_seq_idx].event_type_name,
              complain_text_value : "",
            }, () => {
                this.setGaEvent("[다른 광고 보기] 클릭 - ("+this.state.event_seq_list[this.state.event_seq_idx].event_type_name+")");
                //   alert("[다른 광고 보기] 클릭 - ("+this.state.event_seq_list[this.state.event_seq_idx].event_type_name+") : "+this.state.email);
          })
      })
  }

  onClickDownLoadEvent = (e, index) => {
    console.log(this.state.event_seq_list)
    this.setGaEvent("[다운로드 하기] 클릭 - ("+this.state.event_seq_list[index].event_type_name+")");
    // alert("[다운로드 하기] 클릭 - ("+this.state.event_seq_list[index].event_type_name+") : "+this.state.email);

    const config = {
        headers: {
            'content-type': 'application/json;'
        },
    }
    
    http.post("/rest/demo/ad-trial/download",
        {
            "email" : this.state.email,
            "event_seq": this.state.event_seq_list[index].event_seq,
            "platform_type": this.state.event_seq_list[index].platform_type
        },
        config
    )
    .then(res => {
            if (res.data.code == "200") {
                const fileName = res.data.fileName;
                const fileUrl = res.data.fileUrl;
                let a = document.createElement('a');

                a.href = fileUrl;
                a.download = fileName;
                a.click();
            } else {
                alert(res.data.code + "\n" + res.data.msg);
            }
    })
    .catch(error => {
        console.log (error);
    });
  }
  
  onClickClose = e => {
    this.setGaEvent("창 내 X 버튼 클릭");
    // alert("창 내 X 버튼 클릭 : "+this.state.email);
    this.setState({
        is_close_click: true,
    }, () => {
        this.onClickGoFirstStep(e);
    })
  }

  loadAdTextList () {
        if (this.state.ad_text_list == null || (this.state.ad_text_list != null && this.state.ad_text_list.length == 0)) {
            http.get("/rest/demo/ad-trial/text")
            .then(res => {
                if (res.data.code != "200") {
                    alert(res.data.code + "\n" + res.data.msg);
                } else {
                    var random_number = Menu.randomNum(0, (this.state.ad_text_list.length - 1), -999);
                    this.setState({
                        ad_text_list: res.data.ad_text_list,
                        ad_text: res.data.ad_text_list[random_number].ad_text,
                        ad_idx: random_number,
                        is_btn_next_show: true,
                    })
                }
            })
            .catch(err => { 
                alert("광고 텍스트 조회 실패.\n관리자에게 문의해주세요(1599-3518)"); 
            });
        }
  }

  createAutoEvent () {
    const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const url = '/rest/demo/ad-trial/save';
      const body = {
        email : this.state.email,
        img_url : this.state.img_url,
        bg_color_code : this.state.bg_color_code,
        text_color_code : this.state.text_color_code,
        ad_text : this.state.ad_text,
        dominent_color_code : this.state.img_dominent_color_code,
        color_code_1 : this.state.img_color_code_1,
        color_code_2 : this.state.img_color_code_2,
        color_code_3 : this.state.img_color_code_3,
        color_code_4 : this.state.img_color_code_4,
      }

    //   alert(JSON.stringify(body));

    http
    .post(url, body, config)
    .then((res) => {
        if (res.data.code != "200") {
            alert(res.data.code + "\n" + res.data.msg);
            this.onClickGoFirstStep();
        } else {
            this.setState({
                event_seq_list : res.data.event_seq_list,
                event_seq: res.data.event_seq_list != null && res.data.event_seq_list.length > 0 ? res.data.event_seq_list[0].event_seq : "",
                event_platform_type: res.data.event_seq_list != null && res.data.event_seq_list.length > 0 ? res.data.event_seq_list[0].platform_type : "",
                event_type_name: res.data.event_seq_list != null && res.data.event_seq_list.length > 0 ? res.data.event_seq_list[0].event_type_name : "",
                event_seq_idx: 0,
            }, () => {
                this.onClickNextStep();
            })
        }
    })
    .catch((error) => {
      alert(error + '\n광고 서비스 사용자 배너 생성 실패.\n관리자에게 문의해주세요(1599-3518)');
      this.onClickGoFirstStep();
    });
  }

  updateAdReqDesc () {
    const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const url = '/rest/demo/ad-trial/updateAdReqDesc';
      const body = {
        email : this.state.email,
        event_seq : this.state.event_seq,
        req_desc : this.state.complain_text_value,
      }

    //   alert(JSON.stringify(body));

    http
    .post(url, body, config)
    .then((res) => {
        if (res.data.code != "200") {
            alert(res.data.code + "\n" + res.data.msg);
        } else {
            alert("요청이 접수됐습니다. 이메일로 연락 드릴게요.");
        }
    })
    .catch((error) => {
      alert(error + '\n광고 서비스 요청사항 저장 실패.\n관리자에게 문의해주세요(1599-3518)');
      this.onClickGoFirstStep();
    });
  }

    render() {
        return (
            <div>
                {/* 데모 광고 서비스 */}
                <div className="demoAdServiceBackgroudArea">
                    <div className="circle_purple_area">
                        <div className="circle_purple_inner_area">
                            <div className="circle_purple_1"/>
                            <div className="circle_purple_2"/>
                            <div className="circle_purple_3"/>
                        </div>
                    </div>
                    <div className="circle_purple_area_2">
                        <div className="circle_purple_inner_area">
                            <div className="circle_purple_2_1"/>
                            <div className="circle_purple_2_2"/>
                            <div className="circle_purple_2_3"/>
                        </div>
                    </div>
                    <div className="demo_ad_backgroud_gredient"/>
                    <div className="demo_ad_backgroud_gredient2"/>
                </div>
                <div className="demoAdServiceContentArea" >
                    {/* <div style={{position:"fixed"}} onClick={e=>this.onClickNextStep()}>
                        다음
                    </div> */}
                    <div className="demoAdLogo">
                        <a href="/"><img src={logo_v2_white} style={{width:"100%"}}/></a>
                    </div>
                    <div className="demoAdStep" style={{display:"flex", justifyContent:"space-between"}}>
                        <div className="demoAdStep">
                        {
                            Number(this.state.step) != 0 && Number(this.state.step) != 2.5
                            ?   <>
                                    {
                                        Number(this.state.step) >= 1
                                        ? <div className="white_circle" style={{width:"20px", height:"20px"}}/>
                                        : <div className="white_circle_alpha" style={{width:"20px", height:"20px"}}/>
                                    }
                                    {
                                            Number(this.state.step) >= 2
                                            ? <div className="white_circle" style={{width:"20px", height:"20px", marginLeft:"5px"}}/>
                                            : <div className="white_circle_alpha" style={{width:"20px", height:"20px", marginLeft:"5px"}}/>
                                    }
                                    {
                                            Number(this.state.step) == 3
                                            ? <div className="white_circle" style={{width:"20px", height:"20px", marginLeft:"5px"}}/>
                                            : <div className="white_circle_alpha" style={{width:"20px", height:"20px", marginLeft:"5px"}}/>
                                    }
                                    <div style={{color:"white", fontSize:"20px", lineHeight:"20px", marginLeft:"15px"}}>
                                        {this.state.step + "/3단계"}
                                    </div>
                                </>
                            : ""
                        }    
                        </div>
                        {
                            Number(this.state.step) > 0 && Number(this.state.step) != 2.5
                            ?   <>
                                    <div className="demoAdFirstStepBtn cursor-pointer" 
                                        onClick={e=>this.onClickGoFirstStep(e)}>
                                        처음부터 다시 하기
                                    </div>
                                 </>
                            :   ""
                        }   
                    </div>
                    <div className="demoAdServiceContentInnerArea" >
                        <div style={{display:'flex', justifyContent:"center", height:"100%"}}>
                        {
                            Number(this.state.step) == 0
                            ?   <>
                                    <div style={{alignSelf:"center", alignContent: "center", textAlign:"center", color:"white", maxWidth:"86%"}}>
                                        <div className="demoAdConentText_1">페이스북 / 인스타그램 광고를<br/>AI가 만들어 드립니다.</div>
                                        <br/>
                                        <div className="demoAdConentText_2">회원가입 없이 체험해 보시겠어요?</div>
                                        <div className="mobile-width-100" style={{width:"80%"
                                            , position: "relative"
                                            , borderRadius:"32px"
                                            , margin: "50px auto 0 auto"
                                            , overflow: "hidden"}}>
                                            <div style={{background:"white", flex:"1"}}>
                                                <input id="email" ref={this.emailRef} className="input_no_border" type="email" placeholder="이메일을 입력해 주세요." style={{width:"100%", padding:"20px 180px 20px 30px"}} onKeyDown={e=>this.onKeyDownEmailInput(e)}/>
                                            </div>
                                            <div className="cursor-pointer" style={{position:"absolute", top:"0", right:"0", background:"#10CFC9", clolr:"white", width:"150px", height:"100%", display:"flex", justifyContent:"center", borderRadius:"32px"}}>
                                                <div style={{alignSelf:"center"}} onClick={e=>this.onClicSubmitEmail()}>
                                                    체험해보기
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mobile-width-100"  style={{width:"80%", fontSize:"12px", display:"flex", padding:"0 25px" , margin: "10px auto 0 auto"}}>
                                        {
                                            this.state.is_email_validate
                                            ?   <div style={{ height:"20px" }}></div>    
                                            :   
                                                <>
                                                    <div style={{
                                                        width:"20px"
                                                        , height:"20px"
                                                        , borderRadius:"100%"
                                                        , border:"white solid 1px"
                                                    }}>
                                                    <b>!</b>        
                                                    </div>
                                                    <div style={{
                                                        alignSelf:"center"
                                                        , margin:"0 5px"
                                                    }}>
                                                        이메일 형식에 맞게 입력해 주세요.
                                                    </div>
                                                </>
                                        }
                                        </div>
                                        <div style={{width:"100%", fontSize:"12px", padding:"0 25px" , margin: "10px auto 0 auto", color:"lightgrey"}}>
                                        체험을 하시면 로보MD 
                                        <a href="/common/FormViewer/9002" target="_blank" 
                                            style={{color:"lightgrey", textDecoration:"underline"}}> 개인정보처리방침 </a>
                                        및 마케팅정보수신에 동의하신 것으로 간주합니다.
                                        </div>
                                        
                                    </div>
                                </>    
                            :   ""
                        }
                        {
                            Number(this.state.step) == 1
                            ?   <>
                                    <div style={{alignSelf:"center", color:"white", maxWidth:"86%"}}>
                                        <div style={{textAlign:"center"}}>
                                            <div className="demoAdConentText_2" style={{textAlign:"center", marginBottom:"25px"}}>
                                                사진을 업로드 해주세요.
                                            </div>
                                            <div>
                                                {
                                                    this.state.is_img_uploading
                                                    ?   <div
                                                            className="demoAdMyDropzone"
                                                            style={{
                                                            width:"450px",
                                                            height: '280px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            fontSize: '15px',
                                                            cursor: 'pointer',
                                                            backgroundColor: 'white',
                                                            maxWidth: '100%',
                                                            maxHeight: '50vh',
                                                            color:"grey",
                                                            borderRadius:"13px"
                                                            }}
                                                        >
                                                            <div>
                                                                <img id="loading_logo" src={favicon_v2} style={{width:"80px"}}/>
                                                                <br/>
                                                                <br/>
                                                                AI가 사진을 분석 중입니다.
                                                            </div>
                                                        </div>
                                                    :   
                                                        this.state.is_img_uploaded
                                                        ?
                                                            <div
                                                            className="demoAdMyDropzone"
                                                            style={{
                                                            width:"450px",
                                                            height: '280px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            fontSize: '15px',
                                                            cursor: 'pointer',
                                                            backgroundColor: 'white',
                                                            maxWidth: '100%',
                                                            maxHeight: '50vh',
                                                            color:"grey",
                                                            borderRadius:"13px"
                                                            }}
                                                        >
                                                            <div>
                                                                <img src={favicon_v2} style={{width:"80px"}}/>
                                                                <br/>
                                                                <br/>
                                                                AI가 사진 분석을 완료했습니다.
                                                            </div>
                                                        </div>
                                                        :
                                                            <MyDropzone
                                                            attachImage={this.attachImage}
                                                            this={this}
                                                            />
                                                }
                                            </div>
                                        </div>
                                        {
                                                    this.state.is_img_uploading || this.state.is_img_uploaded
                                                    ?   
                                                        <div>
                                                            <div className="demoAdConentText_3" style={{textAlign:"left", marginTop:"40px", color:"lightgray"}}>
                                                                사진이 없으신가요?
                                                                <br/>
                                                                그럼 배경 색상을 골라 주세요. 
                                                            </div>
                                                            <div className="demoAdBorderMintBox cursor-pointer" 
                                                                style={{
                                                                    width:"auto"
                                                                    , marginTop:"15px"
                                                                    , padding: "10px 15px 10px 15px"
                                                                    , border:"lightgray solid 1px"
                                                                    , cursor:"not-allowed"
                                                                }}>
                                                                <div style={{display:"flex", flexWrap:"nowrap", color:"lightgray"}} onClick={e=>this.onClickToggleBackgroundColor(e, "unlock")}>
                                                                    배경색 고르기 
                                                                    <div className="arrow-down-10-lightgray" style={{alignSelf:"center", marginLeft:"5px"}}></div>
                                                                </div>
                                                            </div>
                                                            <br/>
                                                            <div style={{
                                                                display:"inline-flex"
                                                                , background:"white"
                                                                , maxWidth:"100%"
                                                                , width:"auto"
                                                                , padding:"10px 0px 10px 10px"
                                                                , marginTop:"13px"
                                                                , borderRadius:"3px"
                                                                , visibility:"hidden"
                                                                }}>
                                                                    <div className="cursor-pointer" style={{
                                                                            display:"flex"
                                                                            , justifyContent: "center"
                                                                            , alignSelf:"center"
                                                                            , color:"white"
                                                                            , background:"lightgray"
                                                                            , borderRadius:"3px"
                                                                            , marginRight: "10px"
                                                                            , fontSize:"16px"
                                                                            , width: "30px"
                                                                            , height: "30px"
                                                                            }}>
                                                                            <div style={{alignSelf:"center"}}>
                                                                            A
                                                                            </div>
                                                                        </div>
                                                            </div>
                                                        </div>
                                                    :   
                                                        <div>
                                                            <div className="demoAdConentText_3" style={{textAlign:"left", marginTop:"40px"}}>
                                                                사진이 없으신가요?
                                                                <br/>
                                                                그럼 배경 색상을 골라 주세요. 
                                                            </div>
                                                            <div className="demoAdBorderMintBox cursor-pointer" 
                                                                style={{
                                                                    width:"auto"
                                                                    , marginTop:"15px"
                                                                    , padding: "10px 15px 10px 15px"
                                                                }}>
                                                                {
                                                                    this.state.is_background_color_seletor_open
                                                                    ?   <div style={{display:"flex", flexWrap:"nowrap"}} onClick={e=>this.onClickToggleBackgroundColor(e, false)}>
                                                                            배경색 고르기 
                                                                            <div className="arrow-up-10" style={{alignSelf:"center", marginLeft:"5px"}}></div>
                                                                        </div>
                                                                    :   <div style={{display:"flex", flexWrap:"nowrap"}} onClick={e=>this.onClickToggleBackgroundColor(e, true)}>
                                                                            배경색 고르기 
                                                                            <div className="arrow-down-10" style={{alignSelf:"center", marginLeft:"5px"}}></div>
                                                                        </div>
                                                                }
                                                            </div>
                                                            <br/>
                                                            {
                                                                this.state.is_background_color_seletor_open
                                                                ?
                                                                    <div style={{
                                                                    display:"inline-flex"
                                                                    , background:"white"
                                                                    , maxWidth:"100%"
                                                                    , width:"auto"
                                                                    , padding:"10px 0px 10px 10px"
                                                                    , marginTop:"13px"
                                                                    , borderRadius:"3px"
                                                                    }}>

                                                                    {this.state.color_code_list.map((obj, idx) => (
                                                                        <>
                                                                        {
                                                                            this.state.color_idx == idx
                                                                            ?   <div className="cursor-pointer" style={{
                                                                                    display:"flex"
                                                                                    , justifyContent: "center"
                                                                                    , alignSelf:"center"
                                                                                    , color:"white"
                                                                                    , background:"#000000"
                                                                                    , borderRadius:"3px"
                                                                                    , marginRight: "10px"
                                                                                    , fontSize:"16px"
                                                                                    , width: "32px"
                                                                                    , height: "32px"
                                                                                    , border:"#10CFC9 solid 3px"
                                                                                    }}
                                                                                    onClick={e=>this.onClickColorIdx(e, idx)}>
                                                                                    <div style={{alignSelf:"center"}}>
                                                                                    A
                                                                                    </div>
                                                                                </div>
                                                                            :   <div className="cursor-pointer" style={{
                                                                                    display:"flex"
                                                                                    , justifyContent: "center"
                                                                                    , alignSelf:"center"
                                                                                    , color: obj.text_color_code
                                                                                    , background: obj.bg_color_code
                                                                                    , borderRadius:"3px"
                                                                                    , marginRight: "10px"
                                                                                    , fontSize:"16px"
                                                                                    , width: "30px"
                                                                                    , height: "30px"
                                                                                    }}
                                                                                    onClick={e=>this.onClickColorIdx(e, idx)}>
                                                                                    <div style={{alignSelf:"center"}}>
                                                                                    A
                                                                                    </div>
                                                                                </div>
                                                                        }
                                                                        </>
                                                                    ))}
                                                                    </div>
                                                                :   <div style={{
                                                                    display:"inline-flex"
                                                                    // , background:"white"
                                                                    , maxWidth:"100%"
                                                                    , width:"auto"
                                                                    , marginTop:"13px"
                                                                    , borderRadius:"3px"
                                                                    , height: "46px"
                                                                    }}>
                                                                    </div>
                                                            }
                                                        </div>
                                        }
                                        
                                        <div style={{display:"flex", justifyContent:"flex-end", marginTop:"20px"}}>
                                            {
                                                this.state.is_btn_next_show
                                                ?   <div className="btn-auto-b cursor-pointer" style={{display:"flex", justifyContent:"center", width:"105px", height:"55px", opacity:"1"}}
                                                            onClick={e=>this.onClickNextStep()}>
                                                        <div style={{alignSelf:"center", fontSize:"18px"}}>
                                                        다음
                                                        </div>
                                                    </div>  
                                                :   <div style={{display:"flex", justifyContent:"center", width:"105px", height:"55px"}}>
                                                    </div>
                                            }
                                        </div>  
                                    </div>
                                </>
                            :   ""    
                        }
                        {
                            Number(this.state.step) == 2
                            ?   <div style={{alignSelf:"center", color:"white", maxWidth:"86%"}}>
                                    {/* <div>
                                    앞으로 광고 서비스가 런칭 될 때에는<br/>
                                    글감도 활용하실 수 있도록<br/>
                                    제공해드릴 계획이에요!
                                    </div>     */}
                                    <div className="demoAdConentText_2" style={{textAlign:"left", marginTop:"50px", marginBottom:"25px"}}>
                                        문구는 넣으시겠어요?
                                    </div>
                                    <div style={{display:"flex", flexWrap:"nowrap"}}>
                                        {
                                            this.state.is_text_insert
                                            ?   <div className="btn-auto-b demoAdText18px" 
                                                        style={{padding:"10px 15px", height: "auto", opacity:"1"}}
                                                        onClick={e=>this.onClickIsTextInsert(e, true)}
                                                        >
                                                네, 넣을게요.
                                                </div> 
                                            :   <div className="btn-auto-b-revert demoAdText18px" 
                                                        style={{padding:"10px 15px", opacity:"1"}}
                                                        onClick={e=>this.onClickIsTextInsert(e, true)}
                                                        >
                                                네, 넣을게요.
                                                </div> 
                                        }
                                        {
                                            this.state.is_text_not_insert
                                            ?   <div className="btn-auto-b demoAdText18px" 
                                                        style={{marginLeft:"15px", padding:"10px 15px", height: "auto", opacity:"1"}}
                                                        onClick={e=>this.onClickIsTextInsert(e, false)}
                                                        >
                                                아니요, 안 넣을 거예요.
                                                </div> 
                                            :   <div className="btn-auto-b-revert demoAdText18px" 
                                                        style={{marginLeft:"15px", padding:"10px 15px", opacity:"1"}}
                                                        onClick={e=>this.onClickIsTextInsert(e, false)}
                                                        >
                                                아니요, 안 넣을 거예요.
                                                </div>  
                                        }
                                    </div>        
                                    {
                                        this.state.is_text_insert
                                        ?   
                                            <>
                                                
                                                {  
                                                    this.state.is_text_direct_input 
                                                        ?   <div className="demoAdText18px" style={{background:"white",color:"#333333", width:"470px", height: "45px", maxWidth:"100%" , marginTop:"50px", marginBottom:"25px", borderRadius:"8px"}}>
                                                            <input type="text" className="input_no_border" 
                                                                    style={{width:"100%", height:"100%", padding:"10px 15px"}}
                                                                    placeholder="메인 텍스트 / 서브 텍스트입니다. / 없이 입력하시면 메인 텍스트로만 쓸게요!"
                                                                    value={this.state.title}
                                                                    onChange={e=>this.onChangeTitle(e)}
                                                                    onBlur={e=>this.textFocousOut(e)}
                                                                    />
                                                            </div>
                                                        :   <div className="demoAdText18px" style={{background:"white",color:"#333333",padding:"10px 15px", width:"470px", height: "45px", maxWidth:"100%",whiteSpace: "nowrap", overflowX:"auto", overflowY:"hidden" , marginTop:"50px", marginBottom:"25px", borderRadius:"8px"}}>
                                                            {this.state.ad_text}
                                                            </div>
                                                }
                                                <div style={{display:"flex", flexWrap:"nowrap", marginTop:"15px"}}>
                                                    <div className="btn-auto-b-revert-click demoAdText18px" 
                                                        style={{padding:"10px 15px", opacity:"1"}}
                                                        onClick={e=>this.onClickAnotherText(e, false)}>
                                                    다른 문구 보기
                                                    </div> 
                                                    {
                                                        this.state.is_text_direct_input 
                                                        ?   <div className="btn-auto-b demoAdText18px cursor-pointer" 
                                                                style={{marginLeft:"15px", padding:"10px 15px", height: "auto", opacity:"1"}}
                                                                onClick={e=>this.onClickDirectInput(e, true)}>
                                                            직접 작성하기
                                                            </div> 
                                                        :   <div className="btn-auto-b-revert demoAdText18px cursor-pointer" 
                                                                style={{marginLeft:"15px", padding:"10px 15px", opacity:"1"}}
                                                                onClick={e=>this.onClickDirectInput(e, true)}>
                                                            직접 작성하기
                                                            </div> 
                                                    }
                                                </div> 
                                                <div style={{height:"50px", marginTop:"22px", fontSize:"15px"}}>
                                                    {
                                                        this.state.is_text_direct_input
                                                        ?   "더 좋은 문구가 떠오르셨나요? 위 칸에 입력해 주세요. "
                                                        :   ""    
                                                    }
                                                </div>
                                            </>
                                        :   <>
                                                <div className="demoAdText18px" style={{background:"#ffffff00",color:"#ffffff00",padding:"10px 15px", width:"470px", height: "45px", maxWidth:"100%",whiteSpace: "nowrap", overflowX:"auto", overflowY:"hidden" , marginTop:"50px", marginBottom:"25px", borderRadius:"8px"}}>
                                                    &nbsp;
                                                </div>
                                                <div style={{display:"flex", flexWrap:"nowrap", marginTop:"27px"}}>
                                                    <div className="demoAdText18px" 
                                                        style={{padding:"10px 15px", opacity:"1", border:"1px #ffffff00"}}>
                                                        &nbsp;
                                                    </div>  
                                                    <div className="demoAdText18px" style={{marginLeft:"15px", padding:"10px 15px", height: "auto", opacity:"1", border:"1px #ffffff00"}}>
                                                        &nbsp;
                                                    </div> 
                                                </div> 
                                                <div style={{height:"50px", marginTop:"22px", fontSize:"15px"}}>
                                                </div>
                                            </>
                                    }
                                    <div style={{display:"flex", justifyContent:"flex-end", marginTop:"20px"}}>
                                        {
                                            this.state.is_btn_next_show
                                            ?   <div className="btn-auto-b cursor-pointer" style={{display:"flex", justifyContent:"center", width:"105px", height:"55px", opacity:"1"}}
                                                        onClick={e=>this.onClickNextStep()}>
                                                    <div style={{alignSelf:"center", fontSize:"18px"}}>
                                                    다음
                                                    </div>
                                                </div>  
                                            :   <div style={{display:"flex", justifyContent:"center", width:"105px", height:"55px"}}>
                                                </div>
                                        }
                                    </div>                                     
                                </div>
                            :   ""
                        }
                        {
                            Number(this.state.step) == 2.5
                            ?   <div className="mobile_padding_top_16vh" style={{alignSelf:"center", color:"white", maxWidth:"86%"}}>
                                    <div style={{textAlign:"center"}}>
                                        <img className="loading_bounce2" src={logo_mobile_white} style={{width:"110px"}}/>
                                    </div>
                                    <div className="demoAdConentText_3" style={{textAlign:"center", marginTop:"58px"}}>
                                    잠시 후 AI가 만든 광고를<br/>
                                    보실 수 있어요.<br/>
                                    <br/>
                                    조금만 기다려 주세요!<br/>
                                    </div>    
                                </div>
                            :   ""
                        }
                        {
                            Number(this.state.step) == 3
                            ?   <div style={{alignSelf:"center"}}>
                                    <div className="demoAdPopup">
                                        <div className="demoAdCloseBtnArea">
                                            <div className="modal_v3_btn_close cursor-pointer" 
                                                    style={{marginBottom:"20px"}}
                                                    onClick={e=>this.onClickClose(e)}
                                            />
                                        </div>   
                                        <div className="demoAdPopupFlexArea">
                                            <div className="demoAdPopupBannerArea">
                                                <object type="text/html" data={"/banner_sample.html?event_seq="+this.state.event_seq+"&platform_type="+this.state.event_platform_type} type="text/html" style={{width:"100%",height:"100%"}}></object>
                                            </div>
                                            <div className="pc-left-28" style={{alignSelf:"flex-end"}}>
                                                <div style={{color:"#333333", marginTop:"20px"}}>
                                                    <div className="demoAdConentText_3">광고가 완성됐습니다!</div>
                                                    <div style={{fontSize:"15px", marginTop:"15px"}}>
                                                        AI가 만든 광고가 마음에 드신다면<br/>        
                                                        다운로드 해서 활용해 보세요.
                                                    </div>    
                                                </div>    
                                                <div style={{display:"flex", flexWrap:"nowrap",marginTop:"20px"}}>
                                                    <div className="btn-auto-b-revert demoAdText18px" 
                                                            style={{padding:"7px 10px", height: "auto", opacity:"1"}}
                                                            onClick={e=>this.onClickAnotherEvent(e, this.state.event_seq_idx)}
                                                            >
                                                        다른 광고 보기
                                                    </div>
                                                    <div className="btn-auto-b-revert demoAdText18px" 
                                                            style={{marginLeft:"15px", padding:"7px 10px", height: "auto", opacity:"1"}}
                                                            onClick={e=>this.onClickDownLoadEvent(e, this.state.event_seq_idx)}
                                                            >
                                                        다운로드 하기
                                                    </div>
                                                </div>
                                                {
                                                    this.state.is_complain_text_area_show
                                                    ?   <>
                                                        <div style={{marginTop:"30px", fontSize:"14px", color:"grey"}}>
                                                            <textarea className="demoComplainTextArea" placeholder="수정 요청 사항을 작성해 주세요."
                                                                    value={this.state.complain_text_value}
                                                                    onChange={e=>this.onChangeComplainText(e)}>
                                                                        {this.state.complain_text_value}
                                                            </textarea>
                                                        </div>
                                                        <div style={{display:"flex", flexWrap:"nowrap",marginTop:"20px"}}>
                                                            <div className="btn-auto-b demoAdText18px" 
                                                                    style={{padding:"7px 10px", height: "auto", opacity:"1", fontSize:"15px"}}
                                                                    onClick={e=>this.onClickComplainTextSend(e, false)}
                                                                    >
                                                                요청하기
                                                            </div>    
                                                        </div> 
                                                        </>
                                                    :   <>
                                                        <div style={{marginTop:"30px", fontSize:"14px", color:"grey"}}>
                                                            혹시 광고가 마음에 안 드시나요?<br/>
                                                            수정 요청을 주시면 디자이너가 직접 작업해 연락 드릴게요.
                                                        </div>
                                                        <div style={{display:"flex", flexWrap:"nowrap",marginTop:"20px"}}>
                                                            <div className="btn-auto-b demoAdText18px" 
                                                                    style={{padding:"7px 10px", height: "auto", opacity:"1", fontSize:"15px"}}
                                                                    onClick={e=>this.onClickIsComplainTextAreaShow(e, true)}
                                                                    >
                                                                수정 요청
                                                            </div>    
                                                        </div> 
                                                        </>
                                                }
                                                   
                                            </div>    
                                        </div>
                                    </div>
                                </div>
                            :   ""
                        }
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

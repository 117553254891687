import { nodeName } from 'jquery';
import http from '../modules/api';
import axios from 'axios';
import React from 'react';
// import ReactPixel from 'react-facebook-pixel';
import {ga4react, reactPixel} from '../Menu'
import '../../css/style.css';
import $ from 'jquery';
import * as Menu from '../Menu_v2';
import CommonPopup from '../modules/CommonPopup';
import { setCookie } from "../modules/utils/CookieUtil";

import icn_refresh from '../../resources/img/icn_refresh.svg';
import icn_refresh_spin from '../../resources/img/icn_refresh_spin.svg';

// ReactPixel.init('438227415050093');
reactPixel.pageView();

var is_stroe_loaded = false;
var user_id = sessionStorage.getItem("user_id");

let is_test_use = "";
if (document.domain.indexOf("127.0.0.1") >= 0 || document.domain.indexOf("otdeal.me") >= 0) {
    is_test_use = "&is_test_use=Y"
}

/**
 * 사용자 서비스 - 상점추가 페이지
 */
export class SolutionStoreAdd extends React.Component {
  state = {
    step: 0,
    solution_type_cd: '',
    solution_name: '',
    agree_yn: '' /* 디폴트 값  변경 'N' -> '' */,
    mall_id: '',
    makeshop: {
      /*메이크샵 연동을 위한 필수 정보 입력 (국적, 화폐단위, 샵 이름을 받아야 할까..)*/
      shop_key: '',
      domain: '',
      shop_name: '',
      language_code: 'ko_KR' /*언어 코드 : (기본)국문*/,
    },
    imweb: {
      api_key: '',
      secret_key: '',
      domain: '',
      shop_name: '',
      language_code: '',
    },
    godomall: {
      id: '',
      domain: '',
      shop_name: '',
      language_code: '',
    },
    shops: [] /*cafe24 api에서 가져온 shop 리스트*/,
    options: {
      support_solution: [] /*code : 지원 플랫폼*/,
      language_code_list: [
        { cd_code: '-', cd_desc: '없음' },
        { cd_code: 'ko_KR', cd_desc: '국문' },
        { cd_code: 'en_US', cd_desc: '영문' },
        { cd_code: 'zh_CN', cd_desc: '중문(간체)' },
        { cd_code: 'zh_TW', cd_desc: '중문(번체)' },
        { cd_code: 'ja_JP', cd_desc: '일문' },
        { cd_code: 'vi_VN', cd_desc: '베트남어' },
      ],
    },
  };

  //공통코드 조회
  async getCode(upper_code_cd, option) {
    const res = await http.get(
      '/rest/common/cafe24Code?upper_code_cd=' + upper_code_cd + is_test_use,
    );
    const { options } = this.state;
    this.setState({
      options: {
        ...options,
        [option]: res.data,
      },
    });
  }

  // 1단계 : 플랫폼 선택
  selectSolution = (e, code_cd, code_name) => {
    this.setState({
      solution_type_cd: code_cd,
      solution_name: code_name,
    });

    // 1. 전체
    $('.solutionBox').css({'background-color':'rgb(255, 255, 255)','box-shadow':'0 0 8px 2px rgba(0, 0, 0, 0.04)'});

    if(e.target.style.backgroundColor == 'rgb(255, 255, 255)') {
      e.target.style.backgroundColor = 'rgb(174, 243, 242)';
    }
    else {
      e.target.style.backgroundColor = 'rgb(255, 255, 255)';
    }
  };

  // 리사이징
  // resizing() {
  //   let thisBox = document.getElementsByClassName("solutionBox");
  //   let thisBoxArr = this.state.options.support_solution.length;
  //   if(window.innerWidth <= 800) {
  //     for(let i = 0; i < thisBoxArr; i++) {
  //       console.log(thisBox[i]);
  //       thisBox[i].style.height = thisBox[i].offsetWidth * 0.42 + "px";
  //     }
  //   }
  // }

  // 다음 단계
  nextStep = () => {
    // alert(this.state.solution_name + "을 선택하셨습니다.");
    // if (this.state.step == 2 && this.state.agree_yn == 'N') {
    // 	alert("계정 정보 확인에 동의를 해주십시오.");
    // 	return false;
    // }

    // $('#step' + this.state.step + ' .radio').addClass('on');
    // $('#step' + this.state.step + ' .radio').css('font-size', '0px');
    if (Number(this.state.step) == 1) {
      //this.state.solution_type_cd = "09004" // 샘플 고도몰 코드
      if (this.state.solution_type_cd == '') {
        alert('플랫폼을 선택해 주세요.');
        return false;
      }
    }

    this.setState({
      step: Number(this.state.step) + 1,
    });

    if (this.state.solution_type_cd == '09900') {
      // alert('디자인 전용 배너 서비스를 이용합니다.\n쇼핑몰 설치형 서비스로 전환을 원하시면 1599-3518로 문의 주세요.');
      const confirm = window.confirm('쇼핑몰 미보유 서비스를 선택 하시겠습니까?');
      this.setState({ step: 1 });
      if (confirm == true) {
        this.fn_uninstall_01001_setting();
      } else {
        return false;
      }
    } else if (this.state.solution_type_cd == '09999') {
      alert('기타 플랫폼을 이용하시는 경우,\n1599-3518을 통해 문의를 부탁드려요.');
      this.setState({ step: 1 });
    }

    ga4react.event('[쇼핑몰 데이터 불러오기] 쇼핑몰 플랫폼 선택', '쇼핑몰 플랫폼 선택 ID=' + user_id, '쇼핑몰 데이터 불러오기');
    reactPixel.trackCustom('[쇼핑몰 데이터 불러오기] 쇼핑몰 플랫폼 선택', {user_id : user_id});
  };

  handleMallIdOnBlur = () => {
    ga4react.event('[쇼핑몰 데이터 불러오기] 쇼핑몰 ID 입력', '쇼핑몰 ID 입력 ID=' + user_id, '쇼핑몰 데이터 불러오기');
    reactPixel.trackCustom('[쇼핑몰 데이터 불러오기] 쇼핑몰 ID 입력', {user_id : user_id});
  }

  // 2단계 : 동의
  onClickAgree = (e, value) => {
    // $(".radio_display_yn:checked").prop("checked", false);
    // $("#display_"+e.target.value).prop("checked", true);
    this.setState({ agree_yn: value });

    ga4react.event('[쇼핑몰 데이터 불러오기] 계정 정보 확인 동의', '계정 정보 확인 동의 ID=' + user_id, '쇼핑몰 데이터 불러오기');
    reactPixel.trackCustom('[쇼핑몰 데이터 불러오기] 계정 정보 확인 동의', {user_id : user_id});
  };

  /**
   * 2단계 : mode에 따른 "플랫폼별 상점 가져오기" 기능의 버튼
   *
   * 1. [Cafe24 API] 상점 동기화
   *   mode : '/cafe24_sync_data'
   *
   * 2. 메이크샵 상점 연동에 필요한 데이터 저장
   *   mode : 'makeshop'
   *
   * 3. 아임웹
   *   mode : 'imweb'
   */
  onClickGetStoreInfoButton(mode) {
    if (this.state.step == 2 && this.state.agree_yn != 'Y') {
      alert('계정 정보 확인에 동의를 해주십시오.');
      return false;
    }
    ga4react.event('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 버튼 클릭', '쇼핑몰 데이터 불러오기 버튼 클릭 ID=' + user_id, '쇼핑몰 데이터 불러오기');
    reactPixel.trackCustom('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 버튼 클릭', {user_id : user_id});

    /**
     * 1. 입력받은 상점키 : 저장 및 조회 (해당 계정으로 연동된 멀티샵 리스트 조회)
     * 2. 저장 후 연동된 리스트(체크박스 리스트) 조회
     * 3. 연동할 샵 선택후 저장
     * 4. 연동현황(전체) 조회
     */

    //1. [Cafe24 API] 상점 동기화
    if (mode == '/cafe24_sync_data') {
      var mall_id = this.state.mall_id;
      var solution_type_cd = this.state.solution_type_cd;

      if (mall_id == null || mall_id == '') {
        // 가입신청 - Cafe24 API] 상점 동기화
        // ReactGA.event({
        //     category: "[Cafe24 API] 상점 동기화",
        //     action: "상점 아이디를 입력해 주세요. : " + mall_id,
        //     label: ga_label
        // });
        alert('쇼핑몰 아이디를 입력해 주세요.');
        return false;
      }

      // mall_id 중복체크 추가
      http
        .get('/rest/common/storeCnt?mall_id=' + this.state.mall_id)
        .then((res) => {
          const { data } = res;
          if (data.storeCnt != null) {
            if (Number(data.storeCnt) == 0) {
              let called = false;
              let url = mode + '.html?mall_id='+mall_id+'&client_id='+data.client_id;
              var g_oWindow = window.open('about:blank', '_blank');
              if (g_oWindow != null) {
                called = this.fn_asyncCall(g_oWindow, url);
                console.log('called : ', called);

                // 0.5초 마다 감지
                var g_oInterval = window.setInterval(() => {
                  try {
                    // 창이 꺼졌는지 판단
                    console.log('g_oWindow.closed : ' + g_oWindow.closed);
                    if (g_oWindow == null || g_oWindow.closed) {
                      window.clearInterval(g_oInterval);
                      g_oWindow = null;
                      //this.selectShops(mall_id, solution_type_cd);
                      //window.location.href = "/shop/serviceShopAdd";
                      window.location.href = "/shop/serviceShopList";
                      //$("#mall_id").attr('disabled', true);
                    }
                  } catch (e) {
                    alert(e + '\n관리자에게 문의해 주세요. (1599-3518)');
                    //this.selectShops(mall_id, solution_type_cd);
                  }
                }, 500);
                // g_oWindow.addEventListener("beforeunload", function (event) {
                // 	event.returnValue = "asdasdasd?"
                // 	// console.log("close");
                // 	// this.selectShops(mall_id, solution_type_cd);
                // });
              }

            } else {
              ga4react.event('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 실패', '쇼핑몰 데이터 불러오기 실패 ID=' + user_id, '쇼핑몰 데이터 불러오기');
              reactPixel.trackCustom('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 실패', {user_id : user_id});
              alert('이미 사용중인 쇼핑몰 ID 입니다.');
            }
          } else {
            ga4react.event('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 실패', '쇼핑몰 데이터 불러오기 실패 ID=' + user_id, '쇼핑몰 데이터 불러오기');
            reactPixel.trackCustom('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 실패', {user_id : user_id});
            alert('시스템 점검중 입니다.');
          }
        })
        .catch((error) => {
          ga4react.event('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 실패', '쇼핑몰 데이터 불러오기 실패 ID=' + user_id, '쇼핑몰 데이터 불러오기');
          reactPixel.trackCustom('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 실패', {user_id : user_id});
          alert(error + '\n관리자에게 문의해 주세요. (1599-3518)');
        });

      // Cafe24 로그인 팝업창 열기
      // var g_oWindow = window.open(mode+".html?mall_id="+mall_id, "카페24 데이터 동기화");

      // 가입신청 - Cafe24 API] 상점 동기화
      // ReactGA.event({
      //     category: "[Cafe24 API] 상점 동기화",
      //     action: "동기화 로그인 요청 : " + mall_id,
      //     label: ga_label
      // });
    } else if (mode == 'makeshop') {
      var solution_type_cd = this.state.solution_type_cd;
      var shop_key = this.state.makeshop.shop_key;
      var domain = this.state.makeshop.domain.replace(
        /(https?:\/\/)?((\/[^\s/\/]+)*)?(\?([^#\s]*))?/gi,
        '',
      ); //도메인 추출 (프로토콜, 하위경로, 파라미터 제거)
      //var mall_id = this.state.makeshop.shop_name; // **** cafe24 외의 플랫폼은 shop_name을 mall_id로 대체
      var shop_name = this.state.makeshop.shop_name;
      var language_code = this.state.makeshop.language_code;

      let data = {
        //mall_id: mall_id,
        shop_key: shop_key,
        solution_type_cd: solution_type_cd,
        domain: domain,
        shop_name: shop_name,
        language_code: language_code,
      };

      const config = {
        headers: {
          'content-type': 'application/json;',
        },
      };

      // 1. 입력받은 상점키 : 저장 및 조회 (해당 계정으로 연동된 MakeShop 리스트 조회)
      http
        .post('/rest/common/makeshop/shopAdd', data, config)
        .then((res) => {
          const { data } = res;
          if (data.code == 200) {
            // 2. 저장 후 연동된 리스트(체크박스 리스트) 조회
            //this.selectShops(data.mall_id, solution_type_cd);
            //window.location.href = "/shop/serviceShopAdd";
            window.location.href = "/shop/serviceShopList";
            console.log(res.data);

            ga4react.event('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 완료', '쇼핑몰 데이터 불러오기 완료 ID=' + user_id, '쇼핑몰 데이터 불러오기');
            reactPixel.trackCustom('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 완료', {user_id : user_id});

          } else if (data.code == "-1") {
            ga4react.event('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 실패', '쇼핑몰 데이터 불러오기 실패 ID=' + user_id, '쇼핑몰 데이터 불러오기');
            reactPixel.trackCustom('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 실패', {user_id : user_id});

            alert("도메인 정보가 일치하지 않습니다."+ '\n' +'[code : ' + data.code + ']' + '\n' + data.msg);

          } else if (data.code == "500") {
            ga4react.event('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 실패', '쇼핑몰 데이터 불러오기 실패 ID=' + user_id, '쇼핑몰 데이터 불러오기');
            reactPixel.trackCustom('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 실패', {user_id : user_id});

            alert(
              '[code : ' +
                500 +
                ']' +
                '\n관리자에게 문의해 주세요. (1599-3518)',
            );
          } else {
            ga4react.event('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 실패', '쇼핑몰 데이터 불러오기 실패 ID=' + user_id, '쇼핑몰 데이터 불러오기');
            reactPixel.trackCustom('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 실패', {user_id : user_id});

            alert('[code : ' + data.code + ']' + '\n' + data.msg);
          }
        })
        .catch((error) => {
          ga4react.event('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 실패', '쇼핑몰 데이터 불러오기 실패 ID=' + user_id, '쇼핑몰 데이터 불러오기');
          reactPixel.trackCustom('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 실패', {user_id : user_id});

          alert(error + '\n관리자에게 문의해 주세요. (1599-3518)');
        });

    } else if (mode == 'imweb') {
      var solution_type_cd = this.state.solution_type_cd;
      var api_key = this.state.imweb.api_key;
      var secret_key = this.state.imweb.secret_key;
      var domain = this.state.imweb.domain.replace(
        /(https?:\/\/)?((\/[^\s/\/]+)*)?(\?([^#\s]*))?/gi,
        '',
      ); //도메인 추출 (프로토콜, 하위경로, 파라미터 제거)
      //var mall_id = this.state.imweb.shop_name;     // cafe24 외의 플랫폼은 shop_name을 mall_id로 대체 -> user_id_shop_no 조합으로 대체
      var shop_name = this.state.imweb.shop_name;
      var language_code = this.state.imweb.language_code;

      console.log(domain);

      let data = {
        //mall_id: mall_id,
        api_key: api_key,
        secret_key: secret_key,
        solution_type_cd: solution_type_cd,
        domain: domain,
        shop_name: shop_name,
        language_code: language_code,
      };

      const config = {
        headers: {
          'content-type': 'application/json;',
        },
      };
      // 1. 입력받은 상점키 : 저장 및 조회 (해당 계정으로 연동된 MakeShop 리스트 조회)
      http
        .post('/rest/common/imweb/shopAdd', data, config)
        .then((res) => {
          const { data } = res;
          if (data.code == 200) {
            // 2. 저장 후 연동된 리스트(체크박스 리스트) 조회
            //this.selectShops(data.mall_id, solution_type_cd);
            //window.location.href = "/shop/serviceShopAdd";
            window.location.href = "/shop/serviceShopList";
            console.log(res.data);

            ga4react.event('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 완료', '쇼핑몰 데이터 불러오기 완료 ID=' + user_id, '쇼핑몰 데이터 불러오기');
            reactPixel.trackCustom('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 완료', {user_id : user_id});

          } else if (data.code == -1) {
            ga4react.event('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 실패', '쇼핑몰 데이터 불러오기 실패 ID=' + user_id, '쇼핑몰 데이터 불러오기');
            reactPixel.trackCustom('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 실패', {user_id : user_id});

            alert("API KEY 또는 SECRET KEY 정보가 일치하지 않습니다."+ '\n' +'[code : ' + data.code + ']' + '\n' + data.msg);

          } else if (data.code == 401) {
            ga4react.event('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 실패', '쇼핑몰 데이터 불러오기 실패 ID=' + user_id, '쇼핑몰 데이터 불러오기');
            reactPixel.trackCustom('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 실패', {user_id : user_id});

            alert('[code : ' + data.code + ']' + '\n' + data.msg);

          } else if (data.code == 500) {
            ga4react.event('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 실패', '쇼핑몰 데이터 불러오기 실패 ID=' + user_id, '쇼핑몰 데이터 불러오기');
            reactPixel.trackCustom('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 실패', {user_id : user_id});

            alert(
              '[code : ' +
                data.code +
                ']' +
                '\n관리자에게 문의해 주세요. (1599-3518)',
            );
          }
        })
        .catch((error) => {
          ga4react.event('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 실패', '쇼핑몰 데이터 불러오기 실패 ID=' + user_id, '쇼핑몰 데이터 불러오기');
          reactPixel.trackCustom('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 실패', {user_id : user_id});

          alert(error + '\n관리자에게 문의해 주세요. (1599-3518)');
        });
    } else if (mode == 'godomall') {
      // godomall: {
      //   id: '',
      //   domain: '',
      //   shop_name: '',
      //   language_code: '',
      // },

      var solution_type_cd = this.state.solution_type_cd;
      var id = this.state.godomall.id;
      var domain = this.state.godomall.domain.replace(
        /(https?:\/\/)?((\/[^\s/\/]+)*)?(\?([^#\s]*))?/gi,
        '',
      ); //도메인 추출 (프로토콜, 하위경로, 파라미터 제거)
      var shop_name = this.state.godomall.shop_name;
      var language_code = this.state.godomall.language_code;

      let data = {
        id: id,
        solution_type_cd: solution_type_cd,
        domain: domain,
        shop_name: shop_name,
        language_code: language_code,
      };

      const config = {
        headers: {
          'content-type': 'application/json;',
        },
      };
      // 1. 입력받은 고도몰 정보 : 저장 및 조회
      http
        .post('/rest/common/godomall/shopAdd', data, config)
        .then((res) => {
          const { data } = res;
          if (data.code == 200) {
            // 2. 저장 후 연동된 리스트(체크박스 리스트) 조회
            alert("신청이 완료되었습니다.\n관리자에게 상점키 입력을 요청해 주세요.\n(1599-3518)");
            //this.selectShops(data.mall_id, solution_type_cd);
            //window.location.href = "/shop/serviceShopAdd";
            window.location.href = "/shop/serviceShopList";
            console.log(res.data);

            ga4react.event('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 완료', '쇼핑몰 데이터 불러오기 완료 ID=' + user_id, '쇼핑몰 데이터 불러오기');
            reactPixel.trackCustom('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 완료', {user_id : user_id});

          } else if (data.code == 500) {
            ga4react.event('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 실패', '쇼핑몰 데이터 불러오기 실패 ID=' + user_id, '쇼핑몰 데이터 불러오기');
            reactPixel.trackCustom('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 실패', {user_id : user_id});

            alert(
              '[code : ' +
                data.code +
                ']' +
                '\n관리자에게 문의해 주세요. (1599-3518)',
            );
          }
        })
        .catch((error) => {
          ga4react.event('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 실패', '쇼핑몰 데이터 불러오기 실패 ID=' + user_id, '쇼핑몰 데이터 불러오기');
          reactPixel.trackCustom('[쇼핑몰 데이터 불러오기] 쇼핑몰 데이터 불러오기 실패', {user_id : user_id});

          alert(error + '\n관리자에게 문의해 주세요. (1599-3518)');
        });

    } else {
      alert('[기타 플랫폼] 곧 찾아 뵙겠습니다.');
    }
  }

  /**
   * 비연동형 배너 서비스 데이터 세팅
   */
  fn_uninstall_01001_setting() {
    let data = {};
    const config = {
      headers: {
        'content-type': 'application/json;',
      },
    };
    http.post('/rest/shop/uninstall/shopAdd/01001', data, config)
        .then((res) => {
          const { data } = res;
          if (data.code == "200") {
            setCookie("solution_type_cd", "09900");
            window.location.href = "/shop/cafe24/event/list";
            //window.location.href = "/";

            ga4react.event('[쇼핑몰 데이터 불러오기] 비연동형 배너 서비스 세팅 완료', '비연동형 배너 서비스 세팅 완료 ID=' + user_id, '쇼핑몰 데이터 불러오기');
            reactPixel.trackCustom('[쇼핑몰 데이터 불러오기] 비연동형 배너 서비스 세팅 완료', {user_id : user_id});
          } else {
            ga4react.event('[쇼핑몰 데이터 불러오기] 비연동형 배너 서비스 세팅 실패', '비연동형 배너 서비스 세팅 실패 ID=' + user_id, '쇼핑몰 데이터 불러오기');
            reactPixel.trackCustom('[쇼핑몰 데이터 불러오기] 비연동형 배너 서비스 세팅 실패', {user_id : user_id});
            alert(
              '[code : ' + data.code +'] ' + data.msg
              // + '\n관리자에게 문의해 주세요. (1599-3518)',
            );
          }
        })
        .catch((error) => {
          ga4react.event('[쇼핑몰 데이터 불러오기] 비연동형 배너 서비스 세팅 실패', '비연동형 배너 서비스 세팅 ID=' + user_id, '쇼핑몰 데이터 불러오기');
          reactPixel.trackCustom('[쇼핑몰 데이터 불러오기] 비연동형 배너 서비스 세팅 실패', {user_id : user_id});
          alert(error + '\n관리자에게 문의해 주세요. (1599-3518)');
        });
  }

  // Cafe24 로그인 팝업창 준비 (Safari 대응)
  async fn_asyncCall(g_oWindow, url) {
    g_oWindow.location = url;

    return true;
  }

  /**
   * [베타버전] 연동된 mall이 존재하는지 체크
   *  * 멀티샵만 지원하되 mall은 추가할 수 없다.
   *
   *  1. store 조회 (user_seq)
   *  2. 조회된 데이터에서 solution_type_cd 필드 체크.
   *  3. selectShops 조회
   */
  //사용자의 연동된 상점(store) 조회
  async selectStore() {
    is_stroe_loaded = true;
    // console.log("selectStore : [베타버전] 연동된 mall이 존재하는지 체크");
    const res = await http.get('/rest/common/selectStore');
    // 조회 성공시 다음 단계
    if (res.data != null && res.data.length > 0) {
      //alert("이미 연동된 상점이 존재 합니다.");
      // 쇼핑몰 목록으로 이동
      window.location.href = '/shop/serviceShopList';
    } else {
      console.log("[selectStore] 연동된 상점 정보가 없습니다.");
      // 1단계로 이동
      this.setState({
        step: 1,
      });
    }
  }

  //mall_id에 해당하는 shops 조회
  // async selectShops(mall_id, solution_type_cd) {
  //   const res = await http.get('/rest/common/selectShops', {
  //     params: {
  //       mall_id: mall_id,
  //       solution_type_cd: solution_type_cd,
  //     },
  //   });

  //   // 가입신청 - Cafe24 API] 상점 동기화
  //   //this.nextStep();
  //   // console.log("selectShops : mall_id에 해당하는 shops 조회");
  //   // console.log(res.data);

  //   // 조회 성공시 다음 단계
  //   if (res.data != null && res.data.length > 0) {
  //     /**
  //      * V2 : /shop/serviceShopAdd로 이동
  //      */
  //     alert('연동된 쇼핑몰 정보가 없습니다.\n해당 플랫폼의 쇼핑몰 정보를 확인해 주세요.');
  //   } else {
  //     alert('연동된 쇼핑몰 정보가 없습니다.\n해당 플랫폼의 쇼핑몰 정보를 확인해 주세요.');
  //     this.setState({
  //       step: 1,
  //       mall_id: '',
  //     });
  //   }
  // }

  // input text change event
  handleText = (e, key) => {
    // computed property
    //this.setState({[key]: e.target.value});
    if (key == 'mall_id') {
      this.setState({ mall_id: e.target.value });
    }
  };

  // 메이크샵 input text change event
  makeshop_handleText = (e, key) => {
    const { makeshop } = this.state;
    this.setState({
      makeshop: {
        ...makeshop,
        [key]: e.target.value,
      },
    });
  };

  // 아임웹 input text change event
  imweb_handleText = (e, key) => {
    const { imweb } = this.state;
    this.setState({
      imweb: {
        ...imweb,
        [key]: e.target.value,
      },
    });
  };

  // 고도몰 input text change event
  godomall_handleText = (e, key) => {
    const { godomall } = this.state;
    this.setState({
      godomall: {
        ...godomall,
        [key]: e.target.value,
      },
    });
  };

  // 언어코드 변경 이벤트
  onChangeSelectBoxlanguageCodeHandler = (e, mode) => {
    if (mode == 'makeshop') {
      const { makeshop } = this.state;
      this.setState({
        makeshop: {
          ...makeshop,
          language_code: e.target.value,
        },
      });
    } else if (mode == 'imweb') {
      const { imweb } = this.state;
      this.setState({
        imweb: {
          ...imweb,
          language_code: e.target.value,
        },
      });
    } else if (mode == 'godomall') {
      const { godomall } = this.state;
      this.setState({
        godomall: {
          ...godomall,
          language_code: e.target.value,
        },
      });
    }
  };

  platformImg(solution_type_cd) {
    const { support_solution } = this.state.options
    const idx = support_solution.findIndex((solution) => solution.code_cd == solution_type_cd)
    let img_url = "";
    
    if (idx >= 0) {
      img_url = support_solution[idx].val2;
    }

    return img_url;
  }

  componentDidUpdate() {
    var params = {};
    window.location.search.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (str, key, value) {
        params[key] = value;
      },
    );
    if (this.state.step != '0') {
      // alert(is_stroe_loaded);
      if (!is_stroe_loaded) {
        this.selectStore(); // 상점 추가
      }
    }
  }

  componentDidMount() {
    //쇼핑몰 플랫폼 코드 조회
    this.getCode('09000', 'support_solution');
    //언어 코드 조회 (현재 고정)
    //this.getCode("09000", "language_code_list");

    /**
     * [베타버전] 연동된 mall이 존재하는지 체크
     *  * 멀티샵만 지원하되 mall은 추가할 수 없다.
     *
     *  1. store 조회 (user_seq)
     *  2. 조회된 데이터에서 solution_type_cd 필드 체크.
     *  3. selectShops 조회
     */
    var params = {};
    window.location.search.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (str, key, value) {
        params[key] = value;
      },
    );
    // 로그인 사용자의 연동된 상점 조회
    this.selectStore();
  }

  render() {
    $('.pageName').text('쇼핑몰 데이터 불러오기');
    return (
      <>
        <div
          id="page-wrapper"
          className="container shop_data_container"
        >
          {/* <h3>[{window.location.href.split("/")[3].charAt(0).toUpperCase() + window.location.href.split("/")[3].slice(1) }] 상점 추가하기</h3> */}
          {this.state.step != 1 &&
            <div id="page_title" className="form_primary_title pc_page_title">
              쇼핑몰 데이터 불러오기
            </div>}
          <div className="m-t-43 m-l-29 shop_add_area">

            {/* ----------- 1단계 화면 영역 --------------------------------------------------------------------------------------------------- */}
            {this.state.step == 1 ? (
              <>
                {/* 1단계 title */}
                <div id="page_title" className="form_primary_title pc_page_title">
                  [AI진열ㆍAI배너] 쇼핑몰 설치형 이용시 선택
                </div>
                {/* <div className="flexBox m-t-32 sub_txt">
                  <div id="item_user_type">
                    데이터를 불러올 쇼핑몰 플랫폼을 선택해 주세요.
                  </div>
                </div> */}
                {/* 지원 플랫폼 목록 : 비연동 제외 */}
                <div className="flexBox m-t-32 platform" id="solutions">
                  {this.state.options.support_solution.map((solution, index) => (
                    solution.code_cd != "09900" &&
                      <div
                        key={index}
                        className="solutionBox flexBox m-r-md m-b-md div-hover"
                        id={index}
                        onClick={(e) => { this.selectSolution(e, solution.code_cd, solution.code_name,); }}
                      >
                        <img style={{ pointerEvents: 'none' }} src={solution.val3}/>
                      </div>
                  ))}
                </div>
                <div id="page_title" className="form_primary_title pc_page_title non_connection_title">
                  [AI이미지ㆍAI배너] 디자인 전용 이용시 선택
                </div>
                {/* 지원 플랫폼 목록 : 비연동 */}
                <div className="flexBox m-t-32 platform" id="solutions">
                  {this.state.options.support_solution.map((solution, index) => (
                    solution.code_cd == "09900" &&
                    <div
                      key={index}
                      className="solutionBox flexBox m-r-md m-b-md div-hover solution_select_title"
                      id={index}
                      onClick={(e) => { this.selectSolution(e, solution.code_cd, solution.code_name,); }}
                    >
                      쇼핑몰 미보유
                    </div>
                  ))}
                </div>
                <div className="platform_line"></div>
                <div className="width-100 flexBox mint_btn style_guide_v2">
                  {this.state.solution != '' ? (
                    <button className="hover_mint" onClick={this.nextStep}>{this.state.solution_name} 선택하기</button>
                  ) : (
                    <button className="hover_mint">플랫폼을 선택해 주세요.</button>
                  )}
                </div>
              </>
            ) : (
              ''
            )}

            {/* ----------- 2단계 화면 영역 --------------------------------------------------------------------------------------------------- */}
            {this.state.step == 2 ? (
              <>
                {
                  /* 09001 : Cafe24 */
                  this.state.solution_type_cd == '09001' ? (
                    <>
                      <div className="cafe24_user_guide" style={{ marginBottom: '15px' }}>
                        <div
                          className="flexBox"
                        >
                          <div
                            id="item_user_type"
                            style={{
                              marginTop: 'auto',
                              marginBottom: 'auto',
                              marginRight: '20px',
                              fontSize: '15px',
                            }}
                          >
                            쇼핑몰 데이터를 불러오기 위해 카페24 ID를 알려 주세요.
                          </div>
                          {/* <div className="userGuideButton" onClick={(e) => Menu.call_userGuide('https://www.notion.so/24-f993f919996d43b7ba3694520486a1b7')}> */}
                          <div className="userGuideButton" onClick={(e) => Menu.call_userGuide('https://iclave.notion.site/24-db0f5f49d484468eb7b38bc2983a9e7d')}>
                            <span>사용자 가이드</span>
                          </div>
                        </div>
                        {/* <div className="userGuideButtonMobile" onClick={(e) => Menu.call_userGuide('https://www.notion.so/24-f993f919996d43b7ba3694520486a1b7')} style={{float:'right'}}> */}
                        <div className="userGuideButtonMobile" onClick={(e) => Menu.call_userGuide('https://iclave.notion.site/24-db0f5f49d484468eb7b38bc2983a9e7d')} style={{float:'right'}}>
                            <span>?</span>
                        </div>
                      </div>
                      
                      <div className="input_text_title_d">카페24 쇼핑몰ID</div>
                      <input
                        className="border_form_radius_b"
                        type="text"
                        value={this.state.mall_id}
                        onChange={(e) => this.handleText(e, 'mall_id')}
                        onBlur={this.handleMallIdOnBlur}
                        placeholder="* 필수 입력 사항입니다."
                      />

                      {/*상점 가져오기 완료후 사업자 등록번호 체크
											-> result == true ? 완료 : '가입된 사업자등록번호와 일치하지 않습니다!'*/}
                      <div className="width-100 flexBox shop_data_load_btn">
                        {this.state.solution != '' ? (
                          <button
                            className="btn-175-b"
                            onClick={() =>
                              this.onClickGetStoreInfoButton(
                                '/cafe24_sync_data',
                              )
                            }
                          >
                            쇼핑몰 데이터 불러오기
                          </button>
                        ) : (
                          <button
                            className="btn-175-b"
                            style={{
                              background: '#DBDDE4',
                              boxShadow: '0px 0px 0px',
                            }}
                          >
                            쇼핑몰 ID를 입력해 주세요.
                          </button>
                        )}
                      </div>
                    </>
                  ) : /* -------------------------------------------------------------------------------------------------------------- */
                  /* 09002 : MakeShop */
                  this.state.solution_type_cd == '09002' ? (
                    <>
                      <div
                        className="flexBox"
                        style={{ marginBottom: '15px' }}
                      >
                        <div
                          id="item_user_type"
                          style={{
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            marginRight: '20px',
                            fontSize: '15px',
                          }}
                        >
                          메이크샵 연동을 위한 정보를 입력해 주세요.
                        </div>
                        {/* <div className="userGuideButton" onClick={(e) => Menu.call_userGuide('https://www.notion.so/1b795512f0d2494ab4fa43635fcd083e')}> */}
                        <div className="userGuideButton" onClick={(e) => Menu.call_userGuide('https://iclave.notion.site/c3deef5fe8cb404fad56ecb006aec3fe')}>
                          <span>사용자 가이드</span>
                        </div>
                        {/* <div className="userGuideButtonMobile" onClick={(e) => Menu.call_userGuide('https://www.notion.so/1b795512f0d2494ab4fa43635fcd083e')} style={{float:'right'}}> */}
                        <div className="userGuideButtonMobile" onClick={(e) => Menu.call_userGuide('https://iclave.notion.site/c3deef5fe8cb404fad56ecb006aec3fe')} style={{float:'right'}}>
                          <span>?</span>
                        </div>
                      </div>
                      <div>
                        <div
                          className="input_text_title_d"
                          style={{ zIndex: '999' }}
                        >
                          SHOP KEY
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '500px',
                            maxWidth: 'calc(95% - 15px)',
                            position: 'relative',
                            marginBottom: '12px',
                          }}
                        >
                          <input
                            className="border_form_radius_b"
                            type="text"
                            value={this.state.makeshop.shop_key}
                            onChange={(e) =>
                              this.makeshop_handleText(e, 'shop_key')
                            }
                            placeholder="* 필수 입력 사항입니다."
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          className="input_text_title_d m-t-32"
                          style={{ zIndex: '999' }}
                        >
                          도메인
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '500px',
                            maxWidth: 'calc(95% - 15px)',
                            position: 'relative',
                            marginBottom: '12px',
                          }}
                        >
                          <input
                            className="border_form_radius_b"
                            type="text"
                            value={this.state.makeshop.domain}
                            onChange={(e) =>
                              this.makeshop_handleText(e, 'domain')
                            }
                            placeholder="* 필수 입력 사항입니다."
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          className="input_text_title_d m-t-32"
                          style={{ zIndex: '999' }}
                        >
                          쇼핑몰 이름
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '500px',
                            maxWidth: 'calc(95% - 15px)',
                            position: 'relative',
                            marginBottom: '12px',
                          }}
                        >
                          <input
                            className="border_form_radius_b"
                            type="text"
                            value={this.state.makeshop.shop_name}
                            onChange={(e) =>
                              this.makeshop_handleText(e, 'shop_name')
                            }
                            placeholder="* 필수 입력 사항입니다."
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          className="input_text_title_d eventSettingTitle"
                          style={{ zIndex: '999' }}
                        >
                          <label
                            style={{ marginTop: 'auto', marginBottom: 'auto' }}
                          >
                            언어 코드
                          </label>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '500px',
                            maxWidth: 'calc(95% - 15px)',
                            position: 'relative',
                            marginBottom: '12px',
                          }}
                        >
                          <select
                            id="iframe_page_id_cd"
                            className="border_form_radius_b"
                            value={this.state.makeshop.language_code}
                            onChange={(e) =>
                              this.onChangeSelectBoxlanguageCodeHandler(
                                e,
                                'makeshop',
                              )
                            }
                          >
                            <option value="" disabled>
                              언어 코드를 선택해 주세요.
                            </option>
                            {this.state.options.language_code_list.map(
                              (option, idx) => (
                                <option key={idx} value={option.cd_code}>
                                  {option.cd_desc}
                                </option>
                              ),
                            )}
                          </select>
                        </div>
                      </div>

                      <div className="width-100 flexBox shop_data_load_btn">
                        <button
                          className="btn-175-b"
                          onClick={() =>
                            this.onClickGetStoreInfoButton('makeshop')
                          }
                        >
                          쇼핑몰 데이터 불러오기
                        </button>
                      </div>
                    </>
                  ) : /* -------------------------------------------------------------------------------------------------------------- */
                  /* 09003 : Imweb */
                  this.state.solution_type_cd == '09003' ? (
                    <>
                      <div
                        className="flexBox"
                        style={{ marginBottom: '15px' }}
                      >
                        <div
                          id="item_user_type"
                          style={{
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            marginRight: '20px',
                            fontSize: '15px',
                          }}
                        >
                          아임웹 연동을 위한 정보를 입력해 주세요.
                        </div>
                        {/* <div className="userGuideButton" onClick={(e) => Menu.call_userGuide('https://www.notion.so/ce31dc1d5b9a4640a6a56eafd07f2df8')}> */}
                        <div className="userGuideButton" onClick={(e) => Menu.call_userGuide('https://iclave.notion.site/fe1a130d593f4ef991138f99afde4066')}>
                          <span>사용자 가이드</span>
                        </div>
                        {/* <div className="userGuideButtonMobile" onClick={(e) => Menu.call_userGuide('https://www.notion.so/ce31dc1d5b9a4640a6a56eafd07f2df8')} style={{float:'right'}}> */}
                        <div className="userGuideButtonMobile" onClick={(e) => Menu.call_userGuide('https://iclave.notion.site/fe1a130d593f4ef991138f99afde4066')} style={{float:'right'}}>
                          <span>?</span>
                        </div>
                      </div>
                      <div className="input_text_title_d">API KEY</div>
                      <input
                        className="border_form_radius_b"
                        type="text"
                        value={this.state.imweb.api_key}
                        onChange={(e) => this.imweb_handleText(e, 'api_key')}
                        placeholder="* 필수 입력 사항입니다."
                      />
                      <div className="input_text_title_d">
                        SECRET KEY
                      </div>
                      <input
                        className="border_form_radius_b"
                        type="text"
                        value={this.state.imweb.secret_key}
                        onChange={(e) => this.imweb_handleText(e, 'secret_key')}
                        placeholder="* 필수 입력 사항입니다."
                      />
                      <div>
                        <div
                          className="input_text_title_d m-t-32"
                          style={{ zIndex: '999' }}
                        >
                          도메인
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '500px',
                            maxWidth: 'calc(95% - 15px)',
                            position: 'relative',
                          }}
                        >
                          <input
                            className="border_form_radius_b"
                            type="text"
                            value={this.state.imweb.domain}
                            onChange={(e) => this.imweb_handleText(e, 'domain')}
                            placeholder="* 필수 입력 사항입니다."
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          className="input_text_title_d m-t-32"
                          style={{ zIndex: '999' }}
                        >
                          쇼핑몰 이름
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '500px',
                            maxWidth: 'calc(95% - 15px)',
                            position: 'relative',
                          }}
                        >
                          <input
                            className="border_form_radius_b"
                            type="text"
                            value={this.state.imweb.shop_name}
                            onChange={(e) =>
                              this.imweb_handleText(e, 'shop_name')
                            }
                            placeholder="* 필수 입력 사항입니다."
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          className="input_text_title_d eventSettingTitle"
                          style={{ zIndex: '999' }}
                        >
                          <label
                            style={{ marginTop: 'auto', marginBottom: 'auto' }}
                          >
                            언어 코드
                          </label>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '500px',
                            maxWidth: 'calc(95% - 15px)',
                            position: 'relative',
                          }}
                        >
                          <select
                            id="iframe_page_id_cd"
                            className="border_form_radius_b"
                            value={this.state.imweb.language_code}
                            onChange={(e) =>
                              this.onChangeSelectBoxlanguageCodeHandler(
                                e,
                                'imweb',
                              )
                            }
                          >
                            <option value="" disabled>
                              언어 코드를 선택해 주세요.
                            </option>
                            {this.state.options.language_code_list.map(
                              (option, idx) => (
                                <option key={idx} value={option.cd_code}>
                                  {option.cd_desc}
                                </option>
                              ),
                            )}
                          </select>
                        </div>
                      </div>

                      <div className="width-100 flexBox shop_data_load_btn">
                        <button
                          className="btn-175-b"
                          onClick={() =>
                            this.onClickGetStoreInfoButton('imweb')
                          }
                        >
                          쇼핑몰 데이터 불러오기
                        </button>
                      </div>
                    </>
                  ) :  /* -------------------------------------------------------------------------------------------------------------- */
                    /* 09004 : Godomall */
                    this.state.solution_type_cd == '09004' ? (
                    <>
                      <div
                        className="flexBox"
                        style={{ marginBottom: '15px' }}
                      >
                        <div
                          id="item_user_type"
                          style={{
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            marginRight: '20px',
                            fontSize: '15px',
                          }}
                        >
                          고도몰 연동을 위한 정보를 입력해 주세요.
                        </div>
                        {/* <div className="userGuideButton" onClick={(e) => Menu.call_userGuide('https://www.notion.so/ce31dc1d5b9a4640a6a56eafd07f2df8')}> */}
                        <div className="userGuideButton" onClick={(e) => Menu.call_userGuide('https://iclave.notion.site/3b52245dbcfb4892830c732b539605e4')}>
                          <span>사용자 가이드</span>
                        </div>
                        {/* <div className="userGuideButtonMobile" onClick={(e) => Menu.call_userGuide('https://www.notion.so/ce31dc1d5b9a4640a6a56eafd07f2df8')} style={{float:'right'}}> */}
                        <div className="userGuideButtonMobile" onClick={(e) => Menu.call_userGuide('https://iclave.notion.site/3b52245dbcfb4892830c732b539605e4')} style={{float:'right'}}>
                          <span>?</span>
                        </div>
                      </div>
                      <div className="input_text_title_d" style={{ zIndex: '999' }}>고도몰 ID (nhn-commerce.com 계정 입력)</div>
                      <div
                          style={{
                            display: 'flex',
                            width: '500px',
                            maxWidth: 'calc(95% - 15px)',
                            position: 'relative',
                            marginBottom: '12px',
                          }}
                        >
                        <input
                            className="border_form_radius_b"
                            type="text"
                            value={this.state.godomall.id}
                            onChange={(e) => this.godomall_handleText(e, 'id')}
                            placeholder="* 필수 입력 사항입니다."
                          />
                          {/* <div className="btn-175-b" 
                            style={{display:"flex", justifyContent:"center", alignItems:"center", cursor:"pointer", marginLeft:"10px"}}
                            onClicl={e => this.godomall_id_check()}>
                            아이디 확인
                          </div> */}
                      </div>
                      {/* <div className="input_text_title_d m-t-32">
                        SECRET KEY
                      </div>
                      <input
                        className="border_form_radius_b"
                        type="text"
                        value={this.state.imweb.secret_key}
                        onChange={(e) => this.godomall_handleText(e, 'secret_key')}
                        placeholder="* 필수 입력 사항입니다."
                      /> */}
                      <div>
                        <div
                          className="input_text_title_d m-t-32"
                          style={{ zIndex: '999' }}
                        >
                          도메인
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '500px',
                            maxWidth: 'calc(95% - 15px)',
                            position: 'relative',
                            marginBottom: '12px',
                          }}
                        >
                          <input
                            className="border_form_radius_b"
                            type="text"
                            value={this.state.godomall.domain}
                            onChange={(e) => this.godomall_handleText(e, 'domain')}
                            placeholder="* 필수 입력 사항입니다."
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          className="input_text_title_d m-t-32"
                          style={{ zIndex: '999' }}
                        >
                          쇼핑몰 이름
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '500px',
                            maxWidth: 'calc(95% - 15px)',
                            position: 'relative',
                            marginBottom: '12px',
                          }}
                        >
                          <input
                            className="border_form_radius_b"
                            type="text"
                            value={this.state.godomall.shop_name}
                            onChange={(e) =>
                              this.godomall_handleText(e, 'shop_name')
                            }
                            placeholder="* 필수 입력 사항입니다."
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          className="input_text_title_d eventSettingTitle"
                          style={{ zIndex: '999' }}
                        >
                          <label
                            style={{ marginTop: 'auto', marginBottom: 'auto' }}
                          >
                            언어 코드
                          </label>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '500px',
                            maxWidth: 'calc(95% - 15px)',
                            position: 'relative',
                            marginBottom: '12px',
                          }}
                        >
                          <select
                            id="iframe_page_id_cd"
                            className="border_form_radius_b"
                            value={this.state.godomall.language_code}
                            onChange={(e) =>
                              this.onChangeSelectBoxlanguageCodeHandler(
                                e,
                                'godomall',
                              )
                            }
                          >
                            <option value="" disabled>
                              언어 코드를 선택해 주세요.
                            </option>
                            {this.state.options.language_code_list.map(
                              (option, idx) => (
                                <option key={idx} value={option.cd_code}>
                                  {option.cd_desc}
                                </option>
                              ),
                            )}
                          </select>
                        </div>
                      </div>

                      <div className="width-100 flexBox shop_data_load_btn">
                        <button
                          className="btn-175-b"
                          onClick={() =>
                            this.onClickGetStoreInfoButton('godomall')
                          }
                        >
                          쇼핑몰 데이터 불러오기
                        </button>
                      </div>
                    </>
                  ) : (
                    /* -------------------------------------------------------------------------------------------------------------- */
                    ''
                  )
                }
                {/* 계정정보 확인 동의 */}
                <div
                  className="redio_v2"
                  style={{ flexDirection: 'column' }}
                >
                  {this.state.agree_yn == 'Y' ? (
                    <div className="flexBox radio_area m-t-43">
                      <input
                        type="radio"
                        className="radio_display_yn"
                        name="display_Y"
                        id="display_Y"
                        value="Y"
                        checked={true}
                        onClick={this.onClickAgree}
                      />
                      <div
                        className="radio on"
                        onClick={(e) => this.onClickAgree(e, 'Y')}
                      ></div>
                      <label className="radio_text">
                        계정 정보 확인에 동의합니다.
                      </label>
                    </div>
                  ) : (
                    <div className="flexBox radio_area m-t-43">
                      <input
                        type="radio"
                        className="radio_display_yn"
                        name="display_Y"
                        id="display_Y"
                        value="Y"
                        checked={true}
                        onClick={this.onClickAgree}
                      />
                      <div
                        className="radio"
                        onClick={(e) => this.onClickAgree(e, 'Y')}
                      ></div>
                      <label className="radio_text">
                        계정 정보 확인에 동의합니다.
                      </label>
                    </div>
                  )}
                  {this.state.agree_yn == 'N' ? (
                    <div className="flexBox radio_area m-t-10">
                      <input
                        type="radio"
                        className="radio_display_yn"
                        name="display_N"
                        id="display_N"
                        value="N"
                        onClick={this.onClickAgree}
                      />
                      <div
                        className="radio on"
                        onClick={(e) => this.onClickAgree(e, 'N')}
                      ></div>
                      <label className="radio_text">
                        계정 정보 확인에 동의하지 않습니다.
                      </label>
                    </div>
                  ) : (
                    <div className="flexBox radio_area m-t-10">
                      <input
                        type="radio"
                        className="radio_display_yn"
                        name="display_N"
                        id="display_N"
                        value="N"
                        onClick={this.onClickAgree}
                      />
                      <div
                        className="radio"
                        onClick={(e) => this.onClickAgree(e, 'N')}
                      ></div>
                      <label className="radio_text">
                        계정 정보 확인에 동의하지 않습니다.
                      </label>
                    </div>
                  )}
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        {/* 진열여부 설정 팝업 
          1. popup_id : "common_popup1"		(div id 부여)
          2. width : "450px" 					(최소 450px)
          3. content : "팝업 내용<br/>예제 입니다."
          4. button_list : 
            [
              {
                title: "버튼명",			  // 버튼명
                type: "1",					// 버튼 (컬러)타입 : "1" (#10CFC9), "0" (#FFFFFF) 
                event_method: () => {
                  //이벤트 함수 구현
                  ...
                }
              },
            ]
          5. button_close : 닫기 함수 구현
        */}
        {
          (this.state.common_popup1 === true)
          ? <CommonPopup 
            popup_id={"common_popup1"}
            width={"465px"}
            content={this.state.common_popup1_content}
            button_list={this.state.common_popup1_button_list}
            popup_close={() => this.setState({common_popup1: false})}
          />
          : ''
        }
      </>
    );
  }
}

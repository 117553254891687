import React, { useState } from 'react';
import http from '../../modules/api';

import styles from './ImageUploadTypePopup.module.css';
import DetailImgCropPopup from './DetailImgCropPopup';
import RoboImgSettingPopup from './RoboImgSettingPopup';
import PopupFrameV2 from '../../modules/popup/PopupFrameV2';
import SaveDialogV2 from '../../modules/dialogs/SaveDialogV2';
import UploadFileImagePopup from '../../modules/popup/UploadFileImagePopup';
import UploadLinkImagePopup from '../../modules/popup/UploadLinkImagePopup';
import Button from '../../modules/common/Button';

import file_upload_icon from '../../../resources/img/file_upload_icon.svg';
import link_upload_icon from '../../../resources/img/link_upload_icon.svg';
import detail_page_icon from '../../../resources/img/detail_page_icon.svg';
import robo_img_icon from '../../../resources/img/robo_img_icon.svg';
import ai_badge from '../../../resources/img/ai_badge.svg';

/**
 * 타입별 이미지 업로드 팝업
 * @param {*} className
 * @param {*} uploadImageNum 업로드 될 이미지 개수
 * @function onClose 팝업 닫기 함수
 * @function getUploadImageInfoList 업로드 된 이미지 정보 리스트를 받는 함수
 */
class ImageUploadTypePopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image_idx : 0, // 현재 이미지 idx
            img_info_list : [{
                link_type : "",
                img_link : "",
                org_img_seq : "",
                crop_img_info : {},
            }], // 업로드 된 이미지 정보 리스트
        }
    }

    componentDidMount (){
        document.body.style.overflow = 'hidden';
    }

    componentWillUnmount (){
        document.body.style.overflow = 'auto';
    }

    /**
     * 이미지 변경
     * @param {*} link_type 이미지 링크 타입 (이미지 파일 : 49001, 이미지 주소 : 49002, 상세이미지 분할 : 49003, 로보 이미지 : 49004)
     * @param {*} img_link 이미지 링크
     * @param {*} org_img_seq 이미지 파일 seq (이미지 링크 타입 49001인 경우 사용)
     * @param {*} crop_img_info 상세이미지 정보 (이미지 링크 타입 49003인 경우 사용)
     */
    changeImage = (link_type, img_link, org_img_seq, crop_img_info) => {
        let {img_info_list, image_idx} = this.state;
        img_info_list[image_idx].link_type = link_type;
        img_info_list[image_idx].img_link = img_link;
        img_info_list[image_idx].org_img_seq = org_img_seq;
        img_info_list[image_idx].crop_img_info = crop_img_info;

        this.setState({
            img_info_list: img_info_list,
        }, () => {
            this.closePopup();
        });
    }

    /** 팝업 전부 닫기 */
    closePopup = () => {
        this.setState({
            UploadLinkImagePopupShow: false,
            DetailImgCropPopupShow: false,
            RoboImgSettingPopupShow: false,
        });
    }

    /** 다음 버튼 클릭 */
    nextBtn = () => {
        let {img_info_list, image_idx} = this.state;

        if(!(img_info_list[image_idx].link_type && img_info_list[image_idx].img_link)){
            alert("이미지를 선택해 주세요.")
            return false;
        }

        if(Number(image_idx + 1) < this.props.uploadImageNum){
            img_info_list.push({
                link_type : "",
                img_link : "",
                org_img_seq : "",
                crop_img_info : {},
            });
            this.setState({
                img_info_list : img_info_list,
                image_idx : Number(image_idx) + 1,
            });

            return false;
        }

        this.props.getUploadImageInfoList(img_info_list);
    }


    render () {
		const {className, onClose, uploadImageNum} = this.props;
        const {image_idx} = this.state;
        const {link_type, img_link, crop_img_info} = this.state.img_info_list[image_idx];
        return (
            <>
                <PopupFrameV2
                    className={["ImageUploadTypePopup",className].join(' ')}
                    maxWidth={"500px"}
                    onClose={onClose}
                >
                    <div className={styles.popup_title}>
                        {Number(uploadImageNum) > 1 && (image_idx == "0" ? "첫" : image_idx == "1" ? "두" : image_idx == "2" ? "세" : "네")+"번째"} 이미지 업로드
                    </div>
                    {Number(uploadImageNum) > 1 &&
                        <div className={styles.popup_content}>
                            여러 이미지가 들어가는 배너 템플릿이에요.<br/>{(image_idx == "0" ? "첫" : image_idx == "1" ? "두" : image_idx == "2" ? "세" : "네")}번째 이미지를 업로드해 주세요.
                        </div>}
                    <div className={styles.img_type_area}>
                        <UploadFileImagePopup
                            getImageInfo = {(upload_img) => {
                                this.changeImage(
                                    "49001",
                                    upload_img.org_img_path,
                                    upload_img.robo_banner_org_img_seq,
                                    {},
                                );
                            }}
                        >
                            <div className={[styles.event_img_preview_area, !(link_type == "49001" && img_link) && styles.non_preview_img_area].join(' ')}>
                                <div className={styles.default_img}>
                                    <img src={file_upload_icon}/>
                                    <div>파일 선택</div>
                                </div>
                                {link_type == "49001" && img_link &&
                                    <img className={styles.use_img} src={img_link}/>
                                }
                            </div>
                        </UploadFileImagePopup>

                        <div
                            className={[styles.event_img_preview_area, !(link_type == "49002" && img_link) && styles.non_preview_img_area].join(' ')}
                            onClick={() => {this.setState({UploadLinkImagePopupShow : true})}}
                        >
                            <div className={styles.default_img}>
                                <img src={link_upload_icon}/>
                                <div>이미지 주소</div>
                            </div>
                            {link_type == "49002" && img_link &&
                                <img className={styles.use_img} src={img_link}/>
                            }
                        </div>

                        <div
                            className={[styles.event_img_preview_area, !(link_type == "49003" && img_link) && styles.non_preview_img_area].join(' ')}
                            onClick={() => {
                                this.setState({
                                    DetailImgCropPopupShow : true,
                                });
                            }}
                        >
                            <div className={styles.default_img}>
                                <img src={detail_page_icon}/>
                                <div>상세페이지<br/>자르기</div>
                                <img className={styles.ai_badge} src={ai_badge}/>
                            </div>
                            {link_type == "49003" && img_link &&
                                <img className={styles.use_img} src={img_link}/>
                            }
                        </div>

                        <div
                            className={[styles.event_img_preview_area, !(link_type == "49004" && img_link) && styles.non_preview_img_area].join(' ')}
                            onClick={() => {
                                this.setState({RoboImgSettingPopupShow: true,});
                            }}
                        >
                            <div className={styles.default_img}>
                                <img src={robo_img_icon}/>
                                <div>로보 이미지</div>
                                <img className={styles.ai_badge} src={ai_badge}/>
                            </div>
                            {link_type == "49004" && img_link
                                && <img className={styles.use_img} src={img_link}/>
                            }
                        </div>
                    </div>
                    <div className={styles.button_area}>
                        <Button
                            type={"hover_transparent"}
                            width={"120px"}
                            height={"35px"}
                            fontSize={"14px"}
                            onClick={onClose}
                        >
                            취소
                        </Button>
                        <Button
                            width={"120px"}
                            height={"35px"}
                            fontSize={"14px"}
                            onClick={this.nextBtn}
                        >
                            {Number(uploadImageNum)-1 == image_idx ? "배너 만들기" : "다음"}
                        </Button>
                    </div>
                </PopupFrameV2>
                {this.state.UploadLinkImagePopupShow
                && <UploadLinkImagePopup
                        onClose={this.closePopup}
                        title={"배너에 사용될 이미지 주소를 입력해주세요."}
                        value={this.state.img_info_list[image_idx].link_type == "49002" && this.state.img_info_list[image_idx].img_link}
                        getImageLink={(img_link) => {
                            this.changeImage(
                                "49002",
                                img_link,
                                null,
                                {},
                            );
                        }}
                    />}
                {this.state.DetailImgCropPopupShow
                &&  <DetailImgCropPopup
                        onClose={this.closePopup}
                        crop_img_info = {crop_img_info}
                        selectImgUpload={(crop_img_info) => {
                            this.changeImage(
                                "49003",
                                crop_img_info.crop_img_link_list[crop_img_info.crop_img_seleted_no],
                                null,
                                crop_img_info
                            );
                        }}
                    />}
                {this.state.RoboImgSettingPopupShow
                && <RoboImgSettingPopup
                        onClose={this.closePopup}
                        sendRoboImg={(img) => {
                            this.changeImage(
                                "49004",
                                img,
                                null,
                                {},
                            );
                        }}
                    />}
                {/* 로딩 상태표시 */
                    this.state.save_dialog_show_state &&
                    <SaveDialogV2
                        content={this.state.save_dialog_content}
                        button_list={this.state.save_dialog_button_list}
                    />
                }
            </>
        )
    };
}

ImageUploadTypePopup.defaultProps = {
    className : "",
    uploadImageNum : 1,
    onClose : function(){},
    getUploadImageInfoList : function(){},
}

export default ImageUploadTypePopup;
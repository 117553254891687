export const tagName = {
    bold: "bold",
    italic: "italic",
    underline: "underline",
    strikeThrough: "strikeThrough",
    justifyFull: "justifyFull",
    justifyLeft: "justifyLeft",
    justifyCenter: "justifyCenter",
    justifyRight: "justifyRight",
    removeFormat: "removeFormat",
    undo: "undo",
    redo: "redo",
    foreColor : "foreColor",
    backColor : "backColor",
    letterSpacing: "letterSpacing",
    lineHeight: "lineHeight",
    insertUnorderedList: "insertUnorderedList",
    insertOrderedList: "insertOrderedList",
    insertImage: "insertImage"
}

export const textEditorFontSize = [];
for(let i=0.8;i<2.1;i+=0.2){
    textEditorFontSize.push({value: Math.round(i * 10) / 10, name: Math.round(i * 10) / 10});
}

export const textEditorLetterSpacing = [];
for(let i=0;i<=1.0;i+=0.1){
    textEditorLetterSpacing.push({value: Math.round(i * 10) / 10, name: Math.round(i * 10) / 10});
}

export const textEditorLineHeight = [];
for(let i=0;i<2.1;i+=0.1){
    textEditorLineHeight.push({value: Math.round(i * 10) / 10, name: Math.round(i * 10) / 10});
}
import React from "react";

/**
 * 공통 팝업 (2021-09-10)
 * 
 	1. popup_id : "common_popup1"		(div id 부여)
	2. width : "450px" 					(최소 450px)
	3. content : "팝업 내용<br/>예제 입니다."
	4. button_list : 
		[
			{
				title: "버튼명",			  // 버튼명
				type: "1",					// 버튼 (컬러)타입 : "1" (#10CFC9), "0" (#FFFFFF) 
				event_method: () => {
					//이벤트 함수 구현
					...
				}
			},
		]
	5. popup_close : 닫기 함수 구현

	// 샘플 ********************************************************************************************

	state = {
			...
			//공통 팝업
			common_popup1: false,			//팝업 출력 여부에 사용
			common_popup1_content: '',		//팝업 내용
			common_popup1_button_list: [],	//팝업 버튼 리스트
	};

	...

	this.setState({
		common_popup1: true,
		common_popup1_content: "공통팝업<br/>샘플입니다.",
		common_popup1_button_list: [
			{
				title: "네",
				type: "1",
				event_method: () => {
					//이벤트 함수 구현
					...
					//팝업 닫기
					this.setState({ common_popup1: false })
				}
			},
			{
				title:"아니요",
				type:"0",
				event_method: () => {
					//팝업 닫기
					this.setState({ common_popup1: false })
				}
			}
		]
	});

	...

	{
		(this.state.common_popup1 === true)
		? <CommonPopup 
			popup_id={"common_popup1"}
			width={"500px"}
			content={this.state.common_popup1_content}
			button_list={this.state.button_list}
			popup_close={() => this.setState({common_popup1: false})}
		/>
		: ''
	}

	// ********************************************************************************************

 */
class CommonPopup extends React.Component {

	button_list = () => {
		if( this.props.button_list.length === 0 ) return ''
        return this.props.button_list.map((button, idx) => 
			<div
				key={this.props.popup_id + "_button_" + idx}
				className="btn-auto-b sample_banner_btn"
				style={{
					height: '100%',
					lineHeight: '60px',
					width: '50%',
					cursor: 'pointer',
					fontSize: 'min(1vw, 14px)',
					background: button.type === "1" ? '' : 'white',
					color: button.type === "1" ? '' : '#10CFC9',
					marginLeft: idx > 0 ? '8%' : '',
					border: '1px solid #10CFC9',
					boxShadow: 'none',
					padding: '0 10px',
					textOverflow:'ellipsis',
					overflow:'hidden',
				}}
				onClick={button.event_method}
			>
				{button.title}
			</div>
        );
	}

	render () {

		const button_list = this.button_list()

		return (
			<>
				{/* 진열여부 설정 팝업 */}
				<div id={this.props.popup_id} className="modal_v3_back" style={{top:"0px"}}>
					<div className="modal_v3_popup_area">
						<div className="modal_v3_btn_close"
								onClick={(e) => this.props.popup_close()}
						></div>
						<div
							className="modal_v3_popup"
							style={{
								width: this.props.width,
								minWidth: '450px',
								height: 'auto',
								maxHeight: '70vh',
								overflowY: 'auto',
							}}
						>
							<div className="modal_v3_content"
								style={{height: '95%', textAlign: 'center', fontSize: '16px',}}
							>
								{/* 내용 */}
								<div dangerouslySetInnerHTML={{__html: this.props.content}}></div>
								{/* 버튼영역 */}
								<div
									className="sample_banner_btn_area"
									style={{
										margin: '40px auto 20px',
										width: '80%',
										maxWidth: 'calc(600px + 16%)',
										height: '60px',
										display: 'flex',
									}}
								>
									{ button_list }
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default CommonPopup;
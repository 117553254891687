import React from "react";
import http from '../modules/api';
import * as StringUtil from '../modules/utils/StringUtil';

var email_uid = "";
export class RegistConfirm extends React.Component {

    componentDidMount() {
        email_uid = StringUtil.getUrlParams("email_uid");
    }

    handleResend = () => {
        this.sendAuthMail();
    }
    
    sendAuthMail = () => {
        let body = {
            email_uid: email_uid,
        }

        const config = {
            headers: {
                'content-type': 'application/json;'
            }
        }
			
        http
        .post("/rest/common/email/verify", body, config)
        .then(
            resultData => {		
                if (resultData.data.uuid != null
                    && resultData.data.uuid != "") {
                        // 토스트 3초간 표시
		                document.getElementById("toast_success").classList.add("toast_popup");
                        setTimeout(function() { document.getElementById("toast_success").classList.remove("toast_popup"); },3000);

                        // 주소 갱신 (email_uid 변경)
                        window.history.pushState(null, null, window.location.href.split("?")[0]+"?email_uid="+resultData.data.uuid);
                        email_uid = resultData.data.uuid;
                } else {
                    // 토스트 3초간 표시
		            document.getElementById("toast_error").classList.add("toast_popup");
                    setTimeout(function() { document.getElementById("toast_error").classList.remove("toast_popup"); },3000);
                }
            })
        .catch(
            error => { 
                console.log(error);
            }
        );
    }


    render() {
        return (
            <>
                <div className="only_regist_confirm_page login_style_guide">
                    <div className="regist_confirm">
                        <h2>
                            <span>이메일 인증을 하면</span> <br/>
                            회원가입이 완료됩니다.
                        </h2>
                        <h3>
                            입력하신 이메일 주소로 메일을 보내 드렸습니다. <br/>
                            수신된 이메일에서 인증 버튼을 클릭해 주세요.
                        </h3>
                        <ul>
                            <li>메일 발송이 지연 될 수 있어, 여유를 갖고 확인해주세요.</li>
                            <li>메일이 오지 않는 경우, 스팸 메일함도 확인해주세요.</li>
                            <li>비밀번호 재설정 링크는 메일 수신 후 24시간까지 <br/>유효합니다.</li>
                            <div className="toast_msg" id="toast_success">인증 메일 재발송이 완료되었습니다.</div>
                            <div className="toast_msg" id="toast_error">인증 메일 발송에 실패하였습니다.</div>
                        </ul>
                        <h4>이메일을 받지 못하셨나요?</h4>
                        <button className="hover_white_border" onClick={this.handleResend}>인증 메일 재발송 하기</button>
                        <button className="hover_mint" type="button" onClick={() => window.location.href = "/common/LoginV3"}>로그인 페이지 바로가기</button>
                    </div>
                </div>
            </>
        )
    }
}
import React from 'react';
import axios from 'axios';
import http from '../modules/api';
import {Route, Switch, NavLink, Link} from "react-router-dom";
import $ from 'jquery';

import "react-datepicker/dist/react-datepicker.css";

import ReactGA from 'react-ga';
ReactGA.initialize('UA-78553461-8');

var ga_label = sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")";

export class OrderFreePlan extends React.Component {
    state = {
        tabNum : '1', // 1: 서비스 및 멤버십 이용 내역 , 2: 내 상점 방문자 수

        FreeDatePeriodObj : {},
        // order_payment_amount_list : [
        //     [{shop_name:"블루리본", solution_type: "카페24", solution_url: "blueribbon.co.kr", total_banner_view_cnt: "555,555,555 명", membership:"basic", service_product_name:"메인 상단 배너", payment_type: "기본금액", payment_period: "2021-01-01  ~  2023-01-31", use_yn: "Y", amount: "150,000"}],
        //     [{shop_name:"블루리본", solution_type: "카페24", solution_url: "blueribbon.co.kr", total_banner_view_cnt: "555,555,555 명", membership:"basic", service_product_name:"메인 상단 배너", payment_type: "기본금액", payment_period: "2021-01-01  ~  2023-01-31", use_yn: "Y", amount: "150,000"}],
        // ],
    };

    componentDidMount(e) {
        this.loadFreeDatePeriod();
    }

    componentDidUpdate() {

    }

    componentWillUnmount () {

    }

    onClickTabChange = (e, tabNum) => {
        this.setState({tabNum: tabNum});
    }

    loadFreeDatePeriod () {
        http.get("/rest/shop/freeDatePeriod")
		.then(res => {
            if (res.data != null && res.data != "") {
                this.setState( {FreeDatePeriodObj : res.data} );
            }
		})
		.catch(error => {
			// console.log (error);
		 });

    }


	render() {
        $(".pageName").text("멤버십 관리");
        let today = new Date();  
        return (
            <div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%", paddingBottom: "40px"}}>
                <div className="form_primary_title pc_page_title">멤버십 관리</div>
                <div>
                    {
                        this.state.FreeDatePeriodObj == null || this.state.FreeDatePeriodObj == {}
                        ?   ""
                        :   this.state.FreeDatePeriodObj.is_finish == 0
                            ?
                                <div className="flexBox credit_card_box_area" style={{marginTop:"50px", padding: "37px 50px", border:"1px #EBEBED solid", borderRadius:"10px", justifyContent:"space-between"}}>
                                    <div className="flexBox">
                                        <div className="icn_receipt" style={{margin: "auto 10px auto 0"}}></div>
                                        <div className="icn_credit_card_text" style={{flex: 1, margin: "auto 0"}}><b>무료체험</b><b style={{color:"#10CFC9", marginLeft:"10px"}}>{/*오늘포함*/} {this.state.FreeDatePeriodObj.remain_date}일 남음</b></div>
                                    </div>
                                    <div className="flexBox">
                                        <div className="icn_credit_card_text"><b style={{color:"#10CFC9", marginLeft:"10px"}}>{this.state.FreeDatePeriodObj.start_date_format
                                        + " "
                                        + this.state.FreeDatePeriodObj.start_day_of_week
                                        + " ~ " 
                                        + this.state.FreeDatePeriodObj.end_date_format 
                                        + " "
                                        + this.state.FreeDatePeriodObj.end_day_of_week }</b></div>
                                    </div>
                                </div>
                            :
                                <div className="flexBox credit_card_box_area" style={{marginTop:"50px", padding: "37px 50px", border:"1px #EBEBED solid", borderRadius:"10px", justifyContent:"space-between"}}>
                                    <div className="flexBox">
                                        <div className="icn_receipt_red" style={{margin: "auto 10px auto 0"}}></div>
                                        <div className="icn_credit_card_text" style={{flex: 1, margin: "auto 0"}}><b style={{color:"#F3557C"}}>무료 체험이 종료 되었습니다.</b></div>
                                    </div>
                                    <div className="flexBox">
                                        <div className="icn_credit_card_text" style={{margin:"auto"}}><b style={{color:"#F3557C", marginLeft:"10px"}}>{this.state.FreeDatePeriodObj.start_date_format
                                        + " "
                                        + this.state.FreeDatePeriodObj.start_day_of_week
                                        + " ~ " 
                                        + this.state.FreeDatePeriodObj.end_date_format 
                                        + " "
                                        + this.state.FreeDatePeriodObj.end_day_of_week }</b></div>
                                    </div>
                                </div>
                    }
                    
                    <div style={{display:"flex"}}>
                        {
                            this.state.tabNum == "1"
                            ?   <div className="radius_tab" style={{
                                    width: "auto",
                                    marginTop:"20px",
                                    padding: "50px", 
                                    padding: "37px 50px", 
                                    borderWidth:"1px 1px 0px 1px", 
                                    borderColor: "#EBEBED",
                                    borderStyle: "solid",
                                    borderRadius:"10px 10px 0px 0px",
                                    background:"white",
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    cursor:"pointer"
                                    }}
                                    onClick={e => this.onClickTabChange(e, "1")}>
                                        배너 서비스
                                </div>
                            :   <div className="radius_tab" style={{
                                    width: "auto",
                                    marginTop:"20px",
                                    marginLeft: "20px",
                                    padding: "50px", 
                                    padding: "37px 50px", 
                                    borderWidth:"0", 
                                    borderColor: "#EBEBED",
                                    borderStyle: "solid",
                                    borderRadius:"10px 10px 0px 0px",
                                    // background: "rgba(255, 255, 255, 0.5)",
                                    color: "rgba(51, 51, 51, 0.3)",
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    cursor:"pointer"
                                    }}
                                    onClick={e => this.onClickTabChange(e, "1")}>
                                        배너 서비스
                                </div>
                        }
                    </div>
                    <div className="free_card_box_area" style={{marginTop: "-1px", padding: "50px", padding: "37px 50px", border:"1px #EBEBED solid", borderRadius:"0px 10px 10px 10px", background:"white"}}>
                        <div>
                            <div style={{textAlign:"center", fontWeight: "bold", fontSize: "18px", marginTop:"40px", marginBottom:"50px"}}>
                            올인원 배너 관리 서비스 로보MD 멤버십을 결제하고<br/>
                            모든 배너 업무를 자동화 해 보세요!
                            </div>
                            <div style={{display:"flex", flexWrap:"wrap"}}>
                                <div className="free_card_box" style={{width:"calc((100% - 9vw) / 4)", height: "22vw", background: "#FAFAFB", borderRadius: "10px"}}>
                                    <div className="icn_cursor_pointer_b" style={{
                                            width: "4.5vw",
                                            height: "4.5vw",
                                            margin: "6vw auto 5vw auto"
                                    }}></div>
                                    <div style={{textAlign:"center", fontWeight:"bold", fontSize:"15px"}}>
                                        상품선택
                                    </div>
                                </div>
                                <div className="free_card_box" style={{width:"calc((100% - 9vw) / 4)", height: "22vw", background: "#FAFAFB", borderRadius: "10px", marginLeft:"3vw"}}>
                                    <div className="icn_desktop_phone"style={{
                                            width: "4.5vw",
                                            height: "4.5vw",
                                            margin: "6vw auto 5vw auto"
                                    }}></div>
                                    <div style={{textAlign:"center", fontWeight:"bold", fontSize:"15px"}}>
                                    PC · 모바일 배너 디자인
                                    </div>
                                </div>
                                <div className="free_card_box" style={{width:"calc((100% - 9vw) / 4)", height: "22vw", background: "#FAFAFB", borderRadius: "10px", marginLeft:"3vw"}}>
                                    <div className="icn_thumbs_up"style={{
                                            width: "4.5vw",
                                            height: "4.5vw",
                                            margin: "6vw auto 5vw auto"
                                    }}></div>
                                    <div style={{textAlign:"center", fontWeight:"bold", fontSize:"15px"}}>
                                    고객별 배너 설정
                                    </div>
                                </div>
                                <div className="free_card_box" style={{width:"calc((100% - 9vw) / 4)", height: "22vw", background: "#FAFAFB", borderRadius: "10px", marginLeft:"3vw"}}>
                                    <div className="icn_store_front"style={{
                                            width: "4.5vw",
                                            height: "4.5vw",
                                            margin: "6vw auto 5vw auto"
                                    }}></div>
                                    <div style={{textAlign:"center", fontWeight:"bold", fontSize:"15px"}}>
                                    쇼핑몰 노출
                                    </div>
                                </div>
                            </div>
                            <div style={{marginTop:"50px"}}>
                                <NavLink to="/shop/orderNew">
                                    <div className="btn-auto-b btn-mobile-full" style={{width:"434px", height:"87px", display:"flex", justifyContent:"center" , margin:"0 auto"}}>
                                        <div className="icn_credit_card_w" style={{margin: "auto 0"}}></div>
                                        <div style={{marginLeft:"20px", fontSize:"20px", alignSelf: "center"}}>로보MD 멤버십 결제하기</div>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}   
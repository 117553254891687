import React from "react";
import { v4 as uuidv4 } from 'uuid';

/**
 * @param {*} id 라디오버튼 id
 * @param {*} name 라디오버튼 name
 * @param {*} value 라디오버튼 value 값
 * @param {*} text 라디오버튼 span에 보일 텍스트, null이면 span 영역 사용안함
 * @param {*} checked 체크 상태 true/false, null이면 false
 * @param {*} disabled 비활성화 상태 true/false, null이면 false
 * @param {*} onChangeCheck 체크 변경시 실행 함수
 * @param {*} className 클래스 명
 * @param {*} img_url 이미지 주소
 * @param {*} img_width 이미지 너비
 * @param {*} img_height 이미지 너비
 */
class RadioButton extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            id : "",
        }
    }

    componentDidMount = () => {
        this.setState({id : this.props.id ? this.props.id : uuidv4()});
    }

	render () {
		const {children, name, value, text, checked, disabled, onChangeCheck, className, img_url, img_width, img_height} = this.props;
        const {id} = this.state;
		return (
            <div className="radioWrap" style={{display: "flex",flexDirection: "column"}}>
                <div className={"common_radio_button "+className}>
                    <input className={"radio"} type="radio" id={id} value={value} name={name} disabled={disabled} checked={checked} onChange={e => !disabled ? onChangeCheck(e) : ""}/>
                    <label htmlFor={id}>
                        <div className={"radio_btn"}>
                            <div className={"radio_btn_inner"}></div>
                        </div>
                    </label>
                    {text && <span dangerouslySetInnerHTML={{__html: text}}></span>}
                    { children }
                </div>
                {
                    img_url && <><br/><img src={img_url} style={{width:img_width, height:img_height, margin:"auto"}}></img></>
                }
            </div>
		);
	}
}

RadioButton.defaultProps = {
    value : "",
    checked : false,
    disabled : false,
    className : "",
}

export default RadioButton;
import React, {Component} from 'react';
import http from '../modules/api';
import {Route, Switch} from "react-router-dom";
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";
import FileDownloadComponent from "../modules/FileDownloadComponent";
import $ from 'jquery';

import "react-datepicker/dist/react-datepicker.css";

export class EventSettingHist extends React.Component {
	
	state = {
			list: [],
			hist_list: [],
			orderGubun: "",
			sorting: "",
			currentPage: "",
			start_date: "",
			end_date: "",
			start_date_format: "",
			end_date_format: "",
			title: "",
			roles: "",
			role_cls: "",
			activePage: 1,
			totalCnt: 0,
			startIdx: 0,
			hist_log_activePage: 1,
			hist_log_totalCnt: 0,
			hist_log_startIdx: 0,
			rowcnt:10,
			hist_log_rowcnt:10,
			options:[]
	}
	
	componentDidMount() {
		$("#modalBack").hide(); // 모달 배경 숨김
		$("#modal").hide(); // 모달 숨김
		if (localStorage.getItem ('state') != null){
			new Promise( ( resolve, reject ) => {setTimeout( resolve, 200 ); })
            .then( () => {
				var json = JSON.parse(localStorage.getItem ('state'));
				json.list = [];
				json.start_date = Date.parse(json.start_date_format);
				json.end_date = Date.parse(json.end_date_format);
				this.setState(json);
    			this.state.startIdx = parseInt(localStorage.getItem("startIdx"));
    			localStorage.clear();
    			
    			this.getUserList();
        		this.getCode("2200");		//권한코드조회
            });
		} else {
			this.getUserList();
    		this.getCode("2200");		//권한코드조회
		}

		$(".modalClose").click(function() {
            $("#modalBack").hide();
            $("#modal").hide();
            $("body").css("background-color", "white");
            $("#modalBack").css("background-color", "white");
            $("#page-wrapper").css("background-color", "white");
        });
	}

	componentWillUnmount () {
		$("#modalBack").hide();
		$("#modal").hide();
		$("body").css("background-color", "white");
		$("#modalBack").css("background-color", "white");
		$("#page-wrapper").css("background-color", "white");
	}
	
	//공통코드 조회
	async getCode(cd_class) {
	    const res = await http.get("/rest/common/roboMdCode?cd_class="+cd_class);
	    this.setState({options:res.data});
	};
	
	//1. 사용자 목록 조회
	async getUserList() {
//        const res = await http.get("/rest/admin/adminUserFindAll", {params:{user_id:''}});
//        this.setState(res.data);
        await http.get("/rest/admin/selectAutoEventSettingHist?start_date="+this.state.start_date_format
        											 +"&end_date="+this.state.end_date_format
        											 +"&title="+this.state.title
        											 +"&roles="+this.state.roles
        											 +"&role_cls="+this.state.role_cls
        											 +"&order_type="+this.state.sorting
        											 +"&order_gubun="+this.state.orderGubun
        											 +"&startidx="+this.state.startIdx
        											 +"&rowcnt="+this.state.rowcnt)
        		   .then(res => {
        			   		this.setState( {list : res.data["list"]} );
    			   			this.setState( {totalCnt : res.data["totalCnt"]} );
    			   			// console.log(this.state.totalCnt)
    			   			// console.log(this.state.activePage)
    			   			// console.log(this.state.startIdx)
        		   })
        		   .catch(error => {
        			   		// console.log (error);
        		   });
        
    }
	//2. 사용자 상세 조회 이벤트
	handleClick = (user_id) => {
		this.props.history.push(`/admin/userFind/${user_id}`);
		localStorage.setItem ( 'state' , JSON.stringify(this.state) ) ;
		// localStorage.setItem ( 'activePage' , this.state.activePage ) ;
		localStorage.setItem ( 'startIdx' , this.state.startIdx ) ;
	};
	// 정렬
	onClickSorting = (e) => {
		if (this.state.orderGubun == e.target.className.replace(" sorting","")) {
			if (this.state.sorting == "ASC")
			this.state.sorting = "DESC";
			else
			this.state.sorting = "ASC";
		} else {
			this.state.sorting = "DESC";
		}
		this.state.orderGubun = e.target.className.replace(" sorting","");
		this.state.currentPage = 1;
		this.getUserList();
	};

	// 상세팝업 정렬
	onClickSortingHistLogPage = (e) => {
		if (this.state.orderGubun == e.target.className.replace(" sorting","")) {
			if (this.state.sorting == "ASC")
			this.state.sorting = "DESC";
			else
			this.state.sorting = "ASC";
		} else {
			this.state.sorting = "DESC";
		}
		this.state.orderGubun = e.target.className.replace(" sorting","");
		this.state.currentPage = 1;
		this.getHistLog(this.state.hist_log_user_seq, this.state.hist_log_shop_no, this.state.hist_log_iframe_page_id, this.state.hist_log_iframe_html_id)
	};
	
	//3. 사용자 조회 버튼 이벤트
	onClickSearchListButton = () => {
		this.state.startIdx = 0;
		this.state.activePage = 1;
		this.getUserList();
	};
	//4. 달력 클릭
	handleChangeStartDate = date => {
		console.log(date);
		if (date != null) {
			this.setState({
				start_date_format: date.getFullYear() + "-"+ ("0" + parseInt(date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(date.getDate())).slice(-2),
				start_date: date
			});
		} else {
			this.setState({
				start_date_format: date,
				start_date: date
			});
		}
	};
	handleChangeEndDate = date => {
		console.log(date);
		if (date != null) {
			this.setState({
				end_date_format: date.getFullYear() + "-"+ ("0" + parseInt(date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(date.getDate())).slice(-2),
				end_date: date
			});
		} else {
			this.setState({
				end_date_format: date,
				end_date: date
			});
		}
	};
	//5. 엔터키 검색
	_handleKeyDown = e => {
		if (e.key === 'Enter') {
			this.state.startIdx = 0;
			this.state.activePage = 1;
			this.getUserList();
		}
	}
	
	//검색 권한 변경 이벤트
	_handleChange = (e, key) => {
	    // computed property
	    this.setState({[key]: e.target.value});
	};
	
	//6.페이징
	handlePageChange(pageNumber) {
		// console.log(`active page is ${pageNumber}`);
		this.setState({activePage: pageNumber});
		this.state.startIdx = (pageNumber * 10) - this.state.rowcnt;
		// console.log("this.state.startIdx :" + this.state.startIdx )
		this.getUserList()
	}  

	handleHistPageChange(pageNumber) {
		this.setState({hist_log_activePage: pageNumber});
		this.state.hist_log_startIdx = (pageNumber * 10) - this.state.hist_log_rowcnt;
		this.getHistLog(this.state.hist_log_user_seq)
	}  

	onClickHistCnt = (user_seq, shop_no, iframe_page_id, iframe_html_id) => {
		this.state.hist_log_activePage = 1;
		this.state.hist_log_startIdx = 0;
		this.setState({
			hist_log_user_seq: user_seq,
			hist_log_shop_no: shop_no,
			hist_log_iframe_page_id: iframe_page_id,
			hist_log_iframe_html_id: iframe_html_id,
		})
		this.getHistLog(user_seq, shop_no, iframe_page_id, iframe_html_id);
		$("#modalBack").show();
        $("#modal").show();
        $("body").css("background-color", "#a6a6a6");
        $("#modalBack").css("background-color", "#a6a6a6");
        $("#page-wrapper").css("background-color", "#a6a6a6");
	}

	async getHistLog(user_seq, shop_no, iframe_page_id, iframe_html_id) {
				await http.get("/rest/admin/selectAutoEventSettingHistDetail?user_seq="+user_seq
															 +"&shop_no="+shop_no
															 +"&iframe_page_id="+iframe_page_id
															 +"&iframe_html_id="+iframe_html_id
															 +"&order_type="+this.state.sorting
        											 		 +"&order_gubun="+this.state.orderGubun
															 +"&startidx="+this.state.hist_log_startIdx
															 +"&rowcnt="+this.state.hist_log_rowcnt)
						   .then(res => {
									   this.setState( {hist_list : res.data["list"], hist_log_totalCnt : res.data["totalCnt"]} );
									   // console.log(this.state.hist_log_totalCnt)
									   // console.log(this.state.activePage)
									   // console.log(this.state.startIdx)
						   })
						   .catch(error => {
									   // console.log (error);
						   });
				
			}

	
	render() {
		
		return (
			<div id='page-wrapper' className="container event_set_hist_container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
				<div id="notice_list_component">
					<h3>[Admin] 자동기획전 설정 이력 조회</h3>
					<br/>
					<div className="filter">
						<div style={{display:"flex"}}>
							<div className="input-group" style={{width:"100%", marginBottom:"12px"}}>
								<div style={{width: "100%"}}> 
								<label>날짜</label>
									<div className="input-daterange input-group" id="datepicker">
										<DatePicker name="start_date" id="start_date"
											selected={this.state.start_date}
											onSelect={this.handleSelect} //when day is clicked
											onChange={this.handleChangeStartDate} //only when value has changed
											// showTimeSelect
											dateFormat="yyyy-MM-dd"
											autoComplete='off'
										/>
										<div className="input-group-addon" style={{width: "40px",height:"35px", lineHeight: "34px",textAlign:"center"}}>to</div>
										<DatePicker name="end_date" id="end_date"
											selected={this.state.end_date}
											onSelect={this.handleSelect} //when day is clicked
											onChange={this.handleChangeEndDate} //only when value has changed
											// showTimeSelect
											dateFormat="yyyy-MM-dd"
											autoComplete='off'
										/>	
									</div>
								</div>
							</div>
						</div>
						<div style={{display:"flex", marginBottom: '12px'}}>
							<input type="text" onChange={(e) => {
								// console.log(e.target.value)
								this.setState({ title: e.target.value })
							}} placeholder="검색어를 입력하세요." className="event_set_hist_input" onKeyDown={this._handleKeyDown} value={this.state.title}></input>
							<button className = "greenButton admin_search_btn" onClick={this.onClickSearchListButton}>검색</button>
							<FileDownloadComponent url={"/rest/admin/selectAutoEventSettingHist/excel?"+
																					"&start_date="+this.state.start_date_format
																					+"&end_date="+this.state.end_date_format
																					+"&title="+this.state.title
																					+"&order_type="+this.state.sorting
																					+"&order_gubun="+this.state.orderGubun
																					}
														   obj=""
														   val1="none" />
						</div>
					</div>
					
					<div className="table item_modal_table">
						<table>
							<colgroup>
								<col width="120px" />
								<col width="150px" />
								{/* <col width="100px" /> */}
								<col width="200px" />
								<col width="80px" />
								<col width="80px" />
								<col width="80px" />
								<col width="150px" />
								<col width="150px" />
								<col width="150px" />
								<col width="150px" />
								<col width="250px" />
								<col width="120px" />
								<col width="80px" />
								<col width="80px" />
								<col width="80px" />
								<col width="150px" />
							</colgroup>
							<thead>
								<tr>
				        			<td className="user_id sorting" onClick={this.onClickSorting} style={{width:"120px"}}>
				        				<p className="card-text">사용자 ID</p>
				        			</td>
				        			<td className="corp_name sorting" onClick={this.onClickSorting} style={{width:"150px"}}>
				        				<p className="card-text">소속명</p>
				        			</td>
				        			{/* <td className="user_name sorting" onClick={this.onClickSorting} style={{width:"100px"}}>
				        				<p className="card-text">담당자명</p>
				        			</td> */}
									<td className="shop_text sorting" onClick={this.onClickSorting} style={{width:"200px"}}>
				        				<p className="card-text">배너용 쇼핑몰</p>
				        			</td>
				        			<td className="event_cycle sorting" onClick={this.onClickSorting} style={{width:"80px"}}>
				        				<p className="card-text">생성주기</p>
				        			</td>
				        			<td className="event_time sorting" onClick={this.onClickSorting} style={{width:"80px"}}>
				        				<p className="card-text">생성시간</p>
				        			</td>
									<td className="event_period sorting" onClick={this.onClickSorting} style={{width:"80px"}}>
				        				<p className="card-text">진열기간</p>
				        			</td>
									<td className="event_banner_pc_width_height sorting" onClick={this.onClickSorting} style={{width:"150px"}}>
				        				<p className="card-text">배너 사이즈(PC)</p>
				        			</td>
									<td className="event_banner_mobile_width_height sorting" onClick={this.onClickSorting} style={{width:"150px"}}>
				        				<p className="card-text">배너 사이즈(Mobile)</p>
				        			</td>
									<td className="event_type_cds_nm sorting" onClick={this.onClickSorting} style={{width:"150px"}}>
				        				<p className="card-text">배너유형(PC)</p>
				        			</td>
									<td className="event_mobile_type_cds_nm sorting" onClick={this.onClickSorting} style={{width:"150px"}}>
				        				<p className="card-text">배너유형(Mobile)</p>
				        			</td>
				        			<td className="domain_list sorting" onClick={this.onClickSorting} style={{width:"250px"}}>
				        				<p className="card-text">도메인</p>
				        			</td>
				        			<td className="mod_date sorting" onClick={this.onClickSorting} style={{width:"120px"}}>
				        				<p className="card-text">등록/수정일자</p>
				        			</td>
									<td className="hist_cnt sorting" onClick={this.onClickSorting} style={{width:"80px"}}>
				        				<p className="card-text">변경이력</p>
				        			</td>
									<td className="event_item_created_period sorting" onClick={this.onClickSorting} style={{width:"80px"}}>
				        				<p className="card-text">상품 등록날짜</p>
				        			</td>
									<td className="event_item_analysis_period sorting" onClick={this.onClickSorting} style={{width:"80px"}}>
				        				<p className="card-text">상품 분석기간</p>
				        			</td>
									<td className="event_item_price sorting" onClick={this.onClickSorting} style={{width:"150px"}}>
				        				<p className="card-text">상품 가격a</p>
				        			</td>
				        		</tr>
			        		</thead>
			        		<tbody style={{display:"block", width:"100%", overflow:"scroll"}}>
							{this.state.list.length > 0 ?
								this.state.list.map(user => (
									<tr className="cursor-pointer tbody-tr-hover"  onClick={() => this.onClickHistCnt(user.user_seq, user.shop_no, user.iframe_page_id, user.iframe_html_id)}>
										<td style={{width:"120px"}}>
											<div className="cursor-pointer" style={{color:"blue"}} onClick={() => this.handleClick(user.user_id)}>
												<p className="card-text">{user.user_id}</p>
											</div>
										</td>
										<td style={{width:"150px"}}>
											<div className="cursor-pointer" style={{color:"blue"}} onClick={() => this.handleClick(user.user_id)}>
												<p className="card-text">{user.corp_name}</p>
											</div>
										</td>
										{/* <td style={{width:"100px"}}>
											<p className="card-text">{user.user_name}</p>
										</td> */}
										<td style={{width:"200px"}}>
											<p className="card-text">{user.shop_text}</p>
										</td>
										<td style={{width:"80px"}}>
											<p className="card-text">{user.event_cycle}</p>
										</td>
										<td style={{width:"80px"}}>
											<p className="card-text">{user.event_time}</p>
										</td>
										<td style={{width:"80px"}}>
											<p className="card-text">{user.event_period}</p>
										</td>
										<td style={{width:"150px"}}>
											<p className="card-text">{user.event_banner_pc_width_height}</p>
										</td>
										<td style={{width:"150px"}}>
											<p className="card-text">{user.event_banner_mobile_width_height}</p>
										</td>
										<td style={{width:"150px"}}>
											<p className="card-text">{user.event_type_cds_nm}</p>
										</td>
										<td style={{width:"150px"}}>
											<p className="card-text">{user.event_mobile_type_cds_nm}</p>
										</td>
										<td style={{width:"250px"}}>
											<p className="card-text">
												{
													user.domain_list != null ? (user.domain_list).split(",").map(line => {
														return (<span>{line}<br/></span>)
													}) : ''
												}
											</p>
										</td>
										<td style={{width:"120px"}}>
											<p className="card-text">{user.mod_date}</p>
										</td>
										<td style={{width:"80px"}}>
											{
												user.hist_cnt > 0 
												?	<p className="card-text cursor-pointer" style={{color:"blue"}} onClick={() => this.onClickHistCnt(user.user_seq, user.shop_no, user.iframe_page_id, user.iframe_html_id)}>{user.hist_cnt}</p>
												:	<p className="card-text">{user.hist_cnt}</p>
											}
										</td>
										<td style={{width:"80px"}}>
											<p className="card-text">{user.event_item_created_period}</p>
										</td>
										<td style={{width:"80px"}}>
											<p className="card-text">{user.event_item_analysis_period}</p>
										</td>
										<td style={{width:"150px"}}>
											<p className="card-text">{user.event_item_price}</p>
										</td>
									</tr>
								)) 
								:
									<tr>
										<td colSpan="11">
											<p className="card-text">조회된 데이터가 없습니다.</p>
										</td>
									</tr>
							}
							</tbody>
						</table>
					</div>
					<Pagination
						activePage={this.state.activePage}
						itemsCountPerPage={this.state.rowcnt}
						totalItemsCount={this.state.totalCnt}
						pageRangeDisplayed={5}
						onChange={this.handlePageChange.bind(this)}
					/>
		        </div>

				{/* 이력 상세 팝업 */}
                <div id="modal">
                    <div id="modalContent" className="shadow-box">
						<h3>자동기획전 설정 이력 상세</h3>
						<div className="table item_modal_table">
                            <table>
                                <colgroup>
									<col width="200px" />
                                    <col width="80px" />
                                    <col width="80px" />
                                    <col width="80px" />
									<col width="120px" />
									<col width="120px" />
                                    <col width="120px" />
									<col width="120px" />
									<col width="250px" />
									<col width="120px" />
									<col width="80px" />
									<col width="80px" />
									<col width="150px" />
                                </colgroup>
                                <thead>	
                                    <tr>
										<td className="shop_text sorting" onClick={this.onClickSortingHistLogPage} style={{width: "200px"}}>
											<p className="card-text">배너용 쇼핑몰</p>
                                        </td>
                                        <td className="event_cycle sorting" onClick={this.onClickSortingHistLogPage} style={{width: "80px"}}>
                                            <p className="card-text">생성주기</p>
                                        </td>
                                        <td className="event_time sorting" onClick={this.onClickSortingHistLogPage} style={{width: "80px"}}>
                                            <p className="card-text">생성시간</p>
                                        </td>
										<td className="event_period sorting" onClick={this.onClickSortingHistLogPage} style={{width: "80px"}}>
                                            <p className="card-text">진열기간</p>
                                        </td>
										<td className="event_banner_pc_width_height sorting" onClick={this.onClickSorting} style={{width:"120px"}}>
											<p className="card-text">배너 사이즈(PC)</p>
										</td>
										<td className="event_banner_mobile_width_height sorting" onClick={this.onClickSorting} style={{width:"120px"}}>
											<p className="card-text">배너 사이즈(Mobile)</p>
										</td>
                                        <td className="event_type_cds_nm sorting" onClick={this.onClickSortingHistLogPage} style={{width: "120px"}}>
                                            <p className="card-text">배너유형(PC)</p>
                                        </td>
                                        <td className="event_mobile_type_cds_nm sorting" onClick={this.onClickSortingHistLogPage} style={{width: "120px"}}>
                                            <p className="card-text">배너유형(Mobile)</p>
                                        </td>
                                        <td className="domain_list sorting" onClick={this.onClickSortingHistLogPage} style={{width: "250px"}}>
                                            <p className="card-text">도메인</p>
                                        </td>
                                        <td className="mod_date sorting" onClick={this.onClickSortingHistLogPage} style={{width: "120px"}}>
                                            <p className="card-text">변경일자</p>
                                        </td>
                                        <td className="event_item_created_period sorting" onClick={this.onClickSortingHistLogPage} style={{width: "80px"}}>
                                            <p className="card-text">상품등록날짜</p>
                                        </td>
										<td className="event_item_analysis_period sorting" onClick={this.onClickSortingHistLogPage} style={{width: "80px"}}>
                                            <p className="card-text">상품분석기간</p>
                                        </td>
										<td className="event_item_price sorting" onClick={this.onClickSortingHistLogPage} style={{width: "150px"}}>
                                            <p className="card-text">상품 가격</p>
                                        </td>
										<td className="mod_user_id sorting" onClick={this.onClickSortingHistLogPage} style={{width: "150px"}}>
                                            <p className="card-text">작업계정</p>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody style={{display:"block", width:"100%",  height:"calc(57vh - 140px)", overflowX:"hidden", overflowY:"scroll", marginBottom:"20px"}}>
                                    {this.state.hist_list.map((item, idx) => (
                                        <tr className="cursor-pointer">
                                            <td style={{width: "200px"}}>
                                                <p className="card-text">{item.shop_text}</p>
                                            </td>
                                            <td style={{width: "80px"}}>
												<p className="card-text">{item.event_cycle}</p>
                                            </td>
                                            <td style={{width: "80px"}}>
                                                <p className="card-text">{item.event_time}</p>
                                            </td>
											<td style={{width: "80px"}}>
                                                <p className="card-text">{item.event_period}</p>
                                            </td>
											<td style={{width:"120px"}}>
												<p className="card-text">{item.event_banner_pc_width_height}</p>
											</td>
											<td style={{width:"120px"}}>
												<p className="card-text">{item.event_banner_mobile_width_height}</p>
											</td>
											<td style={{width: "120px"}}>
                                                <p className="card-text">{item.event_type_cds_nm}</p>
                                            </td>
											<td style={{width: "120px"}}>
                                                <p className="card-text">{item.event_mobile_type_cds_nm}</p>
                                            </td>
											<td style={{width: "250px"}}>
                                                <p className="card-text">{item.domain_list}</p>
                                            </td>
											<td style={{width: "120px"}}>
                                                <p className="card-text">{item.mod_date}</p>
                                            </td>
											<td style={{width: "80px"}}>
												<p className="card-text">{item.event_item_created_period}</p>
											</td>
											<td style={{width: "80px"}}>
												<p className="card-text">{item.event_item_analysis_period}</p>
											</td>
											<td style={{width: "150px"}}>
												<p className="card-text">{item.event_item_price}</p>
											</td>
											<td style={{width: "150px"}}>
												<p className="card-text">{item.mod_user_id}</p>
											</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            activePage={this.state.hist_log_activePage}
                            itemsCountPerPage={this.state.hist_log_rowcnt}
                            totalItemsCount={this.state.hist_log_totalCnt}
                            pageRangeDisplayed={5}
                            onChange={this.handleHistPageChange.bind(this)}
                        />
                        <div> 
                            <button className="greenButton" style={{display: "block", margin:"auto"}} onClick={this.onClickApplySelectItem}>
                                선택 적용
                            </button>
                        </div>
                    </div>
                    <div className="fa fa-close modalClose"></div>
                </div>

				{/* 팝업창 배경 */}
                <div id="modalBack" style={{top: "0", height:"130%"}}></div>    
			</div>
		);
	}
	
}

import React from 'react';
import http from '../api';
import SaveDialogV2 from '../dialogs/SaveDialogV2';

/**
 * 파일 업로드 : 태그 내부로 요소를 넣고 내부에서 클릭 이벤트가 발생하면 파일 업로드 창이 열립니다
 * @param {*} nonResize 이미지 업로드시 리사이즈 하지 않는 경우 활성화
 * @function getImageInfo 업로드 된 이미지 정보를 받아서 처리할 함수
 */
class UploadFileImagePopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.fileInputRef = React.createRef();
    }

    checkFileExtension = (file) => {
        let reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/i;

        if(String(file[0].name).match(reg)) {
            return true;
        } else {
            return false;
        }
    }

    /** 로딩 다이얼로그 닫기 */
    clearSaveDialog(){
        this.setState({ save_dialog_show_state: false });
    }

    /**
     * 이미지 업로드 팝업 : 업로드 이벤트
     * @param {*} e
     */
    _handleImageChange = (e) => {
        this.attachImage(e.target.files);
    };

    /**
     * 업로드된 이미지 Preview
     * @param {*} acceptedFiles 파일정보
     */
    attachImage(acceptedFiles) {
        if (acceptedFiles) {

            if(!this.checkFileExtension(acceptedFiles)){
                alert("이미지 파일만 첨부할 수 있습니다.");
                return;
            }

            /* Get files in array form */
            const files = Array.from(acceptedFiles);
            //const { getRootProps, getInputProps, isDragActive } = useDropzone({ files });
            /* Map each file to a promise that resolves to an array of image URI's */ 
            Promise.all(files.map(file => {
                return (new Promise((resolve,reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            }))
            .then(images => {
                this.setState({
                    save_dialog_show_state: true,
                    save_dialog_content: "이미지 업로드중 입니다.",
                    save_dialog_button_list: [
                        {
                            title: "확인",
                            type: "1",
                            display_yn:"N",
                        }
                    ]
                });

                this._handleSubmit(files[0]);
            }, error => {        
                console.error(error);
                this.clearSaveDialog();
            });
        }
    }

    // 이미지 업로드 팝업 : 저장 이벤트
    _handleSubmit = (file) => {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        let url = '/rest/shop/roboBanner/save/orgImg';
        if (this.props.nonResize) {
            url = '/rest/shop/roboBanner/save/nooResizeOrgImg'
        }
        const formData = new FormData();
        formData.append("files", file);
        // forEach((file) => formData.append("files", file));
        
        http.post(url, formData, config)
        .then(
            res => {
                // 조회
                this.searchOrgImg(res.data.robo_banner_org_img_seq);
            })
        .catch(
            error => {
                alert(error+"\n관리자에게 문의해 주세요.");
                this.clearSaveDialog();
            }
        );
    };

    /**
     * 
     * @param {*} robo_banner_org_img_seq 이미지 seq
     */
    searchOrgImg(robo_banner_org_img_seq) {
        http.get("/rest/shop/roboBanner/search/orgImg?robo_banner_org_img_seq="+robo_banner_org_img_seq)
        .then(res => {
            let upload_img = res.data["list"][0];
            this.clearSaveDialog();
            this.props.getImageInfo(upload_img);
        })
        .catch(error => {
            alert(error+"\n관리자에게 문의해 주세요.");
            this.clearSaveDialog();
        });
    }

    render() {
        const {children} = this.props;
        return (
            <>
                <input
                    type="file"
                    ref={this.fileInputRef}
                    onChange={e => this._handleImageChange(e)}
                    accept="image/*"
                    value=""
                    style={{display: "none"}}
                    tabIndex="-1"
                >
                </input>
                <div onClick={() => this.fileInputRef.current.click()}>
                    { children }
                </div>

                {/* 배경제거, 이미지 업로드 등 로딩 상태표시 */
                    (this.state.save_dialog_show_state === true)
                    ? <SaveDialogV2
                        content={this.state.save_dialog_content}
                        button_list={this.state.save_dialog_button_list}
                    />
                    : ''
                }
            </>
        );
    }
}


UploadFileImagePopup.defaultProps = {
    nonResize : false,
    getImageInfo : () => {},
}

export default UploadFileImagePopup;
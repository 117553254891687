import React, { useCallback, useState } from 'react';
import RpProductListPopup from '../modules/popup/RpProductListPopup';

import ShareLink from 'react-facebook-share-link'

export class TestFacebookShare extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
        
        }
    }

    render() {
        return (
            <>
                <div id='common-page-wrapper'>
                   <ShareLink link='https://robomd.io'>
                    {link => (
                        <img src="https://w.namu.la/s/475da918f355850479ae4c0703cf4a70e2911a4df7b96f86396a2e30f5cbd3ff29b1b4fed7cbcc2854a27ddff6258cabb8c720321c5bbdac6e0357fbb8628797f726ea6514b1089b2e95950558cd413c6859eae64f8ad1b95bf1a11edba27615" 
                        style={{width:"40px", height:"auto", cursor:"pointer"}}
                        onClick={(e) => {
                            window.open(link, '_blank', 'width=800, height=600')
                        }} />
                    )}
                    </ShareLink>
                </div>
            </>
        )
    }
}

export default TestFacebookShare;
import React from 'react';
import $ from 'jquery';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Doughnut} from 'react-chartjs-2';


export class TrendCurrent extends React.Component {
    constructor(props){
        super(props);

        if (typeof Object.assign != 'function') {
            // Must be writable: true, enumerable: false, configurable: true
            Object.defineProperty(Object, "assign", {
              value: function assign(target, varArgs) { // .length of function is 2
                'use strict';
                if (target == null) { // TypeError if undefined or null
                  throw new TypeError('Cannot convert undefined or null to object');
                }
          
                var to = Object(target);
          
                for (var index = 1; index < arguments.length; index++) {
                  var nextSource = arguments[index];
          
                  if (nextSource != null) { // Skip over if undefined or null
                    for (var nextKey in nextSource) {
                      // Avoid bugs when hasOwnProperty is shadowed
                      if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                        to[nextKey] = nextSource[nextKey];
                      }
                    }
                  }
                }
                return to;
              },
              writable: true,
              configurable: true
            });
          }
    }

    state = {
        settings : {
            // variableWidth:true,
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 2,
            autoplay : true,
            responsive: [ // 반응형 웹 구현 옵션
                {  
                    breakpoint: 960, //화면 사이즈 960px
                    settings: {
                        //위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
                        slidesToShow:3 
                    } 
                },
                { 
                    breakpoint: 639, //화면 사이즈 639px
                    settings: {	
                        //위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
                        slidesToShow:3 
                    } 
                }
            ]
        },

        colorData : {
            labels: [
                'Red',
                'Green',
                'Yellow'
            ],
            datasets: [{
                data: [300, 50, 100],
                backgroundColor: [
                '#FF6384',
                '#00bf00',
                '#FFCE56'
                ],
                hoverBackgroundColor: [
                '#FF6384',
                '#00bf00',
                '#FFCE56'
                ]
            }]
        },
        options: {
            responsive: true, 
            maintainAspectRatio: false
        },
        
    };

    componentDidMount(){
        let slickListDiv = document.getElementsByClassName('slick-list')[0]
        slickListDiv.addEventListener('wheel', event => {
          event.preventDefault()
          event.deltaY > 0 ? this.slider.slickNext() : this.slider.slickPrev()
        })

        // 현재 키워드 색깔 변경 (인스타 색 또는 초록) 
        var mainKeyword = $("#mainKeyword").text()
        var keywordSeletor = $(".keywordRanking div")
        keywordSeletor = Array.prototype.slice.call(keywordSeletor);
        console.log(keywordSeletor)
        keywordSeletor.map(function(elem){
            if (mainKeyword == elem.innerHTML.split(". ")[1]){
                console.log(elem.innerHTML.split(". ")[1]);
                elem.className += "" + "  textColorGradient";
            }
        });
      }

    render() {
        return (
            <div className="body over-hidden trendContainer">
                <div className="height-100 float-left leftTrendBoard leftTrendBoardMobile">
                    <div className="height-100 shadow-box over-auto" style={{height: "calc(100vh - 103px)"}}>
                        <div className="text-center">
                            <div className="padding-t-15" style={{overflow: "hidden"}}>
                                <div style={{fontSize:"8px"}} className="float-left margin-l-20">04.12</div>
                                <div style={{fontSize:"8px"}} className="float-right margin-r-20">04.18</div>
                            </div>
                            <div id="mainKeyword">BLOUSE</div>
                            <div style={{fontSize:"0.7em", marginTop:"4vh", marginBottom:"1.6vh"}}>
                                2020|04.12 - 18
                            </div>

                            <div className="border-bottom-black margin-auto keywordTypeName">
                                POWER <b>TREND</b>
                            </div>
                            <div className="keywordArea">
                                <div>스퀘어넥블라우스</div>
                                <div>러플블라우스</div>
                                <div>퍼블소매블라우스</div>
                                <div>스모크블라우스</div>
                            </div>
                            <div className="border-bottom-black margin-auto keywordTypeName">
                                NEW <b>TREND</b>
                            </div>
                            <div className="keywordArea">
                                <div>빅카라블라우스</div>
                                <div>핀턱블라우스</div>
                                <div>콩단추블라우스</div>
                            </div>
                            <div className="border-bottom-black margin-auto keywordTypeName">
                                MUST <b>TREND</b>
                            </div>
                            <div className="keywordArea">
                                <div>오브숄더블라우스</div>
                                <div>루즈핏블라우스</div>
                                <div>라운드블라우스</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="height-100 rightTrendBoard rightTrendBoardMobile float-right">
                    <div className="products-slick-box shadow-box width-100">
                    <div className="margin-b-auto keywordTypeName" style={{margin:"22px 0 0 8px", fontSize:"2vh"}}>
                        HOT <b>CORDINATION</b>
                    </div> 
                    <div className="keywordTypeName" style={{margin:"0 0 23px 8px", fontSize:"1vh"}}>
                        최근 새로운 영감을 주는 트렌디한 코디상품
                    </div> 
                        <Slider {...this.state.settings}
                        arrows={false}
                        ref={slider => this.slider = slider && slider['innerSlider']}>
                        <div>
                            <div className="product">
                                <div className="thumbnail">
                                    <img src="https://img.otdeal.net/trend/3_2_16_14369.jpg"/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="product">
                                <div className="thumbnail">
                                    <img src="https://img.otdeal.net/trend/5_2_13_86021.jpg"/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="product">
                                <div className="thumbnail">
                                    <img src="https://img.otdeal.net/trend/a44a1d2ffeba348c6e918debfb044ea335f681558c25a6fabd02f11a7094.jpg"/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="product">
                                <div className="thumbnail">
                                    <img src="https://img.otdeal.net/trend/3_2_30_14309.jpg"/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="product">
                                <div className="thumbnail">
                                    <img src="https://img.otdeal.net/trend/5_1_21_102753.jpg"/>
                                </div>
                            </div>
                        </div>
                        
                        </Slider>
                    </div>
                    <div className="width-100 margin-t-10 innerMiddleTrendBoard" style={{fontSize:"2vh"}}>
                        <div className="shadow-box width-50-dis5 margin-r-5 padding-10 innerLeftTrendBoard over-auto" style={{marginBottom:"3px"}}> 
                            <div className="margin-b-auto keywordTypeName" style={{margin:"16px 0 0 0", fontSize:"2vh"}}>
                                TREND <b>COLOR</b>
                            </div> 
                            <div className="keywordTypeName" style={{margin:"0 0 28px 0", fontSize:"1vh"}}>
                            2020|04.12 - 18<br/>
                            가장 인기있는 컬러
                            </div> 
                            <Doughnut data={this.state.colorData} options={this.state.options}/>
                        </div>
                        <div className="innerRightTitleTrendBoard shadow-box width-50-dis5 padding-10" style={{marginBottom:"3px"}}>
                            <div style={{textAlign:"right"}}>
                                <div className="margin-b-auto keywordTypeName width-100" style={{padding:"16px 5px 0 0", fontSize:"2vh", textAlign:"right"}}>
                                    TREND <b>TAG</b>
                                </div> 
                                <div className="keywordTypeName width-100" style={{padding:"3px 5px 28px 0", fontSize:"1vh", textAlign:"right"}}>
                                2020|04.12 - 18<br/>
                                가장 인기있는 태그
                                </div> 
                            </div>
                            <div className="margin-l-5 flexBox-align-start-center over-auto" style={{fontSize:"1.5vh"}}>
                                <div className="tag">#쉬폰블라우스</div>
                                <div className="tag">#시스루블라우스</div>
                                <div className="tag">#셔링랩블라우스</div>
                                <div className="tag">#퍼프블라우스</div>
                                <div className="tag">#쉬폰블라우스</div>
                                <div className="tag">#스트링블라우스</div>
                                <div className="tag">#실크블라우스</div>
                                <div className="tag">#프릴블라우스</div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="shadow-box width-100 height-fit-content padding-r-10 flexBox-no-wrap over-auto">
                        <div className="product">
                            <div className="thumbnail">
                                <img src="https://thumbnail15.coupangcdn.com/thumbnails/remote/230x230ex/image/retail/images/2020/01/10/15/5/5553e83b-e891-448a-b92c-775ba25cba8c.jpg"/>
                            </div>
                            <div className="text-center">#basic</div>
                        </div>
                        <div className="product">
                            <div className="thumbnail">
                                <img src="https://rozley.co.kr/web/product/big/201906/c75335a551dd275ca4341d983a7de186.jpg"/>
                            </div>
                            <div className="text-center">#minimum</div>
                        </div>
                        <div className="product">
                            <div className="thumbnail">
                                <img src="http://www.kstarfashion.com/news/photo/201706/127317_58957_4928.jpg"/>
                            </div>
                            <div className="text-center">#stylelish</div>
                        </div>
                    </div> */}
                </div>  
            </div>
        )
    }
}
import React from 'react';
import axios from 'axios';
import {http, setIsErrorAlert} from '../modules/api';
import {Route, Switch, NavLink, Link} from "react-router-dom";
import Pagination from "react-js-pagination";
import "react-datepicker/dist/react-datepicker.css";
import * as CookieUtil from '../modules/utils/CookieUtil';
import ConfirmDialogV2 from '../modules/dialogs/ConfirmDialogV2';
import $ from 'jquery';
import BasicDialogV2 from "../modules/dialogs/BasicDialogV2";
import TitleContentDialogV2 from "../modules/dialogs/TitleContentDialogV2";
import SaveDialogV2 from "../modules/dialogs/SaveDialogV2";
import CheckBox from '../modules/common/CheckBox';
import Button from '../modules/common/Button';
import ImageUploadTypePopup from './component/ImageUploadTypePopup';
import CreateBannerPreviewPopup from './component/CreateBannerPreviewPopup';

http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

var is_checkbox_ckick = false;
export class EventSettingList extends React.Component {
	state = {
			event_setting_list: [],
			checked_auto_setting_list: [], // 선택된 배너 설정 목록
			event_list: [],
			iframe_page_id: "",
			iframe_html_id: "",
			orderGubun: "",
			sorting: "",
			currentPage: "",
			start_date: "",
			end_date: "",
			start_date_format: "",
			end_date_format: "",
			title: "",
			activePage: 1,
			totalCnt: 0,
			startIdx: 0,
			rowcnt:10,
			type:"",
			display_yn: "",
			item_user_type: "",
			delete_dialog_show_state : false, // 삭제 여부 물어보기
			delete_dialog_content: "",
			delete_dialog_button_list: [],
			modal_auto_setting_show_state : false, // 배너 설정 삭제시 배너 목록 보여주기 모달창 열기
			modal_auto_setting_flag: 0,	// 배너 설정 삭제시 배너 목록 보여주기 모달창 구분 (1: 배너 다중 삭제, 2: 배너 단일 삭제)
			modal_iframe_page_id: "",
			modal_iframe_html_id: "",
			modal_auto_setting_obj : [],
			notice_delete_dialog_show_state : false, // 비활성화 삭제 버튼 클릭시 알림
			notice_delete_dialog_content: "",
			notice_delete_dialog_button_list: [],
			one_click_UUID : "",
	}

	handleText = (e, key) => {
	    // computed property
		this.setState({[key]: e.target.value});
	}

	componentWillMount() {
		const solution_type_cd = CookieUtil.getCookie("solution_type_cd");
		this.setState({solution_type_cd : solution_type_cd});
	}
	
	componentDidMount(e) {
		// console.log(localStorage.getItem ('state'))
		// if (localStorage.getItem ('state') != null){
		// 	new Promise( ( resolve, reject ) => {setTimeout( resolve, 200 ); })
		// 	.then( () => {
		// 		var json = JSON.parse(localStorage.getItem ('state'));
		// 		json.qna_list = [];
		// 		json.start_date = Date.parse(json.start_date_format);
		// 		json.end_date = Date.parse(json.end_date_format);
		// 		this.setState(json);
		// 		// this.setState({qna_list: []});
		// 		this.state.startIdx = parseInt(localStorage.getItem("startIdx"));
		// 		console.log(localStorage.getItem ('state'));
		// 		console.log(JSON.parse(localStorage.getItem ('state')));
		// 		console.log(this.state.title);
		// 		localStorage.clear();
		// 		this.getTaxRequestList();
		// 	});
		// } else {
			// this.getQnaTypeList();
			this.getEventSettingList();
		// }
	}

	componentDidUpdate(e) {
		// console.log("######### componentDidUpdate ##########")
	}

	onComponentChange = e => {
		// console.log("######### onComponentChange ##########")
	}

	componentWillUnmount = e => {
		// console.log("######### componentWillUnmount ##########")
	}
	
	//1. 목록 조회
	async getEventSettingList() {
		await http.get("/rest/shop/list/event/setting?start_date="+this.state.start_date_format+"&end_date="+this.state.end_date_format+"&title="+this.state.title
		+"&order_type="+this.state.sorting+"&order_gubun="+this.state.orderGubun+"&startidx="+this.state.startIdx+"&rowcnt="+this.state.rowcnt+"&qna_type_cd="+this.state.type+"&group_by_yn=Y")
		.then(res => {
			if (res.data != null && res.data != '') {
				// disabled_del : 삭제 불가능한 설정
				let list = res.data["list"].map((setting) => (setting.pay_operated_yn == "Y" || (setting.free_operated_yn == "Y" && setting.install_yn == "Y")) ? {...setting,disabled_del:true} : setting);
				this.setState({
					event_setting_list : list,
					totalCnt : res.data["totalCnt"],
					checked_auto_setting_list: [],
				});
				// console.log(this.state.totalCnt)
				// console.log ("===== res =====");
				// console.log(res);
				// for(var idx=0; idx<=res.data["list"].length; idx++) {
				// 	this.setState({
				// 		["auto_create_yn_"+res.data["list"][idx].iframe_page_id+"_"+res.data["list"][idx].iframe_html_id]: res.data["list"][idx].auto_create_yn
				// 	})
				// }
			}
			// if (res.indexOf("status code 404") > 0 ) {
			// 	window.location.href = "/login"
			// } 
		})
		.catch(error => {
			// console.log ("===== error =====");
			// console.log (error);
			// console.log ("===== error request =====");
			// console.log(error.request);
		 });
    }
	
	//2. 상세 조회
	handleClick(iframe_page_id, iframe_html_id) {
		if (is_checkbox_ckick) {
			is_checkbox_ckick = false;
			return false;
		}

		this.props.history.push({
			pathname: "/shop/cafe24/event/setting",
			search: "?iframe_page_id="+iframe_page_id+"&iframe_html_id="+iframe_html_id
			// state: {prevPath: window.location.href}
		});
		localStorage.setItem ( 'state' , JSON.stringify(this.state) ) ;
		localStorage.setItem ( 'startIdx' , this.state.startIdx ) ;
	};
	
	// 3. 정렬
	onClickSorting = (e) => {
		// if (this.state.orderGubun == e.target.className.replace(" sorting","")) {
		// 	if (this.state.sorting == "ASC")
		// 	this.state.sorting = "DESC";
		// 	else
		// 	this.state.sorting = "ASC";
		// } else {
		// 	this.state.sorting = "DESC";
		// }
		// this.state.orderGubun = e.target.className.replace(" sorting","");
		// this.state.currentPage = 1;
		// this.getEventSettingList()
	};

	//6.페이징
	handlePageChange(pageNumber) {
		// console.log(`active page is ${pageNumber}`);
		this.setState({activePage: pageNumber});
		this.state.startIdx = (pageNumber * 10) - this.state.rowcnt;
		// console.log("this.state.startIdx :" + this.state.startIdx )
		this.getEventSettingList()
	  }  

	_handleChange = (e, key) => {
	    // computed property
	    this.setState({[key]: e.target.value});
	};

	componentWillReceiveProps(nextProps, nextContext) {
		// console.log(nextProps);
		this.getEventSettingList(nextProps.match.params.id);
	}

	onClickEventSettingCreateYn = (e, iframe_page_id, iframe_html_id, name, state, banner_operated_yn, install_yn) => {
		is_checkbox_ckick =  true;

		// if(install_yn != "Y") {
		// 	alert("배너를 진열하려면 고객님 쇼핑몰에 스크립트를 설치해야 합니다. 화면 왼쪽 아래 채팅 아이콘을 눌러 로보MD팀에게 설치를 신청해 주세요.");
		// 	return false;
		// }
		if (state == "Y" && banner_operated_yn == "N") {
			alert("자동 생성 기능은 멤버십 결제 후 이용하실 수 있습니다. 결제 후 생성하기로 변경해 주세요.");
			return false;
		}
		this.setState({
			[name]: state,
		});
		this.updateAutoCreateYn(iframe_page_id, iframe_html_id, state);
	}

	updateAutoCreateYn (iframe_page_id, iframe_html_id, auto_create_yn) {
		const url = '/rest/shop/event/setting/autoCreateYn';
		const data = {
						'iframe_page_id':iframe_page_id,
						'iframe_html_id':iframe_html_id,
						'auto_create_yn':auto_create_yn
					}
		const config = {
			headers: {
				'content-type': 'application/json;'
			}
		}
		http.post(url, data, config);
	}

	// 설치 요청하기 버튼 클릭
	onClickInstallRequest(){
		this.props.history.push(
            {
                pathname: `/shop/qna/form`,
                state: {qna_type_cd: "2105"}
            }	
        )
	}

	// 배너 설정 전체 선택
	checkAllAutoSettingListOnChange(e) {
		this.setState({
			checked_auto_setting_list: e.target.checked ? JSON.parse(JSON.stringify(this.state.event_setting_list)) : [],
		});
	}

	// 배너 설정 개별 선택
	checkOneAutoSettingListOnChange(e, setting) {
		const {event_setting_list, checked_auto_setting_list} = this.state;

		if(e.target.checked){ // 체크되는 상태라면 기존 체크 리스트에 값 추가
            this.setState({ checked_auto_setting_list : [...checked_auto_setting_list, event_setting_list.find(obj => obj.iframe_page_id == setting.iframe_page_id && obj.iframe_html_id == setting.iframe_html_id)] });
        } else{ // 체크가 해제되는 상태라면 기존 체크 리스트에서 값 제거
            this.setState({ checked_auto_setting_list : checked_auto_setting_list.filter(obj => !(obj.iframe_page_id == setting.iframe_page_id && obj.iframe_html_id == setting.iframe_html_id)) });
        }
	}

	// 배너 설정 다중 삭제시 배너 목록 보여주기
	deleteMultiAutoSettingListOnClick() {
		const {event_setting_list, checked_auto_setting_list} = this.state;

		// 체크에 삭제 불가능한 설정이 포함된 경우
		if(checked_auto_setting_list.find(obj => obj.disabled_del)){
			this.noticeDeleteAutoSettingListOnClick();
			return false;
		}

		let delete_auto_setting_list = JSON.parse(JSON.stringify(checked_auto_setting_list));

		for(var idx in delete_auto_setting_list){
			console.log(delete_auto_setting_list[idx]);
		}

		if(delete_auto_setting_list != null && delete_auto_setting_list.length > 0){
			this.getEventListForDeleteAutoSettingList(delete_auto_setting_list);
			this.setState({
				modal_auto_setting_flag : 1,
				modal_auto_setting_show_state: true
			});

		}else{
			alert("삭제할 배너 설정을 선택해주세요.");
		}
	}

	// 배너 설정 다중 삭제, 배너 목록 삭제
	deleteMultiAutoSettingListAndAutoEventOnClick(e) {
		const {event_setting_list, checked_auto_setting_list} = this.state;
		let delete_auto_setting_list = JSON.parse(JSON.stringify(checked_auto_setting_list));

		for(var idx in delete_auto_setting_list){
			console.log(delete_auto_setting_list[idx]);
		}

		const config = {
			headers: {'Content-Type': 'application/json'}
		};
		const url = '/rest/shop/event/setting/list/delete';
		const body = {
			list : delete_auto_setting_list
		};

		if (delete_auto_setting_list != null && delete_auto_setting_list.length > 0) {
			this.setState({
				delete_dialog_show_state: true,
				delete_dialog_content: "정말 삭제하시겠습니까?",
				delete_dialog_button_list: [
					{
						title: "확인",
						type: "1",
						event_method: () => {
							http
								.post(url, body, config)
								.then((res) => {
									if (res.data.code == "200") {
										this.setState({
											delete_dialog_show_state: false,
											modal_auto_setting_show_state: false,
											modal_auto_setting_flag : 0,
											checked_auto_setting_list: [],
										}, () => {

											this.setState({
												basic_dialog_show_state : true,
												basic_dialog_content: "삭제가 완료되었습니다.",
												basic_dialog_button_list: [
													{
														title: "확인",
														type: "1",
														event_method: () => {
															//팝업 닫기
															this.setState({ basic_dialog_show_state: false });
														}
													}
												],
											});
											this.getEventSettingList();
										});

									} else if (res.data.code == "405" || res.data.code == "400") {
										alert(res.data.msg);
										this.setState({
											delete_dialog_show_state: false
										});

									} else {
										alert("삭제에 실패하였습니다.");
										this.setState({
											delete_dialog_show_state: false
										});
									}
								})
								.catch((error) => {
									alert("삭제에 실패하였습니다.");
									this.setState({
										delete_dialog_show_state: false
									});
									console.log(error);
								});
						}
					},
					{
						title: "취소",
						type: "0",
						event_method: () => {
							this.setState({
								delete_dialog_show_state: false
							});
						}
					}
				]
			});
		}else {
			alert("삭제할 배너 설정을 선택해주세요.");
		}
	}

	// 배너 설정 개별 삭제시 배너 목록 보여주기
	deleteOneAutoSettingListOnClick(e, obj) {
		let iframe_page_id = obj.iframe_page_id;
		let iframe_html_id = obj.iframe_html_id;

		console.log("iframe_page_id: " + iframe_page_id)
		console.log("iframe_html_id: " + iframe_html_id)

		// 배너 목록 불러오기
		this.getEventList(iframe_page_id, iframe_html_id);
		this.setState({
			modal_auto_setting_flag: 2,
			modal_auto_setting_show_state: true,
			modal_iframe_page_id: iframe_page_id,
			modal_iframe_html_id: iframe_html_id,
			modal_auto_setting_obj : obj
		});
	}

	// 배너 설정 개별 삭제, 배너 목록 삭제
	deleteOneAutoSettingListAndAutoEventOnClick(e) {
		const {modal_iframe_page_id, modal_iframe_html_id, modal_auto_setting_obj} = this.state;

		let iframe_page_id = modal_iframe_page_id;
		let iframe_html_id = modal_iframe_html_id;

		console.log("iframe_page_id: " + iframe_page_id)
		console.log("iframe_html_id: " + iframe_html_id)

		const config = {
			headers: {'Content-Type': 'application/json'}
		};
		const url = '/rest/shop/event/setting/delete';
		const body = {
			iframe_page_id : iframe_page_id,
			iframe_html_id : iframe_html_id,
			obj : modal_auto_setting_obj
		};

		if (iframe_page_id != null && iframe_page_id != "" && iframe_html_id != null && iframe_html_id != "") {
			this.setState({
				delete_dialog_show_state: true,
				delete_dialog_content: "정말 삭제하시겠습니까?",
				delete_dialog_button_list: [
					{
						title: "확인",
						type: "1",
						event_method: () => {
							http
								.post(url, body, config)
								.then((res) => {
									if (res.data.code == "200") {
										this.setState({
											delete_dialog_show_state: false,
											modal_auto_setting_show_state: false,
											modal_auto_setting_flag: 0,
											checked_auto_setting_list: [],
											modal_iframe_page_id: "",
											modal_iframe_html_id: "",
											iframe_page_id: "",
											iframe_html_id: "",
											modal_auto_setting_obj: []
										}, () => {

											this.setState({
												basic_dialog_show_state : true,
												basic_dialog_content: "삭제가 완료되었습니다.",
												basic_dialog_button_list: [
													{
														title: "확인",
														type: "1",
														event_method: () => {
															//팝업 닫기
															this.setState({ basic_dialog_show_state: false });
														}
													}
												],
											});
											this.getEventSettingList();
										});

									} else if (res.data.code == "405" || res.data.code == "400") {
										alert(res.data.msg);
										this.setState({
											delete_dialog_show_state: false
										});

									} else {
										alert("삭제에 실패하였습니다.");
										this.setState({
											delete_dialog_show_state: false
										});
									}
								})
								.catch((error) => {
									alert("삭제에 실패하였습니다.");
									this.setState({
										delete_dialog_show_state: false
									});
								});
						}
					},
					{
						title: "취소",
						type: "0",
						event_method: () => {
							this.setState({
								delete_dialog_show_state: false
							});
						}
					}
				]
			});
		}
	}

	// 배너 설정 다중 삭제시 배너 목록 조회
	async getEventListForDeleteAutoSettingList(delete_auto_setting_list) {
		const config = {
			headers: {'Content-Type': 'application/json'}
		};
		const url = '/rest/shop/event/list/select';
		const body = {list : delete_auto_setting_list};

		await http
			.post(url, body, config)
			.then((res) => {
				if(res.data.err_code == 401) {
					window.location.href = "/logout";
				}

				if(res.data["list"] != null) {
					this.setState( {event_list : res.data["list"]} );
				}
			})
			.catch((error) => {
				console.log(error)
			});
	}

	// 배너 설정 개별 삭제시 배너 목록 조회
	async getEventList(iframe_page_id, iframe_html_id) {

		await http.get("/rest/common/event/List?start_date="+this.state.start_date_format+"&end_date="+this.state.end_date_format+"&title="+this.state.title
							+"&order_type="+this.state.sorting+"&order_gubun="+this.state.orderGubun+"&iframe_html_id="+iframe_html_id
							+"&event_type_cd="+""+"&event_mobile_type_cd="+""+"&iframe_page_id="+iframe_page_id+"&shop_no=1"
							+"&display_yn="+this.state.display_yn+"&item_user_type="+this.state.item_user_type+"&mod_user_seq="+this.state.mod_user_seq
							+"&my_banner_yn="+this.state.my_banner_yn)
					.then(res => {
						if(res.data.err_code == 401) {
							window.location.href = "/logout";
						}

						if(res.data["list"] != null) {
							this.setState( {event_list : res.data["list"]} );
						}

					})
					.catch(error => {
						console.log (error);
					});
	}

	closeModalRobomdOnClick() {
		this.setState({modal_auto_setting_show_state: false});
	}

	// 비활성화 삭제 버튼 클릭시 알림
	noticeDeleteAutoSettingListOnClick() {
		this.setState({
			notice_delete_dialog_show_state : true,
			notice_delete_dialog_content: "설치중이거나 결제이용중인 배너 설정은<br/>삭제할 수 없습니다. 삭제를 원하시는 경우,<br/>문의하기 버튼을 눌러주세요.",
			notice_delete_dialog_button_list: [
				{
					title: "문의하기",
					type: "1",
					event_method: () => {
						this.props.history.push(
							{
								pathname: `/shop/qna/form`
							}
						)
					}
				},
				{
					title: "닫기",
					type: "0",
					event_method: () => {
						this.setState({
							notice_delete_dialog_show_state: false,
						});
					}
				}
			],
		});
	}

	/**
	 * 사용 가능한 배너 존재 여부 체크
	 * @param {*} setting 배너 설정
	 */
	checkUseableBanner = (setting_list) => {
		let result_list = [];

		for(let setting of setting_list){
			if(setting.event_banner_pc_width_height > 1  && setting.event_banner_mobile_width_height > 1 && setting.event_type_cds){
				result_list = [...result_list, setting]
			} else if(setting.event_banner_pc_width_height <= 1  && setting.event_banner_mobile_width_height <= 1 && setting.event_mobile_type_cds){
				result_list = [...result_list, setting]
			} else if(setting.event_type_cds && setting.event_mobile_type_cds){
				result_list = [...result_list, setting]
			}
		}
		return result_list;
	}

	/**
	 * 즉시 만들기 버튼 클릭
	 * @param {*} setting 배너 설정
	 */
	onClickjustMakeBanner = (setting_list) => {
		const confirm_setting_list = this.checkUseableBanner(setting_list);
		if(confirm_setting_list.length == 0){
			alert("설정에서 배너 스타일을 선택한 후 사용해 주세요.");
			return false;
		}
		this.setState({
			one_click_event_setting_list : confirm_setting_list,
		}, () => {
			this.getOneClickUUID(() => this.createOneClickImageBanner(56001))
		})
	}

	/**
	 * 이미지로 만들기 버튼 클릭
	 * @param {*} setting 배너 설정
	 */
	openImageUploadTypePopup = (setting_list) => {
		const confirm_setting_list = this.checkUseableBanner(setting_list);
		if(confirm_setting_list.length == 0){
			alert("설정에서 배너 스타일을 선택한 후 사용해 주세요.");
			return false;
		}
		this.setState({
			imageUploadTypePopupShow : true,
			one_click_event_setting_list : confirm_setting_list,
		});
	}

	/**
	 * 원클릭 배너 UUID 발급
	 * @param {*} img_list 이미지 정보 (이미지로 만들기의 경우 사용)
	 * @param {*} type (즉시 만들기 : 56001, 이미지로 만들기 : 56002)
	 */
	async getOneClickUUID(callback) {
		await http.get("/common/create/uuid")
		.then(res => {
			if(res.data.uuid) {
				this.setState({one_click_UUID : res.data.uuid}, () => {
					callback();
				});
			}
		})
		.catch(error => {
			console.log (error);
		});
	}

	/**
	 * 배너 생성하기 : 원클릭 API
	 * @param {*} uuid 원클릭 배너 UUID
	 * @param {*} type (즉시 만들기 : 56001, 이미지로 만들기 : 56002)
	 * @param {*} img_list 이미지 정보 (이미지로 만들기의 경우 사용)
	 */
	createOneClickImageBanner = (type, img_list) => {
		const uuid = this.state.one_click_UUID;
		// 에러 알럿 비활성화
        setIsErrorAlert(false);

		this.setState({
			imageUploadTypePopupShow : false,
			save_dialog_show_state : true,
		});
		const config = {
			headers: {'Content-Type': 'application/json'}
		};
		const url = '/rest/shop/oneclick/auto/event/multi/create/'+type;
		const body = {
			iframe_id_list : this.state.one_click_event_setting_list,
			one_click_event_uuid : uuid,
			img_list : type == "56002" ? img_list : null,
		};
		http
		.post(url, body, config)
		.then((res) => {
			setIsErrorAlert(true);
			
			if (res.data.code == "400") {
				alert("배너 생성에 실패하였습니다.");
				this.setState({
					save_dialog_show_state: false
				});
			} else {
				this.setState({
					save_dialog_show_state : false,
					createBannerPreviewPopupShow : true,
					one_click_event_setting_list : res.data.one_click_event_list
				});
			}
		})
		.catch((error) => {
			this.checkOneClickIsDone(uuid);
		});
	}

	/**
	 * 원클릭 배너 생성 완료 여부 체크
	 * @param {*} uuid 배너 그룹 UUID
	 */
	async checkOneClickIsDone(uuid) {
		setIsErrorAlert(true);

		await http.get("/rest/common/oneclick/multi/isDone/"+uuid)
		.then(res => {
			if(res.data.is_done == "D") {
				this.setState({
					save_dialog_show_state : false,
					createBannerPreviewPopupShow : true,
					one_click_event_setting_list : res.data.one_click_event_list,
				});
			} else if(res.data.is_done == "F") {
				alert("배너 생성에 실패하였습니다.");
				this.setState({
					save_dialog_show_state: false
				});
			} else{
				setTimeout(() => this.checkOneClickIsDone(uuid), 5000);
			}
		})
		.catch(error => {
			alert("배너 생성에 실패하였습니다.");
			this.setState({
				save_dialog_show_state: false
			});
		});
	}

	render() {
		//$(".pageName").text("배너 설정 목록");
		//$(".pageName").text("자동 배너 설정");
		$(".pageName").text("자동 배너 생성");
		return (
			<div id='page-wrapper' className="container eventSettingList" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
				<div id="qna_list_component mobile_bottom_button_area">
					<div style={{display:"flex", flexDirection:"column", marginBottom:"30px"}}>
						<div className="page_title_v2" style={{margin:"auto 0"}}>자동 배너 생성</div>
						{
							this.state.event_setting_list.length == 0
							?	""
							: 	<div style={{display:"flex", justifyContent:"space-between", marginTop:"20px"}}>
									{/* <div className="mobile_page_description" style={{display:"flex", margin:"auto 25px auto 0", fontSize:"15px", color:"#333333"}}><div className="icn_round_blue" style={{fontSize:"13px", textAlign:"center", color:"white", lineHeight:"24px", marginRight:"10px"}}>AI</div><b style={{margin: "auto 0"}}>인공지능 배너설정을 직접 추가해 보세요.</b></div> */}
									
									<div style={{fontSize:"15px", alignSelf:"center"}}>
									인공지능 로보MD가 자동으로 배너를 관리해드릴 수 있도록 자동 배너 설정을 추가하고, 관리해 보세요.
									<br/>
									아래 표의 행을 클릭하시면 배너 설정 상세 페이지로 이동해 설정 값을 수정할 수 있습니다.  
									</div>
								</div>
						}
					</div>
					<div className="filter">
						{/* <div style={{display:"flex", marginBottom: '-12px'}}>
							<div className="input-group m-b" style={{width:"66%"}}>
								<div style={{width: "100%"}}> 
								<label>날짜</label>
									<div className="input-daterange input-group" id="datepicker">
										<DatePicker name="start_date" id="start_date"
											selected={this.state.start_date}
											onSelect={this.handleSelect} //when day is clicked
											onChange={this.handleChangeStartDate} //only when value has changed
											// showTimeSelect
											dateFormat="yyyy-MM-dd"
											autoComplete='off'
										/>
										<div className="input-group-addon" style={{width: "40px",height:"35px", lineHeight: "34px",textAlign:"center"}}>to</div>
										<DatePicker name="end_date" id="end_date"
											selected={this.state.end_date}
											onSelect={this.handleSelect} //when day is clicked
											onChange={this.handleChangeEndDate} //only when value has changed
											// showTimeSelect
											dateFormat="yyyy-MM-dd"
											autoComplete='off'
										/>	
									</div>
								</div>
							</div>
							<div className="input-group m-b" style={{width:"33%", marginLeft:"10px"}}>
								<label>종류</label>
								<select id="qna_type" style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px", marginBottom:"10px"}}
								value={this.state.type} onChange={(e)=>this._handleChange(e, 'type')} >
									<option value="">
										전체
									</option>
									{this.state.qna_type.map((qna, idx) => (	
										<option value={qna.cd_code} key={idx}>
											{qna.cd_desc}
										</option>								
									))};
								</select>
							</div>
						</div> */}
						{/* <div style={{display:"flex", marginBottom: '12px'}}>
							<input type="text" className="border_form_radius_b_non_padding" onChange={(e) => {
								// console.log(e.target.value)
								this.setState({ title: e.target.value })
							}} placeholder="검색어를 입력하세요." style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px"}} onKeyDown={this._handleKeyDown}
							value={this.state.title}></input>
							<button className = "btn-auto-b" onClick={this.onClickSearchListButton} style={{width: "80px",height: "35px", margin: 'auto'}}>검색</button>
						</div> */}
					</div>
					<div className={"table_function_area"}>
						<div className={"table_fuction_left_area"}>
							<NavLink
								to={{
									pathname: `/shop/cafe24/event/setting`,
									// state: {prevPath: window.location.href}
								}}
							>
								<Button width={146} height={36} fontSize={13}>
									<div className={"event_setting_add_btn"}>
										<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 8C14 8.13261 13.9473 8.25979 13.8536 8.35355C13.7598 8.44732 13.6326 8.5 13.5 8.5H8.5V13.5C8.5 13.6326 8.44732 13.7598 8.35355 13.8536C8.25979 13.9473 8.13261 14 8 14C7.86739 14 7.74021 13.9473 7.64645 13.8536C7.55268 13.7598 7.5 13.6326 7.5 13.5V8.5H2.5C2.36739 8.5 2.24021 8.44732 2.14645 8.35355C2.05268 8.25979 2 8.13261 2 8C2 7.86739 2.05268 7.74021 2.14645 7.64645C2.24021 7.55268 2.36739 7.5 2.5 7.5H7.5V2.5C7.5 2.36739 7.55268 2.24021 7.64645 2.14645C7.74021 2.05268 7.86739 2 8 2C8.13261 2 8.25979 2.05268 8.35355 2.14645C8.44732 2.24021 8.5 2.36739 8.5 2.5V7.5H13.5C13.6326 7.5 13.7598 7.55268 13.8536 7.64645C13.9473 7.74021 14 7.86739 14 8Z" fill="#333333"/></svg>
										배너 설정 추가하기
									</div>
								</Button>
							</NavLink>
							<NavLink
								to={{
									pathname: `/shop/cafe24/event/setting/popup`,
									// state: {prevPath: window.location.href}
								}}
							>
								<Button width={146} height={36} fontSize={13}>
									<div className={"event_setting_add_btn"}>
										<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 8C14 8.13261 13.9473 8.25979 13.8536 8.35355C13.7598 8.44732 13.6326 8.5 13.5 8.5H8.5V13.5C8.5 13.6326 8.44732 13.7598 8.35355 13.8536C8.25979 13.9473 8.13261 14 8 14C7.86739 14 7.74021 13.9473 7.64645 13.8536C7.55268 13.7598 7.5 13.6326 7.5 13.5V8.5H2.5C2.36739 8.5 2.24021 8.44732 2.14645 8.35355C2.05268 8.25979 2 8.13261 2 8C2 7.86739 2.05268 7.74021 2.14645 7.64645C2.24021 7.55268 2.36739 7.5 2.5 7.5H7.5V2.5C7.5 2.36739 7.55268 2.24021 7.64645 2.14645C7.74021 2.05268 7.86739 2 8 2C8.13261 2 8.25979 2.05268 8.35355 2.14645C8.44732 2.24021 8.5 2.36739 8.5 2.5V7.5H13.5C13.6326 7.5 13.7598 7.55268 13.8536 7.64645C13.9473 7.74021 14 7.86739 14 8Z" fill="#333333"/></svg>
										팝업 설정 추가하기
									</div>
								</Button>
							</NavLink>
							<Button type={"hover_white"} width={100} height={36} fontSize={13} onClick={() => this.onClickInstallRequest()}>설치 요청하기</Button>
						</div>
						<div className={"table_fuction_right_area"}>
							<div className={"search_area"}>
								<div className={"search_bar"}>
									<input value={this.state.title} onChange={(e) => {this.setState({title:e.target.value})}} onKeyDown={(e) => {e.key === "Enter" && this.getEventSettingList()}} type='text' placeholder='배너 명칭으로 검색해 보세요.'/>
									<button>
										<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9453 17.0547L14.5625 13.6797C15.7564 12.2583 16.3555 10.4308 16.2348 8.57844C16.114 6.72607 15.2828 4.99182 13.9146 3.73738C12.5463 2.48294 10.7465 1.80518 8.89063 1.84545C7.03477 1.88571 5.2661 2.6409 3.9535 3.9535C2.6409 5.2661 1.88571 7.03477 1.84545 8.89063C1.80518 10.7465 2.48294 12.5463 3.73738 13.9146C4.99182 15.2828 6.72607 16.114 8.57844 16.2348C10.4308 16.3555 12.2583 15.7564 13.6797 14.5625L17.0547 17.9453C17.1741 18.0608 17.3338 18.1254 17.5 18.1254C17.6662 18.1254 17.8258 18.0608 17.9453 17.9453C18.0627 17.8268 18.1285 17.6668 18.1285 17.5C18.1285 17.3332 18.0627 17.1732 17.9453 17.0547ZM3.125 9.0625C3.125 7.88817 3.47323 6.74022 4.12565 5.7638C4.77807 4.78738 5.70538 4.02636 6.79032 3.57696C7.87525 3.12757 9.06909 3.00999 10.2208 3.23909C11.3726 3.46819 12.4306 4.03368 13.2609 4.86405C14.0913 5.69443 14.6568 6.75239 14.8859 7.90415C15.115 9.05591 14.9974 10.2497 14.548 11.3347C14.0986 12.4196 13.3376 13.3469 12.3612 13.9993C11.3848 14.6518 10.2368 15 9.0625 15C7.48841 14.9979 5.97938 14.3717 4.86634 13.2587C3.75329 12.1456 3.12707 10.6366 3.125 9.0625Z" fill="#cccccc"/></svg>
									</button>
								</div>
								<Button type={"hover_grey"} width={"56px"} height={"36px"} fontSize={"13px"} onClick={() => this.getEventSettingList()}>검색</Button>
							</div>
							<div className={"search_bar_btn_area"}>
								<Button type={"hover_white"} width={"80px"} height={"36px"} fontSize={"13px"} onClick={() => this.deleteMultiAutoSettingListOnClick()}>선택 삭제</Button>
								<Button type={"hover_white"} width={100} height={36} fontSize={13} onClick={() => this.openImageUploadTypePopup(this.state.checked_auto_setting_list)}>선택 일괄 생성</Button>
							</div>
						</div>
					</div>
					<div className="warning_del_auto_setting">
						<p><i className="fa fa-exclamation-circle" aria-hidden="true"></i>&nbsp;설치중이거나 결제이용중인 배너 설정은 삭제할 수 없습니다.</p>
					</div>
					<div className="table table_V2" style={{marginBottom:0}}>
						<div className="table" style={{marginBottom:0}}>
							<table className={["event_setting_list_table", (this.state.solution_type_cd == "09900" && "non_connection")].join(' ')}>
								<colgroup>
									{/* <col width="70px" />
									<col width="70px" />
									<col width="100px" />
									<col width="70px" />
									<col width="70px" />
									<col width="100px" />
									<col width="100px" />
									<col width="100px" />
									<col width="200px" />
									<col width="100px" />
									<col width="100px" /> */}
									{/* <col width="150px" /> */}
								</colgroup>
								<thead>	
									<tr>
										<td class="use_pointer_events">
											<CheckBox
												id={"list_auto_setting_checkbox_all"}
												checked={this.state.checked_auto_setting_list.length > 0 && (this.state.checked_auto_setting_list.length == this.state.event_setting_list.length)}
												onChangeCheck={e => this.checkAllAutoSettingListOnChange(e)}
											/>
										</td>
										<td className="user_id" onClick={this.onClickSorting} style={{marginLeft:"10px"}}>
											<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>배너명칭
											{/* {(() => {
												if (this.state.orderGubun == "user_id") {
													if(this.state.sorting == "ASC") {
														return ( 
															<>
																<div className="icn_table_v2_sort_desc_arrow_d"></div>
																<div className="icn_table_v2_sort_asc_arrow_b"></div> 
															</>
														)
													} else if (this.state.sorting == "DESC") {
														return ( 
															<>
																<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																<div className="icn_table_v2_sort_asc_arrow_d"></div>
															</>
														)
													} else {
														return ( 
															<>
																<div className="icn_table_v2_sort_desc_arrow_d"></div>
																<div className="icn_table_v2_sort_asc_arrow_d"></div> 
															</>
														)
													}
												} else {
													return ( 
														<>
																<div className="icn_table_v2_sort_desc_arrow_d"></div>
																<div className="icn_table_v2_sort_asc_arrow_d"></div> 
															</>
													)
												}
											})()} */}
											</p>
										</td>
										<td className="corp_reg_no" onClick={this.onClickSorting}>
											<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>페이지
											{/* {(() => {
												if (this.state.orderGubun == "corp_reg_no") {
													if(this.state.sorting == "ASC") {
														return ( 
															<>
																<div className="icn_table_v2_sort_desc_arrow_d"></div>
																<div className="icn_table_v2_sort_asc_arrow_b"></div> 
															</>
														)
													} else if (this.state.sorting == "DESC") {
														return ( 
															<>
																<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																<div className="icn_table_v2_sort_asc_arrow_d"></div>
															</>
														)
													} else {
														return ( 
															<>
																<div className="icn_table_v2_sort_desc_arrow_d"></div>
																<div className="icn_table_v2_sort_asc_arrow_d"></div> 
															</>
														)
													}
												} else {
													return ( 
														<>
																<div className="icn_table_v2_sort_desc_arrow_d"></div>
																<div className="icn_table_v2_sort_asc_arrow_d"></div> 
															</>
													)
												}
											})()} */}
											</p>
										</td>
										<td className="corp_name" onClick={this.onClickSorting} >
											<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>배너사이즈(px)
											{/* {(() => {
												if (this.state.orderGubun == "corp_name") {
													if(this.state.sorting == "ASC") {
														return ( 
															<>
																<div className="icn_table_v2_sort_desc_arrow_d"></div>
																<div className="icn_table_v2_sort_asc_arrow_b"></div> 
															</>
														)
													} else if (this.state.sorting == "DESC") {
														return ( 
															<>
																<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																<div className="icn_table_v2_sort_asc_arrow_d"></div>
															</>
														)
													} else {
														return ( 
															<>
																<div className="icn_table_v2_sort_desc_arrow_d"></div>
																<div className="icn_table_v2_sort_asc_arrow_d"></div> 
															</>
														)
													}
												} else {
													return ( 
														<>
																<div className="icn_table_v2_sort_desc_arrow_d"></div>
																<div className="icn_table_v2_sort_asc_arrow_d"></div> 
															</>
													)
												}
											})()} */}
											</p>
										</td>
										<td className="business_type non_connection_hide" onClick={this.onClickSorting}>
											<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>생성주기
											{/* {(() => {
												if (this.state.orderGubun == "business_type") {
													if(this.state.sorting == "ASC") {
														return ( 
															<>
																<div className="icn_table_v2_sort_desc_arrow_d"></div>
																<div className="icn_table_v2_sort_asc_arrow_b"></div> 
															</>
														)
													} else if (this.state.sorting == "DESC") {
														return ( 
															<>
																<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																<div className="icn_table_v2_sort_asc_arrow_d"></div>
															</>
														)
													} else {
														return ( 
															<>
																<div className="icn_table_v2_sort_desc_arrow_d"></div>
																<div className="icn_table_v2_sort_asc_arrow_d"></div> 
															</>
														)
													}
												} else {
													return ( 
														<>
																<div className="icn_table_v2_sort_desc_arrow_d"></div>
																<div className="icn_table_v2_sort_asc_arrow_d"></div> 
															</>
													)
												}
											})()} */}
											</p>
										</td>
										<td className="business_item non_connection_hide" onClick={this.onClickSorting}>
											<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>생성시간
											{/* {(() => {
												if (this.state.orderGubun == "business_item") {
													if(this.state.sorting == "ASC") {
														return ( 
															<>
																<div className="icn_table_v2_sort_desc_arrow_d"></div>
																<div className="icn_table_v2_sort_asc_arrow_b"></div> 
															</>
														)
													} else if (this.state.sorting == "DESC") {
														return ( 
															<>
																<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																<div className="icn_table_v2_sort_asc_arrow_d"></div>
															</>
														)
													} else {
														return ( 
															<>
																<div className="icn_table_v2_sort_desc_arrow_d"></div>
																<div className="icn_table_v2_sort_asc_arrow_d"></div> 
															</>
														)
													}
												} else {
													return ( 
														<>
																<div className="icn_table_v2_sort_desc_arrow_d"></div>
																<div className="icn_table_v2_sort_asc_arrow_d"></div> 
															</>
													)
												}
											})()} */}
											</p>
										</td>
										<td className="representative_name non_connection_hide" onClick={this.onClickSorting}>
											<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>진열기간
											{/* {(() => {
												if (this.state.orderGubun == "representative_name") {
													if(this.state.sorting == "ASC") {
														return ( 
															<>
																<div className="icn_table_v2_sort_desc_arrow_d"></div>
																<div className="icn_table_v2_sort_asc_arrow_b"></div> 
															</>
														)
													} else if (this.state.sorting == "DESC") {
														return ( 
															<>
																<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																<div className="icn_table_v2_sort_asc_arrow_d"></div>
															</>
														)
													} else {
														return ( 
															<>
																<div className="icn_table_v2_sort_desc_arrow_d"></div>
																<div className="icn_table_v2_sort_asc_arrow_d"></div> 
															</>
														)
													}
												} else {
													return ( 
														<>
																<div className="icn_table_v2_sort_desc_arrow_d"></div>
																<div className="icn_table_v2_sort_asc_arrow_d"></div> 
															</>
													)
												}
											})()} */}
											</p>
										</td>
										<td className="email" onClick={this.onClickSorting}>
											<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>배너노출고객
											{/* {(() => {
												if (this.state.orderGubun == "email") {
													if(this.state.sorting == "ASC") {
														return ( 
															<>
																<div className="icn_table_v2_sort_desc_arrow_d"></div>
																<div className="icn_table_v2_sort_asc_arrow_b"></div> 
															</>
														)
													} else if (this.state.sorting == "DESC") {
														return ( 
															<>
																<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																<div className="icn_table_v2_sort_asc_arrow_d"></div>
															</>
														)
													} else {
														return ( 
															<>
																<div className="icn_table_v2_sort_desc_arrow_d"></div>
																<div className="icn_table_v2_sort_asc_arrow_d"></div> 
															</>
														)
													}
												} else {
													return ( 
														<>
																<div className="icn_table_v2_sort_desc_arrow_d"></div>
																<div className="icn_table_v2_sort_asc_arrow_d"></div> 
															</>
													)
												}
											})()} */}
											</p>
										</td>
										{/* <td className="email sorting" onClick={this.onClickSorting}>
											<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>배너크기유형 */}
											{/* {(() => {
												if (this.state.orderGubun == "email") {
													if(this.state.sorting == "ASC") {
														return ( 
															<>
																<div className="icn_table_v2_sort_desc_arrow_d"></div>
																<div className="icn_table_v2_sort_asc_arrow_b"></div> 
															</>
														)
													} else if (this.state.sorting == "DESC") {
														return ( 
															<>
																<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																<div className="icn_table_v2_sort_asc_arrow_d"></div>
															</>
														)
													} else {
														return ( 
															<>
																<div className="icn_table_v2_sort_desc_arrow_d"></div>
																<div className="icn_table_v2_sort_asc_arrow_d"></div> 
															</>
														)
													}
												} else {
													return ( 
														<>
																<div className="icn_table_v2_sort_desc_arrow_d"></div>
																<div className="icn_table_v2_sort_asc_arrow_d"></div> 
															</>
													)
												}
											})()} */}
											{/* </p>
										</td> */}
										<td className="email" onClick={this.onClickSorting}>
											<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>디자인선택수
											{/* {(() => {
												if (this.state.orderGubun == "email") {
													if(this.state.sorting == "ASC") {
														return ( 
															<>
																<div className="icn_table_v2_sort_desc_arrow_d"></div>
																<div className="icn_table_v2_sort_asc_arrow_b"></div> 
															</>
														)
													} else if (this.state.sorting == "DESC") {
														return ( 
															<>
																<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																<div className="icn_table_v2_sort_asc_arrow_d"></div>
															</>
														)
													} else {
														return ( 
															<>
																<div className="icn_table_v2_sort_desc_arrow_d"></div>
																<div className="icn_table_v2_sort_asc_arrow_d"></div> 
															</>
														)
													}
												} else {
													return ( 
														<>
																<div className="icn_table_v2_sort_desc_arrow_d"></div>
																<div className="icn_table_v2_sort_asc_arrow_d"></div> 
															</>
													)
												}
											})()} */}
											</p>
										</td>
										<td>
											<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>노출 유형</p>
										</td>
										<td>
											<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>슬라이드 여부</p>
										</td>
										<td className="reg_date" onClick={this.onClickSorting}>
											<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>등록일</p>
										</td>
										<td className="reg_date" onClick={this.onClickSorting}>
											<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>설치 상태</p>
										</td>
										<td className="email" style={{width:"120px"}} onClick={this.onClickSorting}>
											<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>이용 상태</p>
										</td>
										<td className="email non_connection_hide" onClick={this.onClickSorting} style={{width:"124px", marginRight:"10px"}}>
											<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>자동 배너 생성</p>
										</td>
										<td className="email">
											<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>배너 설정 삭제</p>
										</td>
										<td className="just_make_banner">
											<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>배너 바로 만들기</p>
										</td>
									</tr>
								</thead>
								<tbody style={{height:"auto"}}>
									{this.state.event_setting_list.length == 0 &&
										<tr style={{height:"50vh", display: "flex", alignItems: "center", justifyContent: "center", background:"white"}}>
											<div style={{fontSize:"15px", color:"#333333",textAlign:"center"}}><b>만들어진 배너 설정이 없어요.<br/>배너 설정을 추가하고 이용해 보세요.</b></div>
										</tr>
									}
									{this.state.event_setting_list.map((obj, idx) => (
										// <NavLink
										// style={{color:"white"}}
										// to={{
										// 	pathname: "/shop/cafe24/event/setting",
										// 	search: "?iframe_page_id="+obj.iframe_page_id+"&iframe_html_id="+obj.iframe_html_id
										// 	// state: {prevPath: window.location.href}
										// }}>
										<tr key={obj.iframe_page_id+'_'+obj.iframe_html_id}>
											<td>
												<CheckBox
													id={"list_auto_setting_checkbox_" + (Number(idx))}
													checked={this.state.checked_auto_setting_list.find(auto => auto.iframe_page_id == obj.iframe_page_id && auto.iframe_html_id == obj.iframe_html_id)}
													onChangeCheck={e => this.checkOneAutoSettingListOnChange(e, obj)}
												/>
											</td>
											<td onClick={e=>this.handleClick(obj.iframe_page_id, obj.iframe_html_id)}>
												<p className="card-text">{obj.iframe_html_name}</p>
											</td>
											<td onClick={e=>this.handleClick(obj.iframe_page_id, obj.iframe_html_id)}>
												<p className="card-text">{obj.iframe_page_name}</p>
											</td>
											<td onClick={e=>this.handleClick(obj.iframe_page_id, obj.iframe_html_id)}>
												{obj.event_banner_pc_width == obj.event_banner_mobile_width && obj.event_banner_pc_height == obj.event_banner_mobile_height
												? <p className="card-text">{obj.event_banner_pc_width+":"+obj.event_banner_pc_height}</p>
												: <p className="card-text">{"pc => "+obj.event_banner_pc_width+":"+obj.event_banner_pc_height}<br/>{"mobile => "+obj.event_banner_mobile_width+":"+obj.event_banner_mobile_height}</p>}
											</td>
											<td className={"non_connection_hide"} onClick={e=>this.handleClick(obj.iframe_page_id, obj.iframe_html_id)}>
												<p className="card-text">{obj.event_cycle+"일"}</p>
											</td>
											<td className={"non_connection_hide"} onClick={e=>this.handleClick(obj.iframe_page_id, obj.iframe_html_id)}>
												<p className="card-text">{obj.event_time+"시"}</p>
											</td>
											<td className={"non_connection_hide"} onClick={e=>this.handleClick(obj.iframe_page_id, obj.iframe_html_id)}>
												<p className="card-text">{obj.event_period+"일"}</p>
											</td>
											<td onClick={e=>this.handleClick(obj.iframe_page_id, obj.iframe_html_id)}>
												<p className="card-text">{ obj.event_item_user_type == "15001"
																			? "전체고객 기준"
																			: "회원/비회원 기준"
																			}</p>
											</td>
											{/* <td>
												<p className="card-text">{obj.business_registration_file_name}</p>
											</td> */}
											<td onClick={e=>this.handleClick(obj.iframe_page_id, obj.iframe_html_id)}>
												<p className="card-text event_type_cd_num">
													{((obj.event_banner_pc_width_height && obj.event_banner_pc_width_height > 1) || (obj.event_banner_mobile_width_height && obj.event_banner_mobile_width_height > 1)) &&
														<div>{"가로 "+(obj.event_type_cds ? obj.event_type_cds.split(",").length : 0)+"개"}&nbsp;</div>
													}
													{((obj.event_banner_pc_width_height && obj.event_banner_pc_width_height <= 1) || (obj.event_banner_mobile_width_height && obj.event_banner_mobile_width_height <= 1)) &&
														<div>{"세로 "+(obj.event_mobile_type_cds ? obj.event_mobile_type_cds.split(",").length : 0)+"개"}</div>
													}
												</p>
											</td>
											<td>
												<p className="card-text">{obj.event_show_type == "70001" ? "웹배너" : "팝업"}</p>
											</td>
											<td>
												<p className="card-text">{obj.slide_yn == "Y" ? "여러 이미지" : "단일 이미지"}</p>
											</td>
											<td onClick={e=>this.handleClick(obj.iframe_page_id, obj.iframe_html_id)}>
												<p className="card-text">{obj.reg_date}</p>
											</td>
											<td onClick={e=>this.handleClick(obj.iframe_page_id, obj.iframe_html_id)}>
												{obj.install_yn == "Y" ? "설치 완료" : "미설치"}
											</td>
											<td style={{width:"120px"}} onClick={e => (obj.pay_operated_yn === "Y" || obj.free_operated_yn === "Y") ? this.handleClick(obj.iframe_page_id, obj.iframe_html_id) : ""}>
												<p className="card-text">
													{obj.pay_operated_yn === "Y" ?
														<font style={{textAlign:"center", color:"#10CFC9"}}>결제 이용 중</font>
														:
														obj.free_operated_yn === "Y" ?
															<font style={{textAlign:"center", color:"#10CFC9"}}>무료 체험 중</font>
															:
															obj.request_type == null ?
																<font style={{textAlign:"center", color:"#B7BDC3"}}>무료 체험 종료</font>
																:
																<font style={{textAlign:"center", color:"#B7BDC3"}}>{obj.code_name}</font>

															// <NavLink
															// 	style={{color:"white"}}
															// 	to={{
															// 		pathname: `/shop/orderNew`,
															// 		search: `?service_type=01001&iframe_page_id=`+obj.iframe_page_id+`&iframe_html_id=`+obj.iframe_html_id+"&payment_type="+""
															// 	}}>
															// 	<button className="btn-auto-b" style={{width:"90px", height:"45px", fontSize:"13px"}}>
															// 		이용하기
															// 	</button>
															// </NavLink>
													}
												</p>
											</td>
											{(() => {
													if ((this.state["auto_create_yn_"+obj.iframe_page_id+"_"+obj.iframe_html_id] == null && obj.auto_create_yn == "Y") || this.state["auto_create_yn_"+obj.iframe_page_id+"_"+obj.iframe_html_id] == "Y") {
														return (<td className={"non_connection_hide"} style={{width:"124px"}} onClick={e => this.onClickEventSettingCreateYn(e, obj.iframe_page_id, obj.iframe_html_id, "auto_create_yn_"+obj.iframe_page_id+"_"+obj.iframe_html_id, "N", obj.banner_operated_yn, obj.install_yn)}>
																	<div>
																		<p className="card-text btn_robomd_recomendation_back" style={{width:"100px", height:"40px", position: "inherit"}}>
																			<p className="card-text btn_robomd_recomendation_on_front" style={{width:"80px", height:"40px", lineHeight: "41px", position: "inherit"}}>자동 생성 중</p>
																		</p>
																	</div>
																</td>)
													} else {
														return (<td className={"non_connection_hide"} style={{width:"124px"}} onClick={e => this.onClickEventSettingCreateYn(e, obj.iframe_page_id, obj.iframe_html_id, "auto_create_yn_"+obj.iframe_page_id+"_"+obj.iframe_html_id, "Y", obj.banner_operated_yn, obj.install_yn)}>
																	<div>
																		<p className="card-text btn_robomd_recomendation_back" style={{width:"100px", height:"40px", position: "inherit"}}>
																			<p className="card-text btn_robomd_recomendation_off_front" style={{width:"80px", height:"40px", lineHeight: "41px", position: "inherit", marginLeft:"22px"}}>자동 생성 중지</p>
																		</p>
																	</div>
																</td>	)
													}
											})()}
											<td>
												{obj.disabled_del
												?	<button className="btn_del_auto_setting_disabled" onClick={(e) => {this.noticeDeleteAutoSettingListOnClick(e, obj)}}>삭제</button>
												:	<button className="btn_del_auto_setting" onClick={(e) => {this.deleteOneAutoSettingListOnClick(e, obj)}}>삭제</button>
												}
											</td>
											<td className={"just_make_banner"}>
												<Button
													className={"product_btn non_connection_hide"}
													type={"hover_grey"}
													width={"104px"}
													height={"34px"}
													fontSize={"12px"}
													onClick={() => this.onClickjustMakeBanner([obj])}
												>
													상품으로 만들기
												</Button>
												<Button
													type={"hover_grey"}
													width={"104px"}
													height={"34px"}
													fontSize={"12px"}
													onClick={() => this.openImageUploadTypePopup([obj])}
												>
													이미지로 만들기
												</Button>
											</td>
										</tr>
										// </NavLink>
									))}
								</tbody>
							</table>
						</div>
					</div>
					{
						// this.state.event_setting_list.length <= 10
						// this.state.event_setting_list.length == 0
						true
						? 	""
						:	<div style={{marginTop:"1em"}}>
								<Pagination
									activePage={this.state.activePage}
									itemsCountPerPage={this.state.rowcnt}
									totalItemsCount={this.state.totalCnt}
									pageRangeDisplayed={5}
									onChange={this.handlePageChange.bind(this)}
								/>
							</div>
	  				}
				</div>
				{/* <div style={{width:"100%", display:"flex", justifyContent:"center", marginTop:"50px"}}>
					<NavLink
						style={{color:"white"}}
						to={{
							pathname: `/shop/cafe24/orderAmountExpect`,
							// state: {prevPath: window.location.href}
						}}>
						<button className="btn-auto-b" 
							style={{width:"190px", height:"55px", margin:"auto"}}>
						멤버십 결제하기
						</button>
					</NavLink>
				</div> */}
				{/* 배너 설정 삭제시 배너 목록을 보여주는 모달창 */}
				{this.state.modal_auto_setting_show_state ?
					<div className="modal_robomd modal_auto_setting">
						<div className="wrap_modal_robomd">
							<button className="btn_close_modal_robomd" onClick={(e) => {this.closeModalRobomdOnClick()}}>
								<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M15.2844 14.2156C15.4253 14.3578 15.5043 14.5499 15.5043 14.75C15.5043 14.9501 15.4253 15.1422 15.2844 15.2844C15.1411 15.423 14.9495 15.5005 14.7501 15.5005C14.5507 15.5005 14.359 15.423 14.2157 15.2844L8.00007 9.05938L1.78444 15.2844C1.64109 15.423 1.44948 15.5005 1.25007 15.5005C1.05066 15.5005 0.859049 15.423 0.715693 15.2844C0.574857 15.1422 0.49585 14.9501 0.49585 14.75C0.49585 14.5499 0.574857 14.3578 0.715693 14.2156L6.94069 8L0.715693 1.78438C0.596096 1.63866 0.534977 1.45366 0.544225 1.26537C0.553472 1.07708 0.632425 0.898961 0.765726 0.76566C0.899027 0.632359 1.07715 0.553406 1.26543 0.544158C1.45372 0.534911 1.63872 0.59603 1.78444 0.715627L8.00007 6.94063L14.2157 0.715627C14.3614 0.59603 14.5464 0.534911 14.7347 0.544158C14.923 0.553406 15.1011 0.632359 15.2344 0.76566C15.3677 0.898961 15.4467 1.07708 15.4559 1.26537C15.4652 1.45366 15.404 1.63866 15.2844 1.78438L9.05944 8L15.2844 14.2156Z" fill="#333333"/>
								</svg>
							</button>

							<div className="modal_auto_setting_notice">
								<p>
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M10 0.25C8.07164 0.25 6.18657 0.821828 4.58319 1.89317C2.97982 2.96451 1.73013 4.48726 0.992179 6.26884C0.254225 8.05042 0.061142 10.0108 0.437348 11.9021C0.813554 13.7934 1.74215 15.5307 3.10571 16.8943C4.46928 18.2579 6.20656 19.1865 8.09787 19.5627C9.98919 19.9389 11.9496 19.7458 13.7312 19.0078C15.5127 18.2699 17.0355 17.0202 18.1068 15.4168C19.1782 13.8134 19.75 11.9284 19.75 10C19.7475 7.4149 18.7195 4.93639 16.8916 3.10845C15.0636 1.28051 12.5851 0.252482 10 0.25ZM9.25 5.5C9.25 5.30109 9.32902 5.11032 9.46967 4.96967C9.61033 4.82902 9.80109 4.75 10 4.75C10.1989 4.75 10.3897 4.82902 10.5303 4.96967C10.671 5.11032 10.75 5.30109 10.75 5.5V10.75C10.75 10.9489 10.671 11.1397 10.5303 11.2803C10.3897 11.421 10.1989 11.5 10 11.5C9.80109 11.5 9.61033 11.421 9.46967 11.2803C9.32902 11.1397 9.25 10.9489 9.25 10.75V5.5ZM10 15.25C9.7775 15.25 9.55999 15.184 9.37499 15.0604C9.18998 14.9368 9.04579 14.7611 8.96064 14.5555C8.87549 14.35 8.85321 14.1238 8.89662 13.9055C8.94003 13.6873 9.04718 13.4868 9.20451 13.3295C9.36184 13.1722 9.5623 13.065 9.78053 13.0216C9.99876 12.9782 10.225 13.0005 10.4305 13.0856C10.6361 13.1708 10.8118 13.315 10.9354 13.5C11.059 13.685 11.125 13.9025 11.125 14.125C11.125 14.4234 11.0065 14.7095 10.7955 14.9205C10.5845 15.1315 10.2984 15.25 10 15.25Z" fill="#FF085E"/>
									</svg>
									&nbsp;&nbsp;배너 설정 삭제시 배너 설정에 해당하는 다음의 배너 목록이 모두 삭제됩니다.
								</p>
							</div>

							<h4>배너 목록</h4>

							<div className="modal_auto_setting_table" style={{width: "calc(95vw - 600px)",flex: "1",height: "auto",overflowX: "auto",overflowY: "hidden",marginLeft: "95px"}}>
								<div style={{width: "100%", minWidth: "1100px", height: "100%"}}>
									<div style={{display: "inline-block",height: "100%",minWidth: "1100px",width: "100%"}}>
										<div className="table table_V2" style={{flex: "1", height: "100%"}}>
											<table>
												<thead>
												<tr>
													<td style={{display: "none"}}>
														<p className="card-text">이벤트 번호</p>
													</td>
													<td style={{width: "50px"}}>
														<p className="card-text">순번</p>
													</td>
													<td>
														<p className="card-text" style={{textAlign: "left"}}>제목</p>
													</td>
													{/* <td style={{width:"130px"}}>
														 <p className="card-text">배너 페이지</p>
													  </td>
													  <td style={{width:"130px"}}>
														 <p className="card-text">배너 영역</p>
													  </td> */}
													<td className="event_view_cnt" style={{width: "100px"}}>
														<p className="card-text">클릭수</p>
													</td>
													<td style={{width: "80px"}}>
														<p className="card-text">진열 대상</p>
													</td>
													<td className="start_date" style={{width: "10.5%"}}>
														<p className="card-text">시작일시</p>
													</td>
													<td className="end_date" style={{width: "10.5%"}}>
														<p className="card-text">종료일시</p>
													</td>
													<td className="mod_date" style={{width: "10.5%"}}>
														<p className="card-text">최종수정</p>
													</td>
													<td className="display_yn" style={{width: "115px"}}>
														<p className="card-text">진열 여부</p>
													</td>
												</tr>
												</thead>
												<tbody style={{height:"50vh"}}>
												{this.state.event_list.length > 0
													?
													this.state.event_list.map((event, idx) => (
													<tr key={event.event_seq} className="tbody-tr-hover">
														<td style={{display: "none"}}>
															<p className="card-text">{event.event_seq}</p>
														</td>
														<td style={{width: "50px"}}>
															{/*<p className="card-text">{(this.state.totalCnt - (idx + (10 * (this.state.activePage - 1))))}</p>*/}
															<p className="card-text">{this.state.event_list.length - idx}</p>
														</td>
														<td>
															<p className="card-text" style={{display: "flex",fontSize: "12.5px",textAlign: "left",margin: "auto 0 auto 0"}}>
																{
																	event.mod_user_seq == -1
																		? <>
																			<span style={{color: "#10CFC9", margin: "auto 0"}}><b>자동 배너</b></span>
																			<span style={{flex: "1",overflowX: "hidden",textOverflow: "ellipsis",whiteSpace: "nowrap",margin: "6px"}}>{event.title}</span>
																		</>
																		: event.mod_user_seq == -2
																			? <>
																				<span style={{color: "#5898fe",    margin: "auto 0"}}><b>샘플 배너</b></span>
																				<span style={{flex: "1",overflowX: "hidden",textOverflow: "ellipsis",whiteSpace: "nowrap",margin: "6px"}}>{event.title}</span>
																			</>
																			:
																			event.event_promotion_type_cd != null && event.event_promotion_type_cd == "19002"
																				? <>
																				 	<span style={{color: "RGB(186 172 254)", margin: "auto 0"}}><b>마이 배너</b></span>
																					<span style={{flex: "1",overflowX: "hidden",textOverflow: "ellipsis",whiteSpace: "nowrap",margin: "6px"}}>{event.title}</span>
																				</>
																				:
																				<>
																				 	<span style={{color: "#000080",margin: "auto 0"}}><b>로보 배너</b></span>
																					<span style={{flex: "1",overflowX: "hidden",textOverflow: "ellipsis",whiteSpace: "nowrap",margin: "6px"}}>{event.title}</span>
																				</>
																}
															</p>
														</td>
														{/* <td style={{width:"130px"}}>
																<p className="card-text">{event.iframe_page_id}</p>
															 </td>
															 <td style={{width:"130px"}}>
																<p className="card-text">{event.iframe_html_id}</p>
															 </td> */}
														<td style={{width: "100px"}}>
															<p className="card-text">{event.event_view_cnt}</p>
														</td>
														<td style={{width: "80px"}}>
															<p className="card-text">
																{
																	event.item_user_type == "U"
																		? "회원"
																		: <>
																			{
																				event.item_user_type == "N"
																					? "비회원"
																					: "모두"
																			}
																		</>
																}
															</p>
														</td>
														<td style={{width: "10.5%"}}>
															<p className="card-text">{event.start_date}</p>
														</td>
														<td style={{width: "10.5%"}}>
															<p className="card-text">{event.end_date}</p>
														</td>
														<td style={{width: "10.5%"}}>
															<p className="card-text">{event.mod_date}</p>
														</td>
														{(() => {
																if ((this.state["display_yn_" + event.event_seq] == null && event.display_yn == "Y") || this.state["display_yn_" + event.event_seq] == "Y") {
																	return (<td style={{width: "113px"}}>
																		<p style={{color: "rgb(16, 207, 201)"}}>진열중</p>
																		{/*<p>*/}
																		{/*	<p className="card-text btn_robomd_recomendation_back" style={{width: "92px", height: "40px", position: "inherit"}}>*/}
																		{/*		<p className="card-text btn_robomd_recomendation_on_front" style={{width: "70px",height: "40px",lineHeight: "41px",position: "inherit"}}>진열중</p>*/}
																		{/*	</p>*/}
																		{/*</p>*/}
																	</td>)
																} else if ((this.state["display_yn_" + event.event_seq] == null && event.display_yn == "R") || this.state["display_yn_" + event.event_seq] == "R") {
																	return (<td style={{width: "113px"}}>
																		<p style={{color:"#000"}}>예약중</p>
																		{/*<p>*/}
																		{/*	<p className="card-text btn_robomd_recomendation_back" style={{width: "92px",height: "40px",position: "inherit"}}>*/}
																		{/*		<p className="card-text btn_robomd_recomendation_ready_front" style={{width: "70px",height: "40px",lineHeight: "41px",position: "inherit"}}>예약중</p>*/}
																		{/*	</p>*/}
																		{/*</p>*/}
																	</td>)
																} else {
																	return (<td style={{width: "113px"}}>
																		<p style={{color:"#B7BDC3"}}>미진열</p>
																		{/*<p>*/}
																		{/*	<p className="card-text btn_robomd_recomendation_back" style={{width: "92px",height: "40px",position: "inherit"}}>*/}
																		{/*		<p className="card-text btn_robomd_recomendation_off_front" style={{width: "70px",height: "40px",lineHeight: "41px",position: "inherit",marginLeft: "22px"}}>미진열</p>*/}
																		{/*	</p>*/}
																		{/*</p>*/}
																	</td>)
																}
															}
														)()}
													</tr>))
													:
													<tr>
														<td>배너 설정에 해당하는 배너 목록이 없습니다.</td>
													</tr>
												}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>

							<div className="modal_auto_setting_confirm">
								<p>배너 설정과 배너 목록을 모두 삭제하시겠습니까?</p>

								<div className="wrap_btn_del_auto_event_setting_in_modal">
									<button className="btn_close_auto_event_setting_in_modal" onClick={(e) => {this.setState({modal_auto_setting_show_state: false})}}>취소</button>
									{this.state.modal_auto_setting_flag == 1 ?
										<button className="btn_del_auto_event_setting_in_modal" onClick={(e) => {this.deleteMultiAutoSettingListAndAutoEventOnClick(e)}}>삭제</button>
										:
										<button className="btn_del_auto_event_setting_in_modal" onClick={(e) => {this.deleteOneAutoSettingListAndAutoEventOnClick(e)}}>삭제</button>
									}
								</div>
							</div>
						</div>
					</div>
					:
					""
				}
				<div>
					{this.state.imageUploadTypePopupShow &&
						<ImageUploadTypePopup
                        onClose={() => {this.setState({imageUploadTypePopupShow: false,})}}
						getUploadImageInfoList={(img_list)=> this.getOneClickUUID(() => this.createOneClickImageBanner(56002, img_list))}
                        uploadImageNum={this.state.one_click_event_setting_list.reduce((result, current) => [(result && result > current.max_template_img_cnt) ? result : current.max_template_img_cnt],[])}
                    	/>}
					{this.state.createBannerPreviewPopupShow &&
						<CreateBannerPreviewPopup
                        	onClose={() => {this.setState({createBannerPreviewPopupShow: false,})}}
							one_click_event_list ={this.state.one_click_event_setting_list}
						/>}
					{
						(this.state.delete_dialog_show_state === true)
							? <ConfirmDialogV2
								content={this.state.delete_dialog_content}
								button_list={this.state.delete_dialog_button_list}
								popup_close={() => {
									this.setState({delete_dialog_show_state: false});
								}}
							/>
							: ''
					}
					{
						(this.state.basic_dialog_show_state === true)
							? <BasicDialogV2
								content={this.state.basic_dialog_content}
								button_list={this.state.basic_dialog_button_list}
							/>
							: ''
					}
					{
						(this.state.notice_delete_dialog_show_state === true)
							? <TitleContentDialogV2
								content={this.state.notice_delete_dialog_content}
								button_list={this.state.notice_delete_dialog_button_list}
								popup_close={() => {
									this.setState({notice_delete_dialog_show_state: false});
								}}
							/>
							: ''
					}
					{/* 로딩 상태표시 */
						this.state.save_dialog_show_state
						&& <SaveDialogV2
							content={"배너를 생성하는 중입니다."}
							button_list={[
								{
									title: "확인",
									type: "1",
									display_yn:"N",
								}
							]}
						/>
					}
				</div>
			</div>
		);
	}
}

import React from 'react';
import axios from 'axios';
import http from '../modules/api';
import {Route, Switch, NavLink, Link} from "react-router-dom";
// import {AdminUserDetail} from "./AdminUserDetail";
import $ from 'jquery';
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";

import "react-datepicker/dist/react-datepicker.css";

export class NoticeList extends React.Component {
	state = {
			notice_list: [],
			orderGubun: "",
			sorting: "",
			currentPage: "",
			start_date: "",
			end_date: "",
			start_date_format: "",
			end_date_format: "",
			title: "",
			activePage: 1,
			totalCnt: 0,
			startIdx: 0,
			rowcnt:10
	};

	handleText = (e, key) => {
	    // computed property
		this.setState({[key]: e.target.value});
	}
	
	componentDidMount(e) {
		// console.log(localStorage.getItem ('state'))
		// if (localStorage.getItem ('state') != null){
		// 	this.setState(JSON.parse(localStorage.getItem ('state')));
		// 	localStorage.clear();
		// } else {
		// }
		if (localStorage.getItem ('state') != null){
			new Promise( ( resolve, reject ) => {setTimeout( resolve, 200 ); })
			.then( () => {
				var json = JSON.parse(localStorage.getItem ('state'));
				json.notice_list = [];
				json.start_date = Date.parse(json.start_date_format);
				json.end_date = Date.parse(json.end_date_format);
				this.setState(json);
				this.state.startIdx = parseInt(localStorage.getItem("startIdx"));
				localStorage.clear();
				this.getNoticeList();
			});
		} else {
			this.getNoticeList();
		}
	}

	componentDidUpdate(e) {
		// console.log("######### componentDidUpdate ##########")
	}

	onComponentChange = e => {
		// console.log("######### onComponentChange ##########")
	}

	componentWillUnmount = e => {
		// console.log("######### componentWillUnmount ##########")
	}

	//1. 목록 조회
	async getNoticeList() {
		await http.get("/rest/shop/list/notice?start_date="+this.state.start_date_format+"&end_date="+this.state.end_date_format+"&title="+this.state.title
		+"&order_type="+this.state.sorting+"&order_gubun="+this.state.orderGubun+"&startidx="+this.state.startIdx+"&rowcnt="+this.state.rowcnt)
		.then(res => {
			this.setState( {notice_list : res.data["list"]} );
			this.setState( {totalCnt : res.data["totalCnt"]} );
			// console.log(this.state.totalCnt)
		})
		.catch(error => {
			// console.log (error);
		 });

		 
    }
	
	//2. 상세 조회
	handleClick = (notice_seq) => {
		this.props.history.push(`/shop/notice/detail/${notice_seq}`);
		localStorage.setItem ( 'state' , JSON.stringify(this.state) ) ;
		localStorage.setItem ( 'startIdx' , this.state.startIdx ) ;
	};
	
	// 3. 정렬
	onClickSorting = (e) => {
		if (this.state.orderGubun == e.target.className.replace(" sorting","")) {
			if (this.state.sorting == "ASC")
			this.state.sorting = "DESC";
			else
			this.state.sorting = "ASC";
		} else {
			this.state.sorting = "DESC";
		}
		this.state.orderGubun = e.target.className.replace(" sorting","");
		this.state.currentPage = 1;
		this.getNoticeList()
	};

	//4. 조회 버튼 클릭
	onClickSearchListButton = () => {
		this.state.startIdx = 0;
		this.state.activePage = 1;
		this.getNoticeList();
	};

	//4. 달력 클릭
	handleChangeStartDate = date => {
		if (date != null) {
			this.setState({
				start_date_format: date.getFullYear() + "-"+ ("0" + parseInt(date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(date.getDate())).slice(-2),
				start_date: date
			});
		} else {
			this.setState({
				start_date_format: date,
				start_date: date
			});
		}
	};
	handleChangeEndDate = date => {
		if (date != null) {
			this.setState({
				end_date_format: date.getFullYear() + "-"+ ("0" + parseInt(date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(date.getDate())).slice(-2),
				end_date: date
			});
		} else {
			this.setState({
				end_date_format: date,
				end_date: date
			});
		}
	};

	//5. 엔터키 검색
	_handleKeyDown = e => {
		if (e.key === 'Enter') {
			this.state.startIdx = 0;
			this.state.activePage = 1;
			this.getNoticeList();
		}
	  }

	//6.페이징
	handlePageChange(pageNumber) {
		// console.log(`active page is ${pageNumber}`);
		this.setState({activePage: pageNumber});
		this.state.startIdx = (pageNumber * 10) - this.state.rowcnt;
		// console.log("this.state.startIdx :" + this.state.startIdx )
		this.getNoticeList()
	  }  

	  componentWillReceiveProps(nextProps, nextContext) {
		// console.log(nextProps);
		this.getNoticeList(nextProps.match.params.id);
	  }

	  render() {
		$(".pageName").text("공지사항");
		return (
			<div id='page-wrapper' className="container noticeList" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
				<div id="notice_list_component" style={{height: "calc(95vh - 250px)"}}>
					<div className="mobile_top_margin"></div>
					<div className="form_primary_title pc_page_title">공지사항</div>
					<div className="filter">
						<div style={{display:"flex", marginBottom: '-12px'}}>
							<div className="input-group m-b" style={{width:"100%"}}>
								<div style={{width: "100%"}}> 
								<label>날짜</label>
									<div className="input-daterange input-group" id="datepicker" style={{flexWrap:"nowrap"}}>
										<div className="input_date_picker_area" style={{width: "100%"}}>
											<div className="input_text_title eventSettingTitle" style={{zIndex:"999", left:"20px"}}>
												<label style={{marginTop: "auto", marginBottom: "auto"}}>시작일자</label>
											</div>
											<div style={{width: "100%"}}>
												<DatePicker name="start_date" id="start_date"
													selected={this.state.start_date}
													onSelect={this.handleSelect} //when day is clicked
													onChange={this.handleChangeStartDate} //only when value has changed
													// showTimeSelect
													dateFormat="yyyy-MM-dd"
													className="border_form_radius_d_non_padding"
													autoComplete='off'
													/>
											</div>
										</div>
										<div className="input-group-addon" style={{width: "100px",height:"35px", lineHeight: "34px",textAlign:"center", margin:"auto"}}>to</div>
										<div className="input_date_picker_area" style={{width: "100%"}}>
											<div className="input_text_title eventSettingTitle" style={{zIndex:"999", left:"20px"}}>
												<label style={{marginTop: "auto", marginBottom: "auto"}}>종료일자</label>
											</div>
											<div style={{width: "100%"}}>
												<DatePicker name="end_date" id="end_date"
													selected={this.state.end_date}
													onSelect={this.handleSelect} //when day is clicked
													onChange={this.handleChangeEndDate} //only when value has changed
													// showTimeSelect
													dateFormat="yyyy-MM-dd"
													className="border_form_radius_d_non_padding"
													autoComplete='off'
												/>	
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* <div className="input-group m-b" style={{width:"33%", marginLeft:"10px"}}>
								<label>종류</label>
								<select style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px", marginBottom:"10px"}}>
									<option value="0">
										전체
									</option>
								</select>
							</div> */}
						</div>
						<div style={{display:"flex", marginBottom: '12px', marginTop: "7px"}}>
							{/* <div className="input_text_title eventSettingTitle" style={{zIndex:"999", left:"40px"}}>
								<label style={{marginTop: "auto", marginBottom: "auto"}}></label>
							</div> */}
							<input id="input_search_keyword" className="border_form_radius_d_non_padding" type="text" onChange={(e) => {
								// console.log(e.target.value)
								this.setState({ title: e.target.value })
							}} placeholder="검색어를 입력하세요." style={{width:"100%", paddingLeft:"10px", paddingRight:"10px"}} onKeyDown={this._handleKeyDown}
							value={this.state.title}></input>
							<button className = "btn-175-b" onClick={this.onClickSearchListButton} style={{width: "80px",height: "35px", margin: 'auto auto auto 10px'}}>검색</button>
						</div>
					</div>
					{/* <div className="banner_list_table_area_parent" style={{width:"100%", height: "auto", overflowX:"auto", overflowY: "hidden"}}> */}
						<div className="banner_list_table_area" style={{width:"calc(95vw - 600px)", minWidth:"100%", height:"100%", overflowX:"auto", overflowY: "hidden"}}>
							<div style={{display:"inline-block", height:"100%", minWidth:"1100px", width:"100%"}}>
								<div className="table table_V2">
									<table>
										<colgroup>
											{/* <col width="30px" />
											<col width="200px" />
											<col width="70px" />
											<col width="100px" /> */}
											{/* <col width="150px" /> */}
										</colgroup>
										<thead>	
											<tr>
												<td style={{width:"50px", maxWidth:"50px"}}>
													<p className="card-text">순번</p>
												</td>
												<td style={{width:"73%", textAlign:"left"}}>
													<p className="card-text">제목</p>
												</td>
												<td className="user_name sorting" onClick={this.onClickSorting} style={{width:"15%"}}>
													작성자
												</td>
												<td className="reg_date sorting" onClick={this.onClickSorting} style={{width:"10%"}}>
													등록일자
												</td>
												{/* <td className="mod_date sorting" onClick={this.onClickSorting}>
													<p className="card-text">수정일자</p>
												</td> */}
											</tr>
										</thead>
										<tbody>
											{this.state.notice_list.map((notice,idx) => (
												<tr key={notice.notice_seq}>
													{/* <td>
														<p className="card-text">{notice.notice_seq}</p>
													</td> */}
													<td style={{width:"50px", maxWidth:"50px"}}>
														<p className="card-text">{(this.state.totalCnt-(idx+(10*(this.state.activePage-1))))}</p>
													</td>
													<td style={{width:"73%", textAlign:"left"}}>
														<p id="title" className="card-text" onClick={() => this.handleClick(notice.notice_seq)}>{notice.title}</p>
													</td>
													<td style={{width:"15%"}}>
														<p className="card-text">{notice.user_name}</p>
													</td>
													<td style={{width:"10%"}}>
														<p className="card-text">{notice.reg_date}</p>
													</td>
													{/* <td>
														<p className="card-text">{notice.mod_date}</p>
													</td> */}
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					{/* </div> */}
					<Pagination
						activePage={this.state.activePage}
						itemsCountPerPage={this.state.rowcnt}
						totalItemsCount={this.state.totalCnt}
						pageRangeDisplayed={5}
						onChange={this.handlePageChange.bind(this)}
					/>
				</div>
			</div>
		);
	}
}

import { React, Component } from 'react';
import { Line } from 'react-chartjs-2';
import axios from 'axios';

class TrendChart extends Component {
    init = true
    constructor(props) {
        super(props)

        this.setState({
            data_list: [],
            render: false,
        })
    }

    componentDidUpdate = (prevProps, prevState) => {
        if( prevProps.date === this.props.date && 
            prevProps.category_type === this.props.category_type &&
            prevProps.shop_seq === this.props.shop_seq &&
            prevProps.item_no === this.props.item_no &&
            this.init !== false ) return

        this.init = true

        var url = '/rest/fb/getRankLast7'
        url += '?category_type=' + this.props.category_type
        url += '&date=' + this.props.date.replace(/-/gi, "")
        url += '&shop_seq=' + this.props.shop_seq
        url += '&item_no=' + this.props.item_no
        axios.get(url).then(
            response => {
                this.setState({
                    ...this.state,
                    data_list: response.data.list
                }, () => this.make_graph_data());
            }
        );


    }

    make_graph_data = () => {
        this.data = {
            labels: this.make_label(),
            datasets: [
                {
                    label: '순위',
                    data: this.make_data(),
                    fill: false,
                    backgroundColor: 'rgb(255, 99, 132)',
                    borderColor: 'rgba(255, 99, 132, 0.2)',
                },
            ],
            datalabels: {
                display: true,
                color: 'black'
            },
        }
    
        this.options = {
            legend: {
                display: false,
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            min: -50,
                            max: 0,
                        },
                    },
                ],
            },
        }

        this.setState({
            ...this.state,
            render: !this.state.render
        })
    }

    make_display_date = (date) => {
        var week = ['일','월','화','수','목','금','토'];
        var month = (1 + date.getMonth());
        month = month >= 10 ? month : '0' + month;
        var day = date.getDate();
        day = day >= 10 ? day : '0' + day;

        var date_message =  month + '/' + day;
        date_message += '(' + week[date.getDay()] + ')'; 
        return date_message
    }

    make_diff_date = (date) => {
        var year = date.getFullYear();
        var month = (1 + date.getMonth());
        month = month >= 10 ? month : '0' + month;
        var day = date.getDate();
        day = day >= 10 ? day : '0' + day;

        return year.toString() + month.toString() + day.toString()
    }

    make_label = () => {
        var date = new Date(this.props.date)
        date = new Date(date.setDate(date.getDate()-7))
        var label_list = []
        for(var gap=0; gap<=6; gap++) {
            date = new Date(date.setDate(date.getDate()+1))
            label_list.push(this.make_display_date(date))
        }
        return label_list
    }

    make_data = () => {
        if( this.state === null ) return [];

        var date = new Date(this.props.date)
        date = new Date(date.setDate(date.getDate()-7))
        var diff_date = ""
        var data_list = []
        for(var gap=0; gap<=6; gap++) {
            date = new Date(date.setDate(date.getDate()+1))
            diff_date = this.make_diff_date(date)
            var return_value = -50;
            for( var item of this.state.data_list ) {
                if( diff_date === item.date_key ) {
                    return_value = parseInt(item.rank) * -1
                }
            }
            data_list.push(return_value)
        }
        return data_list
    }

    data = {
        labels: this.make_label(),
        datasets: [
            {
                label: '순위',
                data: [],
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
            },
        ],
    }

    options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        min: -50,
                        max: 0,
                    },
                },
            ],
        },
    }

    render() {
        return <Line data={this.data} options={this.options} />
    }
};

export default TrendChart;
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

/* ----- example -----
    <TextInput
        type= {'text'}
        id = {'text1'}
        placeholder = {'10글자 이상 입력해주세요.'}
        infoText = {this.state.text1.length == 0 ? "글자를 입력해주세요." : this.state.text1.length < 10 ? '10글자 이상 입력해주세요.' : '유효합니다'}
        onChange = {(e, value) => this.handleChange(e,'text1')}
        success = {this.state.text1.length >= 10}
        error = {this.state.text1.length == 0}
        width = {'100%'}
        fontSize={"13px"}
        padding={"11px 16px"}
    />
 */

// 비밀번호 보임/숨김 이미지
import eye_n from '../../../resources/img/eye_n.svg';
import eye_y from '../../../resources/img/eye_y.svg';

/**
 * @param {*} id input id
 * @param {*} type input type, 기본값 "text"
 * @param {*} value input value 값
 * @param {*} placeholder input placeholder
 * @param {*} infoText input 아래 설명문
 * @param {*} maxlength input maxlength
 * @param {*} onChange 입력값 변경시 실행될 함수
 * @param {*} className 최상단 div에 추가될 클래스 명
 * @param {*} success success 표시(true면 초록 테두리 및 체크표시)
 * @param {*} error error 표시(true면 빨간 테두리 및 느낌표)
 * @param {*} autoFocus 자동 포커스
 * @param {*} inputRef ref
 * 
 * ------ input style ------
 * @param {*} maxWidth 가로 최대 사이즈 (최상위 div에도 적용)
 * @param {*} width 가로 사이즈 (최상위 div에도 적용)
 * @param {*} height 세로 사이즈 (최상위 div에도 적용)
 * @param {*} fontSize 글자 크기
 * @param {*} lineHeight lineHeight
 * @param {*} padding padding
 */ 
class TextInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            value: this.props.value,
            eye_display : false,
        };
    }

    componentDidMount = () => {
        this.setState({id : this.props.id ? this.props.id : uuidv4()});
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (JSON.stringify(this.props.value) !== JSON.stringify(prevProps.value)) { // 하위컴포넌트가 받은 props값 비교
            this.setState({value:this.props.value});
        }
    }

    handleChange = (e) => {
        const value = e.target.value;
        this.setState({ value });

        // 상위 컴포넌트로 값 전달
        this.props.onChange(e, value);
    }

    /** 비밀번호 보임/숨김 */
    handlePwDisplayType = (e) => {
        this.setState({eye_display : e.target.checked});
    }

    render() {
        const {inputRef, type, placeholder, infoText, maxlength, className, success, error, autoFocus, onKeyDown, onKeyUp} = this.props;
        const {maxWidth, width, height, fontSize, lineHeight, padding} = this.props;
        const {id} = this.state;
        return (
            <div
                className={"common_text_input " + className}
                style = {{
                    maxWidth : maxWidth,
                    width : width,
                    height: height
                }}
            >
                <input
                    className={success ? 'success' : error ? 'error' : ''}
                    ref={inputRef}
                    type={type == "password" && this.state.eye_display ? "text" : type}
                    id={id}
                    placeholder={placeholder}
                    value={this.state.value}
                    onChange={this.handleChange}
                    maxlength = {maxlength}
                    autoFocus= {autoFocus}
                    onKeyDown={onKeyDown}
                    onKeyUp={onKeyUp}
                    style = {{
                        maxWidth : maxWidth,
                        width : width,
                        height : height,
                        padding : padding,
                        fontSize : fontSize,
                        lineHeight : lineHeight,
                        background : type == "password" ? "none" : "",
                    }}
                />
                {type == 'password' && // 상위 컴포넌트에서 type을 'password'로 전달한 경우에만 사용
                    <div className='visible_chk'>
                        <input type='checkbox' id={'eye_'+id} onChange={e => this.handlePwDisplayType(e)}/>
                        <label htmlFor={'eye_'+id}>
                            <img src={eye_n} alt="숨김 버튼"/>
                            <img src={eye_y} alt="보임 버튼"/>
                        </label>
                    </div>
                }
                {infoText && <span>{infoText}</span>}
            </div>
        );
    }
}

TextInput.defaultProps = {
    type : "text",
    className : "",
    width: "100%",
    height: "auto",
    fontSize: "14px",
    lineHeight: "1",
    padding: "8px 16px",
    maxlength: "",
    success : false,
    error : false,
    autoFocus : false,
    onKeyDown : () => {},
    onKeyUp : () => {},
    onChange : () => {},
}

export default TextInput;
import React from 'react';
import http from './modules/api';
import {NoMatch} from "./modules";
import $ from 'jquery';
import logo4 from "../resources/img/icn_logo_v2.svg";
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
// import { pdfjsworker } from 'pdfjs-dist/es5/build/pdf.worker.entry';
// var agent = navigator.userAgent.toLowerCase();
// if( agent.indexOf("chrome") != -1 ){
//     pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// } else if ( agent.indexOf("safari") != -1 ) {
//    .

//     // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// } else {
//     pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// }
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
let is_eng = false;
export class FormViewer extends React.Component {

    state = {
        list : '',
        sync : false
    }

    componentWillMount() {
        const {form_type} = this.props.match.params;
        this.getFormTernm('banner', form_type);

        is_eng = false;
        if (window.location.pathname.indexOf("/en") > 0) {
            is_eng = true;
        }
    }
    componentDidUpdate() {
        $('.formViewer').css('height', 'calc(100vh - 390px)');
    }

	//약관동의 후 가입신청 이동
	onClickAgreeButton = () => {
        window.location.href = '/login'
    }
    
    //약관 조회
    getFormTernm(form_cls, form_type) {
        http.get("/rest/common/formTernm", 
            {
                params:{
                    form_cls : form_cls,
                    form_type : form_type
                }
            })
	 	 	 .then(res => {
                this.setState({
                    list : res.data,
                    sync : true
                });

                for(var idx in res.data) {
                    var name = "pageNumber_"+res.data[idx]["form_seq"]
                    var name_agree = "agree_"+res.data[idx]["form_seq"]
                    this.setState( {[name]: 1,
                    [name_agree]: "N"});
                }
	 	 	})
	 	 	 .catch(err => { 
	 	 		 alert("가입약관 조회 에러"); 
	 	 	});
    }

    render () {
            return (
                <>
                    {
                    (this.state.sync) ?
                        (this.state.list != '' && this.state.list != null) ?
                            <div id='non-page-wrapper' style={{maxWidth:"1024px", margin: "auto", padding: "0 5%"}}>
                                <div className="login-container" style={{minHeight: "100vh", position: "relative", paddingTop:"40px"}}>
                                    <div className="text-center animated fadeInDown loginscreen m-t" style={{width:"100%", maxWidth:"100%", paddingBottom:"0px", margin:"0 auto"}}>
                                        <h3 style={{paddingBottom: "20px"}}>
                                            <a href="/login"><img src={logo4} style={{width:"280px", height:"auto", maxWidth: "100%"}} /></a>
                                        </h3>
                                            {this.state.list.map((form, idx) => (
                                                // <div key={idx}>
                                                //     <h4>{form.form_title}</h4>
                                                //     <div dangerouslySetInnerHTML={ {__html: form.content} }></div>
                                                //     <br/>
                                                // </div>
                                                <>
                                                <h3>{ !is_eng
                                                    ? form.form_title
                                                    : form.form_eng_title
                                                }</h3>
                                                <div style={{position:"relative", marginTop:"45px"}}>
                                                    {/* <Document
                                                        file={form.s3_url}
                                                        renderMode="svg"
                                                        onLoadSuccess={({ numPages }) => {
                                                            var name = "numPages_"+form.form_seq;
                                                            this.setState({ [name]: numPages });
                                                            }}>
                                                        <Page pageNumber={this.state["pageNumber_"+form.form_seq]} />
                                                    </Document> */}
                                                    <iframe className="formViewerObject" src = {"https://robomd.io/formViewer.html?form_type="+this.props.match.params.form_type+"&is_eng="+is_eng} style={{width:"100%", height:"calc(100vh - 310px)"}}></iframe>
                                                    {/* <div className="pdf_viewer_page_area" style={{position:"absolute"}}>
                                                        <div style={{alignSelf: "center"}}>
                                                            <span className="btn_pdf_viewer_page left" onClick={() => 
                                                                {
                                                                    var name = "numPages_"+form.form_seq;
                                                                    var name2 = "pageNumber_"+form.form_seq;
                                                                    if (this.state["pageNumber_"+form.form_seq] > 1) {
                                                                        this.setState({[name] : this.state["numPages_"+form.form_seq],
                                                                            [name2] : this.state["pageNumber_"+form.form_seq]-1})
                                                                    }
                                                                }
                                                                }
                                                                style={{margin: "0 20px 0 0"}}>
                                                            </span>
                                                            <span className="pdf_viewer_page_num">{this.state["pageNumber_"+form.form_seq]} / {this.state["numPages_"+form.form_seq]}</span>
                                                            <span className="btn_pdf_viewer_page right" onClick={() =>
                                                                {
                                                                    var name = "numPages_"+form.form_seq;
                                                                    var name2 = "pageNumber_"+form.form_seq;
                                                                    if (this.state["pageNumber_"+form.form_seq] < this.state["numPages_"+form.form_seq]) {
                                                                        this.setState({[name] : this.state["numPages_"+form.form_seq],
                                                                            [name2] : this.state["pageNumber_"+form.form_seq]+1})
                                                                    }
                                                                }
                                                                }
                                                                style={{margin: "0 0 0 20px"}}>
                                                            </span>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                </>
                                            ))}
                                        {/* <button onClick={this.onClickAgreeButton} className="full-width m-b btn-auto-b" style={{height:"40px", borderRadius:"8px", marginTop:"40px"}}>확인</button>&nbsp; */}
                                        <div style={{marginTop:"37px", marginBottom:"30px"}}>
                                            iclave © 2021
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : <NoMatch></NoMatch>
                    : ''
                    }
                </>
            )        
    }
}

export default FormViewer;
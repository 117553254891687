import React from 'react';
import axios from 'axios';
import http from '../modules/api';
import {Route, Switch, NavLink, Link} from "react-router-dom";
import $ from 'jquery';
import queryString from 'query-string';

import "react-datepicker/dist/react-datepicker.css";

import ReactGA from 'react-ga';
import { th } from 'date-fns/locale';
ReactGA.initialize('UA-78553461-8');

var ga_label = sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")";

export class OrderPaymentTypeModify extends React.Component {
    state = {
        file_name: "",
        files: null,
        corp_reg_no: "",
        corp_name: "",
        business_type: "",
        business_item: "",
        representative_name: "",
        email: "",
        val: "",

        card_map: {},
        tax_request_map: {},

        popupType: "0", // 1: 카드등록, 2: 세금계산서 신청

        service_type : '',
        request_type : '05005',    /*서비스 요청 상태 (05005 : 변경)*/
        shop_name : '',
        payment_type : '',         /*사용자가 선택한 요금제 유형*/
        orderType: "",             // DB에서 조회한 결제 타입
        orderTypeTmp: "",          // 화면에서 변경한 결제 타입 // 02001: 카드 정기결제, 02002: 실시간 계좌이체

        data_paymentTypePlanList: [],   /*멤버십 요금제 목록*/
        data_orderShopPaymentType: '',   /*해당 샵의 요금제*/

        payLinkPreData: {},
    };

    componentDidMount(e) {

        // 외부 페이지에서 접근 여부를 체크
		const { search } = this.props.location;
		const queryObj = queryString.parse(search);		// 문자열의 쿼리스트링을 Object로 변환
        const { service_type } = queryObj;
        
        this.setState({
            service_type: service_type
        }, () => {
            // 멤버십 요금제 리스트
            this.fn_selectPaymentTypePlanList();
            // 현재 사용중인 요금제 조회
            this.fn_selectOrderShopPaymentType();
        });
        
    }

    componentDidUpdate() {

    }

    componentWillUnmount () {

    }

    /**
     * 해당 샵의 요금제 조회
     */
	async fn_selectOrderShopPaymentType() {
        let sendForm = {
            service_type: this.state.service_type,
        };
        const config = {
            headers: {
                'content-type': 'application/json;'
            }
        }
		http.post("/rest/common/selectOrderShopPaymentType", sendForm, config)
            .then(
			    resultData => {
                    const { data } = resultData;
                    //console.log(data.data.payment_type)
                    if (data.data != null) {
                        this.setState({
                            shop_name : data.data.shop_name,
                            data_orderShopPaymentType: data.data.payment_type,
                            payment_type : data.data.payment_type,
                        });
                    } else {
                        alert("해당 상점은 설정된 요금제가 없습니다!\n첫 결제 페이지로 이동합니다.");
                        this.props.history.push(`/shop/orderNew`);
                    }
                    //console.log(JSON.stringify(this.state))
                }
            ).catch(
			    error => { alert("서비스 점검중 입니다."); console.log(error); }
			);
    }

    /**
     * 멤버십 요금제 리스트
     */
	async fn_selectPaymentTypePlanList() {
		const res = await http.get("/rest/common/selectPaymentTypePlanList?service_type="+this.state.service_type);
		// 조회 성공시 다음 단계
        if (res.data.plan_list != null && res.data.plan_list.length > 0) {
			console.log(res.data);
            this.setState({
                data_paymentTypePlanList: res.data.plan_list
            })
        } 
        if (res.data.code != null) {
            if (res.data.code != "200") { 
                let msg = res.data.msg;
                alert(msg);
            }
        } else {
			alert("서비스 점검중 입니다.");
			//리다이렉트 경로
        }
    }

    // 요금제 변경 저장
    saveOrderType() {
        let sendForm = {
            service_type: this.state.service_type,
            payment_type: this.state.payment_type,
        };
        const config = {
            headers: {
                'content-type': 'application/json;'
            }
        }
		http.post("/rest/common/saveOrderShopPaymentType", sendForm, config)
            .then(
			    resultData => {
                    const { data } = resultData;
                    console.log(data);
                    this.props.history.push(`/shop/orderAmountExpect`);
                }
            ).catch(
			    error => { alert("서비스 점검중 입니다."); console.log(error); }
			);
    }

    // 요금제 선택 이벤트
	onClickPaymentType = (e, payment_type) => {
        if (payment_type != this.state.payment_type) {
            if (this.state.payment_type != null && this.state.payment_type != '') {
                // 기존 선택영역 해제
                $("#"+this.state.payment_type).addClass('credit_card_box_unselect');
            }
            // 선택영역 설정
            $(e.target).removeClass('credit_card_box_unselect');
    
            this.setState({
                payment_type: payment_type,
            });
        }
	}

    

	render() {
        // let today = new Date();  
        // alert(JSON.stringify(this.state.card_map))
        // alert(Object.keys(this.state.tax_request_map).length)
        // alert(this.state.tax_request_map)
        // alert(this.state.tax_request_map.constructor)
        return (
            <div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
                <div style={{fontSize:"27px"}}>멤버십 변경</div>
                <div>
                    <div className="flexBox credit_card_box_area" style={{marginTop:"30px", padding: "37px 50px", border:"1px #EBEBED solid", borderRadius:"10px"}}>
                        <div className="icn_credit_card" style={{margin: "auto 10px auto 0"}}></div>
                        {/* <div>{today.getFullYear()}년 {today.getMonth()+1}월 {today.getDate()}일 오늘 기준 고객님의 결제 예상 금액</div> */}
                        <div className="icn_credit_card_text" style={{margin: "auto 0", fontSize:"18px", color: "#10CFC9"}}><b>{this.state.shop_name}</b></div>
                        <div className="icn_credit_card_text" style={{margin: "auto 0", fontSize:"18px"}}><b>&nbsp;상점의 멤버십을 변경해 보세요.</b></div>
                    </div>
                    
                    {/* <div className="credit_card_box_area" style={{marginTop:"20px", padding: "50px", padding: "37px 50px", border:"1px #EBEBED solid", borderRadius:"10px"}}>
                        <div>
                            <div className="flexBox">
                                <div className="credit_card_box cursor-pointer" style={{width: "calc((100% - 50px) / 6)",
                                height: "36vh",
                                border: "1px solid #10CFC9",
                                backgroundColor: "#10CFC9",
                                borderRadius: "13px",
                                marginTop: "15px",
                                marginLeft: "10px",
                                color: "white",
                                fontWeight: "bold",
                                }}>
                                    <div style={{textAlign:"center", fontSize:"25px" , margin: "3.5vh auto auto auto", height:"30px" }}>
                                        Basic
                                    </div>
                                    <div style={{
                                        width: "65px",
                                        padding: "6px 0px 5px",
                                        textAlign:"center", 
                                        margin: "4vh auto auto auto", 
                                        fontSize: "12px", 
                                        lineHeight:"16px",
                                        border: "1px solid #FFFFFF",
                                        borderRadius: "100px",
                                        }}>
                                        기본요금
                                    </div>
                                    <div style={{display:"flex", justifyContent: "center", textAlign:"center", fontSize:"25px" , margin: "1vh auto auto auto", height:"30px" }}>
                                        <div style={{lineHeight:"30px"}}>
                                        19,000
                                        </div>   
                                        <div style={{fontSize:"15px", lineHeight:"34px"}}>
                                        원
                                        </div>   
                                    </div>
                                    <div style={{textAlign:"center", margin: "2vh auto auto auto", fontSize: "12px", lineHeight:"16px"}}>
                                        <div className="btn-auto-b" 
                                        style={{
                                            width: "60px",
                                            height: "24px",
                                            lineHeight: "24px",
                                            background: "rgb(255 255 255 / 30%)",
                                            borderRadius: "5px",
                                            fontSize: "10px",
                                            padding: "0px",
                                            margin: "0 auto",
                                            }}
                                            // onClick={e => this.onClickOpenPopup(e, 1)}
                                            >
                                            VAT 별도
                                        </div>
                                    </div>
                                    <div style={{
                                            textAlign:"center", 
                                            fontSize:"12px" , 
                                            margin: "3vh 5.8% auto 5.8%", 
                                            // width:"147px",
                                            height:"36px", 
                                            background:"white", 
                                            borderRadius: "5px", 
                                            color:"#333333",
                                            lineHeight: "36px",
                                            fontWeight: "bold",
                                        }}>
                                        월 방문자 100,000명까지
                                    </div>
                                </div>

                                <div className="credit_card_box credit_card_box_unselect cursor-pointer" style={{width: "calc((100% - 50px) / 6)",
                                height: "36vh",
                                border: "1px solid #FAFAFB",
                                backgroundColor: "#FAFAFB",
                                borderRadius: "13px",
                                marginTop: "15px",
                                marginLeft: "10px",
                                color: "#333333",
                                fontWeight: "bold",
                                }}>
                                    <div style={{textAlign:"center", fontSize:"25px" , margin: "3.5vh auto auto auto", height:"30px" }}>
                                        Basic
                                    </div>
                                    <div style={{
                                        width: "65px",
                                        padding: "6px 0px 5px",
                                        textAlign:"center", 
                                        margin: "4vh auto auto auto", 
                                        fontSize: "12px", 
                                        lineHeight:"16px",
                                        border: "1px solid #333333",
                                        borderRadius: "100px",
                                        }}>
                                        기본요금
                                    </div>
                                    <div style={{display:"flex", justifyContent: "center", textAlign:"center", fontSize:"25px" , margin: "1vh auto auto auto", height:"30px" }}>
                                        <div style={{lineHeight:"30px"}}>
                                        19,000
                                        </div>   
                                        <div style={{fontSize:"15px", lineHeight:"34px"}}>
                                        원
                                        </div>   
                                    </div>
                                    <div style={{textAlign:"center", margin: "2vh auto auto auto", fontSize: "12px", lineHeight:"16px"}}>
                                        <div className="btn-auto-b" 
                                        style={{
                                            width: "60px",
                                            height: "24px",
                                            lineHeight: "24px",
                                            background: "rgba(51, 51, 51, 0.05)",
                                            borderRadius: "5px",
                                            fontSize: "10px",
                                            padding: "0px",
                                            margin: "0 auto",
                                            color: "rgba(51, 51, 51, 0.2)",
                                            boxShadow: "none",
                                            }}
                                            // onClick={e => this.onClickOpenPopup(e, 1)}
                                            >
                                            VAT 별도
                                        </div>
                                    </div>
                                    <div style={{
                                            textAlign:"center", 
                                            fontSize:"12px" , 
                                            margin: "3vh 5.8% auto 5.8%", 
                                            // width:"147px", 
                                            height:"36px", 
                                            background:"white", 
                                            border: "1px solid #333333",
                                            borderRadius: "5px", 
                                            color:"#333333",
                                            lineHeight: "36px",
                                            fontWeight: "bold",
                                        }}>
                                        월 방문자 100,000명까지
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

<div className="credit_card_box_area credit_card_box_area_mobile" style={{marginTop:"20px", padding: "50px", padding: "37px 50px", border:"1px #EBEBED solid", borderRadius:"10px"}}>
                        {/* 요금제 테이블 */}
                        <div>
                            <div className="flexBox">
                                {/* <div className="credit_card_box cursor-pointer" style={{width: "calc((100% - 50px) / 6)",
                                height: "36vh",
                                border: "1px solid #10CFC9",
                                backgroundColor: "#10CFC9",
                                borderRadius: "13px",
                                marginTop: "15px",
                                marginLeft: "10px",
                                color: "white",
                                fontWeight: "bold",
                                }}>
                                    <div style={{textAlign:"center", fontSize:"25px" , margin: "3.5vh auto auto auto", height:"30px" }}>
                                        Basic
                                    </div>
                                    <div style={{
                                        width: "65px",
                                        padding: "6px 0px 5px",
                                        textAlign:"center", 
                                        margin: "4vh auto auto auto", 
                                        fontSize: "12px", 
                                        lineHeight:"16px",
                                        border: "1px solid #FFFFFF",
                                        borderRadius: "100px",
                                        }}>
                                        기본요금
                                    </div>
                                    <div style={{display:"flex", justifyContent: "center", textAlign:"center", fontSize:"25px" , margin: "1vh auto auto auto", height:"30px" }}>
                                        <div style={{lineHeight:"30px"}}>
                                        19,000
                                        </div>   
                                        <div style={{fontSize:"15px", lineHeight:"34px"}}>
                                        원
                                        </div>   
                                    </div>
                                    <div style={{textAlign:"center", margin: "2vh auto auto auto", fontSize: "12px", lineHeight:"16px"}}>
                                        <div className="btn-auto-b" 
                                        style={{
                                            width: "60px",
                                            height: "24px",
                                            lineHeight: "24px",
                                            background: "rgb(255 255 255 / 30%)",
                                            borderRadius: "5px",
                                            fontSize: "10px",
                                            padding: "0px",
                                            margin: "0 auto",
                                            }}
                                            // onClick={e => this.onClickOpenPopup(e, 1)}
                                            >
                                            VAT 별도
                                        </div>
                                    </div>
                                    <div style={{
                                            textAlign:"center", 
                                            fontSize:"12px" , 
                                            margin: "3vh 5.8% auto 5.8%", 
                                            // width:"147px",
                                            height:"36px", 
                                            background:"white", 
                                            borderRadius: "5px", 
                                            color:"#333333",
                                            lineHeight: "36px",
                                            fontWeight: "bold",
                                        }}>
                                        월 방문자 100,000명까지
                                    </div>
                                </div> */}
                                <>
                                {
                                    (this.state.data_paymentTypePlanList != null) ?
                                        this.state.data_paymentTypePlanList.map((data, idx) => (
                                            this.state.payment_type == data.payment_type 
                                            ?   <div className="credit_card_box cursor-pointer" style={{width: "calc((100% - 50px) / 6)",
                                                height: "36vh",
                                                border: "1px solid #10CFC9",
                                                backgroundColor: "#10CFC9",
                                                borderRadius: "13px",
                                                marginTop: "15px",
                                                marginLeft: "10px",
                                                color: "white",
                                                fontWeight: "bold",
                                                }}>
                                                    <div className="credit_card_box_first_area" style={{display:"flex", flexDirection:"column"}}>
                                                        <div className="payment_type_name" style={{textAlign:"center", fontSize:"25px" , margin: "3.5vh auto auto auto", height:"30px" }}>
                                                            {data.payment_type_name}
                                                        </div>
                                                        <div className="round_box" style={{
                                                            width: "65px",
                                                            padding: "6px 0px 5px",
                                                            textAlign:"center", 
                                                            margin: "4vh auto auto auto", 
                                                            fontSize: "12px", 
                                                            lineHeight:"16px",
                                                            border: "1px solid #FFFFFF",
                                                            borderRadius: "100px",
                                                            }}>
                                                            기본요금
                                                        </div>
                                                    </div>
                                                    <div className="credit_card_box_second_area" style={{display:"flex", flexDirection:"column"}}>
                                                        <div className="credit_card_box_second_inner_area" style={{display:"flex", flexDirection:"column"}}>
                                                            <div className="credit_card_box_second_area_txt" style={{display:"flex", justifyContent: "center", textAlign:"center", fontSize:"25px" , margin: "1vh auto auto auto", height:"30px" }}>
                                                                <div className="credit_card_box_second_area_txt_price" style={{lineHeight:"30px"}}>
                                                                    {data.payment_type_price_fm}
                                                                </div>   
                                                                <div className="credit_card_box_second_area_txt_won" style={{fontSize:"15px", lineHeight:"34px"}}>
                                                                원
                                                                </div>   
                                                            </div>   
                                                            <div className="credit_card_box_second_area_txt vat_area" style={{textAlign:"center", margin: "2vh auto auto auto", fontSize: "12px", lineHeight:"16px"}}>
                                                                <div className="btn-auto-b credit_card_box_second_area_txt" 
                                                                style={{
                                                                    width: "60px",
                                                                    height: "24px",
                                                                    lineHeight: "24px",
                                                                    background: "rgb(255 255 255 / 30%)",
                                                                    borderRadius: "5px",
                                                                    fontSize: "10px",
                                                                    padding: "0px",
                                                                    margin: "0 auto",
                                                                }}
                                                                // onClick={e => this.onClickOpenPopup(e, 1)}
                                                                >
                                                                    VAT 별도
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="credit_card_box_second_area_txt over_charge_standard_fm_area"
                                                        style={{
                                                            textAlign:"center", 
                                                            fontSize:"12px" , 
                                                            margin: "3vh 5.8% auto 5.8%", 
                                                            // width:"147px",
                                                            height:"36px", 
                                                            background:"white", 
                                                            borderRadius: "5px", 
                                                            color:"#333333",
                                                            lineHeight: "36px",
                                                            fontWeight: "bold",
                                                        }}>
                                                            월 페이지뷰 {data.over_charge_standard_fm}명까지
                                                        </div>
                                                    </div>
                                                </div>
                                            :   <div id={data.payment_type} className="credit_card_box credit_card_box_unselect cursor-pointer" style={{width: "calc((100% - 50px) / 6)",
                                                        height: "36vh",
                                                        borderRadius: "13px",
                                                        marginTop: "15px",
                                                        marginLeft: "10px",
                                                        fontWeight: "bold",
                                                        border: "1px solid #10CFC9",
                                                        backgroundColor: "#10CFC9",
                                                        color: "white",
                                                    }}
                                                    value={data.payment_type}
                                                    onClick={(e) => this.onClickPaymentType(e, data.payment_type)}
                                                >
                                                <div style={{pointerEvents:"none"}}>
                                                    <div className="credit_card_box_first_area" style={{display:"flex", flexDirection:"column"}}>
                                                        <div className="payment_type_name" style={{textAlign:"center", fontSize:"25px" , margin: "3.5vh auto auto auto", height:"30px" }}>
                                                            {data.payment_type_name}
                                                        </div>
                                                        <div className="round_box" style={{
                                                            width: "65px",
                                                            padding: "6px 0px 5px",
                                                            textAlign:"center", 
                                                            margin: "4vh auto auto auto", 
                                                            fontSize: "12px", 
                                                            lineHeight:"16px",
                                                            borderRadius: "100px",
                                                            border: "1px solid #333333",
                                                            }}>
                                                            기본요금
                                                        </div>
                                                    </div>
                                                    <div className="credit_card_box_second_area" style={{display:"flex", flexDirection:"column"}}>
                                                        <div className="credit_card_box_second_inner_area" style={{display:"flex", flexDirection:"column"}}>
                                                            <div className="credit_card_box_second_area_txt" style={{display:"flex", justifyContent: "center", textAlign:"center", fontSize:"25px" , margin: "1vh auto auto auto", height:"30px" }}>
                                                                <div className="credit_card_box_second_area_txt_price" style={{lineHeight:"30px"}}>
                                                                    {data.payment_type_price_fm}
                                                                </div>   
                                                                <div className="credit_card_box_second_area_txt_won" style={{fontSize:"15px", lineHeight:"34px"}}>
                                                                원
                                                                </div>   
                                                            </div>
                                                            <div className="credit_card_box_second_area_txt vat_area" style={{textAlign:"center", margin: "2vh auto auto auto", fontSize: "12px", lineHeight:"16px"}}>
                                                                <div className="btn-auto-b credit_card_box_second_area_txt" 
                                                                style={{
                                                                    width: "60px",
                                                                    height: "24px",
                                                                    lineHeight: "24px",
                                                                    background: "rgba(51, 51, 51, 0.05)",
                                                                    borderRadius: "5px",
                                                                    fontSize: "10px",
                                                                    padding: "0px",
                                                                    margin: "0 auto",
                                                                    color: "rgba(51, 51, 51, 0.2)",
                                                                    boxShadow: "none",
                                                                    }}
                                                                    // onClick={e => this.onClickOpenPopup(e, 1)}
                                                                    >
                                                                    VAT 별도
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="credit_card_box_second_area_txt over_charge_standard_fm_area" 
                                                        style={{
                                                            textAlign:"center", 
                                                            fontSize:"12px" , 
                                                            margin: "3vh 5.8% auto 5.8%", 
                                                            // width:"147px", 
                                                            height:"36px", 
                                                            background:"white", 
                                                            border: "1px solid #333333",
                                                            borderRadius: "5px", 
                                                            color:"#333333",
                                                            lineHeight: "36px",
                                                            fontWeight: "bold",
                                                        }}>
                                                            월 페이지뷰 {data.over_charge_standard_fm}명 까지
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                            </div>
                                        ))
                                    : ''
                                }
                                </>
                            </div>
                        </div>
                    </div>

                    <div className="flexBox credit_card_box_area" style={{marginTop:"20px", padding: "37px 50px", border:"1px #EBEBED solid", borderRadius:"10px"}}>
                        <div className="icn_round_blue" style={{width: "10px", height: "10px", margin: "auto 0", fontSize:"18px"}}></div>
                        <div className="icn_credit_card_text" style={{margin: "auto 0 auto 15px", fontSize:"15px", fontWeight:"500", flex: "1"}}>&nbsp;배너 페이지 뷰를 기준으로 하는 추가요금이 발생 할 수 있습니다.</div>
                    </div>

                    <button className="btn-auto-b" style={{padding: "18px 30px 17px", marginTop:"30px", marginBottom:"40px"}} onClick={e => this.saveOrderType(e)}>
                        저장
                    </button>
                </div>
            </div>
        )
    }
}   
import React, { useState } from 'react';
import {http, setIsErrorAlert} from '../api';
import $ from 'jquery';

import SelectBoxV2 from '../common/SelectBoxV2';
import CheckBox from '../common/CheckBox';
import RadioButton from '../common/RadioButton';
import * as StringUtil from '../utils/StringUtil';
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale"; // datePicker 한글화
import round_loading_bar from '../../../resources/img/round_loading_bar.gif';
import PopupFrame from './PopupFrame';

/**
 * 기존 상품 추가 팝업을 컴포넌트로 분리
 * title : 팝업 제목
 * select_type : 상품 선택 타입 (select, radio) , null이면 select
 * selected_product_list : 이미 선택되어있는 상품 리스트
 * image_type_cd : 목록이미지 or 상세이미지, null이면 목록이미지
 * onClose : x버튼 클릭시
 * onSubmit : 하단 버튼 클릭시, <select_type : "select">의 경우 selected_product_list에 담겨있던 상품들을 포함해서 체크된 상품목록이 넘겨짐

    <SearchProductPopup
        title = {"상품 선택"}
        // select_type = {"radio"}
        selected_product_list={[{product_no : "11"}]}
        // onClose = {() => this.closeAddProductPopup()}
        // onSubmit = {this.onClickPopupCheckedProductApply.bind(this)}
        submit_btn_name = {"선택 상품 추가"}
    />
*/
class SearchProductPopup extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			width: "378px",
    		height: "50px",
			padding: "14px 36px 14px 16px",

            /** 팝업 상태 값 */
            show_add_product_popup : false, // 상품 추가 팝업 보이는 상태

            search_gubun: "product_name", // 검색어 종류
            select_order_gubun_type : "order_price/desc", // 선택된 정렬
            order_gubun: "order_price", // 정렬 구분
            order_type: "desc", // 정렬

            select_row_cnt : 10, // 보여질 상품 수
            select_row_cnt_list : [{value:10, title:"10개씩 보기"},{value:20, title:"20개씩 보기"},
            {value:30, title:"30개씩 보기"},{value:50, title:"50개씩 보기"},{value:100, title:"100개씩 보기"}],

            select_event_order_gubun_type_list : [
                {value:"order_price/desc", title:"총 판매액이 많은 상품이 위로"},{value:"order_price/asc", title:"총 판매액이 많은 상품이 아래로"},
                {value:"price/desc", title:"판매가 높은 상품이 위로"},{value:"price/asc", title:"판매가 높은 상품이 아래로"},
                {value:"order_cnt/desc", title:"판매수 많은 상품이 위로"},{value:"order_cnt/asc", title:"판매수 많은 상품이 아래로"},
                {value:"hit_cnt/desc", title:"조회 많은 상품이 위로"},{value:"hit_cnt/asc", title:"조회 많은 상품이 아래로"},
                {value:"created_date/desc", title:"최근 등록된 상품이 위로"},{value:"created_date/asc", title:"최근 등록된 상품이 아래로"},
                {value:"updated_date/desc", title:"최근 수정된 상품이 위로"},{value:"updated_date/asc", title:"최근 수정된 상품이 아래로"},
                {value:"product_name/asc", title:"상품명 가나다순"},{value:"product_name/desc", title:"상품명 가나다 역순"},
                {value:"cart_cnt/desc", title:"장바구니 많이 담은 상품이 위로"},{value:"cart_cnt/asc", title:"장바구니 많이 담은 상품이 아래로"},
                {value:"quantity/desc", title:"재고 많은 상품이 위로"},{value:"quantity/asc", title:"재고 많은 상품이 아래로"},
                // {value:"wish_cnt/desc", title:"찜이 많은 상품이 위로"},{value:"wish_cnt/asc", title:"찜이 많은 상품이 아래로"}
        ],


            add_product_min_price : "",
            add_product_max_price : "",

            add_product_start_date : "",
            add_product_end_date : "",
            
            search_product_name: "", // 검색창 : 상품명
            search_product_code: "", // 검색창 : 상품코드

            activePage: 1, // 페이징의 페이지 번호 (하단에 페이징 버튼과 직결)
            startIdx: 0, // 페이징을 위한 불러올 상품 시작 번호
            rowcnt: 10, // 화면에 보여질 상품 개수
            totalCnt: 0, // 총 상품 개수

            hit_product_cnt : "", // 조회수
			cart_product_cnt : "", // 장바구니
            wish_product_cnt : "", // 찜
            order_product_cnt : "", // 판매수
            review_product_cnt : "", // 리뷰수
            quantity_product_cnt : "", // 재고수

            analysis_filter_hit_yn : "N", // 조회수 필터
			analysis_filter_cart_yn : "N", // 장바구니 필터
            analysis_filter_wish_yn : "N", // 찜 필터
            analysis_filter_order_yn : "N", // 판매수 필터
            analysis_filter_review_yn : "N", // 리뷰수 필터
            analysis_filter_quantity_yn : "N", // 재고수 필터

            product_list : [], // 보여지고있는 상품 목록

            add_product_category_list_1:[],
            add_product_category_list_2:[],
            add_product_category_list_3:[],
            add_product_category_list_4:[],

            add_product_category_no_1: "",
            add_product_category_no_2: "",
            add_product_category_no_3: "",
            add_product_category_no_4: "",

            add_popup_category_list: "",

            popup_check_product_list : [], // 체크된 상품 목록
            show_add_product_list_table_loading_bar : false, // 상품 추가 목록 로딩 바

            event_item_analysis_standard_list : [
                {value:"1/D", title:"최근 1일"},{value:"2/D", title:"최근 2일"},{value:"3/D", title:"최근 3일"},{value:"4/D", title:"최근 4일"},{value:"5/D", title:"최근 5일"},
                {value:1, title:"최근 1주"},{value:2, title:"최근 2주"},{value:3, title:"최근 3주"},{value:4, title:"최근 4주"},
            ],
            analysis_standard_value : "4",
        }
    }

    componentDidMount(){
        if(this.props.select_type == "select"){
            this.setState({popup_check_product_list : JSON.parse(JSON.stringify(this.props.selected_product_list))});
        } else if(this.props.select_type == "radio" && this.props.selected_product_list.length > 0){
            this.setState({popup_check_product_list : [this.props.selected_product_list[0]]});
        }
        // 카테고리 초기화
        this.loadProductCategory("", 1, "add_product_category_list_");
        this.loadProductList();
        this.loadProductListCnt();

        $(document).ready(function(){
            $(window).resize(function(){
                if($(window).width() > 800){
                    //'상품 추가' 팝업 720px 초과일 때 '상품 검색' 숨기기 및 카테고리 펼치기
                    $(".add_list_category_search .list_expand_check").prop("checked",true);
                    
                    $(".add_list_category_search_expand").css({
                        "height":"auto",
                        "overflow": "visible"
                    });
                }
            });
            
            // 모바일 화면일 때 '상품 추가' 팝업에 '상품 검색' 보이기/숨기기
            function add_list_category_m_change(){

                if($(window).width() <= 800){ //720px 이하일 때 '상품 검색' 영역 닫힌 상태로 시작
                    $(".add_list_category_search .list_expand_check").prop("checked",false);

                    $(".add_list_category_search_expand").css({
                        "overflow": "hidden",
                        "height":"0"
                    });
                }
                else{ //720px 이상일 때 원상태로
                    $(".add_list_category_search .list_expand_check").prop("checked",true);
                    
                    $(".add_list_category_search_expand").css({
                        "height":"auto",
                        "overflow": "visible"
                    },200);
                }
            }
            setTimeout(add_list_category_m_change,0);

            // 상품추가 팝업에서 상품검색 영역 클릭시, 해당 영역 펼치기/줄이기
            $(document).on('click',".add_list_category_search",function(){
                let add_list_category_height = $(".add_list_category").outerHeight(true); //마진값 포함한 높이
                let add_list_price_height = $(".add_list_price").outerHeight(true);
                let add_list_date_height = $(".add_list_date").outerHeight(true);
                let add_list_search_height = $(".add_list_search").outerHeight(true);
                let add_list_category_expand = add_list_category_height + add_list_price_height + add_list_date_height + add_list_search_height;
                if( $(".add_list_category_search .list_expand_check").is(":checked") ){
                    $(".add_list_category_search .list_expand_check").prop("checked",false);
                    $(".add_list_category_search_expand").css({"overflow": "hidden"});
                    $(".add_list_category_search_expand").animate({"height":"0"},200);
                }
                else{
                    $(".add_list_category_search .list_expand_check").prop("checked",true);
                    $(".add_list_category_search_expand").css({"overflow": "visible"});
                    $(".add_list_category_search_expand").animate({"height":add_list_category_expand+'px'},200, () => {
                        $(".add_list_category_search_expand").css({"height":"auto"});
                    });
                }
            });
        });
    }

    /** 카테고리 리스트를 불러오는 함수
     * @param {*} parentCategory 목록이 바로 포함하는 상위 카테고리 번호
     * @param {*} depth 카테고리 깊이 순서
     * @param {*} key_name 리스트 값이 저장될 이름
     * @param {*} list_self_select_index 직접 지정속 카테고리인 경우 : 선정 기준 순서 번호
    */
    async loadProductCategory(parentCategory, depth, key_name, list_self_select_index) {

        const sendForm = {
            user_seq: this.state.user_seq, 
            shop_no: this.state.shop_no,
            parent_category_no: parentCategory
        }

        // var name = "product_rp_category_list_"+depth;
        var name = key_name+depth;
		await http.get("/rest/common/cafe24/category", {params:sendForm})
		.then(res => {
            if(res.data.list.length > 0){
                res.data.list.splice(0,0,{category_name : "전체상품", category_name_no_format : "전체상품", category_no : "전체상품"})
            }
            if(list_self_select_index != null){
                let {list_self_select} = this.state;
                list_self_select[list_self_select_index][name] = res.data.list;
                this.setState({list_self_select : list_self_select});
            } else{
                this.setState({[name]: res.data.list});
            }
		})
		.catch(error => {
		 });
    }

    /** 상품 추가 카테고리 변경 (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id(question_idx, answer_idx 포함)
    */
    onChangeCategory = (text, value, id) => {
        // 전체상품을 선택한 경우, 대카테고리가 아니면 상위 카테고리 번호를 대표 카테고리 번호로 설정
        const depth = Number(id.split('_')[4])+1;
        var category_name = "add_product_category_no_"+(Number(depth)-1);

        if((value=="전체상품" || value == "")){
            if(Number(depth)>2){
                const parent_category_no = this.state["add_product_category_no_"+(Number(depth)-2)];
                this.setState({ add_popup_category_list : parent_category_no,});
            } else{
                this.setState({ add_popup_category_list : "",});
            }
        } else{
            this.setState({ add_popup_category_list : value,});
        }
        this.setState({
            [category_name] : value,
        });

        // 세분류가 아니라면 하위카테고리 관련 작업을 진행
        if(depth != 5){
            // 선택한 카테고리의 자식 카테고리 목록을 불러온다
            if (value !="전체상품" && value !="") {
                this.loadProductCategory(value, depth, "add_product_category_list_");
            } else{
                var category_list = "add_product_category_list_"+depth;
                this.setState({ [category_list] : []});
            }

            // 선택한 카테고리의 하위 카테고리 리스트를 초기화 시킨다
            for(var idx = depth+1; idx <=4; idx++) {
                var category_list = "add_product_category_list_"+idx;
                this.setState({ [category_list] : []});
            }

            // 선택한 카테고리 이후의 자식 카테고리 넘버를 초기화 시킨다
            for(var idx = depth; idx <=4; idx++) {
                var category_name = "add_product_category_no_"+idx;
                this.setState({
                    [category_name] : ""
                })
            }
        }
    }

    /** 상품 추가 : 상품 불러오기 */
    async loadProductList() {
        this.setState({
            show_add_product_list_table_loading_bar: true,
            product_list : [],
        });
        // 조회수, 판매수, 리뷰수 불러오기
        this.loadProductListCnt();

        let add_product_min_price = StringUtil.numberNonComma(this.state.add_product_min_price); // ,값 공백처리
        let add_product_max_price = StringUtil.numberNonComma(this.state.add_product_max_price); // ,값 공백처리

        // 상품등록일 날짜 포맷하기
        let start_date = '';
        const {add_product_start_date, add_product_end_date} = this.state;
        if(add_product_start_date != null && add_product_start_date != ""){
            start_date = StringUtil.dateFormater(add_product_start_date);
        }
        let end_date = '';
        if(add_product_end_date != null && add_product_end_date != ""){
            end_date = StringUtil.dateFormater(add_product_end_date);
        }
        
        await http.get(
                        "/rest/shop/rp/product/select/v2"
                        +"?startidx="+this.state.startIdx
                        +"&rowcnt="+this.state.rowcnt
                        +"&order_type="+this.state.order_type
                        +"&order_gubun="+this.state.order_gubun
                        +"&category_no="+this.state.add_popup_category_list
                        +"&product_code="+encodeURIComponent(this.state.search_product_code)
                        +"&product_name="+encodeURIComponent(this.state.search_product_name)
                        +"&analysis_filter_hit_yn="+this.state.analysis_filter_hit_yn
						+"&analysis_filter_cart_yn="+this.state.analysis_filter_cart_yn
                        +"&analysis_filter_wish_yn="+this.state.analysis_filter_wish_yn
                        +"&analysis_filter_order_yn="+this.state.analysis_filter_order_yn
                        +"&analysis_filter_review_yn="+this.state.analysis_filter_review_yn
                        +"&analysis_filter_quantity_yn="+this.state.analysis_filter_quantity_yn
                        +"&min_price="+add_product_min_price
                        +"&max_price="+add_product_max_price
                        +"&start_date="+start_date
                        +"&end_date="+end_date
                        +"&analysis_standard_value="+this.state.analysis_standard_value
                    )
		.then(res => {
			if (res.data.code == "200") {
				this.setState({
                    product_list : res.data["list"],
                    totalCnt: res.data["totalCnt"],
                    show_add_product_list_table_loading_bar : false,
                }, () => {
                    if(this.props.select_type == "radio" && this.state.popup_check_product_list.length == 0){
                        this.setState({popup_check_product_list : [res.data["list"][0]]});
                    }
                });
			} else{
                alert("상품 목록을 불러오는데 실패하였습니다.")
                this.setState({
                    product_list : [],
                    totalCnt: 0,
                    show_add_product_list_table_loading_bar : false,
                });
            }
		})
		.catch(error => {
		 });
    }

    /** 상품 추가 : 상품 수 불러오기 */
    async loadProductListCnt() {

        let add_product_min_price = StringUtil.numberNonComma(this.state.add_product_min_price); // ,값 공백처리
        let add_product_max_price = StringUtil.numberNonComma(this.state.add_product_max_price); // ,값 공백처리

        // 상품등록일 날짜 포맷하기
        let start_date = '';
        const {add_product_start_date, add_product_end_date} = this.state;
        if(add_product_start_date != null && add_product_start_date != ""){
            start_date = StringUtil.dateFormater(add_product_start_date);
        }
        let end_date = '';
        if(add_product_end_date != null && add_product_end_date != ""){
            end_date = StringUtil.dateFormater(add_product_end_date);
        }

        // http 에러 알럿 비활성화
        setIsErrorAlert(false);

        await http.get(
                    "/rest/shop/rp/product/cnt/v2"
                    +"?startidx="+this.state.startIdx
                    +"&rowcnt="+this.state.rowcnt
                    +"&order_type="+this.state.order_type
                    +"&order_gubun="+this.state.order_gubun
                    +"&category_no="+this.state.add_popup_category_list
                    +"&product_code="+encodeURIComponent(this.state.search_product_code)
                    +"&product_name="+encodeURIComponent(this.state.search_product_name)
                    +"&analysis_filter_hit_yn="+this.state.analysis_filter_hit_yn
					+"&analysis_filter_cart_yn="+this.state.analysis_filter_cart_yn
                    +"&analysis_filter_wish_yn="+this.state.analysis_filter_wish_yn
                    +"&analysis_filter_order_yn="+this.state.analysis_filter_order_yn
                    +"&analysis_filter_review_yn="+this.state.analysis_filter_review_yn
                    +"&analysis_filter_quantity_yn="+this.state.analysis_filter_quantity_yn
                    +"&min_price="+add_product_min_price
                    +"&max_price="+add_product_max_price
                    +"&start_date="+start_date
                    +"&end_date="+end_date
                    +"&analysis_standard_value="+this.state.analysis_standard_value
                    )
		.then(res => {
			if (res.data.code == "200") {
				this.setState({
                    hit_product_cnt : res.data.hit_product_cnt ? StringUtil.numberComma(res.data.hit_product_cnt.toString()) : 0, // 조회수
					cart_product_cnt : res.data.cart_product_cnt ? StringUtil.numberComma(res.data.cart_product_cnt.toString()) : 0, // 장바구니수
                    wish_product_cnt : res.data.wish_product_cnt ? StringUtil.numberComma(res.data.wish_product_cnt.toString()) : 0, // 찜수
                    order_product_cnt : res.data.order_product_cnt ? StringUtil.numberComma(res.data.order_product_cnt.toString()) : 0, // 판매수
                    review_product_cnt : res.data.review_product_cnt ? StringUtil.numberComma(res.data.review_product_cnt.toString()) : 0, // 리뷰수
                    quantity_product_cnt : res.data.quantity_product_cnt ? StringUtil.numberComma(res.data.quantity_product_cnt.toString()) : 0, // 재고수
                });
			} else{
                this.setState({
                    hit_product_cnt : 0, // 조회수
					cart_product_cnt : 0, // 장바구니
                    wish_product_cnt : 0, // 찜
                    order_product_cnt : 0, // 판매수
                    review_product_cnt : 0, // 리뷰수
                    quantity_product_cnt : 0, // 재고수
                });
            }

            // http 에러 알럿 활성화
            setIsErrorAlert(true);
		})
		.catch(error => {
            this.setState({
                hit_product_cnt : 0, // 조회수
				cart_product_cnt : 0, // 장바구니
                wish_product_cnt : 0, // 찜
                order_product_cnt : 0, // 판매수
                review_product_cnt : 0, // 리뷰수
                quantity_product_cnt : 0, // 재고수
            });

            // http 에러 알럿 활성화
            setIsErrorAlert(true);
		 });
    }

    /** 페이지 변경 */
    handlePageChange(pageNumber) {
		this.setState({
            activePage: pageNumber,
            startIdx : (pageNumber-1) * this.state.rowcnt
        }, () => {
            this.loadProductList()
        });
    }

    /** 상품 가격 필터 값 변경
     * @param {*} e <e.target.id : add_product_min_price or add_product_max_price>
    */
    onChangeAddProductPrice(e){
        let input_value = StringUtil.inputOnlyNumberFormat(e.target.value);
        if(input_value.length > 9){input_value = input_value.slice(0, 9);}

        this.setState({ [e.target.id] : input_value });
    }

    /** 상품 추가 : 상품등록일 시작 날짜 변경
     * @param {*} date 선택한 날짜
    */
    onChangeAddProductStartDate = date => {
        this.setState({
            add_product_start_date: date
        }, () => {
            // 변경된 시작 날짜가 종료날짜보다 크면, 시작날짜 값을 종료날짜에도 넣어준다
            if(this.state.add_product_end_date!= null && this.state.add_product_end_date !='' && new Date(this.state.add_product_start_date) > new Date(this.state.add_product_end_date)){
                this.setState({
                    add_product_end_date : this.state.add_product_start_date,
                });
            }
            
        });
    };

    /** 상품 추가 : 상품등록일 종료 날짜 변경
     * @param {*} date 선택한 날짜
    */
    onChangeAddProductEndDate = date => {
        this.setState({
            add_product_end_date: date
        });
    };

    /** 상품 추가 : 검색어 종류 변경 (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id(question_idx, answer_idx 포함)
    */
    onChangeSearchGubun(text, value, id) {
        var product_name = "";
        var product_code = "";
        if (value == "product_name") {
            product_name = $("input[name='search_input']").val();
        } else if (value == "product_code") {
            product_code = $("input[name='search_input']").val();
        }
        this.setState({
            search_gubun: value,
            search_product_name: product_name,
            search_product_code: product_code,
        })
    }

    /** 상품 추가 : 검색어 변경 */
    onChangeSearchInput(e) {
        let input_value = e.target.value;

        if (this.state.search_gubun != null && this.state.search_gubun == "product_name") {
            this.setState({
                search_product_name: input_value,
                search_product_code: "",
            })
        } else if (this.state.search_gubun != null && this.state.search_gubun == "product_code") {
            this.setState({
                search_product_name: "",
                search_product_code: input_value,
            })
        } else {
            this.setState({
                search_gubun: "product_name",
                search_product_name: input_value,
                search_product_code: "",
            })
        }
    }

     /** 화면에 보여질 상품 개수 변경 (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id(question_idx, answer_idx 포함)
    */
    onChangeSelectRowCnt(text, value, id){
        this.setState({
            startIdx : 0, // 첫 페이지로 만들기 위해 시작 상품을 0으로 초기화
            activePage : 1, // 첫페이지로 돌아가도록 한다
            rowcnt : value,
            select_row_cnt : value,
        }, () => {
            this.loadProductList();
        })
    }

    /** 정렬 기준 변경 (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id(question_idx, answer_idx 포함)
    */
    onChangeOrderGubun(text, value, id){
        const order_data = value.split("/");
        this.setState({
            select_order_gubun_type : value,
            order_gubun : order_data[0],
            order_type : order_data[1],
            activePage: 1,
            startIdx: 0,
        }, () => {
            this.loadProductList();
        })
    }

    /** 조회수/판매수/리뷰수 필터 변경 함수
	 * @param {*} e <e.target.value : analysis_filter_hit_yn or analysis_filter_cart_yn or analysis_filter_wish_yn or analysis_filter_order_yn or analysis_filter_review_yn>
    */
    onChangeAddListCntFilter(e){
        let value = e.target.value;
        // 필터가 변경되었을 경우, 페이징을 첫 페이지로 초기화 한다.
        this.setState({
            [value] : e.target.checked ? "Y" : "N",
            activePage: 1,
            startIdx: 0,
        }, () => {
            this.loadProductList();
        });
    }

    /** 검색 버튼을 눌렀을 때 */
    onSearchProductList(){
        this.setState({
            activePage: 1,
            startIdx: 0,
        }, () => {
            // 모바일 화면에서 가상키보드가 닫히도록 포커스를 끊는다.
            // 한글의 경우 그냥 포커스를 끊으면, 의자 -> 의자자 와 같이 변하기 떄문에
            // readonly 속성을 이용하여 문제를 방지한다.
            $("#search_input").attr("readonly",true);
            $("#search_input").blur();
            setTimeout(() => $("#search_input").removeAttr("readonly"), 50);
            this.loadProductList();
        });
    }

    /** 상품 추가 체크 상태 변경 : 전체 */
    onClickPopupCheckAll(e) {
        if(e.target.checked == true ){

            const {product_list, popup_check_product_list} = this.state;
            var popup_check_product_list_copy = JSON.parse(JSON.stringify(popup_check_product_list));
            popup_check_product_list_copy = popup_check_product_list_copy.filter(function(obj) {
                return !this.has(obj.product_no);
            }, new Set(product_list.map(obj => obj.product_no)));

            
            for(var idx in product_list) {
                popup_check_product_list_copy.push(product_list[idx])
            }
            this.setState({
                popup_check_product_list: popup_check_product_list_copy
            })
        }
        else{
            let selected_product_list = this.props.selected_product_list;

            //  상품을 제외한 추가 상품
            let list = this.state.product_list.filter(function(obj) {
                return !this.has(obj.product_no);
            }, new Set(selected_product_list.map(obj => obj.product_no)));

            var popup_check_product_list_copy = JSON.parse(JSON.stringify(this.state.popup_check_product_list));
            popup_check_product_list_copy = popup_check_product_list_copy.filter(function(obj) {
                return !this.has(obj.product_no);
            }, new Set(list.map(obj => obj.product_no)));

            this.setState({
                popup_check_product_list: popup_check_product_list_copy
            })
        }
    }

    /** 상품 추가 체크 상태 변경
     * @param {*} e
    */
    onClickPopupCheck(e) {
        let idx = e.target.value;
        if (!e.currentTarget.checked) {
            const {product_list,  popup_check_product_list} = this.state;
            
            for(var idx2 in popup_check_product_list) {
                if (popup_check_product_list[idx2].product_no == product_list[idx].product_no) {
                    idx = idx2;
                    break;
                }
            }

            var popup_check_product_list_copy = JSON.parse(JSON.stringify(popup_check_product_list));
            popup_check_product_list_copy.splice(idx, 1);
            
            this.setState({
                popup_check_product_list: popup_check_product_list_copy,
            })
        } else {
            const {product_list, popup_check_product_list} = this.state;
            popup_check_product_list.push(product_list[idx])
            this.setState({
                popup_check_product_list: popup_check_product_list,
            })
        }
    }

    /** 상품 추가 라디오 체크 상태 변경
     * @param {*} e
    */
    onClickPopupRadioCheck(e){
        const {product_list} = this.state;
        let idx = e.target.value;
        this.setState({
            popup_check_product_list: [product_list[idx]],
        })
    }

    /** 알고리즘 분석 표기 기준 변경 (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id
    */
     onChangeEventItemAnalysisStandard(text, value, id){
        this.setState({
            analysis_standard_value : value,
        }, () => {
            // 상품 재조회
            this.loadProductListFirstPage()
        });
    }

    loadProductListFirstPage() {
        this.setState({
            activePage: 1,
            startIdx : 0
        }, () => {
            this.loadProductList();
        })
    }

    render () {
        let {product_list} = this.state;
        let product_no_list = [];
        let selected_product_no_list = [];

        for(var i=0;i<this.props.selected_product_list.length;i++){
            selected_product_no_list.push(this.props.selected_product_list[i].product_no);
        }

        for(var i=0;i<product_list.length;i++){
            if(selected_product_no_list.indexOf(product_list[i].product_no) == -1){
                product_no_list.push(product_list[i].product_no);
            }
        }

		return (
            <>
                <div id='searchProductPopup' class="style_guide_v2">
                    <PopupFrame
                        title={this.props.title}
                        maxWidth={"1150px"}
                        onClose={this.props.onClose}
                    >
                        <div class="add_list_category_search">
                            <h2 class="text_14">상품 검색</h2>
                            <input class="list_expand_check" type="checkbox" id="list_category_search_arrow" name="list_category_search_arrow"/>
                            <label for="list_category_search_arrow">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="list_arrow-down" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </label>
                        </div>
                        <div class="add_list_category_search_expand" id="add_list_category_search_expand">
                            <div class="add_list_category">
                                <h3 class="text_14">카테고리</h3>
                                <div class="add_list_category_select">
                                    {/* 상품 카테고리 : 지정한 카테고리에서 선정 */}
                                    <SelectBoxV2
                                        id={"add_product_category_no_1"}
                                        tabIndex={0}
                                        width="180px"
                                        height="36px"
                                        padding="14px 36px 14px 16px"
                                        pageWrapperName="searchProductPopup"
                                        list= {this.state.add_product_category_list_1}
                                        optionTexttName = "category_name_no_format"
                                        optionValuetName = "category_no"
                                        selectedValue = {this.state.add_product_category_no_1 == "" ? "전체상품" : this.state.add_product_category_no_1}
                                        onClickOption={this.onChangeCategory.bind(this)}
                                    />
                                    {this.state.add_product_category_list_2 != null && this.state.add_product_category_list_2.length > 0
                                        ?<SelectBoxV2
                                            id={"add_product_category_no_2"}
                                            tabIndex={0}
                                            width="180px"
                                            height="36px"
                                            padding="14px 36px 14px 16px"
                                            pageWrapperName="searchProductPopup"
                                            list= {this.state.add_product_category_list_2}
                                            optionTexttName = "category_name_no_format"
                                            optionValuetName = "category_no"
                                            selectedValue = {this.state.add_product_category_no_2 == "" ? "전체상품" : this.state.add_product_category_no_2}
                                            onClickOption={this.onChangeCategory.bind(this)}
                                        />
                                        : ""
                                    }
                                    {this.state.add_product_category_list_3 != null && this.state.add_product_category_list_3.length > 0
                                        ?<SelectBoxV2
                                            id={"add_product_category_no_3"}
                                            tabIndex={0}
                                            width="180px"
                                            height="36px"
                                            padding="14px 36px 14px 16px"
                                            pageWrapperName="searchProductPopup"
                                            list= {this.state.add_product_category_list_3}
                                            optionTexttName = "category_name_no_format"
                                            optionValuetName = "category_no"
                                            selectedValue = {this.state.add_product_category_no_3 == "" ? "전체상품" : this.state.add_product_category_no_3}
                                            onClickOption={this.onChangeCategory.bind(this)}
                                        />
                                        : ""
                                    }
                                    {this.state.add_product_category_list_4 != null && this.state.add_product_category_list_4.length > 0
                                        ?<SelectBoxV2
                                            id={"add_product_category_no_4"}
                                            tabIndex={0}
                                            width="180px"
                                            height="36px"
                                            padding="14px 36px 14px 16px"
                                            pageWrapperName="searchProductPopup"
                                            list= {this.state.add_product_category_list_4}
                                            optionTexttName = "category_name_no_format"
                                            optionValuetName = "category_no"
                                            selectedValue = {this.state.add_product_category_no_4 == "" ? "전체상품" : this.state.add_product_category_no_4}
                                            onClickOption={this.onChangeCategory.bind(this)}
                                        />
                                        : ""
                                    }
                                </div>
                            </div>
                            <div class="add_list_price">
                                <h3 class="text_14">판매 가격</h3>
                                <div class="add_list_price_select">
                                    <input id="add_product_min_price" type="text" maxlength="11" placeholder="0" value={StringUtil.numberComma(this.state.add_product_min_price)} onChange={e => this.onChangeAddProductPrice(e)} autocomplete="off"/>
                                    <span>~</span>
                                    <input id="add_product_max_price" type="text" maxlength="11" placeholder="999,999,999" value={StringUtil.numberComma(this.state.add_product_max_price)} onChange={e => this.onChangeAddProductPrice(e)} autocomplete="off"/>
                                    <h4 class="text_14">원</h4>
                                </div>
                            </div>
                            <div class="add_list_date">
                                <h3 class="text_14">상품등록일</h3>
                                <div class="add_list_date_select">
                                    <DatePicker name="start_date" id="start_date"
                                        selected={this.state.add_product_start_date}
                                        onChange={this.onChangeAddProductStartDate}
                                        placeholderText="시작일자"
                                        dateFormat="yyyy-MM-dd"
                                        todayButton="오늘"
                                        autoComplete="off"
                                        showYearDropdown
                                        yearDropdownItemNumber={15}
                                        scrollableYearDropdown
                                        maxDate={new Date()}
                                        locale={ko}
                                    />
                                    <span>~</span>
                                    <DatePicker name="end_date" id="end_date"
                                        selected={this.state.add_product_end_date}
                                        onChange={this.onChangeAddProductEndDate}
                                        placeholderText="종료일자"
                                        dateFormat="yyyy-MM-dd"
                                        todayButton="오늘"
                                        autoComplete="off"
                                        showYearDropdown
                                        yearDropdownItemNumber={15}
                                        scrollableYearDropdown
                                        minDate={this.state.add_product_start_date}
                                        maxDate={new Date()}
                                        locale={ko}
                                    />
                                </div>
                            </div>
                            <div class="add_list_search">
                                <h3 class="text_14">상품 검색</h3>
                                <div class="add_list_search_select">
                                    <SelectBoxV2
                                        id={"select_search_gubun"}
                                        tabIndex={0}
                                        width="180px"
                                        height="36px"
                                        padding="14px 36px 14px 16px"
                                        pageWrapperName="searchProductPopup"
                                        list= {[{value:"product_name", title:"상품명"}, {value:"product_code", title:"상품코드(번호)"}]}
                                        optionTexttName = "title"
                                        optionValuetName = "value"
                                        selectedValue = {this.state.search_gubun}
                                        onClickOption={this.onChangeSearchGubun.bind(this)}
                                    />
                                    <input type="text" placeholder="검색어를 입력해주세요" id="search_input" name="search_input" onChange={e => this.onChangeSearchInput(e)} onKeyDown={e => (e.key=='Enter' ? this.onSearchProductList() : "")} autocomplete="off"/>
                                    <button class="add_list_search_button text_extrasmall hover_white" style={{marginLeft : "0"}} onClick={e => this.onSearchProductList(e)}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.3563 12.6438L10.6501 9.94385C11.6052 8.80671 12.0845 7.34474 11.9879 5.86285C11.8913 4.38095 11.2264 2.99355 10.1317 1.99C9.0371 0.986453 7.59729 0.444243 6.1126 0.476455C4.62791 0.508667 3.21298 1.11282 2.1629 2.1629C1.11282 3.21298 0.508667 4.62791 0.476455 6.1126C0.444243 7.59729 0.986453 9.0371 1.99 10.1317C2.99355 11.2264 4.38095 11.8913 5.86285 11.9879C7.34474 12.0845 8.80671 11.6052 9.94385 10.6501L12.6438 13.3563C12.7394 13.4488 12.8672 13.5004 13.0001 13.5004C13.133 13.5004 13.2608 13.4488 13.3563 13.3563C13.4502 13.2615 13.5029 13.1335 13.5029 13.0001C13.5029 12.8667 13.4502 12.7386 13.3563 12.6438ZM1.5001 6.2501C1.5001 5.31064 1.77868 4.39227 2.30062 3.61114C2.82255 2.83 3.5644 2.22119 4.43235 1.86167C5.3003 1.50215 6.25537 1.40809 7.17678 1.59137C8.09819 1.77465 8.94455 2.22704 9.60885 2.89134C10.2732 3.55564 10.7255 4.40201 10.9088 5.32342C11.0921 6.24483 10.998 7.19989 10.6385 8.06784C10.279 8.93579 9.67019 9.67764 8.88906 10.1996C8.10792 10.7215 7.18956 11.0001 6.2501 11.0001C4.99083 10.9984 3.78361 10.4975 2.89317 9.60703C2.00273 8.71659 1.50175 7.50937 1.5001 6.2501Z" fill="#333333"/>
                                        </svg>
                                        검색
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="add_list_table_wrap">
                            <div class="add_list_table_header">
                                <div class="add_list_filter" style={{display:"block"}}>
                                    <div class="text_14 add_list_filter_0 max1500-marginBottom-0px" style={{height: "28px", marginBottom:"13px"}}>총<span style={{color: '#333'}}>{StringUtil.numberComma(this.state.totalCnt.toString())}개</span></div>
                                    <div className="flexBox">
                                        <div class="add_list_filter_1">
                                        <CheckBox
                                                id={"add_list_filter_checkbox_hit_cnt"}
                                                checked={this.state.analysis_filter_hit_yn == "Y"}
                                                value={"analysis_filter_hit_yn"}
                                                onChangeCheck={this.onChangeAddListCntFilter.bind(this)}
                                            />
                                            <div class="text_14">조회수 <span class="text_extrasmall">({this.state.hit_product_cnt})</span></div>
                                        </div>
                                        <div class="add_list_filter_1">
                                            <CheckBox
                                                id={"add_list_filter_checkbox_cart_cnt"}
                                                checked={this.state.analysis_filter_cart_yn == "Y"}
                                                value={"analysis_filter_cart_yn"}
                                                onChangeCheck={this.onChangeAddListCntFilter.bind(this)}
                                            />
                                            <div class="text_14">장바구니수 <span class="text_extrasmall">({this.state.cart_product_cnt})</span></div>
                                        </div>
                                        {/* <div class="add_list_filter_1">
                                            <CheckBox
                                                id={"add_list_filter_checkbox_wish_cnt"}
                                                checked={this.state.analysis_filter_wish_yn == "Y"}
                                                value={"analysis_filter_wish_yn"}
                                                onChangeCheck={this.onChangeAddListCntFilter.bind(this)}
                                            />
                                            <div class="text_14">찜 <span class="text_extrasmall">({this.state.wish_product_cnt})</span></div>
                                        </div> */}
                                        <div class="add_list_filter_1">
                                            <CheckBox
                                                id={"add_list_filter_checkbox_order_cnt"}
                                                checked={this.state.analysis_filter_order_yn == "Y"}
                                                value={"analysis_filter_order_yn"}
                                                onChangeCheck={this.onChangeAddListCntFilter.bind(this)}
                                            />
                                            <div class="text_14">판매수 <span class="text_extrasmall">({this.state.order_product_cnt})</span></div>
                                        </div>
                                        <div class="add_list_filter_1">
                                            <CheckBox
                                                id={"add_list_filter_checkbox_review_cnt"}
                                                checked={this.state.analysis_filter_review_yn == "Y"}
                                                value={"analysis_filter_review_yn"}
                                                onChangeCheck={this.onChangeAddListCntFilter.bind(this)}
                                            />
                                            <div class="text_14">리뷰수 <span class="text_extrasmall">({this.state.review_product_cnt})</span></div>
                                        </div>
                                        <div class="add_list_filter_1">
                                            <CheckBox
                                                id={"add_list_filter_checkbox_quantity_cnt"}
                                                checked={this.state.analysis_filter_quantity_yn == "Y"}
                                                value={"analysis_filter_quantity_yn"}
                                                onChangeCheck={this.onChangeAddListCntFilter.bind(this)}
                                            />
                                            <div class="text_14">재고수 <span class="text_extrasmall">({this.state.quantity_product_cnt})</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='max1500-width-100'>
                                    <div class="add_list_filter_select max1500-marginBottom-0px" style={{marginLeft: "auto", marginBottom:"12px"}}>
                                    <SelectBoxV2
                                        id={"select_order_gubun_type"}
                                        tabIndex={0}
                                        width="240px"
                                        height="28px"
                                        padding="10px 36px 10px 16px"
                                        pageWrapperName="searchProductPopup"
                                        list= {this.state.select_event_order_gubun_type_list}
                                        optionTexttName = "title"
                                        optionValuetName = "value"
                                        selectedValue = {this.state.select_order_gubun_type}
                                        onClickOption={this.onChangeOrderGubun.bind(this)}
                                    />
                                    <SelectBoxV2
                                        id={"select_row_cnt"}
                                        tabIndex={0}
                                        width="140px"
                                        height="28px"
                                        padding="10px 36px 10px 16px"
                                        pageWrapperName="searchProductPopup"
                                        list= {this.state.select_row_cnt_list}
                                        optionTexttName = "title"
                                        optionValuetName = "value"
                                        selectedValue = {this.state.select_row_cnt}
                                        onClickOption={this.onChangeSelectRowCnt.bind(this)}
                                    />
                                    </div>
                                    <div className='flexBox'>
                                        <div id="eventItemAnalysisStandardAreaForSearch">
                                            <div id="eventItemAnalysisStandardTitleForSearch">데이터 분석 기간</div>
                                            <div style={{flex: 1, marginLeft:"10px"}}>
                                                <SelectBoxV2
                                                    style={{width:"100%"}}
                                                    id={"eventItemAnalysisStandard"}
                                                    tabIndex={0}
                                                    width="100%"
                                                    height="28px"
                                                    padding="14px 36px 14px 16px"
                                                    list= {this.state.event_item_analysis_standard_list}
                                                    optionTexttName = "title"
                                                    optionValuetName = "value"
                                                    selectedValue = {this.state.analysis_standard_value}
                                                    onClickOption={this.onChangeEventItemAnalysisStandard.bind(this)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="add_list_table">
                                <table class="list_main_board_table">
                                    <thead>
                                        <tr>
                                            <td class="list_main_td">
                                            {this.props.select_type == "select"
                                            ?   <CheckBox
                                                    id={"popup_checkall"}
                                                    checked={(product_list.filter(function(obj) {
                                                        return !this.has(obj.product_no);
                                                    }, new Set(this.state.popup_check_product_list.map(obj => obj.product_no))).length == 0) || (product_no_list.length == 0)}
                                                    disabled={product_no_list.length == 0}
                                                    onChangeCheck={this.onClickPopupCheckAll.bind(this)}
                                                />
                                            :   
                                                <RadioButton
                                                    id={"popup_checkall"}
                                                    name={"popup_checkall"}
                                                    checked={false}
                                                    disabled={true}
                                                />
                                            }
                                            </td>
                                            <td class="list_main_td">사진</td>
                                            <td class="list_main_td">상품명</td>
                                            <td class="list_main_td">상품등록일</td>
                                            <td class="list_main_td">조회수</td>
											<td class="list_main_td">장바구니수</td>
                                            {/* <td class="list_main_td">찜</td> */}
                                            <td class="list_main_td">판매가</td>
                                            <td class="list_main_td">할인가</td>
                                            <td class="list_main_td">판매수</td>
                                            <td class="list_main_td">판매액</td>
                                            <td class="list_main_td">리뷰수</td>
                                            <td class="list_main_td">리뷰 평점</td>
                                            <td class="list_main_td">재고수</td>
                                        </tr>
                                    </thead>
                                        {this.state.product_list !=null && this.state.product_list != ""
                                        ? <tbody id="add_list_table_tbody">
                                            {this.state.product_list.map((obj, idx) => (
                                                <>
                                                    <tr key={obj.product_no}>
                                                        <td>
                                                            {this.props.select_type == "select"
                                                            ?   <CheckBox
                                                                    id={"popup_check"+obj.product_no}
                                                                    value={idx}
                                                                    checked={(this.state.popup_check_product_list.find(product => product.product_no == obj.product_no) != null) || (selected_product_no_list.indexOf(obj.product_no) != -1)}
                                                                    disabled={selected_product_no_list.indexOf(obj.product_no) != -1}
                                                                    onChangeCheck={this.onClickPopupCheck.bind(this)}
                                                                />
                                                            :   
                                                                <RadioButton
                                                                    id={"popup_check"+obj.product_no}
                                                                    name={"popup_check"}
                                                                    value={idx}
                                                                    checked={this.state.popup_check_product_list.find(product => product.product_no == obj.product_no) != null}
                                                                    onChangeCheck={this.onClickPopupRadioCheck.bind(this)}
                                                                />
                                                            }
                                                        </td>
                                                        <td>
                                                            <img src={
                                                                this.props.image_type_cd == "45002"
                                                                ? obj.detail_image_url
                                                                : obj.image_url
                                                            }/>
                                                        </td>
                                                        <td>
                                                            <h4 class="text_extrasmall">
                                                                {obj.product_name}
                                                            </h4>
                                                        </td>
                                                        <td>{obj.created_date}</td>
                                                        <td>{obj.hit_cnt}</td>
														<td>{obj.cart_cnt}</td>
                                                        {/* <td>{obj.wish_cnt}</td> */}
                                                        <td>{obj.price}</td>
                                                        <td>{obj.pc_discount_price}</td>
                                                        <td>{obj.order_cnt}</td>
                                                        <td>{obj.order_price}</td>
                                                        <td>{obj.review_cnt}</td>
                                                        <td>{obj.review_point}</td>
                                                        <td>{obj.quantity}</td>
                                                    </tr>
                                                </>
                                            ))}
                                        </tbody>
                                        :this.state.show_add_product_list_table_loading_bar
                                            ?<tbody style={{position: "relative", height:"200px"}}>
                                                <div style={{width:$(".add_list_table").width(), height:"200px",position: "absolute",display:"flex",justifyContent:"center"}}>
                                                    <img src={round_loading_bar} style={{width:"200px"}}></img>
                                                </div>
                                            </tbody>
                                            : ""
                                        }
                                </table>
                            </div>
                            {
                                Number(this.state.totalCnt) > 0
                                ?
                                <div class="common_pagination" style={{marginTop:"14px"}}>
                                    <Pagination
                                        firstPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.35627 13.3562C8.26147 13.4501 8.13344 13.5028 8.00002 13.5028C7.8666 13.5028 7.73857 13.4501 7.64377 13.3562L2.64377 8.35623C2.55136 8.26066 2.4997 8.13292 2.4997 7.99998C2.4997 7.86704 2.55136 7.7393 2.64377 7.64373L7.64377 2.64373C7.74092 2.564 7.86425 2.52325 7.98977 2.52942C8.1153 2.53558 8.23405 2.58822 8.32291 2.67709C8.41178 2.76595 8.46442 2.8847 8.47058 3.01023C8.47675 3.13575 8.436 3.25908 8.35627 3.35623L3.70627 7.99998L8.35627 12.6437C8.45016 12.7385 8.50283 12.8666 8.50283 13C8.50283 13.1334 8.45016 13.2614 8.35627 13.3562ZM7.64377 7.64373C7.55136 7.7393 7.4997 7.86704 7.4997 7.99998C7.4997 8.13292 7.55136 8.26066 7.64377 8.35623L12.6438 13.3562C12.7409 13.436 12.8643 13.4767 12.9898 13.4705C13.1153 13.4644 13.234 13.4117 13.3229 13.3229C13.4118 13.234 13.4644 13.1153 13.4706 12.9897C13.4767 12.8642 13.436 12.7409 13.3563 12.6437L8.70627 7.99998L13.3563 3.35623C13.436 3.25908 13.4767 3.13575 13.4706 3.01023C13.4644 2.8847 13.4118 2.76595 13.3229 2.67709C13.234 2.58822 13.1153 2.53558 12.9898 2.52942C12.8642 2.52325 12.7409 2.564 12.6438 2.64373L7.64377 7.64373Z" fill="#8B8B8B"/>
                                        </svg>}
                                        prevPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.50002 7.99998C4.50182 7.86746 4.55309 7.74039 4.64377 7.64373L9.64377 2.64373C9.74092 2.564 9.86425 2.52325 9.98977 2.52942C10.1153 2.53558 10.234 2.58822 10.3229 2.67709C10.4118 2.76595 10.4644 2.8847 10.4706 3.01023C10.4767 3.13575 10.436 3.25908 10.3563 3.35623L5.70627 7.99998L10.3563 12.6437C10.436 12.7409 10.4767 12.8642 10.4706 12.9897C10.4644 13.1153 10.4118 13.234 10.3229 13.3229C10.234 13.4117 10.1153 13.4644 9.98977 13.4705C9.86425 13.4767 9.74092 13.436 9.64377 13.3562L4.64377 8.35623C4.55309 8.25957 4.50182 8.1325 4.50002 7.99998Z" fill="#8B8B8B"/>
                                        </svg>}
                                        nextPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.5 8.00002C11.4982 8.13254 11.4469 8.25961 11.3562 8.35627L6.35623 13.3563C6.25908 13.436 6.13575 13.4767 6.01023 13.4706C5.8847 13.4644 5.76595 13.4118 5.67709 13.3229C5.58822 13.234 5.53558 13.1153 5.52942 12.9898C5.52325 12.8642 5.564 12.7409 5.64373 12.6438L10.2937 8.00002L5.64373 3.35627C5.564 3.25912 5.52325 3.13579 5.52942 3.01026C5.53558 2.88474 5.58822 2.76599 5.67709 2.67712C5.76596 2.58826 5.8847 2.53562 6.01023 2.52946C6.13575 2.52329 6.25908 2.56404 6.35623 2.64377L11.3562 7.64377C11.4469 7.74043 11.4982 7.8675 11.5 8.00002Z" fill="#8B8B8B"/>
                                        </svg>}
                                        lastPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.64373 2.64377C7.73853 2.54988 7.86656 2.49721 7.99998 2.49721C8.1334 2.49721 8.26143 2.54988 8.35623 2.64377L13.3562 7.64377C13.4486 7.73934 13.5003 7.86708 13.5003 8.00002C13.5003 8.13296 13.4486 8.2607 13.3562 8.35627L8.35623 13.3563C8.25908 13.436 8.13575 13.4767 8.01023 13.4706C7.8847 13.4644 7.76595 13.4118 7.67709 13.3229C7.58822 13.234 7.53558 13.1153 7.52942 12.9898C7.52325 12.8642 7.564 12.7409 7.64373 12.6438L12.2937 8.00002L7.64373 3.35627C7.54984 3.26147 7.49717 3.13344 7.49717 3.00002C7.49717 2.8666 7.54984 2.73857 7.64373 2.64377ZM8.35623 8.35627C8.44864 8.2607 8.5003 8.13296 8.5003 8.00002C8.5003 7.86708 8.44864 7.73934 8.35623 7.64377L3.35623 2.64377C3.25908 2.56404 3.13575 2.52329 3.01022 2.52946C2.8847 2.53562 2.76595 2.58826 2.67709 2.67712C2.58822 2.76599 2.53558 2.88474 2.52942 3.01026C2.52325 3.13579 2.564 3.25912 2.64373 3.35627L7.29373 8.00002L2.64373 12.6438C2.564 12.7409 2.52325 12.8642 2.52942 12.9898C2.53558 13.1153 2.58822 13.234 2.67709 13.3229C2.76595 13.4118 2.8847 13.4644 3.01023 13.4706C3.13575 13.4767 3.25908 13.436 3.35623 13.3563L8.35623 8.35627Z" fill="#8B8B8B"/>
                                        </svg>}
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.rowcnt}
                                        totalItemsCount={this.state.totalCnt}
                                        pageRangeDisplayed={5}
                                        onChange={this.handlePageChange.bind(this)}
                                    />
                                </div>    
                                : ""
                            }
                            <div class="add_list_btn_wrap">
                                <button class="text_small hover_mint" onClick={() => this.props.onSubmit(this.state.popup_check_product_list)}>{this.props.submit_btn_name}</button>
                            </div>
                        </div>
                    </PopupFrame>
                </div>
            </>
        )
    }
}

SearchProductPopup.defaultProps = {
    selected_product_list : [],
    select_type : "select",
    image_type_cd : "45001",
}

export default SearchProductPopup;
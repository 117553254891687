import React, { useState } from 'react';
import {http, setIsErrorAlert} from '../modules/api';
import $ from 'jquery';

import SelectBoxV2 from '../modules/common/SelectBoxV2';
import CheckBox from '../modules/common/CheckBox';
import RadioButton from '../modules/common/RadioButton';
import * as StringUtil from '../modules/utils/StringUtil';
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale"; // datePicker 한글화
import round_loading_bar from '../../resources/img/round_loading_bar.gif';

export class MembershipPaymentStandardYmList extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			width: "378px",
    		height: "50px",
			padding: "14px 36px 14px 16px",

            std_ym: "",
            search_text: "",    // 검색어
            select_order_gubun_type : "user_seq/desc", // 선택된 정렬
            order_gubun: "user_seq", // 정렬 구분
            order_type: "desc", // 정렬

            select_row_cnt : 10, // 보여질 상품 수
            select_row_cnt_list : [{value:10, title:"10개씩 보기"},{value:20, title:"20개씩 보기"},{value:30, title:"30개씩 보기"},{value:50, title:"50개씩 보기"},{value:100, title:"100개씩 보기"}],
            select_event_order_gubun_type_list : [{value:"user_seq/desc", title:"사용자 순번이 위로"},{value:"user_seq/asc", title:"사용자 순번이 아래로"},{value:"amount/desc", title:"기본금액이 위로"},{value:"amount/asc", title:"기본금액이 아래로"},],

            activePage: 1, // 페이징의 페이지 번호 (하단에 페이징 버튼과 직결)
            startIdx: 0, // 페이징을 위한 불러올 상품 시작 번호
            rowcnt: 10, // 화면에 보여질 상품 개수
            totalCnt: 0, // 총 상품 개수

            product_list : [], // 보여지고있는 상품 목록
            show_add_product_list_table_loading_bar : false, // 상품 추가 목록 로딩 바
        }
    }

    componentDidMount(){
        let std_date = new Date().setDate(new Date().getDate() + 1);
        this.setState({
            std_ym: std_date
        }, () => {
            this.loadProductList();
            //this.loadProductListCnt();
        });
        // $(document).ready(function(){
        //     // $(window).resize(function(){
        //     //     if($(window).width() > 800){
        //     //         //'상품 추가' 팝업 720px 초과일 때 '상품 검색' 숨기기 및 카테고리 펼치기
        //     //         $(".add_list_category_search .list_expand_check").prop("checked",true);
                    
        //     //         $(".add_list_category_search_expand").css({
        //     //             "height":"auto",
        //     //             "overflow": "visible"
        //     //         });
        //     //     }
        //     // });
            
        //     // 모바일 화면일 때 '상품 추가' 팝업에 '상품 검색' 보이기/숨기기
        //     function add_list_category_m_change(){

        //         if($(window).width() <= 800){ //720px 이하일 때 '상품 검색' 영역 닫힌 상태로 시작
        //             $(".add_list_category_search .list_expand_check").prop("checked",false);

        //             $(".add_list_category_search_expand").css({
        //                 "overflow": "hidden",
        //                 "height":"0"
        //             });
        //         }
        //         else{ //720px 이상일 때 원상태로
        //             $(".add_list_category_search .list_expand_check").prop("checked",true);
                    
        //             $(".add_list_category_search_expand").css({
        //                 "height":"auto",
        //                 "overflow": "visible"
        //             },200);
        //         }
        //     }
        //     setTimeout(add_list_category_m_change,0);

        //     // 상품추가 팝업에서 상품검색 영역 클릭시, 해당 영역 펼치기/줄이기
        //     $(document).on('click',".add_list_category_search",function(){
        //         let add_list_category_height = $(".add_list_category").outerHeight(true); //마진값 포함한 높이
        //         let add_list_price_height = $(".add_list_price").outerHeight(true);
        //         let add_list_date_height = $(".add_list_date").outerHeight(true);
        //         let add_list_search_height = $(".add_list_search").outerHeight(true);
        //         let add_list_category_expand = add_list_category_height + add_list_price_height + add_list_date_height + add_list_search_height;
        //         if( $(".add_list_category_search .list_expand_check").is(":checked") ){
        //             $(".add_list_category_search .list_expand_check").prop("checked",false);
        //             $(".add_list_category_search_expand").css({"overflow": "hidden"});
        //             $(".add_list_category_search_expand").animate({"height":"0"},200);
        //         }
        //         else{
        //             $(".add_list_category_search .list_expand_check").prop("checked",true);
        //             $(".add_list_category_search_expand").css({"overflow": "visible"});
        //             $(".add_list_category_search_expand").animate({"height":add_list_category_expand+'px'},200, () => {
        //                 $(".add_list_category_search_expand").css({"height":"auto"});
        //             });
        //         }
        //     });
        // });
    }

    /** 상품 추가 : 상품 불러오기 */
    async loadProductList() {
        this.setState({
            show_add_product_list_table_loading_bar: true,
            product_list : [],
        });
        // 날짜 포맷하기
        const {std_ym} = this.state;
        let str_std_ym = '';
        if(std_ym != null && std_ym != ""){
            str_std_ym = StringUtil.dateYmFormater(std_ym);
        }
        
        await http.get(
                        "/rest/admin/search/membershipPaymentStandardYmList"
                        +"?startidx="+this.state.startIdx
                        +"&rowcnt="+this.state.rowcnt
                        +"&order_type="+this.state.order_type
                        +"&order_gubun="+this.state.order_gubun
                        +"&std_ym="+str_std_ym
                        +"&search_text="+this.state.search_text
                    )
		.then(res => {
			if (res.data.code == "0000") {
				this.setState({
                    product_list : res.data["list"],
                    totalCnt: res.data["totalCnt"],
                    show_add_product_list_table_loading_bar : false,
                }, () => {
                    if(this.props.select_type == "radio" && this.state.popup_check_product_list.length == 0){
                        this.setState({popup_check_product_list : [res.data["list"][0]]});
                    }
                });
			} else {
                alert(res.data.msg);
                this.setState({
                    product_list : [],
                    totalCnt: 0,
                    show_add_product_list_table_loading_bar : false,
                });
            }
		})
		.catch(error => {
		 });
    }

    /** 페이지 변경 */
    handlePageChange(pageNumber) {
		this.setState({
            activePage: pageNumber,
            startIdx : (pageNumber-1) * this.state.rowcnt
        }, () => {
            this.loadProductList()
        });
    }

    /** 
     * 기준년월 변경
     * @param {*} ym 선택한 날짜
    */
    onChangeAddStandardYm = ym => {
        this.setState({
            std_ym: ym
        }, () => {
            this.loadProductList();
        });
    };

    /** 상품 추가 : 검색어 변경 */
    onChangeSearchInput(e) {
        let input_value = e.target.value;
        this.setState({
            search_text : input_value
        })
    }

     /** 화면에 보여질 상품 개수 변경 (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id(question_idx, answer_idx 포함)
    */
    onChangeSelectRowCnt(text, value, id){
        this.setState({
            startIdx : 0, // 첫 페이지로 만들기 위해 시작 상품을 0으로 초기화
            activePage : 1, // 첫페이지로 돌아가도록 한다
            rowcnt : value,
            select_row_cnt : value,
        }, () => {
            this.loadProductList();
        })
    }

    /** 정렬 기준 변경 (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id(question_idx, answer_idx 포함)
    */
    onChangeOrderGubun(text, value, id){
        const order_data = value.split("/");
        this.setState({
            select_order_gubun_type : value,
            order_gubun : order_data[0],
            order_type : order_data[1],
            activePage: 1,
            startIdx: 0,
        }, () => {
            this.loadProductList();
        })
    }

    /** 검색 버튼을 눌렀을 때 */
    onSearchProductList(){
        this.setState({
            activePage: 1,
            startIdx: 0,
        }, () => {
            // 모바일 화면에서 가상키보드가 닫히도록 포커스를 끊는다.
            // 한글의 경우 그냥 포커스를 끊으면, 의자 -> 의자자 와 같이 변하기 떄문에
            // readonly 속성을 이용하여 문제를 방지한다.
            $("#search_input").attr("readonly",true);
            $("#search_input").blur();
            setTimeout(() => $("#search_input").removeAttr("readonly"), 50);
            this.loadProductList();
        });
    }

    render () {
		return (
            <>
                <div id='common-page-wrapper' class="style_guide_v2">
                    <div id="list_wrap">
                        <section className="list_home_main">
                            <div id='searchProductPopup' class="style_guide_v2" style={{width:"100%"}}>
                                <div class="add_list_category_search">
                                    <h2 class="text_14">상품 검색</h2>
                                    <input class="list_expand_check" type="checkbox" id="list_category_search_arrow" name="list_category_search_arrow"/>
                                    <label for="list_category_search_arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="list_arrow-down" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                                        </svg>
                                    </label>
                                </div>
                                <div class="add_list_category_search_expand" id="add_list_category_search_expand">
                                    <div class="add_list_date">
                                        <h3 class="text_14">기준년월</h3>
                                        <div class="add_list_date_select">
                                            <DatePicker name="std_ym" id="std_ym"
                                                selected={this.state.std_ym}
                                                onChange={this.onChangeAddStandardYm}
                                                placeholderText="yyyy-MM"
                                                dateFormat="yyyy-MM"
                                                showMonthYearPicker
                                                showFullMonthYearPicker
                                                autoComplete='off'
                                                locale={ko}
                                            />
                                        </div>
                                    </div>
                                    <div class="add_list_search">
                                        <h3 class="text_14">검색</h3>
                                        <div class="add_list_search_select">
                                            {/* <SelectBoxV2
                                                id={"select_search_gubun"}
                                                tabIndex={0}
                                                width="180px"
                                                height="36px"
                                                padding="14px 36px 14px 16px"
                                                pageWrapperName="searchProductPopup"
                                                list= {[{value:"search_name", title:"사옹자/소속명"}, {value:"user_seq", title:"사용자 순번"}]}
                                                optionTexttName = "title"
                                                optionValuetName = "value"
                                                selectedValue = {this.state.search_gubun}
                                                onClickOption={this.onChangeSearchGubun.bind(this)}
                                            /> */}
                                            <input type="text" placeholder="검색어를 입력해주세요" id="search_input" name="search_input" onChange={e => this.onChangeSearchInput(e)} onKeyDown={e => (e.key=='Enter' ? this.onSearchProductList() : "")} autocomplete="off"/>
                                            <button class="add_list_search_button text_extrasmall hover_white" style={{marginLeft : "0"}} onClick={e => this.onSearchProductList(e)}>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.3563 12.6438L10.6501 9.94385C11.6052 8.80671 12.0845 7.34474 11.9879 5.86285C11.8913 4.38095 11.2264 2.99355 10.1317 1.99C9.0371 0.986453 7.59729 0.444243 6.1126 0.476455C4.62791 0.508667 3.21298 1.11282 2.1629 2.1629C1.11282 3.21298 0.508667 4.62791 0.476455 6.1126C0.444243 7.59729 0.986453 9.0371 1.99 10.1317C2.99355 11.2264 4.38095 11.8913 5.86285 11.9879C7.34474 12.0845 8.80671 11.6052 9.94385 10.6501L12.6438 13.3563C12.7394 13.4488 12.8672 13.5004 13.0001 13.5004C13.133 13.5004 13.2608 13.4488 13.3563 13.3563C13.4502 13.2615 13.5029 13.1335 13.5029 13.0001C13.5029 12.8667 13.4502 12.7386 13.3563 12.6438ZM1.5001 6.2501C1.5001 5.31064 1.77868 4.39227 2.30062 3.61114C2.82255 2.83 3.5644 2.22119 4.43235 1.86167C5.3003 1.50215 6.25537 1.40809 7.17678 1.59137C8.09819 1.77465 8.94455 2.22704 9.60885 2.89134C10.2732 3.55564 10.7255 4.40201 10.9088 5.32342C11.0921 6.24483 10.998 7.19989 10.6385 8.06784C10.279 8.93579 9.67019 9.67764 8.88906 10.1996C8.10792 10.7215 7.18956 11.0001 6.2501 11.0001C4.99083 10.9984 3.78361 10.4975 2.89317 9.60703C2.00273 8.71659 1.50175 7.50937 1.5001 6.2501Z" fill="#333333"/>
                                                </svg>
                                                검색
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="add_list_table_wrap">
                                    <div class="add_list_table_header">
                                        <div class="add_list_filter">
                                            <div class="text_14 add_list_filter_0">총<span style={{color: '#333'}}>{StringUtil.numberComma(this.state.totalCnt.toString())}개</span></div>
                                        </div>
                                        <div class="add_list_filter_select">
                                            <SelectBoxV2
                                                id={"select_order_gubun_type"}
                                                tabIndex={0}
                                                width="240px"
                                                height="28px"
                                                padding="10px 36px 10px 16px"
                                                pageWrapperName="searchProductPopup"
                                                list= {this.state.select_event_order_gubun_type_list}
                                                optionTexttName = "title"
                                                optionValuetName = "value"
                                                selectedValue = {this.state.select_order_gubun_type}
                                                onClickOption={this.onChangeOrderGubun.bind(this)}
                                            />
                                            <SelectBoxV2
                                                id={"select_row_cnt"}
                                                tabIndex={0}
                                                width="140px"
                                                height="28px"
                                                padding="10px 36px 10px 16px"
                                                pageWrapperName="searchProductPopup"
                                                list= {this.state.select_row_cnt_list}
                                                optionTexttName = "title"
                                                optionValuetName = "value"
                                                selectedValue = {this.state.select_row_cnt}
                                                onClickOption={this.onChangeSelectRowCnt.bind(this)}
                                            />
                                        </div>
                                    </div>
                                    <div class="add_list_table">
                                        <table class="list_main_board_table" style={{width:"100%"}}>
                                            <thead>
                                                <tr>
                                                    <td class="list_main_td" style={{width:"3%"}}>사용자 순번</td>
                                                    <td class="list_main_td" style={{width:"10%"}}>소속명</td>
                                                    <td class="list_main_td" style={{width:"10%"}}>사용자ID</td>
                                                    <td class="list_main_td" style={{width:"5%"}}>사용자명</td>
                                                    <td class="list_main_td" style={{width:"7%"}}>결제금액</td>
                                                    <td class="list_main_td" style={{width:"7%"}}>기본금액</td>
                                                    {/* <td class="list_main_td">추가금액</td> */}
                                                    {/* <td class="list_main_td" style={{width:"2%"}}>사용일수</td> */}
                                                    <td class="list_main_td" style={{width:"8%"}}>시작일자</td>
                                                    <td class="list_main_td" style={{width:"8%"}}>종료일자</td>
                                                    <td class="list_main_td" style={{width:"8%"}}>결제수단</td>
                                                    <td class="list_main_td" style={{width:"8%"}}>연락처</td>
                                                    <td class="list_main_td" style={{width:"10%"}}>이메일</td>
                                                    <td class="list_main_td" style={{width:"10%"}}>사업자등록번호</td>
                                                </tr>
                                            </thead>
                                                {this.state.product_list !=null && this.state.product_list != ""
                                                ? <tbody id="add_list_table_tbody">
                                                    {this.state.product_list.map((obj, idx) => (
                                                        <>
                                                            <tr key={obj.user_seq}>
                                                                <td>{obj.user_seq}</td>
                                                                <td>
                                                                    <h4 class="text_extrasmall">
                                                                        {obj.corp_name}
                                                                    </h4>
                                                                </td>
                                                                <td>{obj.user_id}</td>
                                                                <td>{obj.user_name}</td>
                                                                <td style={{textAlign:"right"}}>{obj.payment_amount}</td>
                                                                <td style={{textAlign:"right"}}>{obj.amount}</td>
                                                                {/* <td>{obj.add_price}</td> */}
                                                                {/* <td>{obj.use_day_cnt}</td> */}
                                                                <td>{obj.start_date}</td>
                                                                <td>{obj.end_date}</td>
                                                                <td>{obj.order_type_name}</td>
                                                                <td>{obj.mobile_no}</td>
                                                                <td>{obj.email}</td>
                                                                <td>{obj.corp_reg_no}</td>
                                                            </tr>
                                                        </>
                                                    ))}
                                                </tbody>
                                                :this.state.show_add_product_list_table_loading_bar
                                                    ?<tbody style={{position: "relative", height:"200px"}}>
                                                        <div style={{width:$(".add_list_table").width(), height:"200px",position: "absolute",display:"flex",justifyContent:"center"}}>
                                                            <img src={round_loading_bar} style={{width:"200px"}}></img>
                                                        </div>
                                                    </tbody>
                                                    : ""
                                                }
                                        </table>
                                    </div>
                                    {
                                        Number(this.state.totalCnt) > 0
                                        ?
                                        <div class="common_pagination" style={{marginTop:"14px"}}>
                                            <Pagination
                                                firstPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.35627 13.3562C8.26147 13.4501 8.13344 13.5028 8.00002 13.5028C7.8666 13.5028 7.73857 13.4501 7.64377 13.3562L2.64377 8.35623C2.55136 8.26066 2.4997 8.13292 2.4997 7.99998C2.4997 7.86704 2.55136 7.7393 2.64377 7.64373L7.64377 2.64373C7.74092 2.564 7.86425 2.52325 7.98977 2.52942C8.1153 2.53558 8.23405 2.58822 8.32291 2.67709C8.41178 2.76595 8.46442 2.8847 8.47058 3.01023C8.47675 3.13575 8.436 3.25908 8.35627 3.35623L3.70627 7.99998L8.35627 12.6437C8.45016 12.7385 8.50283 12.8666 8.50283 13C8.50283 13.1334 8.45016 13.2614 8.35627 13.3562ZM7.64377 7.64373C7.55136 7.7393 7.4997 7.86704 7.4997 7.99998C7.4997 8.13292 7.55136 8.26066 7.64377 8.35623L12.6438 13.3562C12.7409 13.436 12.8643 13.4767 12.9898 13.4705C13.1153 13.4644 13.234 13.4117 13.3229 13.3229C13.4118 13.234 13.4644 13.1153 13.4706 12.9897C13.4767 12.8642 13.436 12.7409 13.3563 12.6437L8.70627 7.99998L13.3563 3.35623C13.436 3.25908 13.4767 3.13575 13.4706 3.01023C13.4644 2.8847 13.4118 2.76595 13.3229 2.67709C13.234 2.58822 13.1153 2.53558 12.9898 2.52942C12.8642 2.52325 12.7409 2.564 12.6438 2.64373L7.64377 7.64373Z" fill="#8B8B8B"/>
                                                </svg>}
                                                prevPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.50002 7.99998C4.50182 7.86746 4.55309 7.74039 4.64377 7.64373L9.64377 2.64373C9.74092 2.564 9.86425 2.52325 9.98977 2.52942C10.1153 2.53558 10.234 2.58822 10.3229 2.67709C10.4118 2.76595 10.4644 2.8847 10.4706 3.01023C10.4767 3.13575 10.436 3.25908 10.3563 3.35623L5.70627 7.99998L10.3563 12.6437C10.436 12.7409 10.4767 12.8642 10.4706 12.9897C10.4644 13.1153 10.4118 13.234 10.3229 13.3229C10.234 13.4117 10.1153 13.4644 9.98977 13.4705C9.86425 13.4767 9.74092 13.436 9.64377 13.3562L4.64377 8.35623C4.55309 8.25957 4.50182 8.1325 4.50002 7.99998Z" fill="#8B8B8B"/>
                                                </svg>}
                                                nextPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.5 8.00002C11.4982 8.13254 11.4469 8.25961 11.3562 8.35627L6.35623 13.3563C6.25908 13.436 6.13575 13.4767 6.01023 13.4706C5.8847 13.4644 5.76595 13.4118 5.67709 13.3229C5.58822 13.234 5.53558 13.1153 5.52942 12.9898C5.52325 12.8642 5.564 12.7409 5.64373 12.6438L10.2937 8.00002L5.64373 3.35627C5.564 3.25912 5.52325 3.13579 5.52942 3.01026C5.53558 2.88474 5.58822 2.76599 5.67709 2.67712C5.76596 2.58826 5.8847 2.53562 6.01023 2.52946C6.13575 2.52329 6.25908 2.56404 6.35623 2.64377L11.3562 7.64377C11.4469 7.74043 11.4982 7.8675 11.5 8.00002Z" fill="#8B8B8B"/>
                                                </svg>}
                                                lastPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.64373 2.64377C7.73853 2.54988 7.86656 2.49721 7.99998 2.49721C8.1334 2.49721 8.26143 2.54988 8.35623 2.64377L13.3562 7.64377C13.4486 7.73934 13.5003 7.86708 13.5003 8.00002C13.5003 8.13296 13.4486 8.2607 13.3562 8.35627L8.35623 13.3563C8.25908 13.436 8.13575 13.4767 8.01023 13.4706C7.8847 13.4644 7.76595 13.4118 7.67709 13.3229C7.58822 13.234 7.53558 13.1153 7.52942 12.9898C7.52325 12.8642 7.564 12.7409 7.64373 12.6438L12.2937 8.00002L7.64373 3.35627C7.54984 3.26147 7.49717 3.13344 7.49717 3.00002C7.49717 2.8666 7.54984 2.73857 7.64373 2.64377ZM8.35623 8.35627C8.44864 8.2607 8.5003 8.13296 8.5003 8.00002C8.5003 7.86708 8.44864 7.73934 8.35623 7.64377L3.35623 2.64377C3.25908 2.56404 3.13575 2.52329 3.01022 2.52946C2.8847 2.53562 2.76595 2.58826 2.67709 2.67712C2.58822 2.76599 2.53558 2.88474 2.52942 3.01026C2.52325 3.13579 2.564 3.25912 2.64373 3.35627L7.29373 8.00002L2.64373 12.6438C2.564 12.7409 2.52325 12.8642 2.52942 12.9898C2.53558 13.1153 2.58822 13.234 2.67709 13.3229C2.76595 13.4118 2.8847 13.4644 3.01023 13.4706C3.13575 13.4767 3.25908 13.436 3.35623 13.3563L8.35623 8.35627Z" fill="#8B8B8B"/>
                                                </svg>}
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={this.state.rowcnt}
                                                totalItemsCount={this.state.totalCnt}
                                                pageRangeDisplayed={5}
                                                onChange={this.handlePageChange.bind(this)}
                                            />
                                        </div>    
                                        : ""
                                    }
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </>
        )
    }
}

export default MembershipPaymentStandardYmList;
import React from 'react';
import axios from 'axios';
import http from '../modules/api';
import {Route, Switch, NavLink, Link} from "react-router-dom";
// import {AdminUserDetail} from "./AdminUserDetail";
import $ from 'jquery';
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";

import {AdminUserDetail} from "./AdminUserDetail";
import FileDownloadComponent from "../modules/FileDownloadComponent";

import "react-datepicker/dist/react-datepicker.css";

// var isBtnApply = false;
var filterList = { "list": [] };
var searchKeyword = "";
var hashtagList = [];
var isModify = false;
var isModifyEdit = 0;
var addKeywordIdx = 0;

export class HashTgaManager extends React.Component {
	state = {
			naver_list: [],
			keywordDictList: [],
			keywordHashtagList: [],
			orderGubun: "",
			sorting: "",
			currentPage: "",
			start_date: "",
			end_date: "",
			start_date_format: "",
			end_date_format: "",
			dict_seq: "",
			search_value: "",
			activePage: 1,
			totalCnt: 0,
			startIdx: 0,
			rowcnt:10,
			searchKeyword: '',
			shopNameKeyword: '',
			sorting: '',
			keyword: '',
			filterList: [],
			firstLoad: true,
			isBtnApply: false
			// headers: ['사전키워드', '관련키워드', 	 '월간 PC 검색수', 	 '월간 모바일 검색수', 		'월평균 PC 클릭수',   '월평균 모바일 클릭수',     '월평균 PC 클릭률',       '월평균 모바일 클릭률',        '월간노출광고수', '경쟁정도', '최초수집일',   '마지막수집일'],
			// dataIds: ['keyword', 'relKeyword', 'monthlyPcQcCnt', 'monthlyMobileQcCnt', 'monthlyAvePcCtr', 'monthlyAveMobileCtr', 'monthlyAvePcClkCnt', 'monthlyAveMobileClkCnt', 'plAvgDepth', 'compIdx', 'reg_date', 'mod_date']
	};

	// handleText = (e, key) => {
	//     // computed property
	// 	this.setState({[key]: e.target.value});
	// }
	
	componentDidMount(e) {
		$("#btnFilterApply").hide();

		$(".delBox").click(function() {
			$(this).remove()
		});
		$(".delBox.hashtag").click(function() {
			isModify = true;
		});
		$("#hashTagAdd").click(function() {
			if (searchKeyword.length ==0) {
				alert("키워드를 먼저 선택해주세요.")
				return false;
			}

			$("#hashtagArea").append("<div class='delBox hashtag' id='hashtag"+addKeywordIdx+"'>#<input type='text' value='' class='tagInput' placeholder='입력 후 Enter'/></div>")
			$("#hashtag"+addKeywordIdx+" input").keydown(function (key) {
				// console.log("###### keydown : "+ key.keyCode);
				console.log($(this));
				if(key.keyCode == 13){//키가 13이면 실행 (엔터는 13)
					var value = $(this).val();
					console.log(value);
					$(this).parent().append(value+" <i class='fa fa-times-circle'></i>");
					
					$(this).remove();
					isModifyEdit = isModifyEdit - 1;
					console.log("###### isModifyEdit: "+ isModifyEdit);
				}
			});
			$("#hashtag"+addKeywordIdx).click(function() {
				if($(this).has("input")) {
					isModifyEdit = isModifyEdit - 1;
					console.log("###### isModifyEdit: "+ isModifyEdit);
				}
			});
			$(".delBox").click(function() {
				$(this).remove()
			});
			$(".delBox.hashtag").click(function() {
				isModify = true;
			});
			$(".tagInput").click(function() {
				return false;
			});
			isModifyEdit = isModifyEdit + 1;
			addKeywordIdx = addKeywordIdx + 1;
			console.log("###### isModifyEdit: "+ isModifyEdit);
			isModify = true;
		});
		

		this.getNaverKeywordList();
		this.getDictKeywordList();
	}

	componentDidUpdate(e) {
		// console.log("######### componentDidUpdate ########## ")
		// console.log(e)
	}

	onComponentChange = e => {
		// console.log("######### onComponentChange ##########")
	}

	componentWillUnmount = e => {
		// console.log("######### componentWillUnmount ##########")
	}

	filterStackController = (e) =>  {
		var isAlreadyExistCate = checkExistFilter(filterList);
		var lastAddedLoc = ['ini','ini'];

		if(isNaN($('#submitFilter').val())){
			alert('숫자만 입력해주세요.');
			return;
		} else if($('#submitFilter').val() === ''){
			alert('필터 적용값을 입력해주세요.');
			return;
		}


		if(isAlreadyExistCate == -1){
			filterList.list.push({
				"keyword": $('#filterCateSelect').val(),
				"filter": [
					($('#filterCateSelect').val() + $('#filterOperCateSelect').val() + $('#submitFilter').val())
				]
			});

			lastAddedLoc[0] = filterList.list.length-1;
			lastAddedLoc[1] = filterList.list[filterList.list.length-1].filter.length-1;

		} else if (isAlreadyExistCate != -1) {
			filterList.list[isAlreadyExistCate].filter.push(
				$('#filterCateSelect').val() + $('#filterOperCateSelect').val() + $('#submitFilter').val()
			);

			lastAddedLoc[0] = isAlreadyExistCate;
			lastAddedLoc[1] = filterList.list[isAlreadyExistCate].filter.length-1;

		} else console.log('error');
			
		$("#btnFilterApply").show();
		// console.log(filterList.list["filter"]);
		addFiltertoList(filterList, lastAddedLoc);
		this.state.filterList = filterList.list;

		function checkExistFilter(filterList) {
			for(var i=0; i<filterList.list.length; i++){
				if(filterList.list[i].keyword == $('#filterCateSelect').val()){
					return i;
				}
			}
			return -1;
		}

		function addFiltertoList(filterList, lastAddedLoc){
			if(isAlreadyExistCate == -1){	
				$('#filterListView').append("<button type='button' class='btn btn-outline-primary filter-item' id='"+($('#filterCateSelect>option:checked').val())+"'" +
					"value=" + ( String(lastAddedLoc[0]) + String(lastAddedLoc[1]) ) + ">" +
					($('#filterCateSelect>option:checked').text()) + " " +
					"<strong>" + ($('#submitFilter').val()) + "</strong> " +
					($('#filterOperCateSelect>option:checked').text()) +
					" <i class='fa fa-times-circle'></i>" +
				"</button>");

				$(".filter-item").click(function() {
					$(this).remove();
					console.log($(this).attr('id'));

					for(var i=0; i<filterList.list.length; i++){
						if(filterList.list[i].keyword == $(this).attr('id')){
							filterList.list.splice(i,1);
						}
					}
					// console.log(filterList.list);
				});
			} else {
				console.log("error: "+$('#filterCateSelect>option:checked').text()+"는 이미 추가된 필터 입니다.");
				alert($('#filterCateSelect>option:checked').text()+"는 이미 추가된 필터 입니다.");
			}
		}
	}

	// 네이버 목록 조회
	getNaverKeywordList = (e) =>   {
		$(".btnAddNaverKeyword").click(function() {}); // 이벤트 제거
		http.post("/rest/common/naver/keyword"+"?rowcnt=" + this.state.rowcnt +
		"&startidx=" + this.state.startIdx +
		"&searchKeyword=" + this.state.searchKeyword +
		"&shop_name=" + this.state.shopNameKeyword +
		"&order_gubun=" + this.state.orderGubun +
		"&order_type="+ this.state.sorting, {
			"list": this.state.filterList
		})
		.then(res => {
			this.setState( {naver_list : res.data["list"]} );
			this.setState( {totalCnt : res.data["totalCnt"]} );
			// console.log(this.state.keywordDictList)

			$(".btnAddNaverKeyword").click(function() {
				if (searchKeyword.length ==0) {
					alert("키워드를 먼저 선택해주세요.")
					return false;
				}

				var value = $(this).parent().parent().children("td").children(".relKeyword").text()
				console.log($(this).parent().parent().children("td").children(".relKeyword").text());
				$("#hashtagArea").append("<div class='delBox hashtag'>#"+value+" <i class='fa fa-times-circle'></i>"+"</div>")
				$(".delBox").click(function() {
					$(this).remove()
				});
				$(".tagInput").click(function() {
					return false;
				});
				isModify = true;
			}); // 이벤트 추가
		})
		.catch(error => {
			// console.log (error);
		 });
    }
	
	getDictKeywordList = (e) =>   {
		console.log(this.state.search_value)
		http.get("/rest/common/dict/keyword"
		+"?keyword=" + decodeURI(this.state.search_value)
		)
		.then(res => {
			this.setState( {keywordDictList : res.data["keywordDictList"]} );
			new Promise( ( resolve, reject ) => {setTimeout( resolve, 200 ); })
			.then( () => {
				// console.log('========== $(".keyword") ==========')
				// console.log($(".keyword"))
				// console.log("===================================")
				$(".keyword").each (function (n, element) {
					if ($(this).text().split(".")[1].indexOf(searchKeyword) > 0) {
						$(this).css("color", "#21a777")
					} else {
						$(this).css("color", "black")
					}
				});
			})
			.catch(error => {
				console.log (error);
			});
		})
		.catch(error => {
			// console.log (error);
		 });
	}
	
	getHashTagList = (e) =>   {
		console.log(this.state.search_value)
		http.get("/rest/common/dict/hashtag"
		+"?dict_seq=" + this.state.dict_seq
		)
		.then(res => {
			this.setState( {keywordHashtagList : res.data["keywordHashtagList"]} );
			new Promise( ( resolve, reject ) => {setTimeout( resolve, 200 ); })
			.then( () => {
				$("#hashtagArea").empty()
				for(var obj in this.state.keywordHashtagList) {
					$("#hashtagArea").append("<div class='delBox hashtag'>"+this.state.keywordHashtagList[obj].hashtag+" <i class='fa fa-times-circle'></i>"+"</div>")
					$(".delBox").click(function() {
						$(this).remove()
					});
					$(".delBox.hashtag").click(function() {
						isModify = true;
					});
					$(".tagInput").click(function() {
						return false;
					});
				}
			})
			.catch(error => {
				console.log (error);
			});
		})
		.catch(error => {
			// console.log (error);
		 });
    }
	
	putHashtags = (e) =>   {
		hashtagList = [];	
		$ ('.hashtag') .each (function (n, element) {
			if ($(this).text().length > 0){
				if ($(this).text().split("#")[1].length > 0 ) {
					hashtagList.push($(this).text().replace(" ",""));
				}
			}
		});

		http.post("/rest/common/insert/hashtag"
		, {dict_seq: this.state.dict_seq, hashtags: hashtagList})
		.then(res => {
			console.log(res.data)
			new Promise( ( resolve, reject ) => {setTimeout( resolve, 200 ); })
			.then( () => {
				alert("저장되었습니다.")
				isModify = false;
				this.getHashTagList();
			})
			.catch(error => {
				console.log (error);
			});
		})
		.catch(error => {
			// console.log (error);
		 });
    }
	
	// 키워드 클릭
	onClikcKeyword = (e, keyword) => {
		if(isModifyEdit > 0) {
			alert("입력중인 태그가 있습니다. 입력을 완료해주세요.")
			return false;
		}

		// hashThg 중에 달라진게 있는지 체크 (start)
		var modifyHashtagList = [];
		$ ('.hashtag') .each (function (n, element) {
			if ($(this).text().length > 0){
				if ($(this).text().split("#")[1].length > 0 ) {
					modifyHashtagList.push({hashtag: $(this).text().replace(" ","")});
				}
			}
		});
		if (JSON.stringify(modifyHashtagList) === JSON.stringify(this.state.keywordHashtagList)) {
			isModify = false; // 달라진게 없으면 isModify = false
		} else {
			isModify = true; // 달라진게 있으면 isModify = true
		}
		// hashThg 중에 달라진게 있는지 체크 (end)

		if (isModify) {
			var confirm = window.confirm("변경사항을 저장 하시겠습니까?"
			+"\n취소를 누르면 저장하지 않고 다음 태그로 넘어갑니다.")
			if(confirm) {
				this.putHashtags();
			} else {
				// return false;
			}
		}
		$("."+e.target.className).css("color", "black");
		e.target.style["color"]="#21a777"
		// console.log(e.target.style);
		this.setState({searchKeyword : keyword});
		searchKeyword = keyword;
		this.setState({ dict_seq: e.target.id })
		new Promise( ( resolve, reject ) => {setTimeout( resolve, 200 ); })
		.then( () => {
			this.state.startIdx = 0;
			this.state.activePage = 1;
			this.getNaverKeywordList();
			this.getHashTagList();
			isModify = false;
		})
		.catch(error => {
			// console.log (error);
		});
	};

	// 태그 저장
	onClickSave = e => {
		if(isModifyEdit > 0) {
			alert("입력중인 태그가 있습니다. 입력을 완료해주세요.")
			return false;
		}

		this.putHashtags();
	};

	// 필터 적용
	handleFilterApply = (notice_seq) => {
		this.getNaverKeywordList();
	};
	
	// 정렬
	onClickSorting = (e) => {
		if (this.state.orderGubun == e.target.className.replace("sorting ","")) {
			if (this.state.sorting == "ASC")
			this.state.sorting = "DESC";
			else
			this.state.sorting = "ASC";
		} else {
			this.state.sorting = "DESC";
		}
		this.state.orderGubun = e.target.className.replace("sorting ","");
		this.state.currentPage = 1;
		// console.log(this.state.sorting +" / "+this.state.orderGubun);
		this.getNaverKeywordList()
	};

	// 엔터키 검색
	_handleKeyDown = e => {
		if (e.key === 'Enter') {
			this.state.startIdx = 0;
			this.state.activePage = 1;
			this.getDictKeywordList();
			// this.getNaverKeywordList();
		}
	  }

	// 페이징
	handlePageChange(pageNumber) {
		// console.log(`active page is ${pageNumber}`);
		this.setState({activePage: pageNumber});
		this.state.startIdx = (pageNumber * 10) - this.state.rowcnt;
		// console.log("this.state.startIdx :" + this.state.startIdx )
		this.getNaverKeywordList()
	  }  

	//   componentWillReceiveProps(nextProps, nextContext) {
	// 	// console.log(nextProps);
	// 	this.getNaverKeywordList(nextProps.match.params.id);
	//   }

	  render() {

		return (
			<div id='page-wrapper' className="container hashtag_manager_container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
				<div id="notice_list_component">
					<h3>[Admin] 해시태그 관리 <div className="fa fa-question-circle hash-question">
						<div className="hash-question-answer padding-10">
						* 태그 사용법<br/>
							- 등록된 태그가 있으면 등록된 태그를 필수로 포함한 네이버 연관 키워드 중에 top20을 뽑습니다.<br/>
							{/* &emsp;(네이버 데이터랩 이용)<br/> */}
							- 등록된 태그가 없으면 자동으로 네이버 연관 키워드 목록중에 top20을 뽑습니다. <br/>
							&emsp;(제외단어 + new Trend 단어는 제외)<br/>
						</div>	
					</div> </h3>
					<br/>
					<div className="margin-t-10 innerMiddleTrendBoard" style={{boder:"0px"}}>
						<div className="width-50-dis5 padding-l-4"><h4>사전키워드</h4></div>
						<div className="width-50-dis5 padding-l-4"><div className="float-left"><h4>해시태그</h4></div><div className="btmSave float-right margin-t-5 fa fa-save flexButton" onClick={this.onClickSave}> 저장</div></div>
					</div>
					<div className="margin-t-10 margin-b-10 innerMiddleTrendBoard">
						<div className="shadow-box width-50-dis5 margin-r-5 padding-10 innerLeftTrendBoard">
							<div style={{display:"flex", marginBottom: '12px'}}>
								<input type="text" onChange={(e) => {
									// console.log(e.target.value)
									this.setState({ search_value: e.target.value })
								}} placeholder="검색어를 입력하세요." style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px", fontSize:'14px'}} onKeyDown={this._handleKeyDown}></input>
								<button className = "greenButton admin_search_btn" onClick={this.getDictKeywordList}>검색</button>
							</div>
							<div className="over-auto margin-b-10 tagManager-KeywordArea" style={{height:"253px"}}>
							{this.state.keywordDictList.map((keyword, idx)=> (
								<div className="keyword" key={keyword.dict_seq} id={keyword.dict_seq} onClick={(e) => this.onClikcKeyword(e, keyword.keyword)}>
									{idx+1}. {keyword.keyword}
								</div>	
							))}
							</div>
						</div>
						<div className="innerRightTrendBoard shadow-box width-50-dis5 margin-l-5 rightTrendBoard" style={{height:"330px"}}>
							<div id="hashtagArea" className="flexBox-align-start-center over-auto" style={{width:"100%",flex:"1", border:"0", maxHeight: "none",borderRadius: "0px"}}>
								{/* <div className="delBox">#프릴원피스 <i className='fa fa-times-circle'></i></div>
								<div className="delBox">#미니원피스 <i className='fa fa-times-circle'></i></div>
								<div className="delBox">#롱원피스 <i className='fa fa-times-circle'></i></div>
								<div className="delBox">#하객패션 <i className='fa fa-times-circle'></i></div>
								<div className="delBox">#신학기패션 <i className='fa fa-times-circle'></i></div>
								<div className="delBox">#가디건 <i className='fa fa-times-circle'></i></div>
								<div className="delBox">#가디건 <i className='fa fa-times-circle'></i></div>
								<div className="delBox">#가디건 <i className='fa fa-times-circle'></i></div> */}
							</div>
							<div className="flexBox-align-start-center" style={{border:"0", maxHeight: "none"}}>
								<button className="greenButton hashtag_add_btn" id="hashTagAdd">
								태그 수동 추가 +
								</button>
							</div>
						</div>
					</div>
					<div className="margin-t-20 padding-l-4"><h4> 네이버 연관 키워드</h4></div>
					<div className="shadow-box margin-r-5 padding-10 margin-t-20 innerLeftTrendBoard over-auto" style={{height:"auto"}}>
					<div className="width-100">
						<div className="col-lg-12" style={{padding:"0px"}}>
							<div className="ibox float-e-margins margin-b-20 width-100" style={{border:"1px solid #b6b6b6", padding:"10px", borderRadius: "5px"}}>
								<div className="ibox-title" style={{marginBottom: "15px"}}>
									<h5 style={{display:"inline-block"}}>필터 추가</h5>
									<FileDownloadComponent url={"/rest/common/naver/keyword/excel?"+
																	"&searchKeyword=" + this.state.searchKeyword +
																	"&shop_name=" + this.state.shopNameKeyword +
																	"&order_gubun=" + this.state.orderGubun +
																	"&order_type="+ this.state.sorting}
														   obj={this.state.filterList}
														   val1={this.state.searchKeyword} />
								</div>
								<div className="ibox-content">
									<div className="input-group">
										<div className="input" style={{display: "table-cell", width: "15%"}}>
											<select name="filterCateSelect" id="filterCateSelect">
												<option value="monthlyPcQcCnt">월간검색수(PC)</option>
												<option value="monthlyMobileQcCnt">월간검색수(Mobile)</option>
												<option value="monthlyAvePcClkCnt">월평균클릭수(PC)</option>
												<option value="monthlyAveMobileClkCnt">월평균클릭수(Mobile)</option>
												<option value="monthlyAvePcCtr">월평균클릭률(PC)</option>
												<option value="monthlyAveMobileCtr">월평균클릭률(Mobile)</option>
												<option value="plAvgDepth">월평균노출광고수</option>
											</select>
										</div>
										<div className="input" style={{display: "table-cell", width: "10%"}}>
											<select name="filterOperCateSelect" id="filterOperCateSelect">
												<option value=">">&gt; (초과)</option>
												<option value="<">&lt; (미만)</option>
												<option value=">=">&gt;= (이상)</option>
												<option value="<=">&lt;= (이하)</option>
												<option value="=">= (같음)</option>
											</select>
										</div>
										<input type="text" id="submitFilter" placeholder="필터 값을 입력해주세요." name="submitFilter" className="input form-control"
												style={{border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px", fontSize:'14px'}}/>
										<span>
											<button id="btnAddFilter" type="button" className="greenButton" style={{marginTop: "0px", height:"100%"}} onClick={this.filterStackController}>
												<i className="fa fa-plus"></i> Add
											</button>
										</span>
									</div>
									<div class="filter-group">
										<div className="flex-group" id="filterListView">

										</div>
										<div style={{height:"100%", marginTop: "auto", textAlign: "center"}}> 
											<button id="btnFilterApply" className="greenButton width-100" style={{height:"33.5px", marginBottom: "5px"}} onClick={this.getNaverKeywordList}>
												필터 적용
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
						<div className="table">
							<table  style={{verticalAlign: "middle"}}>
								<colgroup>
									<col width="10%"/> 
									<col width="8%"/> 
									<col width="10%"/> 
									<col width="10%"/> 
									<col width="10%"/> 
									<col width="10%"/> 
									<col width="10%"/> 
									<col width="10%"/> 
									<col width="6%"/> 
									<col width="7%"/> 
									<col width="9%"/> 
									<col width="9%"/> 
									<col width="15%"/> 
								</colgroup>
								<thead className="depthThead">
									<tr>
										<th rowSpan="2" className="text-center vertical-middle">사전키워드</th>
										<th rowSpan="2" className="text-center vertical-middle">관련키워드</th>
										<th colSpan="2" className="text-center vertical-middle">월간검색수</th>
										<th colSpan="2" className="text-center vertical-middle">월평균클릭수</th>
										<th colSpan="2"className="text-center vertical-middle">월평균클릭률</th>
										<th rowSpan="2" className="text-center vertical-middle">월간노출광고수</th>
										<th rowSpan="2" className="text-center vertical-middle">경쟁정도</th>
										<th rowSpan="2" className="text-center vertical-middle">최초 수집일</th>
										<th rowSpan="2" className="text-center vertical-middle">마지막 수집일</th>
										<th rowSpan="2" className="text-center vertical-middle">태그 추가</th>
									</tr>
									<tr>
										<th className="sorting monthlyPcQcCnt" onClick={this.onClickSorting}>PC</th>
										<th className="sorting monthlyMobileQcCnt" onClick={this.onClickSorting}>모바일</th>
										<th className="sorting monthlyAvePcClkCnt" onClick={this.onClickSorting}>PC</th>
										<th className="sorting monthlyAveMobileClkCnt" onClick={this.onClickSorting}>모바일</th>
										<th className="sorting monthlyAvePcCtr" onClick={this.onClickSorting}>PC</th>
										<th className="sorting monthlyAveMobileCtr" onClick={this.onClickSorting}>모바일</th>
									</tr>
								</thead>
								<tbody>
									{this.state.naver_list.map(naver => (
										<tr key={naver.relKeyword_seq}>
											<td>
												<p className="card-text">{naver.keyword}</p>
											</td>
											<td>
												<p className="card-text relKeyword">{naver.relKeyword}</p>
											</td>
											<td>
												<p className="card-text">{naver.monthlyPcQcCnt}</p>
											</td>
											<td>
												<p className="card-text">{naver.monthlyMobileQcCnt}</p>
											</td>
											<td>
												<p className="card-text">{naver.monthlyAvePcCtr}</p>
											</td>
											<td>
												<p className="card-text">{naver.monthlyAveMobileCtr}</p>
											</td>
											<td>
												<p className="card-text">{naver.monthlyAvePcClkCnt}</p>
											</td>
											<td>
												<p className="card-text">{naver.monthlyAveMobileClkCnt}</p>
											</td>
											<td>
												<p className="card-text">{naver.plAvgDepth}</p>
											</td>
											<td>
												<p className="card-text">{naver.compIdx}</p>
											</td>
											<td>
												<p className="card-text">{naver.reg_date}</p>
											</td>
											<td>
												<p className="card-text">{naver.mod_date}</p>
											</td>
											<td>
												<button className="greenButton btnAddNaverKeyword" style={{width:"42px", padding:"0px"}}>
													추가
												</button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
						<Pagination
							activePage={this.state.activePage}
							itemsCountPerPage={this.state.rowcnt}
							totalItemsCount={this.state.totalCnt}
							pageRangeDisplayed={5}
							onChange={this.handlePageChange.bind(this)}
						/>
					</div>
				</div>
			</div>
		);
	}
}

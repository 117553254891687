import React from 'react';
// import ReactPixel from 'react-facebook-pixel';
import {ga4react, reactPixel} from '../Menu'
import axios from 'axios';
import http from '../modules/api';
import { Link } from 'react-router-dom';
import '../../css/style.css';
import * as navigator from '../modules/navigator';
import MetaTags from 'react-meta-tags';
// import Bookmark from 'react-bookmark';
import Inko from 'inko';
// import { Switch } from 'react-router-dom/cjs/react-router-dom.min';
import {BrowserRouter, Route, Switch, HashRouter} from "react-router-dom";

import {Login_v3} from "./Login_v3";
import {MarketingArea} from "./MarketingArea";
import {IdSearch} from "../IdSearch";
import {PwdSearch} from "../PwdSearch";

// ReactPixel.init('438227415050093');
let inko = new Inko();

export class LoginParent_v2 extends React.Component {
	
	state = {
			setting_login_slider : {
				// variableWidth:true,
				dots: true,
				infinite: true,
				speed: 300,
				slidesToShow: 1,
				slidesToScroll: 1,
				// autoplay : true,
				autoplay : true,
				responsive: [ // 반응형 웹 구현 옵션
					// {  
					// 	breakpoint: 960, //화면 사이즈 960px
					// 	settings: {
					// 		//위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
					// 		slidesToShow:5 
					// 	} 
					// },
					// { 
					// 	breakpoint: 639, //화면 사이즈 639px
					// 	settings: {	
					// 		//위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
					// 		slidesToShow:4,
					// 		slidesToScroll: 4
					// 	} 
					// }
				],
				customPaging: i => (
					<div className="ft-slick__dots--custom">
						<div className="login-slider-custum-dot" />
					</div>
				)
			},
	}
	
	handleText = (e, key) => {
		// computed property
		if(key == "password") {
			// console.log(inko.ko2en(e.target.value));
			this.setState({[key]: inko.ko2en(e.target.value)});
		} else {
			// console.log(e.target.value);
			this.setState({[key]: e.target.value});
		}
	}
	
	handleKeyPress = (e) => {
	    if (e.key === "Enter") {
	    	this.fn_loginProcess();
	    }
	}

	onClickLoginButton = () => {
		this.fn_loginProcess();
	};

	onClickDemoButton = () => {
		sessionStorage.setItem("user_id", "DemoId");
		sessionStorage.setItem("userName","DemoUser");
		sessionStorage.setItem("roleType","none");
		sessionStorage.setItem("redirectUrl","/demo/trend/current");
		sessionStorage.setItem("solution_type_cd","");
	};
	
	fn_loginProcess() {
		const sendForm = {...this.state};
		http.post("/loginProcess", null, {params:sendForm, Pragma: "no-cache"})
			 .then(
					 resultData => {
						 var resUrl = resultData.request.responseURL;
						 console.log(resultData);
						 //console.log(resultData.headers['location']);
						 //console.log(resultData.response.location);
						 //alert("######### resUrl : " + resUrl + "    "+ resultData.request.config.url);
						 
						 if (resultData.request.responseURL == null) {
							 resUrl = "/";
						 } else if (resultData.request.responseURL.indexOf("error") > 0) {
	                        alert("가입하지 않은 아이디이거나, 잘못된 비밀번호입니다.");
	                        return false;
	                        // window.location.href = "/login"
						 }
						 this.fn_loginLog();
						 window.location.href = resUrl;
						 // 로그인전 접근 페이지가 아래의 경로일때만
//						 if (resUrl.indexOf("/shop/pwdMod") > 0) {
//							 //window.location.href = resUrl;
//						 } else {
//							 //window.location.href = "/";
//						 }						 
					 })
			 .catch(
					 //error => { alert("인증되지 않은 사용자 입니다."); console.log(error); }
			 );
	}

	fn_loginLog() {

		const browser = navigator.detectBroeser();
		const os = navigator.detectOS();
		const device = navigator.detectDevice();

		http.get("/rest/common/loginLog", {
			params:{
				browser: browser,
				os: os,
				device: device
			}
		})
		.then(
			resultData => {
				console.log(resultData.data);
		})
		.catch(
			error => {
				console.log(error);
			}
		);
	}

	componentDidMount() {
//		var referrer = sessionStorage.getItem("referrer");
//		if (referrer != null) {
//			//console.log("referrer : " + referrer);
//			sessionStorage.setItem("referrer", document.referrer);
//		}
		// axios.get("/logout")
		// .then(res => {
		// 	// alert("세션이 끊어졌습니다.")
		// })
		// .catch(error => {
		// 	// console.log (error);
		//  });
	}

	// 서비스 소개서 다운로드
	serviceDownload = () => {
		// let pdf_url = 'https://s3.ap-northeast-2.amazonaws.com/otdeal.file/221115_%EB%A1%9C%EB%B3%B4MD+%EC%84%9C%EB%B9%84%EC%8A%A4%EC%86%8C%EA%B0%9C%EC%84%9C.pdf';
		let pdf_url = 'https://s3.ap-northeast-2.amazonaws.com/otdeal.file/robomd_service_info/AI%E1%84%85%E1%85%A9%E1%84%87%E1%85%A9MD+%E1%84%89%E1%85%A9%E1%84%80%E1%85%A2%E1%84%89%E1%85%A5.pdf'; // 23년 11월 22일 변경
		window.open(pdf_url);

		ga4react.event('[로그인] 서비스 소개서 다운로드', '서비스 소개서 다운로드', '로그인');
		reactPixel.trackCustom('[로그인] 서비스 소개서 다운로드');
	}

	// 회사 소개서 다운로드
	// aboutUsDownload = () => {
	// 	let pdf_url = 'https://s3.ap-northeast-2.amazonaws.com/otdeal.file/robomd_documents/2022/%EC%95%84%EC%9D%B4%ED%81%B4%EB%A0%88%EC%9D%B4%EB%B8%8C_%ED%9A%8C%EC%82%AC%EC%86%8C%EA%B0%9C%EC%84%9C.pdf';
	// 	window.open(pdf_url);

	// 	ga4react.event('[로그인] 회사 소개서 다운로드', '회사 소개서 다운로드', '로그인');
	// 	reactPixel.trackCustom('[로그인] 회사 소개서 다운로드');
	// }

	render() {
		return (
			<div className="login-container" style={{minHeight: "100vh", position: "relative", minWidth:"900px"}}>
				<MetaTags>
					<title>로보MD | iclave </title>
					<meta name="description" content="MD를 도와주는 똑똑한 인공지능, 로그인" />
					<meta property="og:title" content="로그인 | 로보MD" />
				</MetaTags>
				<div id='non-page-wrapper mobile-non-page-wrapper' style={{margin:0}}>
					{/* <Bookmark className="coolClass" href="/" title="ROBOMD" /> */}
					<div className="mobile-flex-wrap middle-box middle-box-mobile mobile_padding_bottom_0 text-center" style={{display:"flex", maxWidth:"100%", paddingTop:0, height:"auto", minHeight:"100vh"}}>
						{/* <div className="login-50-box slick-no-blinker" style={{width:"50%", padding:"0 6%"}}> */}
						<div className="slick-no-blinker mobile-width-100" style={{width:"50%", padding:"0 6%", margin: "0 auto"}}>
							<Switch>
								{/* <Route exact path="/login" component={Login}></Route>		 */}
								<Route exact path="/common/Login_v3" component={Login_v3}></Route> 				
								<Route exact path="/common/id/search" component={IdSearch}></Route>
								<Route exact path="/common/pwd/search" component={PwdSearch}></Route>				
							</Switch>
						</div>
						<div className="mobile-width-100" style={{width:"50%", backgroundColor:"#00e2e6", position:"relative", margin: "0 auto", overflow:"hidden"}}>
							<MarketingArea/>
						</div>
					</div>
				</div>	

				<footer className="mobile-bottom-11"  style={{padding:"0 2.5%", position:"absolute", bottom:"82px", width:"100%", display:"none"}}>
					<div style={{display:"flex", flexWrap:"wrap", justifyContent:"space-between"}}>
						<div style={{display:"flex", marginBottom:"20px"}}>
							<div>
								<div style={{fontSize:"13px", fontWeight:"bold", marginBottom:"15px"}}>
									정책
								</div>
								<div style={{fontSize:"12px"}}>
									<a href="/common/FormViewer/9001" target="blank">이용약관</a>
								</div>
								<div style={{fontSize:"12px", color: "#10CFC9"}}>
									<a href="/common/FormViewer/9002" target="blank">개인정보 처리방침</a>
								</div>
							</div>
							<div style={{marginLeft:"31px"}}>
								<div style={{fontSize:"13px", fontWeight:"bold", marginBottom:"15px"}}>
									회사
								</div>
								<div style={{fontSize:"12px"}}>
									<a href="https://iclave.co.kr" target="blank">회사소개</a>
								</div>
							</div>
						</div>
						<div style={{textAlign:"right", marginBottom:"20px"}}>
							<div className="mobile_width_100" style={{display:"flex", flexWrap:"wrap", flexDirection:"column"}}>
								<div className="mobile_align_left" style={{fontSize:"13px", fontWeight:"bold", marginBottom:"15px"}}>
									아이클레이브
								</div>
								<div className="mobile_align_left" style={{fontSize:"12px"}}>
									서울특별시 강남구 테헤란로2길 27 패스트파이브(강남 5호점) 빌딩 1308호
								</div>
							</div>
							<div style={{display:"flex", flexWrap:"wrap", fontSize:"12px"}}>
								<div className="mobile_width_100" style={{display:"flex"}}>
									<div>
									Tel
									</div>
									<div style={{fontWeight: "bold", fontSize:"12px", marginLeft:"6px"}}>
										<a href="tel:02-6383-7976" target="blank">02-6383-7976</a>
									</div>
									<div style={{marginLeft:"15px"}}>
									Email
									</div>
									<div style={{fontWeight: "bold", fontSize:"12px", marginLeft:"6px"}}>
										<a href="mailto:robomd@robomd.io" target="blank">robomd@robomd.io</a>
									</div>
								</div>
								<div className="mobile_width_100" style={{display:"flex"}}>
									<div className="mobile_margin_left_0" style={{marginLeft:"15px"}}>
									대표이사
									</div>
									<div style={{fontWeight: "bold", fontSize:"12px", marginLeft:"6px"}}>
									최윤내
									</div>
									<div style={{marginLeft:"15px"}}>
									사업자등록번호
									</div>
									<div style={{fontWeight: "bold", fontSize:"12px", marginLeft:"6px"}}>
									381-81-00136
									</div>
								</div>
							</div>
						</div>
					</div>
				</footer>
				</div>
			
		);
	}
	
}
import React, { useCallback, useState } from 'react';
import GA4React from "ga-4-react";
import axios from 'axios';
import http from './modules/api';

import { BrowserRouter } from 'react-router-dom';
import x_img from '../resources/img/x.svg';

//import {
//  Collapse,
//  Navbar,
//  NavbarToggler,
//  NavbarBrand,
//  Nav,
//  NavItem,
//  UncontrolledDropdown,
//  DropdownToggle,
//  DropdownMenu,
//  DropdownItem } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/menu_style.css';
import '../css/common_module_style.css';
import '../css/font-awesome-4.6.3/css/font-awesome.css';
// import Button from 'react-bootstrap/Button';
import $ from 'jquery';

import * as StringUtil from './modules/utils/StringUtil';
import shopIcon from '../resources/img/main/icn_sale_shop_store.svg';
import logo2 from '../resources/img/main/ROBOMD_logo_nuki.png';
import logo3 from '../resources/img/main/ROBOMD_logo_nuki.png';
import logo4 from '../resources/img/icn_logo_v2.svg';
import logo_mobile from '../resources/img/favicon_v2.svg';
import icn_hamburger_v2 from '../resources/img/icn_hamburger_v2.svg';
import icn_hamburger_v2_2 from '../resources/img/icn_hamburger_v2_2.svg';
import shop_store_icon from '../resources/img/shop-store-icon.svg';

import pc_maison_logo from "../resources/img/pc_maison_logo.jpeg";

import round_loading_bar from '../resources/img/round_loading_bar.gif';

import hamburger from '../resources/img/trend_hamburger.png';

import menu_icon from '../resources/img/main/icn_ArrowSquareUp.png';

import { Link, NavLink } from 'react-router-dom';
import ChannelService from './modules/ChannelService';

import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { th } from 'date-fns/locale';

import Slider from 'react-slick';
import { useDropzone } from 'react-dropzone';

import * as StyleUtil from './modules/utils/StyleUtil';

import ToggleButton from './modules/common/ToggleButton';
import ConfirmDialogV2 from './modules/dialogs/ConfirmDialogV2';
import { getCookie } from "./modules/utils/CookieUtil";
import { setCookie } from "./modules/utils/CookieUtil";
import { deleteCookie } from "./modules/utils/CookieUtil";

import DownloadManager from './modules/CommonDownloadManager';

import ReactPixel from 'react-facebook-pixel';
export const reactPixel = ReactPixel;
export let ga4react = new GA4React('G-VR4MJ2KSB1');
const domain = document.domain;

if(domain.indexOf("127.0.0.1") == -1
    && domain.indexOf("localhost") == -1
    && domain.indexOf("otdeal.me") == -1) { // 로컬이나 테스트 서버를 제외한 나머지 도메인에서만 GA/페이스북 픽셀을 실행
  if(domain.indexOf("robomdaiimage.com") >= 0) { // 로보이미지 마켓팅용 도메인인 경우
    ga4react = new GA4React('G-S1KYGY4L2G');
    reactPixel.init('833859754809649');
    // console.log("##### reactPixel 1 #####", reactPixel)
  } else {
    ga4react = new GA4React('G-VR4MJ2KSB1');
    reactPixel.init('438227415050093');
    // console.log("##### reactPixel 2 #####", reactPixel)
  }
}


var user_id = getCookie('user_id');
var userName = sessionStorage.getItem('userName');
var roleType = sessionStorage.getItem('roleType');
var redirectUrl = sessionStorage.getItem('redirectUrl');
var solution_type_cd = getCookie('solution_type_cd');
var serviceType = sessionStorage.getItem("serviceType");
sessionStorage.setItem('user_id', user_id);
sessionStorage.setItem('solution_type_cd', solution_type_cd);

var isanotherBannerLoaded = true;

var is_reduct_left_menu = false;

// Boot Channel as an anonymous user
ChannelService.boot({
  pluginKey: 'f1c88265-51f4-4bc2-81e8-c3f7a6146cdb', //please fill with your plugin key
});

/**
 * 서브 메뉴
 * @param {} props
 */
function Active_SubMenu(props) {
  return (
    <>
      {
        // props.menu_access == '1' ?   10CFC9   F5F5F7
        <>
          {/* <div style={{width:"22px", height:"50px", borderRight:"3px solid #F5F5F7", float:"left"}}></div> */}
          <div
            className="main_menu_area hover_menu"
            style={{
              width: '100%',
              height: '50px',
              padding: '10px 20px 10px 20px',
              borderTop: props.sub_idx > 0 ? '1px solid #EFEFF0' : '',
              boxShadow:
                props.sub_idx == 0 ? 'rgb(0 0 0 / 5%) 0px 4px 15px inset' : '',
              //padding:"10px 5px"
            }}
          >
              {props.menu_img !== undefined &&
                props.menu_img !== 'undefined' ? (
                <div style={{ width: '32px', height: '32px', float: 'left' }}>
                  <img src={props.menu_img + '_active.svg'} />
                </div>
              ) : (
                ''
              )}
            {
              
              (props.this.state.role_cls == '2202' || props.this.state.role_cls == '2208' || props.this.state.role_cls == '2212' 
                || props.this.state.role_cls == '2203' || props.this.state.role_cls == '2209' || props.this.state.role_cls == '2210') 
                || (props.this.state.shopActiveServiceCnt != null && props.this.state.shopActiveServiceCnt > 0)
              ?
              <NavLink
                to={props.menu_url}
                style={{
                  color: '#10CFC9',
                  position: 'absolute',
                  width: '100%',
                  height: '40px',
                  padding: '8px 45px',
                  borderRadius: '10px',
                  top: '6px',
                  fontWeight: '700',
                }}
                onClick={
                  // <a href={props.menu_url} style={{position:"absolute", width:"100%", height:"40px", padding:"8px 45px", borderRadius:"10px", top: "5px", fontWeight: "normal"}} onClick={
                  (e) => {
                    props.this.setState({
                      currentLocationHref: window.location.href,
                    });
                    props.this.mobileMenuClick(e);
                    window.scrollTo(0, 0);
                  }
                }
              >
                {props.menu_nm}
                {/* </a> */}
              </NavLink>
              :  <a onClick={()=> alert('쇼핑몰 연동 후 이용하실 수 있습니다.')} style={{padding:'7px 10px 7px 52px'}}>{props.menu_nm}</a>
            }
          </div>
        </>
        // :   <span onClick={()=> alert('해당 메뉴의 권한이 없습니다.')} style={{padding:'7px 10px 7px 52px'}}>{props.menu_nm}</span>
      }
    </>
  );
}

function SubMenu(props) {
  return (
    <>
      {
        // props.menu_access == '1' ?   10CFC9   F5F5F7
        <>
          {/* <div style={{width:"22px", height:"50px", borderRight:"3px solid #F5F5F7", float:"left"}}></div> */}
          <div
            className="main_menu_area hover_menu"
            style={{
              width: '100%',
              height: '50px',
              padding: '10px 20px 10px 20px',
              borderTop: props.sub_idx > 0 ? '1px solid #EFEFF0' : '',
              boxShadow:
                props.sub_idx == 0 ? 'rgb(0 0 0 / 5%) 0px 4px 15px inset' : '',
              //padding:"10px 5px"
            }}
          >
              {props.menu_img !== undefined &&
                props.menu_img !== 'undefined' ? (
                <div style={{ width: '32px', height: '32px', float: 'left' }}>
                  <img src={props.menu_img + '.svg'} />
                </div>
              ) : (
                ''
              )}
            {
              (props.this.state.role_cls == '2202' || props.this.state.role_cls == '2208' || props.this.state.role_cls == '2212' 
                || props.this.state.role_cls == '2203' || props.this.state.role_cls == '2209' || props.this.state.role_cls == '2210') 
                || (props.this.state.shopActiveServiceCnt != null && props.this.state.shopActiveServiceCnt > 0)
              ?
              <NavLink
                to={props.menu_url}
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '40px',
                  padding: '8px 45px',
                  borderRadius: '10px',
                  top: '6px',
                  fontWeight: 'normal',
                }}
                onClick={
                  // <a href={props.menu_url} style={{position:"absolute", width:"100%", height:"40px", padding:"8px 45px", borderRadius:"10px", top: "5px", fontWeight: "normal"}} onClick={
                  (e) => {
                    props.this.setState({
                      currentLocationHref: window.location.href,
                    });
                    props.this.mobileMenuClick(e);
                    window.scrollTo(0, 0);
                  }
                }
              >
                {props.menu_nm}
                {/* </a> */}
              </NavLink>
              :
              <a 
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '40px',
                  padding: '8px 45px',
                  borderRadius: '10px',
                  top: '6px',
                  fontWeight: 'normal',
                }}
                onClick={()=> alert('쇼핑몰 연동 후 이용하실 수 있습니다.')}
              >{props.menu_nm}</a>
            }
          </div>
        </>
        // :   <span onClick={()=> alert('해당 메뉴의 권한이 없습니다.')} style={{padding:'7px 10px 7px 52px'}}>{props.menu_nm}</span>
      }
    </>
  );
}

function MyDropzone(props) {
  //const [uploadFiles, imageArray] = useState([]);

  const onDrop = useCallback(async (acceptedFiles) => {
    //props.attachImage(acceptedFiles)
    props.this.attachImage(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const InputProps = {
    ...getInputProps(),
    multiple: true,
    accept: 'image/jpg,image/png,image/jpeg,image/gif,image/bmp,image/tiff',
  };

  const RootProps = {
    ...getRootProps(),
  };

  return (
    <div
      {...getRootProps()}
      style={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '12px',
        cursor: 'pointer',
        backgroundColor: '#F2F2F2',
      }}
    >
      <input type="file" {...InputProps} />
      {isDragActive ? (
        <h5>이제 이미지를 놓아주세요.</h5>
      ) : (
        <h5>
          이곳에 사진 파일 1개를 끌어와 놓거나, 클릭하여 올려주세요.
          <br />
          <br />
          {/* <b>파일명은 꼭 영어</b>로 해주세요.
          <br /> */}
          <b>jpg, jpeg, png, gif, bmp</b>파일만 업로드할 수 있습니다.
        </h5>
      )}
    </div>
  );
}

var this_component;
export function call_checkUser_for_other_component() {
  this_component.checkUser();
}
export function call_onChangeIsAddShopButtonClick_for_other_component(state, kind) {
  if(kind==1){ // 1의 경우 banner
    this_component.onChangeIsAddShopBannerButtonClick(state);
    // 팝업 활성화시 무료체험종료일자 조회하기
    this_component.selectFreeDateEndDateAndEventPeriod();
  } else{ // 2의 경우 exhibition (진열)
    this_component.onChangeIsAddShopExhibitionButtonClick(state);
  }

}
export function call_openChannelTalk() {
  this_component.openChannelTalk();
}
export function openBlankModal(content_html) {
  this_component.setState({
    is_blank_popup_open : true,
    blank_popup_content : content_html
  }, () => {

  });
}
export function call_userGuide(url) {
  this_component.openUserGuide(url);
}

export function expansionLeftMenu(){
  this_component.onClickExpansionButton();
}

export function reductionLeftMenu(){
  this_component.onClickReductionButton();
}

/**
 * 현재 설정된 shop_no 반환
 * @returns shop_no
 */
export function call_shop_no(){
  return this_component.state.shop_no;
}

/* 
* 랜덤 난수 생성기
* min: 최소값,
* max: 최대값,
* preNum: 이전에 생성된 난수 (중복 허용 하고 싶으면 최소~최대 범위 밖의 아무 숫자나 넣으면 됨.)
 */ 
export function randomNum(min, max, preNum){
  var randNum = Math.floor(Math.random()*(max-min+1)) + min;
  while(true) {
      if (preNum == randNum) { // 이전에 생성 된 난수와 같은 값을 생성한 경우.
          randNum = Math.floor(Math.random()*(max-min+1)) + min; // 난수 재생성.
      } else {
          break; // 난수 생성 종료.
      }
  }
  return randNum;
}

export class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
  }

  state = {
    isOpen: false,
    userName: '',
    sessionType: '',
    roleType: '',
    redirectUrl: '',
    solution_type_cd: '',
    set_token: '',
    logoutText: 'Log out',
    role_cls: '',
    shop_no: '',
    service_type: '',
    shopSyncState: '',
    menu: [],
    shops: [],
    commonActiveMenuClass: '',
    currentLocationHref: '' /*선택메뉴 url*/,

    is_add_shop_button_click: false,
    is_add_shop_banner_button_click: false, // 배너 쇼핑몰 연동시 true
    is_add_shop_exhibition_button_click: false, // 진열 쇼핑몰 연동시 true

    is_sample_banner_popup_open: false,
    banner_templa_list: [],
    sample_banner_step: '1',
    imageArray: [],

    setting_sample_banner_slider: {
      // variableWidth:true,
      dots: false,
      arrows: true,
      infinite: true,
      speed: 150,
      autoplaySpeed: 1800,
      slidesToShow: 1,
      slidesToScroll: 1,
      // autoplay : true,
      autoplay: true,
      responsive: [
        // 반응형 웹 구현 옵션
        // {
        // 	breakpoint: 960, //화면 사이즈 960px
        // 	settings: {
        // 		//위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
        // 		slidesToShow:5
        // 	}
        // },
        // {
        // 	breakpoint: 639, //화면 사이즈 639px
        // 	settings: {
        // 		//위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
        // 		slidesToShow:4,
        // 		slidesToScroll: 4
        // 	}
        // }
      ],
      customPaging: (i) => (
        <div className="ft-slick__dots--custom">
          <div className="login-slider-custum-dot" />
        </div>
      ),
    },

    end_date_format: '0000년 00월 00일',
    event_period: '3',

    is_blank_popup_open : false,
    blank_popup_content : "",

    /** 큐레이션 설치 여부 / 진열 여부 */
    curation_install_yn: "N",
    curation_display_yn: "N",

    /** 확인,취소 다이얼로그 */
    confirm_dialog_show_state : false,
    confirm_dialog_content : "",
    confirm_dialog_button_list : [],
  };

  componentWillMount() {
    //권한정보 조회
    this.checkUser();
  }

  componentWillUnmount() {}

  // 다른 영역 클릭시 활성화된 메뉴 닫기
  handleClickOutside(event) {
    const commonActiveMenuClass = this.state.commonActiveMenuClass;
    if (commonActiveMenuClass != event.target.className) {
      this.setState({ commonActiveMenuClass: '' });
    }
  }

  mainMenuClick = (id) => {
    console.log(id);
    //console.log($(id).css())
    // let active_yn = $(id+'.active');
    // console.log(active_yn)
    // if (active_yn == null) {
    // 	$(id).addClass('active');
    // } else {
    // 	$(id).removeClass('active');
    // }
    $(id).toggleClass('active');
  };

  mainMenuActive = (e, id) => {
    console.log(id);
    //console.log($(id).css())
    // let active_yn = $(id+'.active');
    // console.log(active_yn)
    // if (active_yn == null) {
    // 	$(id).addClass('active');
    // } else {
    // 	$(id).removeClass('active');
    // }
    $(id).removeClass('active');
    $(id).addClass('active');
    this.onClickExpansionButton(e);
  };

  mobileMenuClick = (e) => {
    this.setState({
      ...this.state,
    });
    $('.mobile_side').toggleClass('active');
  };

  handleClick = (event) => {
    const commonActiveMenuClass = this.state.commonActiveMenuClass;
    console.log('##### event.target.className' + event.target.className);
    console.log(event.target);
    console.log('##### handleClick state' + commonActiveMenuClass);

    if (commonActiveMenuClass != '') {
      this.setState({ commonActiveMenuClass: '' });
    } else {
      this.setState({ commonActiveMenuClass: event.target.className });
    }
  };

  componentDidMount(e) {
    this_component = this;
    this.onClickExpansionButton(e);
    this.setState({
      currentLocationHref: window.location.href,
    });

    // 진열 관리 페이지일 경우 왼쪽 영역은 닫은 상태가 기본값
    //if(window.location.href.indexOf("/shop/rp/test3/product/list") >= 0){
    if(window.location.href.indexOf("/shop/rp/event/product/list") >= 0 || window.location.href.indexOf("/shop/rp/event/product/cafe24List") >= 0){
      is_reduct_left_menu = true;
    }
  }

  componentDidUpdate() {
    // 메뉴 외의 영역 클릭시 이벤트
    if (this.state.commonActiveMenuClass != '') {
      document.addEventListener(
        'mousedown',
        this.handleClickOutside.bind(this),
      );
    } else {
      document.removeEventListener(
        'mousedown',
        this.handleClickOutside.bind(this),
      );
    }
  }

  // [공통] 사용자 ShopList 조회
  // async selectUserShops() {
  // 	axios.get(`/rest/common/selectUserShops`)
  // 	.then(
  // 		res => {
  // 			this.setState({shops : res.data})
  // 		}
  // 	)
  // }

  // [공통] 사용자 메뉴 + 샵(세션) 조회
  async selectUserMenu() {
    let installBannerCnt = sessionStorage.getItem("isLoadBannerInstallCntForMenu");
    if (installBannerCnt == null) {
      installBannerCnt = "false";
    }
    axios
      .get(`/rest/common/selectUserMenu_V2?roleType=` + this.state.roleType+"&isLoadBannerInstallCntForMenu="+installBannerCnt)
      .then((res) => {
        const { data } = res;
        if (installBannerCnt != null && installBannerCnt == "true") { // 로그인 또는 세션전환을 한 경우 true
            sessionStorage.setItem("installBannerCnt", data.installBannerCnt);
            sessionStorage.setItem("isLoadBannerInstallCntForMenu", false); // 세션 체인지 끝나면 바로 false로 바꿈.
            // alert("installBannerCnt: "+Number(sessionStorage.getItem("installBannerCnt")));
        }
        this.setState({
          role_cls: data.role_cls,
          shop_no: data.shop_no,
          service_type: data.service_type,
          shops: data.shopsList,
          menu: data.resultMenuList,
          installBannerCnt: Number(sessionStorage.getItem("installBannerCnt")),
          //service_type_list: res.data.service_type_list,
          shopActiveServiceCnt: res.data.shopActiveServiceCnt
        }, () => {
          if (this.state.service_type === "01007") {
            this.checkCurationInstallAndDisplay();
          }
        });

        if (window.location.href.indexOf('/shop/cafe24') > 0 || window.location.href.indexOf('/shop/rp') > 0) {
          if (this.state.shopActiveServiceCnt == 0) {
            alert('쇼핑몰 연동 후 이용하실 수 있습니다.');
            //window.location.href = '/shop/serviceShopAdd?service_type='+data.service_type+'&step=1';  
            window.location.href = '/shop/solutionStoreAdd';  
          }
        }

        // console.log(data.shopsList);
        // console.log(this.props.history);
        //if (data.shopsList != null && data.shopsList.length == 0) {
          // 상점 추가 페이지 이동
          // this.props.history.push('/shop/serviceShopAdd');
          // this.props.history.push(
          // 	{
          // 		pathname: `/shop/serviceShopAdd`,
          // 		// search:`?event_seq=`+event_seq,
          // 		// state: {prevPath: window.location.href}
          // 	}
          // )
          //BrowserRouter.props.redirectUrl = `/shop/serviceShopAdd`;
        //}
      });
  }

  // 큐레이션 버튼 설치여부, 진열여부 조회
  async checkCurationInstallAndDisplay() {
    await http
      .get("/rest/shop/curation/service/display")
      .then((res) => {
        this.setState({
          curation_install_yn: res.data.install_yn,
          curation_display_yn: res.data.display_yn
        })
      });
  }

  changeShowCurationService () {
    this.setState({
        confirm_dialog_show_state: true,
        confirm_dialog_content: 
          this.state.curation_display_yn === "Y" ? "큐레이션을 미노출 하시겠습니까?" : "큐레이션을 노출 하시겠습니까?",
        confirm_dialog_button_list: [
          {
              title: "네",
              type: "1",
              event_method: () => {
                const config = {
                  headers: {
                      'content-type': 'application/json;'
                  }
                };
                const body = {
                  display_yn : this.state.curation_display_yn === "Y" ? "N" : "Y"
                }
                http
                  .post("/rest/shop/curation/service/display/modify", body, config)
                  .then((res) => {
                    if (res.data.code == 200) {
                      this.setState({
                        curation_display_yn: this.state.curation_display_yn === "Y" ? "N" : "Y"
                      })
                    }
                  });

                  // 닫기
                  const window_scrollTop = $(window).scrollTop();
                  this.setState({confirm_dialog_show_state: false});
                  // 스크롤 고정 해제
                  StyleUtil.clearPopupNoneScroll(window_scrollTop);
              }
          },
          {
              title: "아니요",
              type: "0",
              event_method: () => {
                const window_scrollTop = $(window).scrollTop();
                this.setState({confirm_dialog_show_state: false});
                // 스크롤 고정 해제
                StyleUtil.clearPopupNoneScroll(window_scrollTop);
              }
          }
      ]
    })
  }

  // [공통] 사용자 Shop 전환
  async changeShop(e) {
    axios
      .get(`/rest/common/changeShop?shop_no=` + e.target.value)
      .then((res) => {
        const { data } = res;
        var code = data.code;
        var msg = '';
        if (code == 200) {
          msg = '멀티샵 전환 성공!';
          sessionStorage.setItem("isLoadBannerInstallCntForMenu", true);
          this.setState({
            shop_no: data.shop_no,
          });
        } else {
          msg =
            '멀티샵 전환에 실패하였습니다.\n관리자에게 문의해 주세요. (1599-3518)';
          //로그인 페이지로 리턴
        }
        //alert(msg);
        //window.location.reload();
        window.location.href = "/";
      });
  }

  // [공통] 사용자 service_type 전환
  async changeServiceType(service_type) {
    axios
      .get(`/rest/common/changeServiceType?service_type=` + service_type)
      .then((res) => {
        var code = res.data.code;
        var redirectUrl = res.data.redirectUrl;
        if (code == 200) {
          if (redirectUrl != '') {
            window.location.href = redirectUrl;
          }
          // this.setState({
          //   service_type: service_type,
          // });
        }
      });
  }

  //사용자 권한 조회
  async checkUser() {
    axios
      .get(`/userSessionType`)
      .then((res) => {
        var roleType = res.data.roleType;
        this.setState({
          isOpen: false,
          userName: res.data.userName,
          user_id: res.data.user_id,
          sessionType: res.data.sessionType,
          roleType: res.data.roleType,
          redirectUrl: res.data.redirectUrl,
          standby_yn: res.data.standby_yn,
          solution_type_cd: res.data.solution_type_cd,
        });
        if (
          window.location.href.indexOf('/login') < 0 &&
          window.location.href.indexOf('/demo') > 0
        ) {
          // roleType = 'none';
          this.setState({
            roleType: '2201',
            logoutText: '나가기',
          });
        }
        this.toggle = this.toggle.bind(this);
        if (sessionStorage.getItem('userName') == null) {
          userName = res.data.userName;
        }
        if (sessionStorage.getItem('roleType') == null) {
          roleType = res.data.roleType;
        }

        if (sessionStorage.getItem('redirectUrl') == null) {
          redirectUrl = res.data.redirectUrl;
        }

        sessionStorage.setItem('user_id', res.data.user_id);
        sessionStorage.setItem('userName', res.data.userName);
        sessionStorage.setItem('roleType', res.data.roleType);
        sessionStorage.setItem('serviceType', res.data.service_type);
        sessionStorage.setItem('redirectUrl', res.data.redirectUrl);
        sessionStorage.setItem('solution_type_cd', res.data.solution_type_cd);
        setCookie("user_id", res.data.user_id);
        setCookie("solution_type_cd", res.data.solution_type_cd);
        // this.render();
        if (
          window.location.href.indexOf('/login') < 0 &&
          window.location.href.indexOf('/demo') > 0
        ) {
          sessionStorage.setItem('user_id', 'DemoId');
          sessionStorage.setItem('userName', 'DemoUser');
          sessionStorage.setItem('roleType', '2201');
          sessionStorage.setItem('serviceType', '');
          sessionStorage.setItem('redirectUrl', '/demo/trend/current');
          sessionStorage.setItem('solution_type_cd', "");
          setCookie("user_id", "DemoId");
          setCookie("solution_type_cd", "");
          // this.setState({roleType: null});
        }

        // 로보이미지 마케팅 페이지는 비연동형 세팅 진행
        if (window.location.href.indexOf("/promo_roboimage/result") >= 0 || window.location.href.indexOf("/promo_aibanner/result") >= 0) {
          sessionStorage.setItem('solution_type_cd', "09900");
          setCookie("solution_type_cd", "09900");
        }

        //console.log("##### " + this.state.roleType);
        // NotificationManager.error('배너 호출을 위한 토큰값이 필요합니다. 클릭하여 입력 화면으로 이동', '토큰을 입력하세요!', 3600000 * 24, () => {
        // 	alert('어서.');
        // });
        // Notification
        //if (this.state.roleType == '2204') {
          //cafe24 Event Setting 여부 체크
          // this.getSetting();
          // NotificationManager.error('배너 호출을 위한 토큰값이 필요합니다. 클릭하여 입력 화면으로 이동', '토큰을 입력하세요!', 3600000 * 24, () => {
          // 	alert('어서.');
          // });
          // NotificationManager.info('배너 호출을 위한 토큰값이 필요합니다. 클릭하여 입력 화면으로 이동');
        //}

        if (res.data.userName != 'anonymousUser') {
          // Boot Channel as a registered user
          ChannelService.boot({
            pluginKey: 'f1c88265-51f4-4bc2-81e8-c3f7a6146cdb', //please fill with your plugin key
            profile: {
              name: res.data.userName, //fill with user name
              mobileNumber: res.data.tel_no, //fill with user phone number
              email: res.data.email,
              memberId: res.data.user_id,
            },
          });
        }

        if(res.data.userName == 'anonymousUser'){
            sessionStorage.removeItem("RP_LIST_OPERATION_BANNER");
        }

        if (res.data.chanel_talk == 'N') {
          // Shutdown Channel
          ChannelService.shutdown();
        }
        console.log("##### userSessionType res.data.redirectUrl : " + res.data.redirectUrl)
        // 진열 서비스 접근시 기본 세팅 여부 확인
        if (res.data.service_type == '01002') {
          if (window.location.href.indexOf('shop/rp/event/') >= 0) {
            if (window.location.href.indexOf(res.data.redirectUrl) == -1)  {
              if ((res.data.solution_type_cd != "09001" && window.location.href.indexOf('/cafe24List') >= 0) || res.data.standby_yn != "Y") {
                window.location.href = res.data.redirectUrl;
              }
              // if (res.data.standby_yn == "Y" && window.location.href.indexOf('shop/rp/event/') >= 0) {
              //   // 아무동작 안함.
              // } 
              // else if (res.data.standby_yn == null || res.data.standby_yn != "Y" || res.data.standby_yn == "Y")  {
              //   window.location.href = res.data.redirectUrl;
              // }
            }
          }
          else if (window.location.href.indexOf('shop/serviceShopAdd') >= 0) {
            if (window.location.href.indexOf(res.data.redirectUrl) == -1) {
              if (res.data.standby_yn == null || res.data.standby_yn != "Y" || res.data.standby_yn == "Y")  {
                  window.location.href = res.data.redirectUrl;
              }
            }
          }
        }
        //사용자 메뉴 조회
        this.selectUserMenu();
        //사용자 ShopList 조회
        //this.selectUserShops();
      })
      .catch((error) => {
        //console.log("Data가 없습니다.");
        if (!this.props.logged && !window.location.pathname.includes('login')) {
          sessionStorage.removeItem('user_id');
          sessionStorage.removeItem('userName');
          sessionStorage.removeItem('roleType');
          sessionStorage.removeItem('redirectUrl');
          sessionStorage.removeItem('solution_type_cd');
          sessionStorage.removeItem("RP_LIST_OPERATION_BANNER");
          deleteCookie("user_id");
          deleteCookie("solution_type_cd");

          if (window.document.domain == "127.0.0.1" || window.document.domain == "localhost") {
            window.location.href = '/login';
          } else {
            window.location.href = '/logout';
          }  
        }
      });

    const res2 = await axios.get(`/rest/common/diff/role`);
    if (
      !res2.data['result'] &&
      window.location.href.indexOf('/login') < 0 &&
      window.location.href.indexOf('/ko') < 0 &&
      window.location.href.indexOf('/demo') < 0 &&
      window.location.href.indexOf('/common') < 0 &&
      window.location.href.indexOf('/ad-trial') < 0 &&
      window.location.href.indexOf(':3000') < 0
    ) {
      //alert("/rest/common/diff/role " + res2.data["result"]);
      sessionStorage.removeItem('user_id');
      sessionStorage.removeItem('userName');
      sessionStorage.removeItem('roleType');
      sessionStorage.removeItem('redirectUrl');
      sessionStorage.removeItem('solution_type_cd');
      sessionStorage.removeItem("RP_LIST_OPERATION_BANNER");
      deleteCookie("user_id");
      deleteCookie("solution_type_cd");
      window.location.href = '/logout';
    }

    // 3000포트(개발모드 일때)
    if (window.location.href.indexOf(':3000') > 0) {
      if (window.location.href.indexOf('/shop/cafe24') > 0) {
        this.setState({ roleType: 2204 });
      } else if (window.location.href.indexOf('/shop/trend') > 0) {
        this.setState({ roleType: 2203 });
      } else if (window.location.href.indexOf('/admin') > 0) {
        this.setState({ roleType: 2202 });
      } else if (window.location.href.indexOf('/demo') > 0) {
        this.setState({ roleType: 2201 });
      }
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  handleNext = (e) => {
    sessionStorage.removeItem('user_id');
    sessionStorage.removeItem('userName');
    sessionStorage.removeItem('roleType');
    sessionStorage.removeItem('redirectUrl');
    sessionStorage.removeItem('solution_type_cd');
    sessionStorage.removeItem("RP_LIST_OPERATION_BANNER");
    deleteCookie("user_id");
    deleteCookie("solution_type_cd");
    window.location.href = '/logout';
  };

  handleShopChange = (e) => {
    console.log(e);
    this.changeShop(e);
  };

  handleServiceChange(service_type) {
    console.log(service_type);
    this.changeServiceType(service_type);

    if(service_type === "01001"){
        ga4react.event('[상단] 로그인 후 상단 배너 클릭', '로그인 후 상단 배너 클릭 ID=' + user_id, '상단 메뉴');
        ReactPixel.trackCustom('[상단] 로그인 후 상단 배너 클릭', {user_id : user_id});

    }else if(service_type === "01002"){
        ga4react.event('[상단] 로그인 후 상단 진열 클릭', '로그인 후 상단 진열 클릭 ID=' + user_id, '상단 메뉴');
        ReactPixel.trackCustom('[상단] 로그인 후 상단 진열 클릭', {user_id : user_id});
    }
  };

  w3_open() {
    $('#mySidenav').css('display', 'block');
  }

  w3_close() {
    $('#mySidenav').css('display', 'none');
  }

  tempStyle = {
    backgroundColor: '#2f4050',
    float: 'left',
    width: '220px',
    height: '100%',
    position: 'fixed',
    overflowY: 'auto',
  };

  getSetting = () => {
    http.get('/rest/shop/event/setting').then((resultData) => {
      if (resultData.data.list != null && resultData.data.list.length < 1) {
        console.log(resultData.data.list);
        console.log(resultData.data.list.length < 1);
        NotificationManager.warning(
          '자동배너 설정 페이지로 바로가기',
          '자동배너 설정이 필요합니다!',
          3600000 * 24 * 365,
          () => {
            window.location.href = '/shop/cafe24/event/setting';
          },
        );
        $('.notification-warning').css('background-color', '#F44336');
      }
    });
  };

  // createNotification = (type) => {
  // 	return () => {
  // 	  switch (type) {
  // 		case 'info':
  // 		  NotificationManager.info('Info message');
  // 		  break;
  // 		case 'success':
  // 		  NotificationManager.success('Success message', 'Title here');
  // 		  break;
  // 		case 'warning':
  // 		  NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
  // 		  break;
  // 		case 'error':
  // 		  NotificationManager.error('Error message', 'Click me!', -1, () => {
  // 			alert('callback');
  // 		  });
  // 		  break;
  // 	  }
  // 	};
  //   };

  onClickExpansionButton = (e) => {
    $('.reduction-side-bar').ready(function() {
      $('.expansion-side-bar').removeClass('transition_hidden');
      $('.reduction-side-bar').removeClass('transition_visible');
      $('.expansion-side-bar').addClass('transition_visible');
      $('.reduction-side-bar').addClass('transition_hidden');

      $('.menu_expansion_arrow').css("display","none");
      
      $('#page-wrapper').css('padding', '130px 0 0 220px');
      $('#common-page-wrapper').css('padding', '90px 0 0 220px');
      $('#sosang-page-wrapper').css('padding', '90px 0 0 220px');
      $('.fixedStepArea').css('left', '220px');
      $(".wrap_file_name_setting").css("margin-left", "260px");

      //$('.mainmenu li ul li a').css('visibility', 'visible');
      //$('.mainmenu li ul li .main_menu_area').show();
    })
  };
  onClickReductionButton = (e) => {
    $('.reduction-side-bar').ready(function() {
      $('.expansion-side-bar').removeClass('transition_visible');
      $('.reduction-side-bar').removeClass('transition_hidden');
      $('.expansion-side-bar').addClass('transition_hidden');
      $('.reduction-side-bar').addClass('transition_visible');

      $('.menu_expansion_arrow').css("display", "block");
      
      $('#page-wrapper').css('padding', '130px 0 0 60px');
      $('#common-page-wrapper').css('padding', '90px 0 0 60px');
      $('#sosang-page-wrapper').css('padding', '90px 0 0 60px');
      $('.fixedStepArea').css('left', '60px');
      $(".wrap_file_name_setting").css("margin-left", "96px");
      
      //$('.mainmenu li ul li a').css('visibility', 'hidden');
      //$('.mainmenu li ul li .main_menu_area').hide();
      // alert($('.reduction-side-bar').length)
    })
  };



  onClickShopNoSelect = (e) => {
    this.onClickExpansionButton(e);
    $('#shop_no').trigger('click');
  };

  onClickCommonMenu = (e) => {
    this.mobileMenuClick(e);
  };

  onClickMenu(menu_url) {
    this.props.history.push(menu_url);
  }

  loadBannerTemplateImg() {
    if (this.state.banner_templa_list.length <= 0) {
      http.get('/rest/common/template/img/list').then((resultData) => {
        if (resultData.data.list != null && resultData.data.list.length > 1) {
          this.setState({ banner_templa_list: resultData.data.list });
        }
      });
    }
  }

  onClickSampleBannerNextStep = (e) => {
    var next_sample_banner_step = Number(this.state.sample_banner_step) + 1;
    this.setState({
      sample_banner_step: [next_sample_banner_step],
    });
    console.log('==next_sample_banner_step:' + next_sample_banner_step);
  };

  onClickSampleBannerOpen = (e) => {
    this.loadBannerTemplateImg();
    this.setState({
      is_sample_banner_popup_open: true,
      sample_banner_step: 1,
    });
  };

  onClickSampleBannerClose = (e) => {
    this.setState({
      is_sample_banner_popup_open: false,
    });
  };

  onClickBlankBannerClose = (e) => {
    this.setState({
      is_blank_popup_open: false,
    });
  };

  // 업로드된 이미지 Preview
  attachImage(acceptedFiles) {
    if (acceptedFiles) {
      /* Get files in array form */
      const files = Array.from(acceptedFiles);

      if (files.length > 1) {
        alert('1개의 파일만 첨부해 주세요.');
        return false;
      } else {
        if (
          files[0].name.match(
            '.jpg$|.png$|.jpeg$|.gif$|.bmp$|.JPG$|.PNG$|.JPEG$|.GIF$|.BMP$',
          )
        ) {
          console.log(files[0].name);
        } else {
          alert('이미지 파일만 첨부 해 주세요.');
          return false;
        }
      }

      //const uploadFiles = Array.from(e.target.files);
      //const { getRootProps, getInputProps, isDragActive } = useDropzone({ files });
      /* Map each file to a promise that resolves to an array of image URI's */
      Promise.all(
        files.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener('load', (ev) => {
              resolve(ev.target.result);
            });
            reader.addEventListener('error', reject);
            reader.readAsDataURL(file);
          });
        }),
      ).then(
        (images) => {
          /* Once all promises are resolved, update state with image URI array */
          console.log(images);
          console.log(files);
          console.log(this);
          this.setState({
            uploadFiles: files,
            imageArray: images,
          });
          //const {uploadFiles, imageArray} = useState(images);
          var fileNames = '';
          files.map((file) => {
            fileNames += file.name + ' ';
          });
          // $("#attachFileName").text(fileNames);

          // $("#sample_banner_image_upload_form").submit();
          this.createSampleEvent();
        },
        (error) => {
          console.error(error);
        },
      );
    }
  }

  // 샘플배너 생성 이벤트
  createSampleEvent() {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const url = '/rest/common/sample/event/create';
    const formData = new FormData();
    const uploadFiles = this.state.uploadFiles;
    uploadFiles.forEach((file) => formData.append('files', file));
    if (this.state.shops.length >= 1) {
      formData.append('is_added_shops', 'Y');
    } else {
      formData.append('is_added_shops', 'N');
    }

    this.onClickSampleBannerNextStep();

    http
      .post(url, formData, config)
      .then((res) => {
        // 조회
        if (res.data != null && res.data.event_seq != null) {
          this.setState({
            sample_banner_event_seq: res.data.event_seq,
          });
          this.onClickSampleBannerNextStep();
        } else if (res.data.code == "401") {
          alert('사용자 세션이 끊어졌습니다.\n다시 로그인해 주세요.');
          window.location.href = "/login";
        } else {
          alert('배너 생성에 실패 하였습니다.\n관리자에게 문의해 주세요.');
          this.onClickSampleBannerClose();
        }
      })
      .catch((error) => {
        alert(error + '\n관리자에게 문의해 주세요.');
      });
  }

  // 새 배너 만들기 (step:2 으로 이동)
  newSampleEvent() {
    this.setState({
      sample_banner_step: 2,
      sample_banner_event_seq: null,
    });
  }

  // 다른 샘플배너 생성 이벤트
  createAnotherSampleEvent() {
    const url = '/rest/common/sample/event/another';
    if (isanotherBannerLoaded == true) {
      isanotherBannerLoaded = false;
      http
        .get(url, {
          params: {
            event_seq: this.state.sample_banner_event_seq,
          },
        })
        .then((res) => {
          // 조회
          if (res.data != null && res.data.event_seq != null) {
            this.setState({
              sample_banner_event_seq: res.data.event_seq,
            });
            // this.onClickSampleBannerNextStep();
          } else if (res.data.code == "401") {
            alert('사용자 세션이 끊어졌습니다.\n다시 로그인해 주세요.');
            window.location.href = "/login";
          } else {
            alert('배너 생성에 실패 하였습니다.\n관리자에게 문의해 주세요.');
          }
          isanotherBannerLoaded = true;
        })
        .catch((error) => {
          alert(error + '\n관리자에게 문의해 주세요.');
          isanotherBannerLoaded = true;
        });
    }
  }

  //  샘플배너 저장 이벤트 : del_yn = "N"
  saveSampleEvent() {
    const url = '/rest/common/sample/event/save';
    if (isanotherBannerLoaded == true) {
      http
        .get(url, {
          params: {
            event_seq: this.state.sample_banner_event_seq,
          },
        })
        .then((res) => {
          // 조회
          if (res.data.result == true || res.data.result == 'true') {
            alert('배너를 저장 하였습니다.');
          } else {
            alert('배너 저장에 실패 하였습니다.');
          }
        })
        .catch((error) => {
          alert(error + '\n관리자에게 문의해 주세요.');
        });
    }
  }

  // 배너 쇼핑몰 연동시
  onChangeIsAddShopBannerButtonClick(state) {
    this.setState({
      is_add_shop_banner_button_click: state,
    });
  }

  // 진열 쇼핑몰 연동시
  onChangeIsAddShopExhibitionButtonClick(state) {
    this.setState({
      is_add_shop_exhibition_button_click: state,
    });
  }

  // 배너 설치 요청 링크
  openChannelTalk() {
    //$('.textLauncherIcon').click();
    //$('.launcherIcon').click();
    // window.ChannelIO('showMessenger');
    window.open("https://docs.google.com/forms/d/1I-Z0tkpHzsqpK76fpKODEX1FzuduWrpyUJ7a_Bv4z8w/edit");
  }

  onClickForceUpdate(path) {
    if (window.location.href.indexOf(path) >= 0) {
      window.location.reload();
    }
  }

  openUserGuide(url) {
    var g_oWindow = window.open('about:blank', '_blank');
    g_oWindow.location = url;
  }

  async selectFreeDateEndDateAndEventPeriod() {
    const res = await http.get("/rest/shop/selectFreeDateEndDateAndEventPeriod");
    // 조회
    if (res.data != null) {
      this.setState({
        end_date_format: res.data.end_date_format,
        event_period: res.data.event_period
      })
    }
  }

  render() {
    var menuCreateCnt = -1;
    if (
      window.location.href.indexOf('/login') < 0 &&
      window.location.href.indexOf('/ko') < 0 &&
      window.location.href.indexOf('/ad-trial') < 0 &&
      window.location.href.indexOf('/common/curation') < 0 &&
      window.location.href.indexOf('/kdeal/common/product/list') < 0 &&
      window.location.href.indexOf('/kdeal/common/sosanggongin') < 0 &&
      window.location.href.indexOf('/kdeal/demo/sosanggongin') < 0 &&
      window.location.href.indexOf('/common/registUser') < 0 &&
      window.location.href.indexOf('/common/id/search') < 0 &&
      window.location.href.indexOf('/common/pwd/search') < 0 &&
      window.location.href.indexOf('/ko/contact') < 0 &&
      window.location.href.indexOf('/common/FormViewer') < 0 &&
      window.location.href.indexOf('/common/EmailVerify') < 0 && // 이메일 인증 페이지
      window.location.href.indexOf('/common/EmailChangePassword') < 0 && // 이메일 비밀번호 변경 페이지
      window.location.href.indexOf('/common/RegistUser_v3') < 0 && // 신규 회원가입 페이지
      window.location.href.indexOf('/common/Login_v3') < 0 && // 신규 로그인 페이지
      window.location.href.indexOf('/common/RegistConfirm') < 0 && // 이메일 인증 페이지
      window.location.href.indexOf('/common/promo_roboimage') < 0 && // 마케팅 로보 이미지
      window.location.href.indexOf('/common/promo_roboimage/result') < 0 && // 마케팅 로보 이미지 결과
      window.location.href.indexOf('/common/promo_aibanner') < 0 && // 디자인 마케팅 로보 이미지
      window.location.href.indexOf('/common/promo_aibanner/result') < 0 && // 디자인 마케팅 로보 이미지 결과
      this.state.roleType != null &&
      this.state.roleType != 'undefined' &&
      this.state.roleType != '2299' &&
      this.state.roleType != ''
    ) {
      const { commonActiveMenuClass } = this.state;
      return (
        <>
          <div>
            {/* PC 헤더 */}
            <div
              className="pc-header-menu"
              style={{
                width: '100%',
                height: '90px',
                top: '0px',
                background: '#FFFFFF',
                padding: '32px 2.5% 32px 25px',
                position: 'fixed',
                boxShadow: '0px 4px 15px rgba(0,0,0,0.05)',
                display: 'flex',
                justifyContent: 'space-between',
                zIndex: '9999',
              }}
            >
              {/* 로고 */}
              <div style={{ width: '138px', height: '26px' }}>
                <Link to={this.state.redirectUrl}>
                  <img
                    src={logo4}
                    width="100%"
                    alt=""
                    style={{ marginLeft: '-10px' }}
                  />
                </Link>
              </div>
              {/* 서비스 구분 메뉴 */}
              {
                (this.state.role_cls == '2204' || this.state.role_cls == '2207' || this.state.role_cls == '2211') ?
                  <div className="commonmenu" style={{ width: '50%', flex: 1, marginLeft: '90px'}}>
                    <ul id="navigation" style={{ display: 'flex' }}>
                      <li>
                        <p className={`cursor-pointer ${this.state.service_type === "01001" ? 'active' : ''}`} style={{fontSize:'18px', fontWeight: '500', color: '#CCD1D7'}} onClick={() => this.handleServiceChange("01001")}>배너</p>
                        {/* <NavLink to="/shop/cafe24/main" onClick={this.onClickCommonMenu}>배너</NavLink> */}
                      </li>
                      <li>
                        <p className={`cursor-pointer ${this.state.service_type === "01002" ? 'active' : ''}`} style={{fontSize:'18px', fontWeight: '500', color: '#CCD1D7'}} onClick={() => this.handleServiceChange("01002")}>진열</p>
                        {/* <NavLink to="/shop/rp/event/list" onClick={this.onClickCommonMenu}>진열</NavLink> */}
                      </li>
                      {
                        (this.state.user_id == 'test_rp' || this.state.user_id == 'inh1101' || this.state.user_id == 'robomd') ? 
                        <li>
                          <p className={`cursor-pointer ${this.state.service_type === "01007" ? 'active' : ''}`} style={{fontSize:'18px', fontWeight: '500', color: '#CCD1D7'}} onClick={() => this.handleServiceChange("01007")}>큐레이션</p>
                          {/* <NavLink to="/shop/rp/event/list" onClick={this.onClickCommonMenu}>진열</NavLink> */}
                        </li> 
                        : ''
                      }
                    </ul>
                  </div>
                : ''
              }
              
              {/* 공통메뉴 */}
              <div className="commonmenu" style={{ alignSelf: 'center' }}>
                <ul
                  id="navigation"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  {/* 최초 : 상점 연동하기 (검정색)  */}
                  {
                    // this.state.role_cls == "2204" ?
                    // 	<li className={`li_commonUserShopSync ${this.state.shopSyncState == "sync" ? " sync" : ""}`} style={{display:"flex"}}>
                    // 		<a href="/shop/serviceShopAdd" style={{marginRight:"15px"}}>{this.state.shopSyncState == "sync" ? "상점 연동 중" : "상점 연동하기"}</a>
                    // 		<div className="commonUserShopSyncState"></div>
                    // 	</li>
                    // : ''
                  }
                  {this.state.service_type == '01001' ? (
                    <div
                      className="btn-auto-b"
                      style={{
                        height: '55px',
                        lineHeight: '55px',
                        width: '160px',
                        cursor: 'pointer',
                        fontSize: '15px',
                        alignItems: 'center',
                        marginRight: '10px',
                      }}
                      onClick={(e) => this.onClickSampleBannerOpen()}
                    >
                      샘플 배너 만들기
                    </div>
                  ) : (
                    ''
                  )}
                  <li
                    className={`li_commonUserMenu ${
                      commonActiveMenuClass == 'commonUserMenu' ? ' active' : ''
                    }`}
                    style={{
                      height: '21px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      className="commonUserMenu"
                      onClick={this.handleClick}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <div
                        style={{
                          marginRight: '15px',
                          cursor: 'pointer',
                          fontWeight: 'bold',
                          fontSize: '15px',
                          pointerEvents: 'none',
                        }}
                      >
                        {this.state.userName} 님
                      </div>
                      <div
                        className="select-arrow-down"
                        style={{ pointerEvents: 'none' }}
                      ></div>
                    </div>
                    <ul>
                      <li>
                        <b>내 정보</b>
                      </li>
                      <li>
                        <NavLink
                          to="/shop/myInfo"
                          onClick={this.onClickCommonMenu}
                        >
                          &nbsp; - 나의 정보 변경
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/shop/pwdMod"
                          onClick={this.onClickCommonMenu}
                        >
                          &nbsp; - 비밀번호 변경
                        </NavLink>
                      </li>
                      {(this.state.role_cls == '2204' || this.state.role_cls == '2207' || this.state.role_cls == '2211') && this.state.shop_no != -1 ? (
                        <>
                          <li style={{ marginTop: '10px' }}>
                            <b>쇼핑몰 관리</b>
                          </li>
                          <li>
                            <NavLink
                              to="/shop/solutionStoreAdd"
                              onClick={this.onClickCommonMenu}
                            >
                              &nbsp; - 쇼핑몰 데이터 불러오기
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={{
                                pathname: '/shop/serviceShopList',
                                //search: '?step=3&finish_yn=y',
                              }}
                              onClick={this.onClickCommonMenu}
                            >
                              &nbsp; - 쇼핑몰 목록
                            </NavLink>
                          </li>
                          {
                            //this.state.service_type == "01001" ?
                            <>
                              <li style={{ marginTop: '10px' }}>
                                <b>결제 관리</b>
                              </li>
                              <li>
                                <NavLink
                                  to="/shop/orderAmountExpect"
                                  onClick={this.onClickCommonMenu}
                                >
                                  &nbsp; - 멤버십 관리
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/shop/order"
                                  onClick={this.onClickCommonMenu}
                                >
                                  &nbsp; - 결제수단 관리
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/shop/order/list"
                                  onClick={this.onClickCommonMenu}
                                >
                                  &nbsp; - 결제 내역
                                </NavLink>
                              </li>
                            </>
                            //:''
                          }
                        </>
                      ) : null}
                      <li
                        className="cursor-pointer"
                        style={{ marginTop: '10px' }}
                      >
                        <Link to="/logout">로그아웃</Link>
                      </li>
                    </ul>
                  </li>
                  <li
                    className={`li_commonBoardMenu ${
                      commonActiveMenuClass == 'commonBoardMenu'
                        ? ' active'
                        : ''
                    }`}
                    style={{
                      height: '21px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      className="commonBoardMenu"
                      onClick={this.handleClick}
                      style={{ marginRight: '15px', cursor: 'pointer' }}
                    ></div>
                    <ul>
                      <li>
                        <b>공지사항</b>
                      </li>
                      <li>
                        <NavLink
                          to="/shop/notice/list"
                          onClick={this.onClickCommonMenu}
                        >
                          &nbsp; - 공지사항 목록
                        </NavLink>
                      </li>
                      <li style={{ marginTop: '10px' }}>
                        <b>문의사항</b>
                      </li>
                      <li>
                        <NavLink
                          to="/shop/qna/list"
                          onClick={this.onClickCommonMenu}
                        >
                          &nbsp; - 나의 문의
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/shop/qna/form"
                          onClick={this.onClickCommonMenu}
                        >
                          &nbsp; - 문의 하기
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  {/* <li><a className="commonAlarm" onClick={this.handleClick} href="#"></a></li> */}
                </ul>
              </div>
            </div>
            {/* PC 메뉴영역 */}
            <div
              name="side-bar"
              className="pc-left-menu expansion-side-bar"
              id="mySidenav"
              style={{
                top: '90px',
                background: '#FFFFFF',
                borderRight: '1px solid',
                borderColor: '#C4C4C4',
                overflow: 'visible',
              }}
            >
              <div
                style={{ position: 'absolute', top: '20px', right: '-10px' }}
              >
                <div
                  className="menu_reduction_arrow cursor-pointer"
                  onClick={this.onClickReductionButton}
                ></div>
              </div>
              <div name="sidebar-collapse">
                {/* 멀티샵 선택영역 */}
                { (this.state.role_cls == '2204' || this.state.role_cls == '2207' || this.state.role_cls == '2211') && this.state.shop_no != -1 ? (
                  <div
                    style={{
                      // height: '60px',
                      height: 'auto',
                      borderBottom: '1px solid rgb(196, 196, 196)',
                      display: 'flex',
                    }}
                  >
                    <div style={{height:"60px", display: "flex"}}>
                      <div
                        className="menu_expansion_arrow cursor-pointer"
                        onClick={this.onClickExpansionButton}
                        style={{margin:"auto", display:"none"}}
                      ></div>
                    </div>
                    {/* 상점 아이콘 */}
                    <div
                      style={{
                        width: '32px',
                        padding: '14px 0 14px 0',
                        marginLeft: '18px',
                      }}
                    >
                      <img src={shopIcon} width="100%" />
                    </div>
                    {/* <div style={{flex:"1"}}> */}
                    <div>
                      {/* 상점 선택 */}
                      {this.state.shops != null &&
                      this.state.shops.length > 0 ? (
                        this.state.shops.length == 1 ? (
                          <div
                            style={{
                              width: '109px',
                              float: 'right',
                              height: 'auto',
                              border: '0px',
                              outline: 'none',
                              fontSize: '13px',
                              margin: '21px 0px',
                              paddingLeft: '10px',
                            }}
                          >
                            <b>{this.state.shops[0].shop_name}</b>
                          </div>
                        ) : (
                          <select
                            className="shop_no_select cursor-pointer"
                            id="shop_no"
                            name="shop_no"
                            value={this.state.shop_no}
                            onChange={(e) => this.handleShopChange(e)}
                            style={{
                              width: '100%',
                              height: '60px',
                              // , float:"right"
                              flexGrow: '1',
                              border: '0px',
                              outline: 'none',
                              fontSize: '13px',
                              backgroundPosition: 'right 22px top 50%',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              // , marginRight:"42px"
                              // , paddingRight:"45px"
                              background: 'none',
                            }}
                          >
                            {this.state.shops.map((option, index) => (
                              <option key={index} value={option.shop_no}>
                                {option.shop_name}
                              </option>
                            ))}
                          </select>
                        )
                      ) : (
                        <select
                          id="shop_no"
                          name="shop_no"
                          value=""
                          style={{
                            width: '100%',
                            height: '60px',
                            float: 'right',
                            flexGrow: '1',
                            border: '0px',
                            outline: 'none',
                            fontSize: '13px',
                            background: 'none',
                            backgroundPosition: 'right 22px top 50%',
                          }}
                        >
                          <option value="" disabled>
                            쇼핑몰 데이터 불러오기
                          </option>
                        </select>
                      )}
                    </div>
                    {/* <div style={{float:"left", padding: "17px 17px 17px 17px"}}>
												<img src={icn_hamburger_v2_2} width="100%" />
											</div> */}
                  </div>
                ) : (
                  ''
                )}
                {/* ------------------------------------------------------------------------- */}
                <div
                  className="side"
                  style={{ width: '100%', marginTop: '10px' }}
                >
                  {/* 메인메뉴 */}
                  <div
                    className="mainmenu"
                    style={{
                      borderTop: '1px',
                      //, padding:"10px 10px"
                    }}
                  >
                    <ul id="navigation">
                      {this.state.menu.map((menu, idx) => (
                        <>
                          <li
                            className={`li_${menu.menu_code} active main_menu`}
                            style={{ width: '100%', textAlign: 'left' }}
                          >
                            {/* 대메뉴 */}
                            {menu.menu_url != null && menu.menu_url != '' ? (
                              <div
                                className="main_menu_area hover_menu background_white"
                                onClick={(e) => {
                                  if (
                                    menu.menu_url == null ||
                                    menu.menu_url == ''
                                  ) {
                                    this.mainMenuClick(`.li_${menu.menu_code}`);
                                  } else {
                                    const state = this.state;
                                    this.setState({
                                      state,
                                    });
                                  }
                                }}
                                style={{
                                  height: '40px',
                                  borderRadius: '5px',
                                  padding: '5px 15px',
                                  margin: '5px',
                                  //, padding:"10px 5px"
                                }}
                              >
                                {menu.menu_url != null &&
                                menu.menu_url != '' ? (
                                  <>
                                    {
                                      (this.state.role_cls == '2202' || this.state.role_cls == '2208' || this.state.role_cls == '2212' 
                                        || this.state.role_cls == '2203'|| this.state.role_cls == '2209' || this.state.role_cls == '2210') 
                                        || (this.state.shopActiveServiceCnt != null && this.state.shopActiveServiceCnt > 0)
                                      ?
                                      <NavLink
                                        to={{
                                          pathname: menu.menu_url,
                                        }}
                                        onClick={() => {
                                          window.scrollTo(0, 0);
                                        }}
                                      >
                                        <>
                                          {menu.menu_img !== undefined &&
                                          menu.menu_img !== 'undefined' ? (
                                              menu.menu_url.indexOf(new URL(window.location.href).pathname) >= 0
                                              || new URL(window.location.href).pathname.indexOf(menu.menu_url) >= 0 ? (
                                              <div
                                                style={{
                                                  width: '32px',
                                                  height: '32px',
                                                  float: 'left',
                                                }}
                                              >
                                                <img
                                                  src={
                                                    menu.menu_img + '_active.svg'
                                                  }
                                                />
                                              </div>
                                            ) : (
                                              <div
                                                style={{
                                                  width: '32px',
                                                  height: '32px',
                                                  float: 'left',
                                                }}
                                              >
                                                <img src={menu.menu_img + '.svg'} />
                                              </div>
                                            )
                                          ) : (
                                            ''
                                          )}
                                        </>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            height: '30px',
                                          }}
                                        >
                                          {
                                          menu.menu_url.indexOf(new URL(window.location.href).pathname) >= 0
                                          || new URL(window.location.href).pathname.indexOf(menu.menu_url) >= 0 ? (
                                            <a
                                              className={menu.menu_code}
                                              href="#"
                                              style={{
                                                margin: 'auto 0',
                                                padding: '0 10px',
                                                color: '#10CFC9',
                                                fontWeight: '700',
                                              }}
                                            >
                                              {menu.menu_nm}
                                            </a>
                                          ) : (
                                            <a
                                              className={menu.menu_code}
                                              href="#"
                                              style={{
                                                margin: 'auto 0',
                                                padding: '0 10px',
                                              }}
                                            >
                                              {menu.menu_nm}
                                            </a>
                                          )}
                                          {/* style={{padding:"5px 40px"}} */}
                                          {/* <div className="icn_plus_minus" style={{margin:"auto 10px auto 0"}}></div> */}
                                        </div>
                                      </NavLink>
                                      : 
                                      <>
                                        <>
                                        {menu.menu_img !== undefined &&
                                          menu.menu_img !== 'undefined' ? (
                                            menu.menu_url.indexOf(new URL(window.location.href).pathname) >= 0
                                            || new URL(window.location.href).pathname.indexOf(menu.menu_url) >= 0 ? (
                                            <div
                                              style={{
                                                width: '32px',
                                                height: '32px',
                                                float: 'left',
                                              }}
                                            >
                                              <img
                                                src={
                                                  menu.menu_img + '_active.svg'
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <div
                                              style={{
                                                width: '32px',
                                                height: '32px',
                                                float: 'left',
                                              }}
                                            >
                                              <img src={menu.menu_img + '.svg'} />
                                            </div>
                                          )
                                        ) : (
                                          ''
                                        )}
                                        </>
                                        <a onClick={()=> alert('쇼핑몰 연동 후 이용하실 수 있습니다.')} style={{padding:'7px 10px 7px 52px'}}>{menu.menu_nm}</a>
                                      </>
                                    }
                                  </>
                                ) : (
                                  <>
                                    <>
                                      {menu.menu_img !== undefined &&
                                      menu.menu_img !== 'undefined' ? (
                                        <div
                                          style={{
                                            width: '32px',
                                            height: '32px',
                                            float: 'left',
                                          }}
                                        >
                                          <img src={menu.menu_img + '.svg'} />
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        height: '30px',
                                      }}
                                    >
                                      <a
                                        className="main_menu_title"
                                        href="#"
                                        style={{
                                          margin: 'auto 0',
                                          padding: '0 10px',
                                        }}
                                      >
                                        {menu.menu_nm}
                                      </a>{' '}
                                      {/* style={{padding:"5px 40px"}} */}
                                      <div
                                        className="icn_up_down"
                                        style={{ margin: 'auto 0px auto 0' }}
                                      ></div>
                                    </div>
                                  </>
                                )}
                              </div>
                            ) : (
                              <div
                                className="main_menu_area hover_menu"
                                onClick={() => {
                                  if (
                                    menu.menu_url == null ||
                                    menu.menu_url == ''
                                  ) {
                                    this.mainMenuClick(`.li_${menu.menu_code}`);
                                  } else {
                                    const state = this.state;
                                    this.setState({
                                      state,
                                    });
                                  }
                                }}
                                style={{
                                  height: '40px',
                                  borderRadius: '5px',
                                  padding: '5px 15px',
                                  margin: '5px',
                                  //, padding:"10px 5px"
                                }}
                              >
                                {menu.menu_url != null &&
                                menu.menu_url != '' ? (
                                  <>
                                    <NavLink
                                      to={{
                                        pathname: menu.menu_url,
                                      }}
                                      onClick={() => {
                                        window.scrollTo(0, 0);
                                      }}
                                    >
                                      <>
                                        {menu.menu_img !== undefined &&
                                        menu.menu_img !== 'undefined' ? (
                                          <div
                                            style={{
                                              width: '32px',
                                              height: '32px',
                                              float: 'left',
                                            }}
                                          >
                                            <img src={menu.menu_img + '.svg'} />
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </>
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          height: '30px',
                                        }}
                                      >
                                        <a
                                          className={menu.menu_code}
                                          href="#"
                                          style={{
                                            margin: 'auto 0',
                                            padding: '0 10px',
                                          }}
                                        >
                                          {menu.menu_nm}
                                        </a>{' '}
                                        {/* style={{padding:"5px 40px"}} */}
                                        {/* <div className="icn_plus_minus" style={{margin:"auto 10px auto 0"}}></div> */}
                                      </div>
                                    </NavLink>
                                  </>
                                ) : (
                                  <>
                                    <>
                                      {menu.menu_img !== undefined &&
                                      menu.menu_img !== 'undefined' ? (
                                        <div
                                          style={{
                                            width: '32px',
                                            height: '32px',
                                            float: 'left',
                                          }}
                                        >
                                          <img src={menu.menu_img + '.svg'} />
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        height: '30px',
                                      }}
                                    >
                                      <a
                                        className="main_menu_title"
                                        href="#"
                                        style={{
                                          margin: 'auto 0',
                                          padding: '0 10px',
                                        }}
                                      >
                                        {menu.menu_nm}
                                      </a>{' '}
                                      {/* style={{padding:"5px 40px"}} */}
                                      <div
                                        className="icn_up_down"
                                        style={{ margin: 'auto 0px auto 0' }}
                                      ></div>
                                    </div>
                                  </>
                                )}
                              </div>
                            )}
                            {/* <div style={{width:"240px", background:"red"}}>
																<a>
																	<i name={menu.menu_icon} aria-hidden="true"></i>
																	<span name="nav-label font-weight-bold">{menu.menu_nm}</span>
																	<span name="fa arrow"></span>
																</a>
															</div> */}
                            <ul
                              style={{
                                width: '100%',
                                background: 'rgba(0, 0, 0, 0.02)',
                              }}
                            >
                              {menu.subMenu.map((sub, sub_idx) => (
                                <>
                                  {(sub.menu_url.indexOf(new URL(window.location.href).pathname) >= 0
                                    || new URL(window.location.href).pathname.indexOf(sub.menu_url) >= 0) 
                                    && this.state.roleType == '2201' ? (
                                    <>
                                      <li name="demoTrendSubMenu">
                                        <SubMenu
                                          menu_url={sub.menu_url}
                                          menu_nm={sub.menu_nm}
                                          menu_access={sub.menu_access}
                                          menu_img={sub.menu_img}
                                          sub_idx={sub_idx}
                                          this={this}
                                        />
                                        <span style={{ display: 'none' }}>
                                          {idx}
                                        </span>
                                        <span style={{ display: 'none' }}>
                                          {(menuCreateCnt = sub_idx)}
                                        </span>
                                      </li>
                                    </>
                                  ) : this.state.roleType != 'none' ? (
                                    //new URL(window.location.href).pathname == sub.menu_url
                                    sub.menu_url.indexOf(new URL(window.location.href).pathname) >= 0
                                    || new URL(window.location.href).pathname.indexOf(sub.menu_url) >= 0 ? (
                                      <>
                                        <li
                                          className="hover_menu"
                                          name="subMenu"
                                          style={{
                                            display: 'flex',
                                            position: 'relative',
                                            overflow: 'visible',
                                            height: 'auto',
                                            //, marginTop:"5px", marginBottom:"5px"
                                          }}
                                        >
                                          <Active_SubMenu
                                            menu_url={sub.menu_url}
                                            menu_nm={sub.menu_nm}
                                            menu_access={sub.menu_access}
                                            menu_img={sub.menu_img}
                                            sub_idx={sub_idx}
                                            this={this}
                                          />
                                          <span style={{ display: 'none' }}>
                                            {idx}
                                          </span>
                                          <span style={{ display: 'none' }}>
                                            {(menuCreateCnt = sub_idx)}
                                          </span>
                                        </li>
                                      </>
                                    ) : (
                                      <>
                                        <li
                                          className="hover_menu"
                                          name="subMenu"
                                          style={{
                                            display: 'flex',
                                            position: 'relative',
                                            overflow: 'visible',
                                            height: 'auto',
                                            //, marginTop:"5px", marginBottom:"5px"
                                          }}
                                        >
                                          <SubMenu
                                            menu_url={sub.menu_url}
                                            menu_nm={sub.menu_nm}
                                            menu_access={sub.menu_access}
                                            menu_img={sub.menu_img}
                                            sub_idx={sub_idx}
                                            this={this}
                                          />
                                          <span style={{ display: 'none' }}>
                                            {idx}
                                          </span>
                                          <span style={{ display: 'none' }}>
                                            {(menuCreateCnt = sub_idx)}
                                          </span>
                                        </li>
                                      </>
                                    )
                                  ) : null}
                                </>
                              ))}
                              {menuCreateCnt == -1 ? (
                                <>
                                  {menu.subMenu.map((sub, sub_idx) =>
                                    sub_idx == 0 ? (
                                        sub.menu_url.indexOf(new URL(window.location.href).pathname) >= 0
                                        || new URL(window.location.href).pathname.indexOf(sub.menu_url) >= 0 ? (
                                        <>
                                          <li name="demoTrendSubMenu">
                                            <SubMenu
                                              menu_url={sub.menu_url}
                                              menu_nm={sub.menu_nm}
                                              menu_access={sub.menu_access}
                                              menu_img={sub.menu_img}
                                              sub_idx={sub_idx}
                                            />
                                            <span style={{ display: 'none' }}>
                                              {idx}
                                            </span>
                                          </li>
                                        </>
                                      ) : (
                                        ''
                                      )
                                    ) : null,
                                  )}
                                </>
                              ) : null}
                            </ul>
                          </li>
                        </>
                      ))}
                      {
                        this.state.installBannerCnt <= 0 && this.state.menu.length > 0 && this.state.roleType == 2204
                        ? this.state.service_type === "01002"
                          ? 
                            <Link
                              to={{
                                pathname: `/shop/qna/form`,
                                  state: {
                                    qna_type_cd: "2104"
                                  }
                              }} style={{ display: "flex", justifyContent: "center" }}>
                              <button class="hover_dark main_menu_install_link_btn">기간 연장하기</button>
                            </Link>
                            
                          :
                            <Link
                              to={{
                                pathname: `/shop/qna/form`,
                                  state: { 
                                    qna_type_cd: "2105"
                                  }
                              }} style={{ display: "flex", justifyContent: "center" }}>
                              <button class="hover_dark main_menu_install_link_btn">쇼핑몰에 설치 요청하기</button>
                            </Link>
                        : ""
                      }
                      {
                        this.state.service_type === "01007" && this.state.curation_install_yn === "Y"
                        ? 
                          <>
                            <div className="curation_button_display_toggle">
                              <>
                                큐레이션<br/>노출여부
                              </>
                              <div style={{alignSelf:"center", marginLeft: "15px", flex:1, alignContent:"center", alignItems: "center"}}>
                                <ToggleButton
                                    id="curation_button_display_toggle"
                                    checked={this.state.curation_display_yn == "Y"}
                                    onClickOption={this.changeShowCurationService.bind(this)}
                                    // toggleAnimation={this.state.toggleAnimation}
                                />
                                <div style={{width:"100%", textAlign:"center", fontSize:"11px"}}>
                                  {
                                    this.state.curation_display_yn == "Y"
                                    ? <div style={{color:"rgb(0 215 219)"}}>ON</div>
                                    : <div style={{color:" rgb(212 212 212)"}}>OFF</div>
                                  }
                                </div>
                              </div>
                            </div>
                          </>
                        : ""
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* PC 짧은 메뉴영역 */}
            <div
              name="side-bar"
              className="pc-left-short-menu reduction-side-bar transition_hidden"
              id="mySidenav"
              style={{
                width: '60px',
                height: '100%',
                top: '90px',
                background: '#FFFFFF',
                borderRight: '1px solid',
                borderColor: '#C4C4C4',
                overflow: 'visible',
              }}
            >
              <div name="sidebar-collapse">
                {/* 멀티샵 선택영역 */}
                {(this.state.role_cls == '2204' || this.state.role_cls == '2207' || this.state.role_cls == '2211') && this.state.shop_no != -1 ? (
                  <div
                    style={{
                      // height: '60px',
                      height: 'auto',
                      borderBottom: '1px solid rgb(196, 196, 196)',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <div style={{height:"60px", display: "flex"}}>
                      <div
                        className="menu_expansion_arrow cursor-pointer"
                        onClick={this.onClickExpansionButton}
                        style={{margin:"auto", display:"none"}}
                      ></div>
                    </div>
                    {/* 상점 아이콘 */}
                    {/* <div className="cursor-pointer" style={{width:"32px", height:"auto", margin: "auto"}} onClick={this.onClickShopNoSelect}>
												<img src={shopIcon} style={{width:"100%"}} />
											</div> */}
                    <div>
                      {/* 상점 선택
												{
													(this.state.shops != null && this.state.shops.length > 0) ? 
														<select id="shop_no" name="shop_no" value={this.state.shop_no} onChange={(e)=>this.handleShopChange(e)} style={{width:"168px", height:"60px", float:"right", flexGrow:"1", border:"0px", outline:"none", fontSize:"13px", backgroundPosition: "right 22px top 50%"}}>
															{this.state.shops.map((option, index) => <option key={index} value={option.shop_no}>{option.shop_name}</option>)}
														</select>
													: 
													<select id="shop_no" name="shop_no" value="" style={{width:"168px", height:"60px", float:"right", flexGrow:"1", border:"0px", outline:"none", fontSize:"13px"}}>
														<option value="" disabled>상점을 추가해 주세요.</option>
													</select>
												} */}
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {/* ------------------------------------------------------------------------- */}
                <div
                  className="side"
                  style={{ width: '100%', marginTop: '10px' }}
                >
                  {/* 메인메뉴 */}
                  <div
                    className="mainmenu"
                    style={{ borderTop: '1px', zIndex: 999999 }}
                  >
                    <ul id="navigation">
                      {this.state.menu.map((menu, idx) => (
                        <>
                          <li
                            className={`li_${menu.menu_code} active main_menu`}
                            style={{
                              width: '100%',
                              textAlign: 'left',
                              paddingBottom: '6px',
                            }}
                          >
                            {/* 대메뉴 */}
                            <div
                              className="hover_menu cursor-pointer"
                              onClick={(e) =>
                                this.mainMenuActive(e, `.li_${menu.menu_code}`)
                              }
                              style={{
                                height: '40px',
                                borderRadius: '5px',
                                padding: '5px 8px',
                                margin: '5px',
                              }}
                            >
                              <div style={{ width: '32px', height: '32px' }}>
                                {menu.menu_img !== undefined && menu.menu_img !== 'undefined' ? (
                                    (menu.menu_url.indexOf(new URL(window.location.href).pathname) >= 0
                                    || new URL(window.location.href).pathname.indexOf(menu.menu_url) >= 0)
                                    && menu.menu_url != '' && menu.menu_url != null ? 
                                  (
                                    <img src={menu.menu_img + '_active.svg'} />
                                  ) : (
                                    <img src={menu.menu_img + '.svg'} />
                                  )
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                            <ul
                              style={{
                                width: '59px',
                                background: 'rgba(0, 0, 0, 0.02)',
                              }}
                            >
                              {menu.subMenu.map((sub, sub_idx) => (
                                <>
                                  <div
                                    style={{
                                      borderTop:
                                        sub_idx > 0 ? '1px solid #EFEFF0' : '',
                                      boxShadow:
                                        sub_idx == 0
                                          ? 'rgb(0 0 0 / 5%) 0px 4px 15px inset'
                                          : '',
                                    }}
                                  >
                                    <li
                                      className="hover_menu cursor-pointer"
                                      onClick={(e) =>
                                        this.mainMenuActive(
                                          e,
                                          `.li_${sub.menu_code}`,
                                        )
                                      }
                                      style={{
                                        height: '50px',
                                        borderRadius: '5px',
                                        padding: '10px 12px',
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: '32px',
                                          height: '32px',
                                        }}
                                      >
                                        {sub.menu_img !== undefined &&
                                        sub.menu_img !== 'undefined' ? (
                                            sub.menu_url.indexOf(new URL(window.location.href).pathname) >= 0
                                            || new URL(window.location.href).pathname.indexOf(sub.menu_url) >= 0 ? (
                                            <img
                                              src={sub.menu_img + '_active.svg'}
                                            />
                                          ) : (
                                            <img src={sub.menu_img + '.svg'} />
                                          )
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    </li>
                                  </div>
                                </>
                              ))}
                            </ul>
                          </li>
                        </>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* mobile 헤더 */}
            <div
              className="mobile-header-menu"
              style={{
                width: '100%',
                height: '70px',
                top: '-1px',
                background: '#FFFFFF',
                padding: '0px 30px',
                position: 'fixed',
                boxShadow: '0px 4px 15px rgba(0,0,0,0.05)',
                zIndex: '9999',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  margin: 'auto',
                  height: '100%',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{ display: 'flex', flex: '1', marginRight: '20px' }}
                >
                  {/* 로고 */}
                  <div
                    style={{ width: '26px', height: '26px', margin: 'auto 0' }}
                  >
                    <Link to={this.state.redirectUrl}>
                      <img
                        src={logo_mobile}
                        width="100%"
                        alt=""
                        style={{ marginLeft: '-10px' }}
                      />
                    </Link>
                  </div>
                  {/* 서비스 구분 메뉴 */}
                  {
                    (this.state.role_cls == '2204' || this.state.role_cls == '2207' || this.state.role_cls == '2211') ?
                      <div className="commonmenu" style={{ width: '50%', flex: 1}}>
                        <ul id="navigation" style={{ display: 'flex', alignItems: 'center' }}>
                          <li style={{width : '80px'}}>
                            <p className={`cursor-pointer ${this.state.service_type === "01001" ? 'active' : ''}`} style={{fontSize:'18px', fontWeight: '500', color: '#CCD1D7', margin: '0'}} onClick={() => this.handleServiceChange("01001")}>배너</p>
                            {/* <NavLink to="/shop/cafe24/main" onClick={this.onClickCommonMenu}>배너</NavLink> */}
                          </li>
                          <li style={{width : '80px'}}>
                            <p className={`cursor-pointer ${this.state.service_type === "01002" ? 'active' : ''}`} style={{fontSize:'18px', fontWeight: '500', color: '#CCD1D7', margin: '0'}} onClick={() => this.handleServiceChange("01002")}>진열</p>
                            {/* <NavLink to="/shop/rp/event/list" onClick={this.onClickCommonMenu}>진열</NavLink> */}
                          </li>
                          {
                            (this.state.user_id == 'test_rp' || this.state.user_id == 'inh1101' || this.state.user_id == 'robomd') ? 
                              <li style={{width : '80px'}}>
                                <p className={`cursor-pointer ${this.state.service_type === "01007" ? 'active' : ''}`} style={{fontSize:'18px', fontWeight: '500', color: '#CCD1D7', margin: '0'}} onClick={() => this.handleServiceChange("01007")}>큐레이션</p>
                                {/* <NavLink to="/shop/rp/event/list" onClick={this.onClickCommonMenu}>진열</NavLink> */}
                              </li>
                            : ''
                          }
                        </ul>
                      </div>
                      : ''
                    }
                    {/* <div
                      className="pageName"
                      style={{
                        whiteSpace: 'nowrap',
                        overflowX: 'hidden',
                        textOverflow: 'ellipsis',
                        // width: 'calc(100vw - 150px)',
                        //marginLeft: '20px',
                        fontWeight: 'bold',
                        fontSize: '16px',
                      }}
                    ></div> */}
                </div>
                {/* 햄버거 버튼 */}
                <div
                  style={{ width: '20px', height: '26px', margin: 'auto 0' }}
                >
                  <div onClick={this.mobileMenuClick}>
                    <img src={icn_hamburger_v2} width="100%" alt="" />
                  </div>
                </div>
              </div>
              {/* ------------------------------------------------------------------------- */}
              <div
                className="mobile_side"
                style={{
                  position: 'fixed',
                  top: '0',
                  right: '-20%',
                  width: 'auto',
                  height: '100%',
                  background: 'white',
                  padding: '0px 30px',
                  maxWidth: '0',
                  overflowY: 'auto',
                }}
              >
                {/* 상단메뉴*/}
                <div
                  className="mobile_slide_header commonmenu"
                  style={{
                    top: '0',
                    left: '0',
                    width: '100%;',
                    height: '70px',
                    background: 'white',
                    display: 'flex',
                    justifyContent: 'space-between',
                    // marginTop: "25px"
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <li
                      className={`li_commonUserMenu ${
                        commonActiveMenuClass == 'commonUserMenu'
                          ? ' active'
                          : ''
                      }`}
                      style={{
                        height: '21px',
                        display: 'flex',
                        alignItems: 'center',
                        margin: 'auto 30px auto 0',
                        position: 'relative',
                      }}
                    >
                      <div
                        className="commonUserMenu"
                        onClick={this.handleClick}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <div
                          style={{
                            marginRight: '15px',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            fontSize: '15px',
                            pointerEvents: 'none',
                          }}
                        >
                          {this.state.userName} 님
                        </div>
                        <div
                          className="select-arrow-down"
                          style={{ pointerEvents: 'none' }}
                        ></div>
                      </div>
                      <ul>
                        <li>
                          <b>내 정보</b>
                        </li>
                        <li>
                          <NavLink
                            to="/shop/myInfo"
                            onClick={this.onClickCommonMenu}
                          >
                            &nbsp; - 나의 정보 변경
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/shop/pwdMod"
                            onClick={this.onClickCommonMenu}
                          >
                            &nbsp; - 비밀번호 변경
                          </NavLink>
                        </li>
                        {
                          (this.state.role_cls == '2204' || this.state.role_cls == '2207' || this.state.role_cls == '2211') && this.state.shop_no != -1 ?
                            <>
                            <li style={{ marginTop: '10px' }}>
                                <b>쇼핑몰 관리</b>
                              </li>
                              <li>
                                <NavLink
                                  to="/shop/solutionStoreAdd"
                                  onClick={this.onClickCommonMenu}
                                >
                                  &nbsp; - 쇼핑몰 데이터 불러오기
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to={{
                                    pathname: '/shop/serviceShopList'
                                    //search: '?step=3&finish_yn=y',
                                  }}
                                  onClick={this.onClickCommonMenu}
                                >
                                  &nbsp; - 쇼핑몰 목록
                                </NavLink>
                              </li>
                              <li style={{ marginTop: '10px' }}>
                                <b>결제 관리</b>
                              </li>
                              <li>
                                <NavLink
                                  to="/shop/orderAmountExpect"
                                  onClick={this.onClickCommonMenu}
                                >
                                  &nbsp; - 멤버십 관리
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/shop/order"
                                  onClick={this.onClickCommonMenu}
                                >
                                  &nbsp; - 결제수단 관리
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/shop/order/list"
                                  onClick={this.onClickCommonMenu}
                                >
                                  &nbsp; - 결제 내역
                                </NavLink>
                              </li>    
                            </>
                          : ''
                        }
                        {/* <li><NavLink to="#" onClick={this.onClickCommonMenu}>서비스 사용 정보</NavLink></li> */}
                        <li
                          className="cursor-pointer"
                          style={{ marginTop: '10px' }}
                        >
                          <Link to="/logout">로그아웃</Link>
                        </li>
                      </ul>
                    </li>
                    <li
                      className={`li_commonBoardMenu ${
                        commonActiveMenuClass == 'commonBoardMenu'
                          ? ' active'
                          : ''
                      }`}
                      style={{
                        height: '21px',
                        display: 'flex',
                        alignItems: 'center',
                        margin: 'auto 0',
                        position: 'relative',
                      }}
                    >
                      <div
                        className="commonBoardMenu"
                        onClick={this.handleClick}
                        style={{ marginRight: '15px', cursor: 'pointer' }}
                      ></div>
                      <ul>
                        <li>
                          <b>공지사항</b>
                        </li>
                        <li>
                          <NavLink
                            to="/shop/notice/list"
                            onClick={this.onClickCommonMenu}
                          >
                            &nbsp; - 공지사항 목록
                          </NavLink>
                        </li>
                        <li style={{ marginTop: '10px' }}>
                          <b>문의사항</b>
                        </li>
                        <li>
                          <NavLink
                            to="/shop/qna/list"
                            onClick={this.onClickCommonMenu}
                          >
                            &nbsp; - 나의 문의
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/shop/qna/form"
                            onClick={this.onClickCommonMenu}
                          >
                            &nbsp; - 문의 하기
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                  </div>
                  <div style={{ margin: 'auto 0' }}>
                    <div
                      style={{
                        width: '20px',
                        height: '2px',
                        background: '#333333',
                      }}
                      onClick={this.mobileMenuClick}
                    ></div>
                  </div>
                </div>
                {/* 메인메뉴 */}
                <div
                  className="mainmenu"
                  style={{ borderTop: '1px', marginTop: '30px' }}
                >
                  <ul id="navigation">
                    {/* 멀티샵 선택영역 */}
                    {(this.state.role_cls == '2204' || this.state.role_cls == '2207' || this.state.role_cls == '2211') && this.state.shop_no != -1 ? (
                      <div
                        style={{
                          height: '60px',
                          border: '1px solid #333333',
                          borderRadius: '8px',
                          display: 'flex',
                        }}
                      >
                        {/* 상점 아이콘 */}
                        <div
                          style={{
                            width: '32px',
                            padding: '14px 0 14px 0',
                            marginLeft: '18px',
                          }}
                        >
                          <img src={shopIcon} width="100%" />
                        </div>
                        <div style={{ flex: '1' }}>
                          {/* 상점 선택 */}
                          {this.state.shops != null &&
                          this.state.shops.length > 0 ? (
                            <select
                              id="shop_no_m"
                              name="shop_no"
                              value={this.state.shop_no}
                              onChange={(e) => this.handleShopChange(e)}
                              style={{
                                width: '100%',
                                height: '60px',
                                float: 'right',
                                flexGrow: '1',
                                border: '0px',
                                outline: 'none',
                                fontSize: '13px',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                backgroundPosition: 'right 20px top 50%',
                                background: 'none',
                              }}
                            >
                              {this.state.shops.map((option, index) => (
                                <option key={index} value={option.shop_no}>
                                  {option.shop_name}
                                </option>
                              ))}
                            </select>
                          ) : (
                            <select
                              id="shop_no_m"
                              name="shop_no"
                              value=""
                              style={{
                                width: '100%',
                                height: '60px',
                                float: 'right',
                                flexGrow: '1',
                                border: '0px',
                                outline: 'none',
                                fontSize: '13px',
                                background: 'none',
                                backgroundPosition: 'right 20px top 50%',
                              }}
                            >
                              <option value="" disabled>
                                쇼핑몰 데이터 불러오기
                              </option>
                            </select>
                          )}
                        </div>
                        {/* <div style={{float:"left", padding: "17px 17px 17px 17px"}}>
																<img src={icn_hamburger_v2_2} width="100%" />
															</div> */}
                      </div>
                    ) : (
                      ''
                    )}

                    {this.state.menu.map((menu, idx) => (
                      <>
                        <li
                          className={`li_${menu.menu_code} active main_menu`}
                          style={{
                            width: '100%',
                            textAlign: 'left',
                            marginTop: '15px',
                          }}
                        >
                          {/* 대메뉴 */}
                          {menu.menu_url != null && menu.menu_url != '' ? (
                            <NavLink
                              to={menu.menu_url}
                              className="main_menu_area background_white"
                              onClick={(e) => {
                                this.mainMenuClick(`.li_${menu.menu_code}`);
                                this.mobileMenuClick(e);
                              }}
                              style={{
                                height: '50px',
                                borderRadius: '10px',
                                padding: '10px 5px 10px 18px',
                              }}
                            >
                              <>
                                {menu.menu_img !== undefined && menu.menu_img !== 'undefined' ? (
                                    (menu.menu_url.indexOf(new URL(window.location.href).pathname) >= 0
                                    || new URL(window.location.href).pathname.indexOf(menu.menu_url) >= 0)
                                    && menu.menu_url != '' && menu.menu_url != null ? 
                                  (
                                    <div style={{
                                        width: '32px',
                                        height: '32px',
                                        float: 'left',
                                      }}
                                    >
                                      <img src={menu.menu_img + '_active.svg'} />
                                    </div>
                                  ) : (
                                    <div style={{
                                        width: '32px',
                                        height: '32px',
                                        float: 'left',
                                      }}
                                    >
                                     <img src={menu.menu_img + '.svg'} />
                                    </div>
                                  )
                                ) : (
                                  ''
                                )}
                              </>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  height: '30px',
                                }}
                              >
                                {
                                menu.menu_url.indexOf(new URL(window.location.href).pathname) >= 0
                                || new URL(window.location.href).pathname.indexOf(menu.menu_url) >= 0 ? 
                                (
                                  <a
                                    className={menu.menu_code}
                                    href="#"
                                    style={{
                                      margin: 'auto 0',
                                      padding: '0 10px',
                                      color: '#10CFC9',
                                      fontWeight: '700',
                                    }}
                                  >
                                    {menu.menu_nm}
                                  </a>
                                ) : (
                                  <a
                                    className={menu.menu_code}
                                    href="#"
                                    style={{
                                      margin: 'auto 0',
                                      padding: '0 10px',
                                    }}
                                  >
                                    {menu.menu_nm}
                                  </a>
                                )}
                              </div>
                            </NavLink>
                          ) : (
                            <div
                              className="main_menu_area"
                              onClick={() =>
                                this.mainMenuClick(`.li_${menu.menu_code}`)
                              }
                              style={{
                                height: '50px',
                                borderRadius: '10px',
                                padding: '10px 5px 10px 18px',
                              }}
                            >
                              <>
                                {menu.menu_img !== undefined && menu.menu_img !== 'undefined' ? (
                                    (menu.menu_url.indexOf(new URL(window.location.href).pathname) >= 0
                                    || new URL(window.location.href).pathname.indexOf(menu.menu_url) >= 0)
                                    && menu.menu_url != '' && menu.menu_url != null ? (
                                    <div
                                      style={{
                                        width: '32px',
                                        height: '32px',
                                        float: 'left',
                                      }}
                                    >
                                      <img src={menu.menu_img + '_active.svg'} />
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        width: '32px',
                                        height: '32px',
                                        float: 'left',
                                      }}
                                    >
                                      <img src={menu.menu_img + '.svg'} />
                                    </div>
                                  )
                                ) : (
                                  ''
                                )}
                              </>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  height: '30px',
                                }}
                              >
                                <a
                                  className="main_menu_title"
                                  href="#"
                                  style={{
                                    margin: 'auto 0',
                                    padding: '0 10px',
                                  }}
                                >
                                  {menu.menu_nm}
                                </a>
                                <div
                                  className="icn_up_down"
                                  style={{ margin: 'auto 20px auto 0' }}
                                ></div>
                              </div>
                            </div>
                          )}
                          {/* <div style={{width:"240px", background:"red"}}>
																<a>
																	<i name={menu.menu_icon} aria-hidden="true"></i>
																	<span name="nav-label font-weight-bold">{menu.menu_nm}</span>
																	<span name="fa arrow"></span>
																</a>
															</div> */}
                          <ul style={{ background: 'rgba(0, 0, 0, 0.02)' }}>
                            {menu.subMenu.map((sub, sub_idx) => (
                              <>
                                {
                                (sub.menu_url.indexOf(new URL(window.location.href).pathname) >= 0
                                || new URL(window.location.href).pathname.indexOf(sub.menu_url) >= 0)
                                && this.state.roleType == '2201' ? (
                                  <>
                                    <li
                                      name="demoTrendSubMenu"
                                      style={{ marginLeft: '12px' }}
                                    >
                                      <SubMenu
                                        menu_url={sub.menu_url}
                                        menu_nm={sub.menu_nm}
                                        menu_access={sub.menu_access}
                                        menu_img={sub.menu_img}
                                        sub_idx={sub_idx}
                                      />
                                      <span style={{ display: 'none' }}>
                                        {idx}
                                      </span>
                                      <span style={{ display: 'none' }}>
                                        {(menuCreateCnt = sub_idx)}
                                      </span>
                                    </li>
                                  </>
                                ) : this.state.roleType != 'none' ? (
                                    sub.menu_url.indexOf(new URL(window.location.href).pathname) >= 0
                                    || new URL(window.location.href).pathname.indexOf(sub.menu_url) >= 0 ?
                                  (
                                    <>
                                      <li
                                        name="subMenu"
                                        style={{
                                          display: 'flex',
                                          position: 'relative',
                                          overflow: 'visible',
                                          height: 'auto',
                                          marginTop: '5px',
                                          marginBottom: '5px',
                                          //, marginLeft:"12px"
                                        }}
                                      >
                                        <Active_SubMenu
                                          menu_url={sub.menu_url}
                                          menu_nm={sub.menu_nm}
                                          menu_access={sub.menu_access}
                                          menu_img={sub.menu_img}
                                          sub_idx={sub_idx}
                                          this={this}
                                        />
                                        <span style={{ display: 'none' }}>
                                          {idx}
                                        </span>
                                        <span style={{ display: 'none' }}>
                                          {(menuCreateCnt = sub_idx)}
                                        </span>
                                      </li>
                                    </>
                                  ) : (
                                    <>
                                      <li
                                        name="subMenu"
                                        style={{
                                          display: 'flex',
                                          position: 'relative',
                                          overflow: 'visible',
                                          height: 'auto',
                                          marginTop: '5px',
                                          marginBottom: '5px',
                                          //, marginLeft:"12px"
                                        }}
                                      >
                                        <SubMenu
                                          menu_url={sub.menu_url}
                                          menu_nm={sub.menu_nm}
                                          menu_access={sub.menu_access}
                                          menu_img={sub.menu_img}
                                          sub_idx={sub_idx}
                                          this={this}
                                        />
                                        <span style={{ display: 'none' }}>
                                          {idx}
                                        </span>
                                        <span style={{ display: 'none' }}>
                                          {(menuCreateCnt = sub_idx)}
                                        </span>
                                      </li>
                                    </>
                                  )
                                ) : null}
                              </>
                            ))}
                            {menuCreateCnt == -1 ? (
                              <>
                                {menu.subMenu.map((sub, sub_idx) =>
                                  sub_idx == 0 ? (
                                    <>
                                      <li
                                        name="demoTrendSubMenu"
                                        style={{ marginLeft: '12px' }}
                                      >
                                        <SubMenu
                                          menu_url={sub.menu_url}
                                          menu_nm={sub.menu_nm}
                                          menu_access={sub.menu_access}
                                          menu_img={sub.menu_img}
                                          sub_idx={sub_idx}
                                        />
                                        <span style={{ display: 'none' }}>
                                          {idx}
                                        </span>
                                      </li>
                                    </>
                                  ) : null,
                                )}
                              </>
                            ) : null}
                          </ul>
                        </li>
                      </>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            {this.state.is_blank_popup_open == true ? (
              <div
                className="modal_v3_back"
                id="common_blank_modal"
                style={
                  {
                    // display:"none"
                  }
                }
              >
                <div className="modal_v3_popup_area">
                  <div
                    className="modal_v3_btn_close"
                    onClick={(e) => this.onClickBlankBannerClose()}
                  ></div>
                  <div className="modal_v3_popup"
                        style={{
                          width: 'auto',
                          maxWidth: '95vw', 
                          height: 'auto',
                          padding: '0px',
                        }}
                  >
                    <div
                            className="modal_v3_content"
                            style={{
                              height: 'auto',
                              marginTop: '0px',
                              marginBottom: "-8px"
                            }}
                            dangerouslySetInnerHTML = {{__html: this.state.blank_popup_content}}
                          >
                    </div>
                  </div>
                </div>
              </div>
            ) : ""
            }
            {this.state.is_sample_banner_popup_open == true ? (
              <div
                className="modal_v3_back"
                style={
                  {
                    // display:"none"
                  }
                }
              >
                <div className="modal_v3_popup_area">
                  <div
                    className="modal_v3_btn_close"
                    onClick={(e) => this.onClickSampleBannerClose()}
                  ></div>
                  <div className="modal_v3_popup">
                    <div className="modal_v3_title">
                      샘플 배너 만들기
                      {/* ({this.state.shops.length}) */}
                    </div>
                    {Number(this.state.sample_banner_step) == 1 ? (
                      <div
                        className="modal_v3_content"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: '95%',
                        }}
                      >
                        <div style={{ height: '80px' }}>
                          로보MD가 자동으로 만들어드리는 배너입니다.
                          <br />
                          고객님의 사진으로 샘플 배너를 만들어 보세요.
                        </div>
                        <div
                          style={{
                            margin: '20px auto',
                            width: '50%',
                            flex: '1',
                          }}
                        >
                          <Slider
                            {...this.state.setting_sample_banner_slider}
                            arrows={false}
                            style={{
                              width: '100%',
                              height: '100%',
                              textAlign: 'center',
                            }}
                            className="sample-banner-slider"
                          >
                            {this.state.banner_templa_list.map((obj, idx) => (
                              <div
                                key={idx}
                                style={{ margin: 'auto', height: '100%' }}
                              >
                                <div
                                  style={{
                                    backgroundImage: 'url(' + obj.val2 + ')',
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    width: '100%',
                                    height: '100%',
                                    backgroundRepeat: 'no-repeat',
                                  }}
                                ></div>
                              </div>
                            ))}
                          </Slider>
                        </div>
                        <div
                          style={{
                            margin: '30px auto 20px',
                            width: '200px',
                            height: '60px',
                          }}
                          onClick={(e) => this.onClickSampleBannerNextStep()}
                        >
                          <div
                            className="btn-auto-b sample_banner_btn"
                            style={{
                              height: '100%',
                              lineHeight: '60px',
                              width: '100%',
                              cursor: 'pointer',
                              fontSize: '16px',
                            }}
                          >
                            샘플 배너 만들기
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {Number(this.state.sample_banner_step) == 2 ? (
                      <div
                        className="modal_v3_content"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: '95%',
                        }}
                      >
                        <div style={{ height: '50px' }}>
                          샘플 배너를 만들 사진을 첨부해 주세요.
                        </div>
                        <div
                          style={{
                            margin: '20px auto',
                            width: '50%',
                            flex: '1',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div
                            style={{
                              flex: '1',
                              border: '1px solid #c4c4c4',
                              borderStyle: 'dotted',
                            }}
                          >
                            <MyDropzone
                              attachImage={this.attachImage}
                              this={this}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {Number(this.state.sample_banner_step) == 3 ? (
                      <div
                        className="modal_v3_content"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: '95%',
                        }}
                      >
                        <div style={{ height: '70px' }}>
                          샘플 배너를 만들고 있습니다.
                          <br />
                          잠시만 기다려 주세요.
                        </div>
                        <div
                          style={{
                            margin: 'auto',
                            width: '50%',
                            flex: '1',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div
                            style={{
                              backgroundImage: 'url(' + round_loading_bar + ')',
                              backgroundSize: '45%',
                              backgroundPositionX: 'center',
                              backgroundPositionY: '30%',
                              backgroundRepeat: 'no-repeat',
                              width: '100%',
                              height: '100%',
                            }}
                          ></div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {Number(this.state.sample_banner_step) == 4 ? (
                      <div
                        className="modal_v3_content"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: '95%',
                        }}
                      >
                        <div style={{ height: '70px' }}>
                          마음에 드시는 배너가 있으세요?
                          <br />
                          배너 목록에 저장 후 나중에 수정하실 수 있습니다.
                        </div>
                        <div
                          id="sample_banner_iframe"
                          style={{
                            margin: 'auto',
                            width: '80%',
                            flex: '1',
                            display: 'flex',
                          }}
                        >
                          <object
                            class="robomd_banner_p"
                            type="text/html"
                            data={
                              '/admin_banner.html?event_seq=' +
                              this.state.sample_banner_event_seq +
                              '&platform_type=p&mirror_type=18002'
                            }
                            style={{
                              width: '65%',
                              height: '90%',
                              background: 'white',
                              alignSelf: 'center',
                            }}
                          ></object>
                          <object
                            class="robomd_banner_p"
                            type="text/html"
                            data={
                              '/admin_banner.html?event_seq=' +
                              this.state.sample_banner_event_seq +
                              '&platform_type=m&mirror_type=18002'
                            }
                            style={{
                              width: '35%',
                              height: '90%',
                              background: 'white',
                              alignSelf: 'center',
                              marginLeft: '10px',
                            }}
                          ></object>
                        </div>
                        <div
                          className="sample_banner_btn_area"
                          style={{
                            margin: '30px auto 20px',
                            width: '70%',
                            maxWidth: 'calc(600px + 16%)',
                            height: '60px',
                            display: 'flex',
                          }}
                        >
                          <div
                            className="btn-auto-b sample_banner_btn"
                            style={{
                              height: '100%',
                              lineHeight: '60px',
                              width: '50%',
                              cursor: 'pointer',
                              fontSize: 'min(1vw, 16px)',
                              background: 'white',
                              border: '1px solid #10CFC9',
                              color: '#10CFC9',
                              boxShadow: 'none',
                            }}
                            onClick={(e) => this.newSampleEvent()}
                          >
                            새 배너 만들기
                          </div>
                          <div
                            className="btn-auto-b sample_banner_btn"
                            style={{
                              height: '100%',
                              lineHeight: '60px',
                              width: '50%',
                              cursor: 'pointer',
                              fontSize: 'min(1vw, 16px)',
                              background: 'white',
                              border: '1px solid #10CFC9',
                              color: '#10CFC9',
                              boxShadow: 'none',
                              marginLeft: '8%',
                            }}
                            onClick={(e) => this.createAnotherSampleEvent()}
                          >
                            다른 샘플 보기
                          </div>
                          {this.state.shops.length >= 1 ? (
                            <div
                              className="btn-auto-b sample_banner_btn"
                              style={{
                                height: '100%',
                                lineHeight: '60px',
                                width: '50%',
                                cursor: 'pointer',
                                fontSize: 'min(1vw, 16px)',
                                marginLeft: '8%',
                              }}
                              onClick={(e) => this.saveSampleEvent()}
                            >
                              배너 목록에 저장하기
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            { // 배너 쇼핑몰 연동시 띄워줄 modal
            this.state.is_add_shop_banner_button_click == true ? (
              <div
                className="modal_v3_back"
                style={
                  {
                    // display:"none"
                  }
                }
              >
                <div className="modal_v3_popup_area">
                  <div
                    className="modal_v3_btn_close"
                    onClick={(e) => this.onChangeIsAddShopBannerButtonClick(false)}
                  ></div>
                  <div
                    className="modal_v3_popup"
                    style={{
                      width: '760px',
                      minWidth: '760px',
                      height: 'auto',
                      maxHeight: '70vh',
                      overflowY: 'auto',
                    }}
                  >
                    <div
                      className="modal_v3_title"
                      style={{ textAlign: 'center', fontSize: '25px' }}
                    >
                      <img
                        src={shop_store_icon}
                        style={{ width: '100px', height: 'auto' }}
                      />
                    </div>
                    <br />
                    <div
                      className="modal_v3_title"
                      style={{ textAlign: 'center', fontSize: '25px' }}
                    >
                      쇼핑몰이 성공적으로 연동되었습니다!
                    </div>
                    <div
                      className="modal_v3_content"
                      style={{
                        height: '95%',
                        textAlign: 'center',
                        fontSize: '16px',
                      }}
                    >
                      로보MD와 함께 하는 쇼핑몰 운영 자동화에 한 발 가까워지신 것을 축하드려요. 😀
                      <br />
                      {this.state.end_date_format}까지 14일 동안 배너 서비스를
                      무료로 이용하실 수 있어요.
                      <br />
                      <br />
                      무료체험 기간 동안 날마다 이 시간대에 배너를 1개씩 만들어드리도록 자동 설정까지 도와드렸어요.
                      <br />
                      내일부터 배너가 만들어지면 카카오톡으로 알림 메세지를 보내드릴게요.
                      <br />
                      *자동 배너 설정 메뉴에서 원하시는 대로 설정을 변경하실 수 있습니다.
                      <br />
                      <br />
                      이제 가지고 계신 상품사진으로 샘플 배너를 만들어 보시겠어요?
                      <br />
                      <div
                        className="sample_banner_btn_area"
                        style={{
                          margin: '40px auto 20px',
                          width: '70%',
                          maxWidth: 'calc(600px + 16%)',
                          height: '60px',
                          display: 'flex',
                        }}
                      >
                        <div
                          className="btn-auto-b sample_banner_btn"
                          style={{
                            height: '100%',
                            lineHeight: '60px',
                            width: '50%',
                            cursor: 'pointer',
                            fontSize: 'min(1vw, 16px)',
                            background: 'white',
                            border: '1px solid #10CFC9',
                            color: '#10CFC9',
                            boxShadow: 'none',
                          }}
                          onClick={(e) =>
                            this.onChangeIsAddShopBannerButtonClick(false)
                          }
                        >
                          다음에 할게요.
                        </div>
                        <div
                          className="btn-auto-b sample_banner_btn"
                          style={{
                            height: '100%',
                            lineHeight: '60px',
                            width: '50%',
                            cursor: 'pointer',
                            fontSize: 'min(1vw, 16px)',
                            boxShadow: 'none',
                            marginLeft: '8%',
                          }}
                          onClick={(e) => {
                            this.onChangeIsAddShopBannerButtonClick(false);
                            this.onClickSampleBannerOpen();
                          }}
                        >
                          샘플 배너 만들기
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            { // 진열 쇼핑몰 연동시 보여줄 modal
            this.state.is_add_shop_exhibition_button_click == true ? (
              <div
                className="modal_v3_back"
                style={
                  {
                    // display:"none"
                  }
                }
              >
                <div className="modal_v3_popup_area">
                  <div
                    className="modal_v3_btn_close"
                    onClick={(e) => this.onChangeIsAddShopExhibitionButtonClick(false)}
                  ></div>
                  <div
                    className="modal_v3_popup"
                    style={{
                      width: '760px',
                      minWidth: '760px',
                      height: 'auto',
                      maxHeight: '70vh',
                      overflowY: 'auto',
                    }}
                  >
                    <div
                      className="modal_v3_title"
                      style={{ textAlign: 'center', fontSize: '25px' }}
                    >
                      <img
                        src={shop_store_icon}
                        style={{ width: '100px', height: 'auto' }}
                      />
                    </div>
                    <br />
                    <div
                      className="modal_v3_title"
                      style={{ textAlign: 'center', fontSize: '25px' }}
                    >
                      쇼핑몰이 성공적으로 연동되었습니다!
                    </div>
                    <div
                      className="modal_v3_content"
                      style={{
                        height: '95%',
                        textAlign: 'center',
                        fontSize: '16px',
                      }}
                    >
                      로보MD와 함께 하는 쇼핑몰 운영 자동화에 한 발 가까워지신 것을 축하드려요. 😀
                      <br />
                      {this.state.end_date_format}까지 14일 동안 진열 서비스를 무료로 이용하실 수 있어요.
                      <br />
                      <br />
                      진열 서비스는 연동하신 쇼핑몰에서 상품 정보를 불러온 후에 세부 내용을 보실 수 있어요.
                      상품 정보를 불러오는 데에는 5시간 정도 걸려요. 완료되면 카카오톡으로 알림 메시지를 보내드립니다.
                      <br />
                      <br />
                      저희가 무료체험 기간 동안 이틀에 한번씩 베스트 상품을 선정하도록 나열과 롤링을 각각 자동 설정해드렸는데요,
                      상품 정보 불러오기가 완료되면 진열 목록에서 원하시는 대로 설정을 변경해 보세요!
                      <br />
                      <div
                        className="sample_banner_btn_area"
                        style={{
                          margin: '40px auto 20px',
                          width: '70%',
                          maxWidth: 'calc(600px + 16%)',
                          height: '60px',
                          display: 'flex',
                        }}
                      >
                        <div
                          className="btn-auto-b sample_banner_btn"
                          style={{
                            height: '100%',
                            lineHeight: '60px',
                            width: '100%',
                            cursor: 'pointer',
                            fontSize: 'min(1vw, 16px)',
                            boxShadow: 'none',
                            marginLeft: '8%',
                          }}
                          onClick={(e) => {
                            this.onChangeIsAddShopExhibitionButtonClick(false);
                          }}
                        >
                          알겠습니다. 기대돼요!
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
              )}
              {
                is_reduct_left_menu
                ? this.onClickReductionButton()
                : null            
              }
          </div>
           {/* 확인 취소 다이얼로그 */
                (this.state.confirm_dialog_show_state === true)
                ? <ConfirmDialogV2
                    content={this.state.confirm_dialog_content}
                    button_list={this.state.confirm_dialog_button_list}
                    popup_close={() => {
                        const window_scrollTop = $(window).scrollTop();
                        this.setState({confirm_dialog_show_state: false});
                        // 스크롤 고정 해제
                        StyleUtil.clearPopupNoneScroll(window_scrollTop);
                    }}
                />
                : ''
            }
            {
              <DownloadManager></DownloadManager>
            }
        </>
      );
    } else {
      if (window.location.href.indexOf('/common/curation') >= 0) {
        return (
          <div id="curation_header" style={{
            display:"flex",
            justifyContent:"center",
            position: "fixed",
            top: 0,
            width: "100vw",
            height: "60px",
            background: "#FFFFFF", 
            boxShadow: "0px 2px 5px 1px rgba(0, 0, 0, 0.06)",
            zIndex: 1,
          }}>
            <div id="user_curation_header" >
              <div id="curation_logo_area">
                <div id="curation_robomd_logo">
                  <a href={"https://maisonys.com/index_sblanc.html"} target="_blank">
                    <img
                      src={pc_maison_logo}
                      alt=""
                    />
                  </a>
                </div>
                <Link to={
                            "/common/curation/list/"+(StringUtil.getUrlParams("service_uid") != null ? StringUtil.getUrlParams("service_uid")  : window.location.pathname.split("/").length >= 5 && window.location.pathname.split("/")[4] != null && window.location.pathname.split("/")[4] != "" ? window.location.pathname.split("/")[4] : "")
                            +"?cookie_robotmd_user="+(StringUtil.getUrlParams("cookie_robotmd_user") != null ? StringUtil.getUrlParams("cookie_robotmd_user")  : "")
                            +"&member_id="+(StringUtil.getUrlParams("member_id") != null ? StringUtil.getUrlParams("member_id")  : "")
                            +"&guest_id="+(StringUtil.getUrlParams("guest_id") != null ? StringUtil.getUrlParams("guest_id")  : "")
                          }>
                  <div id="curation_logo" style={{cursor:"pointer"}}>큐레이션 리스트</div>
                </Link>
              </div>
            </div>
          </div>
        )
      } else {
        return <></>;
      }
    }
  }
}

import React from 'react';
import styles from './RoboImageVideoPopup.module.css';
import PopupFrame from '../../modules/popup/PopupFrame';

export class RoboImageVideoPopup extends React.Component {
    constructor(props) {
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleCheckScroll = this.handleCheckScroll.bind(this);
    }

    // 컨텐츠 영역 외 클릭 시 팝업창 닫기
    handleContentOutsideClick = (e) => {
        if (e.target.className.includes('close_area')) { this.props.close(); }
    }

    // Esc키 눌렀을 때 팝업창 닫기
    handleKeyDown(e) {
        if (e.key === "Escape") { this.props.close(); }
    }

    handleCheckScroll = () => {
        var display_none_area = document.querySelector('.display_none');
        var popup = document.querySelector('.robo_image_video_popup');
        if (display_none_area.scrollHeight > window.innerHeight) {
            popup.classList.add('has-scroll');
        } else {
            popup.classList.remove('has-scroll');
        }
    }

    componentDidMount () {
        this.handleCheckScroll();

        // 컴포넌트가 mount 됐을 때 이벤트 실행
        window.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('resize', this.handleCheckScroll);
    }

    componentWillUnmount () {
        // 컴포넌트가 unmount 됐을 때 이벤트 제거
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('resize', this.handleCheckScroll);
    }

    render() {
        const {close} = this.props;
        return (
            <div className={[styles.video_popup, "robo_image_video_popup"].join(' ')} onClick={e => this.handleContentOutsideClick(e)}>
                <PopupFrame
                    className={"close_area"}
                    maxWidth={"1080px"}
                    headerMarginBottom={"0px"}
                    onClose={close}
                >
                    <div className={styles.video}>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/I993WUCG-oQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </PopupFrame>
                {/* 실제 동영상 팝업 사이즈 측정을 위한 복제 element */}
                <PopupFrame
                    className={"display_none"}
                    maxWidth={"1080px"}
                    headerMarginBottom={"0px"}
                >
                    <div className={styles.video}>
                        <iframe width="100%" height="100%"></iframe>
                    </div>
                </PopupFrame>
            </div>
        );
    }
}


RoboImageVideoPopup.defaultProps = {
}
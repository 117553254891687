import React from 'react';
import http from '../modules/api';
import * as StringUtil from '../modules/utils/StringUtil';
import BasicDialogV2 from "../modules/dialogs/BasicDialogV2";
import { PwdReset } from "../login/PwdReset";
import EmailChangePasswordStyle from './EmailChangePassword.module.css';

export class EmailChangePassword extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
            uuid: "",
            is_valid : null // uuid 유효 여부 (Y: 유효, N: 발금된 적 없거나 기한이 만료된 링크)
		}
    }

    componentWillMount() {
	
    }


	componentDidMount() {
        this.setState({
            uuid: StringUtil.getUrlParams("uuid"),
        }, () => {
            this.uuidCheck()
        })
    }

    componentDidUpdate() {
        
    }

    uuidCheck() {
        http.get("/rest/common/check/email/uuid?email_uid="+this.state.uuid)
        .then(
            resultData => {
                let data = resultData.data;
                this.setState({
                    is_valid : data.is_valid
                }, () => {
                    if (this.state.is_valid == "N") { // is_valid : uuid 유효 여부
                        this.setState({
                            basic_dialog_show_state : true
                            , basic_dialog_content : "기한이 만료된 링크입니다."
                            , basic_dialog_content2 : `기한이 만료되어 비밀번호 변경이 불가합니다.
                                                        <br/>다시 비밀번호 찾기를 이용해 주세요.`
                            , basic_dialog_button_list : [
                                {
                                    title: "로그인 페이지 바로가기",
                                    event_method: () => {
                                        this.goToLoginPage();
                                    }
                                }
                            ]
                        });
                    }
                })
            }
        );
    }

    render() {
        return (
            <>
                {
                    this.state.is_valid != null
                    ?
                        this.state.is_valid == "Y"
                        ? 
                        <>
                            <div className={EmailChangePasswordStyle.header+' pc_display_none'}>
                                <img src="/static/media/icn_logo_v2.3ae64c26.svg" height="26px" alt=""/>
                            </div> 
                           <PwdReset
                            email_uid={this.state.uuid}/>
                        </>
                        : ""
                    :   ""
                }
                {
					(this.state.basic_dialog_show_state === true)
						? <BasicDialogV2
							content={this.state.basic_dialog_content}
							content2={this.state.basic_dialog_content2}
							button_list={this.state.basic_dialog_button_list}
						/>
						: ''
				}
            </>
        )
    };
}

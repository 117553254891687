import React, {useState, useMemo, useRef, useEffect, useCallback} from "react";
import {SketchPicker} from "react-color";
import EmojiPicker, {
    EmojiStyle,
    SkinTones,
    Theme,
    Categories,
    EmojiClickData,
    Emoji,
    SuggestionMode,
    SkinTonePickerLocation
} from "emoji-picker-react";
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import '../../../css/modules/text-editor.css';
import $ from "jquery";
import SelectBox from "../common/SelectBox";
import textEditorIcon from './textEditorIcon';
import textEditorFont from "./textEditorFont";
import {tagName} from "./textEditorUtils";
import {textEditorFontSize} from "./textEditorUtils";
import {textEditorLetterSpacing} from "./textEditorUtils";
import {textEditorLineHeight} from "./textEditorUtils";
import axios from "axios";
import * as StyleUtil from "../utils/StyleUtil";
import InputDialogV2 from "../dialogs/InputDialogV2";
import {rgbToHex} from "@mui/material";


// 블록 정보 초기화를 위한 변수
let preClass = "";
let curClass = "";
let windowScrollTop;

const Icon = ({editor, icon, tag, tagName}) => {
    return (
        <div className={"icon_text_editor " + tagName} onClick={(e) => {wrapTag(e, editor, tag, tagName)}} onMouseDown={(e) => e.preventDefault()}>
            {icon}
        </div>
    );
}

const wrapTag = (e, editor, tag, tagName) => {
    document.designMode = "off"

    if(checkSelection()){
        return;
    }
    if(document.getSelection().anchorNode.parentElement.closest("." + editor)){
        switch (tagName) {
            case 'removeFormat':
                document.execCommand('removeFormat', false, null);
                document.execCommand('backColor', false, "initial");

                // 셀렉트박스 초기화
                // document.querySelectorAll('.select_box_text_editor').forEach((element) => {
                //     element.value = "";
                // });
                document.querySelectorAll('.txt_font_family_text_editor').forEach((element) => {
                    element.innerHTML = "글씨체";
                });
                // document.querySelectorAll('.txt_font_size_text_editor').forEach((element) => {
                //     element.innerHTML = "글씨 크기";
                // });
                break;
            case 'p':
                document.execCommand('formatBlock', false, tagName);
                break;
            default:
                document.execCommand(tagName, false, null);
                break;
        }
    }
}

const checkSelection = () =>{
    if(document.getSelection().anchorNode == null){
        return true;
    }
    if(document.getSelection().anchorNode.parentElement == null){
        return true;
    }
    if(document.getSelection().anchorNode.parentElement.closest(".text_editor_title") == null){
        return true;
    }
}

export const TextEditor = ({id, onBannerTemplateChange, onMobileBannerTemplateChange, checkImgLinkUseAble}) => {
    const [selectedRange, setSelectedRange] = useState(null);
    const [fontFamilyIsOpen, setFontFamilyIsOpen] = useState(false);
    const [fontSizeIsOpen, setFontSizeIsOpen] = useState(false);
    const [fontColor, setFontColor] = useState("");
    const [fontBackColor, setFontBackColor] = useState("");
    const [fontColorSketchPickerIsOpen, setFontColorSketchPickerIsOpen] = useState(false);
    const [fontBackColorSketchPickerIsOpen, setFontBackColorSketchPickerIsOpen] = useState(false);
    const [emojiPickerIsOpen, setEmojiPickerIsOpen] = useState(false);
    const [paletteBackIsOpen, setPaletteBackIsOpen] = useState(false);
    const [imgAttachIsOpen, setImgAttachIsOpen] = useState(false);
    const [imgUrlDialogIsOpen, setImgUrlDialogIsOpen] = useState(false);

    const inputRef = useRef(null);

    useMemo(() => {
        // console.log("123");
    }, [])

    const useComponentWillMount = (func) => {
        const willMount = useRef(true);
        if (willMount.current) func();
        willMount.current = false;
    }

    useComponentWillMount(() => {
        // console.log("456");
    });

    useEffect(() => {
        // 텍스트 문구 입력창 blur 이벤트시 selection 유지
        document.querySelector("." + id).onblur = (e) => {
            e.stopPropagation();

            // selection 정보 저장
            saveSelection();
        };

        // 컬러 초기화
        document.querySelector("#" + id + " .removeFormat").onclick = ((e) => {
            setFontColor("");
            setFontBackColor("");
        });

        // 드래그 영역 감지 후 에디터 설정 변경
        document.onmouseup = (e) => {
            try {
                if(document.getSelection().type === 'Range') {
                    let editor = "";

                    if (document.getSelection().anchorNode.parentElement.closest(".subTextEditor")) {
                        editor = "subTextEditor";
                    }else {
                        editor = "mainTextEditor"
                    }

                    let fontFace;
                    let fontSize;
                    let fontColor;
                    let fontBackColor;

                    // 글씨체
                    if(document.getSelection().anchorNode.parentElement.face){
                        fontFace = document.getSelection().anchorNode.parentElement.face;
                    }else if(document.getSelection().anchorNode.parentElement.parentElement.face){
                        fontFace = document.getSelection().anchorNode.parentElement.parentElement.face;
                    }else if(document.getSelection().anchorNode.parentElement.parentElement.parentElement.face){
                        fontFace = document.getSelection().anchorNode.parentElement.parentElement.parentElement.face;
                    }else if(document.getSelection().anchorNode.parentElement.closest("span") != null){
                        fontFace = document.getSelection().anchorNode.parentElement.closest("span").style.fontFamily;
                    }

                    // 글씨 색상
                    if(document.getSelection().anchorNode.parentElement.color){
                        fontColor = document.getSelection().anchorNode.parentElement.color;
                    }else if(document.getSelection().anchorNode.parentElement.parentElement.color){
                        fontColor = document.getSelection().anchorNode.parentElement.parentElement.color;
                    }else if(document.getSelection().anchorNode.parentElement.parentElement.parentElement.color){
                        fontColor = document.getSelection().anchorNode.parentElement.parentElement.parentElement.color;
                    }else if(document.getSelection().anchorNode.parentElement.closest("span") != null){
                        fontColor = document.getSelection().anchorNode.parentElement.closest("span").style.color;
                    }

                    // 글씨 배경 색상
                    if(document.getSelection().anchorNode.parentElement.style.backgroundColor) {
                        fontBackColor = document.getSelection().anchorNode.parentElement.style.backgroundColor;
                    }else if(document.getSelection().anchorNode.parentElement.closest("span") != null){
                        fontBackColor = document.getSelection().anchorNode.parentElement.closest("span").style.backgroundColor;
                    }else if(document.getSelection().anchorNode.parentElement.closest("font") != null) {
                        fontBackColor = document.getSelection().anchorNode.parentElement.closest("font").style.backgroundColor;
                    }

                    // let fontSizeValue = String((Number(fontSize) * 0.2 + 0.6).toFixed(1));
                    //
                    // if(fontSizeValue == "1.0") {
                    //     fontSizeValue = "1";
                    // }else if(fontSizeValue == "2.0") {
                    //     fontSizeValue = "2";
                    // }

                    if(fontFace != null && fontFace != "") {
                        // document.querySelector("#" + editor + " .select_box_font_family").value = fontFace;
                        document.querySelector("#" + editor +" .txt_font_family_text_editor").innerHTML = fontFace;
                    }
                    // if(fontSize != null && fontSize != "") {
                    //     document.querySelector("#" + editor + " .select_box_font_size").value = fontSizeValue;
                    //     document.querySelector("#" + editor +" .txt_font_size_text_editor").innerHTML = fontSizeValue + "x";
                    // }
                    if(fontColor != null && fontColor != "") {
                        document.querySelector("#" + editor + " .input_font_color_text_editor").value = rgbToHex(fontColor);
                        document.querySelector("#" + editor + " .box_font_color_text_editor").style.backgroundColor = rgbToHex(fontColor);
                    }
                    if(fontBackColor != null && fontBackColor != "") {
                        document.querySelector("#" + editor + " .input_font_back_color_text_editor").value = rgbToHex(fontBackColor);
                        document.querySelector("#" + editor + " .box_font_back_color_text_editor").style.backgroundColor = rgbToHex(fontBackColor);
                    }
                }
            }catch (e) {
                console.log(e)
            }
        };

    }, []);

    // 템플릿 변경시 초기화
    useEffect(() => {
        setFontColor("");
        setFontBackColor("");

        // document.querySelectorAll('.select_box_text_editor').forEach((element) => {
        //     element.value = "";
        // });
        document.querySelectorAll('.txt_font_family_text_editor').forEach((element) => {
            element.innerHTML = "글씨체";
        });
        document.querySelectorAll('.txt_font_size_text_editor').forEach((element) => {
            element.innerHTML = "글씨 크기";
        });

        document.querySelectorAll(".icon_text_editor").forEach((element) => {
            element.style.backgroundColor = "#FFF";
        });

    }, [onBannerTemplateChange, onMobileBannerTemplateChange]);

    // 초기화 버튼 클릭
    const removeFormatHandler = (e, id) => {
        if(checkSelection()){
            return;
        }
        if (document.getSelection().anchorNode.parentElement.closest("." + id)) {
            document.execCommand('removeFormat', false, null);
            document.execCommand('backColor', false, "initial");

            // 셀렉트박스 초기화
            // document.querySelectorAll("#" + id + ' .select_box_text_editor').forEach((element) => {
            //     element.value = "";
            // });
            document.querySelectorAll('.txt_font_family_text_editor').forEach((element) => {
                element.innerHTML = "글씨체";
            });
            document.querySelectorAll('.txt_font_size_text_editor').forEach((element) => {
                element.innerHTML = "글씨 크기";
            });

            // 컬러 초기화
            setFontColor("");
            setFontBackColor("");
        }
    }

    // selection 정보 저장
    const saveSelection = () => {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            range && setSelectedRange(range);
        }
    }

    // selection 복구
    const recoverSelection = () => {
        if(document.getSelection().type === 'None'){
            return;
        }

        if(selectedRange){
            const selection = document.getSelection();
            const range = document.createRange();
            range.setStart(selectedRange.startContainer, selectedRange.startOffset);
            range.setEnd(selectedRange.endContainer, selectedRange.endOffset);
            const isBlockSelected = range.startContainer !== range.endContainer || range.startOffset !== range.endOffset;
            if (isBlockSelected) {
                selection.removeAllRanges();
                selection.addRange(range);
            }
        }
    }

    // selection 복구
    useEffect(() => {
        if (!/iPad|iPhone|iPod/.test(navigator.platform)) {
            // recoverSelection();
        }
    }, [selectedRange]);

    // 폰트체 선택
    const handleSelectBoxFontFamilyOnChange = (e, editor) => {
        if(checkSelection()){
            return;
        }
        if (document.getSelection().anchorNode.parentElement.closest(".text_editor_title").className.includes(editor)) {
            document.execCommand("fontName", false, e.target.value);
        }
    }

    // 폰트 사이즈 선택
    const handleSelectBoxFontSizeOnChange = (e, editor) => {
        if(checkSelection()){
            return;
        }
        if (document.getSelection().anchorNode.parentElement.closest(".text_editor_title").className.includes(editor)) {
            switch (e.target.value) {
                case "0.8" :
                    document.execCommand("fontSize", false, "1");
                    break;
                case "1" :
                    document.execCommand("fontSize", false, "2");
                    break;
                case "1.2" :
                    document.execCommand("fontSize", false, "3");
                    break;
                case "1.4" :
                    document.execCommand("fontSize", false, "4");
                    break;
                case "1.6" :
                    document.execCommand("fontSize", false, "5");
                    break;
                case "1.8" :
                    document.execCommand("fontSize", false, "6");
                    break;
                case "2" :
                    document.execCommand("fontSize", false, "7");
                    break;
            }
        }
    }

    // 폰트 자간 선택
    const handleSelectBoxLetterSpacingOnChange = (e, editor) => {
        if(selectedRange && selectedRange.startContainer.parentElement.closest("." + editor)){
            // recoverSelection();
            // execHandler('letter-spacing', e.target.value, 'em');
        }
    }

    // 폰트 행간 선택
    const handleSelectBoxLineHeightOnChange = (e, editor) => {
        if(selectedRange && selectedRange.startContainer.parentElement.closest("." + editor)){
            // recoverSelection();
            // execHandler('line-height', e.target.value, 'em');
        }
    }

    // 폰트 색상 팔레트 열고 닫기
    const handleFontColorClick = (e, id) => {
        recoverSelection();
        setFontColorSketchPickerIsOpen((prev) => !prev);
    }

    // 폰트 배경 색상 팔레트 열고 닫기
    const handleFontBackColorClick = (e, id) => {
        recoverSelection();
        setFontBackColorSketchPickerIsOpen((prev) => !prev);
    };

    useEffect(() => {
        if(fontColorSketchPickerIsOpen){
            document.querySelector("#" + id + " .font_color_sketchpicker").style.display = "block";
            document.querySelector("#" + id + " .icon_font_color_text_editor").classList.replace("icn_four_ractangle", "icn_four_ractangle_on");
            setPaletteBackIsOpen(true);
        }

        document.querySelector("#palleteBack").onclick = ((e) => {
            closePaletteHandler();
            setFontColorSketchPickerIsOpen(false);
            setPaletteBackIsOpen(false);
        });
    }, [fontColorSketchPickerIsOpen]);


    useEffect(() => {
        if(fontBackColorSketchPickerIsOpen){
            document.querySelector("#" + id + " .font_back_color_sketchpicker").style.display = "block";
            document.querySelector("#" + id + " .icon_font_back_color_text_editor").classList.replace("icn_four_ractangle", "icn_four_ractangle_on");
            setPaletteBackIsOpen(true);
        }

        document.querySelector("#palleteBack").onclick = ((e) => {
            closePaletteHandler();
            setFontBackColorSketchPickerIsOpen(false);
            setPaletteBackIsOpen(false);
        });
    }, [fontBackColorSketchPickerIsOpen]);

    useEffect(() => {
        if(paletteBackIsOpen){
            document.querySelector("#palleteBack").style.display = "block";
        }else{
            document.querySelector("#palleteBack").style.display = "none";
        }
    }, [paletteBackIsOpen]);

    // 색상 팔레트 닫기
    const closePaletteHandler = () => {
        document.querySelectorAll(".sketch-picker").forEach((element) => {
            element.style.display = "none";
        });
        document.querySelectorAll(".icon_font_color_text_editor").forEach((element) => {
            element.classList.replace("icn_four_ractangle_on", "icn_four_ractangle");
        });
        document.querySelectorAll(".icon_font_back_color_text_editor").forEach((element) => {
            element.classList.replace("icn_four_ractangle_on", "icn_four_ractangle");
        });
    };

    const handleChangeFontColor = (color, editor, complete) => {
        setFontColor(color.hex);
        try{
            if(checkSelection()){
                return;
            }
            if (document.getSelection().anchorNode.parentElement.closest(".text_editor_title").className.includes(editor)) {
                document.execCommand("foreColor", false, color.hex);
            }
        }catch (e) {
            console.log(e);
        }
    };

    const handleChangeFontBackColor = (color, editor, complete) => {
        setFontBackColor(color.hex);
        try{
            if(checkSelection()){
                return;
            }
            if (document.getSelection().anchorNode.parentElement.closest(".text_editor_title").className.includes(editor)) {
                document.execCommand("backColor", false, color.hex);
            }
        }catch (e) {
            console.log(e);
        }
    };

    // const onEmojiClick = (emojiData) => {
    //     console.log(emojiData)
    //     setSelectedEmoji(emojiData.unified);
    // }

    const handleEmojiToggleOnClick = () => {
        setEmojiPickerIsOpen((prev) => !prev);
    };

    useEffect(() => {
        if(emojiPickerIsOpen){
            document.querySelector("#" + id + " em-emoji-picker").style.display = "flex";
            setPaletteBackIsOpen(true);
        }else{
            document.querySelector("#" + id + " em-emoji-picker").style.display = "none";
        }

        document.querySelector("#palleteBack").onclick = (e) => {
            document.querySelectorAll("em-emoji-picker").forEach((element) => {
                element.style.display = "none";
            });
            setEmojiPickerIsOpen(false);
            setPaletteBackIsOpen(false);
        }
    }, [emojiPickerIsOpen]);

    const handleEmojiSelect = (emoji, id) => {
        try{
            if(selectedRange){
                if(selectedRange.startContainer.parentElement.closest("." + id)){
                    if(document.getSelection().type === 'Range'){
                        execEmoji(emoji);

                    }else {
                        focusInTextarea(id);
                        insertEmoji(emoji);
                        recoverSelection();
                        execEmoji(emoji);
                    }
                } else {
                    focusInTextarea(id);
                    insertEmoji(emoji);
                    recoverSelection();
                    execEmoji(emoji);
                }
            }else{
                focusInTextarea(id);
                const spanNode = document.createElement('span');
                spanNode.innerHTML = emoji.native;
                document.getSelection().getRangeAt(0).insertNode(spanNode);
                recoverSelection();
                execEmoji(emoji);
            }
        }catch (e) {
            console.log(e);
        }
        setEmojiPickerIsOpen(false);
        setPaletteBackIsOpen(false);
    }

    const insertEmoji = (emoji) => {
        const spanNode = document.createElement('span');
        spanNode.innerHTML = emoji.native;
        selectedRange.insertNode(spanNode);
    }

    const execEmoji = (emoji) => {
        const spanString = $('<span/>', {
            'text': emoji.native
        }).prop('outerHTML');

        document.execCommand('insertHTML', false, spanString);
    }

    const focusInTextarea = (id) => {
        const newRange = document.createRange();

        if(document.querySelector("." + id).lastChild){
            newRange.setStart(document.querySelector("." + id).lastChild, 0);
            newRange.setEnd(document.querySelector("." + id).lastChild, 0);
        }else{
            newRange.setStart(document.querySelector("." + id), 0);
            newRange.setEnd(document.querySelector("." + id), 0);
        }
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(newRange);
    }

    useEffect(() => {
        document.onclick = (e) => {chooseTextEditor(e)};
    });

    const chooseTextEditor = (e) => {
        if(e.target.closest("#mainTextEditor") || e.target.closest(".mainTextEditor")){
            iconBackgroundToggleHandler(e, "mainTextEditor");
        }else if(e.target.closest("#subTextEditor") || e.target.closest(".subTextEditor")){
            iconBackgroundToggleHandler(e, "subTextEditor");
        }
    }

    const iconBackgroundToggleHandler = (e, editor) => {
        changeIconBackgroundHandler(editor, "b", "bold");
        changeIconBackgroundHandler(editor, "i", "italic");
        changeIconBackgroundHandler(editor, "u", "underline");
        changeIconBackgroundHandler(editor, "strike", "strikeThrough");
        changeIconBackgroundHandler(editor, "ul", "insertUnorderedList");
        changeIconBackgroundHandler(editor, "ol", "insertOrderedList");
    }

    // 텍스트 에디터 아이콘 눌림 표시
    const changeIconBackgroundHandler = (editor, tag, tagName) => {
        if(checkSelection()){
            return;
        }
        if (document.getSelection().anchorNode.parentElement.closest(".text_editor_title").className.includes(editor)) {
            if (document.getSelection().anchorNode.parentElement.closest(tag) || document.getSelection().focusNode.parentElement.closest(tag)) {
                document.querySelectorAll("#" + editor + " ."+tagName).forEach((element) => {
                    element.style.backgroundColor = "#E3E3E3";
                    element.style.borderRadius = "6px";
                });
            } else {
                document.querySelectorAll("#" + editor + " ."+tagName).forEach((element) => {
                    element.style.backgroundColor = "#FFF";
                });
            }
        }
    }

    const handleInsertImageOnClick = () => {
        setImgAttachIsOpen((prev) => !prev);
    }

    useEffect(() => {
        if(imgAttachIsOpen){
            document.querySelector("#" + id + " .img_attach_editor").style.display = "block";
            setImgAttachIsOpen(true);
            setPaletteBackIsOpen(true);
        }else{
            document.querySelector("#" + id + " .img_attach_editor").style.display = "none";
            setImgAttachIsOpen(false);
            setPaletteBackIsOpen(false);
        }

        document.querySelector("#palleteBack").onclick = (e) => {
            document.querySelector("#" + id + " .img_attach_editor").style.display = "none";
            setImgAttachIsOpen(false);
            setPaletteBackIsOpen(false);
        }
    }, [imgAttachIsOpen]);

    const checkFileExtension = (file) => {
        let reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/i;

        if(String(file[0].name).match(reg)) {
            return true;
        }else{
            return false;
        }
    }

    const handleImgUploadOnChange = useCallback((e) => {
        if (!e.target.files) {
            return;
        }

        if(!checkFileExtension(e.target.files)){
            alert("이미지 파일만 첨부할 수 있습니다.");
            return;
        }

        const formData = new FormData();
        formData.append('files', e.target.files[0]);

        axios({
            url: '/rest/shop/roboBanner/save/orgImg',
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                let img_path = response.data.org_img_path_uri_encode;
                insertImg(img_path);
                setImgAttachIsOpen(false);
            })
            .catch(error => {
                console.error(error);
            });

        e.target.value = '';

    }, []);

    const insertImg = (img_path) => {
        focusInTextarea(id);
        const imgString = '<img class="img_in_text_editor" src="' + img_path + '"/>';
        document.execCommand('insertHTML', false, imgString);
        focusInTextarea(id);
    }

    const handleImgUploadOnClick = useCallback(() => {
        if (!inputRef.current) {
            return;
        }
        inputRef.current.click();
    }, []);

    const handleEnterImgUrlOnClick = () => {
        setImgUrlDialogIsOpen((prev) => !prev);
    }

    useEffect(() => {
        if(imgUrlDialogIsOpen){
            document.querySelector("#" + id + " .img_attach_editor").style.display = "none";
            windowScrollTop = StyleUtil.onPopupNoneScroll(windowScrollTop);
        }else{
            document.querySelector("#" + id + " .img_attach_editor").style.display = "none";
            StyleUtil.clearPopupNoneScroll(windowScrollTop);
        }
    }, [imgUrlDialogIsOpen]);

    const handleSelectListFontFamilyToggle = () => {
        recoverSelection();
        setFontFamilyIsOpen((prev) => !prev)
    }

    useEffect(() => {
        if(fontFamilyIsOpen){
            if(document.querySelector("#" + id + " .select_list_font_family")){
                document.querySelector("#" + id + " .select_list_font_family").style.display = "block";
                document.querySelector("#" + id + " .select_list_font_family").style.top = "42px";
            }else if(document.querySelector("#" + id + " .select_list_font_family_mobile")) {
                document.querySelector("#" + id + " .select_list_font_family_mobile").style.display = "block";
                document.querySelector("#" + id + " .select_list_font_family_mobile").style.top = "30vh";
                windowScrollTop = StyleUtil.onPopupNoneScroll(windowScrollTop);

                if(document.getSelection().type === 'Range') {
                    hideKeyboard(id);
                }
            }
            setFontFamilyIsOpen(true);
            setPaletteBackIsOpen(true);

        }else {
            closeSelectListFontFamily(id);
        }

        document.querySelector("#palleteBack").onclick = (e) => {
            closeSelectListFontFamily(id);
        }

    }, [fontFamilyIsOpen]);

    const closeSelectListFontFamily = (id) => {
        if(document.querySelector("#" + id + " .select_list_font_family")){
            document.querySelector("#" + id + " .select_list_font_family").style.display = "none";
        }else if(document.querySelector("#" + id + " .select_list_font_family_mobile")) {
            document.querySelector("#" + id + " .select_list_font_family_mobile").style.top = "1000vh";
            StyleUtil.clearPopupNoneScroll(windowScrollTop);
        }
        setFontFamilyIsOpen(false);
        setPaletteBackIsOpen(false);
    }

    const handleSelectListFontFamilyOnClick = (e, editor, fontName, fontValue) => {
        recoverSelection();
        document.execCommand("fontName", false, fontName);
        document.querySelector("#" + editor +" .txt_font_family_text_editor").innerHTML = fontValue;
    }

    const handleSelectListFontSizeToggle = () => {
        recoverSelection();
        setFontSizeIsOpen((prev) => !prev)
    }

    // useEffect(() => {
    //     if(fontSizeIsOpen){
    //         if(document.querySelector("#" + id + " .select_list_font_size")){
    //             document.querySelector("#" + id + " .select_list_font_size").style.display = "block";
    //             document.querySelector("#" + id + " .select_list_font_size").style.top = "42px";
    //         }else if(document.querySelector("#" + id + " .select_list_font_size_mobile")) {
    //             document.querySelector("#" + id + " .select_list_font_size_mobile").style.display = "block";
    //             document.querySelector("#" + id + " .select_list_font_size_mobile").style.top = "60vh";
    //             windowScrollTop = StyleUtil.onPopupNoneScroll(windowScrollTop);
    //
    //             if(document.getSelection().type === 'Range') {
    //                 hideKeyboard(id);
    //             }
    //         }
    //         setFontSizeIsOpen(true);
    //         setPaletteBackIsOpen(true);
    //
    //     }else {
    //         closeSelectListFontSize(id);
    //     }
    //
    //     document.querySelector("#palleteBack").onclick = (e) => {
    //         closeSelectListFontSize(id);
    //     }
    //
    // }, [fontSizeIsOpen]);

    const closeSelectListFontSize = (id) => {
        if(document.querySelector("#" + id + " .select_list_font_size")){
            document.querySelector("#" + id + " .select_list_font_size").style.display = "none";
        }else if(document.querySelector("#" + id + " .select_list_font_size_mobile")) {
            document.querySelector("#" + id + " .select_list_font_size_mobile").style.top = "1000vh";
            StyleUtil.clearPopupNoneScroll(windowScrollTop);
        }
        setFontSizeIsOpen(false);
        setPaletteBackIsOpen(false);
    }

    const handleSelectListFontSizeOnClick = (e, editor, value) => {
        recoverSelection();
        switch (value) {
            case 0.8 :
                document.execCommand("fontSize", false, "1");
                break;
            case 1 :
                document.execCommand("fontSize", false, "2");
                break;
            case 1.2 :
                document.execCommand("fontSize", false, "3");
                break;
            case 1.4 :
                document.execCommand("fontSize", false, "4");
                break;
            case 1.6 :
                document.execCommand("fontSize", false, "5");
                break;
            case 1.8 :
                document.execCommand("fontSize", false, "6");
                break;
            case 2 :
                document.execCommand("fontSize", false, "7");
                break;
        }
        document.querySelector("#" + editor +" .txt_font_size_text_editor").innerHTML = value + "x";
    }

    const hideKeyboard = (id) => {
        setTimeout(function () {
            document.querySelector("#" + id + " .hide_mobile_keybord").style.display = "block";
            document.querySelector("#" + id + " .hide_mobile_keybord").focus();
            setTimeout(function () {
                document.querySelector("#" + id + " .hide_mobile_keybord").style.display = "none";
            }, 10);
        }, 10);
    }

    const line = <div className={"line_text_editor"}>{textEditorIcon.line}</div>;

    return (
        <div id={id} className={"container_text_editor"}>
            <input type="text" className={"hide_mobile_keybord"} style={{display: "none"}}/>
            <div className={"wrap_text_editor"}>
                <div className={"font_family_text_editor"} onClick={handleSelectListFontFamilyToggle}>
                    {/*<SelectBox className={"select_box_font_family"} options={textEditorFont} onChange={(e) => {handleSelectBoxFontFamilyOnChange(e, id)}} defaultValue={"글씨체"} />*/}
                    <div className={"txt_font_family_text_editor"}>글씨체</div>
                    {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ?
                        <div className={"select_list_text_editor_mobile select_list_font_family_mobile"}>
                            <ul>
                                <button className={"btn_close_select_list"} onClick={() => {closeSelectListFontFamily(id);handleSelectListFontFamilyToggle();}}>
                                    {textEditorIcon.arrowDownBold}
                                </button>
                                {textEditorFont.map((obj) => (
                                    <li style={{fontFamily: obj.name}} onClick={(e) => handleSelectListFontFamilyOnClick(e, id, obj.name, obj.value)}>{obj.value}</li>
                                ))}
                            </ul>
                        </div>
                        :
                        <div className={"select_list_text_editor select_list_font_family"}>
                            <ul>
                                {textEditorFont.map((obj) => (
                                    <li style={{fontFamily: obj.name}} onClick={(e) => handleSelectListFontFamilyOnClick(e, id, obj.name, obj.value)}>{obj.value}</li>
                                ))}
                            </ul>
                        </div>
                    }
                    <div className={"select_box_arrow"}>
                        {textEditorIcon.arrowDown}
                    </div>
                </div>
                {/*{line}*/}
                {/*<div className={"font_size_text_editor"} onClick={handleSelectListFontSizeToggle}>*/}
                {/*    /!*<SelectBox className={"select_box_font_size"} options={textEditorFontSize} onChange={(e) => {handleSelectBoxFontSizeOnChange(e, id)}} defaultValue={"글씨 크기"} />*!/*/}
                {/*    <div className={"txt_font_size_text_editor"}>글씨 크기</div>*/}
                {/*    {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ?*/}
                {/*        <div className={"select_list_text_editor_mobile select_list_font_size_mobile"}>*/}
                {/*            <ul>*/}
                {/*                <button className={"btn_close_select_list"} onClick={() => {closeSelectListFontSize(id);handleSelectListFontSizeToggle();}}>*/}
                {/*                    {textEditorIcon.arrowDownBold}*/}
                {/*                </button>*/}
                {/*                {textEditorFontSize.map((obj) => (*/}
                {/*                    <li onClick={(e) => handleSelectListFontSizeOnClick(e, id, obj.value)}>{obj.value}x</li>*/}
                {/*                ))}*/}
                {/*            </ul>*/}
                {/*        </div>*/}
                {/*        :*/}
                {/*        <div className={"select_list_text_editor select_list_font_size"}>*/}
                {/*            <ul>*/}
                {/*                {textEditorFontSize.map((obj) => (*/}
                {/*                    <li onClick={(e) => handleSelectListFontSizeOnClick(e, id, obj.value)}>{obj.value}x</li>*/}
                {/*                ))}*/}
                {/*            </ul>*/}
                {/*        </div>*/}
                {/*    }*/}
                {/*    <div className={"select_box_arrow"}>*/}
                {/*        {textEditorIcon.arrowDown}*/}
                {/*    </div>*/}
                {/*</div>*/}
                {line}
                <div className={"bg_text_editor"}>
                    <Icon editor={id} icon={textEditorIcon.bold} tagName={tagName.bold} tag={"b"} />
                    <Icon editor={id} icon={textEditorIcon.underline} tagName={tagName.underline} tag={"u"} />
                    <Icon editor={id} icon={textEditorIcon.strikeThrough} tagName={tagName.strikeThrough} tag={"strike"} />
                </div>
                {line}
                <div className={"bg_text_editor"}>
                    <Icon editor={id} icon={textEditorIcon.insertUnorderedList} tagName={tagName.insertUnorderedList} tag={"ul"} />
                    <Icon editor={id} icon={textEditorIcon.insertOrderedList} tagName={tagName.insertOrderedList} tag={"ol"} />
                </div>
                {/*{line}*/}
                {/*<div className={"bg_text_editor bg_select_box"}>*/}
                {/*    <Icon editor={id} icon={textEditorIcon.letterSpacing} tagName={tagName.letterSpacing} />*/}
                {/*    <SelectBox className={"select_box_letter_spacing"} options={textEditorLetterSpacing} onChange={(e) => handleSelectBoxLetterSpacingOnChange(e, id)} />*/}
                {/*</div>*/}
                {/*{line}*/}
                {/*<div className={"bg_text_editor bg_select_box"}>*/}
                {/*    <Icon editor={id} icon={textEditorIcon.lineHeight} tagName={tagName.lineHeight} />*/}
                {/*    <SelectBox options={textEditorLineHeight} className={"select_box_line_height"} onChange={(e) => handleSelectBoxLineHeightOnChange(e, id)} />*/}
                {/*</div>*/}
                {line}
                <div className={"bg_text_editor"}>
                    <div className={"img_text_editor"}>
                        <div className={"icon_text_editor insertImage"} onClick={(e) => handleInsertImageOnClick(e, id)}>
                            {textEditorIcon.insertImage}
                        </div>
                        <div className={"wrap_img_attach_editor"}>
                            <div className={"img_attach_editor"}>
                                <input type="file" accept="image/*" ref={inputRef} onChange={handleImgUploadOnChange} style={{display: "none"}}/>
                                <div className={"img_upload_editor"} onClick={handleImgUploadOnClick}>이미지 파일 업로드</div>
                                <div className={"enter_img_url_editor"} onClick={handleEnterImgUrlOnClick}>이미지 URL 입력</div>
                            </div>
                        </div>
                    </div>
                </div>
                {line}
                <div className={"bg_text_editor"}>
                    <Icon editor={id} icon={textEditorIcon.removeFormat} tagName={tagName.removeFormat} />
                    <button className={"txt_removeFormat"} onClick={(e) => removeFormatHandler(e, id)}>초기화</button>
                </div>
                {line}
                <div className={"bg_text_editor"}>
                    <Icon editor={id} icon={textEditorIcon.undo} tagName={tagName.undo} />
                    <Icon editor={id} icon={textEditorIcon.redo} tagName={tagName.redo} />
                </div>

                <div className={"font_color_text_editor"}>
                    <Icon editor={id} icon={textEditorIcon.foreColor} />
                    <input className={"input_font_color_text_editor"} value={fontColor} placeholder={"#FFFFFF"} readOnly={true} disabled={true} />
                    <div className={"wrap_font_color_text_editor"}>
                        <div className={"box_font_color_text_editor"} style={{backgroundColor:fontColor}} onClick={(e) => handleFontColorClick(e, id)}></div>
                        <div className={"icon_font_color_text_editor icn_four_ractangle"} onClick={(e) => handleFontColorClick(e, id)}>
                            <div className={"wrap_sketchpicker"}>
                                <SketchPicker
                                    className={"font_color_sketchpicker"}
                                    color={fontColor}
                                    onChange={(e) => handleChangeFontColor(e, id)}
                                    onChangeComplete={(e) => handleChangeFontColor(e, id, true)}
                                    disableAlpha
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {line}
                <div className={"font_color_text_editor"}>
                    <Icon editor={id} icon={textEditorIcon.backColor} />
                    <input className={"input_font_back_color_text_editor"} value={fontBackColor} placeholder={"#FFFFFF"} readOnly={true} disabled={true} />
                    <div className={"wrap_font_color_text_editor"}>
                        <div className={"box_font_back_color_text_editor"} style={{backgroundColor:fontBackColor}} onClick={(e) => handleFontBackColorClick(e, id)}></div>
                        <div className={"icon_font_back_color_text_editor icn_four_ractangle"} onClick={(e) => handleFontBackColorClick(e, id)}>
                            <div className={"wrap_sketchpicker"}>
                                <SketchPicker
                                    className={"font_back_color_sketchpicker"}
                                    color={fontBackColor}
                                    onChange={(e) => handleChangeFontBackColor(e, id)}
                                    onChangeComplete={(e) => handleChangeFontBackColor(e, id, true)}
                                    disableAlpha
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {line}
                <div className={"wrap_emoji_text_editor"}>
                    <div className={"emoji_text_editor"}>
                        <div className={"emoji_picker"} onClick={handleEmojiToggleOnClick}>&#128578;</div>
                    </div>
                    <Picker data={data}
                            categories={["frequent", "people", "nature", "foods", "activity", "places", "objects", "symbols", "flags"]}
                            onEmojiSelect={emoji => handleEmojiSelect(emoji, id)}
                    />
                    {/*<EmojiPicker*/}
                    {/*    onEmojiClick={onEmojiClick}*/}
                    {/*    autoFocusSearch={false}*/}
                    {/*    theme={Theme.AUTO}*/}
                    {/*    emojiStyle={EmojiStyle.NATIVE}*/}
                    {/*/>*/}
                </div>

            </div>{/* ./end wrap_text_editor  */}

            {/* 에디터 하단 문구 */}
            {/*<p className={"notice_text_editor"}><i className={"fa fa-exclamation-circle"} aria-hidden="true"></i>&nbsp;글씨체에 따라 에디터 기능이 적용되지 않을 수 있어요.</p>*/}

            {/* 이미지 URL 입력 다이얼로그 */}
            {imgUrlDialogIsOpen &&
                <InputDialogV2
                    title={"이미지 URL 입력"}
                    input_value={""}
                    button_list={[
                        {
                            title: "확인",
                            type: "1",
                            event_method: (e) => {
                                if(e.target.value === ""){
                                    alert("이미지 URL 주소를 입력해주세요.");
                                } else{
                                    checkImgLinkUseAble(e.target.value ,function () {
                                        insertImg(e.target.value);
                                        setImgUrlDialogIsOpen(false);
                                        StyleUtil.clearPopupNoneScroll(windowScrollTop);

                                    }, function () {
                                        alert("올바른 URL 주소를 입력해주세요.");
                                    });
                                }
                            }
                        },
                        {
                            title: "취소",
                            type: "0",
                            event_method: (e) => {
                                setImgUrlDialogIsOpen(false);
                                StyleUtil.clearPopupNoneScroll(windowScrollTop);
                            }
                        }
                    ]}
                    popup_close={() => {
                        setImgUrlDialogIsOpen(false);
                        StyleUtil.clearPopupNoneScroll(windowScrollTop);
                    }}
                />
            }

        </div>// ./end container_text_editor
    );

}

// 블록 정보 초기화 (텍스트 문구 입력창 onMouseDown 이벤트에 추가)
export const initSelectionOnMouseDown = (e) => {
    e.stopPropagation();

    preClass = curClass
    curClass = e.target.className

    if (preClass != "" && preClass != curClass) {
        const selection = window.getSelection();
        selection.removeAllRanges();
        e.target.focus();
    }
}
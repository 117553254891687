import { React, Component } from 'react';
import '../../resources/fb/css/reset.css';
import '../../resources/fb/css/master.css';
import '../../resources/fb/css/bootstrap.min.css';
import '../../resources/fb/css/master.css';
import '../../resources/fb/css/trend_all.css';
import '../../resources/fb/css/responsive.css';
import TrendChart from './component/TrendChart';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import axios from 'axios';
import TitleContentDialogV2 from '../modules/dialogs/TitleContentDialogV2';

class TrendAll extends Component {

    constructor(props) {
        super(props);

        var today = new Date();
        var date = new Date(Number(today.getFullYear()), Number(today.getMonth()), Number(today.getDate()))
        this.state = {
            date: date,
            goods_type: 'RANK',
            keyword_seq: 4,
            keyword_list: [],
            last_date: new Date(),
            goods_list: [],
            rank_item: {'item_no': '-1', 'goods_name': ''},
            rank_draw: false,
        };
    }

    componentDidMount() {
        axios.get('/rest/coupang/getKeywordList').then(
            response => {
                this.setState({
                    keyword_list: response.data.list,
                    keyword_seq: response.data.list[0].keyword_seq,
                });
            }
        );

        if (true) {
            const { history } = this.props;
            this.setState({
                show_save_title_content_dialog : true,
                title_content_dialog_button_list : [{
                    title : "신청하기",
                    event_method : (e) => {
                        this.setState({
                            show_save_title_content_dialog : false,
                        });
                        history.push({
                            pathname: `/shop/qna/form`,
                            state: { qna_type_cd: "2108" }
                        });
                    }
                },{
                    title : "체험하기",
                    event_method : () => {this.setState({show_save_title_content_dialog : false})}
                }]
            });
        }

        axios.get('/rest/coupang/getLastDate').then(
            response => {
                var yyyyMMdd = String(response.data.lastDate);
                var yyyy = yyyyMMdd.substring(0,4);
                var mm = yyyyMMdd.substring(4,6);
                var dd = yyyyMMdd.substring(6,8);
                var date = new Date(Number(yyyy), Number(mm)-1, Number(dd))
                this.setState({
                    date: date,
                    last_date: date,
                });

                this.get_list();
            }
        )
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    onClickType = (e) => {
        var data_type = e.target.getAttribute('data-type');
        this.setState({
            goods_type: data_type,
        }, () => this.get_list());
    }

    onClickKeyword = (e) => {
        this.setState({
            keyword_seq: e.target.value,
        }, () => this.get_list());
    }

    get_keyword_list() {
        return this.state.keyword_list.map((item, i) => 
            <option value={item.keyword_seq}>{item.display_name}</option>
        );
    }

    get_date_message() {
        var date = this.state.date;
        var week = ['일','월','화','수','목','금','토'];

        var day_message = week[date.getDay()] + '요일'; 
        return <>
                    <p style={{marginBottom: 0, alignSelf: "center"}}>
                        {/* {date_message} */}
                        <DatePicker name="from_date" id="from_date" className="fb_from_date_no_border font_family_normal"
                            selected={this.state.date}
                            onSelect={this.onSelectFromDate}
                            placeholderText="일자"
                            dateFormat="yyyy년 MM월 dd일"
                            autoComplete="off"
                            showYearDropdown
                            showMonthDropdown
                            locale={ko}
                            minDate={new Date("2021","04","02")} // 2021-05-02
                            maxDate={this.state.last_date}
                        />
                    </p>
                    <span className="fb_from_date_no_border" style={{margin:"auto 0"}}>{day_message}</span>
                </>;
    }

    make_graph_date = () => {
        var date = this.state.date;
        var year = date.getFullYear();
        var month = (1 + date.getMonth());
        month = month >= 10 ? month : '0' + month;
        var day = date.getDate();
        day = day >= 10 ? day : '0' + day;

        return year + '-' + month + '-' + day;
    }
    
    prev_day = () => {
        if (this.state.date <= new Date("2021","04","02")) return false;

        var date = this.state.date
        date = new Date(date.setDate(date.getDate()-1))
        this.setState({
            date: new Date(date),
        }, () => {
            this.get_list();
        });
    }

    next_day = () => {
        var date = this.state.date
        if( (date <= this.state.last_date) === false ) return;
        date = new Date(date.setDate(date.getDate()+1))

        this.setState({
            date: new Date(date),
        }, () => {
            this.get_list();
        });
    }

    get_list = () => {
        if( this.state.keyword_seq === '') return;

        var date = this.state.date;
        var year = date.getFullYear();
        var month = (1 + date.getMonth());
        month = month >= 10 ? month : '0' + month;
        var day = date.getDate();
        day = day >= 10 ? day : '0' + day;
        var date_message = String(year) + String(month) + String(day);

        var url = '/rest/coupang/getGoodsListByKeyword?date=' + date_message + '&keyword_seq=' + this.state.keyword_seq + '&goods_type=' + this.state.goods_type
		axios.get(url).then(response => {
            this.setState({
                goods_list: response.data.list,
            });
        });
    }

    make_number = (price) => {
        try {
            return Number(price).toLocaleString()
        } catch (e) {
            return "-1"
        }
    }

    make_price = (item) => {
        if( item.item_price === item.item_sale_price ) return;
        return <strike>{this.make_number(item.item_price)}</strike>
    }

    make_display = (item) => {
        return  <div className="cont_bt">
                    <p>{item.goods_name}</p>
                    {this.make_price(item)}
                    <span>{this.make_number(item.item_sale_price)}</span>
                    {item.expected_arrival}{ item.coupang_recommend_yn === 'Y' ? ', 쿠팡추천' : ''}{ item.rocket_delivery_yn === 'Y' ? ', 로켓배송': ''}
                    {item.coupon_yn === 'Y' ? '쿠폰제공' : ''}
                </div>
    }

    make_icon = (item) => {
        if( item.before_rank === 51 ) return 'new'
        if( item.rank === item.before_rank ) return 'gray'
        if( item.rank < item.before_rank ) return 'green'
        if( item.rank > item.before_rank ) return 'pink'
    }

    make_image = (item) => {
        if( item.before_rank === 51 ) return 'ico_new.png'
        if( item.rank === item.before_rank ) return 'Ranking_NoChange.png'
        if( item.rank < item.before_rank ) return 'Ranking_Increase.png'
        if( item.rank > item.before_rank ) return 'Ranking_Degradation.png'
    }

    make_rank = (item) => {
        if( item.before_rank === 51 ) return ''
        return  <div className="number">
                    <p>{item.before_rank - item.rank}</p>
                </div>
    }

    show_rank_graph = (item) => {
        this.setState({
            ...this.state,
            rank_item: item,
            rank_draw: true,
        })
    }

    graph_close = () => {
        this.setState({
            ...this.state,
            rank_draw: false,
        })
    }

    make_goods_list = () => {
        if( this.state.goods_list.length === 0 ) return '조건에 해당하는 상품이 없습니다.'
        return this.state.goods_list.map((item, i) => 
            <li>
                <div className="cont_top">
                    <h3>{i+1}</h3>
                    <div className={`icon icon_${this.make_icon(item)}`} onClick={() => this.show_rank_graph(item)} style={{cursor: 'pointer'}}>
                        <div className="img_area">
                            <img src={`/img/icon/${this.make_image(item)}`} alt="" />
                        </div>
                        {this.make_rank(item)}
                    </div>
                </div>
                <div className="cont_md">
                    <img width="300" height="300" src={item.img_url} alt={item.goods_name}/>
                </div>
                {this.make_display(item)}
            </li>    
        );
    }

    onSelectFromDate = (date) => {
        if (date != null && date != "") {
            this.setState({
                date: date,
            }, () => {
                this.get_list();
            });
        }
    }

    render() {
        const is_rank = this.state.goods_type === 'RANK' ? 'active' : '';
        const opacity_rank = this.state.goods_type === 'RANK' ? 100+'%' : 40+'%';
        const is_sale = this.state.goods_type === 'SALE' ? 'active' : '';
        const opacity_sale = this.state.goods_type === 'SALE' ? 100+'%' : 40+'%';
        const goods_list = this.make_goods_list();
        const visibility_graph = this.state.rank_draw ? 'visible' : 'hidden';

        return (
            <>
                <section>
                    <div className="container-fluid bg-gr">
                        <div className="row">
                            {/* <div className="menu_area"></div> */}
                            <div className="contents_area">
                                <div className="content_area">
                                    <div className="row">
                                        <div className="col-12 content_title">
                                            <h1 className="font_family_normal" style={{margin: "auto 0"}}>키워드별 상품 랭킹</h1>
                                        </div>
                                    </div>
                                    <div className="content_header">
                                        <div className="date_wrap">
                                            <div className="date_input">
                                                <button type="button" onClick={this.prev_day} className="date_prev"></button>
                                                <div className="date">
                                                    {this.get_date_message()}
                                                </div>
                                                <button type="button" onClick={this.next_day} className="date_next"></button>
                                            </div>
                                        </div>
                                        <div className="content_tabs products_tabs">
                                            <ul style={{marginBottom: 0}}>
                                                <li className={is_rank}>
                                                    <a href={()=>false} onClick={this.onClickType} style={{cursor: 'pointer', opacity: opacity_rank}} data-type="RANK">쿠팡 랭킹순</a>
                                                </li>
                                                <li className={is_sale}>
                                                    <a href={()=>false} onClick={this.onClickType}  style={{cursor: 'pointer', opacity: opacity_sale}} data-type="SALE">판매량순</a>
                                                </li>
                                            </ul>
                                            <select onChange={this.onClickKeyword}>
                                                {this.get_keyword_list()}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="products_wrap">
                                        <div className="products_container">
                                            <ul className="products">
                                                { goods_list }
                                            </ul>
                                            <div class="conts" style={{
                                                    visibility: visibility_graph,
                                                    position: 'fixed',
                                                    left: 40 + '%',
                                                    top: 30 + '%',
                                                    width: 40 + '%',
                                                    backgroundColor: 'white',
                                                    borderRadius: 15 + 'px',

                                            }}>
                                                <div id="container" className="chart_title">
                                                    <p>랭킹</p>
                                                    <button type="button" onClick={this.graph_close}/>
                                                </div>
                                                <TrendChart category_type={this.state.goods_type} date={this.make_graph_date(this.state.date)} keyword_seq={this.state.keyword_seq} item_no={this.state.rank_item.item_no} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="go-top">
                    <button onClick={this.scrollToTop}></button>
                </div>
                {
                    this.state.show_save_title_content_dialog &&
                    <TitleContentDialogV2
                        title={"랭킹인사이트를 체험해볼까요?"}
                        content={"기능 체험하시도록 <strong>테스트 정보</strong>로<br/>동작하고 있습니다.<br/>실제 관심 상품 키워드로 분석을 원한다면<br/>키워드를 신청해주세요."}
                        button_list={this.state.title_content_dialog_button_list}
                        popup_close={() => this.setState({show_save_title_content_dialog: false})}
                    />
                }
            </>

        );
    }
}

export default TrendAll;

import React from 'react';
import axios from 'axios';
import http from '../modules/api';
import SignupForm_trend from '../SignupForm_trend';
import SignupForm_cafe24 from '../SignupForm_cafe24';
import { isThursday } from 'date-fns';
import $ from "jquery"

export class MyInfo extends React.Component {

	state = {
		user_join_cls: '',
		user_id: '',
		token: '',
		corp_reg_no : '',
		emp_cnt : '',
		tel_no : '',
	} 
	
	handleText = (e, key) => {
	    // computed property
		this.setState({[key]: e.target.value});
	}
	
	componentDidMount() {
	    this.getMyInfo();
	}
	
	//개인정보조회
	async getMyInfo() {
		const res = await http.get(`/rest/shop/myInfo`);
		if (res.data.data != null) {
			var corp_addr = res.data.data.corp_addr;
			
			//기본, 상세주소 자르기
			if (corp_addr != null || corp_addr != '') {
				res.data.data.corp_addr = corp_addr.split('|')[0];
				res.data.data.corp_addr2 = corp_addr.split('|')[1];
			}
			this.setState(res.data.data);
		}
	};
	
	//[Cafe24 API] 상점 정보 재동기화
//	onClickGetStoreInfoButton = () => {
//		
//		var mall_id = this.state.mall_id
//		// 팝업창 열기
//		var g_oWindow = window.open("/cafe24_sync_tmp_user.html?mall_id="+mall_id
//				,"카페24 데이터 동기화");
//		// 0.5초 마다 감지
//	    var g_oInterval = window.setInterval(function() {
//	        try {
//	            // 창이 꺼졌는지 판단
//	            if( g_oWindow == null || g_oWindow.closed ) {
//	                window.clearInterval(g_oInterval);
//	                g_oWindow = null;
//					//store_temp 조회
//	                this.getStore(mall_id);
//	            }
//	        } catch (e) {}
//	    }, 500);
		
//	    *************************************************
//		var settings = "width=400, height=300, left=100, top=50";
//		var target = "/cafe24_sync_tmp_user.html?mall_id="+"sugarstyle";	//this.state.mall_id;
//		var popup = window.open('about:blank', 'popup_name', settings);
//		
//		popup.location = target;		
//	};
	
	//store_temp 조회
//	async getStore(mall_id) {
//        const res = await http.get("/rest/common/userTmp", {params:{mall_id:mall_id}});
//        this.setState(res.data);
//    }

	//store 조회
	getStore = (data) => {
		this.setState(data);
	}

	//숫자만 입력
	number_keydown = e => {
        if (e.keyCode === 69 || e.keyCode === 107 || e.keyCode === 109 || e.keyCode === 189) {
            /* e, -, + 막기 */
            e.preventDefault(); 
        } 
    }
	
	//인증토큰 갱신
	// onClickRefTokenButton = () => {
	// 	http.put("/rest/shop/userRefToken", {user_id:this.state.user_id})
	// 		 .then(
	// 				 resultData => {
	// 					this.setState({token: resultData.data.token}); 
	// 					alert("토큰 갱신을 완료하였습니다.");
	// 				 })
	// 		 .catch(
	// 				 error => { console.log("토큰 갱신 실패."); }
	// 		 );
	// }

	//수정완료
	onClickSignupButton = () => {

		const sendForm = {...this.state};
		//1. Validation Check
		// if (sendForm.corp_reg_no == null || sendForm.corp_reg_no == "") {
		// 	alert("사업자등록번호를 입력해 주세요.");
		// 	return false;
		// }
		// if (sendForm.emp_cnt == null || sendForm.emp_cnt == "") {
		// 	alert("재직자 수를 입력해 주세요.");
		// 	this.setState({emp_cnt : ''});
		// 	return false;
		// }
		// if (sendForm.corp_name == null || sendForm.corp_name == "") {
		// 	alert("회사 이름을 입력해 주세요.");
		// 	return false;
		// }
		if (sendForm.user_name == null || sendForm.user_name == "") {
			alert("담당자 이름을 입력해 주세요.");
			return false;
		}
		if (sendForm.tel_no == null || sendForm.tel_no == "") {
			alert("연락처를 입력해 주세요.\n(비밀번호 분실시 카카오 알림톡을 수신 가능한 연락처)");
			return false;
		}
		if (sendForm.email == null || sendForm.email == "") {
			alert("이메일 주소를 입력해 주세요.");
			return false;
		}
		// if (sendForm.hosting_type == null || sendForm.hosting_type == "") {
		// 	alert("호스팅을 입력해 주세요.");
		// 	return false;
		// }
		
		//2. 수정
		const config = {
            headers: {
                'content-type': 'application/json;'
            },
        }
		http.post("/rest/shop/myInfoModify", sendForm, config)
			 .then(
					 resultData => {
						alert(resultData.data.msg);
					 })
			 .catch(
					 //error => { console.log("사용자 저장 오류."); }
			 );
	};
	
	render() {
		$(".pageName").text("내 정보 관리");
		return (
			<div id='page-wrapper' className="container user_list_container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
				<div className="form_primary_title pc_page_title">내 정보 관리</div>
				<div className="mobile_top_margin"></div>
				<div className="form-group m-b-lg">
					<label>아이디</label><small className="text-navy"></small>
					<div className="input-group">
						<p>{this.state.user_id}</p>
					</div>
				</div>
				<div className="form-group m-b-lg">
					<label>인증토큰</label><small className="text-navy"></small>
					<input type="text" id="tel_no" className="form-control"
                        value={this.state.token} 
                        required disabled />
					{/* <span className="input-group-btn">
                        <button className="btn btn-primary" onClick={this.onClickRefTokenButton}>토큰갱신</button>
                    </span> */}
				</div>
				{/* <div className="form-group m-b-lg">
					<label>가입 구분</label><small className="text-navy"></small>
					<input type="text" id="tel_no" className="form-control"
                        value={
								{
									trend: "트렌드 리포트",
									cafe24: "카페24",
								}[this.state.user_join_cls]
						} 
                        required disabled />
				</div> */}
				<div className="form-group m-b-md">
					<label>담당자 이름<small className="text-navy"> *</small></label>
					<div className="input-group">
						<input type="text" id="user_name" className="form-control" placeholder=""
							value={this.state.user_name} 
							onChange={(e) => this.handleText(e, 'user_name')} 
							required maxLength="10" />
					</div>
					{/* <h5 style={{color:'red'}}>* 비밀번호 분실시 카카오 알림톡을 수신 가능한 연락처</h5> */}
				</div>
				<div className="form-group m-b-md">
					<label>연락처<small className="text-navy"> *</small></label>
					<div className="input-group">
						<input type="text" id="tel_no" className="form-control" placeholder="***-****-****"
							value={this.state.tel_no} 
							onChange={(e) => this.handleText(e, 'tel_no')} 
							required maxLength="13" />
					</div>
					{/* <h5 style={{color:'red'}}>* 비밀번호 분실시 카카오 알림톡을 수신 가능한 연락처</h5> */}
				</div>
				<div className="form-group m-b-md">
					<label>이메일<small className="text-navy"> *</small></label>
					<div className="input-group">
						<input type="text" id="email" className="form-control" placeholder="***@***.com"
							value={this.state.email} 
							onChange={(e) => this.handleText(e, 'email')} 
							required maxLength="255" />
					</div>
					{/* <h5 style={{color:'red'}}>* 비밀번호 분실시 카카오 알림톡을 수신 가능한 연락처</h5> */}
				</div>
				<div className="form-group m-b-md">
					<label>호스팅</label>
					<div className="input-group">
						<input type="text" id="hosting_type" className="form-control" placeholder=""
							value={this.state.hosting_type} 
							onChange={(e) => this.handleText(e, 'hosting_type')} 
							required maxLength="200" />
					</div>
					{/* <h5 style={{color:'red'}}>* 비밀번호 분실시 카카오 알림톡을 수신 가능한 연락처</h5> */}
				</div>
				<div className="form-group m-b-md">
					<label>회사 이름</label>
					<div className="input-group">
						<input type="text" id="corp_name" className="form-control"
							value={this.state.corp_name} 
							onChange={(e) => this.handleText(e, 'corp_name')} 
							required maxLength="50" />
					</div>
				</div>
				<div className="form-group m-b-md">
					<label>사업자등록번호</label>
					<div className="input-group">
						{/* <input type="text" id="corp_reg_no" className="form-control" placeholder="***-**-*****"
							value={this.state.corp_reg_no} 
							onChange={(e) => this.handleText(e, 'corp_reg_no')} 
							required maxLength="12" /> */}
						{this.state.corp_reg_no} 	
					</div>
				</div>
				{/* <div className="form-group m-b-md">
					<label>재직자 수</label><small className="text-navy"> *</small>
					<div className="input-group">
						<input type="number" id="emp_cnt" className="form-control"
							value={this.state.emp_cnt} 
							onKeyDown={this.number_keydown}
							onChange={(e) => this.handleText(e, 'emp_cnt')} 
							required />
					</div>
				</div> */}
				{/* ******************************************트렌드 리포트 가입 신청서****************************************** */}
				<SignupForm_trend user_join_cls={this.state.user_join_cls} 
								  userState={this.state}
								  handleText={this.handleText} />
				{/* ******************************************연동 플랫폼별 회원가입 폼****************************************** */}
				{/* ******************************************Cafe24 업체 연동 모듈****************************************** */}
				{/* <SignupForm_cafe24 user_join_cls={this.state.user_join_cls} 
								   token={this.state.token} 
								   storeState={this.state}
								   handleText={this.handleText}
								   getStore={this.getStore}/> */}
				{/* ****************************************** 가입신청완료****************************************** */}
				<div style={{textAlign:"center"}}>
					<button className="btn-auto-b" style={{height:"50px", borderRadius:"10px"}} onClick={this.onClickSignupButton}>저장</button>
				</div>
			</div>
		);
	}
	
}

import React from 'react';

class PopupFrameV2 extends React.Component {
    constructor(props) {
        super(props);
        // this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    // // 컨텐츠 영역 외 클릭 시 팝업창 닫기
    // handleContentOutsideClick = (e) => {
    //     console.log(e.target.className)
    //     if (typeof e.target.className == "string" && e.target.className.includes('common_popup_frame_v2_wrap')) {
    //         this.props.onClose();
    //     }
    // }

    // Esc키 눌렀을 때 팝업창 닫기
    // handleKeyDown(e) {
    //     if (e.key === "Escape") { this.props.onClose(); }
    // }

    // componentDidMount () {
    //     // 컴포넌트가 mount 됐을 때 Esc 이벤트 실행
    //     window.addEventListener('keydown', this.handleKeyDown);
    // }

    // componentWillUnmount () {
    //     // 컴포넌트가 unmount 됐을 때 Esc 이벤트 제거
    //     window.removeEventListener('keydown', this.handleKeyDown);
    // }

    render() {
        const {className, children, onClose, width, maxWidth, padding} = this.props;
        return (
            <div className={[className, "common_popup_frame_v2_wrap"].join(' ')}>
                <div className={"common_popup_frame_v2_frame"}>
                    <div className={"common_popup_frame_v2_content"} style={{width : width, maxWidth: maxWidth, padding: padding}}>
                        <svg className={"common_popup_frame_v2_close_btn"} onClick={onClose} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.2847 18.2154C19.4255 18.3575 19.5045 18.5496 19.5045 18.7497C19.5045 18.9499 19.4255 19.1419 19.2847 19.2841C19.1413 19.4227 18.9497 19.5002 18.7503 19.5002C18.5509 19.5002 18.3593 19.4227 18.2159 19.2841L12.0003 13.0591L5.78469 19.2841C5.64133 19.4227 5.44972 19.5002 5.25031 19.5002C5.0509 19.5002 4.85929 19.4227 4.71594 19.2841C4.5751 19.1419 4.49609 18.9499 4.49609 18.7497C4.49609 18.5496 4.5751 18.3575 4.71594 18.2154L10.9409 11.9997L4.71594 5.7841C4.59634 5.63838 4.53522 5.45338 4.54447 5.26509C4.55372 5.0768 4.63267 4.89869 4.76597 4.76539C4.89927 4.63208 5.07739 4.55313 5.26568 4.54388C5.45397 4.53464 5.63897 4.59575 5.78469 4.71535L12.0003 10.9404L18.2159 4.71535C18.3617 4.59575 18.5467 4.53464 18.7349 4.54388C18.9232 4.55313 19.1014 4.63208 19.2347 4.76539C19.368 4.89869 19.4469 5.0768 19.4562 5.26509C19.4654 5.45338 19.4043 5.63838 19.2847 5.7841L13.0597 11.9997L19.2847 18.2154Z" fill="#333333"/></svg>
                        { children }
                    </div>
                </div>
            </div>
        );
    }
}

PopupFrameV2.defaultProps = {
    className: "",
    onClose : function(){alert("닫기")},
    width : "100%",
    maxWidth : "500px",
    padding : "36px 12px 12px 12px",
}

export default PopupFrameV2;
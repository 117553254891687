import React from 'react';
import axios from 'axios';
import http from '../modules/api';

export class Auth extends React.Component {

	constructor(props) {
		super(props);
	    console.log(this.props);
	    this.state = {
	    	sessionType: null
	    }
	}
	
	componentDidMount() {
	    this.checkUser();
	}
	
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.logged !== this.props.logged && !this.props.logged) {
	      window.location.href = "/login";
	    }
	}
	
	checkUser = () => {
		
		axios
			.get("/userSessionType")
			.then(
				resultData => {
					this.setState({sessionType: resultData.data});
					console.log("####### sessionType : " + this.state.sessionType);
				})
			.catch(
				error => { 
					console.log("Data가 없습니다.");
					if (!this.props.logged && !window.location.pathname.includes("login")) {
						window.location.href = "/login";
					}
				}
			);
		
//		history.push("/login");
		//window.location.href = "/login";
		
	};
	
	render() {
		return (
				<div />
		);
	}
	
}

import React, { StrictMode } from 'react';
import { BrowserRouter, Route, Switch, HashRouter } from "react-router-dom";
import { Auth } from "./modules/Auth";
import { Unsubscribe } from "./modules/Unsubscribe";
import { NoMatch } from "./modules";
import { getCookie } from "./modules/utils/CookieUtil";
import { Menu } from "./Menu_v2";
import { AdminHome } from "./admin/AdminHome";
import { ShopHome } from "./shop/ShopHome";
import { AdminUserList } from "./admin/AdminUserList";
import { AdminUserDetail } from "./admin/AdminUserDetail";
import { AdminUserListForAdditionalFunction } from "./admin/AdminUserListForAdditionalFunction";
import { MyInfo } from "./shop/MyInfo";
import { PwdMod } from "./shop/PwdMod";
import { NoticeDetail } from "./shop/NoticeDetail";
import { QnaDetail as QnaDetailShop } from "./shop/QnaDetail";
import { QnaDetail as QnaDetailAdmin } from "./admin/QnaDetail";
import { HashTgaManager } from "./admin/HashTgaManager";
import { Home } from "./Home";
import { Login } from "./Login_v2";
// import { Login_v3 } from "./login/Login_v3";
import { LoginParent } from "./LoginParent";
import RegistUser from "./RegistUser_v2";
// import { RegistUserFromCafe24MyApp } from "./RegistUserFromCafe24MyApp";
import { IdSearch } from "./IdSearch";
import { PwdSearch } from "./PwdSearch";
// import { PwdReset } from "./login/PwdReset";
import { KoInfo } from "./KoInfo";
import { KoContact } from './KoContact';
import { Main as MainShop } from "./shop/Main";
import { Main as MainAdmin } from "./admin/Main";
import { NoticeList as NoticeListAdmin } from "./admin/NoticeList";
import { NoticeList as NoticeListShop } from "./shop/NoticeList";
import { QnaList as QnaListAdmin } from "./admin/QnaList";
import { QnaList as QnaListShop } from "./shop/QnaList";
import { InfoReqList as InfoReqList } from "./admin/InfoReqList";
import { InfoReqDetail as InfoReqDetail } from "./admin/InfoReqDetail";
import { NoticeForm } from "./admin/NoticeForm";
import { QnaForm } from "./shop/QnaForm";
import { AutoEventState as AutoEventStateShop } from "./autoEvent/AutoEventState";
import { AutoEventState as AutoEventStateAdmin } from "./admin/AutoEventState";
import { EventSetting } from "./autoEvent/EventSetting";
import { EventSettingList } from "./autoEvent/EventSettingList";
import { EventSettingHist } from "./admin/EventSettingHist";
import { RpEventProducHist } from "./admin/RpEventProducHist";
import { RpEventBestProductList } from "./admin/RpEventBestProductList";
import { EventHist } from "./admin/EventHist";
import { EventList as EventListShop } from "./autoEvent/EventList";
import { EventList as EventListAdmin } from "./admin/EventList";
import { MembershipPaymentStandardYmList as MembershipPaymentStandardYmList } from "./admin/MembershipPaymentStandardYmList";
import { MembershipPaymentList as MembershipPaymentList } from "./admin/MembershipPaymentList";
import { OrderLogList as OrderLogList } from "./admin/OrderLogList";

import { EventForm } from "./autoEvent/EventForm";
import { EventForm_V2 } from "./autoEvent/EventForm_V2";
import { EventForm_Promotion_V2 } from "./autoEvent/EventForm_Promotion_V2";
import { Cafe24OperatorAccountManager } from "./shop/Cafe24OperatorAccountManager";
import { Cafe24OperatorAccountManagerDetail } from "./shop/Cafe24OperatorAccountManagerDetail";
// import {TrendCurrent} from "./shop/TrendCurrent";
import { TrendCurrent as TrendCurrentDemo } from "./shop/TrendCurrent2";
import { TrendCurrent as TrendCurrent } from "./shop/TrendCurrent3";
import { TrendCurrent3_style_filter } from "./shop/TrendCurrent3_style_filter";
import { TrendCurrent as TrendCurrentRev1 } from "./shop/TrendCurrent3_rev1";
import { TrendCurrent_keyword_detail as TrendCurrentRev1_keyword_detail } from "./shop/TrendCurrent3_rev1_keyword_detail";
import { TrendCurrentEng } from "./shop/TrendCurrentEng";
import { TrendCurrentMld } from "./shop/TrendCurrentMld";
import { TrendCurrentBrand } from "./shop/TrendCurrentBrand";
import { TrendCurrentFood } from "./shop/TrendCurrentFood";
import { FormViewer } from "./FormViewer";
import { SnsManager } from "./shop/SnsManager";
import { TrendCurrentTiny } from "./shop/TrendCurrentTiny";
import { InfluenceList } from "./influence/List";
import { OrderNew } from "./shop/OrderNew_V2";
import { Order } from "./shop/Order";
import { OrderList } from "./shop/OrderList";
import { OrderPaymentTypeModify } from "./shop/OrderPaymentTypeModify";
import { OrderAmountExpect } from "./shop/OrderAmountExpect_V2";
import { OrderFreePlan } from "./shop/OrderFreePlan";
import { TaxRequestList } from "./admin/TaxRequestList";
import { UserPaymentPanList } from "./admin/UserPaymentPanList";
import { ServiceShopAdd } from "./shop/ServiceShopAdd";
import { bankLinkProc } from "./shop/bankLinkProc";
import { NaverTrendList } from "./shop/NaverTrendList";

import { SolutionStoreAdd } from "./shop/SolutionStoreAdd";
import { ServiceShopAdd_V2 } from "./shop/ServiceShopAdd_V2";
import { ServiceShopList } from "./shop/ServiceShopList";

import TrendAll from './fb/TrendAll';
import Trendy from './fb/Trendy';
import LongBest from './fb/LongBest';
import Search from './fb/Search';
import Stat from './fb/Stat';

import CoupangTrendAll from './coupang/TrendAll';
import CoupangTrendy from './coupang/Trendy';
import CoupangLongBest from './coupang/LongBest';
import CoupangSearch from './coupang/Search';
import CoupangStat from './coupang/Stat';

import { RpEventSettingList } from "./rollingProduct/RpEventSettingList";
import { RpEventSetting } from "./rollingProduct/RpEventSetting";

import { RpEventList } from "./rollingProduct/RpEventList";
import { RpEventProductList } from "./rollingProduct/RpEventProductList";

import RpMain from './rollingProduct/RpMain';
import { RpFavoriteAlgorithm_v2 } from './rollingProduct/RpFavoriteAlgorithm_v2';
// import RpProductList_nonInstall from "./rollingProduct/RpProductList_nonInstall";
import RpProductList_nonInstall from "./rollingProduct/RpProductList_nonInstall_v5";
// import RpProductList_install from "./rollingProduct/RpProductList_install";
import RpProductList_install from "./rollingProduct/RpProductList_install_v5";
import { RpEventList_nonInstall } from './rollingProduct/RpEventList_nonInstall';
import { RpEventList_install } from "./rollingProduct/RpEventList_install";
import { RpRealTimeService } from './rollingProduct/RpRealTimeService';

import { KdealProductList } from "./kdeal/KdealProductList";
import { KdealProductListForUser } from "./kdeal/KdealProductListForUser";
import { KdealProductListForAdmin } from "./kdeal/KdealProductListForAdmin";
import { KdealProductListForAdmin_v3 } from "./kdeal/KdealProductListForAdmin_v3";
import { KdealProductListForUser_v3 } from "./kdeal/KdealProductListForUser_v3";
import { TestSosanggongin } from "./kdeal/TestSosanggongin";
import { KdealDemoSosanggongin } from "./kdeal/KdealDemoSosanggongin";
import { ShopKdealSosanggongin } from "./kdeal/ShopKdealSosanggongin";
import { Sosanggongin } from "./kdeal/Sosanggongin";
import { Sosanggongin2 } from "./kdeal/Sosanggongin2";
import { SosanggonginForAdmin_v3 } from "./kdeal/SosanggonginForAdmin_v3";
import { SosanggonginForUser_v3 } from "./kdeal/SosanggonginForUser_v3";

import { DemoCreateAd } from './shop/DemoCreateAd';

import { MenuPipeLine as MenuPipeLineShop } from "./shop/MenuPipeLine";

import { TestRpProductPopup } from "./curation/TestRpProductPopup";
import { TestFacebookShare } from "./shop/TestFacebookShare";
import { CurationGen } from "./curation/CurationGen";
import { CurationResultGen } from "./curation/CurationResultGen";
import { Curationlist } from "./curation/Curationlist";
import { CurationResultList } from "./curation/CurationResultList";
import { CurationStatus } from './curation/CurationStatus';
import { UserCurationtList } from "./curation/UserCurationtList";
import { UserCurationQuestion } from "./curation/UserCurationQuestion";
import UserCurationResult from './curation/UserCurationResult';

import { EmailVerify } from './emailLinkPage/EmailVerify';
import { EmailChangePassword } from './emailLinkPage/EmailChangePassword';	

import NukkiEditor from './modules/nukki/NukkiEditor';
import NukkiRestoreEditor from './modules/nukki/NukkiRestoreEditor';
import ModuleGuide from './modules/ModuleGuide';

import { LoginParent_v2 } from "./login/LoginParent_v2";
import { RegistUserParent } from './login/RegistUserParent';
import { TermsCndtnForm_v3 } from './login/TermsCndtnForm_v3';
import { RegistConfirm } from './login/RegistConfirm';
import { RoboImage } from './roboimage/RoboImage';
import { RoboImageResult } from './roboimage/RoboImageResult';
import RoboImageDesign from './roboimage/RoboImageDesign';
import { RoboImageDesignResult } from './roboimage/RoboImageDesignResult';

import TargetViewSetting from './targetView/TargetViewSetting';
import TargetViewList from './targetView/targetViewList';

//import {AutoEventState as AutoEventStateShop} from "./shop/AutoEventState";
import ReactGA from 'react-ga';
// ReactGA.event({
// 	category: 'Social',
// 	action: 'Rated an App',
// 	value: 3
//   });
import GA4React from "ga-4-react";
import ReactPixel from 'react-facebook-pixel';
import MetaTags from 'react-meta-tags';
import Favicon from 'react-favicon';
import logo_mobile from "../resources/img/favicon_v2.svg";
import RpProductListStatistics from './rollingProduct/RpProductListStatistics';

ReactGA.initialize('UA-78553461-8');
ReactGA.pageview(window.location.pathname + window.location.search);

export class Root extends React.Component {
	render() {
		const pathname =window.location.pathname;
		// 주소 뒤에 '/en'이 붙은 경우 번역되는 언어를 영어로 변경
		if (pathname.substring(pathname.length-3) == '/en') {
			this.props.i18n.changeLanguage('en');
		}

		return (
			<>
				<BrowserRouter forceRefresh={true} getUserConfirmation={(message, callback) => {
					// this is the default behavior
					const allowTransition = window.confirm(message);
					callback(allowTransition);
				}}>
					{/*네스티드 라우팅 영역*/}
					<MetaTags>
						<title>로보MD | iclave</title>
						<meta name="description" content="MD를 도와주는 똑똑한 인공지능, 로보MD" />
						<meta property="og:title" content="로보MD | iclave" />
						<meta property="og:description" content="MD를 도와주는 똑똑한 인공지능, 로보MD" />
						<meta property="og:image" content="https://s3.ap-northeast-2.amazonaws.com/otdeal.file/robomd_og_image_v2.jpg" />
						<meta name="naver-site-verification" content="a8f7e3eb075021c98abe5307abfafeda33d6062e" />
						<link rel="canonical" href="https://robomd.io/"></link>
						<link rel="shortcut icon" href="https://robomd.io/images/favicons/favicon_v2.ico" />
						<link rel="icon" href="https://robomd.io/images/favicons/favicon_v2.ico" />
						<link rel="apple-touch-icon" href="https://robomd.io/images/favicons/favicon_v2.ico" />
					</MetaTags>
					<Favicon url={logo_mobile} />
					<Menu></Menu>
					<div>
						<Switch>
							<Route exact path="/admin/userFind/:user_id" component={AdminUserDetail}></Route>
							<Route exact path="/admin/userFind" component={AdminUserList}></Route>
							<Route exact path="/admin/AdminUserListForAdditionalFunction" component={AdminUserListForAdditionalFunction}></Route>
							<Route exact path="/admin/notice/form/:notice_seq" component={NoticeForm}></Route>
							<Route exact path="/admin/notice/form" component={NoticeForm}></Route>
							<Route exact path="/admin/notice/list" component={NoticeListAdmin}></Route>
							<Route exact path="/admin/qna/detail/:qna_seq" component={QnaDetailAdmin}></Route>
							<Route exact path="/admin/qna/list" component={QnaListAdmin}></Route>
							<Route exact path="/admin/cafe24/event/list" component={EventListAdmin}></Route>
							<Route exact path="/admin/cafe24/event/form" component={EventForm_V2}></Route>
							<Route exact path="/admin/cafe24/promotion/form" component={EventForm_Promotion_V2}></Route>
							{/* <Route exact path="/admin/cafe24/event/form" component={EventForm}></Route> */}
							<Route exact path="/admin/cafe24/event/state" component={AutoEventStateAdmin}></Route>
							<Route exact path="/admin/hashtag/manager" component={HashTgaManager}></Route>
							<Route exact path="/admin/main" component={MainAdmin}></Route>
							<Route exact path="/admin" component={AdminHome}></Route>
							<Route exact path="/shop/notice/detail/:notice_seq" component={NoticeDetail}></Route>
							<Route exact path="/shop/notice/list" component={NoticeListShop}></Route>
							<Route exact path="/shop/qna/detail/:qna_seq" component={QnaDetailShop}></Route>
							<Route exact path="/shop/qna/list" component={QnaListShop}></Route>
							<Route exact path="/shop/qna/form/:qna_seq" component={QnaForm}></Route>
							<Route exact path="/shop/qna/form" component={QnaForm}></Route>
							<Route exact path="/admin/infoReq/list" component={InfoReqList}></Route>
							<Route exact path="/admin/infoReq/detail/:info_req_seq" component={InfoReqDetail}></Route>
							<Route exact path="/admin/membershipPaymentStandardYmList" component={MembershipPaymentStandardYmList}></Route>
							<Route exact path="/admin/membershipPaymentList" component={MembershipPaymentList}></Route>
							<Route exact path="/admin/orderLogList" component={OrderLogList}></Route>

							{/* 이벤트 설정 페이지 */}
							<Route exact path="/shop/cafe24/event/setting" component={MenuPipeLineShop}></Route>
							<Route exact path="/shop/cafe24/event/setting/popup" component={MenuPipeLineShop}></Route>
							<Route exact path="/shop/cafe24/event/setting/list" component={MenuPipeLineShop}></Route>
							<Route exact path="/admin/cafe24/event/eventHist" component={EventHist}></Route>
							<Route exact path="/admin/cafe24/event/eventSettingHist" component={EventSettingHist}></Route>
							<Route exact path="/admin/rp/event/bestProductHist" component={RpEventProducHist}></Route>
							<Route exact path="/admin/rp/event/bestProduct/list" component={RpEventBestProductList}></Route>
							{/* 이벤트 페이지 */}
							<Route exact path="/shop/cafe24/event/list" component={MenuPipeLineShop}></Route>
							<Route exact path="/shop/cafe24/event/form" component={MenuPipeLineShop}></Route>
							<Route exact path="/shop/cafe24/promotion/form" component={MenuPipeLineShop}></Route>
							{/* <Route exact path="/shop/cafe24/event/form" component={EventForm}></Route> */}

							<Route exact path="/shop/cafe24/event/state" component={AutoEventStateShop}></Route>
							<Route exact path="/shop/cafe24/operatorAccountManager" component={Cafe24OperatorAccountManager}></Route>
							<Route exact path="/shop/cafe24/operatorCreate" component={Cafe24OperatorAccountManagerDetail}></Route>
							<Route exact path="/shop/cafe24/operatorFind/:user_id" component={Cafe24OperatorAccountManagerDetail}></Route>
							{/* <Route exact path="/shop/trend/current" component={TrendCurrent}></Route> */}
							<Route exact path="/shop/trend/current" component={TrendCurrent3_style_filter}></Route>
							<Route exact path="/shop/snsManager" component={SnsManager}></Route>
							<Route exact path="/demo/mld/trend/current" component={TrendCurrentMld}></Route>
							<Route exact path="/demo/brand/trend/current" component={TrendCurrentBrand}></Route>
							<Route exact path="/demo/food/trend/current" component={TrendCurrentFood}></Route>
							<Route exact path="/demo/eng/trend/current" component={TrendCurrentEng}></Route>
							{/* <Route exact path="/demo/trend/current" component={TrendCurrentDemo}></Route> */}
							<Route exact path="/demo/trend/current" component={TrendCurrent}></Route>
							<Route exact path="/demo/trend/v2/current" component={TrendCurrentRev1}></Route>
							<Route exact path="/demo/trend/v2/current/detail" component={TrendCurrentRev1_keyword_detail}></Route>
							<Route exact path="/shop/trend/v2/current" component={TrendCurrentRev1}></Route>
							{/* <Route exact path="/shop/trend/current/detail" component={TrendCurrentRev1_keyword_detail}></Route> */}
							<Route exact path="/shop/trend/v2/current/detail" component={TrendCurrentRev1_keyword_detail}></Route>
							<Route exact path="/shop/myInfo" component={MyInfo}></Route>
							<Route exact path="/shop/pwdMod" component={PwdMod}></Route>
							<Route exact path="/shop/serviceShopAddV1" component={ServiceShopAdd}></Route>

							<Route exact path="/shop/solutionStoreAdd" component={SolutionStoreAdd}></Route>
							<Route exact path="/shop/serviceShopAdd" component={ServiceShopAdd_V2}></Route>
							<Route exact path="/shop/serviceShopList" component={ServiceShopList}></Route>

							<Route exact path="/shop/cafe24/main" component={MainShop}></Route>
							<Route exact path="/shop" component={ShopHome}></Route>
							<Route exact path="/" component={Home}></Route>
							{/*<Route path="/shop/event/state" component={AutoEventStateShop}></Route>*/}
							<Route exact path="/common/registUser" component={RegistUser}></Route>
							<Route exact path="/common/registUser/en" component={RegistUser}></Route>
							{/* <Route exact path="/common/registUserFromCafe24MyApp" component={RegistUserFromCafe24MyApp}></Route> */}
							<Route exact path="/common/id/search" component={LoginParent}></Route>
							<Route exact path="/common/pwd/search" component={LoginParent}></Route>
							{/* <Route exact path="/common/pwd/reset" component={PwdReset}></Route> */}
							<Route exact path="/common/formViewer/:form_type" component={FormViewer}></Route>
							<Route exact path="/common/formViewer/en/:form_type" component={FormViewer}></Route>
							<Route exact path="/login" component={LoginParent}></Route>
							{/* <Route exact path="/login2" component={Login_v3}></Route> */}
							<Route exact path="/ko" component={KoInfo}></Route>
							<Route exact path="/ko/contact" component={KoContact}></Route>
							<Route exact path="/shop/trend/tiny/current" component={TrendCurrentTiny}></Route>
							<Route exact path="/common/influence/list" component={InfluenceList}></Route>

							{/* 롤링상품 설정 페이지 */}
							<Route exact path="/shop/rp/event/cafe24List" component={
								getCookie("user_id") == "marketb"
									? RpEventList
									: RpEventList_nonInstall
							}></Route>
							<Route exact path="/shop/rp/event/product/cafe24List" component={
								getCookie("user_id") == "marketb"
									? RpEventProductList
									: RpProductList_nonInstall
							}></Route>
							<Route exact path="/shop/rp/event/product/cafe24List/16006" component={
								getCookie("user_id") == "marketb"
									? RpEventProductList
									: RpProductList_nonInstall
							}></Route>
							<Route exact path="/shop/rp/event/product/cafe24List/16009" component={
								getCookie("user_id") == "marketb"
									? RpEventProductList
									: RpProductList_nonInstall
							}></Route>
							<Route exact path="/shop/rp/event/product/statistics/main" component={
								RpProductListStatistics
							}></Route>
							<Route exact path="/shop/rp/event/product/statistics/category" component={
								RpProductListStatistics
							}></Route>
							<Route exact path="/shop/rp/event/product/statistics/cafe24" component={
								RpProductListStatistics
							}></Route>
							
							
							<Route exact path="/shop/rp/event/list" component={
								getCookie("user_id") == "marketb"
								? RpEventList
								: RpEventList_install
							}></Route>
							<Route exact path="/shop/rp/event/product/list" component={
								getCookie("user_id") == "marketb"
								? RpEventProductList
								: RpProductList_install
							}></Route>

							<Route exact path="/shop/rp/main" component={RpMain}></Route>
							
							<Route exact path="/shop/rp/favoriteAlgorithm" component={RpFavoriteAlgorithm_v2}></Route>
							
							<Route exact path="/shop/rp/realTimeService" component={RpRealTimeService}></Route>

							{/* <Route exact path="/shop/rp/event/product/list" component={RpEventSetting}></Route> */}
							{/* <Route exact path="/shop/rp/event/setting/list" component={RpEventSettingList}></Route>
							<Route exact path="/shop/rp/event/setting" component={RpEventSetting}></Route> */}
							{/* <Route exact path="/shop/rp/v2/event/setting/list" component={TestRpEventList}></Route> */}
							{/* <Route exact path="/shop/rp/v2/event/setting" component={TestProductList3}></Route> */}

							{/* 마켓비 버전 */}
							{/* <Route exact path="/shop/rp/event/list" component={RpEventList}></Route>
							<Route exact path="/shop/rp/event/product/list" component={RpEventProductList}></Route> */}

							{/* 소상공인 페이지 */}
							<Route exact path="/shop/kdeal/product/list" component={KdealProductList}></Route>
							<Route exact path="/kdeal/common/product/list" component={KdealProductListForUser}></Route>
							<Route exact path="/kdeal/admin/product/list" component={KdealProductListForAdmin}></Route>

							{/* 소상공인 상세페이지 시연 */}
							<Route exact path="/test/sosanggongin" component={TestSosanggongin}></Route>
							<Route exact path="/kdeal/demo/sosanggongin" component={KdealDemoSosanggongin}></Route>
							<Route exact path="/shop/kdeal/sosanggongin" component={ShopKdealSosanggongin}></Route>
							<Route exact path="/kdeal/admin/sosanggongin" component={Sosanggongin}></Route>
							<Route exact path="/kdeal/common/sosanggongin" component={Sosanggongin2}></Route>


							{/* 상세디자인 페이지 (링크업) */}
							{/* 어드민 */}
							<Route exact path="/productDetailDesign/:roleTypeName/admin/product/list" component={KdealProductListForAdmin_v3}></Route>
							<Route exact path="/productDetailDesign/:roleTypeName/admin/product/detail" component={SosanggonginForAdmin_v3}></Route>
							{/* 사용자 */}
							<Route exact path="/productDetailDesign/:roleTypeName/user/product/list" component={KdealProductListForUser_v3}></Route>
							<Route exact path="/productDetailDesign/:roleTypeName/user/product/detail" component={SosanggonginForUser_v3}></Route>
							{/* 회원가입 */}
							<Route exact path="/common/productDetailDesign/worldBeer/registUser" component={RegistUser}></Route>
							{/* 로그인 */}
							<Route exact path="/common/productDetailDesign/worldBeer/login" component={Login}></Route>
							
							
							{/* 결제 관련 페이지 */}
							<Route exact path="/shop/orderNew" component={
								OrderNew
								// sessionStorage.getItem("serviceType") == "01001"
								// ? OrderNew
								// : Home
							}></Route>
							<Route exact path="/shop/order" component={
								Order
								// sessionStorage.getItem("serviceType") == "01001"
								// ? Order
								// : Home
							}></Route>
							<Route exact path="/shop/order/list" component={
								OrderList
								// sessionStorage.getItem("serviceType") == "01001"
								// ? OrderList
								// : Home
							}></Route>
							<Route exact path="/shop/orderPaymentTypeModify" component={
								OrderPaymentTypeModify
								// sessionStorage.getItem("serviceType") == "01001"
								// ? OrderPaymentTypeModify
								// : Home
							}></Route>
							<Route exact path="/shop/orderAmountExpect" component={
								OrderAmountExpect
								// sessionStorage.getItem("serviceType") == "01001"
								// ? MenuPipeLineShop
								// : Home
							}></Route>
							<Route exact path="/shop/orderFreePlan" component={
								OrderFreePlan
								// sessionStorage.getItem("serviceType") == "01001"
								// ? OrderFreePlan
								// : Home
							}></Route>

							{/* 실시간 계좌이체 결제 완료 */}
							<Route exact path="/common/bankLinkProc" component={bankLinkProc}></Route>

							{/* 통합관리자에 만들어야 되는 페이지들 start */}
							<Route exact path="/admin/taxRequest/list" component={TaxRequestList}></Route>
							<Route exact path="/admin/userPaymentPan/list" component={UserPaymentPanList}></Route>
							{/* 통합관리자에 만들어야 되는 페이지들 end */}

							<Route exact path="/common/unsubscribe/:email" component={Unsubscribe}></Route>
							<Route exact path="/common/naver/list" component={NaverTrendList}></Route>

							{/* 트렌드인사이트 - 프롬비기닝 */}
							<Route exact path="/fb/trendall" component={TrendAll}></Route>
							<Route exact path="/fb/trendy" component={Trendy}></Route>
							<Route exact path="/fb/longbest" component={LongBest}></Route>
							<Route exact path="/fb/search" component={Search}></Route>
							<Route exact path="/fb/stat" component={Stat}></Route>

							{/* 트렌드인사이트 - 쿠팡 */}
							<Route exact path="/coupang/trendall" component={CoupangTrendAll}></Route>
							<Route exact path="/coupang/trendy" component={CoupangTrendy}></Route>
							<Route exact path="/coupang/longbest" component={CoupangLongBest}></Route>
							<Route exact path="/coupang/search" component={CoupangSearch}></Route>
							<Route exact path="/coupang/stat" component={CoupangStat}></Route>

							<Route exact path="/ad-trial" component={DemoCreateAd}></Route>

							{/* 큐레이션 관리자 페이지 */}
							<Route exact path="/shop/curation/info" component={CurationGen}></Route>
							<Route exact path="/shop/curation/result" component={CurationResultGen}></Route>
							<Route exact path="/shop/curation/info/list" component={Curationlist}></Route>
							<Route exact path="/shop/curation/result/list" component={CurationResultList}></Route>
							<Route exact path="/shop/curation/status" component={CurationStatus}></Route>
							{/* 큐레이션 사용자 페이지 */}
							<Route exact path="/common/curation/list/:service_uid" component={UserCurationtList}></Route>
							<Route exact path="/common/curation/question/:curation_seq" component={UserCurationQuestion}></Route>
							<Route exact path="/common/curation/result/:result_uid" component={UserCurationResult}></Route>
							<Route exact path="/common/curation/result/:service_uid/:result_uid/:channel_cd" component={UserCurationResult}></Route>

							{/* 이메일 링크 페이지 */}
							<Route exact path="/common/EmailVerify" component={EmailVerify}></Route>
							<Route exact path="/common/EmailChangePassword" component={EmailChangePassword}></Route>

							{/* 누끼 */}
							<Route exact path="/common/NukkiEditor" component={NukkiEditor}></Route>
							<Route exact path="/common/NukkiRestoreEditor" component={NukkiRestoreEditor}></Route>
							
							<Route exact path="/common/RegistUser_v3" component={RegistUserParent}></Route>
							<Route exact path="/common/Login_v3" component={LoginParent_v2}></Route>
							<Route exact path="/common/RegistConfirm" component={RegistConfirm}></Route>
							{/* <Route exact path="/common/TermsCndtnForm_v3" component={TermsCndtnForm_v3}></Route> */}

							{/* 로보 이미지 마케팅 페이지 */}
							<Route exact path="/common/promo_roboimage" component={RoboImage}></Route>
							<Route exact path="/common/promo_roboimage/result" component={RoboImageResult}></Route>
							<Route exact path="/common/promo_aibanner" component={RoboImageDesign}></Route>
							<Route exact path="/common/promo_aibanner/en" component={RoboImageDesign}></Route>
							<Route exact path="/common/promo_aibanner/result" component={RoboImageDesignResult}></Route>

							{/* 타겟뷰 */}
							<Route exact path="/shop/targetview/setting" component={TargetViewSetting}></Route>
							<Route exact path="/shop/targetview/list" component={TargetViewList}></Route>
							
							<Route exact path="/common/ModuleGuide" component={ModuleGuide}></Route>

							<Route component={NoMatch}></Route>
						</Switch>
					</div>
					{/*<Auth></Auth>*/}
				</BrowserRouter>
			</>
		);
	}

}
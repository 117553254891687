import { nodeName } from 'jquery';
import http from '../modules/api';
import axios from 'axios';
import React from 'react';
import '../../css/style.css';
import $ from 'jquery';
import * as Menu from '../Menu_v2';
import CommonPopup from '../modules/CommonPopup';
import BasicDialogV2 from "../modules/dialogs/BasicDialogV2";

import icn_refresh from '../../resources/img/icn_refresh.svg';
import icn_refresh_spin from '../../resources/img/icn_refresh_spin.svg';

var is_stroe_loaded = false;
// var is_shops_refresh = false;

/**
 * 사용자 서비스 - 상점추가 페이지
 */
export class ServiceShopList extends React.Component {
  state = {
    platform_shop_list: [] /*최종 사용자의 플랫폼별 상점 리스트*/,
    is_shops_refresh: false
  };

  //공통코드 조회
  async getCode(upper_code_cd, option) {
    const res = await http.get(
      '/rest/common/cafe24Code?upper_code_cd=' + upper_code_cd,
    );
    const { options } = this.state;
    this.setState({
      options: {
        ...options,
        [option]: res.data,
      },
    });
  }

  // 디폴트 배너 설정 추가
  // async insertAutoEventSettingsDefault(shopData) {
  //   const config = {
  //     headers: {
  //       'content-type': 'application/json;',
  //     },
  //   };
  //   await axios.post('/rest/shop/event/setting/default', shopData, config);
  //   // const res = await axios.post("/rest/shop/event/setting/default", shopData, config);
  //   // console.log("insertAutoEventSettingsDefault : " + res.data.message);
  // }

  /**
   * [베타버전] 연동된 mall이 존재하는지 체크
   *  * 멀티샵만 지원하되 mall은 추가할 수 없다.
   *
   *  1. store 조회 (user_seq)
   *  2. 조회된 데이터에서 solution_type_cd 필드 체크.
   *  3. selectShops 조회
   */
  //사용자의 연동된 상점(store) 조회
  // async selectStore() {
  //   is_stroe_loaded = true;
  //   // console.log("selectStore : [베타버전] 연동된 mall이 존재하는지 체크");
  //   const res = await http.get('/rest/common/selectStore');
  //   // 조회 성공시 다음 단계
  //   if (res.data != null && res.data.length > 0) {
  //     let mall_id = res.data[0].mall_id;
  //     let solution_type_cd = res.data[0].solution_type_cd;

  //     // console.log(mall_id + solution_type_cd);

  //     // 모든 샵이 추가 완료된 경우, 연동 현황 페이지로 이동

  //     this.selectShops(mall_id, solution_type_cd);
  //     this.setState({
  //       mall_id: mall_id,
  //       solution_type_cd: solution_type_cd,
  //     });
  //     // this.setState({
  //     // 	step: 2,
  //     // 	shops: res.data
  //     // })
  //     if (this.state.is_shops_refresh) {
  //       alert('쇼핑몰 목록을 새로 불러왔습니다.');
  //       this.state.is_shops_refresh = false;
  //     }
  //   } else {
  //     alert("연동된 쇼핑몰이 없습니다.\n쇼핑몰 연동 페이지로 이동합니다.");
  //     window.location.href = '/shop/solutionStoreAdd';   //'/shop/serviceShopAdd';

  //     //console.log("selectStore : 연동된 상점 정보가 없습니다. 1단계도 이동")
  //     //alert("연동된 상점 정보가 없습니다.\n해당 플랫폼의 상점 정보를 확인해 주세요.");
  //     // 1단계로 이동
  //     // this.setState({
  //     //   step: 1,
  //     // });
  //   }
  // }

  //mall_id에 해당하는 shops 조회
  // async selectShops(mall_id, solution_type_cd) {
  //   const res = await http.get('/rest/common/selectShops', {
  //     params: {
  //       mall_id: mall_id,
  //       solution_type_cd: solution_type_cd,
  //     },
  //   });

  //   // 가입신청 - Cafe24 API] 상점 동기화
  //   //this.nextStep();
  //   // console.log("selectShops : mall_id에 해당하는 shops 조회");
  //   // console.log(res.data);

  //   // 조회 성공시 다음 단계
  //   if (res.data != null && res.data.length > 0) {
  //     this.setState({
  //       //step: 3, //Number(this.state.step) + 1,
  //       shops: res.data,
  //     });
  //   } else {
  //     alert(
  //       '연동된 쇼핑몰 정보가 없습니다.\n해당 플랫폼의 쇼핑몰 정보를 확인해 주세요.',
  //     );
  //   }
  // }

  selectAddShop(is_refresh) {
    http
      .get('/rest/shop/v2/selectShops')
      .then((res) => {
        const { data } = res;
        if (data.code == 200) {
          if(data.platform_shop_cnt > 0) {
            this.setState({
              platform_shop_list: data.platform_shop_list,
              userActiveServiceCntMap: data.userActiveServiceCntMap,
            }, () => {
              if (is_refresh) {
                this.setState({
                  basic_dialog_show_state : true,
                  basic_dialog_content: "쇼핑몰 목록 새로고침을 완료 하였습니다.",
                  basic_dialog_button_list: [
                    {
                      title: "확인",
                      type: "1",
                      event_method: () => {
                        this.setState({ basic_dialog_show_state: false });
                      }
                    }
                  ],
                });
              }
            });
          } else {
            alert("연동된 쇼핑몰이 없습니다.\n쇼핑몰 연동 페이지로 이동합니다.");
            window.location.href = '/shop/solutionStoreAdd';   //'/shop/serviceShopAdd';
          }
        }
      })
      .catch((error) => {
        alert(error + '\n관리자에게 문의해 주세요. (1599-3518)');
      });
  }

  // 선택된 멀티샵 세션 전환 및 배너세팅 페이지 이동
  onClickShopSettingButton = (solution_type_cd, shop_no, service_type) => {
    var mall_id = this.state.mall_id;
    http.get(`/rest/common/changeServiceShop?service_type=` + service_type + `&shop_no=` + shop_no).then((res) => {
      var code = res.data.code;
      var msg = '';
      if (code == 200) {
        msg = '멀티샵 전환 성공!';
        console.log('#### 이동 : ' + mall_id, solution_type_cd, shop_no);
        if (service_type == "01001") {
          window.location.href = '/shop/cafe24/event/setting/list';
        } else if (service_type == "01002") {
          window.location.href = '/shop/rp/event/list';
        } else if (service_type == "01009") {
          window.location.href = '/shop/targetview/list';
        }
      } else {
        msg =
          '멀티샵 전환에 실패하였습니다.\n관리자에게 문의해 주세요. (1599-3518)';
      }
      //alert(msg);
    });
  };

  // 선택된 멀티샵의 서비스 활성화
  onClickShopActiveServiceButton = (solution_type_cd, shop_no, service_type) => {
    
    this.setState({
      common_popup1: true,
      common_popup1_content: "쇼핑몰 연동을 진행합니다.",
      common_popup1_button_list: [
        {
          title:"아니요",
          type:"0",
          event_method: () => {
            //팝업 닫기
            this.setState({ common_popup1: false })
          }
        },
        {
          title: "예",
          type: "1",
          event_method: () => {
            let shop = {
              shop_no: shop_no,
              shop_name: '',
              solution_type_cd: solution_type_cd,
            };
            let checkedShopList = [];
            checkedShopList.push(shop);
            let shopData = {
              //mall_id: mall_id,
              //solution_type_cd: solution_type_cd,
              service_type: service_type,
              checkedShopList: checkedShopList
            };

            this.shopActiveService(solution_type_cd, shop_no, service_type);
            // 목록 재조회
            setTimeout(() => this.selectAddShop(), 500);
            if (service_type == "01001") {
              // 01001: 배너 신청시 안내 팝업
              //Menu.call_onChangeIsAddShopButtonClick_for_other_component(true);
              // 디폴트 배너 세팅 추가
              this.insertAutoEventSettingsDefault(shopData);
              //자동배너 설정 이동
              //window.location.href = "/shop/cafe24/event/setting/list";
            } else if (service_type == "01002") {
              /**
               * 디폴트 진열 세팅 추가 
               * 2022.09.05 : 연동 요청 직후 기본 설정 저장 / 상품 생성 백엔드에서 연동 완료후 처리
               * 2022.06.27 : 연동 요청 직후 백엔드에서 연동 완료후 처리
               */
              this.insertRpEventSettingsDefault(shopData);
            }
            //팝업 닫기
            this.setState({ common_popup1: false })

            // 상품 목록 불러오기 상태값 변경
            const {platform_shop_list} = this.state;
            platform_shop_list.filter((platform) => platform.solution_type_cd == solution_type_cd).map(
              map => map.target_shops.filter((target_shop) => target_shop.shop_no == shop_no).map(
                map => map.sync_state = "1"
              )
            )
            // this.setState({
            //   platform_shop_list: platform_shop_list
            // })
          }
        },
      ]
    });

  };

  // 선택된 멀티샵의 서비스 활성화
  async shopActiveService(solution_type_cd, shop_no, service_type) {
    let shopData = {
      solution_type_cd: solution_type_cd,
      shop_no: shop_no,
      service_type: service_type
    }
    const config = {
      headers: {
        'content-type': 'application/json;',
      },
    };
    await axios.post('/rest/shop/activeService', shopData, config);
  }

  // 상점 새로고침
  async refreshShops() {
    if (this.state.is_shops_refresh) {
      alert('쇼핑몰 목록을 불러오는 중입니다.\n잠시만 기다려 주세요.');
      return false;
    }

    this.setState({
      is_shops_refresh: true
    });
    const res = await http.get('/rest/common/reflesh/shopList');

    // 새로고침 성공시 스토어 다시 조회
    if (res.data != null) {
      //this.selectStore();
      this.selectAddShop(true);
    }
    this.setState({
      is_shops_refresh: false
    });
  }

  // 선택된 플랫폼의 상품 데이터 새로 연동하기
  onClickRefreshDataSync = (solution_type_cd, shop_no) => {

    this.setState({
      common_popup1: true,
      common_popup1_content: "상품 목록을 새로 불러오시겠습니까?</br>" + 
                             "상품 수에 따라 짧게는 몇 분에서 길게는 몇 시간이 걸릴 수 있습니다.</br>" +
                             "그 동안 다른 기능들은 모두 정상적으로 이용하실 수 있습니다.",
      common_popup1_button_list: [
        {
          title:"아니요",
          type:"0",
          event_method: () => {
            //팝업 닫기
            this.setState({ common_popup1: false })
          }
        },
        {
          title: "네",
          type: "1",
          event_method: () => {
            this.refreshDataSyncConfirm(solution_type_cd, shop_no);
          }
        },
      ]
    });

  }

  // 3단계 : 선택된 플랫폼의 상품 데이터 새로 연동하기 (확인)
  refreshDataSyncConfirm = (solution_type_cd, shop_no) => {

    this.setState({
      common_popup1_content: "전체 상품 목록을 새로 불러옵니다.</br>완료 시 카카오톡으로 알림 메시지를 드립니다.",
      common_popup1_button_list: [
        {
          title:"아니요",
          type:"0",
          event_method: () => {
            //팝업 닫기
            this.setState({ common_popup1: false })
          }
        },
        {
          title: "네",
          type: "1",
          event_method: () => {
            const {platform_shop_list} = this.state;
            platform_shop_list.filter((platform) => platform.solution_type_cd == solution_type_cd).map(
              map => map.target_shops.filter((target_shop) => target_shop.shop_no == shop_no).map(
                map => map.sync_state = "1"
              )
            )
            // this.setState({
            //   platform_shop_list: platform_shop_list
            // })

            // console.log('[상품 불러오기 이벤트] ----------------------------------------------------------------------')
            // console.log(JSON.stringify(this.state))
            // console.log('----------------------------------------------------------------------')
            this.fn_refreshDataSync(solution_type_cd, shop_no);

            //팝업 닫기
            this.setState({ common_popup1: false })
          }
        },
      ]
    });

  }

  fn_refreshDataSync(solution_type_cd, shop_no) {
    // 연동 시작
    let shopData = {
      solution_type_cd: solution_type_cd,
      shop_no: shop_no,
    };

    const config = {
      headers: {
        'content-type': 'application/json;',
      },
    };
    axios.post('/rest/shop/refreshDataSyncShop', shopData, config).then((res) => {
      //console.log(res);
      let regex = /\/login/;
      let responseURL = res.request.responseURL.replace(":8092", ":3000");
      if (responseURL.match(regex) != null) {
    	alert("해당 리소스에 접근하기 위한 권한이 없습니다.");
        window.location.href = responseURL;  
      } else if (res.data != null && res.data.code != "200") {
    	  alert(res.data.msg);  
      }
    }).catch((error) => {
      //console.log(error.response);
      if (error.response.status != 504 && error.response.request.responseURL != null && error.response.request.responseURL != "") {
        alert("해당 리소스에 접근하기 위한 권한이 없습니다.");
        let responseURL = error.response.request.responseURL;
        responseURL = responseURL.replace(":8092", ":3000");
        window.location.href = responseURL;
      }
    });
  }

  // 디폴트 배너 설정 추가
  insertAutoEventSettingsDefault(shopData) {
    const config = {
      headers: {
        'content-type': 'application/json;',
      },
    };
    http.post('/rest/shop/event/setting/default', shopData, config)
    .then((res) => {
      const { data } = res;
      if (data.code == "200") {
        //자동배너 설정 이동
        //window.location.href = "/shop/cafe24/event/setting/list";
      } else {
        alert(
          '[code : ' +
            data.code +
            ']' +
            '\n관리자에게 문의해 주세요. (1599-3518)',
        );
      }
    })
    .catch((error) => {
      alert(error + '\n관리자에게 문의해 주세요. (1599-3518)');
    });
    // const res = await axios.post("/rest/shop/event/setting/default", shopData, config);
    // console.log("insertAutoEventSettingsDefault : " + res.data.message);
  }

  // 디폴트 진열 설정 추가
  insertRpEventSettingsDefault(shopData) {
    const config = {
      headers: {
        'content-type': 'application/json;',
      },
    };
    http.post('/rest/shop/rp/setting/default', shopData, config)
    .then((res) => {
      const { data } = res;
      if (data.code == "200") {
        //3단계 화면 출력
        //this.setState({ step: 3 });
        //자동배너 설정 이동
        //window.location.href = '/shop/rp/event/list';
      } else {
        alert(
          '[code : ' +
            data.code +
            ']' +
            '\n관리자에게 문의해 주세요. (1599-3518)',
        );
      }
    })
    .catch((error) => {
      alert(error + '\n관리자에게 문의해 주세요. (1599-3518)');
    });
  }

  // componentDidUpdate() {
  //   var params = {};
  //   window.location.search.replace(
  //     /[?&]+([^=&]+)=([^&]*)/gi,
  //     function (str, key, value) {
  //       params[key] = value;
  //     },
  //   );
  //   if (this.state.step != '0') {
  //     // alert(params.finish_yn);
  //     if (params.finish_yn == 'y') {
  //       if (params.step == '3') {
  //         if (this.state.finish_yn == 'N') {
  //           //$('#page_title').text('쇼핑몰 목록');
  //           //this.setState({ step: 3 }); // 상점 목록
  //           //this.setState({ finish_yn: 'Y' });
  //           this.selectAddShop();
  //           is_stroe_loaded = false;
  //         }
  //       }
  //     } else {
  //       // alert(is_stroe_loaded);
  //       if (!is_stroe_loaded) {
  //         this.selectStore(); // 상점 추가
  //         //this.setState({ finish_yn: 'N' });
  //       }
  //     }
  //   }
  // }

  componentDidMount() {
    this.selectAddShop();
  }

  render() {
    //$('.pageName').text('쇼핑몰 목록');
    return (
      <>
        <div
          id="page-wrapper"
          className="container style_guide_v2"
          style={{ backgroundColor: '#ffffff', minHeight: '100%' }}
        >
          {/* <h3>[{window.location.href.split("/")[3].charAt(0).toUpperCase() + window.location.href.split("/")[3].slice(1) }] 상점 추가하기</h3> */}
          {/* <br />
          <br />

          <div id="page_title" className="form_primary_title pc_page_title">
            쇼핑몰 목록
          </div> */}
          <div className="m-t-43 m-l-29 shop_add_area">
            {/* 플랫폼별 연동 현황 리스트 출력 */}
            <div id="list_wrap">
              <section className="link_main">
                {this.state.platform_shop_list != null ? (
                  <>
                    {this.state.platform_shop_list.map((platform, idx) =>
                      platform.target_shops.length > 0 ? (
                        <>
                          <div className="link_main_row_1_wrap">
                            <div className="link_main_row_1_left">
                                {
                                  idx == 0 ?
                                  <h2 className="text_regular">쇼핑몰 목록</h2>
                                  :''
                                }
                                <h3 className="text_small">
                                    {platform.solution_type_name} 플랫폼의 쇼핑몰 정보를 확인해 주세요.
                                </h3>
                            </div>
                            <div className="link_main_row_1">
                                {
                                  platform.solution_type_cd == "09001" ?
                                    <>
                                      <button 
                                        className="link_refresh_btn hover_grey"
                                        onClick={() => this.refreshShops()}
                                      >
                                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.5125 1.73125V4.73125C12.5125 4.86386 12.4598 4.99104 12.366 5.08481C12.2723 5.17857 12.1451 5.23125 12.0125 5.23125H9.01248C8.87987 5.23125 8.75269 5.17857 8.65893 5.08481C8.56516 4.99104 8.51248 4.86386 8.51248 4.73125C8.51248 4.59864 8.56516 4.47147 8.65893 4.3777C8.75269 4.28393 8.87987 4.23125 9.01248 4.23125H10.8062L9.53748 2.9625C8.83799 2.2638 7.94707 1.78815 6.97732 1.59567C6.00756 1.40319 5.00252 1.50254 4.08921 1.88114C3.1759 2.25974 2.39534 2.9006 1.84618 3.72273C1.29702 4.54485 1.00391 5.51133 1.00391 6.5C1.00391 7.48867 1.29702 8.45515 1.84618 9.27728C2.39534 10.0994 3.1759 10.7403 4.08921 11.1189C5.00252 11.4975 6.00756 11.5968 6.97732 11.4043C7.94707 11.2119 8.83799 10.7362 9.53748 10.0375C9.58346 9.99045 9.63839 9.95306 9.69903 9.92753C9.75968 9.902 9.82481 9.88885 9.8906 9.88885C9.9564 9.88885 10.0215 9.902 10.0822 9.92753C10.1428 9.95306 10.1977 9.99045 10.2437 10.0375C10.3372 10.1312 10.3897 10.2582 10.3897 10.3906C10.3897 10.523 10.3372 10.65 10.2437 10.7438C9.40449 11.5826 8.33539 12.1537 7.17159 12.3849C6.00778 12.6162 4.80155 12.4972 3.70537 12.043C2.6092 11.5888 1.67231 10.8197 1.01316 9.8331C0.354012 8.84647 0.00219727 7.68656 0.00219727 6.5C0.00219727 5.31345 0.354012 4.15353 1.01316 3.16691C1.67231 2.18028 2.6092 1.41124 3.70537 0.957026C4.80155 0.502808 6.00778 0.383805 7.17159 0.615062C8.33539 0.846319 9.40449 1.41745 10.2437 2.25625L11.5125 3.525V1.73125C11.5125 1.59864 11.5652 1.47147 11.6589 1.3777C11.7527 1.28393 11.8799 1.23125 12.0125 1.23125C12.1451 1.23125 12.2723 1.28393 12.366 1.3777C12.4598 1.47147 12.5125 1.59864 12.5125 1.73125Z" fill="#333333"/>
                                        </svg>
                                        {
                                          this.state.is_shops_refresh
                                          ? "로딩중..."
                                          : "쇼핑몰 목록 새로고침"
                                        }
                                        
                                      </button>
                                    </>
                                  :''
                                }
                            </div>
                          </div>
                          <div className="link_main_table_wrap">
                            <table className="link_main_table" style={{width:"100%"}}>
                                <thead>
                                    <tr>
                                        <td className="link_td">쇼핑몰</td>
                                        <td className="link_td">주소</td>
                                        {
                                          this.state.userActiveServiceCntMap.active_service_cnt_01001 > 0 
                                          ?
                                            <td className="link_td">배너</td>
                                          : ''
                                        }
                                        {
                                          this.state.userActiveServiceCntMap.active_service_cnt_01002 > 0 
                                          ?
                                            <td className="link_td">진열</td>
                                          : ''
                                        }
                                        {
                                          this.state.userActiveServiceCntMap.active_service_cnt_01009 > 0 
                                          ?
                                            <td className="link_td">타겟뷰</td>
                                          : ''
                                        }
                                        <td className="link_td">데이터 동기화</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                      //(platform.target_shops != null && platform.target_shops.length > 0) ?
                                      platform.target_shops.map((shop, j) => (
                                        <tr key={j} id={shop.shop_no}>
                                          <td>{shop.shop_name}</td>
                                          <td>{shop.primary_domain}</td>
                                          {
                                            this.state.userActiveServiceCntMap.active_service_cnt_01001 > 0 
                                            ?
                                              <td>
                                                {
                                                  shop.added_type_01001 == 'Y' ?
                                                    <>
                                                      <h4 className="text_extrasmall">
                                                        {shop.added_date_01001} 연동
                                                      </h4>
                                                      <button className="link_main_table_linked_btn hover_mint" onClick={() => this.onClickShopSettingButton(platform.solution_type_cd, shop.shop_no, "01001")}>자동배너 설정 바로가기</button>
                                                    </>
                                                  : <button className="link_main_table_unlinked_btn hover_mint_b" onClick={() => this.onClickShopActiveServiceButton(platform.solution_type_cd, shop.shop_no, "01001")}>쇼핑몰 연동하기</button>
                                                }
                                              </td>
                                            : ''
                                          }
                                          {
                                            this.state.userActiveServiceCntMap.active_service_cnt_01002 > 0 
                                            ?
                                              <td>
                                                {
                                                  shop.added_type_01002 == 'Y' ?
                                                    <>
                                                      <h4 className="text_extrasmall">
                                                        {shop.added_date_01002} 연동
                                                      </h4>
                                                      <button className="link_main_table_linked_btn hover_mint" onClick={() => this.onClickShopSettingButton(platform.solution_type_cd, shop.shop_no, "01002")}>진열 설정 바로가기</button>
                                                    </>
                                                  : <button className="link_main_table_unlinked_btn hover_mint_b" onClick={() => this.onClickShopActiveServiceButton(platform.solution_type_cd, shop.shop_no, "01002")}>쇼핑몰 연동하기</button>
                                                }
                                              </td>
                                            : ''
                                          }
                                          {
                                            this.state.userActiveServiceCntMap.active_service_cnt_01009 > 0 
                                            ?
                                              <td>
                                                {
                                                  shop.added_type_01009 == 'Y' ?
                                                    <>
                                                      <h4 className="text_extrasmall">
                                                        {shop.added_date_01009} 연동
                                                      </h4>
                                                      <button className="link_main_table_linked_btn hover_mint" onClick={() => this.onClickShopSettingButton(platform.solution_type_cd, shop.shop_no, "01009")}>타겟뷰 바로가기</button>
                                                    </>
                                                  : <button className="link_main_table_unlinked_btn hover_mint_b" onClick={() => this.onClickShopActiveServiceButton(platform.solution_type_cd, shop.shop_no, "01009")}>쇼핑몰 연동하기</button>
                                                }
                                              </td>
                                            : ''
                                          }
                                          <td>
                                              {
                                                platform.solution_type_cd != "09900"  
                                                ? shop.active_shop_no != ""
                                                  ? 
                                                    shop.sync_state == "0"
                                                    ? <div onClick={() => this.onClickRefreshDataSync(platform.solution_type_cd, shop.shop_no)}>
                                                        <button>
                                                          {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M4.27344 7.03906C4.15637 6.92096 4.09101 6.76119 4.09174 6.59489C4.09248 6.4286 4.15924 6.26941 4.27734 6.15234C4.39545 6.03528 4.55522 5.96992 4.72151 5.97065C4.8878 5.97138 5.04699 6.03815 5.16406 6.15625L7.375 8.36719V1.125C7.375 0.95924 7.44085 0.800269 7.55806 0.683058C7.67527 0.565848 7.83424 0.5 8 0.5C8.16576 0.5 8.32473 0.565848 8.44194 0.683058C8.55915 0.800269 8.625 0.95924 8.625 1.125V8.36719L10.8359 6.15625C10.8939 6.09777 10.9628 6.05128 11.0388 6.01944C11.1147 5.98759 11.1961 5.97101 11.2785 5.97065C11.3608 5.97029 11.4424 5.98615 11.5186 6.01732C11.5949 6.0485 11.6642 6.09438 11.7227 6.15234C11.7811 6.21031 11.8276 6.27923 11.8595 6.35516C11.8913 6.43109 11.9079 6.51256 11.9083 6.59489C11.9086 6.67723 11.8928 6.75884 11.8616 6.83505C11.8304 6.91126 11.7845 6.98058 11.7266 7.03906L8.44531 10.3203C8.32585 10.4358 8.16618 10.5004 8 10.5004C7.83382 10.5004 7.67415 10.4358 7.55469 10.3203L4.27344 7.03906ZM14.875 9.25C14.7092 9.25 14.5503 9.31585 14.4331 9.43306C14.3158 9.55027 14.25 9.70924 14.25 9.875V14.25H1.75V9.875C1.75 9.70924 1.68415 9.55027 1.56694 9.43306C1.44973 9.31585 1.29076 9.25 1.125 9.25C0.95924 9.25 0.800269 9.31585 0.683058 9.43306C0.565848 9.55027 0.5 9.70924 0.5 9.875V14.25C0.5 14.5815 0.631696 14.8995 0.866116 15.1339C1.10054 15.3683 1.41848 15.5 1.75 15.5H14.25C14.5815 15.5 14.8995 15.3683 15.1339 15.1339C15.3683 14.8995 15.5 14.5815 15.5 14.25V9.875C15.5 9.70924 15.4342 9.55027 15.3169 9.43306C15.1997 9.31585 15.0408 9.25 14.875 9.25Z" fill="#333333"/>
                                                          </svg> */}
                                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M18.5316 17.4722C18.6715 17.6127 18.7501 17.803 18.7501 18.0014C18.7501 18.1998 18.6715 18.3901 18.5316 18.5306C18.4294 18.6309 16.0144 21 12 21C8.49469 21 5.95031 18.9 4.5 17.2641V19.5C4.5 19.6989 4.42098 19.8897 4.28033 20.0303C4.13968 20.171 3.94891 20.25 3.75 20.25C3.55109 20.25 3.36032 20.171 3.21967 20.0303C3.07902 19.8897 3 19.6989 3 19.5V15C3 14.8011 3.07902 14.6103 3.21967 14.4697C3.36032 14.329 3.55109 14.25 3.75 14.25H8.25C8.44891 14.25 8.63968 14.329 8.78033 14.4697C8.92098 14.6103 9 14.8011 9 15C9 15.1989 8.92098 15.3897 8.78033 15.5303C8.63968 15.671 8.44891 15.75 8.25 15.75H5.1975C6.3525 17.1891 8.71875 19.5 12 19.5C15.375 19.5 17.4506 17.4881 17.4713 17.4675C17.6125 17.3276 17.8035 17.2495 18.0024 17.2503C18.2012 17.2512 18.3915 17.331 18.5316 17.4722ZM20.25 3.75C20.0511 3.75 19.8603 3.82902 19.7197 3.96967C19.579 4.11032 19.5 4.30109 19.5 4.5V6.73594C18.0497 5.1 15.5053 3 12 3C7.98563 3 5.57063 5.36906 5.46938 5.46938C5.3284 5.60986 5.249 5.80059 5.24865 5.99961C5.24829 6.19863 5.32702 6.38965 5.4675 6.53063C5.60798 6.6716 5.79871 6.751 5.99774 6.75135C6.19676 6.75171 6.38777 6.67298 6.52875 6.5325C6.54938 6.51187 8.625 4.5 12 4.5C15.2812 4.5 17.6475 6.81094 18.8025 8.25H15.75C15.5511 8.25 15.3603 8.32902 15.2197 8.46967C15.079 8.61032 15 8.80109 15 9C15 9.19891 15.079 9.38968 15.2197 9.53033C15.3603 9.67098 15.5511 9.75 15.75 9.75H20.25C20.4489 9.75 20.6397 9.67098 20.7803 9.53033C20.921 9.38968 21 9.19891 21 9V4.5C21 4.30109 20.921 4.11032 20.7803 3.96967C20.6397 3.82902 20.4489 3.75 20.25 3.75Z" fill="#333333"/>
                                                          </svg>
                                                        </button>
                                                      </div>
                                                    : <div onClick={() => 
                                                        alert("상품 목록을 불러오는 중입니다. 완료될 때까지 기다려 주세요.\n"+
                                                              "상품 수에 따라 짧게는 몇 분에서 길게는 몇 시간이 걸릴 수 있습니다.\n"+
                                                              "완료 시 카카오톡으로 알림 메시지를 드립니다.")
                                                      }>
                                                        <button>
                                                          <div className="list_loading">
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                          </div>
                                                        </button>
                                                      </div>
                                                  : 
                                                  <button className="download_disabled">
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M4.27344 7.03906C4.15637 6.92096 4.09101 6.76119 4.09174 6.59489C4.09248 6.4286 4.15924 6.26941 4.27734 6.15234C4.39545 6.03528 4.55522 5.96992 4.72151 5.97065C4.8878 5.97138 5.04699 6.03815 5.16406 6.15625L7.375 8.36719V1.125C7.375 0.95924 7.44085 0.800269 7.55806 0.683058C7.67527 0.565848 7.83424 0.5 8 0.5C8.16576 0.5 8.32473 0.565848 8.44194 0.683058C8.55915 0.800269 8.625 0.95924 8.625 1.125V8.36719L10.8359 6.15625C10.8939 6.09777 10.9628 6.05128 11.0388 6.01944C11.1147 5.98759 11.1961 5.97101 11.2785 5.97065C11.3608 5.97029 11.4424 5.98615 11.5186 6.01732C11.5949 6.0485 11.6642 6.09438 11.7227 6.15234C11.7811 6.21031 11.8276 6.27923 11.8595 6.35516C11.8913 6.43109 11.9079 6.51256 11.9083 6.59489C11.9086 6.67723 11.8928 6.75884 11.8616 6.83505C11.8304 6.91126 11.7845 6.98058 11.7266 7.03906L8.44531 10.3203C8.32585 10.4358 8.16618 10.5004 8 10.5004C7.83382 10.5004 7.67415 10.4358 7.55469 10.3203L4.27344 7.03906ZM14.875 9.25C14.7092 9.25 14.5503 9.31585 14.4331 9.43306C14.3158 9.55027 14.25 9.70924 14.25 9.875V14.25H1.75V9.875C1.75 9.70924 1.68415 9.55027 1.56694 9.43306C1.44973 9.31585 1.29076 9.25 1.125 9.25C0.95924 9.25 0.800269 9.31585 0.683058 9.43306C0.565848 9.55027 0.5 9.70924 0.5 9.875V14.25C0.5 14.5815 0.631696 14.8995 0.866116 15.1339C1.10054 15.3683 1.41848 15.5 1.75 15.5H14.25C14.5815 15.5 14.8995 15.3683 15.1339 15.1339C15.3683 14.8995 15.5 14.5815 15.5 14.25V9.875C15.5 9.70924 15.4342 9.55027 15.3169 9.43306C15.1997 9.31585 15.0408 9.25 14.875 9.25Z" fill="#333333"/>
                                                    </svg>
                                                  </button>
                                                : ''  
                                              }
                                          </td>
                                          {/* <td style={{borderLeft:"0px", width:"250px"}}>무료체험중</td> */}{' '}
                                          {/*사용 요금제*/}
                                        </tr>
                                      ))
                                      //: <tr style={{height:"53px", borderBottom:"1.5px solid #DBDDE4", borderRight:"0px"}}><td colSpan="5" style={{borderLeft:"0px"}}>데이터가 없습니다.</td></tr>
                                    }
                                </tbody>
                            </table>
                          </div>
                          <div
                            key={idx}
                            id={platform.solution_type_cd}
                            style={{ marginTop: '80px', overflowX: 'auto' }}
                          >
                            <table className="m-t-32">
                              <tbody>
                                
                              </tbody>
                            </table>
                          </div>
                        </>
                      ) : (
                        ''
                      ),
                    )}
                  </>
                ) : (
                  ''
                )}
              </section>
            </div>
          </div>
        </div>
        {/* 진열여부 설정 팝업 
          1. popup_id : "common_popup1"		(div id 부여)
          2. width : "450px" 					(최소 450px)
          3. content : "팝업 내용<br/>예제 입니다."
          4. button_list : 
            [
              {
                title: "버튼명",			  // 버튼명
                type: "1",					// 버튼 (컬러)타입 : "1" (#10CFC9), "0" (#FFFFFF) 
                event_method: () => {
                  //이벤트 함수 구현
                  ...
                }
              },
            ]
          5. button_close : 닫기 함수 구현
        */}
        {
          (this.state.common_popup1 === true)
          ? <CommonPopup 
            popup_id={"common_popup1"}
            width={"490px"}
            content={this.state.common_popup1_content}
            button_list={this.state.common_popup1_button_list}
            popup_close={() => this.setState({common_popup1: false})}
          />
          : ''
        }
        {
					(this.state.basic_dialog_show_state === true)
						? <BasicDialogV2
							content={this.state.basic_dialog_content}
							button_list={this.state.basic_dialog_button_list}
						/>
						: ''
				}
      </>
    );
  }
}

import React from 'react';
import http from '../modules/api';
import {Route, Switch, NavLink, Link} from "react-router-dom";
import $ from 'jquery';
import queryString from 'query-string';

import "react-datepicker/dist/react-datepicker.css";

import ReactGA from 'react-ga';
import { isThursday } from 'date-fns';
ReactGA.initialize('UA-78553461-8');

var ga_label = sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")";

var calc_payment = false;
var fromExpect = false;
var pf = null;
var isPayLinkClick = false;

export class OrderNew extends React.Component {
    state = {
        step : '1',     // 1: 서비스 및 멤버십 이용 내역 , 2: 내 상점 방문자 수
        // tot_default_price : 0,
        // tot_banner_cnt : 0,
        service_type : '',
        request_type : '05001',    /*서비스 요청 상태 (05001 : 등록)*/
        payment_type : '',         
        req_payment_type_list : [], /*사용자가 선택한 요금제 유형*/
        req_coupon_list : [],       /*사용자가 입력한 쿠폰 번호*/
        order_type : '',           /*결제타입 (정기결제 / 실시간결제)  [02001	정기결제, 02002	실시간계좌이체]*/
        set_order_type : '',       /* 사용자가 선택한 결제타입*/
        payment_type_service_list: [],   /*멤버십 요금제 목록*/
        std_date : '',
        payment_amount : 0,
        requestMembershipPaymentList: [],
        //data_shopItem_list: [],            /*사용자가 세팅한 배너 목록*/
        //req_bannerList: [],             /*사용자가 요청한 배너 목록*/
        //data_requestMembershipTotalMap: '', 
        is_coupon_check : false,
        //data_requestMembershipList: [],     /*사용자가 선택한 배너별 요금목록*/

        card_map: {},
        popupType: "0",                  // 1: 카드등록

        coupon_no: "",                  // 쿠폰 번호

        payLinkPreData: {}
    };

    componentDidMount(e) {
        pf = document.payForm;
        // 외부 페이지에서 접근 여부를 체크
		// const { search } = this.props.location;
		// const queryObj = queryString.parse(search);		// 문자열의 쿼리스트링을 Object로 변환
		//console.log(queryObj);
        
        //const { service_type, iframe_page_id, iframe_html_id, order_new_yn } = queryObj;
        
        //service_type = (service_type == '' || service_type == 'null' || service_type == undefined) ? sessionStorage.getItem("serviceType") : service_type;
        // 사용자 등록 쿠폰 조회
        // this.fn_paymentTypeCoupon();

        // this.setState({
        //     service_type: service_type
        // }, () => {
        //     if ((iframe_page_id != "" && iframe_page_id !== undefined) && 
        //     (iframe_html_id != "" && iframe_html_id !== undefined) 
        //      // && (order_new_yn != "" && order_new_yn !== undefined)
        //     ) {
        //         //let banner_key = iframe_page_id+"-"+iframe_html_id;
        //         this.onClickReqBanner("on", banner_key);

                

        //         // if (order_new_yn == "N") {
        //         //     let banner_key = iframe_page_id+"-"+iframe_html_id;
        //         //     //alert(order_new_yn + "  " + banner_key)
        //         //     this.state.req_bannerList.push(banner_key);
        //         //     // 현재 사용중인 요금제 조회, 요금 계산
        //         //     this.fn_selectOrderShopPaymentType();
        //         // } else {
        //         //     /**
        //         //      * [배너 설정 목록] 화면을 통해서 선택한 배너로 접근시 요금제 선택 화면으로 이동
        //         //      */
        //         //     let banner_key = iframe_page_id+"-"+iframe_html_id;
        //         //     this.onClickReqBanner("on", banner_key);

        //         //     // 배너 목록 숨김
        //         //     //$(".credit_card_box_area_banner_type").hide();

        //         //     // state on
        //         //     // var key = "checkbox_state_"+idx;
        //         //     // this.setState({[key]: state});
        //         // }
        //     }
            
        // });
        // 현재 사용중인 요금제 조회, 요금 계산
        //this.fn_selectOrderShopPaymentType();
        // step1 : 멤버십 요금제 리스트
        this.fn_selectPaymentTypePlanList();
        // 사용자의 결제 수단 조회
        this.fn_selectOrderType();
        // 등록된 카드 조회
        this.loadCardKeygenProc();
    }

    componentDidUpdate() {

        if (calc_payment) {
            calc_payment = false;
            const { step } = this.state;
            //console.log(step)
            // step2 : 현재 날짜 기준으로 요금 계산 조회
            if (step == '2') {
                this.fn_requestMembership();
            }
        }

        // 외부(멤버십 관리 페이지로부터) 호출 받았을때 이전 버튼 삭제
        if (fromExpect) {
            fromExpect = false;
            $("#button_step_before").remove();
        }

        // PG창 호출 이벤트
        if(isPayLinkClick) {
            isPayLinkClick = false;

            console.log(pf);
            pf = document.payForm;
        
            //var fileName = "https://apitest.payjoa.co.kr/pay/link"; //개발서버
            var fileName = "https://api.payjoa.co.kr/pay/link"; //운영서버
            var PAYJOA = window.open("", "PAYJOA", "width=468,height=750");
            
            pf.target = "PAYJOA";
            pf.action = fileName;
            pf.method = "post";
            pf.submit();
        }

    }

    componentWillUnmount () {

    }

    // 이전 단계 클릭
    onChangeStepBefore () {
        let step = (Number(this.state.step) - 1)+"";
        if (step == '2') { 
            //calc_payment = true; 
        } else if (step == '-1') {
            alert("첫 번째 단계 입니다.");
            return false;
        }

        if (step == '1') {
            // 스크롤탑
            window.scrollTo(0, 0);
        }
        this.setState({
            step: step
        })
    }
 
    // 다음 단계 클릭
    onChangeStepNext () {
        let step = (Number(this.state.step) + 1)+"";
        if (step == '2') { 
            // 1. 배너 / 진열 서비스 멤버십 1개 이상 선택 여부를 확인
            if (this.state.req_payment_type_list == null || this.state.req_payment_type_list.length == 0) {
                alert("요금제를 선택해 주세요.");
                return false;
            }
            // 선택한 요금제로 조회
            calc_payment = true;

            // 다음단계 진행
            this.setState({ step: step });

            // 스크롤탑
            window.scrollTo(0, 0);

        } else if (step == '3') {
            // 1. 결제수단 체크
            let order_type = this.state.order_type;
            if (order_type == '02001') {
                // 사용자의 등록된 카드를 조회
                if (Object.keys(this.state.card_map).length === 0 && this.state.card_map.constructor === Object) {
                    // 없다면, 카드 등록 팝업 호출
                    this.setState({ popupType: "1" });
                } else {
                    // 등록된 카드가 있다면
                    var is_pay = window.confirm("등록된 카드를 이용하여 결제를 진행하시겠습니까?");
                    if (is_pay) {
                        // 결제를 위한 금액 데이터 세팅
                        this.fn_saveRequestMembership();
                    }
                }
            } else if (order_type == '02002') {
                // 실시간 계좌이체
                var is_pay = window.confirm("실시간 계좌이체로 결제를 진행하시겠습니까?\n(OTP 보안토큰과 공인인증서를 사용하여 진행)");
                if(is_pay) {
                    // 결제를 위한 금액 데이터 세팅
                    this.fn_saveRequestMembership();
                }
            } else {
                alert("결제 수단을 선택해 주세요.");
                return false;
            }
        } else if (step == '4') {
            alert("더이상 진행할 단계가 없습니다.");
            return false;
        }
    }

    /**
     * 해당 샵의 요금제 조회
     */
	// fn_selectOrderShopPaymentType() {
    //     let sendForm = {
    //         service_type: this.state.service_type,
    //     };
    //     const config = {
    //         headers: {
    //             'content-type': 'application/json;'
    //         }
    //     }
	// 	http.post("/rest/common/selectOrderShopPaymentType", sendForm, config)
    //         .then(
	// 		    resultData => {
    //                 const { data } = resultData;
    //                 if (data.data != null) {
    //                     calc_payment = true;
    //                     fromExpect = true;
    //                     this.setState({
    //                         step: '2',
    //                         payment_type: data.data.payment_type,
    //                     });
    //                 }
    //             }
    //         ).catch(
	// 		    error => { alert("서비스 점검중 입니다."); console.log(error); }
	// 		);
    // }
    
    // 결제를 위한 금액 데이터 세팅
    fn_saveRequestMembership() {
        let sendForm = {
            req_payment_type_list:  this.state.req_payment_type_list,
            req_coupon_list:  this.state.req_coupon_list,
            order_type : this.state.order_type,          /*결제타입 (정기결제 / 실시간결제)  [02001	정기결제, 02002	실시간계좌이체]*/
            //std_date: '2021-02-28',
            //std_ym: '2021-02',
            // service_type: this.state.service_type,
            // request_type: this.state.request_type,
            // payment_type: this.state.payment_type,
            // req_banner_list: this.state.req_bannerList,
            // coupon_no: this.state.coupon_no,
        };
        const config = {
            headers: {
                'content-type': 'application/json;'
            }
        }
        // 결제금액, 잔여금액, 추가금액을 계산하여 저장
		http.post("/rest/shop/saveRequestMembership", sendForm, config)
            .then(
			    resultData => {
                    const { data } = resultData;
                    console.log(data);
                    if (data.code == 200) {
                        //let payment_amount_seq = data.PAYMENT_AMOUNT_SEQ;
                        let order_type = this.state.order_type;
                        // 최종 요청 금액으로 결제모듈 호출
                        if (order_type == '02001') {
                            // 카드결제 
                            // this.fn_cardBatch(payment_amount_seq);
                            alert("결제가 완료 되었습니다.");
                            // 결제내역 페이지로 이동
                            this.props.history.push(
                                {
                                    pathname: `/shop/order/list`,
                                    state: {prevPath: window.location.href}
                                }	
                            )
                        } else if (order_type == '02002') {
                            // 실시간 계좌이체
                            if (data.result) {
                                // PG창 호출 이벤트
                                isPayLinkClick = true;
                                this.setState({payLinkPreData: data});
                            } else {
                                alert("결제에 실패하였습니다.\n관리자에게 문의해주세요(1599-3518)");
                            }
                            //this.fn_payLink(payment_amount_seq);
                        }
                    } else {
                        alert(data.msg);
                    }
                }
            ).catch(
			    error => { alert("서비스 점검중 입니다."); console.log(error); }
			);
    }

    // [카드결제] 최종 요청 금액으로 결제모듈 호출
    // fn_cardBatch(payment_amount_seq) {
    //     let sendForm = {
    //         payment_amount_seq: payment_amount_seq
    //     };
    //     const config = {
    //         headers: {
    //             'content-type': 'application/json;'
    //         }
    //     }
    //     // 결제금액, 잔여금액, 추가금액을 계산하여 저장
	// 	http.post("/rest/common/cardBatch", sendForm, config)
    //         .then(
	// 		    resultData => {
    //                 const { data } = resultData;
    //                 console.log(data);
    //                 if (data.result) {
    //                     alert("결제가 완료 되었습니다.");
    //                     // 결제내역 페이지로 이동
    //                     this.props.history.push(
    //                         {
    //                             pathname: `/shop/order/list`,
    //                             state: {prevPath: window.location.href}
    //                         }	
    //                     )
    //                 } else {
    //                     let msg = data.error_msg;
    //                     if (msg != null && msg != "") {
    //                         alert(msg);
    //                     } else {
    //                         alert("결제에 실패하였습니다.\n관리자에게 문의해주세요(1599-3518)");
    //                     }
    //                 }
    //             }
    //         ).catch(
	// 		    error => { alert("서비스 점검중 입니다."); console.log(error); }
	// 		);
    // }

    // [실시간 계좌이체] 최종 요청 금액으로 결제모듈 호출
    // fn_payLink(payment_amount_seq) {
    //         let sendForm = {
    //             payment_amount_seq: payment_amount_seq
    //         };
    //         const config = {
    //             headers: {
    //                 'content-type': 'application/json;'
    //             }
    //         }
    //         // 결제금액, 잔여금액, 추가금액을 계산하여 저장
    //         http.post("/rest/shop/get/payLinkPreData", sendForm, config)
    //         .then(
    //             resultData => {
    //                 const { data } = resultData;
    //                 console.log(data);
    //                 if (data.result) {
    //                     // PG창 호출 이벤트
    //                     isPayLinkClick = true;
    //                     this.setState({payLinkPreData: resultData.data});
    //                 } else {
    //                     alert("결제에 실패하였습니다.\n관리자에게 문의해주세요(1599-3518)");
    //                 }
    //             }
    //         ).catch(
    //             error => { alert("서비스 점검중 입니다."); console.log(error); }
    //         );
    // }

    // 요금제 선택 이벤트
	onClickPaymentType = (payment_type, service_type) => {
        let select_obj = payment_type + service_type;
        let req_payment_type_list = [...this.state.req_payment_type_list];
        let target = req_payment_type_list.indexOf(payment_type + service_type);
        if (target > -1) {
            req_payment_type_list.splice(req_payment_type_list.indexOf(select_obj), 1);
        } else {
            req_payment_type_list = req_payment_type_list.filter(item => !item.includes(service_type));
            req_payment_type_list.push(select_obj);
        }
        this.setState({
            req_payment_type_list : req_payment_type_list
        })
	}

    /**
     * 현재 설정된 결제 수단을 조회
     */
    fn_selectOrderType() {
        const config = {
            headers: {
                'content-type': 'application/json;'
            }
        }
		http.post("/rest/shop/get/orderType", null, config)
            .then(
			    resultData => {
                    const { data } = resultData;
                    console.log(data);
                    this.setState({
                        order_type: data.order_type,
                        set_order_type: data.order_type
                    });
                }
            ).catch(
			    error => { alert("서비스 점검중 입니다."); console.log(error); }
			);
	}

    /**
     * 쿠폰 유효성 체크
     */
    fn_checkCouponNo() {
        const config = {
            headers: {
                'content-type': 'application/json;'
            }
        }
		http.get("/rest/shop/selectCheckCouponNo?coupon_no="+this.state.coupon_no, null, config)
            .then(
			    resultData => {
                    const { data } = resultData;
                    if (data.code == "0000") {
                        if (data.coupon != null && data.coupon.is_active == "Y") {
                            // 현재 사용중인 요금제 조회, 요금 계산
                            this.fn_requestMembership();
                            this.setState({ is_coupon_check : true });
                        }
                    } else {
                        //alert(data.msg);
                        console.log(data.msg);
                        this.setState({ is_coupon_check : false });
                    }
                }
            ).catch(
			    error => { alert("서비스 점검중 입니다.\n관리자에게 문의해 주세요. (1599-3518)"); console.log(error); }
			);
	}

    /**
     * 사용자 등록 쿠폰 조회
     */
    fn_paymentTypeCoupon() {
        const config = {
            headers: {
                'content-type': 'application/json;'
            }
        }
		http.get("/rest/shop/paymentTypeCoupon", null, config)
            .then(
			    resultData => {
                    const { data } = resultData;
                    if (data.code == "0000") {
                        if (data.coupon != null) {
                            this.setState({
                                coupon_no: data.coupon.coupon_no,
                                is_use: data.coupon.is_use
                            });
                            this.fn_checkCouponNo();
                        }
                    } else {
                        alert(data.msg);
                    }
                }
            ).catch(
			    error => { alert("서비스 점검중 입니다.\n관리자에게 문의해 주세요. (1599-3518)"); console.log(error); }
			);
	}

    /**
     * step1 : 멤버십 요금제 리스트
     */
	async fn_selectPaymentTypePlanList() {
		const res = await http.get("/rest/shop/selectPaymentTypePlanList");
		// 조회 성공시 다음 단계
        if (res.data.payment_type_service_list != null && res.data.payment_type_service_list.length > 0) {
            let req_payment_type_list = res.data.payment_type_service_list;
            req_payment_type_list.find(item => 
                (item.user_service_membership_state != null && item.user_service_membership_state.request_type === "05001")   //신청 단계시 선택된 요금제가 있으면 결제요청
                ? this.onClickPaymentType(item.user_service_membership_state.payment_type, item.user_service_membership_state.service_type)
                : ''      
            )
            this.setState({
                payment_type_service_list: req_payment_type_list,
            })
        } 
        if (res.data.code != null) {
            if (res.data.code == "401") { 
                // 세션 만료시 로그인으로 이동
                let msg = res.data.msg;
                alert(msg);
                this.props.history.push(
                    {
                        pathname: `/login`,
                    }	
                )
            } else if (res.data.code != "200") {
                // 그 외 예외 메시지
                let msg = res.data.msg;
                alert(msg);
            }
        } else {
			alert("서비스 점검중 입니다.");
			//리다이렉트 경로
        }
    }

    /**
     * step2 : 현재 날짜 기준으로 요금 계산 조회
     */
    fn_requestMembership() {
		let sendForm = {
            req_payment_type_list: this.state.req_payment_type_list,
            req_coupon_list: this.state.req_coupon_list,
            request_type: this.state.request_type,
            //coupon_no: this.state.coupon_no,
        };
        const config = {
            headers: {
                'content-type': 'application/json;'
            }
        }
		http.post("/rest/shop/requestMembership", sendForm, config)
            .then(
			    resultData => {
                    const { data } = resultData;
                    //console.log(data);
                    if (data.code == 200) {
                        this.setState({
                            requestMembershipPaymentList: data.requestMembershipPaymentList,
                            std_date: data.std_date,
                            payment_amount: data.payment_amount,
                        });
                    } else {
                        alert(data.msg);
                        this.setState({
                            step: '1'
                        });
                        // this.props.history.push(
                        //     {
                        //         pathname: `/shop/orderNew`,
                        //         state: {prevPath: window.location.href}
                        //     }	
                        // )
                    }
                    //console.log(JSON.stringify(this.state))
                }
            ).catch(
			    error => { alert("서비스 점검중 입니다."); console.log(error); }
			);
	}

    // 결제수단 선택
    onClickOrderType = (e, order_type) => {
        if (this.state.set_order_type == null || this.state.set_order_type == '') {
            this.setState({order_type : order_type});
        } else if (order_type != this.state.set_order_type) {
            alert("설정된 결제수단과 다릅니다!");
            return false;
        }
    }

    // 카드등록 팝업 닫기
    closePopup = e => {
        this.setState({popupType: "0"})
    }

    // 등록된 카드 조회
    loadCardKeygenProc () {
        http.get("/rest/shop/get/cardKeygen")
        .then(res => {
            console.log(res)
            if (res.data != "" && res.data != null) {
                this.setState({card_map: res.data});
            }
        })
        .catch(error => {
            alert("서비스 점검중 입니다.");
            console.log(error);
        });
    }

    // 카드등록
    saveCardKeygenProc = e => {
		if (this.state.CARDNO == null || this.state.CARDNO == "") {
			alert("카드번호를 입력하여주세요.");
        } 
		else if (this.state.EXPIREDT == null || this.state.EXPIREDT == "") {
			alert("카드유효기간을 입력해주세요.");
        } 
		else if (this.state.CARDAUTH == null || this.state.CARDAUTH == "") {
			alert("카드 소유자 생년월일(6자) 또는 사업자번호를 입력해주세요.");
        } 
		else if (this.state.CARDAUTH == null || this.state.CARDAUTH == "") {
			alert("카드 비밀번호 앞 2자리를 입력해주세요.");
        } 
		else if (this.state.CARDPASSWORD == null || this.state.CARDPASSWORD == "") {
			alert("대표자명을 입력해주세요.");
        } 
		else if (this.state.CARDALIAS == null || this.state.CARDALIAS == "") {
			alert("카드 별명을 입력해주세요.");
        } 
        else {
            // 카드 저장
			this.insertCardKeygenProc();
		}
    }

    onChangeInputText = (e, name) => {
        if (name == "coupon_no") {
            let pre_input = this.state.coupon_no;
            this.setState({[name]:e.target.value}, () => {
                let pattern = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|]+$/;
                if(e.target.value.length > 0 && pattern.test(this.state.coupon_no) == false) {
                    this.setState({
                        coupon_no : pre_input
                    });
                    alert("알파벳, 숫자의 조합으로 입력해 주세요.");
                    return false;
                } else {
                    this.fn_checkCouponNo();
                }
            });
        } else {
            this.setState({[name]:e.target.value})
        }
    }

    // 카드 저장
    insertCardKeygenProc () {
        let data = {
            CARDNO: this.state.CARDNO,
            EXPIREDT: this.state.EXPIREDT,
            CARDAUTH: this.state.CARDAUTH,
            CARDPASSWORD: this.state.CARDPASSWORD,
            CARDALIAS: this.state.CARDALIAS
        }
        const config = {
            headers: {
                'content-type': 'application/json;'
            }
        }
        http.post("/rest/shop/cardKeygenProc", data, config)
        .then(res => {
            if (res.data.result == true) {
                // 등록된 카드 조회
                this.loadCardKeygenProc();
                alert("카드가 등록되었습니다.");
                var is_pay = window.confirm("등록된 카드를 이용하여 결제를 진행하시겠습니까?");
                if (is_pay) {
                    // 결제 유형 설정 (카드)
                    const formData = new FormData();
                    formData.append('order_type', '02001');
                    const url = '/rest/shop/set/orderType';
                    http.post(url, formData)
                    .then(res => {
                        if (res.data.result) {
                            // 결제를 위한 금액 데이터 세팅
                            this.setState({ order_type : '02001' }, ()=> this.fn_saveRequestMembership())
                        } else {
                            alert("결제수단 변경에 실패하였습니다.");
                        }
                    })
                    .catch(error => {
                        alert("서비스 점검중 입니다."); console.log(error);
                    });
                }
            } else {
                alert(res.data.errormessage);
            }
        })
        .catch(error => {
            alert("서비스 점검중 입니다."); console.log(error);
        });
    }

	render() {
        const { requestMembershipPaymentList } = this.state;
        $(".pageName").text("멤버십 관리");

        const input_bg_color = this.state.is_use == "Y" ? "#f7f7f7" : "white";

        return (
            <div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
                <div className="form_primary_title pc_page_title" style={{fontSize:"27px"}}>멤버십 관리</div>
                <div>
                    <div className="flexBox credit_card_box_area credit_card_box_area_header" style={{marginTop:"50px", padding: "37px 50px", border:"1px #EBEBED solid", borderRadius:"10px"}}>
                        {/* <div>{today.getFullYear()}년 {today.getMonth()+1}월 {today.getDate()}일 오늘 기준 고객님의 결제 예상 금액</div> */}
                        {
                            this.state.step == "1"
                            ?   <>
                                    <div className="icn_credit_card_text" style={{margin: "auto 0", fontSize:"18px", color: "#333333"}}><b>멤버십 선택</b></div>
                                    <div className="icn_arrow_v2_g_right" style={{margin: "auto 43px auto 43px"}}></div>
                                    <div className="icn_credit_card_text" style={{margin: "auto 0", fontSize:"18px", color:"#C2C2C2"}}><b>&nbsp;첫 달 이용요금 결제하기</b></div>
                                </>
                            : this.state.step == "2"
                              ? <>
                                    <div className="icn_credit_card_text" style={{margin: "auto 0", fontSize:"18px", color:"#C2C2C2"}}><b>멤버십 선택</b></div>
                                    <div className="icn_arrow_v2_g_right" style={{margin: "auto 43px auto 43px"}}></div>
                                    <div className="icn_credit_card_text" style={{margin: "auto 0", fontSize:"18px", color: "#333333"}}><b>&nbsp;첫 달 이용요금 결제하기</b></div>
                                </>
                              : ""
                        }
                    </div>
                    {
                        this.state.step == "1"
                        ?
                            <>
                                {
                                    (this.state.payment_type_service_list != null) 
                                    ?
                                    this.state.payment_type_service_list.map((service_data, service_idx) => (
                                        <div className="flexBox credit_card_box_area" style={{display:'flex', marginTop:"20px", padding: "37px 50px", border:"1px #EBEBED solid", borderRadius:"10px"}}>
                                            {/* 서비스 종류 */}
                                            <div className="icn_credit_card_text" style={{width:"100%", fontSize:"25px", fontWeight:"500"}}>&nbsp;{service_data.service_type_name}</div>
                                            <div className="flexBox" style={{width:"100%"}}>
                                            {
                                                service_data.plan_list.map((plan_data, plan_idx) => (
                                                    <div id={(plan_data.payment_type + service_data.service_type)}
                                                        className={"credit_card_box cursor-pointer " 
                                                                        + (service_data.user_service_membership_state != null 
                                                                            && service_data.user_service_membership_state.request_type === "05002"
                                                                            && service_data.user_service_membership_state.order_state === "03002"
                                                                            && service_data.user_service_membership_state.payment_type === plan_data.payment_type
                                                                        ? 'credit_card_box_disable' 
                                                                        : service_data.user_service_membership_state != null 
                                                                            && service_data.user_service_membership_state.request_type === "05002"
                                                                            && service_data.user_service_membership_state.order_state === "03002"
                                                                            // && service_data.user_service_membership_state.fix_use_yn === "Y"
                                                                        ? 'credit_card_box_disable_unselect'
                                                                        : (this.state.req_payment_type_list.indexOf(plan_data.payment_type + service_data.service_type) > -1 ? "" : "credit_card_box_unselect"))}
                                                        style={{
                                                            width: "calc((100% - 50px) / 6)",
                                                            height: "32vh",
                                                            border: "1px solid #10CFC9",
                                                            backgroundColor: "#10CFC9",
                                                            borderRadius: "13px",
                                                            marginTop: "15px",
                                                            marginLeft: "10px",
                                                            color: "white",
                                                            fontWeight: "bold",
                                                            alignContent:"center",
                                                        }}
                                                        onClick={(e) => 
                                                            service_data.user_service_membership_state != null 
                                                                && service_data.user_service_membership_state.request_type === "05002"
                                                                && service_data.user_service_membership_state.order_state === "03002"
                                                                // && service_data.user_service_membership_state.fix_use_yn === "Y"
                                                            ? alert('지정된 멤버십을 변경할 수 없습니다.')
                                                            : this.onClickPaymentType(plan_data.payment_type, service_data.service_type)
                                                        }
                                                    >
                                                        <div className="credit_card_box_first_area" style={{display:"flex", flexDirection:"column", marginTop: "-4.2vh"}}>
                                                            <div className="payment_type_name" style={{textAlign:"center", fontSize:"25px" , margin: "3.5vh auto auto auto", height:"30px" }}>
                                                                {plan_data.payment_type_name}
                                                            </div>
                                                            <div className="round_box" style={{
                                                                width: "65px",
                                                                padding: "6px 0px 5px",
                                                                textAlign:"center", 
                                                                margin: "4vh auto auto auto", 
                                                                fontSize: "12px", 
                                                                lineHeight:"16px",
                                                                border: "1px solid #FFFFFF",
                                                                borderRadius: "100px",
                                                                }}>
                                                                기본요금
                                                            </div>
                                                        </div>
                                                        <div className="credit_card_box_second_area" style={{display:"flex", flexDirection:"column"}}>
                                                            <div className="credit_card_box_second_inner_area" style={{display:"flex", flexDirection:"column"}}>
                                                                <div className="credit_card_box_second_area_txt" style={{display:"flex", justifyContent: "center", textAlign:"center", fontSize:"25px" , margin: "1vh auto auto auto", height:"30px" }}>
                                                                    <div className="credit_card_box_second_area_txt_price" style={{lineHeight:"30px"}}>
                                                                        {plan_data.payment_type_price_fm}
                                                                    </div>   
                                                                    <div className="credit_card_box_second_area_txt_won" style={{fontSize:"15px", lineHeight:"34px"}}>
                                                                    원
                                                                    </div>   
                                                                </div>   
                                                                <div className="credit_card_box_second_area_txt vat_area" style={{textAlign:"center", margin: "2vh auto auto auto", fontSize: "12px", lineHeight:"16px"}}>
                                                                    <div className="btn-auto-b credit_card_box_second_area_txt" 
                                                                    style={{
                                                                        width: "60px",
                                                                        height: "24px",
                                                                        lineHeight: "24px",
                                                                        background: "rgb(255 255 255 / 30%)",
                                                                        borderRadius: "5px",
                                                                        fontSize: "10px",
                                                                        padding: "0px",
                                                                        margin: "0 auto",
                                                                    }}
                                                                    // onClick={e => this.onClickOpenPopup(e, 1)}
                                                                    >
                                                                        VAT 별도
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div className="credit_card_box_second_area_txt over_charge_standard_fm_area"
                                                            style={{
                                                                textAlign:"center", 
                                                                fontSize:"12px" , 
                                                                margin: "3vh 5.8% auto 5.8%", 
                                                                // width:"147px",
                                                                // height:"36px", 
                                                                background:"white", 
                                                                borderRadius: "5px", 
                                                                color:"#333333",
                                                                lineHeight: "36px",
                                                                fontWeight: "bold",
                                                            }}>
                                                                {plan_data.payment_type_target}
                                                                {
                                                                //월 페이지뷰 {plan_data.over_charge_standard_fm}명 까지 
                                                                }
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            </div>
                                        </div>
                                    ))
                                    : ''
                                }
                            </>
                        :   
                            /*  step: 2 */
                            (requestMembershipPaymentList != null && requestMembershipPaymentList.length > 0) ?
                                <>
                                    <div className="flexBox credit_card_box_area" style={{width:"100%", marginTop:"39px", padding: "37px 50px", border:"1px #EBEBED solid", borderRadius:"10px", justifyContent:"space-between"}}>
                                        <div className="flexBox">
                                            <div className="icn_receipt" style={{margin: "auto 10px auto 0"}}></div>
                                            <div className="icn_credit_card_text" style={{flex: 1, margin: "auto 10px"}}><b>{this.state.std_date} 기준 고객님의 결제 예상 금액</b></div>
                                        </div>
                                        <div className="flexBox">
                                            <div className="icn_credit_card_text" style={{margin: "auto 0", fontSize:"20px", color: "#10CFC9", fontWeight: "bold"}}>{this.state.payment_amount}</div>
                                            <div className="icn_credit_card_text" style={{margin: "auto 0", fontSize:"15px", color: "#333333", fontWeight:"500", lineHeight:"15px"}}>&nbsp;원&nbsp;&nbsp;</div>
                                            <div className="icn_credit_card_text" style={{margin: "auto 0", fontSize:"12px", color: "#ADADAD", fontWeight:"500", lineHeight:"15px"}}>VAT 포함</div>
                                        </div>
                                    </div>
                                    {
                                        this.state.requestMembershipPaymentList.map((req_data, req_idx) => 
                                            <>
                                                <div className="credit_card_box_area credit_card_box_area_mobile" style={{marginTop:"20px", padding: "50px", padding: "37px 50px", border:"1px #EBEBED solid", borderRadius:"10px"}}>
                                                    <div className="credit_card_box_area_flex" style={{display:"flex"}}>
                                                        <div className="credit_card_box cursor-pointer" style={{width: "calc((100% - 50px) / 6)",
                                                            height: "32vh",
                                                            border: "1px solid #10CFC9",
                                                            backgroundColor: "#10CFC9",
                                                            borderRadius: "13px",
                                                            marginTop: "15px",
                                                            marginLeft: "10px",
                                                            color: "white",
                                                            fontWeight: "bold",
                                                            maxWidth:"200px",
                                                        }}>
                                                            <div className="credit_card_box_first_area" style={{display:"flex", flexDirection:"column"}}>
                                                                <div className="payment_type_name" style={{textAlign:"center", fontSize:"25px" , margin: "3.5vh auto auto auto", height:"30px" }}>
                                                                    {req_data.payment_type_name}
                                                                </div>
                                                                <div className="round_box" style={{
                                                                    width: "65px",
                                                                    padding: "6px 0px 5px",
                                                                    textAlign:"center", 
                                                                    margin: "4vh auto auto auto", 
                                                                    fontSize: "12px", 
                                                                    lineHeight:"16px",
                                                                    border: "1px solid #FFFFFF",
                                                                    borderRadius: "100px",
                                                                    }}>
                                                                    기본요금
                                                                </div>
                                                            </div>
                                                            <div className="credit_card_box_second_area" style={{display:"flex", flexDirection:"column"}}>
                                                                <div className="credit_card_box_second_inner_area" style={{display:"flex", flexDirection:"column"}}>
                                                                    <div className="credit_card_box_second_area_txt" style={{display:"flex", justifyContent: "center", textAlign:"center", fontSize:"25px" , margin: "1vh auto auto auto", height:"30px" }}>
                                                                        <div className="credit_card_box_second_area_txt_price" style={{lineHeight:"30px"}}>
                                                                            {req_data.payment_type_price_fm}
                                                                        </div>   
                                                                        <div className="credit_card_box_second_area_txt_won" style={{fontSize:"15px", lineHeight:"34px"}}>
                                                                            원
                                                                        </div>   
                                                                    </div>
                                                                    <div className="credit_card_box_second_area_txt vat_area" style={{textAlign:"center", margin: "2vh auto auto auto", fontSize: "12px", lineHeight:"16px"}}>
                                                                        <div className="btn-auto-b credit_card_box_second_area_txt"
                                                                            style={{
                                                                                    width: "60px",
                                                                                    height: "24px",
                                                                                    lineHeight: "24px",
                                                                                    background: "rgb(255 255 255 / 30%)",
                                                                                    borderRadius: "5px",
                                                                                    fontSize: "10px",
                                                                                    padding: "0px",
                                                                                    margin: "0 auto",
                                                                                }}
                                                                                // onClick={e => this.onClickOpenPopup(e, 1)}
                                                                                >
                                                                                VAT 별도
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="credit_card_box_second_area_txt over_charge_standard_fm_area"  
                                                                    style={{
                                                                        textAlign:"center", 
                                                                        fontSize:"12px" , 
                                                                        margin: "3vh 5.8% auto 5.8%", 
                                                                        // width:"147px",
                                                                        // height:"36px", 
                                                                        background:"white", 
                                                                        borderRadius: "5px", 
                                                                        color:"#333333",
                                                                        lineHeight: "36px",
                                                                        fontWeight: "bold",
                                                                }}>
                                                                    {req_data.payment_type_target}
                                                                    {/* 월 페이지뷰 {req_data.over_charge_standard}명 까지 */}
                                                                </div>
                                                            </div>
                                                            {/* <div className="credit_card_box_second_area" style={{display:"flex", flexDirection:"column"}}>
                                                                <div className="credit_card_box_second_area_txt over_charge_standard_fm_area coupon_area"
                                                                    style={{
                                                                        textAlign:"center", 
                                                                        fontSize:"12px" , 
                                                                        margin: "1vh 5.8% 1vh 5.8%", 
                                                                        // width:"147px",
                                                                        // height:"36px", 
                                                                        background:input_bg_color, 
                                                                        borderRadius: "5px", 
                                                                        color:"#333333",
                                                                        lineHeight: "36px",
                                                                        fontWeight: "bold",
                                                                }}>
                                                                    <input type="text" id="coupon_no"
                                                                        placeholder="쿠폰번호 입력"
                                                                        disabled={this.state.is_use == "Y" ? "disabled" : ""}
                                                                        maxLength={20}
                                                                        style={{width:"100%", height:"28px", textAlign:"center", background: input_bg_color, fontSize:"13px", border:"0px"}} onChange={e => this.onChangeInputText(e, "coupon_no")} value={this.state.coupon_no}></input>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                        <div className="credit_card_box_area2" style={{padding: "4.5vh 3vw 4.5vh 3vw", flex:"1", overflow:"auto"}}>
                                                            <div style={{width: "auto", whiteSpace: "nowrap"}}>
                                                                <div className="credit_card_box_area_flex" style={{display: "flex", width: "auto",whiteSpace: "nowrap"}}>
                                                                    <div className="mobile_width_100" style={{
                                                                        // width: "84px",
                                                                        fontWeight: "bold",
                                                                        fontSize: "12px",
                                                                        margin: "auto 0",
                                                                    }}>
                                                                        서비스명
                                                                    </div>
                                                                    <div className="credit_card_box_area_flex_inner" style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: "25px",
                                                                        margin: "auto 0 auto 68px",
                                                                    }}>{req_data.service_type_name}</div>
                                                                </div>  
                                                                <div className="credit_card_box_area_flex" style={{display: "flex", marginTop:"2vh"}}>
                                                                    <div className="mobile_width_100" style={{
                                                                        // width: "84px",
                                                                        fontWeight: "bold",
                                                                        fontSize: "12px",
                                                                        margin: "auto 0",
                                                                    }}>이번 달 이용요금</div>  
                                                                    <div className="credit_card_box_area_flex_inner" style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: "25px",
                                                                        margin: "auto 0 auto 30px",
                                                                    }}>{
                                                                        // this.state.is_coupon_check ? data_requestMembershipTotalMap.amount : data_requestMembershipTotalMap.pre_amount
                                                                        req_data.payment_amount_fm
                                                                    }</div>
                                                                    <div style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: "15px",
                                                                        margin: "auto 0 auto 5px",
                                                                    }}>원</div>
                                                                    <div
                                                                    style={{
                                                                        width: "60px",
                                                                        height: "24px",
                                                                        lineHeight: "24px",
                                                                        background: "#F5F5F5",
                                                                        borderRadius: "5px",
                                                                        fontSize: "10px",
                                                                        padding: "0px",
                                                                        margin: "auto 5px",
                                                                        textAlign: "center",
                                                                        color: "rgba(51, 51, 51, 0.2)",
                                                                        }}
                                                                        // onClick={e => this.onClickOpenPopup(e, 1)}
                                                                        >
                                                                        VAT 포함
                                                                    </div>  
                                                                </div>
                                                                <div className="credit_card_box_area_flex" style={{display: "flex", width: "auto",whiteSpace: "nowrap", marginTop:"2vh"}}>
                                                                    <div className="mobile_width_100" style={{
                                                                        // width: "45px",
                                                                        fontWeight: "bold",
                                                                        fontSize: "12px",
                                                                        margin: "auto 0",
                                                                    }}>이용기간
                                                                    </div>  
                                                                    <div className="mobile_width_100" style={{display: "flex", width: "auto",whiteSpace: "nowrap"}}>
                                                                        <div className="credit_card_box_area_flex_inner" style={{
                                                                            fontWeight: "bold",
                                                                            fontSize: "25px",
                                                                            margin: "auto 0 auto 69px",
                                                                        }}>{req_data.start_date_y}</div>
                                                                        <div style={{
                                                                            fontWeight: "bold",
                                                                            fontSize: "15px",
                                                                            margin: "auto 0 auto 5px",
                                                                        }}>년</div>  
                                                                        <div style={{
                                                                            fontWeight: "bold",
                                                                            fontSize: "25px",
                                                                            margin: "auto 0 auto 15px",
                                                                        }}>{req_data.start_date_m}</div>
                                                                        <div style={{
                                                                            fontWeight: "bold",
                                                                            fontSize: "15px",
                                                                            margin: "auto 0 auto 5px",
                                                                        }}>월</div>  
                                                                        <div style={{
                                                                            fontWeight: "bold",
                                                                            fontSize: "25px",
                                                                            margin: "auto 0 auto 15px",
                                                                        }}>{req_data.start_date_d}</div>
                                                                        <div style={{
                                                                            fontWeight: "bold",
                                                                            fontSize: "15px",
                                                                            margin: "auto 0 auto 5px",
                                                                        }}>일</div>  
                                                                        <div style={{
                                                                            fontWeight: "bold",
                                                                            fontSize: "15px",
                                                                            margin: "auto 20px auto 20px",
                                                                            color:"#C2C2C2",
                                                                        }}>~</div>  
                                                                    </div>
                                                                    <div className="mobile_width_100" style={{display: "flex", width: "auto",whiteSpace: "nowrap"}}>
                                                                        <div style={{
                                                                            fontWeight: "bold",
                                                                            fontSize: "25px",
                                                                            margin: "auto 0 auto 0",
                                                                        }}>{req_data.end_date_y}</div>
                                                                        <div style={{
                                                                            fontWeight: "bold",
                                                                            fontSize: "15px",
                                                                            margin: "auto 0 auto 5px",
                                                                        }}>년</div>  
                                                                        <div style={{
                                                                            fontWeight: "bold",
                                                                            fontSize: "25px",
                                                                            margin: "auto 0 auto 15px",
                                                                        }}>{req_data.end_date_m}</div>
                                                                        <div style={{
                                                                            fontWeight: "bold",
                                                                            fontSize: "15px",
                                                                            margin: "auto 0 auto 5px",
                                                                        }}>월</div>  
                                                                        <div style={{
                                                                            fontWeight: "bold",
                                                                            fontSize: "25px",
                                                                            margin: "auto 0 auto 15px",
                                                                        }}>{req_data.end_date_d}</div>
                                                                        <div style={{
                                                                            fontWeight: "bold",
                                                                            fontSize: "15px",
                                                                            margin: "auto 0 auto 5px",
                                                                        }}>일</div>  
                                                                    </div>  
                                                                </div>  

                                                                <div className="credit_card_box_area_flex" style={{display: "flex", width: "auto",whiteSpace: "nowrap", marginTop:"2vh"}}>
                                                                    <div className="mobile_width_100" style={{
                                                                        // width: "45px",
                                                                        fontWeight: "bold",
                                                                        fontSize: "12px",
                                                                        margin: "auto 0",
                                                                    }}>다음 결제일
                                                                    </div>  
                                                                    <div className="credit_card_box_area_flex_inner" style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: "25px",
                                                                        margin: "auto 0 auto 56px",
                                                                    }}>{req_data.end_date_y}</div>
                                                                    <div style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: "15px",
                                                                        margin: "auto 0 auto 5px",
                                                                    }}>년</div>  
                                                                    <div style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: "25px",
                                                                        margin: "auto 0 auto 15px",
                                                                    }}>{req_data.end_date_m}</div>
                                                                    <div style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: "15px",
                                                                        margin: "auto 0 auto 5px",
                                                                    }}>월</div>  
                                                                    <div style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: "25px",
                                                                        margin: "auto 0 auto 15px",
                                                                    }}>{req_data.end_date_d}</div>
                                                                    <div style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: "15px",
                                                                        margin: "auto 0 auto 5px",
                                                                    }}>일</div>  
                                                                </div>  
                                                                {/* <div className="credit_card_box_area_flex" style={{display: "flex", width: "auto",whiteSpace: "nowrap", marginTop:"3.1vh"}}>
                                                                    <div className="mobile_width_100" style={{
                                                                        // width: "45px",
                                                                        fontWeight: "bold",
                                                                        fontSize: "12px",
                                                                        margin: "auto 0",
                                                                    }}>유의사항
                                                                    </div>  
                                                                    <div className="credit_card_box_area_flex_inner" style={{
                                                                        fontWeight: "400",
                                                                        fontSize: "12px",
                                                                        margin: "auto 0 auto 69px",
                                                                    }}><b>이용기간</b> 동안의 페이지뷰가 {req_data.over_charge_standard}명을 넘는 경우, <b>다음 결제일</b>에 기본요금과 추가요금이 결제 됩니다.</div>
                                                                </div> */}
                                                            </div>  
                                                        </div>  
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                    <div>
                                        <div className="credit_card_box_area" style={{marginTop:"20px", padding: "37px 50px", border:"1px #EBEBED solid", borderRadius:"10px"}}>
                                            <div style={{display:"flex"}}>
                                                <div className="icn_round_blue" style={{width: "10px", height: "10px", margin: "auto 0", fontSize:"18px"}}></div>
                                                <div className="icn_credit_card_text" style={{margin: "auto 0 auto 15px", fontSize:"15px", fontWeight:"500", flex: "1"}}>&nbsp;로보MD는 결제 후 이용 가능합니다.</div>
                                            </div>
                                            <div style={{display:"flex", marginTop:"10px"}}>
                                                <div className="icn_round_blue" style={{width: "10px", height: "10px", margin: "auto 0", fontSize:"18px"}}></div>
                                                <div className="icn_credit_card_text" style={{margin: "auto 0 auto 15px", fontSize:"15px", fontWeight:"500", flex: "1"}}>&nbsp;결제일은 매달 말일입니다.</div>
                                            </div>
                                            {/* <div style={{display:"flex", marginTop:"10px"}}>
                                                <div className="icn_round_blue" style={{width: "10px", height: "10px", margin: "auto 0", fontSize:"18px"}}></div>
                                                <div className="icn_credit_card_text" style={{margin: "auto 0 auto 15px", fontSize:"15px", fontWeight:"500", flex: "1"}}>&nbsp;다음 달 기본 요금과 이번 달 총 페이지뷰 수가 멤버십 기준을 넘는 경우, 추가 요금이 부과 됩니다.</div>
                                            </div> */}
                                            <div style={{display:"flex", marginTop:"10px"}}>
                                                <div className="icn_round_blue" style={{width: "10px", height: "10px", margin: "auto 0", fontSize:"18px"}}></div>
                                                <div className="icn_credit_card_text" style={{margin: "auto 0 auto 15px", fontSize:"15px", fontWeight:"500", flex: "1"}}>&nbsp;결제수단이 카드는 자동 결제되며, 실시간계좌이체는 매달 말일 로보MD 웹사이트를 통해(실시간계좌이체) 해주셔야 합니다.</div>
                                            </div>
                                            <div style={{display:"flex", marginTop:"10px"}}>
                                                <div className="icn_round_blue" style={{width: "10px", height: "10px", margin: "auto 0", fontSize:"18px"}}></div>
                                                <div className="icn_credit_card_text" style={{margin: "auto 0 auto 15px", fontSize:"15px", fontWeight:"500", flex: "1"}}>&nbsp;실시간 계좌이체시 세금계산서는 요청일자에 발행해 드립니다.</div>
                                            </div>
                                            {/* <div style={{display:"flex", marginTop:"10px"}}>
                                                <div className="icn_round_blue" style={{width: "10px", height: "10px", margin: "auto 0", fontSize:"18px"}}></div>
                                                <div className="icn_credit_card_text" style={{margin: "auto 0 auto 15px", fontSize:"15px", fontWeight:"500", flex: "1"}}>&nbsp;멤버십 결제를 진행하시면 다른 상점의 무료 체험도 종료됩니다.</div>
                                            </div> */}
                                        </div>

                                        
                                        <div style={{display:"flex"}}>
                                            {
                                                this.state.order_type == "02001"
                                                ?
                                                <div className="redio_v2 m-l-34 m-t-32">
                                                    <div className="flexBox radio_area">
                                                        <input type="radio"></input>
                                                        <div className="radio on" onClick={
                                                            e => this.onClickOrderType(e, "02001")
                                                        }><div className="hover"></div></div><div className="radio_text">체크·신용카드</div>
                                                    </div> 
                                                </div>
                                                :
                                                <div className="redio_v2 m-l-34 m-t-32">
                                                    <div className="flexBox radio_area">
                                                        <input type="radio"></input>
                                                        <div className="radio" onClick={
                                                            e => this.onClickOrderType(e, "02001")
                                                        }><div className="hover"></div></div><div className="radio_text">체크·신용카드</div>
                                                    </div> 
                                                </div>
                                            }
                                            {
                                                this.state.order_type == "02002"
                                                ?
                                                <div className="redio_v2 m-l-34 m-t-32">
                                                    <div className="flexBox radio_area">
                                                        <input type="radio"></input>
                                                        <div className="radio on" onClick={
                                                            e => this.onClickOrderType(e, "02002")
                                                        }><div className="hover"></div></div><div className="radio_text">실시간계좌이체</div>
                                                    </div> 
                                                </div>
                                                :
                                                <div className="redio_v2 m-l-34 m-t-32">
                                                    <div className="flexBox radio_area">
                                                        <input type="radio"></input>
                                                        <div className="radio" onClick={
                                                            e => this.onClickOrderType(e, "02002")
                                                        }><div className="hover"></div></div><div className="radio_text">실시간계좌이체</div>
                                                    </div> 
                                                </div>
                                            }                                            
                                        </div>
                                    </div>
                                </>
                            : ''
                    }          
                </div>

                {/* 카드등록 팝업 */}
                {
                    this.state.popupType == "1"
                    ?   <div>
                            <div id="modal" className="registCard" style={{maxHeight:"100%", position:"fixed", height:"100vh", top:"0"}}>
                                <div className="modal_wrap">
                                    <div className="modalClose_v2" onClick={this.closePopup}></div>
                                    <div id="modalContent" className="shadow-box" style={{padding:"50px",maxHeight:"80%", overflowY:"auto"}}>
                                        <div className="f-s-18 m-b-50">
                                            {
                                                !(Object.keys(this.state.card_map).length === 0 && this.state.card_map.constructor === Object)
                                                ?   <b>카드 조회</b>
                                                :   <b>카드 등록</b>
                                            }
                                        </div>
                                        {
                                            !(Object.keys(this.state.card_map).length === 0 && this.state.card_map.constructor === Object)
                                            ?   <div className="formArea">
                                                    {/* <div className="flexBox m-t-15">
                                                        <div className="tax_request_input_div" style={{flex:"1", marginRight:"10px"}}>
                                                            <font style={{color:"#6d6d6d"}}>카드 소유자명</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "user_name")} value={this.state.card_map["user_name"]}></input>
                                                        </div>
                                                        <div className="tax_request_input_div" style={{flex:"1"}}>
                                                            <font style={{color:"#6d6d6d"}}>카드 소유자 생년월일(6자) 또는 사업자번호</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "corp_reg_no")} value={this.state.card_map["corp_reg_no"]}></input>
                                                        </div>
                                                    </div> */}
                                                    <div className="flexBox m-t-15">
                                                        <div className="tax_request_input_div" style={{flex:"1", marginRight:"10px"}}>
                                                            <font style={{color:"#6d6d6d"}}>카드번호 맨 뒷 4자리</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "card_no")} value={this.state.card_map["card_no"]} disabled></input>
                                                        </div>
                                                        {/* <div className="tax_request_input_div" style={{flex:"0.5"}}>
                                                        <font style={{color:"#6d6d6d"}}>카드 유효기간 (MM)</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "card_mm")} value={this.state.card_map["card_mm"]}></input>
                                                        </div>
                                                        <div className="tax_request_input_div" style={{flex:"0.5"}}>
                                                        <font style={{color:"#6d6d6d"}}>카드 유효기간 (YY)</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "card_yy")} value={this.state.card_map["card_yy"]}></input>
                                                        </div> */}
                                                    </div>
                                                    {/* <div className="flexBox m-t-15">
                                                        <div className="tax_request_input_div" style={{flex:"1", marginRight:"10px"}}>
                                                            <font style={{color:"#6d6d6d"}}>카드 비밀번호 (앞 2자리)</font>
                                                            <input type="password" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "card_pass")} value={this.state.card_map["card_pass"]}></input>
                                                        </div>
                                                        <div className="tax_request_input_div" style={{flex:"1"}}>
                                                            <font style={{color:"#6d6d6d"}}>이메일</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "email")} value={this.state.card_map["email"]}></input>
                                                        </div>
                                                    </div> */}
                                                    <div className="flexBox m-t-15">
                                                        {/* <div className="tax_request_input_div" style={{flex:"1", marginRight:"10px"}}>
                                                            <font style={{color:"#6d6d6d"}}>휴대폰번호</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "phone_num")} value={this.state.card_map["phone_num"]}></input>
                                                        </div> */}
                                                        <div className="tax_request_input_div" style={{flex:"1"}}>
                                                            <font style={{color:"#6d6d6d"}}>카드 별명</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "card_alias")} value={this.state.card_map["card_alias"]}></input>
                                                        </div>
                                                    </div>
                                                </div>
                                            :   <div className="formArea">
                                                    <div className="flexBox m-t-15">
                                                        {/* <div className="tax_request_input_div" style={{flex:"1", marginRight:"10px"}}>
                                                            <font style={{color:"#6d6d6d"}}>카드 소유자명</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "user_name")}></input>
                                                        </div> */}
                                                        <div className="tax_request_input_div" style={{flex:"1", marginRight:"10px"}}>
                                                            <font style={{color:"#6d6d6d"}}>카드번호</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "CARDNO")}></input>
                                                        </div>
                                                        <div className="tax_request_input_div" style={{flex:"1"}}>
                                                            <font style={{color:"#6d6d6d"}}>카드 유효기간 (YYYYMM)</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "EXPIREDT")}></input>
                                                        </div>
                                                    </div>
                                                    <div className="flexBox m-t-15">
                                                        <div className="tax_request_input_div" style={{flex:"1", marginRight:"10px"}}>
                                                            <font style={{color:"#6d6d6d"}}>카드명의자 주민/사업자번호 주민 : YYMMDD 형식, 사업자번호 : 10자리</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "CARDAUTH")}></input>
                                                        </div>
                                                        <div className="tax_request_input_div" style={{flex:"1"}}>
                                                            <font style={{color:"#6d6d6d"}}>카드 비밀번호 앞 2자리</font>
                                                            <input type="password" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "CARDPASSWORD")}></input>
                                                        </div>
                                                        {/* <div className="tax_request_input_div" style={{flex:"0.5"}}>
                                                        <font style={{color:"#6d6d6d"}}>카드 유효기간 (YY)</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "card_yy")}></input>
                                                        </div> */}
                                                    </div>
                                                    <div className="flexBox m-t-15">
                                                        {/* <div className="tax_request_input_div" style={{flex:"1"}}>
                                                            <font style={{color:"#6d6d6d"}}>이메일</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "email")}></input>
                                                        </div> */}
                                                    </div>
                                                    <div className="flexBox m-t-15">
                                                        {/* <div className="tax_request_input_div" style={{flex:"1", marginRight:"10px"}}>
                                                            <font style={{color:"#6d6d6d"}}>휴대폰번호</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "phone_no")}></input>
                                                        </div> */}
                                                        <div className="tax_request_input_div" style={{flex:"1"}}>
                                                            <font style={{color:"#6d6d6d"}}>카드 별명</font>
                                                            <input type="text" style={{width:"100%", borderRadius:"8px"}} onChange={e => this.onChangeInputText(e, "CARDALIAS")}></input>
                                                        </div>
                                                    </div>
                                                    {/* <div className="flexBox m-t-32">
                                                        <button id="btnSaveTaxRecipt" className="btn-auto-b" style={{flex:"1", height:"40px", borderRadius:"8px"}} onClick={this.saveTaxRequestFileUpload}>등록하기</button> 
                                                    </div> */}
                                                </div>
                                        }
                                    </div>
                                    {
                                        !(Object.keys(this.state.card_map).length === 0 && this.state.card_map.constructor === Object)
                                        ?   <div className="flexBox m-t-32 btn_save" style={{width:"132px", right: "calc(13.5%)",position: "fixed", top: "calc(304px + 22%)", zIndex:9999}}>
                                                <button id="btnSaveTaxRecipt" className="btn-auto-b" style={{flex:"1", height:"57px", borderRadius:"8px"}} onClick={this.saveTaxRequestFileUpload}>별명 수정</button> 
                                            </div>
                                        :   <div className="flexBox btnSaveTaxRecipt btn_save" style={{width:"132px", right: "calc(13.5%)",position: "fixed", top: "calc(411px + 25%)", zIndex:9999}}>
                                                <button id="btnSaveTaxRecipt" className="btn-auto-b" style={{zIndex: 999999999, width:"100%", height:"55px", borderRadius:"8px"}} onClick={this.saveCardKeygenProc}>등록하기</button> 
                                            </div>
                                    }
                                </div>
                            </div>
                            {/* 팝업창 배경 */}
                            <div id="modalBack" style={{top: "0", left:"0", height:"130%", zIndex:"999"}}></div>
                        </div>
                    : ""
                }

                <div style={{display:"flex", float:"right"}}>
                    {
                        this.state.step == "1"
                        ? ""
                        :   <div id="button_step_before" style={{padding: "18px 30px 17px", marginTop:"30px", marginBottom:"40px", color: "#C4C4C4", cursor:"pointer"}} onClick={e => this.onChangeStepBefore(e)}>
                                이전
                            </div>
                    }
                    <button id="button_step_next" className="btn-auto-b" style={{padding: "18px 30px 17px", marginTop:"30px", marginBottom:"40px"}} onClick={e => this.onChangeStepNext(e)}>
                        다음
                    </button>
                </div>







                {/* 실시간 계좌이체 FORM */}
                <form name="payForm" style={{display:"none"}} accept-charset="euc-kr">
                <table style={{width:"100%",border:"1px",cellspacing:"0", cellpadding:"0"}}>
                    <colgroup>
                        <col width="160px" />
                        <col width="*" />
                    </colgroup>
                    <tr>
                        <td></td>
                        <td>
                            {/* <input type="button" value="결제하기" onClick={this.payLink} style={{width:"63", height:"25"}}/> */}
                        </td>
                    </tr>
                </table>
                <br/>
                <br/>

                <table style={{width:"100%",border:"1px",cellspacing:0,cellpadding:0}}>
                    <colgroup>
                        <col width="160px" />
                        <col width="180px" />
                        <col width="*" />
                    </colgroup>
                    <tr> 
                        <td colspan="3"><b>----------공통항목(필수)</b></td>
                    </tr>
                    <tr> 
                        <td><b>파라미터</b></td>
                        <td><b>값</b></td>
                        <td><b>설명</b></td>
                    </tr>
                    <tr> 
                        <td>USER_SEQ</td>
                        <td><input type="text" name="RESERVEDINDEX1" value={this.state.payLinkPreData.USER_SEQ}/></td>
                        <td>사용자 일련번호</td>
                    </tr>
                    <tr> 
                        <td>SHOP_NO</td>
                        <td><input type="text" name="RESERVEDINDEX2" value={this.state.payLinkPreData.SHOP_NO}/></td>
                        <td>쇼핑몰 번호</td>
                    </tr>
                    <tr> 
                        <td>STD_YM</td>
                        <td><input type="text" name="STD_YM" value={this.state.payLinkPreData.STD_YM}/></td>
                        <td>기준 년월</td>
                    </tr>
                    <tr> 
                        <td colspan="3"><b>----------고정항목----------</b></td>
                    </tr>
                    <tr> 
                        <td>TYPE</td>
                        <td><input type="text" name="TYPE" value="W"/></td>
                        <td>결제방식</td>
                    </tr>
                    <tr> 
                        <td>PAYMETHOD</td>
                        <td><input type="text" name="PAYMETHOD" value="BANK"/></td>
                        <td>결제수단</td>
                    </tr>
                    <tr> 
                        <td>CPID</td>
                        <td>
                            {/* 개발 : <input type="text" name="CPID" value="CTS16826"/> */}
                            <input type="text" name="CPID" value="CRG55686"/>
                        </td>
                        <td>상점아이디</td>
                    </tr>
                    <tr> 
                        <td>PRODUCTTYPE</td>
                        <td>
                            {/* 개발 : <input type="text" name="PRODUCTTYPE" value="1"/> */}
                            <input type="text" name="PRODUCTTYPE" value="2"/>
                        </td>
                        <td>상품구분(1: 디지털, 2: 실물)</td>
                    </tr>
                    <tr> 
                        <td colspan="3"><b>----------변동항목(DB에서 조회)----------</b></td>
                    </tr>
                    <tr> 
                        <td>ORDERNO</td>
                        <td><input type="text" name="ORDERNO" value={this.state.payLinkPreData.ORDERNO}/></td>
                        <td>주문번호</td>
                    </tr>
                    <tr> 
                        <td>AMOUNT</td>
                        <td><input type="text" name="AMOUNT" value={this.state.payLinkPreData.AMOUNT}/></td>
                        {/* <td><input type="text" name="AMOUNT" value="1000"/></td> */}
                        <td>결제금액</td>
                    </tr>
                    <tr> 
                        <td>PRODUCTNAME</td>
                        <td><input type="text" name="PRODUCTNAME" value={this.state.payLinkPreData.PRODUCT_NAME}/></td>
                        <td>상품명</td>
                    </tr>
                    <tr> 
                        <td>PRODUCTCODE</td>
                        <td><input type="text" name="PRODUCTCODE" value={this.state.payLinkPreData.PRODUCT_NO}/></td>
                        <td>상품코드</td>
                    </tr>
                    <tr> 
                        <td>USERID</td>
                        <td><input type="text" name="USERID" value={this.state.payLinkPreData.USERID}/></td>
                        <td>고객 ID</td>
                    </tr>
                    <tr> 
                        <td>EMAIL</td>
                        <td><input type="text" name="EMAIL" value={this.state.payLinkPreData.EMAIL}/></td>
                        <td>고객 이메일</td>
                    </tr>
                    <tr> 
                        <td>USERNAME</td>
                        <td><input type="text" name="USERNAME" value={this.state.payLinkPreData.USERNAME}/></td>
                        <td>고객명</td>
                    </tr>
                    <tr> 
                        <td colspan="3"><b>----------변동항목(선택)----------</b></td>
                    </tr>
                    <tr> 
                        <td>RESERVEDSTRING</td>
                        <td><input type="text" name="RESERVEDSTRING" value={this.state.payLinkPreData.PAYMENT_AMOUNT_SEQ}/></td>
                        <td>예약스트링</td>
                    </tr>
                    <tr> 
                        <td>RETURNURL</td>
                        <td><input type="text" name="RETURNURL" value="https://robomd.io/shop/order/list"/></td>
                        <td>결제 성공 후, 이동할 URL(새 창)</td>
                    </tr>
                    <tr> 
                        <td>DIRECTRESULTFLAG</td>
                        <td><input type="text" name="DIRECTRESULTFLAG" value=""/></td>
                        <td>페이조아 결제 완료 창 없이 HOMEURL로 바로 이동(Y/N)</td>
                    </tr>
                    <tr> 
                        <td>SET_LOGO</td>
                        <td><input type="text" name="SET_LOGO" value=""/></td>
                        <td>결제 창 하단 상점로고 URL</td>
                    </tr>	
                    <tr> 
                        <td colspan="3"><b>----------웹뷰결제창(필수)</b></td>
                    </tr> 
                    <tr> 
                        <td>HOMEURL</td>
                        <td><input type="text" name="HOMEURL" value="javascript:window.close()"/></td>
                        <td>결제 성공 후, 이동할 URL(결제 창에서 이동)</td>
                    </tr>
                    <tr>
                        <td>CLOSEURL</td>
                        <td><input type="text" name="CLOSEURL" value="javascript:window.close()"/></td>
                        <td>결제 창에서 취소 누를 시 이동할 URL</td>
                    </tr>
                    <tr> 
                        <td>FAILURL</td>
                        <td><input type="text" name="FAILURL" value="javascript:window.close()"/></td>
                        <td>결제실패 후, 확인버튼 입력 시 이동할 URL</td>
                    </tr>
                    <tr>
                        <td>APPURL</td>
                        <td><input type="text" name="APPURL" value=""/></td>
                        <td>인증완료 후 돌아갈 앱의 URL(CARD, CARDK, BANK 필수)</td>
                    </tr>
                    <tr> 
                        <td colspan="3"><b>----------계좌이체(선택)</b></td>
                    </tr>
                    <tr> 
                        <td>USERSSN</td>
                        <td><input type="text" name="USERSSN" value=""/></td>
                        <td>생년월일(YYMMDD)</td>
                    </tr>
                    <tr> 
                        <td>CHECKSSNYN</td>
                        <td><input type="text" name="CHECKSSNYN" value=""/></td>
                        <td>USERSSN사용여부(Y/N)(계좌이체 결제자와 명의자(USERSSN)가 동일한 경우만 결제가능)</td>
                    </tr>
                </table>
                </form>
            </div>
        )
    }
}   
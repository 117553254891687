import React, {useEffect, useState} from "react";
import { v4 as uuidv4 } from 'uuid';

/**
 * @param {*} id 체크박스 id
 * @param {*} value 체크박스 value 값
 * @param {*} text 체크박스 span에 보일 텍스트, null이면 span 영역 사용안함
 * @param {*} checked 체크 상태 true/false, null이면 false
 * @param {*} disabled 비활성화 상태 true/false, null이면 false
 * @param {*} onChangeCheck 체크 변경시 실행 함수
 * @param {*} className 클래스 명
 */

const CheckBox = ({children, id, value, text, checked, disabled, onChangeCheck, className}) => {
    const [idValue, setIdValue] = useState(id ? id : uuidv4());

    useEffect(() => {
        if(id){
            setIdValue(id);
        }
    },[id]);

    return (
        <div className={"common_checkbox "+className}>
            <input className={"checkbox"} type="checkbox" id={idValue} value={value} disabled={disabled} checked={checked} onChange={e => onChangeCheck(e)}/>
            {disabled
            ?   checked
                ?<>
                    <label for={idValue}>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 9C3 5.68629 5.68629 3 9 3H23C26.3137 3 29 5.68629 29 9V23C29 26.3137 26.3137 29 23 29H9C5.68629 29 3 26.3137 3 23V9Z" fill="#E3E3E3"/>
                            <path d="M24.6443 10.4134C24.8811 10.6655 25.0093 11.0084 24.9995 11.3613C24.9995 11.7142 24.8416 12.047 24.595 12.289L15.0346 21.6364C14.7879 21.8785 14.4624 22.0095 14.117 21.9995C13.7816 21.9995 13.456 21.8684 13.2192 21.6364L8.44398 16.9678C8.31572 16.8467 8.20719 16.7056 8.12826 16.5442C8.04933 16.3829 8.00987 16.2014 8 16.03C8 15.8485 8.01973 15.667 8.07893 15.5056C8.13813 15.3342 8.23679 15.183 8.35518 15.0519C8.47358 14.9208 8.62157 14.82 8.77943 14.7494C8.93729 14.6788 9.11488 14.6384 9.29247 14.6384C9.47006 14.6384 9.64766 14.6687 9.80552 14.7393C9.96337 14.8099 10.1114 14.9107 10.2396 15.0418L14.1269 18.8231L22.8092 10.363C23.0558 10.121 23.3913 10 23.7366 10C24.0819 10 24.4075 10.1613 24.6443 10.4134Z" fill="white"/>
                        </svg>
                    </label>
                </>
                :<>
                    <label for={idValue}>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 9C3 5.68629 5.68629 3 9 3H23C26.3137 3 29 5.68629 29 9V23C29 26.3137 26.3137 29 23 29H9C5.68629 29 3 26.3137 3 23V9Z" fill="#F7F7F7"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M23 4H9C6.23858 4 4 6.23858 4 9V23C4 25.7614 6.23858 28 9 28H23C25.7614 28 28 25.7614 28 23V9C28 6.23858 25.7614 4 23 4ZM9 3C5.68629 3 3 5.68629 3 9V23C3 26.3137 5.68629 29 9 29H23C26.3137 29 29 26.3137 29 23V9C29 5.68629 26.3137 3 23 3H9Z" fill="#EEEEEE"/>
                        </svg>
                    </label>
                </>
            :<>
                <label for={idValue}>
                    <svg className={'checkbox_default'} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 9C3 5.68629 5.68629 3 9 3H23C26.3137 3 29 5.68629 29 9V23C29 26.3137 26.3137 29 23 29H9C5.68629 29 3 26.3137 3 23V9Z" fill="white"/><path fillRule="evenodd" clipRule="evenodd" d="M23 4H9C6.23858 4 4 6.23858 4 9V23C4 25.7614 6.23858 28 9 28H23C25.7614 28 28 25.7614 28 23V9C28 6.23858 25.7614 4 23 4ZM9 3C5.68629 3 3 5.68629 3 9V23C3 26.3137 5.68629 29 9 29H23C26.3137 29 29 26.3137 29 23V9C29 5.68629 26.3137 3 23 3H9Z" fill="#ACACAC"/><path className='check_icn' d="M24.6443 10.4134C24.8811 10.6655 25.0093 11.0084 24.9995 11.3613C24.9995 11.7142 24.8416 12.047 24.595 12.289L15.0346 21.6364C14.7879 21.8785 14.4624 22.0095 14.117 21.9995C13.7816 21.9995 13.456 21.8684 13.2192 21.6364L8.44398 16.9678C8.31572 16.8467 8.20719 16.7056 8.12826 16.5442C8.04933 16.3829 8.00987 16.2014 8 16.03C8 15.8485 8.01973 15.667 8.07893 15.5056C8.13813 15.3342 8.23679 15.183 8.35518 15.0519C8.47358 14.9208 8.62157 14.82 8.77943 14.7494C8.93729 14.6788 9.11488 14.6384 9.29247 14.6384C9.47006 14.6384 9.64766 14.6687 9.80552 14.7393C9.96337 14.8099 10.1114 14.9107 10.2396 15.0418L14.1269 18.8231L22.8092 10.363C23.0558 10.121 23.3913 10 23.7366 10C24.0819 10 24.4075 10.1613 24.6443 10.4134Z" fill="#D1D1D1"/></svg>
                    <svg className={'checkbox_selected'} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 9C3 5.68629 5.68629 3 9 3H23C26.3137 3 29 5.68629 29 9V23C29 26.3137 26.3137 29 23 29H9C5.68629 29 3 26.3137 3 23V9Z" fill="#00E2E6"/><path d="M24.6443 10.4134C24.8811 10.6655 25.0093 11.0084 24.9995 11.3613C24.9995 11.7142 24.8416 12.047 24.595 12.289L15.0346 21.6364C14.7879 21.8785 14.4624 22.0095 14.117 21.9995C13.7816 21.9995 13.456 21.8684 13.2192 21.6364L8.44398 16.9678C8.31572 16.8467 8.20719 16.7056 8.12826 16.5442C8.04933 16.3829 8.00987 16.2014 8 16.03C8 15.8485 8.01973 15.667 8.07893 15.5056C8.13813 15.3342 8.23679 15.183 8.35518 15.0519C8.47358 14.9208 8.62157 14.82 8.77943 14.7494C8.93729 14.6788 9.11488 14.6384 9.29247 14.6384C9.47006 14.6384 9.64766 14.6687 9.80552 14.7393C9.96337 14.8099 10.1114 14.9107 10.2396 15.0418L14.1269 18.8231L22.8092 10.363C23.0558 10.121 23.3913 10 23.7366 10C24.0819 10 24.4075 10.1613 24.6443 10.4134Z" fill="#333333"/></svg>
                </label>
            </>}
            {text && <span dangerouslySetInnerHTML={{__html: text}}></span>}
            { children }
        </div>
    );
}

CheckBox.defaultProps = {
    value : "",
    checked : false,
    disabled : false,
    className : "",
}

export default React.memo(CheckBox);
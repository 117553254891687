import React, { useCallback, useState } from 'react';
import Pagination from "react-js-pagination";
import http from '../modules/api';
import $ from 'jquery';

import RpProductListPopup from '../modules/popup/RpProductListPopup';
import * as StyleUtil from '../modules/utils/StyleUtil';
import * as CurationServiceVariable from '../modules/CurationServiceVariable.js';

var curation_result_page_url = "/shop/curation/result";

let window_scrollTop; // 스크롤 위치

export class CurationResultList extends React.Component {
    
    constructor(props) {
        super(props);
		this.state = {
            /* 페이징 */
            total_cnt:100,
            activePage:1,
            rowcnt:10,
            startIdx:0,

            /*  큐레이션 결과 목록 */
            curation_result_list: [],
            
            /*  진열 팝업 호춣용 아이디 */
            rp_popup_iframe_page_id: "",
            rp_popup_iframe_html_id: "",
            
            /* 진열 팝업 열림/닫힘 상태값 */
            rp_product_list_dialog_show_state: false,

            /* 응답 데이터 없음 상태값 */
            noResponse : false,
        }
    }

    componentDidMount() {
        const {location} = this.props; 
        if (location.state != null && location.state.curationResultListState != null) { 
            // console.log(location.state.curationResultListState);
            this.setState(location.state.curationResultListState, () => {
                this.loadCurationResult(); // 큐레이션 결과 목록 조회
            }); // history에 저장된 state 사용
            window.history.replaceState({}, document.title); // location.state 초기화
        } else {
            this.loadCurationResult(); // 큐레이션 결과 목록 조회
        }

        // $(document).ready(function(){
        //     var quration_result = $("#quration_result"); // 큐레이션 결과
        
        //     var table_th, table_width;
        
        //     // 큐레이션 결과 목록
            
        //     // 큐레이션 결과 목록 - 레이어팝업 테이블 넓이 구하기
        //     function result_popup_table_width(){
        //         table_th = quration_result.next(".quration_popup").find("th");
        //         table_width = 0;
        
        //         for(let i = 0; i < table_th.length; i++){
        //             table_width += table_th.eq(i).outerWidth();
        //         }
        //         quration_result.next(".quration_popup").find(".quration_list_title").css('width',table_width);
        //         quration_result.next(".quration_popup").find(".quration_list_table_wrap").css('width',table_width);
        //         quration_result.next(".quration_popup").find(".quration_list_table").css('width',table_width);
        //         return;
        //     }
        //     result_popup_table_width();
        
        
        //     // 큐레이션 결과 목록 - 레이어팝업
        //     quration_result.find(".table_list").on("click",function(){
        //         quration_result.next(".quration_popup").css("display","flex");
        //         quration_result.next(".quration_popup").find(".quration_result_layer").css("display","flex");
        //         return;
        //     });
        
        //     // 큐레이션 결과 목록 - 레이어팝업 (취소 버튼 클릭)
        //     quration_result.next(".quration_popup").find(".quration_result_layer_cancel_btn").on("click",function(){
        //         quration_result.next(".quration_popup").css("display","none");
        //         quration_result.next(".quration_popup").find(".quration_result_layer").css("display","none");
        //         return;
        //     });
        // });
    }

    // 큐레이션 결과 목록 조회
    async loadCurationResult() {
        this.setState({
            curation_result_list: [], // 목록 초기화 후 axios 호출
        });
        await http.get("/rest/shop/curation_result"
                        +"?start_idx="+this.state.startIdx
                        +"&row_cnt="+this.state.rowcnt)
        .then(res => {
            if (res.data.code == 200) {
                this.setState({
                    curation_result_list:  res.data.curation_result_list,
                    noResponse: (
                        res.data.curation_result_list != null && res.data.curation_result_list.length > 0 
                        ?   false 
                        :   res.data.curation_result_list == null
                            ?   false
                            :   true
                    ),
                    total_cnt: res.data.total_cnt,
                })
            } else {
                this.setState({
                    noResponse: true,
                })
            }
        })
        .catch(error => {
            alert(CurationServiceVariable.RESULT+CurationServiceVariable.LIST+" 조회에 실패하였습니다.");
            this.setState({
                noResponse: true,
            })
        });
    }
    
    // 페이징
    handlePageChange(pageNumber) {		
		this.setState({
            activePage: pageNumber,
            startIdx : (pageNumber-1) * this.state.rowcnt
        }, () => {
            this.loadCurationResult();
        });
    }

    // 진열 팝업 열기
    rpProductListPopupOpen(e, rp_popup_iframe_page_id, rp_popup_iframe_html_id) {
        e.stopPropagation(); // 상위 클릭 이벤트 막기
        window_scrollTop = StyleUtil.onPopupNoneScroll();
        this.setState({
            rp_product_list_dialog_show_state: true,
            rp_popup_iframe_page_id: rp_popup_iframe_page_id,
            rp_popup_iframe_html_id: rp_popup_iframe_html_id,
        })
    }
    // 진열 팝업 닫기
    rpProductListPopupClose() {
        this.setState({
            rp_product_list_dialog_show_state: false,
            rp_popup_iframe_page_id: "",
            rp_popup_iframe_html_id: "",
        }, () => {
            StyleUtil.clearPopupNoneScroll(window_scrollTop);
        });
    }

    /* 상세 페이지로 이동 */
    goResultPage(result_seq) {
        this.props.history.push(
            {
                pathname: curation_result_page_url+`?result_seq=`+result_seq,
                state: {curationResultListState: JSON.parse(JSON.stringify(this.state))}
            }	
        )
    }

    render() {
        // alert("render: "+this.state.curation_result_list.length);
        return (
            <>
                <div id='common-page-wrapper'>
                <div id="wrap">
                    <section class="quration_list quration_result style_guide_v2" id="quration_result">
                        <div class="quration_list_title">
                            <h2 class="text_20">{CurationServiceVariable.CURATION} {CurationServiceVariable.RESULT} {CurationServiceVariable.LIST}</h2>
                            <button class="text_13 hover_mint" onClick={e => {
                                   this.props.history.push(
                                    {
                                        pathname: curation_result_page_url,
                                        state: {curationResultListState: this.state}
                                    }	
                                )
                                }
                            }>{CurationServiceVariable.RESULT} {CurationServiceVariable.CREATE}</button>
                        </div>
                        <div class="quration_list_table_wrap">
                                <table class="quration_list_table">
                                    <thead>
                                        <tr class="text_13">
                                            <th>번호</th>
                                            <th>{CurationServiceVariable.CURATION} {CurationServiceVariable.RESULT} {CurationServiceVariable.TITLE}</th>
                                            <th>진열 목록</th>
                                            <th>등록일</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.curation_result_list != null && this.state.curation_result_list.map((obj, idx) => (
                                                <>
                                                <tr class="text_13" onClick={() => this.goResultPage(obj.curation_result_seq)}>
                                                    <td class="table_number">{(this.state.total_cnt-(idx+(10*(this.state.activePage-1))))}</td>
                                                    <td class="table_name"><span>{obj.curation_result_title}</span></td>
                                                    <td class="table_list">
                                                        <span>{obj.rp_title}</span>
                                                        <button class="table_list_btn" type="button" onClick={e => this.rpProductListPopupOpen(e, obj.rp_iframe_page_id, obj.rp_iframe_html_id)}>
                                                            <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M18.4453 17.0547L15.0625 13.6797C16.2564 12.2583 16.8555 10.4308 16.7348 8.57844C16.614 6.72607 15.7828 4.99182 14.4146 3.73738C13.0463 2.48294 11.2465 1.80518 9.39063 1.84545C7.53477 1.88571 5.7661 2.6409 4.4535 3.9535C3.1409 5.2661 2.38571 7.03477 2.34545 8.89063C2.30518 10.7465 2.98294 12.5463 4.23738 13.9146C5.49182 15.2828 7.22607 16.114 9.07844 16.2348C10.9308 16.3555 12.7583 15.7564 14.1797 14.5625L17.5547 17.9453C17.6742 18.0608 17.8338 18.1254 18 18.1254C18.1662 18.1254 18.3258 18.0608 18.4453 17.9453C18.5627 17.8268 18.6285 17.6668 18.6285 17.5C18.6285 17.3332 18.5627 17.1732 18.4453 17.0547ZM3.625 9.0625C3.625 7.88817 3.97323 6.74022 4.62565 5.7638C5.27807 4.78738 6.20538 4.02636 7.29032 3.57696C8.37525 3.12757 9.56909 3.00999 10.7208 3.23909C11.8726 3.46819 12.9306 4.03368 13.7609 4.86405C14.5913 5.69443 15.1568 6.75239 15.3859 7.90415C15.615 9.05591 15.4974 10.2497 15.048 11.3347C14.5986 12.4196 13.8376 13.3469 12.8612 13.9994C11.8848 14.6518 10.7368 15 9.5625 15C7.98841 14.9979 6.47939 14.3717 5.36634 13.2587C4.25329 12.1456 3.62707 10.6366 3.625 9.0625Z" fill="#333333"/>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                    <td class="table_date">{obj.reg_date}</td>
                                                </tr>
                                                </>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            {    
                                this.state.noResponse == true
                                ?   <div class='quration_list_table_empty'>생성된 {CurationServiceVariable.RESULT} 정보가 없습니다.<br/>{CurationServiceVariable.RESULT} 정보를 생성해주세요.</div>
                                :   ""
                            }
                        </div>
                        {    
                            this.state.curation_result_list.length > 0 && this.state.noResponse == false
                            ?
                                <>
                                {
                                    Number(this.state.total_cnt) > 0
                                    ?
                                    <div class="common_pagination" style={{maxWidth: '1241px'}}>
                                        <Pagination
                                            firstPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.35627 13.3562C8.26147 13.4501 8.13344 13.5028 8.00002 13.5028C7.8666 13.5028 7.73857 13.4501 7.64377 13.3562L2.64377 8.35623C2.55136 8.26066 2.4997 8.13292 2.4997 7.99998C2.4997 7.86704 2.55136 7.7393 2.64377 7.64373L7.64377 2.64373C7.74092 2.564 7.86425 2.52325 7.98977 2.52942C8.1153 2.53558 8.23405 2.58822 8.32291 2.67709C8.41178 2.76595 8.46442 2.8847 8.47058 3.01023C8.47675 3.13575 8.436 3.25908 8.35627 3.35623L3.70627 7.99998L8.35627 12.6437C8.45016 12.7385 8.50283 12.8666 8.50283 13C8.50283 13.1334 8.45016 13.2614 8.35627 13.3562ZM7.64377 7.64373C7.55136 7.7393 7.4997 7.86704 7.4997 7.99998C7.4997 8.13292 7.55136 8.26066 7.64377 8.35623L12.6438 13.3562C12.7409 13.436 12.8643 13.4767 12.9898 13.4705C13.1153 13.4644 13.234 13.4117 13.3229 13.3229C13.4118 13.234 13.4644 13.1153 13.4706 12.9897C13.4767 12.8642 13.436 12.7409 13.3563 12.6437L8.70627 7.99998L13.3563 3.35623C13.436 3.25908 13.4767 3.13575 13.4706 3.01023C13.4644 2.8847 13.4118 2.76595 13.3229 2.67709C13.234 2.58822 13.1153 2.53558 12.9898 2.52942C12.8642 2.52325 12.7409 2.564 12.6438 2.64373L7.64377 7.64373Z" fill="#8B8B8B"/>
                                            </svg>}
                                            prevPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.50002 7.99998C4.50182 7.86746 4.55309 7.74039 4.64377 7.64373L9.64377 2.64373C9.74092 2.564 9.86425 2.52325 9.98977 2.52942C10.1153 2.53558 10.234 2.58822 10.3229 2.67709C10.4118 2.76595 10.4644 2.8847 10.4706 3.01023C10.4767 3.13575 10.436 3.25908 10.3563 3.35623L5.70627 7.99998L10.3563 12.6437C10.436 12.7409 10.4767 12.8642 10.4706 12.9897C10.4644 13.1153 10.4118 13.234 10.3229 13.3229C10.234 13.4117 10.1153 13.4644 9.98977 13.4705C9.86425 13.4767 9.74092 13.436 9.64377 13.3562L4.64377 8.35623C4.55309 8.25957 4.50182 8.1325 4.50002 7.99998Z" fill="#8B8B8B"/>
                                            </svg>}
                                            nextPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.5 8.00002C11.4982 8.13254 11.4469 8.25961 11.3562 8.35627L6.35623 13.3563C6.25908 13.436 6.13575 13.4767 6.01023 13.4706C5.8847 13.4644 5.76595 13.4118 5.67709 13.3229C5.58822 13.234 5.53558 13.1153 5.52942 12.9898C5.52325 12.8642 5.564 12.7409 5.64373 12.6438L10.2937 8.00002L5.64373 3.35627C5.564 3.25912 5.52325 3.13579 5.52942 3.01026C5.53558 2.88474 5.58822 2.76599 5.67709 2.67712C5.76596 2.58826 5.8847 2.53562 6.01023 2.52946C6.13575 2.52329 6.25908 2.56404 6.35623 2.64377L11.3562 7.64377C11.4469 7.74043 11.4982 7.8675 11.5 8.00002Z" fill="#8B8B8B"/>
                                            </svg>}
                                            lastPageText={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.64373 2.64377C7.73853 2.54988 7.86656 2.49721 7.99998 2.49721C8.1334 2.49721 8.26143 2.54988 8.35623 2.64377L13.3562 7.64377C13.4486 7.73934 13.5003 7.86708 13.5003 8.00002C13.5003 8.13296 13.4486 8.2607 13.3562 8.35627L8.35623 13.3563C8.25908 13.436 8.13575 13.4767 8.01023 13.4706C7.8847 13.4644 7.76595 13.4118 7.67709 13.3229C7.58822 13.234 7.53558 13.1153 7.52942 12.9898C7.52325 12.8642 7.564 12.7409 7.64373 12.6438L12.2937 8.00002L7.64373 3.35627C7.54984 3.26147 7.49717 3.13344 7.49717 3.00002C7.49717 2.8666 7.54984 2.73857 7.64373 2.64377ZM8.35623 8.35627C8.44864 8.2607 8.5003 8.13296 8.5003 8.00002C8.5003 7.86708 8.44864 7.73934 8.35623 7.64377L3.35623 2.64377C3.25908 2.56404 3.13575 2.52329 3.01022 2.52946C2.8847 2.53562 2.76595 2.58826 2.67709 2.67712C2.58822 2.76599 2.53558 2.88474 2.52942 3.01026C2.52325 3.13579 2.564 3.25912 2.64373 3.35627L7.29373 8.00002L2.64373 12.6438C2.564 12.7409 2.52325 12.8642 2.52942 12.9898C2.53558 13.1153 2.58822 13.234 2.67709 13.3229C2.76595 13.4118 2.8847 13.4644 3.01023 13.4706C3.13575 13.4767 3.25908 13.436 3.35623 13.3563L8.35623 8.35627Z" fill="#8B8B8B"/>
                                            </svg>}
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.row_cnt}
                                            totalItemsCount={this.state.total_cnt}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange.bind(this)}
                                        />
                                    </div>    
                                    : ""
                                }
                            </>
                            :   ""  
                        } 
                    </section>

                    {
                        /* 진열 상품 팝업 */
                        this.state.rp_product_list_dialog_show_state
                        ?    <RpProductListPopup
                            iframe_page_id={this.state.rp_popup_iframe_page_id}
                            iframe_html_id={this.state.rp_popup_iframe_html_id}
                            popup_close={this.rpProductListPopupClose.bind(this)}
                            />
                        :   ""
                    }
                </div>
                </div>
            </>
        )
    }
}

export default CurationResultList;
import React, { useState } from 'react';
import http from '../../modules/api';

import Slider from "react-slick";
import styles from './CreateBannerPreviewPopup.module.css';
import PopupFrameV2 from '../../modules/popup/PopupFrameV2';
import Button from '../../modules/common/Button';

/**
 * 생성된 배너 미리보기 팝업
 * @param {*} className
 * @param {*} event_seq_list 배너 event_seq 리스트
 * @param {*} ratio_list 배너 비율 리스트 ex) [2, 0.5]
 * @function onClose 팝업 닫기 함수
 */
class CreateBannerPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pc_banner_width : '',
            pc_banner_height : '',
            mobile_banner_width : '',
            mobile_banner_height : '',
            pc_width_height : 0,
            mobile_width_height : 0,
            slick_idx : 0,
            save_banner_list : [], // 저장을 누른 배너 리스트
            event_seq_list: [],
            banner_slide_setting: [],
        }
        this.setBannerSize = this.setBannerSize.bind(this);
    }

    componentDidMount (){
        let event_length_list = this.props.one_click_event_list.map((setting) => setting.event_seq_list.length);
        let event_seq_list = this.props.one_click_event_list.map((setting) => setting.event_seq_list).reduce((result, current) => [...result, ...current],[]);
        let banner_slide_setting = this.props.one_click_event_list.map(() => ({
            pc_banner_width : 0,
            pc_banner_height : 0,
            mobile_banner_width : 0,
            mobile_banner_height : 0,
            pc_width_height : 0,
            mobile_width_height : 0,
            w_event_create_yn: "N",
            h_event_create_yn : "N",
        }));

        this.setState({
            event_seq_list : event_seq_list,
            event_length_list : event_length_list,
            banner_slide_setting : banner_slide_setting,
        }, ()  => {
            this.setBannerSize();  
        });
        if(event_seq_list.length == 0){
            alert("생성된 배너가 없습니다.");
            this.props.onClose();
            
            return false;
        }
        
        document.body.style.overflow = 'hidden';
        window.addEventListener("resize", this.setBannerSize);
        window.addEventListener( "orientationchange", () => this.setBannerSize(true));
        this.setBannerSize();
    }

    componentWillUnmount (){
        document.body.style.overflow = 'auto';
        window.removeEventListener("resize", this.setBannerSize);
        window.removeEventListener( "orientationchange", this.setBannerSize);
    }

    /** 캡처되는 실제 사이즈 계산 */
    getCaptureSize(type, org_width_height){
        let banner_width = 0;
        let banner_height = 0;
        
        banner_width = 1000 * org_width_height;
        banner_height = 1000;

        if (banner_width > 10000 || banner_height > 10000) {
            if(type == "pc") {
                    banner_width = 10000
                    banner_height = 10000 / org_width_height;
            } else {
                    banner_width = 10000 / org_width_height;
                    banner_height = 10000
            }
        }

        return {banner_width, banner_height};
    }

    /**
     * 배너 비율 설정
     */
    setRatio = (setting) => {
        const ratio_list = [
            {
                ratio : setting.event_banner_pc_width_height,
                width : setting.event_banner_pc_width,
                height : setting.event_banner_pc_height,
            },
            {
                ratio : setting.event_banner_mobile_width_height,
                width : setting.event_banner_mobile_width,
                height : setting.event_banner_mobile_height,
            }
        ];
        let pc_width_height, mobile_width_height, capture_pc_width, capture_pc_height, capture_mobile_width, capture_mobile_height;

        for(let i=0;i<ratio_list.length;i++){
            if(ratio_list[i].ratio > 1 && !pc_width_height){
                pc_width_height = ratio_list[i].ratio;
                capture_pc_width = ratio_list[i].width;
                capture_pc_height = ratio_list[i].height;
                if(!(capture_pc_width > 50 || capture_pc_height > 50)){
                    const {banner_width, banner_height} = this.getCaptureSize("pc",pc_width_height);
                    capture_pc_width = banner_width;
                    capture_pc_height = banner_height;
                }
            } else if(ratio_list[i].ratio <= 1 && !mobile_width_height){
                mobile_width_height = ratio_list[i].ratio;
                capture_mobile_width = ratio_list[i].width;
                capture_mobile_height = ratio_list[i].height;
                if(!(capture_mobile_width > 50 || capture_mobile_height > 50)){
                    const {banner_width, banner_height} = this.getCaptureSize("mobile",mobile_width_height);
                    capture_mobile_width = banner_width;
                    capture_mobile_height = banner_height;
                }
            }
        }

        return {pc_width_height, mobile_width_height, capture_pc_width, capture_pc_height, capture_mobile_width, capture_mobile_height};
    }

    /**
     *  배너 사이즈 설정
     * @param {*} resize 사이즈 설정 다시 실행
     */
    setBannerSize = (resize) => {
        if(!document.getElementById("banner_area")){
            return false;
        }
        
        let banner_slide_setting =[];

        for(let setting of this.props.one_click_event_list){
            let area_width = document.getElementById("banner_area").clientWidth; // 사용 가능한 가로 전체 길이
            let pc_width, mobile_width;

            let {pc_width_height, mobile_width_height, capture_pc_width, capture_pc_height, capture_mobile_width, capture_mobile_height} = this.setRatio(setting);
            
            if(window.innerWidth > 800 && pc_width_height && mobile_width_height && setting.w_event_create_yn == "Y" && setting.h_event_create_yn == "Y"){
                area_width -= 32; // 전체 길이에서 가로형과 세로형 비율 사이의 여백 사이즈 만큼을 제외하고 계산한다
                const total_ratio_width = pc_width_height + mobile_width_height;
                pc_width = area_width * (pc_width_height / total_ratio_width);
                mobile_width = area_width * (mobile_width_height / total_ratio_width);
            } else {
                pc_width = area_width;
                mobile_width = area_width;
            }
    
            banner_slide_setting = [...banner_slide_setting, {
                pc_banner_width : pc_width,
                pc_banner_height : pc_width / pc_width_height,
                mobile_banner_width : mobile_width,
                mobile_banner_height : mobile_width / mobile_width_height,
                pc_width_height : pc_width_height,
                mobile_width_height : mobile_width_height,
                w_event_create_yn: setting.w_event_create_yn,
                h_event_create_yn : setting.h_event_create_yn,
                iframe_html_name : setting.iframe_html_name,
                capture_pc_width : capture_pc_width,
                capture_pc_height : capture_pc_height,
                capture_mobile_width : capture_mobile_width,
                capture_mobile_height : capture_mobile_height,
            }];
        }

        this.setState({banner_slide_setting: banner_slide_setting});

        // 화면 회전의 경우 회전된 사이즈로 다시 사이즈 설정
        if(resize){
            setTimeout(() => this.setBannerSize(), 100);
        }
    }

    /**
     * 배너 목록에 저장하기 버튼 클릭 : 배너 저장
     */
    saveBanner = () => {
        let event_seq = this.state.event_seq_list[this.state.slick_idx];

        if(this.state.save_banner_list.includes(event_seq)){
            alert("이미 저장되었습니다.");
            return false;
        }

        const sendForm = {
            event_seq : event_seq,
        };
        http.get("/rest/common/roboImage/event/save", {params:sendForm})
        .then(res => {
            if(res.data.code == "200"){
                let {save_banner_list} = this.state;
                save_banner_list.push(event_seq);
                this.setState({save_banner_list : save_banner_list,})
                alert("저장되었습니다.")
            } else{
                alert("배너 저장에 실패하였습니다.");
            }
        })
        .catch(error=> {
            alert("배너 저장에 실패하였습니다.");
        });
    }

    /** 이벤트 idx로 이벤트 세팅 찾기
     * 
     */
    getEventSetting = (event_idx) => {
        const {event_length_list} = this.state;
        let one_click_setting_idx;

        for(let i=0;i<event_length_list.length;i++){
            event_idx -= event_length_list[i];
            if(event_idx < 0){
                one_click_setting_idx = i;
                break;
            }
        }
        return one_click_setting_idx;
    }


    render () {
		const {className, onClose} = this.props;
        const {event_seq_list, banner_slide_setting} = this.state;
        const only_mobile_ratio = !this.state.pc_width_height && this.state.mobile_width_height;
        return (
            <>
                <PopupFrameV2
                    className={["create_banner_preview", className].join(' ')}
                    maxWidth={only_mobile_ratio ? "500px" :"1000px"}
                    width={"100%"}
                    onClose={onClose}
                >
                    <div className={styles.popup_title}>
                        배너 만들기 완료!
                    </div>
                    <div className={styles.popup_content}>
                        배너 디자인이 완성되었어요.<br/>배너 목록에 저장된 디자인을 확인 또는 수정해 보세요.
                    </div>
                    <div className={styles.popup_preview_area}>
                        <Slider
                            slidesToShow={1}
                            slidesToScroll={1}
                            initialSlide={0}
                            dots={false}
                            infinite={false}
                            adaptiveHeight={true}
                            beforeChange={(old_event_idx, new_event_idx) =>{
                                this.setState({slick_idx: new_event_idx});
                            }}
                        >
                            {banner_slide_setting.length != 0 && event_seq_list.map((event_seq, idx) => (
                                <div key={event_seq}>
                                    <div className={styles.banner_slide_area}></div>
                                    <div className={styles.preview_title}>{`${banner_slide_setting[this.getEventSetting(idx)].iframe_html_name} / ${idx+1}번 배너`}</div>
                                    <div id={"banner_area"} className={styles.popup_preview}>
                                        <div className={[styles.robo_img_pc_banner, !(banner_slide_setting[this.getEventSetting(idx)].pc_width_height && banner_slide_setting[this.getEventSetting(idx)].w_event_create_yn == "Y") && styles.hidden_area].join(' ')} style={{width: banner_slide_setting[this.getEventSetting(idx)].pc_banner_width}}>
                                            <div className={styles.preview_ratio_title}>{`가로형 / ${banner_slide_setting[this.getEventSetting(idx)].capture_pc_width}x${banner_slide_setting[this.getEventSetting(idx)].capture_pc_height}`}</div>
                                            <div style={{width: banner_slide_setting[this.getEventSetting(idx)].pc_banner_width, height : banner_slide_setting[this.getEventSetting(idx)].pc_banner_height}}>
                                                <object
                                                    type={"text/html"}
                                                    data={`https://robomd.io/banner_sample.html?event_seq=${event_seq}&platform_type=p&mirror_type=18002&req_user_seq=-5`}
                                                    style={{width: "100%", height : "100%", background:'#FFF'}}
                                                ></object>
                                            </div>
                                        </div>
                                        <div className={[styles.robo_img_mobile_banner, !(banner_slide_setting[this.getEventSetting(idx)].mobile_width_height && banner_slide_setting[this.getEventSetting(idx)].h_event_create_yn == "Y") && styles.hidden_area].join(' ')} style={only_mobile_ratio ? {width : "100%"}: {width: banner_slide_setting[this.getEventSetting(idx)].mobile_banner_width}}>
                                            <div className={styles.preview_ratio_title}>{`세로형 / ${banner_slide_setting[this.getEventSetting(idx)].capture_mobile_width}x${banner_slide_setting[this.getEventSetting(idx)].capture_mobile_height}`}</div>
                                            <div style={{width: banner_slide_setting[this.getEventSetting(idx)].mobile_banner_width, height : banner_slide_setting[this.getEventSetting(idx)].mobile_banner_height}}>
                                            <object
                                                    type={"text/html"}
                                                    data={`https://robomd.io/banner_sample.html?event_seq=${event_seq}&platform_type=m&mirror_type=18002&req_user_seq=-5`}
                                                    style={{width: "100%", height : "100%", background:'#FFF'}}
                                                ></object>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                    {event_seq_list.length > 0 &&
                        <div className={styles.btn_area}>
                            <Button
                                onClick={() => this.saveBanner()}
                                width={"210px"}
                                height={"35px"}
                                fontSize={"13px"}
                            >
                                배너 목록에 저장하기
                            </Button>
                        </div>}
                </PopupFrameV2>
            </>
        )
    };
}

CreateBannerPreview.defaultProps = {
    className : "",
    one_click_event_list:[],
    onClose : function(){},
}

export default CreateBannerPreview;
import React from 'react';
import axios from 'axios';
import http from '../modules/api';
import $ from 'jquery';

import {BrowserRouter, Route, Switch, HashRouter} from "react-router-dom";

import {EventList as EventListShop} from "../autoEvent/EventList";
import {ServiceShopAdd} from "./ServiceShopAdd";
import {EventForm_V2} from "../autoEvent/EventForm_V2";
import {EventForm_Promotion_V2} from "../autoEvent/EventForm_Promotion_V2";
import {EventSetting} from "../autoEvent/EventSetting";
import {EventSettingList} from "../autoEvent/EventSettingList";
import {OrderAmountExpect} from "./OrderAmountExpect";

var page_redirect_type = "";
var service_type = "";
var is_loaded = false
var is_free_finish = false
var is_order = false

export class MenuPipeLine extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			is_loaded : false,
		}
	}

	componentDidUpdate() {
		is_loaded = false;
		page_redirect_type = ""
		is_loaded = false
		is_free_finish = false
		is_order = false
		if(!is_loaded) {
			this.stroeOrSettingCnt();
		}
	}

	componentDidMount() {
		if(!is_loaded) {
			this.stroeOrSettingCnt();
		}
	}


    stroeOrSettingCnt() {
		// alert(is_free_finish + "<- is_free_finish")
		// alert("=========")
		// alert(window.location.href.indexOf("/shop/cafe24/event/setting"))
		// alert("=========")
        http.get("/rest/shop/stroeOrSettingCnt")
        .then(res => {
            if (res.data.stroeOrSettingCnt != null) {
				page_redirect_type = res.data.stroeOrSettingCnt["page_redirect_type"];
				service_type = res.data.stroeOrSettingCnt["service_type"];
				is_loaded = true;
				if(page_redirect_type == "10001") {
					alert("먼저 상점을 추가해 주세요.");
					window.location.href="/shop/serviceShopAdd?service_type="+service_type+"&step=2"
					return false;
				} 	else if (window.location.href.indexOf("/shop/cafe24/event/setting") < 0) {
						if(page_redirect_type == "10002") {
							alert("로보 배너 설정을 먼저 진행해주세요.");
							window.location.href="/shop/cafe24/event/setting/list"
							return false;
						}
						if (page_redirect_type == "10000") {
							// alert(res.data.freeDatePeriod.is_finish == "1")
							if(res.data.freeDatePeriod.is_finish == "1") {
								if (Number(res.data.orderCnt.order_cnt) > 0) {
									is_order = true;
									return false;
								}
								if(window.location.href.indexOf("/shop/orderAmountExpect") == -1) {
									alert("무료 체험이 종료 되었습니다.");
									window.location.href="/shop/orderFreePlan"
									return false;
								} else {
									alert("첫 결제를 위한 페이지로 이동합니다.");
									window.location.href="/shop/orderFreePlan"
									return false;
								}
								is_free_finish = true;
							} else {
								if (Number(res.data.orderCnt.order_cnt) > 0) {
									is_free_finish = true;
									is_order = true;
								} else {
									if(window.location.href.indexOf("/shop/orderAmountExpect") >= 0) {
										// alert("첫 결제를 위한 페이지로 이동합니다.");
										window.location.href="/shop/orderFreePlan"
									} 
									// else {
									// 	if(window.location.href.indexOf("/shop/cafe24/event/setting") < 0) {
									// 		window.location.href="/shop/cafe24/orderFreePlan"
									// 	}
									// }
									is_free_finish = false;
									is_order = false;
									// alert(is_free_finish + "<- is_free_finish")
									// alert(is_free_finish);
									// const url = new URL(window.location.href);
									// this.props.history.push(url.pathname);
								}
							}
						} 
					} else {
						is_free_finish = true; 
						page_redirect_type = "10000";
					}
            }
			// alert(is_free_finish + "<- is_free_finish2")
			this.render();
        })
        .catch(error => {
            alert(error); 
        });
    }

	render() {
		// alert(is_loaded + "<- is_loaded")
		if (is_loaded) {
			if (page_redirect_type == "10001") {
				return (
					<>
					</>
				)
			} else if (page_redirect_type == "10002") {
				return (
					<>
					</>
				)
			} else {
				if (!is_free_finish) {
					if (window.location.href.indexOf("/shop/cafe24/event/list") >= 0) {
						return (
							<>
							<Switch>
								<Route component={EventListShop}/>
							</Switch>
							</>
						)
					} 
					else if (window.location.href.indexOf("/shop/cafe24/event/form") >= 0) {
						return (
							<Route component={EventForm_V2}/>
						)
					}
					else if (window.location.href.indexOf("/shop/cafe24/promotion/form") >= 0) {
						return (
							<Route component={EventForm_Promotion_V2}/>
						)
					}
					else if (window.location.href.indexOf("/shop/cafe24/event/setting/list") >= 0) {
						return (
							<Route component={EventSettingList}/>
						)
					}
					else if (window.location.href.indexOf("/shop/cafe24/event/setting/popup") >= 0) {
						return (
							<Route component={EventSetting}/>
						)
					}
					else if (window.location.href.indexOf("/shop/cafe24/event/setting") >= 0) {
						return (
							<Route component={EventSetting}/>
						)
					}
					else if (window.location.href.indexOf("/shop/orderAmountExpect") >= 0) {
						return (
							<Route component={OrderAmountExpect}/>
						)
					} else {
						return (
							<>
							</>
						)
					} 
				} else {
					if(is_order == true) {
						if (window.location.href.indexOf("/shop/cafe24/event/list") >= 0) {
							return (
								<Route component={EventListShop}/>
							)
						} 
						else if (window.location.href.indexOf("/shop/cafe24/event/form") >= 0) {
							return (
								<Route component={EventForm_V2}/>
							)
						}			
						else if (window.location.href.indexOf("/shop/cafe24/promotion/form") >= 0) {
							return (
								<Route component={EventForm_Promotion_V2}/>
							)
						}			
						else if (window.location.href.indexOf("/shop/cafe24/event/setting/list") >= 0) {
							return (
								<Route component={EventSettingList}/>
							)
						}			
						else if (window.location.href.indexOf("/shop/cafe24/event/setting") >= 0) {
							return (
								<Route component={EventSetting}/>
							)
						}			
						else if (window.location.href.indexOf("/shop/orderAmountExpect") >= 0) {
							return (
								<Route component={OrderAmountExpect}/>
							)
						} else {
							return (
								<>
								</>
							)
						}			
					} else {
						if (window.location.href.indexOf("/shop/cafe24/event/setting/list") >= 0) {
							return (
								<Switch>
									<Route component={EventSettingList}/>
								</Switch>
							)
						} 
						else if (window.location.href.indexOf("/shop/cafe24/event/setting") >= 0) {
							return (
								<Switch>
									<Route component={EventSetting}/>
								</Switch>
							)
						} else {
							if (window.location.href.indexOf("/shop/cafe24/event/list") >= 0) {
								return (
									<>
									<Switch>
										<Route component={EventListShop}/>
									</Switch>
									</>
								)
							} 
							else if (window.location.href.indexOf("/shop/cafe24/event/form") >= 0) {
								return (
									<Route component={EventForm_V2}/>
								)
							}
							else if (window.location.href.indexOf("/shop/cafe24/promotion/form") >= 0) {
								return (
									<Route component={EventForm_Promotion_V2}/>
								)
							}
							else if (window.location.href.indexOf("/shop/cafe24/event/setting/list") >= 0) {
								return (
									<Route component={EventSettingList}/>
								)
							}
							else if (window.location.href.indexOf("/shop/cafe24/event/setting") >= 0) {
								return (
									<Route component={EventSetting}/>
								)
							}
							else if (window.location.href.indexOf("/shop/orderAmountExpect") >= 0) {
								return (
									<Route component={OrderAmountExpect}/>
								)
							} else {
								return (
									<>
									</>
								)
							} 
						}
					}
				}
			}
		} else {
				if (window.location.href.indexOf("/shop/cafe24/event/list") >= 0) {
					return (
						<>
						<Switch>
							<Route component={EventListShop}/>
						</Switch>
						</>
					)
				} 
				else if (window.location.href.indexOf("/shop/cafe24/event/form") >= 0) {
					return (
						<Route component={EventForm_V2}/>
					)
				}
				else if (window.location.href.indexOf("/shop/cafe24/promotion/form") >= 0) {
					return (
						<Route component={EventForm_Promotion_V2}/>
					)
				}
				else if (window.location.href.indexOf("/shop/cafe24/event/setting/list") >= 0) {
					return (
						<Route component={EventSettingList}/>
					)
				}
				else if (window.location.href.indexOf("/shop/cafe24/event/setting") >= 0) {
					return (
						<Route component={EventSetting}/>
					)
				}
				else if (window.location.href.indexOf("/shop/orderAmountExpect") >= 0) {
					return (
						<Route component={OrderAmountExpect}/>
					)
				} else {
					return (
						<>
						</>
					)
				} 
		}
			
			// return (
			// 	<>
			// 		{/* {
			// 			is_loaded == true
			// 			?
			// 				page_redirect_type == "10001"
			// 					?   <>
			// 						</>
			// 					:   page_redirect_type == "10002"
			// 						?
			// 							<>
			// 							</>
			// 						: 	is_free_finish == false
			// 							?
			// 								window.location.href.indexOf("/shop/cafe24/event/list") >= 0
			// 								?   <Route component={EventListShop}/>
			// 								// ?   <>{alert("EventListShop")}</>
			// 								:   window.location.href.indexOf("/shop/cafe24/event/form") >= 0
			// 									?	<Route component={EventForm_V2}/>
			// 									:	window.location.href.indexOf("/shop/cafe24/promotion/form") >= 0
			// 										?	<Route component={EventForm_Promotion_V2}/>
			// 										:	window.location.href.indexOf("/shop/cafe24/event/setting/list") >= 0
			// 											?	<Route component={EventSettingList}></Route>
			// 											// ?	<>{alert("hjffdlqjfiejfio")}</>
			// 											:	window.location.href.indexOf("/shop/cafe24/event/setting") >= 0
			// 												?	<Route component={EventSetting}/>
			// 												:	window.location.href.indexOf("/shop/cafe24/orderAmountExpect") >= 0
			// 													?	<>
			// 														</>
			// 													: 	<>
			// 														</>
			// 							:	is_order == true
			// 									?	window.location.href.indexOf("/shop/cafe24/event/list") >= 0
			// 										?   <Route component={EventListShop}/>
			// 										:   window.location.href.indexOf("/shop/cafe24/event/form") >= 0
			// 											?	<Route component={EventForm_V2}/>
			// 											:	window.location.href.indexOf("/shop/cafe24/promotion/form") >= 0
			// 												?	<Route component={EventForm_Promotion_V2}/>
			// 												:	window.location.href.indexOf("/shop/cafe24/event/setting/list") >= 0
			// 													?	<Route component={EventSettingList}></Route>
			// 													:	window.location.href.indexOf("/shop/cafe24/event/setting") >= 0
			// 														?	<Route component={EventSetting}/>
			// 														:	window.location.href.indexOf("/shop/cafe24/orderAmountExpect") >= 0
			// 															?	<Route component={OrderAmountExpect}/>
			// 															: 	<>
			// 																</>
			// 									:	window.location.href.indexOf("/shop/cafe24/event/setting/list") >= 0
			// 											?	<Route component={EventSettingList}/>
			// 											:	window.location.href.indexOf("/shop/cafe24/event/setting") >= 0
			// 												?	<Route component={EventSetting}/>
			// 												:	<>
			// 													</>
			// 			:   window.location.href.indexOf("/shop/cafe24/event/list") >= 0
			// 						?   <Route component={EventListShop}/>
			// 						:   window.location.href.indexOf("/shop/cafe24/event/form") >= 0
			// 							?	<Route component={EventForm_V2}/>
			// 							:	window.location.href.indexOf("/shop/cafe24/promotion/form") >= 0
			// 								?	<Route component={EventForm_Promotion_V2}/>
			// 								:	window.location.href.indexOf("/shop/cafe24/event/setting/list") >= 0
			// 									?	<Route component={EventSettingList}></Route>
			// 									:	window.location.href.indexOf("/shop/cafe24/event/setting") >= 0
			// 										?	<Route component={EventSetting}/>
			// 										:	window.location.href.indexOf("/shop/cafe24/orderAmountExpect") >= 0
			// 											?	<Route component={OrderAmountExpect}/>
			// 											: 	<>
			// 												</>
			// 		} */}
			// 		{/* <Route component={EventListShop}/> */}
			// 	</>
			// );
		// } else {
		// 	return (
		// 		<>
		// 			<Route component={EventSettingList}/>
		// 		</>
		// 	)
		// }	
	}
}

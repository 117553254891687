import React from "react";
import '../../css/style.css';
import '../../css/autoEvent/EventSettingFileName.css';
import RadioButton from "../modules/common/RadioButton";
import SelectBoxV2 from "../modules/common/SelectBoxV2";
import http from "../modules/api";
import $ from 'jquery';
import DatePicker from "react-datepicker";
import {ko} from "date-fns/esm/locale";
import SaveDialog from "../modules/dialogs/SaveDialog";
import * as StyleUtil from "../modules/utils/StyleUtil";
import {dateFormater} from "../modules/utils/StringUtil";
import * as StringUtil from "../modules/utils/StringUtil";
import WarningDialog from "../modules/dialogs/WarningDialog";

let window_scrollTop;

class EventSettingFileName extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iframe_page_id : this.props.iframePageId,
            iframe_html_id : this.props.iframeHtmlId,
            file_name_setting_yn : this.props.fileNameSettingYn,

            // 파일명 규칙 목록
            w_file_name_setting_list : [],
            h_file_name_setting_list : [],

            // 파일명 규칙 선택 목록
            file_name_rules_list : [],
            file_name_banner_size_list : [],
            file_name_date_setting_list : [],
            file_name_date_format_list : [],
            file_name_number_format_list : [],

            rules_list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],

            // 가로형 배너 파일명 규칙
            w_rules_1 : {code_flag: false, code_cd: "", code_name: "", second_code_cd: "", second_code_name: "", third_code_cd: "", third_code_name: "", rules_date: null, rules_name: "", rules_auto_num: ""},
            w_rules_2 : {code_flag: false, code_cd: "", code_name: "", second_code_cd: "", second_code_name: "", third_code_cd: "", third_code_name: "", rules_date: null, rules_name: "", rules_auto_num: ""},
            w_rules_3 : {code_flag: false, code_cd: "", code_name: "", second_code_cd: "", second_code_name: "", third_code_cd: "", third_code_name: "", rules_date: null, rules_name: "", rules_auto_num: ""},
            w_rules_4 : {code_flag: false, code_cd: "", code_name: "", second_code_cd: "", second_code_name: "", third_code_cd: "", third_code_name: "", rules_date: null, rules_name: "", rules_auto_num: ""},
            w_rules_5 : {code_flag: false, code_cd: "", code_name: "", second_code_cd: "", second_code_name: "", third_code_cd: "", third_code_name: "", rules_date: null, rules_name: "", rules_auto_num: ""},
            w_rules_6 : {code_flag: false, code_cd: "", code_name: "", second_code_cd: "", second_code_name: "", third_code_cd: "", third_code_name: "", rules_date: null, rules_name: "", rules_auto_num: ""},
            w_rules_7 : {code_flag: false, code_cd: "", code_name: "", second_code_cd: "", second_code_name: "", third_code_cd: "", third_code_name: "", rules_date: null, rules_name: "", rules_auto_num: ""},
            w_rules_8 : {code_flag: false, code_cd: "", code_name: "", second_code_cd: "", second_code_name: "", third_code_cd: "", third_code_name: "", rules_date: null, rules_name: "", rules_auto_num: ""},
            w_rules_9 : {code_flag: false, code_cd: "", code_name: "", second_code_cd: "", second_code_name: "", third_code_cd: "", third_code_name: "", rules_date: null, rules_name: "", rules_auto_num: ""},
            w_rules_10 : {code_flag: false, code_cd: "", code_name: "", second_code_cd: "", second_code_name: "", third_code_cd: "", third_code_name: "", rules_date: null, rules_name: "", rules_auto_num: ""},

            // 세로형 배너 파일명 규칙
            h_rules_1 : {code_flag: false, code_cd: "", code_name: "", second_code_cd: "", second_code_name: "", third_code_cd: "", third_code_name: "", rules_date: null, rules_name: "", rules_auto_num: ""},
            h_rules_2 : {code_flag: false, code_cd: "", code_name: "", second_code_cd: "", second_code_name: "", third_code_cd: "", third_code_name: "", rules_date: null, rules_name: "", rules_auto_num: ""},
            h_rules_3 : {code_flag: false, code_cd: "", code_name: "", second_code_cd: "", second_code_name: "", third_code_cd: "", third_code_name: "", rules_date: null, rules_name: "", rules_auto_num: ""},
            h_rules_4 : {code_flag: false, code_cd: "", code_name: "", second_code_cd: "", second_code_name: "", third_code_cd: "", third_code_name: "", rules_date: null, rules_name: "", rules_auto_num: ""},
            h_rules_5 : {code_flag: false, code_cd: "", code_name: "", second_code_cd: "", second_code_name: "", third_code_cd: "", third_code_name: "", rules_date: null, rules_name: "", rules_auto_num: ""},
            h_rules_6 : {code_flag: false, code_cd: "", code_name: "", second_code_cd: "", second_code_name: "", third_code_cd: "", third_code_name: "", rules_date: null, rules_name: "", rules_auto_num: ""},
            h_rules_7 : {code_flag: false, code_cd: "", code_name: "", second_code_cd: "", second_code_name: "", third_code_cd: "", third_code_name: "", rules_date: null, rules_name: "", rules_auto_num: ""},
            h_rules_8 : {code_flag: false, code_cd: "", code_name: "", second_code_cd: "", second_code_name: "", third_code_cd: "", third_code_name: "", rules_date: null, rules_name: "", rules_auto_num: ""},
            h_rules_9 : {code_flag: false, code_cd: "", code_name: "", second_code_cd: "", second_code_name: "", third_code_cd: "", third_code_name: "", rules_date: null, rules_name: "", rules_auto_num: ""},
            h_rules_10 : {code_flag: false, code_cd: "", code_name: "", second_code_cd: "", second_code_name: "", third_code_cd: "", third_code_name: "", rules_date: null, rules_name: "", rules_auto_num: ""},
        }
    }

    componentDidMount() {
        this.getFileNameRulesList();
        this.getFileNameSettingList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        $(document).ready(function(){
            // 셀렉트 박스 닫기
            $(".select_area").on("click", function (e) {
                if (!$(e.target).hasClass("select_option")) {
                    $("#palleteBack").trigger("click");
                }
            });
        });
    }

    componentWillUnmount() {
    }

    changeFileNameSettingYn(e) {
        this.setState({file_name_setting_yn: e.target.value});
    }

    // 파일명 규칙 선택 목록 조회
    getFileNameRulesList() {
        http.get("/rest/shop/event/setting/file-name/rules")
            .then(res => {
                if (res.data.file_name_rules_list) {
                    this.setState({
                        file_name_rules_list: res.data.file_name_rules_list,
                        file_name_banner_size_list: res.data.file_name_banner_size_list,
                        file_name_date_setting_list: res.data.file_name_date_setting_list,
                        file_name_date_format_list: res.data.file_name_date_format_list,
                        file_name_number_format_list: res.data.file_name_number_format_list,
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    // 파일명 규칙 설정 조회
    getFileNameSettingList() {
        http.get("/rest/shop/event/setting/file-name/setting?iframe_page_id="+this.state.iframe_page_id+"&iframe_html_id="+this.state.iframe_html_id)
            .then(res => {
                if (res.data.code == 401) {
                    alert(res.data.msg);
                    window.location.href = "/logout";
                }

                if(res.data.file_name_setting_list == null || res.data.file_name_setting_list.length <= 0) {
                    this.getFileNameDefaultSetting();

                }else {
                    this.setState({
                        w_file_name_setting_list : res.data.w_file_name_setting_list,
                        h_file_name_setting_list : res.data.h_file_name_setting_list,
                    });

                    res.data.file_name_setting_list.map((obj, idx) => {
                        let type = obj.banner_type;

                        this.setState(prevState => ({
                            [type + "_rules_" + obj.rules_number]: {
                                ...prevState[type + "_rules_" + obj.rules_number],
                                code_flag: true,
                                code_cd: obj.code_cd,
                                code_name: obj.code_name,
                                second_code_cd: obj.second_code_cd,
                                second_code_name: obj.second_code_name,
                                third_code_cd: obj.third_code_cd,
                                third_code_name: obj.third_code_name,
                                rules_date: new Date(obj.rules_date),
                                rules_name: obj.rules_name,
                                rules_auto_num: obj.rules_auto_num
                            }
                        }));
                    });
                }

            })
            .catch(error => {
                console.log(error)
            });
    }

    getFileNameDefaultSetting() {
        http.get("/rest/shop/event/setting/file-name/default")
            .then(res => {
                res.data.file_name_default_setting_list.map((obj, idx) => {
                    let type = obj.banner_type;

                    this.setState(prevState => ({
                        [type + "_rules_" + obj.rules_number]: {
                            ...prevState[type + "_rules_" + obj.rules_number],
                            code_flag: true,
                            code_cd: obj.code_cd,
                            code_name: obj.code_name,
                            second_code_cd: obj.second_code_cd,
                            second_code_name: obj.second_code_name,
                            third_code_cd: obj.third_code_cd,
                            third_code_name: obj.third_code_name,
                            rules_date: new Date(obj.rules_date),
                            rules_name: obj.rules_name,
                            rules_auto_num: obj.rules_auto_num
                        }
                    }));

                });

                this.setState({
                    w_file_name_setting_list: res.data.w_file_name_default_setting_list,
                    h_file_name_setting_list: res.data.h_file_name_default_setting_list,
                });

            })
            .catch(error => {
                console.log(error);
            });
    }

    selectFileNameRules(text, value, id) {
        const type = id.substr(0, 1);
        const number = id.substring(19);

        let _second_code_cd = "";
        let _second_code_name = "";
        let _third_code_cd = "";
        let _third_code_name = "";

        if(value == "63000"){
            _second_code_cd = this.state.file_name_banner_size_list[0].code_cd;
            _second_code_name = this.state.file_name_banner_size_list[0].code_name;
        }else if(value == "64000"){
            _second_code_cd = this.state.file_name_date_setting_list[0].code_cd;
            _second_code_name = this.state.file_name_date_setting_list[0].code_name;
            _third_code_cd = this.state.file_name_date_format_list[0].code_cd;
            _third_code_name = this.state.file_name_date_format_list[0].code_name;
        }else if(value == "66000"){
            _second_code_cd = this.state.file_name_number_format_list[0].code_cd;
            _second_code_name = this.state.file_name_number_format_list[0].code_name;
        }

        this.setState(prevState => ({
            [type + "_rules_" + number]: {
                ...prevState[type + "_rules_" + number],
                code_flag: true,
                code_cd: value,
                code_name: text,
                second_code_cd: _second_code_cd,
                second_code_name: _second_code_name,
                third_code_cd: _third_code_cd,
                third_code_name: _third_code_name,
                rules_date: null,
                rules_name: "",
                rules_auto_num: "",
            }
        }));

        this.state[type + "_file_name_setting_list"].map((obj, idx) => {
            if(idx+1 == number){
                obj.code_cd = value;
                obj.code_name = text;
                obj.second_code_cd = _second_code_cd;
                obj.second_code_name = _second_code_name;
                obj.third_code_cd = _third_code_cd;
                obj.third_code_name = _third_code_name;
                obj.rules_date = null;
                obj.rules_name = "";
                obj.rules_auto_num = "";
            }
        });
    }

    selectFileNameSecondDepth(text, value, id) {
        const type = id.substr(0, 1);
        const number = id.substring(19);

        let _third_code_cd = "";
        let _third_code_name = "";
        let _rules_date = "";
        let _rules_name = "";
        let _rules_auto_num = "";

        if(value == "63003"){           // PC
            _rules_name = this.state.file_name_banner_size_list[2].code_name;
        }else if(value == "63004"){     // MO
            _rules_name = this.state.file_name_banner_size_list[3].code_name;
        }else if(value == "63005"){     // pc
            _rules_name = this.state.file_name_banner_size_list[4].code_name;
        }else if(value == "63006"){     // m
            _rules_name = this.state.file_name_banner_size_list[5].code_name;
        }else if(value == "63007"){     // mobile
            _rules_name = this.state.file_name_banner_size_list[6].code_name;
        }else if(value == "63008"){     // 가로
            _rules_name = this.state.file_name_banner_size_list[7].code_name;
        }else if(value == "63009"){     // 세로
            _rules_name = this.state.file_name_banner_size_list[8].code_name;
        }else if(value == "64004"){     // 날짜 직접 선택
            _rules_date = new Date();
        }else if(value == "66001"){     // 1부터 시작
            _rules_auto_num = "1";
        }else if(value == "66002"){     // 01부터 시작
            _rules_auto_num = "01";
        }else if(value == "66003"){     // 001부터 시작
            _rules_auto_num = "001";
        }else if(value == "66004"){     // 0001부터 시작
            _rules_auto_num = "0001";
        }

        if(Number(value) > 64000 && Number(value) < 65000){     // 날짜 선택시 기본 포맷 YYYYMMDD
            _third_code_cd = this.state.file_name_date_format_list[0].code_cd;
            _third_code_name = this.state.file_name_date_format_list[0].code_name;
        }

        this.setState(prevState => ({
            [type + "_rules_" + number]: {
                ...prevState[type + "_rules_" + number],
                second_code_cd: value,
                second_code_name: text,
                third_code_cd: _third_code_cd,
                third_code_name: _third_code_name,
                rules_date: _rules_date,
                rules_name: _rules_name,
                rules_auto_num: _rules_auto_num
            }
        }));

        this.state[type + "_file_name_setting_list"].map((obj, idx) => {
            if(idx+1 == number){
                obj.second_code_cd = value;
                obj.second_code_name = text;
                obj.third_code_cd = _third_code_cd;
                obj.third_code_name = _third_code_name;
                obj.rules_date = _rules_date;
                obj.rules_name = _rules_name;
                obj.rules_auto_num = _rules_auto_num;
            }
        });
    }

    selectFileNameThirdDepth(text, value, id) {
        const type = id.substr(0, 1);
        const number = id.substring(19);

        this.setState(prevState => ({
            [type + "_rules_" + number]: {
                ...prevState[type + "_rules_" + number],
                third_code_cd: value,
                third_code_name: text,
                // rules_name: "",
            }
        }));

        this.state[type + "_file_name_setting_list"].map((obj, idx) => {
            if(idx+1 == number){
                obj.third_code_cd = value;
                obj.third_code_name = text;
                // obj.rules_name = "";
            }
        });
    }

    closeRulesCardClick(e, type, number) {
        if(this.state[type + "_file_name_setting_list"].length <= 1) {
            window_scrollTop = StyleUtil.onPopupNoneScroll(window_scrollTop);
            this.setState({
                warning_dialog_show_state : true,
                warning_dialog_content : "마지막 규칙을 제거할 수 없어요. <br/>하나 이상의 규칙을 필수로 설정해야 해요.",
                warning_dialog_button_list: [
                    {
                        title: "확인",
                        type: "1",
                        event_method: () => {
                            this.setState({ warning_dialog_show_state: false });
                            StyleUtil.clearPopupNoneScroll(window_scrollTop);
                        }
                    }
                ],
            });
            return;
        }

        // 초기화
        this.state.rules_list.map((obj, idx) => {
            this.setState(prevState => ({
                [type + "_rules_" + (idx+1)] : {
                    ...prevState[type + "_rules_" + (idx+1)],
                    code_flag : false,
                    code_cd : "",
                    code_name : "",
                    second_code_cd : "",
                    second_code_name : "",
                    third_code_cd : "",
                    third_code_name : "",
                    rules_date : null,
                    rules_name : "",
                    rules_auto_num: "",
                }
            }));
        });

        const list = this.state[type + "_file_name_setting_list"].filter((data) => {
            return data.rules_number != number
        });

        list.map((obj, idx) => {
            obj.rules_number = (idx+1).toString();

            this.setState(prevState => ({
                [type + "_rules_" + (idx+1)]: {
                    ...prevState[type + "_rules_" + (idx+1)],
                    code_flag: true,
                    code_cd: obj.code_cd,
                    code_name: obj.code_name,
                    second_code_cd: obj.second_code_cd,
                    second_code_name: obj.second_code_name,
                    third_code_cd: obj.third_code_cd,
                    third_code_name: obj.third_code_name,
                    rules_date: new Date(obj.rules_date),
                    rules_name: obj.rules_name,
                    rules_auto_num: obj.rules_auto_num,
                }
            }));
        });

        this.setState({[type + "_file_name_setting_list"] : list});
    }

    addRulesBtnClick(e, type) {
        for(let i=0;i<=this.state.rules_list.length;i++) {
            if(i>=this.state.rules_list.length){
                window_scrollTop = StyleUtil.onPopupNoneScroll(window_scrollTop);
                this.setState({
                    warning_dialog_show_state : true,
                    warning_dialog_content : "규칙은 " + this.state.rules_list.length + "개까지 설정할 수 있어요.",
                    warning_dialog_button_list: [
                        {
                            title: "확인",
                            type: "1",
                            event_method: () => {
                                this.setState({ warning_dialog_show_state: false });
                                StyleUtil.clearPopupNoneScroll(window_scrollTop);
                            }
                        }
                    ],
                });
                return;
            }

            if(!this.state[type + "_rules_" + (i+1)].code_flag){
                this.setState({
                    [type + "_rules_" + (i+1)] : {
                        code_flag : true,
                        code_cd : this.state.file_name_rules_list[0].code_cd,
                        code_name : this.state.file_name_rules_list[0].code_name,
                    }
                });

                this.state[type + "_file_name_setting_list"].push({
                    iframe_page_id : this.state.iframe_page_id,
                    iframe_html_id : this.state.iframe_html_id,
                    banner_type : type,
                    rules_number : (i+1).toString(),
                    code_cd : this.state.file_name_rules_list[0].code_cd,
                    code_name : this.state.file_name_rules_list[0].code_name,
                });
                return;
            }
        }
    }

    saveFileNameSetting(e) {
        this.state.w_file_name_setting_list.map((obj, idx) => {
            obj.rules_date = StringUtil.dateFormater(obj.rules_date);

            if(obj.second_code_cd == "66001"){          // 1부터 시작
                obj.rules_auto_num = "1";
            }else if(obj.second_code_cd == "66002"){    // 01부터 시작
                obj.rules_auto_num = "01";
            }else if(obj.second_code_cd == "66003"){    // 001부터 시작
                obj.rules_auto_num = "001";
            }else if(obj.second_code_cd == "66004"){    // 0001부터 시작
                obj.rules_auto_num = "0001";
            }else if(obj.second_code_cd == "66010"){    // 시작 번호 직접 입력
                obj.rules_auto_num = obj.rules_name;
            }
        });

        this.state.h_file_name_setting_list.map((obj, idx) => {
            obj.rules_date = StringUtil.dateFormater(obj.rules_date);

            if(obj.second_code_cd == "66001"){          // 1부터 시작
                obj.rules_auto_num = "1";
            }else if(obj.second_code_cd == "66002"){    // 01부터 시작
                obj.rules_auto_num = "01";
            }else if(obj.second_code_cd == "66003"){    // 001부터 시작
                obj.rules_auto_num = "001";
            }else if(obj.second_code_cd == "66004"){    // 0001부터 시작
                obj.rules_auto_num = "0001";
            }else if(obj.second_code_cd == "66010"){    // 시작 번호 직접 입력
                obj.rules_auto_num = obj.rules_name;
            }
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        let body = {
            iframe_page_id: this.state.iframe_page_id,
            iframe_html_id: this.state.iframe_html_id,
            file_name_setting_yn: this.state.file_name_setting_yn,
            w_file_name_setting_list: this.state.w_file_name_setting_list,
            h_file_name_setting_list: this.state.h_file_name_setting_list
        }

        http.post("/rest/shop/event/setting/file-name/save", body, config)
            .then(res => {
                if(res.data.code == "200"){
                    window_scrollTop = StyleUtil.onPopupNoneScroll(window_scrollTop);
                    this.setState({
                        save_confirm_dialog_show_state : true,
                        save_confirm_dialog_content : "저장되었습니다.",
                        save_confirm_dialog_button_list: [
                            {
                                title: "확인",
                                type: "1",
                                event_method: () => {
                                    this.setState({ save_confirm_dialog_show_state: false });
                                    StyleUtil.clearPopupNoneScroll(window_scrollTop);
                                }
                            }
                        ],
                    });

                }else {
                    alert(res.data.msg);
                }

            })
            .catch(error => {
                console.log(error)
            });
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    handleFileNameRulesDateChange = (date, type, number) => {
        this.setState(prevState => ({
            [type + "_rules_" + number]: {
                ...prevState[type + "_rules_" + number],
                rules_date: date,
            }
        }));

        this.state[type + "_file_name_setting_list"].map((obj, idx) => {
            if(idx+1 == number){
                obj.rules_date = date;
            }
        });
    }

    handleInputFileNameRulesChange = (e, type, number) => {
        let _file_name = this.replaceFilename(e.target.value);

        this.setState(prevState => ({
            [type + "_rules_" + number]: {
                ...prevState[type + "_rules_" + number],
                rules_name: _file_name,
            }
        }));

        this.state[type + "_file_name_setting_list"].map((obj, idx) => {
            if(idx+1 == number){
                obj.rules_name = _file_name;
            }
        });
    }

    handleInputFileNameRulesNumberChange = (e, type, number) => {
        let _file_name = this.replaceFilenameNumber(e.target.value);

        this.setState(prevState => ({
            [type + "_rules_" + number]: {
                ...prevState[type + "_rules_" + number],
                rules_name: _file_name,
                rules_auto_num: _file_name,
            }
        }));

        this.state[type + "_file_name_setting_list"].map((obj, idx) => {
            if(idx+1 == number){
                obj.rules_name = _file_name;
                obj.rules_auto_num = _file_name;
            }
        });
    }

    closeFileNameSetting = (e) => {
        this.props.handleFileNameSettingBtnClick();
    }

    replaceFilename = (filename) => {
        const allowedRegex = /[^a-z A-Z 0-9 ㄱ-ㅎ 가-힣 ㅏ-ㅣ \s\-_()]/g;
        const sanitizedFilename = filename.replace(allowedRegex,"");

        return sanitizedFilename;
    }

    replaceFilenameNumber = (filename) => {
        const allowedRegex = /[^0-9\s]/g;
        const sanitizedFilename = filename.replace(allowedRegex,"");

        return sanitizedFilename;
    }

    render () {
        return (
            <>
                <div className="container_file_name_setting">
                    <div className="wrap_file_name_setting">
                        <button className={"btn_close_file_name_setting"} onClick={(e) => {this.closeFileNameSetting(e)}}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.2844 14.2156C15.4253 14.3578 15.5043 14.5499 15.5043 14.75C15.5043 14.9501 15.4253 15.1422 15.2844 15.2844C15.1411 15.423 14.9495 15.5005 14.7501 15.5005C14.5507 15.5005 14.359 15.423 14.2157 15.2844L8.00007 9.05938L1.78444 15.2844C1.64109 15.423 1.44948 15.5005 1.25007 15.5005C1.05066 15.5005 0.859049 15.423 0.715693 15.2844C0.574857 15.1422 0.49585 14.9501 0.49585 14.75C0.49585 14.5499 0.574857 14.3578 0.715693 14.2156L6.94069 8L0.715693 1.78438C0.596096 1.63866 0.534977 1.45366 0.544225 1.26537C0.553472 1.07708 0.632425 0.898961 0.765726 0.76566C0.899027 0.632359 1.07715 0.553406 1.26543 0.544158C1.45372 0.534911 1.63872 0.59603 1.78444 0.715627L8.00007 6.94063L14.2157 0.715627C14.3614 0.59603 14.5464 0.534911 14.7347 0.544158C14.923 0.553406 15.1011 0.632359 15.2344 0.76566C15.3677 0.898961 15.4467 1.07708 15.4559 1.26537C15.4652 1.45366 15.404 1.63866 15.2844 1.78438L9.05944 8L15.2844 14.2156Z" fill="#333333"/>
                            </svg>
                        </button>
                        <section className="section_file_name_setting_yn">
                            <h3>다운로드 파일명 설정</h3>
                            <p>등록한 배너를 다운로드 받을 때 저장되는 파일명을 설정해 보세요.</p>
                            {
                                [
                                    {type: "Y", text: "직접 설정", children: "직접 설정한 파일명 규칙으로 다운로드 되어요.  ex) 규칙1_규칙2_규칙3_규칙4.jpg"},
                                    {type: "N", text: "기본 설정", children: "배너 이름 그대로 다운로드 되어요. 단, 동일한 파일명을 여러번 다운로드 받을 때에는 맨 뒤에 번호가 자동으로 붙어요.  ex) 배너이름_번호.jpg"}
                                ].map((obj, idx) => (
                                    <RadioButton
                                        key={idx}
                                        id={"radio_btn_file_name_setting_" + obj.type}
                                        className={"radio_btn_file_name_setting"}
                                        name={"radio_btn_file_name_setting"}
                                        value={obj.type == "Y" ? "Y" : obj.type == "D" ? "D" : "N"}
                                        text={obj.text}
                                        children={<span>{obj.children}</span>}
                                        checked={this.state.file_name_setting_yn == null || this.state.file_name_setting_yn == "" ? "Y" : this.state.file_name_setting_yn == obj.type}
                                        onChangeCheck={this.changeFileNameSettingYn.bind(this)}
                                    />
                                ))
                            }
                            <br/>
                            {/*<p className={"notice_file_name_setting"}>*/}
                            {/*    <i className={"fa fa-exclamation-circle"} aria-hidden="true"></i>&nbsp;동일한 파일명을 여러번 다운로드 받을 때에는 맨 뒤에 번호가 자동으로 붙어요.*/}
                            {/*</p>*/}
                        </section>
                        {this.state.file_name_setting_yn == "Y" &&
                            <section className="section_file_name_setting">
                                <hr/>
                                <div className="file_name_setting_w">
                                    {this.props.singleSize
                                    ?<h3>배너 사이즈 파일명</h3>
                                    :<h3>배너 사이즈 1 파일명</h3>}
                                    <p>ex) 규칙1_규칙2_규칙3_규칙4.jpg</p>
                                    <div className={"file_name_setting_rules"}>
                                        {this.state.rules_list.map((obj, idx) => {
                                            if(this.state["w_rules_" + (idx+1)].code_flag) {
                                                return (
                                                    <div className={"rules_card rules_card_" + (idx+1)} key={idx}>
                                                        <h5>규칙 {idx + 1}</h5>
                                                        <SelectBoxV2
                                                            id={"w_select_box_rules_" + (idx+1)}
                                                            className={"select_box_rules w_select_box_rules_" + (idx+1)}
                                                            width="100%"
                                                            height="36px"
                                                            padding="0 15px"
                                                            list={this.state.file_name_rules_list}
                                                            optionValuetName="code_cd"
                                                            optionTexttName="code_name"
                                                            selectedValue={this.state["w_rules_" + (idx+1)].code_flag && this.state["w_rules_" + (idx+1)].code_cd != null && this.state["w_rules_" + (idx+1)].code_cd != ""
                                                                            ? this.state["w_rules_" + (idx+1)].code_cd
                                                                            : this.state.file_name_rules_list != null && this.state.file_name_rules_list[0].code_cd != null && this.state.file_name_rules_list[0].code_cd != ""
                                                                            ? this.state.file_name_rules_list[0].code_cd
                                                                            : ""}
                                                            selectedText={this.state["w_rules_" + (idx+1)].code_flag && this.state["w_rules_" + (idx+1)].code_name != null && this.state["w_rules_" + (idx+1)].code_name != ""
                                                                            ? this.state["w_rules_" + (idx+1)].code_name
                                                                            : this.state.file_name_rules_list != null && this.state.file_name_rules_list[0].code_name != null && this.state.file_name_rules_list[0].code_name != ""
                                                                            ? this.state.file_name_rules_list[0].code_name
                                                                            : ""}
                                                            onClickOption={this.selectFileNameRules.bind(this)}
                                                        />
                                                        {this.state["w_rules_" + (idx+1)].code_cd == "63000" &&
                                                            <SelectBoxV2
                                                                id={"w_select_box_rules_" + (idx+1)}
                                                                className={"select_box_rules w_select_box_rules_" + (idx+1)}
                                                                width="100%"
                                                                height="36px"
                                                                padding="0 15px"
                                                                list={this.state.file_name_banner_size_list}
                                                                optionValuetName="code_cd"
                                                                optionTexttName="code_name"
                                                                selectedValue={this.state["w_rules_" + (idx+1)].second_code_cd != null && this.state["w_rules_" + (idx+1)].second_code_cd != ""
                                                                                ? this.state["w_rules_" + (idx+1)].second_code_cd
                                                                                : this.state.file_name_banner_size_list != null && this.state.file_name_banner_size_list[0].code_cd != null && this.state.file_name_banner_size_list[0].code_cd != ""
                                                                                ? this.state.file_name_banner_size_list[0].code_cd
                                                                                : ""}
                                                                selectedText={this.state["w_rules_" + (idx+1)].second_code_name != null && this.state["w_rules_" + (idx+1)].second_code_name != ""
                                                                                ? this.state["w_rules_" + (idx+1)].second_code_name
                                                                                : this.state.file_name_banner_size_list != null && this.state.file_name_banner_size_list[0].code_name != null && this.state.file_name_banner_size_list[0].code_name != ""
                                                                                ? this.state.file_name_banner_size_list[0].code_name
                                                                                : ""}
                                                                onClickOption={this.selectFileNameSecondDepth.bind(this)}
                                                            />
                                                        }
                                                        {(this.state["w_rules_" + (idx+1)].code_cd == "63000" && this.state["w_rules_" + (idx+1)].second_code_cd == "63010") &&
                                                            <input type="text" className="form-control input_file_name_rules" value={this.state["w_rules_" + (idx+1)].rules_name} onChange={(e) => this.handleInputFileNameRulesChange(e, "w", (idx+1))} maxLength={20}/>
                                                        }
                                                        {this.state["w_rules_" + (idx+1)].code_cd == "64000" &&
                                                            <SelectBoxV2
                                                                id={"w_select_box_rules_" + (idx+1)}
                                                                className={"select_box_rules w_select_box_rules_" + (idx+1)}
                                                                width="100%"
                                                                height="36px"
                                                                padding="0 15px"
                                                                list={this.state.file_name_date_setting_list}
                                                                optionValuetName="code_cd"
                                                                optionTexttName="code_name"
                                                                selectedValue={this.state["w_rules_" + (idx+1)].second_code_cd != null && this.state["w_rules_" + (idx+1)].second_code_cd != ""
                                                                                ? this.state["w_rules_" + (idx+1)].second_code_cd
                                                                                : this.state.file_name_date_setting_list != null && this.state.file_name_date_setting_list[0].code_cd != null && this.state.file_name_date_setting_list[0].code_cd != ""
                                                                                ? this.state.file_name_date_setting_list[0].code_cd
                                                                                : ""}
                                                                selectedText={this.state["w_rules_" + (idx+1)].second_code_name != null && this.state["w_rules_" + (idx+1)].second_code_name != ""
                                                                                ? this.state["w_rules_" + (idx+1)].second_code_name
                                                                                : this.state.file_name_date_setting_list != null && this.state.file_name_date_setting_list[0].code_name != null && this.state.file_name_date_setting_list[0].code_name != ""
                                                                                ? this.state.file_name_date_setting_list[0].code_name
                                                                                : ""}
                                                                onClickOption={this.selectFileNameSecondDepth.bind(this)}
                                                            />
                                                        }
                                                        {(this.state["w_rules_" + (idx+1)].code_cd == "64000" && this.state["w_rules_" + (idx+1)].second_code_cd == "64004") &&
                                                            <div className={"container_datepicker"}>
                                                                <label className={"wrap_datepicker"}>
                                                                    <i className={"icn_datepicker"}>
                                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M13 2H11.5V1.5C11.5 1.36739 11.4473 1.24021 11.3536 1.14645C11.2598 1.05268 11.1326 1 11 1C10.8674 1 10.7402 1.05268 10.6464 1.14645C10.5527 1.24021 10.5 1.36739 10.5 1.5V2H5.5V1.5C5.5 1.36739 5.44732 1.24021 5.35355 1.14645C5.25979 1.05268 5.13261 1 5 1C4.86739 1 4.74021 1.05268 4.64645 1.14645C4.55268 1.24021 4.5 1.36739 4.5 1.5V2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V13C2 13.2652 2.10536 13.5196 2.29289 13.7071C2.48043 13.8946 2.73478 14 3 14H13C13.2652 14 13.5196 13.8946 13.7071 13.7071C13.8946 13.5196 14 13.2652 14 13V3C14 2.73478 13.8946 2.48043 13.7071 2.29289C13.5196 2.10536 13.2652 2 13 2ZM4.5 3V3.5C4.5 3.63261 4.55268 3.75979 4.64645 3.85355C4.74021 3.94732 4.86739 4 5 4C5.13261 4 5.25979 3.94732 5.35355 3.85355C5.44732 3.75979 5.5 3.63261 5.5 3.5V3H10.5V3.5C10.5 3.63261 10.5527 3.75979 10.6464 3.85355C10.7402 3.94732 10.8674 4 11 4C11.1326 4 11.2598 3.94732 11.3536 3.85355C11.4473 3.75979 11.5 3.63261 11.5 3.5V3H13V5H3V3H4.5ZM13 13H3V6H13V13Z" fill="#3A3A3A"/>
                                                                        </svg>
                                                                    </i>
                                                                </label>
                                                                <DatePicker
                                                                    className={"file_name_datepicker"}
                                                                    selected={this.state["w_rules_" + (idx+1)].rules_date != null && this.state["w_rules_" + (idx+1)].rules_date != "" ? this.state["w_rules_" + (idx+1)].rules_date : new Date()}
                                                                    onSelect={this.handleSelect}
                                                                    onChange={date => {this.handleFileNameRulesDateChange(date, "w", (idx+1))}}
                                                                    minDate={new Date("1000-01-01")}
                                                                    maxDate={new Date("9999-12-31")}
                                                                    dateFormat={"yyyy-MM-dd"}
                                                                    onChangeRaw={this.handleDateChangeRaw}
                                                                    shouldCloseOnSelect={true}
                                                                    locale={ko}
                                                                />
                                                            </div>
                                                        }
                                                        {this.state["w_rules_" + (idx+1)].code_cd == "64000" &&
                                                            <SelectBoxV2
                                                                id={"w_select_box_rules_" + (idx+1)}
                                                                className={"select_box_rules w_select_box_rules_" + (idx+1)}
                                                                width="100%"
                                                                height="36px"
                                                                padding="0 15px"
                                                                list={this.state.file_name_date_format_list}
                                                                optionValuetName="code_cd"
                                                                optionTexttName="code_name"
                                                                selectedValue={this.state["w_rules_" + (idx+1)].third_code_cd != null && this.state["w_rules_" + (idx+1)].third_code_cd != ""
                                                                                ? this.state["w_rules_" + (idx+1)].third_code_cd
                                                                                : this.state.file_name_date_format_list != null && this.state.file_name_date_format_list[0].code_cd != null && this.state.file_name_date_format_list[0].code_cd != ""
                                                                                ? this.state.file_name_date_format_list[0].code_cd
                                                                                : ""}
                                                                selectedText={this.state["w_rules_" + (idx+1)].third_code_name != null && this.state["w_rules_" + (idx+1)].third_code_name != ""
                                                                                ? this.state["w_rules_" + (idx+1)].third_code_name
                                                                                : this.state.file_name_date_format_list != null && this.state.file_name_date_format_list[0].code_name != null && this.state.file_name_date_format_list[0].code_name != ""
                                                                                ? this.state.file_name_date_format_list[0].code_name
                                                                                : ""}
                                                                onClickOption={this.selectFileNameThirdDepth.bind(this)}
                                                            />
                                                        }
                                                        {this.state["w_rules_" + (idx+1)].code_cd == "66000" &&
                                                            <SelectBoxV2
                                                                id={"w_select_box_rules_" + (idx+1)}
                                                                className={"select_box_rules w_select_box_rules_" + (idx+1)}
                                                                width="100%"
                                                                height="36px"
                                                                padding="0 15px"
                                                                list={this.state.file_name_number_format_list}
                                                                optionValuetName="code_cd"
                                                                optionTexttName="code_name"
                                                                selectedValue={this.state["w_rules_" + (idx+1)].second_code_cd != null && this.state["w_rules_" + (idx+1)].second_code_cd != ""
                                                                                ? this.state["w_rules_" + (idx+1)].second_code_cd
                                                                                : this.state.file_name_number_format_list != null && this.state.file_name_number_format_list[0].code_cd != null && this.state.file_name_number_format_list[0].code_cd != ""
                                                                                ? this.state.file_name_number_format_list[0].code_cd
                                                                                : ""}
                                                                selectedText={this.state["w_rules_" + (idx+1)].second_code_name != null && this.state["w_rules_" + (idx+1)].second_code_name != ""
                                                                                ? this.state["w_rules_" + (idx+1)].second_code_name
                                                                                : this.state.file_name_number_format_list != null && this.state.file_name_number_format_list[0].code_name != null && this.state.file_name_number_format_list[0].code_name != ""
                                                                                ? this.state.file_name_number_format_list[0].code_name
                                                                                : ""}
                                                                onClickOption={this.selectFileNameSecondDepth.bind(this)}
                                                            />
                                                        }
                                                        {(this.state["w_rules_" + (idx+1)].code_cd == "66000" && this.state["w_rules_" + (idx+1)].second_code_cd == "66010") &&
                                                            <input type="text" className="form-control input_file_name_rules" value={this.state["w_rules_" + (idx+1)].rules_name} onChange={(e) => this.handleInputFileNameRulesNumberChange(e, "w", (idx+1))} maxLength={10}/>
                                                        }
                                                        {this.state["w_rules_" + (idx+1)].code_cd == "69999" &&
                                                            <input type="text" className="form-control input_file_name_rules" value={this.state["w_rules_" + (idx+1)].rules_name} onChange={(e) => this.handleInputFileNameRulesChange(e, "w", (idx+1))} maxLength={20}/>
                                                        }
                                                        <button className={"btn_close_rules_card"} onClick={(e) => this.closeRulesCardClick(e, "w", (idx+1))}>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12.8552 12.1423C12.949 12.2371 13.0017 12.3651 13.0017 12.4985C13.0017 12.6319 12.949 12.76 12.8552 12.8548C12.7596 12.9472 12.6318 12.9988 12.4989 12.9988C12.366 12.9988 12.2382 12.9472 12.1427 12.8548L7.99891 8.70477L3.85516 12.8548C3.75959 12.9472 3.63185 12.9988 3.49891 12.9988C3.36597 12.9988 3.23823 12.9472 3.14266 12.8548C3.04877 12.76 2.99609 12.6319 2.99609 12.4985C2.99609 12.3651 3.04877 12.2371 3.14266 12.1423L7.29266 7.99852L3.14266 3.85477C3.06292 3.75762 3.02218 3.63429 3.02834 3.50876C3.03451 3.38323 3.08714 3.26449 3.17601 3.17562C3.26488 3.08675 3.38362 3.03412 3.50915 3.02795C3.63468 3.02179 3.75801 3.06253 3.85516 3.14227L7.99891 7.29227L12.1427 3.14227C12.2398 3.06253 12.3631 3.02179 12.4887 3.02795C12.6142 3.03412 12.7329 3.08675 12.8218 3.17562C12.9107 3.26449 12.9633 3.38323 12.9695 3.50876C12.9756 3.63429 12.9349 3.75762 12.8552 3.85477L8.70516 7.99852L12.8552 12.1423Z" fill="#333333"/>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                );
                                            }
                                        })}
                                        <div className={"rules_card bg_add_rules"}>
                                            <button className={"btn_add_rules"} onClick={(e) => this.addRulesBtnClick(e, "w")}>
                                                <svg width="17" height="16" viewBox="1 1 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M14.5 8C14.5 8.13261 14.4473 8.25979 14.3536 8.35355C14.2598 8.44732 14.1326 8.5 14 8.5H9V13.5C9 13.6326 8.94732 13.7598 8.85355 13.8536C8.75979 13.9473 8.63261 14 8.5 14C8.36739 14 8.24021 13.9473 8.14645 13.8536C8.05268 13.7598 8 13.6326 8 13.5V8.5H3C2.86739 8.5 2.74021 8.44732 2.64645 8.35355C2.55268 8.25979 2.5 8.13261 2.5 8C2.5 7.86739 2.55268 7.74021 2.64645 7.64645C2.74021 7.55268 2.86739 7.5 3 7.5H8V2.5C8 2.36739 8.05268 2.24021 8.14645 2.14645C8.24021 2.05268 8.36739 2 8.5 2C8.63261 2 8.75979 2.05268 8.85355 2.14645C8.94732 2.24021 9 2.36739 9 2.5V7.5H14C14.1326 7.5 14.2598 7.55268 14.3536 7.64645C14.4473 7.74021 14.5 7.86739 14.5 8Z" fill="#333333"/>
                                                </svg>
                                                &nbsp;규칙 추가
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {!this.props.singleSize &&
                                <>
                                    <hr/>
                                    <div className="file_name_setting_h">
                                        <h3>배너 사이즈 2 파일명</h3>
                                        <p>ex) 규칙1_규칙2_규칙3_규칙4.jpg</p>
                                        <div className={"file_name_setting_rules"}>
                                            {this.state.rules_list.map((obj, idx) => {
                                                if(this.state["h_rules_" + (idx+1)].code_flag){
                                                    return (
                                                        <div className={"rules_card rules_card_" + (idx+1)} key={idx}>
                                                            <h5>규칙 {idx+1}</h5>
                                                            <SelectBoxV2
                                                                id={"h_select_box_rules_" + (idx+1)}
                                                                className={"select_box_rules h_select_box_rules_" + (idx+1)}
                                                                width="100%"
                                                                height="36px"
                                                                padding="0 15px"
                                                                list={this.state.file_name_rules_list}
                                                                optionValuetName = "code_cd"
                                                                optionTexttName = "code_name"
                                                                selectedValue={this.state["h_rules_" + (idx+1)].code_flag && this.state["h_rules_" + (idx+1)].code_cd != null && this.state["h_rules_" + (idx+1)].code_cd != ""
                                                                                ? this.state["h_rules_" + (idx+1)].code_cd
                                                                                : this.state.file_name_rules_list != null && this.state.file_name_rules_list[0].code_cd != null && this.state.file_name_rules_list[0].code_cd != ""
                                                                                ? this.state.file_name_rules_list[0].code_cd
                                                                                : ""}
                                                                selectedText={this.state["h_rules_" + (idx+1)].code_flag && this.state["h_rules_" + (idx+1)].code_name != null && this.state["h_rules_" + (idx+1)].code_name != ""
                                                                                ? this.state["h_rules_" + (idx+1)].code_name
                                                                                : this.state.file_name_rules_list != null && this.state.file_name_rules_list[0].code_name != null && this.state.file_name_rules_list[0].code_name != ""
                                                                                ? this.state.file_name_rules_list[0].code_name
                                                                                : ""}
                                                                onClickOption={this.selectFileNameRules.bind(this)}
                                                            />
                                                            {this.state["h_rules_" + (idx+1)].code_cd == "63000" &&
                                                                <SelectBoxV2
                                                                    id={"h_select_box_rules_" + (idx+1)}
                                                                    className={"select_box_rules h_select_box_rules_" + (idx+1)}
                                                                    width="100%"
                                                                    height="36px"
                                                                    padding="0 15px"
                                                                    list={this.state.file_name_banner_size_list}
                                                                    optionValuetName = "code_cd"
                                                                    optionTexttName = "code_name"
                                                                    selectedValue = {this.state["h_rules_" + (idx+1)].second_code_cd != null && this.state["h_rules_" + (idx+1)].second_code_cd != ""
                                                                                    ? this.state["h_rules_" + (idx+1)].second_code_cd
                                                                                    : this.state.file_name_banner_size_list != null && this.state.file_name_banner_size_list[0].code_cd != null && this.state.file_name_banner_size_list[0].code_cd != ""
                                                                                    ? this.state.file_name_banner_size_list[0].code_cd
                                                                                    : ""}
                                                                    selectedText = {this.state["h_rules_" + (idx+1)].second_code_name != null && this.state["h_rules_" + (idx+1)].second_code_name != ""
                                                                                    ? this.state["h_rules_" + (idx+1)].second_code_name
                                                                                    : this.state.file_name_banner_size_list != null && this.state.file_name_banner_size_list[0].code_name != null && this.state.file_name_banner_size_list[0].code_name != ""
                                                                                    ? this.state.file_name_banner_size_list[0].code_name
                                                                                    : ""}
                                                                    onClickOption={this.selectFileNameSecondDepth.bind(this)}
                                                                />
                                                            }
                                                            {(this.state["h_rules_" + (idx+1)].code_cd == "63000" && this.state["h_rules_" + (idx+1)].second_code_cd == "63010") &&
                                                                <input type="text" className="form-control input_file_name_rules" value={this.state["h_rules_" + (idx+1)].rules_name} onChange={(e) => this.handleInputFileNameRulesChange(e, "h", (idx+1))} maxLength={20}/>
                                                            }
                                                            {this.state["h_rules_" + (idx+1)].code_cd == "64000" &&
                                                                <SelectBoxV2
                                                                    id={"h_select_box_rules_" + (idx+1)}
                                                                    className={"select_box_rules h_select_box_rules_" + (idx+1)}
                                                                    width="100%"
                                                                    height="36px"
                                                                    padding="0 15px"
                                                                    list={this.state.file_name_date_setting_list}
                                                                    optionValuetName = "code_cd"
                                                                    optionTexttName = "code_name"
                                                                    selectedValue = {this.state["h_rules_" + (idx+1)].second_code_cd != null && this.state["h_rules_" + (idx+1)].second_code_cd != ""
                                                                                    ? this.state["h_rules_" + (idx+1)].second_code_cd
                                                                                    : this.state.file_name_date_setting_list != null && this.state.file_name_date_setting_list[0].code_cd != null && this.state.file_name_date_setting_list[0].code_cd != ""
                                                                                    ? this.state.file_name_date_setting_list[0].code_cd
                                                                                    : ""}
                                                                    selectedText = {this.state["h_rules_" + (idx+1)].second_code_name != null && this.state["h_rules_" + (idx+1)].second_code_name != ""
                                                                                    ? this.state["h_rules_" + (idx+1)].second_code_name
                                                                                    : this.state.file_name_date_setting_list != null && this.state.file_name_date_setting_list[0].code_name != null && this.state.file_name_date_setting_list[0].code_name != ""
                                                                                    ? this.state.file_name_date_setting_list[0].code_name
                                                                                    : ""}
                                                                    onClickOption={this.selectFileNameSecondDepth.bind(this)}
                                                                />
                                                            }
                                                            {(this.state["h_rules_" + (idx+1)].code_cd == "64000" && this.state["h_rules_" + (idx+1)].second_code_cd == "64004") &&
                                                                <div className={"container_datepicker"}>
                                                                    <label className={"wrap_datepicker"}>
                                                                        <i className={"icn_datepicker"}>
                                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M13 2H11.5V1.5C11.5 1.36739 11.4473 1.24021 11.3536 1.14645C11.2598 1.05268 11.1326 1 11 1C10.8674 1 10.7402 1.05268 10.6464 1.14645C10.5527 1.24021 10.5 1.36739 10.5 1.5V2H5.5V1.5C5.5 1.36739 5.44732 1.24021 5.35355 1.14645C5.25979 1.05268 5.13261 1 5 1C4.86739 1 4.74021 1.05268 4.64645 1.14645C4.55268 1.24021 4.5 1.36739 4.5 1.5V2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V13C2 13.2652 2.10536 13.5196 2.29289 13.7071C2.48043 13.8946 2.73478 14 3 14H13C13.2652 14 13.5196 13.8946 13.7071 13.7071C13.8946 13.5196 14 13.2652 14 13V3C14 2.73478 13.8946 2.48043 13.7071 2.29289C13.5196 2.10536 13.2652 2 13 2ZM4.5 3V3.5C4.5 3.63261 4.55268 3.75979 4.64645 3.85355C4.74021 3.94732 4.86739 4 5 4C5.13261 4 5.25979 3.94732 5.35355 3.85355C5.44732 3.75979 5.5 3.63261 5.5 3.5V3H10.5V3.5C10.5 3.63261 10.5527 3.75979 10.6464 3.85355C10.7402 3.94732 10.8674 4 11 4C11.1326 4 11.2598 3.94732 11.3536 3.85355C11.4473 3.75979 11.5 3.63261 11.5 3.5V3H13V5H3V3H4.5ZM13 13H3V6H13V13Z" fill="#3A3A3A"/>
                                                                            </svg>
                                                                        </i>
                                                                    </label>
                                                                    <DatePicker
                                                                        className={"file_name_datepicker"}
                                                                        selected={this.state["h_rules_" + (idx+1)].rules_date != null && this.state["h_rules_" + (idx+1)].rules_date != "" ? this.state["h_rules_" + (idx+1)].rules_date : new Date()}
                                                                        onSelect={this.handleSelect}
                                                                        onChange={date => {this.handleFileNameRulesDateChange(date, "h", (idx+1))}}
                                                                        minDate={new Date("1000-01-01")}
                                                                        maxDate={new Date("9999-12-31")}
                                                                        dateFormat={"yyyy-MM-dd"}
                                                                        onChangeRaw={this.handleDateChangeRaw}
                                                                        shouldCloseOnSelect={true}
                                                                        locale={ko}
                                                                    />
                                                                </div>
                                                            }
                                                            {this.state["h_rules_" + (idx+1)].code_cd == "64000" &&
                                                                <SelectBoxV2
                                                                    id={"h_select_box_rules_" + (idx+1)}
                                                                    className={"select_box_rules h_select_box_rules_" + (idx+1)}
                                                                    width="100%"
                                                                    height="36px"
                                                                    padding="0 15px"
                                                                    list={this.state.file_name_date_format_list}
                                                                    optionValuetName = "code_cd"
                                                                    optionTexttName = "code_name"
                                                                    selectedValue = {this.state["h_rules_" + (idx+1)].third_code_cd != null && this.state["h_rules_" + (idx+1)].third_code_cd != ""
                                                                                    ? this.state["h_rules_" + (idx+1)].third_code_cd
                                                                                    : this.state.file_name_date_format_list != null && this.state.file_name_date_format_list[0].code_cd != null && this.state.file_name_date_format_list[0].code_cd != ""
                                                                                    ? this.state.file_name_date_format_list[0].code_cd
                                                                                    : ""}
                                                                    selectedText = {this.state["h_rules_" + (idx+1)].third_code_name != null && this.state["h_rules_" + (idx+1)].third_code_name != ""
                                                                                    ? this.state["h_rules_" + (idx+1)].third_code_name
                                                                                    : this.state.file_name_date_format_list != null && this.state.file_name_date_format_list[0].code_name != null && this.state.file_name_date_format_list[0].code_name != ""
                                                                                    ? this.state.file_name_date_format_list[0].code_name
                                                                                    : ""}
                                                                    onClickOption={this.selectFileNameThirdDepth.bind(this)}
                                                                />
                                                            }
                                                            {this.state["h_rules_" + (idx+1)].code_cd == "66000" &&
                                                                <SelectBoxV2
                                                                    id={"h_select_box_rules_" + (idx+1)}
                                                                    className={"select_box_rules h_select_box_rules_" + (idx+1)}
                                                                    width="100%"
                                                                    height="36px"
                                                                    padding="0 15px"
                                                                    list={this.state.file_name_number_format_list}
                                                                    optionValuetName="code_cd"
                                                                    optionTexttName="code_name"
                                                                    selectedValue={this.state["h_rules_" + (idx+1)].second_code_cd != null && this.state["h_rules_" + (idx+1)].second_code_cd != ""
                                                                                    ? this.state["h_rules_" + (idx+1)].second_code_cd
                                                                                    : this.state.file_name_number_format_list != null && this.state.file_name_number_format_list[0].code_cd != null && this.state.file_name_number_format_list[0].code_cd != ""
                                                                                    ? this.state.file_name_number_format_list[0].code_cd
                                                                                    : ""}
                                                                    selectedText={this.state["h_rules_" + (idx+1)].second_code_name != null && this.state["h_rules_" + (idx+1)].second_code_name != ""
                                                                                ? this.state["h_rules_" + (idx+1)].second_code_name
                                                                                : this.state.file_name_number_format_list != null && this.state.file_name_number_format_list[0].code_name != null && this.state.file_name_number_format_list[0].code_name != ""
                                                                                ? this.state.file_name_number_format_list[0].code_name
                                                                                : ""}
                                                                    onClickOption={this.selectFileNameSecondDepth.bind(this)}
                                                                />
                                                            }
                                                            {(this.state["h_rules_" + (idx+1)].code_cd == "66000" && this.state["h_rules_" + (idx+1)].second_code_cd == "66010") &&
                                                                <input type="text" className="form-control input_file_name_rules" value={this.state["h_rules_" + (idx+1)].rules_name} onChange={(e) => this.handleInputFileNameRulesNumberChange(e, "h", (idx+1))} maxLength={10}/>
                                                            }
                                                            {this.state["h_rules_" + (idx+1)].code_cd == "69999" &&
                                                                <input type="text" className="form-control input_file_name_rules" value={this.state["h_rules_" + (idx+1)].rules_name} onChange={(e) => this.handleInputFileNameRulesChange(e, "h", (idx+1))} maxLength={20}/>
                                                            }
                                                            <button className={"btn_close_rules_card"} onClick={(e) => this.closeRulesCardClick(e, "h", (idx+1))}>
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M12.8552 12.1423C12.949 12.2371 13.0017 12.3651 13.0017 12.4985C13.0017 12.6319 12.949 12.76 12.8552 12.8548C12.7596 12.9472 12.6318 12.9988 12.4989 12.9988C12.366 12.9988 12.2382 12.9472 12.1427 12.8548L7.99891 8.70477L3.85516 12.8548C3.75959 12.9472 3.63185 12.9988 3.49891 12.9988C3.36597 12.9988 3.23823 12.9472 3.14266 12.8548C3.04877 12.76 2.99609 12.6319 2.99609 12.4985C2.99609 12.3651 3.04877 12.2371 3.14266 12.1423L7.29266 7.99852L3.14266 3.85477C3.06292 3.75762 3.02218 3.63429 3.02834 3.50876C3.03451 3.38323 3.08714 3.26449 3.17601 3.17562C3.26488 3.08675 3.38362 3.03412 3.50915 3.02795C3.63468 3.02179 3.75801 3.06253 3.85516 3.14227L7.99891 7.29227L12.1427 3.14227C12.2398 3.06253 12.3631 3.02179 12.4887 3.02795C12.6142 3.03412 12.7329 3.08675 12.8218 3.17562C12.9107 3.26449 12.9633 3.38323 12.9695 3.50876C12.9756 3.63429 12.9349 3.75762 12.8552 3.85477L8.70516 7.99852L12.8552 12.1423Z" fill="#333333"/>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    );
                                                }
                                            })}
                                            <div className={"rules_card bg_add_rules"}>
                                                <button className={"btn_add_rules"} onClick={(e) => this.addRulesBtnClick(e, "h")}>
                                                    <svg width="17" height="16" viewBox="1 1 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M14.5 8C14.5 8.13261 14.4473 8.25979 14.3536 8.35355C14.2598 8.44732 14.1326 8.5 14 8.5H9V13.5C9 13.6326 8.94732 13.7598 8.85355 13.8536C8.75979 13.9473 8.63261 14 8.5 14C8.36739 14 8.24021 13.9473 8.14645 13.8536C8.05268 13.7598 8 13.6326 8 13.5V8.5H3C2.86739 8.5 2.74021 8.44732 2.64645 8.35355C2.55268 8.25979 2.5 8.13261 2.5 8C2.5 7.86739 2.55268 7.74021 2.64645 7.64645C2.74021 7.55268 2.86739 7.5 3 7.5H8V2.5C8 2.36739 8.05268 2.24021 8.14645 2.14645C8.24021 2.05268 8.36739 2 8.5 2C8.63261 2 8.75979 2.05268 8.85355 2.14645C8.94732 2.24021 9 2.36739 9 2.5V7.5H14C14.1326 7.5 14.2598 7.55268 14.3536 7.64645C14.4473 7.74021 14.5 7.86739 14.5 8Z" fill="#333333"/>
                                                    </svg>
                                                    &nbsp;규칙 추가
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                }
                            </section>
                        }
                        <section className={"section_file_name_setting_btn"}>
                            <button className={"btn_prev_file_name_setting"} onClick={(e) => this.closeFileNameSetting(e)}>취소</button>
                            <button className={"btn_save_file_name_setting"} onClick={(e) => {this.saveFileNameSetting(e)}}>저장하기</button>
                        </section>
                    </div>
                </div>
                {/* 저장 다이얼로그 */
                    this.state.save_confirm_dialog_show_state === true &&
                    <SaveDialog
                        content={this.state.save_confirm_dialog_content}
                        button_list={this.state.save_confirm_dialog_button_list}
                    />
                }
                {/* 경고 다이얼로그 */
                    this.state.warning_dialog_show_state === true &&
                    <WarningDialog
                        content={this.state.warning_dialog_content}
                        button_list={this.state.warning_dialog_button_list}
                    />
                }
            </>
        )
    };
}

export default EventSettingFileName;
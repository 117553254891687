import React from 'react';
import axios from 'axios';
import http from '../modules/api';
import {Route, Switch, NavLink, Link} from "react-router-dom";
import $ from 'jquery';

import "react-datepicker/dist/react-datepicker.css";

import ReactGA from 'react-ga';
ReactGA.initialize('UA-78553461-8');

var ga_label = sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")";

export class OrderList extends React.Component {
    state = {
        order_list : [],
    };

    componentDidMount(e) {
        this.fn_selectOrderList();
    }

    componentDidUpdate() {

    }

    componentWillUnmount () {

    }

    onClickTabChange = (e, tabNum) => {
        this.setState({tabNum: tabNum});
    }

    /**
     * 사용자 세팅 배너 리스트 조회
     */
	async fn_selectOrderList() {
		const res = await http.get("/rest/common/selectOrderList");
		// 조회 성공시 다음 단계
        if (res.data.dataList != null && res.data.dataList.length > 0) {
            this.setState({
                order_list: res.data.dataList
            })
        } 
        if (res.data.code != null) {
            if (res.data.code != "200") { 
                let msg = res.data.msg;
                alert(msg);
            }
        } else {
			alert("서비스 점검중 입니다.");
			//리다이렉트 경로
        }
    }

	render() {
        $(".pageName").text("결제 내역");
        let today = new Date();  
        return (
            <div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%", paddingBottom: "40px", marginBottom: "50px"}}>
                <div className="form_primary_title pc_page_title" style={{fontSize:"27px"}}>결제 내역</div>
                <div>
                    {/* <div className="flexBox credit_card_box_area" style={{marginTop:"50px", padding: "37px 50px", border:"1px #EBEBED solid", borderRadius:"10px", justifyContent:"space-between"}}>
                        <div className="flexBox">
                            <div className="icn_receipt" style={{margin: "auto 10px auto 0"}}></div>
                            <div className="icn_credit_card_text" style={{flex: 1, margin: "auto 0"}}><b>{today.getFullYear()}년 {today.getMonth()+1}월 {today.getDate()}일 오늘 기준 고객님의 결제 예상 금액</b></div>
                        </div>
                        <div className="flexBox">
                            <div className="icn_credit_card_text" style={{margin: "auto 0", fontSize:"20px", color: "#10CFC9", fontWeight: "bold"}}>{"19,900"}</div>
                            <div className="icn_credit_card_text" style={{margin: "auto 0", fontSize:"15px", color: "#333333", fontWeight:"500", lineHeight:"15px"}}>&nbsp;원&nbsp;&nbsp;</div>
                            <div className="icn_credit_card_text" style={{margin: "auto 0", fontSize:"12px", color: "#ADADAD", fontWeight:"500", lineHeight:"15px"}}>VAT 별도</div>
                        </div>
                    </div> */}
                        <div className="credit_card_box_area" style={{marginTop: "20px", padding: "50px", padding: "37px 50px", border:"1px #EBEBED solid", borderRadius:"10px", background:"white"}}>
                            <div className="flexBox">
                                {/* <div className="icn_receipt" style={{margin: "auto 10px auto 0"}}></div> */}
                                <div class="icn_round_blue" style={{width: "10px", height: "10px", margin: "auto 0px", fontSize: "18px"}}></div>
                                <div className="icn_credit_card_text" style={{flex: 1, margin: "auto 0px auto 15px"}}>카드결제의 경우 가맹점명이 "옷딜" 또는 "다우데이터"로 표기됩니다.</div>
                            </div>
                        </div>
                        <div className="credit_card_box_area" style={{marginTop: "20px", padding: "50px", padding: "37px 50px", border:"1px #EBEBED solid", borderRadius:"10px", background:"white"}}>
                            <div>
                                <div style={{display:"flex", marginBottom: "19px", fontSize:"18px", fontWeight: "bold", justifyContent:"space-between"}}>
                                    <div style={{margin:"auto 0"}}>목록</div>
                                </div>
                                <div className="banner_list_table_area" style={{width:"calc(95vw - 600px)", minWidth:"100%", height:"100%", overflowX:"auto", overflowY: "hidden"}}>
                                    <div style={{display:"inline-block", height:"100%", minWidth:"1080px", width:"100%"}}>
                                        <div className="table" style={{marginBottom:0}}>
                                            <div className="table table_v3" style={{marginBottom:0}}>
                                                <table style={{overflowX: "visible",display: "table-row"}}>
                                                    <colgroup>
                                                    </colgroup>
                                                    <thead style={{background: "#EBEBED", border:"0",height: "40px"}}>	
                                                        <tr style={{background: "#EBEBED", border:"0"}}>
                                                            <td className="user_id" onClick={this.onClickSorting} style={{width:"250px", marginLeft:"10px", border:"5px white solid"}}>
                                                                <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>결제번호
                                                                </p>
                                                            </td>
                                                            <td className="corp_reg_no" onClick={this.onClickSorting} style={{width:"250px", border:"5px white solid"}}>
                                                                <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>결제일
                                                                </p>
                                                            </td>
                                                            <td className="business_type" onClick={this.onClickSorting} style={{width:"200px", border:"5px white solid"}}>
                                                                <p style={{display:"flex", justifyContent:"space-between", pointerEvents: "none", margin: "auto"}}>
                                                                    <span style={{margin:"auto 0"}}>금액</span>
                                                                    <span style={{color:"#B4B4B5", fontSize:"12px", fontWeight:"400", margin:"auto 0"}}>(원, VAT 포함)</span>
                                                                </p>
                                                            </td>
                                                            <td className="corp_reg_no" onClick={this.onClickSorting} style={{width:"200px", border:"5px white solid"}}>
                                                                <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>결제수단
                                                                </p>
                                                            </td>
                                                            <td className="corp_reg_no" onClick={this.onClickSorting} style={{width:"180px", border:"5px white solid"}}>
                                                                <p style={{display:"flex", justifyContent:"center", pointerEvents: "none", margin: "auto"}}>상태
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{height:"auto", background:"#F5F5F7"}}>
                                                        {
                                                            this.state.order_list != null && this.state.order_list.length > 0
                                                            ?
                                                                this.state.order_list.map((data, idx) => (
                                                                    <tr style={{marginTop: "-5px"}}>
                                                                        <td style={{width:"250px", border:"5px white solid", fontWeight:"400"}}>
                                                                            <p className="card-text">{data.order_no}</p>
                                                                        </td>
                                                                        <td style={{width:"250px", border:"5px white solid", fontWeight:"400"}}>
                                                                            <p className="card-text">{data.mod_date}</p>
                                                                        </td>
                                                                        <td style={{width:"200px", border:"5px white solid", fontWeight:"400"}}>
                                                                            <p className="card-text" style={{textAlign:"right"}}>{data.amount}</p>
                                                                        </td>
                                                                        <td style={{width:"200px", border:"5px white solid", fontWeight:"400"}}>
                                                                            <p className="card-text">{data.order_type_name}</p>
                                                                        </td>
                                                                        <td style={{width:"180px", border:"5px white solid"}}>
                                                                            <p className="card-text">{data.order_state_name}</p>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            : 
                                                                <tr style={{marginTop: "-5px"}}>
                                                                    <td colSpan="5" style={{width:"250px", border:"5px white solid", fontWeight:"400"}}>
                                                                        <p className="card-text">조회된 데이터가 없습니다.</p>
                                                                    </td>
                                                                </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        )
    }
}   
export function getValue(obj) {
    if(typeof(obj)=='string') {
        return obj;
    } else if(obj.attr('type')=='text' || obj.attr('type')=='password' || obj.attr('type')=='file' || obj.is('textarea')) {
        return obj.val();
    }
    return obj.val();
};

/** Date 형식 날짜 포맷 맞추기 => 2022-08-02
 */
export function dateFormater(date_param){
    if(date_param == null || date_param == ""){
        return date_param;
    }
    const org_date = new Date(date_param);
    const year = org_date.getFullYear();
    const month = org_date.getMonth() + 1;
    const date = org_date.getDate();

    return (year+'-'+(month >= 10 ? month : '0' + month)+'-'+(date >= 10 ? date : '0' + date));
}

export function dateYmFormater(date_param){
    if(date_param == null || date_param == ""){
        return date_param;
    }
    const org_date = new Date(date_param);
    const year = org_date.getFullYear();
    const month = org_date.getMonth() + 1;

    return (year+'-'+(month >= 10 ? month : '0' + month));
}

/** 맨앞에 작성된 0들 제거
 */
export function delLeftZero(str) {
    return String(str).replace( /^0+/, "" );
}

/** 맨뒤에 작성된 0들 제거
 */
 export function delRightZero(str) {
    return String(str).replace( /0+$/, "" );
}

/** 맨뒤에 작성된 .들 제거
 * .은 문자의 종류를 가리지 않는다는 의미이기 때문에 \를 사용하여 문자 취급
 */
 export function delRightDot(str) {
    return String(str).replace(/\.+$/, '');
}

/** 왼쪽 공백 제거
 */
export function delLeftBlank(str) {
    return String(str).replace( /^\s+/, "" );
}

/** 오른쪽 공백 제거
 */
export function delRightBlank(str) {
    return String(str).replace( /\s+$/, "" );
}

/** 왼쪽, 오른쪽 공백 제거
 */
export function delLRBlank(str) {
    return String(str).replace(/^\s*|\s*$/g, '');
}

/** 모든 공백 제거
 */
export function delAllBlank(str) {
    return String(str).replaceAll(" ", "");
}

/** 세자리마다 ',' 붙이는 함수
 */
export function numberComma(str) {
    return String(str).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
/** 문자열 , 해제
 */
export function numberNonComma(str) {
    // return String(str).replaceAll(",", "");
    return String(str).replace(/[^\d]+/g, '');
}

/** 이메일 유효성 검사
 */
export function checkEmail(obj, msg) {

    var val = getValue(obj);

    var regex = /([0-9a-zA-Z_-]+)@(([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2})/;
    var tmp = val.match(regex);
    if (tmp != null) {
        return true;
    } else {
        if(msg!='') {
            alert(msg);
        }
        if(typeof(obj)=='object' && obj.attr('type')=='text') {
            obj.focus();
        }
        return false;
    }

};

/** 휴대폰 값 유효성 검사
 */
export function checkTelno(obj, msg) {

    var val = getValue(obj);

    var regex = /(01[0-9])(\-)([0-9]{4}|[0-9]{3})(\-)([0-9]{4})/;
    var tmp = val.match(regex);
    if (tmp != null) {
        return true;
    } else {
        if(msg!=='') {
            alert(msg);
        }
        if(typeof(obj)=='object' && obj.attr('type')==='text') {
            obj.focus();
        }
        return false;
    }

};

/** 페이지 쿼리스트링 추출
 * @param {*} state 쿼리스트링에서 추출할 값 이름
 */
export function getUrlParams(state) {
    var params = {};
    window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(str, key, value) { params[key] = value; });
    return params[state];
}

/** 파일 확장자 이미지 체크
 * @param {*} fileName 파일명
 */
export function CheckImagefiles(fileName) {
    var result = false;
    var ext = fileName.substring(fileName.lastIndexOf('.') + 1);
    if(ext == null || ext == ""){
        return result;
    }
    var imgs = ['gif', 'jpg', 'jpeg', 'png', 'bmp' ,'ico', 'apng'];
    ext = ext.toLocaleLowerCase();
    imgs.forEach( function(element) {
        if(ext == element){
            result = true;
        }
    });
    return result;
}

/** 두 값을 나눠서 퍼센트로 나타내는 함수
 * @param {*} num1 나눠지는 수 (분자)
 * @param {*} num2 나누는 수 (분모)
 * @param {*} roundIdx 반올림 자리수, 음수 불가능
 */
export function divPercent(num1, num2, roundIdx){
    if(num1 == null || isNaN(num1) || num1 ==0 || num2 == null || isNaN(num2) || num2 == 0){
        return 0;
    }
    let divNum = (parseFloat(num1)/parseFloat(num2))*100;
    return safeNumberDecimal(divNum,roundIdx); // delRightDot(delRightZero((divNum).toFixed(roundIdx)));
}

/** 숫자 계산 소수점 오류 방지
 * ex ) 0.1 + 0.2 = 0.30000000000000004
 * @param {*} num 숫자
 * @param {*} roundIdx 반올림 자리수, 음수 불가능
 */
export function safeNumberDecimal(num, roundIdx){
    if(num == null || isNaN(num) || num == 0 || String(num).indexOf('.') == -1){
        return num;
    }
    return delRightDot(delRightZero((num).toFixed(roundIdx)));
}

/** 문자열이 비어있는지 체크 : enter, blank는 입력으로 간주 안함
 * @param {*} value 입력된 문자열 (체크할 대상)
 */
export function checkEmptyString(value){
    if(value == null){
        return true;
    }
    if(String(value).replace(/[\n\s]/gi, "").length == 0){
        return true;
    }

    return false;
}

/** 숫자만 입력 가능
 * @param {*} value 입력된 문자열 (숫자만 입력되었는지 체크할 대상)
 */
export function inputOnlyNumberFormat(value, max_value) {
    if(value == "0"){
        return value;
    }
    if (max_value && value > max_value) {
        // 마지막 글자만 변경
        console.log("value.slice(0, -2)", value.slice(0, -2));
        console.log("value.slice(-1)", value.slice(-1));
        value = value.slice(0, -2) + value.slice(-1)
        if (value > max_value) {
            // 변경 후에도 최대값 보다 큰 경우
            return "";
        }
        return value;
    } 
    return delLeftZero(numberNonComma(value).replace(/(\d)(?=(?:\d{3})+(?!\d))/g,'$1'));
}

/** 숫자만 입력 가능(세자리마다 콤마)
 * @param {*} value 입력된 문자열 (숫자만 입력되었는지 체크할 대상)
 */
 export function inputNumberFormat(value) {
    return delLeftZero(numberNonComma(value).replace(/(\d)(?=(?:\d{3})+(?!\d))/g,'$1,'));
}


/** 
 * 비밀번호 유효성 검사
 * 8~20자 영문+숫자+특수문자
 */ 
export function passwordValidCheck (value) {
    const num = /[0-9]/;
    const eng = /[a-zA-Z]/;;
    const spe = /[~!@#$%^&*()_+|<>?:{}]/;

    if (value.length >= 8 
                && value.length <= 20 
                && num.test(value) === true 
                && eng.test(value) === true 
                && spe.test(value) === true ) {
        return true;
    }
    else {
        return false
    }
}

/**
 * UUID 생성
 * @returns 
 */
export function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
import React from "react";
import http from '../api';
import $ from 'jquery';
import * as StringUtil from '../utils/StringUtil';

import round_loading_bar from "../../../resources/img/round_loading_bar.gif";
import x_img from '../../../resources/img/x.svg';

/*
{
    this.state.rp_product_list_dialog_show_state // 초기값 : false
    ?    <RpProductListPopup
        iframe_page_id={"robomd_main_page"}
        iframe_html_id={"robomd_rp_1"}
        popup_close={() => {this.setState({rp_product_list_dialog_show_state: false});}}
        // popup_close={this.rpProductListPopupClose.bind(this)}
        />
    :   ""
}
 */
class RpProductListPopup extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
            product_list: [],
        }
    }

    componentDidMount() {
        this.loadRpEventProductList(); 

        $(window).resize(function () {
            $("#rp_product_list_popup_default_tbody_area").css("width", $("#rp_product_popup_table_area").width())
        })
    }

    // 진열 상품 목록 불러오기
    async loadRpEventProductList() {
        this.setState({
            show_rp_product_list_table_loading_bar : true,
        });
        
        await http.get("/rest/shop/rp/event/select/v2?iframe_page_id="+this.props.iframe_page_id+"&iframe_html_id="+this.props.iframe_html_id)
		.then(res => {
			if (res.data.code == "200") {
                this.setState({
                    rp_product_list : res.data["list"],
                    show_rp_product_list_table_loading_bar : false,
                }, ()=>{
                    // 상품 이미지 byte로 변환
                    const {rp_product_list} = this.state;
                    for(let i=0;i<rp_product_list.length;i++){
                        // 이미지가 재로딩 되지 않은 경우에만 byte변환 api를 타도록 한다
                        if(rp_product_list[i]["image_reload_yn"]!="Y"){
                            this.changeImageToByte(rp_product_list[i]["image_url"],i,"rp_product_list");
                        }
                    }
                });
            }
        })
        .catch(error => {
            this.setState({
                show_rp_product_list_table_loading_bar : false,
            });
        });
    }

    // 이미지 백엔드에서 byte로 변환
    async changeImageToByte(url,idx,product_list_name){
        const sendForm = {
            img_url : url,
        };
        await http.get("/rest/common/v2/backImageLoad", {params:sendForm})
        .then(res => {
            if (res.data != null && res.data != '') {
                let product_list = this.state[product_list_name];
                product_list[idx]["image_url"] = "data:image/png;base64," + window.btoa(String.fromCharCode.apply(null, new Uint8Array(res.data)));
                product_list[idx]["image_reload_yn"] = "Y";
                this.setState({[product_list_name] : product_list});
            }
        })
        .catch(error => {
        });
    }

	render () {

		return (
			<>
            {
                <>
                    {/* 진열 상품 목록 팝업 */}
                    <div class="dialog_bg" style={{zIndex: "10000"}}>
                        <div id="rp_product_dialog" class="dialog_layer dialog_confirm_pause">
                            <div>
                                <div id="rp_product_dialog_close_area" onClick={()=>this.props.popup_close()}>
                                    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.0704 15.1788C16.1878 15.2973 16.2536 15.4573 16.2536 15.6241C16.2536 15.7909 16.1878 15.9509 16.0704 16.0694C15.9509 16.1849 15.7913 16.2495 15.6251 16.2495C15.4589 16.2495 15.2992 16.1849 15.1798 16.0694L10.0001 10.8819L4.82041 16.0694C4.70095 16.1849 4.54127 16.2495 4.3751 16.2495C4.20892 16.2495 4.04925 16.1849 3.92979 16.0694C3.81242 15.9509 3.74658 15.7909 3.74658 15.6241C3.74658 15.4573 3.81242 15.2973 3.92979 15.1788L9.11728 9.99912L3.92979 4.81943C3.83012 4.698 3.77919 4.54383 3.78689 4.38693C3.7946 4.23002 3.8604 4.08159 3.97148 3.9705C4.08256 3.85942 4.231 3.79362 4.3879 3.78592C4.54481 3.77821 4.69898 3.82914 4.82041 3.92881L10.0001 9.11631L15.1798 3.92881C15.3012 3.82914 15.4554 3.77821 15.6123 3.78592C15.7692 3.79362 15.9176 3.85942 16.0287 3.9705C16.1398 4.08159 16.2056 4.23002 16.2133 4.38693C16.221 4.54383 16.1701 4.698 16.0704 4.81943L10.8829 9.99912L16.0704 15.1788Z" fill="#333333"></path>
                                    </svg>
                                </div>
                                <div id="rp_product_popup_title">
                                    상품 목록
                                </div>
                            </div>
                            {/* rp_product_popup_table_area 넓이와 rp_product_popup_table 넓이가 같으면 overflow-x:hidden */}
                            <div id="rp_product_popup_table_area" style={{
                                                                            overflowX:$("#rp_product_popup_table").width() == $("#rp_product_popup_table_area").width() 
                                                                                    ? "hidden" 
                                                                                    :   this.state.rp_product_list !=null && this.state.rp_product_list.length > 0
                                                                                        ? "auto"
                                                                                        : "hidden" 
                                                                        }}
                            > 
                                <table id="rp_product_popup_table" class="selected_list_table list_main_board_table">
                                    <thead>
                                        <tr>
                                            <th class="list_main_td" style={{display:"none"}}>
                                                <input class="list_guide_radio" type="checkbox" id="selected_list_main_board_checkbox_all" name="selected_list_main_board_checkbox_all" onChange={e => this.onChangeEventProductListCheckAll(e)}/>
                                                <label for="selected_list_main_board_checkbox_all">
                                                    <svg class="check_off" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="12" cy="12" r="12" fill="white"/>
                                                        <path d="M17.7462 8.59615C17.9143 8.77374 18.005 9.01078 17.9986 9.25524C17.9921 9.49969 17.8888 9.73158 17.7115 9.9L10.95 16.3615C10.7752 16.526 10.5438 16.6169 10.3039 16.6154C10.0674 16.6163 9.83984 16.5253 9.66923 16.3615L6.28847 13.1308C6.1947 13.049 6.11844 12.949 6.06427 12.837C6.0101 12.725 5.97915 12.6031 5.97327 12.4788C5.96739 12.3545 5.9867 12.2303 6.03005 12.1137C6.07339 11.997 6.13988 11.8903 6.22551 11.8C6.31113 11.7097 6.41413 11.6377 6.52832 11.5882C6.6425 11.5387 6.76551 11.5129 6.88995 11.5121C7.01439 11.5114 7.13769 11.5359 7.25244 11.584C7.36719 11.6322 7.47103 11.703 7.5577 11.7923L10.3039 14.4115L16.4423 8.56154C16.6199 8.39342 16.8569 8.30265 17.1014 8.30914C17.3458 8.31563 17.5777 8.41885 17.7462 8.59615ZM24 12C24 14.3734 23.2962 16.6934 21.9776 18.6668C20.6591 20.6402 18.7849 22.1783 16.5922 23.0865C14.3995 23.9948 11.9867 24.2324 9.65892 23.7694C7.33115 23.3064 5.19295 22.1635 3.51472 20.4853C1.83649 18.807 0.693605 16.6689 0.230582 14.3411C-0.232441 12.0133 0.00519936 9.60051 0.913451 7.4078C1.8217 5.21508 3.35977 3.34094 5.33316 2.02236C7.30655 0.703788 9.62663 0 12 0C15.1807 0.0060992 18.2294 1.27234 20.4785 3.52146C22.7277 5.77057 23.9939 8.81927 24 12ZM22.1538 12C22.1538 9.99176 21.5583 8.02862 20.4426 6.35882C19.3269 4.68903 17.7411 3.38759 15.8857 2.61907C14.0303 1.85055 11.9887 1.64947 10.0191 2.04126C8.04943 2.43305 6.24019 3.40011 4.82015 4.82015C3.40011 6.24019 2.43305 8.04943 2.04126 10.0191C1.64947 11.9887 1.85055 14.0303 2.61907 15.8857C3.38759 17.7411 4.68904 19.3269 6.35883 20.4426C8.02862 21.5583 9.99176 22.1538 12 22.1538C14.692 22.1508 17.2729 21.08 19.1765 19.1765C21.08 17.2729 22.1508 14.692 22.1538 12Z" fill="#D1D1D1"/>
                                                    </svg> 
                                                    <svg class="check_on" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="12" cy="12" r="12" fill="#333333"/>
                                                        <path d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21508 0.913451 7.4078C0.00519941 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6934 24 14.3734 24 12C23.9939 8.81927 22.7277 5.77057 20.4785 3.52146C18.2294 1.27234 15.1807 0.0060992 12 0ZM17.7115 9.9L10.95 16.3615C10.7752 16.526 10.5438 16.6169 10.3038 16.6154C10.1865 16.6171 10.0701 16.5955 9.96115 16.5519C9.85223 16.5084 9.75302 16.4436 9.66923 16.3615L6.28847 13.1308C6.1947 13.049 6.11844 12.949 6.06427 12.837C6.0101 12.725 5.97915 12.6031 5.97327 12.4788C5.96739 12.3545 5.9867 12.2303 6.03005 12.1137C6.07339 11.997 6.13988 11.8903 6.22551 11.8C6.31113 11.7097 6.41413 11.6377 6.52832 11.5882C6.6425 11.5387 6.76551 11.5129 6.88995 11.5121C7.01439 11.5114 7.13769 11.5359 7.25244 11.584C7.36719 11.6322 7.47103 11.703 7.5577 11.7923L10.3038 14.4115L16.4423 8.56154C16.6218 8.40495 16.8549 8.3238 17.0928 8.33505C17.3307 8.34631 17.5551 8.4491 17.719 8.62194C17.8829 8.79477 17.9736 9.02428 17.9722 9.26246C17.9708 9.50065 17.8774 9.72908 17.7115 9.9Z" fill="#10CFC9"/>
                                                    </svg>
                                                </label>
                                            </th>
                                            <th class="list_main_td bg_color_E3E3E3">사진</th>
                                            <th class="list_main_td bg_color_E3E3E3">상품명</th>
                                            <th class="list_main_td bg_color_E3E3E3">상품등록일</th>
                                            <th class="list_main_td bg_color_E3E3E3">조회수</th>
                                            <th class="list_main_td bg_color_E3E3E3">판매가</th>
                                            <th class="list_main_td bg_color_E3E3E3">할인가</th>
                                            <th class="list_main_td bg_color_E3E3E3">판매수</th>
                                            <th class="list_main_td bg_color_E3E3E3">판매액</th>
                                            <th class="list_main_td bg_color_E3E3E3">리뷰수</th>
                                            <th class="list_main_td bg_color_E3E3E3">리뷰 평점</th>
                                        </tr>
                                    </thead>

                                {this.state.rp_product_list !=null && this.state.rp_product_list.length > 0
                                    ? <tbody id="selected_list_table_tbody">
                                        {this.state.rp_product_list.map((obj, idx) => (
                                            <tr>
                                                <td style={{display:"none"}}>
                                                    <input class="list_guide_radio" type="checkbox" id={"selected_list_main_board_checkbox_"+obj.product_no} name="selected_list_main_board_checkbox" onChange={e => this.onChangeEventProductListCheck(e,idx)}/>
                                                    <label for={"selected_list_main_board_checkbox_"+obj.product_no}>
                                                        <svg class="check_off" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="12" cy="12" r="12" fill="white"/>
                                                            <path d="M17.7462 8.59615C17.9143 8.77374 18.005 9.01078 17.9986 9.25524C17.9921 9.49969 17.8888 9.73158 17.7115 9.9L10.95 16.3615C10.7752 16.526 10.5438 16.6169 10.3039 16.6154C10.0674 16.6163 9.83984 16.5253 9.66923 16.3615L6.28847 13.1308C6.1947 13.049 6.11844 12.949 6.06427 12.837C6.0101 12.725 5.97915 12.6031 5.97327 12.4788C5.96739 12.3545 5.9867 12.2303 6.03005 12.1137C6.07339 11.997 6.13988 11.8903 6.22551 11.8C6.31113 11.7097 6.41413 11.6377 6.52832 11.5882C6.6425 11.5387 6.76551 11.5129 6.88995 11.5121C7.01439 11.5114 7.13769 11.5359 7.25244 11.584C7.36719 11.6322 7.47103 11.703 7.5577 11.7923L10.3039 14.4115L16.4423 8.56154C16.6199 8.39342 16.8569 8.30265 17.1014 8.30914C17.3458 8.31563 17.5777 8.41885 17.7462 8.59615ZM24 12C24 14.3734 23.2962 16.6934 21.9776 18.6668C20.6591 20.6402 18.7849 22.1783 16.5922 23.0865C14.3995 23.9948 11.9867 24.2324 9.65892 23.7694C7.33115 23.3064 5.19295 22.1635 3.51472 20.4853C1.83649 18.807 0.693605 16.6689 0.230582 14.3411C-0.232441 12.0133 0.00519936 9.60051 0.913451 7.4078C1.8217 5.21508 3.35977 3.34094 5.33316 2.02236C7.30655 0.703788 9.62663 0 12 0C15.1807 0.0060992 18.2294 1.27234 20.4785 3.52146C22.7277 5.77057 23.9939 8.81927 24 12ZM22.1538 12C22.1538 9.99176 21.5583 8.02862 20.4426 6.35882C19.3269 4.68903 17.7411 3.38759 15.8857 2.61907C14.0303 1.85055 11.9887 1.64947 10.0191 2.04126C8.04943 2.43305 6.24019 3.40011 4.82015 4.82015C3.40011 6.24019 2.43305 8.04943 2.04126 10.0191C1.64947 11.9887 1.85055 14.0303 2.61907 15.8857C3.38759 17.7411 4.68904 19.3269 6.35883 20.4426C8.02862 21.5583 9.99176 22.1538 12 22.1538C14.692 22.1508 17.2729 21.08 19.1765 19.1765C21.08 17.2729 22.1508 14.692 22.1538 12Z" fill="#D1D1D1"/>
                                                        </svg> 
                                                        <svg class="check_on" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="12" cy="12" r="12" fill="#333333"/>
                                                            <path d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21508 0.913451 7.4078C0.00519941 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6934 24 14.3734 24 12C23.9939 8.81927 22.7277 5.77057 20.4785 3.52146C18.2294 1.27234 15.1807 0.0060992 12 0ZM17.7115 9.9L10.95 16.3615C10.7752 16.526 10.5438 16.6169 10.3038 16.6154C10.1865 16.6171 10.0701 16.5955 9.96115 16.5519C9.85223 16.5084 9.75302 16.4436 9.66923 16.3615L6.28847 13.1308C6.1947 13.049 6.11844 12.949 6.06427 12.837C6.0101 12.725 5.97915 12.6031 5.97327 12.4788C5.96739 12.3545 5.9867 12.2303 6.03005 12.1137C6.07339 11.997 6.13988 11.8903 6.22551 11.8C6.31113 11.7097 6.41413 11.6377 6.52832 11.5882C6.6425 11.5387 6.76551 11.5129 6.88995 11.5121C7.01439 11.5114 7.13769 11.5359 7.25244 11.584C7.36719 11.6322 7.47103 11.703 7.5577 11.7923L10.3038 14.4115L16.4423 8.56154C16.6218 8.40495 16.8549 8.3238 17.0928 8.33505C17.3307 8.34631 17.5551 8.4491 17.719 8.62194C17.8829 8.79477 17.9736 9.02428 17.9722 9.26246C17.9708 9.50065 17.8774 9.72908 17.7115 9.9Z" fill="#10CFC9"/>
                                                        </svg>
                                                    </label>
                                                </td>
                                                <td>
                                                    <img src={obj.image_url}/>
                                                </td> 
                                                <td>
                                                    <h4 class="text_extrasmall" style={{textAlign:"left", paddingLeft: "1.5%"}}>
                                                        {obj.product_name}
                                                    </h4>
                                                </td> 
                                                <td>{obj.created_date}</td>
                                                <td>{StringUtil.numberComma(obj.hit_cnt)}</td>
                                                <td>{obj.price}</td>
                                                <td>{obj.pc_discount_price}</td>
                                                <td>{StringUtil.numberComma(obj.order_cnt)}</td>
                                                <td>{StringUtil.numberComma(obj.order_price)}</td>
                                                <td>{StringUtil.numberComma(obj.review_cnt)}</td>
                                                <td>{obj.review_point}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    : this.state.show_rp_product_list_table_loading_bar
                                        ?   <tbody style={{position: "relative", height:"200px"}}>
                                                <div id="rp_product_list_popup_default_tbody_area" style={{width:$("#rp_product_popup_table_area").width(), height:"200px",position: "absolute",display:"flex",justifyContent:"center"}}>
                                                    <img src={round_loading_bar} style={{width:"200px"}}></img>
                                                </div>
                                            </tbody>
                                        :   <tbody style={{position: "relative", height:"200px"}}>
                                                <div id="rp_product_list_popup_default_tbody_area" style={{width:$("#rp_product_popup_table_area").width(), height:"200px",position: "absolute",display:"flex",justifyContent:"center"}}>
                                                    <dlv style={{alignSelf:"center", color:"#878B90"}}>
                                                    상품이 없습니다.
                                                    </dlv>
                                                </div>
                                            </tbody>
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            }
			</>
		);
	}
}

export default RpProductListPopup;
export var FILE_DOMAIN = "https://s3.ap-northeast-2.amazonaws.com/";
export var CURATION = "큐레이션";
export var QUESTION = "질문";
export var ANSWER = "답변";
export var RESULT = "결과";
export var LIST = "목록";
export var CREATE = "만들기";
export var INFO = "정보";
export var TITLE = "제목";
export var DESCRIPTION = "설명";
export var STATUS = "현황";
export var REACT = "반응";
export var SHARE = "공유";
export var REPORT =  "분석 리포트";
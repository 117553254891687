import React from "react";
import $ from 'jquery';

/**
 * button_color : 버튼 기본 컬러, null 이면 기본값 적용
 * button_hover_color : 버튼 hover시 컬러, null 이면 기본값 적용
 * content : content2가 있으면 제목으로 사용, content2가 없으면 내용으로 사용 (폰트 굵기는 제목, 내용 상관없이 똑같음)
 * content2 : 제목과 내용을 따로 입력 해야 할 때 내용으로 사용 (content 보다 얇은 굵기의 폰트로 사용.)
 */
class BasicDialogV2 extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
            button_color: '#00e2e6',
            button_hover_color: '#00d3e0',
        }
    }

    componentWillMount() {
        if(this.props.button_color != null && this.props.button_color != ''){
            this.setState({button_color:this.props.button_color,})
        }
        if(this.props.button_hover_color != null && this.props.button_hover_color != ''){
            this.setState({button_hover_color:this.props.button_hover_color,})
        }
    }

    componentDidMount() {
        let this_component = this;
        $(document).ready(function(){
            $("#common_alert_dialog_btn").hover(function(){
                //요소에 마우스를 올렸을 때
                $(this).css('background-color',this_component.state.button_hover_color);  
                },function(){
                //요소에 마우스를 올린뒤 떨어졌을때   
                $(this).css('background-color',this_component.state.button_color);
                });
        });
        
    }

	render () {
        
		return (
			<>
				{/* 기본 다이얼로그 V2 */}
                <div class="common_dialog">
                    <div class="dialog_insert" style={{
                        width:this.props.width ? this.props.width : null
                    }}>
                        {
                            this.props.content2 != null && this.props.content2 != ""
                            ?   
                                <>
                                    <div class="dialog_insert_txt" dangerouslySetInnerHTML={{__html: this.props.content}} style={{padding: "48px 0px 0px 0px"}}>
                                    </div>
                                    <div class="dialog_insert_content" dangerouslySetInnerHTML={{__html: this.props.content2}} style={{padding: "8px 0px 48px 0px"}}>
                                    </div>
                                </>
                            :   <div class="dialog_insert_txt" dangerouslySetInnerHTML={{__html: this.props.content}}>
                                </div>
                        }
                        <button id="common_alert_dialog_btn" style={{backgroundColor:this.state.button_color}}
                        onClick={this.props.button_list[0].event_method} onKeyDown={e => (e.key=='Enter' ? this.props.button_list[0].event_method : "")} autoFocus={true}>
                            {this.props.button_list[0].title}
                        </button>
                    </div>
                </div>
			</>
		);
	}
}

export default BasicDialogV2;
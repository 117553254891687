import React, { useState } from 'react';
import x_img from '../../../resources/img/x.svg';
import back_arrow from '../../../resources/img/back_arrow.svg';

// function PopupFrame({ children, title, titleFontSize, content, maxWidth, onClose}) {
class PopupFrame extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render () {
		const {children, title, titleIcon, titleIconWidth, titleIconHeight, titleIconMargin, titleFontSize, headerMarginBottom, content, maxWidth, onClose, backAction, className} = this.props;
        return (
            <div className={"common_popup_frame "+ className}>
                <div className={"common_popup_frame_div"} style={{maxWidth: maxWidth, width: "100%"}}>
                    <div className={"common_popup_frame_header"} style={{marginBottom:headerMarginBottom}}>
                        <div className={"common_popup_frame_title"}>
                            {backAction &&
                                <button onClick={backAction} className={"back_btn"}>
                                    <img src={back_arrow} alt="뒤로가기 버튼"/>
                                </button>}
                            <div className={"common_popup_frame_title_icon"}>
                                <h2 className="text_16" style={{fontSize : titleFontSize}} dangerouslySetInnerHTML={{__html: title}}></h2>
                                {titleIcon
                                ?<img
                                    className={"title_icon"}
                                    style={{
                                        width : titleIconWidth,
                                        height : titleIconHeight,
                                        margin : titleIconMargin,
                                    }}
                                    src={titleIcon}
                                />
                                : ""}
                            </div>
                            <button onClick={onClose}>
                                <img src={x_img} alt="닫기 버튼"/>
                            </button>
                        </div>
                        <h3 className="text_12" style={{marginTop:"3px"}} dangerouslySetInnerHTML={{__html: content}}></h3>
                    </div>
                    { children }
                </div>
            </div>
        )
    };
}

PopupFrame.defaultProps = {
    title: null,
    titleFontSize: "16px",
    content: null,
    headerMarginBottom : "24px",
    maxWidth: "1200px",
    className : "",
    titleIcon: "",
    titleIconWidth: "20px",
    titleIconHeight: "20px",
    titleIconMargin: "0px 0px 0px 6px",
}

export default PopupFrame;
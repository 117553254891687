/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
import axios from 'axios';
import http from '../modules/api';
import {Route, Switch, NavLink, Link} from "react-router-dom";
import $ from 'jquery';

import bannerType1 from "../../resources/img/event_typeA.png";
import bannerType2 from "../../resources/img/event_typeB.png";
import bannerType3 from "../../resources/img/event_typeC.png";
import bannerType4 from "../../resources/img/event_typeD.png";


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SketchPicker } from 'react-color'
import Pagination from "react-js-pagination";
import ReactWaterMark from 'react-watermark-component';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-78553461-8');

var ga_label = sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")";
var isTmp = true; // 배너 업테이트 할지 여부
var startIdxChange = false; // 상품 페이징 클릭시 true
const beginAlarm = function() { console.log('start alarm'); };
const w_options = {
    chunkWidth: 200,
    chunkHeight: 60,
    textAlign: 'left',
    textBaseline: 'bottom',
    globalAlpha: 0.17,
    // font: '30px Microsoft Yahei',
    font: '30px Malgun Gothic',
    rotateAngle: -0.26,
    fillStyle: '#666'
  }
export class EventForm extends React.Component {
    state = {
        event : {},
        select_tmp_item_list : [],
        select_item_list : [],
        item_list : [],
        keyword_list : [],
        bannerTypeList : [{}],
        mobileBannerTypeList : [],

        product_name: "",

        background: '#fff',
        background2: '#fff',
        background3: '#fff',
        background4: '#fff',

        event_seq:"",
        start_date: "",
        start_date_format: "",
        end_date: "",
        end_date_format: "",
        lang_type: "",
        title: "",
        foreign_title: "",
        event_type_cd: "",
        event_type_name: "",
        event_mobile_type_cd: "",
        event_mobile_type_name: "",
        event_tmp_type: "",
        event_tmp_type_name: "",
        event_mobile_tmp_type: "",
        event_mobile_tmp_type_name: "",
        title_sentence_cd: "",
        content_sentence_cd: "",
        display_yn:"" ,

        keyword_cd: "",
        keyword: "",
        image: "",
        product_no: "",

        activePage: 1,
        totalCnt: 0,
        startIdx: 0,
        rowcnt:10,

        bannerImgWidth: 0 + "%",

        user_seq: "",
        shop_no: "",

        reg_user_seq: "",
        reg_date: "",

    };

    componentDidMount(e) {
        // $("#robomd_area").hide();
        $("#modalBack").hide();
        $("#modal").hide();
        $("#modal_banner_type").hide();
        $("#modal_mobile_banner_type").hide();
        $("#modal_mirror_banner").hide();
        $("#modal_keyword").hide();

        $("#btnLoadItem").click(function() {
            $("#modalBack").show();
            $("#modal").show();
            $("body").css("background-color", "#a6a6a6");
            $("#modalBack").css("background-color", "#a6a6a6");
            $("#page-wrapper").css("background-color", "#a6a6a6");
        });
        $("#btn_banner_type").click(function() {
            $("#modalBack").show();
            $("#modal_banner_type").show();
            $("body").css("background-color", "#a6a6a6");
            $("#modalBack").css("background-color", "#a6a6a6");
            $("#page-wrapper").css("background-color", "#a6a6a6");
        });
        $("#btn_mobile_banner_type").click(function() {
            $("#modalBack").show();
            $("#modal_mobile_banner_type").show();
            $("body").css("background-color", "#a6a6a6");
            $("#modalBack").css("background-color", "#a6a6a6");
            $("#page-wrapper").css("background-color", "#a6a6a6");
        });
        $("#btn_keyword").click(function() {
            $("#modalBack").show();
            $("#modal_keyword").show();
            $("body").css("background-color", "#a6a6a6");
            $("#modalBack").css("background-color", "#a6a6a6");
            $("#page-wrapper").css("background-color", "#a6a6a6");
        });
        $(".modalClose").click(function() {
            $("#modalBack").hide();
            $("#modal").hide();
            $("#modal_keyword").hide();
            $("#modal_banner_type").hide();
            $("#modal_mobile_banner_type").hide();
            $("#modal_mirror_banner").hide();
            $("body").css("background-color", "white");
            $("#modalBack").css("background-color", "white");
            $("#page-wrapper").css("background-color", "white");
        });

        // this.getSetting();
        this.loadEventDetail(this.getUrlParams()["event_seq"]);
    }

    componentWillUnmount () {
		$("#modalBack").hide();
        $("#modal").hide();
        $("#modal_keyword").hide();
        $("#modal_banner_type").hide();
        $("#modal_mobile_banner_type").hide();
        $("#modal_mirror_banner").hide();
        $("body").css("background-color", "white");
        $("#modalBack").css("background-color", "white");
        $("#page-wrapper").css("background-color", "white");
	}

    getUrlParams() {
        var params = {};
        window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(str, key, value) { params[key] = value; });
        return params;
    }

    onClickSelectItem = e => {
        if (this.state.item_list != null && this.state.item_list.length > 0) {
            return false;
        }

        this.loadCafe24Item();
    }
   
    onClickSearchItem = e => {
        this.loadCafe24Item();
    }

    loadCommonCode(upper_code_cd, banner_type) {
		http.get("/rest/common/cafe24Code?upper_code_cd="+upper_code_cd)
		.then(res => {
            console.log(res.data);
            for(var idx in res.data) {
                if(res.data[idx].code_cd === banner_type) {
                    this.setState({"lang_type": res.data[idx].val3});
                }
            }
        })
    }
    loadEventDetail(event_seq) {
		http.get("/rest/common/admin/event/detail?event_seq="+event_seq)
		.then(res => {

            if (res.data.err_code == 401) {
                window.location.href = "/logout";
            }

            var event = res.data["event"];
            var bannerTypeList = res.data["bannerTypeList"];
            var mobileBannerTypeList = res.data["mobileBannerTypeList"];
            console.log(bannerTypeList);
			if(event != null) {
                console.log("### start_date: "+event.start_date);
                console.log("### start_date: "+new Date(event.start_date));
                var start_date = new Date(event.start_date);
                var end_date = new Date(event.end_date);
                // console.log(res.data.eventProduct);
                // console.log(res.data.eventProduct[0]);
                // console.log(res.data.eventProduct[0].product_no);
				this.setState( {
                    event_seq: event.event_seq,
                    start_date: start_date,
                    start_date_format: start_date.getFullYear() + "-"+ ("0" + parseInt(start_date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(start_date.getDate())).slice(-2),
                    end_date: end_date,
                    end_date_format: end_date.getFullYear() + "-"+ ("0" + parseInt(end_date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(end_date.getDate())).slice(-2),
                    lang_type: event.lang_type,
                    title: event.title,
                    foreign_title: event.foreign_title,
                    event_type_cd: event.event_type_cd,
                    event_mobile_type_cd: event.event_mobile_type_cd,
                    event_type_name: event.event_type_name,
                    event_mobile_type_name: event.event_mobile_type_name,
                    keyword_cd: event.keyword_cd,
                    keyword: event.keyword,
                    image: event.img_link,
                    background: event.color_code_1,
                    background2: event.color_code_2,
                    background3: event.color_code_3,
                    background4: event.color_code_4,
                    bannerTypeList: bannerTypeList,
                    mobileBannerTypeList: mobileBannerTypeList,
                    user_seq: event.user_seq,
                    shop_no: event.shop_no,
                    select_item_list: res.data.eventProduct,
                    product_no: res.data.eventProduct[0].product_no,
                    display_yn: event.display_yn,
                    title_sentence_cd: event.title_sentence_cd,
                    content_sentence_cd: event.content_sentence_cd,
                    sentence: event.sentence,
                    eng_sentence: event.eng_sentence,
                    reg_user_seq: event.reg_user_seq,
                    reg_date: event.reg_date,
                    dominant_color: event.dominant_color,
                    is_product_change: event.is_product_change,
                } );
            }
            
            // if(bannerTypeList.length > 0) {
            //     this.setState({bannerImgWidth : (100 / bannerTypeList.length) + "%"});
            // }

            if(res.data.bannerTypeList.length > 0) {
                this.setState({bannerTypeList : res.data.bannerTypeList});
                if (res.data.bannerTypeList.length >= 4) {
                    this.setState({bannerImgWidth : parseInt((100 / 4)-6) + "%"});
                } else {
                    this.setState({bannerImgWidth : parseInt((100 / res.data.bannerTypeList.length)-6) + "%"});
                }
            }
            if(res.data.mobileBannerTypeList.length > 0) {
                this.setState({mobileBannerTypeList : res.data.mobileBannerTypeList});
                if (res.data.mobileBannerTypeList.length >= 4) {
                    this.setState({mobileBannerImgWidth : parseInt((100 / 4)-6) + "%"});
                } else {
                    this.setState({mobileBannerImgWidth : parseInt((100 / res.data.mobileBannerTypeList.length)-6) + "%"});
                }
            }

            this.insertEventTmp();
		})
		.catch(error => {
			// console.log ("===== error =====");
			// console.log (error);
			// console.log ("===== error request =====");
			// console.log(error.request);
		 });
    }

    componentDidUpdate(e) {
        // console.log("######### componentDidUpdate ##########")
        if (isTmp) {
            // this.insertEventTmp(); //미리보기 버튼 클릭시 동작 하도록 변경함.
        }
        isTmp = true;

        if (startIdxChange) {
            this.loadCafe24Item()
        }
        startIdxChange = false;
	}

    insertEventTmp(call_cls) {
        const formData = new FormData();
        formData.append('event_seq',this.state.event_seq);
        formData.append('start_date',this.state.start_date_format);
        formData.append('end_date',this.state.end_date_format);
        formData.append('title',this.state.title);
        formData.append('foreign_title',this.state.foreign_title);
        formData.append('display_yn',this.state.display_yn);
        formData.append('event_type_cd',this.state.event_type_cd);
        formData.append('event_mobile_type_cd',this.state.event_mobile_type_cd);
        formData.append('event_type_name',this.state.event_type_name);
        formData.append('event_mobile_type_name',this.state.event_mobile_type_name);
        formData.append('keyword_cd',this.state.keyword);
        formData.append('img_link',this.state.image);
        formData.append('color_code_1',this.state.background);
        formData.append('color_code_2',this.state.background2);
        formData.append('color_code_3',this.state.background3);
        formData.append('color_code_4',this.state.background4);
        formData.append('user_seq',this.state.user_seq);
        formData.append('shop_no',this.state.shop_no);
        formData.append('product_no',this.state.product_no);
        formData.append('title_sentence_cd',this.state.title_sentence_cd);
        formData.append('content_sentence_cd',this.state.content_sentence_cd);
        formData.append('sentence',this.state.sentence);
        formData.append('eng_sentence',this.state.eng_sentence);
        formData.append('reg_user_seq',this.state.reg_user_seq);
        formData.append('reg_date',this.state.reg_date);
        formData.append('call_cls',call_cls);   //호출 구분 : 미리보기 호출시 이력 테이블 저장

        const config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded; charset=utf-8'
            }
        }
        
        http.post("/rest/common/insert/event/tmp", formData, config)
		.then(res => {
            console.log("임시저장 완료");
            // $('#robomd').reload();
            $("#robomd").attr('data',$("#robomd").attr('data'));
            $("#robomd_m").attr('data',$("#robomd_m").attr('data'));
        })
		.catch(error => {
			// console.log ("===== error =====");
			// console.log (error);
			// console.log ("===== error request =====");
			// console.log(error.request);
		 });
    }

    saveEvent =  e => {
        if (this.state.start_date_format == null || this.state.start_date_format == ""){
            alert("시작 일자를 지정해주세요.")
            return false;
        }
        if (this.state.end_date_format == null || this.state.end_date_format == ""){
            alert("종료 일자를 지정해주세요.")
            return false;
        }
        if (this.state.title == null || this.state.title == ""){
            alert("제목을 입력해주세요.")
            return false;
        }
        if (this.state.lang_type == "eng" && (this.state.foreign_title == null  || this.state.foreign_title == "" || this.state.foreign_title == "undefined")) {
            alert("영문 배너 제목을 입력해주세요.")
            return false;
        }
        if (this.state.background == null || this.state.background == ""){
            alert("색상을 선택해주세요.")
            return false;
        }
        if (this.state.event_type_cd == null || this.state.event_type_cd == ""){
            alert("PC 배너 유형을 선택해주세요.")
            return false;
        }
        if (this.state.event_mobile_type_cd == null || this.state.event_mobile_type_cd == ""){
            alert("mobile 배너 유형을 선택해주세요.")
            return false;
        }

        const formData = new FormData();
        formData.append('event_seq',this.state.event_seq);
        formData.append('user_seq',this.state.user_seq);
        formData.append('foreign_title',this.state.foreign_title);
        formData.append('title',this.state.title);
        formData.append('content',this.state.title);
        formData.append('img_link',this.state.image);
        // formData.append('title_sentence_cd',null);
        // formData.append('content_sentence_cd',null);
        formData.append('event_type_cd',this.state.event_type_cd);
        formData.append('event_mobile_type_cd',this.state.event_mobile_type_cd);
        // formData.append('keyword_cd',null);
        formData.append('color_code_1',this.state.background);
        formData.append('color_code_2',this.state.background2);
        formData.append('color_code_3',this.state.background3);
        formData.append('color_code_4',this.state.background4);
        formData.append('start_date',this.state.start_date_format);
        formData.append('end_date',this.state.end_date_format);
        formData.append('product_no',this.state.product_no);
        formData.append('display_yn',this.state.display_yn);
        formData.append('reg_user_seq',this.state.reg_user_seq);
        formData.append('reg_date',this.state.reg_date);
        const config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded; charset=utf-8'
            }
        }
		http.post("/rest/common/event/save", formData, config)
		.then(res => {
            if (res.data.err_msg != null) {
                alert(res.data.err_msg);
                if (res.data.err_code == 401) {
                    window.location.href = "/logout";
                }
            } else {
                alert("저장되었습니다.")
                window.location.reload();
            }
		})
		.catch(error => {
			// console.log ("===== error =====");
			// console.log (error);
			// console.log ("===== error request =====");
			// console.log(error.request);
		 });
    }

    loadCafe24Item() {
        http.get("/rest/common/list/product?product_name="+this.state.product_name+"&user_seq="+this.state.user_seq+"&shop_no="+this.state.shop_no
        +"&startidx="+this.state.startIdx+"&rowcnt="+this.state.rowcnt
        +"&order_type="+this.state.sorting+"&order_gubun="+this.state.orderGubun)
        .then( res => { 
            isTmp = false;
            this.setState({item_list : res.data.list, totalCnt : res.data.totalCnt});
            // console.log(res.data.list); 

            if (this.state.select_tmp_item_list != null && this.state.select_tmp_item_list != {}){
                for (var idx in res.data.list) {
                    if (res.data.list[idx].product_no == this.state.select_tmp_item_list[0].product_no) {
                        $("#modalContent tbody tr").css("background-color","white");
                        $("#"+this.state.select_tmp_item_list[0].product_no).css("background-color","#2ab88552");
                    }
                }
            }
        })
        .catch(
            error => { 
                // console.log("저장에 실패하였습니다."); 
                isTmp = true;
            }
        );

        // 자동배너 수정 - 상품검색
        ReactGA.event({
            category: "자동배너 수정",
            action: "상품검색 : " + this.state.product_name,
            label: ga_label
        });
    }

    reloadBanner = e => {
        //미리보기 호출시 이력 테이블 저장
        this.insertEventTmp('preview');
        
        $("#modalBack").show();
        $("#modal_mirror_banner").show();
        $("body").css("background-color", "#a6a6a6");
        $("#modalBack").css("background-color", "#a6a6a6");
        $("#page-wrapper").css("background-color", "#a6a6a6");
        // $("#robomd_area").show(function () {
        //     $([document.documentElement, document.body]).animate({
        //         scrollTop: $("#robomd").offset().top
        //     }, 2000);
        // });
        // $("#robomd").attr('data',$("#robomd").attr('data'));
    }

    // 엔터키 검색
	_handleKeyDown = e => {
		if (e.key === 'Enter') {
			this.state.startIdx = 0;
			this.state.activePage = 1;
			this.loadCafe24Item();
		}
      }
      
    handleTitleChange = e => {
        this.setState({title: e.target.value})
        // console.log(e.target.value)
        // console.log(this.state.title)
    }
    
    // 외국어 타이틀 변경
    handleForeignTitleChange = e => {
        this.setState({foreign_title: e.target.value})
        // console.log(e.target.value)
        // console.log(this.state.foreign_title)
    }

    // 페이징
	handlePageChange(pageNumber) {
        isTmp = false;
        startIdxChange = true;
        this.setState({activePage: pageNumber
                       , startIdx: ((pageNumber * 10) - this.state.rowcnt)});
		// this.state.startIdx = (pageNumber * 10) - this.state.rowcnt;
        // this.loadCafe24Item(); // 상품 조회 componentDidUpdate 에서 실행
	  }   
    
    // 달력 클릭
    handleChangeStartDate = date => {
		if (date != null) {
			this.setState({
				start_date_format: date.getFullYear() + "-"+ ("0" + parseInt(date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(date.getDate())).slice(-2),
				start_date: date
			});
		} else {
			this.setState({
				start_date_format: date,
				start_date: date
			});
		}
    };
    handleChangeEndDate = date => {
		if (date != null) {
			this.setState({
				end_date_format: date.getFullYear() + "-"+ ("0" + parseInt(date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(date.getDate())).slice(-2),
				end_date: date
			});
		} else {
			this.setState({
				end_date_format: date,
				end_date: date
			});
		}
	};

    handleTypeChange = e => {
        isTmp = false;

        console.log("##### event_tmp_type: "+e.target.value);
        if (e.target.value != null && e.target.value !="") {
            this.setState({event_tmp_type : e.target.value,
                           event_tmp_type_name : e.target.options[e.nativeEvent.target.selectedIndex].text});
        }
    };
    handleMobileTypeChange = e => {
        isTmp = false;

        console.log("##### event_tmp_type: "+e.target.value);
        console.log("##### event_tmp_type_name: "+e.target.options[e.nativeEvent.target.selectedIndex].text);
        if (e.target.value != null && e.target.value !="") {
            this.setState({event_mobile_tmp_type : e.target.value,
                           event_mobile_tmp_type_name : e.target.options[e.nativeEvent.target.selectedIndex].text});
        }
    };

    handleChangeComplete = (color) => {
        this.setState({ 
            // background: color.hex + Math.round(color.rgb.a * 255).toString(16)
            background: color.hex
        });

        $("#image_2_0").css("background-color", "rgb("+color.hex + Math.round(color.rgb.a * 255).toString(16)+")");

        // 자동배너 수정 - 배경색상1
        ReactGA.event({
            category: "자동배너 수정",
            action: "배경색상1 : " + color.hex,
            label: ga_label
        });

        console.log("##### color: "+color.hex + Math.round(color.rgb.a * 255).toString(16));

    };

    handleChangeComplete2 = (color) => {
        this.setState({ 
            // background2: color.hex + Math.round(color.rgb.a * 255).toString(16)
            background2: color.hex
        });

        // 자동배너 수정 - 배경색상2
        ReactGA.event({
            category: "자동배너 수정",
            action: "배경색상2 : " + color.hex,
            label: ga_label
        });

        console.log("##### color: "+color.hex + Math.round(color.rgb.a * 255).toString(16));
    };

    handleChangeComplete3 = (color) => {
        this.setState({ 
            // background3: color.hex + Math.round(color.rgb.a * 255).toString(16)
            background3: color.hex
        });

        // 자동배너 수정 - 배경색상3
        ReactGA.event({
            category: "자동배너 수정",
            action: "배경색상3 : " + color.hex,
            label: ga_label
        });

        console.log("##### color: "+color.hex + Math.round(color.rgb.a * 255).toString(16));

    };

    handleChangeComplete4 = (color) => {
        this.setState({ 
            // background4: color.hex + Math.round(color.rgb.a * 255).toString(16)
            background4: color.hex
        });

        // 자동배너 수정 - 배경색상4
        ReactGA.event({
            category: "자동배너 수정",
            action: "배경색상4 : " + color.hex,
            label: ga_label
        });

        console.log("##### color: "+color.hex + Math.round(color.rgb.a * 255).toString(16));

    };

    onClickModalTableItem = (e,
        product_no,
        product_code,
        product_name,
        image,
        category_name,
        retail_price,
        hit_cnt,
        sale_cnt,
        review_cnt
    ) => {
        isTmp = false;
        this.setState({
            select_tmp_item_list : [
                {
                    product_no: product_no,
                    product_code: product_code,
                    product_name: product_name,
                    image: image,
                    category_name: category_name,
                    retail_price: retail_price,
                    hit_cnt: hit_cnt,
                    sale_cnt: sale_cnt,
                    review_cnt: review_cnt
                }
            ]
        })

        $("#modalContent tbody tr").css("background-color","white");
        $("#"+product_no).css("background-color","#2ab88552");

        // 자동배너 수정 - 상품선택
        ReactGA.event({
            category: "자동배너 수정",
            action: "상품선택 : " + product_name,
            label: ga_label
        });
    }

    onClickApplySelectItem = e => {
        this.setState({ select_item_list : this.state.select_tmp_item_list
                      , image : this.state.select_tmp_item_list[0].image 
                      , product_no : this.state.select_tmp_item_list[0].product_no });

        // 자동배너 수정 - 상품선택 적용
        ReactGA.event({
            category: "자동배너 수정",
            action: "상품선택 적용 : " + this.state.select_tmp_item_list[0].product_name,
            label: ga_label
        });

        $(".modalClose").click();
    }

    onClickApplyBannerType = e => {
        var action = "변경안함";
        if (this.state.event_tmp_type != null && this.state.event_tmp_type != "") {
            action = this.state.event_tmp_type_name;
            this.setState({ event_type_cd : this.state.event_tmp_type
                        , event_type_name : this.state.event_tmp_type_name });
        }

        this.loadCommonCode("03000", this.state.event_tmp_type);

        // 자동배너 수정 - PC배너유형
        ReactGA.event({
            category: "자동배너 수정",
            action: "PC배너유형 : " + action,
            label: ga_label
        });

        $(".modalClose").click();
    }
    onClickApplyMobileBannerType = e => {
        var action = "변경안함";
        if (this.state.event_mobile_tmp_type != null && this.state.event_mobile_tmp_type != "") {
            action = this.state.event_mobile_tmp_type_name;
            this.setState({ event_mobile_type_cd : this.state.event_mobile_tmp_type
                        , event_mobile_type_name : this.state.event_mobile_tmp_type_name });
        }

        this.loadCommonCode("07000", this.state.event_mobile_tmp_type);

        // 자동배너 수정 - Mobile배너유형
        ReactGA.event({
            category: "자동배너 수정",
            action: "Mobile배너유형 : " + action,
            label: ga_label
        });
        
        $(".modalClose").click();
    }

    onClickApplyBannerTypeClose = e => {
        // 자동배너 수정 - 배너유형변경(취소)
        ReactGA.event({
            category: "자동배너 수정",
            action: (e.target.id == "pcBannerClose" ? "PC" : "Mobile") + "배너유형 : " + "취소",
            label: ga_label
        });
    }

    onClickSorting = (e) => {
		if (this.state.orderGubun == e.target.className.replace(" sorting","")) {
			if (this.state.sorting == "ASC")
			this.state.sorting = "DESC";
			else
			this.state.sorting = "ASC";
		} else {
			this.state.sorting = "DESC";
		}
		this.state.orderGubun = e.target.className.replace(" sorting","");
        this.state.currentPage = 1;
        this.state.activePage = 1;
        this.state.totalCnt = 0;
        this.state.startIdx = 0;
		this.loadCafe24Item();
    };
    
    onClickDisplayYn = e => {
        // $(".radio_display_yn:checked").prop("checked", false); 
        // $("#display_"+e.target.value).prop("checked", true); 
        this.setState({display_yn : e.target.value})

        // 자동배너 수정 - 진열여부
        ReactGA.event({
            category: "자동배너 수정",
            action: "진열여부 : " + e.target.value,
            label: ga_label
        });
    };

    handleTitleBlur = e => {
        console.log(e);
        // 자동배너 수정 - 배너제목
        ReactGA.event({
            category: "자동배너 수정",
            action: (e.target.id == "foreign_title" ? "(영문)" : "") + "배너제목 : " + e.target.value,
            label: ga_label
        });
    };

	render() {
        return (
            <div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
                <div id="setting_container">
					<h3>[{window.location.href.split("/")[3].charAt(0).toUpperCase() + window.location.href.split("/")[3].slice(1) }] 자동배너 수정</h3>
                    <br/>
                    <br/>
                    {/* reg_user_seq : {this.state.reg_user_seq}
                    reg_date : {this.state.reg_date} */}
                    {/* <div style={{width:"100%", borderTop:"1px solid #676867", margin: "0 0 0 0"}}>
                    <div style={{backgroundColor:"white", width:"120px", margin:"-10px auto 10px auto", padding:"0 10px 0 10px", color:"#676867", textAlign: "center"}}>
                         배너 기본 설정
                    </div>
                    </div> */}
                    <div className="flexBox eventSettingItemArea" style={{height:"70px"}}>
                        <div className="margin-vertical-auto eventSettingTitle" style={{width:"22%", lineHeight:"2rem"}}>
                            <label style={{marginTop: "auto", marginBottom: "auto"}}>진열 여부</label>
                        </div>
                        <div className="margin-vertical-auto" style={{width:"auto"}}>
                            <div style={{marginTop: "auto", marginBottom: "auto"}}>
                                {
                                    this.state.display_yn == "Y" 
                                    ? <input type="radio" className="radio_display_yn" name="display_Y" id="display_Y" value="Y" checked="true" onClick={this.onClickDisplayYn}/>
                                    : <input type="radio" className="radio_display_yn" name="display_Y" id="display_Y" value="Y" onClick={this.onClickDisplayYn}/>
                                }
                                진열  
                                &nbsp; 
                                {
                                    this.state.display_yn == "N" 
                                    ? <input type="radio" className="radio_display_yn" name="display_N" id="display_N" value="N" checked="true" onClick={this.onClickDisplayYn}/>
                                    : <input type="radio" className="radio_display_yn" name="display_N" id="display_N" value="N" onClick={this.onClickDisplayYn}/>
                                } 
                                미진열
                            </div>
                        </div>
                    </div>
                    
                    <div className="flexBox eventSettingItemArea" style={{height:"70px"}}>
                        <div className="margin-vertical-auto eventSettingTitle" style={{width:"22%", lineHeight:"2rem"}}>
                            <label style={{marginTop: "auto", marginBottom: "auto"}}>진열 기간</label>
                        </div>
                        <div className="margin-vertical-auto" style={{width:"auto"}}>
                                <div className="input-daterange input-group" id="datepicker">
                                <div className="input_disabled">
                                    <DatePicker name="start_date" id="start_date"
                                        selected={this.state.start_date}
                                        onSelect={this.handleSelect} //when day is clicked
                                        onChange={this.handleChangeStartDate} //only when value has changed
                                        placeholderText="시작일자"
                                        // showTimeSelect
                                        dateFormat="yyyy-MM-dd"
                                        disabled="true"
                                        autoComplete='off'
                                    />
                                </div>
                                <div className="input-group-addon" style={{width: "40px",height:"35px", lineHeight: "34px",textAlign:"center"}}>to</div>
                                <div className="input_disabled">
                                    <DatePicker name="end_date" id="end_date"
                                        selected={this.state.end_date}
                                        onSelect={this.handleSelect} //when day is clicked
                                        onChange={this.handleChangeEndDate} //only when value has changed
                                        placeholderText="종료일자"
                                        // showTimeSelect
                                        dateFormat="yyyy-MM-dd"
                                        disabled="true"
                                        autoComplete='off'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flexBox eventSettingItemArea" style={{height:"70px"}}>
                        <div className="margin-vertical-auto eventSettingTitle" style={{width:"22%"}}>
                            <label style={{marginTop: "auto", marginBottom: "auto"}}>배너 제목</label>
                        </div>
                        <div className="margin-vertical-auto flexBox" style={{flex:"1"}}>
                            <input type="text" id="title" style={{width:"100%"}} value={this.state.title} onChange={this.handleTitleChange} onBlur={this.handleTitleBlur}></input>
                        </div>
                    </div>
                    { this.state.lang_type == "eng" &&
                        <div className="flexBox eventSettingItemArea" style={{height:"70px"}}>
                            <div className="margin-vertical-auto eventSettingTitle" style={{width:"22%"}}>
                                <label style={{marginTop: "auto", marginBottom: "auto"}}>영문 배너 제목</label>
                            </div>
                            <div className="margin-vertical-auto flexBox" style={{flex:"1"}}>
                                <input type="text" id="foreign_title" style={{width:"100%"}} value={this.state.foreign_title == null || this.state.foreign_title == "undefined" ? "" : this.state.foreign_title} onChange={this.handleForeignTitleChange} onBlur={this.handleTitleBlur}></input>
                            </div>
                        </div>
                        
                    }
                    <div className="flexBox eventSettingItemArea" id="bannerTypeArea" style={{marginTop: "auto", marginBottom: "auto", height:"auto", marginTop: "20px"}}>
                        <div className="margin-vertical-auto eventSettingTitle" style={{width:"22%", lineHeight:"2rem"}}>
                            <label style={{marginTop: "auto", marginBottom: "auto"}}>PC 배너유형</label>
                        </div>
                        <div className="flexBox" style={{width:"200px", height:"auto" }}>
                            <div className="margin-vertical-auto">
                            {this.state.event_type_name}
                            </div>
                            <button className="greenButton margin-vertical-auto" id="btn_banner_type" style={{marginLeft:"20px"}}>
                            변경
                            </button>
                        </div>
                    </div>
                    <div className="flexBox eventSettingItemArea" id="bannerTypeArea" style={{marginTop: "auto", marginBottom: "auto", height:"auto", marginTop: "20px"}}>
                        <div className="margin-vertical-auto eventSettingTitle" style={{width:"22%", lineHeight:"2rem"}}>
                            <label style={{marginTop: "auto", marginBottom: "auto"}}>mobile 배너유형</label>
                        </div>
                        <div className="flexBox" style={{width:"200px", height:"auto" }}>
                            <div className="margin-vertical-auto">
                            {this.state.event_mobile_type_name}
                            </div>
                            <button className="greenButton margin-vertical-auto" id="btn_mobile_banner_type" style={{marginLeft:"20px"}}>
                            변경
                            </button>
                        </div>
                    </div>
                    {/* <div className="flexBox eventSettingItemArea" style={{height:"auto", marginTop:"20px"}}>
                        <div className="margin-vertical-auto eventSettingTitle" style={{width:"22%"}}>
                            <label style={{marginTop: "auto", marginBottom: "auto"}}>배너 이미지</label>
                        </div>
                        <div className="margin-vertical-auto flexBox" style={{width:"200px", height:"auto" }}>
                            <img src={this.state.image} style={{width:"140px"}}></img>
                        </div>
                    </div> */}
                </div>
                <div className="flexBox eventSettingItemArea" style={{height:"auto", marginTop:"30px"}}>
                    <div className="margin-vertical-auto eventSettingTitle" style={{width:"22%"}}>
                    <label style={{marginTop: "auto", marginBottom: "auto"}}>배경색상</label><br/>
                    {
                        this.state.is_product_change == "N"
                        ? <div className="flexBox">(대표색상: {this.state.dominant_color}&nbsp;&nbsp;<div style={{background:"RGB("+this.state.dominant_color+")", width:"20px", height:"20px", border:"black solid 1px"}}></div>)
                          <div className="fa fa-question-circle" style={{margin: "auto auto auto 10px"}}>
                            <div className="question-hover-area"><font style={{fontSize:"14px",  lineHeight:"15px", wordBreak:"keep-all"}}>Color Detector AI가 추출한 상품의 대표색상 입니다.<br/></font></div>
                          </div></div> 
                        : ""
                    }
                    </div>
                    <div className="margin-vertical-auto flexBox" style={{width:"78%", height:"auto" }}>
                    <div>
                        <div>색상1</div>
                        <SketchPicker
                            color={ this.state.background }
                            onChangeComplete={ this.handleChangeComplete }
                            disableAlpha
                        />
                    </div>
                    <div>
                        <div>색상2</div>
                        <SketchPicker
                            color={ this.state.background2 }
                            onChangeComplete={ this.handleChangeComplete2 }
                            disableAlpha
                        />
                    </div>
                    <div>
                        <div>색상3</div>
                        <SketchPicker
                            color={ this.state.background3 }
                            onChangeComplete={ this.handleChangeComplete3 }
                            disableAlpha
                        />
                    </div>
                    <div>
                        <div>색상4</div>
                        <SketchPicker
                            color={ this.state.background4 }
                            onChangeComplete={ this.handleChangeComplete4 }
                            disableAlpha
                        />
                    </div>
                    </div>
                </div>

                <div className="flexBox eventSettingItemArea" style={{height:"70px", marginTop:"50px"}}>
                    <div className="margin-vertical-auto eventSettingTitle" style={{width:"22%"}}>
                        <label style={{marginTop: "auto", marginBottom: "auto"}}>상품 고르기</label>
                    </div>
                    <div className="margin-vertical-auto flexBox" style={{width:"150px", height:"auto" }}>
                        <button className="greenButton" id="btnLoadItem" onClick={this.onClickSelectItem}>
                            불러오기
                        </button>
                    </div>
                </div>
                
                <div style={{width:"100%", borderTop:"1px solid #c3c3c3", margin: "0 0 0 0 "}}></div>


                <div className="table">
                    <table>
                        <colgroup>
                            <col width="*" />
                            <col width="130px" />
                            <col width="100px" />
                            <col width="100px" />
                            <col width="100px" />
                            <col width="100px" />
                            <col width="100px" />
                        </colgroup>
                        <thead>	
                            <tr>
                                <td style={{display: "none"}}>
                                    <p className="card-text">상품번호</p>
                                </td>
                                <td style={{display: "none"}}>
                                    <p className="card-text">상품코드</p>
                                </td>
                                <td>
                                    <p className="card-text">상품명</p>
                                </td>
                                <td className="user_name">
                                    <p className="card-text">이미지</p>
                                </td>
                                <td className="event_view_cnt">
                                    <p className="card-text">카테고리</p>
                                </td>
                                <td className="start_date">
                                    <p className="card-text">판매가</p>
                                </td>
                                <td className="end_date">
                                    <p className="card-text">조회수</p>
                                </td>
                                <td className="mod_date">
                                    <p className="card-text">구매수</p>
                                </td>
                                <td className="display_yn">
                                    <p className="card-text">리뷰수</p>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.select_item_list.map((item, idx) => (
                                <tr key={item.product_no}>
                                    <td style={{display: "none"}}>
                                        <p className="card-text">{item.product_no}</p>
                                    </td>
                                    <td style={{display: "none"}}>
                                        <p className="card-text">{item.product_code}</p>
                                    </td>
                                    <td>
                                        <p className="card-text" dangerouslySetInnerHTML={{__html: item.product_name}}></p>
                                    </td>
                                    <td>
                                        <p className="card-text"><img src={item.image} width="100px"/></p>
                                    </td>
                                    <td>
                                        <p className="card-text">{item.category_name}</p>
                                    </td>
                                    <td>
                                        <p className="card-text">{item.retail_price}원</p>
                                    </td>
                                    <td>
                                        <p className="card-text">{item.hit_cnt}</p>
                                    </td>
                                    <td>
                                        <p className="card-text">{item.sale_cnt}</p>
                                    </td>
                                    <td>
                                        <p className="card-text">{item.review_cnt}</p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                            

                <div className="width-100 flexBox" style={{marginTop:"50px", marginBottom:"50px"}}>
                    <div className="greenButton margin-auto cursor-pointer" onClick={this.reloadBanner} style={{padding:"0 6px 0 6px", lineHeight:"28px"}}>
                        미리보기
                    </div>
                    <button className="greenButton margin-auto" onClick={this.saveEvent}>
                        저장
                    </button>
                </div>
                {/* 배너 미리보기 팝업 */}
                <div id="modal_mirror_banner">
                    <div className="shadow-box modal_mirror_banner_content" style={{top: "8%"}}>
                        <div className="width-100" id="robomd_area" style={{paddingBottom: "10px"}}>
                            <div style={{width:"100%", borderTop:"1px solid #676867", margin: "15px 0 0 0 "}}>
                                <div style={{backgroundColor:"white", width:"130px", margin:"-10px auto 10px auto", padding:"0 10px 0 10px", color:"#676867", textAlign: "center"}}>
                                    미리보기
                                </div>
                            </div>
                            {/* <div style={{width:"100%", textAlign: "center", color:"#676867", marginBottom:"20px"}}>('미리보기'를 클릭하면 배너를 새로고침 합니다.)</div> */}
                            <ReactWaterMark
                                waterMarkText={"ROBOMD"}
                                openSecurityDefense
                                securityAlarm={beginAlarm}
                                options={w_options} >
                                <div className="flexBox width-100">
                                <object id="robomd" type="text/html" data={"/admin_banner.html?event_seq="+this.state.event_seq+"&platform_type=p"} type="text/html" style={{flex:"1",height:"25vw"}}></object>
                                &nbsp;&nbsp;
                                <object id="robomd_m" type="text/html" data={"/admin_banner.html?event_seq="+this.state.event_seq+"&platform_type=m"} type="text/html" style={{width:"30%",height:"40vw"}}></object>
                                </div>
                            </ReactWaterMark>
                        </div>
                        <div className="fa fa-close modalClose modal_mirror_banner_close" style={{top: "5%"}}></div>
                    </div>
                </div>
                {/* 상품 선택 팝업 */}
                <div id="modal">
                    <div id="modalContent" className="shadow-box">
                            <h3>상품 선택</h3>
                            상품중 하나를 선택해 주세요.
                            <br/><br/>

                            <div style={{display:"flex", marginBottom: '12px'}}>
							<input type="text" onChange={(e) => {
								// console.log(e.target.value)
								this.setState({ product_name: e.target.value })
							}} placeholder="검색어를 입력하세요." style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px"}} onKeyDown={this._handleKeyDown}></input>
							<button className = "greenButton" onClick={this.onClickSearchItem} style={{width: "45px",height: "35px", margin: 'auto'}}>검색</button>
						    </div>

                            <div className="table item_modal_table">
                            <table>
                                <colgroup>
                                    <col width="*" />
                                    <col width="130px" />
                                    <col width="100px" />
                                    <col width="100px" />
                                    <col width="100px" />
                                    <col width="100px" />
                                    <col width="100px" />
                                </colgroup>
                                <thead>	
                                    <tr>
                                        <td style={{display: "none"}}>
                                            <p className="card-text">상품번호</p>
                                        </td>
                                        <td style={{display: "none"}}>
                                            <p className="card-text">상품코드</p>
                                        </td>
                                        <td>
                                            <p className="card-text">상품명</p>
                                        </td>
                                        <td className="user_name">
                                            <p className="card-text">이미지</p>
                                        </td>
                                        <td className="category_name sorting" onClick={this.onClickSorting}>
                                            <p className="card-text">카테고리</p>
                                        </td>
                                        <td className="price sorting" onClick={this.onClickSorting}>
                                            <p className="card-text">판매가</p>
                                        </td>
                                        <td className="hit_cnt sorting" onClick={this.onClickSorting}>
                                            <p className="card-text">조회수</p>
                                        </td>
                                        <td className="sale_cnt sorting" onClick={this.onClickSorting}>
                                            <p className="card-text">구매수</p>
                                        </td>
                                        <td className="review_cnt sorting" onClick={this.onClickSorting}>
                                            <p className="card-text">리뷰수</p>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody style={{display:"block", width:"100%",  height:"calc(57vh - 140px)", overflow:"scroll", marginBottom:"20px"}}>
                                    {this.state.item_list.map((item, idx) => (
                                        <tr key={item.product_no} onClick={(e) => this.onClickModalTableItem(e,
                                            item.product_no,
                                            item.product_code,
                                            item.product_name,
                                            item.image,
                                            item.category_name,
                                            item.retail_price,
                                            item.hit_cnt,
                                            item.sale_cnt,
                                            item.review_cnt
                                        )} id = {item.product_no} className="cursor-pointer">
                                            <td style={{display: "none"}}>
                                                <p className="card-text">{item.product_no}</p>
                                            </td>
                                            <td style={{display: "none"}}>
                                                <p className="card-text">{item.product_code}</p>
                                            </td>
                                            <td>
                                                <p className="card-text" dangerouslySetInnerHTML={{__html: item.product_name}}></p>
                                            </td>
                                            <td>
                                                <p className="card-text"><img src={item.image} width="100px"/></p>
                                            </td>
                                            <td>
                                                <p className="card-text">{item.category_name}</p>
                                            </td>
                                            <td>
                                                <p className="card-text">{item.retail_price}원</p>
                                            </td>
                                            <td>
                                                <p className="card-text">{item.hit_cnt}</p>
                                            </td>
                                            <td>
                                                <p className="card-text">{item.sale_cnt}</p>
                                            </td>
                                            <td>
                                                <p className="card-text">{item.review_cnt}</p>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.rowcnt}
                            totalItemsCount={this.state.totalCnt}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange.bind(this)}
                        />
                        <div> 
                            <button className="greenButton" style={{display: "block", margin:"auto"}} onClick={this.onClickApplySelectItem}>
                                선택 적용
                            </button>
                        </div>
                    </div>
                    <div className="fa fa-close modalClose"></div>
                </div>
                
                {/* 배너 유형 팝업 */}
                <div id="modal_banner_type">
                    <div id="modalContent" className="shadow-box" style={{maxHeight: "80%"}}>
                            <h3>PC 배너 유형</h3>
                            변경할 배너 유형을 선택해 주세요.
                            <br/><br/>

                            <div className="margin-vertical-auto" style={{width:"auto"}}>
                            <select id="event_type" style={{width:"150px", padding:"5px"}} onChange={this.handleTypeChange}>
                                <option value="">
                                    변경 안함
                                </option>
                            {this.state.bannerTypeList.map((bannerType,idx) => (
                                <option key={idx} value={bannerType.code_cd}>
                                    {bannerType.val1}
                                </option>
                            ))}
                            </select>
                        </div>
                        <div className="flexBox bannerTypeImg" style={{flex:"1", borderLeft:"0px"}}>
                            <div className="flexBox" style={{width:"100%", flex:"1", marginTop:"2%", overflow:"scroll", maxHeight:"51vh"}}>
                                {this.state.bannerTypeList.map((bannerType,idx) => (
                                    <div key={idx} className="banner-inner mobile-width-100" style={{margin: "auto auto auto 1%", width: "24%"}}>
                                        <div className="flexBox">
                                           {bannerType.val1}
                                        </div>
                                        <img src={bannerType.val2} width="100%" height="auto" style={{margin:"auto auto auto auto", border:"0.5px solid dimgray"}}></img>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div> 
                            <button className="greenButton" style={{display: "block", margin:"3% auto 2% auto"}} onClick={this.onClickApplyBannerType}>
                                변경 적용
                            </button>
                        </div>
                    </div>
                    <div id="pcBannerClose" className="fa fa-close modalClose"></div>
                </div>
                
                {/* 모바일 배너 유형 팝업 */}
                <div id="modal_mobile_banner_type">
                    <div id="modalContent" className="shadow-box" style={{maxHeight: "80%"}}>
                            <h3>mobile 배너 유형</h3>
                            변경할 배너 유형을 선택해 주세요.
                            <br/><br/>

                            <div className="margin-vertical-auto" style={{width:"auto"}}>
                            <select id="event_type" style={{width:"150px", padding:"5px"}} onChange={this.handleMobileTypeChange}>
                                <option value="">
                                    변경 안함
                                </option>
                            {this.state.mobileBannerTypeList.map((bannerType,idx) => (
                                <option key={idx} value={bannerType.code_cd}>
                                    {bannerType.val1}
                                </option>
                            ))}
                            </select>
                        </div>
                        <div className="flexBox bannerTypeImg" style={{flex:"1", borderLeft:"0px"}}>
                            <div className="flexBox" style={{width:"100%", flex:"1", marginTop:"2%", overflow:"scroll", maxHeight:"51vh"}}>
                                {this.state.mobileBannerTypeList.map((bannerType,idx) => (
                                    <div key={idx} className="banner-inner mobile-width-100" style={{margin: "0% 0% 0% 1%", width: "24%"}}>
                                        <div className="flexBox">
                                           {bannerType.val1}
                                        </div>
                                        <img src={bannerType.val2} width="100%" height="auto" style={{margin:"auto auto auto auto", border:"0.1px solid dimgray"}}></img>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div> 
                            <button className="greenButton" style={{display: "block", margin:"3% auto 2% auto"}} onClick={this.onClickApplyMobileBannerType}>
                                변경 적용
                            </button>
                        </div>
                    </div>
                    <div id="mobileBannerClose" className="fa fa-close modalClose"></div>
                </div>

                {/* 팝업창 배경 */}
                <div id="modalBack" style={{top: "0", height:"130%"}}></div>    
            </div>
        )
    }
}   
import React, { useCallback, useState } from 'react';
import axios from '../modules/api';
import http from '../modules/api';
import * as StyleUtil from '../modules/utils/StyleUtil';
import * as StringUtil from '../modules/utils/StringUtil';
import {Line} from 'react-chartjs-2';
import { NavLink, Link, Prompt } from 'react-router-dom';
import { SketchPicker } from 'react-color';
import RoboImgSettingPopup from '../autoEvent/component/RoboImgSettingPopup';
import ToggleButton from '../modules/common/ToggleButton';
import SaveDialogV2 from '../modules/dialogs/SaveDialogV2';
import $ from 'jquery';
import Slider from "react-slick";
import download_img from '../../resources/img/download.svg';
import btn_arrow_up_img from '../../resources/img/btn_arrow_up.svg';
import btn_arrow_down_img from '../../resources/img/btn_arrow_down.svg';

import FileDownload from 'js-file-download';
import PropTypes from 'prop-types';

import GA4React from "ga-4-react";

try {
    setTimeout(_ => {
      const ga4react = new GA4React("G-EBVEYNW6SX");
      ga4react.initialize().catch(err => console.error("ga4react"+err));
    }, 4000);
} catch (err) {
    console.error("ga4react",err);
}

var object_domain = "https://"+document.domain
var object_port = window.location.port;
if (document.domain.indexOf("127.0.0.1") >= 0 && object_port == "3000") {
    object_domain = "http://127.0.0.1:8092"
} else if (object_port != '') {
    object_domain = object_domain + ":" + object_port
}
var textareaTimer = {}; // textarea 입력 작업시 여러번 요청 보내는 것을 방지

export class Sosanggongin extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
            tmp_product_no : "",
            product_no : "",
            product_name : "",
            kdeal_user_no: "",
            kdeal_user_name: "",
            user_seq: "",
            corp_name: "",
           
            event_list : [],
           
            tmp_event_list: [],
            tmp_event_seq_list : [],
            template_form_list: [],
            displayColorPicker : {},

            parent_event_type_cd : "",
            
            is_downloaded: true,
        }
    }

    componentWillMount() {

    }

	componentDidMount() {
        this.loadKdealShopList();
        this.loadKdealCommonCode("01000");

        const product_no = StringUtil.getUrlParams("product_no");
        if(product_no != null && product_no != ""){
            this.setState({
                product_no : product_no
            }, () => {
                // 저장된 이벤트라면 업체명, 템플릿 비활성화
                // $("#kdeal_user_no").prop('disabled',true);
                $("#kdeal_user_nane").prop('disabled',true);
                // $("#kdeal_user_no").css({background:"#F3F3F3", cursor:"default"});
                $("#kdeal_user_nane").css({background:"#F3F3F3", cursor:"default", color:"#afafaf"});
                $("#parent_event_type_cd").prop('disabled',true);
                $("#parent_event_type_cd").css({background:"#F3F3F3", cursor:"default"});

                // 저장된 이벤트 목록 불러오기
                this.loadProduct();
            });
        }

        $(document).ready(function(){
            $("#palleteBack").hide();
            setTimeout(() => window.dispatchEvent(new Event('resize')), 10);
        });

        $(window).resize(function(){
            $("#sosang-page-wrapper").css('height',window.innerHeight);
            $("#footer").css('width',$("#admin_wrap").width());
            $("#footer_line").css('width',$("#admin_wrap").width());
        });
        
        // 사이드바 확장/축소 버튼 클릭 시 화면 비율 조정
        $('html').click(function(e) {
            if($(e.target).hasClass("cursor-pointer") || $(e.target).parents(".cursor-pointer").length > 0) {
                setTimeout(() => window.dispatchEvent(new Event('resize')), 10);
            }
        });
        
    }

    componentDidUpdate() {
        let parent_event_type_cd = this.state.parent_event_type_cd;
        $(window).resize(function(){
            if(parent_event_type_cd == ""){
                // event_list를 불러오지 않기 때문에 짧은 상태로 존재하는 page_area의 높이를 윈도우 높이만큼 늘려준다.(배경색이 끊기는 듯한 현상 방지)
                $("#sosang-page-wrapper").css('height',window.innerHeight);
            } else{
                $("#sosang-page-wrapper").css('height',"");
            }
            $("#footer").css('width',$("#admin_wrap").width());
            $("#footer_line").css('width',$("#admin_wrap").width());
        });

        //console.log(this.state.template_form_list);
        // console.log(this.state.tmp_event_seq_list)
        //console.log(this.state.event_list);
        //console.log(this.state.tmp_event_list);
        //console.log(this.state.intro_first_line);
        // console.log(this.state.intro_second_line);
        // console.log(this.state.intro_third_line);

        // console.log(this.state.intro_image);
        // console.log(this.state.detail_one_image);
        // console.log(this.state.detail_two_image);
        // console.log(this.state.detail_three_image);
        // console.log(this.state.detail_info_image);

    }

    componentWillUnmount(e) {

    }

    // 템플릿 목록 조회
    async loadKdealCommonCode(upper_code_cd){
        await http.get("/rest/common/kdeal/commonCode?upper_code_cd="+upper_code_cd)
        .then(res => {
            if (upper_code_cd == "01000") {
                this.setState({
                    template_code_list:  res.data
                })
            }
        })
        .catch(error => {
        });
    }

    // 소상공인 업체 목록 데이터 불러오기
    async loadKdealShopList() {
        await http.get("/rest/common/kdeal/user/list")
		.then(res => {
            //console.log(res.data);
			if (res.data != null && res.data != '') {
				this.setState({
                    shop_list : res.data,
                });
			}
		})
		.catch(error => {
		 });
    }

    // 선택한 템플릿 이벤트 개수 불러오기
    async loadBasicEventCnt(type) {
        await http.get("/rest/common/kdeal/template/form/cnt?parent_event_type_cd="+this.state.parent_event_type_cd)
		.then(res => {
            //console.log(res.data);
			if (res.data != null && res.data != '') {
                if(type=="first_change"){
                    // tmp_product_no가 없는 경우에만, 템플릿을 새로 불러온것으로 처리하기 때문에
                    // ""으로 만들어준 후 tmp_event_seq_list를 불러온다.
                    this.setState({
                        tmp_product_no : ""
                    }, () => {
                        this.loadTmpEventSeqList(res.data["total_form_cnt"],null,"first_change");
                    });
                } else if(type=="loadProduct"){
                    // 기존 상품 이벤트 리스트랑 템플릿 이벤트 리스트의 개수를 합쳐서 tmp_seq를 다시 불러온다.
                    // 여러가지 디자인을 다 지워버리고 한 종류로 엄청 만들었을 경우, 다시 불러올때는 사라진 디자인들을 띄워야하는데,
                    // 그 개수 차이를 loadDesignTemplate쪽에서 구할수있지만, loadDesignTemplate를 실행하기 전에 tmp_event_seq_list를 받아와야 해서
                    // 순서가 꼬이고 미리 받아올 수 없어서 디자인 템플릿 이벤트 개수만큼 넉넉하게 받아온다.
                    this.setState({
                        tmp_product_no : ""
                    }, () => {
                        this.loadTmpEventSeqList(Number(res.data["total_form_cnt"])+this.state.event_list.length,null,"loadProduct");
                    });
                }
			}
		})
		.catch(error => {
		 });
    }

    // 디자인 템플릿 폼 영역 조회 
    async loadDesignTemplate(type){
        setTimeout(() => window.dispatchEvent(new Event('resize')), 10);

        if (this.state.parent_event_type_cd == "") {
            return false;
        }
        await http.get("/rest/common/kdeal/designTemplate/v2?parent_event_type_cd="+this.state.parent_event_type_cd)
        .then(res => {
            let event_list = [];
            let template_form_list = res.data["list"];
            // 불러온 템플릿 디자인에 맞게 event를 추가한다
            if (type == "first_change") {
                for(let idx in template_form_list) {
                    for(let idx2=0; idx2 < template_form_list[idx].form_cnt; idx2++) {
                        let content_list = [];
                        let contentDesignTemplateList = template_form_list[idx]["contentDesignTemplateList"];
                        for (let cd_idx = 0; cd_idx < contentDesignTemplateList.length; cd_idx++) {
                            if(contentDesignTemplateList[cd_idx].content_type_cd == "44001"){
                                content_list.push({
                                    contents : "",
                                    content_seq : null,
                                    font_color: contentDesignTemplateList[cd_idx].text_color,
                                    content_type_cd: contentDesignTemplateList[cd_idx].content_type_cd,
                                    content_title_cd : contentDesignTemplateList[cd_idx].content_title_cd,
                                });
                            } else if(contentDesignTemplateList[cd_idx].content_type_cd == "44002"){
                                content_list.push({
                                    kdeal_org_img_seq : null,
                                    img_name: null,
                                    content_seq : null,
                                    content_type_cd: contentDesignTemplateList[cd_idx].content_type_cd,
                                    content_title_cd : contentDesignTemplateList[cd_idx].content_title_cd,
                                    img_position_x : 0,
                                    img_position_y : 0,
                                    img_scale : 1,
                                });
                            }
                            
                        }
                        event_list.push(
                            {
                                form_type_cd : template_form_list[idx].form_type_cd,
                                event_type_cd: template_form_list[idx].event_type_cd,
                                event_seq : null,
                                background_color: template_form_list[idx].background_color,
                                guide_hide_yn: "N", // 가이드 숨기기 적용 여부
                                content_list: content_list
                            }
                        )
                    }
                }
            } else { /* 저장된 정보를 불러오는 경우 */
                event_list = this.state.event_list;

                // 디자인 템플릿과 불러온 데이터를 비교하여, 각 디자인의 event 개수가 디자인 템플릿의 event 개수보다 적을경우, 부족한 만큼 이벤트를 추가하는 작업을 진행한다.
                // 템플릿을 모두 조회하여 작업을 진행한다.
                for(let i=0;i<template_form_list.length;i++){
                    let template_form_type_cd = template_form_list[i].form_type_cd;
                    let template_event_type_cd = template_form_list[i].event_type_cd;
                    let template_form_cnt = template_form_list[i].form_cnt;
                    let event_form_cnt = 0;
                    for(let idx=0;idx<event_list.length;idx++){
                        // 해당 템플릿과 form_type_cd 및 event_type_cd가 동일한(디자인이 같은) 이벤트의 개수를 센다.
                        if(event_list[idx].form_type_cd == template_form_type_cd && event_list[idx].event_type_cd == template_event_type_cd){
                            event_form_cnt ++;
                        }
                    }
                    // 저장된 이벤트 개수가 기존 템플릿 수보다 작다면, 부족한 만큼 이벤트를 추가
                    if(template_form_cnt-event_form_cnt > 0){
                        let event_num = 0;
                        for(let idx=0;idx<event_list.length;idx++){
                            if(event_list[idx].form_type_cd == template_form_type_cd){
                                event_num = idx;
                            }
                        }
                        for(let idx=0;idx<template_form_cnt-event_form_cnt;idx++){
                            let content_list = [];
                            let contentDesignTemplateList = template_form_list[i]["contentDesignTemplateList"];
                            for (let cd_idx = 0; cd_idx < contentDesignTemplateList.length; cd_idx++) {
                                if(contentDesignTemplateList[cd_idx].content_type_cd == "44001"){
                                    content_list.push({
                                        contents : "",
                                        content_seq : null,
                                        content_type_cd: contentDesignTemplateList[cd_idx].content_type_cd,
                                        font_color: contentDesignTemplateList[cd_idx].text_color,
                                        content_title_cd : contentDesignTemplateList[cd_idx].content_title_cd
                                    });
                                } else if(contentDesignTemplateList[cd_idx].content_type_cd == "44002"){
                                    content_list.push({
                                        kdeal_org_img_seq : null,
                                        img_name: null,
                                        content_seq : null,
                                        content_type_cd: contentDesignTemplateList[cd_idx].content_type_cd,
                                        content_title_cd : contentDesignTemplateList[cd_idx].content_title_cd,
                                    });
                                }
                            }
                            event_list.push(
                                {
                                    form_type_cd : template_form_list[i].form_type_cd,
                                    event_type_cd: template_form_list[i].event_type_cd,
                                    event_seq : null,
                                    background_color: template_form_list[i].background_color,
                                    content_list: content_list
                                }
                            )
                        }
                    }
                    // 저장된 이벤트 개수가 기존 템플릿 수보다 많다면, 템플릿 form_cnt를 늘린다
                    else if(template_form_cnt-event_form_cnt < 0){
                        template_form_list[i].form_cnt = event_form_cnt;
                    }
                }
            }
            
            // 각 form_type_cd의 event 개수를 세어 form_total_cnt에 넣는다
            let form_type_cd_list = [];
            for(let i=0;i<template_form_list.length;i++){
                form_type_cd_list.push(template_form_list[i].form_type_cd);
            }
            const set = new Set(form_type_cd_list);
            form_type_cd_list = [...set];
            // form_type_cd 마다 form_cnt를 합친다
            for(let i=0;i<form_type_cd_list.length;i++){
                let form_total_cnt = 0;
                for(let idx=0;idx<template_form_list.length;idx++){
                    if(template_form_list[idx].form_type_cd == form_type_cd_list[i]){
                        form_total_cnt += template_form_list[idx]["form_cnt"];
                    }
                }
                for(let idx=0;idx<template_form_list.length;idx++){
                    if(template_form_list[idx].form_type_cd == form_type_cd_list[i]){
                        template_form_list[idx]["form_total_cnt"] = form_total_cnt;
                    }
                }
            }

            // 이벤트에 tmp_event_seq를 추가한다
            for(var idx in event_list) {
                event_list[idx].tmp_event_seq  = this.state.tmp_event_seq_list[idx];
            }
            let tmp_event_list = JSON.parse(JSON.stringify(event_list));
            // 저장되었던 상품이라면 tmp_event_list의 content_seq 초기화(맨 처음 미리보기 데이터 저장시, tmp_event_list의 content_seq는 null로 보내져야함)
            if(this.state.product_no != null && this.state.product_no != ""){
                for(let idx in tmp_event_list){
                    for(let idx2 in tmp_event_list[idx]["content_list"]){
                        tmp_event_list[idx]["content_list"][idx2].content_seq = null
                    }
                }
            }
            this.setState({
                template_form_list: template_form_list,
                event_list: JSON.parse(JSON.stringify(event_list)),
                tmp_event_list: tmp_event_list,
            }, () => {
                for (let idx in this.state.tmp_event_list) {
                    this.onChangeEventFormContent(idx); // 미리보기 데이터 저장
                }

                // blur를 강제로 주어, textarea 영역 크기와 맞게 오른쪽 영역도 변동되도록 한다 (blur에 달려있는 함수 실행)
                let window_scrollTop = $(window).scrollTop();
                for (let idx in this.state.event_list) {
                    let content_list = this.state.event_list[idx].content_list;

                    for (let i=1; i<=content_list.length; i++) {
                        $("#"+this.state.tmp_event_list[idx].tmp_event_seq+"_"+i+"_textarea").focus();
                        $("#"+this.state.tmp_event_list[idx].tmp_event_seq+"_"+i+"_textarea").blur();
                    }
                }
                // 초기의 스크롤 위치로 스크롤 이동
                $("html,body").scrollTop(window_scrollTop);
            })
        })
        .catch(error => {
        });
    }

    // 기존 상품 이벤트 개수 불러오기 (현재 사용되지 않음)
    async loadProductEventCnt(){
        
        await http.get("/rest/common/kdeal/product/event/cnt?product_no="+this.state.product_no)
        .then(res => {
            //console.log(res.data);
            if (res.data != null && res.data != '') {

            }
        })
        .catch(error => {
            });
    }

    // 기존 상세페이지 정보 불러오기
    async loadProduct(){
        await http.get("/rest/common/kdeal/product?product_no="+this.state.product_no)
            .then(res => {
                //console.log(res.data);
                if (res.data != null && res.data != '') {
                    let event_list = [...res.data["event_list"]]
                    let tmp_event_list = [...res.data["event_list"]]
                    for(var idx in tmp_event_list) {
                        tmp_event_list[idx].tmp_event_seq = this.state.tmp_event_seq_list[idx];

                        /* AI 컬러 적용 여부 */
                        if (tmp_event_list[idx].ai_background_color_yn == "Y") {
                            event_list[idx].ai_background_color_apply_complete = true;
                            tmp_event_list[idx].ai_background_color_apply_complete = true;
                        }

                        /* 가이드 숨기기 적용 여부 */
                        tmp_event_list[idx].guide_hide_yn = "N";

                        let content_list = JSON.parse(JSON.stringify(tmp_event_list[idx].content_list));
                        for(var content_idx in content_list) {
                            if (content_list[content_idx].nukki_yn == "Y") {
                                content_list[content_idx].img_chage_yn_for_nukki = "N";
                            }
                            if (content_list[content_idx].ai_background_color_yn == "Y") {
                                content_list[content_idx].img_chage_yn_for_ai_color = "N";
                            }
                        }
                        event_list[idx].content_list = content_list;
                        tmp_event_list[idx].content_list = content_list;
                    }

                    this.setState({
                        event_list: event_list,
                        product_name: res.data.product_name,
                        parent_event_type_cd: res.data.parent_event_type_cd, 
                        kdeal_user_no: res.data.kdeal_user_no,
                        user_seq: res.data.user_seq,
                        corp_name: res.data.corp_name,
                        tmp_event_list: tmp_event_list
                    }, () => {
                        this.loadBasicEventCnt("loadProduct");

                        // kdeal_user_no로 kdeal_user_name 알아내기
                        let kdeal_user_obj = this.state.shop_list.filter(obj => obj.user_no == this.state.kdeal_user_no)
                        let kdeal_user_name = ""
                        if (kdeal_user_obj.length > 0) {
                            kdeal_user_name = kdeal_user_obj[0].corp_name_reg_no
                        } else {
                            // 업체 리스트에서 검색된 내용 없으면 user의 corp_name 출력
                            kdeal_user_name = res.data.corp_name;
                        }
                        this.setState({
                            kdeal_user_name: kdeal_user_name,
                        }, () => {
                            // alert(this.state.kdeal_user_no);
                        })
                    })
                }
            })
            .catch(error => {
             });
    }

    // 소상공인 미리보기용 이벤트 생성
    loadTmpEventSeqList(event_num, event_type_cd, type){
        const config = {
            headers: {
                'Content-Type': 'application/json',
                },
        };
        const url = '/rest/common/kdeal/first/event/tmp/save';
        let body = {};
        // event_num의 개수만큼 tmp_event_seq를 발급받는다
        // +버튼을 누른 경우에는 1개를 발급받는다 (이미 tmp_event_seq_list를 발급 받은 경우 +버튼을 누른 것)
        body = {
            event_cnt : this.state.tmp_product_no != ""? "1" : event_num,
            product_no : this.state.tmp_product_no != ""? this.state.tmp_product_no : null,
        }
        http
        .post(url, body, config)
		.then(res => {
            //console.log(res.data);
			if (res.data != null && res.data != '') {
                if(this.state.tmp_product_no == ""){
                    // 새로 들어온 생성한 페이지, 상품 이벤트 목록을 불러왔을 경우, 템플릿을 변경한 경우 등은
                    // tmp_event_seq_list를 갈아끼운다
                    console.log(res.data["tmp_event_seq_list"])
                    console.log(res.data["product_no"])
                    this.setState({
                        tmp_event_seq_list : res.data["tmp_event_seq_list"],
                        tmp_product_no : res.data["product_no"],
                    }, () => {
                        if(type=="loadProduct"){
                            this.loadDesignTemplate();
                        } // 템플릿을 선택했을 경우(변경했을 경우)
                        else if(type == "first_change"){
                            this.loadDesignTemplate("first_change");
                        }
                    });
                } else{
                    // +버튼을 눌러, 새로운 tmp_event_seq를 발급받은 경우, 발급받은 seq를 기존 리스트에 위치에 맞게 추가한다
                    let {tmp_event_seq_list} = this.state;
                    tmp_event_seq_list.splice(event_num+1, 0, res.data["tmp_event_seq_list"][0]);
                    this.setState({
                        tmp_event_seq_list: tmp_event_seq_list
                    }, () => {
                        if(type == "plus"){
                            this.EventNumChange(event_num, event_type_cd, type);
                        }
                    });
                }
			}
		})
		.catch(error => {
		 });
    }

    // 템플릿 변경
    changeTemplate(e){
        if (this.state.parent_event_type_cd!="" && !window.confirm("템플릿을 변경 하시면 입력한 내용이 모두 지워집니다. 변경 하시겠습니까?")) {
            return false;
        }
        this.setState({
            event_list : [],
            tmp_event_list : [],
            template_form_list : [],
            parent_event_type_cd: e.target.value
        }, () => {
            this.loadBasicEventCnt("first_change");
        })
    }

    // 소상공인 이미지 업로드
    uploadOrgImg(file, event_idx, content_idx, image_id){
        const files = Array.from(file);
        Promise.all(files.map(file => {
            return (new Promise((resolve,reject) => {
                const reader = new FileReader();
                reader.addEventListener('load', (ev) => {
                    resolve(ev.target.result);
                });
                reader.addEventListener('error', reject);
                reader.readAsDataURL(file);
            }));
        }))
        .then(images => {
            const formData = new FormData();
            formData.append("kdeal_user_no", this.state.kdeal_user_no);
            formData.append("user_seq", this.state.user_seq);
            formData.append("files", files[0]);
            //console.log(formData);
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    },
            };
            const url = '/rest/common/kdeal/save/orgImg';
            http
            .post(url, formData, config)
            .then(res => {
                //console.log(res.data);
                // 이미지가 추가된 정보를 event_list, tmp_event_list 모두에 추가한다
                let event_list = [...this.state.event_list]
                event_list[event_idx].content_list[content_idx].kdeal_org_img_seq = res.data["kdeal_org_img_seq"];
                event_list[event_idx].content_list[content_idx].img_name = files[0].name;
                event_list[event_idx].content_list[content_idx].img_url = res.data["kdeal_org_img_path"];
                let tmp_event_list = [...this.state.tmp_event_list]
                tmp_event_list[event_idx].content_list[content_idx].kdeal_org_img_seq = res.data["kdeal_org_img_seq"];
                tmp_event_list[event_idx].content_list[content_idx].img_name = files[0].name;
                tmp_event_list[event_idx].content_list[content_idx].img_url = res.data["kdeal_org_img_path"];
                //console.log("uploadOrgImg event_list",event_list)
                if (res.data != null && res.data != '') {
                    // 이미지 교체 여부
                    tmp_event_list[event_idx].content_list[content_idx].img_chage_yn_for_ai_color = "Y";
                    tmp_event_list[event_idx].content_list[content_idx].img_chage_yn_for_nukki = "Y";
                    // 배경 제거 스위치 OFF
                    event_list[event_idx].content_list[content_idx].nukki_yn = "N";
                    tmp_event_list[event_idx].content_list[content_idx].nukki_yn = "N";
                    this.setState({
                        event_list: event_list,
                        tmp_event_list: tmp_event_list,
                    }, () => {
                        // iframe 대신 사진만을 보여주기 위해 img를 사용하는 경우, img영역에 해당 사진을 띄운다
                        if(image_id == "detail_info_image"){
                            $("#"+this.state.tmp_event_list[event_idx].tmp_event_seq+"_image_viewer").attr("src",images[0]);
                        }
                        // 미리보기 데이터 저장
                        this.onChangeEventFormContent(event_idx);
                    });
                }
            })
            .catch(error => {
             });
        }, error => {
            console.error(error);
        });
    }

    // 소상공인 AI 이미지 적용
    applyAIImg(image_url, event_idx, content_idx, image_id){
        // 이미지가 추가된 정보를 event_list, tmp_event_list 모두에 추가한다
        let event_list = [...this.state.event_list]
        event_list[event_idx].content_list[content_idx].kdeal_org_img_seq = "-1";
        event_list[event_idx].content_list[content_idx].img_url = image_url;
        event_list[event_idx].content_list[content_idx].img_name = image_url;
        let tmp_event_list = [...this.state.tmp_event_list]
        tmp_event_list[event_idx].content_list[content_idx].kdeal_org_img_seq = "-1";
        tmp_event_list[event_idx].content_list[content_idx].img_url = image_url;
        tmp_event_list[event_idx].content_list[content_idx].img_name = image_url;

        // 이미지 교체 여부
        tmp_event_list[event_idx].content_list[content_idx].img_chage_yn_for_ai_color = "Y";
        tmp_event_list[event_idx].content_list[content_idx].img_chage_yn_for_nukki = "Y";
        // 배경 제거 스위치 OFF
        event_list[event_idx].content_list[content_idx].nukki_yn = "N";
        tmp_event_list[event_idx].content_list[content_idx].nukki_yn = "N";
        if (image_url != null && image_url != '') {
            this.setState({
                event_list: event_list,
                tmp_event_list: tmp_event_list,
            }, () => {
                // iframe 대신 사진만을 보여주기 위해 img를 사용하는 경우, img영역에 해당 사진을 띄운다
                if(image_id == "detail_info_image"){
                    $("#"+this.state.tmp_event_list[event_idx].tmp_event_seq+"_image_viewer").attr("src",image_url);
                }

                // 미리보기 데이터 저장
                this.onChangeEventFormContent(event_idx);
            });

        }
    }

    // 소상공인 누끼 이미지 적용
    applyNukkiImg(image_url, event_idx, content_idx, image_id){
        // 이미지가 추가된 정보를 event_list, tmp_event_list 모두에 추가한다
        let event_list = [...this.state.event_list]
        event_list[event_idx].content_list[content_idx].nukki_img_url = image_url;
        let tmp_event_list = [...this.state.tmp_event_list]
        tmp_event_list[event_idx].content_list[content_idx].nukki_img_url = image_url;
        if (image_url != null && image_url != '') {
            this.setState({
                event_list: event_list,
                tmp_event_list: tmp_event_list,
            }, () => {
                // iframe 대신 사진만을 보여주기 위해 img를 사용하는 경우, img영역에 해당 사진을 띄운다
                if(image_id == "detail_info_image"){
                    $("#"+this.state.tmp_event_list[event_idx].tmp_event_seq+"_image_viewer").attr("src",image_url);
                }
                // 미리보기 데이터 저장
                this.onChangeEventFormContent(event_idx);
            });
        }
    }

    // 이미지 업로드 팝업 : 파일첨부 열기
    handleClickBtnFileUpload(e, tmp_event_seq){ 
        e.preventDefault ();
        $("#"+tmp_event_seq+"_file_input").click();	
    };

    // AI 이미지 버튼 클릭
    handleClickBtnAIImage(e, tmp_event_seq){ 
        e.preventDefault ();
        this.setState ({
            ["RoboImgSettingPopupShow"+tmp_event_seq]: true,
        })
    };
    // AI 이미지 팝업 닫기
    closeAIImagePopup(tmp_event_seq) {
        this.setState ({
            ["RoboImgSettingPopupShow"+tmp_event_seq]: false,
        })
    }

    // 이미지 업로드 팝업 : 업로드 이벤트
    _handleImageChange = (e, event_idx, content_idx, image_id) => {
        this.uploadOrgImg(e.target.files, event_idx, content_idx, image_id);
    };

    // 이미지 삭제
    onClickRemoveImg(event_idx, content_idx){
        // 이미지가 삭제된 정보를 event_list, tmp_event_list 모두에 반영한다
        let event_list = [...this.state.event_list];
        event_list[event_idx].content_list[content_idx].img_name = null;
        event_list[event_idx].content_list[content_idx].org_img_seq = null;
        event_list[event_idx].content_list[content_idx].kdeal_org_img_seq = null;

        let tmp_event_list = [...this.state.tmp_event_list];
        tmp_event_list[event_idx].content_list[content_idx].img_name = null;
        tmp_event_list[event_idx].content_list[content_idx].org_img_seq = null;
        tmp_event_list[event_idx].content_list[content_idx].kdeal_org_img_seq = null;

        this.setState({
            event_list: event_list,
            tmp_event_list: tmp_event_list,
        }, () => {
            // 미리보기 데이터 저장
            this.onChangeEventFormContent(event_idx);
        });
    }

    // textarea 내용 변경시
    onKeyUpTextarea = (e, event_idx, content_idx, input_type_cd) => {

        // input_type_cd에 따라 입력 가능한 문자를 체크
        if(input_type_cd == "04002"){
            let check = /^[0-9\n]+$/; // 숫자만 입력되는지 체크
            if(!(check.test(e.target.value) || "" == e.target.value)){
                return false;
            }
        }
        // 텍스트 변경사항을 event_list, tmp_event_list 모두에 반영한다
        let event_list = [...this.state.event_list];
        let tmp_event_list = [...this.state.tmp_event_list];
        
        event_list[event_idx].content_list[content_idx].contents = e.target.value;
        tmp_event_list[event_idx].content_list[content_idx].contents = e.target.value;

        this.setState({
            event_list : event_list,
            tmp_event_list : tmp_event_list,
        }, () => {
            // iframe 연결끊기 : 로딩 되는것처럼 보이게 하는 부분
            $("#"+this.state.tmp_event_list[event_idx].tmp_event_seq+"_sosang_image_object_area").attr('src', "");

            // textareaTimer의 동작을 취소한다 (시간이 300 만큼 지나기 전에 입력이 또 있었다면, 이전의 timeout동작은 실행되지 않을것이다)
            clearTimeout(textareaTimer[event_idx+"/"+content_idx]);
            textareaTimer[event_idx+"/"+content_idx] = setTimeout(() => {
                // 미리보기 데이터 저장
                this.onChangeEventFormContent(event_idx);
            },300);

            // textarea와 object 높이 변경
            this.resizeHeight(e,event_idx);
        })
    }

    // textarea와 object 높이 변경
    resizeHeight(e,event_idx, event_target_id) {
        let target_id;
        if(e != null){ // textarea 내용 변경시
            target_id = e.target.id;
        } else{ // 순서변경 또는 추가 등 이벤트를 타지 않고 함수를 실행하는 경우
            target_id = event_target_id;
        }
        
        // 입력한 textarea 높이 변경
        StyleUtil.textareaHeightAuto(target_id,3);

        // object 길이
        const areaHeight= $("#"+this.state.tmp_event_list[event_idx].tmp_event_seq+"_area").innerHeight();
        $("#"+this.state.tmp_event_list[event_idx].tmp_event_seq+"_object").css('max-height',areaHeight);
    }

    // textarea와 object 높이 리셋
    resetHeight(event_idx, template_idx) {
        const {template_form_list} = this.state;
        
        // 입력한 textarea 높이 변경 : 컨텐츠가 텍스트인것만 실행
        if(template_form_list[template_idx].text_use_yn == "Y"){
            for (let cd_idx=0; cd_idx<template_form_list[template_idx]["contentDesignTemplateList"].length; cd_idx++) {
                if(template_form_list[template_idx]["contentDesignTemplateList"][cd_idx].content_type_cd == "44001"){
                    StyleUtil.textareaHeightAuto(this.state.tmp_event_list[event_idx].tmp_event_seq+"_"+(cd_idx+1)+"_textarea",3);
                }
            }
        }

        // object 길이
        const areaHeight= $("#"+this.state.tmp_event_list[event_idx].tmp_event_seq+"_area").innerHeight();
        $("#"+this.state.tmp_event_list[event_idx].tmp_event_seq+"_object").css('max-height',areaHeight);
    }

    // textarea와 object 높이 초기화
    initHeight(e, event_idx) {
        // 입력한 textarea 높이 변경
        StyleUtil.textareaHeightAuto(e.target.id,3);

        // object 길이
        const areaHeight= $("#"+this.state.tmp_event_list[event_idx].tmp_event_seq+"_area").innerHeight();
        $("#"+this.state.tmp_event_list[event_idx].tmp_event_seq+"_object").css('max-height',areaHeight);
    }

    // 이벤트 폼의 내용이 변경되었을 경우
    onChangeEventFormContent(event_idx){
        const content_list = this.state.tmp_event_list[event_idx].content_list;
        let tmp_event_seq = this.state.tmp_event_seq_list[event_idx];
        let event_type_cd = this.state.event_list[event_idx].event_type_cd;
        let background_color = this.state.event_list[event_idx].background_color;
        let ai_background_color = this.state.event_list[event_idx].ai_background_color;
        let ai_background_color_yn = this.state.event_list[event_idx].ai_background_color_yn;

        // 미리보기용 데이터 저장
        const config = {
            headers: {
                'Content-Type': 'application/json',
                },
        };
        const url = '/rest/common/kdeal/content/event/tmp/save';
        const body = {
            content_list : content_list,
            tmp_event_seq : tmp_event_seq,
            event_type_cd : event_type_cd,
            background_color : background_color,
            ai_background_color : ai_background_color,
            ai_background_color_yn : ai_background_color_yn,
        }
        http
        .post(url, body, config)
		.then(res => {
			if (res.data != null && res.data != '') {
                // tmp_event_list에 content_seq 추가
                for(let content_idx in res.data.content_list) {
                    content_list[content_idx].content_seq = res.data.content_list[content_idx].content_seq;
                }
                let tmp_event_list  = this.state.tmp_event_list;
                tmp_event_list[event_idx].content_list = content_list
                this.setState({
                    tmp_event_list: tmp_event_list,
                });

                // iframe 재로딩
                if (this.state.tmp_event_list[event_idx].guide_hide_yn == "Y") {
                    $("#"+this.state.tmp_event_list[event_idx].tmp_event_seq+"_sosang_image_object_area").attr('src',object_domain+"/robomd_product_detail_v3.html?view_type_cd=&product_no="+this.state.tmp_product_no+"&tmp_event_seq="+this.state.tmp_event_list[event_idx].tmp_event_seq+"&token=");
                } else {
                    $("#"+this.state.tmp_event_list[event_idx].tmp_event_seq+"_sosang_image_object_area").attr('src',object_domain+"/robomd_product_detail_v3.html?view_type_cd=0&product_no="+this.state.tmp_product_no+"&tmp_event_seq="+this.state.tmp_event_list[event_idx].tmp_event_seq+"&token=");
                }
			}
		})
		.catch(error => {
		 });
    }

    // 폼 순서 변경 이벤트
    onClickFormOrderChange(event_num, change_event_num, type){
        let {event_list} = this.state;
        let {tmp_event_list} = this.state;
        let {tmp_event_seq_list} = this.state;
        
        // event_list, tmp_event_list에서 순서가 변경될 event를 뺀 후, 위치에 맞게 다시 넣는다
        // tmp_event_seq_list도 event가 이동함과 같이 변경한다
        if(type == "up"){
            const event = event_list.splice(event_num, 1);
            event_list.splice(change_event_num, 0, event[0]);
            const tmp_event = tmp_event_list.splice(event_num, 1);
            tmp_event_list.splice(change_event_num, 0, tmp_event[0]);
            const tmp_event_seq = tmp_event_seq_list.splice(event_num, 1);
            tmp_event_seq_list.splice(change_event_num, 0, tmp_event_seq[0]);
        } else if(type == "down"){
            const event = event_list.splice(event_num, 1);
            event_list.splice(change_event_num, 0, event[0]);
            const tmp_event = tmp_event_list.splice(event_num, 1);
            tmp_event_list.splice(change_event_num, 0, tmp_event[0]);
            const tmp_event_seq = tmp_event_seq_list.splice(event_num, 1);
            tmp_event_seq_list.splice(change_event_num, 0, tmp_event_seq[0]);
        }
        
        this.setState({
            event_list: event_list,
            tmp_event_list: tmp_event_list,
            tmp_event_seq_list: tmp_event_seq_list,
        }, ()=>{
            let min_idx = event_num  > change_event_num ? change_event_num : event_num;
            let max_idx = event_num  > change_event_num ? event_num : change_event_num;
            // 순서가 바뀐 위치 사이의 삭제되지 않은 event의 iframe을 재로딩하고 textarea와 iframe의 높이를 재설정한다
            for(var idx=min_idx;idx<max_idx+1;idx++){
                // 이벤트가 삭제된 것이 아니라면, iframe을 재로딩하고 textarea와 iframe의 높이를 재설정한다
                if(event_list[idx]["del_yn"] != "Y"){
                    // iframe 재로딩
                    if (this.state.tmp_event_list[idx].guide_hide_yn == "Y") {
                        $("#"+this.state.tmp_event_list[idx].tmp_event_seq+"_sosang_image_object_area").attr('src',object_domain+"/robomd_product_detail_v3.html?view_type_cd=&product_no="+this.state.tmp_product_no+"&tmp_event_seq="+this.state.tmp_event_list[idx].tmp_event_seq+"&token=");
                    } else {
                        $("#"+this.state.tmp_event_list[idx].tmp_event_seq+"_sosang_image_object_area").attr('src',object_domain+"/robomd_product_detail_v3.html?view_type_cd=0&product_no="+this.state.tmp_product_no+"&tmp_event_seq="+this.state.tmp_event_list[idx].tmp_event_seq+"&token=");
                    }
                    // 이벤트의 템플릿을 조회
                    const {template_form_list} = this.state;
                    let template_idx;
                    for(var i=0; i<template_form_list.length; i++){
                        if(template_form_list[i]["event_type_cd"] == event_list[idx]["event_type_cd"]){
                            template_idx = i;
                        }
                    }
                    // 입력한 textarea 높이 변경 : 컨텐츠가 텍스트인것만 실행
                    if(template_form_list[template_idx].text_use_yn == "Y"){
                        for (let cd_idx=0; cd_idx<template_form_list[template_idx]["contentDesignTemplateList"].length; cd_idx++) {
                            if(template_form_list[template_idx]["contentDesignTemplateList"][cd_idx].content_type_cd == "44001"){
                                this.resizeHeight(null, idx, this.state.tmp_event_list[idx].tmp_event_seq+"_"+(cd_idx+1)+"_textarea");
                            }
                        }
                    }
                }
            }

            // 폼에 포커싱 맞추기
            $("#"+this.state.tmp_event_list[change_event_num].tmp_event_seq+"_1_textarea").focus();
            $(':focus').blur();
        });
    }

    // 이벤트 개수 변경(+, - 버튼 클릭)
    EventNumChange(event_num, event_type_cd, type){
        let {event_list} = this.state;
        let {tmp_event_list} = this.state;
        let {tmp_event_seq_list} = this.state;
        let {template_form_list} = this.state;
        let template_idx;
        
        // 활성화된 이벤트의 디자인 템플릿 idx를 찾는다
        for(let idx in template_form_list) {
            if (template_form_list[idx].event_type_cd == event_type_cd) {
                template_idx = idx;
            }
        }

        if(type == "plus"){
            // 추가될 이벤트를 만든다
            let form_type_cd = template_form_list[template_idx].form_type_cd;
            let new_event = {
                form_type_cd : form_type_cd,
                // +버튼이 눌린 event와 같은 형식이니, 해당 event의 event_type_cd를 넣어 만든다
                event_type_cd: event_type_cd,
                event_seq : null,
                tmp_event_seq: tmp_event_seq_list[event_num+1], // 새로 발급받은 tmp_event_seq
                background_color: template_form_list[template_idx].background_color,
                content_list: [],
            };
            for(let cd_idx=0; cd_idx < template_form_list[template_idx]["contentDesignTemplateList"].length; cd_idx++) {
                if(template_form_list[template_idx]["contentDesignTemplateList"][cd_idx].content_type_cd == "44001"){
                    new_event["content_list"].push({
                            contents : "",
                            content_seq : null,
                            content_type_cd: template_form_list[template_idx]["contentDesignTemplateList"][cd_idx].content_type_cd,
                            font_color: template_form_list[template_idx]["contentDesignTemplateList"][cd_idx].text_color,
                            content_title_cd : template_form_list[template_idx]["contentDesignTemplateList"][cd_idx].content_title_cd,
                    });
                } else if(template_form_list[template_idx]["contentDesignTemplateList"][cd_idx].content_type_cd == "44002"){
                    new_event["content_list"].push({
                        kdeal_org_img_seq : null,
                        img_name: null,
                        content_seq : null,
                        content_type_cd: template_form_list[template_idx]["contentDesignTemplateList"][cd_idx].content_type_cd,
                        content_title_cd : template_form_list[template_idx]["contentDesignTemplateList"][cd_idx].content_title_cd,
                });
                }
            }
            // 이벤트가 추가되었으니, template_form_list의 cnt의 개수를 증가시킨다
            for(let idx in template_form_list) {
                if (template_form_list[idx].form_type_cd == form_type_cd) {
                    if (template_form_list[idx].event_type_cd == event_type_cd) {
                        template_form_list[idx]["form_cnt"] ++;
                    }
                    template_form_list[idx]["form_total_cnt"]++;
                }
            }
            // 위치에 맞게 이벤트를 추가한다
            event_list.splice(event_num+1, 0, JSON.parse(JSON.stringify(new_event)));
            tmp_event_list.splice(event_num+1, 0, JSON.parse(JSON.stringify(new_event)));

        } else if(type == "minus"){
            // 이벤트 제거는 리스트에서 빼는것이 아닌, del_yn값을 Y로 주는 방식을 사용한다
            event_list[event_num]["del_yn"] = "Y";
            tmp_event_list[event_num]["del_yn"] = "Y";
        }

        this.setState({
            event_list: event_list,
            tmp_event_list: tmp_event_list,
            template_form_list: template_form_list,
        }, ()=> {

            const {event_list, tmp_event_list, template_form_list} = this.state;
            for(var idx=event_num+1;idx<event_list.length;idx++){
                // 이벤트가 삭제된 것이 아니라면, iframe을 재로딩하고 textarea와 iframe의 높이를 재설정한다
                if(event_list[idx]["del_yn"] != "Y"){
                    $("#"+tmp_event_list[idx].tmp_event_seq+"_sosang_image_object_area").attr('src',object_domain+"/robomd_product_detail_v3.html?view_type_cd=0&product_no="+this.state.tmp_product_no+"&tmp_event_seq="+tmp_event_list[idx].tmp_event_seq+"&token=");
                    // resetHeight에서 템플릿의 textarea 영역 개수에 맞게 반복문을 돌려 높이를 지정하기 때문에 해당 event의 template을 조회해야 한다
                    for(let idx2=0;idx2<template_form_list.length;idx2++){
                        if(event_list[idx].form_type_cd == template_form_list[idx2].form_type_cd && event_list[idx].event_type_cd == template_form_list[idx2].event_type_cd){
                            this.resetHeight(idx,idx2);
                            break;
                        }
                    }
                }
            }
            
            if(type == "plus"){
                // 미리보기 데이터 저장
                this.onChangeEventFormContent(event_num+1);
                
                // 폼에 포커싱 맞추기
                $("#"+tmp_event_list[event_num+1].tmp_event_seq+"_1_textarea").focus();
                $(':focus').blur();
            }
        });
    }

    // event_form 순서이동을 위한 검사
    checkMoveEvent(event_num, type){
        const {event_list} = this.state;
        // 현재 event의 위쪽 이벤트로 del_yn이 아닌 evnet를 찾는다
        if(type=="up"){
            for(var i=event_num-1;i>=0;i--){
                if(event_list[i]["del_yn"] != "Y"){
                    return i;
                }
            }
        } else if(type=="down"){
            // 현재 event의 아래쪽 이벤트로 del_yn이 아닌 evnet를 찾는다
            for(var i=event_num+1;i<event_list.length;i++){
                if(event_list[i]["del_yn"] != "Y"){
                    return i;
                }
            }
        }
        return -1;
    }

    // 저장 버튼 클릭
    onClickSaveForm(){
        if (this.state.product_name == null || this.state.product_name.replace(" ", "").length == 0) {
            alert("상품명을 입력해 주세요.");
            $("#product_name").focus();
            return false;
        }
        if ((this.state.kdeal_user_no == null || this.state.kdeal_user_no == "") && (this.state.user_seq == null || this.state.user_seq == "")) {
            alert("업체명을 선택해 주세요.");
            $("#kdeal_user_no").focus();
            return false;
        }
        if (this.state.parent_event_type_cd == null || this.state.parent_event_type_cd == "") {
            alert("템플릿을 선택해 주세요.");
            $("#parent_event_type_cd").focus();
            return false;
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
                },
        };
        const url = '/rest/common/kdeal/product/event/save';
        const body = {
            event_list : this.state.event_list,
            // null로 검사하기 때문에 ""로 보내면 수정으로 인식
            product_no : this.state.product_no != ""?  this.state.product_no : null,
            product_name : this.state.product_name != ""?  this.state.product_name : null,
            kdeal_user_no : this.state.kdeal_user_no != ""?  this.state.kdeal_user_no : null,
            user_seq : this.state.user_seq != ""?  this.state.user_seq : null,
            parent_event_type_cd : this.state.parent_event_type_cd != ""?  this.state.parent_event_type_cd : null,
        }
        //console.log(JSON.stringify(body));
        http
        .post(url, body, config)
		.then(res => {
            //console.log(res.data);
			if (res.data != null && res.data != '') {

                this.setState({
                    product_no : res.data["product_no"],
                }, () => {
                    // 처음 저장하는 경우 주소값에 product_no 정보를 추가한다
                    if(StringUtil.getUrlParams("product_no") == null || StringUtil.getUrlParams("product_no") == ""){
                        window.history.replaceState("","",window.location.href+"?product_no="+this.state.product_no);
                    }
                    alert("저장하였습니다.");
                    window.location.reload();
                });
			}
		})
		.catch(error => {
		 });
    }

    // 취소 버튼 클릭
    onClickCancelBtn(){
        $("#detail_info_image").attr("src","");
        if (!window.confirm("작업 내용을 취소 하시겠습니까?")) {
            return false;
        }

        window.location.reload();
    }

    // 상품 임시 저장(캡처 전에 실행)
    saveTmpEventList(){
        const config = {
            headers: {
                'content-type': 'application/json;'
            },
        }
        const body = {
            event_list : this.state.event_list,
            product_no : this.state.tmp_product_no,
        }
        http.post("/rest/common/kdeal/product/event/save/tmp",
            body,
            config
        )
        .then(res => {
            // 이미지 다운로드
            this.contentDownload();
        })
        .catch(error => {
        });
    }

    // 이미지 다운로드
    contentDownload() {
        this.setState({
            is_downloaded: false,
        })
        const config = {
            headers: {
                'content-type': 'application/json;'
            },
            //responseType: 'arraybuffer'
        }
        http.post("/rest/common/kdeal/content/capture",
            {
                "product_no" : this.state.tmp_product_no,
                "banner_width": "960",
                "view_type_cd" : "1"
            },
            config
        )
        .then(res => {
            this.setState({
                is_downloaded: true,
            })
            if (res.data.code == "200") {
                // 성공시 s3 파일 다운로드 요청
                this.fn_s3ImageFileDownload(res.data.capture_code, this.state.product_name);
            } else {
                alert(res.data.code + "\n" + res.data.msg);
            }
        })
        .catch(error => {
            //console.log (error);
            this.setState({
                is_downloaded: false,
            })
        });
    }

    // s3 이미지 다운로드
    fn_s3ImageFileDownload(capture_code, product_name) {
        // 파일 확장자 추출
        let file_type = capture_code.substring(capture_code.lastIndexOf(".")+1, capture_code.length);
        const config = {
            headers: {
                'content-type': 'application/json;'
            },
            responseType: 'arraybuffer'
        }
        http.post("/rest/common/kdeal/content/download",
            {
                "capture_code" : capture_code,
                "file_type" : file_type
            },
            config
        )
        .then(res => {
            if (res.data != null) {
                // 상품명(제목) 미입력시 '제목없음'로 다운로드
                if (product_name == '') {
                    product_name = '제목없음';
                }
                // alert(res.data);
                let file_name = product_name + '.'+file_type;
                var blob = new Blob([res.data], { type: 'image/'+file_type });
                FileDownload(blob, decodeURI(file_name));
            } else {
                alert("s3 이미지 다운로드 요청 실패\n고객센터 : 1599-3518");
            }
        })
        .catch(error => {
            alert("s3 이미지 다운로드 요청 실패\n고객센터 : 1599-3518");
        });
    }

    // 컬러 변경 팔레트 열기
    onClickColorBox(event_idx, content_idx){
        const {displayColorPicker} = this.state;
        if(content_idx == null){
            displayColorPicker[event_idx] = true;
        } else{
            displayColorPicker[event_idx+"/"+content_idx] = true;
        }
        this.setState({
            displayColorPicker : displayColorPicker,
        }, () => {
            $("#palleteBack").show();
        });
    }

    // 컬러 변경 팔레트 닫기
    onClosePaletteArea(){
        this.setState({
            displayColorPicker : {},
        }, () => {
            $("#palleteBack").hide();
        });
    }

    // 이벤트 배경색 변경 : onChagne(계속 변경중일 경우)
    onChangeEventBackgroundColor = (e, event_idx) => {
        // 컬러값 맨 앞에붙는 #을 제거한다
        let color = e.hex.substr(1);

        // 색상 변경사항을 event_list, tmp_event_list 모두에 반영한다
        const {event_list, tmp_event_list} = this.state;
        event_list[event_idx].background_color = color;
        tmp_event_list[event_idx].background_color = color;
        
        /** 전체 컨텐츠 대상 AI 이미지 컬러 OFF 처리 */
        for(let loop_content_idx = 0; loop_content_idx < event_list[event_idx].content_list.length; loop_content_idx++) {
            if (event_list[event_idx].content_list[loop_content_idx].content_type_cd == "44002") {
                event_list[event_idx].content_list[loop_content_idx].ai_background_color_yn = "N";
                tmp_event_list[event_idx].content_list[loop_content_idx].ai_background_color_yn = "N";
            } 
        }
        /**  AI 이미지 컬러 OFF 처리 */
        event_list[event_idx].ai_background_color_yn = "N";
        tmp_event_list[event_idx].ai_background_color_yn = "N";
        event_list[event_idx].ai_background_color_apply_complete = false;
        tmp_event_list[event_idx].ai_background_color_apply_complete = false;

        this.setState({
            event_list : event_list,
            tmp_event_list : tmp_event_list,
        });
    }

    // 이벤트 배경색 변경 : onChangeComplete(마우스의 움직임이 멈췄을 경우)
    // 변경이 끝났을 때만, onChangeEventFormContent()를 실행한다
    onChangeEventBackgroundColorComplete = (e, event_idx) => {
        // 컬러값 맨 앞에붙는 #을 제거한다
        let color = e.hex.substr(1);

        // 색상 변경사항을 event_list, tmp_event_list 모두에 반영한다
        const {event_list, tmp_event_list} = this.state;
        event_list[event_idx].background_color = color;
        tmp_event_list[event_idx].background_color = color;

        this.setState({
            event_list : event_list,
            tmp_event_list : tmp_event_list,
        }, () => {
            // 미리보기 데이터 저장
            this.onChangeEventFormContent(event_idx);
        });
    }

    // 내용 글자색 변경 : onChagne(계속 변경중일 경우)
    onChangeContentColor = (e,event_idx, content_idx) => {
        // 컬러값 맨 앞에붙는 #을 제거한다
        let color = e.hex.substr(1);

        // 색상 변경사항을 event_list, tmp_event_list 모두에 반영한다
        const {event_list, tmp_event_list} = this.state;
        event_list[event_idx].content_list[content_idx].font_color = color;
        tmp_event_list[event_idx].content_list[content_idx].font_color = color;
        
        this.setState({
            event_list : event_list,
            tmp_event_list : tmp_event_list,
        });
    }

    // 내용 글자색 변경 : onChangeComplete(마우스의 움직임이 멈췄을 경우)
    // 변경이 끝났을 때만, onChangeEventFormContent()를 실행한다
    onChangeContentColorComplete = (e,event_idx, content_idx) => {
        // 컬러값 맨 앞에붙는 #을 제거한다
        let color = e.hex.substr(1);

        // 색상 변경사항을 event_list, tmp_event_list 모두에 반영한다
        const {event_list, tmp_event_list} = this.state;
        event_list[event_idx].content_list[content_idx].font_color = color;
        tmp_event_list[event_idx].content_list[content_idx].font_color = color;
        
        this.setState({
            event_list : event_list,
            tmp_event_list : tmp_event_list,
        }, () => {
            // 미리보기 데이터 저장
            this.onChangeEventFormContent(event_idx);
        });
    }
    
    // AI 배경 제거 버튼 클릭
    onClickRemoveBackground(e, event_idx, content_idx){
        let event_list = [...this.state.event_list]
        let tmp_event_list = [...this.state.tmp_event_list]
        if (!tmp_event_list[event_idx].content_list[content_idx].img_url) {
            alert("배경 제거 할 이미지를 먼저 업로드 해주세요.");
            return;
        }
        
        let nukki_yn = "N";
        if (tmp_event_list[event_idx].content_list[content_idx].nukki_yn) {
            nukki_yn = tmp_event_list[event_idx].content_list[content_idx].nukki_yn;
        }

        let nukki_img_url = "";
        if (tmp_event_list[event_idx].content_list[content_idx].nukki_img_url) {
            nukki_img_url = tmp_event_list[event_idx].content_list[content_idx].nukki_img_url;
        }

        /** 버튼 누르기 전 누끼 여부 */
        if (nukki_yn == "Y") {
            /** ON 상태인 경우 
             * OFF 처리 / 새로 누끼 따지 않고 미리보기만 적용 
            */
            event_list[event_idx].content_list[content_idx].nukki_yn = "N";
            tmp_event_list[event_idx].content_list[content_idx].nukki_yn = "N";
            this.setState({
                event_list,
                tmp_event_list,
            }, () => {
                this.applyNukkiImg(nukki_img_url, event_idx, content_idx, "");
            });
            return; // 미리보기 적용 후 종료
        } else {
            /** OFF 상태인 경우 
             * ON 처리 / 새로 누끼 따고 미리보기 적용
            */
            event_list[event_idx].content_list[content_idx].nukki_yn = "Y";
            tmp_event_list[event_idx].content_list[content_idx].nukki_yn = "Y";

            if (tmp_event_list[event_idx].content_list[content_idx].img_chage_yn_for_nukki == "N") {
                this.setState({
                    event_list,
                    tmp_event_list,
                }, () => {
                    this.applyNukkiImg(nukki_img_url, event_idx, content_idx, "");
                });
                return; // 한 번 컬러를 추출한 이후에는 이미지 변경이 있기 전까지 컬러 추출을 하지 않음.
            }
        }

        const event_seq = tmp_event_list[event_idx].event_seq;
        const tmp_event_seq = tmp_event_list[event_idx].tmp_event_seq;
        const content_seq = tmp_event_list[event_idx].content_list[content_idx].content_seq;
        const img_link = tmp_event_list[event_idx].content_list[content_idx].img_url;

        let nukki_version = 3; // remove bg와 가장 흡사한 내부 AI
        if (tmp_event_list[event_idx].content_list[content_idx].nukki_version) {
            nukki_version = tmp_event_list[event_idx].content_list[content_idx].nukki_version;
        }
        
        this.setState({
            event_list,
            tmp_event_list,
        }, () => {
            this.setState({
                show_loading_dialog: true,
                loading_dialog_content: "AI 배경제거 적용중 입니다.",
                loading_dialog_button_list: [
                    {
                        title: "확인",
                        type: "1",
                        display_yn:"N",
                        event_method: () => {}
                    }
                ]
            });

            const body = {
                event_seq,
                tmp_event_seq,
                content_seq,
                nukki_version,
                img_link,
            }
            const config = {
                headers: {
                    'content-type': 'application/json;'
                },
            }
            http.post("/rest/common/kdeal/ai/nukki",
                body,
                config
            )
            .then(res => {
                // console.log(res);
                tmp_event_list[event_idx].content_list[content_idx].img_chage_yn_for_nukki = "N";
                
                const res_img_link = res.data.url;
                this.applyNukkiImg(res_img_link, event_idx, content_idx, "");

                this.setState({
                    show_loading_dialog: false,
                });
            })
            .catch(error => {
                console.log(error);
                event_list[event_idx].content_list[content_idx].nukki_yn = "N";
                tmp_event_list[event_idx].content_list[content_idx].nukki_yn = "N";
                this.setState({
                    event_list,
                    tmp_event_list,
                })
            });
        });
    }

    /** AI 컬러 추출 */
    onSelectImgLinkColor(e, event_idx, content_idx){
        let event_list = [...this.state.event_list]
        let tmp_event_list = [...this.state.tmp_event_list]
        if (!tmp_event_list[event_idx].content_list[content_idx].img_url) {
            alert("컬러를 추출 할 이미지를 먼저 업로드 해주세요.");
            return;
        }
        
        let ai_background_color_yn = "N";
        if (tmp_event_list[event_idx].ai_background_color_yn) {
            ai_background_color_yn = tmp_event_list[event_idx].content_list[content_idx].ai_background_color_yn;
        }

        let ai_background_color = "";
        if (tmp_event_list[event_idx].ai_background_color) {
            ai_background_color = tmp_event_list[event_idx].ai_background_color;
        }

        /** 버튼 누르기 전 누끼 여부 */
        if (ai_background_color_yn == "Y") {
            /** ON 상태인 경우 
             * OFF 처리 / 새로 컬러를 추출하지 않고 미리보기만 적용 
            */
            event_list[event_idx].ai_background_color_yn = "N";
            tmp_event_list[event_idx].ai_background_color_yn = "N";
            event_list[event_idx].content_list[content_idx].ai_background_color_yn = "N";
            tmp_event_list[event_idx].content_list[content_idx].ai_background_color_yn = "N";
            event_list[event_idx].ai_background_color_apply_complete = false;
            tmp_event_list[event_idx].ai_background_color_apply_complete = false;
            this.setState({
                event_list,
                tmp_event_list,
            }, () => {
                // 미리보기 데이터 저장
                this.onChangeEventFormContent(event_idx);
            });
            return; // 미리보기 적용 후 종료
        } else {
            /** OFF 상태인 경우 
             * ON 처리 / 새로 컬러를 추출하고 미리보기 적용
            */
            event_list[event_idx].ai_background_color_yn = "Y";
            tmp_event_list[event_idx].ai_background_color_yn = "Y";
            event_list[event_idx].ai_background_color_apply_complete = false;
            tmp_event_list[event_idx].ai_background_color_apply_complete = false;

            
            /** 전체 컨텐츠 대상 OFF 처리 */
            for(let loop_content_idx = 0; loop_content_idx < event_list[event_idx].content_list.length; loop_content_idx++) {
                if (event_list[event_idx].content_list[loop_content_idx].content_type_cd == "44002") {
                    event_list[event_idx].content_list[loop_content_idx].ai_background_color_yn = "N";
                    tmp_event_list[event_idx].content_list[loop_content_idx].ai_background_color_yn = "N";
                } 
            }
            event_list[event_idx].content_list[content_idx].ai_background_color_yn = "Y";
            tmp_event_list[event_idx].content_list[content_idx].ai_background_color_yn = "Y";
            
            if (tmp_event_list[event_idx].content_list[content_idx].img_chage_yn_for_ai_color == "N") {
                event_list[event_idx].ai_background_color_apply_complete = true;
                tmp_event_list[event_idx].ai_background_color_apply_complete = true;

                this.setState({
                    event_list,
                    tmp_event_list,
                }, () => {
                    // 미리보기 데이터 저장
                    this.onChangeEventFormContent(event_idx);
                });
                return; // 한 번 컬러를 추출한 이후에는 이미지 변경이 있기 전까지 컬러 추출을 하지 않음.
            }
        }

        const img_link = tmp_event_list[event_idx].content_list[content_idx].nukki_yn == "Y" 
                        ? tmp_event_list[event_idx].content_list[content_idx].nukki_img_url 
                        : tmp_event_list[event_idx].content_list[content_idx].img_url;
        this.setState({
            event_list,
            tmp_event_list,
        }, () => {
            this.setState({
                show_loading_dialog: true,
                loading_dialog_content: "AI 배경컬러 적용중 입니다.",
                loading_dialog_button_list: [
                    {
                        title: "확인",
                        type: "1",
                        display_yn:"N",
                        event_method: () => {}
                    }
                ]
            });

            const body = {
                img_link,
            }
            const config = {
                headers: {
                    'content-type': 'application/json;'
                },
            }

            // 컬러 추출
            http.post("/rest/commmon/ai/colorDetector",
                body,
                config
            )
            .then(res => {
                const color_result = res.data.color;
                console.log("color_result", color_result);
                const background_color = color_result.event.color_code_4;
                console.log("background_color", background_color);

                event_list[event_idx].ai_background_color = background_color.replace("#", "");
                tmp_event_list[event_idx].ai_background_color = background_color.replace("#", "");
                event_list[event_idx].content_list[content_idx].ai_background_color = background_color.replace("#", "");
                tmp_event_list[event_idx].content_list[content_idx].ai_background_color = background_color.replace("#", "");
                
                event_list[event_idx].ai_background_color_apply_complete = true;
                tmp_event_list[event_idx].ai_background_color_apply_complete = true;

                /** 
                 * 컬러 추출 할 이미지가 변동 되었는지 여부
                 * 한 번 컬러를 추출한 이후에는 이미지 변경이 있기 전까지 컬러 추출을 하지 않기 위한 용도.
                 */
                tmp_event_list[event_idx].content_list[content_idx].img_chage_yn_for_ai_color = "N";
                this.setState({
                    event_list,
                    tmp_event_list,
                    show_loading_dialog: false,
                }, () => {
                    // 미리보기 데이터 저장
                    this.onChangeEventFormContent(event_idx);
                })
            })
            .catch(error => {
                console.log(error);
                event_list[event_idx].ai_background_color_yn = "N";
                tmp_event_list[event_idx].ai_background_color_yn = "N";
                event_list[event_idx].content_list[content_idx].ai_background_color_yn = "N";
                tmp_event_list[event_idx].content_list[content_idx].ai_background_color_yn = "N";
                event_list[event_idx].ai_background_color_apply_complete = false;
                tmp_event_list[event_idx].ai_background_color_apply_complete = false;
                this.setState({
                    event_list,
                    tmp_event_list,
                })
            });

        })
    }

    onChangeImgPositionX(e, event_idx, content_idx) {
        let event_list = [...this.state.event_list]
        let tmp_event_list = [...this.state.tmp_event_list]

        let value = e.target.value.replace(/[^0-9.-]/g, ''); // 숫자, '-', '.' 외의 문자는 제거

        // '-'가 여러 번 입력되지 않도록 하고, 반드시 맨 앞에만 위치하도록 처리
        if (value.indexOf('-') !== 0) {
            value = value.replace(/-/g, ''); // '-'가 맨 앞에 없으면 제거
        } else if (value.split('-').length > 2) {
            value = value.slice(0, -1); // '-'가 여러 번 입력된 경우 마지막 '-'를 제거
        }

        // 소수점이 여러 번 입력되지 않도록 처리
        if (value.indexOf('.') !== -1 && value.split('.').length > 2) {
            value = value.slice(0, -1); // 소수점이 두 번 이상 입력되지 않도록 마지막 문자를 제거
        }
        
        if (tmp_event_list[event_idx].content_list[content_idx].img_position_x != value) {
            event_list[event_idx].content_list[content_idx].img_position_x_change = true;
            tmp_event_list[event_idx].content_list[content_idx].img_position_x_change = true;
        } else {
            event_list[event_idx].content_list[content_idx].img_position_x_change = false;
            tmp_event_list[event_idx].content_list[content_idx].img_position_x_change = false;
        }
        event_list[event_idx].content_list[content_idx].img_position_x = value;
        tmp_event_list[event_idx].content_list[content_idx].img_position_x = value;

        this.setState({
            event_list,
            tmp_event_list,
        })
    }
    
    onChangeImgPositionY(e, event_idx, content_idx) {
        let event_list = [...this.state.event_list]
        let tmp_event_list = [...this.state.tmp_event_list]

        let value = e.target.value.replace(/[^0-9.-]/g, ''); // 숫자, '-', '.' 외의 문자는 제거

        // '-'가 여러 번 입력되지 않도록 하고, 반드시 맨 앞에만 위치하도록 처리
        if (value.indexOf('-') !== 0) {
            value = value.replace(/-/g, ''); // '-'가 맨 앞에 없으면 제거
        } else if (value.split('-').length > 2) {
            value = value.slice(0, -1); // '-'가 여러 번 입력된 경우 마지막 '-'를 제거
        }

        // 소수점이 여러 번 입력되지 않도록 처리
        if (value.indexOf('.') !== -1 && value.split('.').length > 2) {
            value = value.slice(0, -1); // 소수점이 두 번 이상 입력되지 않도록 마지막 문자를 제거
        }

        if (tmp_event_list[event_idx].content_list[content_idx].img_position_y != value) {
            event_list[event_idx].content_list[content_idx].img_position_y_change = true;
            tmp_event_list[event_idx].content_list[content_idx].img_position_y_change = true;
        } else {
            event_list[event_idx].content_list[content_idx].img_position_y_change = false;
            tmp_event_list[event_idx].content_list[content_idx].img_position_y_change = false;
        }
        event_list[event_idx].content_list[content_idx].img_position_y = value;
        tmp_event_list[event_idx].content_list[content_idx].img_position_y = value;

        this.setState({
            event_list,
            tmp_event_list,
        })
    }
    
    onChangeImgScale(e, event_idx, content_idx) {
        let event_list = [...this.state.event_list]
        let tmp_event_list = [...this.state.tmp_event_list]

        let value = e.target.value.replace(/[^0-9.]/g, ''); // 숫자와 소수점 외의 문자는 제거

        // 소수점이 여러 번 입력되지 않도록 처리
        if (value.indexOf('.') !== -1 && value.split('.').length > 2) {
            value = value.slice(0, -1); // 소수점이 두 번 이상 입력되지 않도록 마지막 문자를 제거
        }
        
        if (tmp_event_list[event_idx].content_list[content_idx].img_scale != value) {
            event_list[event_idx].content_list[content_idx].img_scale_change = true;
            tmp_event_list[event_idx].content_list[content_idx].img_scale_change = true;
        } else {
            event_list[event_idx].content_list[content_idx].img_scale_change = false;
            tmp_event_list[event_idx].content_list[content_idx].img_scale_change = false;
        }
        event_list[event_idx].content_list[content_idx].img_scale = value;
        tmp_event_list[event_idx].content_list[content_idx].img_scale = value;

        this.setState({
            event_list,
            tmp_event_list,
        })
    }

    render() {
        let sosang_form_and_reselt = []; // 폼 영역 html 태그 array
        // 템플릿을 조회한 후에 실행해야함
        if (this.state.template_form_list != null && this.state.template_form_list.length > 0) {
            let form_type_no = {} // 현재 그려진 각 form_type의 개수를 담고있는 객체
            let show_event_idx = 0; // 화면에 실제로 그려진 이벤트 개수 
            
            for(let event_idx=0; event_idx<this.state.event_list.length;event_idx++) { // event_list 만큼 loop 실행
                let event = this.state.event_list[event_idx];
                let template;
                for(let idx=0;idx<this.state.template_form_list.length;idx++){
                    if(event.form_type_cd == this.state.template_form_list[idx].form_type_cd && event.event_type_cd == this.state.template_form_list[idx].event_type_cd){
                        template = this.state.template_form_list[idx];
                    }
                }
                
                // 만약 해당 form_type_cd의 form_type_num이 비어있다면, 1로 초기화 시킨다.
                if(form_type_no[event.form_type_cd]==null ||form_type_no[event.form_type_cd] == ""){
                    form_type_no[event.form_type_cd] = 1;
                }
                let form_no = form_type_no[event.form_type_cd];

                let text_array = []; // textarea와 input에 넣을 텍스트 array
                if (this.state.event_list[event_idx] != null && this.state.event_list[event_idx].content_list != null && this.state.event_list[event_idx].content_list.length >= 1) {
                    // event_list의 content_list의 length가 1보다 큰 경우
                    for(let content_idx in this.state.event_list[event_idx].content_list) {
                        if (this.state.event_list[event_idx].content_list[content_idx].content_type_cd == "44002") { // 이미지 영역인 경우
                            text_array.push(this.state.event_list[event_idx].content_list[content_idx].img_name)
                        } else { // 그 외는 텍스트 영역
                            text_array.push(this.state.event_list[event_idx].content_list[content_idx].contents)
                        }
                    }
                }

                // content를 이벤트 템플릿에 맞게 동적으로 채워주는 부분
                let cd_list = [];
                for(let cd_idx=0; cd_idx < template["contentDesignTemplateList"].length; cd_idx++) {
                    cd_list.push(
                        template["contentDesignTemplateList"][cd_idx].content_type_cd == "44001" && template.text_use_yn == "Y"
                        ?
                        <div className="sosang_form_line_area">
                            <h3 className="sosang_form_span_text">
                                {template["contentDesignTemplateList"][cd_idx].content_title}
                                {template["contentDesignTemplateList"][cd_idx].input_type_cd == "04002"? " (숫자)" : null}
                            </h3>
                            {this.state.displayColorPicker[event_idx+"/"+cd_idx]
                            ?<div>
                                <div class="sosang_form_content_color_box" style={{background : "#"+this.state.event_list[event_idx].content_list[cd_idx].font_color}}/>
                                <SketchPicker color={"#"+this.state.event_list[event_idx].content_list[cd_idx].font_color}
                                onChange={e => this.onChangeContentColor(e,event_idx, cd_idx)}
                                onChangeComplete={e => this.onChangeContentColorComplete(e,event_idx, cd_idx)} disableAlpha/>
                            </div>
                            :<div class="sosang_form_content_color_box" style={{background : "#"+this.state.event_list[event_idx].content_list[cd_idx].font_color}} onClick={() => this.onClickColorBox(event_idx,cd_idx)} />
                            }
                            <textarea id={this.state.tmp_event_list[event_idx].tmp_event_seq+"_"+(cd_idx+1)+"_textarea"} name="intro_first" className="sosang_form_textarea"
                            onChange={e => this.onKeyUpTextarea(e,event_idx, cd_idx, template["contentDesignTemplateList"][cd_idx].input_type_cd)} onBlur={e=>this.initHeight(e, event_idx)} value={text_array.length >= (cd_idx+1) && text_array[cd_idx] != null ? text_array[cd_idx] : ""} placeholder="내용을 입력해 주세요." />
                        </div>
                        : template["contentDesignTemplateList"][cd_idx].content_type_cd == "44002" && template.image_use_yn == "Y"
                        ?
                        <>
                            <h3 className="sosang_form_span_text">{template["contentDesignTemplateList"][cd_idx].content_title}</h3>
                            <div className="sosang_form_line_area">
                                <input type="file" id={this.state.tmp_event_list[event_idx].tmp_event_seq+"_file_input"} onChange={e => this._handleImageChange(e,event_idx, cd_idx, "")} accept="image/jpg,image/png,image/jpeg,image/gif,image/bmp,image/tiff" value="" style={{display:"none"}} tabindex="-1"></input>
                                <button className="sosang_select_file_btn" onClick={e => this.handleClickBtnFileUpload(e, this.state.tmp_event_list[event_idx].tmp_event_seq)}>파일 선택</button>
                                <button className="sosang_select_file_btn" onClick={e => this.handleClickBtnAIImage(e, this.state.tmp_event_list[event_idx].tmp_event_seq)} style={{marginLeft:"0px"}}>AI 이미지</button>
                                {this.state["RoboImgSettingPopupShow"+this.state.tmp_event_list[event_idx].tmp_event_seq]
                                && <RoboImgSettingPopup
                                        onClose={e => this.closeAIImagePopup(this.state.tmp_event_list[event_idx].tmp_event_seq)}
                                        sendRoboImg={(img) => {
                                            this.applyAIImg(img, event_idx, cd_idx,"");
                                            this.closeAIImagePopup(this.state.tmp_event_list[event_idx].tmp_event_seq);
                                        }}
                                    />}
                                <input className="sosang_select_file_input" id={this.state.tmp_event_list[event_idx].tmp_event_seq+"_image_name"} placeholder="파일을 선택해 주세요." value={text_array.length >= (cd_idx+1) && text_array[cd_idx] != null ? text_array[cd_idx] : ""} readOnly tabindex="-1"></input>
                                {text_array.length >= (cd_idx+1) && text_array[cd_idx] != null
                                ?<svg className="sosang_select_file_input_x_btn" onClick={() => {this.onClickRemoveImg(event_idx, cd_idx)}} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 0.875C7.39303 0.875 5.82214 1.35152 4.486 2.24431C3.14985 3.1371 2.10844 4.40605 1.49348 5.8907C0.87852 7.37535 0.717618 9.00901 1.03112 10.5851C1.34463 12.1612 2.11846 13.6089 3.25476 14.7452C4.39106 15.8815 5.8388 16.6554 7.4149 16.9689C8.99099 17.2824 10.6247 17.1215 12.1093 16.5065C13.594 15.8916 14.8629 14.8502 15.7557 13.514C16.6485 12.1779 17.125 10.607 17.125 9C17.1209 6.84638 16.2635 4.78216 14.7407 3.25932C13.2178 1.73648 11.1536 0.87913 9 0.875ZM11.9453 11.0547C12.0627 11.1732 12.1285 11.3332 12.1285 11.5C12.1285 11.6668 12.0627 11.8268 11.9453 11.9453C11.8259 12.0608 11.6662 12.1254 11.5 12.1254C11.3338 12.1254 11.1742 12.0608 11.0547 11.9453L9 9.88281L6.94532 11.9453C6.82585 12.0608 6.66618 12.1254 6.5 12.1254C6.33383 12.1254 6.17415 12.0608 6.05469 11.9453C5.93733 11.8268 5.87149 11.6668 5.87149 11.5C5.87149 11.3332 5.93733 11.1732 6.05469 11.0547L8.11719 9L6.05469 6.94531C5.95503 6.82388 5.90409 6.66971 5.9118 6.51281C5.91951 6.3559 5.9853 6.20747 6.09639 6.09638C6.20747 5.9853 6.3559 5.9195 6.51281 5.9118C6.66972 5.90409 6.82388 5.95502 6.94532 6.05469L9 8.11719L11.0547 6.05469C11.1761 5.95502 11.3303 5.90409 11.4872 5.9118C11.6441 5.9195 11.7925 5.9853 11.9036 6.09638C12.0147 6.20747 12.0805 6.3559 12.0882 6.51281C12.0959 6.66971 12.045 6.82388 11.9453 6.94531L9.88282 9L11.9453 11.0547Z" fill="#D1D1D1"/>
                                </svg>
                                :""}
                            </div>
                            <>
                                {/* 이미지 편집 영역 */}
                                <div className={"event_img_setting_area"} style={{width:"100%", padding: "0 40px", marginTop:"24px"}}>
                                    <>
                                        <div className="two-four-division_area"
                                                style={{
                                                    minWidth: "210px",
                                                    // maxWidth: "210px",
                                                    width:"100%",
                                                }}>
                                            <div className="flexBox" style={{width:"100%"}}>
                                                <div
                                                    // className="content_box_radius flexBox"
                                                    className="flexBox" 
                                                    style={{width: "100%"}}>
                                                    <>
                                                        <div style={{flex:1, borderRight: "0.5px solid #D1D1D1", paddingRight:"20px"}}>
                                                            <>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexWrap: "nowrap",
                                                                    justifyContent: "space-between",
                                                                    width: "100%",
                                                                    marginTop: "10px"
                                                                }}>
                                                                    <div style={{
                                                                        marginLeft: "6px",
                                                                        fontSize: "15px"
                                                                    }}>AI 이미지 배경 제거
                                                                    </div>
                                                                    <ToggleButton
                                                                        id={"nukki_yn_"+event_idx+"_"+cd_idx}
                                                                        checked={this.state.tmp_event_list[event_idx].content_list[cd_idx].nukki_yn == "Y"}
                                                                        onClickOption={(e) => this.onClickRemoveBackground(e, event_idx, cd_idx)}
                                                                    />
                                                                </div>
                                                            </>
                                                            <>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexWrap: "nowrap",
                                                                    justifyContent: "space-between",
                                                                    width: "100%",
                                                                    marginTop: "10px"
                                                                }}>
                                                                    <div style={{
                                                                        marginLeft: "6px",
                                                                        fontSize: "15px"
                                                                    }}>AI 배경 컬러
                                                                    </div>
                                                                    <ToggleButton
                                                                        id={"color_yn_"+event_idx+"_"+cd_idx}
                                                                        checked={this.state.tmp_event_list[event_idx].content_list[cd_idx].ai_background_color_yn == "Y"}
                                                                        onClickOption={(e) => this.onSelectImgLinkColor(e, event_idx, cd_idx)}
                                                                    />
                                                                </div>
                                                            </>
                                                        </div>
                                                        <>
                                                            <div style={{display: "flex", flex:1, borderRight: "0.5px solid #D1D1D1", padding:"0px 20px", justifyContent: "space-between",}}>
                                                                <div style={{marginTop: "10px",fontSize: "15px",marginRight: "20px"}}>
                                                                    이미지 이동
                                                                </div>
                                                                <div style={{flex: 1}}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexWrap: "nowrap",
                                                                        justifyContent: "space-between",
                                                                        flex:1,
                                                                        marginTop: "10px"
                                                                    }}>
                                                                        <div style={{
                                                                            marginLeft: "6px",
                                                                            fontSize: "15px"
                                                                        }}>X
                                                                        </div>
                                                                        <div style={{
                                                                            marginLeft: "6px",
                                                                            fontSize: "15px"
                                                                        }}>
                                                                            <input 
                                                                            type="text" 
                                                                            maxlength="4" 
                                                                            id={"img_position_x_"+event_idx+"_"+cd_idx}
                                                                            value={this.state.tmp_event_list[event_idx].content_list[cd_idx].img_position_x} 
                                                                            onChange={e => this.onChangeImgPositionX(e, event_idx, cd_idx)} 
                                                                            onKeyDown={e => {
                                                                                    const isEnterKey = e.key === 'Enter' || e.keyCode === 13;
                                                                                    if (isEnterKey) {
                                                                                        e.target.blur();
                                                                                    }
                                                                                }
                                                                            }
                                                                            onBlur={e => {
                                                                                    if (this.state.tmp_event_list[event_idx].content_list[cd_idx].img_position_x_change == true) {
                                                                                        this.state.event_list[event_idx].content_list[cd_idx].img_position_x_change = false;
                                                                                        this.state.tmp_event_list[event_idx].content_list[cd_idx].img_position_x_change = false;
                                                                                        // 미리보기 데이터 저장
                                                                                        this.onChangeEventFormContent(event_idx);
                                                                                    }
                                                                                }
                                                                            }
                                                                            style={{
                                                                                width: "50px",
                                                                                height: "25px",
                                                                                paddingTop:"0px",
                                                                                paddingBottom:"0px",
                                                                                backgroundColor: "white",
                                                                            }}
                                                                            autocomplete="off"/>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexWrap: "nowrap",
                                                                        justifyContent: "space-between",
                                                                        width: "100%",
                                                                        marginTop: "10px"
                                                                    }}>
                                                                        <div style={{
                                                                            marginLeft: "6px",
                                                                            fontSize: "15px"
                                                                        }}>Y
                                                                        </div>
                                                                        <div style={{
                                                                            marginLeft: "6px",
                                                                            fontSize: "15px"
                                                                        }}>
                                                                            <input 
                                                                            type="text" 
                                                                            maxlength="4" 
                                                                            id={"img_position_y_"+event_idx+"_"+cd_idx}
                                                                            value={this.state.tmp_event_list[event_idx].content_list[cd_idx].img_position_y} 
                                                                            onChange={e => this.onChangeImgPositionY(e, event_idx, cd_idx)} 
                                                                            onKeyDown={e => {
                                                                                    const isEnterKey = e.key === 'Enter' || e.keyCode === 13;
                                                                                    if (isEnterKey) {
                                                                                        e.target.blur();
                                                                                    }
                                                                                }
                                                                            }
                                                                            onBlur={e => {
                                                                                    if (this.state.tmp_event_list[event_idx].content_list[cd_idx].img_position_y_change == true) {
                                                                                        this.state.event_list[event_idx].content_list[cd_idx].img_position_y_change = false;
                                                                                        this.state.tmp_event_list[event_idx].content_list[cd_idx].img_position_y_change = false;
                                                                                        // 미리보기 데이터 저장
                                                                                        this.onChangeEventFormContent(event_idx);
                                                                                    }
                                                                                }
                                                                            }
                                                                            style={{
                                                                                width: "50px",
                                                                                height: "25px",
                                                                                paddingTop:"0px",
                                                                                paddingBottom:"0px",
                                                                                backgroundColor: "white",
                                                                            }}
                                                                            autocomplete="off"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{flex:1, paddingLeft: "20px"}}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexWrap: "nowrap",
                                                                    justifyContent: "space-between",
                                                                    width: "100%",
                                                                    marginTop: "10px"
                                                                }}>
                                                                    <div style={{
                                                                        marginLeft: "6px",
                                                                        fontSize: "15px",
                                                                        // lineHeight: 1,
                                                                    }}>이미지 크기
                                                                    {/* <br/><font style={{fontSize: "12px"}}>(0.1 단위로 조절)</font> */}
                                                                    </div>
                                                                    <div style={{
                                                                        marginLeft: "6px",
                                                                        fontSize: "15px"
                                                                    }}>
                                                                        <input 
                                                                        type="text" 
                                                                        maxlength="4" 
                                                                        id={"img_position_y_"+event_idx+"_"+cd_idx}
                                                                        value={this.state.tmp_event_list[event_idx].content_list[cd_idx].img_scale} 
                                                                        onChange={e => this.onChangeImgScale(e, event_idx, cd_idx)} 
                                                                        onKeyDown={e => {
                                                                                const isEnterKey = e.key === 'Enter' || e.keyCode === 13;
                                                                                if (isEnterKey) {
                                                                                    e.target.blur();
                                                                                }
                                                                            }
                                                                        }
                                                                        onBlur={e => {
                                                                                if (this.state.tmp_event_list[event_idx].content_list[cd_idx].img_scale_change == true) {
                                                                                    this.state.event_list[event_idx].content_list[cd_idx].img_scale_change = false;
                                                                                    this.state.tmp_event_list[event_idx].content_list[cd_idx].img_scale_change = false;
                                                                                    // 미리보기 데이터 저장
                                                                                    this.onChangeEventFormContent(event_idx);
                                                                                }
                                                                            }
                                                                        }
                                                                        style={{
                                                                            width: "70px",
                                                                            height: "25px",
                                                                            paddingTop:"0px",
                                                                            paddingBottom:"0px",
                                                                            backgroundColor: "white",
                                                                        }}
                                                                        autocomplete="off"/>
                                                                    </div>
                                                                </div>
                                                            </div> 
                                                        </>    
                                                    </>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </>
                        </>
                        :''
                    )
                }
                cd_list.push(<div style={{marginBottom:"32px"}}></div>); // 마지막 컨텐츠 아래로 이벤트 여백 생성

                let event_num = event_idx;
                let obj = template;

                if(this.state.event_list[event_num]["del_yn"] != "Y"){
                    sosang_form_and_reselt.push(
                        <div>
                            <div className="sosang_event_description" style={{marginLeft:"50px"}}>{(show_event_idx+1)+". "+obj.description}</div>
                            <div className="sosang_form_and_reselt">
                                {/* <div className="sosang_up_down_btn_area">
                                    {this.checkMoveEvent(event_num, "up") != -1
                                    ?<img src={btn_arrow_up_img} onClick={() => this.onClickFormOrderChange(event_num, this.checkMoveEvent(event_num, "up"), "up")} style={{marginBottom: "15px",cursor: "pointer"}} />
                                    :<div style={{marginLeft: "28px"}}></div>}
                                    {this.checkMoveEvent(event_num, "down") != -1
                                    ?<img src={btn_arrow_down_img} onClick={() => this.onClickFormOrderChange(event_num, this.checkMoveEvent(event_num, "down"), "down")} style={{cursor: "pointer"}} />
                                    :""}
                                </div> */}
                                <div id={this.state.tmp_event_list[event_num].tmp_event_seq+"_area"} className="sosang_form_area">
                                    <div className="sosang_form_title_area">
                                        <h2 className="sosang_form_title_text">{obj.form_title}{obj.form_total_cnt > 1?" "+form_no:""}</h2>
                                        {this.state.displayColorPicker[event_num]
                                        ?<div>
                                            <div class="sosang_form_event_color_box" style={{background : "#"+(this.state.event_list[event_num].ai_background_color_apply_complete == true ? this.state.event_list[event_num].ai_background_color : this.state.event_list[event_num].background_color)}}/>
                                            <SketchPicker color={"#"+(this.state.event_list[event_num].ai_background_color_apply_complete == true ? this.state.event_list[event_num].ai_background_color : this.state.event_list[event_num].background_color)}
                                            onChange={e => this.onChangeEventBackgroundColor(e,event_num)} 
                                            onChangeComplete={e => this.onChangeEventBackgroundColorComplete(e,event_num)} disableAlpha/>
                                        </div>
                                        : <div class="sosang_form_event_color_box" style={{background : "#"+(this.state.event_list[event_num].ai_background_color_apply_complete == true ? this.state.event_list[event_num].ai_background_color : this.state.event_list[event_num].background_color)}} onClick={() => this.onClickColorBox(event_num)} />
                                        }
                                        <div className="sosang_plus_area" style={{display: "flex",flexWrap: "nowrap",alignItems: "center"}}>
                                            <>
                                                <div style={{
                                                    display: "flex",
                                                    flexWrap: "nowrap",
                                                    justifyContent: "space-between",
                                                    width: "100%",
                                                    alignItems: "center",
                                                    marginRight: "40px"
                                                }}>
                                                    <div style={{
                                                        marginLeft: "6px",
                                                        fontSize: "15px"
                                                    }}>미리보기 가이드 숨기기
                                                    </div>
                                                    <ToggleButton
                                                        id={"guide_hide_yn_"+event_num}
                                                        checked={this.state.tmp_event_list[event_num].guide_hide_yn == "Y"}
                                                        onClickOption={(e) => {
                                                            let event_list = [...this.state.event_list]
                                                            let tmp_event_list = [...this.state.tmp_event_list]
                                                            if (tmp_event_list[event_num].guide_hide_yn == "Y") {
                                                                event_list[event_num].guide_hide_yn = "N";
                                                                tmp_event_list[event_num].guide_hide_yn = "N";
                                                            } else {
                                                                event_list[event_num].guide_hide_yn = "Y";
                                                                tmp_event_list[event_num].guide_hide_yn = "Y";
                                                            }
                                                            this.setState({
                                                                event_list,
                                                                tmp_event_list
                                                            }, () => {
                                                                // 미리보기 데이터 저장
                                                                this.onChangeEventFormContent(event_num);
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </> 
                                            {this.checkMoveEvent(event_num, "up") != -1
                                            ?<svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg"  onClick={() => this.onClickFormOrderChange(event_num, this.checkMoveEvent(event_num, "up"), "up")} style={{cursor: "pointer", marginRight:"19px"}}>
                                                <path d="M9 0.719022C9.19878 0.721723 9.38938 0.798631 9.53437 0.934648L17.0344 8.43465C17.154 8.58037 17.2151 8.76537 17.2058 8.95366C17.1966 9.14195 17.1176 9.32006 16.9843 9.45336C16.851 9.58667 16.6729 9.66562 16.4846 9.67487C16.2963 9.68411 16.1113 9.62299 15.9656 9.5034L9 2.5284L2.03437 9.5034C1.88865 9.62299 1.70365 9.68411 1.51536 9.67486C1.32708 9.66562 1.14896 9.58666 1.01566 9.45336C0.882355 9.32006 0.803402 9.14194 0.794153 8.95365C0.784906 8.76537 0.846026 8.58037 0.965624 8.43465L8.46562 0.934648C8.61062 0.798631 8.80121 0.721723 9 0.719022Z" fill="#8E8E8E"/>
                                            </svg>
                                            :""}
                                            {this.checkMoveEvent(event_num, "down") != -1
                                            ?<svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg"  onClick={() => this.onClickFormOrderChange(event_num, this.checkMoveEvent(event_num, "down"), "down")} style={{cursor: "pointer", marginRight:"19px"}}>
                                                <path d="M9 9.21848C8.80122 9.21578 8.61062 9.13887 8.46563 9.00285L0.965627 1.50285C0.84603 1.35713 0.784911 1.17213 0.794158 0.983844C0.803406 0.795555 0.882359 0.617437 1.01566 0.484135C1.14896 0.350834 1.32708 0.271881 1.51537 0.262634C1.70366 0.253386 1.88866 0.314505 2.03438 0.434103L9 7.4091L15.9656 0.434103C16.1113 0.314505 16.2963 0.253386 16.4846 0.262634C16.6729 0.271881 16.851 0.350834 16.9843 0.484135C17.1176 0.617437 17.1966 0.795555 17.2058 0.983844C17.2151 1.17213 17.154 1.35713 17.0344 1.50285L9.53438 9.00285C9.38939 9.13887 9.19879 9.21578 9 9.21848Z" fill="#8E8E8E"/>
                                            </svg>
                                            :""}

                                            {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {this.loadTmpEventSeqList(event_num,this.state.event_list[event_num].event_type_cd, "plus")}} style={{cursor: "pointer"}}>
                                                <path d="M21 12C21 12.1989 20.921 12.3897 20.7803 12.5303C20.6397 12.671 20.4489 12.75 20.25 12.75H12.75V20.25C12.75 20.4489 12.671 20.6397 12.5303 20.7803C12.3897 20.921 12.1989 21 12 21C11.8011 21 11.6103 20.921 11.4697 20.7803C11.329 20.6397 11.25 20.4489 11.25 20.25V12.75H3.75C3.55109 12.75 3.36032 12.671 3.21967 12.5303C3.07902 12.3897 3 12.1989 3 12C3 11.8011 3.07902 11.6103 3.21967 11.4697C3.36032 11.329 3.55109 11.25 3.75 11.25H11.25V3.75C11.25 3.55109 11.329 3.36032 11.4697 3.21967C11.6103 3.07902 11.8011 3 12 3C12.1989 3 12.3897 3.07902 12.5303 3.21967C12.671 3.36032 12.75 3.55109 12.75 3.75V11.25H20.25C20.4489 11.25 20.6397 11.329 20.7803 11.4697C20.921 11.6103 21 11.8011 21 12Z" fill="#646464"/>
                                            </svg> */}
                                            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {this.loadTmpEventSeqList(event_num,this.state.event_list[event_num].event_type_cd, "plus")}} style={{cursor: "pointer"}}>
                                                <path d="M18 9.96875C18 10.1677 17.921 10.3584 17.7803 10.4991C17.6397 10.6397 17.4489 10.7188 17.25 10.7188H9.75V18.2188C9.75 18.4177 9.67098 18.6084 9.53033 18.7491C9.38968 18.8897 9.19891 18.9688 9 18.9688C8.80109 18.9688 8.61032 18.8897 8.46967 18.7491C8.32902 18.6084 8.25 18.4177 8.25 18.2188V10.7188H0.75C0.551088 10.7188 0.360322 10.6397 0.21967 10.4991C0.0790178 10.3584 0 10.1677 0 9.96875C0 9.76984 0.0790178 9.57907 0.21967 9.43842C0.360322 9.29777 0.551088 9.21875 0.75 9.21875H8.25V1.71875C8.25 1.51984 8.32902 1.32907 8.46967 1.18842C8.61032 1.04777 8.80109 0.96875 9 0.96875C9.19891 0.96875 9.38968 1.04777 9.53033 1.18842C9.67098 1.32907 9.75 1.51984 9.75 1.71875V9.21875H17.25C17.4489 9.21875 17.6397 9.29777 17.7803 9.43842C17.921 9.57907 18 9.76984 18 9.96875Z" fill="#8E8E8E"/>
                                            </svg>

                                            {form_no != 1
                                            ?<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {this.EventNumChange(event_num,this.state.event_list[event_num].event_type_cd,"minus")}} style={{cursor: "pointer", marginLeft:"19px"}}>
                                                <path d="M13.255 13.4105C13.1126 13.5494 12.9209 13.6261 12.722 13.6237C12.5231 13.6212 12.3333 13.5399 12.1944 13.3975L6.95635 8.02974L1.58857 13.2678C1.44621 13.4067 1.25449 13.4834 1.0556 13.4809C0.856699 13.4785 0.666915 13.3972 0.527993 13.2548C0.389072 13.1124 0.312393 12.9207 0.314826 12.7218C0.317259 12.5229 0.398605 12.3331 0.540967 12.1942L5.90874 6.95618L0.670701 1.58841C0.53178 1.44605 0.455101 1.25433 0.457534 1.05544C0.459967 0.856538 0.541312 0.666753 0.683675 0.527832C0.826037 0.38891 1.01775 0.312232 1.21665 0.314665C1.41555 0.317098 1.60533 0.398443 1.74426 0.540805L6.98229 5.90858L12.3501 0.67054C12.4924 0.531618 12.6841 0.45494 12.883 0.457373C13.0819 0.459806 13.2717 0.541151 13.4106 0.683513C13.5496 0.825875 13.6262 1.01759 13.6238 1.21649C13.6214 1.41539 13.54 1.60517 13.3977 1.74409L8.0299 6.98213L13.2679 12.3499C13.4069 12.4923 13.4835 12.684 13.4811 12.8829C13.4787 13.0818 13.3973 13.2716 13.255 13.4105Z" fill="#8E8E8E"/>
                                            </svg>
                                            
                                            : ""
                                            }
                                        </div>
                                    </div>
                                    {cd_list}
                                </div>
                                {/* id="intro_image_object" */}
                                <div id={this.state.tmp_event_list[event_num].tmp_event_seq+"_object"} style={{position:"relative", width:"100%"}}>
                                    <iframe id={this.state.tmp_event_list[event_num].tmp_event_seq+"_sosang_image_object_area"} className="sosang_image_object_area" type="text/html" src={""} style={{position:"absolute", minHeight:"100%"}}>
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    )
                    form_type_no[event.form_type_cd] ++;
                    show_event_idx ++;
                }
            }
        }
        return (
            <>
                <div id='sosang-page-wrapper' class="style_guide_v2" style={{minHeight:"100%"}}>
                    <div id="admin_wrap" class="admin_wrap" style={{overflowX:"auto"}}>
                        <div class="admin_page" style={{padding:"0 0 0 0"}}>
                        <div style={{height: "38px", visibility:"hidden"}}>상단여백</div> 
                            <div className="sosang_event_description" style={{marginLeft:"50px"}}>상세디자인 등록</div>
                            <div className="sosang_form_area sosang_select_input_area flexBox" style={{padding:"20px 17px 24px 17px", marginBottom:"40px", marginLeft:"50px"}}>
                                <div className="sosang_select_input_area" style={{marginTop:"0px", marginRight:"8px", marginLeft:"0px", flex:1,}}>
                                    <div className="sosang_form_menu_text" style={{marginBottom:"10px", fontWeight: "400", fontSize: "14px"}}>상품명 (상세디자인명)</div>
                                    <input type="text" style={{width: "100%", padding:"0 30px 0 15px" , height: "42px", marginBottom:"0px", marginRight: "0px"}} 
                                    className="" id="product_name" placeholder="상품명을 입력해 주세요." value={this.state.product_name} autoComplete="off"
                                    onChange={(e) => {
                                        this.setState({
                                            product_name: e.target.value
                                        })
                                    }}></input>
                                </div>
                                <div className="sosang_select_input_area" style={{marginLeft:"0px", flex:1,}}>
                                    {/* <div className="sosang_form_menu_text" style={{marginBottom:"10px"}}>업체명 (작성자)</div> */}
                                    <div style={{display:"flex", flexWrap:"nowrap", marginBottom:"10px"}}>
                                        <div className="sosang_form_menu_text" style={{fontWeight: "400", fontSize: "14px"}}>업체명 (작성자)</div> 
                                        <div id="kdeal_user_no_select_error" style={{color:"#e7342c", marginLeft:"8px", lineHeight: "1.15", display:"none", fontWeight: "400", fontSize: "14px"}}>(업체명을 선택해 주세요.)</div>
                                    </div>
                                    <div style={{position:"relative", height: "42px", marginBottom:"0px"}}>
                                        <input id="kdeal_user_nane" type="text" 
                                        style={{position:"absolute", left:"1px", width:"100%", height: "42px", padding:"0 30px 0 15px"}} 
                                        placeholder="업체명을 검색 후 선택해 주세요."
                                        value={this.state.kdeal_user_name}
                                        onChange={(e) => {
                                            if (e.target.value != null && e.target.value != "") {
                                                let kdeal_user_obj = this.state.shop_list.filter(obj => obj.corp_name_reg_no == e.target.value)
                                                // alert(JSON.stringify(kdeal_user_obj));
                                                let kdeal_user_no = ""
                                                if (kdeal_user_obj.length > 0) {
                                                    kdeal_user_no = kdeal_user_obj[0].user_no
                                                    $("#kdeal_user_nane").blur(); // 매칭되는 업체 있으면 포커스 아웃
                                                } else {
                                                    kdeal_user_no = ""
                                                }
                                                this.setState({
                                                    kdeal_user_name: e.target.value,
                                                    kdeal_user_no: kdeal_user_no
                                                }, () => {
                                                    // alert(this.state.kdeal_user_no);
                                                    if(this.state.kdeal_user_no == null || this.state.kdeal_user_no == "") {
                                                        // $("#kdeal_user_no_select_error").show();
                                                    } else {
                                                        $("#kdeal_user_no_select_error").hide();
                                                    }
                                                })
                                            } else {
                                                this.setState({
                                                    kdeal_user_name: e.target.value,
                                                    kdeal_user_no: ""
                                                }, () => {
                                                    // alert(this.state.kdeal_user_no);
                                                    if(this.state.kdeal_user_no == null || this.state.kdeal_user_no == "") {
                                                        // $("#kdeal_user_no_select_error").show();
                                                    } else {
                                                        $("#kdeal_user_no_select_error").hide();
                                                    }
                                                })
                                            }
                                        }}
                                        onBlur={(e) => {
                                            if(this.state.kdeal_user_no == null || this.state.kdeal_user_no == "") {
                                                $("#kdeal_user_no_select_error").show();
                                            } else {
                                                $("#kdeal_user_no_select_error").hide();
                                            }
                                        }}
                                        autoComplete="off"
                                        list="kdeal_user_no">

                                        </input>
                                        <datalist id="kdeal_user_no" style={{width: "300px", height: "42px", padding:"0 30px 0 15px", fontSize: "14px", marginBottom:"20px", marginRight: "0px", color:"#999"}} value={this.state.kdeal_user_no}>
                                            <option value="" disabled>
                                                업체명을 선택해 주세요.
                                            </option>    
                                            {
                                                this.state.shop_list != null && this.state.shop_list.map((obj, idx) => (
                                                    <option value= {obj.corp_name_reg_no}>
                                                        {/* {obj.corp_name_reg_no} */}
                                                    </option>    
                                                ))
                                            }
                                        </datalist>
                                    </div> 
                                </div>   
                            </div>
                            <div className="sosang_event_description" style={{marginLeft:"50px"}}>디자인 선택</div>
                            <div className="sosang_form_area sosang_select_input_area flexBox" style={{padding:"20px 17px 24px 17px", marginBottom:"40px", marginLeft:"50px"}}>
                                <div className={"sosang_select_input_area"} style={{flex:1, marginLeft:"0px"}}>
                                    <div style={{display:"flex", flexWrap:"nowrap"}}>
                                        <div className="sosang_form_menu_text" style={{marginBottom:"10px", fontWeight: "400", fontSize: "14px"}}>템플릿</div> 
                                        <div style={{color:"#10CFC9", marginLeft:"8px", lineHeight: "1.15", fontWeight: "400", fontSize: "14px"}}>{
                                            this.state.parent_event_type_cd == null || this.state.parent_event_type_cd == ""?"(템플릿을 먼저 선택해 주세요.)":""
                                        }</div>
                                    </div>
                                    {
                                        this.state.parent_event_type_cd == "" || this.state.parent_event_type_cd == null
                                        ? 
                                            <select id="parent_event_type_cd" style={{color:"#999", width:"100%", margin: "0px"}} value={this.state.parent_event_type_cd} onChange={(e) => this.changeTemplate(e)}>
                                                <option value="" disabled>
                                                    템플릿을 선택해 주세요.
                                                </option>    
                                                {
                                                    this.state.template_code_list != null && this.state.template_code_list.map((obj, idx) => (
                                                        <option value={obj.code_cd}>
                                                            {obj.code_name}
                                                        </option>    
                                                    ))
                                                }
                                            </select>
                                        :
                                            <select id="parent_event_type_cd" style={{width:"100%", margin: "0px"}} value={this.state.parent_event_type_cd} onChange={(e) => this.changeTemplate(e)}>
                                                <option value="" disabled>
                                                    템플릿을 선택해 주세요.
                                                </option>    
                                                {
                                                    this.state.template_code_list != null && this.state.template_code_list.map((obj, idx) => (
                                                        <option value={obj.code_cd}>
                                                            {obj.code_name}
                                                        </option>    
                                                    ))
                                                }
                                            </select>
                                    }
                                    
                                </div>    
                            </div>  
                            {
                                this.state.parent_event_type_cd != ""
                                ?   <>
                                        {sosang_form_and_reselt}
                                        <div className='footer_wrapper'>
                                            <div id="footer_line" style={{border: "0.4px solid #ACACAC", minWidth:"1400px"}}></div>
                                            <div id="footer" style={{paddingTop:"32px", background:"#FFFFFF", minWidth:"1400px"}}>
                                                <div className="sosang_form_and_reselt" style={{marginLeft:"115px"}}>
                                                    <button className="sosang_form_cancel_btn hover_white" style={{color:"#3A3A3A"}} onClick={e => this.onClickCancelBtn()}>취소</button>
                                                    <button className="sosang_form_save_btn hover_mint" style={{marginRight:"784px", color:"#3A3A3A"}} onClick={e => this.onClickSaveForm()}>저장</button>
                                                    {
                                                        this.state.is_downloaded
                                                        ?
                                                            <button className="sosang_form_download_btn hover_white" onClick={e => this.saveTmpEventList()}>
                                                                <img src={download_img}/>&nbsp;이미지 다운로드
                                                            </button>
                                                        :
                                                            <button className="sosang_form_download_btn" style={{width:"155px"}}>
                                                                다운로드 중...
                                                            </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                :   <div style={{margin:"0 0 20px 0"}}>
                                        {/* 템플릿을 선택해 주세요. */}
                                    </div>
                            }
                        </div>
                    </div>

                    {/* 팔레트 배경 */}
                    <div id="palleteBack" style={{top: "0", left:"0", width:"100%", height:"100%", position:"fixed", display:"none", background:"#ffffff00"}} onClick={() => this.onClosePaletteArea()}></div>    
                    {/* 로딩 다이얼로그 */}
                    {
                        (this.state.show_loading_dialog === true)
                        ? <SaveDialogV2
                        content={this.state.loading_dialog_content}
                        button_list={this.state.loading_dialog_button_list}
                        />
                        : ''
                    }
                </div>
            </>
        )
    };
    
}
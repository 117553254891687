import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {http} from '../../modules/api';
import CheckBox from '../../modules/common/CheckBox';
import SearchProductPopup from '../../modules/popup/SearchProductPopup';
import PopupFrame from "../../modules/popup/PopupFrame";
import ConfirmDialogV2 from "../../modules/dialogs/ConfirmDialogV2";
import BasicDialogV2 from "../../modules/dialogs/BasicDialogV2";
import SaveDialogV2 from "../../modules/dialogs/SaveDialogV2";
import SelectBoxV2, {getSelectBoxSelectIdx} from '../../modules/common/SelectBoxV2';

// import {loadList, saveList, addItem, removeItem} from "../slice/ExcludeProductsSlice";

const EssentialProductTableMgmt = ({
    list_self_select // 알고리즘 목록
    , item_select_type_self_list // 알고리즘에서 선택한 알고리즘 종류 (~ 순서대로)
    , list_self_select_uuid // 현재 알고리즘의 고유 번호
    , imageTypeCd // 이미지 타입
    , onClose // 닫기 이벤트
    , onAddProduct  // 인자: list_self_select_uuid, newProductNoList
                    // 상품 추가 이벤트 (팝업에 상품이 추가되면 알고리즘에도 추가 되어야함, 삼품번호 목록만 저장.)
}) => {
    const [checkList, setCheckList] = useState([]);
    const [listSelfSelectNameValue, setListSelfSelectNameValue] = useState([]);
    const [listSelfSelectUuid, setListSelfSelectUuid] = useState("");
    const [addPopupToggle, setAddPopupToggle] = useState(false);
    const [noticeDialogToggle, setNoticeDialogToggle] = useState(false);
    const [productList, setProductList] = useState([]);
    const [productObj, setAddProductObj] = useState({});
    const [showBasicDialog, setShowBasicDialog] = useState(false);
    const [basicDialogConent, setBasicDialogConent] = useState("");
    
    useEffect(() => {
        /* 팝업창이 열리면 실행하는 부분 */

        // let default_list_self_select_uuid = ""; 
        // for(let idx in list_self_select) {
        //     // uuid 초기화
        //     if (idx == 0) {
        //         default_list_self_select_uuid = list_self_select[idx]["uuid"];
        //     }
        // }

        // // props로 넘겨 받은 uuid가 없으면 초기화된 uuid 사용
        // setListSelfSelectUuid(list_self_select_uuid ? list_self_select_uuid : default_list_self_select_uuid)
    }, []);

    useEffect(() => {
        // item_select_type_self_list 를 map으로 변경 key는 code_cd
        const listMap = item_select_type_self_list.reduce((acc, obj) => {
            acc[obj.code_cd] = obj;
            return acc;
        }, {});
        let default_list_self_select_uuid = ""; // uuid 초기화
        let list_self_select_name_value = [];
        for(let idx in list_self_select) {
            list_self_select_name_value[idx] = {name: (Number(idx)+1)+". "+listMap[list_self_select[idx]["cd"]]["val1"], value: list_self_select[idx]["uuid"]}
        }
        setListSelfSelectNameValue(list_self_select_name_value)
    }, [list_self_select]);

    useEffect(() => {
        setListSelfSelectUuid(list_self_select_uuid);
    }, [list_self_select_uuid]);

    useEffect(() => {
        let current_list_self_select = {};
        for(let idx in list_self_select) {
            // 현재 알고리즘 추출
            if (list_self_select[idx]["uuid"] == listSelfSelectUuid) {
                current_list_self_select = list_self_select[idx]
            }
        }
        // 상품 목록 조회
        const essential_product_no_list = current_list_self_select["essential_product_no_list"];
        loadProductList(essential_product_no_list);

    }, [listSelfSelectUuid]);

    /** 상품 목록 팝업 전체 체크
     * @param {*} e 전체 체크 상태
    */
    const onChangeProductListCheckAll = useCallback((e) => {
        setCheckList(e.target.checked ? [...productList] : []);
    },[productList]);

    /** 상품 목록 팝업 체크
     * @param {*} e 체크 상태
     * @param {*} idx 체크된 idx 번호
    */
    const onChangeProductListCheck = useCallback((e, idx) => {
        if (!e.currentTarget.checked) {
            setCheckList(checkList.filter((obj) => obj.product_no !== productList[idx].product_no));
        } else {
            setCheckList([...checkList, productList[idx]]);
        }
    },[productList, checkList]);

    /**
     * 필수 상품 추가
     * @param {*} allProductList 
     * @returns 
     */
    const onClickAddProductbtn = (allProductList) => {
        /* 추가한 상품 개수가 선정수를 넘었는지 체크 */
        let current_list_self_select = {};
        for(let idx in list_self_select) {
            // 현재 알고리즘 추출
            if (list_self_select[idx]["uuid"] == listSelfSelectUuid) {
                current_list_self_select = list_self_select[idx]
            }
        }
        if (allProductList.length > current_list_self_select["cnt"]) {
            setBasicDialogConent("상품 선정수 보다 많은 상품을 추가 할 수 없습니다.<br/>"
            +"상품 선정수를 늘려주세요. (현재 상품 선정수 "+current_list_self_select["cnt"]+"개)");
            setShowBasicDialog(true);
            return;
        }
        
        /* 상품 추가 */
        const copyProductObj = {...productObj}
        let productList = copyProductObj[listSelfSelectUuid] ? productObj[listSelfSelectUuid] : [];
        const essentialProductNoList = new Set(productList.map(product => product.product_no));
        const addList = allProductList.filter(product => !essentialProductNoList.has(product.product_no));


        if(addList.length === 0){
            setNoticeDialogToggle(true);
            return;
        }

        const newAddList = addList.map(product => ({
            ...product,
        }));
        let addProductList = [...productList];
        addProductList.push(...newAddList);
        setProductList(addProductList);
        copyProductObj[listSelfSelectUuid] = addProductList;
        setAddProductObj(copyProductObj);
        setAddPopupToggle(false);

        // 상위 페이지의 list_selef_select에 상품목록을 추가
        let addProductNoList = [];
        for (let idx  in addProductList) {
            addProductNoList[idx] = addProductList[idx].product_no
        }
        onAddProduct(listSelfSelectUuid, addProductNoList);
    }

    /**
     * 필수 상품 삭제
     * @returns 
     */
    const delEsessntialProductList = () => {
        if(checkList.length === 0) {return}

        const copyProductObj = {...productObj}
        let productList = copyProductObj[listSelfSelectUuid] ? productObj[listSelfSelectUuid] : [];
        let newProductList = [];
        const checkSet = new Set(checkList.map(product => product.product_no));
        newProductList = productList.filter(product => !checkSet.has(product.product_no));


        const finalProductList = newProductList.map(product => ({
            ...product,
        }));
        setProductList(finalProductList);
        copyProductObj[listSelfSelectUuid] = finalProductList;
        setAddProductObj(copyProductObj);
        
        // 상위 페이지의 list_selef_select에 상품목록을 추가
        let finalProductNoList = [];
        for (let idx  in finalProductList) {
            finalProductNoList[idx] = finalProductList[idx].product_no
        }
        onAddProduct(listSelfSelectUuid, finalProductNoList);

        setCheckList([]);
    }

    const handleCloseMgmt = () => {
        onClose();
    }

    const onChangeListSelfSelectUuid = (text,value) => {
        setListSelfSelectUuid(value);
    }

     const loadProductList = async (product_no_list) => {
        const copyProductObj = {...productObj}
        if (product_no_list == null) {
            console.log("상품이 존재하지 않습니다.");
            
            setProductList([]);
            copyProductObj[listSelfSelectUuid] = [];
            setAddProductObj(copyProductObj);
            
            // 상위 페이지의 list_selef_select에 빈 리스트를 추가
            onAddProduct(listSelfSelectUuid, []);
            
            return;
        }
        if (copyProductObj[listSelfSelectUuid] != null && copyProductObj[listSelfSelectUuid].length > 0) {
            console.log("이미 로드한 상품이 있습니다.");

            setProductList(copyProductObj[listSelfSelectUuid]);
            // setAddProductObj(copyProductObj);

            return;
        }
        if (product_no_list.length == 0) {
            console.log("상품이 0개 입니다.");
            return
        }

        const str_product_no_list = product_no_list.join(",");
        setProductList([]);
        copyProductObj[listSelfSelectUuid] = [];
        setAddProductObj(copyProductObj);

        await http.get(
                        "/rest/shop/rp/product/select/v2"
                        +"?startidx=0"
                        +"&rowcnt="+product_no_list.length
                        +"&product_no_list="+str_product_no_list
                    )
        .then(res => {
            if (res.data.code == "200") {
                const copyProductObj2 = {...productObj}
                setProductList(res.data["list"]);
                copyProductObj2[listSelfSelectUuid] = res.data["list"];
                setAddProductObj(copyProductObj2);
            } else{
                alert("상품 목록을 불러오는데 실패하였습니다.")
            }
        })
        .catch(error => {
        });
    }

    return (
        <>
            <PopupFrame
                title={"고정 상품 설정"}
                content={"자동 선정되는 상품에서 고정으로 선정되는 상품을 추가할 수 있습니다<br/><font style='color: dimgray;'>상품 추가 후 꼭 [설정 저장] 버튼을 눌러서 저장해주세요.</font>"}
                maxWidth={"1150px"}
                onClose={handleCloseMgmt}
            >
                <div class="list_main_board_row_1 exclude_list_table_control" id="exclude_list_table_control" style={{overflow:"visible"}}>
                <SelectBoxV2
                    id={"except_event_setting_select"}
                    tabIndex={0}
                    width="200px"
                    height="34px"
                    padding="10px 36px 10px 16px"
                    list= {listSelfSelectNameValue}
                    optionTexttName = "name"
                    optionValuetName = "value"
                    selectedValue = {listSelfSelectUuid}
                    onClickOption={onChangeListSelfSelectUuid}
                />
                <div>
                    <button class="list_exclude_btn text_extrasmall hover_grey" onClick={delEsessntialProductList} style={{marginRight:"6px"}}>고정 상품 삭제</button>
                    <button class="list_exclude_btn text_extrasmall hover_mint_b" onClick={() => setAddPopupToggle(true)}>고정 상품 추가</button>
                </div>
                </div> 
                <div class="selected_list_table_wrap">
                    <table class="selected_list_table list_main_board_table">
                        <thead>
                            <tr>
                                <td class="list_main_td">
                                    <CheckBox
                                        checked={productList.length > 0 && productList.length == checkList.length}
                                        onChangeCheck={onChangeProductListCheckAll}
                                    />
                                </td>
                                <td class="list_main_td">순서</td>
                                <td class="list_main_td">사진</td>
                                <td class="list_main_td">상품명</td>
                                <td class="list_main_td">상품등록일</td>
                                <td class="list_main_td">조회수</td>
                                <td class="list_main_td">장바구니수</td>
                                <td class="list_main_td">판매가</td>
                                <td class="list_main_td">할인가</td>
                                <td class="list_main_td">판매수</td>
                                <td class="list_main_td">판매액</td>
                                <td class="list_main_td">리뷰수</td>
                                <td class="list_main_td">리뷰 평점</td>
                            </tr>
                        </thead>
                        
                        {productObj[listSelfSelectUuid] && productObj[listSelfSelectUuid].length > 0 &&
                            <tbody id="selected_list_table_tbody">
                                {productList.map((obj, idx) => (
                                    <tr key={obj.product_no}>
                                        <td>
                                            <CheckBox
                                                value={idx}
                                                checked={checkList.find(product => product.product_no == obj.product_no)}
                                                onChangeCheck={e => onChangeProductListCheck(e, idx)}
                                            />
                                        </td>
                                        <td>{idx+1}</td>
                                        <td>
                                            <img src={
                                                imageTypeCd === "45002"
                                                ? obj.detail_image_url
                                                : obj.image_url
                                            }/>
                                        </td>
                                        <td>
                                            <h4 class="text_extrasmall">
                                                {obj.product_name}
                                            </h4>
                                        </td>
                                        <td>{obj.created_date}</td>
                                        <td>{obj.hit_cnt}</td>
                                        <td>{obj.cart_cnt}</td>
                                        <td>{obj.price}</td>
                                        <td>{obj.pc_discount_price}</td>
                                        <td>{obj.order_cnt}</td>
                                        <td>{obj.order_price}</td>
                                        <td>{obj.review_cnt}</td>
                                        <td>{obj.review_point}</td>
                                    </tr>
                                ))}
                            </tbody>
                        }
                    </table>
                </div>
            </PopupFrame>

            {/* 상품 추가 팝업 */}
            {addPopupToggle &&
                <SearchProductPopup
                    title = {"고정 상품 추가"}
                    selected_product_list = {productObj[listSelfSelectUuid]}
                    image_type_cd = {imageTypeCd}
                    onClose = {() => setAddPopupToggle(false)}
                    onSubmit = {onClickAddProductbtn}
                    submit_btn_name = {"선택 상품 추가"}
                />
            }

            {/* 다이얼로그 */}
            {showBasicDialog &&
                <BasicDialogV2
                content={basicDialogConent}
                button_list={[
                    {
                        title: "확인", type: "1",
                        event_method: () => setShowBasicDialog(false)
                    }
                ]}
                width="auto"
                />
            }
        </>
    );
};

export default EssentialProductTableMgmt
import React from 'react';
// import ReactPixel from 'react-facebook-pixel';
import {ga4react, reactPixel} from './Menu'
import axios from 'axios';
import http from './modules/api';
import { Link } from 'react-router-dom';
import '../css/style.css';
import * as navigator from './modules/navigator';
import MetaTags from 'react-meta-tags';
import Slider from "react-slick";
import logo4 from "../resources/img/icn_logo_v2.svg";
import logo5 from "../resources/img/logo_v2_white.svg";
import login_rouding_background_1 from "../resources/img/login_rouding_background_1.png";
import login_rouding_background_2 from "../resources/img/login_rouding_background_2_1.png";
// import Bookmark from 'react-bookmark';
import Inko from 'inko';
// import { Switch } from 'react-router-dom/cjs/react-router-dom.min';
import {BrowserRouter, Route, Switch, HashRouter} from "react-router-dom";

import {Login} from "./Login_v2";
import {IdSearch} from "./IdSearch";
import {PwdSearch} from "./PwdSearch";
import HomePageHeader from './modules/header/HomePageHeader';

// ReactPixel.init('438227415050093');
let inko = new Inko();

export class LoginParent extends React.Component {
	
	state = {
			setting_login_slider : {
				// variableWidth:true,
				dots: true,
				infinite: true,
				speed: 300,
				slidesToShow: 1,
				slidesToScroll: 1,
				// autoplay : true,
				autoplay : true,
				responsive: [ // 반응형 웹 구현 옵션
					// {  
					// 	breakpoint: 960, //화면 사이즈 960px
					// 	settings: {
					// 		//위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
					// 		slidesToShow:5 
					// 	} 
					// },
					// { 
					// 	breakpoint: 639, //화면 사이즈 639px
					// 	settings: {	
					// 		//위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
					// 		slidesToShow:4,
					// 		slidesToScroll: 4
					// 	} 
					// }
				],
				customPaging: i => (
					<div className="ft-slick__dots--custom">
						<div className="login-slider-custum-dot" />
					</div>
				)
			},
	}
	
	handleText = (e, key) => {
		// computed property
		if(key == "password") {
			// console.log(inko.ko2en(e.target.value));
			this.setState({[key]: inko.ko2en(e.target.value)});
		} else {
			// console.log(e.target.value);
			this.setState({[key]: e.target.value});
		}
	}
	
	handleKeyPress = (e) => {
	    if (e.key === "Enter") {
	    	this.fn_loginProcess();
	    }
	}

	onClickLoginButton = () => {
		this.fn_loginProcess();
	};

	onClickDemoButton = () => {
		sessionStorage.setItem("user_id", "DemoId");
		sessionStorage.setItem("userName","DemoUser");
		sessionStorage.setItem("roleType","none");
		sessionStorage.setItem("redirectUrl","/demo/trend/current");
		sessionStorage.setItem("solution_type_cd","");
	};
	
	fn_loginProcess() {
		const sendForm = {...this.state};
		http.post("/loginProcess", null, {params:sendForm, Pragma: "no-cache"})
			 .then(
					 resultData => {
						 var resUrl = resultData.request.responseURL;
						 console.log(resultData);
						 //console.log(resultData.headers['location']);
						 //console.log(resultData.response.location);
						 //alert("######### resUrl : " + resUrl + "    "+ resultData.request.config.url);
						 
						 if (resultData.request.responseURL == null) {
							 resUrl = "/";
						 } else if (resultData.request.responseURL.indexOf("error") > 0) {
	                        alert("가입하지 않은 아이디이거나, 잘못된 비밀번호입니다.");
	                        return false;
	                        // window.location.href = "/login"
						 }
						 this.fn_loginLog();
						 window.location.href = resUrl;
						 // 로그인전 접근 페이지가 아래의 경로일때만
//						 if (resUrl.indexOf("/shop/pwdMod") > 0) {
//							 //window.location.href = resUrl;
//						 } else {
//							 //window.location.href = "/";
//						 }						 
					 })
			 .catch(
					 //error => { alert("인증되지 않은 사용자 입니다."); console.log(error); }
			 );
	}

	fn_loginLog() {

		const browser = navigator.detectBroeser();
		const os = navigator.detectOS();
		const device = navigator.detectDevice();

		http.get("/rest/common/loginLog", {
			params:{
				browser: browser,
				os: os,
				device: device
			}
		})
		.then(
			resultData => {
				console.log(resultData.data);
		})
		.catch(
			error => {
				console.log(error);
			}
		);
	}

	componentDidMount() {
//		var referrer = sessionStorage.getItem("referrer");
//		if (referrer != null) {
//			//console.log("referrer : " + referrer);
//			sessionStorage.setItem("referrer", document.referrer);
//		}
		// axios.get("/logout")
		// .then(res => {
		// 	// alert("세션이 끊어졌습니다.")
		// })
		// .catch(error => {
		// 	// console.log (error);
		//  });
	}

	// 서비스 소개서 다운로드
	serviceDownload = () => {
		// let pdf_url = 'https://s3.ap-northeast-2.amazonaws.com/otdeal.file/221115_%EB%A1%9C%EB%B3%B4MD+%EC%84%9C%EB%B9%84%EC%8A%A4%EC%86%8C%EA%B0%9C%EC%84%9C.pdf';
		//let pdf_url = 'https://s3.ap-northeast-2.amazonaws.com/otdeal.file/robomd_service_info/AI%E1%84%85%E1%85%A9%E1%84%87%E1%85%A9MD+%E1%84%89%E1%85%A9%E1%84%80%E1%85%A2%E1%84%89%E1%85%A5.pdf'; // 23년 11월 22일 변경
		let pdf_url = 'https://s3.ap-northeast-2.amazonaws.com/otdeal.file/(%EC%A3%BC)%EC%95%84%EC%9D%B4%ED%81%B4%EB%A0%88%EC%9D%B4%EB%B8%8C_%EB%A1%9C%EB%B3%B4MD%EC%84%9C%EB%B9%84%EC%8A%A4%EC%86%8C%EA%B0%9C%EC%84%9C(20231222).pdf'; // 23년 12월 26일 변경
		window.open(pdf_url);

		ga4react.event('[로그인] 서비스 소개서 다운로드', '서비스 소개서 다운로드', '로그인');
		reactPixel.trackCustom('[로그인] 서비스 소개서 다운로드');
	}

	// 회사 소개서 다운로드
	// aboutUsDownload = () => {
	// 	let pdf_url = 'https://s3.ap-northeast-2.amazonaws.com/otdeal.file/robomd_documents/2022/%EC%95%84%EC%9D%B4%ED%81%B4%EB%A0%88%EC%9D%B4%EB%B8%8C_%ED%9A%8C%EC%82%AC%EC%86%8C%EA%B0%9C%EC%84%9C.pdf';
	// 	window.open(pdf_url);

	// 	ga4react.event('[로그인] 회사 소개서 다운로드', '회사 소개서 다운로드', '로그인');
	// 	ReactPixel.trackCustom('[로그인] 회사 소개서 다운로드');
	// }

	render() {
		return (
			<div className="login-container" style={{minHeight: "100vh", position: "relative", minWidth:"1400px"}}>
				<HomePageHeader />
				<MetaTags>
					<title>로보MD | iclave </title>
					<meta name="description" content="MD를 도와주는 똑똑한 인공지능, 로그인" />
					<meta property="og:title" content="로그인 | 로보MD" />
				</MetaTags>

				<div id='non-page-wrapper mobile-non-page-wrapper' style={{margin:0}}>
					
					{/* <Bookmark className="coolClass" href="/" title="ROBOMD" /> */}
					<div className="mobile-flex-wrap middle-box middle-box-mobile text-center" style={{display:"flex", maxWidth:"100%", paddingTop:0, height:"auto", minHeight:"100vh", alignContent: "flex-start"}}>
						<div className="login-50-box" style={{width:"50%", backgroundColor:"#00e2e6", position:"relative"}}>
							<div style={{position:"absolute", left:"0",top:"0"}}>
								<img style={{
									width:"714.29px", 
									height:"auto",
									marginLeft: "-357.145px",
									marginTop:"-357.145px",
								}} src={login_rouding_background_2}/>
							</div>
							<div style={{position:"absolute", right:"0",top:"9%"}}>
								<img style={{
									width:"714.29px", 
									height:"auto",
									marginRight: "-357.145px",
									// marginTop:"-357.145px",
								}} src={login_rouding_background_2}/>
							</div>
							{/* <a href="/">
								<div className="mobile-absolute-align-centet login_logo not_regist_logo">
									<img style={{width:"205.31px", height:"auto"}} src={logo5} />
									<div style={{
												height:"22px",
												marginLeft: "12px",
												marginTop:"-5px",
												color: "#10CFC9",
												backgroundColor: "#ffffff",
												padding: "3px 5px 3px 5px",
												borderBottomLeftRadius: "10px",
												borderBottomRightRadius: "10px",
												borderTopRightRadius: "10px",
												borderTopLeftRadius: "10px",
												fontSize: "11px"
												}} >
										BETA
									</div>
								</div>
							</a> */}
							<div className="mobile-absolute-align-centet mobile-absolute-top-27 login-slider-wrap not_regist_slider_wrap mobile_display_none">
								<Slider {...this.state.setting_login_slider}
                                	arrows={false}
									className="login-slider" 
									>
										<div className="slick-category">
											<div className='slider_lt'>
												쇼핑몰 운영 자동화의<br/>첫 걸음
											</div>
											<div className="mobile-margin-bottom-10 slider_st">
												반복적인 배너 업무, 인공지능 로보MD 에게 맡겨 주세요.
											</div>
										</div>	
										<div className="slick-category">
											<div className='slider_lt'>
												로보MD 팀의 든든한 지원
											</div>
											<div className="mobile-margin-bottom-10 slider_st">
												회원 가입만 하시면 로보MD 팀이 이후 모든 프로세스를 알아서 지원해드립니다.
											</div>
										</div>	
										<div className="slick-category">
											<div className='slider_lt'>
												365일24시간 무제한<br/>배너 생성
											</div>
											<div className="mobile-margin-bottom-10 slider_st">
												로보MD는 끊임없이  변화하는 사용자 데이터에 맞춰 최적의 배너를 만들고 쇼핑몰에 적용해드립니다.
											</div>
										</div>	
								</Slider>
								<div className='btn_wrap btn_wrap_p'>
									<button id="service_info_download_btn" class="login_download_btn" onClick={e => this.serviceDownload()}>서비스 소개서 다운로드</button>
								</div>
							</div>
							{/* <div className="btn-login-manual" style={{position:"absolute", bottom:"35px", right:"25px"}}>
								<a href="https://iclave.notion.site/MD-10e5e04c0372403581d736a1a95f4553" target="blank">
									<div style={{color:"white", fontWeight:"bold"}}>로보MD 이용 가이드</div>
								</a>
							</div> */}
						</div>
						<div className="login-50-box slick-no-blinker" style={{width:"50%", padding:"0 6%"}}>
							<Switch>
								<Route exact path="/login" component={Login}></Route>				
								<Route exact path="/common/id/search" component={IdSearch}></Route>
								<Route exact path="/common/pwd/search" component={PwdSearch}></Route>				
							</Switch>
						</div>
					</div>
				</div>	
				{/* <footer className="flexBox" style={{borderTop: "1px solid  #c3c3c3", position:"absolute", bottom:"0", backgroundColor:"white", width: "100%", padding:"20px 0 20px 0", color:"#3c3c3c", fontSize:"14px"}}>
					<div className="footer-first-child" style={{width:"32%", paddingLeft:"3.5%"}}>
						<div style={{display: "table", width:"auto"}}>
							<div style={{display: "table-cell", verticalAlign: "middle"}}>
								(주)아이클레이브 대표이사 : 최윤내<br/>
								<br/>
								사업자등록번호 : 381-81-00136<br/>
								통신판매번호 : 2021-서울강남-03381<br/>
								사업장 소재지 : 서울특별시 강남구 테헤란로2길, 13층 1308호(역삼동, 패스트파이브빌딩)<br/>
								이메일 : robomd@robomd.io
							</div>
						</div>
					</div>
					<div className="footer-second-child" style={{width:"32%", borderLeft:"1px solid  #c3c3c3", textAlign:"center"}}>
						<div className="flexBox" style={{width:"100%", height:"50%"}}>
							<div style={{width:"33%",height:"100%"}}>
								<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
									<div style={{display: "table-cell", verticalAlign: "middle"}}>
										<a href="https://iclave.co.kr/contact.html" target="blank">문의 하기</a>
									</div>
								</div>
							</div>
							<div style={{width:"33%",height:"100%", borderLeft:"1px solid  #c3c3c3"}}>
								<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
									<div style={{display: "table-cell", verticalAlign: "middle"}}>
										<a href="https://iclave.co.kr" target="blank">회사 사이트</a>
									</div>
								</div>	
							</div>
							<div style={{width:"33%",height:"100%", borderLeft:"1px solid  #c3c3c3"}}>
								<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
									<div style={{display: "table-cell", verticalAlign: "middle"}}>
										<a href="/common/FormViewer/9001" target="blank">이용약관</a>
									</div>
								</div>	
							</div>
						</div>
						<div className="flexBox" style={{width:"100%", height:"55%", paddingTop: "10px", margin: "10px 0 30px 0", borderTop:"1px solid  #c3c3c3"}}>
							<div style={{width:"33%",height:"100%"}}>
								<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
									<div style={{display: "table-cell", verticalAlign: "middle"}}>
										<a href="https://www.instagram.com/otdeal_official" target="blank">Instagram</a>
									</div>
								</div>	
							</div>
							<div style={{width:"33%",height:"100%", borderLeft:"1px solid  #c3c3c3"}}>
								<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
									<div style={{display: "table-cell", verticalAlign: "middle"}}>
										<a href="https://www.facebook.com/otdeal" target="blank">Facebook</a>
									</div>
								</div>
							</div>
							<div style={{width:"33%",height:"100%", borderLeft:"1px solid  #c3c3c3"}}>
								<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
									<div style={{display: "table-cell", verticalAlign: "middle"}}>
										<a href="/common/FormViewer/9002" target="blank">개인정보처리방침</a>
									</div>
								</div>
							</div>
						</div>
					</div>		
					<div className="footer-third-child" style={{width:"32%", borderLeft:"1px solid  #c3c3c3"}}>
					<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
						<div style={{display: "table-cell", verticalAlign: "middle"}}>
							<h1><b>고객센터 : 1599-3518</b></h1><br/>

							오전 10:00 ~ 오후 7:00 lunch 13:00 ~ 14:00 토요일/공휴일 휴무<br/>
						</div>
					</div>
					</div>
				</footer>	 */}

				<footer className="mobile-bottom-11"  style={{padding:"0 2.5%", position:"absolute", bottom:"82px", width:"100%", display:"none"}}>
					<div style={{display:"flex", flexWrap:"wrap", justifyContent:"space-between"}}>
						<div style={{display:"flex", marginBottom:"20px"}}>
							<div>
								<div style={{fontSize:"13px", fontWeight:"bold", marginBottom:"15px"}}>
									정책
								</div>
								<div style={{fontSize:"12px"}}>
									<a href="/common/FormViewer/9001" target="blank">이용약관</a>
								</div>
								<div style={{fontSize:"12px", color: "#10CFC9"}}>
									<a href="/common/FormViewer/9002" target="blank">개인정보 처리방침</a>
								</div>
							</div>
							<div style={{marginLeft:"31px"}}>
								<div style={{fontSize:"13px", fontWeight:"bold", marginBottom:"15px"}}>
									회사
								</div>
								<div style={{fontSize:"12px"}}>
									<a href="https://iclave.co.kr" target="blank">회사소개</a>
								</div>
							</div>
						</div>
						<div style={{textAlign:"right", marginBottom:"20px"}}>
							<div className="mobile_width_100" style={{display:"flex", flexWrap:"wrap", flexDirection:"column"}}>
								<div className="mobile_align_left" style={{fontSize:"13px", fontWeight:"bold", marginBottom:"15px"}}>
									아이클레이브
								</div>
								<div className="mobile_align_left" style={{fontSize:"12px"}}>
									서울특별시 강남구 테헤란로2길 27 패스트파이브(강남 5호점) 빌딩 1308호
								</div>
							</div>
							<div style={{display:"flex", flexWrap:"wrap", fontSize:"12px"}}>
								<div className="mobile_width_100" style={{display:"flex"}}>
									<div>
									Tel
									</div>
									<div style={{fontWeight: "bold", fontSize:"12px", marginLeft:"6px"}}>
										<a href="tel:02-6383-7976" target="blank">02-6383-7976</a>
									</div>
									<div style={{marginLeft:"15px"}}>
									Email
									</div>
									<div style={{fontWeight: "bold", fontSize:"12px", marginLeft:"6px"}}>
										<a href="mailto:robomd@robomd.io" target="blank">robomd@robomd.io</a>
									</div>
								</div>
								<div className="mobile_width_100" style={{display:"flex"}}>
									<div className="mobile_margin_left_0" style={{marginLeft:"15px"}}>
									대표이사
									</div>
									<div style={{fontWeight: "bold", fontSize:"12px", marginLeft:"6px"}}>
									최윤내
									</div>
									<div style={{marginLeft:"15px"}}>
									사업자등록번호
									</div>
									<div style={{fontWeight: "bold", fontSize:"12px", marginLeft:"6px"}}>
									381-81-00136
									</div>
								</div>
							</div>
						</div>
					</div>
				</footer>
				</div>
			
		);
	}
	
}
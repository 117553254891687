import React from "react";

import SimpleHeader from '../modules/header/SimpleHeader';
import { RegistUser_v3 } from './RegistUser_v3';

export class RegistUserParent extends React.Component {
    state = {

    }
    render () {
        return (
            <>
                <SimpleHeader/>
                <RegistUser_v3/>
            </>
        )
    }
}

export default RegistUserParent;
import React from 'react';
import axios from 'axios';
import http from '../modules/api';
import {Route, Switch, NavLink, Link} from "react-router-dom";
// import {AdminUserDetail} from "./AdminUserDetail";
import $ from 'jquery';
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";
import {Line} from 'react-chartjs-2';


import "react-datepicker/dist/react-datepicker.css";
export class AutoEventState extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			shopList : [],
			start_date : "",
			end_date : "",
			start_date_format : "",
			end_date_format : "",
			user_seq : "",
			item_start_date : "",
			item_end_date : "",
			item_start_date_format : "",
			item_end_date_format : "",
			item_user_seq : "",
			sale_start_date : "",
			sale_end_date : "",
			sale_start_date_format : "",
			sale_end_date_format : "",
			sale_user_seq : "",
			eventData : {
				labels:[""],
				datasets:[]
			},
			itemData : {
				labels:[""],
				datasets:[]
			},
			saleData : {
				labels:[""],
				datasets:[]
			},
		}
	}

	componentWillMount() {
		let date = new Date();
		let startDate = new Date(date.getFullYear()+"-"+(date.getMonth()+1)+"-"+"01");
		this.setState({end_date_format: date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate()});
		this.setState({start_date_format: date.getFullYear()+"-"+(date.getMonth()+1)+"-"+"01"});
		this.setState({end_date: date});
		this.setState({start_date: startDate});

		this.setState({item_end_date_format: date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate()});
		this.setState({item_start_date_format: date.getFullYear()+"-"+(date.getMonth()+1)+"-"+"01"});
		this.setState({item_end_date: date});
		this.setState({item_start_date: startDate});

		this.setState({sale_end_date_format: date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate()});
		this.setState({sale_start_date_format: date.getFullYear()+"-"+(date.getMonth()+1)+"-"+"01"});
		this.setState({sale_end_date: date});
		this.setState({sale_start_date: startDate});
	}

	componentDidMount() {
		this.getShopList();
		this.getEventSates();
		this.getEventItemSates();
		this.getEventSaleSates();
	}

	handleEventShopChange = (event) => {
		this.setState({ user_seq: event.target.value });
		new Promise( ( resolve, reject ) => {setTimeout( resolve, 200 ); })
		.then( () => {
			this.getEventSates();
			})
		.catch(error => {
			// console.log (error);
			});
	  };

	handleItemShopChange = (event) => {
		this.setState({ item_user_seq : event.target.value });
		new Promise( ( resolve, reject ) => {setTimeout( resolve, 200 ); })
		.then( () => {
			this.getEventItemSates();
			})
		.catch(error => {
			// console.log (error);
			});
	  };

	handleItemSaleShopChange = (event) => {
		this.setState({ sale_user_seq : event.target.value });
		new Promise( ( resolve, reject ) => {setTimeout( resolve, 200 ); })
		.then( () => {
			this.getEventSaleSates();
			})
		.catch(error => {
			// console.log (error);
			});
	  };

	getShopList() {
		http.get(`/shop/list/shop`).then(res => {
			console.log(res);
			this.setState({shopList : res.data});
			// this.state.eventData.datasets = res.data.datasets;
			// this.state.eventData.labels = res.data.labels;
		})
		.catch(error => {
			console.log (error);
		})
	}

	getEventSates() {
		console.log(this.state.user_seq);
		http.get(`/rest/shop/event/shop/states?start_date=`+this.state.start_date_format+"&end_date="+this.state.end_date_format+"&user_seq="+this.state.user_seq).then(res => {
			console.log(res);
			this.setState({eventData : {datasets: res.data.datasets, labels: res.data.labels}});
			// this.state.eventData.datasets = res.data.datasets;
			// this.state.eventData.labels = res.data.labels;
		})
		.catch(error => {
			console.log (error);
		})
	}

	getEventItemSates() {
		http.get(`/rest/shop/event/item/shop/states?start_date=`+this.state.item_start_date_format+"&end_date="+this.state.item_end_date_format+"&user_seq="+this.state.item_user_seq).then(res => {
			this.setState({itemData : {datasets: res.data.datasets, labels: res.data.labels}});
			// this.state.eventData.datasets = res.data.datasets;
			// this.state.eventData.labels = res.data.labels;
		})
		.catch(error => {
			// console.log (error);
		})
	}

	getEventSaleSates() {
		http.get(`/rest/shop/event/sale/shop/states?start_date=`+this.state.sale_start_date_format+"&end_date="+this.state.sale_end_date_format+"&user_seq="+this.state.sale_user_seq).then(res => {
			this.setState({saleData : {datasets: res.data.datasets, labels: res.data.labels}});
			// this.state.eventData.datasets = res.data.datasets;
			// this.state.eventData.labels = res.data.labels;
		})
		.catch(error => {
			console.log (error);
		})
	}

	handleChangeStartDate = date => {
		if (date != null) {
			this.setState({
				start_date_format: date.getFullYear() + "-"+ parseInt(date.getMonth()+1) +"-"+date.getDate(),
				start_date: date
			});
		} else {
			this.setState({
				start_date_format: date,
				start_date: date
			});
		}
	};
	handleChangeEndDate = date => {
		if (date != null) {
			this.setState({
				end_date_format: date.getFullYear() + "-"+ parseInt(date.getMonth()+1) +"-"+date.getDate(),
				end_date: date
			});
		} else {
			this.setState({
				end_date_format: date,
				end_date: date
			});
		}
	};
	
	handleChangeItemStartDate = date => {
		if (date != null) {
			this.setState({
				item_start_date_format: date.getFullYear() + "-"+ parseInt(date.getMonth()+1) +"-"+date.getDate(),
				item_start_date: date
			});
		} else {
			this.setState({
				item_start_date_format: date,
				item_start_date: date
			});
		}
	};
	handleChangeItemEndDate = date => {
		if (date != null) {
			this.setState({
				item_end_date_format: date.getFullYear() + "-"+ parseInt(date.getMonth()+1) +"-"+date.getDate(),
				item_end_date: date
			});
		} else {
			this.setState({
				item_end_date_format: date,
				item_end_date: date
			});
		}
	};
	
	handleChangeSaleStartDate = date => {
		if (date != null) {
			this.setState({
				sale_start_date_format: date.getFullYear() + "-"+ parseInt(date.getMonth()+1) +"-"+date.getDate(),
				sale_start_date: date
			});
		} else {
			this.setState({
				sale_start_date_format: date,
				sale_start_date: date
			});
		}
	};
	handleChangeSaleEndDate = date => {
		if (date != null) {
			this.setState({
				sale_end_date_format: date.getFullYear() + "-"+ parseInt(date.getMonth()+1) +"-"+date.getDate(),
				sale_end_date: date
			});
		} else {
			this.setState({
				sale_end_date_format: date,
				sale_end_date: date
			});
		}
	};

	onClickSearchListButton = () => {
		this.getEventSates();
	};

	onClickItemSearchListButton = () => {
		this.getEventItemSates();
	};

	onClickSaleSearchListButton = () => {
		this.getEventSaleSates();
	};

	render() {
		
		return (
			<div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
			<div id="notice_list_component">
					<h3>[Admin] 기획전 통계</h3>
					<br/>
					<h4>[배너 클릭수 - 업체별]</h4>
					<br/>
						<div style={{width:"380px", display:"flex", margin: "auto"}}>
							<div style={{width:"60px"}}><h4 style={{lineHeight:"22px"}}>날짜</h4></div>
							<div className="input-daterange input-group" id="datepicker">
								<DatePicker name="start_date" id="start_date"
									selected={this.state.start_date}
									onSelect={this.handleSelect} //when day is clicked
									onChange={this.handleChangeStartDate} //only when value has changed
									// showTimeSelect
									dateFormat="yyyy-MM-dd"
									autoComplete='off'
								/>
								<div className="input-group-addon" style={{width: "40px",height:"35px", lineHeight: "34px",textAlign:"center"}}>to</div>
								<DatePicker name="end_date" id="end_date"
									selected={this.state.end_date}
									onSelect={this.handleSelect} //when day is clicked
									onChange={this.handleChangeEndDate} //only when value has changed
									// showTimeSelect
									dateFormat="yyyy-MM-dd"
									autoComplete='off'
								/>	
							</div>
							<button className = "greenButton" onClick={this.onClickSearchListButton} style={{width: "55px",height: "35px", margin: "0px 20px 0px -40px"}}>조회</button>
						</div>
					<br/>					
					<div className="input-group m-b" style={{width:"380px", display:"flex", margin: "auto"}}>
						<div style={{width:"60px"}}><h4 style={{lineHeight:"22px"}}>업체</h4></div>
						<div style={{width: "calc(100% - 68px)", marginLeft: "-12px"}}>
							<select id="eventClickShops" onChange={this.handleEventShopChange} value={this.state.user_seq} style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px", marginBottom:"10px"}}>
								<option value="">
									업체를 선택해 주세요
								</option>
								{this.state.shopList.map(shop => (
									<option key={shop.user_seq} value={shop.user_seq}>
										{shop.company_name}
									</option>
								))}
							</select>
						</div>
					</div>
					<br/>					
					<Line key={0} data={this.state.eventData} /> 
					<br/>
					<br/>
					<h4>[상품 클릭수 - 업체별]</h4>
					<br/>
						<div style={{width:"380px", display:"flex", margin: "auto"}}>
							<div style={{width:"60px"}}><h4 style={{lineHeight:"22px"}}>날짜</h4></div>
							<div className="input-daterange input-group" id="datepicker">
								<DatePicker name="start_date" id="start_date"
									selected={this.state.item_start_date}
									onSelect={this.handleSelect} //when day is clicked
									onChange={this.handleChangeItemStartDate} //only when value has changed
									// showTimeSelect
									dateFormat="yyyy-MM-dd"
									autoComplete='off'
								/>
								<div className="input-group-addon" style={{width: "40px",height:"35px", lineHeight: "34px",textAlign:"center"}}>to</div>
								<DatePicker name="end_date" id="end_date"
									selected={this.state.item_end_date}
									onSelect={this.handleSelect} //when day is clicked
									onChange={this.handleChangeItemEndDate} //only when value has changed
									// showTimeSelect
									dateFormat="yyyy-MM-dd"
									autoComplete='off'
								/>	
							</div>
							<button className = "greenButton" onClick={this.onClickItemSearchListButton} style={{width: "55px",height: "35px", margin: "0px 20px 0px -40px"}}>조회</button>
						</div>
					<br/>
					<div className="input-group m-b" style={{width:"380px", display:"flex", margin: "auto"}}>
						<div style={{width:"60px"}}><h4 style={{lineHeight:"22px"}}>업체</h4></div>
						<div style={{width: "calc(100% - 68px)", marginLeft: "-12px"}}>
							<select id="eventClickShops" onChange={this.handleItemShopChange} value={this.state.item_user_seq} style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px", marginBottom:"10px"}}>
								<option value="">
									업체를 선택해 주세요
								</option>
								{this.state.shopList.map(shop => (
									<option key={shop.user_seq} value={shop.user_seq}>
										{shop.company_name}
									</option>
								))}
							</select>
						</div>
					</div>			
					<Line key={1} data={this.state.itemData} /> 
					<br/>
					<br/>
					<h4>[상품 판매수 - 업체별]</h4>
					<br/>
						<div style={{width:"380px", display:"flex", margin: "auto"}}>
							<div style={{width:"60px"}}><h4 style={{lineHeight:"22px"}}>날짜</h4></div>
							<div className="input-daterange input-group" id="datepicker">
								<DatePicker name="start_date" id="start_date"
									selected={this.state.sale_start_date}
									onSelect={this.handleSelect} //when day is clicked
									onChange={this.handleChangeSaleStartDate} //only when value has changed
									// showTimeSelect
									dateFormat="yyyy-MM-dd"
									autoComplete='off'
								/>
								<div className="input-group-addon" style={{width: "40px",height:"35px", lineHeight: "34px",textAlign:"center"}}>to</div>
								<DatePicker name="end_date" id="end_date"
									selected={this.state.sale_end_date}
									onSelect={this.handleSelect} //when day is clicked
									onChange={this.handleChangeSaleEndDate} //only when value has changed
									// showTimeSelect
									dateFormat="yyyy-MM-dd"
									autoComplete='off'
								/>	
							</div>
							<button className = "greenButton" onClick={this.onClickSaleSearchListButton} style={{width: "55px",height: "35px", margin: "0px 20px 0px -40px"}}>조회</button>
						</div>
					<br/>	
					<div className="input-group m-b" style={{width:"380px", display:"flex", margin: "auto"}}>
						<div style={{width:"60px"}}><h4 style={{lineHeight:"22px"}}>업체</h4></div>
						<div style={{width: "calc(100% - 68px)", marginLeft: "-12px"}}>
							<select id="eventClickShops" onChange={this.handleItemSaleShopChange} value={this.state.sale_user_seq} style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px", marginBottom:"10px"}}>
								<option value="">
									업체를 선택해 주세요
								</option>
								{this.state.shopList.map(shop => (
									<option key={shop.user_seq} value={shop.user_seq}>
										{shop.company_name}
									</option>
								))}
							</select>
						</div>
					</div>			
					<Line key={2} data={this.state.saleData} /> 
			</div>
			</div>
		);
	}
}

// 훅
import React from 'react';
import { Link } from "react-router-dom";

// 콩통 모듈
import http from '../modules/api';
import * as navigator from '../modules/navigator';
import * as CookieUtil  from "../modules/utils/CookieUtil.js";

// 컴포넌트
import { MarketingArea } from './MarketingArea';
import CheckBox from '../modules/common/CheckBox';

// 스타일
import '../../css/login_style.css';
import logo from '../../resources/img/icn_logo_v2.svg';

import Inko from 'inko';
let inko = new Inko();

let isPasswordReSetting = false;
export class Login_v3 extends React.Component {
    state = {
        // idChk : false, // 아이디 인풋 : true/false
        // pwChk : false, // 비밀번호 인풋 : true/false
        dialogPopup: false, // 다이얼로그 숨김/보임 : true/false
        findMyInfoType: "ID", // 아이디 찾기 / 비밀번호 찾기 텝 전환 : ID/PW
        findMyIdStep: "default", // 아이디 찾기 단계 : default/after => 추후에 단계 추가되는 것 고려해 문자열로 제작
        findMyIdSuccess: true, // 아이디 찾기 성공/실페 : true/false
        findMyIdDefaultBtnChk: false, // 아이디 찾기 버튼 활성화/비활성화 : true/false
        findMyPwStep: "default", // 비밀번호 찾기 단계 : default/after => 추후에 단계 추가되는 것 고려해 문자열로 제작
        findMyPwSuccess: true, // 비밀번호 찾기 성공/실패 : true/false
        findMyPwDefaultBtnChk: false, // 비밀번호 찾기 버튼 활성화/비활성화 : true/false
        
        idSearchResultList : [
            // {
            //     userId: "robomd", // 아이디 찾기 성공 시 보여줄 아이디
            //     userRegistDate: "2023-01-01 12:12:12", // 아이디 찾기 성공 시 보여줄 회원가입 일자
            // }
        ],

        saveId: false, // 아이디 저장 여부

        loginForm : {
            username: '',
            password: '',
        },
        findIdForm : {
            email: '',
        },
        findPasswordForm : {
            user_id: '',
            email: '',
        }
    }

    /** 엔터키 클릭 시 로그인 이벤트 */
    // handleEnter = (e) => {
    //     if (e.code === "Enter") {
    //         /** 로그인 이벤트 */
    //     }
    // }

    componentDidMount() {
        let {loginForm} = this.state

        if (CookieUtil.getCookie("isLoginIdSave") == "Y") {
            loginForm = {
                username: CookieUtil.getCookie("loginSavedId"),
                password: '',
            }
            this.setState({
                loginForm: loginForm,
                saveId: CookieUtil.getCookie("lisLoginIdSave") == "Y" ? true : false,
            })
        } else {
            loginForm = {
                username: '',
                password: '',
            }
            this.setState({
                loginForm: loginForm,
                saveId: CookieUtil.getCookie("lisLoginIdSave") == "Y" ? true : false,
            })
        }
    }

    /** 다이얼로그 숨김/표시 */
    handleDialogPopup = () => {
        this.setState({dialogPopup:true});
        if (window.innerWidth <= 888) { document.body.style.cssText = `width: 100%; position: fixed; top: -${window.scrollY}px; overflow-y: scroll;`; }
    }
    handleDialogClose = () => {
        this.setState({
            dialogPopup:false, 
            findMyInfoType:"ID", 
            findMyIdStep:"default", 
            findMyIdDefaultBtnChk:false,
            findMyPwStep:"default", 
            findMyPwDefaultBtnChk:false,
        });
        if (window.innerWidth <= 888) { const scrollY = document.body.style.top; document.body.style.cssText = ''; window.scrollTo(0, parseInt(scrollY || '0', 10) * -1); }
    }

    /** 아이디 찾기 / 비밀번호 찾기 클릭 이벤트 */
    handleFindMyId = () => {
        this.setState({
            findMyInfoType:"ID",
            findMyIdStep:"default",
            findMyIdDefaultBtnChk:false,
        });
        if (document.getElementById("search_id") !== null) {
            document.getElementById("search_id").value = "";
            document.getElementById("search_email").value = "";
        }
    }
    handleFindMyPw = () => {
        this.setState({
            findMyInfoType:"PW",
            findMyPwStep:"default",
            findMyPwDefaultBtnChk:false,
        });
        if (document.getElementById("find_my_id") !== null) {
            document.getElementById("find_my_id").value = "";
        }
    }

    /** 아이디 찾기 / 비밀번호 찾기 체인지 이벤트 */
    handleFindMyIdChange = (e) => { if (this.state.findMyInfoType === "ID") { e.target.checked = true; } }
    handleFindMyPwChange = (e) => { if (this.state.findMyInfoType === "PW") { e.target.checked = true; } }

    /** 아이디 찾기 - 아이디 확인하기 이메일 값이 들어있는지 체크 */
    handleFindMyIdDefaultBtnChk = (e) => {
        let {findIdForm} = this.state;
        findIdForm = {
            email : e.target.value
        }
        this.setState({findIdForm:findIdForm}); 
        
        if (e.target.value !== "") { 
            this.setState({findMyIdDefaultBtnChk:true}); 
        }  else { 
            this.setState({findMyIdDefaultBtnChk:false}); 
        }
    }

    /** 아이디 찾기 - 아이디 확인하기 버튼 클릭 이벤트 */
    handleFindMyIdDefaultBtnClick = (e) => { 
        if (!e.target.classList.contains("disabled")) { 
            this.idSearch();
        }
    }
    handleFindMyIdDefaultBtnKeydown = (e) => {
        let thisBtn = document.getElementsByClassName("confirm_id")[0];
        if (!thisBtn.classList.contains("disabled")) {
            if (e.code === "Enter") {
                this.idSearch();
            }
        }
    }

    /** 아이디 찾기 - 비밀번호 재설정하기 버튼 클릭 이벤트 */
    handleFindMyIdSuccessBtnClick = () => {
        this.setState({findMyInfoType:"PW"});
        document.getElementById("find_my_pw_tab").checked = true;
    }

    /** 아이디 찾기 - 다시 입력하기 버튼 클릭 이벤트 */
    handleFindMyIdFailBtnClick = () => { this.setState({findMyIdStep:"default"}); }

    /** 비밀번호 찾기 - 아이디 값 들어있는 지 체크 */
    handleFindMyPwIdChk = (e) => {
        let {findPasswordForm} = this.state;
        findPasswordForm = {
            user_id : e.target.value,
            email : findPasswordForm.email,
        }
        this.setState({findPasswordForm:findPasswordForm}); 
        if (e.target.value !== "") { 
            if (document.getElementById("search_email").value !== "") { 
                this.setState({findMyPwDefaultBtnChk:true}) 
            }
        } else { 
            this.setState({findMyPwDefaultBtnChk:false}); 
        }
    }

    /** 비밀번호 찾기 - 이메일 값 들어있는 지 체크 */
    handleFindMyPwEmailChk = (e) => {
        let {findPasswordForm} = this.state;
        findPasswordForm = {
            user_id : findPasswordForm.user_id,
            email : e.target.value,
        }
        this.setState({findPasswordForm:findPasswordForm}); 
        if (e.target.value !== "") { 
            if (document.getElementById("search_id").value !== "") { 
                this.setState({findMyPwDefaultBtnChk:true}) 
            } 
        }
        else { 
            this.setState({findMyPwDefaultBtnChk:false}); 
        }
    }

    /** 비밀번호 찾기 - 비밀번호 재설정하기 버튼 클릭 이벤트 */
    handleFindMyPwDefaultBtnClick = (e) => { if (!e.target.classList.contains("disabled")) { 
        this.passwordReSetting();
    }}
    handleFindMyPwDefaultBtnKeydown = (e) => {
        let thisBtn = document.getElementsByClassName("reset_pw_btn")[0];
        if (!thisBtn.classList.contains("disabled")) {
            if (e.code === "Enter") {
                this.passwordReSetting();
            }
        }
    }

    /** 비밀번호 찾기 - 확인 버튼 클릭 이벤트 */
    handleFindMyPwSuccessBtnClick = () => {
        this.setState({
            dialogPopup:false, 
            findMyInfoType:"ID", 
            findMyIdStep:"default", 
            findMyIdDefaultBtnChk:false,
            findMyPwStep:"default", 
            findMyPwDefaultBtnChk:false,
        });
        if (window.innerWidth <= 888) { const scrollY = document.body.style.top; document.body.style.cssText = ''; window.scrollTo(0, parseInt(scrollY || '0', 10) * -1); }
    }

    /** 비밀번호 찾기 - 다시 입력하기 버튼 클릭 이벤트 */
    handleFindMyPwFailBtnClick = () => { this.setState({findMyPwStep:"default"}); }

    handleKeyPress = (e) => {
	    if (e.key === "Enter") {
	    	this.fn_loginProcess();
	    }
	}

    handleText = (e, key) => {
        let loginForm = this.state.loginForm;
		// computed property
		if(key == "password") {
			// console.log(inko.ko2en(e.target.value));
            loginForm.password = inko.ko2en(e.target.value);
			this.setState({"loginForm": loginForm});
		} else {
			// console.log(e.target.value);
            loginForm.username = e.target.value;
			this.setState({"loginForm": loginForm});
		}
        console.log(loginForm);
	}

	onClickLoginButton = () => {
		this.fn_loginProcess();
	}

    fn_loginProcess() {
        if (this.state.loginForm.username == null || this.state.loginForm.username == ""
        || this.state.loginForm.password == null || this.state.loginForm.password == "") {
            alert("아이디 또는 비밀번호를 입력해 주세요.");
            return false;
        }

		const sendForm = {...this.state.loginForm};
		http.post("/loginProcess", null, {params:sendForm, Pragma: "no-cache"})
        .then(
            resultData => {
            var resUrl = resultData.request.responseURL;
            //console.log(JSON.stringify(resultData));
            //console.log("resultData.request.responseURL : "+(resultData.request.responseURL));
            //console.log(resultData.headers['location']);
            //console.log(resultData.response.location);
            //alert("######### resUrl : " + resUrl + "    "+ resultData.request.config.url);

            // 아이디 저장 여부에 따른 쿠키 저장
            if (this.state.saveId) {
                CookieUtil.setCookie("loginSavedId", this.state.loginForm.username, 365);
                CookieUtil.setCookie("isLoginIdSave", "Y", 365);
            } else {
                CookieUtil.deleteCookie("loginSavedId");
                CookieUtil.setCookie("isLoginIdSave", "N", 365);
            }
            
            if (resultData.request.responseURL == null) {
            resUrl = "/";
            } else if (resultData.request.responseURL.indexOf("error") > 0) {
            alert("가입하지 않은 아이디이거나, 잘못된 비밀번호입니다.");
            return false;
            // window.location.href = "/login"
            }

            //let r = (Math.random() + 1).toString(36).substring(7);
            this.fn_loginLog();
            window.location.href = resUrl;	// + "?v=" + r;
            sessionStorage.setItem("isLoadBannerInstallCntForMenu", true); // 메뉴용 설치 여부 적용 상태값

            // 로그인전 접근 페이지가 아래의 경로일때만
    //						 if (resUrl.indexOf("/shop/pwdMod") > 0) {
    //							 //window.location.href = resUrl;
    //						 } else {
    //							 //window.location.href = "/";
    //						 }						 
        })
        .catch(
                //error => { alert("인증되지 않은 사용자 입니다."); console.log(error); }
        );
	}

    fn_loginLog() {
		const browser = navigator.detectBroeser();
		const os = navigator.detectOS();
		const device = navigator.detectDevice();

		http.get("/rest/common/loginLog", {
			params:{
				browser: browser,
				os: os,
				device: device
			}
		})
		.then(
			resultData => {
				console.log(resultData.data);
		})
		.catch(
			error => {
				console.log(error);
			}
		);
	}

    // 아이디 조회 API
    idSearch () {
		let {findIdForm, idSearchResultList} = this.state;
		//1. Validation Check
		if (findIdForm.email == null || findIdForm.email == "") {
			alert("이메일을 입력해 주세요.");
			return false;
		}
		
        this.setState({
            idSearchResultList : [], // 초기화
        }, () => {
            http.get("/rest/common/id/search/v2", {params:findIdForm})
            .then(
                    resultData => {
                        var data = resultData.data;
                        if (data.length > 0) {
                            data.map(user => (
                                idSearchResultList.push({
                                    userId: user.masking_id, // 아이디 찾기 성공 시 보여줄 아이디
                                    userRegistDate: user.reg_date + " 가입", // 아이디 찾기 성공 시 보여줄 회원가입 일자
                                })
                            ));
                            console.log("resultData.data", idSearchResultList);
                            this.setState({
                                idSearchResultList : idSearchResultList,
                            });
                        } else {
                        // alert("가입하신 아이디가 없습니다.");
                        }
                        this.setState({
                            findMyIdStep:"after",
                        })
                    })
            .catch(
                    error => {
                        console.log(error);
                    }
            );
        });
    }

    // 비밀번호 재설정
    passwordReSetting () {
        console.log("isPasswordReSetting", isPasswordReSetting);
        if (isPasswordReSetting) {
            return false;
        }

		let {findPasswordForm} = this.state;
		//1. Validation Check
		if (findPasswordForm.user_id == null || findPasswordForm.user_id == "") {
			alert("아이디를  입력해 주세요.");
			return false;
		}
		if (findPasswordForm.email == null || findPasswordForm.email == "") {
			alert("이메일을 입력해 주세요.");
			return false;
		}
		const config = {
            headers: {
                'Content-Type': 'application/json',
                },
        };
        isPasswordReSetting = true;
        http.post("/rest/common/email/changePassword", findPasswordForm, config)
        .then(
                resultData => {
                    var data = resultData.data;
                    if (data.code == 200) {
                        this.setState({
                            findMyPwSuccess:true,
                        })
                    } else {
                        this.setState({
                            findMyPwSuccess:false,
                        })
                    }
                    this.setState({
                        findMyPwStep:"after",
                    })

                    isPasswordReSetting = false;
                })
        .catch(
                error => {
                    console.log(error);
                    this.setState({
                        findMyPwSuccess:false,
                    })

                    isPasswordReSetting = false;
                }
        );
    }

    render () {
        return (
            <>
                <div className='only_login_page login_style_guide'>
                    <div className='login'>
                        <div className='login_content'>
                            <Link to={'/'}><img className='logo' src={logo} alt='로고'/></Link>
                            <h2>
                                쇼핑몰 운영 자동화의 첫 걸음 <br/>
                                로보MD와 함께 하세요.
                            </h2>
                            <Link to={'/common/RegistUser_v3'} className='regist_btn hover_mint'>회원가입</Link>
                            <div className='divider'></div>
                            <form className='login_form'>
                                <div className='login_input'>
                                    <label htmlFor='login_id'>아이디</label>
                                    <input type='text' id='login_id' placeholder='아이디를 입력해 주세요.' value={this.state.loginForm.username} onChange={(e) => this.handleText(e, 'username')} onKeyDown={e => this.handleKeyPress(e)}/>
                                    {/* <input className='save_id' type='checkbox' id='save_id'/>
                                    <label className='save_id_icn' htmlFor='save_id' onClick={() => {
                                        this.setState({
                                            saveId : !this.state.saveId
                                        }, () => {
                                            // alert(this.state.saveId)
                                        })
                                    }}>
                                        <svg className='checkbox_default' viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 9C3 5.68629 5.68629 3 9 3H23C26.3137 3 29 5.68629 29 9V23C29 26.3137 26.3137 29 23 29H9C5.68629 29 3 26.3137 3 23V9Z" fill="white"/><path fillRule="evenodd" clipRule="evenodd" d="M23 4H9C6.23858 4 4 6.23858 4 9V23C4 25.7614 6.23858 28 9 28H23C25.7614 28 28 25.7614 28 23V9C28 6.23858 25.7614 4 23 4ZM9 3C5.68629 3 3 5.68629 3 9V23C3 26.3137 5.68629 29 9 29H23C26.3137 29 29 26.3137 29 23V9C29 5.68629 26.3137 3 23 3H9Z" fill="#ACACAC"/><path className='check_icn' d="M24.6443 10.4134C24.8811 10.6655 25.0093 11.0084 24.9995 11.3613C24.9995 11.7142 24.8416 12.047 24.595 12.289L15.0346 21.6364C14.7879 21.8785 14.4624 22.0095 14.117 21.9995C13.7816 21.9995 13.456 21.8684 13.2192 21.6364L8.44398 16.9678C8.31572 16.8467 8.20719 16.7056 8.12826 16.5442C8.04933 16.3829 8.00987 16.2014 8 16.03C8 15.8485 8.01973 15.667 8.07893 15.5056C8.13813 15.3342 8.23679 15.183 8.35518 15.0519C8.47358 14.9208 8.62157 14.82 8.77943 14.7494C8.93729 14.6788 9.11488 14.6384 9.29247 14.6384C9.47006 14.6384 9.64766 14.6687 9.80552 14.7393C9.96337 14.8099 10.1114 14.9107 10.2396 15.0418L14.1269 18.8231L22.8092 10.363C23.0558 10.121 23.3913 10 23.7366 10C24.0819 10 24.4075 10.1613 24.6443 10.4134Z" fill="#D1D1D1"/></svg>
                                        <svg className='checkbox_checked' viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 9C3 5.68629 5.68629 3 9 3H23C26.3137 3 29 5.68629 29 9V23C29 26.3137 26.3137 29 23 29H9C5.68629 29 3 26.3137 3 23V9Z" fill="#00E2E6"/><path d="M24.6443 10.4134C24.8811 10.6655 25.0093 11.0084 24.9995 11.3613C24.9995 11.7142 24.8416 12.047 24.595 12.289L15.0346 21.6364C14.7879 21.8785 14.4624 22.0095 14.117 21.9995C13.7816 21.9995 13.456 21.8684 13.2192 21.6364L8.44398 16.9678C8.31572 16.8467 8.20719 16.7056 8.12826 16.5442C8.04933 16.3829 8.00987 16.2014 8 16.03C8 15.8485 8.01973 15.667 8.07893 15.5056C8.13813 15.3342 8.23679 15.183 8.35518 15.0519C8.47358 14.9208 8.62157 14.82 8.77943 14.7494C8.93729 14.6788 9.11488 14.6384 9.29247 14.6384C9.47006 14.6384 9.64766 14.6687 9.80552 14.7393C9.96337 14.8099 10.1114 14.9107 10.2396 15.0418L14.1269 18.8231L22.8092 10.363C23.0558 10.121 23.3913 10 23.7366 10C24.0819 10 24.4075 10.1613 24.6443 10.4134Z" fill="#333333"/></svg>
                                        <span>아이디 저장</span>
                                    </label> */}
                                    <div className='save_id'>
                                        <CheckBox
                                            className='save_id_icn'
                                            id={"save_id"}
                                            value={this.state["saveId"]}
                                            checked={this.state["saveId"]}
                                            onChangeCheck={() => this.setState({
                                                saveId : !this.state.saveId
                                            })}
                                            style={{marginBottom:"0px"}}
                                        >
                                        <span>아이디 저장</span>
                                        </CheckBox>
                                    </div>
                                </div>
                                <div className='login_input'>
                                    <label htmlFor='login_pw'>비밀번호</label>
                                    <input type='password' id='login_pw' placeholder='비밀번호를 입력해 주세요.' autoComplete='on'  value={this.state.loginForm.password} onChange={(e) => this.handleText(e, 'password')} onKeyDown={e => this.handleKeyPress(e)}/>
                                </div>
                                <button className="hover_mint login_btn" onClick={() => this.onClickLoginButton()} type="button">로그인</button>
                            </form>
                            <div className='find_my_info'>
                                <h3 style={{paddingBottom:0}}>계정을 잊으셨나요?</h3>
                                <h4 onClick={this.handleDialogPopup}>아이디 비밀번호 찾기</h4>
                            </div>
                            {
                                this.state.dialogPopup === true
                                ? <div className='dialog_wrap'>
                                    <div className='dialog'>
                                        <svg onClick={this.handleDialogClose} className='cancel_btn' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.2847 18.2154C19.4255 18.3575 19.5045 18.5496 19.5045 18.7497C19.5045 18.9499 19.4255 19.1419 19.2847 19.2841C19.1413 19.4227 18.9497 19.5002 18.7503 19.5002C18.5509 19.5002 18.3593 19.4227 18.2159 19.2841L12.0003 13.0591L5.78469 19.2841C5.64133 19.4227 5.44972 19.5002 5.25031 19.5002C5.0509 19.5002 4.85929 19.4227 4.71594 19.2841C4.5751 19.1419 4.49609 18.9499 4.49609 18.7497C4.49609 18.5496 4.5751 18.3575 4.71594 18.2154L10.9409 11.9997L4.71594 5.7841C4.59634 5.63838 4.53522 5.45338 4.54447 5.26509C4.55372 5.0768 4.63267 4.89869 4.76597 4.76539C4.89927 4.63208 5.07739 4.55313 5.26568 4.54388C5.45397 4.53464 5.63897 4.59575 5.78469 4.71535L12.0003 10.9404L18.2159 4.71535C18.3617 4.59575 18.5467 4.53464 18.7349 4.54388C18.9232 4.55313 19.1014 4.63208 19.2347 4.76539C19.368 4.89869 19.4469 5.0768 19.4562 5.26509C19.4654 5.45338 19.4043 5.63838 19.2847 5.7841L13.0597 11.9997L19.2847 18.2154Z" fill="#333333"/></svg>
                                        <div className="tab_wrap">
                                            <input onClick={() => this.handleFindMyId()} onChange={e => this.handleFindMyIdChange(e)} className="tab" type="radio" id="find_my_id_tab" name="find_my_info" defaultChecked={this.state.findMyInfoType === "ID" ? true : false}/>
                                            <label htmlFor="find_my_id_tab">아이디 찾기</label>
                                            <input onClick={() => this.handleFindMyPw()} onChange={e => this.handleFindMyPwChange(e)} className="tab" type="radio" id="find_my_pw_tab" name="find_my_info" defaultChecked={this.state.findMyInfoType === "PW" ? true : false}/>
                                            <label htmlFor="find_my_pw_tab">비밀번호 찾기</label>
                                        </div>
                                        <div className='find_my_info_content_wrap'>
                                                {
                                                    this.state.findMyInfoType === "ID"
                                                    ? <div className='find_my_info_content find_my_id_content'>
                                                        {
                                                            this.state.findMyIdStep === "default"
                                                            ? <>
                                                                <div className='find_my_info_common find_my_id_common'>
                                                                    <label htmlFor='find_my_id'>이메일</label>
                                                                    <input type='text' id='find_my_id' placeholder='가입 이메일 주소를 입력해주세요.' onChange={e => this.handleFindMyIdDefaultBtnChk(e)} onKeyDown={e => this.handleFindMyIdDefaultBtnKeydown(e)}/>
                                                                </div>
                                                                <button onClick={e => this.handleFindMyIdDefaultBtnClick(e)} className={this.state.findMyIdDefaultBtnChk === true ? "confirm_id hover_mint" : "confirm_id hover_mint disabled"}>아이디 확인하기</button>
                                                            </>
                                                            : ( this.state.findMyIdStep === "after"
                                                                ? ( 
                                                                    // this.state.findMyIdSuccess === true
                                                                    this.state.idSearchResultList.length > 0
                                                                    ? <>
                                                                        <div className='find_my_info_common find_my_id_success'
                                                                        style={{
                                                                            height : "auto",
                                                                            maxHeight: "70vh",
                                                                        }}
                                                                        >
                                                                            <div style={{
                                                                                width:"40px",
                                                                                height:"40px",
                                                                                margin: "0 auto",
                                                                            }}>
                                                                            <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="19.5827" cy="19.5837" r="15.4167" fill="#333333"/><path d="M19.584 3.33301C16.37 3.33301 13.2283 4.28605 10.556 6.07163C7.88368 7.8572 5.80087 10.3951 4.57095 13.3644C3.34103 16.3337 3.01922 19.601 3.64623 22.7532C4.27324 25.9054 5.8209 28.8009 8.09351 31.0735C10.3661 33.3461 13.2616 34.8938 16.4138 35.5208C19.566 36.1478 22.8333 35.826 25.8026 34.5961C28.7719 33.3661 31.3098 31.2833 33.0954 28.611C34.8809 25.9387 35.834 22.797 35.834 19.583C35.8257 15.2758 34.111 11.1473 31.0654 8.10165C28.0197 5.05597 23.8912 3.34127 19.584 3.33301V3.33301ZM27.3184 16.7393L18.1621 25.4893C17.9254 25.7119 17.6121 25.835 17.2871 25.833C17.1283 25.8353 16.9706 25.8061 16.8231 25.7471C16.6756 25.6881 16.5412 25.6005 16.4277 25.4893L11.8496 21.1143C11.7226 21.0035 11.6194 20.8682 11.546 20.7164C11.4727 20.5647 11.4308 20.3998 11.4228 20.2314C11.4148 20.0631 11.441 19.8949 11.4997 19.7369C11.5584 19.579 11.6484 19.4345 11.7644 19.3122C11.8803 19.19 12.0198 19.0924 12.1744 19.0254C12.329 18.9584 12.4956 18.9234 12.6641 18.9224C12.8326 18.9214 12.9996 18.9545 13.155 19.0197C13.3104 19.0849 13.451 19.1808 13.5684 19.3018L17.2871 22.8486L25.5996 14.9268C25.8427 14.7147 26.1583 14.6048 26.4805 14.6201C26.8027 14.6353 27.1065 14.7745 27.3284 15.0086C27.5504 15.2426 27.6732 15.5534 27.6714 15.8759C27.6695 16.1985 27.543 16.5078 27.3184 16.7393Z" fill="#00E2E6"/></svg>
                                                                            </div>
                                                                            <h3>회원님의 아이디를 찾았습니다.</h3>
                                                                            <div style={{width: "100%",
                                                                                height : "auto",
                                                                                overflow : "scroll"                                                                                   
                                                                            }}>
                                                                                {
                                                                                    this.state.idSearchResultList.map((obj, idx) => (
                                                                                        idx > 0 
                                                                                        ?   <div className='user_info' style={{marginTop: "10px"}}>
                                                                                                <div className='user_id'>ID : {obj.userId}</div>
                                                                                                <div className='user_regist_date'>{obj.userRegistDate} 가입</div>
                                                                                            </div>
                                                                                        :   <div className='user_info'>
                                                                                                <div className='user_id'>ID : {obj.userId}</div>
                                                                                                <div className='user_regist_date'>{obj.userRegistDate} 가입</div>
                                                                                            </div>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <button onClick={() => this.handleFindMyIdSuccessBtnClick()} className='hover_mint'>비밀번호 재설정하기</button>
                                                                    </>
                                                                    : <>
                                                                        <div className='find_my_info_common find_my_id_fail'>
                                                                            <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 3.75C16.7861 3.75 13.6443 4.70305 10.972 6.48862C8.29969 8.27419 6.21689 10.8121 4.98696 13.7814C3.75704 16.7507 3.43524 20.018 4.06225 23.1702C4.68926 26.3224 6.23692 29.2179 8.50952 31.4905C10.7821 33.7631 13.6776 35.3107 16.8298 35.9378C19.982 36.5648 23.2493 36.243 26.2186 35.013C29.1879 33.7831 31.7258 31.7003 33.5114 29.028C35.297 26.3557 36.25 23.2139 36.25 20C36.2459 15.6915 34.5325 11.5607 31.4859 8.51408C28.4394 5.46751 24.3085 3.75414 20 3.75ZM18.75 12.5C18.75 12.1685 18.8817 11.8505 19.1161 11.6161C19.3505 11.3817 19.6685 11.25 20 11.25C20.3315 11.25 20.6495 11.3817 20.8839 11.6161C21.1183 11.8505 21.25 12.1685 21.25 12.5V21.25C21.25 21.5815 21.1183 21.8995 20.8839 22.1339C20.6495 22.3683 20.3315 22.5 20 22.5C19.6685 22.5 19.3505 22.3683 19.1161 22.1339C18.8817 21.8995 18.75 21.5815 18.75 21.25V12.5ZM20 28.75C19.6292 28.75 19.2667 28.64 18.9583 28.434C18.65 28.228 18.4096 27.9351 18.2677 27.5925C18.1258 27.2499 18.0887 26.8729 18.161 26.5092C18.2334 26.1455 18.412 25.8114 18.6742 25.5492C18.9364 25.287 19.2705 25.1084 19.6342 25.036C19.9979 24.9637 20.3749 25.0008 20.7175 25.1427C21.0601 25.2846 21.353 25.525 21.559 25.8333C21.765 26.1416 21.875 26.5042 21.875 26.875C21.875 27.3723 21.6775 27.8492 21.3258 28.2008C20.9742 28.5525 20.4973 28.75 20 28.75Z" fill="#ACACAC"/></svg>
                                                                            <h3>
                                                                                입력하신 정보와 일치하는 회원이 없습니다. <br/>
                                                                                입력하신 이메일을 확인해주세요.
                                                                            </h3>
                                                                        </div>
                                                                        <button onClick={() => this.handleFindMyIdFailBtnClick()} className='hover_grey'>다시 입력하기</button>
                                                                    </>
                                                                ) : <></>
                                                            )
                                                        }
                                                    </div>
                                                    : <div className='find_my_info_content find_my_pw_content'>
                                                        {
                                                            this.state.findMyPwStep === "default"
                                                            ? <>
                                                                <div className='find_my_info_common'>
                                                                    <label htmlFor='search_id'>아이디</label>
                                                                    <input type='text' id='search_id' placeholder='가입 아이디를 입력해주세요.' onChange={e => this.handleFindMyPwIdChk(e)} onKeyDown={e => this.handleFindMyPwDefaultBtnKeydown(e)}/>
                                                                    <label htmlFor='search_email'>이메일</label>
                                                                    <input type='text' id='search_email' placeholder='가입 이메일 주소를 입력해주세요.' onChange={e => this.handleFindMyPwEmailChk(e)} onKeyDown={e => this.handleFindMyPwDefaultBtnKeydown(e)}/>
                                                                </div>
                                                                <button onClick={e => this.handleFindMyPwDefaultBtnClick(e)} className={this.state.findMyPwDefaultBtnChk === true ? "reset_pw_btn hover_mint" : "reset_pw_btn hover_mint disabled"}>비밀번호 재설정하기</button>
                                                            </>
                                                            : ( this.state.findMyPwStep === "after"
                                                                ? ( this.state.findMyPwSuccess === true
                                                                    ? <>
                                                                        <div className='find_my_info_common find_my_pw_success'>
                                                                            <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="19.5827" cy="19.5837" r="15.4167" fill="#333333"/><path d="M19.584 3.33301C16.37 3.33301 13.2283 4.28605 10.556 6.07163C7.88368 7.8572 5.80087 10.3951 4.57095 13.3644C3.34103 16.3337 3.01922 19.601 3.64623 22.7532C4.27324 25.9054 5.8209 28.8009 8.09351 31.0735C10.3661 33.3461 13.2616 34.8938 16.4138 35.5208C19.566 36.1478 22.8333 35.826 25.8026 34.5961C28.7719 33.3661 31.3098 31.2833 33.0954 28.611C34.8809 25.9387 35.834 22.797 35.834 19.583C35.8257 15.2758 34.111 11.1473 31.0654 8.10165C28.0197 5.05597 23.8912 3.34127 19.584 3.33301V3.33301ZM27.3184 16.7393L18.1621 25.4893C17.9254 25.7119 17.6121 25.835 17.2871 25.833C17.1283 25.8353 16.9706 25.8061 16.8231 25.7471C16.6756 25.6881 16.5412 25.6005 16.4277 25.4893L11.8496 21.1143C11.7226 21.0035 11.6194 20.8682 11.546 20.7164C11.4727 20.5647 11.4308 20.3998 11.4228 20.2314C11.4148 20.0631 11.441 19.8949 11.4997 19.7369C11.5584 19.579 11.6484 19.4345 11.7644 19.3122C11.8803 19.19 12.0198 19.0924 12.1744 19.0254C12.329 18.9584 12.4956 18.9234 12.6641 18.9224C12.8326 18.9214 12.9996 18.9545 13.155 19.0197C13.3104 19.0849 13.451 19.1808 13.5684 19.3018L17.2871 22.8486L25.5996 14.9268C25.8427 14.7147 26.1583 14.6048 26.4805 14.6201C26.8027 14.6353 27.1065 14.7745 27.3284 15.0086C27.5504 15.2426 27.6732 15.5534 27.6714 15.8759C27.6695 16.1985 27.543 16.5078 27.3184 16.7393Z" fill="#00E2E6"/></svg>
                                                                            <h3>
                                                                                가입하신 이메일 주소로 메일을 보내 드렸어요. <br/>
                                                                                수신된 메일에서 비밀번호를 재설정해주세요.
                                                                            </h3>
                                                                            <ul style={{
                                                                                textAlign: "left"
                                                                            }}>
                                                                                <li>
                                                                                    <span></span>메일 발송이 지연 될 수 있어, 여유를 갖고 확인해주세요.
                                                                                </li>
                                                                                <li>
                                                                                    <span></span>메일이 오지 않는 경우, 스팸 메일함도 확인해주세요.
                                                                                </li>
                                                                                <li>
                                                                                    <span></span>비밀번호 재설정 링크는 메일 수신 후 30분까지 유효합니다.
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        <button onClick={() => this.handleFindMyPwSuccessBtnClick()} className='hover_mint'>확인</button>
                                                                    </>
                                                                    : <>
                                                                        <div className='find_my_info_common find_my_pw_fail'>
                                                                            <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 3.75C16.7861 3.75 13.6443 4.70305 10.972 6.48862C8.29969 8.27419 6.21689 10.8121 4.98696 13.7814C3.75704 16.7507 3.43524 20.018 4.06225 23.1702C4.68926 26.3224 6.23692 29.2179 8.50952 31.4905C10.7821 33.7631 13.6776 35.3107 16.8298 35.9378C19.982 36.5648 23.2493 36.243 26.2186 35.013C29.1879 33.7831 31.7258 31.7003 33.5114 29.028C35.297 26.3557 36.25 23.2139 36.25 20C36.2459 15.6915 34.5325 11.5607 31.4859 8.51408C28.4394 5.46751 24.3085 3.75414 20 3.75ZM18.75 12.5C18.75 12.1685 18.8817 11.8505 19.1161 11.6161C19.3505 11.3817 19.6685 11.25 20 11.25C20.3315 11.25 20.6495 11.3817 20.8839 11.6161C21.1183 11.8505 21.25 12.1685 21.25 12.5V21.25C21.25 21.5815 21.1183 21.8995 20.8839 22.1339C20.6495 22.3683 20.3315 22.5 20 22.5C19.6685 22.5 19.3505 22.3683 19.1161 22.1339C18.8817 21.8995 18.75 21.5815 18.75 21.25V12.5ZM20 28.75C19.6292 28.75 19.2667 28.64 18.9583 28.434C18.65 28.228 18.4096 27.9351 18.2677 27.5925C18.1258 27.2499 18.0887 26.8729 18.161 26.5092C18.2334 26.1455 18.412 25.8114 18.6742 25.5492C18.9364 25.287 19.2705 25.1084 19.6342 25.036C19.9979 24.9637 20.3749 25.0008 20.7175 25.1427C21.0601 25.2846 21.353 25.525 21.559 25.8333C21.765 26.1416 21.875 26.5042 21.875 26.875C21.875 27.3723 21.6775 27.8492 21.3258 28.2008C20.9742 28.5525 20.4973 28.75 20 28.75Z" fill="#ACACAC"/></svg>
                                                                            <h3>
                                                                                입력하신 정보와 일치하는 회원이 없습니다. <br/>
                                                                                입력하신 아이디와 이메일을 확인해주세요.
                                                                            </h3>
                                                                        </div>
                                                                        <button onClick={() => this.handleFindMyPwFailBtnClick()} className='hover_grey'>다시 입력하기</button>
                                                                    </>
                                                                ) : <></>
                                                            )
                                                        }
                                                    </div>
                                                }
                                        </div>
                                    </div>
                                </div>
                                : <></>
                            }
                        </div>
                    </div>
                    {/* <MarketingArea/> */}
                </div>
            </>
        )
    }
}
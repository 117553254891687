import { React, Component } from 'react';
import '../../resources/fb/css/reset.css';
import '../../resources/fb/css/master.css';
import '../../resources/fb/css/bootstrap.min.css';
import '../../resources/fb/css/master.css';
import '../../resources/fb/css/trend_all.css';
import '../../resources/fb/css/responsive.css';
import TrendChart from './component/TrendChart';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import axios from 'axios';

class LongBest extends Component {

    constructor(props) {
        super(props);

        var today = new Date();
        var date = new Date(Number(today.getFullYear()), Number(today.getMonth()), Number(today.getDate()))
        this.state = {
            date: date,
            category_code: '',
            dur: 1,
            last_date: new Date(),
            category_list: [],
            count_info: [],
            goods_list: [],
            rank_item: {'item_no': '-1', 'goods_name': ''},
            rank_draw: false,
        };
    }

    componentDidMount() {
        axios.get('/rest/fb/getCategoryList').then(
            response => {
                this.setState({
                    category_list: response.data.list
                });
            }
        );

        axios.get('/rest/fb/getLastDate').then(
            response => {
                var yyyyMMdd = String(response.data.lastDate);
                var yyyy = yyyyMMdd.substring(0,4);
                var mm = yyyyMMdd.substring(4,6);
                var dd = yyyyMMdd.substring(6,8);
                var date = new Date(Number(yyyy), Number(mm)-1, Number(dd))
                this.setState({
                    date: date,
                    last_date: date,
                });
                this.get_list();
            }
        )
    }

    get_category_list() {
        return this.state.category_list.map((item, i) => 
            <option value={item.cd_code}>{item.cd_desc}</option>
        );
    }

    get_date_message() {
        var date = this.state.date;
        var week = ['일','월','화','수','목','금','토'];
        var year = date.getFullYear();
        var month = (1 + date.getMonth());
        month = month >= 10 ? month : '0' + month;
        var day = date.getDate();
        day = day >= 10 ? day : '0' + day;

        var date_message =  year + '년 ' + month + '월 ' + day + '일';
        var day_message = week[date.getDay()] + '요일'; 
        return <>
                    <span style={{marginBottom: 0, alignSelf: "center"}}>
                        {/* {date_message} */}
                        <DatePicker name="from_date" id="from_date" className="fb_from_date_no_border font_family_normal"
                            selected={this.state.date}
                            onSelect={this.onSelectFromDate}
                            placeholderText="일자"
                            dateFormat="yyyy년 MM월 dd일"
                            autoComplete="off"
                            showYearDropdown
                            showMonthDropdown
                            todayButton="오늘로 이동"
                            locale={ko}
                            minDate={new Date("2021","04","02")} // 2021-05-02
                            maxDate={this.state.last_date}
                        />
                    </span>
                <span className="fb_from_date_no_border" style={{margin:"auto 0"}}>{day_message}</span></>;
    }

    prev_day = () => {
        if (this.state.date <= new Date("2021","04","02")) return false;
        
        var date = this.state.date
        date = new Date(date.setDate(date.getDate()-1))
        this.setState({
            date: new Date(date),
        }, () => {
            this.get_list();
        });
    }

    next_day = () => {
        var date = this.state.date
        if( (date <= this.state.last_date) === false ) return;
        date = new Date(date.setDate(date.getDate()+1))

        this.setState({
            date: new Date(date),
        }, () => {
            this.get_list();
        });
    }

    onClickDur = (e) => {
        this.setState({
            dur: e.target.value,
        }, () => this.get_list());
    }

    onClickCategory = (e) => {
        this.setState({
            category_code: e.target.value,
        }, () => this.get_list());
    }

    get_list = () => {
        if( this.state.shop_seq === '') return;

        var date = this.state.date;
        var year = date.getFullYear();
        var month = (1 + date.getMonth());
        month = month >= 10 ? month : '0' + month;
        var day = date.getDate();
        day = day >= 10 ? day : '0' + day;
        var date_message = String(year) + String(month) + String(day);

        var url = '/rest/fb/getLongBestGoodsList?date=' + date_message + '&dur=' + this.state.dur + '&category_code=' + this.state.category_code
		axios.get(url).then(response => {
            this.setState({
                count_info: response.data.count,
                goods_list: response.data.list,
            });
        });
    }

    make_count = () => {
        const mall_count = this.state.count_info === [] ? '0' : this.state.count_info.mall_count
        const goods_count = this.state.count_info === [] ? '0' : this.state.count_info.goods_count
        return <p className="font_family_normal" style={{marginBottom: 0}}><img src="/img/icon/Package.png" alt="상품" />총 <span>{mall_count}개</span> 쇼핑몰 <em class="divider">/</em> <span>{goods_count}개</span> 상품이 있습니다.</p>  
    }

    make_number = (price) => {
        try {
            return Number(price).toLocaleString()
        } catch (e) {
            return "-1"
        }
    }

    make_price = (item) => {
        if( item.item_price === item.item_sale_price ) return;
        return <strike>{this.make_number(item.item_price)}</strike>
    }

    make_sale_percent = (item) => {
        if( item.item_price === item.item_sale_price ) return;
        return <span>{Math.round((1-(parseInt(item.item_sale_price)/parseInt(item.item_price)))*100)}%</span>
    }

    make_display = (item) => {
        return  <div class="text-area">
                    <a className="text-area" href={item.goods_url} target="_blank" style={{color:"#333"}}>
                        <h3>{item.shop_name}<br/>{item.goods_name}</h3>
                        <div class="price">
                            {this.make_price(item)}&nbsp;
                            {this.make_number(item.item_sale_price)}&nbsp;
                            {this.make_sale_percent(item)}
                        </div>
                    </a>
                </div>
    }


    make_icon = (item) => {
        if( item.move_rank === 0 ) return 'gray'
        if( item.move_rank > 0 ) return 'green'
        if( item.move_rank < 0 ) return 'pink'
    }


    make_image = (item) => {
        if( item.move_rank === 0 ) return 'Ranking_NoChange.png'
        if( item.move_rank > 0 ) return 'Ranking_Increase.png'
        if( item.move_rank < 0 ) return 'Ranking_Degradation.png'
    }


    make_goods_list = () => {
        if( this.state.goods_list.length === 0 ) return '조건에 해당하는 결과가 없습니다.'
        return this.state.goods_list.map((item, i) => 
            <li class={this.make_icon(item)}>
                <a className="img-area" href={item.goods_url} target="_blank" style={{color:"#333"}}>
                    <div class="img-area">
                        <img width="100%" height="350px" src={item.img_url} alt={item.goods_name} />
                        <div class="icon_wrap" onClick={(e) => this.show_rank_graph(e, item)}>
                            <div class="ico img">
                                <img src={`/img/icon/${this.make_image(item)}`} alt="" />
                            </div>
                        </div>
                    </div>
                </a>
                {this.make_display(item)}
            </li>
        );
    }

    show_rank_graph = (e, item) => {
        e.preventDefault ();
        this.setState({
            ...this.state,
            rank_item: item,
            rank_draw: true,
        })
    }

    graph_close = () => {
        this.setState({
            ...this.state,
            rank_draw: false,
        })
    }

    make_graph_date = () => {
        var date = this.state.date;
        var year = date.getFullYear();
        var month = (1 + date.getMonth());
        month = month >= 10 ? month : '0' + month;
        var day = date.getDate();
        day = day >= 10 ? day : '0' + day;

        return year + '-' + month + '-' + day;
    }

    onSelectFromDate = (date) => {
        if (date != null && date != "") {
            this.setState({
                date: date,
            }, () => {
                this.get_list();
            });
        }
    }

    render() {
        const goods_list = this.make_goods_list()
        const visibility_graph = this.state.rank_draw ? 'visible' : 'hidden';
        return (
            <section>
            <div class="container-fluid bg-gr">
                <div class="row">
                    {/* <div class="col-md-2 menu_area"></div> */}
                    <div class="col-md-10 contents_area rank products_wrap">
                        <div class="content_area">
                            <div class="content_title">
                                <h1 className="font_family_normal" style={{margin: "auto 0"}}>장기베스트 상품</h1>
                                <div class="help">
                                    <p className="font_family_normal" style={{margin: "auto 0"}}><i>?</i>장기베스트 상품이란?<span>장기 베스트 상품은 기준일로부터 과거 15일 또는 30일 동안 지속적으로 베스트 상품에 노출된 상품입니다.</span></p>
                                </div>
                            </div>
                            <div class="content_header">
                                <div class="date_wrap">
                                    <div class="date_input">
                                        <button type="button" onClick={this.prev_day} className="date_prev"></button>
                                        <div className="date">
                                            {this.get_date_message()}
                                        </div>
                                        <button type="button" onClick={this.next_day} className="date_next"></button>
                                    </div>
                                    <div class="date_selecter">
                                        <select onChange={this.onClickDur}>
                                            <option value="15">15일</option>
                                            <option value="30">30일</option>
                                        </select>
                                    </div>
                                </div>
    
                                <div class="category_wrap">
                                    <select onChange={this.onClickCategory}>
                                        <option value="">전체 카테고리</option>
                                        {this.get_category_list()}
                                    </select>
                                </div>
                            </div>
                            <div class="result_wrap">
                                { this.make_count() }
                            </div>
                            <div className="content_item">
                                <div className="content">
                                    <ul className="products">
                                        { goods_list }
                                    </ul>
                                    <div class="conts" style={{
                                            visibility: visibility_graph,
                                            position: 'fixed',
                                            left: 40 + '%',
                                            top: 30 + '%',
                                            width: 40 + '%',
                                            backgroundColor: 'white',
                                            borderRadius: 15 + 'px',

                                    }}>
                                        <div id="container" className="chart_title">
                                            <p>랭킹</p>
                                            <button type="button" onClick={this.graph_close}/>
                                        </div>
                                    <TrendChart category_type="BEST" date={this.make_graph_date(this.state.date)} shop_seq={this.state.rank_item.shop_seq} item_no={this.state.rank_item.item_no} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        );
    }
}

export default LongBest;
import React from 'react';
import $, { param } from 'jquery';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import http from '../modules/api';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import trend_calendar from '../../resources/img/trend_calendar.png'

import ReactGA from 'react-ga';
ReactGA.initialize('UA-78553461-8');


// defaults.global.defaultFontSize = '12'

var pieOptions = {
    pieceLabel: {
   fontSize: 12,
 }
};

function getWeekDays(weekStart) {
    const days = [weekStart];
    for (let i = 1; i < 7; i += 1) {
      days.push(
        moment(weekStart)
          .add(i, 'days')
          .toDate()
      );
    }
    return days;
  }
  
  function getWeekRange(date) {
    return {
      from: moment(date)
        .startOf('isoWeek')
        .toDate(),
      to: moment(date)
        .endOf('isoWeek')
        .toDate(),
    };
  }
  

var userName = sessionStorage.getItem("userName");

var is_first = true;
String.prototype.replaceAll = function(org, dest) {
    return this.split(org).join(dest);
}

var category = decodeURIComponent(String(getUrlParams()["category"]));
// alert(category)
if(getUrlParams()["category"] == null) {
    category = "우먼"
}
// console.log(category)
var paramDate = new Date(new Date(getUrlParams()["date"]).toString().replace("09:", "00:"));

var date = new Date(paramDate);
var db_date;
var nowDate = "";
var nowDateForQuery = "";

var start_date = "";
var end_date = "";
if(getUrlParams()["start_date"] != null) {
    start_date = getUrlParams()["start_date"].replaceAll("-",".");
}
if(getUrlParams()["end_date"] != null) {
    end_date = getUrlParams()["end_date"].replaceAll("-",".");
}

// console.log(getUrlParams()["date"])
// if (getUrlParams()["date"] == null) {

var graphType = "";

function getUrlParams() {
    var params = {};
    window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(str, key, value) { params[key] = value; });
    return params;
}

function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

/**
 *  yyyyMMdd 포맷으로 반환
 */
function getFormatDate(date){
    var year = date.getFullYear();              //yyyy
    var month = (1 + date.getMonth());          //M
    month = month >= 10 ? month : '0' + month;  //month 두자리로 저장
    var day = date.getDate();                   //d
    day = day >= 10 ? day : '0' + day;          //day 두자리로 저장
    return  year + '.' + month + '.' + day;
}
function getFormatDate2(date){
    var year = date.getFullYear();              //yyyy
    var month = (1 + date.getMonth());          //M
    month = month >= 10 ? month : '0' + month;  //month 두자리로 저장
    var day = date.getDate();                   //d
    day = day >= 10 ? day : '0' + day;          //day 두자리로 저장
    return  month + '.' + day;
}
function getFormatDate3(date){
    var year = date.getFullYear();              //yyyy
    var month = (1 + date.getMonth());          //M
    month = month >= 10 ? month : '0' + month;  //month 두자리로 저장
    var day = date.getDate();                   //d
    day = day >= 10 ? day : '0' + day;          //day 두자리로 저장
    return  year + '-' + month + '-' + day;
}
function getFormatDate4(date){
    var year = date.getFullYear();              //yyyy
    var month = (1 + date.getMonth());          //M
    month = month >= 10 ? month : '0' + month;  //month 두자리로 저장
    var day = date.getDate();                   //d
    day = day >= 10 ? day : '0' + day;          //day 두자리로 저장
    return  String(year).substring(2,4) + '.' + month + '.' + day; // yy.mm.dd
}
function getFormatDate5(date){
    var year = date.getFullYear();              //yyyy
    var month = (1 + date.getMonth());          //M
    // month = month >= 10 ? month : '0' + month;  //month 두자리로 저장
    var day = date.getDate();                   //d
    // day = day >= 10 ? day : '0' + day;          //day 두자리로 저장
    var week = new Array('일', '월', '화', '수', '목', '금', '토');
    var dayName = week[date.getDay()];          // 요일
    return  month + '월' + day + '일' + '('+dayName+')'; // mm월dd일(요일)
}

export class NaverTrendList extends React.Component {
    constructor(props){
        super(props);

        // if (typeof Object.assign != 'function') {
        //     // Must be writable: true, enumerable: false, configurable: true
        //     Object.defineProperty(Object, "assign", {
        //       value: function assign(target, varArgs) { // .length of function is 2
        //         'use strict';
        //         if (target == null) { // TypeError if undefined or null
        //           throw new TypeError('Cannot convert undefined or null to object');
        //         }
          
        //         var to = Object(target);
          
        //         for (var index = 1; index < arguments.length; index++) {
        //           var nextSource = arguments[index];
          
        //           if (nextSource != null) { // Skip over if undefined or null
        //             for (var nextKey in nextSource) {
        //               // Avoid bugs when hasOwnProperty is shadowed
        //               if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
        //                 to[nextKey] = nextSource[nextKey];
        //               }
        //             }
        //           }
        //         }
        //         return to;
        //       },
        //       writable: true,
        //       configurable: true
        //     });
        //   }
    }

    state = {
        before_date_limit : "2020-09-22",
        goodsList: [],
        categoryList : ['우먼','잇걸'],
        powerKeyword : {keywordList:[]},
        hashtagList: [],
        settings : {
            // variableWidth:true,
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            autoplay : true,
            responsive: [ // 반응형 웹 구현 옵션
                {  
                    breakpoint: 960, //화면 사이즈 960px
                    settings: {
                        //위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
                        slidesToShow:4 
                    } 
                },
                { 
                    breakpoint: 639, //화면 사이즈 639px
                    settings: {	
                        //위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
                        slidesToShow:4 
                    } 
                }
            ]
        },

        settingsCategory : {
            // variableWidth:true,
            dots: false,
            infinite: false,
            // speed: 500,
            slidesToShow: 8,
            slidesToScroll: 3,
            autoplay : false,
            responsive: [ // 반응형 웹 구현 옵션
                {  
                    breakpoint: 960, //화면 사이즈 960px
                    settings: {
                        //위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
                        slidesToShow:7 
                    } 
                },
                { 
                    breakpoint: 639, //화면 사이즈 639px
                    settings: {	
                        //위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
                        slidesToShow:6,
                        slidesToScroll: 6
                    } 
                }
            ]
        },

        barData : {
            labels: [],
            datasets: [
                {},
            ]
        },
        options: {
            responsive: true, 
            maintainAspectRatio: false,
            // fontSize: 100,
        },
        
        FeedCntData : {
            labels:[""],
            datasets:[]
        },

        windowSize : window.innerWidth,

        nowDate : "",
        hoverRange : "", 
        selectedDays : [],
    };

    makeImageURL = (image_key) => {
        return 'https://s3.ap-northeast-2.amazonaws.com/naver.img/' + image_key;
    }

    handleResize = e => {
        const windowSize = window.innerWidth;
        this.setState({
            windowSize: windowSize
        });

        console.log(this.state.windowSize);
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    componentDidMount(){
        window.addEventListener('resize', this.handleResize)
        $(".weeklyDate").hide();
        $(".nowDate2").show();
        $(".nowDate2").text(nowDate);
        $("#weeklyCalendar").hide();
        $("#dailyCalendar").show();
        /* 일간 radio 버튼 클릭 */
        $(".radio_Weekly_Daily:checked").prop("checked", false); 
        $("#daily").prop("checked", true); 
        $("#weeklyCalendar").hide();
        $("#dailyCalendar").show();
        if (nowDate != "") {
            this.setState({
                selectedDays: [
                    new Date(nowDate.replaceAll(".","-")),
                ],
                dailyDateTmp : [
                    new Date(nowDate.replaceAll(".","-")),
                ]
            })
        }

        $("#trendCalendar").hide();
        $("#powerKeyword_none").hide();
        $(window).resize( function () { });
 
        /* 뒤로가기 이벤트 */
        onpopstate = () => {
            category = decodeURIComponent(getUrlParams()["category"]);
            if(getUrlParams()["category"] == null) {
                category = ""
            }
            $("#mainKeyword").text(decodeURIComponent(category));
            if (getUrlParams()["start_date"] != null) {
                start_date = getUrlParams()["start_date"].replaceAll("-",".");
            }
            if (getUrlParams()["end_date"] != null) {
                end_date = getUrlParams()["end_date"].replaceAll("-",".");
            }
            if (start_date != "" && end_date != "") {
                $(".nowDate2").hide();
                $(".weeklyDate").show();
                $(".weeklyDate").text(start_date+" ~ "+end_date);
            } else {
                $(".weeklyDate").hide();
                $(".nowDate2").show();
                $(".nowDate2").text(nowDate);
            }
            this.loadFullData();
        }

        this.getCategoryList();
        this.loadFullData();
      }

    loadFullData() {
        paramDate = new Date(new Date(getUrlParams()["date"]).toString().replace("09:", "00:"));
        date = new Date(paramDate);
        if (getUrlParams()["date"] == null) {
            nowDateForQuery = getFormatDate3(date);
        } else {
            date = new Date()
            nowDateForQuery = getFormatDate(date);
        }

        // window.location.href=(`/shop/trend/current?date=`+beforeDate2.replaceAll(".", "-")+"&category="+ encodeURI(category));
        http.get("/rest/common/LastAnlysDate?category="+encodeURI(category)+"&date="+nowDateForQuery)
        .then(res => {
            db_date = new Date(res.data["last_anlys_date"]);
            if (getUrlParams()["date"] == null) {
                date = new Date(new Date(res.data["last_anlys_date"]).toString().replace("09:", "00:"));
                nowDate = getFormatDate(date);
                if (getUrlParams()["end_date"] != null) {
                    nowDateForQuery = getFormatDate3(new Date(new Date("20"+getUrlParams()["end_date"]).toString().replace("09:", "00:")));
                } else {
                    date = new Date()
                    nowDate = getFormatDate3(date);
                    nowDateForQuery = getFormatDate3(date);
                }
            } else {
                nowDate = getFormatDate(date);
                nowDateForQuery = getFormatDate3(date);
                $(".nowDate2").text(nowDate);
            }

            if( 'now_date_format' in res.data ) {
                $(".nowDate2").text(res.data["now_date_format"]);
                this.setState({nowDate: res.data["now_date_format"]});
            } else {
                $(".nowDate2").text(nowDateForQuery);
                this.setState({nowDate: nowDateForQuery});
            }

            console.log("##### 1 - nowDateForQuery: "+nowDateForQuery);
            this.getKeywordData(category, nowDateForQuery);
        })
        .catch(error => {
                // console.log (error);
        });
    }

    handleDayChange = date => {
        if (new Date(new Date().setDate(new Date().getDate() - new Date().getDay())) < new Date(date.toString().replaceAll("12:", "00:")) || new Date(date.toString().replaceAll("12:", "00:")) < new Date("2018-01-01")) {
            alert("해당 날짜를 선택 할 수 없습니다.");
            this.setState({
                selectedDays: [],
            });
            ReactGA.event({
                category: '트렌드리포트 2차버전 '+decodeURIComponent(category)+' 페이지',
                action:  "달력 주간 비활성화 된 날짜 선택 ("+getFormatDate(date)+")",
                label: sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")"
            });
            return false;
        }

        this.setState({
          selectedDays: getWeekDays(getWeekRange(date).from),
          weeklyDateTmp : getWeekDays(getWeekRange(date).from)
        });
        ReactGA.event({
            category: '트렌드리포트 2차버전 '+decodeURIComponent(category)+' 페이지',
            action:  "주간 날짜 선택 ("+getFormatDate(date)+")",
            label: sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")"
        });
      };

      handleDayChangeForDaily = date => {
        if (new Date() < new Date(date.toString().replaceAll("12:", "00:")) || new Date(date.toString().replaceAll("12:", "00:")) < new Date("2018-01-01")) {
            alert("해당 날짜를 선택 할 수 없습니다.");
            this.setState({
                selectedDays: [],
            });
            ReactGA.event({
                category: '트렌드리포트 2차버전 '+decodeURIComponent(category)+' 페이지',
                action:  "달력 일간 비활성화 된 날짜 선택 ("+getFormatDate(date)+")",
                label: sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")"
            });
            return false;
        }

        this.setState({
          selectedDays: [date],
          dailyDateTmp : [date]
        });

        ReactGA.event({
            category: '트렌드리포트 2차버전 '+decodeURIComponent(category)+' 페이지',
            action:  "일간 날짜 선택 ("+getFormatDate(date)+")",
            label: sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")"
        });
      };
    
      handleDayEnter = date => {
        this.setState({
          hoverRange: getWeekRange(date),
        });
      };
    
      handleDayLeave = () => {
        this.setState({
          hoverRange: undefined,
        });
      };
    
      handleWeekClick = (weekNumber, days, e) => {
        this.setState({
          selectedDays: days,
        });
      };

    onClickCategory = e => {
        var categoryTmp = category;
        category = e.currentTarget.textContent;
        $("#mainKeyword").text(decodeURIComponent(category))
        if (start_date != "" && end_date != "") {
            window.history.pushState(null, null, window.location.href.split("?")[0]+"?category="+ encodeURI(category)+"&start_date="+start_date.replaceAll(".", "-")+"&end_date="+end_date.replaceAll(".", "-"));
        } else {
            window.history.pushState(null, null, window.location.href.split("?")[0]+`?date=`+nowDate.replaceAll(".", "-")+"&category="+ encodeURI(category));
        }
        this.loadFullData();

        ReactGA.event({
            category: '트렌드리포트 2차버전 '+decodeURIComponent(categoryTmp)+' 페이지',
            action: "카테고리 클릭 (" + category + ")",
            label: sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")"
        });
    }

    onClickKeyword = (e,keyword) => {
        window.open(keyword.link, 'naver');

        ReactGA.event({
            category: '트렌드리포트 네이버 메인 '+decodeURIComponent(category)+' 페이지',
            action:  "키워드 클릭 ("+keyword.name +")",
            label: sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")"
        });
    }
    
    getGoodsList(paramCategory, nowDateForQuery) {
		http.get("/rest/common/trend/goods?category="+ encodeURI(paramCategory)+"&date="+nowDateForQuery)
        .then(res => {
            if (res.data.session_error != null && res.data.session_error == "anonymousUser") {
                // // alert("가입 후 이용 해주세요.")
                return false;
            }

            this.setState( {goodsList : res.data.list} );
            // console.log(this.state.goodsList)
            new Promise( ( resolve, reject ) => {setTimeout( resolve, 400 ); })
            .then( () => {
                $(".products-slick-box .slick-slider").show();
            })
            .catch(error => {
                console.log (error);
                });
            })
		.catch(error => {
			// console.log (error);
		 });
    }

    getKeywordData(paramCategory, nowDateForQuery) {

        var date_param = nowDateForQuery;
        if(getUrlParams()["date"] != null && getUrlParams()["date"] != "") {
            date_param = nowDateForQuery;
        } else {
            date_param = '20210313'
        }
        if(is_first) {
            date_param = nowDateForQuery;
        }

        var convCategory = "";
        if( paramCategory === '우먼' ) convCategory = 'WOMEN';
        if( paramCategory === '잇걸' ) convCategory = 'ITGIRL';

        var uuu = "/rest/common/naver/trend/list?date=" + date_param.replaceAll('-','') + "&category=" + convCategory
        // http.get("/rest/common/naver/trend/list?date=" + date_param.replaceAll('-','') + "&category=" + convCategory)
        console.log('uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu')
        console.log(uuu);
        http.get(uuu)
        .then(res => {
            if(res.data != null) {
                var keywordList = [];
                for (var idx in res.data.keywordList) {
                    keywordList.push(res.data.keywordList[idx])
                    if(idx == (Math.floor(parseInt(res.data.keywordList.length)/10) * 10)-1)
                        break;
                }

                this.setState( {powerKeyword : {keywordList: keywordList}} );
                if(keywordList.length == 0) {
                    $("#powerKeyword_none").show();
                } 
            }
		})
		.catch(error => {
			console.log (error);
		 });
    }

    getCategoryList() {
        this.setState( {categoryList : [
            {
            category4: "우먼"
            },
            {
            category4: "잇걸"
            },
            ]} );
    }


    getFeedCntData(keyword) {
		http.get("/rest/common/state/feedCnt?keyword="+keyword+"&date="+nowDateForQuery)
        .then(res => {
            if (res.data.session_error != null && res.data.session_error == "anonymousUser") {
                // alert("가입 후 이용 해주세요.")
                return false;
            }

            this.setState({FeedCntData : {datasets: res.data.datasets, labels: res.data.labels}});
        // console.log(this.state.categoryList)
		})
		.catch(error => {
			// console.log (error);
		 });
    }

    onClickProduct = (item_name) => {
        ReactGA.event({
            category: '트렌드리포트 2차버전 '+decodeURIComponent(category)+' 페이지',
            action: "상품클릭 (" + item_name + ")",
            label: sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")"
        });
    };
    
    onClickNowDate = e => {
        if ($("#trendCalendar").is(':visible') == false) {
            $("#trendCalendar").show();
        } else {
            $("#trendCalendar").hide();
        }
    }

    onClickCalendarCancel = e => {
        $("#trendCalendar").hide();
        ReactGA.event({
            category: '트렌드리포트 2차버전 '+decodeURIComponent(category)+' 페이지',
            action:  "달력 취소 버튼 클릭",
            label: sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")"
        });       
    }

    onClickCalendarApply = e => {
        if (this.state.selectedDays == null || (this.state.selectedDays != null && this.state.selectedDays.length == 0)) {
            alert("날짜를 선택해 주세요.");
            return false;
        }

        $("#trendCalendar").hide();
        if (this.state.selectedDays != null && this.state.selectedDays.length > 1) {
            nowDate = getFormatDate(this.state.selectedDays[this.state.selectedDays.length - 1]);
            nowDateForQuery = getFormatDate3(this.state.selectedDays[this.state.selectedDays.length - 1]);

            start_date = getFormatDate4(this.state.selectedDays[0])
            end_date = getFormatDate4(this.state.selectedDays[this.state.selectedDays.length - 1])

            $(".nowDate2").hide();
            $(".weeklyDate").show();
            $(".weeklyDate").text(start_date+" ~ "+end_date);
            window.history.pushState(null, null, window.location.href.split("?")[0]+"?category="+ encodeURI(category)+"&start_date="+start_date.replaceAll(".", "-")+"&end_date="+end_date.replaceAll(".", "-"));
            ReactGA.event({
                category: '트렌드리포트 2차버전 '+decodeURIComponent(category)+' 페이지',
                action:  "달력 날짜 적용 ("+start_date+" ~ "+end_date+")",
                label: sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")"
            });
        } else {
            nowDate = getFormatDate(this.state.selectedDays[0]);
            nowDateForQuery = getFormatDate3(this.state.selectedDays[0]);
            $(".weeklyDate").hide();
            $(".nowDate2").show();
            $(".nowDate2").text(getFormatDate5(new Date(nowDate.replaceAll(".", "-"))));
            window.history.pushState(null, null, window.location.href.split("?")[0]+`?date=`+nowDate.replaceAll(".", "-")+"&category="+ encodeURI(category));
            ReactGA.event({
                category: '트렌드리포트 2차버전 '+decodeURIComponent(category)+' 페이지',
                action:  "달력 날짜 적용 ("+nowDate+")",
                label: sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")"
            });
        }

        this.getKeywordData(category, nowDateForQuery);
    }
    
    onClickWeekly = e => {
        if (this.state.weeklyDateTmp != null) {
            this.setState({selectedDays: this.state.weeklyDateTmp});
        } else {
            if (this.state.selectedDays != null) {
                if (new Date(new Date().setDate(new Date().getDate() - new Date().getDay())) < new Date(this.state.selectedDays[0].toString().replaceAll("12:", "00:")) || new Date(this.state.selectedDays[0].toString().replaceAll("12:", "00:")) < new Date("2018-01-01")) {
                    this.setState({
                        selectedDays: getWeekDays(getWeekRange(new Date(new Date().setDate(new Date().getDate() - new Date().getDay()))).from),
                    });
                } else {
                    this.setState({selectedDays: getWeekDays(getWeekRange(this.state.selectedDays[0]).from)});
                }
            } 
        }

        $(".radio_Weekly_Daily:checked").prop("checked", false); 
        $("#"+e.target.value).prop("checked", true); 
        $("#dailyCalendar").hide();
        $("#weeklyCalendar").show();
        // $(".DayPicker-TodayButton").click();

        ReactGA.event({
            category: '트렌드리포트 2차버전 '+decodeURIComponent(category)+' 페이지',
            action:  "달력 주간 radioButton 클릭",
            label: sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")"
        });
    }
    
    onClickDaily = e => {
        if (this.state.dailyDateTmp != null) {
            this.setState({selectedDays: this.state.dailyDateTmp});
        } else {
            if (this.state.selectedDays != null) {
                // alert(this.state.selectedDays[6]);
                this.setState({selectedDays: [this.state.selectedDays[6]]});
            }
        }

        $(".radio_Weekly_Daily:checked").prop("checked", false); 
        $("#"+e.target.value).prop("checked", true); 
        $("#weeklyCalendar").hide();
        $("#dailyCalendar").show();
        // $(".DayPicker-TodayButton").click();

        ReactGA.event({
            category: '트렌드리포트 2차버전 '+decodeURIComponent(category)+' 페이지',
            action:  "달력 일간 radioButton 클릭",
            label: sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")"
        });
    }

    render() {
        const { hoverRange, selectedDays } = this.state;

        const daysAreSelected = selectedDays.length > 0;
    
        const modifiers = {
          hoverRange,
          selectedRange: daysAreSelected && {
            from: selectedDays[0],
            to: selectedDays[6],
          },
          hoverRangeStart: hoverRange && hoverRange.from,
          hoverRangeEnd: hoverRange && hoverRange.to,
          selectedRangeStart: daysAreSelected && selectedDays[0],
          selectedRangeEnd: daysAreSelected && selectedDays[6],
        };

        // alert(decodeURIComponent(category));

        return (

            <>
            <div className="trendCategoryArea2" style={{position: "relative", height: "30px"}}>
                {
                    this.state.windowSize > 800
                    ?   <div className="mobile-top-60" style={{display:"table",right:"0", position:"absolute", marginRight:"2.5%", fontSize:"15px", marginTop:"-28px"}}>
                                {this.state.categoryList.map((ctg, idx)=> (
                                    <div className="slick-category margin-l-10" key={idx} onClick={this.onClickCategory} style={{display:"inline-block"}}>
                                    {
                                        category == ctg.category4
                                        ? <div style={{fontWeight:"bolder"}}>{ctg.category4}</div>
                                            
                                        : <div style={{color:"grey"}}>{ctg.category4}</div>
                                    }  
                                    </div>  	
                                ))}
                        </div>
                    :   <div className="trendCategoryArea" style={{paddingTop:"60px"}}>
                            <Slider {...this.state.settingsCategory}
                                    arrows={false}>
                                {this.state.categoryList.map((ctg, idx)=> (
                                    <div className="slick-category" key={idx} onClick={this.onClickCategory}>
                                        {
                                            category == ctg.category4
                                            ? <div style={{fontWeight:"bolder"}}>{ctg.category4}</div>
                                                
                                            : <div style={{color:"grey"}}>{ctg.category4}</div>
                                        }  
                                    </div>  
                                ))}
                            </Slider>
                        </div>
                }
                
            </div>
            <div className="trendCategoryArea2_line" style={{width:"100%", borderTop:"1px solid #d9d9da", margin: "-12px 0 25px 0"}}></div>

                <div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
                    <div style={{padding:"0 2% 0 2%"}}>
                        <div className="flexBox" style={{position:"relative", marginBottom:"25px", justifyContent: "space-between"}}>
                            <div style={{paddingTop:"0px", fontSize:"27px", fontWeight:"bolder"}}>{decodeURIComponent(category)}</div>
                            <div style={{display:"flex"}}>
                                {/* <div className="fa fa-calendar" style={{width: "15px",height: "15px", fontSize:"15px", margin: "auto 0 auto 0", float: "left"}}></div> */}
                                <img src={trend_calendar} style={{width: "18px",height: "17px", margin: "auto 0 auto 0", float: "left"}}></img>
                                <div className="nowDate2 margin-vertical-auto cursor-pointer" onClick={this.onClickNowDate}
                                    style={{margin: "auto 0 auto 10px",  fontSize:"15px", color:"#000000", lineHeight:"16px" , borderBottom: "1px solid #000"}}>
                                </div>
                                <div className="weeklyDate margin-vertical-auto cursor-pointer" onClick={this.onClickNowDate}
                                    style={{margin: "auto 0 auto 10px", fontSize:"15px", color:"#000000", lineHeight:"16px" , borderBottom: "1px solid #000"}}>
                                </div>
                            </div>
                        </div>
                        <div className="flexBox trendWeeklyCalendarArea" style={{position:"relative", marginBottom:"25px", justifyContent: "space-between"}}>
                            <div id="trendCalendar" style={{border:"1px solid #70707033", background:"white", position:"absolute", top:"-18px", right:"0px", zIndex:"999", boxShadow: "1px 5px 10px 0px lightgrey"}}>
                                <div id="dailyCalendar">
                                    <DayPicker
                                        month={selectedDays[selectedDays.length-1]} 
                                        selectedDays={selectedDays}
                                        // showWeekNumbers
                                        showOutsideDays
                                        modifiers={modifiers}
                                        onDayClick={this.handleDayChangeForDaily}
                                        onDayMouseEnter={this.handleDayEnter}
                                        onDayMouseLeave={this.handleDayLeave}
                                        onWeekClick={this.handleWeekClick}
                                        disabledDays={[
                                            {
                                                after: new Date(),
                                                before: new Date("2018-01-01")
                                            }
                                        ]}
                                        todayButton="오늘로 이동"
                                    />
                                </div>
                                <div className="flexBox cursor-pointer" style={{justifyContent: "center", marginBottom:"10px"}}>
                                    <div className="greenLineButton" style={{width: "40px", height:"30px", lineHeight:"30px", textAlign:"center"}}
                                    onClick={this.onClickCalendarCancel}>
                                        취소
                                    </div>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div className="greenButton cursor-pointer" style={{width: "40px", height:"30px", lineHeight:"30px", textAlign:"center"}}
                                    onClick={this.onClickCalendarApply}>
                                        적용
                                    </div>
                                </div>
                            </div>    
                        </div>
                        <div className="body over-hidden trendContainer flexBox">
                        <div className="width-100">
                            <div className="over-auto" style={{width:"100%", marginTop:"20px", marginBottom: "20px"}}>
                                <div className="text-center">
                                    <div className="keywordArea" style={{textAlign:"left", padding:"0 30px 0 0"}}>
                                        <label style={{fontSize:"20px", marginBottom:"55px"}}>
                                            <b>트렌드</b> {decodeURIComponent(category)}랭킹
                                        </label>
                                        {this.state.powerKeyword.keywordList.map((keyword, idx)=> (
                                            <div key={idx} className="flexBox cursor-pointer" style={{marginBottom:"16px"}} onClick={e => this.onClickKeyword(e, keyword)}>
                                                <div className="flexBox">
                                                    <div className="margin-vertical-auto" style={{width:"33px"}}><b>{idx+1}</b></div>
                                                    <div className="hashtagImage">
                                                        <a>
                                                            <img className="width-100" src={this.makeImageURL(keyword.image_key)}/> 
                                                        </a>                          
                                                    </div>
                                                </div>
                                                <div className="hashtagRankGraphArea">
                                                    <div key={idx} style={{marginBottom:"6px"}}>
                                                        <b>{keyword.name}</b>
                                                    </div>	
                                                    <div className="flexBox" style={{borderRadius: "6px"}}>
                                                        <div className="keyword trendLineGraghHeight" style={{flex:"1", backgroundColor:"#2bac81", height:"16px", borderTopLeftRadius:"6px", borderBottomLeftRadius:"6px", borderTopRightRadius:"6px", borderBottomRightRadius:"6px"}}></div>
                                                        <div className="trendLineGraghHeight" style={{backgroundColor:"#D8D8D8", marginLeft:"-6px", height:"16px", width: (idx/this.state.powerKeyword.keywordList.length)*100 + "%", borderTopRightRadius:"6px", borderBottomRightRadius:"6px"}}></div>
                                                    </div>
                                                    <div className="trendLineGraghHover" style={{fontSize:"14px"}}>
                                                            {((this.state.powerKeyword.keywordList.length-idx)/this.state.powerKeyword.keywordList.length)*100}%
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div id="powerKeyword_none" style={{textAlign:"left", color:"#c1c1c1"}}>
                                            키워드가 없습니다.
                                        </div>   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}
import React, { useCallback, useState } from 'react';
import $ from 'jquery';

import http from '../modules/api';
import * as CommonVariable from '../modules/CurationServiceVariable.js'
import * as StringUtil from '../modules/utils/StringUtil';

export class UserCurationtList extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
            cookie_robotmd_user : StringUtil.getUrlParams("cookie_robotmd_user"),
            member_id : StringUtil.getUrlParams("member_id"),
            guest_id : StringUtil.getUrlParams("guest_id"),
            
            curations: [],
        }
    }

    componentDidMount() {
        window.ChannelIO('hideChannelButton'); // 채널톡 버튼 숨김
        window.ChannelIO('onBoot', function() { // 새로고침 시 채널톡을 재로딩 한다. 로딩 완료 체크 후 숨김 처리
            window.ChannelIO('hideChannelButton'); // 채널톡 버튼 숨김
        });

        const {service_uid} = this.props.match.params;
        this.setState({
            service_uid: service_uid,
            window_width:  $(window).width(),
        }, () => {
            this.loadCurationList(); // 큐레이션 목록 조회
        });

        var this_component = this;
        $(window).resize(function() {
            this_component.setState({
                window_width:  $(window).width(),
                user_list_box_width: $("#quration_user_list").find(".quration_user_list_box").width(), // 큐레이션 박스 사이즈
            })
        });
    }

    // 큐레이션 목록 조회
	async loadCurationList() {
	    await http.get("/rest/common/curation/list/"+this.state.service_uid)
		.then(res => {
            if (res.data["code"] == 200) {
                this.setState({
                    curations: res.data["curations"],
                }, () => {
                    // 너무 빨리 실행하면 적용되지 않기 때문에 setTimeout을 사용
                    setTimeout(() => {
                        this.setState({
                            window_width:  $(window).width(),
                            user_list_box_width: $("#quration_user_list").find(".quration_user_list_box").width(), // 큐레이션 박스 사이즈
                        });
                    }, 100);
                })
            } else {
                alert("큐레이션이 존재하지 않습니다.")
            }
		})
		.catch(error => {
            console.log("큐레이션 목록 조회 오류", error)
            alert("큐레이션이 존재하지 않습니다.")
		 });
    }

    onClickLink(curation_seq) {
            window.location.href = (
                "/common/curation/question/"+curation_seq
                +"?cookie_robotmd_user="+(this.state.cookie_robotmd_user != null ? this.state.cookie_robotmd_user : "") 
                +"&member_id="+(this.state.member_id != null ? this.state.member_id : "") 
                +"&guest_id="+(this.state.guest_id != null ? this.state.guest_id : "")
                +"&service_uid="+(this.state.service_uid != null ? this.state.service_uid : "")
            )
    }

    render() {
        // 빈 div 삽입
        let empty_boxes = [];
        let user_list_count;
        let window_width = this.state.window_width;
        if(window_width > 1080){ // PC
            user_list_count = 3;
        }
        else if(window_width <= 1080 && window_width > 720){ // 테블릿
            user_list_count = 2;
        }
        else{ // 모바일
            user_list_count = 1;
        }
        for (let i= 0; i < Number(this.state.curations.length % user_list_count); i++) {
            empty_boxes.push(<div class="quration_user_list_box_empty" key={i}></div>);
        }
        return (
            <>
               <div id="wrap" style={{marginTop:"60px"}}>
                    <section class="quration_user_list" id="quration_user_list">
                        <div class="water_mark">Powered by RoboMD</div>
                        <div class="quration_user_list_content_wrap">
                            <h2 class="quration_user_list_title">AI 상품추천</h2>
                            <div class="quration_user_list_content">
                                {
                                    this.state.curations.map((obj, idx) => (
                                        <div class="quration_user_list_box" key={idx} onClick={() => this.onClickLink(obj.curation_seq)}>
                                            <div class="quration_user_list_box_img">
                                                <img src={CommonVariable.FILE_DOMAIN+obj.file_path} style={{height:this.state.user_list_box_width}}/>
                                            </div>
                                            <div class="quration_user_list_box_txt">
                                                <h3><span>{obj.curation_title}</span></h3>
                                                <h4><span>{obj.curation_desc}</span></h4>
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    Number(this.state.curations.length / 3) != 0
                                    ?   <>{empty_boxes}</>
                                    :   ""
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default UserCurationtList;
import React from "react";

import logo4 from "../../resources/img/icn_logo_v2.svg";

const NoMatch = () => {
	
		return (
				<div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
					<div className="odInfoCt">
						<p className="odInfoLogo"><img src={logo4} alt="ROBOTMD"/></p>
						<div className="odInfo">
							<h4>Sorry</h4>
							<p>죄송합니다<br/>
								요청하신 페이지를 찾을 수 없습니다.
							</p>
							<div>
								로보MD는 여러분과 함께 만드는 서비스 입니다<br/><br/>
								입력하신 주소가 정확한지 다시 한번<br/>
								확인해 주시기 바랍니다. 감사합니다.
							</div>
							<div className="btArea2">
								<a href="javascript:history.back();" className="btRedBd">이전페이지</a>
								<a href="/" className="btRed">홈으로 가기</a>
							</div>
						</div>
					</div>
				</div>
		);
};

export default NoMatch;
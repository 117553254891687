import React, { useCallback, useState } from 'react';
import $ from 'jquery';
import http from '../modules/api';

import * as StringUtil from '../modules/utils/StringUtil';
import * as ArrayUtil from '../modules/utils/ArrayUtil';
import BasicDialogV2 from '../modules/dialogs/BasicDialogV2';

export class UserCurationQuestion extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
            cookie_robotmd_user : StringUtil.getUrlParams("cookie_robotmd_user"),
            member_id : StringUtil.getUrlParams("member_id"),
            guest_id : StringUtil.getUrlParams("guest_id"),

            file_domain: "https://s3.ap-northeast-2.amazonaws.com/",

            curation: {
                questions: []
            },

            answers : [],

            /* BasicDialog 변수 */
            basic_dialog_show_state: false,
            basic_dialog_content: "",
            basic_dialog_button_list: [{title:"확인", event_method:this.closeBasicDialog.bind(this)}],
        }
    }

    componentDidMount() {
        window.ChannelIO('hideChannelButton'); // 채널톡 버튼 숨김 (페이지 새로고침 하지 않은 경우, SPA)
        window.ChannelIO('onBoot', function() { // 채널톡 로딩 완료 체크 후 숨김 처리 (페이지 새로고침 한 경우)
            window.ChannelIO('hideChannelButton'); // 채널톡 버튼 숨김
        });
        
        const {curation_seq} = this.props.match.params;
		this.setState({ curation_seq: curation_seq }, () => {
            this.loadCurationDetail();
        });

        $(document).ready(function(){
            var quration_user_question = $("#quration_user_question"); // 큐레이션 고객 질문
        
            // 큐레이션 고객 질문
            var user_q_content,
                user_q_content_number,
                user_q_question;
            
            // 큐레이션 고객 질문 - 라디오버튼 name/id 지정
            function user_question_radio_check(){
                user_q_content = quration_user_question.find(".quration_user_q_content");
                user_q_content_number = user_q_content.length;
        
                for(let i = 0; i < user_q_content_number; i++){
                    user_q_content.eq(i).find("input").attr("name","user_q_radio_"+i);
                    
                    user_q_question = user_q_content.eq(i).find("input");
                    for(let o = 0; o < user_q_question.length; o++){
                        user_q_question.eq(o).attr("id","user_q_radio_"+i+"_"+o);
                        user_q_question.eq(o).next("label").attr("for","user_q_radio_"+i+"_"+o);
                    }
                }
                return;
            }
            user_question_radio_check();
        });
    }

    // 큐레이션 질문 조회
	async loadCurationDetail() {
	    await http.get("/rest/common/curation/detail/"+this.state.curation_seq)
		.then(res => {
            if (res.data.code == 200) {
                this.setState({
                    curation: res.data["curation"],
                })
            } else {
                alert("큐레이션 질문이 존재하지 않습니다.")
                window.location.href = "/common/curation/list/"+(StringUtil.getUrlParams("service_uid") != null ? StringUtil.getUrlParams("service_uid")  : "")
                                        +"?cookie_robotmd_user="+(StringUtil.getUrlParams("cookie_robotmd_user") != null ? StringUtil.getUrlParams("cookie_robotmd_user")  : "")
                                        +"&member_id="+(StringUtil.getUrlParams("member_id") != null ? StringUtil.getUrlParams("member_id")  : "")
                                        +"&guest_id="+(StringUtil.getUrlParams("guest_id") != null ? StringUtil.getUrlParams("guest_id")  : "")
            }
		})
		.catch(error => {
            console.log("큐레이션 질문 조회 오류", error)
            alert("큐레이션 질문이 존재하지 않습니다.")
            window.location.href = "/common/curation/list/"+(StringUtil.getUrlParams("service_uid") != null ? StringUtil.getUrlParams("service_uid")  : "")
                                    +"?cookie_robotmd_user="+(StringUtil.getUrlParams("cookie_robotmd_user") != null ? StringUtil.getUrlParams("cookie_robotmd_user")  : "")
                                    +"&member_id="+(StringUtil.getUrlParams("member_id") != null ? StringUtil.getUrlParams("member_id")  : "")
                                    +"&guest_id="+(StringUtil.getUrlParams("guest_id") != null ? StringUtil.getUrlParams("guest_id")  : "")
		 });
    }

    onClickAnswer(question_seq, answer_seq) {
        let answers = JSON.parse(JSON.stringify(this.state.answers));
        // filter
        let new_answers = answers.filter(answer => answer.question_seq != question_seq);
        new_answers.push(
            {
                question_seq : question_seq,
                answer_seq : answer_seq,
            }
        )
        this.setState({
            answers : new_answers
        }, () => console.log("answers",this.state.answers))
    }

    onClickSubmit(e) {
        /* 큐레이션 사용자 질문 validate 체크 */
        let questions = JSON.parse(JSON.stringify(this.state.curation["questions"])); // 질문 리스트
        let answers = JSON.parse(JSON.stringify(this.state.answers)); // 답변 리스트
        let filter_questions = ArrayUtil.diffrentTwoArrayObject(questions, answers, "question_seq", "question_seq") // 답변이 누락된 질문 리스트 추출   
        if (filter_questions.length > 0) { // 답변이 누락된 질문 리스트가 있으면
            let scrollTop = $("#"+filter_questions[0]["question_seq"]).offset().top - $("#curation_header").height(); // 답변이 누락된 질문의 스크롤 높이 추출
            $(document).scrollTop(scrollTop); // 답변이 누락된 질문으로 스크롤 이동
            this.setState({
                basic_dialog_show_state : true, // 다이얼로그 띄우기
                basic_dialog_content : "답변이 누락된 질문이 있습니다."
            })
            return false;
        }

        this.postCurationAnswer();
    }

    // 큐레이션 답변 제출
	async postCurationAnswer() {
        let user_answers = [];
       for (let idx in this.state.answers) {
            user_answers.push(this.state.answers[idx]["answer_seq"]);
       }

       const config = {
            headers: {
                'content-type': 'application/json;'
            },
        }
        let body = {
            curation_seq: this.state.curation["curation_seq"],
            cookie_robotmd_user: this.state.cookie_robotmd_user,
            member_id: this.state.member_id,
            guest_id: this.state.guest_id,
            user_answers: user_answers
        }    

	    await http.post("/rest/common/curation/submit", body, config)
		.then(res => {
            if (res.data.code == 200) {
                // axios 호출 후 응답 받은 result_uid 사용
                let result_uid = res.data["result_uid"];
                window.location.href = (
                    "/common/curation/result/"+result_uid
                    +"?cookie_robotmd_user="+(this.state.cookie_robotmd_user != null ? this.state.cookie_robotmd_user : "") 
                    +"&member_id="+(this.state.member_id != null ? this.state.member_id : "") 
                    +"&guest_id="+(this.state.guest_id != null ? this.state.guest_id : "")
                    +"&service_uid="+(StringUtil.getUrlParams("service_uid") != null ? StringUtil.getUrlParams("service_uid") : "")
                )
            } else {
                alert("결과 보기에 싪패하였습니다.")
            }
		})
		.catch(error => {
            console.log("큐레이션 질문 답변 제출 오류", error)
		 });
    }

    closeBasicDialog() {
        this.setState({
            basic_dialog_show_state : false,
            basic_dialog_content : ""
        })
    }
    
    render() {
        return (
            <>
               <div id="wrap" style={{paddingTop:"60px"}}>
                    <section class="quration_user_question style_guide_v2" id="quration_user_question">
                        <div class="water_mark">Powered by RoboMD</div>
                        <div class="quration_user_q_content_wrap">
                        <div class="quration_user_q_title_wrap">
                            <h2>{this.state.curation["curation_title"]}</h2>
                            <h3 dangerouslySetInnerHTML={{__html: (this.state.curation["curation_desc"] != null ? this.state.curation["curation_desc"].replaceAll("\n", "<br/>") : "")}}></h3>
                        </div>
                            {
                                this.state.curation["questions"].map((obj, idx) => (
                                    <>
                                        <div class="quration_user_q_content" key={idx} id={obj.question_seq}>
                                            {
                                                obj["file_path"] == null ||  obj["file_path"] == ""
                                                ?   ""
                                                :   <img src={this.state.file_domain+obj["file_path"]}/>
                                            }
                                            <h3 dangerouslySetInnerHTML={{__html: (obj.question_desc != null ? obj.question_desc.replaceAll("\n", "<br/>") : "")}}></h3>
                                            {
                                                obj.answers != null
                                                ?   <div class="quration_user_q_btn_wrap">
                                                        {
                                                            obj["answers"].map((answers_obj, idx) => (
                                                                <>
                                                                    {
                                                                        this.state.answers.filter(answer => answer.question_seq == obj.question_seq)
                                                                                            .filter(answer => answer.answer_seq == answers_obj.answer_seq ).length > 0
                                                                        ? <input type="radio" checked/> 
                                                                        : <input type="radio"/>
                                                                    }
                                                                    <label class="hover_white" onClick={e => this.onClickAnswer(obj.question_seq, answers_obj.answer_seq) }>{answers_obj.answer_desc}</label>
                                                                </>
                                                            ))
                                                        }
                                                    </div>
                                                :   ""
                                            }
                                        </div>
                                        {
                                            idx != (this.state.curation["questions"].length - 1)
                                            ?   <div style={{backgroundColor:"#F4F7F9", width:"100%", height:"8px"}}></div>
                                            :   ""
                                        }
                                    </>
                                ))
                            }
                            <span class="quration_user_q_result_btn_wrap">
                                <button class="quration_user_q_result_btn" type="button" onClick={e => this.onClickSubmit(e)}>결과 보기</button>
                            </span>
                        </div> 
                    </section>
                </div>
                {
					(this.state.basic_dialog_show_state === true)
					? <BasicDialogV2
						content={this.state.basic_dialog_content}
						button_list={this.state.basic_dialog_button_list}
                        button_color="#FFDFDD"
                        button_hover_color="#FFDFDD"
					/>
					: ''
				}
            </>
        )
    }
}

export default UserCurationQuestion;
import React from 'react';
import axios from 'axios';
import http from '../modules/api';
import $ from 'jquery'

export class NoticeDetail extends React.Component {
	
	constructor(props) {
		super(props);
	    this.state = {
	      notice: null
	    }
	}
	
	componentDidMount() {
		// uri 파라메터 얻기: this.props.match.params
	    const {notice_seq} = this.props.match.params;
	    console.log("### 1. componentDidMount" + notice_seq);
		this.getNotice(notice_seq);
	}
	
	async getNotice(id) {
		const res = await http.get(`/rest/shop/notice/${id}`);
		console.log(res.data)
		this.setState({notice: res.data});
	}

	async delNotice(id) {
		const res = await http.post(`/admin/del/notice/${id}`);
		console.log(res.data)
		if (res.data != null && res.data["result"] == "true") {
			alert("게시물이 삭제 되었습니다.")
			this.props.history.goBack();
			localStorage.clear();
		}
	}
	
	//3. 사용자 권한 변경
	onClickSignupButton = () => {
		
	};
	
	// 목록으로 이동 (뒤로가기)
	onGoList = () => {
		this.props.history.push(`/shop/notice/list`);
	};

	// 수정페이지 이동
	onGoModify = (notice_seq) => {
		this.props.history.push(`/shop/notice/form/${notice_seq}`);
	};
	
	render() {
		$(".pageName").text("공지사항");
		return (
			this.state.notice ?
			<div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
				<div className="form_primary_title pc_page_title">[Shop] 공지사항</div>
				<div className="mobile_top_margin"></div>
				<div className="shadow-box margin-r-5">
					<div style={{height:"auto"}} className="float-right-parent border-bottom-grey padding-10">
						<div className="bold-text float-left" style={{textOverflow: "ellipsis"}}>
							{this.state.notice.title}
						</div>
						<div className="color-grey text-small float-right" style={{lineHeight: "22px"}}>
							<span className="margin-r-10">{this.state.notice.user_name}</span>
							<span>{this.state.notice.reg_date}</span>
						</div>
					</div>
					{(() => {
						if (this.state.notice.file_link != null) {
							return <div>
								<div className="padding-10 border-bottom-grey text-small">
									파일 - <a className="color-black" href={this.state.notice.file_link} target="blank">{this.state.notice.file_name}</a>
								</div>
							</div>
						}	
					})()}
					<div>
						<div className="padding-10" style={{height:"60vh", overflow: "auto"}}
						dangerouslySetInnerHTML={ {__html: this.state.notice.content} }>
						</div>
					</div>
				</div>
				<div className="margin-t-10 float-right"> 
		        	{/* <button className="greenButton margin-r-5" onClick={() => this.onGoModify(this.state.notice.notice_seq)}>수정</button>&nbsp; */}
		        	{/* <button className="greenButton margin-r-5" onClick={() => this.delNotice(this.state.notice.notice_seq)}>삭제</button>&nbsp; */}
		        	<button className="btn-auto-b margin-r-5" style={{width:"auto", height:"40px"}} onClick={this.onGoList}>목록</button>&nbsp;
				</div>

			</div>
			: 'non-data'
		);
	}
	
	componentWillReceiveProps(nextProps, nextContext) {
		console.log(nextProps);
		this.getUser(nextProps.match.params.notice_seq);
	}
	
}

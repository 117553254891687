import React from 'react';
import axios from 'axios';
import http from '../modules/api';

export class InfoReqDetail extends React.Component {
	
	constructor(props) {
		super(props);
	    this.state = {
		  data: null
	    }
	}
	
	componentDidMount() {
		// uri 파라메터 얻기: this.props.match.params
	    const {info_req_seq} = this.props.match.params;
		this.getInfoReq(info_req_seq);
	}
	
	async getInfoReq(id) {
		const res = await http.get(`/rest/admin/infoReq/detail/${id}`);
		this.setState({data: res.data});
	}	
	
	// 목록으로 이동 (뒤로가기)
	onGoList = () => {
		this.props.history.push(`/admin/infoReq/list`);
	};
	
	render() {
		
		return (
			this.state.data ?
			<div id='page-wrapper' className="container info_req_detail_container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
				<h3>[Admin] CS 문의사항</h3>
				<br/>
				<div className="shadow-box margin-r-5">
					<div style={{height:"auto"}} className="float-right-parent border-bottom-grey padding-10">
						<div className="bold-text float-left" style={{textOverflow: "ellipsis"}}>
							{this.state.data.title}
						</div>
						<div className="color-grey text-small float-right" style={{lineHeight: "22px"}}>
							<span className="margin-r-10">{this.state.data.corp_name}</span>
							<span>{this.state.data.reg_date}</span>
						</div>
					</div>
					<div className="flexBox">
						<div className="border-bottom-grey text-small flexBox" style={{flex:"1", flexDirection:"column", borderRight:"1px solid #c4c4c4"}}>
							<div className="padding-10" style={{flex:"1", background:"#f6f6f6f6"}}>
							문의 유형
							</div> 
							<div className="padding-10" style={{flex:"3"}}>
							{this.state.data.type}
							</div>
						</div>
						<div className="border-bottom-grey text-small flexBox" style={{flex:"1", flexDirection:"column"}}>
							<div className="padding-10" style={{flex:"1", background:"#f6f6f6f6"}}>
							플랫폼 유형 
							</div>
							<div className="padding-10" style={{flex:"3"}}>
							{this.state.data.platform_type}
							</div>
						</div>
						<div className="border-bottom-grey text-small flexBox" style={{flex:"1", flexDirection:"column", borderLeft:"1px solid #c4c4c4"}}>
							<div className="padding-10" style={{flex:"1", background:"#f6f6f6f6"}}>
							사이트 주소 
							</div>
							<a className="color-black padding-10" href={'http://'+this.state.data.site_url} target="blank" style={{flex:"3"}}>{this.state.data.site_url}</a>
						</div>
					</div>
					<div className="flexBox">
						<div className="border-bottom-grey text-small flexBox" style={{flex:"1", flexDirection:"column"}}>
						<div className="padding-10" style={{flex:"1", background:"#f6f6f6f6"}}>
							담당자명 
							</div>
							<div className="padding-10" style={{flex:"3"}}>
							{this.state.data.user_name}
							</div>
						</div>
						<div className="border-bottom-grey text-small flexBox" style={{flex:"1", flexDirection:"column", borderLeft:"1px solid #c4c4c4"}}>
							<div className="padding-10" style={{flex:"1", background:"#f6f6f6f6"}}>
							이메일
							</div> 
							<div className="padding-10" style={{flex:"3"}}>
							{this.state.data.email}
							</div>
						</div>
						<div className="border-bottom-grey text-small flexBox" style={{flex:"1", flexDirection:"column", borderLeft:"1px solid #c4c4c4"}}>
							<div className="padding-10" style={{flex:"1", background:"#f6f6f6f6"}}>
							연락처 
							</div>
							<div className="padding-10" style={{flex:"3"}}>
							{this.state.data.tel_no}
							</div>
						</div>
					</div>
					{(() => {
						if (this.state.data.file_link != null) {
							return <div>
								<div className="padding-10 border-bottom-grey text-small">
									파일 - <a className="color-black" href={this.state.data.file_link} target="blank">{this.state.data.file_name}</a>
								</div>
							</div>
						}	
					})()}
					<div>
						<div className="padding-10" style={{height:"40vh", overflow: "auto"}}>
							{/* <textarea style={{width:"100%", height:"100%"}} readOnly>{this.state.qna.description}</textarea> */}
							<pre>{this.state.data.description}</pre>
						{/* dangerouslySetInnerHTML={ {__html: this.state.qna.description} }> */}
						</div>
					</div>
				</div>
				<div className="margin-t-10 btn_wrap"> 
		        	<button className="greenButton margin-r-5" onClick={this.onGoList}>목록</button>&nbsp;
				</div>
			</div>
			: 'non-data'
		);
	}
	
}

import { React, Component } from 'react';
import '../../resources/fb/css/reset.css';
import '../../resources/fb/css/master.css';
import '../../resources/fb/css/bootstrap.min.css';
import '../../resources/fb/css/trend_all.css';
import '../../resources/fb/css/responsive.css';
import { ProgressBar } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import axios from 'axios';

class Stat extends Component {

    constructor(props) {
        super(props);

        var today = new Date();
        var date = new Date(Number(today.getFullYear()), Number(today.getMonth()), Number(today.getDate()))
        this.state = {
            date: date,
            goods_type: 'BEST',
            category_code: '',
            category_name: '',
            last_date: new Date(),
            total_count: 0,
            total_list: [],
            category_count: 0,
            category_list: [],
        };
    }

    componentDidMount() {
        axios.get('/rest/fb/getLastDate').then(
            response => {
                var yyyyMMdd = String(response.data.lastDate);
                var yyyy = yyyyMMdd.substring(0,4);
                var mm = yyyyMMdd.substring(4,6);
                var dd = yyyyMMdd.substring(6,8);
                var date = new Date(Number(yyyy), Number(mm)-1, Number(dd))
                this.setState({
                    date: date,
                    last_date: date,
                }, () => this.get_list());
            }
        )
    }
    
    onClickCategory = (e) => {
        this.setState({
            category_code: e.target.getAttribute('data-category-code'),
            category_name: e.target.getAttribute('data-category-name'),
        }, () => this.get_category_list());
    }

    onClickType = (e) => {
        var data_type = e.target.getAttribute('data-type');
        this.setState({
            goods_type: data_type,
        }, () => this.get_list());
    }

    get_date_message() {
        var date = this.state.date;
        var week = ['일','월','화','수','목','금','토'];
        var year = date.getFullYear();
        var month = (1 + date.getMonth());
        month = month >= 10 ? month : '0' + month;
        var day = date.getDate();
        day = day >= 10 ? day : '0' + day;

        var date_message =  year + '년 ' + month + '월 ' + day + '일';
        var day_message = week[date.getDay()] + '요일'; 
        return <>
                <p style={{marginBottom: 0, alignSelf: "center"}}>
                    {/* {date_message} */}
                    <DatePicker name="from_date" id="from_date" className="fb_from_date_no_border font_family_normal"
                            selected={this.state.date}
                            onSelect={this.onSelectFromDate}
                            placeholderText="일자"
                            dateFormat="yyyy년 MM월 dd일"
                            autoComplete="off"
                            showYearDropdown
                            showMonthDropdown
                            todayButton="오늘로 이동"
                            locale={ko}
                            minDate={new Date("2021","04","02")} // 2021-05-02
                            maxDate={this.state.last_date}
                        />
                </p>
                <span className="fb_from_date_no_border" style={{margin:"auto 0"}}>{day_message}</span></>;
    }
    
    prev_day = () => {
        if (this.state.date <= new Date("2021","04","02")) return false;
        
        var date = this.state.date
        date = new Date(date.setDate(date.getDate()-1))
        this.setState({
            date: new Date(date),
        }, () => {
            this.get_list();
        });
    }

    next_day = () => {
        var date = this.state.date
        if( (date <= this.state.last_date) === false ) return;
        date = new Date(date.setDate(date.getDate()+1))

        this.setState({
            date: new Date(date),
        }, () => {
            this.get_list();
        });
    }

    get_list = () => {
        this.get_total_list();
        this.get_category_list();
    }

    make_date_message = () => {
        var date = this.state.date;
        var year = date.getFullYear();
        var month = (1 + date.getMonth());
        month = month >= 10 ? month : '0' + month;
        var day = date.getDate();
        day = day >= 10 ? day : '0' + day;
        return String(year) + String(month) + String(day);
    }

    get_category_list = () => {
        var date_message = this.make_date_message()
        var url = '/rest/fb/getStatCategory?goods_type=' + this.state.goods_type + '&date=' + date_message + '&category_code=' + this.state.category_code;
        console.log(url)
		axios.get(url).then(response => {
            this.setState({
                category_count: response.data.count,
                category_list: response.data.list,
            });
        });
    }

    make_category_list = () => {
        if( this.state.category_list.length === 0 ) return ''
        return this.state.category_list.map((item, i) => 
            <li>
                <p style={{marginBottom: 0}}>{item.keyword}</p>
                <ProgressBar variant="info" now={item.count} max={this.state.category_count} />
                &nbsp;
                &nbsp;
                &nbsp;
                &nbsp;
                &nbsp;
                &nbsp;
                <span>{Math.round((item.count/this.state.category_count)*100,0)}%</span>
            </li>
        );
    }

    get_total_list = () => {
        var date_message = this.make_date_message()
        var url = '/rest/fb/getStatTotal?goods_type=' + this.state.goods_type + '&date=' + date_message;
		axios.get(url).then(response => {
            this.setState({
                total_count: response.data.count,
                total_list: response.data.list,
            });
        });
    }

    make_total_list = () => {
        if( this.state.total_list.length === 0 ) return ''
        return this.state.total_list.map((item, i) => 
            <li class={ item.category_code === this.state.category_code ? 'imp' : '' }>
                <p style={{marginBottom: 0}}><a href={() => false} style={{cursor: 'pointer'}} onClick={this.onClickCategory} data-category-code={item.category_code} data-category-name={item.cd_desc}>{item.cd_desc}</a></p>
                <ProgressBar variant="info" now={item.count} max={this.state.total_count} />
                &nbsp;
                &nbsp;
                &nbsp;
                &nbsp;
                &nbsp;
                &nbsp;
                <span>{Math.round((item.count/this.state.total_count)*100,0)}%</span>
            </li>
        );
    }

    onSelectFromDate = (date) => {
        if (date != null && date != "") {
            this.setState({
                date: date,
            }, () => {
                this.get_list();
            });
        }
    }

    render() {
        const is_best = this.state.goods_type === 'BEST' ? 'active' : '';
        const opacity_best = this.state.goods_type === 'BEST' ? 100+'%' : 40+'%';
        const is_new = this.state.goods_type === 'NEW' ? 'active' : '';
        const opacity_new = this.state.goods_type === 'NEW' ? 100+'%' : 40+'%';
        return (
            <section>
                <div className="container-fluid bg-gr">
                    <div className="row">
                        {/* <div className="col-md-2 menu_area"></div> */}
                        <div className="col-md-10 contents_area everage">
                            <div className="content_area">
                                <div className="content_title font_family_normal">
                                    <h1 className="font_family_normal" style={{margin: "auto 0"}}>통계</h1>
                                </div>
                                <div className="content_header">
                                    <div className="date_wrap">
                                        <div className="date_input">
                                            <button type="button" onClick={this.prev_day} className="date_prev"></button>
                                            <div className="date">
                                                {this.get_date_message()}
                                            </div>
                                            <button type="button" onClick={this.next_day} className="date_next"></button>
                                        </div>
                                    </div>
                                    <div className="content_tabs">
                                        <ul style={{marginBottom: 0}}>
                                            <li className={is_best}>
                                                <a href={()=>false} onClick={this.onClickType} style={{cursor: 'pointer', opacity: opacity_best}} data-type="BEST">베스트</a>
                                            </li>
                                            <li className={is_new}>
                                                <a href={()=>false} onClick={this.onClickType}  style={{cursor: 'pointer', opacity: opacity_new}} data-type="NEW">신상</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="content_item">
                                    <div className="content">
                                        <div className="title">
                                            <h1 className="font_family_normal">상품구성</h1>
                                        </div>
                                        <div className="total">
                                            <p className="font_family_normal">총 {Number(this.state.total_count).toLocaleString()}개</p>
                                        </div>
                                        <div className="progress_area progress_first_area">
                                            <ul>
                                                { this.make_total_list() }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="title">
                                            <h1 className="font_family_normal"><span>{ this.state.category_name }</span> 세부 구성</h1>
                                        </div>
                                        <div className="total">
                                            <p className="font_family_normal">총 {Number(this.state.category_count).toLocaleString()}개</p>
                                        </div>
                                        <div className="progress_area progress_second_area">
                                            <ul>
                                                { this.make_category_list() }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

}

export default Stat;
import React from 'react';
import '../css/style.css';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-78553461-8');
var ga_label = sessionStorage.getItem("userName");
/**
 * 최초 접속 / 간단한 소개 페이지
 * 	1. 서비스 안내 신청
 */
export class KoInfo extends React.Component {
	
	state = {
		email: ''
	}
	
	handleText = (e, key) => {
		// computed property
		this.setState({[key]: e.target.value.trim() });
	}
	
	handleKeyPress = (e) => {
	    if (e.key === "Enter") {
	    	this.onClickInfoReqButton();
	    }
	}

	// 서비스 안내 신청
	onClickInfoReqButton = () => {
		
		var email = this.state.email;

		if (email == null || email == "") {
			alert("업무용 이메일을 입력해주세요.");
			// 가입신청 - 가입신청 요청
			ReactGA.event({
				category: "서비스 신청 이벤트 요청",
				action: "업무용 이메일을 입력해주세요. : " + email,
				label: ga_label
			});
			return false;
		}
		// const browser = navigator.detectBroeser();
		// const os = navigator.detectOS();
		// const device = navigator.detectDevice();

		//sessionStorage.setItem("email", email);
		//고객정보 상세 입력폼 이동
		this.props.history.push(`/ko/contact?email=${email}`);
	};

	componentDidMount() {
		var referrer = sessionStorage.getItem("referrer");
		var doc_referrer = document.referrer;
		console.log("----------------------------------")
		console.log(referrer)
		console.log(doc_referrer)
		console.log("----------------------------------")

		sessionStorage.setItem("referrer", doc_referrer);

		// if (referrer != null) {
		// 	console.log("referrer : " + referrer);
		// }
		// axios.get("/logout")
		// .then(res => {
		// 	// alert("세션이 끊어졌습니다.")
		// })
		// .catch(error => {
		// 	// console.log (error);
		//  });
	}

	render() {
		return (
			<div className="login-container" style={{minHeight: "100vh", position: "relative"}}>
				<div id='non-page-wrapper'>
					<div className="middle-box text-center" style={{paddingBottom:"188px"}}>
						<h3>1. 서비스 신청 페이지</h3>
						<div className="form-group m-b-lg">
							<div className="input-group">
								<input type="text" id="email" placeholder="업무용 이메일을 입력해주세요" className="form-control"
									value={this.state.email} 
									onChange={(e) => this.handleText(e, 'email')}
									onKeyPress={this.handleKeyPress}
									required maxLength="100" />
								<span className="input-group-btn">
									<button className="btn btn-primary" onClick={this.onClickInfoReqButton}>서비스 신청</button>
									{/* <Link className="btn find_btn" to={`/ko/infoReq?email=${this.state.email}`}>서비스 신청</Link> */}
								</span>
							</div>
						</div>
						<div style={{marginTop:"15px"}}>
						iclave © 2021
						</div>
					</div>
				</div>	
				<footer className="flexBox" style={{borderTop: "1px solid  #c3c3c3", position:"absolute", bottom:"0", backgroundColor:"white", width: "100%", padding:"20px 0 20px 0", color:"#3c3c3c", fontSize:"14px"}}>
					<div className="footer-first-child" style={{width:"32%", paddingLeft:"3.5%"}}>
						<div style={{display: "table", width:"auto"}}>
							<div style={{display: "table-cell", verticalAlign: "middle"}}>
								(주)아이클레이브 대표이사 : 최윤내<br/>
								<br/>
								사업자등록번호 : 381-81-00136<br/>
								통신판매번호 : 2020-서울마포-2676<br/>
								사업장 소재지 : 서울특별시 강남구 테헤란로2길, 13층 1308호(역삼동, 패스트파이브빌딩)<br/>
								{/* 사업장 소재지 : 서울특별시 강남구 영동대로85길 34, 스파크플러스 삼성2호점, 405호 (대치동)<br/> */}
								{/* 우편물 : 서울특별시 강남구 영동대로85길 34, 스파크플러스 삼성2호점, 6층 (대치동)<br/> */}
								이메일 : robomd@robomd.io
							</div>
						</div>
					</div>
					<div className="footer-second-child" style={{width:"32%", borderLeft:"1px solid  #c3c3c3", textAlign:"center"}}>
						<div className="flexBox" style={{width:"100%", height:"50%"}}>
							<div style={{width:"33%",height:"100%"}}>
								<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
									<div style={{display: "table-cell", verticalAlign: "middle"}}>
										<a href="https://www.otdeal.co.kr/home/contact" target="blank">문의 하기</a>
									</div>
								</div>
							</div>
							<div style={{width:"33%",height:"100%", borderLeft:"1px solid  #c3c3c3"}}>
								<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
									<div style={{display: "table-cell", verticalAlign: "middle"}}>
										<a href="https://www.otdeal.co.kr" target="blank">회사 사이트</a>
									</div>
								</div>	
							</div>
							<div style={{width:"33%",height:"100%", borderLeft:"1px solid  #c3c3c3"}}>
								<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
									<div style={{display: "table-cell", verticalAlign: "middle"}}>
										<a href="/common/FormViewer/1" target="blank">이용약관</a>
									</div>
								</div>	
							</div>
						</div>
						<div className="flexBox" style={{width:"100%", height:"55%", paddingTop: "10px", margin: "10px 0 30px 0", borderTop:"1px solid  #c3c3c3"}}>
							<div style={{width:"33%",height:"100%"}}>
								<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
									<div style={{display: "table-cell", verticalAlign: "middle"}}>
										<a href="https://www.instagram.com/otdeal_official" target="blank">Instagram</a>
									</div>
								</div>	
							</div>
							<div style={{width:"33%",height:"100%", borderLeft:"1px solid  #c3c3c3"}}>
								<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
									<div style={{display: "table-cell", verticalAlign: "middle"}}>
										<a href="https://www.facebook.com/otdeal" target="blank">Facebook</a>
									</div>
								</div>
							</div>
							<div style={{width:"33%",height:"100%", borderLeft:"1px solid  #c3c3c3"}}>
								<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
									<div style={{display: "table-cell", verticalAlign: "middle"}}>
										<a href="/common/FormViewer/2" target="blank">개인정보처리방침</a>
									</div>
								</div>
							</div>
						</div>
					</div>		
					<div className="footer-third-child" style={{width:"32%", borderLeft:"1px solid  #c3c3c3"}}>
					<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
						<div style={{display: "table-cell", verticalAlign: "middle"}}>
							<h1><b>고객센터 : 1599-3518</b></h1><br/>

							오전 10:00 ~ 오후 7:00 lunch 13:00 ~ 14:00 토요일/공휴일 휴무<br/>
						</div>
					</div>
					</div>
				</footer>		
				</div>
			
		);
	}
	
}
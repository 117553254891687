import React from 'react';
import axios from '../modules/api';
import http from '../modules/api';
import {Line} from 'react-chartjs-2';
import { NavLink, Link } from 'react-router-dom';
import $ from 'jquery';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import Draggable from 'react-draggable';

var mirror_banner_url = "/admin_banner.html";
var isEventPcMobile = false;
var calendar_event_seq = "";
// alert($(document).width());
// alert(document.width);
var fullCalendarInnerModal_width=$(document).width() * 1.4;
// var fullCalendarInnerModal_pcbanner_width=$(document).width()/1.68; 
// var fullCalendarInnerModal_mobileBanner_width=$(document).width()/0.69; 

var event_banner_pc_width_height = 1.68;
var event_banner_mobile_width_height = 0.69;
export class Main extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
            user_token : "",
			start_date_format : "",
			end_date_format : "",
			item_start_date_format : "",
            item_end_date_format : "",
            sale_start_date_format : "",
			sale_end_date_format : "",
			eventData : {
				labels:[""],
				datasets:[]
			},
			itemData : {
				labels:[""],
				datasets:[]
            },
            saleData : {
				labels:[""],
				datasets:[]
			},
            options: {maintainAspectRatio: false},
            
            notice_list: [],
			notice_start_date_format: "",
			notice_end_date_format: "",
            
            qna_list: [],
			qna_start_date_format: "",
            qna_end_date_format: "",
            
            eventCntByThisMonth : null,
            eventCntByThisMonthFromUser : null,
            eventCntByThisMonthFromNonUser : null,
            eventCnt : null,
            eventCntFromUser : null,
            eventCntFromNonUser : null,
            pageViewCntByToday : null,
            pageViewCntByMonth : null,
            overChargeStandard : null,
            eventSettingDescription : null,
		}
    }

    componentWillMount() {
		let date = new Date();
		let startDate = new Date(date.getFullYear()+"-"+(date.getMonth()+1)+"-"+"01");
		this.setState({end_date_format: date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate()});
		this.setState({start_date_format: date.getFullYear()+"-"+(date.getMonth()+1)+"-"+"01"});
		this.setState({end_date: date});
		this.setState({start_date: startDate});

		this.setState({item_end_date_format: date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate()});
		this.setState({item_start_date_format: date.getFullYear()+"-"+(date.getMonth()+1)+"-"+"01"});
		this.setState({item_end_date: date});
		this.setState({item_start_date: startDate});

		this.setState({sale_end_date_format: date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate()});
		this.setState({sale_start_date_format: date.getFullYear()+"-"+(date.getMonth()+1)+"-"+"01"});
		this.setState({sale_end_date: date});
		this.setState({sale_start_date: startDate});
	}

	componentDidMount() {
        this.getFullCalendarEventList("", ""); // 달력용 기획전 목록 조회
        this.getCntDatas();
        this.getUserToken();
		// this.getEventSates();
        // this.getEventItemSates();
        // this.getEventSaleSates();

        // this.getNoticeList();
        // this.getQnaList();

        $('#copy_user_token').on('click', function(e) { 
            alert("복사되었습니다.");
        })
        
        var this_componnent = this;
        $(window).resize( function() {
            fullCalendarInnerModal_width=$(document).width() * 1.4;
            // this_componnent.calendarBannerModalSetSize();
        });
    }

    componentDidUpdate() {
        if (isEventPcMobile) {
            isEventPcMobile = false;
            this.calendarBannerModalSetSize();
        }
    }

    getCntDatas() {
        http.get(`/rest/shop/main/cnt/data`).then(res => {
            this.setState({
                eventCntByThisMonth : res.data.eventCntByThisMonth,
                eventCntByThisMonthFromUser : res.data.eventCntByThisMonthFromUser,
                eventCntByThisMonthFromNonUser : res.data.eventCntByThisMonthFromNonUser,
                eventCnt : res.data.eventCnt,
                eventCntFromUser : res.data.eventCntFromUser,
                eventCntFromNonUser : res.data.eventCntFromNonUser,
                pageViewCntByToday : res.data.pageViewCntByToday,
                pageViewCntByMonth : res.data.pageViewCntByMonth,
                overChargeStandard : res.data.overChargeStandard,
                eventSettingDescription : res.data.eventSettingDescription,
            });
		})
		.catch(error => {
			// console.log (error);
		})
    }

    
    getUserToken() {
		http.get(`/rest/shop/userToken`).then(res => {
			this.setState({user_token : res.data.token});
		})
		.catch(error => {
			// console.log (error);
		})
    }
    
    getEventSates() {
		http.get(`/rest/shop/event/states?start_date=`+this.state.start_date_format+"&end_date="+this.state.end_date_format).then(res => {
            console.log(res.data);
			if(typeof res.data === 'string') {
				if(res.data.indexOf("<script>") >= 0){
                    alert("로그인 후 이용해 주세요.")
                    window.location.href = "/login";
				}
			}
            this.setState({eventData : {datasets: res.data.datasets, labels: res.data.labels}});
			// this.state.eventData.datasets = res.data.datasets;
			// this.state.eventData.labels = res.data.labels;
		})
		.catch(error => {
			// console.log (error);
		})
	}

    getEventItemSates() {
		http.get(`/rest/shop/event/item/states?start_date=`+this.state.item_start_date_format+"&end_date="+this.state.item_end_date_format).then(res => {
			this.setState({itemData : {datasets: res.data.datasets, labels: res.data.labels}});
			// this.state.eventData.datasets = res.data.datasets;
			// this.state.eventData.labels = res.data.labels;
		})
		.catch(error => {
			// console.log (error);
		})
    }

    getEventSaleSates() {
		http.get(`/rest/shop/event/sale/states?start_date=`+this.state.sale_start_date_format+"&end_date="+this.state.sale_end_date_format).then(res => {
			this.setState({saleData : {datasets: res.data.datasets, labels: res.data.labels}});
			// this.state.eventData.datasets = res.data.datasets;
			// this.state.eventData.labels = res.data.labels;
		})
		.catch(error => {
			console.log (error);
		})
	}
    
    getNoticeList() {
		http.get("/rest/shop/list/notice?start_date="+this.state.notice_start_date_format+"&end_date="+this.state.notice_end_date_format+"&startidx="+0+"&rowcnt="+5)
		.then(res => {
            this.setState( {notice_list : res.data["list"]} );
            for (var idx in this.state.notice_list) {
                this.state.notice_list[idx].reg_date = ('0' + new Date(this.state.notice_list[idx].reg_date).getMonth ()).slice(-2) + '-'
             +  ('0' + new Date(this.state.notice_list[idx].reg_date).getDate ()).slice(-2);
            }
		})
		.catch(error => {
			// console.log (error);
		 });
    }

    getQnaList() {
		http.get("/rest/shop/list/qna?start_date="+this.state.qna_start_date_format+"&end_date="+this.state.qna_end_date_format+"&startidx="+0+"&rowcnt="+5)
		.then(res => {
            this.setState( {qna_list : res.data["list"]} );
            for (var idx in this.state.qna_list) {
                this.state.qna_list[idx].reg_date = ('0' + new Date(this.state.qna_list[idx].reg_date).getMonth ()).slice(-2) + '-'
             +  ('0' + new Date(this.state.qna_list[idx].reg_date).getDate ()).slice(-2);
            }
			// console.log(this.state.totalCnt)
		})
		.catch(error => {
			// console.log (error);
		 });
    }
    

    handleNoticeClick = (notice_seq) => {
		this.props.history.push(`/shop/notice/detail/${notice_seq}`);
    };
    
    handleQnaClick = (qna_seq) => {
		this.props.history.push(`/shop/qna/detail/${qna_seq}`);
    };
    
    getFullCalendarEventList (year, month) {
        var sendForm = {
            user_seq: this.state.user_seq , 
            shop_no: "1", 
            year: year,
            month: month,
            calendar_type: "event_form"
        }

        http.get("/rest/common/calendar/event/list", {params:sendForm})
        .then(res => {
            this.setState({fullCalendarEventList: res.data.list});
        })
    }

    onClickeFullCalendarEvent = (e) => {
        // if (this.state.pc_banner_ratio_list == null || this.state.mobile_banner_ratio_list == null) {
        //     alert("배너 미리보기를 하려면 적용범위(페이지와 배너)를 먼저 선택해 주세요.");
        //     return false;
        // }
        $(".fullCalendarModal").show();
        $(".fullCalendarModal").css("left", e.jsEvent.pageX+"px");
        $(".fullCalendarModal").css("top", e.jsEvent.pageY+"px");
        $(".fullCalendarModal").css("transform", "");
        calendar_event_seq = e.event.id;
        http.get("/rest/shop/is/event/pcmobile?event_seq="+calendar_event_seq)
        .then(res => {
            isEventPcMobile = true;
            this.setState({isEventPcMobile: res.data.obj});
        })
    }

    onClickFullCalendarModalClose = (e) => {
        $(".fullCalendarModal").hide();
    }

    calendarBannerModalSetSize() {
        if(this.state.isEventPcMobile != null && this.state.isEventPcMobile.is_pc == "Y") {
            $(".fullCalendarInnerModal .pcBanner").html('<label>가로형</label><br><object class="calendar_robomd_banner_p" type="text/html" data="'+mirror_banner_url+'?event_seq='+calendar_event_seq+'&platform_type=p&mirror_type=18002" type="text/html" style="width:100%;height:100%;margin-left:1%;"></object>');
        
            $(".fullCalendarInnerModal .pcBanner").show();
            $(".fullCalendarModal").css("width", ((fullCalendarInnerModal_width/6.2)*2.2)+"px");
            $(".fullCalendarModal").css("min-width", ((fullCalendarInnerModal_width/6.2)*2.2)+"px");
        } else {
            $(".fullCalendarInnerModal .pcBanner").hide();
            $(".fullCalendarModal").css("width", ((fullCalendarInnerModal_width/6.2)*1.5)+"px");
            $(".fullCalendarModal").css("min-width", ((fullCalendarInnerModal_width/6.2)*1.5)+"px");
        }
        
        if(this.state.isEventPcMobile != null && this.state.isEventPcMobile.is_mobile == "Y") {
            $(".fullCalendarInnerModal .mobileBanner").html('<label>세로형</label><br><object class="calendar_robomd_banner_m" type="text/html" data="'+mirror_banner_url+'?event_seq='+calendar_event_seq+'&platform_type=m&mirror_type=18002" type="text/html" style="width:100%;height:100%;"></object>');
        
            $(".fullCalendarInnerModal .mobileBanner").show();
            $(".fullCalendarModal").css("width", ((fullCalendarInnerModal_width/6.2)*2.2)+"px");
            $(".fullCalendarModal").css("min-width", ((fullCalendarInnerModal_width/6.2)*2.2)+"px");
            if(!(this.state.isEventPcMobile != null && this.state.isEventPcMobile.is_pc == "Y")) {
                $(".fullCalendarInnerModal .pcBanner").hide();
                $(".fullCalendarModal").css("width", ((fullCalendarInnerModal_width/6.2)*0.8)+"px");
                $(".fullCalendarModal").css("min-width", ((fullCalendarInnerModal_width/6.2)*0.8)+"px");
            }
        } else {
            $(".fullCalendarInnerModal .mobileBanner").hide();
            $(".fullCalendarModal").css("width", ((fullCalendarInnerModal_width/6.2)*1.5)+"px");
            $(".fullCalendarModal").css("min-width", ((fullCalendarInnerModal_width/6.2)*1.5)+"px");
        }

        if (Number(event_banner_pc_width_height) > 1) {
            // $(".fullCalendarInnerModal .pcBanner").height((fullCalendarInnerModal_pcbanner_width /  Number(event_banner_pc_width_height)) + "px");
            $(".fullCalendarInnerModal .pcBanner").height(((((fullCalendarInnerModal_width/6.2)*2.2) * 0.65) /  Number(event_banner_pc_width_height)) + "px");
            // this.setState({fullCalendarInnerModal_pc_banner_height: Number(event_banner_pc_width_height)});
        } else {
            // $(".fullCalendarInnerModal .pcBanner").height((fullCalendarInnerModal_pcbanner_width.width() /  1.68) + "px");
            $(".fullCalendarInnerModal .pcBanner").height(((((fullCalendarInnerModal_width/6.2)*2.2) * 0.65) / 1.68) + "px");
            // this.setState({fullCalendarInnerModal_pc_banner_height: 1.68});
        }
        if (Number(event_banner_mobile_width_height) <= 1) {
            // $(".fullCalendarInnerModal .mobileBanner").height((fullCalendarInnerModal_mobileBanner_width / Number(event_banner_mobile_width_height))+ "px");
            $(".fullCalendarInnerModal .mobileBanner").height(((((fullCalendarInnerModal_width/6.2)*2.2) * 0.4) / Number(event_banner_mobile_width_height))+ "px");
            // this.setState({fullCalendarInnerModal_mobile_banner_height: Number(event_banner_mobile_width_height)});
        } else {
            // $(".fullCalendarInnerModal .mobileBanner").height((fullCalendarInnerModal_mobileBanner_width / 0.69)+ "px");
            $(".fullCalendarInnerModal .mobileBanner").height(((((fullCalendarInnerModal_width/6.2)*2.2) * 0.4) / 0.69)+ "px");
            // this.setState({fullCalendarInnerModal_mobile_banner_height: 0.69});
        } 
    }


    render() {
        // alert(Number("20,000"))
        $(".pageName").text("홈");
        return (
            <div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
                {/* <h4>[Shop] 메인</h4> */}
                <div className="mainDashboardTopBoxParent" style={{display:"flex", flexWrap:"wrap"}}>
                    <div className="mainDashboardTopBox" style={{padding:"1.607vw 1.786vw 1.726vw", border: "1px solid #ebebed", borderRadius: "0.298vw", width:"calc((100% - 3.57vw) / 4)"}}>
                        <div style={{display:"flex", fontWeight: "bold", justifyContent:"space-between"}}>
                            <div className="mobile_txt_893" style={{fontSize:"0.893vw", margin:"auto 0"}}>
                                이번 달에 만든 배너 수
                            </div>
                            <div className="mobile_txt_741 min-w-53" style={{fontSize:"0.714vw", background: "#D9D9DA", color: "white", padding:"5px 10px", margin:"auto 0", borderRadius:"4px"}}>
                                단위: 개
                            </div>
                        </div>
                        <div style={{display:"flex", fontWeight: "bold", marginTop:"0.833vw"}}>
                            <NavLink to="/shop/cafe24/event/list" style={{margin:"auto 0"}}><div className="icn_dashboard_banner" style={{margin:"auto 0"}}></div></NavLink>
                            <div className="mobile_txt_893" style={{color:"#10CFC9", fontSize:"1.548vw", margin:"auto 0 auto 0.893vw"}}>
                                { this.state.eventCntByThisMonth != null
                                    ? this.state.eventCntByThisMonth.event_cnt
                                    : 0
                                }
                            </div>
                        </div>
                        <div style={{display:"flex", fontWeight: "bold", marginTop:"0.833vw"}}>
                            <div className="mobile_txt_774" style={{fontSize: "0.774vw",color: "rgba(51, 51, 51, 0.6)", margin:"auto 0"}}>회원</div>
                            <div className="mobile_txt_893" style={{color:"#10CFC9", fontSize:"0.893vw", margin:"auto 0 auto 0.298vw"}}>
                                { this.state.eventCntByThisMonthFromUser != null
                                    ? this.state.eventCntByThisMonthFromUser.event_cnt
                                    : 0
                                }
                            </div>
                            <div className="mobile_txt_774" style={{fontSize: "0.774vw",color: "rgba(51, 51, 51, 0.6)", margin:"auto 0 auto 0.893vw"}}>비회원</div>
                            <div className="mobile_txt_893" style={{color:"#10CFC9", fontSize:"0.893vw", margin:"auto 0 auto 0.298vw"}}>
                                { this.state.eventCntByThisMonthFromNonUser != null
                                    ? this.state.eventCntByThisMonthFromNonUser.event_cnt
                                    : 0
                                }
                            </div>
                        </div>
                    </div>
                    <div className="mainDashboardTopBox" style={{padding:"1.607vw 1.786vw 1.726vw", border: "1px solid #ebebed", borderRadius: "0.298vw", width:"calc((100% - 3.57vw) / 4)", marginLeft:"1.190vw"}}>
                        <div style={{display:"flex", fontWeight: "bold", justifyContent:"space-between"}}>
                            <div className="mobile_txt_893" style={{fontSize:"0.893vw", margin:"auto 0"}}>
                                지금까지 만든 배너 수
                            </div>
                            <div className="mobile_txt_741 min-w-53" style={{fontSize:"0.714vw", background: "#D9D9DA", color: "white", padding:"5px 10px", margin:"auto 0", borderRadius:"5px"}}>
                                단위: 개
                            </div>
                        </div>
                        <div style={{display:"flex", fontWeight: "bold", marginTop:"0.833vw"}}>
                        <NavLink to="/shop/cafe24/event/list" style={{margin:"auto 0"}}><div className="icn_dashboard_banner" style={{margin:"auto 0"}}></div></NavLink>
                            <div className="mobile_txt_893" style={{color:"#10CFC9", fontSize:"1.548vw", margin:"auto 0 auto 0.893vw"}}>
                                { this.state.eventCnt != null
                                    ? this.state.eventCnt.event_cnt
                                    : 0
                                }
                            </div>
                        </div>
                        <div style={{display:"flex", fontWeight: "bold", marginTop:"0.833vw"}}>
                            <div className="mobile_txt_774" style={{fontSize: "0.774vw",color: "rgba(51, 51, 51, 0.6)", margin:"auto 0"}}>회원</div>
                            <div className="mobile_txt_893" style={{color:"#10CFC9", fontSize:"0.893vw", margin:"auto 0 auto 0.298vw"}}>
                                { this.state.eventCntFromUser != null
                                    ? this.state.eventCntFromUser.event_cnt
                                    : 0
                                }
                            </div>
                            <div className="mobile_txt_774" style={{fontSize: "0.774vw",color: "rgba(51, 51, 51, 0.6)", margin:"auto 0 auto 0.893vw"}}>비회원</div>
                            <div className="mobile_txt_893" style={{color:"#10CFC9", fontSize:"0.893vw", margin:"auto 0 auto 0.298vw"}}>
                                { this.state.eventCntFromNonUser != null
                                    ? this.state.eventCntFromNonUser.event_cnt
                                    : 0
                                }
                            </div>
                        </div>
                    </div>
                    <div className="mainDashboardTopBox" style={{padding:"1.607vw 1.786vw 1.726vw", border: "1px solid #ebebed", borderRadius: "0.298vw", width:"calc((100% - 3.57vw) / 4)", marginLeft:"1.190vw"}}>
                        <div style={{display:"flex", fontWeight: "bold", justifyContent:"space-between"}}>
                            <div className="mobile_txt_893" style={{fontSize:"0.893vw", margin:"auto 0"}}>
                                어제 페이지뷰
                            </div>
                            <div className="mobile_txt_741 min-w-53" style={{fontSize:"0.714vw", background: "#D9D9DA", color: "white", padding:"5px 10px", margin:"auto 0", borderRadius:"5px"}}>
                                단위: 명
                            </div>
                        </div>
                        <div style={{display:"flex", fontWeight: "bold", marginTop:"0.833vw"}}>
                            <div className="icn_users" style={{margin:"auto 0"}}></div>
                            <div className="mobile_txt_893" style={{color:"#10CFC9", fontSize:"1.548vw", margin:"auto 0 auto 0.893vw"}}>
                                { this.state.pageViewCntByToday != null
                                    ? this.state.pageViewCntByToday.eventcall_cnt
                                    : 0
                                }
                            </div>
                        </div>
                        {/* <div style={{display:"flex", fontWeight: "bold", marginTop:"0.833vw", visibility: "hidden"}}>
                            <div style={{fontSize: "0.774vw",color: "rgba(51, 51, 51, 0.6)", margin:"auto 0"}}>회원</div>
                            <div style={{color:"#10CFC9", fontSize:"0.893vw", margin:"auto 0 auto 0.298vw"}}>
                                200
                            </div>
                            <div style={{fontSize: "0.774vw",color: "rgba(51, 51, 51, 0.6)", margin:"auto 0 auto 0.893vw"}}>비회원</div>
                            <div style={{color:"#10CFC9", fontSize:"0.893vw", margin:"auto 0 auto 0.298vw"}}>
                                200
                            </div>
                        </div> */}
                    </div>
                    <div className="mainDashboardTopBox" style={{padding:"1.607vw 1.786vw 1.726vw", border: "1px solid #ebebed", borderRadius: "0.298vw", width:"calc((100% - 3.57vw) / 4)", marginLeft:"1.190vw"}}>
                        <div style={{display:"flex", fontWeight: "bold", justifyContent:"space-between"}}>
                            <div className="mobile_txt_893" style={{fontSize:"0.893vw", margin:"auto 0"}}>
                                이번 달 페이지뷰
                            </div>
                            <div className="mobile_txt_741 min-w-53" style={{fontSize:"0.714vw", background: "#D9D9DA", color: "white", padding:"5px 10px", margin:"auto 0", borderRadius:"5px"}}>
                                단위: 명
                            </div>
                        </div>
                        <div style={{display:"flex", fontWeight: "bold", marginTop:"0.833vw"}}>
                            <div className="icn_users" style={{margin:"auto 0"}}></div>
                            <div className="mobile_txt_893" style={{color:"#10CFC9", fontSize:"1.548vw", margin:"auto 0 auto 0.893vw"}}>
                                { this.state.pageViewCntByMonth != null
                                    ? this.state.pageViewCntByMonth.eventcall_cnt
                                    : 0
                                }
                            </div>
                        </div>
                        <div style={{display:"flex", fontWeight: "bold", marginTop:"0.833vw"}}>
                            <div className="mobile_txt_774" style={{fontSize: "0.774vw",color: "rgba(51, 51, 51, 0.6)", margin:"auto 0"}}>
                                {
                                    this.state.overChargeStandard != null
                                    ? "/ "+this.state.overChargeStandard.over_charge_standard
                                    : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>


                <div className="mainMySettingBox"
                style={{display:"flex", justifyContent:"center", padding:"1.250vw 0"
                // , marginTop:"2.976vw"
                , marginTop:"40px"
                , border: "1px solid #ebebed", borderRadius: "0.298vw"}}>
                    <div className="mobile_txt_774" style={{display:"flex", margin:"auto", fontSize:"0.774vw", fontWeight: "bold"}}>
                        <NavLink to="/shop/cafe24/event/setting/list" style={{color: "#10CFC9", margin:"auto 0", minWidth:"8vw"}}>
                            <div className="mainMySettingBox_first" style={{color: "#10CFC9", margin:"auto 0", minWidth:"8vw"}}>
                                내 설정
                            </div> 
                        </NavLink>
                        {
                            this.state.eventSettingDescription
                            ?   <div style={{display:"flex", flexWrap:"wrap", justifyContent: "center"}}>
                                    <div style={{margin: "auto 0 auto 3.095vw"}}>
                                        현재
                                    </div>
                                    <div className="mobile_txt_893" style={{margin: "auto 0 auto 0.893vw", background: "#F5F5F7", borderRadius: "5.952vw", padding: "0.595vw 0.893vw"}}>
                                        {this.state.eventSettingDescription.event_item_user_type}
                                    </div>
                                    <div style={{margin: "auto 0 auto 0.298vw"}}>
                                        으로
                                    </div>
                                    <div className="mobile_txt_893" style={{margin: "auto 0 auto 0.893vw", background: "#F5F5F7", borderRadius: "5.952vw", padding: "0.595vw 0.893vw"}}>
                                        {this.state.eventSettingDescription.event_cycle}
                                    </div>
                                    <div style={{margin: "auto 0 auto 0.298vw"}}>
                                        로
                                    </div>
                                    <div className="mobile_txt_893" style={{margin: "auto 0 auto 0.893vw", background: "#F5F5F7", borderRadius: "5.952vw", padding: "0.595vw 0.893vw"}}>
                                        {this.state.eventSettingDescription.event_item_analysis_period}
                                    </div>
                                    <div style={{margin: "auto 0 auto 0.298vw"}}>
                                        으로
                                    </div>
                                    <div className="mobile_txt_893" style={{margin: "auto 0 auto 0.893vw", background: "#F5F5F7", borderRadius: "5.952vw", padding: "0.595vw 0.893vw"}}>
                                        {"자동배너설정"}
                                    </div>
                                    <div style={{margin: "auto 0 auto 0.298vw"}}>
                                        적용중입니다.
                                    </div>
                                </div>
                            :   <div style={{margin: "auto 0 auto 3.095vw"}}>
                                    배너 설정을 추가해 보세요. 설정한 값에 따라 자동으로 배너가 생성됩니다.
                                </div>
                        }
                    </div>
                </div>

                {/* <div className="fullCalendarArea" style={{marginTop:"2.976vw"}}> */} 
                <div className="fullCalendarArea" style={{visibility:"hidden", maxHeight:"0"}}>
                    <FullCalendar
                        timeZone= 'local'
                        plugins={[ dayGridPlugin ]}
                        initialView="dayGridMonth"
                        locale='ko'
                        events={this.state.fullCalendarEventList}
                        eventClick={this.onClickeFullCalendarEvent}
                        contentHeight='auto'
                    />
                </div>

                <div style={{display:"flex", flexWrap:"wrap" , marginTop:"2.976vw"}}>
                    {/* <div className="mainDashboardBottomBox" style={{display: 'flex', justifyContent: 'center', border: "1px solid #ebebed", borderRadius: "0.298vw", width:"calc((100% - 3.57vw) / 4)", height:"5.357vw"}}>
                        <div style={{display:"flex", fontWeight: "bold", justifyContent:"space-between"}}>
                            <div className="mobile_txt_741" style={{fontSize:"0.8vw", margin:"auto 0"}}>
                            <b>로보MD와 함께<br/>
                            즐거운 배너 관리를 시작해 보세요.</b>
                            </div>
                        </div>
                    </div> */}
                    <div className="cursor-pointer mainDashboardBottomBox" style={{border: "0px solid #ebebed", borderRadius: "0.298vw", width:"calc((100% - 3.57vw) / 4)", height:"5.357vw"}}>
                    <a 
                        href="https://iclave.notion.site/MD-10e5e04c0372403581d736a1a95f4553"
                        style={{display:"flex", fontWeight: "bold", height: "100%"}} target="_blank">
                            {/* <div style={{display:"flex", fontWeight: "bold", height: "100%"}}> */}
                                <div className="mainDashboardBottomBox_inner_left" style={{background: "#58DDD9", width:"4.167vw", height: "100%", justifyContent: "center", display: "flex"}}>
                                    <div className="icn_circle">
                                        <div style={{alignSelf:"center", color:"white"}}>i</div>
                                    </div>
                                </div>
                                <div style={{background: "white", flex:"1", height: "100%", paddingLeft:"1.190vw", color:"#58DDD9", border:"#58ddd9 solid 2px",display: "inline-flex"}}>
                                    <div className="mobile_txt_893" style={{margin:"auto 0", fontSize:"0.893vw", fontWeight:"bold"}}>사용자 가이드 보기</div>
                                </div>
                            {/* </div> */}
                    </a>
                    </div>
                    <div className="cursor-pointer mainDashboardBottomBox" style={{border: "0px solid #ebebed", borderRadius: "0.298vw", width:"calc((100% - 3.57vw) / 4)", marginLeft:"1.190vw", height:"5.357vw"}}>
                    <NavLink 
                        to="/shop/cafe24/event/form"
                        style={{display:"flex", fontWeight: "bold", height: "100%"}}>
                            {/* <div style={{display:"flex", fontWeight: "bold", height: "100%"}}> */}
                                <div className="mainDashboardBottomBox_inner_left" style={{background: "#58DDD9", width:"4.167vw", height: "100%", justifyContent: "center", display: "flex"}}>
                                    <div className="icn_cursor_pointer"></div>
                                </div>
                                <div style={{background: "#10CFC9", flex:"1", height: "100%", paddingLeft:"1.190vw", color:"white",display: "inline-flex"}}>
                                    <div className="mobile_txt_893" style={{margin:"auto 0", fontSize:"0.893vw"}}>로보 배너 등록</div>
                                </div>
                            {/* </div> */}
                    </NavLink>
                    </div>
                    <div className="cursor-pointer mainDashboardBottomBox" style={{border: "0px solid #ebebed", borderRadius: "0.298vw", width:"calc((100% - 3.57vw) / 4)", marginLeft:"1.190vw", height:"5.357vw"}}>
                        <NavLink 
                            to="/shop/cafe24/promotion/form"
                            style={{display:"flex", fontWeight: "bold", height: "100%"}}>
                            {/* <div style={{display:"flex", fontWeight: "bold", height: "100%"}}> */}
                                <div className="mainDashboardBottomBox_inner_left" style={{background: "#58DDD9", width:"4.167vw", height: "100%", justifyContent: "center", display: "flex"}}>
                                    <div className="icn_photo_white"></div>
                                </div>
                                <div style={{background: "#10CFC9", flex:"1", height: "100%", paddingLeft:"1.190vw", color:"white",display: "inline-flex"}}>
                                    <div className="mobile_txt_893" style={{margin:"auto 0", fontSize:"0.893vw"}}>마이 배너 등록</div>
                                </div>
                            {/* </div> */}
                        </NavLink>
                    </div>
                    <div className="cursor-pointer mainDashboardBottomBox" style={{border: "0px solid #ebebed", borderRadius: "0.298vw", width:"calc((100% - 3.57vw) / 4)", marginLeft:"1.190vw", height:"5.357vw"}}>
                        <NavLink 
                            to="/shop/cafe24/event/setting/list"
                            style={{display:"flex", fontWeight: "bold", height: "100%"}}>
                            {/* <div style={{display:"flex", fontWeight: "bold", height: "100%"}}> */}
                                <div className="mainDashboardBottomBox_inner_left" style={{background: "#58DDD9", width:"4.167vw", height: "100%", justifyContent: "center", display: "flex"}}>
                                    <div className="icn_setting_white"></div>
                                </div>
                                <div style={{background: "#10CFC9", flex:"1", height: "100%", paddingLeft:"1.190vw", color:"white",display: "inline-flex"}}>
                                    <div className="mobile_txt_893" style={{margin:"auto 0", fontSize:"0.893vw"}}>자동 배너 설정</div>
                                </div>
                            {/* </div> */}
                        </NavLink>
                    </div>
                </div>

                <div className="flexBox eventSettingItemArea mobile_txt_741" style={{height:"70px" , marginTop:"1.2vw", marginBottom:"1.2vw", fontSize:"0.8vw"}}>
                    <div className="margin-vertical-auto eventSettingTitle" style={{width:"auto", lineHeight:"2rem"}}>
                        <label style={{marginTop: "auto", marginBottom: "auto"}}><b>배너용 토큰</b></label>
                        <div className="fa fa-question-circle margin-l-10">
                            <div className="question-hover-area">배너 삽입시 사용하는 토큰 입니다.</div>
                        </div>
                    </div>
                    <div className="flexBox margin-vertical-auto input_banner_token" style={{flex:"1", marginLeft: "2%",marginRight: "calc(4% - 30px)", alignItems: "center", justifyContent: "center"}}>
                        <input className="mobile_txt_741" id="user_token" type="text" value={this.state.user_token} style={{flex:"1", borderRadius: "8px", fontSize:"0.8vw"}} tabIndex='-1' disabled/>
                        <CopyToClipboard id="copy_user_token" text={this.state.user_token} style={{ width: "5.762vw" , marginLeft: "15px", height: "100%", fontSize: "0.8vw", display: "flex", alignItems: "center", justifyContent: "center", padding: "0", borderRadius: "8px" }}>
                            <button className="btn-auto-b" >복사</button>
                        </CopyToClipboard>
                    </div>
                </div>    
                {/* <div style={{display:"flex", flexWrap: "wrap", width:"100%"}}>
                    <div className="mainDashBox" style={{width:"48%", margin:"10px"}}>
                        <div style={{overflow: "auto", marginBottom:"10px"}}><div style={{float:"left"}}>공지사항</div><div style={{float:"right"}}><Link to="/shop/notice/list">더보기</Link></div></div>
                        <div className="shadow-box" style={{padding:"5px"}}>
                            {this.state.notice_list.map(notice => (
                                <div key={notice.notice_seq}>
                                    <div key={notice.notice_seq} style={{overflow: "hidden",fontSize:"13px"}}>
                                        <div style={{float: "right",display:"flex", fontSize:"13px"}}>
                                            <div style={{color:"#bbbbbb"}}>
                                                <p className="card-text">{notice.user_name}</p>
                                            </div>
                                            <div style={{marginLeft:"10px", textAlign:"right", color:"#bbbbbb", fontSize:"13px"}}> 
                                                <p className="card-text">{notice.reg_date}</p>
                                            </div>
                                        </div>
                                        <div className="mainBoardTitle">
                                            <p id="title" style={{textOverflow: "ellipsis", overflow: "hidden"}} className="card-text cursor-pointer" onClick={() => this.handleNoticeClick(notice.notice_seq)}>{notice.title}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div> 
                    <div className="mainDashBox" style={{width:"48%", margin:"10px"}}>
                        <div style={{overflow: "auto", marginBottom:"10px"}}><div style={{float:"left"}}>나의 QnA</div><div style={{float:"right"}}><Link to="/shop/qna/list">더보기</Link></div></div>
                        <div className="shadow-box" style={{padding:"5px"}}>
                            {this.state.qna_list.map(qna => (
                                <div key={qna.qna_seq}>
                                    <div key={qna.qna_seq} style={{overflow: "hidden"}}>
                                        <div style={{float: "right",display:"flex", fontSize:"13px"}}>
                                            <div style={{color:"#bbbbbb", fontSize:"13px"}}>
                                                <p className="card-text">{qna.company_name}</p>
                                            </div>
                                            <div style={{marginLeft:"10px", textAlign:"right", color:"#bbbbbb", fontSize:"13px"}}> 
                                                <p className="card-text">{qna.reg_date}</p>
                                            </div>
                                        </div>
                                        <div className="mainBoardTitle">
                                            <p style={{textOverflow: "ellipsis", overflow: "hidden"}}  id="title" className="card-text cursor-pointer" onClick={() => this.handleQnaClick(qna.qna_seq)}>{qna.title}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div> 
                    <div className="mainDashBox" style={{width:"48%", margin:"10px"}}>
                        <div style={{overflow: "auto", marginBottom:"10px"}}><div style={{float:"left"}}>최근 배너 클릭 수</div><div style={{float:"right"}}><Link to="/shop/cafe24/event/state">더보기</Link></div></div>
                        <div className="shadow-box mainDashBoxState" >
                        <Line key={0} data={this.state.eventData} options={this.state.options}/> 
                        </div>
                    </div> 
                    <div className="mainDashBox" style={{width:"48%", margin:"10px"}}>
                        <div style={{overflow: "auto", marginBottom:"10px"}}><div style={{float:"left"}}>최근 상품 판매 수</div><div style={{float:"right"}}><Link to="/shop/cafe24/event/state">더보기</Link></div></div>
                        <div className="shadow-box mainDashBoxState">
                        <Line key={2} data={this.state.saleData} options={this.state.options}/> 
                        </div>
                    </div> 
                </div> */}

                {/* 배너 미리보기 팝업 */}
                <Draggable cancel=".fullCalendarModalClose">
                    <div className="fullCalendarModal">
                        <div className="fullCalendarInnerModal">
                            <div className="flexBox width-100">
                                <div class="mobileBanner" style={{pointerEvents:"none", display: "none"}}>

                                </div>
                                <div class="pcBanner" style={{pointerEvents:"none", display: "none"}}>

                                </div>
                            </div>
                            {/*<div className="fa fa-close fullCalendarModalClose" onClick={this.onClickFullCalendarModalClose}></div>*/}
                        </div>
                    </div>
                </Draggable>
            </div>
        )
    };
}

import React, { useCallback, useState } from 'react';
import CheckBox from './common/CheckBox';
import Alert from './common/Alert';
import Button from './common/Button';
import PopupFrameV2 from './popup/PopupFrameV2';
import Tooltip from './common/Tooltip';
import ConfirmDialogV2 from './dialogs/ConfirmDialogV2';
import styles from './ModuleGuide.module.css';
import BasicDialogV2 from './dialogs/BasicDialogV2';
import SaveDialogV2 from './dialogs/SaveDialogV2';
import TitleContentDialogV2 from './dialogs/TitleContentDialogV2';
import WarningDialog from './dialogs/WarningDialog';
import ToggleButton from './common/ToggleButton';
import RadioButton from './common/RadioButton';
import HomePageHeader from './header/HomePageHeader';
import SimpleHeader from './header/SimpleHeader';

import Select from 'react-select';
import {reactSelectStyles} from '../modules/libraries/react-select';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

export class ModuleGuide extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
            fruit_list : [
                {
                    name : "사과",
                    value : 1,
                    color : "#FF4646",
                },
                {
                    name : "포도",
                    value : 2,
                    color : "#A267C7",
                },
                {
                    name : "오렌지",
                    value : 3,
                    color : "#FF781A",
                },
                {
                    name : "복숭아",
                    value : 4,
                    color : "#FFB2F3",
                },
            ],

            fruit_select_radio : '', // 라디오 버튼으로 선택된 값
            fruit_check_list : [], // 체크 박스로 선택된 값
        }
    }

    componentDidMount(){
        
    }

    componentDidUpdate(){
        // 화면 스크롤 고정 : 여러개의 팝업 중 하나라도 보여지는 상태라면 스크롤 고정
        if(this.state.show_popup_frame
            || this.state.show_confirm_dialog
            || this.state.show_basic_dialog
            || this.state.show_save_dialog
            || this.state.show_save_loading_dialog) {
            document.body.style.overflow = 'hidden'; // 스크롤 고정
        } else {
            document.body.style.overflow = 'auto'; // 스크롤 고정 해제
        }
    }

    /**
     * 여러 항목을 선택하는 체크 박스 : 전체 수정
     * @param {*} e 체크박스 이벤트
     */
    allCheckboxChange = (e) => {
        let {fruit_list} = this.state;
        const checked = e.target.checked;

        if(checked){
            this.setState({ fruit_check_list : JSON.parse(JSON.stringify(fruit_list)) });
        } else{
            this.setState({ fruit_check_list : [] });
        }
    }
    /**
     * 여러 항목을 선택하는 체크 박스 : 단일 수정
     * @param {*} e 체크박스 이벤트
     * @param {*} value 리스트 키 값
     */
    checkboxChange = (e, value) => {
        let {fruit_list, fruit_check_list} = this.state;
        const checked = e.target.checked;

        if(checked){ // 체크되는 상태라면 기존 체크 리스트에 값 추가
            this.setState({ fruit_check_list : [...fruit_check_list, fruit_list.find(obj => obj.value == value)] });
        } else{ // 체크가 해제되는 상태라면 기존 체크 리스트에서 값 제거
            this.setState({ fruit_check_list : fruit_check_list.filter(obj => obj.value != value) });
        }
    }

    handleToggle = (state, autoCloseSecond) => {
        this.setState({
            [state] : !this.state[state]
        }, () => {
            if(autoCloseSecond){
                setTimeout(() => {this.setState({ [state] : !this.state[state] })}, autoCloseSecond*1000)
            }
        });
    }

    handleChange = (state, value) => {
        this.setState({[state] : value});
    }

    render() {
        const {fruit_list, fruit_check_list} = this.state;
        return (
            <>
                <div id='common-page-wrapper'>
                    <div className={styles.page_wrapper}>
                        <div className={styles.content}>일반 페이지나 스타일 변경 없이 사용되는 컴포넌트의 경우 클래스명 중복을 피하기 위해 CSS Module(xxx.module.css)를 사용하지만<br/>스타일 변경이 잦은 컴포넌트는 일반 CSS로 구성하고 있습니다.</div>
                        {/* 라디오 버튼 */}
                        <div className={styles.title}>라디오 버튼</div>
                        <div className={styles.list_area}>
                            <table className={styles.list}>
                                <thead>
                                    <tr>
                                        <th>설명</th>
                                        <th>예시</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className={styles.list_row}>
                                        <td>라디오 버튼</td>
                                        <td>
                                            <RadioButton
                                                className={styles.radio_button}
                                                checked={this.state.radioButton}
                                                onChangeCheck={e => {
                                                    this.setState({radioButton : e.target.checked});
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr className={styles.list_row}>
                                        <td>비활성화 : 체크</td>
                                        <td>
                                            <RadioButton
                                                className={styles.radio_button}
                                                checked={true}
                                                disabled={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr className={styles.list_row}>
                                        <td>비활성화 : 체크 안함</td>
                                        <td>
                                            <RadioButton
                                                className={styles.radio_button}
                                                checked={false}
                                                disabled={true}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className={styles.title}>여러 항목 중 선택하는 라디오 버튼</div>
                        <div className={styles.radio_button_list}>
                            {fruit_list.map((obj,idx) => (
                                <RadioButton
                                    checked={this.state.fruit_select_radio == obj.value}
                                    onChangeCheck={() => this.setState({fruit_select_radio : obj.value})}
                                >
                                    <div className={styles.radio_button_text}>{obj.name}</div>
                                </RadioButton>
                            ))}
                        </div>

                        {/* 체크 박스 */}
                        <div className={styles.title}>체크 박스</div>
                        <div className={styles.list_area}>
                            <table className={styles.list}>
                                <thead>
                                    <tr>
                                        <th>설명</th>
                                        <th>예시</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className={styles.list_row}>
                                        <td>체크 박스</td>
                                        <td>
                                            <CheckBox
                                                checked={this.state.checkbox}
                                                onChangeCheck={e => {
                                                    this.setState({checkbox : e.target.checked});
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr className={styles.list_row}>
                                        <td>비활성화 : 체크</td>
                                        <td>
                                            <CheckBox
                                                checked={true} // 체크
                                                disabled={true} // 비활성화
                                            />
                                        </td>
                                    </tr>
                                    <tr className={styles.list_row}>
                                        <td>비활성화 : 체크 안함</td>
                                        <td>
                                            <CheckBox
                                                checked={false} // 체크하지 않음
                                                disabled={true} // 비활성화
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className={styles.title}>여러 항목을 선택하는 체크 박스</div>
                        <div className={styles.list_area}>
                            <table className={styles.list}>
                                <thead>
                                    <tr>
                                        <th>
                                            <CheckBox
                                                checked={fruit_check_list.length == fruit_list.length}
                                                onChangeCheck={e => this.allCheckboxChange(e)}
                                            />
                                        </th>
                                        <th>과일 이름</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fruit_list.map((obj,idx) => (
                                        <tr className={styles.list_row} key={obj.value}>
                                            <td>
                                                <CheckBox
                                                    checked={fruit_check_list.find(fruit => fruit.value == obj.value) != null}
                                                    onChangeCheck={e => this.checkboxChange(e, obj.value)}
                                                />
                                            </td>
                                            <td>{obj.name}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <Alert className={styles.check_alert} text={"value props는 e.target.value로 값을 뽑아오고 싶을때 사용해도 좋을것같습니다 (참고로 이것은 Alert 컴포넌트입니다)"}/>

                        {/* 셀렉트박스 */}
                        
                        <div className={styles.title}>셀렉트박스 라이브러리</div>
                        <div className={styles.content}><a href={"https://react-select.com/home"}>react-select 공식 문서</a>에서 더욱 다양한 활용 방법을 찾아볼 수 있습니다.</div>
                        <div className={styles.select_area}>
                            <Select
                                className={"react-select"}
                                components={animatedComponents}
                                placeholder={"과일을 선택해 주세요"}
                                noOptionsMessage={() => "없음"}
                                defaultValue={this.state.fruit_list[0]}
                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option) => option.name}
                                options={this.state.fruit_list}
                                // onChange={this.handleChange}
                                // value={this.state.selectedOption}
                                styles={reactSelectStyles()}
                            />
                            <Select
                                className={"react-select"}
                                components={animatedComponents}
                                placeholder={"과일을 선택해 주세요"}
                                noOptionsMessage={() => "없음"}
                                defaultValue={this.state.fruit_list[0]}
                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option) => option.name}
                                options={this.state.fruit_list}
                                isDisabled={true}
                                // onChange={this.handleChange}
                                // value={this.state.selectedOption}
                                styles={reactSelectStyles()}
                            />
                            <Select
                                className={"react-select"}
                                defaultValue={this.state.fruit_list}
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                placeholder={"과일을 여러개 선택해 주세요"}
                                noOptionsMessage={() => "없음"}
                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option) => option.name}
                                options={this.state.fruit_list}
                                // onChange={this.handleChange}
                                // value={this.state.selectedOption}
                                styles={reactSelectStyles()}
                            />
                            <Select
                                className={"react-select disabled"}
                                defaultValue={this.state.fruit_list}
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                isDisabled={true}
                                placeholder={"과일을 여러개 선택해 주세요"}
                                noOptionsMessage={() => "없음"}
                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option) => option.name}
                                options={this.state.fruit_list}
                                // onChange={this.handleChange}
                                // value={this.state.selectedOption}
                                styles={reactSelectStyles()}
                            />
                            <Select
                                className={"react-select error"}
                                defaultValue={this.state.fruit_list}
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                placeholder={"과일을 여러개 선택해 주세요"}
                                noOptionsMessage={() => "없음"}
                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option) => option.name}
                                options={this.state.fruit_list}
                                // onChange={this.handleChange}
                                // value={this.state.selectedOption}
                                styles={reactSelectStyles()}
                            />
                            <Select
                                className={"react-select"}
                                defaultValue={this.state.fruit_list}
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                placeholder={"과일을 여러개 선택해 주세요"}
                                noOptionsMessage={() => "없음"}
                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option) => option.name}
                                options={this.state.fruit_list}
                                // onChange={this.handleChange}
                                // value={this.state.selectedOption}
                                styles={reactSelectStyles({optionColorName: "color", height: "48px"})}
                            />
                        </div>

                        {/* 버튼 */}
                        
                        <div className={styles.title}>버튼</div>
                        <div className={styles.list_area}>
                            <table className={[styles.list, styles.button_table].join(' ')}>
                                <thead>
                                    <tr>
                                        <th>설명</th>
                                        <th>예시</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className={styles.list_row}>
                                        <td>hover_mint</td>
                                        <td>
                                            <div className={styles.button_list}>
                                                <Button
                                                    type={"hover_mint"}
                                                    width={"140px"}
                                                    onClick={() => {alert("버튼을 클릭하셨습니다.")}}
                                                >
                                                    기본 버튼
                                                </Button>
                                                <Button
                                                    type={"hover_mint"}
                                                    disabled={true}
                                                    width={"160px"}
                                                >
                                                    비활성화
                                                </Button>
                                                <Button
                                                    type={"hover_mint"}
                                                    width={"160px"}
                                                >
                                                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                        <svg style={{marginRight:"4px"}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M21 15V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V15ZM19.5 4.5V13.1906L16.8094 10.5C16.527 10.2217 16.1464 10.0658 15.75 10.0658C15.3536 10.0658 14.973 10.2217 14.6906 10.5L10.5 14.6906L8.55938 12.75C8.27698 12.4717 7.89645 12.3158 7.5 12.3158C7.10355 12.3158 6.72302 12.4717 6.44062 12.75L4.5 14.6906V4.5H19.5ZM19.5 19.5H4.5V16.8094L7.5 13.8094L9.44062 15.75C9.72302 16.0283 10.1036 16.1842 10.5 16.1842C10.8964 16.1842 11.277 16.0283 11.5594 15.75L15.75 11.5594L19.5 15.3094V19.5ZM8.57812 9.42188C8.47332 9.31744 8.39034 9.19321 8.33401 9.0564C8.27768 8.91959 8.24912 8.77295 8.25 8.625C8.25 8.32663 8.36853 8.04048 8.5795 7.8295C8.79048 7.61853 9.07663 7.5 9.375 7.5C9.67337 7.5 9.95952 7.61853 10.1705 7.8295C10.3815 8.04048 10.5 8.32663 10.5 8.625C10.5 8.92337 10.3815 9.20952 10.1705 9.4205C9.95952 9.63147 9.67337 9.75 9.375 9.75C9.07695 9.74772 8.79136 9.63013 8.57812 9.42188Z" fill="#333333"/>
                                                        </svg>
                                                        이미지 버튼
                                                    </div>
                                                </Button>
                                                <Button
                                                    type={"hover_mint"}
                                                    width={"60px"}
                                                    height={"22px"}
                                                    fontSize={"12px"}
                                                >
                                                    작은 버튼
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className={styles.list_row}>
                                        <td>hover_dark</td>
                                        <td>
                                            <div className={styles.button_list}>
                                                <Button
                                                    type={"hover_dark"}
                                                    width={"140px"}
                                                >
                                                    기본 버튼
                                                </Button>
                                                <Button
                                                    type={"hover_dark"}
                                                    disabled={true}
                                                    width={"160px"}
                                                >
                                                    비활성화
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className={styles.list_row}>
                                        <td>hover_blue</td>
                                        <td>
                                            <div className={styles.button_list}>
                                                <Button
                                                    type={"hover_blue"}
                                                    width={"140px"}
                                                >
                                                    기본 버튼
                                                </Button>
                                                <Button
                                                    type={"hover_blue"}
                                                    disabled={true}
                                                    width={"160px"}
                                                >
                                                    비활성화
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className={styles.list_row}>
                                        <td>hover_grey</td>
                                        <td>
                                            <div className={styles.button_list}>
                                                <Button
                                                    type={"hover_grey"}
                                                    width={"140px"}
                                                >
                                                    기본 버튼
                                                </Button>
                                                <Button
                                                    type={"hover_grey"}
                                                    disabled={true}
                                                    width={"160px"}
                                                >
                                                    비활성화
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className={styles.list_row}>
                                        <td>hover_white</td>
                                        <td>
                                            <div className={styles.button_list}>
                                                <Button
                                                    type={"hover_white"}
                                                    width={"140px"}
                                                >
                                                    기본 버튼
                                                </Button>
                                                <Button
                                                    type={"hover_white"}
                                                    disabled={true}
                                                    width={"160px"}
                                                >
                                                    비활성화
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className={styles.list_row}>
                                        <td>hover_transparent</td>
                                        <td>
                                            <div className={styles.button_list}>
                                                <Button
                                                    type={"hover_transparent"}
                                                    width={"140px"}
                                                >
                                                    기본 버튼
                                                </Button>
                                                <Button
                                                    type={"hover_transparent"}
                                                    disabled={true}
                                                    width={"160px"}
                                                >
                                                    비활성화
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        {/* 팝업 프레임 */}
                        
                        <div className={styles.title}>팝업 / 다이얼로그</div>
                        <div className={styles.popup_frame_button_area}>
                            <Button type={"hover_white"} onClick={() => this.handleToggle("show_popup_frame")}>프레임 열기</Button>
                            <Button type={"hover_white"} width={160} onClick={() => this.handleToggle("show_confirm_dialog")}>confirm 다이얼로그</Button>
                            <Button type={"hover_white"} width={160} onClick={() => this.handleToggle("show_basic_dialog")}>basic 다이얼로그</Button>
                            <Button type={"hover_white"} width={160} onClick={() => this.handleToggle("show_save_dialog")}>save 다이얼로그</Button>
                            <Button type={"hover_white"} width={160} onClick={() => this.handleToggle("show_save_loading_dialog", 2)}>loading 다이얼로그</Button>
                            <Button type={"hover_white"} width={160} onClick={() => this.handleToggle("show_save_title_content_dialog")}> 제목/내용 다이얼로그</Button>
                            <Button type={"hover_white"} width={160} onClick={() => this.handleToggle("show_save_warning_dialog")}> warning 다이얼로그</Button>
                        </div>

                        {/* 토글 */}

                        <div className={styles.title}>토글</div>
                        <div className={styles.list_area}>
                            <table className={styles.list}>
                                <thead>
                                    <tr>
                                        <th>설명</th>
                                        <th>예시</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className={styles.list_row}>
                                        <td>토글</td>
                                        <td>
                                            <ToggleButton checked={this.state.toggle} onClickOption={(e) => {this.setState({toggle : e.target.checked})}}/>
                                        </td>
                                    </tr>
                                    <tr className={styles.list_row}>
                                        <td>작은 토글</td>
                                        <td>
                                            <ToggleButton className={"small"} checked={this.state.toggle2} onClickOption={(e) => {this.setState({toggle2 : e.target.checked})}}/>
                                        </td>
                                    </tr>
                                    <tr className={styles.list_row}>
                                        <td>비활성화 : 체크</td>
                                        <td>
                                            <ToggleButton checked={true} disabled={true}/>
                                        </td>
                                    </tr>
                                    <tr className={styles.list_row}>
                                        <td>비활성화 : 체크 안함</td>
                                        <td>
                                            <ToggleButton checked={false} disabled={true}/>
                                        </td>
                                    </tr>
                                    <tr className={styles.list_row}>
                                        <td>애니메이션 효과 X</td>
                                        <td>
                                            <ToggleButton toggleAnimation={false} checked={this.state.toggle3} onClickOption={(e) => {this.setState({toggle3 : e.target.checked})}}/>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        {/* 알럿 */}

                        <div className={styles.title}>알럿</div>
                        <Alert text={"기본 알럿은 보여질 text값만 넘겨주면 사용 가능합니다."}/>
                        <Alert type={"warning"} text={"경고의 의미가 담긴 알럿은 type을 warning으로 설정한 후 사용합니다."}/>

                        {/* 툴팁 */}

                        <div className={styles.title}>툴팁</div>
                        <div className={styles.content}>squareMoveLeft 및 squareMoveRight 속성을 통해 콘텐츠 박스 위치를 옮길 수 있습니다.</div>
                        <div className={styles.tooltip_area}>
                            <Tooltip squareMoveRight={40} content={"툴팁에는 원하는 내용을 작성하여 넣을 수 있습니다."}/>
                            <Tooltip iconWidth={16} iconHeight={16} content={"툴팁에는 원하는 내용을 작성하여 넣을 수 있습니다."}/>
                            <Tooltip squareMoveLeft={40} content={"툴팁에는 원하는 내용을 작성하여 넣을 수 있습니다."}/>
                        </div>

                        {/* 헤더 */}

                        <div className={styles.title}>헤더</div>
                        <div className={styles.header_area}>
                            <SimpleHeader />
                            <HomePageHeader />
                        </div>


                        {this.state.show_popup_frame &&
                            <PopupFrameV2
                                maxWidth={"600px"}
                                padding={"40px"}
                                onClose={() => this.handleToggle("show_popup_frame")}
                            >
                                <div className={[styles.title, styles.popup_frame_title].join(' ')}>팝업 프레임</div>
                                <div className={styles.content}>팝업 프레임 태그 내부로는 팝업 내에 들어갈 요소를 추가할 수 있습니다.</div>
                                <div className={styles.content}>팝업의 가로 길이를 maxWidth로 조절하여 사용하고 있습니다.</div>
                                <div className={styles.content}>팝업 프레임의 여백을 padding으로 조절하여 사용하고 있습니다.</div>
                                <div className={styles.content}>팝업 프레임이 기본으로는 화면 중앙에 위치하도록 하고 팝업 높이와 화면 높이의 차이값이 80px 미만이 될 경우 팝업이 상단으로 붙고 스크롤 가능하도록 개발할 예정입니다.</div>
                                <div style={{display:"flex", justifyContent: "center", marginTop: "30px"}}>
                                    <Button onClick={() => this.handleToggle("show_popup_frame")}>닫기</Button>
                                </div>
                            </PopupFrameV2>
                        }
                        {this.state.show_confirm_dialog &&
                            <ConfirmDialogV2
                                content={"confirm 다이얼로그"}
                                button_list={[{
                                    // 확인으로 사용될 버튼 (오른쪽)
                                    title : "확인",
                                    event_method : () => { alert("확인 버튼을 누르셨습니다.") }
                                },{
                                    title : "아니요",
                                    event_method : () => { this.handleToggle("show_confirm_dialog") }
                                }]}
                                popup_close={() => this.handleToggle("show_confirm_dialog")}
                            />
                        }
                        {this.state.show_basic_dialog &&
                            <BasicDialogV2
                                content={"basic 다이얼로그"}
                                button_list={[{
                                    title : "확인",
                                    event_method : () => { this.handleToggle("show_basic_dialog") }
                                }]}
                            />
                        }
                        {this.state.show_save_dialog &&
                            <SaveDialogV2
                                content={"save 다이얼로그"}
                                button_list={[{
                                    title : "확인",
                                    event_method : () => { this.handleToggle("show_save_dialog") }
                                }]}
                            />
                        }
                        {this.state.show_save_loading_dialog &&
                            <SaveDialogV2
                                content={"save loading 다이얼로그"}
                                button_list={[{
                                    title : "확인",
                                    display_yn : "N",
                                    event_method : () => { this.handleToggle("show_save_loading_dialog") }
                                }]}
                            />
                        }
                        {this.state.show_save_title_content_dialog &&
                            <TitleContentDialogV2
                                title={"제목 입력"}
                                content={"title content 다이얼로그"}
                                button_list={[{
                                    title : "확인",
                                    event_method : () => { alert("확인 버튼을 누르셨습니다.") }
                                },{
                                    title : "취소",
                                    event_method : () => { this.handleToggle("show_save_title_content_dialog") }
                                }]}
                                popup_close={() => this.handleToggle("show_save_title_content_dialog")}
                            />
                        }
                        {this.state.show_save_warning_dialog &&
                            <WarningDialog
                                content={"warning 다이얼로그"}
                                button_list={[{
                                    title : "확인",
                                    event_method : () => { this.handleToggle("show_save_warning_dialog") }
                                }]}
                                popup_close={() => this.handleToggle("show_save_warning_dialog")}
                            />
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default ModuleGuide;
import React from 'react';
import http from './modules/api';
import { Link } from 'react-router-dom';
import '../css/style.css';
import $ from 'jquery';
import queryString from 'query-string';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-78553461-8');
var ga_label = sessionStorage.getItem("userName");
/**
 * 최초 접속 / 간단한 소개 페이지
 * 	1. 서비스 안내 신청
 */
export class KoContact extends React.Component {
	
	state = {
		email: '',			/*이메일*/
		tel_no: '',			/*연락처*/
		corp_name: '', 		/*업체명*/
		user_name: '', 		/*담당자명*/
		platform_type: '',  /*플랫폼 유형*/
		site_url: '', 		/*쇼핑몰 주소*/
		type: '',   		/*문의유형*/
		title: '',   		/*제목*/
		description: '', 	/*내용*/
		refferer: '',		/*이전 방문 사이트*/
		files: [],
		options: {
					platform_type:[{cd_code:'-', cd_desc:'없음'}],
					type: [{cd_code:'-', cd_desc:'없음'}]
				 }
	}
	
	handleText = (e, key) => {
		// computed property
		if (key == "email") {
			this.setState({[key]: e.target.value.trim() });
		} else {
			this.setState({[key]: e.target.value });
		}
	}
	
	handleKeyPress = (e) => {
	    if (e.key === "Enter") {
	    	this.onClickInfoReqButton();
	    }
	}

	// 이미지 업로드 팝업 : 파일첨부 열기
    handleClickBtnFileUpload  = e => { 
		e.preventDefault ();
		$("#file").click();	
	};

	// 이미지 업로드 팝업 : 업로드 이벤트
    _handleFileChange = e => {
        this.attachFiles(e.target.files)
    };

	// 업로드된 파일 체크
    attachFiles(acceptedFiles) {
		if (acceptedFiles) {
			/* Get files in array form */
			const files = Array.from(acceptedFiles);
			var file_max_size = 200*1024*1024;		// 최대 200MB 제한
			var file_total_size = 0;
			var fileNames = "";
			var isChk = true;
			var check_file_type = new Array();
			check_file_type = ['exe', 'bat', 'bin', 'msi', 'dll', 'reg', 'ocx', 'log'];		//특정 실행 파일의 확장자 제외

			files.map(file => {
				try {
					var file_name = file.name;
					var file_type = file.type;
					var file_size = file.size;

					// 파일 객체에서 확장자를 알아내지 못하면 이름에서 찾기
					var pathpoint = file_name.lastIndexOf('.');
					var filepoint = file_name.substring(pathpoint+1, file_name.length);
					file_type = filepoint.toLowerCase();

					file_total_size += file_size;
					console.log(file_name + " :: " + file_type + " :: " + file_size + " / "+file_total_size);

					if(check_file_type.indexOf(file_type) != -1) {
						alert(file_type + ' 확장자는 업로드 할 수 없습니다.');
						//document.getElementById("file");
						isChk = false;

						return false;
					}

					console.log((file_total_size/1024/1024).toFixed(2) + " / "+(file_max_size/1024/1024));
					if (file_total_size > file_max_size) {
						alert('파일의 총 용량은' + (file_max_size/1024/1024) +'MB를 초과할 수 없습니다.\n업로드 대상 용량 : ' + (file_total_size/1024/1024).toFixed(2) + "MB");
						//document.getElementById("file").select();
						isChk = false;

						return false;
					}

					fileNames += file.name + " "
				
				} catch (e) {
					console.log("### 파일첨부 오류 : " + file_name);
					console.log(e);
					alert("다음의 파일첨부 오류가 발생하였습니다.\n고객센터 : 1599-3518\n오류가 발생된 파일 : "+file_name);
					isChk = false;

					return false;
				}

			});

			if (isChk) {
				this.setState({ files: files });
				$("#attachFileName").text(fileNames);
			} else {
				$("#file").val("");
			}
		}
	}

	// 서비스 안내 신청
	onClickInfoReqButton = () => {
		
		// const sendForm = {
		// 	email		: this.state.email,
		// 	tel_no		: this.state.tel_no,
		// 	corp_name	: this.state.corp_name,
		// 	user_name	: this.state.user_name,
		// 	platform_type: this.state.platform_type,
		// 	site_url	: this.state.site_url,
		// 	type		: this.state.type,
		// 	title		: this.state.title,
		// 	description	: this.state.description,
		// 	files	    : this.state.files,
		// 	refferer	: this.state.refferer,
		// };

		const formData = new FormData();
		formData.append("email", this.state.email);
		formData.append("tel_no", this.state.tel_no);
		formData.append("corp_name", this.state.corp_name);
		formData.append("user_name", this.state.user_name);
		formData.append("platform_type", this.state.platform_type);
		formData.append("site_url", this.state.site_url);
		formData.append("type", this.state.type);
		formData.append("title", this.state.title);
		formData.append("description", this.state.description);
		formData.append("refferer", this.state.refferer);
		//formData.append("files", this.state.files);

		this.state.files.forEach((file) => formData.append("files", file));
		
		if (formData.get("email") == null || formData.get("email") == "") {
			alert("업무용 이메일을 입력해주세요.");
			// 가입신청 - 가입신청 요청
			ReactGA.event({
				category: "서비스 신청 이벤트 요청",
				action: "업무용 이메일을 입력해주세요. : " + formData.get("email"),
				label: ga_label
			});
			return false;
		}
		if (formData.get("tel_no") == null || formData.get("tel_no") == "") {
			alert("연락처를 입력해 주세요.");
			// 가입신청 - 가입신청 요청
			ReactGA.event({
				category: "서비스 신청 이벤트 요청",
				action: "연락처를 입력해 주세요. : " + formData.get("tel_no") + " / " + formData.get("corp_name"),
				label: ga_label
			});
			return false;
		}
		// if (formData.get("platform_type") == null || formData.get("platform_type") == "") {
		// 	alert("플랫폼 유형을 선택해 주세요.");
		// 	// 가입신청 - 가입신청 요청
		// 	ReactGA.event({
		// 		category: "서비스 신청 이벤트 요청",
		// 		action: "플랫폼 유형을 선택해 주세요. : " + formData.get("platform_type") + " / " + formData.get("corp_name"),
		// 		label: ga_label
		// 	});
		// 	return false;
		// }
		// if (formData.get("type") == null || formData.get("type") == "") {
		// 	alert("문의 유형을 선택해 주세요.");
		// 	// 가입신청 - 가입신청 요청
		// 	ReactGA.event({
		// 		category: "서비스 신청 이벤트 요청",
		// 		action: "문의 유형을 선택해 주세요. : " + formData.get("type") + " / " + formData.get("corp_name"),
		// 		label: ga_label
		// 	});
		// 	return false;
		// }

		const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
		// 문의 신청
		http.post("/rest/common/infoReq/save", formData, config)
			.then(
					 resultData => {	
						var result = resultData.data.code;
						console.log(result, (result === 500));	 
						if (!result === 200) {
							// 문의신청 - 문의신청 오류
							ReactGA.event({
								category: "문의신청 오류",
								action: "신청 업체명 : " + formData.get("corp_name"),
								label: ga_label
							});
							alert("문의신청 오류\n서비스 점검중 입니다.\n고객센터 : 1599-3518");
							return false;	
						}
						// 가입신청 - 가입신청 완료
						ReactGA.event({
							category: "문의신청 완료",
							action: "신청 업체명 : " + formData.get("corp_name"),
							label: ga_label
						});
						alert("문의 신청이 완료 되었습니다.\n곧 찾아뵙겠습니다.");
						//로그인 페이지로 이동.
						window.location.href = "/";
					 })
			 .catch(
					error => { 
						// 문의신청 - 문의신청 오류
						ReactGA.event({
							category: "문의신청 오류",
							action: "신청 업체명 : " + formData.get("corp_name"),
							label: ga_label
						});
						alert("문의신청 오류\n서비스 점검중 입니다.\n고객센터 : 1599-3518");
					}
			 );

		// const browser = navigator.detectBroeser();
		// const os = navigator.detectOS();
		// const device = navigator.detectDevice();
	};

	//공통코드 조회
	async getCode(cd_class, option) {
		const res = await http.get("/rest/common/roboMdCode?cd_class="+cd_class);
		const { options } = this.state;
		this.setState({
			options:{
				...options,
				[option]:res.data
			}
		});
		// console.log(this.state)
	};

	componentDidMount() {
		//쇼핑몰 플랫폼 코드 조회 
		this.getCode("5200", "platform_type");
		//문의코드 조회 
		this.getCode("5100", "type");
		
		const { email } = queryString.parse(this.props.location.search);
		var referrer = sessionStorage.getItem("referrer");

		if (email != null) {
			this.setState({email: email})
		}
		if (referrer != null) {

			this.setState({referrer: referrer})
			
			if (referrer.indexOf("voucher") > 0) {
				this.setState({type:'5102'})	/*문의유형 : 데이터바우처*/
			} else if (referrer.indexOf("robomd") > 0) {
				this.setState({type:'5101'})	/*문의유형 : 로보MD*/
			}

			if (referrer.indexOf("cafe24") > 0) {
				this.setState({platform_type:'5201'})	/*플랫폼_유형 : cafe24*/
			}
		}

//		if (referrer != null) {
//			//console.log("referrer : " + referrer);
//			sessionStorage.setItem("referrer", document.referrer);
//		}
		// axios.get("/logout")
		// .then(res => {
		// 	// alert("세션이 끊어졌습니다.")
		// })
		// .catch(error => {
		// 	// console.log (error);
		//  });
	}

	render() {
		return (
			<div className="login-container" style={{minHeight: "100vh", position: "relative"}}>
				<div id='non-page-wrapper'>
					<div className="middle-box text-center" style={{paddingBottom:"188px"}}>
						{/* <h3>2. 서비스 신청 상세 페이지</h3> */}
						<h3>[문의 및 상담 신청하기]</h3>
						<br/>
						<div className="form-group m-b-lg">
							<label>이메일</label><small className="text-navy"> (*필수입력사항)</small>
							<div className="input-group">
								<input type="text" id="email" placeholder="업무용 이메일을 입력해주세요" className="form-control"
									value={this.state.email} 
									onChange={(e) => this.handleText(e, 'email')}
									onKeyPress={this.handleKeyPress}
									required maxLength="100" />
							</div>
						</div>
						<div className="form-group m-b-md">
							<label>연락처</label><small className="text-navy"> (*필수입력사항)</small>
							<div className="input-group">
								<input type="text" id="tel_no" className="form-control" placeholder="***-****-****"
									value={this.state.tel_no} 
									onChange={(e) => this.handleText(e, 'tel_no')} 
									required maxLength="13" />
							</div>
						</div>
						<div className="form-group m-b-md">
							<label>회사이름</label>
							<div className="input-group">
								<input type="text" id="corp_name" className="form-control"
									value={this.state.corp_name} 
									onChange={(e) => this.handleText(e, 'corp_name')} 
									required maxLength="500" />
							</div>
						</div>
						<div className="form-group m-b-md">
							<label>담당자명</label>
							<div className="input-group">
								<input type="text" id="user_name" className="form-control"
									value={this.state.user_name} 
									onChange={(e) => this.handleText(e, 'user_name')} 
									required maxLength="200" />
							</div>
						</div>
						<div className="form-group m-b-md">
							<label>쇼핑몰 플랫폼</label>
							<div className="input-group">
								<select className="form-control" id="platform_type" style={{width:"250px"}}
			                        value={this.state.platform_type} onChange={(e)=>this.handleText(e, 'platform_type')}>
										<option value="">***선택해 주세요***</option>
										{this.state.options.platform_type.map((option, index) => <option key={index} value={option.cd_code}>{option.cd_desc}</option>)}
				                </select>
							</div>
						</div>
						<div className="form-group m-b-md">
							<label>쇼핑몰 주소</label>
							<div className="input-group">
								<input type="text" id="site_url" className="form-control"
									value={this.state.site_url} 
									onChange={(e) => this.handleText(e, 'site_url')} 
									required maxLength="500" />
							</div>
						</div>
						<div className="form-group m-b-md">
							<label>문의 유형</label>
							<div className="input-group">
								<select className="form-control" id="type" style={{width:"250px"}}
			                        value={this.state.type} onChange={(e)=>this.handleText(e, 'type')}>
										<option value="">***선택해 주세요***</option>
										{this.state.options.type.map((option, index) => <option key={index} value={option.cd_code}>{option.cd_desc}</option>)}
				                </select>
							</div>
						</div>
						<div className="form-group m-b-md">
							<label>제목</label>
							<div className="input-group">
								<input type="text" id="title" className="form-control"
									value={this.state.title} 
									onChange={(e) => this.handleText(e, 'title')} 
									required maxLength="1000" />
							</div>
						</div>
						<div className="form-group m-b-md">
							<label>내용</label>
							<div className="input-group">
								<textarea type="text" id="description" className="form-control"
									value={this.state.description} 
									onChange={(e) => this.handleText(e, 'description')} 
									rows="10" cols="20"
									required maxLength="5000" />
							</div>
						</div>
						<div className="form-group m-b-md">
							<label>파일첨부</label>
							<div className="input-group">
								<div className="fileUp">
									<button id="btnFileUpload" onClick={this.handleClickBtnFileUpload}>파일첨부</button>
									<input type="file" id="file" name="file" onChange={this._handleFileChange} value="" style={{display:'none'}}/> 
									<div id="attachFileName" className='form-control' style={{float:"left", textAlign:"left"}}></div>
								</div>
							</div>
						</div>
						<span className="input-group-btn">
							<button className="btn-auto-b" onClick={this.onClickInfoReqButton}>서비스 신청</button>
							{/* <Link className="btn find_btn" to={`/ko/infoReq?email=${this.state.email}`}>서비스 신청</Link> */}
						</span>
						{/* <p>{JSON.stringify(this.state)}</p> */}
						<div style={{marginTop:"15px"}}>
							iclave © 2021
						</div>
					</div>
				</div>	
				{/* <footer className="flexBox" style={{borderTop: "1px solid  #c3c3c3", position:"absolute", bottom:"0", backgroundColor:"white", width: "100%", padding:"20px 0 20px 0", color:"#3c3c3c", fontSize:"14px"}}>
					<div className="footer-first-child" style={{width:"32%", paddingLeft:"3.5%"}}>
						<div style={{display: "table", width:"auto"}}>
							<div style={{display: "table-cell", verticalAlign: "middle"}}>
								(주)아이클레이브 대표이사 : 최윤내<br/>
								<br/>
								사업자등록번호 : 381-81-00136<br/>
								통신판매번호 : 2020-서울마포-2676<br/>
								사업장 소재지 : 서울특별시 강남구 테헤란로2길, 13층 1308호(역삼동, 패스트파이브빌딩)<br/>
								이메일 : robomd@robomd.io
							</div>
						</div>
					</div>
					<div className="footer-second-child" style={{width:"32%", borderLeft:"1px solid  #c3c3c3", textAlign:"center"}}>
						<div className="flexBox" style={{width:"100%", height:"50%"}}>
							<div style={{width:"33%",height:"100%"}}>
								<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
									<div style={{display: "table-cell", verticalAlign: "middle"}}>
										<a href="https://www.otdeal.co.kr/home/contact" target="blank">문의 하기</a>
									</div>
								</div>
							</div>
							<div style={{width:"33%",height:"100%", borderLeft:"1px solid  #c3c3c3"}}>
								<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
									<div style={{display: "table-cell", verticalAlign: "middle"}}>
										<a href="https://www.otdeal.co.kr" target="blank">회사 사이트</a>
									</div>
								</div>	
							</div>
							<div style={{width:"33%",height:"100%", borderLeft:"1px solid  #c3c3c3"}}>
								<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
									<div style={{display: "table-cell", verticalAlign: "middle"}}>
										<a href="/common/FormViewer/1" target="blank">이용약관</a>
									</div>
								</div>	
							</div>
						</div>
						<div className="flexBox" style={{width:"100%", height:"55%", paddingTop: "10px", margin: "10px 0 30px 0", borderTop:"1px solid  #c3c3c3"}}>
							<div style={{width:"33%",height:"100%"}}>
								<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
									<div style={{display: "table-cell", verticalAlign: "middle"}}>
										<a href="https://www.instagram.com/otdeal_official" target="blank">Instagram</a>
									</div>
								</div>	
							</div>
							<div style={{width:"33%",height:"100%", borderLeft:"1px solid  #c3c3c3"}}>
								<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
									<div style={{display: "table-cell", verticalAlign: "middle"}}>
										<a href="https://www.facebook.com/otdeal" target="blank">Facebook</a>
									</div>
								</div>
							</div>
							<div style={{width:"33%",height:"100%", borderLeft:"1px solid  #c3c3c3"}}>
								<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
									<div style={{display: "table-cell", verticalAlign: "middle"}}>
										<a href="/common/FormViewer/2" target="blank">개인정보처리방침</a>
									</div>
								</div>
							</div>
						</div>
					</div>		
					<div className="footer-third-child" style={{width:"32%", borderLeft:"1px solid  #c3c3c3"}}>
					<div style={{display: "table", width:"auto", height:"100%", margin:"auto"}}>
						<div style={{display: "table-cell", verticalAlign: "middle"}}>
							<h1><b>고객센터 : 1599-3518</b></h1><br/>

							오전 10:00 ~ 오후 7:00 lunch 13:00 ~ 14:00 토요일/공휴일 휴무<br/>
						</div>
					</div>
					</div>
				</footer> */}
				</div>
			
		);
	}
	
}
import React from 'react';
import http from '../modules/api';
import * as StringUtil from '../modules/utils/StringUtil';
import * as CookieUtil from '../modules/utils/CookieUtil'; // JSESSIONID
import BasicDialogV2 from "../modules/dialogs/BasicDialogV2";
import Slider from 'react-slick';
import EmailVerifyStyle from './EmailVerify.module.css';

// 이미지
import marketingImg1 from '../../resources/img/marketing_img_1.jpg';
import marketingImg2 from '../../resources/img/marketing_img_2.jpg';
import marketingImg3 from '../../resources/img/marketing_img_3.jpg';

export class EmailVerify extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
            sliderSetting: {
                dots: true, // 페이지 컨트롤 디자인
                arrows:false, // 화살표
                infinite: true, // 무한 루프
                autoplay: true, // 자동 재생
                speed: 500, // 전환 속도
                autoplaySpeed: 3000, // 전환 텀
                slidesToShow: 1, // 전환 개수
                slidesToScroll: 1 // 스크롤 시 전환 개수
            },

            banner_template_list: [],  

            uuid: "",
            is_valid : null,
            already_auth_yn : null,
            auth_complete_yn : null
		}
    }

    componentWillMount() {
	
    }


	componentDidMount() {
        this.setState({
            uuid: StringUtil.getUrlParams("uuid"),
        }, () => {
            this.uuidCheck()
        })
    }

    componentDidUpdate() {
        
    }

    loadBannerTemplateImg() {
        if (this.state.banner_template_list.length <= 0) {
          http.get('/rest/common/template/img/list').then((resultData) => {
            if (resultData.data.list != null && resultData.data.list.length > 1) {
              this.setState({ banner_template_list: resultData.data.list });
            }
          });
        }
      }

    goToLoginPage () {
        window.location.href="/login";
    }

    // 1. 유효한 토큰인지 확인
    uuidCheck() {
        http.get("/rest/common/check/email/uuid?email_uid="+this.state.uuid)
        .then(
            resultData => {
                let data = resultData.data;
                this.setState({
                    is_valid : data.is_valid
                }, () => {
                    if (this.state.is_valid == "N") {
                        this.setState({
                            basic_dialog_show_state : true
                            , basic_dialog_content : "기한이 만료된 링크입니다."
                            , basic_dialog_content2 : `기한이 만료되어 가입 승인이 불가합니다.
                                                        <br/>로그인 후 가입 인증 메일을 다시 발송해 주세요.`
                            , basic_dialog_button_list : [
                                {
                                    title: "로그인 페이지 바로가기",
                                    event_method: () => {
                                        this.goToLoginPage();
                                    }
                                }
                            ]
                        });
                        CookieUtil.deleteCookie("JSESSIONID");
                    } else {
                        this.checkAuthYn();
                    }
                })
            }
        );
    }
    
    // 2. 이미 인증된 사용자인지 확인
    checkAuthYn() {
        http.get("/rest/common/check/email/authYn?email_uid="+this.state.uuid)
        .then(
            resultData => {
                let data = resultData.data;
                this.setState({
                    already_auth_yn : data.auth_yn
                }, () => {
                    if (this.state.already_auth_yn == "Y") {
                        this.setState({
                            basic_dialog_show_state : true
                            , basic_dialog_content : "인증이 완료된 링크입니다."
                            , basic_dialog_content2 : `이미 인증 완료된 이메일입니다.
                                                        <br/>로그인 후 서비스를 이용해 보세요.`
                            , basic_dialog_button_list : [
                                {
                                    title: "로그인 페이지 바로가기",
                                    event_method: () => {
                                        this.goToLoginPage();
                                    }
                                }
                            ]
                        });
                    } else {
                        // 이메일 인증 완료
                        this.emailAuth();
                        this.loadBannerTemplateImg();
                        CookieUtil.deleteCookie("JSESSIONID");
                    }
                })
            }
        );
    }
    
    // 3. 이메일 인증 완료 처리
    emailAuth() {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                },
        };
        let body = {
            "email_uid": this.state.uuid
        }
        http.post("/rest/common/email/auth", body, config)
        .then(
            resultData => {
                let data = resultData.data;
                this.setState({
                    auth_complete_yn : data.auth_yn
                })
            }
        );
    }

    render() {
        return (
            <>
                {
                    // this.state.is_valid != null
                    // ?
                    //     this.state.is_valid == "Y" && this.state.auth_complete_yn != null && this.state.auth_complete_yn == "Y"
                    //     ? 
                        <>
                            <div className={EmailVerifyStyle.email_verify_wrap+" width_height_100 mobile-height-auto"}>
                                <div className={EmailVerifyStyle.flexWrap}>
                                    <div>
                                        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="27.4173" cy="27.4173" r="21.5833" fill="#333333"/>
                                            <path d="M27.416 4.66602C22.9165 4.66602 18.518 6.00028 14.7768 8.50008C11.0356 10.9999 8.11966 14.5529 6.39777 18.71C4.67587 22.867 4.22535 27.4413 5.10316 31.8543C5.98097 36.2674 8.1477 40.3211 11.3293 43.5027C14.511 46.6843 18.5647 48.8511 22.9777 49.7289C27.3908 50.6067 31.9651 50.1562 36.1221 48.4343C40.2791 46.7124 43.8322 43.7965 46.332 40.0552C48.8318 36.314 50.166 31.9155 50.166 27.416C50.1545 21.3859 47.7539 15.6061 43.4899 11.3421C39.226 7.07816 33.4462 4.67758 27.416 4.66602ZM38.2442 23.4348L25.4254 35.6848C25.094 35.9965 24.6554 36.1688 24.2004 36.166C23.978 36.1692 23.7572 36.1283 23.5507 36.0457C23.3442 35.9631 23.1561 35.8404 22.9973 35.6848L16.5879 29.5598C16.4101 29.4047 16.2656 29.2152 16.1629 29.0028C16.0602 28.7904 16.0015 28.5595 15.9903 28.3238C15.9792 28.0881 16.0158 27.8527 16.098 27.6315C16.1802 27.4104 16.3062 27.2081 16.4685 27.0369C16.6309 26.8657 16.8261 26.7291 17.0426 26.6354C17.2591 26.5416 17.4923 26.4925 17.7282 26.4911C17.9641 26.4898 18.1979 26.5361 18.4154 26.6274C18.633 26.7187 18.8298 26.853 18.9942 27.0223L24.2004 31.9879L35.8379 20.8973C36.1782 20.6004 36.62 20.4466 37.0711 20.4679C37.5222 20.4892 37.9475 20.6841 38.2582 21.0118C38.5689 21.3394 38.741 21.7745 38.7384 22.2261C38.7357 22.6777 38.5587 23.1107 38.2442 23.4348Z" fill="white"/>
                                        </svg>
                                    </div>
                                    <div className={EmailVerifyStyle.content}>
                                        로보MD 회원이 되신 것을 환영해요! 
                                        <br/>
                                        지금 바로 서비스를 이용해 보세요.
                                    </div>
                                    <div className={EmailVerifyStyle.slider_wrap}>
                                         <Slider
                                            {...this.state.sliderSetting}
                                            arrows={false}
                                            style={{
                                            width: '100%',
                                            height: '100%',
                                            textAlign: 'center',
                                            }}
                                            className="sample-banner-slider"
                                        >
                                            <div><img src={marketingImg1} alt='마케팅 이미지 1'/></div>
                                            <div><img src={marketingImg2} alt='마케팅 이미지 2'/></div>
                                            <div><img src={marketingImg3} alt='마케팅 이미지 3'/></div>
                                        </Slider>
                                    </div>
                                    <div className={EmailVerifyStyle.btn_wrap} onClick={
                                            () => {
                                                this.goToLoginPage();
                                            }
                                        }>
                                        <div className={EmailVerifyStyle.btn_content}>
                                            지금 로그인하기
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    //     : ""
                    // :   ""
                }
                {
					// (this.state.basic_dialog_show_state === true)
					// 	? <BasicDialogV2
					// 		content={this.state.basic_dialog_content}
					// 		content2={this.state.basic_dialog_content2}
					// 		button_list={this.state.basic_dialog_button_list}
					// 	/>
					// 	: ''
				}
            </>
        )
    };
}

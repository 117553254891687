import React from 'react';
import axios from 'axios';
import http from '../modules/api';
import {Route, Switch, NavLink, Link} from "react-router-dom";
// import {AdminUserDetail} from "./AdminUserDetail";
import $ from 'jquery';
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";
import {Line} from 'react-chartjs-2';


import "react-datepicker/dist/react-datepicker.css";
export class AutoEventState extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			start_date : "",
			end_date : "",
			start_date_format : "",
			end_date_format : "",
			item_start_date : "",
			item_end_date : "",
			item_start_date_format : "",
			item_end_date_format : "",
			sale_start_date : "",
			sale_end_date : "",
			sale_start_date_format : "",
			sale_end_date_format : "",
			eventData : {
				labels:[""],
				datasets:[]
			},
			itemData : {
				labels:[""],
				datasets:[]
			},
			saleData : {
				labels:[""],
				datasets:[]
			},
			options: {maintainAspectRatio: false},
		}
	}

	componentWillMount() {
		let date = new Date();
		let startDate = new Date(date.getFullYear()+"-"+(date.getMonth()+1)+"-"+"01");
		this.setState({end_date_format: date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate()});
		this.setState({start_date_format: date.getFullYear()+"-"+(date.getMonth()+1)+"-"+"01"});
		this.setState({end_date: date});
		this.setState({start_date: startDate});

		this.setState({item_end_date_format: date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate()});
		this.setState({item_start_date_format: date.getFullYear()+"-"+(date.getMonth()+1)+"-"+"01"});
		this.setState({item_end_date: date});
		this.setState({item_start_date: startDate});

		this.setState({sale_end_date_format: date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate()});
		this.setState({sale_start_date_format: date.getFullYear()+"-"+(date.getMonth()+1)+"-"+"01"});
		this.setState({sale_end_date: date});
		this.setState({sale_start_date: startDate});
	}

	componentDidMount() {
		// console.log(this.state.eventData);
		// console.log(this.state.itemData);

		this.getEventSates();
		// this.getEventItemSates();
		this.getEventSaleSates();
	}

	getEventSates() {
		// console.log(this.state.start_date_format)
		http.get(`/rest/shop/event/states?start_date=`+this.state.start_date_format+"&end_date="+this.state.end_date_format+"&user_seq=8").then(res => {
			console.log(typeof res.data === 'string')
			if(typeof res.data === 'string') {
				if(res.data.indexOf("<script>") >= 0){
					alert("로그인 후 이용해 주세요.")
					window.location.href = "/login";
				}
			}
			this.setState({eventData : {datasets: res.data.datasets, labels: res.data.labels}});
			// console.log(res.data);
			// this.state.eventData.datasets = res.data.datasets;
			// this.state.eventData.labels = res.data.labels;
		})
		.catch(error => {
			console.log(error);
		})
	}

	getEventItemSates() {
		http.get(`/rest/shop/event/item/states?start_date=`+this.state.item_start_date_format+"&end_date="+this.state.item_end_date_format+"&user_seq=8").then(res => {
			this.setState({itemData : {datasets: res.data.datasets, labels: res.data.labels}});
			// console.log(res.data);
			// this.state.eventData.datasets = res.data.datasets;
			// this.state.eventData.labels = res.data.labels;
		})
		.catch(error => {
			// console.log (error);
		})
	}

	getEventSaleSates() {
		http.get(`/rest/shop/event/sale/states?start_date=`+this.state.sale_start_date_format+"&end_date="+this.state.sale_end_date_format).then(res => {
			this.setState({saleData : {datasets: res.data.datasets, labels: res.data.labels}});
			// this.state.eventData.datasets = res.data.datasets;
			// this.state.eventData.labels = res.data.labels;
		})
		.catch(error => {
			console.log (error);
		})
	}

	handleChangeStartDate = date => {
		if (date != null) {
			this.setState({
				start_date_format: date.getFullYear() + "-"+ parseInt(date.getMonth()+1) +"-"+date.getDate(),
				start_date: date
			});
		} else {
			this.setState({
				start_date_format: date,
				start_date: date
			});
		}
	};
	handleChangeEndDate = date => {
		if (date != null) {
			this.setState({
				end_date_format: date.getFullYear() + "-"+ parseInt(date.getMonth()+1) +"-"+date.getDate(),
				end_date: date
			});
		} else {
			this.setState({
				end_date_format: date,
				end_date: date
			});
		}
	};
	
	handleChangeItemStartDate = date => {
		if (date != null) {
			this.setState({
				item_start_date_format: date.getFullYear() + "-"+ parseInt(date.getMonth()+1) +"-"+date.getDate(),
				item_start_date: date
			});
		} else {
			this.setState({
				item_start_date_format: date,
				item_start_date: date
			});
		}
	};
	handleChangeItemEndDate = date => {
		if (date != null) {
			this.setState({
				item_end_date_format: date.getFullYear() + "-"+ parseInt(date.getMonth()+1) +"-"+date.getDate(),
				item_end_date: date
			});
		} else {
			this.setState({
				item_end_date_format: date,
				item_end_date: date
			});
		}
	};
	
	handleChangeSaleStartDate = date => {
		if (date != null) {
			this.setState({
				sale_start_date_format: date.getFullYear() + "-"+ parseInt(date.getMonth()+1) +"-"+date.getDate(),
				sale_start_date: date
			});
		} else {
			this.setState({
				sale_start_date_format: date,
				sale_start_date: date
			});
		}
	};
	handleChangeSaleEndDate = date => {
		if (date != null) {
			this.setState({
				sale_end_date_format: date.getFullYear() + "-"+ parseInt(date.getMonth()+1) +"-"+date.getDate(),
				sale_end_date: date
			});
		} else {
			this.setState({
				sale_end_date_format: date,
				sale_end_date: date
			});
		}
	};

	onClickSearchListButton = () => {
		this.getEventSates();
	};

	onClickItemSearchListButton = () => {
		this.getEventItemSates();
	};

	onClickSaleSearchListButton = () => {
		this.getEventSaleSates();
	};

	render() {
		
		return (
			<div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
				<div id="notice_list_component">
						<h3>[Shop] 기획전 통계</h3>
						<br/>
						<h4>[배너 클릭수]</h4>
						<br/>
							<div style={{width:"100%", display:"flex", margin: "auto"}}>
								<div style={{width:"60px"}}><h4 style={{lineHeight:"22px"}}>날짜</h4></div>
								<div className="input-daterange input-group" id="datepicker">
									<DatePicker name="start_date" id="start_date"
										selected={this.state.start_date}
										onSelect={this.handleSelect} //when day is clicked
										onChange={this.handleChangeStartDate} //only when value has changed
										// showTimeSelect
										dateFormat="yyyy-MM-dd"
										autoComplete='off'
									/>
									<div className="input-group-addon" style={{width: "40px",height:"35px", lineHeight: "34px",textAlign:"center"}}>to</div>
									<DatePicker name="end_date" id="end_date"
										selected={this.state.end_date}
										onSelect={this.handleSelect} //when day is clicked
										onChange={this.handleChangeEndDate} //only when value has changed
										// showTimeSelect
										dateFormat="yyyy-MM-dd"
										autoComplete='off'
									/>	
								</div>
								<button className = "greenButton" onClick={this.onClickSearchListButton} style={{width: "55px",height: "35px", margin: "0px 20px 0px -40px"}}>조회</button>
							</div>
						<br/>					
						<div style={{width:"98%", height:"700px"}}><Line key={0} data={this.state.eventData} options={this.state.options}/></div>
						<br/>
						<br/>
						{/* <h4>[상품 클릭수]</h4>
						<br/>
							<div style={{width:"100%", display:"flex", margin: "auto"}}>
								<div style={{width:"60px"}}><h4 style={{lineHeight:"22px"}}>날짜</h4></div>
								<div className="input-daterange input-group" id="datepicker">
									<DatePicker name="start_date" id="start_date"
										selected={this.state.item_start_date}
										onSelect={this.handleSelect} //when day is clicked
										onChange={this.handleChangeItemStartDate} //only when value has changed
										// showTimeSelect
										dateFormat="yyyy-MM-dd"
									/>
									<div className="input-group-addon" style={{width: "40px",height:"35px", lineHeight: "34px",textAlign:"center"}}>to</div>
									<DatePicker name="end_date" id="end_date"
										selected={this.state.item_end_date}
										onSelect={this.handleSelect} //when day is clicked
										onChange={this.handleChangeItemEndDate} //only when value has changed
										// showTimeSelect
										dateFormat="yyyy-MM-dd"
									/>	
								</div>
								<button className = "greenButton" onClick={this.onClickItemSearchListButton} style={{width: "55px",height: "35px", margin: "0px 20px 0px -40px"}}>조회</button>
							</div>
						<br/>					
						<div style={{width:"98%", height:"300px"}}><Line key={1} data={this.state.itemData} options={this.state.options}/></div> 
						<br/>
						<br/> */}
						<h4>[상품 판매수]</h4>
						<br/>
							<div style={{width:"100%", display:"flex", margin: "auto"}}>
								<div style={{width:"60px"}}><h4 style={{lineHeight:"22px"}}>날짜</h4></div>
								<div className="input-daterange input-group" id="datepicker">
									<DatePicker name="start_date" id="start_date"
										selected={this.state.sale_start_date}
										onSelect={this.handleSelect} //when day is clicked
										onChange={this.handleChangeSaleStartDate} //only when value has changed
										// showTimeSelect
										dateFormat="yyyy-MM-dd"
										autoComplete='off'
									/>
									<div className="input-group-addon" style={{width: "40px",height:"35px", lineHeight: "34px",textAlign:"center"}}>to</div>
									<DatePicker name="end_date" id="end_date"
										selected={this.state.sale_end_date}
										onSelect={this.handleSelect} //when day is clicked
										onChange={this.handleChangeSaleEndDate} //only when value has changed
										// showTimeSelect
										dateFormat="yyyy-MM-dd"
										autoComplete='off'
									/>	
								</div>
								<button className = "greenButton" onClick={this.onClickSaleSearchListButton} style={{width: "55px",height: "35px", margin: "0px 20px 0px -40px"}}>조회</button>
							</div>
							<br/>					
							<div style={{width:"98%", height:"700px"}}><Line key={2} data={this.state.saleData} options={this.state.options}/></div>
							<br/>
				</div>
			</div>
		);
	}
}

import React, { useCallback, useState } from 'react';
import axios from '../modules/api';
import http from '../modules/api';
import * as StyleUtil from '../modules/utils/StyleUtil';
import {Line} from 'react-chartjs-2';
import { NavLink, Link, Prompt } from 'react-router-dom';
import $ from 'jquery';
import Slider from "react-slick";
import plus_img from '../../resources/img/plus.svg';
import TitleContentDialogV2 from '../modules/dialogs/TitleContentDialogV2';
import ConfirmDialogV2 from '../modules/dialogs/ConfirmDialogV2';
import BasicDialogV2 from '../modules/dialogs/BasicDialogV2';
import SaveDialogV2 from '../modules/dialogs/SaveDialogV2';
import ToggleButton from '../modules/common/ToggleButton';
import CheckBox from '../modules/common/CheckBox';
import Icon from '../modules/common/Icon';
import icn_clone from '../../resources/img/icn_clone.svg';

import ReactGA, { send } from 'react-ga';
ReactGA.initialize('UA-78553461-8', {
    titleCase: false,
});

let window_scrollTop; // 스크롤 위치

export class RpRealTimeService extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
            service_type: "0100201002",

            regist_yn_0100201001: "",
            operate_yn_0100201001: "",
            regist_yn_0100201002: "",
            operate_yn_0100201002: "",

            // 노출 여부 관련 다이얼로그
            confirm_dialog_show_state : false, // 노출 여부 변경 물어보기
            confirm_dialog_content: "",
            confirm_dialog_button_list: [],
            basic_dialog_show_state : false, // 노출 여부 변경 확인
            basic_dialog_content: "",
            basic_dialog_button_list: [],
            delete_dialog_show_state : false, // 삭제 여부 물어보기
            delete_dialog_content: "",
            delete_dialog_button_list: [],
            notice_period_title_content_dialog_show_state : false,
            title_content_dialog_title: "",
            title_content_dialog_content: "",
            title_content_dialog_button_list: [],
            notice_delete_dialog_show_state : false, // 비활성화 삭제 버튼 클릭시 알림
            notice_delete_dialog_content: "",
            notice_delete_dialog_button_list: [],
            notice_period_banner_show_state: false // 진열 이용기간 상단 배너 노출
        }
    }

    componentWillMount() {

    }

	componentDidMount() {
        $(document).ready(function(){
            // 왼쪽 네비바 닫혀있는 경우 열기
            $(".menu_expansion_arrow.cursor-pointer").trigger('click');

            //table의 넓이 계산
            var list_td = document.getElementsByClassName("list_td");
            var list_td_sum = 0;

            for(let i = 0; i < list_td.length; i++){
                list_td_sum = list_td_sum + list_td[i].offsetWidth;
            }

            $(".list_home_main_row_3 table").css({
                "width":list_td_sum
            });
        });

        this.loadRegistedService("0100201001");
        this.loadRegistedService("0100201002");
    }

    componentDidUpdate() {
    }

    componentWillUnmount(e) {

    }

    setCookie(name, value, day) {
        var date = new Date();
        date.setTime(date.getTime() + day * 60 * 60 * 24 * 1000);
        document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + ';path=/';
    };

    getCookie(name) {
        var value =document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return value? value[2] : null;
    };

    setGaEvent(action_text) {
        ReactGA.event({
             category: "베스트 서비스 (롤링 목록)",
             action: action_text,
             label: sessionStorage.getItem("user_id"),
         });
    }

    /**
     * 실시간 서비스 신청 여부 & 이용 여부
     */
    loadRegistedService(service_type) {
        http.get("/rest/shop/get/additionFunction?function_cd="+service_type)
        .then(res => {
            let regist_state_name = "regist_yn_"+service_type;
            let operate_state_name = "operate_yn_"+service_type;
            this.setState({
                [regist_state_name]: res.data.regist_cnt > 0 ? "Y" : "N",
                [operate_state_name]: res.data.operate_cnt > 0 ? "Y" : "N",
            })
        }).catch(error => {
			console.log(error);
		});
    }

    /**
     * 실시간 서비스 신청 (부가 서비스 신청)
     */
    onClickRegistService() {
        let {service_type} = this.state
        const body = {
			function_cd : service_type
		}
		const config = {
            headers: {
                'content-type': 'application/json;'
            }
        }
        http.post("/rest/shop/regist/additionFunction", body , config)
		.then(res => {
            // bagic
            if (res.data.regist_cnt != null) {
                this.setState({
                    basic_dialog_show_state: true,
                    basic_dialog_content: (service_type == "0100201001" ? "실시간 랭킹 베이직" : service_type == "0100201002" ? "실시간 랭킹 프리미엄" : "") + "가 신청 되었습니다.",
                    basic_dialog_button_list: [
                        {
                            title: "확인",
                            type: "1",
                            event_method: () => {
                                //팝업 닫기
                                this.setState({ basic_dialog_show_state: false });
                            }
                        }
                    ]
                })
            }
		}).catch(error => {
			console.log(error);
		});
    }

    render() {
        return (
            <>
                <div id='common-page-wrapper' className="style_guide_v2 real_time_service_page" 
                style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100vh"
                }}>
                    <div className="title">
                        부가서비스
                    </div>
                    <div id="list_wrap" className="realTimeServiceHeader">
                        <div className='flexBox' style={{justifyContent:"flex-start"}}>
                            <button className={'tab ' + (this.state.service_type == "0100201002" ? "active" : "")} onClick={e => {
                                this.setState({
                                    service_type: "0100201002"
                                })
                            }}>
                                실시간 랭킹 프리미엄
                            </button>
                            <button className={'tab ' + (this.state.service_type == "0100201001" ? "active" : "")} onClick={e => {
                                this.setState({
                                    service_type: "0100201001"
                                })
                            }}>
                                실시간 랭킹 베이직
                            </button>
                        </div>
                    </div>
                    
                    <div style={{flex:1}}
                    className={
                        "real_time_img "+
                        (
                            this.state["operate_yn_"+this.state.service_type] == "Y"
                            ? "real_time_img_operate"
                            :   this.state["regist_yn_"+this.state.service_type] == "Y"
                                ? "real_time_img_regist"
                                    : this.state.service_type == "0100201001"
                                        ? "real_time_img_basic"
                                        :   this.state.service_type == "0100201002"
                                            ? "real_time_img_premium"
                                            : null
                        )
                    }>
                        <div className='flexBox real_time_img_button'>
                            {
                                this.state["operate_yn_"+this.state.service_type] == "Y"
                                ?   
                                    <button className="btn_balck_mint" 
                                    onClick={() => {
                                        if (sessionStorage.getItem("solution_type_cd") == "09001") {
                                            window.location.href="/shop/rp/event/product/cafe24List/16006"
                                        } else {
                                            window.location.href="/shop/rp/event/product/list"
                                        }
                                    }}
                                    >
                                        실시간 랭킹 서비스 사용하러가기
                                    </button>
                                :   this.state["regist_yn_"+this.state.service_type] == "Y"
                                    ?   
                                        // <button className="btn_balck_mint disabled" 
                                        // onClick={() => {    
                                        //     this.onClickRegistService();
                                        // }}
                                        // disabled>
                                        //     신청 완료 되었습니다.
                                        // </button>
                                        <></>
                                    :   <button className="btn_balck_mint" 
                                        onClick={() => {    
                                            this.onClickRegistService();
                                        }}>
                                            실시간 랭킹 서비스 신청하기
                                        </button>
                            }
                        </div>
                    </div>
                </div>
                {
					(this.state.confirm_dialog_show_state === true)
					? <ConfirmDialogV2
						content={this.state.confirm_dialog_content}
						button_list={this.state.confirm_dialog_button_list}
						popup_close={() => {
                            this.setState({confirm_dialog_show_state: false});
                            StyleUtil.clearPopupNoneScroll(window_scrollTop);
                        }}
					/>
					: ''
				}
                {
					(this.state.basic_dialog_show_state === true)
					? <BasicDialogV2
						content={this.state.basic_dialog_content}
						button_list={this.state.basic_dialog_button_list}
					/>
					: ''
				}
                {
                    (this.state.delete_dialog_show_state === true)
                        ? <ConfirmDialogV2
                            content={this.state.delete_dialog_content}
                            button_list={this.state.delete_dialog_button_list}
                            popup_close={() => {
                                this.setState({delete_dialog_show_state: false});
                                StyleUtil.clearPopupNoneScroll(window_scrollTop);
                            }}
                        />
                        : ''
                }
                {
                    (this.state.notice_period_title_content_dialog_show_state === true)
                        ? <TitleContentDialogV2
                            title={ this.state.is_finish === 0 ? this.state.remain_date +"일 뒤 "+ this.state.payment_cls_text+"이 종료됩니다. <br/> 기간을 연장하시겠습니까?"
                                                                : this.state.payment_cls_text+"이 종료되었습니다. <br/> 기간을 연장하시겠습니까?" }
                            content={this.state.payment_cls_text + " 기간<br/>" +  this.state.start_date_format + " ~ " + this.state.end_date_format}
                            button_list={this.state.title_content_dialog_button_list}
                            popup_close={() => {
                                this.setState({notice_period_title_content_dialog_show_state: false});
                            }}
                        />
                        : ''
                }
                {
                    (this.state.notice_delete_dialog_show_state === true)
                        ? <TitleContentDialogV2
                            content={this.state.notice_delete_dialog_content}
                            button_list={this.state.notice_delete_dialog_button_list}
                            popup_close={() => {
                                this.setState({notice_delete_dialog_show_state: false});
                                StyleUtil.clearPopupNoneScroll(window_scrollTop);
                            }}
                        />
                        : ''
                }
                {this.state.show_save_loading_dialog &&
                    <SaveDialogV2
                        content={this.state.loading_dialog_message}
                        button_list={[{
                            title : "확인",
                            display_yn : "N",
                            event_method : () => { this.handleToggle("show_save_loading_dialog") }
                        }]}
                    />
                }
            </>
        )
    };
    
}
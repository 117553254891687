import React from 'react';
import axios from 'axios';
import http from '../modules/api';
import {Route, Switch, NavLink, Link, Prompt} from "react-router-dom";
import $ from 'jquery';

import * as Menu from '../Menu_v2';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale"; // datePicker 한글화
import { SketchPicker } from 'react-color'
import Pagination from "react-js-pagination";
import ReactWaterMark from 'react-watermark-component';
import Draggable from 'react-draggable';
import SaveDialogV2 from '../modules/dialogs/SaveDialogV2';
import BasicDialogV2 from "../modules/dialogs/BasicDialogV2";
import InputDialogV2 from '../modules/dialogs/InputDialogV2';
import * as StringUtil from '../modules/utils/StringUtil';
import * as CookieUtil from '../modules/utils/CookieUtil';
import CheckBox from '../modules/common/CheckBox';
import RadioButton from '../modules/common/RadioButton';
import ToggleButton from '../modules/common/ToggleButton';
import TagButton from '../modules/common/TagButton';
import Icon from '../modules/common/Icon';
import * as CommonDownloadManager from '../modules/CommonDownloadManager';

import icn_download from '../../resources/img/download.svg';
import select_item_icon from '../../resources/img/select_item_icon.svg';
import file_upload_icon from '../../resources/img/file_upload_icon.svg';
import link_upload_icon from '../../resources/img/link_upload_icon.svg';
import detail_page_icon from '../../resources/img/detail_page_icon.svg';
import robo_img_icon from '../../resources/img/robo_img_icon.svg';
import ai_badge from '../../resources/img/ai_badge.svg';
import preview_icon from '../../resources/img/preview_icon.svg';
import video_icon from '../../resources/img/video_icon.svg';
import crown_icon from '../../resources/img/crown_icon.svg';
import curved_arrow_right from '../../resources/img/curved_arrow_right.svg';
import Tooltip from '../modules/common/Tooltip';
import TitleContentDialogV2 from '../modules/dialogs/TitleContentDialogV2';

import ReactGA, { send } from 'react-ga';
import { CoverageMap } from 'istanbul-lib-coverage';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { isThisHour } from 'date-fns';
import { fi, is } from 'date-fns/locale';
import FileDownload from 'js-file-download';

import RCSlider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import DetailImgCropPopup from './component/DetailImgCropPopup';
import RoboImgSettingPopup from './component/RoboImgSettingPopup';

import plus_reading_glasses from '../../resources/img/plus_reading_glasses.png';
import calendar_in_num_1 from '../../resources/img/calendar_in_num_1.png';
import price_tag from '../../resources/img/price_tag.png';
import {TextEditor} from "../modules/textEditor/TextEditor";
import {initSelectionOnMouseDown} from "../modules/textEditor/TextEditor";
import ContentEditable from "react-contenteditable";
import Slider from '@mui/material/Slider';

import button_list from '../../resources/img/button_list.png';
import WarningDialog from "../modules/dialogs/WarningDialog";
import UploadYoutubeLinkPopup from '../modules/popup/UploadYoutubeLinkPopup';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import textEditorFont from "../modules/textEditor/textEditorFont";
import ContentState from "draft-js/lib/ContentState";
import htmlToDraft from "html-to-draftjs";

import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import icn_filter from '../../resources/img/icn_filter.svg';
import icn_refresh_2 from '../../resources/img/icn_refresh_2.svg';
import PopupFrameV2 from "../modules/popup/PopupFrameV2";

import Alert from '../modules/common/Alert';
import Button from '../modules/common/Button';

/** 셀렉트 박스 */
import Select from 'react-select';
import {reactSelectStyles} from '../modules/libraries/react-select';
import makeAnimated from 'react-select/animated';

let button_info_input_timer = {}; // 버튼정보 입력 작업시 여러번 요청 보내는 것을 방지
let title_input_timer = {}; // 제목 입력 작업시 여러번 요청 보내는 것을 방지(배너 이름, 큰제목, 작은제목)

ReactGA.initialize('UA-78553461-8');
const animatedComponents = makeAnimated();

let is_test_use = "";
if (document.domain.indexOf("127.0.0.1") >= 0 || document.domain.indexOf("otdeal.me") >= 0) {
    is_test_use = "&is_test_use=Y"
}

const transparent_image = "https://s3.ap-northeast-2.amazonaws.com/otdeal.file/robo_banner/2023/04/07/367/74ce6536-7b78-47f7-a813-6030b2534e70.png";

const presetColors = [
    "rgb(227,227,227)",
    "rgb(208,2,27)",
    "rgb(245,166,35)",
    "rgb(248,231,28)",
    "rgb(139,87,42)",
    "rgb(126,211,33)",
    "rgb(67,117,5)",
    "rgb(189,16,224)",
    "rgb(144,19,254)",
    "rgb(74,144,226)",
    "rgb(80,227,194)",
    "rgb(184,233,134)",
    "rgb(0,0,0)",
    "rgb(74,74,74)",
    "rgb(155,155,155)",
    "rgb(255,255,255)",
];
var is_banner_color_init = false;
var is_mobile_banner_color_init = false;

var is_auto_sub_title_load = true;
var is_banner_ratio_loaded = false;

var fullCalendarInnerModal_width;
var fullCalendarInnerModal_pcbanner_width; 
var fullCalendarInnerModal_mobileBanner_width; 

var color_title_num_p = 0;
var color_title_num_m = 0;

var calendar_event_seq = "";

var is_free_finish = false;
var is_order = false;

var first_lodded_cafe24_item = false;
var first_lodded_sub_titie = false;
var first_lodded_pc_banner = false;
var first_lodded_mobile_banner = false;

var product_random_seed = 0;

let window_scrollTop; // 스크롤 위치

var ga_label = sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")";
var isTmp = false; // 배너 업테이트 할지 여부

var dateString = new Date().getFullYear() + ("0" + parseInt(new Date().getMonth()+1)).slice(-2) + ("0" + parseInt(new Date().getDate())).slice(-2) + ("0" + parseInt(new Date().getHours())).slice(-2) + ("0" + parseInt(new Date().getSeconds())).slice(-2)

var object_domain = "https://"+document.domain;
var object_port = window.location.port;
if (document.domain.indexOf("127.0.0.1") >= 0 && object_port == "3000") {
    object_domain = "http://127.0.0.1:8092"
} else if (object_port != '') {
    object_domain = object_domain + ":" + object_port
}
var mirror_banner_url = object_domain+"/admin_banner.html";

// 배너 변경으로 누끼를 중복으로 따는것을 방지
var w_nukki_action_state = true;
var h_nukki_action_state = true;

// checkNukkiState를 페이지 로드시에는 실행하지 않도록 하기 위해 사용
// (이미지 주소가 접근 불가능해진 경우 실행하면 누끼 템플릿의 경우 실제 배너와 미리보기가 달라질 수 있기때문)
var getBannerRatioCnt = 0;

// 이미지 컬럼 순서별 이름
const youtube_order_text = ["youtube", "youtube_detail", "youtube_third","youtube_fourth"];
const img_order_text = ["img", "img_detail", "img_third","img_fourth"];

// 동시 누끼 작업 진행중 체크 변수
var nukki_action_state_cnt = 0;
// 저장된 배너일 경우 처음으로 실행하는 getBannerHtmlList 함수안에서 첫번째 배너 선택 안함
var first_load_page_id_list = false;

// Ref
const scollSelectItemRef = React.createRef(); // 상품 선택으로 가기
const scollSettingBtnRef = React.createRef(); // 배너 버튼 설정으로 가기
const scrollMakeWRoboImgRef = React.createRef(); // 로보 이미지 만들어보기 클릭 (가로형)
const scrollMakeHRoboImgRef = React.createRef(); // 로보 이미지 만들어보기 클릭 (세로형)

let img_scale_value = 0;
let txt_scale_value = 0;

let fontArray = [];
let mainPlainText = "";
let subPlainText = "";
let mainContent = ContentState.createFromText(mainPlainText);
let subContent = ContentState.createFromText(subPlainText);
let keydownTimeout;
let font_family_list = [];

export class EventForm_V2 extends React.Component {
    state = {
        solution_type_cd : "", // 비연동 서비스 구분자 : 09900

        select_item_list : [],
        item_list : [],
        keyword_list : [],
        bannerTypeList : [{}],
        mobileBannerTypeList : [],

        product_name: "",

        background: '#ffffff',
        background2: '#ffffff',
        background3: '#ffffff',
        background4: '#ffffff',

        event_seq:"",
        lang_type: "",
        none_title: "",
        title: "",
        sub_title: "",
        foreign_title: "",
        org_title: "",
        event_type_cd: "",
        event_type_name: "",
        event_type_img: "",
        event_pc_banner_name: "",
        event_pc_tag_cd_list: [],
        event_pc_tag_name_list: [],
        event_mobile_type_cd: "",
        event_mobile_type_name: "",
        event_mobile_type_img:"",
        event_mobile_banner_name: "",
        event_mobile_tag_cd_list: [],
        event_mobile_tag_name_list: [],
        title_sentence_cd: "",
        content_sentence_cd: "",
        sentence: "",
        eng_sentence: "",
        display_yn:"N" ,
        sentence_cd:"",

        keyword_cd: "",
        keyword: "",
        image: "",
        product_no: "",
        event_price : 0,

        orderGubun: "created_date",
        sorting: "DESC",
        activePage: 1,
        totalCnt: 0,
        startIdx: 0,
        rowcnt:12,

        pc_banner_type_paging : {
            activePage: 1,
            totalCnt: 0,
            startIdx: 0,
            rowcnt:15,
        },
        
        mobile_banner_type_paging : {
            activePage: 1,
            totalCnt: 0,
            startIdx: 0,
            rowcnt:12,
        },

        user_seq: "",
        shop_no: "",

        reg_user_seq: "",
        reg_date: "",
        mod_date: "",

        is_recommendation_small_title : "",
        title_length: 0,

        parentCategory: "",
        product_category_list: [],
        category_no: "",

        product_heder_category_no_1: "",
        product_heder_category_no_2: "",
        product_heder_category_no_3: "",
        product_heder_category_no_4: "",

        page_list: [],
        html_list: [],

        /* 시간 select Box Data */
        hour_list: [],
        minute_list: [],

        /* 진열 기간 설정 start */
        start_date: "",
        start_hour: "",
        start_minute: "",

        end_date: "",
        end_hour: "",
        end_minute: "",
        
        start_date_format: "",
        end_date_format: "",
        /* 진열 기간 설정 end */

        fullCalendarEventList:[],

        simple_date_setting_list : [],

        simple_date_cd: "17001",
        is_soldout_display: "Y",

        item_user_type: "A",

        is_link_url:"N",
        link_url:"",

        facial_x_start:"",
        facial_y_start:"",
        facial_width: "",
        facial_height: "",
        facial_yn: "",
        origin_img_width: "",
        origin_img_height: "",
        disabledStartDate: false,
        disabledEndDate: false,

        is_expand_color1: false,
        is_expand_color2: false,
        is_expand_color3: false,
        is_expand_color4: false,
        is_expand_w_large_text_color: false,
        is_expand_h_large_text_color: false,
        is_expand_w_small_text_color: false,
        is_expand_h_small_text_color: false,
        is_expand_w_color_etc: false,
        is_expand_h_color_etc: false,

        color_value_1 : "",
        color_value_2 : "",
        color_value_3 : "",
        color_value_4 : "",

        color_lmnst_1 : "",
        color_lmnst_2 : "",
        color_lmnst_3 : "",
        color_lmnst_4 : "",

        color_strtn_1 : "",
        color_strtn_2 : "",
        color_strtn_3 : "",
        color_strtn_4 : "",

        color_hue_1 : "",
        color_hue_2 : "",
        color_hue_3 : "",
        color_hue_4 : "",

        /** 이미지 포지션 타입 : px or %
         * DB 기본값은 기존에 px를 쓰고있던 고객들을 위해 50001(px)이지만,
         * 새로 생성할때는 %가 기본값으로 설정되도록 50002를 설정
         */
        img_position_1_type : "50002",
        img_position_2_type : "50002",
        img_position_3_type : "50002",
        img_position_4_type : "50002",
        h_img_position_1_type : "50002",
        h_img_position_2_type : "50002",
        h_img_position_3_type : "50002",
        h_img_position_4_type : "50002",

        /* 이미지 포지션 배너에 적용되지 않는 값 */
        w_img_position_left_1: 0,
        w_img_position_right_1: 0,
        w_img_position_top_1: 0,
        w_img_position_bottom_1: 0,

        w_img_position_left_2: 0,
        w_img_position_right_2: 0,
        w_img_position_top_2: 0,
        w_img_position_bottom_2: 0,

        w_img_position_left_3: 0,
        w_img_position_right_3: 0,
        w_img_position_top_3: 0,
        w_img_position_bottom_3: 0,

        w_img_position_left_4: 0,
        w_img_position_right_4: 0,
        w_img_position_top_4: 0,
        w_img_position_bottom_4: 0,

        h_img_position_left_1: 0,
        h_img_position_right_1: 0,
        h_img_position_top_1: 0,
        h_img_position_bottom_1: 0,

        h_img_position_left_2: 0,
        h_img_position_right_2: 0,
        h_img_position_top_2: 0,
        h_img_position_bottom_2: 0,

        h_img_position_left_3: 0,
        h_img_position_right_3: 0,
        h_img_position_top_3: 0,
        h_img_position_bottom_3: 0,

        h_img_position_left_4: 0,
        h_img_position_right_4: 0,
        h_img_position_top_4: 0,
        h_img_position_bottom_4: 0,

        w_img_position_name : [],
        h_img_position_name : [],

        /* 현재 선택된 이미지 타입 및 index */
        select_img_type : "", // 타입 : "w" or "h"
        select_img_idx : "", // 이미지 idx

        /* 누끼를 딴 이미지인지 여부 */
        w_img_nukki_yn : "N",
        w_img_detail_nukki_yn : "N",
        w_img_third_nukki_yn : "N",
        w_img_fourth_nukki_yn : "N",
        h_img_nukki_yn : "N",
        h_img_detail_nukki_yn : "N",
        h_img_third_nukki_yn : "N",
        h_img_fourth_nukki_yn : "N",

        /* 누끼 해제 요청 상태 : 사용자가 직접 원본 이미지 사용을 클릭한 경우(상태를 유지) */
        w_non_nukki_state: [false, false, false, false],
        h_non_nukki_state: [false, false, false, false],

        /* 누끼 이미지 버전 정보 */
        w_img_nukki_version : 3,
        w_img_detail_nukki_version : 3,
        w_img_third_nukki_version : 3,
        w_img_fourth_nukki_version : 3,
        h_img_nukki_version : 3,
        h_img_detail_nukki_version : 3,
        h_img_third_nukki_version : 3,
        h_img_fourth_nukki_version : 3,

        /** 이미지 누끼 타입 */
        w_nukki_version_list : [3,3,3,3],
        h_nukki_version_list : [3,3,3,3],

        /* 누끼 이미지 ppm 여부 */
        w_img_nukki_ppm_yn : "N",
        w_img_detail_nukki_ppm_yn : "N",
        w_img_third_nukki_ppm_yn : "N",
        w_img_fourth_nukki_ppm_yn : "N",
        h_img_nukki_ppm_yn : "N",
        h_img_detail_nukki_ppm_yn : "N",
        h_img_third_nukki_ppm_yn : "N",
        h_img_fourth_nukki_ppm_yn : "N",

        /** 누끼 이미지 AI 윤곽 보정 상태 : 배너별 이미지 포지션이 변경되어도 위치에 맞게 상태가 유지되도록 하기 위해 사용 */
        w_nukki_ppm_state: [false, false, false, false],
        h_nukki_ppm_state: [false, false, false, false],

        /* 누끼 이미지 링크 : 배너가 변경되면 무조건 변경되기 때문에 위치에 맞는 상태 유지 필요 없음*/
        w_nukki_img_link : "",
        w_nukki_img_detail_link : "",
        w_nukki_img_third_link : "",
        w_nukki_img_fourth_link : "",
        h_nukki_img_link : "",
        h_nukki_img_detail_link : "",
        h_nukki_img_third_link : "",
        h_nukki_img_fourth_link : "",

        /* 링크 이미지 타입 (49001 : 업로드 / 49002 : 링크) */
        w_img_link_type : "49001",
        w_img_detail_link_type : "49001",
        w_img_third_link_type : "49001",
        w_img_fourth_link_type : "49001",
        h_img_link_type : "49001",
        h_img_detail_link_type : "49001",
        h_img_third_link_type : "49001",
        h_img_fourth_link_type : "49001",
        
        /* 링크 입력형 배너 이미지 주소 */
        w_img_49002_link : "",
        w_img_detail_49002_link : "",
        w_img_third_49002_link : "",
        w_img_fourth_49002_link : "",
        h_img_49002_link : "",
        h_img_detail_49002_link : "",
        h_img_third_49002_link : "",
        h_img_fourth_49002_link : "",
        
        /* 상세 이미지 업로드 */
        w_crop_img_info_list : [{},{},{},{}],
        h_crop_img_info_list : [{},{},{},{}],

        /* 분할된 상세 이미지 선택 팝업 */
        select_detail_crop_img_popup_show : false,

        /* 링크 입력형 이미지 리스트 : 배너별 이미지 포지션이 변경되어도 위치에 맞게 상태가 유지되도록 하기 위해 사용
         * 포함 정보 : 링크 이미지 타입(업로드 or 링크형), 이미지 seq(파일형), 이미지 이름, 이미지 주소, 이미지 유효성(현재는 링크형 전용), 누끼 이미지 링크
         */
        w_user_img_list : [
                            {link_type : "49001", org_img_seq : "", org_img_name : "", img_link : "", img_link_able : false},
                            {link_type : "49001", org_img_seq : "", org_img_name : "", img_link : "", img_link_able : false},
                            {link_type : "49001", org_img_seq : "", org_img_name : "", img_link : "", img_link_able : false},
                            {link_type : "49001", org_img_seq : "", org_img_name : "", img_link : "", img_link_able : false}
                        ],
        h_user_img_list : [
                            {link_type : "49001", org_img_seq : "", org_img_name : "", img_link : "", img_link_able : false},
                            {link_type : "49001", org_img_seq : "", org_img_name : "", img_link : "", img_link_able : false},
                            {link_type : "49001", org_img_seq : "", org_img_name : "", img_link : "", img_link_able : false},
                            {link_type : "49001", org_img_seq : "", org_img_name : "", img_link : "", img_link_able : false}
                        ],
        
        /* 로보 이미지 주소 */
        w_img_49004_link : "",
        w_img_detail_49004_link : "",
        w_img_third_49004_link : "",
        w_img_fourth_49004_link : "",
        h_img_49004_link : "",
        h_img_detail_49004_link : "",
        h_img_third_49004_link : "",
        h_img_fourth_49004_link : "",

        show_robo_img_popup : false,

        /* 텍스트 컬러 */
        w_large_text_color : "#ffffff",
        h_large_text_color : "#ffffff",
   
        w_small_text_color : "#ffffff",
        h_small_text_color : "#ffffff",

        /* 텍스트 외 요소 색상 */
        w_color_etc : "#ffffff",
        h_color_etc : "#ffffff", 

        img_position_idx_list: [1, 2, 3, 4],

        /* 예약진열 여부 */
        book_yn : 'N',

        translated_yn: "N",     /*번역 여부*/
        language_code: "",

        language_code_list:[],  /*번역 언어 코드 리스트*/

        img_link: "",
        img_detail_link: "",
        img_third_link: "",
        img_fourth_link: "",

        product_sold_out: "F",

        default_bg_color_list: [],

        is_detail_image_use: false,
        image_type_cd: "46001",

        event_type_cd_use_yn : "Y",
        event_mobile_cd_use_yn : "Y",

        // 처음 저장 tmp가 실행되었는지 여부 : 저장된 페이지를 들어온 경우 tmp 실행 전에 미리보기 못누르게 방지
        page_load_tmp_state : false,

        robomd_on_sentence_cd : "",
        robomd_on_sub_title: "",
        robomd_on_foreign_title: "",
        robomd_on_sentence: "",
        robomd_on_eng_sentence: "",

        is_download: true,
        is_download_h: true,
        is_download_w: true,
        is_download_pc: true,
        is_download_mobile: true,

        pc_banner_ratio_list: [],
        mobile_banner_ratio_list: [],
        selected_pc_banner_ratio : {},
        selected_mobile_banner_ratio : {},

        // 비연동형 : 배너 사이즈 입력값
        event_banner_pc_width : null,
        event_banner_pc_height : null,
        event_banner_mobile_width : null,
        event_banner_mobile_height : null,

        // 버튼 템플릿 여부
        w_is_button_use : "N",
        h_is_button_use : "N",

        // 버튼 리스트
        button_list : [{
            button_text : "버튼명",
            link : "",
            order_no : 1,
            event_seq : dateString,
        }],

        // 텍스트 위치 이동
        w_main_txt_position_left: 0,
        w_main_txt_position_right: 0,
        w_main_txt_position_top: 0,
        w_main_txt_position_bottom: 0,

        h_main_txt_position_left: 0,
        h_main_txt_position_right: 0,
        h_main_txt_position_top: 0,
        h_main_txt_position_bottom: 0,

        w_sub_txt_position_left: 0,
        w_sub_txt_position_right: 0,
        w_sub_txt_position_top: 0,
        w_sub_txt_position_bottom: 0,

        h_sub_txt_position_left: 0,
        h_sub_txt_position_right: 0,
        h_sub_txt_position_top: 0,
        h_sub_txt_position_bottom: 0,

        // AI 인물 위치 자동 이동 가능 여부
        w_is_full_background_img_use : "N",
        h_is_full_background_img_use : "N",

        // 위치 자동 이동 실행 여부
        w_img_1_auto_move_yn : "N",
        w_img_2_auto_move_yn : "N",
        w_img_3_auto_move_yn : "N",
        w_img_4_auto_move_yn : "N",
        h_img_1_auto_move_yn : "N",
        h_img_2_auto_move_yn : "N",
        h_img_3_auto_move_yn : "N",
        h_img_4_auto_move_yn : "N",

        // 이미지 크기 조절
        w_img_1_scale: 1,
        w_img_2_scale: 1,
        w_img_3_scale: 1,
        w_img_4_scale: 1,
        h_img_1_scale: 1,
        h_img_2_scale: 1,
        h_img_3_scale: 1,
        h_img_4_scale: 1,

        // 텍스트 크기 조절
        w_main_txt_scale: 1,
        h_main_txt_scale: 1,
        w_sub_txt_scale: 1,
        h_sub_txt_scale: 1,

        // 파일명 규칙 설정
        auto_event_setting: {},
        file_name_setting_yn : "Y",
        file_name_setting_list: [],
        w_file_name_setting_list: [],
        h_file_name_setting_list: [],
        file_name_default_setting_list: [],
        w_file_name_default_setting_list: [],
        h_file_name_default_setting_list: [],

        // 유튜브 링크 사용 가능 여부
        w_is_youtube_link_use : "N",
        h_is_youtube_link_use : "N",

        // 유튜브 링크 업로드
        youtube_link : "",
        youtube_detail_link : "",
        youtube_third_link : "",
        youtube_fourth_link : "",
        h_youtube_link : "",
        h_youtube_detail_link : "",
        h_youtube_third_link : "",
        h_youtube_fourth_link : "",
        youtube_thumbnail : "",
        youtube_detail_thumbnail : "",
        youtube_third_thumbnail : "",
        youtube_fourth_thumbnail : "",
        h_youtube_thumbnail : "",
        h_youtube_detail_thumbnail : "",
        h_youtube_third_thumbnail : "",
        h_youtube_fourth_thumbnail : "",
        w_is_youtube_use: "N",
        w_is_youtube_detail_use: "N",
        w_is_youtube_third_use: "N",
        w_is_youtube_fourth_use: "N",
        h_is_youtube_use: "N",
        h_is_youtube_detail_use: "N",
        h_is_youtube_third_use: "N",
        h_is_youtube_fourth_use: "N",
        mainEditorState: EditorState.createWithContent(mainContent),
        subEditorState: EditorState.createWithContent(subContent),
        event_show_type : "70001", // 웹배너로 노출 or 팝업으로 노출
        popup_target_type : "73001", // 상품 설정에 따라 노출 or 타겟으로 노출

        target_view_list : [], // 타겟뷰 목록
        selected_target_view_list : [],
        is_use_target_view_service : "N", // 타겟뷰 서비스 사용여부

        font_family_list: [], // 에디터 폰트 리스트

        // 템플릿 필터 태그 가로 스크롤 동작
        selected: "",
        clicked: false,
        dragging: false,
        position: 0,

        template_ctg_list: [],        // 템플릿 카테고리 리스트
        template_tag_list: [],        // 템플릿 태그 리스트
        w_template_all_tag_list: {    // 가로 템플릿 카테고리별 태그 리스트
            bsn: [],
            usg: [],
            lyt: [],
            sty: [],
        },
        h_template_all_tag_list: {    // 세로 템플릿 카테고리별 태그 리스트
            bsn: [],
            usg: [],
            lyt: [],
            sty: [],
        },
        w_selected_tags: {             // 가로 템플릿 카테고리별 선택된 태그 리스트
            bsn: [],
            usg: [],
            lyt: [],
            sty: [],
        },
        h_selected_tags: {             // 세로 템플릿 카테고리별 선택된 태그 리스트
            bsn: [],
            usg: [],
            lyt: [],
            sty: [],
        },
        w_all_selected: {              // 가로 템플릿 카테고리별 전체 선택 여부
            bsn: false,
            usg: false,
            lyt: false,
            sty: false,
        },
        h_all_selected: {              // 세로 템플릿 카테고리별 전체 선택 여부
            bsn: false,
            usg: false,
            lyt: false,
            sty: false,
        },
        w_all_tag_selected: true,                     // 가로 템플릿 필터 전체 태그 버튼 선택 여부
        h_all_tag_selected: true,                     // 세로 템플릿 필터 전체 태그 버튼 선택 여부
        show_template_filter_popup: false,            // 템플릿 필터 팝업
        show_template_filter_popup_type: "03000",     // 템플릿 필터 팝업 가로/세로 타입 구분
    };

    componentWillMount() {
        const solution_type_cd = CookieUtil.getCookie("solution_type_cd");
        this.setState({ solution_type_cd : solution_type_cd });
        if(solution_type_cd == "09900"){
            this.setState({
                select_item_list : [
                    {
                        product_no: -1,
                        image: transparent_image,
                        detail_image: transparent_image,
                        retail_price: 100,
                    }
                ],
                product_no : -1,
                img_link : transparent_image,
                img_detail_link : transparent_image,
                img_third_link : transparent_image,
                img_fourth_link : transparent_image,
                h_img_link : transparent_image,
                h_img_detail_link : transparent_image,
                h_img_third_link : transparent_image,
                h_img_fourth_link : transparent_image,
            });
        }
    }

    componentDidMount(e) {
        /* 일반 변수 값 초기화 : 뒤로가기 및 앞으로 가기로 접근시 변경된 값이 초기화 되지 않는 현상 개선 */
        is_banner_color_init = false;
        is_mobile_banner_color_init = false;

        is_auto_sub_title_load = true;
        is_banner_ratio_loaded = false;

        is_free_finish = false;
        is_order = false;

        first_lodded_cafe24_item = false;
        first_lodded_sub_titie = false;
        first_lodded_pc_banner = false;
        first_lodded_mobile_banner = false;

        product_random_seed = 0;
        getBannerRatioCnt = 0;
        first_load_page_id_list = false;

        img_scale_value = 0;
        txt_scale_value = 0;

        w_nukki_action_state = true;
        h_nukki_action_state = true;
        /* 일반 변수 값 초기화 끝 */

        console.log("#### solution_type_cd ####", sessionStorage.getItem('solution_type_cd'));

        // 첫 미리보기(데이터 덮어쓰기 등) 실행 전, 설정 변경 방지
        $("#blockPageClick").show();

        fullCalendarInnerModal_width = $(document).width() * 1.4;

        var this_component = this;
        $(window).resize(function() {
            this_component.setHeight();

            if ($(".fullCalendarInnerModal .pcBanner").width() > 0) {
                fullCalendarInnerModal_pcbanner_width = $(".fullCalendarInnerModal .pcBanner").width();
            }
            if ($(".fullCalendarInnerModal .mobileBanner").width() > 0) {
                fullCalendarInnerModal_mobileBanner_width = $(".fullCalendarInnerModal .mobileBanner").width();
            }

            $(".item_content_box_category_area_body").css("max-height", $(".item_content_box_item_area_body").height());
            $(".item_content_box_category_area_body").css("overflow", "auto");
        });    

        $(".fullCalendarModal").hide();
        
        //this.getCafe24CodePaging("03000");		//배너타입 코드 조회
        //this.getCafe24CodePaging("07000");		//모바일배너타입 코드 조회
        this.getBannerDesignTemplateFilter();          // 배너 템플릿 필터 조회
        this.getBannerDesignTemplateUserFavoritePaging("03000");    // 배너타입 : 배너 템플릿 + 사용자 선호 템플릿 조회 (페이징)
        this.getBannerDesignTemplateUserFavoritePaging("07000");    // 모바일배너타입 : 배너 템플릿 + 사용자 선호 템플릿 조회 (페이징)
        this.getCafe24Code("17000", "simple_date_setting_list");		    //예약일정 및 노출일정 타입 조회
        this.loadCommonCodeForAutoEvent("16000", "page_list") //배너 page 조회
        this.getCafe24Code("23000", "language_code_list"); // 대제목 언어 선택 코드 조회
        this.getCafe24Code("50000", "img_position_type_list"); // 이미지 위치 이동 단위

        if(this.getUrlParams()["event_seq"]) {
            var event_seq = this.getUrlParams()["event_seq"];
            this.loadEventDetail(event_seq);
        } else {
            this.loadCafe24Item();
            this.loadProductCategory(this.state.parentCategory);
            this.getFullCalendarEventList("", ""); // 달력용 기획전 목록 조회
            this.loadCommonCodeForAutoEvent("30000", "default_bg_color_list") // 디폴트 컬러 조회
            this.onClickSimpleDateCd(null, 17005); // 항상 노출을 defalt값으로 사용
        }
        this.getTargetViewList(); // 타겟뷰 목록 조회
        
        for(var i=0; i<=23; i++ ) {
            const {hour_list} = this.state;
            hour_list.push({key:i , hour:i+"시"});
            this.setState({hour_list: hour_list});
        }
        for(var i=0; i<=59; i++ ) {
            const {minute_list} = this.state;
            minute_list.push({key:i, minute:i+"분"});
            this.setState({minute_list: minute_list});
        }
        
        // FullCalendar 달 변경 이벤트
        $(".fc-prev-button").click(function() {
            var year = $(".fc-toolbar-title").text().split("년")[0];
            var month =  $(".fc-toolbar-title").text().split("년")[1].replace("월", "");
            
            if(Number(month)-1 == 0) {
                year = Number(year)-1;
                month = 12;
            } else {
                month = Number(month)-1;
            }

            if(Number(month) < 10) {
                month = "0"+month;
            }

            this_component.getFullCalendarEventList(year, month);
        });
        $(".fc-next-button").click(function() {
            var year = $(".fc-toolbar-title").text().split("년")[0];
            var month =  $(".fc-toolbar-title").text().split("년")[1].replace("월", "");
            
            if(Number(month)+1 == 13) {
                year = Number(year)+1;
                month = 1;
            } else {
                month = Number(month)+1;
            }
            
            if(Number(month) < 10) {
                month = "0"+month;
            }
            
            this_component.getFullCalendarEventList(year, month);
        });

        // 메인/서브 텍스트 문구 내용에서 클립보드 복사시 텍스트만 복사하기
        document.querySelectorAll(".text_editor_title").forEach((title) => {
            title.addEventListener('paste', function(e){
                e.preventDefault();

                let pastedData = e.clipboardData || window.clipboardData;
                let textData = pastedData.getData('Text');
                textData = textData.replaceAll(/<[^>]*>/g, '')
                window.document.execCommand('insertHTML', false, textData);
            });
        });

        // 배너 큰 글자 제목 넣기
        if(this.state.none_title == null || this.state.none_title == "") {
            this.setState({none_title: "배너_" + StringUtil.dateFormater(new Date()).replaceAll('-', '')})
        }

        // iOS 에디터 폰트 리스트
        textEditorFont.map((obj) => {
            fontArray.push(obj.name);
        });
    }

    componentDidUpdate() {
        // 화면 스크롤 고정
        if( this.state.save_dialog_show_state
            || this.state.warning_dialog_show_state
            || this.state.input_dialog_show_state
            || this.state.basic_dialog_show_state
            || this.state.show_robo_img_popup
            || this.state.select_detail_crop_img_popup_show
            || this.state.showUploadYoutubeLinkPopup) {
            document.body.style.overflow = 'hidden'; // 스크롤 고정
        } else {
            document.body.style.overflow = 'auto'; // 스크롤 고정 해제
        }

        if (isTmp && this.state.mirror_banner_state == "on") {
            this.insertEventTmp('preview'); //미리보기 버튼 클릭시 동작 하도록 변경함.
            isTmp = false;
            this.setHeight();
        } else {
            isTmp = false;
        }

        // 상품 카테고리 영역 동적 높이 할당
        $(".item_content_box_category_area_body").css("max-height", $(".item_content_box_item_area_body").height());
        $(".item_content_box_category_area_body").css("overflow", "auto");

        if (is_banner_ratio_loaded && this.state.iframe_html_id && this.state.iframe_page_id) {
            this.getBannerRatio();
            this.setHeight();
            is_banner_ratio_loaded = false;
        }

        // 에디터에 적용된 폰트 추출
        document.querySelectorAll(".text_editor_title font").forEach((element) => {
            if(element.getAttribute("face") != null && element.getAttribute("face") != ""){
                font_family_list.push(element.getAttribute("face"));
                font_family_list = [...new Set(font_family_list)];
            }
        });

        // 에디터에 적용된 폰트 추출
        document.querySelectorAll(".text_editor_title span").forEach((element) => {
            let fontFamily = window.getComputedStyle(element).fontFamily;
            if(fontFamily != null && fontFamily != ""){
                font_family_list.push(fontFamily.replaceAll('\"', ''));
                font_family_list = [...new Set(font_family_list)];
            }
        });

        // 에디터에 적용된 폰트 추출(iOS)
        document.querySelectorAll(".main_editor_wysiwyg span").forEach((element) => {
            let fontFamily = window.getComputedStyle(element).fontFamily;
            if(fontFamily != null && fontFamily != ""){
                font_family_list.push(fontFamily.replaceAll('\"', ''));
                font_family_list = [...new Set(font_family_list)];
            }
        });

        // 에디터에 적용된 폰트 추출(iOS)
        document.querySelectorAll(".sub_editor_wysiwyg span").forEach((element) => {
            let fontFamily = window.getComputedStyle(element).fontFamily;
            if(fontFamily != null && fontFamily != ""){
                font_family_list.push(fontFamily.replaceAll('\"', ''));
                font_family_list = [...new Set(font_family_list)];
            }
        });
	}

    getUrlParams() {
        var params = {};
        window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(str, key, value) { params[key] = value; });
        return params;
    }

    loadCommonCode(upper_code_cd, banner_type) {
		http.get("/rest/common/cafe24Code?upper_code_cd="+upper_code_cd)
		.then(res => {
            console.log(res.data);
            for(var idx in res.data) {
                if(res.data[idx].code_cd === banner_type) {
                    this.setState({"lang_type": res.data[idx].val3});
                }
            }
        })
    }

    loadCommonCodeForAutoEvent(upper_code_cd, stateName) {
		http.get("/rest/common/cafe24AutoEventCode?upper_code_cd="+upper_code_cd)
		.then(res => {
            console.log(res.data);
            this.setState({
                [stateName]: res.data
            }, () => {
                // 배너 page 조회
                if (upper_code_cd == "16000") {
                    if ((this.getUrlParams()["sample_yn"] && this.getUrlParams()["sample_yn"] == "Y") 
                        || !this.getUrlParams()["event_seq"]) {
                        var name = "iframe_page_id"
                        this.setState({
                            // 디폴트 페이지선택
                            iframe_page_id: this.state[stateName][0].code_name,
                        }, () => {
                            this.getBannerHtmlList(this.state.iframe_page_id);
                        });
                    }
                }
                // 디폴트 컬러 조회
                if (upper_code_cd == "30000") {
                    for (var idx in this.state[stateName]) {
                        var name = ""
                        if (idx == 0){
                            name = "background"
                        } else {
                            name = "background"+(Number(idx)+1)
                        }
                        this.setState({
                            [name]:this.state[stateName][idx].val1
                        })
                    }
                }
            }); 
        })
    }

    // iOS 텍스트 에디터의 경우 font 태그 대신 span 태그를 사용하므로 font 태그를 span 태그로 변환
    convertFontTagToSpan = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const fontTags = doc.querySelectorAll('font');

        // font 태그를 span 태그로 변환
        fontTags.forEach((fontTag) => {
            const spanTag = document.createElement('span');

            // face -> font-family
            const fontFace = fontTag.getAttribute('face');
            if(fontFace){
                spanTag.style.fontFamily = fontFace;
            }

            // color
            const fontColor = fontTag.getAttribute('color');
            if(fontColor){
                spanTag.style.color = fontColor;
            }

            // background-color
            const styleAttribute = fontTag.getAttribute('style');
            if (styleAttribute) {
                const styleParts = styleAttribute.split(';');
                styleParts.forEach((stylePart) => {
                    const [property, value] = stylePart.split(':').map((s) => s.trim().toLowerCase());
                    if (property === 'background-color') {
                        spanTag.style.backgroundColor = value;
                    }
                });
            }

            spanTag.innerHTML = fontTag.innerHTML;
            fontTag.parentNode.replaceChild(spanTag, fontTag);
        });

        return doc.body.innerHTML;
    }

    loadEventDetail(event_seq) {
		http.get("/rest/common/admin/event/detail?event_seq="+event_seq)
		.then(res => {
            if (res.data.err_code == 401) {
                window.location.href = "/logout";
            } else if (res.data.err_code == 404) {
                alert("접근할 수 없는 배너입니다.");
                window.location.href = "/shop/cafe24/event/list";
            } else {
                var event = res.data["event"];
                let timeFormater = function(time){
                    if (Number(time) < 10) {
                        if(time == "00") {
                            return "0";
                        } else {
                            return time.replace("0", "");
                        }
                    } else{
                        return time;
                    }
                };

                if(event) {
                    var start_date = new Date(event.start_date);
                    var end_date = new Date(event.end_date);
                    var start_hour = timeFormater(event.start_hour);
                    var start_minute = timeFormater(event.start_minute);
                    var end_hour = timeFormater(event.end_hour);
                    var end_minute = timeFormater(event.end_minute);
                    var start_date_format = start_date.getFullYear() + "-"+ ("0" + parseInt(start_date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(start_date.getDate())).slice(-2)+" "+start_hour+":"+start_minute+":00";
                    var end_date_format = end_date.getFullYear() + "-"+ ("0" + parseInt(end_date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(end_date.getDate())).slice(-2)+" "+end_hour+":"+end_minute+":59";

                    let is_detail_image_use = false;
                    if (event.image_type_cd == "46002") {
                        is_detail_image_use = true
                    }

                    let w_img_position_name = this.addBannerDesignPositionText(res.data.w_banner_design_position_img_list);
                    let h_img_position_name = this.addBannerDesignPositionText(res.data.h_banner_design_position_img_list);

                    // iOS 텍스트 에디터의 경우 font 태그 대신 span 태그를 사용하므로 변환
                    let mainEditorText = this.convertFontTagToSpan(event.title);
                    let subEditorText = this.convertFontTagToSpan(event.foreign_title);

                    this.setState({
                        event_seq: event.event_seq,
                        pre_iframe_html_id: event.pre_iframe_html_id,
                        start_date: start_date,
                        start_hour: start_hour,
                        start_minute: start_minute,
                        start_date_format: start_date_format,
                        end_date: end_date,
                        end_hour: end_hour,
                        end_minute: end_minute,
                        end_date_format: end_date_format,
                        simple_date_cd: event.simple_date_cd,

                        start_date_format_calendar: start_date.getFullYear() + "-"+ ("0" + parseInt(start_date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(start_date.getDate())).slice(-2)
                                    +" "+event.start_hour+":"+event.start_minute+":00.000+0900",
                        end_date_format_calendar: end_date.getFullYear() + "-"+ ("0" + parseInt(end_date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(end_date.getDate())).slice(-2)
                                    +" "+event.end_hour+":"+event.end_minute+":59.000+0900",
                        book_yn : new Date(start_date_format) > new Date() ? "Y" : "N",
                        lang_type: event.lang_type,
                        none_title: !event.none_title ? "" : event.none_title,
                        auto_title: event.title && event.title.replaceAll("font-size: 15px;", ""),
                        non_auto_title: event.title && event.title.replaceAll("font-size: 15px;", ""),
                        title: event.title && event.title.replaceAll("font-size: 15px;", ""),
                        sub_title: event.foreign_title && event.foreign_title.replaceAll("font-size: 15px;", ""),
                        foreign_title: event.foreign_title && event.foreign_title.replaceAll("font-size: 15px;", ""),
                        org_title: event.org_title,
                        event_type_cd: event.event_type_cd,
                        event_mobile_type_cd: event.event_mobile_type_cd,
                        event_type_name: event.event_type_name,
                        event_type_img: event.event_type_img,
                        event_mobile_type_name: event.event_mobile_type_name,
                        event_mobile_type_img: event.event_mobile_type_img,
                        event_pc_banner_name: event.event_pc_banner_name,
                        event_mobile_banner_name: event.event_mobile_banner_name,
                        event_pc_tag_cd_list: (event.event_pc_tag_cd_list || "").toString().split(","),
                        event_pc_tag_name_list: (event.event_pc_tag_name_list || "").toString().split(","),
                        event_mobile_tag_cd_list: (event.event_mobile_tag_cd_list || "").toString().split(","),
                        event_mobile_tag_name_list: (event.event_mobile_tag_name_list || "").toString().split(","),
                        keyword_cd: event.keyword_cd,
                        keyword: event.keyword,
                        image: event.img_link,
                        image_type_cd: event.image_type_cd,
                        is_soldout_display : event.is_soldout_display,
                        gpt_main_keyword : event.gpt_main_keyword,
                        event_price : event.event_price,
    
                        user_seq: event.user_seq,
                        shop_no: event.shop_no,
                        display_yn: event.display_yn,
                        title_sentence_cd: event.title_sentence_cd,
                        content_sentence_cd: event.content_sentence_cd,
                        sentence: event.sentence,
                        eng_sentence: event.eng_sentence,
                        reg_user_seq: event.reg_user_seq,
                        reg_date: event.reg_date,
                        mod_date: event.mod_date,
                        dominant_color: event.dominant_color, // 현재 사용여부 알수없음
                        is_product_change: event.is_product_change,
                        w_color1_is_use: event.w_color1_is_use,
                        w_color2_is_use: event.w_color2_is_use,
                        w_color3_is_use: event.w_color3_is_use,
                        w_color4_is_use: event.w_color4_is_use,
                        h_color1_is_use: event.h_color1_is_use,
                        h_color2_is_use: event.h_color2_is_use,
                        h_color3_is_use: event.h_color3_is_use,
                        h_color4_is_use: event.h_color4_is_use,
                        w_color_etc_is_use: event.w_color_etc_is_use,
                        h_color_etc_is_use: event.h_color_etc_is_use,
                        w_img1_is_use: event.w_img1_is_use,
                        w_img2_is_use: event.w_img2_is_use,
                        w_img3_is_use: event.w_img3_is_use,
                        w_img4_is_use: event.w_img4_is_use,
                        h_img1_is_use: event.h_img1_is_use,
                        h_img2_is_use: event.h_img2_is_use,
                        h_img3_is_use: event.h_img3_is_use,
                        h_img4_is_use: event.h_img4_is_use,
                        background: event.color_code_1,
                        background2: event.color_code_2,
                        background3: event.color_code_3,
                        background4: event.color_code_4,

                        color_value_1 : event.color_value_1,
                        color_value_2 : event.color_value_2,
                        color_value_3 : event.color_value_3,
                        color_value_4 : event.color_value_4,

                        color_lmnst_1 : event.color_lmnst_1,
                        color_lmnst_2 : event.color_lmnst_2,
                        color_lmnst_3 : event.color_lmnst_3,
                        color_lmnst_4 : event.color_lmnst_4,

                        color_strtn_1 : event.color_strtn_1, 
                        color_strtn_2 : event.color_strtn_2,
                        color_strtn_3 : event.color_strtn_3,
                        color_strtn_4 : event.color_strtn_4,

                        color_hue_1 : event.color_hue_1, 
                        color_hue_2 : event.color_hue_2,
                        color_hue_3 : event.color_hue_3,
                        color_hue_4 : event.color_hue_4,

                        link_url: event.link_url,
                        is_link_url : event.link_url ? "Y" : "N",
                        facial_x_start: event.facial_x_start,
                        facial_y_start: event.facial_y_start,
                        facial_width: event.facial_width,
                        facial_height: event.facial_height,
                        facial_yn: event.facial_yn,
                        origin_img_width: event.origin_img_width,
                        origin_img_height: event.origin_img_height,

                        // 기존에는 px를 사용하는것만 존재했기 때문에 null값이라면 px(50001)로 설정
                        img_position_1_type : event.img_position_1_type ?? "50001",
                        img_position_2_type : event.img_position_2_type ?? "50001",
                        img_position_3_type : event.img_position_3_type ?? "50001",
                        img_position_4_type : event.img_position_4_type ?? "50001",
                        h_img_position_1_type : event.h_img_position_1_type ?? "50001",
                        h_img_position_2_type : event.h_img_position_2_type ?? "50001",
                        h_img_position_3_type : event.h_img_position_3_type ?? "50001",
                        h_img_position_4_type : event.h_img_position_4_type ?? "50001",

                        w_large_text_color : event.w_large_text_color,
                        h_large_text_color : event.h_large_text_color,
                        w_small_text_color : event.w_small_text_color,
                        h_small_text_color : event.h_small_text_color,
                        w_color_etc: event.w_color_etc,
                        h_color_etc: event.h_color_etc,

                        img_link: event.img_link == "null" ? "" : event.img_link,
                        img_detail_link: event.img_detail_link == "null" ? "" : event.img_detail_link,
                        img_third_link: event.img_third_link == "null" ? "" : event.img_third_link,
                        img_fourth_link: event.img_fourth_link == "null" ? "" : event.img_fourth_link,
                        h_img_link: event.h_img_link == "null" ? "" : event.h_img_link,
                        h_img_detail_link: event.h_img_detail_link == "null" ? "" : event.h_img_detail_link,
                        h_img_third_link: event.h_img_third_link == "null" ? "" : event.h_img_third_link,
                        h_img_fourth_link: event.h_img_fourth_link == "null" ? "" : event.h_img_fourth_link,

                        robo_banner_org_img_link_seq : event.robo_banner_org_img_link_seq,
                        robo_banner_org_img_detail_link_seq: event.robo_banner_org_img_detail_link_seq,
                        robo_banner_org_img_third_link_seq: event.robo_banner_org_img_third_link_seq,
                        robo_banner_org_img_fourth_link_seq: event.robo_banner_org_img_fourth_link_seq,
                        h_robo_banner_org_img_link_seq : event.h_robo_banner_org_img_link_seq,
                        h_robo_banner_org_img_detail_link_seq: event.h_robo_banner_org_img_detail_link_seq,
                        h_robo_banner_org_img_third_link_seq: event.h_robo_banner_org_img_third_link_seq,
                        h_robo_banner_org_img_fourth_link_seq: event.h_robo_banner_org_img_fourth_link_seq,

                        w_crop_img_info_list : res.data.w_crop_img_info_list,
                        h_crop_img_info_list : res.data.h_crop_img_info_list,

                        is_detail_image_use: is_detail_image_use,

                        w_img_nukki_yn : event.w_img_nukki_yn,
                        h_img_nukki_yn : event.h_img_nukki_yn,
                        w_img_detail_nukki_yn : event.w_img_detail_nukki_yn,
                        h_img_detail_nukki_yn : event.h_img_detail_nukki_yn,
                        w_img_third_nukki_yn : event.w_img_third_nukki_yn,
                        h_img_third_nukki_yn : event.h_img_third_nukki_yn,
                        w_img_fourth_nukki_yn : event.w_img_fourth_nukki_yn,
                        h_img_fourth_nukki_yn : event.h_img_fourth_nukki_yn,

                        w_img_nukki_ppm_yn : event.w_img_nukki_ppm_yn,
                        w_img_detail_nukki_ppm_yn : event.w_img_detail_nukki_ppm_yn,
                        w_img_third_nukki_ppm_yn : event.w_img_third_nukki_ppm_yn,
                        w_img_fourth_nukki_ppm_yn : event.w_img_fourth_nukki_ppm_yn,
                        h_img_nukki_ppm_yn : event.h_img_nukki_ppm_yn,
                        h_img_detail_nukki_ppm_yn : event.h_img_detail_nukki_ppm_yn,
                        h_img_third_nukki_ppm_yn : event.h_img_third_nukki_ppm_yn,
                        h_img_fourth_nukki_ppm_yn :event.h_img_fourth_nukki_ppm_yn,

                        w_img_nukki_version : event.w_img_nukki_version,
                        w_img_detail_nukki_version : event.w_img_detail_nukki_version,
                        w_img_third_nukki_version : event.w_img_third_nukki_version,
                        w_img_fourth_nukki_version : event.w_img_fourth_nukki_version,
                        h_img_nukki_version : event.h_img_nukki_version,
                        h_img_detail_nukki_version : event.h_img_detail_nukki_version,
                        h_img_third_nukki_version : event.h_img_third_nukki_version,
                        h_img_fourth_nukki_version : event.h_img_fourth_nukki_version,

                        w_nukki_img_link : (!event.w_nukki_img_link || event.w_nukki_img_link == "undefined") ? "" : event.w_nukki_img_link,
                        w_nukki_img_detail_link : (!event.w_nukki_img_detail_link || event.w_nukki_img_detail_link == "undefined") ? "" : event.w_nukki_img_detail_link,
                        w_nukki_img_third_link : (!event.w_nukki_img_third_link || event.w_nukki_img_third_link == "undefined") ? "" : event.w_nukki_img_third_link,
                        w_nukki_img_fourth_link : (!event.w_nukki_img_fourth_link || event.w_nukki_img_fourth_link == "undefined") ? "" : event.w_nukki_img_fourth_link,
                        h_nukki_img_link : (!event.h_nukki_img_link || event.h_nukki_img_link == "undefined") ? "" : event.h_nukki_img_link,
                        h_nukki_img_detail_link : (!event.h_nukki_img_detail_link || event.h_nukki_img_detail_link == "undefined") ? "" : event.h_nukki_img_detail_link,
                        h_nukki_img_third_link : (!event.h_nukki_img_third_link || event.h_nukki_img_third_link == "undefined") ? "" : event.h_nukki_img_third_link,
                        h_nukki_img_fourth_link : (!event.h_nukki_img_fourth_link || event.h_nukki_img_fourth_link == "undefined") ? "" : event.h_nukki_img_fourth_link,

                        w_img_link_type : event.w_img_link_type,
                        w_img_detail_link_type : event.w_img_detail_link_type,
                        w_img_third_link_type : event.w_img_third_link_type,
                        w_img_fourth_link_type : event.w_img_fourth_link_type,
                        h_img_link_type : event.h_img_link_type,
                        h_img_detail_link_type : event.h_img_detail_link_type,
                        h_img_third_link_type : event.h_img_third_link_type,
                        h_img_fourth_link_type : event.h_img_fourth_link_type,

                        w_img_49002_link : (!event.w_img_49002_link || event.w_img_49002_link == "undefined") ? "" : event.w_img_49002_link,
                        w_img_detail_49002_link : (!event.w_img_detail_49002_link || event.w_img_detail_49002_link == "undefined") ? "" : event.w_img_detail_49002_link,
                        w_img_third_49002_link : (!event.w_img_third_49002_link || event.w_img_third_49002_link == "undefined") ? "" : event.w_img_third_49002_link,
                        w_img_fourth_49002_link : (!event.w_img_fourth_49002_link || event.w_img_fourth_49002_link == "undefined") ? "" : event.w_img_fourth_49002_link,
                        h_img_49002_link : (!event.h_img_49002_link || event.h_img_49002_link == "undefined") ? "" : event.h_img_49002_link,
                        h_img_detail_49002_link : (!event.h_img_detail_49002_link || event.h_img_detail_49002_link == "undefined") ? "" : event.h_img_detail_49002_link,
                        h_img_third_49002_link : (!event.h_img_third_49002_link || event.h_img_third_49002_link == "undefined") ? "" : event.h_img_third_49002_link,
                        h_img_fourth_49002_link : (!event.h_img_fourth_49002_link || event.h_img_fourth_49002_link == "undefined") ? "" : event.h_img_fourth_49002_link,

                        w_img_49004_link: event.w_img_49004_link,
                        w_img_detail_49004_link: event.w_img_detail_49004_link,
                        w_img_third_49004_link: event.w_img_third_49004_link,
                        w_img_fourth_49004_link: event.w_img_fourth_49004_link,
                        h_img_49004_link: event.h_img_49004_link,
                        h_img_detail_49004_link: event.h_img_detail_49004_link,
                        h_img_third_49004_link: event.h_img_third_49004_link,
                        h_img_fourth_49004_link: event.h_img_fourth_49004_link,

                        youtube_link : event.youtube_link,
                        youtube_detail_link : event.youtube_detail_link,
                        youtube_third_link : event.youtube_third_link,
                        youtube_fourth_link : event.youtube_fourth_link,
                        h_youtube_link : event.h_youtube_link,
                        h_youtube_detail_link : event.h_youtube_detail_link,
                        h_youtube_third_link : event.h_youtube_third_link,
                        h_youtube_fourth_link : event.h_youtube_fourth_link,
                        youtube_thumbnail : event.youtube_thumbnail,
                        youtube_detail_thumbnail : event.youtube_detail_thumbnail,
                        youtube_third_thumbnail : event.youtube_third_thumbnail,
                        youtube_fourth_thumbnail : event.youtube_fourth_thumbnail,
                        h_youtube_thumbnail : event.h_youtube_thumbnail,
                        h_youtube_detail_thumbnail : event.h_youtube_detail_thumbnail,
                        h_youtube_third_thumbnail : event.h_youtube_third_thumbnail,
                        h_youtube_fourth_thumbnail : event.h_youtube_fourth_thumbnail,
                        w_is_youtube_use: event.w_is_youtube_use,
                        w_is_youtube_detail_use: event.w_is_youtube_detail_use,
                        w_is_youtube_third_use: event.w_is_youtube_third_use,
                        w_is_youtube_fourth_use: event.w_is_youtube_fourth_use,
                        h_is_youtube_use: event.h_is_youtube_use,
                        h_is_youtube_detail_use: event.h_is_youtube_detail_use,
                        h_is_youtube_third_use: event.h_is_youtube_third_use,
                        h_is_youtube_fourth_use: event.h_is_youtube_fourth_use,

                        event_type_cd_use_yn : event.event_type_cd_use_yn,
                        event_mobile_cd_use_yn : event.event_mobile_cd_use_yn,

                        w_img_position_name : w_img_position_name,
                        h_img_position_name : h_img_position_name,
                        w_essential_img_nukki_yn : res.data.w_essential_img_nukki_yn,
                        h_essential_img_nukki_yn : res.data.h_essential_img_nukki_yn,
                        w_is_nukki_ppm_yn : res.data.w_is_nukki_ppm_yn,
                        h_is_nukki_ppm_yn : res.data.h_is_nukki_ppm_yn,
                        w_is_nukki_multi_use_yn : res.data.w_is_nukki_multi_use_yn,
                        h_is_nukki_multi_use_yn : res.data.h_is_nukki_multi_use_yn,
                        w_is_nukki_crop_yn : res.data.w_is_nukki_crop_yn,
                        h_is_nukki_crop_yn : res.data.h_is_nukki_crop_yn,
                        w_is_date_use : res.data.w_is_date_use,
                        h_is_date_use : res.data.h_is_date_use,
                        w_is_price_use : res.data.w_is_price_use,
                        h_is_price_use : res.data.h_is_price_use,
                        w_is_button_use : res.data.w_is_button_use,
                        h_is_button_use : res.data.h_is_button_use,
                        w_is_youtube_link_use : res.data.w_is_youtube_link_use,
                        h_is_youtube_link_use : res.data.h_is_youtube_link_use,

                        // 미리보기 사이즈 입력값
                        event_banner_pc_width : event.event_banner_pc_width == "0" ? "" : event.event_banner_pc_width,
                        event_banner_pc_height : event.event_banner_pc_height  == "0" ? "" : event.event_banner_pc_height,
                        event_banner_mobile_width : event.event_banner_mobile_width  == "0" ? "" : event.event_banner_mobile_width,
                        event_banner_mobile_height : event.event_banner_mobile_height  == "0" ? "" : event.event_banner_mobile_height,
                        		
                		// 버튼 리스트
                        button_list : event.button_list,

                        // 텍스트 위치 이동
                        w_main_txt_position_x : event.w_main_txt_position_x,
                        w_main_txt_position_y : event.w_main_txt_position_y,
                        h_main_txt_position_x : event.h_main_txt_position_x,
                        h_main_txt_position_y : event.h_main_txt_position_y,
                        w_sub_txt_position_x : event.w_sub_txt_position_x,
                        w_sub_txt_position_y : event.w_sub_txt_position_y,
                        h_sub_txt_position_x : event.h_sub_txt_position_x,
                        h_sub_txt_position_y : event.h_sub_txt_position_y,

                        // 이미지 크기 조절
                        w_img_1_scale : event.w_img_1_scale,
                        w_img_2_scale : event.w_img_2_scale,
                        w_img_3_scale : event.w_img_3_scale,
                        w_img_4_scale : event.w_img_4_scale,
                        h_img_1_scale : event.h_img_1_scale,
                        h_img_2_scale : event.h_img_2_scale,
                        h_img_3_scale : event.h_img_3_scale,
                        h_img_4_scale : event.h_img_4_scale,

                        // 텍스트 크기 조절
                        w_main_txt_scale : event.w_main_txt_scale,
                        h_main_txt_scale : event.h_main_txt_scale,
                        w_sub_txt_scale : event.w_sub_txt_scale,
                        h_sub_txt_scale : event.h_sub_txt_scale,

                        // iOS 텍스트 에디터
                        mainEditorState: this.htmlToEditor(mainEditorText),
                        subEditorState: this.htmlToEditor(subEditorText),

                        selected_target_view_list : event.target_view_list,

                    }, () => {
                        this.getisUsePallete();

                        let w_nukki_ppm_state = [false, false, false, false];
                        let h_nukki_ppm_state = [false, false, false, false];
                        let w_nukki_version_list = [1,1,1,1];
                        let h_nukki_version_list = [1,1,1,1];
                        let {w_user_img_list, h_user_img_list} = this.state;
                        
                        // 업로드 이미지, 링크형 이미지, pmm 상태 데이터 담는 작업
                        /** 가로형 */
                        for(var i=0;i<this.state.w_img_position_name.length;i++){
                            w_user_img_list[i].link_type = this.state["w_"+this.state.w_img_position_name[i].position_name+"_link_type"];
                            if(this.state["robo_banner_org_"+this.state.w_img_position_name[i].position_name+"_link_seq"]){
                                this.searchOrgImg(this.state["robo_banner_org_"+this.state.w_img_position_name[i].position_name+"_link_seq"], i, "w", true); // 첨부 이미지 조회
                            }
                            if(w_user_img_list[i].link_type == "49002"){
                                const img_link = this.state["w_"+this.state.w_img_position_name[i].position_name+"_49002_link"];
                                const idx = i;
                                this.checkImgLinkUseAble(img_link, () => {
                                    var {w_user_img_list} = this.state;
                                    w_user_img_list[idx].img_link_able = true;
                                    this.setState({w_user_img_list : w_user_img_list,});
                                });

                                w_user_img_list[i].img_link = img_link;
                                w_user_img_list[i].org_img_name = img_link;
                            }
                            if(w_user_img_list[i].link_type == "49003"){
                                let crop_img_info = this.state.w_crop_img_info_list[i];
                                let img_link = crop_img_info.crop_img_link_list ? crop_img_info.crop_img_link_list[crop_img_info.crop_img_seleted_no] : "";
                                w_user_img_list[i].img_link = img_link;
                                w_user_img_list[i].org_img_name = img_link;
                            }
                            if(w_user_img_list[i].link_type == "49004"){
                                const img_link = this.state["w_"+this.state.w_img_position_name[i].position_name+"_49004_link"];
                                const idx = i;
                                this.checkImgLinkUseAble(img_link, () => {
                                    var {w_user_img_list} = this.state;
                                    w_user_img_list[idx].img_link_able = true;
                                    this.setState({w_user_img_list : w_user_img_list,});
                                });

                                w_user_img_list[i].img_link = img_link;
                                w_user_img_list[i].org_img_name = img_link;
                            }
                            if(w_user_img_list[i].link_type == "49005"){
                                const video_link = this.state[this.state.w_img_position_name[i].youtube_position_name+"_link"];
                                const thumbnail = this.state[this.state.w_img_position_name[i].youtube_position_name+"_thumbnail"];

                                w_user_img_list[i].img_link = thumbnail;
                                w_user_img_list[i].video_link = video_link;
                                w_user_img_list[i].org_img_name = video_link;
                            }
                            if(this.state["w_"+this.state.w_img_position_name[i].position_name+"_nukki_ppm_yn"] == "Y"){ // ppm 상태값 조회
                                w_nukki_ppm_state[i] = true;
                            }
                            w_nukki_version_list[i] = this.state["w_"+this.state.w_img_position_name[i].position_name+"_nukki_version"] ?? 1;
                        }

                        /** 세로형 */
                        for(var i=0;i<this.state.h_img_position_name.length;i++){
                            h_user_img_list[i].link_type = this.state["h_"+this.state.h_img_position_name[i].position_name+"_link_type"];
                            if(this.state["h_robo_banner_org_"+this.state.h_img_position_name[i].position_name+"_link_seq"]){
                                this.searchOrgImg(this.state["h_robo_banner_org_"+this.state.h_img_position_name[i].position_name+"_link_seq"], i, "h", true); // 첨부 이미지 조회
                            }
                            if(h_user_img_list[i].link_type == "49002"){
                                const img_link = this.state["h_"+this.state.h_img_position_name[i].position_name+"_49002_link"];
                                const idx = i;
                                this.checkImgLinkUseAble(img_link, () => {
                                    var {h_user_img_list} = this.state;
                                    h_user_img_list[idx].img_link_able = true;
                                    this.setState({h_user_img_list : h_user_img_list,});
                                });

                                h_user_img_list[i].img_link = img_link;
                                h_user_img_list[i].org_img_name = img_link;
                            }
                            if(h_user_img_list[i].link_type == "49003"){
                                let crop_img_info = this.state.h_crop_img_info_list[i];
                                let img_link = crop_img_info.crop_img_link_list ? crop_img_info.crop_img_link_list[crop_img_info.crop_img_seleted_no] : "";
                                h_user_img_list[i].img_link = img_link;
                                h_user_img_list[i].org_img_name = img_link;
                            }
                            if(h_user_img_list[i].link_type == "49004"){
                                const img_link = this.state["h_"+this.state.h_img_position_name[i].position_name+"_49004_link"];
                                const idx = i;
                                this.checkImgLinkUseAble(img_link, () => {
                                    var {h_user_img_list} = this.state;
                                    h_user_img_list[idx].img_link_able = true;
                                    this.setState({h_user_img_list : h_user_img_list,});
                                });

                                h_user_img_list[i].img_link = img_link;
                                h_user_img_list[i].org_img_name = img_link;
                            }
                            if(h_user_img_list[i].link_type == "49005"){
                                const video_link = this.state["h_"+this.state.h_img_position_name[i].youtube_position_name+"_link"];
                                const thumbnail = this.state["h_"+this.state.h_img_position_name[i].youtube_position_name+"_thumbnail"];

                                h_user_img_list[i].img_link = thumbnail;
                                h_user_img_list[i].video_link = video_link;
                            }
                            if(this.state["h_"+this.state.h_img_position_name[i].position_name+"_nukki_ppm_yn"] == "Y"){
                                h_nukki_ppm_state[i] = true;
                            }
                            h_nukki_version_list[i] = this.state["h_"+this.state.h_img_position_name[i].position_name+"_nukki_version"] ?? 1;
                        }

                        // 저장된 데이터를 불러왔을때 이미지가 사용되지 않는 영역의 누끼 이미지를 초기화
                        let non_use_w_position_name = JSON.parse(JSON.stringify(img_order_text));
                        let non_use_h_position_name = JSON.parse(JSON.stringify(img_order_text));
                        for(var i=0;i<4;i++){
                            if(this.state["w_img"+(i+1)+"_is_use"] == "Y"){
                                non_use_w_position_name.splice(non_use_w_position_name.indexOf(this.state.w_img_position_name[i].position_name),1);
                            }
                            if(this.state["h_img"+(i+1)+"_is_use"] == "Y"){
                                non_use_h_position_name.splice(non_use_h_position_name.indexOf(this.state.h_img_position_name[i].position_name),1);
                            }
                        }
                        for(var i=0;i<non_use_w_position_name.length;i++){
                            this.setState({["w_nukki_"+non_use_w_position_name[i]+"_link"] : ""});
                        }
                        for(var i=0;i<non_use_h_position_name.length;i++){
                            this.setState({["h_nukki_"+non_use_h_position_name[i]+"_link"] : ""});
                        }
                        this.setState({
                            w_nukki_ppm_state: w_nukki_ppm_state,
                            h_nukki_ppm_state: h_nukki_ppm_state,
                            w_nukki_version_list : w_nukki_version_list,
                            h_nukki_version_list : h_nukki_version_list,
                            w_user_img_list : w_user_img_list,
                            h_user_img_list : h_user_img_list,
                        }, () => {
                            var event_seq = this.getUrlParams()["event_seq"];
                            this.loadEventDetailProduct(event_seq);
                        });

                        if (this.getUrlParams()["sample_yn"] != "Y") {
                            // 샘플 배너 일 때, DB 저장값 null이라서 사용 하지 않음. 디폴트값으로 대체.
                            this.setState({
                                item_user_type: event.item_user_type,
                                iframe_page_id: event.iframe_page_id,
                                iframe_html_id: event.iframe_html_id,
                            });

                            // 자동 배너 설정 불러오기
                            this.getAutoEventSetting(event.iframe_page_id, event.iframe_html_id).then(r => {});
                            // 파일명 규칙 설정 불러오기
                            this.getFileNameSettingList(event.iframe_page_id, event.iframe_html_id).then(r => {});
                        }

                        is_banner_ratio_loaded = true;

                        // 사용자 멀티샵 서비스 무료체험, 결제여부 확인
                        this.fn_shopServiceOperated("01009");   // 01009 : 타겟뷰 서비스
                    });


                    for(var i=1;i<=4;i++){
                        /* 가로형 이미지 가로 위치 */
                        if (event["img_position_x_"+i]) {
                            if (event["img_position_x_"+i] > 0) {
                                this.setState({
                                    ["w_img_position_left_"+i]: 0,
                                    ["w_img_position_right_"+i]: Number(event["img_position_x_"+i]),
                                })
                            } else {
                                this.setState({
                                    ["w_img_position_left_"+i]: -1 * Number(event["img_position_x_"+i]),
                                    ["w_img_position_right_"+i]: 0,
                                })
                            }
                        }
                        /* 가로형 이미지 세로 위치 */
                        if (event["img_position_y_"+i]) {
                            if (event["img_position_y_"+i] > 0) {
                                this.setState({
                                    ["w_img_position_bottom_"+i]: Number(event["img_position_y_"+i]),
                                    ["w_img_position_top_"+i]: 0,
                                })
                            } else {
                                this.setState({
                                    ["w_img_position_bottom_"+i]: 0,
                                    ["w_img_position_top_"+i]: -1 * Number(event["img_position_y_"+i]),
                                })
                            }
                        }
                        /* 세로형 이미지 가로 위치 */
                        if (event["h_img_position_x_"+i]) {
                            if (event["h_img_position_x_"+i] > 0) {
                                this.setState({
                                    ["h_img_position_left_"+i]: 0,
                                    ["h_img_position_right_"+i]: Number(event["h_img_position_x_"+i]),
                                })
                            } else {
                                this.setState({
                                    ["h_img_position_left_"+i]: -1 * Number(event["h_img_position_x_"+i]),
                                    ["h_img_position_right_"+i]: 0,
                                })
                            }
                        }
                        /* 세로형 이미지 세로 위치 */
                        if (event["h_img_position_y_"+i]) {
                            if (event["h_img_position_y_"+i] > 0) {
                                this.setState({
                                    ["h_img_position_bottom_"+i]: Number(event["h_img_position_y_"+i]),
                                    ["h_img_position_top_"+i]: 0,
                                })
                            } else {
                                this.setState({
                                    ["h_img_position_bottom_"+i]: 0,
                                    ["h_img_position_top_"+i]: -1 * Number(event["h_img_position_y_"+i]),
                                })
                            }
                        }
                    }

                    if (event.simple_date_cd && event.simple_date_cd == "17001") {
                        this.state.disabledStartDate = false;
                        this.state.disabledEndDate = false; 
                    } else {
                        this.state.disabledStartDate = true;
                        this.state.disabledEndDate = true;
                    }

                    // 페이지 로드시 배너 설정값 미리보기 테이블에 덮어쓰기
                    const nukki_info = { page_load : true, };
                    this.insertEventTmp('preview', nukki_info, true);

                    // 텍스트 위치 이동
                    if(event.w_main_txt_position_x == undefined || event.w_main_txt_position_x == null){
                        event.w_main_txt_position_x = 0;
                    }
                    if(event.w_main_txt_position_y == undefined || event.w_main_txt_position_y == null){
                        event.w_main_txt_position_y = 0;
                    }
                    if(event.h_main_txt_position_x == undefined || event.h_main_txt_position_x == null){
                        event.h_main_txt_position_x = 0;
                    }
                    if(event.h_main_txt_position_y == undefined || event.h_main_txt_position_y == null){
                        event.h_main_txt_position_y = 0;
                    }
                    if(event.w_sub_txt_position_x == undefined || event.w_sub_txt_position_x == null){
                        event.w_sub_txt_position_x = 0;
                    }
                    if(event.w_sub_txt_position_y == undefined || event.w_sub_txt_position_y == null){
                        event.w_sub_txt_position_y = 0;
                    }
                    if(event.h_sub_txt_position_x == undefined || event.h_sub_txt_position_x == null){
                        event.h_sub_txt_position_x = 0;
                    }
                    if(event.h_sub_txt_position_y == undefined || event.h_sub_txt_position_y == null){
                        event.h_sub_txt_position_y = 0;
                    }

                    if(Number(event.w_main_txt_position_x) < 0){
                        this.setState({ w_main_txt_position_left : Math.abs(Number(event.w_main_txt_position_x))});
                    }else{
                        this.setState({ w_main_txt_position_right : Math.abs(Number(event.w_main_txt_position_x))});
                    }
                    if(Number(event.w_main_txt_position_y) < 0){
                        this.setState({ w_main_txt_position_bottom : Math.abs(Number(event.w_main_txt_position_y))});
                    }else{
                        this.setState({ w_main_txt_position_top : Math.abs(Number(event.w_main_txt_position_y))});
                    }
                    if(Number(event.h_main_txt_position_x) < 0){
                        this.setState({ h_main_txt_position_left : Math.abs(Number(event.h_main_txt_position_x))});
                    }else{
                        this.setState({ h_main_txt_position_right : Math.abs(Number(event.h_main_txt_position_x))});
                    }
                    if(Number(event.h_main_txt_position_y) < 0){
                        this.setState({ h_main_txt_position_bottom : Math.abs(Number(event.h_main_txt_position_y))});
                    }else{
                        this.setState({ h_main_txt_position_top : Math.abs(Number(event.h_main_txt_position_y))});
                    }

                    if(Number(event.w_sub_txt_position_x) < 0){
                        this.setState({ w_sub_txt_position_left : Math.abs(Number(event.w_sub_txt_position_x))});
                    }else{
                        this.setState({ w_sub_txt_position_right : Math.abs(Number(event.w_sub_txt_position_x))});
                    }
                    if(Number(event.w_sub_txt_position_y) < 0){
                        this.setState({ w_sub_txt_position_bottom : Math.abs(Number(event.w_sub_txt_position_y))});
                    }else{
                        this.setState({ w_sub_txt_position_top : Math.abs(Number(event.w_sub_txt_position_y))});
                    }
                    if(Number(event.h_sub_txt_position_x) < 0){
                        this.setState({ h_sub_txt_position_left : Math.abs(Number(event.h_sub_txt_position_x))});
                    }else{
                        this.setState({ h_sub_txt_position_right : Math.abs(Number(event.h_sub_txt_position_x))});
                    }
                    if(Number(event.h_sub_txt_position_y) < 0){
                        this.setState({ h_sub_txt_position_bottom : Math.abs(Number(event.h_sub_txt_position_y))});
                    }else{
                        this.setState({ h_sub_txt_position_top : Math.abs(Number(event.h_sub_txt_position_y))});
                    }
                }

                this.loadCafe24Item();
                this.loadProductCategory(this.state.parentCategory);
                this.getFullCalendarEventList("", ""); // 달력용 기획전 목록 조회

                if(event.iframe_page_id) {
                    this.getBannerHtmlList(event.iframe_page_id);
                }
            }
		})
		.catch(error => {
			// console.log ("===== error =====");
			// console.log (error);
			// console.log ("===== error request =====");
			// console.log(error.request);
		 });
    }

    loadProductCategory(parentCategory) {

        const sendForm = {
            user_seq: this.state.user_seq , 
            shop_no: this.state.shop_no, 
            parent_category_no: parentCategory
        }

		http.get("/rest/common/cafe24/category", {params:sendForm})
		.then(res => {
            if (res.data.list) {
                this.setState({product_category_list: res.data.list});
            }
		})
		.catch(error => {
			console.log ("===== error =====");
			console.log (error);
			console.log ("===== error request =====");
			console.log(error.request);
		 });
    }

    loadEventDetailProduct(event_seq) {
        const setItemImage = function(this_component){
            // 저장된 데이터를 불러왔을때 이미지가 사용되지 않는 영역은 상품 이미지로 초기화
            let item_img = (this_component.state.is_detail_image_use == true && this_component.state.select_item_list[0].detail_image) ? this_component.state.select_item_list[0].detail_image : this_component.state.select_item_list[0].image ? this_component.state.select_item_list[0].image : "";
            let non_use_w_position_name = JSON.parse(JSON.stringify(img_order_text));
            let non_use_h_position_name = JSON.parse(JSON.stringify(img_order_text));
            for(var i=0;i<this_component.state.w_img_position_name.length;i++){
                non_use_w_position_name.splice(non_use_w_position_name.indexOf(this_component.state.w_img_position_name[i].position_name),1);
            }
            for(var i=0;i<this_component.state.h_img_position_name.length;i++){
                non_use_h_position_name.splice(non_use_h_position_name.indexOf(this_component.state.h_img_position_name[i].position_name),1);
            }
            for(var i=0;i<non_use_w_position_name.length;i++){
                this_component.setState({[non_use_w_position_name[i]+"_link"]: item_img,})
            }
            for(var i=0;i<non_use_h_position_name.length;i++){
                this_component.setState({["h_"+non_use_h_position_name[i]+"_link"]: item_img,})
            }

            /* 누끼 해제 요청 상태
                누끼 필수 템플릿인데 누끼가 해제되어있다면, 원본이미지 사용을 누른 경우로 취급
            */
            let {w_non_nukki_state} = this_component.state;
            let {h_non_nukki_state} = this_component.state;
            for(var i=0;i<this_component.state.w_img_position_name.length;i++){
                if(this_component.positionImgCheck("w", i, true) && this_component.state.w_essential_img_nukki_yn == "Y" && this_component.state["w_"+this_component.state.w_img_position_name[i].position_name+"_nukki_yn"] == "N"){
                    w_non_nukki_state[i] = true;
                }
            }
            for(var i=0;i<this_component.state.h_img_position_name.length;i++){
                if(this_component.positionImgCheck("h", i, true) && this_component.state.h_essential_img_nukki_yn == "Y" && this_component.state["h_"+this_component.state.h_img_position_name[i].position_name+"_nukki_yn"] == "N"){
                    h_non_nukki_state[i] = true;
                }
            }
            this_component.setState({
                w_non_nukki_state: w_non_nukki_state,
                h_non_nukki_state: h_non_nukki_state,
            });
        };

        const sendForm = {
            event_seq: event_seq
        }

		http.get("/rest/common/admin/event/detailProduct", {params:sendForm})
		.then(res => {
            if (res.data.err_code == 401) {
                window.location.href = "/logout";
            }

            if(res.data.eventProduct && res.data.eventProduct.length > 0) {
                isTmp = true;
                this.setState({
                    select_item_list: res.data.eventProduct,
                    product_no: res.data.eventProduct[0].product_no,
                }, () => {
                    is_banner_ratio_loaded = true;

                    setItemImage(this);
                });
            } else if(this.state.solution_type_cd == "09900"){
                setItemImage(this);
            }
        })
    }

    //Cafe24 공통코드 : 배너 템플릿 + 사용자 선호 템플릿 조회 (페이징)
    getBannerDesignTemplateUserFavoritePaging(upper_code_cd, selected_tag_list) {
        var startidx = 0
        var rowcnt = 10
        if (upper_code_cd == "03000") {
            startidx = this.state.pc_banner_type_paging.startIdx;
            rowcnt = this.state.pc_banner_type_paging.rowcnt;
        } else if (upper_code_cd == "07000") {
            startidx = this.state.mobile_banner_type_paging.startIdx;
            rowcnt = this.state.mobile_banner_type_paging.rowcnt;
        }

        let tag_list = [];

        if(selected_tag_list != null && selected_tag_list != undefined && selected_tag_list.length > 0){
            tag_list = selected_tag_list;
        }

        http.get("/rest/common/bannerDesignTemplateUserFavorite/paging"
                +"?upper_code_cd="+upper_code_cd
                +"&startidx="+startidx
                +"&rowcnt="+rowcnt
                +is_test_use
                +"&tag_list="+tag_list
                )
        .then(res => {
            if (upper_code_cd == "03000") {
                const {pc_banner_type_paging} = this.state;
                pc_banner_type_paging["totalCnt"] = res.data.totalCnt;

                this.setState({bannerTypeList : res.data.list
                                , pc_banner_type_paging : pc_banner_type_paging});

                if (!this.getUrlParams()["event_seq"] && !first_lodded_pc_banner) {
                    // 디폴트 가로형 배너 디자인
                    this.onClickBannerTypeRadioButton(res.data.list[0], "event_type_cd", "event_pc_banner_name")
                    first_lodded_pc_banner = true;
                }

            } else if (upper_code_cd == "07000") {
                const{mobile_banner_type_paging} = this.state;
                mobile_banner_type_paging["totalCnt"] = res.data.totalCnt;

                this.setState({mobileBannerTypeList : res.data.list
                                , mobile_banner_type_paging : mobile_banner_type_paging});
                if (!this.getUrlParams()["event_seq"] && !first_lodded_mobile_banner) {
                    // 디폴트 세로형 배너 디자인
                    this.onClickBannerTypeRadioButton(res.data.list[0], "event_mobile_type_cd", "event_mobile_banner_name")
                    first_lodded_mobile_banner = true;
                }
            }
        }).catch(e => {
            console.log("### [ERROR] getBannerDesignTemplateUserFavoritePaging ###");
        })
    }

    //공통코드 조회 (페이징)
	getCafe24CodePaging(upper_code_cd) {
        var startidx = 0
        var rowcnt = 10
        if (upper_code_cd == "03000") {
            startidx = this.state.pc_banner_type_paging.startIdx;
            rowcnt = this.state.pc_banner_type_paging.rowcnt;
        } else if (upper_code_cd == "07000") {
            startidx = this.state.mobile_banner_type_paging.startIdx;
            rowcnt = this.state.mobile_banner_type_paging.rowcnt;
        }

        http.get("/rest/common/cafe24Code/paging"
                +"?upper_code_cd="+upper_code_cd
                +"&startidx="+startidx
                +"&rowcnt="+rowcnt
                +is_test_use
                )
        .then(res => {
            if (upper_code_cd == "03000") {
                const {pc_banner_type_paging} = this.state;
                pc_banner_type_paging["totalCnt"] = res.data.totalCnt;
                if(res.data.list.length > 0) {
                    this.setState({bannerTypeList : res.data.list
                                    , pc_banner_type_paging : pc_banner_type_paging});

                    if (!this.getUrlParams()["event_seq"] && !first_lodded_pc_banner) {
                        // 디폴트 가로형 배너 디자인
                        this.onClickBannerTypeRadioButton(res.data.list[0], "event_type_cd", "event_pc_banner_name")
                        first_lodded_pc_banner = true;
                    }
                }
            } else if (upper_code_cd == "07000") {
                const{mobile_banner_type_paging} = this.state;
                mobile_banner_type_paging["totalCnt"] = res.data.totalCnt;
                if(res.data.list.length > 0) {
                    this.setState({mobileBannerTypeList : res.data.list
                                    , mobile_banner_type_paging : mobile_banner_type_paging});
                    if (!this.getUrlParams()["event_seq"] && !first_lodded_mobile_banner) {
                        // 디폴트 세로형 배너 디자인
                        this.onClickBannerTypeRadioButton(res.data.list[0], "event_mobile_type_cd", "event_mobile_banner_name")
                        first_lodded_mobile_banner = true;
                    }
                }
            }
        }).catch(e => {

        })
    };

    //공통코드 조회
	getCafe24Code(upper_code_cd, name) {
        http.get( "/rest/common/cafe24Code?upper_code_cd="+upper_code_cd)
        .then(res => {        
            if(res.data.length > 0) {
                this.setState({[name] : res.data});
            }
        }).catch(e => {

        })
    };
    
    getFullCalendarEventList (year, month) {
        var sendForm = {
            user_seq: this.state.user_seq , 
            shop_no: this.state.shop_no, 
            year: year,
            month: month,
            calendar_type: "event_form"
        }

        http.get("/rest/common/calendar/event/list", {params:sendForm})
        .then(res => {
            this.setState({
                fullCalendarEventList: res.data.list,
            }, () => {
                this.changeDateAddCalendar();
            });
        })
    }
    
    getBannerRatio (changeBannerArea, inputType){
        // 페이지 로드시 사이즈 입력값이 모두 비어있다면, 설정에 있는 사이즈 값을 사용 중이던 배너이므로 설정값 사이즈를 사용한다.
        const loadInputAddSetting = getBannerRatioCnt==0 && !this.state.event_banner_pc_width && !this.state.event_banner_pc_height && !this.state.event_banner_mobile_width && !this.state.event_banner_mobile_height;
        var sendForm = {
            user_seq: this.state.user_seq,
            shop_no: "1",
            iframe_page_id: this.state.iframe_page_id,
            iframe_html_id: this.state.iframe_html_id,

            // 배너 영역을 변경하여 비율을 새로 받아오거나, 로보 배너 등록을 새로하는 경우에만 설정 비율로 응답받음
            store_connected_yn : ( changeBannerArea || loadInputAddSetting) ? "Y" : "N",

            /* 비연동형 사이즈 입력값 전달 */
            event_banner_pc_width : this.state.event_banner_pc_width,
            event_banner_pc_height : this.state.event_banner_pc_height,
            event_banner_mobile_width : this.state.event_banner_mobile_width,
            event_banner_mobile_height : this.state.event_banner_mobile_height,
        }

        if(sendForm.store_connected_yn == "Y" && !(this.state.iframe_page_id && this.state.iframe_html_id)){
            return false;
        }

        http.get("/rest/common/banner/ratio", {params:sendForm})
        .then(res => {
            let single_size = this.state.single_size;
            //this.loadTargetViewList(); // 타겟뷰 목록 조회
            
            let org_event_banner_pc_width = res.data.org_event_banner_pc_width;
            let org_event_banner_pc_height = res.data.org_event_banner_pc_height;
            let org_event_banner_mobile_width = res.data.org_event_banner_mobile_width;
            let org_event_banner_mobile_height = res.data.org_event_banner_mobile_height;
            let event_banner_pc_width_height = res.data.event_banner_pc_width_height;
            let event_banner_mobile_width_height = res.data.event_banner_mobile_width_height;

            let pc_banner_ratio_list = [];
            let mobile_banner_ratio_list = [];
            let selected_pc_banner_ratio = {};
            let selected_mobile_banner_ratio = {};
            let pc_mobile_same_ratio = false; // pc/mobile형 비율 사이즈가 정확하게 일치하면 true, 셀렉트박스에 하나의 설정만 들어가도록 한다. 

            if(org_event_banner_pc_width == org_event_banner_mobile_width && org_event_banner_pc_height == org_event_banner_mobile_height){
                pc_mobile_same_ratio = true;
                if(sendForm.store_connected_yn == "Y" || getBannerRatioCnt == 0){
                    single_size = true;
                }
            }

            //배너 영역을 변경하여 비율을 새로 받아오거나, 로보 배너 등록을 새로하는 경우
            if(loadInputAddSetting || changeBannerArea){
                if(!pc_mobile_same_ratio){
                    single_size = false;
                }
                /* 사이즈 입력란에 설정 사이즈 적용 */
                this.setState({
                    event_banner_pc_width : org_event_banner_pc_width ?? "",
                    event_banner_pc_height : org_event_banner_pc_height ?? "",
                    event_banner_mobile_width : org_event_banner_mobile_width ?? "",
                    event_banner_mobile_height : org_event_banner_mobile_height ?? "",
                });
            } else if(single_size){
                this.setState({
                    event_banner_pc_width : org_event_banner_pc_width ?? "",
                    event_banner_pc_height : org_event_banner_pc_height ?? "",
                    event_banner_mobile_width : org_event_banner_pc_width ?? "",
                    event_banner_mobile_height : org_event_banner_pc_height ?? "",
                });
            }

            var pc_org_obj = {
                "org_banner_width":   org_event_banner_pc_width,
                "org_banner_height": org_event_banner_pc_height,
                "org_event_type" : "p",
                "org_width_height" : event_banner_pc_width_height,
            };
            if (event_banner_pc_width_height && event_banner_pc_width_height > 1) {
                pc_org_obj = this.getCaptureSize("pc",pc_org_obj);
                // 원본 pc 비율이 1보다 큰 경우
                pc_banner_ratio_list.push(pc_org_obj);
                selected_pc_banner_ratio = pc_org_obj;
            } else if (event_banner_pc_width_height && event_banner_pc_width_height <= 1){
                pc_org_obj = this.getCaptureSize("mobile",pc_org_obj);
                // 원본 pc 비율이 1보다 작거나 같은 경우
                mobile_banner_ratio_list.push(pc_org_obj);
                selected_mobile_banner_ratio = pc_org_obj;
            }

            if(!pc_mobile_same_ratio && !single_size){
                var mobile_org_obj = {
                    "org_banner_width": org_event_banner_mobile_width,
                    "org_banner_height": org_event_banner_mobile_height,
                    "org_event_type" : "m",
                    "org_width_height" : event_banner_mobile_width_height,
                };
                if (event_banner_mobile_width_height && event_banner_mobile_width_height <= 1){
                    mobile_org_obj = this.getCaptureSize("mobile",mobile_org_obj);
                    // 원본 모바일 비율이 1보다 작거나 같은 경우
                    mobile_banner_ratio_list.push(mobile_org_obj);
                    
                    // 비연동형에서 입력값이 모바일이였다면, 해당 비율을 선택값으로 설정
                    if(!selected_mobile_banner_ratio.org_banner_width || inputType == "mobile"){
                        selected_mobile_banner_ratio = mobile_org_obj;
                    }
                } else if (event_banner_mobile_width_height && event_banner_mobile_width_height > 1) {
                    mobile_org_obj = this.getCaptureSize("pc",mobile_org_obj);
                    // 원본 모바일 비율이 1보다 큰 경우
                    pc_banner_ratio_list.push(mobile_org_obj);
    
                    // 비연동형에서 입력값이 모바일이였다면, 해당 비율을 선택값으로 설정
                    if(!selected_pc_banner_ratio.org_banner_width || inputType == "mobile"){
                        selected_pc_banner_ratio = mobile_org_obj;
                    }
                }
            }

            this.setState({
                pc_banner_ratio_list: pc_banner_ratio_list,
                mobile_banner_ratio_list: mobile_banner_ratio_list,
                selected_pc_banner_ratio : selected_pc_banner_ratio,
                selected_mobile_banner_ratio : selected_mobile_banner_ratio,
                single_size : single_size,
            }, () => {
                if(getBannerRatioCnt > 0 || !this.getUrlParams()["event_seq"]){
                    // 페이지 로드할때 실행하고 자동배너를 변경할떄마다 실행
                    this.checkNukkiState();
                } else{
                    this.insertEventTmp('preview');
                }
                getBannerRatioCnt++;
            });

            // banner_ratio_list가 존재하는지로 가로형 또는 세로형이 보여지는 여부 판별
            this.setState({
                is_show_pc_banner_area: pc_banner_ratio_list.length > 0 ? true : false,
                is_show_mobile_banner_area: mobile_banner_ratio_list.length > 0 ? true : false,
            });

            this.setHeight();
        })
    }

    // 자동 타이틀
	getAutoTitle() {
        let keyword = this.state.gpt_main_keyword;

        if(!keyword){
            alert("키워드를 입력해 주세요.");
            return false;
        }

        this.setState({
            save_dialog_show_state: true,
            save_dialog_content: "AI 문구 추천중 입니다.",
            save_dialog_button_list: [
                {
                    title: "확인",
                    type: "1",
                    display_yn:"N",
                }
            ]
        });
        http.get("/rest/shop/event/gpt/sentence?user_seq="+this.state.user_seq+"&keyword="+encodeURI(keyword))
        .then(res => {
            if(res.data.res_txt) {
                const content = ContentState.createFromText(res.data.res_txt);
                isTmp = true;
                this.setState({
                    auto_title: res.data.res_txt,
                    title: res.data.res_txt,
                    org_title: res.data.res_txt,
                    mainEditorState: EditorState.createWithContent(content),
                    save_dialog_show_state: false,
                });
            } else{
                alert("문구 추천에 실패하였습니다.");
                this.setState({ save_dialog_show_state: false });
            }
        }).catch(e => {
            alert("문구 추천에 실패하였습니다.");
            this.setState({ save_dialog_show_state: false });
        })
	};

    // 자동 서브 타이틀
	getAutoSubTitle(click_btn, change_product) {
        this.setState({autoSubTextList: ""});
        http.get("/rest/common/auto/event/sentence?user_seq="+this.state.user_seq+"&product_name="+encodeURI(this.state.select_item_list[0].product_name))
        .then(res => {
            if(res.data.sentence_list) {
                this.setState({
                    autoSubTextList: res.data.sentence_list
                }, () => {
                    if (!first_lodded_sub_titie && !this.getUrlParams()["event_seq"]) {
                        // 디폴트 소제목
                        first_lodded_sub_titie = true;
                        var name = "sentence_cd";
                        var name2 = "robomd_on_sentence_cd";
                        this.setState({[name]: res.data.sentence_list[1].sentence_seq,
                                        sub_title: res.data.sentence_list[1].sentence,
                                        foreign_title: res.data.sentence_list[1].sentence,
                                        sentence: res.data.sentence_list[1].sentence,
                                        eng_sentence: res.data.sentence_list[1].sentence,

                                        [name2]: res.data.sentence_list[1].sentence_seq,
                                        robomd_on_sub_title: res.data.sentence_list[1].sentence,
                                        robomd_on_foreign_title: res.data.sentence_list[1].sentence,
                                        robomd_on_sentence: res.data.sentence_list[1].sentence,
                                        robomd_on_eng_sentence: res.data.sentence_list[1].sentence,
                        }, () => {
                            this.insertEventTmp('preview');
                        });
                    } else{
                        var name2 = "robomd_on_sentence_cd";
                        this.setState({
                            [name2]: res.data.sentence_list[1].sentence_seq,
                            robomd_on_sub_title: res.data.sentence_list[1].sentence,
                            robomd_on_foreign_title: res.data.sentence_list[1].sentence,
                            robomd_on_sentence: res.data.sentence_list[1].sentence,
                            robomd_on_eng_sentence: res.data.sentence_list[1].sentence,
                        });
                    }
                    if(click_btn){
                        isTmp = true;
                        this.setState({ save_dialog_show_state: false });
                    } else if(change_product){
                        // 이어서 AI 원본 배경 제거를 진행하지 않으면 다이얼로그를 닫는다
                        if(this.state.w_essential_img_nukki_yn == "N" && this.state.h_essential_img_nukki_yn == "N"){
                            isTmp = true;
                            this.setState({ save_dialog_show_state: false });
                        }
                        this.eventChangeProductImageOfNukki();
                    }
                });

            } else{
                alert("문구 추천에 실패하였습니다.");
                this.setState({is_recommendation_small_title : "off"});

                if(change_product){
                    // 이어서 AI 원본 배경 제거를 진행하지 않으면 다이얼로그를 닫는다
                    if(this.state.w_essential_img_nukki_yn == "N" && this.state.h_essential_img_nukki_yn == "N"){
                        isTmp = true;
                        this.setState({ save_dialog_show_state: false });
                    }
                    this.eventChangeProductImageOfNukki();
                } else{
                    isTmp = true;
                    this.setState({ save_dialog_show_state: false });
                }
            }
        }).catch(e => {
            alert("문구 추천에 실패하였습니다.");
            this.setState({is_recommendation_small_title : "off"});

            if(change_product){
                // 이어서 AI 원본 배경 제거를 진행하지 않으면 다이얼로그를 닫는다
                if(this.state.w_essential_img_nukki_yn == "N" && this.state.h_essential_img_nukki_yn == "N"){
                    isTmp = true;
                    this.setState({ save_dialog_show_state: false });
                }
                this.eventChangeProductImageOfNukki();
            } else{
                isTmp = true;
                this.setState({ save_dialog_show_state: false });
            }
        })
        is_auto_sub_title_load = false;
	};

    /** 배너 html_id_list 조회 */
	getBannerHtmlList(page_id) {
        let type = "html";
		http.get("/rest/common/robomd/PageHtmlId?user_seq="+this.state.user_seq+"&shop_no="+this.state.shop_no+"&type="+type+"&iframe_page_id="+page_id)
        .then(res => {
            if(res.data) {
                this.setState({
                    html_list: res.data.html_id_list
                }, () => {
                    if((!this.getUrlParams()["event_seq"] || first_load_page_id_list)
                    || this.getUrlParams()["sample_yn"] && this.getUrlParams()["sample_yn"] == "Y"){
    
                        this.setState({
                            // 디폴트 배너 위치 선택
                            iframe_html_id: this.state.html_list && this.state.html_list.length > 0  ? this.state.html_list[0].iframe_html_id : "",
                        }, () => {
                            this.getBannerRatio(true);
                        });
                    }
                    first_load_page_id_list = true;
                });
            }
        }).catch(e => {

        })
	};
    
    //배너 팔레트 사용 유무 조회
	getisUsePallete() {
        if(this.state.event_type_cd || this.state.event_mobile_type_cd) {
            http.get("/rest/common/banner/isUsePallete?event_type_cd="+this.state.event_type_cd+"&event_mobile_type_cd="+this.state.event_mobile_type_cd)
            .then(res => {
                if(res.data) {
                    if (is_banner_color_init == true) {
                        this.setState({ 
                            w_large_text_color : res.data.w_large_text_color,
                            w_small_text_color : res.data.w_small_text_color,
                            w_color_etc: res.data.w_color_etc,
                        });
                        is_banner_color_init = false;
                    } else if (is_mobile_banner_color_init == true) {
                        this.setState({ 
                            h_large_text_color : res.data.h_large_text_color,
                            h_small_text_color : res.data.h_small_text_color,
                            h_color_etc: res.data.h_color_etc,
                        });
                        is_mobile_banner_color_init = false;
                    }

                    let w_img_position_name = this.addBannerDesignPositionText(res.data.w_banner_design_position_img_list);
                    let h_img_position_name = this.addBannerDesignPositionText(res.data.h_banner_design_position_img_list);

                    for(var i=0;i<w_img_position_name.length;i++){
                        // 동영상 주소 타입인 경우, 동영상 주소를 쓰지 않는 템플릿에서 기본 이미지(상품)이 나올 수 있도록 초기화하는 처리
                        if(this.state.w_user_img_list[i].link_type == "49005"){
                            this.setState({[w_img_position_name[i].img_column_name] : (this.state.is_detail_image_use == true && this.state.select_item_list[0].detail_image) ? this.state.select_item_list[0].detail_image : this.state.select_item_list[0].image ? this.state.select_item_list[0].image : ""});
                        }

                    }
                    for(var i=0;i<h_img_position_name.length;i++){
                        // 동영상 주소 타입인 경우, 동영상 주소를 쓰지 않는 템플릿에서 기본 이미지(상품)이 나올 수 있도록 초기화하는 처리
                        if(this.state.h_user_img_list[i].link_type == "49005"){
                            this.setState({["h_"+h_img_position_name[i].img_column_name] : (this.state.is_detail_image_use == true && this.state.select_item_list[0].detail_image) ? this.state.select_item_list[0].detail_image : this.state.select_item_list[0].image ? this.state.select_item_list[0].image : ""});
                        }
                    }

                    this.setState({ 
                        w_color1_is_use: res.data.w_color1_is_use,
                        w_color2_is_use: res.data.w_color2_is_use,
                        w_color3_is_use: res.data.w_color3_is_use,
                        w_color4_is_use: res.data.w_color4_is_use,
                        h_color1_is_use: res.data.h_color1_is_use,
                        h_color2_is_use: res.data.h_color2_is_use,
                        h_color3_is_use: res.data.h_color3_is_use,
                        h_color4_is_use: res.data.h_color4_is_use,
                        w_color_etc_is_use: res.data.w_color_etc_is_use,
                        h_color_etc_is_use: res.data.h_color_etc_is_use,

                        w_img1_is_use: res.data.w_img1_is_use,
                        w_img2_is_use: res.data.w_img2_is_use,
                        w_img3_is_use: res.data.w_img3_is_use,
                        w_img4_is_use: res.data.w_img4_is_use,
                        h_img1_is_use: res.data.h_img1_is_use,
                        h_img2_is_use: res.data.h_img2_is_use,
                        h_img3_is_use: res.data.h_img3_is_use,
                        h_img4_is_use: res.data.h_img4_is_use,

                        // 필수로 누끼를 사용하는 템플릿인지 (w 가로형, h 세로형)
                        w_essential_img_nukki_yn : res.data.w_img_nukki_yn,
                        h_essential_img_nukki_yn : res.data.h_img_nukki_yn,

                        // 템플릿 누끼 ppm 기본값
                        w_is_nukki_ppm_yn : res.data.w_is_nukki_ppm_yn,
                        h_is_nukki_ppm_yn : res.data.h_is_nukki_ppm_yn,

                        // 이미지 누끼를 딴 이미지와 안 딴 이미지를 둘다 사용하는 템플릿 여부
                        w_is_nukki_multi_use_yn : res.data.w_is_nukki_multi_use_yn,
                        h_is_nukki_multi_use_yn : res.data.h_is_nukki_multi_use_yn,

                        // 이미지 누끼딸때 크롭 여부
                        w_is_nukki_crop_yn : res.data.w_is_nukki_crop_yn,
                        h_is_nukki_crop_yn : res.data.h_is_nukki_crop_yn,

                        // 날짜 입력란이 있는 템플릿인지
                        w_is_date_use : res.data.w_is_date_use,
                        h_is_date_use : res.data.h_is_date_use,

                        // 가격 입력란이 있는 템플릿인지
                        w_is_price_use : res.data.w_is_price_use,
                        h_is_price_use : res.data.h_is_price_use,

                        // 포지션당 이미지 컬럼명 조회
                        w_img_position_name : w_img_position_name,
                        h_img_position_name : h_img_position_name,

                        // 버튼 템플릿 여부
                        w_is_button_use : res.data.w_is_button_use,
                        h_is_button_use : res.data.h_is_button_use,

                        // AI 인물 위치 자동 이동 가능 여부
                        w_is_full_background_img_use : res.data.w_is_full_background_img_use,
                        h_is_full_background_img_use : res.data.h_is_full_background_img_use,

                        // AI 얼굴 위치 자동 이동 가능 여부
                        w_is_fashionplus_face_banner_use : res.data.w_is_fashionplus_face_banner_use,
                        h_is_fashionplus_face_banner_use : res.data.h_is_fashionplus_face_banner_use,

                        // 유튜브 링크 사용 가능 여부
                        w_is_youtube_link_use : res.data.w_is_youtube_link_use,
                        h_is_youtube_link_use : res.data.h_is_youtube_link_use,
                    }, () => {

                        if(!w_nukki_action_state){
                            let w_nukki_ppm_state = false; // this.state.w_is_nukki_ppm_yn == "Y" ? true : false;
                            this.setState({
                                w_nukki_ppm_state : [w_nukki_ppm_state, w_nukki_ppm_state, w_nukki_ppm_state, w_nukki_ppm_state],
                                w_nukki_version_list : [3,3,3,3],
                            }, () => {
                                // 누끼를 따지않는 배너인 경우
                                if(this.state.page_load_tmp_state && this.state.w_essential_img_nukki_yn == "N"){
                                    this.setState({
                                        w_img_nukki_yn : "N",
                                        w_img_detail_nukki_yn : "N",
                                        w_img_third_nukki_yn : "N",
                                        w_img_fourth_nukki_yn : "N",
                                    });
                                    let change_N_list = [];
                                    for(var i=0;i<4;i++){
                                        change_N_list.push({
                                            key : "w_"+img_order_text[i]+"_nukki_yn",
                                            value : "N"
                                        });
                                        change_N_list.push({
                                            key : "w_nukki_"+img_order_text[i]+'_link',
                                            value : ""
                                        });
                                    }
                                    const nukki_info = {
                                        change_N_list : change_N_list,
                                    };
                                    this.insertEventTmp('preview', nukki_info);
                                } else if(this.state.page_load_tmp_state && this.state.w_essential_img_nukki_yn == "Y"){
                                    let nukki_info_Y_list = [];
                                    // 누끼를 따는 배너인 경우
                                    for(var i=0;i<this.state.w_img_position_name.length;i++){
                                        // 이미지를 사용하는 영역인데
                                        if(this.state["w_img"+(i+1)+"_is_use"] == "Y" && !this.state.w_non_nukki_state[i] && !this.state["w_non_nukki_state"][i]){
                                            // 누끼를 제거할 이미지가 있는 경우
                                            if(this.positionImgCheck("w", i)){
                                                const nukki_info_Y = {
                                                    type : "w",
                                                    position : i+1,
                                                    action : "Y",
                                                };
                                                nukki_info_Y_list.push(nukki_info_Y);
                                            }
                                        }
                                    }
    
                                    const nukki_info = {
                                        change_N_list : [],
                                        nukki_info_Y_list : nukki_info_Y_list,
                                    };
                                    this.insertEventTmp('preview', nukki_info);
                                }
                            });
                            w_nukki_action_state = true;
                        }
    
                        if(!h_nukki_action_state){
                            let h_nukki_ppm_state = false; // this.state.h_is_nukki_ppm_yn == "Y" ? true : false;
                            this.setState({
                                h_nukki_ppm_state : [h_nukki_ppm_state, h_nukki_ppm_state, h_nukki_ppm_state, h_nukki_ppm_state],
                                h_nukki_version_list : [3,3,3,3],
                            },()=> {
                                // 누끼를 따지않는 배너인 경우
                                if(this.state.page_load_tmp_state  && this.state.h_essential_img_nukki_yn == "N"){
                                    this.setState({
                                        h_img_nukki_yn : "N",
                                        h_img_detail_nukki_yn : "N",
                                        h_img_third_nukki_yn : "N",
                                        h_img_fourth_nukki_yn : "N",
                                    });
                                    let change_N_list = [];
                                    for(var i=0;i<4;i++){
                                        change_N_list.push({
                                            key : "h_"+img_order_text[i]+"_nukki_yn",
                                            value : "N"
                                        });
                                        change_N_list.push({
                                            key : "h_nukki_"+img_order_text[i]+'_link',
                                            value : ""
                                        });
                                    }
                                    const nukki_info = {
                                        change_N_list : change_N_list,
                                    };
                                    this.insertEventTmp('preview', nukki_info);
                                } else if(this.state.page_load_tmp_state && this.state.h_essential_img_nukki_yn == "Y"){
                                    let nukki_info_Y_list = [];
                                    // 누끼를 따는 배너인 경우
                                    for(var i=0;i<this.state.h_img_position_name.length;i++){
                                        // 이미지를 사용하는 영역인데
                                        if(this.state["h_img"+(i+1)+"_is_use"] == "Y" && !this.state.h_non_nukki_state[i] && !this.state["h_non_nukki_state"][i]){
                                            // 누끼를 제거할 이미지가 있는 경우
                                            if(this.positionImgCheck("h", i)){
                                                const nukki_info_Y = {
                                                    type : "h",
                                                    position : i+1,
                                                    action : "Y",
                                                };
                                                nukki_info_Y_list.push(nukki_info_Y);
                                            }
                                        }
                                    }
    
                                    const nukki_info = {
                                        change_N_list : [],
                                        nukki_info_Y_list : nukki_info_Y_list,
                                    };
                                    this.insertEventTmp('preview', nukki_info);
                                }
                            });
                            h_nukki_action_state = true;
                        }
                    });

                }
            }).catch(e => {

            })
        }
	};

    updateFontList = (font_family_list) => {
        font_family_list = [...new Set(font_family_list)];
        this.setState({font_family_list: font_family_list});
    }

    insertEventTmp(call_cls, nukki_info, button_update) {
        if(!this.getUrlParams()["event_seq"]) {
            if ((this.state.pc_banner_ratio_list.length > 0) && !this.state.event_type_cd) {
                if ((this.state.mobile_banner_ratio_list.length > 0) && !this.state.event_mobile_type_cd) {
                    return false;
                }
            }
        }
        const {is_recommendation_small_title} = this.state;

        // 배너 문구 replace
        let replaceText = function(text){
            return String(text).replace(/(?:\r\n|\r|\n)/g, '<br />');
        };

        // 폰트 리스트 호출
        this.updateFontList(font_family_list);

        let body = {
            event_seq : this.getUrlParams()["event_seq"] ? this.state.event_seq : dateString,
            insert_type : "N", // insert_type은 무조건 N으로 사용중, 페이지 시작때 db에 미리보기를 넣는것으로 대체 가능
            nukki_run_yn : "N", // Y인 경우 누끼 따는 작업 실행

            start_date : this.state.start_date_format,
            end_date : this.state.end_date_format,
            title : replaceText(this.state.title),
            title_sentence_cd : this.state.title_sentence_cd,

            // 작은 글자 추천 여부에 따라 적용
            foreign_title : is_recommendation_small_title == "on" ? replaceText(this.state.robomd_on_foreign_title) : replaceText(this.state.foreign_title),
            content_sentence_cd : is_recommendation_small_title == "on" ? this.state.robomd_on_sentence_cd : this.state.content_sentence_cd,
            sentence : is_recommendation_small_title == "on" ? replaceText(this.state.robomd_on_sentence) : replaceText(this.state.sentence),
            eng_sentence : is_recommendation_small_title == "on" ? replaceText(this.state.robomd_on_eng_sentence) : replaceText(this.state.eng_sentence),

            display_yn : this.state.display_yn,
            event_type_cd : this.state.event_type_cd,
            event_mobile_type_cd : this.state.event_mobile_type_cd,
            event_type_name : this.state.event_type_name,
            event_mobile_type_name : this.state.event_mobile_type_name,
            event_pc_banner_name: this.state.event_pc_banner_name,
            event_mobile_banner_name: this.state.event_mobile_banner_name,
            keyword_cd : this.state.keyword,
            event_price : this.state.event_price,

            img_link : this.state.img_link,
            img_detail_link : this.state.img_detail_link,
            img_third_link : this.state.img_third_link,
            img_fourth_link : this.state.img_fourth_link,
            h_img_link : this.state.h_img_link,
            h_img_detail_link : this.state.h_img_detail_link,
            h_img_third_link : this.state.h_img_third_link,
            h_img_fourth_link : this.state.h_img_fourth_link,

            color_code_1 : this.state.background,
            color_code_2 : this.state.background2,
            color_code_3 : this.state.background3,
            color_code_4 : this.state.background4,

            color_value_1 : this.state.color_value_1,
            color_value_2 : this.state.color_value_2,
            color_value_3 : this.state.color_value_3,
            color_value_4 : this.state.color_value_4,

            color_lmnst_1 : this.state.color_lmnst_1,
            color_lmnst_2 : this.state.color_lmnst_2,
            color_lmnst_3 : this.state.color_lmnst_3,
            color_lmnst_4 : this.state.color_lmnst_4,

            color_strtn_1 : this.state.color_strtn_1,
            color_strtn_2 : this.state.color_strtn_2,
            color_strtn_3 : this.state.color_strtn_3,
            color_strtn_4 : this.state.color_strtn_4,

            color_hue_1 : this.state.color_hue_1,
            color_hue_2 : this.state.color_hue_2,
            color_hue_3 : this.state.color_hue_3,
            color_hue_4 : this.state.color_hue_4,
            
            user_seq : this.state.user_seq,
            shop_no : "1",
            product_no : this.state.product_no,
            reg_user_seq : this.state.reg_user_seq,
            reg_date : this.state.reg_date,

            facial_x_start : this.state.facial_x_start,
            facial_y_start : this.state.facial_y_start,
            facial_width: this.state.facial_width,
            facial_height : this.state.facial_height,
            facial_yn : this.state.facial_yn,
            origin_img_width : this.state.origin_img_width,
            origin_img_height : this.state.origin_img_height,
            call_cls : call_cls,   //호출 구분 : 미리보기 호출시 이력 테이블 저장

            /* 이미지 위치 조절 값 */
            img_position_1_type : this.state.img_position_1_type,
            img_position_2_type : this.state.img_position_2_type,
            img_position_3_type : this.state.img_position_3_type,
            img_position_4_type : this.state.img_position_4_type,
            h_img_position_1_type : this.state.h_img_position_1_type,
            h_img_position_2_type : this.state.h_img_position_2_type,
            h_img_position_3_type : this.state.h_img_position_3_type,
            h_img_position_4_type : this.state.h_img_position_4_type,

            /* 텍스트 컬러 */
            w_large_text_color : this.state.w_large_text_color,
            h_large_text_color : this.state.h_large_text_color,
            w_small_text_color : this.state.w_small_text_color,
            h_small_text_color :this.state.h_small_text_color,
            /* 텍스트 외 요소 색상 */
            w_color_etc : this.state.w_color_etc,
            h_color_etc : this.state.h_color_etc,
            /* 버튼 리스트 */
            button_list : this.state.button_list,
            button_clear_yn : button_update || !this.state.page_load_tmp_state ? "Y" : "N", // 버튼 수정 여부

            /* 텍스트 위치 이동 */
            w_main_txt_position_x : Number(this.state.w_main_txt_position_right) - Number(this.state.w_main_txt_position_left),
            w_main_txt_position_y : Number(this.state.w_main_txt_position_top) - Number(this.state.w_main_txt_position_bottom),
            h_main_txt_position_x : Number(this.state.h_main_txt_position_right) - Number(this.state.h_main_txt_position_left),
            h_main_txt_position_y : Number(this.state.h_main_txt_position_top) - Number(this.state.h_main_txt_position_bottom),
            w_sub_txt_position_x : Number(this.state.w_sub_txt_position_right) - Number(this.state.w_sub_txt_position_left),
            w_sub_txt_position_y : Number(this.state.w_sub_txt_position_top) - Number(this.state.w_sub_txt_position_bottom),
            h_sub_txt_position_x : Number(this.state.h_sub_txt_position_right) - Number(this.state.h_sub_txt_position_left),
            h_sub_txt_position_y : Number(this.state.h_sub_txt_position_top) - Number(this.state.h_sub_txt_position_bottom),

            /* 이미지 크기 조절 */
            w_img_1_scale : this.state.w_img_1_scale,
            w_img_2_scale : this.state.w_img_2_scale,
            w_img_3_scale : this.state.w_img_3_scale,
            w_img_4_scale : this.state.w_img_4_scale,
            h_img_1_scale : this.state.h_img_1_scale,
            h_img_2_scale : this.state.h_img_2_scale,
            h_img_3_scale : this.state.h_img_3_scale,
            h_img_4_scale : this.state.h_img_4_scale,

            /* 텍스트 크기 조절 */
            w_main_txt_scale : this.state.w_main_txt_scale,
            h_main_txt_scale : this.state.h_main_txt_scale,
            w_sub_txt_scale : this.state.w_sub_txt_scale,
            h_sub_txt_scale : this.state.h_sub_txt_scale,

            /* 폰트 리스트 */
            font_family_list : this.state.font_family_list,
        };

        // 4개의 이미지 영역 초기화
        for(var i=0;i<4;i++){
            body[youtube_order_text[i]+"_link"] = "";
            body["h_"+youtube_order_text[i]+"_link"] = "";
            body[youtube_order_text[i]+"_thumbnail"] = "";
            body["h_"+youtube_order_text[i]+"_thumbnail"] = "";
            body["w_is_"+youtube_order_text[i]+"_use"] = "N";
            body["h_is_"+youtube_order_text[i]+"_use"] = "N";
        }

        for(var i=0;i<this.state.w_img_position_name.length;i++){
            // 업로드 이미지가 사용되는 경우
            if(this.state["w_img"+(i+1)+"_is_use"] == "Y" && this.state.w_user_img_list[i].img_link){
                // 링크 타입이 동영상 주소가 아닌 경우에만 추가
                if(this.state.w_user_img_list[i].link_type != "49005"){
                    body[this.state.w_img_position_name[i].img_column_name] = this.state.w_user_img_list[i].img_link;
                }
                body[youtube_order_text[i]+"_link"] = (this.state.w_is_youtube_link_use == "Y" && this.state.w_user_img_list[i].video_link) ? this.state.w_user_img_list[i].video_link : "";
                body[youtube_order_text[i]+"_thumbnail"] = (this.state.w_is_youtube_link_use == "Y" && this.state.w_user_img_list[i].video_link) ? this.state.w_user_img_list[i].img_link : "";
                body["w_is_"+youtube_order_text[i]+"_use"] = (this.state.w_is_youtube_link_use == "Y" && this.state.w_user_img_list[i].video_link) ? "Y" : "N";
            }
        }
        for(var i=0;i<this.state.h_img_position_name.length;i++){
            // 업로드 이미지가 사용되는 경우
            if(this.state["h_img"+(i+1)+"_is_use"] == "Y" && this.state.h_user_img_list[i].img_link){
                // 링크 타입이 동영상 주소가 아닌 경우에만 추가
                if(this.state.h_user_img_list[i].link_type != "49005"){
                    body["h_"+this.state.h_img_position_name[i].img_column_name] = this.state.h_user_img_list[i].img_link;
                }
                body["h_"+youtube_order_text[i]+"_link"] = (this.state.h_is_youtube_link_use == "Y" && this.state.h_user_img_list[i].video_link) ? this.state.h_user_img_list[i].video_link : "";
                body["h_"+youtube_order_text[i]+"_thumbnail"] = (this.state.h_is_youtube_link_use == "Y" && this.state.h_user_img_list[i].video_link) ? this.state.h_user_img_list[i].img_link : "";
                body["h_is_"+youtube_order_text[i]+"_use"] = (this.state.h_is_youtube_link_use == "Y" && this.state.h_user_img_list[i].video_link) ? "Y" : "N";
            }
        }
        
        /* 이미지 위치 조절 값 */
        for(var img_position_idx = 1; img_position_idx <= 4; img_position_idx ++) {
            // 위쪽, 왼쪽으로 이동시 음수로 보낸다
            /* 가로형 */
            body["img_position_x_"+img_position_idx] = Number(this.state["w_img_position_left_"+img_position_idx]) != 0 ? Number(this.state["w_img_position_left_"+img_position_idx])*-1 : Number(this.state["w_img_position_right_"+img_position_idx]);
            body["img_position_y_"+img_position_idx] = Number(this.state["w_img_position_top_"+img_position_idx]) != 0 ? Number(this.state["w_img_position_top_"+img_position_idx])*-1 : Number(this.state["w_img_position_bottom_"+img_position_idx]);
            /* 세로형 */
            body["h_img_position_x_"+img_position_idx] = Number(this.state["h_img_position_left_"+img_position_idx]) != 0 ? Number(this.state["h_img_position_left_"+img_position_idx])*-1 : Number(this.state["h_img_position_right_"+img_position_idx]);
            body["h_img_position_y_"+img_position_idx] = Number(this.state["h_img_position_top_"+img_position_idx]) != 0 ? Number(this.state["h_img_position_top_"+img_position_idx])*-1 : Number(this.state["h_img_position_bottom_"+img_position_idx]);
        }

        /**
         * 현재 미리보기 기능은 백엔드에서 해당 이미지의 img_nukki_yn 값이 Y로 되어있으면, 누끼 따는 요청을 보내도 누끼를 안따고 넘겨준다.
         * 누끼따는 템플릿으로 누끼가 따여져있는 경우 이미 img_nukki_yn값이 Y로 되어있을때,
         * 이미지를 변경하게 되면 곧바로 변경된 이미지의 누끼를 따서 적용하면 되지만 img_nukki_yn값이 Y로 되어있어서 새로운 이미지 링크여도 누끼를 안따고 넘겨준다.
         * 그래서 img_nukki_yn값을 현재는 N으로 변경한 후 누끼를 따는 방식으로 진행중이지만 이미지 링크만으로도 누끼를 따는 api가 생겨서 추후에 기능 수정 예정.
         * 
         * /shop/removeBackground
         * 위 api를 통해 이미지의 누끼를 따고 미리보기 api를 태워서 새로운 누끼 이미지를 미리보기 DB에 적용한다.
         * 혹시 모르니 img_nukki_yn 값도 함께 적용
         */
        let nukki_idx;
        var nukkiLink; // 이미 존재하는 누끼 링크
        if(nukki_info){
            nukki_idx = nukki_info.position ? Number(nukki_info.position)-1: "";
            /* 이미지 누끼 관련 정보를 미리보기 테이블에 덮어씌우는 부분
             * event_seq가 존재하는 페이지에 진입했을때 실행 */
            if(nukki_info.page_load){
                body['w_img_nukki_yn'] = this.state.w_img_nukki_yn;
                body['h_img_nukki_yn'] = this.state.h_img_nukki_yn;
                body['w_img_detail_nukki_yn'] = this.state.w_img_detail_nukki_yn;
                body['h_img_detail_nukki_yn'] = this.state.h_img_detail_nukki_yn;
                body['w_img_third_nukki_yn'] = this.state.w_img_third_nukki_yn;
                body['h_img_third_nukki_yn'] = this.state.h_img_third_nukki_yn;
                body['w_img_fourth_nukki_yn'] = this.state.w_img_fourth_nukki_yn;
                body['h_img_fourth_nukki_yn'] = this.state.h_img_fourth_nukki_yn;

                body['w_img_nukki_ppm_yn'] = this.state.w_img_nukki_ppm_yn;
                body['w_img_detail_nukki_ppm_yn'] = this.state.w_img_detail_nukki_ppm_yn;
                body['w_img_third_nukki_ppm_yn'] = this.state.w_img_third_nukki_ppm_yn;
                body['w_img_fourth_nukki_ppm_yn'] = this.state.w_img_fourth_nukki_ppm_yn;
                body['h_img_nukki_ppm_yn'] = this.state.h_img_nukki_ppm_yn;
                body['h_img_detail_nukki_ppm_yn'] = this.state.h_img_detail_nukki_ppm_yn;
                body['h_img_third_nukki_ppm_yn'] = this.state.h_img_third_nukki_ppm_yn;
                body['h_img_fourth_nukki_ppm_yn'] = this.state.h_img_fourth_nukki_ppm_yn;
    
                body['w_nukki_img_link'] = this.state.w_nukki_img_link;
                body['w_nukki_img_detail_link'] = this.state.w_nukki_img_detail_link;
                body['w_nukki_img_third_link'] = this.state.w_nukki_img_third_link;
                body['w_nukki_img_fourth_link'] = this.state.w_nukki_img_fourth_link;
                body['h_nukki_img_link'] = this.state.h_nukki_img_link;
                body['h_nukki_img_detail_link'] = this.state.h_nukki_img_detail_link;
                body['h_nukki_img_third_link'] = this.state.h_nukki_img_third_link;
                body['h_nukki_img_fourth_link'] = this.state.h_nukki_img_fourth_link;

                $("#blockPageClick").hide();
            }
    
            /* 이미지 배경 제거 요청 */
            if(nukki_info.action){
                const ppm_yn = this.state[nukki_info.type+"_nukki_ppm_state"][nukki_idx] ? "Y" : "N";
                const version = this.state[nukki_info.type+"_nukki_version_list"][nukki_idx];
                body[nukki_info.type+'_nukki_ppm_yn'] = ppm_yn;
                body[nukki_info.type+'_nukki_version'] = version;

                nukkiLink = this.checkTypeNukkiRunImage(nukki_info.type, Number(nukki_info.position)-1);
                if(nukkiLink){
                    body[nukki_info.type+"_nukki_"+this.state[nukki_info.type+"_img_position_name"][nukki_idx].position_name+'_link'] =nukkiLink;
                    body[nukki_info.type+'_'+this.state[nukki_info.type+"_img_position_name"][nukki_idx].position_name+'_nukki_yn'] = "Y";
                } else{
                    body['nukki_run_yn'] = "Y";
                    body[nukki_info.type+'_nukki_yn'] = nukki_info.action;
                    body[nukki_info.type+'_nukki_position'] = nukki_info.position;

                    let save_dialog_content = "AI 원본 배경 제거중 입니다.";
                    this.setState({
                        save_dialog_show_state: true,
                        save_dialog_content: save_dialog_content,
                        save_dialog_button_list: [
                            {
                                title: "확인",
                                type: "1",
                                display_yn:"N",
                            }
                        ]
                    }, () => {
                        $("#blockPageClick").hide();
                    });
                }
                
                $(".mirror_banner_v2_pc_banner_area").css({opacity : "0"});
                $(".mirror_banner_v2_mobile_banner_area").css({opacity : "0"});
            }

            /** 누끼 상태값 및 누끼 링크 변경
             * nukki_info_Y_list가 있으면, api 응답후 배경 제거 작업을 이어서 실행
            */
            if(nukki_info.change_N_list){
                let N_list = nukki_info.change_N_list ? nukki_info.change_N_list : [];
                for(var i=0;i<N_list.length;i++){
                    body[N_list[i].key] = N_list[i].value;
                }
            }
        }
        const config = {
            headers: {
                'Content-Type': 'application/json',
                },
        };
        http.post("/rest/common/insert/event/tmp", body, config)
		.then(res =>
            {this.setState({page_load_tmp_state : true,})
            let event_seq = this.getUrlParams()["event_seq"] ? this.state.event_seq : dateString;
            // $(".mirror_banner_v2_pc_banner").html('<object class="robomd_banner_m" type="text/html" data="'+object_domain+'/admin_banner.html?event_seq='+event_seq+'&platform_type=p&mirror_type=18001" type="text/html" style="width:100%;height:100%; background: #FFF;"></object>');
            // $(".mirror_banner_v2_mobile_banner").html('<object class="robomd_banner_p" type="text/html" data="'+object_domain+'/admin_banner.html?event_seq='+event_seq+'&platform_type=m&mirror_type=18001" type="text/html" style="width:100%;height:100%; background: #FFF;"></object>');
            
            const $object_p = $(".robomd_banner_p");
            $object_p.attr('data', object_domain+"/admin_banner.html?event_seq="+event_seq+"&platform_type=p&mirror_type=18001");

            const $object_m = $(".robomd_banner_m");
            $object_m.attr('data', object_domain+"/admin_banner.html?event_seq="+event_seq+"&platform_type=m&mirror_type=18001");
            
            if(nukki_info){
                if(nukki_info.action == "Y"){
                    if(nukki_action_state_cnt > 0){
                        nukki_action_state_cnt --;
                    }
                    if(res.data.nukki_response_code == "200"){
                        const position_name = this.state[nukki_info.type+"_img_position_name"][nukki_idx].position_name;
                        const nukki_img_link = res.data[nukki_info.type+"_nukki_"+position_name+"_link"];

                        this.setState({
                            [nukki_info.type+"_"+position_name+"_nukki_yn"] : "Y",
                            [nukki_info.type+"_nukki_"+position_name+"_link"] : nukki_img_link,
                        });
                        // 상품 이미지라면
                        if(!this.positionLinkTypeImgCheck(nukki_info.type,nukki_idx)){
                            var robo_nukki_img = {
                                org_img_link : body[nukki_info.type == "w" ? this.state[nukki_info.type+"_img_position_name"][nukki_idx].img_column_name : "h_"+this.state[nukki_info.type+"_img_position_name"][nukki_idx].img_column_name],
                                ppm_yn : this.state[nukki_info.type+"_nukki_ppm_state"][nukki_idx] ? "Y" : "N",
                                crop_yn : nukki_info.type == "w" ? this.state.w_is_nukki_crop_yn : this.state.h_is_nukki_crop_yn,
                                version : this.state[nukki_info.type+"_nukki_version_list"][nukki_idx],
                                nukki_img_link : nukki_img_link,
                            };
                            var robo_nukki_img_list = !sessionStorage.getItem("robo_nukki_img_list") ? []: JSON.parse(sessionStorage.getItem("robo_nukki_img_list"));
                            robo_nukki_img_list.push(robo_nukki_img);
                            sessionStorage.setItem("robo_nukki_img_list",JSON.stringify(robo_nukki_img_list));
                        } else{
                            // 사용자가 직접 올린 이미지라면
                            let user_img_list = this.state[nukki_info.type+"_user_img_list"];
                            let ppm_yn = this.state[nukki_info.type+"_nukki_ppm_state"][nukki_idx] ? "Y" : "N";
                            let crop_yn = nukki_info.type == "w" ? this.state.w_is_nukki_crop_yn : this.state.h_is_nukki_crop_yn;
                            let version = this.state[nukki_info.type+"_nukki_version_list"][nukki_idx];
                            user_img_list[nukki_idx]["img_ppm_"+ppm_yn+"_crop_"+crop_yn+"_version_"+version] = nukki_img_link;

                            this.setState({[nukki_info.type+"_user_img_list"]: user_img_list,});
                        }
                        
                    } else if(nukkiLink){
                        this.setState({
                            [nukki_info.type+"_"+this.state[nukki_info.type+"_img_position_name"][nukki_idx].position_name+"_nukki_yn"] : "Y",
                            [nukki_info.type+"_nukki_"+this.state[nukki_info.type+"_img_position_name"][nukki_idx].position_name+"_link"] : nukkiLink,
                        });
                    } else{
                        alert("배경 제거에 실패하였습니다.");
                    }

                    if(nukki_action_state_cnt == 0){
                        this.setState({ save_dialog_show_state: false });
                        $(".mirror_banner_v2_pc_banner_area").css({opacity : "1"});
                        $(".mirror_banner_v2_mobile_banner_area").css({opacity : "1"});
                        $("#blockPageClick").hide();
                    }
                    return false;
                }

                if(nukki_info.change_N_list){
                    // 누끼를 이어서 따야하는 경우
                    if(nukki_info.nukki_info_Y_list && nukki_info.nukki_info_Y_list.length > 0){
                        let action_y_num = 0;

                        for(var i=0;i<nukki_info.nukki_info_Y_list.length;i++){
                            this.insertEventTmp('preview', nukki_info.nukki_info_Y_list[i]);
                            action_y_num ++;
                        }

                        // 배경 제거를 하나라도 실행하면 미리보기 막기
                        if(action_y_num != 0){
                            nukki_action_state_cnt = action_y_num;

                            $(".mirror_banner_v2_pc_banner_area").css({opacity : "0"});
                            $(".mirror_banner_v2_mobile_banner_area").css({opacity : "0"});
                            return false;
                        }
                    }
                    this.setState({ save_dialog_show_state: false });
                }

                if(nukki_info.page_load){
                    this.insertEventTmp('preview');
                }
            }
            $(".mirror_banner_v2_pc_banner_area").css({opacity : "1"});
            $(".mirror_banner_v2_mobile_banner_area").css({opacity : "1"});
            $("#blockPageClick").hide();
        })
		.catch(error => {
            if(nukki_info && nukki_info.action == "Y"){
                alert("배경 제거에 실패하였습니다.");
            }
            this.setState({
                page_load_tmp_state : true,
                save_dialog_show_state: false,
            })
            $(".mirror_banner_v2_pc_banner_area").css({opacity : "1"});
            $(".mirror_banner_v2_mobile_banner_area").css({opacity : "1"});
            $("#blockPageClick").hide();
		 });
    }

    // 미리보기 영역 사이즈 설정
    setHeight = () => {
        const dbcw = document.body.clientWidth;

        const mirror_banner_modal_v2_width = dbcw / 2;

        // 영역 가로 사이즈 설정 / 영역 margin 설정(가로형 세로형 사이 간격)
        if(dbcw > 795) { // 800인 모바일 사이즈에 맞춰 변화하도록 설정 (5px 만큼 차이 있음)
            let mWidth = (mirror_banner_modal_v2_width / 6.2) * 2.2;
            let pWidth = (mirror_banner_modal_v2_width / 6.2) * 4;
            
            $(".mirror_banner_v2_mobile_banner").css("width", `${mWidth - 40}px`);
            $(".mirror_banner_v2_pc_banner").css("width", `${pWidth - 50}px`);
            $(".mirror_banner_v2_pc_banner_area").css("margin-right", "10px");
            $(".mirror_banner_v2_pc_banner_area").css("margin-bottom", "0px");
        } else {
            let mbmw = $(".mirror_banner_v2_body").width();
            $(".mirror_banner_v2_mobile_banner").css("width", `${mbmw - 5}px`);
            $(".mirror_banner_v2_pc_banner").css("width", `${mbmw - 5}px`);
            $(".mirror_banner_v2_pc_banner_area").css("margin-right", "0px");
            $(".mirror_banner_v2_pc_banner_area").css("margin-bottom", "10px");
        }

        const dbch = window.innerHeight;
		const maxHeight = dbch * 0.6 - 43; //0.6은 임의의 값, 43은 세로형 or 가로형 텍스트 칸 크기

        /* 세로형 베너 세로 사이즈 너무 커지는 현상 방지 */
        // 설정된 가로 사이즈를 기준으로 비율 계산을 통해 세로 사이즈 구하기
        let mh = $(".mirror_banner_v2_mobile_banner").width() / this.state.selected_mobile_banner_ratio.org_width_height;
        // 비율에 맞게 설정된 세로사이즈가 maxHeight를 넘은 경우의 처리 
		if(mh > maxHeight) {
			mh = maxHeight; // 세로 사이즈를 max값으로 설정
            // 세로 사이즈를 기준으로 비율 계산으로 통해 가로 사이즈 구하기
            const width = mh * this.state.selected_mobile_banner_ratio.org_width_height;
            $(".mirror_banner_v2_mobile_banner").css("width", width+"px");
		}
        $(".mirror_banner_v2_mobile_banner").css({"height": mh+"px"});

        /* 가로형 베너 세로 사이즈 커지는 현상 방지 */
        // 설정된 가로 사이즈를 기준으로 비율 계산을 통해 세로 사이즈 구하기
        let ph = $(".mirror_banner_v2_pc_banner").width() / this.state.selected_pc_banner_ratio.org_width_height;
        // 비율에 맞게 설정된 세로사이즈가 maxHeight를 넘은 경우의 처리 
		if(ph > maxHeight) {
			ph = maxHeight; // 세로 사이즈를 max값으로 설정
            // 세로 사이즈를 기준으로 비율 계산으로 통해 가로 사이즈 구하기
            const width = ph * this.state.selected_pc_banner_ratio.org_width_height;
			$(".mirror_banner_v2_pc_banner").css("width", width+"px");
		}
        $(".mirror_banner_v2_pc_banner").css({"height": ph+"px"});
    }

    /** 달력에 추가 */
    changeDateAddCalendar(){
        let id = (this.state.event_seq != "") ? this.state.event_seq : -1;
            const {fullCalendarEventList} = this.state;
            const itemToFind = fullCalendarEventList.find(function(item) {return item.id === id}); 
            const idx = fullCalendarEventList.indexOf(itemToFind);
            if (idx > -1) fullCalendarEventList.splice(idx, 1)

            isTmp = true;

            this.setState({
                fullCalendarEventList: fullCalendarEventList.concat({
                    id: id,
                    title: "",
                    start: this.state.start_date_format_calendar,
                    end: this.state.end_date_format_calendar,
                    // start: new Date(this.state.start_date_format).toISOString().slice(0, 10),
                    // end: new Date(this.state.end_date_format).toISOString().slice(0, 10),
                    color: "#10CFC9",
                }),
            })
    }

    /** 세부 일정 변경 */
    changeDetailDateSetting(){
        if (this.state.start_date != "") {
            if (this.state.start_hour != "") {
                if (this.state.start_minute != "") {
                    if (this.state.end_date != "") {
                        if (this.state.end_hour != "") {
                            if (this.state.end_minute != "") {

                                var start_hour = this.state.start_hour;
                                if(Number(this.state.start_hour) < 10) {
                                    start_hour = "0"+ this.state.start_hour;
                                }
                                var start_minute = this.state.start_minute;
                                if(Number(this.state.start_minute) < 10) {
                                    start_minute = "0"+ this.state.start_minute;
                                }
                                var end_hour = this.state.end_hour;
                                if(Number(this.state.end_hour) < 10) {
                                    end_hour = "0"+ this.state.end_hour;
                                }
                                var end_minute = this.state.end_minute;
                                if(Number(this.state.end_minute) < 10) {
                                    end_minute = "0"+ this.state.end_minute;
                                }


                                // 시작시간, 종료시간 모두 00초로 세팅 해놓고 비교
                                var start_date_format = this.state.start_date.getFullYear() + "-"+ ("0" + parseInt(this.state.start_date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(this.state.start_date.getDate())).slice(-2)
                                +" "+start_hour+":"+start_minute+":00";
                                var end_date_format = this.state.end_date.getFullYear() + "-"+ ("0" + parseInt(this.state.end_date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(this.state.end_date.getDate())).slice(-2)
                                                        +" "+end_hour+":"+end_minute+":00";
                                if(new Date(start_date_format).valueOf() == new Date(end_date_format).valueOf()) {
                                    alert("종료 일시는 시작 일시와 다르게 선택해 주세요. ");
                                } else {
                                    // 종료 시간을 59초로 세팅
                                    start_date_format = this.state.start_date.getFullYear() + "-"+ ("0" + parseInt(this.state.start_date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(this.state.start_date.getDate())).slice(-2)
                                                            +" "+start_hour+":"+start_minute+":00";
                                    end_date_format = this.state.end_date.getFullYear() + "-"+ ("0" + parseInt(this.state.end_date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(this.state.end_date.getDate())).slice(-2)
                                                            +" "+end_hour+":"+end_minute+":59";
                                    if(new Date(start_date_format) > new Date(end_date_format)) {
                                        alert("시작일시가 종료일시 보다 미래 일 수 없습니다.\n다시 설정해 주세요.");
                                    } 
                                }

                                // 예약진열 여부
                                var book_yn;
                                if(new Date(start_date_format) > new Date()) {
                                    book_yn = 'Y';
                                } else {
                                    book_yn = 'N';
                                }

                                this.setState({
                                    start_date_format: start_date_format,
                                    end_date_format: end_date_format,
                                    start_date_format_calendar: this.state.start_date.getFullYear() + "-"+ ("0" + parseInt(this.state.start_date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(this.state.start_date.getDate())).slice(-2)
                                                        +" "+start_hour+":"+start_minute+":00.000+0900",
                                    end_date_format_calendar: this.state.end_date.getFullYear() + "-"+ ("0" + parseInt(this.state.end_date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(this.state.end_date.getDate())).slice(-2)
                                                        +" "+end_hour+":"+end_minute+":59.000+0900",
                                    book_yn: book_yn,
                                }, () => {
                                    this.changeDateAddCalendar();
                                });
                            }
                        }
                    }
                }
            }
        }
    }

    saveEvent =  e => {
        if (!this.state.iframe_page_id){
            alert("적용 범위 - 페이지를 선택해 주세요.");
            $("#iframe_page_id_select").focus();
            return false;
        }
        if (this.state.html_list.length == 0 || !this.state.iframe_html_id){
            alert("적용 범위 - 배너를 선택해 주세요.");
            $("#iframe_html_id_select").focus();
            return false;
        }
        if (!this.state.item_user_type){
            alert("적용 범위 - 누구에게 노출할지 선택해 주세요.");
            $("#item_user_type").focus();
            return false;
        }
        if(this.state.item_user_type == "T" && this.state.is_use_target_view_service == "Y" && this.state.selected_target_view_list.length == 0){
            alert("타겟을 선택해 주세요.")
        }
        if (!this.state.title) {
            if (!this.state.none_title) {
                alert("큰 글자 제목이 없습니다. 대신 배너 이름을 입력해 주세요.");
                $("#none_title").focus();
                return false;
            }
        }

        if (this.state.solution_type_cd != "09900" && !this.state.product_no){
            alert("상품을 선택해 주세요.");
            $("#search_product").focus();
            return false;
        }
        if (this.state.solution_type_cd != "09900" && !this.state.is_soldout_display){
            alert("선택된 상품 품절 시 종료 여부를 선택해 주세요.");
            return false;
        }

        if(!this.state.selected_pc_banner_ratio.org_width_height && !this.state.selected_mobile_banner_ratio.org_width_height){
            alert("배너 사이즈를 입력해주세요.");
            if(!this.state.event_banner_pc_width || this.state.event_banner_pc_width == "0"){
                $("#input_event_banner_pc_width").focus();
                return false;
            }
            if(!this.state.event_banner_pc_height || this.state.event_banner_pc_height == "0"){
                $("#input_event_banner_pc_height").focus();
                return false;
            }
        }

        if (!this.state.start_hour) {
            alert("시작 일시를 지정해주세요.")
            $("#start_hour").focus();
            return false;
        }
        if (!this.state.start_minute) {
            alert("시작 일시를 지정해주세요.")
            $("#start_minute").focus();
            return false;
        }

        if (!this.state.end_hour) {
            alert("종료 일시를 지정해주세요.")
            $("#end_hour").focus();
            return false;
        }
        if (!this.state.end_minute) {
            alert("종료 일시를 지정해주세요.")
            $("#end_minute").focus();
            return false;
        }

        // 비연동인 경우에 가격을 사용하는 템플릿이 있다면 체크
        if(this.state.solution_type_cd == "09900" && ((this.state.pc_banner_ratio_list.length > 0 && this.state.w_is_price_use == "Y") || (this.state.mobile_banner_ratio_list.length > 0 && this.state.h_is_price_use == "Y"))){
            if (this.state.event_price !== 0 && (this.state.event_price == null || this.state.event_price == "")) {
                alert("가격 텍스트를 작성해 주세요.")
                $("#input_event_price_text").focus();
                return false;
            }
        }

        if (this.state.pc_banner_ratio_list.length > 0 && !this.state.event_type_cd){
            alert("가로형 배너 디자인을 선택해주세요.")
            return false;
        }
        if (this.state.mobile_banner_ratio_list.length > 0 && !this.state.event_mobile_type_cd){
            alert("세로형 배너 디자인을 선택해주세요.")
            return false;
        }
        if(this.state.pc_banner_ratio_list.length > 0 && this.state.event_type_cd_use_yn == "N"){
            alert("사용 가능한 가로형 배너 디자인을 선택해주세요.")
            return false;
        }
        if(this.state.mobile_banner_ratio_list.length > 0 && this.state.event_mobile_cd_use_yn == "N"){
            alert("사용 가능한 세로형 배너 디자인을 선택해주세요.")
            return false;
        }
        if (!this.state.background){
            alert("색상을 선택해주세요.")
            return false;
        }
        // if (this.state.title == null || this.state.title == ""){
        //     alert("큰 글자 제목을 입력해주세요.")
        //     return false;
        // }
        // if (this.state.sub_title == null || this.state.sub_title == ""){
        //     alert("작은 글자 제목을 입력해주세요.")
        //     return false;
        // }
        if (this.state.lang_type == "eng" && (!this.state.foreign_title || this.state.foreign_title == "undefined")) {
            alert("영문 배너 제목을 입력해주세요.")
            return false;
        }

        if(new Date(this.state.start_date_format) > new Date(this.state.end_date_format)) {
            alert("시작일시가 종료일시 보다 미래 일 수 없습니다.\n다시 설정해 주세요.");
            $("#start_date").focus();
            return false;
        } else if(new Date(this.state.start_date_format).valueOf() == new Date(this.state.end_date_format).valueOf()) {
            alert("종료 일시는 시작 일시와 다르게 선택해 주세요. ");
            $("#start_date").focus();
            return false;
        }

        if((this.state.pc_banner_ratio_list.length > 0 && this.state.w_is_button_use == "Y") || (this.state.mobile_banner_ratio_list.length > 0 && this.state.h_is_button_use == "Y")){
            for(var i=0;i<this.state.button_list.length;i++){
                if(!this.state.button_list[i].button_text){
                    alert("버튼 텍스트를 입력해주세요.");
                    $("#button_text_input_"+(i+1)).focus();
                    return false;
                }
                if(!this.state.button_list[i].link){
                    alert("버튼 링크를 입력해주세요.");
                    $("#button_link_input_"+(i+1)).focus();
                    return false;
                }
            }
        }

        this.stroeOrSettingCnt()

        const {is_recommendation_small_title} = this.state;
        // 배너 문구 replace
        let replaceText = function(text){
            return text.replace(/(?:\r\n|\r|\n)/g, '<br />');
        };
        let item_img = (this.state.is_detail_image_use == true && this.state.select_item_list[0].detail_image) ? this.state.select_item_list[0].detail_image : this.state.select_item_list[0].image ? this.state.select_item_list[0].image : "";

        // 폰트 리스트 호출
        this.updateFontList(font_family_list);

        let event_banner_pc_width, event_banner_pc_height, event_banner_mobile_width, event_banner_mobile_height;
        if((this.state.event_banner_pc_width && this.state.event_banner_pc_height && Number(this.state.event_banner_pc_width) > 0 && Number(this.state.event_banner_pc_height) > 0) && !(this.state.event_banner_mobile_width && this.state.event_banner_mobile_height && Number(this.state.event_banner_mobile_width) > 0 && Number(this.state.event_banner_mobile_height) > 0)){
            event_banner_pc_width = this.state.event_banner_pc_width;
            event_banner_pc_height = this.state.event_banner_pc_height;
            event_banner_mobile_width = this.state.event_banner_pc_width;
            event_banner_mobile_height = this.state.event_banner_pc_height;
        } else if(!(this.state.event_banner_pc_width && this.state.event_banner_pc_height && Number(this.state.event_banner_pc_width) > 0 && Number(this.state.event_banner_pc_height) > 0) && (this.state.event_banner_mobile_width && this.state.event_banner_mobile_height && Number(this.state.event_banner_mobile_width) > 0 && Number(this.state.event_banner_mobile_height) > 0)){
            event_banner_pc_width = this.state.event_banner_mobile_width;
            event_banner_pc_height = this.state.event_banner_mobile_height;
            event_banner_mobile_width = this.state.event_banner_mobile_width;
            event_banner_mobile_height = this.state.event_banner_mobile_height;
        } else if(this.state.single_size) {
            event_banner_pc_width = this.state.event_banner_pc_width;
            event_banner_pc_height = this.state.event_banner_pc_height;
            event_banner_mobile_width = this.state.event_banner_pc_width;
            event_banner_mobile_height = this.state.event_banner_pc_height;
        } else {
            event_banner_pc_width = this.state.event_banner_pc_width;
            event_banner_pc_height = this.state.event_banner_pc_height;
            event_banner_mobile_width = this.state.event_banner_mobile_width;
            event_banner_mobile_height = this.state.event_banner_mobile_height;
        }

        let body = {
            event_seq : this.state.event_seq,
            user_seq : this.state.user_seq,
            shop_no : "1",
            iframe_page_id : this.state.iframe_page_id,
            iframe_html_id : this.state.iframe_html_id,
            pre_iframe_html_id : this.state.pre_iframe_html_id,
            item_user_type : this.state.item_user_type,
            target_view_seq_list : this.state.selected_target_view_list.length != 0 ? this.state.selected_target_view_list.map(obj => obj.target_view_seq) : null,

            event_type_cd : this.state.event_type_cd,
            event_mobile_type_cd : this.state.event_mobile_type_cd,

            
            none_title : replaceText(this.state.none_title),
            title : replaceText(this.state.title),
            content : replaceText(this.state.title),
            // 작은 글자 추천 여부에 따라 적용
            foreign_title : is_recommendation_small_title == "on" ? replaceText(this.state.robomd_on_foreign_title) : replaceText(this.state.foreign_title),
            sentence : is_recommendation_small_title == "on" ? replaceText(this.state.robomd_on_sentence) : replaceText(this.state.sentence),
            eng_sentence : is_recommendation_small_title == "on" ? replaceText(this.state.robomd_on_eng_sentence) : replaceText(this.state.eng_sentence),
            
            img_link : item_img,
            img_detail_link : item_img,
            img_third_link : item_img,
            img_fourth_link : item_img,
            h_img_link : item_img,
            h_img_detail_link : item_img,
            h_img_third_link : item_img,
            h_img_fourth_link : item_img,

            w_crop_img_info_list : this.state.w_crop_img_info_list,
            h_crop_img_info_list : this.state.h_crop_img_info_list,

            image_type_cd : this.state.image_type_cd,
            
            color_code_1 : this.state.background,
            color_code_2 : this.state.background2,
            color_code_3 : this.state.background3,
            color_code_4 : this.state.background4,
            
            color_value_1 : this.state.color_value_1,
            color_value_2 : this.state.color_value_2,
            color_value_3 : this.state.color_value_3,
            color_value_4 : this.state.color_value_4,
            
            color_lmnst_1 : this.state.color_lmnst_1,
            color_lmnst_2 : this.state.color_lmnst_2,
            color_lmnst_3 : this.state.color_lmnst_3,
            color_lmnst_4 : this.state.color_lmnst_4,
            
            color_strtn_1 : this.state.color_strtn_1,
            color_strtn_2 : this.state.color_strtn_2,
            color_strtn_3 : this.state.color_strtn_3,
            color_strtn_4 : this.state.color_strtn_4,
            
            color_hue_1 : this.state.color_hue_1,
            color_hue_2 : this.state.color_hue_2,
            color_hue_3 : this.state.color_hue_3,
            color_hue_4 : this.state.color_hue_4,
            
            start_date : this.state.start_date_format,
            end_date : this.state.end_date_format,
            product_no : this.state.product_no,
            display_yn : this.state.display_yn,
            reg_user_seq : this.state.reg_user_seq,
            reg_date : this.state.reg_date,
            is_soldout_display : this.state.solution_type_cd == "09900" ? "Y" : this.state.is_soldout_display,
            simple_date_cd : this.state.simple_date_cd,
            link_url : this.state.link_url && this.state.is_link_url == "Y" ? this.state.link_url : null,
            gpt_main_keyword : this.state.gpt_main_keyword,
            event_price : this.state.event_price,

            facial_x_start : this.state.facial_x_start,
            facial_y_start : this.state.facial_y_start,
            facial_width: this.state.facial_width,
            facial_height : this.state.facial_height,
            facial_yn : this.state.facial_yn,
            origin_img_width : this.state.origin_img_width,
            origin_img_height : this.state.origin_img_height,
            
            /* 텍스트 컬러 */
            w_large_text_color : this.state.w_large_text_color,
            h_large_text_color : this.state.h_large_text_color,
            w_small_text_color : this.state.w_small_text_color,
            h_small_text_color :this.state.h_small_text_color,
            /* 텍스트 외 요소 색상 */
            w_color_etc : this.state.w_color_etc,
            h_color_etc : this.state.h_color_etc,
            /* 번역 */
            translated_yn : this.state.translated_yn,
            language_code : this.state.language_code,

            // 미리보기 사이즈 입력값
            event_banner_pc_width : event_banner_pc_width,
            event_banner_pc_height : event_banner_pc_height,
            event_banner_mobile_width : event_banner_mobile_width,
            event_banner_mobile_height : event_banner_mobile_height,
            		
    		/* 버튼 리스트 */
            button_list : this.state.button_list,

            /* 텍스트 위치 이동 */
            w_main_txt_position_x : Number(this.state.w_main_txt_position_right) - Number(this.state.w_main_txt_position_left),
            w_main_txt_position_y : Number(this.state.w_main_txt_position_top) - Number(this.state.w_main_txt_position_bottom),
            h_main_txt_position_x : Number(this.state.h_main_txt_position_right) - Number(this.state.h_main_txt_position_left),
            h_main_txt_position_y : Number(this.state.h_main_txt_position_top) - Number(this.state.h_main_txt_position_bottom),
            w_sub_txt_position_x : Number(this.state.w_sub_txt_position_right) - Number(this.state.w_sub_txt_position_left),
            w_sub_txt_position_y : Number(this.state.w_sub_txt_position_top) - Number(this.state.w_sub_txt_position_bottom),
            h_sub_txt_position_x : Number(this.state.h_sub_txt_position_right) - Number(this.state.h_sub_txt_position_left),
            h_sub_txt_position_y : Number(this.state.h_sub_txt_position_top) - Number(this.state.h_sub_txt_position_bottom),

            /* 이미지 크기 조절 */
            w_img_1_scale: this.state.w_img_1_scale,
            w_img_2_scale: this.state.w_img_2_scale,
            w_img_3_scale: this.state.w_img_3_scale,
            w_img_4_scale: this.state.w_img_4_scale,
            h_img_1_scale: this.state.h_img_1_scale,
            h_img_2_scale: this.state.h_img_2_scale,
            h_img_3_scale: this.state.h_img_3_scale,
            h_img_4_scale: this.state.h_img_4_scale,

            /* 텍스트 크기 조절 */
            w_main_txt_scale: this.state.w_main_txt_scale,
            h_main_txt_scale: this.state.h_main_txt_scale,
            w_sub_txt_scale: this.state.w_sub_txt_scale,
            h_sub_txt_scale: this.state.h_sub_txt_scale,

            /* 폰트 리스트 */
            font_family_list: this.state.font_family_list,
        };

        // 이미지가 사용되는 영역의 이미지 처리
        for(var i=0;i<this.state.w_img_position_name.length;i++){
            body[this.state.w_img_position_name[i].img_column_name] = this.state[this.state.w_img_position_name[i].img_column_name];
            // 업로드 이미지 관련 처리
            if(this.state.w_user_img_list[i].img_link){
                // 동영상 주소 타입인데, 유튜브를 사용하지 않는 템플릿인 경우 업로드 img_link(썸네일)을 적용하지 않음
                if(!(this.state.w_is_youtube_link_use != "Y" && this.state.w_user_img_list[i].link_type == "49005")){
                    body[this.state.w_img_position_name[i].img_column_name] = this.state.w_user_img_list[i].img_link;
                }
                // 파일 업로드의 경우 img_seq도 저장
                if(this.state.w_user_img_list[i].link_type == "49001"){
                    body["robo_banner_org_"+this.state.w_img_position_name[i].img_column_name+"_seq"] = this.state.w_user_img_list[i].org_img_seq;
                }
            }
        }
        // 이미지가 사용되는 영역의 이미지 처리
        for(var i=0;i<this.state.h_img_position_name.length;i++){
            body["h_"+this.state.h_img_position_name[i].img_column_name] = this.state["h_"+this.state.h_img_position_name[i].img_column_name];
            // 업로드 이미지 관련 처리
            if(this.state.h_user_img_list[i].img_link){
                // 동영상 주소 타입인데, 유튜브를 사용하지 않는 템플릿인 경우 업로드 img_link(썸네일)을 적용하지 않음
                if(!(this.state.h_is_youtube_link_use != "Y" && this.state.h_user_img_list[i].link_type == "49005")){
                    body["h_"+this.state.h_img_position_name[i].img_column_name] = this.state.h_user_img_list[i].img_link;
                }
                // 파일 업로드의 경우 img_seq도 저장
                if(this.state.h_user_img_list[i].link_type == "49001"){
                    body["h_robo_banner_org_"+this.state.h_img_position_name[i].img_column_name+"_seq"] = this.state.h_user_img_list[i].org_img_seq;
                }

            }
        }

        for(var img_position_idx = 1; img_position_idx <= 4; img_position_idx ++) {
            // 위쪽, 왼쪽으로 이동시 음수로 보낸다
            /* 가로형 */
            body["img_position_x_"+img_position_idx] = Number(this.state["w_img_position_left_"+img_position_idx]) != 0 ? Number(this.state["w_img_position_left_"+img_position_idx])*-1 : Number(this.state["w_img_position_right_"+img_position_idx]);
            body["img_position_y_"+img_position_idx] = Number(this.state["w_img_position_top_"+img_position_idx]) != 0 ? Number(this.state["w_img_position_top_"+img_position_idx])*-1 : Number(this.state["w_img_position_bottom_"+img_position_idx]);
            /* 세로형 */
            body["h_img_position_x_"+img_position_idx] = Number(this.state["h_img_position_left_"+img_position_idx]) != 0 ? Number(this.state["h_img_position_left_"+img_position_idx])*-1 : Number(this.state["h_img_position_right_"+img_position_idx]);
            body["h_img_position_y_"+img_position_idx] = Number(this.state["h_img_position_top_"+img_position_idx]) != 0 ? Number(this.state["h_img_position_top_"+img_position_idx])*-1 : Number(this.state["h_img_position_bottom_"+img_position_idx]);
        }

        // 4개의 이미지 영역중 배너 타입에 따라 쓰지 않게된 부분의 초기화를 위한 설정
        for(var i=0;i<4;i++){
            /* 이미지 위치 조절 값 : 50002*/
            body["img_position_"+(i+1)+"_type"] = "50002";
            body["h_img_position_"+(i+1)+"_type"] = "50002";
            
            // 이미지 누끼딴 여부(yn)
            body["w_"+img_order_text[i]+"_nukki_yn"] = "N";
            body["h_"+img_order_text[i]+"_nukki_yn"] = "N";

            // 이미지 ppm 상태 : 밑에서 Y로 사용중인 것만 체크하여 값 변경
            body["w_"+img_order_text[i]+"_nukki_ppm_yn"] = "N";
            body["h_"+img_order_text[i]+"_nukki_ppm_yn"] = "N";
            
            // 배경제거 버전
            body["w_"+img_order_text[i]+"_nukki_version"] = "1";
            body["h_"+img_order_text[i]+"_nukki_version"] = "1";

            // 이미지 타입 초기화 (49001 : 업로드 / 49002 : 링크 / 49004 : 로보이미지)
            body["w_"+img_order_text[i]+"_link_type"] = "49001";
            body["h_"+img_order_text[i]+"_link_type"] = "49001";
            body["w_"+img_order_text[i]+"_49002_link"] = null;
            body["h_"+img_order_text[i]+"_49002_link"] = null;
            body["w_"+img_order_text[i]+"_49004_link"] = null;
            body["h_"+img_order_text[i]+"_49004_link"] = null;

            // 누끼 이미지 링크
            if(this.state["w_"+img_order_text[i]+"_nukki_yn"] == "Y"){
                body["w_nukki_"+img_order_text[i]+"_link"] = this.state["w_nukki_"+img_order_text[i]+"_link"];
            }
            if(this.state["h_"+img_order_text[i]+"_nukki_yn"] == "Y"){
                body["h_nukki_"+img_order_text[i]+"_link"] = this.state["h_nukki_"+img_order_text[i]+"_link"];
            }

            body[youtube_order_text[i]+"_link"] = "";
            body["h_"+youtube_order_text[i]+"_link"] = "";
            body[youtube_order_text[i]+"_thumbnail"] = "";
            body["h_"+youtube_order_text[i]+"_thumbnail"] = "";
            body["w_is_"+youtube_order_text[i]+"_use"] = "N";
            body["h_is_"+youtube_order_text[i]+"_use"] = "N";
        }

        for(var i=0;i<this.state.w_img_position_name.length;i++){
            body["img_position_"+(i+1)+"_type"] = this.state["img_position_"+(i+1)+"_type"] ? this.state["img_position_"+(i+1)+"_type"] : "50001";
            body["w_"+this.state.w_img_position_name[i].position_name+"_nukki_yn"] = this.state["w_"+this.state.w_img_position_name[i].position_name+"_nukki_yn"];
            body["w_"+this.state.w_img_position_name[i].position_name+"_nukki_ppm_yn"] = this.state.w_nukki_ppm_state[i] ? "Y" : "N";
            body["w_"+this.state.w_img_position_name[i].position_name+"_nukki_version"] = this.state.w_nukki_version_list[i];
            body["w_"+this.state.w_img_position_name[i].position_name+"_link_type"] = this.state.w_user_img_list[i].link_type;
            if(this.state.w_user_img_list[i].link_type == "49002"){
                body["w_"+this.state.w_img_position_name[i].position_name+"_49002_link"] = this.state.w_user_img_list[i].img_link;
            }
            if(this.state.w_user_img_list[i].link_type == "49004"){
                body["w_"+this.state.w_img_position_name[i].position_name+"_49004_link"] = this.state.w_user_img_list[i].img_link;
            }
            if(this.state.w_is_youtube_link_use == "Y" && this.state.w_user_img_list[i].link_type == "49005" && this.state.w_user_img_list[i].video_link){
                let video_link = this.state.w_user_img_list[i].video_link;
                // 입력값에 https://가 들어있으면 그대로 사용, 없다면 https://를 붙여서 사용
                if(!video_link.includes("https://")){
                    video_link = video_link.includes("youtube.com") ? ("https://www.youtube.com" + video_link.split("youtube.com")[1]) : ("https://www.youtu.be" + video_link.split("youtu.be")[1]);
                }
                body[youtube_order_text[i]+"_link"] = video_link;
                body[youtube_order_text[i]+"_thumbnail"] = this.state.w_user_img_list[i].img_link;
                body["w_is_"+youtube_order_text[i]+"_use"] = this.state.w_user_img_list[i].video_link ? "Y" : "N";
            }
        }
        for(var i=0;i<this.state.h_img_position_name.length;i++){
            body["h_img_position_"+(i+1)+"_type"] = this.state["h_img_position_"+(i+1)+"_type"] ? this.state["h_img_position_"+(i+1)+"_type"] : "50001";
            body["h_"+this.state.h_img_position_name[i].position_name+"_nukki_yn"] = this.state["h_"+this.state.h_img_position_name[i].position_name+"_nukki_yn"];
            body["h_"+this.state.h_img_position_name[i].position_name+"_nukki_ppm_yn"] = this.state.h_nukki_ppm_state[i] ? "Y" : "N";
            body["h_"+this.state.h_img_position_name[i].position_name+"_nukki_version"] = this.state.h_nukki_version_list[i];
            body["h_"+this.state.h_img_position_name[i].position_name+"_link_type"] = this.state.h_user_img_list[i].link_type;
            if(this.state.h_user_img_list[i].link_type == "49002"){
                body["h_"+this.state.h_img_position_name[i].position_name+"_49002_link"] = this.state.h_user_img_list[i].img_link;
            }
            if(this.state.h_user_img_list[i].link_type == "49004"){
                body["h_"+this.state.h_img_position_name[i].position_name+"_49004_link"] = this.state.h_user_img_list[i].img_link;
            }
            if(this.state.h_is_youtube_link_use == "Y" && this.state.h_user_img_list[i].link_type == "49005" && this.state.h_user_img_list[i].video_link){
                let video_link = this.state.h_user_img_list[i].video_link;
                // 입력값에 https://가 들어있으면 그대로 사용, 없다면 https://를 붙여서 사용
                if(!video_link.includes("https://")){
                    video_link = video_link.includes("youtube.com") ? ("https://www.youtube.com" + video_link.split("youtube.com")[1]) : ("https://www.youtu.be" + video_link.split("youtu.be")[1]);
                }
                body["h_"+youtube_order_text[i]+"_link"] = video_link;
                body["h_"+youtube_order_text[i]+"_thumbnail"] = this.state.h_user_img_list[i].img_link;
                body["h_is_"+youtube_order_text[i]+"_use"] = this.state.h_user_img_list[i].video_link ? "Y" : "N";
            }
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }
		http.post("/rest/common/event/save", body, config)
		.then(res => {
            if (res.data.err_msg) {
                // alert(res.data.err_msg);
                if (res.data.err_code == 401) {
                    window.location.href = "/logout";
                }
            } else {
                let save_notice = "";
                if (this.state.solution_type_cd != "09900" && this.getUrlParams()["sample_yn"] && this.getUrlParams()["sample_yn"] == "Y") {
                    save_notice = "저장되었습니다. 이제부터 배너 목록에서 '샘플 배너'가 아니라 '로보 배너'로 표기됩니다.";
                } else {
                    save_notice = "저장되었습니다.";
                }
                if(res.data.is_change_order_no){
                    let save_page_name = this.state.page_list.find((obj) => obj.code_name == this.state.iframe_page_id)["val1"];
                    let save_html_name = this.state.html_list.find((obj) => obj.iframe_html_id == this.state.iframe_html_id)["iframe_html_name"];
                    save_notice += "\n이 배너는 ["+save_page_name+"]의 ["+save_html_name+"] 영역의 1번째 순서로 변경되었습니다.";
                }
                alert(save_notice);
                if (this.getUrlParams()["event_seq"]) {
                    window.location.href = window.location.href.replace('&sample_yn=Y', "");
                } else {
                    window.location.href="/shop/cafe24/event/form?event_seq="+res.data.event_seq;
                }
            }
		})
		.catch(error => {
			// console.log ("===== error =====");
			// console.log (error);
			// console.log ("===== error request =====");
			// console.log(error.request);
		 });
    }

    loadCafe24Item() {
        $("#non_product_msg").css("display", "none");
        $("#loding_product_msg").css("display", "block");

        if (this.state.startIdx == 0) {
            this.setState({ totalCnt : 0 }); // 총 개수 비우기 (페이징을 비우기 위한 용도)
        }
        this.setState({ item_list : [] }); // 상품목록 비우기

        product_random_seed = Math.floor(Math.random() * 10000);

        http.get("/rest/common/list/product?product_name="+this.state.product_name+"&user_seq="+this.state.user_seq+"&shop_no="+this.state.shop_no
        +"&startidx="+this.state.startIdx+"&rowcnt="+this.state.rowcnt
        +"&order_type="+this.state.sorting+"&order_gubun="+this.state.orderGubun+"&category_no="+this.state.category_no
        +"&sold_out="+this.state.product_sold_out
        +"&product_random_seed="+product_random_seed)
        .then(res => {
            // 가장 최신 결과만 받고싶어서 랜덤값을 사용하는 것(이라고 추측)
            if (res.data.product_random_seed != product_random_seed) {
                return false;
            }
            $("#loding_product_msg").css("display", "none");

            isTmp = false;
            this.setState({
                item_list : res.data.list,
                totalCnt : res.data.totalCnt,
            }, () => {
                if (!first_lodded_cafe24_item && res.data.list.length > 0) {
                    first_lodded_cafe24_item = true;

                    const sample_yn = this.getUrlParams()["sample_yn"];
                    if (sample_yn && sample_yn == "Y" || !this.getUrlParams()["event_seq"]){
                        
                        is_banner_ratio_loaded = true;

                        let item_img = res.data.list[0].image ? res.data.list[0].image : "";
                        // 디폴트 상품 선택
                        this.setState({
                            select_item_list : [
                                {
                                    product_no: res.data.list[0].product_no,
                                    product_code: res.data.list[0].product_code,
                                    product_name: res.data.list[0].product_name,
                                    image: res.data.list[0].image,
                                    detail_image: res.data.list[0].detail_image,
                                    category_name: res.data.list[0].category_name,
                                    retail_price: res.data.list[0].retail_price,
                                    hit_cnt: res.data.list[0].hit_cnt,
                                    sale_cnt: res.data.list[0].sale_cnt,
                                    review_cnt: res.data.list[0].review_cnt
                                }
                            ],
                            image : res.data.list[0].image ,
                            product_no : res.data.list[0].product_no,
                            facial_x_start : 0,
                            facial_y_start : 0,
                            facial_width : 0,
                            facial_height : 0,
                            facial_yn : 0,
                            origin_img_width : 0,
                            origin_img_height : 0,
                            img_link : item_img,
                            img_detail_link : item_img,
                            img_third_link : item_img,
                            img_fourth_link : item_img,
                            h_img_link : item_img,
                            h_img_detail_link : item_img,
                            h_img_third_link : item_img,
                            h_img_fourth_link : item_img,

                            is_recommendation_small_title : sample_yn != "Y" ? "on" : ""
                        }, () => {
                            // 샘플 배너 일 때는 상품 선택 시 큰글자 작은 글자 재로딩 하지 않음.
                            if (sample_yn != "Y") { 
                                this.getAutoSubTitle(); // 자동 작은 글자 제목 재로딩
                            }
                        });
                    }
                }
            });

            // 상품이 존재하지 않습니다.
            if(res.data.list.length == 0) {
                $("#non_product_msg").css("display", "block");
            } else {
                $("#non_product_msg").css("display", "none");
            }
        })
        .catch(
            error => { 
                // console.log("저장에 실패하였습니다."); 
                isTmp = true;
            }
        );

        // 자동배너 수정 - 상품검색
        ReactGA.event({
            category: "자동배너 수정",
            action: "상품검색 : " + this.state.product_name,
            label: ga_label
        });
    }

    // 엔터키 검색
	_handleKeyDown = e => {
		if (e.key === 'Enter') {
			this.state.startIdx = 0;
			this.state.activePage = 1;
			this.loadCafe24Item();
		}
    }

    // 페이징
	handlePageChange(pageNumber) {
        isTmp = false;
        this.setState({
            activePage: pageNumber,
            startIdx: ((pageNumber * 12) - this.state.rowcnt)
        }, () => {
            this.loadCafe24Item();
        });
		// this.state.startIdx = (pageNumber * 10) - this.state.rowcnt;
	}   

    handleChange = (color, complete) => {
        if(complete){
            isTmp = true;
        }
        this.setState({ 
            // background: color.hex + Math.round(color.rgb.a * 255).toString(16)
            background: color.hex,
            color_value_1 : "",
            color_lmnst_1 : "",
            color_strtn_1 : "",
        });

        $("#image_2_0").css("background-color", "rgb("+color.hex + Math.round(color.rgb.a * 255).toString(16)+")");

        // 자동배너 수정 - 배경색상1
        ReactGA.event({
            category: "자동배너 수정",
            action: "배경색상1 : " + color.hex,
            label: ga_label
        });

        console.log("##### color: "+color.hex + Math.round(color.rgb.a * 255).toString(16));

    };

    handleChange2 = (color, complete) => {
        if(complete){
            isTmp = true;
        }
        this.setState({ 
            // background2: color.hex + Math.round(color.rgb.a * 255).toString(16)
            background2: color.hex,
            color_value_2 : "",
            color_lmnst_2 : "",
            color_strtn_2 : "",
        });

        // 자동배너 수정 - 배경색상2
        ReactGA.event({
            category: "자동배너 수정",
            action: "배경색상2 : " + color.hex,
            label: ga_label
        });

        console.log("##### color: "+color.hex + Math.round(color.rgb.a * 255).toString(16));
    };

    handleChange3 = (color, complete) => {
        if(complete){
            isTmp = true;
        }
        this.setState({ 
            // background3: color.hex + Math.round(color.rgb.a * 255).toString(16)
            background3: color.hex,
            color_value_3 : "",
            color_lmnst_3 : "",
            color_strtn_3 : "",
        });

        // 자동배너 수정 - 배경색상3
        ReactGA.event({
            category: "자동배너 수정",
            action: "배경색상3 : " + color.hex,
            label: ga_label
        });

        console.log("##### color: "+color.hex + Math.round(color.rgb.a * 255).toString(16));

    };

    handleChange4 = (color, complete) => {
        if(complete){
            isTmp = true;
        }
        this.setState({ 
            // background4: color.hex + Math.round(color.rgb.a * 255).toString(16)
            background4: color.hex,
            color_value_4 : "",
            color_lmnst_4 : "",
            color_strtn_4 : "",
        });

        // 자동배너 수정 - 배경색상4
        ReactGA.event({
            category: "자동배너 수정",
            action: "배경색상4 : " + color.hex,
            label: ga_label
        });

        console.log("##### color: "+color.hex + Math.round(color.rgb.a * 255).toString(16));

    };

    handleChangeTextColor = (color, name, complete) => {

        this.setState({ 
            [name]: color.hex
        }, () => {
            if(complete){
                this.insertEventTmp('preview');
            }
        });

        // 자동배너 수정 - 배경색상4
        ReactGA.event({
            category: "자동배너 수정",
            action: name+" : " + color.hex,
            label: ga_label
        });

        console.log("##### color: "+color.hex + Math.round(color.rgb.a * 255).toString(16));
    };

    onClickModalTableItem = (e,item) => {
        // 이미 선택되어있는 상품을 선택했다면 동작하지 않도록 한다
        if(this.state.select_item_list.length > 0 && this.state.select_item_list[0].product_no == item.product_no){
            return false;
        }
        this.nukkiBlockPageClick();

        let item_img = (this.state.is_detail_image_use == true && item.detail_image) ? item.detail_image : item.image ? item.image : null;
        this.setState({ select_item_list : [
                {
                    product_no: item.product_no,
                    product_code: item.product_code,
                    product_name: item.product_name,
                    image: item.image,
                    detail_image: item.detail_image,
                    category_name: item.category_name,
                    retail_price: item.retail_price,
                    hit_cnt: item.hit_cnt,
                    sale_cnt: item.sale_cnt,
                    review_cnt: item.review_cnt
                }
            ],
            image : item.image,
            product_no : item.product_no,
        
            facial_x_start : 0,
            facial_y_start : 0,
            facial_width : 0,
            facial_height : 0,
            facial_yn : 0,
            origin_img_width : 0,
            origin_img_height : 0,

            img_link : item_img,
            img_detail_link : item_img,
            img_third_link : item_img,
            img_fourth_link : item_img,
            h_img_link : item_img,
            h_img_detail_link : item_img,
            h_img_third_link : item_img,
            h_img_fourth_link : item_img,
        }, () => {
            this.setHeight();
            if(this.state.is_recommendation_small_title == "on"){
                this.setState({
                    save_dialog_show_state: true,
                    save_dialog_content: "AI 문구 추천중 입니다.",
                    save_dialog_button_list: [
                        {
                            title: "확인",
                            type: "1",
                            display_yn:"N",
                        }
                    ]
                }, () => {
                    $("#blockPageClick").hide();
                });

                if (this.state.is_recommendation_small_title == "on"){
                    this.getAutoSubTitle(false, true); // 자동 작은 글자 제목 재로딩
                }
            } else{
                is_auto_sub_title_load = true;
                this.eventChangeProductImageOfNukki();
            }
        });
        
        // 자동배너 수정 - 상품선택
        ReactGA.event({
            category: "자동배너 수정",
            action: "상품선택 : " + item.product_name,
            label: ga_label
        });
    }

    onClickSorting = (e) => {
        this.setState({
            orderGubun : e.target.id.replace(" sorting",""),
            sorting : "DESC",
            activePage : 1,
            totalCnt : 0,
            startIdx : 0,
        }, () => {
            this.loadCafe24Item();
        });
    };

    onClickSortingArrow = (e, sorting) => {
        this.setState({
            sorting : sorting,
            activePage : 1,
            totalCnt : 0,
            startIdx : 0,
        }, () => {
            this.loadCafe24Item();
        });
    };
    

    onClickDisplayYn = (value) => {
        // 진열여부 변경전 대상 페이지, 배너를 선택 해야함.
        if (!this.state.iframe_page_id){
            alert("적용 범위 - 페이지를 선택해 주세요.");
            $("#iframe_page_id_select").focus();
            return false;
        }
        if (!this.state.iframe_html_id){
            alert("적용 범위 - 배너를 선택해 주세요.");
            $("#iframe_html_id_select").focus();
            return false;
        }
        this.stroeOrSettingCnt(value);

        // 자동배너 수정 - 진열여부
        ReactGA.event({
            category: "자동배너 수정",
            action: "진열여부 : " + value,
            label: ga_label
        });
    };

    onClickIsLinkUrl = (e, value) => {
        this.setState({is_link_url : e.target.value,})

        // 자동배너 수정 - 진열여부
        ReactGA.event({
            category: "자동배너 수정",
            action: "링크여부 : " + value,
            label: ga_label
        });
    };

    onChageLinkUrl = e => {
        this.setState({
            link_url : e.target.value,
        })
    }

    handleTitleBlur = e => {
        // console.log(e);
        // 자동배너 수정 - 배너제목
        ReactGA.event({
            category: "자동배너 수정",
            action: (e.target.id == "foreign_title" ? "(영문)" : "") + "배너제목 : " + e.target.value,
            label: ga_label
        });
    };

    onClickBtnRecommendationForSmallTitle = (e, state) => {
        if (this.state.product_no) {
            this.setState({is_recommendation_small_title: state}, () => {
                if (state == "on") {
                    $("#subTextEditor").hide();
                    if(this.state.autoSubTextList && this.state.autoSubTextList.length > 0 && !is_auto_sub_title_load) {
                        this.insertEventTmp('preview');
                        return false;
                    }
                    this.setState({
                        save_dialog_show_state: true,
                        save_dialog_content: "AI 문구 추천중 입니다.",
                        save_dialog_button_list: [
                            {
                                title: "확인",
                                type: "1",
                                display_yn:"N",
                            }
                        ]
                    });
                    this.getAutoSubTitle(true);

                } else{
                    $("#subTextEditor").show();
                    this.insertEventTmp('preview');
                }
            });
        } else {
            alert("상품을 먼저 선택해 주세요.")
            return false;
        }
    }

    onChangeTitle = (e, name) => {
        let text_value = e.target.value.replace("font-size: 15px;", "");
        const checkInputTimer = function(this_component, value){
            // title_input_timer 동작을 취소한다 (시간이 100 만큼 지나기 전에 입력이 또 있었다면, 이전의 timeout동작은 실행되지 않을것이다)
            clearTimeout(title_input_timer[value]);
            title_input_timer[value] = setTimeout(() => {
                // 미리보기 데이터 저장
                this_component.insertEventTmp('preview');
            },500);
        };

        if (name == "none_title") {
            this.setState({none_title: e.target.value});
        } else if (name == "auto_title") {
            this.setState({[name]: e.target.value,
                            title: e.target.value,
                            org_title: e.target.value
                        }, () =>{checkInputTimer(this, name)});
        } else {
            this.setState({[name]: text_value,
                foreign_title: text_value,
                sentence: text_value,
                eng_sentence: text_value,
            }, () =>{checkInputTimer(this, name)});
        }
        // this.getTextLength (name+"_length", e.target.value);
    }

    onChangeAutoSubText = (e, name) => {
        isTmp = true;
        var index = e.nativeEvent.target.selectedIndex;
        var name2 = "robomd_on_sentence_cd";
        this.setState({[name]: e.nativeEvent.target[index].value,

                        [name2]: e.nativeEvent.target[index].value,
                        robomd_on_sub_title: e.nativeEvent.target[index].text,
                        robomd_on_foreign_title: e.nativeEvent.target[index].text,
                        robomd_on_sentence: e.nativeEvent.target[index].text,
                        robomd_on_eng_sentence: e.nativeEvent.target[index].text,
                    });
    }

    // 글자수 체크 (추후에 반영 - 기획이 필요한 부분이 너무 많음)
    getTextLength = function(name, str) {
        this.setState({[name]: str.length});
    }

    // 배너 라디오 버튼 클릭
    onClickBannerTypeRadioButton = (banner, name, banner_name) => {
        // 선택한 배너가 이미 선택되어있는 배너라면 동작하지 않는다
        if(this.state.event_type_cd == banner.code_cd || this.state.event_mobile_type_cd == banner.code_cd){
            return false;
        }
        this.nukkiBlockPageClick();

        var name2 = name.replace("_cd", "") + "_name";
        var name3 = name.replace("_cd", "") + "_img";
        var name4 = banner_name; // event_pc_banner_name or event_mobile_banner_name
        var name5 = banner_name.replace("_banner_name", "_tag_name_list"); // event_mobile_tag_name_list or event_mobile_tag_name_list
        this.setState({[name] : banner.code_cd,
            [name2] : banner.val1,
            [name3] : banner.val2,
            [name4] : banner.banner_name,
            [name5] : (banner.tag_name_list || "").toString().split(","),
            img_position_idx_list: this.state.img_position_idx_list,
        }, () => {
            this.getisUsePallete();
            this.setHeight();
        });

        if (name.indexOf("mobile") > 0) {
            // 누끼 관련 설정 초기화
            h_nukki_action_state = false;

            for (var idx=1; idx<=4; idx++) {
                if (idx == 4) {
                    is_mobile_banner_color_init = true;
                }
                this.setState({
                    ["h_img_position_left_"+idx]:0,
                    ["h_img_position_right_"+idx]:0,
                    ["h_img_position_top_"+idx]:0,
                    ["h_img_position_bottom_"+idx]:0,

                    ["h_img_position_"+(idx)+"_type"] : "50002",
                    ["h_img_"+idx+"_auto_move_yn"]: "N",
                });
            }

            this.setState({
                event_mobile_cd_use_yn : "Y",
            });

            let mainText;
            let subText;

            if(document.querySelector(".mainTextEditor")) {
                mainText = document.querySelector(".mainTextEditor").innerHTML.replace(/(<([^>]+)>)/ig,"");
                this.setState({
                    title: mainText,
                    auto_title: mainText,
                });
            }
            if(document.querySelector(".subTextEditor")) {
                subText = document.querySelector(".subTextEditor").innerHTML.replace(/(<([^>]+)>)/ig,"");
                this.setState({
                    sub_title: subText,
                    sentence: subText,
                    eng_sentence: subText,
                    foreign_title: subText,
                });
            }

            // 이미지 크기 조절 초기화
            this.resetImgScale("h", "1");
            this.resetImgScale("h", "2");
            this.resetImgScale("h", "3");
            this.resetImgScale("h", "4");

            // 텍스트 위치 이동 초기화
            this.moveTextPositionResetOnClick(null, "h", "main");
            this.moveTextPositionResetOnClick(null, "h", "sub");

            // 텍스트 크기 조절 초기화
            this.handleTxtScaleResetOnClick(null, "h", "main");
            this.handleTxtScaleResetOnClick(null, "h", "sub");

        } else {
            // 누끼 관련 설정 초기화
            w_nukki_action_state = false;

            for (var idx=1; idx<=4; idx++) {
                if (idx == 4) {
                    is_banner_color_init = true;
                }
                this.setState({
                    ["w_img_position_left_"+idx]:0,
                    ["w_img_position_right_"+idx]:0,
                    ["w_img_position_top_"+idx]:0,
                    ["w_img_position_bottom_"+idx]:0,

                    ["img_position_"+(idx)+"_type"] : "50002",
                    ["w_img_"+idx+"_auto_move_yn"]: "N",
                });
            }

            this.setState({
                event_type_cd_use_yn : "Y",
            });

            let mainText;
            let subText;

            if(document.querySelector(".mainTextEditor")) {
                mainText = document.querySelector(".mainTextEditor").innerHTML.replace(/(<([^>]+)>)/ig,"");
                this.setState({
                    title: mainText,
                    auto_title: mainText,
                });
            }

            if(document.querySelector(".subTextEditor")) {
                subText = document.querySelector(".subTextEditor").innerHTML.replace(/(<([^>]+)>)/ig,"");
                this.setState({
                    sub_title: subText,
                    sentence: subText,
                    eng_sentence: subText,
                    foreign_title: subText,
                });
            }

            // 이미지 크기 조절 초기화
            this.resetImgScale("w", "1");
            this.resetImgScale("w", "2");
            this.resetImgScale("w", "3");
            this.resetImgScale("w", "4");

            // 텍스트 위치 이동 초기화
            this.moveTextPositionResetOnClick(null, "w", "main");
            this.moveTextPositionResetOnClick(null, "w", "sub");

            // 텍스트 크기 조절 초기화
            this.handleTxtScaleResetOnClick(null, "w", "main");
            this.handleTxtScaleResetOnClick(null, "w", "sub");
        }
    }

    // 이미지 크기 조절 초기화
    resetImgScale(type, idx) {
        this.setState({
            [type + "_img_" + idx + "_scale"] : 1,
        });
    }

    // 배너 페이징
	handleBannerPageChange(pageNumber) {
        const { pc_banner_type_paging, w_selected_tags } = this.state;
        pc_banner_type_paging["activePage"] = pageNumber;
        pc_banner_type_paging["startIdx"] = ((pageNumber * Number(pc_banner_type_paging["rowcnt"])) - pc_banner_type_paging["rowcnt"]);
        this.setState({pc_banner_type_paging : pc_banner_type_paging});
		// this.state.startIdx = (pageNumber * 10) - this.state.rowcnt;
        // this.loadCafe24Item(); // 상품 조회 componentDidUpdate 에서 실행
        //this.getCafe24CodePaging("03000");

        const selected_tag_list = [];
        for(const category in w_selected_tags){
            if(w_selected_tags.hasOwnProperty(category)){
                w_selected_tags[category].map((obj => {
                    selected_tag_list.push(obj)
                }));
            }
        }
        this.getBannerDesignTemplateUserFavoritePaging("03000", selected_tag_list);
    }   
	handleMobileBannerPageChange(pageNumber) {
        const { mobile_banner_type_paging, h_selected_tags } = this.state;
        mobile_banner_type_paging["activePage"] = pageNumber;
        mobile_banner_type_paging["startIdx"] = ((pageNumber * Number(mobile_banner_type_paging["rowcnt"])) - mobile_banner_type_paging["rowcnt"]);
        this.setState({mobile_banner_type_paging : mobile_banner_type_paging});
		// this.state.startIdx = (pageNumber * 10) - this.state.rowcnt;
        // this.loadCafe24Item(); // 상품 조회 componentDidUpdate 에서 실행
        //this.getCafe24CodePaging("07000");

        const selected_tag_list = [];
        for(const category in h_selected_tags){
            if(h_selected_tags.hasOwnProperty(category)){
                h_selected_tags[category].map((obj => {
                    selected_tag_list.push(obj)
                }));
            }
        }
        this.getBannerDesignTemplateUserFavoritePaging("07000", selected_tag_list);
    }  
      
    // 색상 팔레트 확장 여부
    onClickRactangleColorButton = (e, name) => {
        var boolean = false;

        if(!this.state[name]){
            boolean = true;
        }
        
        if (boolean) {
            this.setState({[name] : true});
            $("#palleteBack").show();
        } else {
            this.setState({[name] : false});
            $("#palleteBack").hide();
        }
    }  

    // 색상 팔레트 닫기
    onCloseRactangleColorButton = (e) => {
        $("#palleteBack").hide();
        this.setState({
            is_expand_color1: false,
            is_expand_color2: false,
            is_expand_color3: false,
            is_expand_color4: false,
            is_expand_w_large_text_color: false,
            is_expand_h_large_text_color: false,
            is_expand_w_small_text_color: false,
            is_expand_h_small_text_color: false,
            is_expand_w_color_etc: false,
            is_expand_h_color_etc: false,
        });
    }  

    // 상품 카테고리 클릭
    onClickProductCategory = (e, category_name, category_no, category_depth) => {
        var name = "product_heder_category_name_"+category_depth
        var name2 = "product_heder_category_no_"+category_depth

        if (category_depth == 4) {
            $("#product_category_all").css("color", "#333333");
        }
        
        this.setState({
            category_no: category_no,
            [name]: category_name,
            [name2]: category_no,
        }, () => {
            this.loadCafe24Item();
        });

        this.loadProductCategory(category_no);
    }

    onClickproductHeaderCategoryForMobile = (e, depth, category_no) => {
        for(var idx = depth; idx <= 4; idx++) {
            var name = "product_heder_category_name_"+idx;
            var name2 = "product_heder_category_no_"+idx;
            var product_category_list = "product_category_list_"+(idx + 1);

            this.setState({
                [name]: "",
                [name2]: "",
                [product_category_list]: null,
            });
        }
        
        this.setState({
            category_no: category_no
        }, () => {
            this.loadCafe24Item();
        });

        this.loadProductCategory(category_no);
    }

    onClickproductHeaderCategory = (e, depth, category_no) => {
        for(var idx = (depth+1); idx <= 4; idx++) {
            var name = "product_heder_category_name_"+idx;
            var name2 = "product_heder_category_no_"+idx;
            this.setState({
                [name]: "",
                [name2]: "",
            });
        } 

        this.setState({
            category_no: category_no
        }, () => {
            this.loadCafe24Item();
        });

        this.loadProductCategory(category_no);
    }

    onClickeFullCalendarEvent = (e) => {

        // 상품을 변경한 경우 또는 문구 색상 템플릿 등등..
        if (e.event.id == this.state.event_seq) {
            calendar_event_seq = -1;
        } else {
            calendar_event_seq = e.event.id;
        }

        //이벤트 번호가 없으면 배너 미리보기 출력
        if (calendar_event_seq != -1) {
            
            $(".fullCalendarModal").show();
            $(".fullCalendarModal").css("left", e.jsEvent.pageX+"px");
            $(".fullCalendarModal").css("top", e.jsEvent.pageY+"px");
            $(".fullCalendarModal").css("transform", "");

            http.get("/rest/shop/is/event/pcmobile?event_seq="+calendar_event_seq)
            .then(res => {
                this.setState({
                    isEventPcMobile: res.data.obj
                }, () => {
                    this.calendarBannerModalSetSize();
                });
            })

            $(".fullCalendarModal").show();
            $(".fullCalendarModal").css("left", e.jsEvent.pageX+"px");
            $(".fullCalendarModal").css("top", e.jsEvent.pageY+"px");
            $(".fullCalendarModal").css("transform", "");

        } else {
            this.onClickMirrorBannerOnOff(e, "on");
        }     
    }

    calendarBannerModalSetSize() {
        if(this.state.isEventPcMobile && this.state.isEventPcMobile.is_pc == "Y") {
            $(".fullCalendarInnerModal .pcBanner").html('<label>가로형</label><br><object class="calendar_robomd_banner_p" type="text/html" data="'+mirror_banner_url+'?event_seq='+calendar_event_seq+'&platform_type=p&mirror_type=18002" type="text/html" style="width:100%;height:100%;border: 1px solid black;border-radius: 13px;"></object>');
        
            $(".fullCalendarInnerModal .pcBanner").show();
            $(".fullCalendarModal").css("width", ((fullCalendarInnerModal_width/6.2)*2.2)+"px");
            $(".fullCalendarModal").css("min-width", ((fullCalendarInnerModal_width/6.2)*2.2)+"px");
        } else {
            $(".fullCalendarInnerModal .pcBanner").hide();
            $(".fullCalendarModal").css("width", ((fullCalendarInnerModal_width/6.2)*1.5)+"px");
            $(".fullCalendarModal").css("min-width", ((fullCalendarInnerModal_width/6.2)*1.5)+"px");
        }
        
        if(this.state.isEventPcMobile && this.state.isEventPcMobile.is_mobile == "Y") {
            $(".fullCalendarInnerModal .mobileBanner").html('<label>세로형</label><br><object class="calendar_robomd_banner_m" type="text/html" data="'+mirror_banner_url+'?event_seq='+calendar_event_seq+'&platform_type=m&mirror_type=18002" type="text/html" style="width:100%;height:100%;margin-left:1%;border: 1px solid black;border-radius: 13px;"></object>');
        
            $(".fullCalendarInnerModal .mobileBanner").show();
            $(".fullCalendarModal").css("width", ((fullCalendarInnerModal_width/6.2)*2.2)+"px");
            $(".fullCalendarModal").css("min-width", ((fullCalendarInnerModal_width/6.2)*2.2)+"px");
            if(!(this.state.isEventPcMobile && this.state.isEventPcMobile.is_pc == "Y")) {
                $(".fullCalendarInnerModal .pcBanner").hide();
                $(".fullCalendarModal").css("width", ((fullCalendarInnerModal_width/6.2)*0.8)+"px");
                $(".fullCalendarModal").css("min-width", ((fullCalendarInnerModal_width/6.2)*0.8)+"px");
            }
        } else {
            $(".fullCalendarInnerModal .mobileBanner").hide();
            $(".fullCalendarModal").css("width", ((fullCalendarInnerModal_width/6.2)*1.5)+"px");
            $(".fullCalendarModal").css("min-width", ((fullCalendarInnerModal_width/6.2)*1.5)+"px");
        }

        fullCalendarInnerModal_pcbanner_width = $(".fullCalendarInnerModal .pcBanner").width();
        fullCalendarInnerModal_mobileBanner_width = $(".fullCalendarInnerModal .mobileBanner").width();
        
        if (this.state.pc_banner_ratio_list.length > 0) {
            $(".fullCalendarInnerModal .pcBanner").height((fullCalendarInnerModal_pcbanner_width /  Number(this.state.selected_pc_banner_ratio.org_width_height)) + "px");
        } else {
            $(".fullCalendarInnerModal .pcBanner").height((fullCalendarInnerModal_pcbanner_width /  1.68) + "px");
        }
        if (this.state.mobile_banner_ratio_list.length > 0) {
            $(".fullCalendarInnerModal .mobileBanner").height((fullCalendarInnerModal_mobileBanner_width / Number(this.state.selected_mobile_banner_ratio.org_width_height))+ "px");
        } else {
            $(".fullCalendarInnerModal .mobileBanner").height((fullCalendarInnerModal_mobileBanner_width / 0.69)+ "px");
        } 
    }

    onClickFullCalendarModalClose = (e) => {
        $(".fullCalendarModal").hide();
    }

    onChangeDateSetting = (e, name) => {
        this.setState({
            [name]:e.target.value,
        }, () => {
            this.changeDetailDateSetting();
        });
    }

    // input 달력 클릭
    handleChangeStartDate = date => {
        this.setState({
            start_date: date ?? "",
        }, () => {
            isTmp = true;
            if(this.state.start_date){
                this.setState({
                    start_date_format: this.state.start_date.getFullYear() + "-"+ ("0" + parseInt(this.state.start_date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(this.state.start_date.getDate())).slice(-2),
                });
            } else{
                this.setState({
                    start_date_format: "",
                });
            }
            this.changeDetailDateSetting();
        });
    };

    handleChangeEndDate = date => {
        this.setState({
            end_date: date ?? "",
        }, () => {
            isTmp = true;
            if(this.state.end_date){
                this.setState({
                    end_date_format: this.state.end_date.getFullYear() + "-"+ ("0" + parseInt(this.state.end_date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(this.state.end_date.getDate())).slice(-2),
                });
            } else{
                this.setState({
                    end_date_format: "",
                });
            }
            this.changeDetailDateSetting();
        });
    };

    onClickSoldOutDisplay = (e) => {
        this.setState({is_soldout_display:e.target.value})
    }   

    onClickItemUserType = (e) => {
        const state = e.target.value;
        if (state == "U" || state == "N") {
            http.get("/rest/shop/solutionType")
            .then(
                resultData => { 
                    if(resultData.data.solution_type_cd != "09003") {
                        this.setState({item_user_type:state})
                    } else {
                        alert("아입웹 솔루션은 회원/비회원 기준을 등록 할 수 없습니다.")
                        return false;
                    }
                })
            .catch(
                error => { this.setState({item_user_type:state}) }
            );
        } else if (this.state.is_use_target_view_service == "N" && state == "T") {
            // 타겟뷰 권한 체크
            this.fn_shopServiceOperated("01009", () => {
                if(this.state.is_use_target_view_service == "N"){
                    this.setState({
                        title_content_dialog_show_state : true,
                        title_content_dialog_title : "타겟뷰 서비스<br/>신청이 필요한 기능입니다.",
                        title_content_dialog_content : "타겟뷰로 맞춤 팝업을 원하는 방문자에게<br/>효과적으로 노출해 보세요.",
                        title_content_dialog_button_list: [
                            {
                                title: "확인",
                                type: "1",
                                event_method: () => {
                                    // 결제 페이지 새창 열기
                                    window.open("/shop/serviceShopAdd?service_type=01009&step=1", '_blank'); // 새 창으로 열기
        
                                    this.setState({
                                        title_content_dialog_show_state: false,
                                    });
                                }
                            },
                            {
                                title: "아니요",
                                type: "0",
                                event_method: () => {
                                    this.setState({
                                        title_content_dialog_show_state: false,
                                    });
                                }
                            }
                        ]
                    });
                } else{
                    this.setState({item_user_type:state})
                }
            });
        } else {
            this.setState({item_user_type:state})
        }
    }   
    onClickSimpleDateCd = (e, firstSetting) => {
        let state = firstSetting ?? e.target.value;
        this.setState({simple_date_cd:state});
        if(state == "17005") {
            if (!this.state.start_date) {
                this.setState({ 
                    start_hour:"0"
                    , start_minute:"0"
                    , start_date: new Date()
                    , end_hour:"23"
                    , end_minute:"59"
                    , end_date: new Date("9999-12-31")
                }, () => {
                    this.changeDetailDateSetting();
                });
            } else {
                this.setState({
                    end_hour:"23",
                    end_minute:"59",
                    end_date: new Date("9999-12-31")
                }, () => {
                    this.changeDetailDateSetting();
                });
            }

        } else if(state == "17002") {
            var nextDate = new Date()
            var dayOfMonth = nextDate.getDate()
            nextDate.setDate(dayOfMonth + 0)
            // nextDate = (nextDate.getFullYear() + '-' + (nextDate.getMonth() + 1) + '-' + nextDate.getDate())

            this.setState({
                start_hour:"0"
                , start_minute:"0"
                , start_date: new Date()
                , end_hour:"23"
                , end_minute:"59"
                , end_date: new Date(nextDate.getFullYear(), nextDate.getMonth(), nextDate.getDate())
            }, () => {
                this.changeDetailDateSetting();
            });

        } else if(state == "17003") {
            var nextDate = new Date()
            var dayOfMonth = nextDate.getDate()
            nextDate.setDate(dayOfMonth + 7)
            // nextDate = (nextDate.getFullYear() + '-' + (nextDate.getMonth() + 1) + '-' + nextDate.getDate())

            this.setState({
                start_hour:"0"
                , start_minute:"0"
                , start_date: new Date()
                , end_hour:"23"
                , end_minute:"59"
                , end_date: new Date(nextDate.getFullYear(), nextDate.getMonth(), nextDate.getDate())
            }, () => {
                this.changeDetailDateSetting();
            });
             
        } else if(state == "17004") {
            var nextDate = new Date()
            var dayOfMonth = nextDate.getDate()
            nextDate.setDate(dayOfMonth + 30)
            // nextDate = (nextDate.getFullYear() + '-' + (nextDate.getMonth() + 1) + '-' + nextDate.getDate())

            this.setState({
                start_hour:"0"
                , start_minute:"0"
                , start_date: new Date()
                , end_hour:"23"
                , end_minute:"59"
                , end_date: new Date(nextDate.getFullYear(), nextDate.getMonth(), nextDate.getDate())
            }, () => {
                this.changeDetailDateSetting();
            });
             
        }

        if (state == "17001") {
            this.state.disabledStartDate = false;
            this.state.disabledEndDate = false;
        } else {
            this.state.disabledStartDate = true;
            this.state.disabledEndDate = true;
        }
    }

    /**
     * 배너 미리보기 버튼 클릭 (비연동형 전용)
     * @param {*} type pc or mobile
     */
    onClickMirrorBannerOn = (type) => {
        const {pc_banner_ratio_list, mobile_banner_ratio_list} = this.state;
        const banner_width = this.state["event_banner_"+type+"_width"];
        const banner_height = this.state["event_banner_"+type+"_height"];

        if(!(banner_width && banner_height)){
            alert("배너 사이즈를 입력해 주세요.");
            return false;
        }
        
        let is_size_horizontal = banner_width > banner_height; // 가로형 여부
        let selected_banner_ratio = {};
        // banner_ratio_list는 pc(배너 사이즈1)이 항상 0번째 자리에 들어가도록 되어있다
        // type이 mobile인 경우 둘다 가로형이거나 세로형이라면 1번째 자리에 들어있는 비율 정보를 사용해야한다
        if(is_size_horizontal && pc_banner_ratio_list.length > 0) {
            selected_banner_ratio = pc_banner_ratio_list.length > 1 && type == "mobile" ? pc_banner_ratio_list[1] : pc_banner_ratio_list[0];
        } else if(!is_size_horizontal && mobile_banner_ratio_list.length > 0){
            selected_banner_ratio = mobile_banner_ratio_list.length > 1 && type == "mobile" ? mobile_banner_ratio_list[1] : mobile_banner_ratio_list[0];
        }

        isTmp = true;
        this.setState({
            mirror_banner_state: "on",
            ["selected_"+(is_size_horizontal ? "pc":"mobile")+"_banner_ratio"]: selected_banner_ratio,
        });
    }

    onClickMirrorBannerOnOff = (e, state) => {
        if(state == "on") {
            isTmp = true;
            this.setState({mirror_banner_state: state});
        } else {
            this.setState({mirror_banner_state: state});
        }
    }

    onChangeSelectBoxCommonHandler = (e, name) => {
        let _iframe_page_id = "";
        let _iframe_html_id = "";

        if (name == "iframe_page_id") {
            _iframe_page_id = e.target.value;

            this.setState({
                iframe_page_id: _iframe_page_id,
            });
            this.getBannerHtmlList(_iframe_page_id);
            // 자동 배너 설정 불러오기
            this.getAutoEventSetting(_iframe_page_id, this.state.iframe_html_id).then(r => {});
            // 파일명 규칙 설정 불러오기
            this.getFileNameSettingList(_iframe_page_id, this.state.iframe_html_id).then(r => {});

        } else {
            _iframe_html_id = e.target.value;

            // $("#blockPageClick").show();

            this.setState({
                iframe_html_id: _iframe_html_id,
            }, () => {
                this.getBannerRatio(true);
                // 자동 배너 설정 불러오기
                this.getAutoEventSetting(this.state.iframe_page_id, _iframe_html_id).then(r => {});
                // 파일명 규칙 설정 불러오기
                this.getFileNameSettingList(this.state.iframe_page_id, _iframe_html_id).then(r => {});
            });
        }
    }

    onClickBackButton = e => {
        console.log(this.props.location.state)
        if (this.props.location.state && this.props.location.state.prevPath.indexOf("/shop/cafe24/event/list") > 0){
            // alert("레퍼러")
            this.props.history.goBack();
        } else {
            // alert("로케이션")
            this.props.history.push("/shop/cafe24/event/list")
        }
    }

    onChangeMirrorBannerRatio = (e, type) => {
        var index = e.nativeEvent.target.selectedIndex;

        if (type == "w") {
            this.setState({
                // 원본 비율 목록에서도 현재 셀렉트 박스에 선택된 인덱스 번호에 해당하는 원본 비율을 선택함.
                selected_pc_banner_ratio:  this.state.pc_banner_ratio_list.length > index 
                                                ? this.state.pc_banner_ratio_list[index]
                                                : {},
            }, () => {this.setHeight()});
        } else {
            this.setState({
                // 원본 비율 목록에서도 현재 셀렉트 박스에 선택된 인덱스 번호에 해당하는 원본 비율을 선택함.
                selected_mobile_banner_ratio:  this.state.mobile_banner_ratio_list.length > index 
                                                ? this.state.mobile_banner_ratio_list[index]
                                                : {},
            }, () => {this.setHeight()});
        }
        isTmp = true;
    }
    
    onClickShortcutFocusing = (e, element_id) => {
        $("#"+element_id).focus();
    }

    selectProductCategory = (e, category_depth) => {
        var name = "product_heder_category_name_"+category_depth;
        var name2 = "product_heder_category_no_"+category_depth;
        this.setState({
            [name]: e.target[e.target.selectedIndex].text,
            [name2]: e.target.value,
        });

        const sendForm = {
            user_seq: this.state.user_seq , 
            shop_no: "1", 
            parent_category_no: e.target.value
        }

        var product_category_list = "product_category_list_"+(category_depth + 1)
		http.get("/rest/common/cafe24/category", {params:sendForm})
		.then(res => {
            if (res.data.list) {
                this.setState({[product_category_list]: res.data.list});
            }
		})
		.catch(error => {
			console.log ("===== error =====");
			console.log (error);
			console.log ("===== error request =====");
			console.log(error.request);
		 });
        this.setState({
            category_no: e.target.value,
        }, () => {
            this.loadCafe24Item();
        });
    }

    stroeOrSettingCnt(state) {
        // 배너 설치 여부
        var is_install = false;

        http.get("/rest/shop/stroeOrSettingCnt?iframe_page_id="+this.state.iframe_page_id+"&iframe_html_id="+this.state.iframe_html_id)
        .then(res => {
            if (res.data.freeDatePeriod) {
                if(res.data.freeDatePeriod.is_finish == "1") {
                    if (res.data.stroeOrSettingCnt.banner_operateed_yn == 'Y') {
                        is_order = true;
                    }
                    is_free_finish = true;
                } else {
                    if (res.data.stroeOrSettingCnt.banner_operateed_yn == 'Y') {
                        is_free_finish = true;
                        is_order = true;
                    } else {
                        is_free_finish = false;
                    }
                }

                // 설치 여부 확인
                if (res.data.bannerInstallInfo) {
                    if (res.data.bannerInstallInfo.install_yn == "Y") {
                        is_install = true;
                    }
                }
            }

            if (state == "Y") {
                if(is_install != true) {
					alert("배너를 진열하려면 고객님 쇼핑몰에 스크립트를 설치해야 합니다. 화면 왼쪽 아래 채팅 아이콘을 눌러 로보MD팀에게 설치를 신청해 주세요.");
					return false;
				} 
                if(is_free_finish == true) {
					//미결제시 진열 불가
					if(is_order != true) {
						alert("자동 진열 기능은 멤버십 결제 후 이용하실 수 있습니다. 결제 후 진열하기로 변경해 주세요.");
						//alert("배너를 진열하려면 고객님 쇼핑몰에 스크립트를 설치해야 합니다. 화면 왼쪽 아래 채팅 아이콘을 눌러 로보MD팀에게 설치를 신청해 주세요.");
						return false;
					}
				}
				// 2022.01.26 : 무료체험기간 중 진열 가능
				//else {
					// if(is_order != true) {
                    //     // alert("무료기간 중에는 진열로 변경 할 수 없습니다.");
                    //     alert("배너를 진열하려면 고객님 쇼핑몰에 스크립트를 설치해야 합니다. 화면 왼쪽 아래 채팅 아이콘을 눌러 로보MD팀에게 설치를 신청해 주세요.");
					// 	return false;
					// }
				//}
            }

            this.setState({display_yn : state})

            // if (state == 'Y') {
            //     if(new Date(this.state.start_date_format) <= new Date()
            //     && new Date() <= new Date(this.state.end_date_format)) 
            //     {
            //         this.setState({display_yn : state})
            //     } else {
            //         alert("진열 기간을 먼저 변경해 주세요.");
            //         $(window).scrollTop($("#date_setting_title").offset().top - 100);
            //     }
            // } else {
            //     this.setState({display_yn : state})
            // }

        })
        .catch(error => {
            alert(error); 
        });
    }

    /**
     * 이미지 업로드 팝업 : 업로드 이벤트
     * @param {*} e 
     * @param {*} idx 배너 이미지 순서 번호
     * @param {*} type w or h
     * @param {*} link_type 49001(파일 업로드) or 49003(상세 이미지)
     */
    _handleImageChange = (e, idx, type, link_type) => {
        this.nukkiBlockPageClick();
        this.attachImage(e.target.files, idx, type, link_type);
    };

    checkFileExtension = (file) => {
        let reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/i;

        if(String(file[0].name).match(reg)) {
            return true;
        }else{
            return false;
        }
    }

    /**
     * 업로드된 이미지 Preview
     * @param {*} acceptedFiles 파일정보
     * @param {*} idx 배너 이미지 순서 번호
     * @param {*} type w or h
     * @param {*} link_type 49001(파일 업로드) or 49003(상세 이미지)
     */
    attachImage(acceptedFiles, idx, type, link_type) {
        if (acceptedFiles) {

            if(!this.checkFileExtension(acceptedFiles)){
                alert("이미지 파일만 첨부할 수 있습니다.");
                return;
            }

            /* Get files in array form */
            const files = Array.from(acceptedFiles);
            //const { getRootProps, getInputProps, isDragActive } = useDropzone({ files });
            /* Map each file to a promise that resolves to an array of image URI's */ 
            Promise.all(files.map(file => {
                return (new Promise((resolve,reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            }))
            .then(images => {
                /* Once all promises are resolved, update state with image URI array */
                console.log(images);
                console.log(files);

                this.setState({
                    save_dialog_show_state: true,
                    save_dialog_content: "이미지 업로드중 입니다.",
                    save_dialog_button_list: [
                        {
                            title: "확인",
                            type: "1",
                            display_yn:"N",
                        }
                    ]
                }, () => {
                    $("#blockPageClick").hide();
                });

                this._handleSubmit(idx, type, files[0], link_type);
            }, error => {        
                console.error(error);
                this.setState({ save_dialog_show_state: false });
            });
        }
    }

    // 이미지 업로드 팝업 : 저장 이벤트
    _handleSubmit = (idx, type, file, link_type) => {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        let url = '/rest/shop/roboBanner/save/orgImg';
        if (link_type == "49003") {
            url = '/rest/shop/roboBanner/save/nooResizeOrgImg'
        }
        const formData = new FormData();
        formData.append("files", file);
        // forEach((file) => formData.append("files", file));
        
        http.post(url, formData, config)
        .then(
            res => {
                // 조회
                this.searchOrgImg(res.data.robo_banner_org_img_seq, idx, type, null, link_type);
            })
        .catch(
            error => {
                alert(error+"\n관리자에게 문의해 주세요.");
                this.setState({ save_dialog_show_state: false });
            }
        );
    };

    /**
     * 
     * @param {*} robo_banner_org_img_seq 이미지 seq
     * @param {*} idx 배너 이미지 순서 번호
     * @param {*} type w or h
     * @param {*} non_nukki_action 누끼를 따지 않는 경우(화면 로드시 기존 이미지 조회때 사용)
     * @param {*} link_type 49001(파일 업로드) or 49003(상세 이미지)
     */
    searchOrgImg(robo_banner_org_img_seq, idx, type, non_nukki_action, link_type) {
        if(link_type != "49003"){
            $(".mirror_banner_v2_pc_banner_area").css({opacity : "0"});
            $(".mirror_banner_v2_mobile_banner_area").css({opacity : "0"});
        }

        http.get("/rest/shop/roboBanner/search/orgImg?robo_banner_org_img_seq="+robo_banner_org_img_seq+"&user_seq="+this.state.user_seq)
        .then(res => {
                    let upload_img = res.data["list"][0];

                    let user_img_list = this.state[type+"_user_img_list"];
                    user_img_list[idx].org_img_seq = upload_img.robo_banner_org_img_seq;
                    user_img_list[idx].org_img_name = upload_img.org_img_name;
                    user_img_list[idx].img_link = upload_img.org_img_path;
                    user_img_list[idx].video_link = "";
                    if(!non_nukki_action){
                        user_img_list[idx].link_type = link_type;
                    }

                    this.setState({
                        [type+"_user_img_list"] : user_img_list,
                    }, () => {
                        if(non_nukki_action){
                            this.setState({ save_dialog_show_state: false });
                            $(".mirror_banner_v2_pc_banner_area").css({opacity : "1"});
                            $(".mirror_banner_v2_mobile_banner_area").css({opacity : "1"});
                        } else{
                            this.eventChangeOneProductImageOfNukki(type, idx);
                        }
                    });
        })
        .catch(error => {
            alert(error+"\n관리자에게 문의해 주세요.");

            this.setState({ save_dialog_show_state: false });
            $(".mirror_banner_v2_pc_banner_area").css({opacity : "1"});
            $(".mirror_banner_v2_mobile_banner_area").css({opacity : "1"});
        });
    }

    onChangeImgPositionType(type, idx, value){
        isTmp = true;
        this.setState({
            [(type=="h" ? "h_" : "") + "img_position_"+idx+"_type"] : value,
        })
    }

    // 번역 선택
    handleLanguageChange = e => {
        if (e.target.value && this.state.auto_title) {
            this.setState({language_code : e.target.value});
            var index = e.target.selectedIndex;
            var optionElement = e.target.childNodes[index]
            var language =  optionElement.getAttribute('language');
            
            let data = {
                text : this.state.auto_title,
                target : language
            }
            http.post("/rest/common/translate/google", data)
            .then(res => {
                if (res.data.resCode == "200") {
                    const blocksFromHTML = htmlToDraft(res.data.translated_text);
                    const state = ContentState.createFromBlockArray(
                        blocksFromHTML.contentBlocks,
                        blocksFromHTML.entityMap,
                    );
                    isTmp = true;
                    this.setState({
                        auto_title: res.data.translated_text,
                        title: res.data.translated_text,
                        mainEditorState: EditorState.createWithContent(state),
                        language_code : "",
                    });
                } else {
                    alert("번역 서비스 점검중 입니다.\n관리자에게 문의해 주세요. (1599-3518)")
                }
            })
            .catch(error => {
                alert("번역 서비스 오류가 발생했습니다.\n관리자에게 문의해 주세요. (1599-3518)")
            });
        }
    }

    onClickProductSoldOut = (e) => {
        this.setState({
            product_sold_out : e.target.value
        }, () => {
            this.loadCafe24Item();
        })
    }

    onClickProductIsDetailImageUse = (e, state) => {
        this.nukkiBlockPageClick();

        let image_type_cd = "46001";
        if (e.target.checked) {
            image_type_cd = "46002";
        }

        let item_img = (e.target.checked && this.state.select_item_list[0].detail_image) ? this.state.select_item_list[0].detail_image : this.state.select_item_list[0].image ? this.state.select_item_list[0].image : "";
        this.setState({
            is_detail_image_use : e.target.checked,
            image_type_cd : image_type_cd,
            img_link : item_img,
            img_detail_link : item_img,
            img_third_link : item_img,
            img_fourth_link : item_img,
            h_img_link : item_img,
            h_img_detail_link : item_img,
            h_img_third_link : item_img,
            h_img_fourth_link : item_img,
        } , () => {
            this.eventChangeProductImageOfNukki();
        })
    }

    onClickBannerFullSize = (type) => {
        var event_seq = "";
        if (this.state.event_seq) {
            event_seq = this.state.event_seq;
        } else {
            event_seq = dateString
        }

        // 가로형에서 풀사이즈 클릭시
        if(type == "w") {
            if (window.innerWidth > 800) {
                if ((window.innerHeight * this.state.selected_pc_banner_ratio.org_width_height) > window.innerWidth) {
                    Menu.openBlankModal(
                        '<object class="robomd_banner_p" type="text/html" data="'+object_domain+'/admin_banner.html?event_seq='+event_seq+'&platform_type=p&mirror_type=18001" type="text/html" style="width:95vw;height:calc(95vw / '+this.state.selected_pc_banner_ratio.org_width_height+'); max-width: 95vw; max-height: 80vh; background: white;"></object>'
                    );
                } else {
                    Menu.openBlankModal(
                        '<object class="robomd_banner_p" type="text/html" data="'+object_domain+'/admin_banner.html?event_seq='+event_seq+'&platform_type=p&mirror_type=18001" type="text/html" style="width:calc(80vh * '+this.state.selected_pc_banner_ratio.org_width_height+');height:80vh; max-width: 95vw; max-height: 80vh; background: white;"></object>'
                    );
                }
            } else {
                Menu.openBlankModal(
                    '<object class="robomd_banner_p" type="text/html" data="'+object_domain+'/admin_banner.html?event_seq='+event_seq+'&platform_type=p&mirror_type=18001" type="text/html" style="width:80vw;height:calc(80vw / '+this.state.selected_pc_banner_ratio.org_width_height+'); max-width: 95vw; max-height: 70vh; background: white;"></object>'
                );
            }
        } else { // 세로형에서 풀사이즈 클릭시
            if (window.innerWidth > 800) {
                Menu.openBlankModal(
                    '<object class="robomd_banner_p" type="text/html" data="'+object_domain+'/admin_banner.html?event_seq='+event_seq+'&platform_type=m&mirror_type=18001" type="text/html" style="width:calc(80vh * '+this.state.selected_mobile_banner_ratio.org_width_height+');height:80vh; max-width: 95vw; max-height: 80vh; background: white;"></object>'
                );
            } else {
                if ((window.innerHeight * this.state.selected_mobile_banner_ratio.org_width_height) > window.innerWidth) {
                    Menu.openBlankModal(
                        '<object class="robomd_banner_p" type="text/html" data="'+object_domain+'/admin_banner.html?event_seq='+event_seq+'&platform_type=m&mirror_type=18001" type="text/html" style="width:75vw;height:calc(75vw / '+this.state.selected_mobile_banner_ratio.org_width_height+'); max-width: 75vw; max-height: 70vh; background: white;"></object>'
                    );
                } else {
                    Menu.openBlankModal(
                        '<object class="robomd_banner_p" type="text/html" data="'+object_domain+'/admin_banner.html?event_seq='+event_seq+'&platform_type=m&mirror_type=18001" type="text/html" style="width:calc(70vh * '+this.state.selected_mobile_banner_ratio.org_width_height+');height:80vh; max-width: 75vw; max-height: 70vh; background: white;"></object>'
                    );
                }
            }
        }

        var this_component = this;
        $(window).resize( function() {
            if ($("#common_blank_modal .robomd_banner_p")) {
                if(type == "w") {
                    if (window.innerWidth > 800) {
                        if ((window.innerHeight * this_component.state.selected_pc_banner_ratio.org_width_height) > window.innerWidth) {
                            $("#common_blank_modal .robomd_banner_p").css("width", "95vw");
                            $("#common_blank_modal .robomd_banner_p").css("height", 'calc(95vw / '+this_component.state.selected_pc_banner_ratio.org_width_height+')');
                        } else {
                            $("#common_blank_modal .robomd_banner_p").css("height", "80vh");
                            $("#common_blank_modal .robomd_banner_p").css("width", 'calc(80vh * '+this_component.state.selected_pc_banner_ratio.org_width_height+')');
                        }
                    } else {
                        $("#common_blank_modal .robomd_banner_p").css("width", '80vw');
                        $("#common_blank_modal .robomd_banner_p").css("height", 'calc(80vw / '+this_component.state.selected_pc_banner_ratio.org_width_height+')');
                    }
                } else {
                    if (window.innerWidth > 800) {
                        $("#common_blank_modal .robomd_banner_p").css("height", '80vh');
                        $("#common_blank_modal .robomd_banner_p").css("width", 'calc(80vh * '+this_component.state.selected_mobile_banner_ratio.org_width_height+')');
                    } else {
                        if ((window.innerHeight * this_component.state.selected_mobile_banner_ratio.org_width_height) > window.innerWidth) {
                            $("#common_blank_modal .robomd_banner_p").css("width", '75vw');
                            $("#common_blank_modal .robomd_banner_p").css("height", 'calc(75vw / '+this_component.state.selected_mobile_banner_ratio.org_width_height+')');
                        } else {
                            $("#common_blank_modal .robomd_banner_p").css("height", '70vh');
                            $("#common_blank_modal .robomd_banner_p").css("width", 'calc(70vh * '+this_component.state.selected_mobile_banner_ratio.org_width_height+')');
                        }
                    }
                }
            }
        })
    }

    /** 캡처되는 실제 사이즈 계산 */
    getCaptureSize(type, banner_ratio){
        let banner_width = 0;
        let banner_height = 0;
        
        banner_width = 1000 * banner_ratio.org_width_height;
        banner_height = 1000;

        if (banner_width > 10000 || banner_height > 10000) {
            if(type == "pc") {
                    banner_width = 10000
                    banner_height = 10000 / banner_ratio.org_width_height;
            } else {
                    banner_width = 10000 / banner_ratio.org_width_height;
                    banner_height = 10000
            }
        }
        banner_ratio["banner_width"] = banner_width;
        banner_ratio["banner_height"] = banner_height;

        return banner_ratio;
    }

    // 자동 배너 설정 조회
    async getAutoEventSetting(iframe_page_id, iframe_html_id) {
        try{
            const res = await http.get("/rest/shop/event/setting?iframe_page_id="+iframe_page_id+"&iframe_html_id="+iframe_html_id);

            if(res.data != null) {
                if (res.data.err_code == 401) {
                    alert(res.data.err_msg);
                    window.location.href = "/logout";
                }

                if(res.data.list[0] != null) {
                    this.setState({
                        auto_event_setting: res.data.list[0],
                        file_name_setting_yn: res.data.list[0].file_name_setting_yn,
                    });
                }
                return res.data;
            }
        }catch(error) {
            console.log(error);
        }
    }

    // 파일명 규칙 설정 조회
    async getFileNameSettingList(iframe_page_id, iframe_html_id) {
        try{
            const res = await http.get("/rest/shop/event/setting/file-name/setting?iframe_page_id="+iframe_page_id+"&iframe_html_id="+iframe_html_id);

            if(res.data != null) {
                if (res.data.code == 401) {
                    alert(res.data.msg);
                    window.location.href = "/logout";
                }

                if(res.data.file_name_setting_list != null && res.data.file_name_setting_list.length > 0) {
                    this.setState({
                        file_name_setting_list : res.data.file_name_setting_list,
                        w_file_name_setting_list : res.data.w_file_name_setting_list,
                        h_file_name_setting_list : res.data.h_file_name_setting_list,
                    });
                }else {
                    this.setState({
                        file_name_setting_list : [],
                        w_file_name_setting_list : [],
                        h_file_name_setting_list : [],
                    });

                    // 파일명 규칙 기본 설정 불러오기
                    await this.getFileNameDefaultSetting();
                }

                return res.data;
            }

        }catch(error) {
            console.log(error);
        }
    }

    // 파일명 규칙 기본 설정 조회
    async getFileNameDefaultSetting() {
        try{
            const res = await http.get("/rest/shop/event/setting/file-name/default");

            if(res.data != null) {
                if (res.data.code == 401) {
                    alert(res.data.msg);
                    window.location.href = "/logout";
                }

                if(res.data.file_name_default_setting_list != null && res.data.file_name_default_setting_list.length > 0) {
                    this.setState({
                        file_name_default_setting_list: res.data.file_name_default_setting_list,
                        w_file_name_default_setting_list: res.data.w_file_name_default_setting_list,
                        h_file_name_default_setting_list: res.data.h_file_name_default_setting_list,
                    });
                }
            }

        }catch(error) {
            console.log(error);
        }
    }

    getDateFormat = (date) => {
        let dateFormat = new Date(date);
        let year = dateFormat.getFullYear();
        let month = ('0' + (dateFormat.getMonth() + 1)).slice(-2);
        let day = ('0' + dateFormat.getDate()).slice(-2);
        let dateString = year + month + day;

        return dateString;
    }

    getDayOfWeek = (date) => {
        const week = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
        const dayOfWeek = week[new Date(date).getDay()];

        return dayOfWeek;
    }

    getAutoNumber = (start_num, increment_value) => {
        const padded_increment_value = increment_value.toString().padStart(String(start_num).length, '0');
        const incremented_num = parseInt(start_num) + parseInt(padded_increment_value);
        const auto_num = incremented_num.toString().padStart(String(start_num).length, '0');

        return auto_num;
    }

    // 파일명 규칙 자동 번호 저장
    async updateFileNameAutoNum(type, rules_number, rules_auto_num) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        let body = {
            iframe_page_id: this.state.iframe_page_id,
            iframe_html_id: this.state.iframe_html_id,
            banner_type: type,
            rules_number: rules_number,
            rules_auto_num: rules_auto_num
        }

        try{
            const res = await http.post("/rest/shop/event/setting/file-name/auto-num", body, config);

        }catch(error) {
            console.log(error);
        }
    }

    getFileName = (real_type, banner_type, rules_number, code_cd, second_code_cd, third_code_cd, rules_date, rules_name, rules_auto_num) => {
        let file_name = "";

        switch (code_cd) {
            case "61000":   // 페이지 위치
                file_name = this.state.auto_event_setting.iframe_page_id.split("_")[1];
                break;

            case "62000":   // 배너 영역명
                file_name = this.state.auto_event_setting.iframe_html_name;
                break;

            case "63000":   // 배너 사이즈
                if(second_code_cd == "63001") {  // 픽셀(가로x세로)
                    if(real_type == "pc") {
                        file_name = "픽셀(" + String(this.state.selected_pc_banner_ratio.org_banner_width) + "x" + String(this.state.selected_pc_banner_ratio.org_banner_height) + ")";
                    }else if(real_type == "mobile") {
                        file_name = "픽셀(" + String(this.state.selected_mobile_banner_ratio.org_banner_width) + "x" + String(this.state.selected_mobile_banner_ratio.org_banner_height) + ")";
                    }
                }else if(second_code_cd == "63002") {   // 비율(가로x세로)
                    if(real_type == "pc") {
                        file_name = "비율(" + String(Number(this.state.selected_pc_banner_ratio.org_width_height).toFixed(1)) + "x1)";
                    }else if(real_type == "mobile") {
                        file_name = "비율(" + String(Number(this.state.selected_mobile_banner_ratio.org_width_height).toFixed(1)) + "x1)";
                    }
                }else if(second_code_cd == "63003" || second_code_cd == "63004" || second_code_cd == "63005" || second_code_cd == "63006" || second_code_cd == "63007" || second_code_cd == "63008" || second_code_cd == "63009" || second_code_cd == "63010") {    // PC, MO, pc, m, mobile, 가로, 세로, 직접 입력
                    file_name = rules_name;
                }
                break;

            case "64000":   // 날짜
                if(second_code_cd == "64001") {     // 배너 등록일
                    if(third_code_cd == "65001") {          // YYYYMMDD
                        file_name = this.getDateFormat(this.state.reg_date);
                    }else if(third_code_cd == "65002") {    // YYMMDD
                        file_name = this.getDateFormat(this.state.reg_date).substring(2);
                    }else if(third_code_cd == "65003") {    // MMDD
                        file_name = this.getDateFormat(this.state.reg_date).substring(4);
                    }else if(third_code_cd == "65004") {    // 요일
                        file_name = this.getDayOfWeek(this.state.reg_date);
                    }

                }else if(second_code_cd == "64002") {   // 최종 수정일
                    if(third_code_cd == "65001") {          // YYYYMMDD
                        file_name = this.getDateFormat(this.state.mod_date);
                    }else if(third_code_cd == "65002") {    // YYMMDD
                        file_name = this.getDateFormat(this.state.mod_date).substring(2);
                    }else if(third_code_cd == "65003") {    // MMDD
                        file_name = this.getDateFormat(this.state.mod_date).substring(4);
                    }else if(third_code_cd == "65004") {    // 요일
                        file_name = this.getDayOfWeek(this.state.mod_date);
                    }

                }else if(second_code_cd == "64003") {   // 다운로드 날짜
                    if(third_code_cd == "65001") {          // YYYYMMDD
                        file_name = this.getDateFormat(new Date());
                    }else if(third_code_cd == "65002") {    // YYMMDD
                        file_name = this.getDateFormat(new Date()).substring(2);
                    }else if(third_code_cd == "65003") {    // MMDD
                        file_name = this.getDateFormat(new Date()).substring(4);
                    }else if(third_code_cd == "65004") {    // 요일
                        file_name = this.getDayOfWeek(new Date());
                    }

                }else if(second_code_cd == "64004") {   // 날짜 직접 선택
                    if(third_code_cd == "65001") {          // YYYYMMDD
                        file_name = this.getDateFormat(rules_date);
                    }else if(third_code_cd == "65002") {    // YYMMDD
                        file_name = this.getDateFormat(rules_date).substring(2);
                    }else if(third_code_cd == "65003") {    // MMDD
                        file_name = this.getDateFormat(rules_date).substring(4);
                    }else if(third_code_cd == "65004") {    // 요일
                        file_name = this.getDayOfWeek(rules_date);
                    }
                }
                break;

            case "66000":   // 자동 번호
                file_name = rules_auto_num;

                const updated_num = this.getAutoNumber(rules_auto_num, 1);
                this.updateFileNameAutoNum(banner_type, rules_number, updated_num).then(r => {});

                break;

            case "67000":   // 배너 이름
                if(this.state.none_title != null && this.state.none_title != ""){
                    file_name = this.state.none_title;
                }else if(this.state.org_title != null && this.state.org_title != ""){
                    file_name = this.state.org_title;
                }else if(this.state.auto_title != null && this.state.auto_title != ""){
                    file_name = this.state.auto_title;
                }else{
                    file_name = "제목없음";
                }
                break;

            case "69999":   // 직접 입력
                file_name = rules_name;
                break;

            default:
                break;

        }
        return file_name;
    }

    getBannerDownloadFileName = (file_type, real_type) => {
        let _file_name = "";

        if (this.state.event_seq != null && this.state.event_seq != "") {    // event_seq 가 존재하는 경우

            // 파일명 규칙 설정 여부
            if (this.state.file_name_setting_yn == "Y") {     // 파일명 규칙 설정이 "Y"인 경우 (파일명 규칙 설정이 "직접 설정"인 경우)

                if (this.state.file_name_setting_list != null && this.state.file_name_setting_list.length > 0) {    // 파일명 규칙 설정 목록이 존재하는 경우

                    // 저장된 파일명 규칙 설정에 맞게 다운로드. ex) 규칙1_규칙2_규칙3_규칙4.jpg
                    this.state[file_type + "_file_name_setting_list"].map((obj, idx) => {
                        _file_name += String(this.getFileName(real_type, obj.banner_type, obj.rules_number, obj.code_cd, obj.second_code_cd, obj.third_code_cd, obj.rules_date, obj.rules_name, obj.rules_auto_num));

                        if (idx + 1 < this.state[file_type + "_file_name_setting_list"].length) {
                            _file_name += "_";
                        }
                    });

                } else {    // 파일명 규칙 설정 목록이 존재하지 않는 경우

                    // 파일명 규칙 기본 설정으로 다운로드. ex) 규칙1_규칙2_규칙3_규칙4.jpg
                    if(this.state[file_type + "_file_name_default_setting_list"] != null && this.state[file_type + "_file_name_default_setting_list"].length>0){
                        this.state[file_type + "_file_name_default_setting_list"].map((obj, idx) => {
                            _file_name += String(this.getFileName(real_type, obj.banner_type, obj.rules_number, obj.code_cd, obj.second_code_cd, obj.third_code_cd, obj.rules_date, obj.rules_name, obj.rules_auto_num));

                            if(idx+1 < this.state[file_type + "_file_name_default_setting_list"].length) {
                                _file_name += "_";
                            }
                        });
                    }
                }

            }else if(this.state.file_name_setting_yn == "D") {     // 파일명 규칙 설정이 "D"인 경우

                // 로보MD_배너종류_배너번호로 다운로드
                if(this.state.reg_user_seq == -1) {
                    _file_name = "로보MD_자동배너_" + this.state.event_seq;

                }else if(this.state.mod_user_seq == -2 || this.getUrlParams()["sample_yn"] == "Y") {
                    _file_name = "로보MD_샘플배너_" + this.state.event_seq;

                }else {
                    _file_name = "로보MD_로보배너_" + this.state.event_seq;
                }

            }else if(this.state.file_name_setting_yn == "N") {      // 파일명 규칙 설정이 "N"인 경우 (파일명 규칙 설정이 "기본 설정"인 경우)
                if(this.state.none_title != null && this.state.none_title != ""){
                    _file_name = this.state.none_title;
                }else if(this.state.org_title != null && this.state.org_title != ""){
                    _file_name = this.state.org_title;
                }else if(this.state.auto_title != null && this.state.auto_title != ""){
                    _file_name = this.state.auto_title;
                }else{
                    _file_name = "제목없음";
                }
            }
        }

        return _file_name;
    }

    // 비연동형 배너 사이즈 옆 미리보기 옆 다운로드 버튼 클릭
    downloadBannerRightAwayOnClick = (size_type) => {
        if(this.state.event_seq == null || this.state.event_seq == "") {
            this.setState({
                warning_dialog_show_state : true,
                warning_dialog_content : "배너를 저장한 이후에<br/> 다운로드 할 수 있어요.",
                warning_dialog_button_list: [
                    {
                        title: "확인",
                        type: "1",
                        event_method: () => {
                            this.setState({ warning_dialog_show_state: false });
                        }
                    }
                ],
            });

        }else{
            const {pc_banner_ratio_list, mobile_banner_ratio_list} = this.state;
            let selected_banner_ratio = {};

            this.setState({
                pc_banner_ratio_list: pc_banner_ratio_list,
                mobile_banner_ratio_list: mobile_banner_ratio_list,

            }, () => {

                if(size_type == "pc"){
                    if(!this.state.event_banner_pc_width || !this.state.event_banner_pc_height){
                        alert("배너 사이즈를 입력해 주세요.");
                        return false;
                    }

                    if(pc_banner_ratio_list.length > 1){
                        selected_banner_ratio = pc_banner_ratio_list[0];
                        this.setState({selected_pc_banner_ratio: pc_banner_ratio_list[0]});

                    }else if(pc_banner_ratio_list.length > 0){
                        if(mobile_banner_ratio_list[0] && mobile_banner_ratio_list[0].org_event_type == "p"){
                            selected_banner_ratio = mobile_banner_ratio_list[0];
                            this.setState({selected_mobile_banner_ratio: mobile_banner_ratio_list[0]});

                        }else{
                            selected_banner_ratio = pc_banner_ratio_list[0];
                            this.setState({selected_pc_banner_ratio: pc_banner_ratio_list[0]});

                        }
                    }else{
                        if(mobile_banner_ratio_list.length > 0){
                            selected_banner_ratio = mobile_banner_ratio_list[0];
                            this.setState({selected_mobile_banner_ratio: mobile_banner_ratio_list[0]});
                        }
                    }

                }else if(size_type == "mobile"){
                    if(!this.state.event_banner_mobile_width || !this.state.event_banner_mobile_height){
                        alert("배너 사이즈를 입력해 주세요.");
                        return false;
                    }
                    
                    if(mobile_banner_ratio_list.length > 1){
                        selected_banner_ratio = mobile_banner_ratio_list[1];
                        this.setState({selected_mobile_banner_ratio: mobile_banner_ratio_list[1]});

                    }else if(mobile_banner_ratio_list.length > 0){
                        if(pc_banner_ratio_list[0] && pc_banner_ratio_list[0].org_event_type == "m"){
                            selected_banner_ratio = pc_banner_ratio_list[0];
                            this.setState({selected_pc_banner_ratio: pc_banner_ratio_list[0]});

                        }else{
                            selected_banner_ratio = mobile_banner_ratio_list[0];
                            this.setState({selected_mobile_banner_ratio: mobile_banner_ratio_list[0]});
                        }

                    }else{
                        if(pc_banner_ratio_list.length > 1){
                            selected_banner_ratio = pc_banner_ratio_list[1];
                            this.setState({selected_pc_banner_ratio: pc_banner_ratio_list[1]});

                        }else if(pc_banner_ratio_list.length > 0){
                            selected_banner_ratio = pc_banner_ratio_list[0];
                            this.setState({selected_pc_banner_ratio: pc_banner_ratio_list[0]});
                        }
                    }
                }

                this.setState({
                    selected_current_banner_ratio: selected_banner_ratio,
                }, () => {
                    // 배너 다운로드
                    this.downloadBannerRightAwayHandler(size_type);
                });

            });
        }
    }

    // 배너 다운로드
    downloadBannerRightAwayHandler = (size_type) => {
        // 자동 배너 설정 불러오기
        this.getAutoEventSetting(this.state.iframe_page_id, this.state.iframe_html_id).then(data => {
            if(data.list[0] != null) {
                this.setState({
                    auto_event_setting: data.list[0],
                    file_name_setting_yn: data.list[0].file_name_setting_yn,
                }, () => {

                    // 파일명 규칙 설정 불러오기
                    this.getFileNameSettingList(this.state.iframe_page_id, this.state.iframe_html_id).then(data => {
                        this.setState({
                            file_name_setting_list : data.file_name_setting_list,
                            w_file_name_setting_list : data.w_file_name_setting_list,
                            h_file_name_setting_list :data.h_file_name_setting_list,
                        }, () => {

                            // 배너 다운로드
                            this.downloadBannerRightAway(size_type);
                        });
                    });
                });
            }else{
                // alert('자동 배너 설정을 불러올 수 없습니다.');
            }
        });
    }

    // 배너 다운로드
    downloadBannerRightAway = (size_type) => {
        let event_seq = this.state.event_seq;
        let file_name = this.state.org_title;
        let file_type = "";
        let real_type = "";
        let platform_type = "";
        let banner_width = 0;
        let banner_height = 0;
        let org_banner_width = 0;
        let org_banner_height = 0;
        let mirror_type = "18001";      // 18001(미리보기), 18002

        const {selected_current_banner_ratio} = this.state;

        org_banner_width = selected_current_banner_ratio.org_banner_width;
        org_banner_height = selected_current_banner_ratio.org_banner_height;
        banner_width = selected_current_banner_ratio.banner_width;
        banner_height = selected_current_banner_ratio.banner_height;

        if(size_type == "pc"){
            file_type = "w";
        }else if(size_type == "mobile"){
            file_type = "h";
        }

        if(banner_width > banner_height){
            real_type = "pc";
            platform_type = "p";
        }else{
            real_type = "mobile";
            platform_type = "m";
        }

        file_name = this.getBannerDownloadFileName(file_type, real_type);

        // 파일명 규칙 설정이 "기본 설정"일 경우 파일명 카운트 저장하기
        if(this.state.file_name_setting_yn == "N"){
            if(file_name != null && file_name != ""){
                this.saveFileNameCnt(file_type, file_name).then(res => {
                    file_name = res;
                });
            }
        }

        if (file_name == null || file_name == "") {
            file_name = this.state.org_title;
        }

        if (file_name == null || file_name == "") {
            file_name = this.state.auto_title;
        }

        if (file_name == null || file_name == "") {
            file_name = this.state.none_title;
        }

        if (file_name == null || file_name == "") {
            file_name = "제목없음";
        }

        let req_list = [];
        let req_map = {
            event_seq : event_seq
            , platform_type : platform_type
            , banner_width : banner_width
            , banner_height : banner_height
            , org_banner_width : org_banner_width
            , org_banner_height : org_banner_height
            , mirror_type : mirror_type
            , file_name: this.getBannerDownloadFileName(file_type, real_type)
        }
        req_list.push(req_map);
        //this.contentDownload(file_name, download_type, req_list);

        console.log(req_list)

        // 다운로드 준비상태 : 진행중
        this.setState({["is_download_" + size_type]:false}, () => {
            new Promise( ( resolve, reject ) => {setTimeout( resolve, 500 ); })
                .then( () => {
                    CommonDownloadManager.fn_contentDownload(file_name, req_list);
                    // 다운로드 준비상태 : 완료
                    this.setState({["is_download_" + size_type]:true});
                });
        });
    }

    // 미리보기 바깥의 하단 배너 다운로드 버튼 클릭
    handleDownloadBannerBtnClick = (e) => {
        if(!this.state.event_banner_pc_width || !this.state.event_banner_pc_height || !this.state.event_banner_mobile_width || !this.state.event_banner_mobile_height){
            alert("배너 사이즈를 입력해 주세요.");
            return false;
        }
        this.handleDownloadMultiBanner();
    }

    // 미리보기 바깥의 하단 배너 다운로드
    handleDownloadMultiBanner = () => {
        // 자동 배너 설정 불러오기
        this.getAutoEventSetting(this.state.iframe_page_id, this.state.iframe_html_id).then(data => {
            if(data.list[0] != null) {
                this.setState({
                    auto_event_setting: data.list[0],
                    file_name_setting_yn: data.list[0].file_name_setting_yn,
                }, () => {

                    // 파일명 규칙 설정 불러오기
                    this.getFileNameSettingList(this.state.iframe_page_id, this.state.iframe_html_id).then(data => {
                        this.setState({
                            file_name_setting_list : data.file_name_setting_list,
                            w_file_name_setting_list : data.w_file_name_setting_list,
                            h_file_name_setting_list :data.h_file_name_setting_list,
                        }, () => {

                            // 배너 다운로드
                            this.downloadMultiBannerHandler().then(r => {});
                        });
                    });
                });
            }else{
                // alert('자동 배너 설정을 불러올 수 없습니다.');
            }
        });
    }

    // 미리보기 바깥의 하단 배너 다운로드
    async downloadMultiBannerHandler() {
        const {pc_banner_ratio_list, mobile_banner_ratio_list} = this.state;

        if(pc_banner_ratio_list.length > 0 && pc_banner_ratio_list[0]){
            await new Promise((resolve) => {
                this.setState({selected_pc_banner_ratio: pc_banner_ratio_list[0]}, () => {
                    resolve();
                    this.downloadMultiBanner(pc_banner_ratio_list[0], "w");
                });
            });
        }

        if(pc_banner_ratio_list.length > 0 && pc_banner_ratio_list[1]){
            await new Promise((resolve) => {
                this.setState({selected_pc_banner_ratio: pc_banner_ratio_list[1]}, () => {
                    resolve();
                    this.downloadMultiBanner(pc_banner_ratio_list[1], "w");
                });
            });
        }

        if(mobile_banner_ratio_list.length > 0 && mobile_banner_ratio_list[0]){
            await new Promise((resolve) => {
                this.setState({selected_mobile_banner_ratio: mobile_banner_ratio_list[0]}, () => {
                    resolve();
                    this.downloadMultiBanner(mobile_banner_ratio_list[0], "h");
                });
            });
        }

        if(mobile_banner_ratio_list.length > 0 && mobile_banner_ratio_list[1]){
            await new Promise((resolve) => {
                this.setState({selected_mobile_banner_ratio: mobile_banner_ratio_list[1]}, () => {
                    resolve();
                    this.downloadMultiBanner(mobile_banner_ratio_list[1], "h");
                });
            });
        }
    }

    // 미리보기 바깥의 하단 배너 다운로드
    downloadMultiBanner = (selected_current_banner_ratio, type) => {
        let event_seq = this.state.event_seq;
        let file_name = this.state.org_title;
        let file_type = "";
        let real_type = "";
        let platform_type = "";
        let banner_width = 0;
        let banner_height = 0;
        let org_banner_width = 0;
        let org_banner_height = 0;
        let org_event_type = "";
        let mirror_type = "18001";      // 18001(미리보기), 18002


        org_event_type = selected_current_banner_ratio.org_event_type;
        org_banner_width = selected_current_banner_ratio.org_banner_width;
        org_banner_height = selected_current_banner_ratio.org_banner_height;
        banner_width = selected_current_banner_ratio.banner_width;
        banner_height = selected_current_banner_ratio.banner_height;

        if(type == "w"){
            real_type = "pc";
            platform_type = "p";

        }else{
            real_type = "mobile";
            platform_type = "m";
        }

        if(org_event_type == "p"){
            file_type = "w";
        }else if(org_event_type == "m"){
            file_type = "h";
        }

        file_name = this.getBannerDownloadFileName(file_type, real_type);


        // 파일명 규칙 설정이 "기본 설정"일 경우 파일명 카운트 저장하기
        if(this.state.file_name_setting_yn == "N"){
            if(file_name != null && file_name != ""){
                this.saveFileNameCnt(file_type, file_name).then(res => {
                    file_name = res;
                });
            }
        }

        if (file_name == null || file_name == "") {
            file_name = this.state.org_title;
        }

        if (file_name == null || file_name == "") {
            file_name = this.state.auto_title;
        }

        if (file_name == null || file_name == "") {
            file_name = this.state.none_title;
        }

        if (file_name == null || file_name == "") {
            file_name = "제목없음";
        }

        let req_list = [];
        let req_map = {
            event_seq : event_seq
            , platform_type : platform_type
            , banner_width : banner_width
            , banner_height : banner_height
            , org_banner_width : org_banner_width
            , org_banner_height : org_banner_height
            , mirror_type : mirror_type
            , file_name: this.getBannerDownloadFileName(file_type, real_type)
        }
        req_list.push(req_map);
        //this.contentDownload(file_name, download_type, req_list);

        console.log(req_list)

        // 다운로드 준비상태 : 진행중
        this.setState({is_download:false}, () => {
            new Promise( ( resolve, reject ) => {setTimeout( resolve, 500 ); })
                .then( () => {
                    CommonDownloadManager.fn_contentDownload(file_name, req_list);
                    // 다운로드 준비상태 : 완료
                    this.setState({is_download:true});
                });
        });
    }

    // 미리보기 안의 개별 다운로드 버튼 클릭
    downloadOneBannerBtnClick = (download_type) => {
        // 자동 배너 설정 불러오기
        this.getAutoEventSetting(this.state.iframe_page_id, this.state.iframe_html_id).then(data => {
            if(data.list[0] != null) {
                this.setState({
                    auto_event_setting: data.list[0],
                    file_name_setting_yn: data.list[0].file_name_setting_yn,
                }, () => {

                    // 파일명 규칙 설정 불러오기
                    this.getFileNameSettingList(this.state.iframe_page_id, this.state.iframe_html_id).then(data => {
                        this.setState({
                            file_name_setting_list: data.file_name_setting_list,
                            w_file_name_setting_list: data.w_file_name_setting_list,
                            h_file_name_setting_list: data.h_file_name_setting_list,

                        }, () => {
                            // 배너 다운로드
                            this.onClickBannerDownload(download_type);
                        });
                    });
                });
            }else{
                // alert('자동 배너 설정을 불러올 수 없습니다.');
            }
        });
    }

    // 배너 다운로드
    onClickBannerDownload = (download_type) => {
        let event_seq = "";
        let file_name = this.state.org_title;
        let file_type = "";
        let real_type = "";
        let platform_type = "";
        let banner_width = 0;
        let banner_height = 0;
        let org_banner_width = 0;
        let org_banner_height = 0;
        let mirror_type = "18001";      // 18001(미리보기), 18002

        if(this.state.event_seq == null || this.state.event_seq == "") {
            // event_seq = dateString;

            this.setState({
                warning_dialog_show_state : true,
                warning_dialog_content : "배너를 저장한 이후에<br/> 다운로드 할 수 있어요.",
                warning_dialog_button_list: [
                    {
                        title: "확인",
                        type: "1",
                        event_method: () => {
                            this.setState({ warning_dialog_show_state: false });
                        }
                    }
                ],
            });

        }else{
            event_seq = this.state.event_seq;

            if(download_type == "is_download_w") {
                const {selected_pc_banner_ratio} = this.state;
                real_type = "pc";
                platform_type = "p";
                org_banner_width = selected_pc_banner_ratio.org_banner_width;
                org_banner_height = selected_pc_banner_ratio.org_banner_height;
                banner_width = selected_pc_banner_ratio.banner_width;
                banner_height = selected_pc_banner_ratio.banner_height;

                if(this.state.selected_pc_banner_ratio.org_event_type == "p"){
                    file_type = "w";
                }else if(this.state.selected_pc_banner_ratio.org_event_type == "m"){
                    file_type = "h";
                }

            } else {
                const {selected_mobile_banner_ratio} = this.state;
                real_type = "mobile";
                platform_type = "m";
                org_banner_width = selected_mobile_banner_ratio.org_banner_width;
                org_banner_height = selected_mobile_banner_ratio.org_banner_height;
                banner_width = selected_mobile_banner_ratio.banner_width;
                banner_height = selected_mobile_banner_ratio.banner_height;

                if(this.state.selected_mobile_banner_ratio.org_event_type == "p"){
                    file_type = "w";
                }else if(this.state.selected_mobile_banner_ratio.org_event_type == "m"){
                    file_type = "h";
                }
            }

            file_name = this.getBannerDownloadFileName(file_type, real_type);

            // 파일명 규칙 설정이 "기본 설정"일 경우 파일명 카운트 저장하기
            if(this.state.file_name_setting_yn == "N"){
                if(file_name != null && file_name != ""){
                    this.saveFileNameCnt(file_type, file_name).then(res => {
                        file_name = res;
                    });
                }
            }

            if (file_name == null || file_name == "") {
                file_name = this.state.org_title;
            }

            if (file_name == null || file_name == "") {
                file_name = this.state.auto_title;
            }

            if (file_name == null || file_name == "") {
                file_name = this.state.none_title;
            }

            if (file_name == null || file_name == "") {
                file_name = "제목없음";
            }

            let req_list = [];
            let req_map = {
                event_seq : event_seq
                , platform_type : platform_type
                , banner_width : banner_width
                , banner_height : banner_height
                , org_banner_width : org_banner_width
                , org_banner_height : org_banner_height
                , mirror_type : mirror_type
                , file_name: this.getBannerDownloadFileName(file_type, real_type)
            }
            req_list.push(req_map);
            //this.contentDownload(file_name, download_type, req_list);

            console.log(req_list)

            // 다운로드 준비상태 : 진행중
            this.setState({[download_type]:false}, () => {
                new Promise( ( resolve, reject ) => {setTimeout( resolve, 500 ); })
                    .then( () => {
                        CommonDownloadManager.fn_contentDownload(file_name, req_list);
                        // 다운로드 준비상태 : 완료
                        this.setState({[download_type]:true});
                    });
            });
        }
    }

    // 파일명 카운트 저장하기
    async saveFileNameCnt(type, file_name) {
        let _file_name = "";
        let _file_name_num = "";

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        let body = {
            iframe_page_id: this.state.iframe_page_id,
            iframe_html_id: this.state.iframe_html_id,
            banner_type: type,
            file_name: file_name
        }

        try{
            const res = await http.post("/rest/shop/event/setting/file-name/cnt", body, config);

            if(res.data != null) {
                if(res.data.code == "200"){
                    if(res.data.file_name_cnt > 0){
                        _file_name_num = res.data.file_name_cnt;
                        _file_name = file_name + "_" + this.padWithZero(Number(_file_name_num));
                    }else {
                        _file_name = file_name;
                    }

                    return _file_name;
                }
            }
        }catch(error) {
            console.log(error);
        }
    }

    padWithZero(num) {
        if (num < 10) {
            return "0" + num;
        } else {
            return num.toString();
        }
    }

    // 이미지 다운로드
    contentDownload(file_name, download_type, req_list) {
        // 다운로드 준비상태 : 진행중
        this.setState({[download_type]:false});
        const config = {
            headers: {
                'content-type': 'application/json;'
            },
        }
        http.post("/rest/shop/cafe24/event/capture_v2", { req_list : req_list }, config )
        .then(res => {
            if (res.data.code == "200") {
                // 성공시 s3 파일 다운로드 요청
                // this.fn_s3ImageFileDownload(res.data.capture_code, file_name);
                // 캡처요청 상태 조회
                let limit_count = 0;
                this.fn_captureStateChecker(res.data.req_seq, res.data.multi_image_yn, file_name, download_type, limit_count);
            } else {
                alert(res.data.code + "\n" + res.data.msg);
            }
        })
        .catch(error => {
            //console.log (error);
            alert("배너 다운로드 요청 실패\n고객센터 : 1599-3518");
            // 다운로드 준비상태 : 완료
            this.setState({[download_type]:true});
        });
    }

    // 캡처요청 상태 조회
    fn_captureStateChecker(req_seq, multi_image_yn, file_name, download_type, limit_count) {
        limit_count = limit_count + 1
        const config = {
            headers: {
                'content-type': 'application/json;'
            },
        }
		http.post("/rest/shop/cafe24/content/stateChecker", { req_seq : req_seq, multi_image_yn : multi_image_yn }, config )
        .then(res => {
            if (res.data.is_done == "D") {
                //console.log("[0] " + limit_count +  "/ " + res.data.content_code);
                this.fn_s3FileDownload(res.data.content_code, file_name, download_type);
            } else {
                if (limit_count < 100) {
                    new Promise( ( resolve, reject ) => {setTimeout( resolve, 1000 ); })
                    .then( () => {
                        //console.log("[1] : " + limit_count + " : 호출 재시도 ");
                        // 캡처요청 상태 조회 재시도
                        this.fn_captureStateChecker(req_seq, multi_image_yn, file_name, download_type, limit_count);
                    });
                } else {
                    alert("이미지 캡처 상태조회 실패\n고객센터 : 1599-3518");
                    // 다운로드 준비상태 : 완료
                    this.setState({[download_type]:true});
                }
            }
        })
        .catch(error => {
            alert("이미지 캡처 상태조회 오류\n고객센터 : 1599-3518");
            // 다운로드 준비상태 : 완료
            this.setState({[download_type]:true});
            console.log("#### fn_captureStateChecker [ERROR] start ####");
            console.log(error);
            console.log("#### fn_captureStateChecker [ERROR] end ####");
        });
    }

    // s3 파일 다운로드
    fn_s3FileDownload(content_code, file_name, download_type) {
        // 파일 확장자 추출
        let file_type = content_code.substring(content_code.lastIndexOf(".")+1, content_code.length);
        const config = {
            headers: {
                'content-type': 'application/json;'
            },
            responseType: 'arraybuffer'
        }
        http.post("/rest/shop/cafe24/content/download",
            {
                "content_code" : content_code
            },
            config
        )
        .then(res => {
            if (res.data != null) {
                if(file_name){
                    // 파일명으로 사용 불가능한 특수문자 제거
                    file_name = file_name.replace(/[`~!@#$%^&*|\\\'\";:\/?]/gi, "");
                } else{
                    // 상품명(제목) 미입력시 '제목없음'로 다운로드
                    file_name = '제목없음';
                }
                file_name = file_name + '.'+file_type;
                var blob = new Blob([res.data], { type: file_type });
                FileDownload(blob, decodeURI(file_name));
            } else {
                alert("s3 이미지 다운로드 요청 실패\n고객센터 : 1599-3518");
            }
            // 다운로드 준비상태 : 완료
            this.setState({[download_type]:true});
        })
        .catch(error => {
            alert("s3 이미지 다운로드 요청 실패\n고객센터 : 1599-3518");
            // 다운로드 준비상태 : 완료
            this.setState({[download_type]:true});
        });
    }

    // s3 이미지 다운로드
    fn_s3ImageFileDownload(capture_code, file_name) {
        // 파일 확장자 추출
        let file_type = capture_code.substring(capture_code.lastIndexOf(".")+1, capture_code.length);
        const config = {
            headers: {
                'content-type': 'application/json;'
            },
            responseType: 'arraybuffer'
        }
        http.post("/rest/shop/cafe24/content/download",
            {
                "capture_code" : capture_code,
                "file_type" : file_type
            },
            config
        )
        .then(res => {
            if (res.data != null) {
                console.log(file_name);
                if(file_name){
                    // 파일명으로 사용 불가능한 특수문자 제거
                    file_name = file_name.replace(/[`~!@#$%^&*|\\\'\";:\/?]/gi, "");
                } else{
                    // 상품명(제목) 미입력시 '제목없음'로 다운로드
                    file_name = '제목없음';
                }
                file_name = file_name + '.'+file_type;
                var blob = new Blob([res.data], { type: 'image/'+file_type });
                FileDownload(blob, decodeURI(file_name));
            } else {
                alert("s3 이미지 다운로드 요청 실패\n고객센터 : 1599-3518");
            }
        })
        .catch(error => {
            alert("s3 이미지 다운로드 요청 실패\n고객센터 : 1599-3518");
        });
    }

    // 달력으로 확인하기 클릭
    onClickShowCalendar(){
        if($("#calendar_div").css('display') === 'none'){
            // resize를 통해 열기 전 화면 크기가 바뀌었더라도, 다시 달력 비율을 조정한다
            window.dispatchEvent(new Event('resize'));
            $("#calendar_div").css({display:'', height:'auto'});
        } else{
            $("#calendar_div").css({display:'none'});
        }
    }

    /* 배너 템플릿 이미지 컬럼명의 _link값을 제외한 정보 추가 */
    addBannerDesignPositionText(obj){
        for(var i=0;i<obj.length;i++){
            obj[i]["position_name"] = obj[i].img_column_name.replace("_link","");
            obj[i]["youtube_position_name"] = "youtube"+obj[i].img_column_name.replace("_link","").replace("img","");
        }
        return obj;
    }

    /** 링크 AI 배경색 추출 */
    onSelectImgLinkColor(type, idx){
        $(".mirror_banner_v2_pc_banner_area").css({opacity : "0"});
        $(".mirror_banner_v2_mobile_banner_area").css({opacity : "0"});

        this.setState({
            save_dialog_show_state: true,
            save_dialog_content: "AI 배경색 추출중 입니다.",
            save_dialog_button_list: [
                {
                    title: "확인",
                    type: "1",
                    display_yn:"N",
                }
            ]
        }, () => {
            $("#blockPageClick").hide();
        });

        let link = this.state[(type == "h" ? "h_" : "") + this.state[type+"_img_position_name"][idx].img_column_name];

        // 업로드 이미지가 있는 경우
        if(!(this.state[type+"_is_youtube_link_use"] == "N" && this.state[type+"_user_img_list"][idx].link_type == "49005") && this.state[type+"_user_img_list"][idx].img_link){
            link = this.state[type+"_user_img_list"][idx].img_link;
        }

        let event_type_cd_param_name = type == "h" ? "event_mobile_type_cd" : "event_type_cd";
        let event_type_cd = type == "h" ? this.state.event_mobile_type_cd : this.state.event_type_cd;

        http.get("/rest/shop/linkImg/ColorDetector?img_link="+link+"&"+event_type_cd_param_name+"="+event_type_cd)
        .then(res => {
            let event_color = res.data["color"]["event"];
            
            this.setState({
                background : event_color.color_code_1,
                background2 : event_color.color_code_2,
                background3 : event_color.color_code_3,
                background4 : event_color.color_code_4,
                color_value_1 : event_color.color_value_1,
                color_value_2 : event_color.color_value_2,
                color_value_3 : event_color.color_value_3,
                color_value_4 : event_color.color_value_4,
                color_lmnst_1 : event_color.color_lmnst_1,
                color_lmnst_2 : event_color.color_lmnst_2,
                color_lmnst_3 : event_color.color_lmnst_3,
                color_lmnst_4 : event_color.color_lmnst_4,
                color_strtn_1 : event_color.color_strtn_1,
                color_strtn_2 : event_color.color_strtn_2,
                color_strtn_3 : event_color.color_strtn_3,
                color_strtn_4 : event_color.color_strtn_4,
                color_hue_1 : event_color.color_hue_1,
                color_hue_2 : event_color.color_hue_2,
                color_hue_3 : event_color.color_hue_3,
                color_hue_4 : event_color.color_hue_4,
            }, () => {
                this.insertEventTmp('preview');

                this.setState({ save_dialog_show_state: false });
                $(".mirror_banner_v2_pc_banner_area").css({opacity : "1"});
                $(".mirror_banner_v2_mobile_banner_area").css({opacity : "1"});
                $("#blockPageClick").hide();
            });
        })
        .catch(error => {
            alert("AI 배경색 추출에 실패하였습니다.");

            this.setState({ save_dialog_show_state: false });
            $(".mirror_banner_v2_pc_banner_area").css({opacity : "1"});
            $(".mirror_banner_v2_mobile_banner_area").css({opacity : "1"});
            $("#blockPageClick").hide();
        });
    }

    /**
     * 이미지 링크가 사용 가능한 링크인지 확인 : onload, onerror 작업이 끝난 후 action 함수 실행
     * @param {*} link 이미지 링크
     * @function loadAction 이미지 링크가 로드되었을때 실행할 함수
     * @function errorAction 이미지 링크가 에러떴을때 실행할 함수
     */
    checkImgLinkUseAble = (link, loadAction, errorAction) => {
        var img = new Image();
        img.src = link;
        if(loadAction){
            img.onload = () => { // 링크 상태 유효함
                loadAction();
            };
        }
        if(errorAction){
            img.onerror = () => {
                errorAction();
            };
        }
    }

    /* 이미지 링크 입력 팝업 열기 */
    openImgLinkUrlPopup(type, idx, link_type){
        this.setState({
            input_dialog_show_state: true,
            input_dialog_title: "배너에 사용될 이미지 주소를 입력해주세요.",
            input_dialog_content: "",
            input_dialog_input_value: this.state[`${type}_user_img_list`][idx].link_type == "49002" && this.state[`${type}_user_img_list`][idx].img_link,
            input_dialog_input_placeholder : "",
            input_dialog_button_list: [
                {
                    title: "적용",
                    type: "1",
                    event_method: (e) => {
                        $("#blockPageClick").show();
                        this.setState({input_dialog_show_state: false});

                        const link = e.target.value.replaceAll("&quot;","").replaceAll("&quot","");

                        this.checkImgLinkUseAble(link,
                            () => {
                                this.onChangeImgLinkUrl(type, idx, link, link_type);
                            },
                            () => {
                                alert("허용하지 않는 이미지 주소입니다.");
                                $("#blockPageClick").hide();
                            }
                        );
                    }
                },
                {
                    title: "취소",
                    type: "0",
                    event_method: () => {
                        this.setState({input_dialog_show_state: false});
                    }
                }
            ]
        });
    }

    /* 링크 입력형 이미지 주소 입력 */
    onChangeImgLinkUrl(type, idx, link, link_type){
        let user_img_list = this.state[type+"_user_img_list"];
        // 이전과 같은 이미지 링크를 입력한 경우
        if(link == user_img_list[idx].img_link){
            // 이미지 업로드 팝업 닫기
            $("#blockPageClick").hide();
            return false;
        }
        // 입력된 이미지 주소 적용
        user_img_list[idx].link_type = link_type;
        user_img_list[idx].img_link = link;
        user_img_list[idx].org_img_name = link;
        user_img_list[idx].org_img_seq = "";
        user_img_list[idx].video_link = "";

        this.setState({
            [type+"_user_img_list"] : user_img_list,
        }, () => {
            this.eventChangeOneProductImageOfNukki(type, idx);
        });
    }

    /* 이미지 편집 : 이미지 삭제 클릭 */
    deleteUploadImg(type, idx){
        let type_user_img_list = this.state[type+"_user_img_list"];
        type_user_img_list[idx].link_type = "49001"
        type_user_img_list[idx].img_link = "";
        type_user_img_list[idx].org_img_seq = "";
        type_user_img_list[idx].org_img_name = "";
        type_user_img_list[idx].video_link = "";

        let crop_img_info_list = this.state[type+"_crop_img_info_list"];
        crop_img_info_list[idx] = {};

        let item_img = "";
        if(this.state.select_item_list.length > 0){
            item_img = (this.state.is_detail_image_use == true && this.state.select_item_list[0].detail_image) ? this.state.select_item_list[0].detail_image : this.state.select_item_list[0].image ? this.state.select_item_list[0].image : "";
        }
        this.setState({
            [type+"_user_img_list"] : type_user_img_list,
            [type+"_crop_img_info_list"] : crop_img_info_list,
            [(type == "h" ? "h_" : "") + this.state[type+"_img_position_name"][idx].img_column_name] : item_img,
        }, () => {
            this.eventChangeOneProductImageOfNukki(type, idx);
        });
    }

    // AI 원본 배경 제거 버튼 클릭
    onClickRemoveBackground(e, type, img_position_idx){
        /** 누끼따주는 API를 실행하면 누끼 따진 이미지가 프론트로 return 될것
         * 해당 이미지를 누끼 상태가 Y인 경우에만 저장으로 넘겨야한다.
        */
        let idx = Number(img_position_idx) - 1;
        let action = e.target.checked ? "Y" : "N";

        // 이미지가 존재하는 경우와, 누끼 해제하는데 링크 입력형 이미지가 유효하지 않은 경우는(이미지가 비어있는 상태로 보이도록) 통과
        if(this.positionImgCheck(type, idx)
        || (action == "N" && (this.state[type+"_user_img_list"][idx].link_type == "49002" || this.state[type+"_user_img_list"][idx].link_type == "49004") && this.state[type+"_user_img_list"][idx].img_link && !this.state[type+"_user_img_list"][idx].img_link_able)){
            let non_nukki_state = this.state[type+"_non_nukki_state"];
            non_nukki_state[idx] = (action == "N") ? true : false;
            this.setState({
                [type+"_non_nukki_state"] : non_nukki_state,
            }, () => {
                let nukki_info;
                if(action == "Y"){
                    nukki_info = {
                        type : type,
                        position : Number(img_position_idx),
                        action : "Y",
                    };
                } else{
                    let change_N_list= [];
                    change_N_list.push({
                        key : type+"_"+this.state[type+"_img_position_name"][idx].position_name+"_nukki_yn",
                        value : "N"
                    });
                    change_N_list.push({
                        key : type+"_nukki_"+this.state[type+"_img_position_name"][idx].position_name+"_link",
                        value : ""
                    });
                    nukki_info = {
                        change_N_list : change_N_list,
                    };
                    this.setState({[type+"_"+this.state[type+"_img_position_name"][idx].position_name+"_nukki_yn"] : "N"});
                }
                this.insertEventTmp('preview', nukki_info);
            });
        }
    }

    /** 누끼 AI 윤곽 보정 상태 변경 */
    changeNukkiPPMstate(e, type, img_position_idx){
        let nukki_ppm_state = this.state[type+"_nukki_ppm_state"];
        nukki_ppm_state[Number(img_position_idx)-1] = e.target.checked;
        this.setState({[type+"_nukki_ppm_state"]: nukki_ppm_state}, () => {
            let nukki_info_Y_list = [];
            nukki_info_Y_list.push({
                type : type,
                position : Number(img_position_idx),
                action : "Y",
            });

            const nukki_info = {
                type : type,
                change_N_list : [],
                nukki_info_Y_list : nukki_info_Y_list,
            };
            this.insertEventTmp('preview', nukki_info);
        });

    }

    /* 이미지가 존재하는지 체크 (상품 이미지 or 업로드 or 링크) */
    positionImgCheck(type, idx, nonNukkiStateCheck){
        const link_type = this.state[type+"_user_img_list"][idx].link_type; // 49001: 파일 업로드, 49002: 이미지 주소, 49003: 상세페이지, 49004: 로보이미지, 49005: 동영상 주소
        let upload_img = this.state[type+"_user_img_list"][idx].img_link; // link_type에 맞는 이미지

        if(!nonNukkiStateCheck && (link_type == "49002" || link_type == "49004") && upload_img && !this.state[type+"_user_img_list"][idx].img_link_able){
            return false;
        }
        // 상품 이미지 또는 자동 생성시 들어간 상품 두번째 이미지 등
        let link = this.state[(type == "h" ? "h_" : "") + this.state[type+"_img_position_name"][idx].img_column_name];
        if(this.state[type+"_img"+(Number(idx)+1)+"_is_use"] == "Y"){
            switch (link_type){
                case "49002":
                case "49004":
                    return (upload_img && (this.state[type+"_user_img_list"][idx].img_link_able || nonNukkiStateCheck)) ? upload_img : link;
                case "49005":
                    if(this.state[type+"_is_youtube_link_use"] == "Y"){
                        return false;
                    } else{
                        upload_img = link;
                    }
                default : // 49001, 49003, 49004 등..
                return upload_img ? upload_img : link;
            }
        }
    }

    /* 링크 타입 관련 이미지가 존재하는지 체크 (업로드 or 링크) */
    positionLinkTypeImgCheck(type, idx){
        const link_type = this.state[type+"_user_img_list"][idx].link_type;
        const upload_img = this.state[type+"_user_img_list"][idx].img_link;

        // position 정보가 없다면 체크 불가능
        if(this.state[type+"_img_position_name"].length == 0){
            return false;
        }

        switch (link_type){
            case "49002":
            case "49004":
                return (upload_img && this.state[type+"_user_img_list"][idx].img_link_able) ? upload_img : false;
            case "49005":
                return false;
            default : // 49001, 49003, 49004 등..
                return upload_img ? upload_img : false;
        }
    }

    // 이미지 링크로 배경 제거
    removeBackgroundLinkImg = (img_path, ppm) => {
        http.get("/rest/removeBackground?img_path="+img_path+"&ppm_yn="+ppm)
        .then(res => {
            console.log(res.data);
            
        })
    }

    /** 상품 자체 이미지가 수정되는 경우 이벤트(누끼 관련 설정)
     *  1. 상품 변경
     *  2. 상세 이미지 사용 여부 변경
    */
    eventChangeProductImageOfNukki(){
        let change_N_list = [];
        let nukki_info_Y_list = [];
        for(var i=0;i<4;i++){
            if(this.state["w_img"+(i+1)+"_is_use"] == "Y"){
                if(this.positionImgCheck("w", i)){
                    // 업로드 이미지가 아닌데(상품 이미지를 사용하는데)
                    if(!this.positionLinkTypeImgCheck("w", i)){
                        if(this.state.pc_banner_ratio_list.length > 0){
                            // 누끼영역이 필수이면 돌려야함
                            if(this.state.w_essential_img_nukki_yn == "Y" && !this.state.w_non_nukki_state[i]){
                                nukki_info_Y_list.push({
                                    type : "w",
                                    position : i+1,
                                    action : "Y",
                                });
                                this.setState({
                                    ["w_"+this.state.w_img_position_name[i].position_name+"_nukki_yn"] : "Y",
                                });
                            }
                        } else{
                            // 세로형 비율만 존재해서 배너가 보이지 않는 상태
                            change_N_list.push({
                                key : "w_"+this.state.w_img_position_name[i].position_name+"_nukki_yn",
                                value : "N"
                            });
                            change_N_list.push({
                                key : "w_nukki_"+this.state.w_img_position_name[i].position_name+'_link',
                                value : ""
                            });
                            this.setState({
                                ["w_"+this.state.w_img_position_name[i].position_name+"_nukki_yn"] : "N",
                            });
                        }

                        // 위치 자동 이동을 실행한 경우 위치이동 초기화
                        if(this.state["w_img_"+(i+1)+"_auto_move_yn"]=="Y"){
                            this.setState({
                                ["w_img_"+(i+1)+"_auto_move_yn"] : "N",
                                ["w_img_"+(i+1)+"_scale"] : 1,
                                ["w_img_position_left_"+(i+1)] : 0,
                                ["w_img_position_right_"+(i+1)] : 0,
                                ["w_img_position_top_"+(i+1)] : 0,
                                ["w_img_position_bottom_"+(i+1)] : 0,
                            });
                        }
                    }
                } else{
                    // 이미지가 없는 경우
                    change_N_list.push({
                        key : "w_"+this.state.w_img_position_name[i].position_name+"_nukki_yn",
                        value : "N"
                    });
                    change_N_list.push({
                        key : "w_nukki_"+this.state.w_img_position_name[i].position_name+'_link',
                        value : ""
                    });
                    this.setState({
                        ["w_"+this.state.w_img_position_name[i].position_name+"_nukki_yn"] : "N",
                    });
                }
            }

            if(this.state["h_img"+(i+1)+"_is_use"] == "Y"){
                if(this.positionImgCheck("h", i)){
                    // 업로드 이미지가 아닌데(상품 이미지를 사용하는데)
                    if(!this.positionLinkTypeImgCheck("h", i)){
                        if(this.state.mobile_banner_ratio_list.length > 0){
                            // 누끼영역이 필수이면 돌려야함
                            if(this.state.h_essential_img_nukki_yn == "Y" && !this.state.h_non_nukki_state[i]){
                                nukki_info_Y_list.push({
                                    type : "h",
                                    position : i+1,
                                    action : "Y",
                                });
                                this.setState({
                                    ["h_"+this.state.h_img_position_name[i].position_name+"_nukki_yn"] : "Y",
                                });
                            }
                        } else{
                            // 가로형 비율만 존재해서 배너가 보이지 않는 상태
                            change_N_list.push({
                                key : "h_"+this.state.h_img_position_name[i].position_name+"_nukki_yn",
                                value : "N"
                            });
                            change_N_list.push({
                                key : "h_nukki_"+this.state.h_img_position_name[i].position_name+'_link',
                                value : ""
                            });
                            this.setState({
                                ["h_"+this.state.h_img_position_name[i].position_name+"_nukki_yn"] : "N",
                            });
                        }

                        // 위치 자동 이동을 실행한 경우 위치이동 초기화
                        if(this.state["h_img_"+(i+1)+"_auto_move_yn"]=="Y"){
                            this.setState({
                                ["h_img_"+(i+1)+"_auto_move_yn"] : "N",
                                ["h_img_"+(i+1)+"_scale"] : 1,
                                ["h_img_position_left_"+(i+1)] : 0,
                                ["h_img_position_right_"+(i+1)] : 0,
                                ["h_img_position_top_"+(i+1)] : 0,
                                ["h_img_position_bottom_"+(i+1)] : 0,
                            });
                        }
                    }
                } else{
                    // 이미지가 없는 경우
                    change_N_list.push({
                        key : "h_"+this.state.h_img_position_name[i].position_name+"_nukki_yn",
                        value : "N"
                    });
                    change_N_list.push({
                        key : "h_nukki_"+this.state.h_img_position_name[i].position_name+'_link',
                        value : ""
                    });
                    this.setState({
                        ["h_"+this.state.h_img_position_name[i].position_name+"_nukki_yn"] : "N",
                    });
                }
            }
            
        }

        const nukki_info = {
            change_N_list : change_N_list,
            nukki_info_Y_list : nukki_info_Y_list,
        };
        this.insertEventTmp('preview', nukki_info);

        $(".mirror_banner_v2_pc_banner_area").css({opacity : "0"});
        $(".mirror_banner_v2_mobile_banner_area").css({opacity : "0"});
    }

    /** 상품 이미지가 하나가 수정되는 경우 이벤트(누끼 관련 설정)
     *  1. 이미지 업로드/링크 변경
     *  2. 업로드/링크 이미지 x 버튼 클릭
    */
    eventChangeOneProductImageOfNukki(type, idx){
        let user_img_list = this.state[type+"_user_img_list"];

        user_img_list[idx].img_link_able = true;

        // version 3의 경우 ppm Y 상태가 없다
        user_img_list[idx]["img_ppm_N_crop_N_version_1"] = "";
        user_img_list[idx]["img_ppm_N_crop_N_version_2"] = "";
        user_img_list[idx]["img_ppm_N_crop_N_version_3"] = "";

        user_img_list[idx]["img_ppm_N_crop_Y_version_1"] = "";
        user_img_list[idx]["img_ppm_N_crop_Y_version_2"] = "";
        user_img_list[idx]["img_ppm_N_crop_Y_version_3"] = "";

        user_img_list[idx]["img_ppm_Y_crop_N_version_1"] = "";
        user_img_list[idx]["img_ppm_Y_crop_N_version_2"] = "";
        user_img_list[idx]["img_ppm_Y_crop_N_version_3"] = "";
        
        user_img_list[idx]["img_ppm_Y_crop_Y_version_1"] = "";
        user_img_list[idx]["img_ppm_Y_crop_Y_version_2"] = "";
        user_img_list[idx]["img_ppm_Y_crop_Y_version_3"] = "";

        // 상세이미지를 쓰지 않는 경우, 상세이미지 데이터를 초기화한다
        let crop_img_info_list = this.state[type+"_crop_img_info_list"];
        if(!(user_img_list[idx].link_type == "49003" && user_img_list[idx].img_link)){
            crop_img_info_list[idx] = {};
        }

        // AI 얼굴 위치 자동 이동을 실행한 경우 위치이동 초기화
        if(this.state[type+"_img_"+(idx+1)+"_auto_move_yn"]=="Y"){
            this.setState({
                [type+"_img_"+(idx+1)+"_auto_move_yn"] : "N",
                [type+"_img_"+(idx+1)+"_scale"] : 1,
                [type+"_img_position_left_"+(idx+1)] : 0,
                [type+"_img_position_right_"+(idx+1)] : 0,
                [type+"_img_position_top_"+(idx+1)] : 0,
                [type+"_img_position_bottom_"+(idx+1)] : 0,
            });
        }

        this.setState({
            select_detail_img_type_popup_show : false, // 이미지 업로드 팝업 닫기
            [type+"_user_img_list"] : user_img_list,
            [type+"_"+this.state[type+"_img_position_name"][idx].position_name+"_nukki_yn"] : "N",
            [type+"_crop_img_info_list"] : crop_img_info_list,
        }, () => {
            let change_N_list = [];
            let nukki_info_Y_list = [];
            if(this.state[type+"_essential_img_nukki_yn"] == "Y" && !this.state[type+"_non_nukki_state"][idx] && this.positionImgCheck(type, idx)){
                nukki_info_Y_list.push({
                    type : type,
                    position : idx+1,
                    action : "Y",
                });
            }
            change_N_list.push({
                key : type+"_"+this.state[type+"_img_position_name"][idx].position_name+"_nukki_yn",
                value : "N"
            });
            change_N_list.push({
                key : type+"_nukki_"+this.state[type+"_img_position_name"][idx].position_name+"_link",
                value : ""
            });
    
            const nukki_info = {
                type : type,
                change_N_list : change_N_list,
                nukki_info_Y_list : nukki_info_Y_list,
            };

            this.insertEventTmp('preview', nukki_info);
        });
    }

    /** 누끼 필수 템플릿이 누끼가 따져있는지 확인하고 배경 제거를 진행하는 함수
     * 1. getBannerRatio (iframe_html_id가 변경되는 함수 실행시)
    */
    checkNukkiState(){
        let nukki_info_Y_list = [];
        for(var i=0;i<4;i++){
            // 이미지가 사용되는 영역에 이미지가 있는데, 누끼 필수 영역임에도 누끼를 안땄다면 배경제거 실행
            if(this.state.w_essential_img_nukki_yn == "Y" && this.state["w_img"+(i+1)+"_is_use"] == "Y" && !this.state.w_non_nukki_state[i] && this.state["w_"+this.state.w_img_position_name[i].position_name+"_nukki_yn"] == "N"){
                if(this.positionImgCheck("w", i)){
                    const nukki_info_Y = {
                        type : "w",
                        position : i+1,
                        action : "Y",
                    };
                    nukki_info_Y_list.push(nukki_info_Y);
                } else{
                    this.setState({["w_"+this.state.w_img_position_name[i].position_name+"nukki_yn"] : "N"});
                }
            }

            // 이미지가 사용되는 영역에 이미지가 있는데, 누끼 필수 영역임에도 누끼를 안땄다면 배경제거 실행
            if(this.state.h_essential_img_nukki_yn == "Y" && this.state["h_img"+(i+1)+"_is_use"] == "Y" && !this.state.h_non_nukki_state[i] && this.state["h_"+this.state.h_img_position_name[i].position_name+"_nukki_yn"] == "N"){
                if(this.positionImgCheck("h", i)){
                    const nukki_info_Y = {
                        type : "h",
                        position : i+1,
                        action : "Y",
                    };
                    nukki_info_Y_list.push(nukki_info_Y);
                } else{
                    this.setState({["h_"+this.state.h_img_position_name[i].position_name+"nukki_yn"] : "N"});
                }
            }
        }
        if(nukki_info_Y_list.length == 0){
            this.insertEventTmp('preview');
            
            this.setState({ save_dialog_show_state: false });
            return false;
        }

        const nukki_info = {
            change_N_list : [],
            nukki_info_Y_list : nukki_info_Y_list,
        };
        this.insertEventTmp('preview', nukki_info);
    }

    /** 누끼 링크 조회 */
    checkTypeNukkiRunImage(type, idx){
        const user_img_list = this.state[type+"_user_img_list"];
        let ppm_yn = this.state[type+"_nukki_ppm_state"][idx] ? "Y" : "N";
        let crop_yn = this.state[type+"_is_nukki_crop_yn"];
        let version = this.state[type+"_nukki_version_list"][idx];

        // 업로드 이미지가 있는 경우
        if(this.state[type+"_user_img_list"][idx].link_type != "49005" && this.state[type+"_user_img_list"][idx].img_link){
            return user_img_list[idx]["img_ppm_"+ppm_yn+"_crop_"+crop_yn+"_version_"+version];
        }

        // 비연동형 : 이미지가 없는 경우
        if(this.state.solution_type_cd == "09900"){
            return transparent_image;
        }
        // 상품 이미지가 있는 경우
        let link = this.state[(type == "h" ? "h_" : "") + this.state[type+"_img_position_name"][idx].img_column_name];
        if(link) {
            var robo_nukki_img_list = !sessionStorage.getItem("robo_nukki_img_list") ? []: JSON.parse(sessionStorage.getItem("robo_nukki_img_list"))
            var resultLink = false;
            
            if(robo_nukki_img_list.length > 0){
                for(var i=0;i<robo_nukki_img_list.length;i++){
                    if(robo_nukki_img_list[i].org_img_link == link && robo_nukki_img_list[i].ppm_yn == ppm_yn && robo_nukki_img_list[i].crop_yn == crop_yn && robo_nukki_img_list[i].version == version){
                        resultLink = robo_nukki_img_list[i].nukki_img_link;
                    }
                }
                return resultLink;
            }
        }
        return false;
    }


    /** 누끼 작업중 화면 클릭 막기 */
    nukkiBlockPageClick(){
        if(this.state.w_essential_img_nukki_yn == "Y" || this.state.h_essential_img_nukki_yn == "Y"){
            $("#blockPageClick").show();
        }
    }

    /** 비연동형 배너 사이즈 입력 */
    onChangeInputBannerSize(e, type, name){
        if(e.key=='Enter'){
            this.onBlurInputBannerSize(type, name);
            return false;
        }

        let size = StringUtil.inputOnlyNumberFormat(e.target.value);
        this.setState({
            [name]: size,
            ["org_"+name] : size,
            [type+"_modified_ratio"] : true,
        });
    }

    /** 비연동형 배너 사이즈 입력 : blur */
    onBlurInputBannerSize(type, name){
        // 잘못된 비율을 입력하거나 0을 입력한 경우, 방금 입력된 부분의 값을 비운다
        // if((type == "pc" && this.state.event_banner_pc_width && this.state.event_banner_pc_height && Number(this.state.event_banner_pc_width) <= Number(this.state.event_banner_pc_height)) ||
        //     (type == "mobile" && this.state.event_banner_mobile_width && this.state.event_banner_mobile_height && Number(this.state.event_banner_mobile_width) > Number(this.state.event_banner_mobile_height))){
        //         let type_name = (type == "pc") ? "가로" : "세로";
        //         let type_name2 = (type == "pc") ? "세로" : "가로";
        //         alert(type_name2+"를 "+ type_name +" 이상의 값으로 설정하실 수 없습니다.");
        //         this.setState({[name] : ""}, () => {
        //             this.getBannerRatio();
        //         });
        //         return false;
        // }

        // 0을 입력한 경우, 방금 입력된 부분의 값을 비운다
        if(this.state[name] == "0"){
            alert("0이상의 값을 입력해주세요.");
            this.setState({[name] : ""}, () => {
                this.getBannerRatio();
            });
            return false;
        }

        if(this.state[type+"_modified_ratio"]){
            this.getBannerRatio(null, type);
            this.setState({
                [type+"_modified_ratio"] : false,
            });
        }
    }

    /** 메인 키워드 입력 */
    onChangeMainKeyword(e){
        this.setState({gpt_main_keyword : e.target.value});
    }

    /** 가격 텍스트 입력 */
    onChangeEventPrice(e){
        let event_price = StringUtil.inputOnlyNumberFormat(e.target.value);
        this.setState({
            event_price : event_price
        }, () => {this.insertEventTmp('preview')});
    }

    /** 버튼 텍스트/링크 입력 */
    onChangeButtonSetting(e, idx, type){
        let {button_list} = this.state;
        button_list[idx][type] = e.target.value;
        this.setState({
            button_list : button_list,
        }, () => {
            // button_info_input_timer의 동작을 취소한다 (시간이 200 만큼 지나기 전에 입력이 또 있었다면, 이전의 timeout동작은 실행되지 않을것이다)
            clearTimeout(button_info_input_timer[type+"/"+idx]);
            button_info_input_timer[type+"/"+idx] = setTimeout(() => {
                // 미리보기 데이터 저장
                this.insertEventTmp('preview', null, true);
            },200);
        });
    }

    /** 버튼 추가 */
    onClickAddButton(){
        let {button_list} = this.state;
        button_list.push({
            event_seq : this.getUrlParams()["event_seq"] ? this.state.event_seq : dateString,
            button_text : "",
            link : "",
        });
        for(var i=0;i<button_list.length;i++){
            button_list[i].order_no = i+1; 
        }
        this.setState({
            button_list : button_list,
        }, () => {
            this.insertEventTmp('preview', null, true);
        });
    }

    /** 버튼 삭제 */
    onClickDelButton(idx){
        let {button_list} = this.state;
        button_list.splice(idx,1);
        for(var i=0;i<button_list.length;i++){
            button_list[i].order_no = i+1; 
        }
        this.setState({
            button_list : button_list,
        }, () => {
            this.insertEventTmp('preview', null, true);
        });
    }

    /** 배경제거 버전 슬라이더 변경 */
    onChangeVersionSlider = (value, type, img_position_idx, afterChange) => {
        const idx = Number(img_position_idx) - 1;
        let nukki_version_list = this.state[type+"_nukki_version_list"];
        nukki_version_list[idx] = value;

        let nukki_ppm_state = this.state[type+"_nukki_ppm_state"];
        if(afterChange && value == "3"){
            nukki_ppm_state[idx] = false;
        }
        this.setState({
            [type+"_nukki_version_list"] : nukki_version_list,
            [type+"_nukki_ppm_state"] : nukki_ppm_state,
        }, () => {
            if(!afterChange){
                return false;
            }

            let nukki_info_Y_list = [];
            nukki_info_Y_list.push({
                type : type,
                position : Number(img_position_idx),
                action : "Y",
            });

            const nukki_info = {
                type : type,
                change_N_list : [],
                nukki_info_Y_list : nukki_info_Y_list,
            };
            this.insertEventTmp('preview', nukki_info);
        });
    }

    showReqSelectItem = () => {
        this.setState({
            basic_dialog_show_state : true,
            basic_dialog_content : "상품을 먼저 선택해 주세요.",
            basic_dialog_button_list: [
                {
                    title: "확인",
                    type: "1",
                    event_method: () => {
                        //팝업 닫기
                        this.setState({ basic_dialog_show_state: false });
                        scollSelectItemRef.current.scrollIntoView({block:"start"});
                    }
                }
            ]
        });
    }

    /* 이미지/텍스트 위치 이동 화살표 버튼 클릭 */
    movePositionOnClick = (e, position, opposite, value) => {
        isTmp = true;

        let _position = this.state[position];
        let _opposite = this.state[opposite];

        if(_position == undefined || _position == null){
            _position = 0;
        }

        if(_opposite == undefined || _opposite == null){
            _opposite = 0;
        }

        if(Number(_opposite) == 0){
            this.setState({[position] : Number(_position) + Number(value)});
        }else{
            this.setState({[opposite] : Number(_opposite) - Number(value)});
        }
    }

    /* 이미지 위치 이동 초기화 버튼 클릭 */
    moveImgPositionResetOnClick = (e, type, idx) => {
        isTmp = true;
        this.setState({
            [type + "_img_position_left_" + idx]: 0,
            [type + "_img_position_right_" + idx]: 0,
            [type + "_img_position_top_" + idx]: 0,
            [type + "_img_position_bottom_" + idx]: 0,
        });
    }

    /* 텍스트 위치 이동 초기화 버튼 클릭 */
    moveTextPositionResetOnClick = (e, type1, type2) => {
        isTmp = true;
        this.setState({
            [type1 + "_" + type2 + "_txt_position_left"] : 0,
            [type1 + "_" + type2 + "_txt_position_right"] : 0,
            [type1 + "_" + type2 + "_txt_position_top"] : 0,
            [type1 + "_" + type2 + "_txt_position_bottom"] : 0,
        });
    }

    /* 이미지/텍스트 위치 이동 입력 */
    movePositionOnBlur = (e, position, opposite) => {
        isTmp = true;

        if(e.target.value == "" || e.target.value == -0 || isNaN(e.target.value)){
            this.setState({[position] : 0})
            return;
        }

        if(e.target.value != 0 && e.target.value != -0){
            this.setState({
                [position] : Math.abs(Number(e.target.value)),
                [opposite] : 0,
            });
        }
    }

    /* 이미지/텍스트 위치 이동 입력 */
    movePositionOnKeyPress = (e) => {
        if (e.key === "Enter") {
            e.target.blur();
        }
    }

    /** 로보 이미지 마케팅 배너 클릭 */
    roboImgMarketingBanner = () => {
        if(!((this.state.pc_banner_ratio_list.length > 0 && this.state.event_type_cd) || (this.state.mobile_banner_ratio_list.length > 0 && this.state.event_mobile_type_cd))){
            if(this.state.pc_banner_ratio_list.length == 0 && this.state.mobile_banner_ratio_list.length == 0){
                alert("배너 사이즈를 입력해주세요.");
                return false;
            }
            alert("선택된 디자인이 없습니다. 디자인을 선택해주세요.")
            return false;
        }

        // 가로형 이미지 사용 영역이 있는 경우
        if(this.state.pc_banner_ratio_list.length > 0 && this.state.w_img_position_name.length > 0){
            $("#blockPageClick").show();
            scrollMakeWRoboImgRef.current.scrollIntoView({block:"center"});
            setTimeout(() => {
                this.setState({
                    show_robo_img_popup : true,
                    select_img_type : "w",
                    select_img_idx : 0,
                });
                $("#blockPageClick").hide();
            }, 300);

            return false;
        } else if(this.state.mobile_banner_ratio_list.length > 0 && this.state.h_img_position_name.length > 0){
            // 세로형 이미지 사용 영역이 있는 경우
            $("#blockPageClick").show();
            scrollMakeHRoboImgRef.current.scrollIntoView({block:"center"});
            setTimeout(() => {
                this.setState({
                    show_robo_img_popup : true,
                    select_img_type : "h",
                    select_img_idx : 0,
                });
                $("#blockPageClick").hide();
            }, 300);

            return false;
        }

        alert("선택하신 디자인은 이미지를 사용하지 않습니다. 다른 디자인을 선택해주세요.");
    }

    /**
     * 인물 위치 자동 이동
     * @param {*} type w or h
     * @param {*} idx 배너 이미지 순서 번호
     */
    modelImageShift(type, idx){
        this.setState({
            save_dialog_show_state: true,
            save_dialog_content: "인물 위치를 추출중 입니다.",
            save_dialog_button_list: [
                {
                    title: "확인",
                    type: "1",
                    display_yn:"N",
                }
            ]
        });

        let target_width, target_height;
        if(type == "w"){
            if(this.state.selected_pc_banner_ratio.org_banner_width > 50 || this.state.selected_pc_banner_ratio.org_banner_height > 50){
                target_width = this.state.selected_pc_banner_ratio.org_banner_width;
                target_height = this.state.selected_pc_banner_ratio.org_banner_height;
            } else{
                target_width = this.state.selected_pc_banner_ratio.banner_width;
                target_height = this.state.selected_pc_banner_ratio.banner_height;
            }
        } else{
            if(this.state.selected_mobile_banner_ratio.org_banner_width > 50 || this.state.selected_mobile_banner_ratio.org_banner_height > 50){
                target_width = this.state.selected_mobile_banner_ratio.org_banner_width;
                target_height = this.state.selected_mobile_banner_ratio.org_banner_height;
            } else{
                target_width = this.state.selected_mobile_banner_ratio.banner_width;
                target_height = this.state.selected_mobile_banner_ratio.banner_height;
            }
        }

        const sendForm = {
            img_path : this.positionImgCheck(type, idx),
            target_width : Math.round(target_width),
            target_height : Math.round(target_height),
        };
        http.get("/rest/shop/modelImageShift", {params:sendForm})
        .then(res => {
            if(res.data.person != "0" && res.data.resCode != "500"){
                const x = res.data.x;
                const y = res.data.y;
                const img_idx = idx+1;

                // 왼쪽과 위쪽 방향은 음수로 들어오기 때문에 양수로 변환후 적용
                this.setState({
                    [type+"_img_"+img_idx+"_auto_move_yn"] : "Y", // 위치 이동 실행 여부
                    [(type == "w" ? "":"h_")+"img_position_"+img_idx+"_type"] : "50002", // %로 변경
                    [type+"_img_position_left_"+img_idx] : (x && x < 0) ? -x : 0,
                    [type+"_img_position_right_"+img_idx] : (x && x > 0) ? x : 0,
                    [type+"_img_position_top_"+img_idx] : (y && y < 0) ? -y : 0,
                    [type+"_img_position_bottom_"+img_idx] : (y && y > 0) ? y : 0,
                }, () => {
                    this.insertEventTmp('preview');
                });
                this.setState({ save_dialog_show_state: false });

                return false;
            }

            alert("인물 위치를 찾지 못했습니다.");
            this.setState({ save_dialog_show_state: false });
        })
        .catch(error => {
            alert("인물 위치를 찾지 못했습니다.");
            this.setState({ save_dialog_show_state: false });
        })
    }
 
    /**
     * 패션플러스 얼굴 위치 자동 이동
     * @param {*} type w or h
     * @param {*} idx 배너 이미지 순서 번호
     */
    faceImageShiftForFashionPlus(type, idx, nukki_crop_yn){
        this.setState({
            save_dialog_show_state: true,
            save_dialog_content: "얼굴 위치를 추출중 입니다.",
            save_dialog_button_list: [
                {
                    title: "확인",
                    type: "1",
                    display_yn:"N",
                }
            ]
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
                },
        };

        let target_width, target_height;
        if(type == "w"){
            if(this.state.selected_pc_banner_ratio.org_banner_width > 50 || this.state.selected_pc_banner_ratio.org_banner_height > 50){
                target_width = this.state.selected_pc_banner_ratio.org_banner_width;
                target_height = this.state.selected_pc_banner_ratio.org_banner_height;
            } else{
                target_width = this.state.selected_pc_banner_ratio.banner_width;
                target_height = this.state.selected_pc_banner_ratio.banner_height;
            }
        } else{
            if(this.state.selected_mobile_banner_ratio.org_banner_width > 50 || this.state.selected_mobile_banner_ratio.org_banner_height > 50){
                target_width = this.state.selected_mobile_banner_ratio.org_banner_width;
                target_height = this.state.selected_mobile_banner_ratio.org_banner_height;
            } else{
                target_width = this.state.selected_mobile_banner_ratio.banner_width;
                target_height = this.state.selected_mobile_banner_ratio.banner_height;
            }
        }

        let is_nukki_img = nukki_crop_yn == "Y"? true : false;
        const body = {
            // 누끼 이미지를 사용하는 템플릿이고 누끼이미지가 존재하는 경우 누끼 이미지를 보내고 그렇지 않은 경우에는 원본 이미지를 보낸다
            img_path : is_nukki_img && this.state[type+"_essential_img_nukki_yn"] == "Y" && (this.checkTypeNukkiRunImage(type, idx) || this.state[type+"_nukki_"+this.state[type+"_img_position_name"][idx].img_column_name])
                        ? this.checkTypeNukkiRunImage(type, idx) ? this.checkTypeNukkiRunImage(type, idx) : this.state[type+"_nukki_"+this.state[type+"_img_position_name"][idx].img_column_name]
                        : this.positionImgCheck(type, idx),
            position : (idx+1),
            target_width : Math.round(target_width),
            target_height : Math.round(target_height),
            event_type_cd : (type == "w") ? this.state.event_type_cd : null,
            event_mobile_type_cd : (type == "w") ? null : this.state.event_mobile_type_cd,
        };
        http.post("/rest/shop/faceImageShift", body, config)
        .then(res => {
            if(res.data.resCode == "200"){
                const shift_pc = res.data.shift_pc;
                const scale_factor = res.data.scale_factor;
                if (shift_pc != null &&  shift_pc.length> 0 && scale_factor != null) {
                    const x = shift_pc[0];
                    const y = shift_pc[1];
                    const img_idx = idx+1;
    
                    // 왼쪽과 위쪽 방향은 음수로 들어오기 때문에 양수로 변환후 적용
                    this.setState({
                        [type+"_img_"+img_idx+"_auto_move_yn"] : "Y", // 위치 이동 실행 여부
                        [(type == "w" ? "":"h_")+"img_position_"+img_idx+"_type"] : "50002", // %로 변경
                        [type+"_img_position_left_"+img_idx] : (x && x < 0) ? -x : 0,
                        [type+"_img_position_right_"+img_idx] : (x && x > 0) ? x : 0,
                        [type+"_img_position_top_"+img_idx] : (y && y < 0) ? -y : 0,
                        [type+"_img_position_bottom_"+img_idx] : (y && y > 0) ? y : 0,
                        
                        [type + '_img_' + img_idx + '_scale'] : (scale_factor && scale_factor > 0) ? scale_factor : 0,
                    }, () => {
                        this.insertEventTmp('preview');
                    });
                }
                this.setState({ save_dialog_show_state: false });

                return false;
            }

            alert("얼굴 위치를 찾지 못했습니다.");
            this.setState({ save_dialog_show_state: false });
        })
        .catch(error => {
            alert("얼굴 위치를 찾지 못했습니다.");
            this.setState({ save_dialog_show_state: false });
        })
    }

    getImgScaleValueText = (value) => {
        img_scale_value = value;
        return `${value}`;
    }

    handleImgScaleOnChange = (e, type, idx) => {
        e.stopPropagation();
        isTmp = true;

        this.setState({
            [type + '_img_' + idx + '_scale'] : e.target.value,
        });
    }

    handleImgScaleOnClick = (e, type, idx) => {
        e.stopPropagation();
        isTmp = true;

        this.setState({
            [type + '_img_' + idx + '_scale'] : img_scale_value,
        });
    }

    handleImgScaleResetOnClick = (e, type, idx) => {
        if(e){e.stopPropagation()}
        isTmp = true;

        this.setState({
            [type + '_img_' + idx + '_scale'] : 1,
        });
    }

    getTxtScaleValueText = (value) => {
        txt_scale_value = value;
        return `${value}`;
    }

    handleTxtScaleOnChange = (e, type1, type2) => {
        e.stopPropagation();
        isTmp = true;

        this.setState({
            [type1 + '_' + type2 + '_txt_scale'] : e.target.value
        });
    }

    handleTxtScaleOnClick = (e, type1, type2) => {
        e.stopPropagation();
        isTmp = true;

        this.setState({
            [type1 + '_' + type2 + '_txt_scale'] : txt_scale_value
        });
    }

    handleTxtScaleResetOnClick = (e, type1, type2) => {
        if(e){e.stopPropagation()}
        isTmp = true;

        this.setState({
            [type1 + '_' + type2 + '_txt_scale'] : 1
        });
    }

    htmlToEditor = (html) => {
        const blocksFromHTML = htmlToDraft(html);
        const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap,
        );
        return EditorState.createWithContent(state);
    }

    editorToHtml = (editorState) => {
        let convertHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        convertHtml = convertHtml.replace(/<(\/)?[Pp](\/)?>/g,"");
        return convertHtml;
    }

    onMainEditorStateChange = (mainEditorState) => {
        const currentContentState = this.state.mainEditorState.getCurrentContent();
        const newContentState = mainEditorState.getCurrentContent();

        this.setState({
            mainEditorState: mainEditorState,
            auto_title: this.editorToHtml(mainEditorState),
            title: this.editorToHtml(mainEditorState),
            org_title: this.editorToHtml(mainEditorState),

        }, () => {
            if(currentContentState !== newContentState) {
                this.checkKeydown(this);
            }
        });
    }

    onSubEditorStateChange = (subEditorState) => {
        const currentContentState = this.state.subEditorState.getCurrentContent();
        const newContentState = subEditorState.getCurrentContent();

        this.setState({
            subEditorState: subEditorState,
            sub_title: this.editorToHtml(subEditorState),
            foreign_title: this.editorToHtml(subEditorState),
            sentence: this.editorToHtml(subEditorState),
            eng_sentence: this.editorToHtml(subEditorState),

        }, () => {
            if(currentContentState !== newContentState) {
                this.checkKeydown(this);
            }
        });
    }

    checkKeydown = function(thisComponent){
        clearTimeout(keydownTimeout);
        keydownTimeout = setTimeout(function(){
            thisComponent.insertEventTmp('preview');
        }, 1000);
    }

    checkImgFileExtension = (file) => {
        let reg = /(.*?)\.(jpg|jpeg|png|gif|bmp|svg)$/i;

        if(String(file.name).match(reg)) {
            return true;
        }else{
            return false;
        }
    }

    uploadCallback = (file) => {
        return new Promise(
            (resolve, reject) => {
                if(!this.checkImgFileExtension(file)){
                    alert("이미지 파일만 첨부할 수 있습니다.");
                    resolve();
                    return;
                }

                const formData = new FormData();
                formData.append('files', file);

                axios({
                    url: '/rest/shop/roboBanner/save/orgImg',
                    method: 'POST',
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                    .then(response => {
                        resolve({ data: { link: response.data.org_img_path_uri_encode } });
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        );
    }

    /** 타겟 선택하기 */
    onChangeTargetViewSelect = (selectedOption) => {
        let originSelectedOption = this.state.selected_target_view_list;
        let selecteList = this.state.target_view_list;
        const noLimitCode = -1;
        const selectAll = -2;

        // 기존 모든 타겟 선택
        if(selectedOption.find((obj) => obj.target_view_seq == selectAll)){
            selectedOption = selecteList.filter(obj => obj.target_view_seq != noLimitCode && obj.target_view_seq != selectAll);
        } else if(selectedOption.find((obj) => obj.target_view_seq == noLimitCode)){ // 모든 타겟 선택
            // 변경 전에도 모든 타겟 값이 포함되어 있었다면, 모든 타겟이 아닌 새로운 값을 추가한 것
            if(originSelectedOption.find((obj) => obj.target_view_seq == noLimitCode)){
                selectedOption = selectedOption.filter(obj => obj.target_view_seq != noLimitCode);
            } else{ // 모든 타겟 값이 새로 추가된 경우
                selectedOption = selectedOption.filter((obj) => obj.target_view_seq == noLimitCode);
            }
        }

        this.setState({ selected_target_view_list: selectedOption });
    };
    
    // 사용자 멀티샵 서비스 무료체험, 결제여부 확인
    fn_shopServiceOperated = (service_type, callback=()=>{}) => {
        http.get("/rest/shop/serviceOperated/"+service_type)
        .then(res => {
            if(res.data.code == "0000"){
                if(service_type == "01009" 
                    && (
                            res.data.shopServiceOperated_map.is_finish == "0" 
                            || res.data.shopServiceOperated_map.is_finish == "1"        //타겟뷰 서비스 : 무료체험 구분 없음
                            || res.data.shopServiceOperated_map.is_payment == "1"
                        )
                ){
                    this.setState({is_use_target_view_service : "Y"});
                } else{
                    this.setState({
                        is_use_target_view_service : "N",       // is_finish : -1 미신청시 해당 팝업 출력
                    });
                }
            }
            return res;
        })
        .then((res) => {
            callback(res);
        })
		.catch(error => {
			console.log (error);
		});
    }

    loadTargetViewList = () => {
        http.get(`/rest/common/auto/event/targetview/list?iframe_page_id=${this.state.iframe_page_id}&iframe_html_id=${this.state.iframe_html_id}`)
        .then(res => {
            if(res.data.code == "200"){
                this.setState({
                    target_view_list : res.data.target_view_list,
                    popup_target_type : res.data.popup_target_type,
                    event_show_type : res.data.event_show_type,
                })
            }
        })
		.catch(error => {
			console.log (error);
		});
    }

    getTargetViewList = () => {
        http.get("/rest/shop/selectbox/targetview/list")
        .then(res => {
            if(res.data.target_view_list){
                let target_view_list = res.data.target_view_list;
                if(target_view_list.length > 0){
                    target_view_list = [
                        {title : "모든 타겟 (새롭게 추가되는 모든 타겟 리스트를 포함)", target_view_seq: -1},
                        {title : "기존 타겟 전체 선택", target_view_seq: -2},
                        ...target_view_list
                    ];
                }
                this.setState({target_view_list : target_view_list})
            }
        })
		.catch(error => {
			console.log (error);
		});
    }

    goTargetViewSetting = () => {
        window.open("/shop/targetview/setting", '_blank'); // 새 창으로 열기
    }

    // 배너 템플릿 필터 조회
    getBannerDesignTemplateFilter() {
        http.get("/rest/common/bannerDesignTemplateFilter")
            .then(res => {
                let tagLists = {};
                let categoryList = [];

                res.data.ctg_list.map(obj => {
                    categoryList.push(obj.tag_cd.substr(0, 3).toLowerCase());
                });

                for (const category of categoryList) {
                    tagLists[category] = res.data[category + "_tag_list"].map((tag) => ({
                        ctg_cd: tag.ctg_cd,
                        ctg_sorting: tag.ctg_sorting,
                        ctg_sorting_inside: tag.ctg_sorting_inside,
                        display_yn: tag.display_yn,
                        tag_cd: tag.tag_cd,
                        tag_name: tag.tag_name,
                        tag_sorting: tag.tag_sorting,
                        isSelected: false,
                    }));
                }

                this.setState({
                    template_ctg_list: res.data.ctg_list,
                    template_tag_list: res.data.tag_list,
                    w_template_all_tag_list: tagLists,
                    h_template_all_tag_list: tagLists,
                });

            }).catch(e => {
            console.log("### [ERROR] getBannerDesignTemplateFilter ###", e);
        })
    }

    handleDrag = ({ scrollContainer }) => (ev) => {
        this.handleDragMove(ev, (posDiff) => {
            if (scrollContainer && scrollContainer.current) {
                scrollContainer.current.scrollLeft += posDiff;
            }
        });
    };

    handleMouseOnWheel = (apiObj, ev) => {
        const isTouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

        if (isTouchpad) {
            ev.stopPropagation();
            return;
        }

        if (ev.deltaY < 0) {
            apiObj.scrollNext();
        } else if (ev.deltaY > 0) {
            apiObj.scrollPrev();
        }
    };

    handleDragStart = (ev) => {
        this.setState({
            position: ev.clientX,
            clicked: true,
        });
    };

    handleDragStop = () => {
        window.requestAnimationFrame(() => {
            this.setState({
                dragging: false,
                clicked: false,
            });
        });
    };

    handleDragMove = (ev, cb) => {
        const newDiff = this.state.position - ev.clientX;
        const movedEnough = Math.abs(newDiff) > 5;

        if (this.state.clicked && movedEnough) {
            this.setState({
                dragging: true,
            });
        }

        if (this.state.dragging && movedEnough) {
            this.setState(
                {
                    position: ev.clientX,
                },
                () => cb(newDiff)
            );
        }
    };

    // 템플릿 필터 버튼 클릭
    handleTemplateFilterBtnClick = (upper_code_cd) => {
        this.setState({
            show_template_filter_popup_type: upper_code_cd,
            show_template_filter_popup: true,
        })
    }

    // 템플릿 필터 팝업 닫기(x) 버튼 클릭
    closeTemplateFilterPopupBtnClick = () => {
        this.resetTemplateFilterBtnClick("03000", "w");     // 템플릿 필터 초기화
        this.resetTemplateFilterBtnClick("07000", "h");     // 템플릿 필터 초기화
        this.setState({
            show_template_filter_popup: false,
        });
    }

    // 태그 버튼 클릭
    tagBtnClick = (category, tagCd, isSelected, upper_code_cd, template_type) => {
        if (this.state.dragging) {
            return false;
        }

        const selected_tag_list = [];

        this.setState((prevState) => {
            const updatedList = prevState[`${template_type}_template_all_tag_list`][category].map((tag) => {
                if (tag.tag_cd === tagCd) {
                    return {
                        ...tag,
                        isSelected: isSelected,
                    };
                }
                return tag;
            });

            const allSelected = this.checkAllSelected(category, updatedList);

            const updatedSelectedTags = {
                ...prevState[`${template_type}_selected_tags`],
                [category]: updatedList.filter((tag) => tag.isSelected).map((tag) => tag.tag_cd),
            };

            for(const category in updatedSelectedTags){
                if(updatedSelectedTags.hasOwnProperty(category)){
                    updatedSelectedTags[category].map((obj => {
                        selected_tag_list.push(obj)
                    }));
                }
            }
            // console.log(selected_tag_list)

            if(updatedSelectedTags == null || updatedSelectedTags == undefined || updatedSelectedTags.length == 0){
                return {
                    [template_type + "_all_tag_selected"]: true,
                }
            }else{
                return {
                    selected: prevState.selected !== tagCd ? tagCd : "",
                    [template_type + "_template_all_tag_list"]: {
                        ...prevState[`${template_type}_template_all_tag_list`],
                        [category]: updatedList,
                    },
                    [template_type + "_selected_tags"]: updatedSelectedTags,
                    [template_type + "_all_selected"]: {
                        ...prevState[`${template_type}_all_selected`],
                        [category]: allSelected,
                    },
                    [template_type + "_all_tag_selected"]: false,
                }
            }
        }, () => {
            if(template_type == "w"){
                this.setState({
                    pc_banner_type_paging : {   // 페이징 초기화
                        activePage: 1,
                        totalCnt: 0,
                        startIdx: 0,
                        rowcnt:15,
                    }
                }, () => {
                    this.getBannerDesignTemplateUserFavoritePaging(upper_code_cd, selected_tag_list);
                })
            }else {
                this.setState({
                    mobile_banner_type_paging : {   // 페이징 초기화
                        activePage: 1,
                        totalCnt: 0,
                        startIdx: 0,
                        rowcnt:12,
                    },
                }, () => {
                    this.getBannerDesignTemplateUserFavoritePaging(upper_code_cd, selected_tag_list);
                })
            }
        });
    }

    // 필터 팝업 내부 태그 버튼 클릭
    handleTagBtnClick = (category, tagCd, isSelected, template_type) => {
        this.setState((prevState) => {
            const updatedList = prevState[`${template_type}_template_all_tag_list`][category].map((tag) => {
                if (tag.tag_cd === tagCd) {
                    return {
                        ...tag,
                        isSelected: isSelected,
                    };
                }
                return tag;
            });

            const allSelected = this.checkAllSelected(category, updatedList);

            const updatedSelectedTags = {
                ...prevState[`${template_type}_selected_tags`],
                [category]: updatedList.filter((tag) => tag.isSelected).map((tag) => tag.tag_cd),
            };

            if(updatedSelectedTags == null || updatedSelectedTags == undefined || updatedSelectedTags.length == 0) {
                return {
                    [template_type + "_all_tag_selected"]: true,
                }
            }else{
                return {
                    [template_type + "_template_all_tag_list"]: {
                        ...prevState[`${template_type}_template_all_tag_list`],
                        [category]: updatedList,
                    },
                    [template_type + "_selected_tags"]: updatedSelectedTags,
                    [template_type + "_all_selected"]: {
                        ...prevState[`${template_type}_all_selected`],
                        [category]: allSelected,
                    },
                    [template_type + "_all_tag_selected"]: false,
                };
            }
        });
    };

    // 필터 팝업 밖 전체 태그 버튼 클릭
    allTagBtnClick = (upper_code_cd, template_type) => {
        this.setState((prevState) => ({
            [template_type + "_all_tag_selected"]: !prevState[`${template_type}_all_tag_selected`],
        }), () => {
            if(this.state[template_type + "_all_tag_selected"]){
                this.resetTemplateFilterBtnClick(upper_code_cd, template_type);
            }
        });
    }

    // 필터 팝업 내부 전체 태그 버튼 클릭
    handleAllTagBtnClick = (category, template_type) => {
        this.setState((prevState) => {
            const allSelected = !prevState[`${template_type}_all_selected`][category];

            const updatedList = prevState[`${template_type}_template_all_tag_list`][category].map((tag) => ({
                ...tag,
                isSelected: allSelected,
            }));

            const updatedSelectedTags = {
                ...prevState[`${template_type}_selected_tags`],
                [category]: allSelected ? updatedList.map((tag) => tag.tag_cd) : [],
            };

            return {
                [template_type + "_template_all_tag_list"]: {
                    ...prevState[`${template_type}_template_all_tag_list`],
                    [category]: updatedList,
                },
                [template_type + "_selected_tags"]: updatedSelectedTags,
                [template_type + "_all_selected"]: {
                    ...prevState[`${template_type}_all_selected`],
                    [category]: allSelected,
                },
            };
        });
    };

    checkAllSelected = (category, updatedList) => {
        return updatedList.every((tag) => tag.isSelected) && updatedList.length > 0;
    };

    // 템플릿 필터 팝업 초기화 버튼 클릭
    resetTemplateFilterBtnClick = (upper_code_cd, template_type) => {
        this.setState((prevState) => {
            const updatedTagLists = {};
            const updatedAllSelected = {};

            Object.keys(prevState[`${template_type}_template_all_tag_list`]).forEach((category) => {
                updatedTagLists[category] = prevState[`${template_type}_template_all_tag_list`][category].map((tag) => ({
                    ...tag,
                    isSelected: false,
                }));
                updatedAllSelected[category] = false;
            });

            return {
                [template_type + "_template_all_tag_list"]: {
                    ...prevState[`${template_type}_template_all_tag_list`],
                    ...updatedTagLists,
                },
                [template_type + "_all_selected"]: {
                    ...prevState[`${template_type}_all_selected`],
                    ...updatedAllSelected,
                },
                [template_type + "_selected_tags"]: {
                    bsn: [],
                    usg: [],
                    lyt: [],
                    sty: [],
                },
                [template_type + "_all_tag_selected"]: true,
            };
        }, () => {
            if(template_type == "w"){
                this.setState({
                    pc_banner_type_paging : {   // 페이징 초기화
                        activePage: 1,
                        totalCnt: 0,
                        startIdx: 0,
                        rowcnt:15,
                    }
                }, () => {
                    this.getBannerDesignTemplateUserFavoritePaging(upper_code_cd);
                });

            }else {
                this.setState({
                    mobile_banner_type_paging : {   // 페이징 초기화
                        activePage: 1,
                        totalCnt: 0,
                        startIdx: 0,
                        rowcnt:12,
                    },
                }, () => {
                    this.getBannerDesignTemplateUserFavoritePaging(upper_code_cd);
                });
            }
        });
    }

    // 템플릿 필터 팝업 적용 버튼 클릭
    applyTemplateFilterBtnClick = (upper_code_cd, template_type) => {
        const selected_tag_list = [];

        for(const category in this.state[template_type + "_selected_tags"]){
            if(this.state[template_type + "_selected_tags"].hasOwnProperty(category)){
                this.state[template_type + "_selected_tags"][category].map((obj => {
                    selected_tag_list.push(obj)
                }));
            }
        }
        // console.log(selected_tag_list)

        if(template_type == "w"){
            this.setState({
                show_template_filter_popup: false,
                pc_banner_type_paging : {   // 페이징 초기화
                    activePage: 1,
                    totalCnt: 0,
                    startIdx: 0,
                    rowcnt:15,
                }
            }, () => {
                this.getBannerDesignTemplateUserFavoritePaging(upper_code_cd, selected_tag_list);
            });

        }else {
            this.setState({
                show_template_filter_popup: false,
                mobile_banner_type_paging : {   // 페이징 초기화
                    activePage: 1,
                    totalCnt: 0,
                    startIdx: 0,
                    rowcnt:12,
                },
            }, () => {
                this.getBannerDesignTemplateUserFavoritePaging(upper_code_cd, selected_tag_list);
            });
        }
    }

    render() {
        color_title_num_p = 0;
        color_title_num_m = 0;
        return (
            <>
                <div id='page-wrapper' className="container eventFormPromotion" style={{backgroundColor: '#ffffff', minHeight:"100%", overflowX: "hidden"}}>
                        <div id="setting_container">
                            <div className="form_primary_title m-b-20">배너 디자인</div>
                        </div>
                        <div className={"event_robo_img_marketing_banner"} onClick={() => this.roboImgMarketingBanner()}>
                        </div>
                        <div className="form_primary_title">적용 범위 설정</div>
                        <div className="m-t-43 m-l-29">
                            <div className="m-t-43">
                                <div style={{display:"flex"}}> 
                                    <div className="icn_round_blue_small"></div>
                                    &nbsp;&nbsp;
                                    <div className="form_title" style={{flex:"1", marginTop: "auto", marginBottom: "auto"}}>배너의 이름을 입력해 주세요.</div>
                                </div>
                                <div className="margin-vertical-auto flexBox m-l-34" style={{flex:"1"}}>
                                <div style={{width: "500px", maxWidth: "calc(95% - 15px)"}}>
                                    <div className="input_text_title_d">배너 이름</div>
                                        <input id="none_title" type="text" className="border_form_radius_b f-s-15 input_width_100 input_height_35px" value={this.state.none_title} onChange={e => this.onChangeTitle(e, "none_title")} onBlur={e => this.handleTitleBlur(e)} placeholder="배너 리스트 이름을 입력해 주세요."></input>
                                    </div>
                                </div>
                            </div>
                            <div className="flexBox m-t-43"> 
                                <div className="icn_round_blue_small"></div>
                                &nbsp;&nbsp;
                                <div className="form_title" style={{marginTop: "auto", marginBottom: "auto"}}>배너 영역을 설정해 주세요.</div>
                            </div>
                            <div className="flexBox m-t-32" style={{marginBottom: "15px"}}> 
                                <div id="item_user_type" className="m-l-34" style={{marginTop: "auto", marginBottom: "auto", fontSize:"15px"}}>어느 영역에 배너를 삽입 하시겠습니까?</div>
                            </div>
                            <div>
                                <div className="input_text_title_d eventSettingTitle m-l-34" style={{zIndex:"999"}}><label style={{marginTop: "auto", marginBottom: "auto"}}>페이지 선택</label></div>
                                <div className="m-l-34" style={{display:"flex", width:"500px", maxWidth: "calc(95% - 15px)", position:"relative", marginBottom: '12px'}}>
                                    <select id="iframe_page_id_select" className="border_form_radius_b" value={this.state.iframe_page_id} onChange={e => this.onChangeSelectBoxCommonHandler(e, "iframe_page_id")}>
                                        <option value="" disabled selected>페이지를 선택해 주세요.</option>
                                        {this.state.page_list.map((page, idx) => ( 
                                            <option key={idx} value={page.code_name}>{page.val1}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div>
                                <div className="input_text_title_d eventSettingTitle m-l-34" style={{zIndex:"999"}}><label style={{marginTop: "auto", marginBottom: "auto"}}>배너 영역</label></div>
                                <div className="m-l-34" style={{display:"flex", width:"500px", maxWidth: "calc(95% - 15px)", position:"relative", marginBottom: '12px'}}>
                                    {
                                        this.state.html_list.length > 0
                                        ?   <select id="iframe_html_id_select" className="border_form_radius_b" value={this.state.iframe_html_id} onChange={e => this.onChangeSelectBoxCommonHandler(e, "iframe_html_id")}>
                                                <option value="" disabled selected>배너를 선택해 주세요.</option>
                                                {this.state.html_list.map((html, idx) => (
                                                    <option key={idx} value={html.iframe_html_id}>{html.iframe_html_name}</option>
                                                ))}
                                            </select>
                                        :   <select id="iframe_html_id_select" className="border_form_radius_b" value={""} onChange={e => this.onChangeSelectBoxCommonHandler(e, "iframe_html_id")}>
                                                <option value="" disabled selected>페이지를 먼저 선택해 주세요.</option>
                                            </select>
                                    }
                                    
                                </div>
                            </div>
                            <div className="margin-vertical-auto flexBox m-l-34 m-t-32">
                                    <div className="icn_warning_round"></div>
                                    <div style={{marginLeft:"10px", flex:"1"}}>로보MD 배너가 삽입 되어 있는 <b>페이지</b>와 <b>배너영역</b> 2개 모두 선택해 주세요.</div>
                            </div>
                            <div className="margin-vertical-auto flexBox m-l-34 m-t-15">
                                    <div className="icn_warning_round"></div>
                                    <div style={{marginLeft:"10px", flex:"1"}}><b>페이지</b>와 <b>배너</b>를 선택하시면 해당 배너의 <b>사이즈 비율</b>을 자동으로 불러옵니다. (미리보기용)</div>
                            </div>

                            {/* <div className="flexBox m-t-43" style={{marginBottom: "15px"}}> 
                                <div id="item_user_type" className="m-l-34" style={{marginTop: "auto", marginBottom: "auto", fontSize:"15px"}}>누구에게 노출 하시겠습니까?</div>
                            </div> */}
                            <div className="flexBox m-t-43" style={{marginBottom: "15px"}}> 
                                <div id="item_user_type" style={{marginTop: "auto", marginBottom: "auto", fontSize:"15px"}}>배너 진열 타겟</div>
                            </div>
                            <div className={"event_item_user_type_radio"}>
                                <div className="redio_v2 m-l-34 m-t-32 event_content_radio">
                                {
                                    [{type:"A", text:"모두"},{type:"U", text:"회원"},{type:"N", text:"비회원"}].map((obj, idx) => (
                                        <RadioButton
                                            id={"item_user_type"+obj.type}
                                            name={"item_user_type"}
                                            value={obj.type}
                                            checked={this.state.item_user_type == obj.type}
                                            onChangeCheck={this.onClickItemUserType.bind(this)}
                                        >
                                            <div className="banner_radio_text">{obj.text}</div>
                                        </RadioButton>
                                    ))
                                }
                                </div>
                                <div className={"item_user_type_tootip"}>
                                    <RadioButton
                                        name={"item_user_type"}
                                        value={"T"}
                                        checked={this.state.item_user_type == "T"}
                                        onChangeCheck={this.onClickItemUserType.bind(this)}
                                    >
                                        <div className={"EventFormPromotionTargetViewLabel"}>타겟뷰</div>
                                        <div className={["banner_radio_text", "radio_tooltip"].join(' ')}>방문자별 맞춤 노출</div>
                                        <img src={crown_icon} style={{width:"18px", height:"18px", marginRight:"6px"}}/>
                                        <Tooltip
                                            className={"target_view_tooltip"}
                                            zIndex={4}
                                            iconWidth={22.2}
                                            iconHeight={22.2}
                                            squareMoveLeft={this.state.innerWidth < 400 ? 20 : 0}
                                            content={"세분화된 고객 분류 기준 설정 기능을 통해 로보MD에서 생성한 콘텐츠를 원하는 고객별로 맞춤 노출하는 타겟 마케팅이 가능한 서비스에요."}
                                        />
                                        <div style={{color: "#ACACAC", fontSize: "12px", marginLeft:"2px", whiteSpace: "nowrap",position:"relative"}}>타겟뷰란</div>
                                    </RadioButton>
                                    <RadioButton
                                        name={"item_user_type"}
                                        value={"D"}
                                        checked={this.state.item_user_type == "D"}
                                        onChangeCheck={this.onClickItemUserType.bind(this)}
                                    >
                                        <div className={["banner_radio_text", "radio_tooltip"].join(' ')}>타겟 미선정 기본노출</div>
                                        <Tooltip
                                            zIndex={4}
                                            iconWidth={22.2}
                                            iconHeight={22.2}
                                            squareMoveLeft={this.state.innerWidth < 400 ? 20 : 0}
                                            content={"세분화된 고객 세그먼트 설정 기능을 통해 로보MD에서 생성한 고객이 미선정되거나, 상품이 자동으로 미선정될 시에 배너코너가 비어보이지 않도록 예비용으로 노출하는 배너이미지 서비스에요."}
                                        />
                                        <div style={{color: "#ACACAC", fontSize: "12px", marginLeft:"2px", whiteSpace: "nowrap",position:"relative"}}>타겟 미선정시 기본노출이란?</div>
                                    </RadioButton>
                                </div>
                                {this.state.item_user_type == "T" && this.state.is_use_target_view_service == "Y" &&
                                    (this.state.target_view_list.length > 0
                                    ?<div style={{marginLeft:"62px"}}>
                                        <div style={{maxWidth: "600px", width: "100%", marginBottom: "10px", zIndex: 3, position: "relative"}}>
                                            <Select
                                                className={"react-select"}
                                                value={this.state.selected_target_view_list}
                                                closeMenuOnSelect={false}
                                                isMulti
                                                components={animatedComponents}
                                                placeholder={"타겟을 선택해 주세요"}
                                                getOptionValue={(option) => option.target_view_seq}
                                                getOptionLabel={(option) => option.title}
                                                noOptionsMessage={() => "없음"}
                                                options={this.state.target_view_list}
                                                onChange={selectedOption => this.onChangeTargetViewSelect(selectedOption)}
                                                styles={reactSelectStyles()}
                                            />
                                        </div>
                                        <span style={{width:"100%"}}>
                                            <Alert type={"info"} text={"설정한 조건에 포함되는 고객이 없는 경우, 팝업이 노출되지 않으니 주의해 주세요."}/>
                                        </span>
                                    </div>
                                    :<>
                                        <div style={{display:"flex", fontSize:"15px", alignItems: "center", marginLeft:"62px"}}>
                                            <div style={{marginRight:"10px"}}>아직 등록된 타겟이 없어요.<br/>타겟을 설정하고 나의 콘텐츠를 노출해 보세요.</div>
                                            <Button fontSize={"14px"} width={"120px"} height={"36px"} minWidth={"86px"} onClick={this.goTargetViewSetting}>
                                                타겟 설정하기
                                            </Button>
                                        </div>
                                        <div style={{display:"flex", fontSize:"15px", marginTop:"20px", alignItems: "center", marginLeft:"62px"}}>
                                            <div style={{marginRight:"10px"}}>타겟 설정 이후 버튼을 눌러 주세요.</div>
                                            <Button type={"hover_white"} fontSize={"12px"} width={"80px"} height={"24px"} minWidth={"70px"} onClick={this.getTargetViewList}>
                                                <div style={{display: "flex", justifyContent: "center", alignItems: "center", color:"#3a3a3a", paddingTop:"1px"}}>
                                                    <img src={curved_arrow_right} style={{width:"14px", height:"14px", marginRight: "4px", marginBottom:"1px"}}/>
                                                    새로고침
                                                </div>
                                            </Button>
                                        </div>
                                    </>
                                    )
                                }
                            </div>

                            {/* {this.state.is_use_target_view_service == "Y" && this.state.event_show_type == "70002" && this.state.popup_target_type == "73002" && this.state.target_view_list.length > 0 &&
                                <div className="m-t-43" style={{marginBottom: "15px"}}>
                                    <div className="m-l-34" style={{display:"flex", marginBottom: "10px"}}>
                                        <div className="icn_warning_round"></div>
                                        <div style={{marginTop: "auto", marginLeft:"10px", fontSize:"15px"}}>아래의 대상에게 노출됩니다.</div>
                                    </div>
                                    <div>
                                        {this.state.target_view_list.map((obj) => (
                                            <div id="item_user_type" className="m-l-34" style={{marginTop: "auto", marginBottom: "auto", fontSize:"14px"}}>{obj.title}</div>
                                        ))}
                                    </div>
                                </div>
                            } */}

                            {this.state.solution_type_cd != "09900" &&
                                <>
                                    <div className="flexBox m-t-43" style={{marginBottom: "15px"}}> 
                                        <div className="icn_round_blue_small"></div>
                                        &nbsp;&nbsp;
                                        <div className="form_title" style={{marginTop: "auto", marginBottom: "auto"}}>상품을 선택해 주세요.</div>
                                    </div>
                                    <div class="flexBox" style={{marginTop:"10px"}}>
                                        <div className="mobile-width-100" style={{marginTop:"-10px"}}>
                                            <div className="input_text_title_d eventSettingTitle m-l-34" style={{zIndex:"2"}}><label style={{marginTop: "auto", marginBottom: "auto"}}>상품 검색</label></div>
                                            <div className="m-l-34 mobile-width-100" style={{display:"flex", width:"500px", maxWidth: "calc(95% - 15px)", position:"relative", marginBottom: '12px'}}>
                                                <input id="search_product" className="border_form_radius_b" type="text" onChange={(e) => {
                                                    // console.log(e.target.value)
                                                    this.setState({ product_name: e.target.value })
                                                }} placeholder="검색어를 입력하세요." style={{width:"100%", height: "35px"}} onKeyDown={this._handleKeyDown}></input>
                                                <button className="btn_input_search margin-auto" onClick={() => this.loadCafe24Item()} style={{position: "absolute", right: "0", top: "25%", margin: 'auto'}}></button>
                                            </div>
                                        </div>
                                        <div className="checkbox_v2 mobile-left-34 event_banner_check_flexbox" style={{margin:"auto 0 auto 15px"}}>
                                            <CheckBox
                                                id={"product_sold_out"}
                                                value={(this.state.product_sold_out == "F") ? "T" : "F"}
                                                checked={this.state.product_sold_out == "F"}
                                                onChangeCheck={this.onClickProductSoldOut.bind(this)}
                                            >
                                                <div className="m-l-10">품절 제외</div>
                                            </CheckBox>
                                        </div>
                                        <div className="checkbox_v2 mobile-left-34 event_banner_check_flexbox" style={{margin:"auto 0 auto 15px"}}>
                                            <CheckBox
                                                id={"is_detail_image_use"}
                                                checked={this.state.is_detail_image_use}
                                                onChangeCheck={this.onClickProductIsDetailImageUse.bind(this)}
                                            >
                                                <div className="m-l-10">상세 이미지 사용</div>
                                            </CheckBox>
                                        </div>
                                    </div>
                                    <div className="content_box_radius m-t-21 m-b-20 m-l-34 width-100-34px" ref={scollSelectItemRef}>
                                        <div className="flexBox bannerTypeImg" style={{flex:"1", borderLeft:"0px"}}>
                                            <div className="mobile_category_area" style={{width:"100%"}}>
                                                <div className="item_content_box_category_area_head" style={{display:"flex", flexWrap:"wrap", gap: "12px", width: "100%", height: "100%",  padding: "10px 0px 10px 10px"}}>
                                                        {/* <div style={{display:"flex"}}> 
                                                                <div className="category_name" style={{margin:"auto", cursor:"pointer"}} onClick={e => this.onClickproductHeaderCategory(e, 0, "")}>
                                                                    카테고리
                                                                </div>
                                                                <div className="icn_arrow_v2 m-l-15"></div>
                                                        </div> */}
                                                        <div style={{display:"flex", overflow:"hidden", maxWidth:"70px"}}>
                                                            {this.state.product_heder_category_name_1
                                                            ?   <> 
                                                                <div className="category_name" id={this.state.product_heder_category_no_1} style={{flex: 1, minWidth:"30px", margin:"auto", cursor:"pointer", overflow:"hidden",textOverflow:"ellipsis"}} onClick={e => this.onClickproductHeaderCategoryForMobile(e, 1, "")}>
                                                                    {this.state.product_heder_category_name_1}
                                                                </div>
                                                                <div className="icn_arrow_v2 m-l-15"></div>
                                                                </>
                                                            :   <>
                                                                <select className="select_none_border category_name" 
                                                                                style={{minWidth:"70px", overflow:"hidden",textOverflow:"ellipsis"}}
                                                                                onChange={e => this.selectProductCategory(e, 1)}>
                                                                            <option>
                                                                                전체
                                                                            </option>
                                                                            {this.state.product_category_list.map((category, idx) => (
                                                                                <option value={category.category_no}>
                                                                                {category.category_name}
                                                                                </option>
                                                                            ))}    
                                                                        </select>  
                                                                </>
                                                            }
                                                        </div>

                                                        <div style={{display:"flex", overflow:"hidden", maxWidth:"70px"}}>
                                                            {this.state.product_heder_category_name_2
                                                            ?
                                                                <>
                                                                <div className="category_name" id={this.state.product_heder_category_no_2} style={{flex: 1, margin:"auto", cursor:"pointer", overflow:"hidden",textOverflow:"ellipsis"}} onClick={e => this.onClickproductHeaderCategoryForMobile(e, 2, this.state.product_heder_category_no_1)}>
                                                                    {this.state.product_heder_category_name_2}
                                                                </div>
                                                                <div className="icn_arrow_v2 m-l-15"></div>
                                                                </>
                                                            :   <>
                                                                    {
                                                                        this.state.product_category_list_2
                                                                        ?    <select className="select_none_border category_name" 
                                                                                    style={{minWidth:"70px", overflow:"hidden",textOverflow:"ellipsis"}}
                                                                                    onChange={e => this.selectProductCategory(e, 2)}>
                                                                                <option>
                                                                                    전체
                                                                                </option>
                                                                                {this.state.product_category_list_2.map((category, idx) => (
                                                                                    <option value={category.category_no}>
                                                                                    {category.category_name}
                                                                                    </option>
                                                                                ))}    
                                                                            </select>  
                                                                        : ""
                                                                    }    
                                                                </>    
                                                            }    
                                                        </div>
                                                        <div style={{display:"flex", overflow:"hidden", maxWidth:"70px"}}>
                                                            {this.state.product_heder_category_name_3
                                                            ?
                                                                <>
                                                                <div className="category_name" id={this.state.product_heder_category_no_3} style={{flex: 1, margin:"auto", cursor:"pointer", overflow:"hidden",textOverflow:"ellipsis"}} onClick={e => this.onClickproductHeaderCategoryForMobile(e, 3, this.state.product_heder_category_no_2)}>
                                                                    {this.state.product_heder_category_name_3}
                                                                </div>
                                                                <div className="icn_arrow_v2 m-l-15"></div>
                                                                </>
                                                            :   <>
                                                                    {
                                                                        this.state.product_category_list_3
                                                                        ?    <select className="select_none_border category_name" 
                                                                                    style={{minWidth:"70px", overflow:"hidden",textOverflow:"ellipsis"}}
                                                                                    onChange={e => this.selectProductCategory(e, 3)}>
                                                                                <option>
                                                                                    전체
                                                                                </option>
                                                                                {this.state.product_category_list_3.map((category, idx) => (
                                                                                    <option value={category.category_no}>
                                                                                    {category.category_name}
                                                                                    </option>
                                                                                ))}    
                                                                            </select>
                                                                        : ""
                                                                    }      
                                                                </>    
                                                            }    
                                                        </div>
                                                    
                                                        <div style={{display:"flex", overflow:"hidden", maxWidth:"70px"}}>
                                                            {this.state.product_heder_category_name_4
                                                                ? 
                                                                <div className="category_name" id={this.state.product_heder_category_no_4} style={{flex: 1, margin:"auto", cursor:"pointer", overflow:"hidden"}} onClick={e => this.onClickproductHeaderCategoryForMobile(e, 4, this.state.product_heder_category_no_3)}>
                                                                    {this.state.product_heder_category_name_4}
                                                                </div>
                                                                :   <>
                                                                        {
                                                                            this.state.product_category_list_4
                                                                            ?    <select className="select_none_border category_name" 
                                                                                        style={{minWidth:"70px", overflow:"hidden",textOverflow:"ellipsis"}}
                                                                                        onChange={e => this.selectProductCategory(e, 4)}>
                                                                                    <option>
                                                                                        전체
                                                                                    </option>
                                                                                    {this.state.product_category_list_4.map((category, idx) => (
                                                                                        <option value={category.category_no}>
                                                                                        {category.category_name}
                                                                                        </option>
                                                                                    ))}    
                                                                                </select>  
                                                                            : ""
                                                                        }    
                                                                    </>    
                                                            }
                                                        </div>
                                                </div>
                                            </div>
                                            
                                            
                                            <div style={{display:"flex", width:"100%"}}>
                                                <div className="item_content_box_category_area"> 
                                                    <div className="item_content_box_category_area_head" style={{display:"flex", width: "calc(68vw - 419px)"}}>
                                                        <div style={{display:"flex"}}> 
                                                                <div style={{margin:"auto", cursor:"pointer"}} onClick={e => this.onClickproductHeaderCategory(e, 0, "")}>
                                                                    홈
                                                                </div>
                                                                <div className="icn_arrow_v2 m-l-15"></div>
                                                        </div>
                                                            <div className="m-l-15" style={{display:"flex", overflow:"hidden", maxWidth:"24%"}}>
                                                                {this.state.product_heder_category_name_1
                                                                ?   <> 
                                                                    <div id={this.state.product_heder_category_no_1} style={{flex: 1, margin:"auto", cursor:"pointer", overflow:"hidden",textOverflow:"ellipsis"}} onClick={e => this.onClickproductHeaderCategory(e, 1, this.state.product_heder_category_no_1)}>
                                                                        {this.state.product_heder_category_name_1}
                                                                    </div>
                                                                    <div className="icn_arrow_v2 m-l-15"></div>
                                                                    </>
                                                                : ""    
                                                                }
                                                            </div>

                                                            <div className="m-l-15" style={{display:"flex", overflow:"hidden", maxWidth:"24%"}}>
                                                                {this.state.product_heder_category_name_2
                                                                ?
                                                                    <>
                                                                    <div id={this.state.product_heder_category_no_2} style={{flex: 1, margin:"auto", cursor:"pointer", overflow:"hidden",textOverflow:"ellipsis"}} onClick={e => this.onClickproductHeaderCategory(e, 2, this.state.product_heder_category_no_2)}>
                                                                        {this.state.product_heder_category_name_2}
                                                                    </div>
                                                                    <div className="icn_arrow_v2 m-l-15"></div>
                                                                    </>
                                                                : ""    
                                                                }    
                                                            </div>
                                                            <div className="m-l-15" style={{display:"flex", overflow:"hidden", maxWidth:"24%"}}>
                                                                {this.state.product_heder_category_name_3
                                                                ?
                                                                    <>
                                                                    <div id={this.state.product_heder_category_no_3} style={{flex: 1, margin:"auto", cursor:"pointer", overflow:"hidden",textOverflow:"ellipsis"}} onClick={e => this.onClickproductHeaderCategory(e, 3, this.state.product_heder_category_no_3)}>
                                                                        {this.state.product_heder_category_name_3}
                                                                    </div>
                                                                    <div className="icn_arrow_v2 m-l-15"></div>
                                                                    </>
                                                                : ""    
                                                                }    
                                                            </div>
                                                        
                                                            <div className="m-l-15" style={{display:"flex", overflow:"hidden", maxWidth:"24%"}}>
                                                                {this.state.product_heder_category_name_4
                                                                    ? 
                                                                    <div id={this.state.product_heder_category_no_4} style={{flex: 1, margin:"auto", cursor:"pointer", overflow:"hidden"}} onClick={e => this.onClickproductHeaderCategory(e, 4, this.state.product_heder_category_no_4)}>
                                                                        {this.state.product_heder_category_name_4}
                                                                    </div>
                                                                    : ""    
                                                                }
                                                            </div>
                                                        
                                                    </div>
                                                    <div className="item_content_box_category_area_body">
                                                            <div>
                                                                <div className="item_content_box_category_area_body_content" key="0" id="product_category_all" style={{color:"#10CFC9"}}>
                                                                    전체
                                                                </div>
                                                                {this.state.product_category_list.map((category, idx) => (
                                                                    <div className="item_content_box_category_area_body_content" key={category.category_no} onClick={e => this.onClickProductCategory(e, category.category_name, category.category_no, category.category_depth)}>
                                                                        {category.category_name_no_format}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                    </div>
                                                </div>
                                                <div className="item_content_box_item_area">
                                                    <div className="item_content_box_item_area_head" style={{position:"relative", lineHeight: "18px"}}>
                                                        <div className="flexBox" id="sort_area" style={{position:"absolute", right:"0", justifyContent: "space-between"}}>
                                                            {/* <div className="btn_product_sort_title">정렬기준</div> */}
                                                            {/* <div className="m-l-15 btn_product_sort" style={{height:"20px"}}></div> */}
                                                            <div className="flexBox" id="sort_type">
                                                                { this.state.orderGubun == "created_date" 
                                                                ?   <div className="m-l-10 btn_product_sort_on cursor-pointer" id="created_date sorting" onClick={this.onClickSorting}>등록일</div>
                                                                :   <div className="m-l-10 btn_product_sort cursor-pointer" id="created_date sorting" onClick={this.onClickSorting}>등록일</div>
                                                                }
                                                                { this.state.orderGubun == "price" 
                                                                ?   <div className="m-l-10 btn_product_sort_on cursor-pointer" id="price sorting" onClick={this.onClickSorting}>판매가</div>
                                                                :   <div className="m-l-10 btn_product_sort cursor-pointer" id="price sorting" onClick={this.onClickSorting}>판매가</div>
                                                                }
                                                                { this.state.orderGubun == "hit_cnt" 
                                                                ?   <div className="m-l-10 btn_product_sort_on cursor-pointer" id="hit_cnt sorting" onClick={this.onClickSorting}>조회수</div>
                                                                :   <div className="m-l-10 btn_product_sort cursor-pointer" id="hit_cnt sorting" onClick={this.onClickSorting}>조회수</div>
                                                                }
                                                                { this.state.orderGubun == "sale_cnt" 
                                                                ?   <div className="m-l-10 btn_product_sort_on cursor-pointer" id="sale_cnt sorting" onClick={this.onClickSorting}>구매수</div>
                                                                :   <div className="m-l-10 btn_product_sort cursor-pointer" id="sale_cnt sorting" onClick={this.onClickSorting}>구매수</div>
                                                                }
                                                                { this.state.orderGubun == "review_cnt" 
                                                                ?   <div className="m-l-10 btn_product_sort_on cursor-pointer" id="review_cnt sorting" onClick={this.onClickSorting}>리뷰수</div>
                                                                :   <div className="m-l-10 btn_product_sort cursor-pointer" id="review_cnt sorting" onClick={this.onClickSorting}>리뷰수</div>
                                                                }
                                                            </div>
                                                            { this.state.sorting == "ASC"
                                                            ?   <div className="flexBox">
                                                                    <div className="m-l-15 btn_sort_arrow_down cursor-pointer" onClick={e => this.onClickSortingArrow(e, "DESC")}></div>
                                                                    <div className="m-l-10 btn_sort_arrow_up_b cursor-pointer" onClick={e => this.onClickSortingArrow(e, "ASC")}></div>
                                                                </div>
                                                            :   <div className="flexBox">
                                                                    <div className="m-l-15 btn_sort_arrow_down_b cursor-pointer" onClick={e => this.onClickSortingArrow(e, "DESC")}></div>
                                                                    <div className="m-l-10 btn_sort_arrow_up cursor-pointer" onClick={e => this.onClickSortingArrow(e, "ASC")}></div>
                                                                </div> 
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="item_content_box_item_area_body">
                                                        <div id="product_four_grid" className="four_grid">
                                                            <div id="non_product_msg">상품이 존재하지 않습니다.</div>    
                                                            <div id="loding_product_msg">상품을 불러오는 중입니다.</div>    
                                                            {this.state.item_list.map((item, idx) => (
                                                                <div key={idx} className="item_content_box_item_area_body_content" style={{position:"relative", marginBottom:"20px"}}>
                                                                    <RadioButton
                                                                        id={"select_item_radio_"+item.product_no}
                                                                        name={"select_item_radio"}
                                                                        checked={this.state.select_item_list && this.state.select_item_list.length> 0 && this.state.select_item_list[0].product_no == item.product_no}
                                                                        onChangeCheck={(e) => this.onClickModalTableItem(e, item)}
                                                                        className={"event_radio_img_area"}
                                                                    />
                                                                    {
                                                                        item.sold_out == "T"
                                                                        ?   <div style={{position:"absolute", top:"6px", right:"7px", background:"#404040", color:"white", zIndex:"999", borderRadius:"5px", padding:"0 3px 0 3px"}}>
                                                                            품절
                                                                            </div>
                                                                        :   ""    
                                                                    }
                                                                    <div className="img_area" style={{flex:"1", background: "url("+
                                                                        (this.state.is_detail_image_use == true ? item.detail_image : item.image)
                                                                    +") center / cover", borderRadius:"13px", zIndex:"100"}}>
                                                                    </div>    
                                                                    <div id="non_img_area" style={{width: "100%", height: "calc(100% - 88px)", border:"1.3px solid #7F828A",  borderRadius:"13px", position:"absolute", top:"0",textAlign:"center", background: "url('https://img.otdeal.net/trend/noimg.gif') center", backgroundSize:"cover"}}>
                                                                    </div>    
                                                                    <div style={{fontSize:"15px"}}>
                                                                        <div className="flexBox" style={{alignItems: "center"}}>
                                                                            <div style={{fontSize:"20px"}}>
                                                                                {item.retail_price}
                                                                            </div>
                                                                            <div>
                                                                                원
                                                                            </div>       
                                                                        </div>
                                                                        <div dangerouslySetInnerHTML={{__html: item.product_name}} style={{width:"100%", height:"22px", overflow:"hidden", textOverflow: "ellipsis"}}>
                                                                        </div>    
                                                                        <div className="flexBox" id="product_cnt_area" style={{height:"18px", overflow:"hidden", marginTop:"17px"}}>
                                                                            <div id="review_cnt" style={{flex:"1"}}>조회수 : {item.hit_cnt}</div>
                                                                            <div id="order_cnt" style={{flex:"1"}}>구매수 : {item.sale_cnt}</div>
                                                                        </div>    
                                                                    </div>    
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="content_box_radius_paging">
                                            <div style={{lineHeight:"60px"}}>
                                                <Pagination
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={this.state.rowcnt}
                                                totalItemsCount={this.state.totalCnt}
                                                pageRangeDisplayed={12}
                                                onChange={this.handlePageChange.bind(this)}
                                                />                    
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flexBox">
                                        { this.state.select_item_list && this.state.select_item_list.length > 0
                                        ?   <>
                                            <div className="two-four-division_area">
                                                <div className="choose_box_title eventSettingTitle m-l-34">
                                                    <label style={{marginTop: "auto", marginBottom: "auto", color:"#333333"}}>선택된 상품을 확인해 주세요.</label>
                                                </div>
                                                <div className="flexBox">
                                                    <div className="banner_choose_box content_box_radius m-l-34 flexBox">
                                                        {/* <div style={{width:"50%", height:"100%", background:"url('"+this.state.event_mobile_type_img+"') center", backgroundSize:"cover"}}></div> */}
                                                        <img src={this.state.is_detail_image_use ? this.state.select_item_list[0].detail_image : this.state.select_item_list[0].image} style={{width:"40%", height:"auto", alignSelf: "flex-start"}} referrerpolicy="no-referrer"></img>
                                                        <div className="flexBox" style={{flexDirection:"coulnm", width:"calc(60% - 1vw)", height:"auto", alignSelf: "flex-start", margin:"0 0.5vw"}}>
                                                            <div style={{overflow:"hidden", height: "calc(100% - 18px)"}}><b>{this.state.select_item_list[0].product_name}</b></div>
                                                            <div style={{}}><b>{this.state.select_item_list[0].retail_price}원</b></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </>
                                        :   ""
                                        }
                                    </div>
                                </>
                            }
                        </div>
                        {this.state.solution_type_cd != "09900" &&
                            <div className="m-t-43 m-l-29">
                                <div className="flexBox"> 
                                    <div className="icn_round_blue_small"></div>
                                    &nbsp;&nbsp;
                                    <div className="form_title" style={{marginTop: "auto", marginBottom: "auto"}}>선택된 상품 품절 시 종료 여부</div>
                                </div>
                                <div className="redio_v2 m-l-34 m-t-32 event_content_radio">
                                    {
                                        [{type:"N", text:"품절시 자동 종료"},{type:"Y", text:"품절 되어도 종료하지 않음"}].map((obj, idx) => (
                                            <RadioButton
                                                id={"is_soldout_display"+obj.type}
                                                name={"is_soldout_display"}
                                                value={obj.type=="Y" ? "Y" : "N"}
                                                checked={this.state.is_soldout_display == obj.type}
                                                onChangeCheck={this.onClickSoldOutDisplay.bind(this)}
                                            >
                                                <div className="banner_radio_text">{obj.text}</div>
                                            </RadioButton>
                                        ))
                                    }
                                </div>
                            </div>
                        }
                        <div className="m-t-43 m-l-29">
                            <div className="flexBox"> 
                                <div className="icn_round_blue_small"></div>
                                &nbsp;&nbsp;
                                <div className="form_title" style={{marginTop: "auto", marginBottom: "auto"}}>연결 링크를 설정해 주세요.</div>
                            </div>
                            <div className="redio_v2 m-l-34 m-t-32 event_content_radio event_link_radio">
                                {(this.state.pc_banner_ratio_list.length > 0 && this.state.w_is_button_use == "Y") && (this.state.mobile_banner_ratio_list.length > 0 && this.state.h_is_button_use == "Y")
                                    || (this.state.h_is_button_use == "Y" && this.state.pc_banner_ratio_list.length == 0) || (this.state.w_is_button_use == "Y" && this.state.mobile_banner_ratio_list.length == 0)
                                ? ""
                                :<>
                                    <RadioButton
                                        id={"is_link_url_N"}
                                        name={"is_link_url"}
                                        value={"N"}
                                        checked={this.state.is_link_url == "N"}
                                        onChangeCheck={this.onClickIsLinkUrl.bind(this)}
                                    >
                                        <label className="banner_radio_text">선택된 상품 페이지로 이동 </label>
                                    </RadioButton>
                                    <RadioButton
                                        id={"is_link_url_Y"}
                                        name={"is_link_url"}
                                        value={"Y"}
                                        checked={this.state.is_link_url == "Y"}
                                        onChangeCheck={this.onClickIsLinkUrl.bind(this)}
                                    >
                                        <div className="banner_radio_text" style={{margin: "-19px 0 auto 14px"}}>
                                            <div className="input_text_title_d">URL 직접 입력하기</div>
                                            <input className="border_form_radius_b input_width_100 input_height_35px" type="text" value={this.state.link_url} onChange={e => this.onChageLinkUrl(e)}/>
                                        </div>
                                    </RadioButton>
                                </>
                                }
                                {(this.state.pc_banner_ratio_list.length > 0 && this.state.w_is_button_use == "Y") || (this.state.mobile_banner_ratio_list.length > 0 && this.state.h_is_button_use == "Y")
                                ? <div className="margin-vertical-auto flexBox m-t-15">
                                    <div className="icn_warning_round"></div>
                                    <div style={{marginLeft:"10px", flex:"1"}}>
                                        버튼형 템플릿의 연결 링크 설정은 <font className="go_event_button_setting_area" onClick={() => scollSettingBtnRef.current.scrollIntoView({block:"center"})}>버튼설정</font> 영역에서 수정할 수 있습니다.
                                    </div>
                                </div>
                                :""}
                            </div>
                        </div>        
                        <div id="date_setting_title" className="form_primary_title m-t-43">
                            예약일정 및 노출일정
                        </div>
                        <div className="reservation-schedule-flex">
                            <div className="m-l-29">
                                {/* <div className="flexBox"> 
                                    <div className="icn_round_blue_small"></div>
                                    &nbsp;&nbsp;
                                    <div className="form_title" style={{marginTop: "auto", marginBottom: "auto"}}>간편 설정</div>
                                </div> */}
                                <div className="redio_v2 m-t-32 event_content_radio">
                                    {this.state.simple_date_setting_list.map((obj, idx) => (
                                        <RadioButton
                                            id={"simple_date_cd"+obj.code_cd}
                                            name={"simple_date_cd"}
                                            value={obj.code_cd}
                                            checked={this.state.simple_date_cd == obj.code_cd}
                                            onChangeCheck={this.onClickSimpleDateCd.bind(this)}
                                        >
                                            <label className="banner_radio_text">{obj.val2}</label>
                                        </RadioButton>
                                    ))}
                                </div>
                            </div>
                            <div className="m-l-29 my-banner-create-datepicker">
                                {/* <div className="flexBox"> 
                                    <div className="icn_round_blue_small"></div>
                                    &nbsp;&nbsp;
                                    <div id="date_setting" className="form_title" style={{marginTop: "auto", marginBottom: "auto"}}>일정 설정</div>
                                </div> */}
                                <div className="input-daterange input-group" id="datepicker">
                                    <div>
                                        <div className="input_text_title_d eventSettingTitle" style={{zIndex:"1"}}>
                                            <label style={{marginTop: "auto", marginBottom: "auto"}}>시작일</label>
                                        </div>
                                        <div className="mobile_width_30vw">
                                            <DatePicker name="start_date" id="start_date" className="border_form_radius_b mobile_width_30vw"
                                                selected={this.state.start_date}
                                                onSelect={this.handleSelect} //when day is clicked
                                                onChange={this.handleChangeStartDate} //only when value has changed
                                                placeholderText="시작일자"
                                                todayButton="오늘"
                                                dateFormat="yyyy-MM-dd"
                                                maxDate={new Date("9999-12-31")}
                                                autoComplete='off'
                                                showYearDropdown
                                                yearDropdownItemNumber={15}
                                                scrollableYearDropdown
                                                locale={ko}
                                                disabled={this.state.disabledStartDate}
                                            />
                                            
                                        </div>
                                    </div>
                                    <div>
                                        <div className="input_text_title eventSettingTitle" style={{zIndex:"999", left:"40px"}}>
                                            <label style={{marginTop: "auto", marginBottom: "auto"}}></label>
                                        </div>
                                        <div className="mobile_width_20vw">
                                            <select id="start_hour" className="border_form_radius_b" style={{width:"150px", padding:"19.6px 20px !important", marginLeft:"10px", backgroundPosition: "90% 50%"}} value={this.state.start_hour} onChange={e => this.onChangeDateSetting(e, "start_hour")} disabled={this.state.disabledStartDate}>
                                            <option value={""} disabled>
                                                    시
                                            </option>
                                            {this.state.hour_list.map((hour, idx) => (
                                                <option key={idx} value={hour.key}>
                                                    {hour.hour}
                                                </option>
                                            ))}    
                                            </select>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="input_text_title eventSettingTitle" style={{zIndex:"999", left:"40px"}}>
                                            <label style={{marginTop: "auto", marginBottom: "auto"}}></label>
                                        </div>
                                        <div className="mobile_width_20vw">
                                            <select id="start_minute" className="border_form_radius_b" style={{width:"150px", padding:"19.6px 20px !important", marginLeft:"10px", backgroundPosition: "90% 50%"}} value={this.state.start_minute} onChange={e => this.onChangeDateSetting(e, "start_minute")} disabled={this.state.disabledStartDate}>
                                            <option value={""} disabled>
                                                    분
                                            </option>
                                            {this.state.minute_list.map((minute, idx) => (
                                                <option key={idx} value={minute.key}>
                                                    {minute.minute}
                                                </option>
                                            ))}    
                                            </select>
                                        </div>
                                    </div>
                                    <div className="input-group-addon mobile-input-group-addon" style={{width: "40px",height:"35px", marginTop: "44px",textAlign:"center"}}>~</div>
                                    {this.state.simple_date_cd == "17005"
                                    ? ""
                                    :<div className="flexBox" id="end_date_area">
                                        <div>
                                            <div className="input_text_title_d eventSettingTitle" style={{zIndex:"1"}}>
                                                <label style={{marginTop: "auto", marginBottom: "auto"}}>종료일</label>
                                            </div>
                                            <div className="mobile_width_30vw">
                                                <DatePicker name="end_date" id="end_date" className="border_form_radius_b mobile_width_30vw"
                                                    selected={this.state.end_date}
                                                    onSelect={this.handleSelect} //when day is clicked
                                                    onChange={this.handleChangeEndDate} //only when value has changed
                                                    placeholderText="종료일자"
                                                    todayButton="오늘"
                                                    dateFormat="yyyy-MM-dd"
                                                    maxDate={new Date("9999-12-31")}
                                                    autoComplete='off'
                                                    showYearDropdown
                                                    yearDropdownItemNumber={15}
                                                    scrollableYearDropdown
                                                    locale={ko}
                                                    disabled={this.state.disabledEndDate}
                                                />
                                            </div>	
                                        </div>
                                        <div>
                                            <div className="input_text_title eventSettingTitle" style={{zIndex:"999", left:"40px"}}>
                                                <label style={{marginTop: "auto", marginBottom: "auto"}}></label>
                                            </div>
                                            <div className="mobile_width_20vw">
                                                <select id="end_hour" className="border_form_radius_b" style={{width:"150px", padding:"19.6px 20px !important", marginLeft:"10px", backgroundPosition: "90% 50%"}} value={this.state.end_hour} onChange={e => this.onChangeDateSetting(e, "end_hour")} disabled={this.state.disabledEndDate}>
                                                <option value={""} disabled>
                                                        시
                                                </option>
                                                {this.state.hour_list.map((hour, idx) => (
                                                    <option key={idx} value={hour.key}>
                                                        {hour.hour}
                                                    </option>
                                                ))}    
                                                </select>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="input_text_title eventSettingTitle" style={{zIndex:"999", left:"40px"}}>
                                                <label style={{marginTop: "auto", marginBottom: "auto"}}></label>
                                            </div>
                                            <div className="mobile_width_20vw">
                                                <select id="end_minute" className="border_form_radius_b" style={{width:"150px", padding:"19.6px 20px !important", marginLeft:"10px", backgroundPosition: "90% 50%"}} value={this.state.end_minute} onChange={e => this.onChangeDateSetting(e, "end_minute")} disabled={this.state.disabledEndDate}>
                                                <option value={""} disabled>
                                                        분
                                                </option>
                                                {this.state.minute_list.map((minute, idx) => (
                                                    <option key={idx} value={minute.key}>
                                                        {minute.minute}
                                                    </option>
                                                ))}    
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        
                        <div className="m-t-43 m-l-29">
                            <div className="flexBox"> 
                                <div className="icn_round_blue_small"></div>
                                &nbsp;&nbsp;
                                <div className="form_title" style={{marginTop: "auto", marginBottom: "auto", cursor: "pointer", textDecoration:"underline"}}
                                onClick={e => this.onClickShowCalendar()}>달력 보기</div>
                            </div>
                            <div className="m-t-32 m-l-34 fullCalendarArea" id="calendar_div" style={{display : "none"}}>
                                <FullCalendar
                                    timeZone= 'local'
                                    plugins={[ dayGridPlugin ]}
                                    initialView="dayGridMonth"
                                    locale='ko'
                                    events={this.state.fullCalendarEventList}
                                    eventClick={this.onClickeFullCalendarEvent}
                                    contentHeight='auto'
                                />
                            </div>
                        </div>

                        <div className="form_primary_title m-t-43">
                            템플릿 설정
                        </div>
                        <div className="m-t-43 m-l-29">
                            <div className="flexBox" style={{margin:"20px 20px 0px 0px", minWidth:"150px"}}> 
                                <div className="icn_round_blue_small"></div>
                                &nbsp;&nbsp;
                                <div className="form_title" style={{marginTop: "auto", marginBottom: "auto"}}>
                                    {`배너 사이즈 ${!this.state.single_size ? "1" : ""}`}
                                </div>
                            </div>
                            <div class="m-l-34" className={"event_input_banner_size_area"}>
                                <div style={{flex:1}}>
                                    <div className="input_text_title_d">가로</div>
                                        <input id="input_event_banner_pc_width" type="text" className="border_form_radius_b f-s-15 input_width_100 input_height_35px" value={this.state.event_banner_pc_width} onChange={e => this.onChangeInputBannerSize(e, "pc", "event_banner_pc_width")} onKeyPress={e => this.onChangeInputBannerSize(e, "pc", "event_banner_pc_width")} onBlur={() => this.onBlurInputBannerSize("pc", "event_banner_pc_width")} maxlength="4"></input>
                                </div>
                                <div class="btn_close_v2" style={{fontSize:"30px", margin: "15px 20px 0px"}}></div>
                                <div style={{flex:1}}>
                                    <div className="input_text_title_d">세로</div>
                                        <input id="input_event_banner_pc_height" type="text" className="border_form_radius_b f-s-15 input_width_100 input_height_35px" value={this.state.event_banner_pc_height} onChange={e => this.onChangeInputBannerSize(e, "pc", "event_banner_pc_height")} onKeyPress={e => this.onChangeInputBannerSize(e, "pc", "event_banner_pc_height")} onBlur={() => this.onBlurInputBannerSize("pc", "event_banner_pc_height")} maxlength="4"></input>
                                </div>
                                <div className={"btn_area"}>
                                    {/* 미리보기 버튼 */}
                                    <Icon text={"미리보기"} onClick={() => this.onClickMirrorBannerOn("pc")}>
                                        <img src={preview_icon} style={{width:"28px", height:"28px"}}/>
                                    </Icon>
                                    {/* 다운로드 버튼 */}
                                    <Icon text={"다운로드"} onClick={() => {this.downloadBannerRightAwayOnClick("pc");this.onClickMirrorBannerOn("pc")}}>
                                        {this.state.is_download_pc ?
                                            <img src={icn_download} />
                                            :
                                            <div className="list_loading" style={{margin: "0 auto"}}>
                                                <span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
                                            </div>
                                        }
                                    </Icon>
                                </div>
                            </div>
                        </div>
                        {!this.state.single_size &&
                            <div className="m-t-43 m-l-29">
                                <div className="flexBox" style={{margin:"20px 20px 0px 0px", minWidth:"150px"}}> 
                                    <div className="icn_round_blue_small"></div>
                                    &nbsp;&nbsp;
                                    <div className="form_title" style={{marginTop: "auto", marginBottom: "auto"}}>
                                        배너 사이즈 2
                                    </div>
                                </div>
                                <div class="m-l-34" className={"event_input_banner_size_area"}>
                                    <div style={{flex:1}}>
                                        <div className="input_text_title_d">가로</div>
                                            <input id="input_event_banner_mobile_width" type="text" className="border_form_radius_b f-s-15 input_width_100 input_height_35px" value={this.state.event_banner_mobile_width} onChange={e => this.onChangeInputBannerSize(e, "mobile", "event_banner_mobile_width")} onKeyPress={e => this.onChangeInputBannerSize(e, "mobile", "event_banner_mobile_width")} onBlur={() => this.onBlurInputBannerSize("mobile", "event_banner_mobile_width")} maxlength="4"></input>
                                    </div>
                                    <div class="btn_close_v2" style={{fontSize:"30px", margin: "15px 20px 0px"}}></div>
                                    <div style={{flex:1}}>
                                        <div className="input_text_title_d">세로</div>
                                            <input id="input_event_banner_mobile_height" type="text" className="border_form_radius_b f-s-15 input_width_100 input_height_35px" value={this.state.event_banner_mobile_height} onChange={e => this.onChangeInputBannerSize(e, "mobile", "event_banner_mobile_height")} onKeyPress={e => this.onChangeInputBannerSize(e, "mobile", "event_banner_mobile_height")} onBlur={() => this.onBlurInputBannerSize("mobile", "event_banner_mobile_height")} maxlength="4"></input>
                                    </div>
                                    <div className={"btn_area"}>
                                        {/* 미리보기 버튼 */}
                                        <Icon text={"미리보기"} onClick={() => this.onClickMirrorBannerOn("mobile")}>
                                            <img src={preview_icon} style={{width:"28px", height:"28px"}}/>
                                        </Icon>
                                        {/* 다운로드 버튼 */}
                                        <Icon text={"다운로드"} onClick={() => {this.downloadBannerRightAwayOnClick("mobile");this.onClickMirrorBannerOn("mobile")}}>
                                            {this.state.is_download_mobile ?
                                                <img src={icn_download} />
                                                :
                                                <div className="list_loading" style={{margin: "0 auto"}}>
                                                    <span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
                                                </div>
                                            }
                                        </Icon>
                                    </div>
                                </div>
                            </div>
                        }
                        { 
                            true
                            ? <> 
                                {
                                    this.state.pc_banner_ratio_list.length > 0
                                    ?   <div className="m-t-43 m-l-29">
                                            <div className="flexBox"> 
                                                <div className="icn_round_blue_small"></div>
                                                &nbsp;&nbsp;
                                                <div className="form_title" style={{marginTop: "auto", marginBottom: "auto"}}>
                                                    가로형 배너 디자인을 선택해 주세요.
                                                </div>
                                            </div>
                                            {/* 템플릿 필터 start */}
                                            <div className={"template_tag_filter"}>
                                                <button className={"btn_template_filter"} onClick={() => this.handleTemplateFilterBtnClick("03000")}>
                                                    <img src={icn_filter} alt={"템플릿 태그 필터 아이콘"}/>
                                                </button>
                                                <div className={"template_tag_list"}>
                                                    <div onMouseLeave={this.handleDragStop}>
                                                        <ScrollMenu
                                                            handleMouseOnWheel={(apiObj, ev) => this.handleMouseOnWheel(apiObj, ev)}
                                                            onMouseDown={() => this.handleDragStart}
                                                            onMouseUp={() => this.handleDragStop}
                                                            onMouseMove={this.handleDrag}
                                                        >
                                                            <button style={this.state.w_all_tag_selected ? {backgroundColor:'#00E2E6', border: '1px solid #00E2E6'} : {backgroundColor: '#FFF', border: '1px solid #ACACAC'}}
                                                                    onClick={() => this.allTagBtnClick("03000", "w")}>{this.state.template_tag_list ? this.state.template_tag_list[0].tag_name : ""}</button>
                                                            {
                                                                Object.keys(this.state.w_template_all_tag_list).map((category, tag_idx) => (
                                                                    this.state.w_template_all_tag_list[category].map((tag)=>(
                                                                        <TagButton key={tag.tag_cd} tagCd={tag.tag_cd} tagName={tag.tag_name}
                                                                                   isSelected={tag.isSelected}
                                                                                   onClick={(tagCd, isSelected) => this.tagBtnClick(category, tag.tag_cd, isSelected, "03000", "w")}/>
                                                                    ))
                                                                ))
                                                            }
                                                        </ScrollMenu>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* 템플릿 필터 end */}
                                            <div className="container_banner_template m-t-21 m-b-20 width-100-34px">
                                                <div className="flexBox bannerTypeImg" style={{flex:"1", borderLeft:"0px"}}>
                                                    <div className="flexBox" style={{width:"100%"}}>
                                                        {this.state.bannerTypeList == null || this.state.bannerTypeList.length == 0
                                                            ?
                                                            <div className={"no_banner_template"}>
                                                                <div>
                                                                    <p>해당 템플릿 디자인이 없어요.<br/>다른 디자인으로 선택해 볼까요?</p>
                                                                    <button onClick={() => this.resetTemplateFilterBtnClick("03000", "w")}>
                                                                        <img src={icn_refresh_2} alt={"전체 다시보기"}/>
                                                                        &nbsp;&nbsp;전체 다시보기
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            :
                                                            this.state.bannerTypeList.map((bannerType,idx) => (
                                                            <div key={idx} className="banner-inner-v2 mobile-width-100" style={{margin: "0px 0.82vw 30px 0", width: "calc((99% - 3.5vw) / 5)", cursor: "pointer"}}
                                                                 onClick={() => this.onClickBannerTypeRadioButton(bannerType, "event_type_cd", "event_pc_banner_name")}>
                                                                {/*<RadioButton*/}
                                                                {/*    id={"select_event_type_cd_"+bannerType.code_cd}*/}
                                                                {/*    name={"select_event_type_cd"}*/}
                                                                {/*    checked={this.state.event_type_cd == bannerType.code_cd}*/}
                                                                {/*    onChangeCheck={() => this.onClickBannerTypeRadioButton(bannerType, "event_type_cd")}*/}
                                                                {/*    className={"event_radio_img_area"}*/}
                                                                {/*/>*/}
                                                                <img src={bannerType.val2} width="100%" height="auto"
                                                                     style={{margin:"auto", border: this.state.event_type_cd == bannerType.code_cd ? "4px solid #00E2E6" : "1px solid #D9D9D9", borderRadius: "13px"}}/>
                                                                <div className={"title_banner_template"} style={{fontWeight: this.state.event_type_cd == bannerType.code_cd ? "500" : "400"}}>{bannerType.banner_name}</div>
                                                                <div className={"btn_banner_template_tag"}>
                                                                    {(bannerType.tag_name_list || "").toString() != "" && (bannerType.tag_name_list || "").toString().split(",").map((tag_name) => (
                                                                        <button key={tag_name}>{tag_name.trim()}</button>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        ))}
                                                        {/* 가로형 테스트 배너 */}
                                                        {/* <div className="banner-inner-v2 mobile-width-100" style={{margin: "0px 0.82vw 13px 0", width: "calc((99% - 3.4vw) / 5)"}}>
                                                            <RadioButton
                                                                id={"select_event_type_cd_test_"+"03122"}
                                                                name={"select_event_type_cd"}
                                                                checked={this.state.event_type_cd == "03122"}
                                                                onChangeCheck={() => this.onClickBannerTypeRadioButton({
                                                                    code_cd : "03122",
                                                                    val1 : "테스트 형",
                                                                    val2 : "https://s3.ap-northeast-2.amazonaws.com/otdeal.file/banner_sample_230310/03122.png",
                                                                }, "event_type_cd")}
                                                                className={"event_radio_img_area"}
                                                            />
                                                            <img src={"https://s3.ap-northeast-2.amazonaws.com/otdeal.file/banner_sample_230310/03122.png"} width="100%" height="auto" style={{margin:"auto auto auto auto", border:"0.5px solid dimgray"}}></img>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                {this.state.bannerTypeList == null || this.state.bannerTypeList.length == 0 ? <></>
                                                    :
                                                    <div className="container_banner_template_paging">
                                                        <div style={{lineHeight:"60px"}}>
                                                            <Pagination
                                                                activePage={this.state.pc_banner_type_paging.activePage}
                                                                itemsCountPerPage={this.state.pc_banner_type_paging.rowcnt}
                                                                totalItemsCount={this.state.pc_banner_type_paging.totalCnt}
                                                                pageRangeDisplayed={10}
                                                                onChange={this.handleBannerPageChange.bind(this)}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="flexBox">
                                                { this.state.event_type_cd != null && this.state.event_type_cd != "null" && this.state.event_type_cd != ""
                                                ?   <>
                                                        <div className="two-four-division_area">
                                                            {/*<div className="choose_box_title eventSettingTitle m-l-34">*/}
                                                            {/*    <label style={{marginTop: "auto", marginBottom: "auto", color:"#333333"}}>선택한 배너 디자인</label>*/}
                                                            {/*</div>*/}
                                                            <div className="current_banner_template">
                                                                <h5>선택한 템플릿</h5>
                                                                {/* <div style={{width:"50%", height:"100%", background:"url('"+this.state.event_mobile_type_img+"') center", backgroundSize:"cover"}}></div> */}
                                                                <img src={this.state.event_type_img}/>
                                                                {/*<label style={{margin:"auto"}}><b>{this.state.event_type_name}</b></label>*/}
                                                                <h6>{this.state.event_pc_banner_name}</h6>
                                                                <div>
                                                                    {this.state.event_pc_tag_name_list != "" && this.state.event_pc_tag_name_list.map(tag_name => (
                                                                        <button key={tag_name}>{tag_name.trim()}</button>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            {/*<div style={{marginTop:"20px", paddingRight : "38px", fontSize:"14px"}}>*/}
                                                            {/*    <div className="margin-vertical-auto flexBox m-l-34 m-t-21">*/}
                                                            {/*        <div className="icn_warning_round"></div>*/}
                                                            {/*        <div style={{marginLeft:"10px", flex:"1"}}>상세페이지 중 마음에 드는 이미지로 업로드 할 수 있어요.</div>*/}
                                                            {/*    </div>*/}
                                                            {/*    <div className="margin-vertical-auto flexBox m-l-34 m-t-21">*/}
                                                            {/*        <div className="icn_warning_round"></div>*/}
                                                            {/*        <div style={{marginLeft:"10px", flex:"1"}}>단위를 선택한 후, 화살표 버튼을 클릭하거나 숫자를 적어서 이미지 위치를 옮겨 보세요.</div>*/}
                                                            {/*    </div>*/}
                                                            {/*    <div className="margin-vertical-auto flexBox m-l-34 m-t-21">*/}
                                                            {/*        <div className="icn_warning_round"></div>*/}
                                                            {/*        <div style={{marginLeft:"10px", flex:"1"}}>AI가 원본 이미지의 보기 싫은 배경을 제거해 드려요. 원치 않으시는 경우, AI 배경 제거 기능을 꺼 주세요.</div>*/}
                                                            {/*    </div>*/}
                                                            {/*    <div className="margin-vertical-auto flexBox m-l-34 m-t-21">*/}
                                                            {/*        <div className="icn_warning_round"></div>*/}
                                                            {/*        <div style={{marginLeft:"10px", flex:"1"}}>AI가 원본 이미지와 어울리는 배경색을 추천해줘요.</div>*/}
                                                            {/*    </div>*/}
                                                            {/*</div>*/}
                                                        </div>

                                                        <div className={"event_img_setting_area"}>
                                                            {this.state.w_img_position_name.map((w_position, idx) => (
                                                                <>
                                                                    {this.state["w_img" + (idx + 1) + "_is_use"] == "Y"
                                                                        ? <div className="two-four-division_area"
                                                                               style={{
                                                                                   minWidth: "280px",
                                                                                   maxWidth: "280px",
                                                                                   marginLeft: "10px"
                                                                               }}>
                                                                            <div
                                                                                className="choose_box_title eventSettingTitle m-l-34">
                                                                                <label style={{
                                                                                    marginTop: "auto",
                                                                                    marginBottom: "0px",
                                                                                    color: "#333333"
                                                                                }}>{(idx == "0" ? "첫" : idx == "1" ? "두" : idx == "2" ? "세" : "네") + "번째 이미지 편집"}</label>
                                                                            </div>
                                                                            <div className="flexBox">
                                                                                <div
                                                                                    className="content_box_radius m-l-34 flexBox"
                                                                                    style={{width: "auto"}}>
                                                                                    <div
                                                                                        className="flexBox event_img_edit_area">
                                                                                        {this.state.solution_type_cd != "09900" // 연동형인 경우에만 상품 선택 영역 표시
                                                                                            ? <div
                                                                                                className={"event_img_preview_area"}
                                                                                                onClick={() => {
                                                                                                    if (this.state.select_item_list.length > 0) {
                                                                                                        let item_img = (this.state.is_detail_image_use == true && this.state.select_item_list[0].detail_image) ? this.state.select_item_list[0].detail_image : this.state.select_item_list[0].image ? this.state.select_item_list[0].image : "";
                                                                                                        if(this.positionImgCheck("w", idx) && !this.positionLinkTypeImgCheck("w", idx) && (this.state[w_position.img_column_name] == item_img)){
                                                                                                            return false;
                                                                                                        }
                                                                                                        this.deleteUploadImg("w", idx);
                                                                                                    } else {
                                                                                                        this.showReqSelectItem();
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                {/* 기본 안내 이미지 */}
                                                                                                <div
                                                                                                    className={"default_img"}>
                                                                                                    <img
                                                                                                        src={select_item_icon}/>
                                                                                                    <div>상품 이미지</div>
                                                                                                </div>
                                                                                                {/* 사용중인 이미지 */}
                                                                                                {(this.positionImgCheck("w", idx) && !this.positionLinkTypeImgCheck("w", idx) && this.state.select_item_list.length > 0 && !(this.state.w_is_youtube_link_use == "Y" && this.state.w_user_img_list[idx].link_type == "49005" && this.state.w_user_img_list[idx].video_link))
                                                                                                    ? <img
                                                                                                        className={"use_img"}
                                                                                                        src={this.state[w_position.img_column_name]}
                                                                                                    />
                                                                                                    : ""}
                                                                                            </div>
                                                                                            : ""}

                                                                                        <div
                                                                                            className={"event_img_preview_area" + (this.state.solution_type_cd == "09900" ? " event_img_preview_area_non_item" : "")}
                                                                                        >
                                                                                            <input type="file"
                                                                                                   id={"w_banner_img_upload_" + idx}
                                                                                                   onChange={e => this._handleImageChange(e, idx, "w", "49001")}
                                                                                                   accept="image/*"
                                                                                                   value=""
                                                                                                   style={{display: "none"}}
                                                                                                   tabIndex="-1"></input>
                                                                                            <label
                                                                                                htmlFor={"w_banner_img_upload_" + idx}
                                                                                                style={{display: "contents"}}>
                                                                                                <div
                                                                                                    className={"default_img"}>
                                                                                                    <img
                                                                                                        src={file_upload_icon}/>
                                                                                                    <div>파일 선택</div>
                                                                                                </div>
                                                                                                {this.state.w_user_img_list[idx].link_type == "49001" && this.state.w_user_img_list[idx].img_link
                                                                                                    ? <img
                                                                                                        className={"use_img"}
                                                                                                        src={this.state.w_user_img_list[idx].img_link}
                                                                                                    />
                                                                                                    : ""}
                                                                                            </label>
                                                                                        </div>

                                                                                        <div
                                                                                            className={"event_img_preview_area" + (this.state.solution_type_cd == "09900" ? " event_img_preview_area_non_item" : "")}
                                                                                            onClick={() => this.openImgLinkUrlPopup("w", idx, "49002")}
                                                                                        >
                                                                                            <div
                                                                                                className={"default_img"}>
                                                                                                <img
                                                                                                    src={link_upload_icon}/>
                                                                                                <div>이미지 주소</div>
                                                                                            </div>
                                                                                            {this.state.w_user_img_list[idx].link_type == "49002" && this.state.w_user_img_list[idx].img_link
                                                                                                ? this.state.w_user_img_list[idx].img_link_able
                                                                                                    ? <img
                                                                                                        className={"use_img"}
                                                                                                        src={this.state.w_user_img_list[idx].img_link}
                                                                                                    />
                                                                                                    : <div
                                                                                                        className={"use_img disabled_img"}>
                                                                                                        이미지를<br/>불러올
                                                                                                        수<br/>없습니다.
                                                                                                    </div>
                                                                                                : ""
                                                                                            }
                                                                                        </div>

                                                                                        <div
                                                                                            className={"event_img_preview_area" + (this.state.solution_type_cd == "09900" ? " event_img_preview_area_non_item" : "")}
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    select_detail_crop_img_popup_show: true,
                                                                                                    select_img_type: "w",
                                                                                                    select_img_idx: idx,
                                                                                                });
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                className={"default_img"}>
                                                                                                <img
                                                                                                    src={detail_page_icon}/>
                                                                                                <div>상세페이지<br/>자르기</div>
                                                                                                <img
                                                                                                    className={"ai_badge"}
                                                                                                    src={ai_badge}/>
                                                                                            </div>
                                                                                            {this.state.w_user_img_list[idx].link_type == "49003" && this.state.w_user_img_list[idx].img_link
                                                                                                ? <img
                                                                                                    className={"use_img"}
                                                                                                    src={this.state.w_user_img_list[idx].img_link}
                                                                                                />
                                                                                                : ""}
                                                                                        </div>

                                                                                        <div
                                                                                            className={"event_img_preview_area" + (this.state.solution_type_cd == "09900" ? " event_img_preview_area_non_item" : "")}
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    show_robo_img_popup: true,
                                                                                                    select_img_type: "w",
                                                                                                    select_img_idx: idx,
                                                                                                });
                                                                                            }}
                                                                                            ref={idx == 0 && scrollMakeWRoboImgRef}
                                                                                        >
                                                                                            <div
                                                                                                className={"default_img"}>
                                                                                                <img
                                                                                                    src={robo_img_icon}/>
                                                                                                <div>로보 이미지</div>
                                                                                                <img
                                                                                                    className={"ai_badge"}
                                                                                                    src={ai_badge}/>
                                                                                            </div>
                                                                                            {this.state.w_user_img_list[idx].link_type == "49004" && this.state.w_user_img_list[idx].img_link
                                                                                                ? this.state.w_user_img_list[idx].img_link_able
                                                                                                    ? <img
                                                                                                        className={"use_img"}
                                                                                                        src={this.state.w_user_img_list[idx].img_link}
                                                                                                    />
                                                                                                    : <div
                                                                                                        className={"use_img disabled_img"}>
                                                                                                        이미지를<br/>불러올
                                                                                                        수<br/>없습니다.
                                                                                                    </div>
                                                                                                : ""
                                                                                            }
                                                                                        </div>
                                                                                        {this.state.w_is_youtube_link_use == "Y" &&
                                                                                            <div
                                                                                                className={"event_img_preview_area" + (this.state.solution_type_cd == "09900" ? " event_img_preview_area_non_item" : "")}
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        showUploadYoutubeLinkPopup: true,
                                                                                                        select_img_type: "w",
                                                                                                        select_img_idx: idx,
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    className={"default_img"}>
                                                                                                    <img
                                                                                                        src={video_icon}/>
                                                                                                    <div>동영상 주소</div>
                                                                                                </div>
                                                                                                {this.state.w_user_img_list[idx].link_type == "49005" && this.state.w_user_img_list[idx].video_link
                                                                                                    ? this.state.w_user_img_list[idx].img_link
                                                                                                        ? <img
                                                                                                            className={"use_img"}
                                                                                                            src={this.state.w_user_img_list[idx].img_link}
                                                                                                        />
                                                                                                        : <div
                                                                                                            className={"use_img disabled_img"}>
                                                                                                            업로드<br/>완료
                                                                                                        </div>
                                                                                                    : ""
                                                                                                }
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    {this.positionLinkTypeImgCheck("w", idx)
                                                                                    || (this.state.w_user_img_list[idx].link_type == "49002" && this.state.w_user_img_list[idx].img_link && !this.state.w_user_img_list[idx].img_link_able)
                                                                                    || (this.state.w_user_img_list[idx].link_type == "49004" && this.state.w_user_img_list[idx].img_link && !this.state.w_user_img_list[idx].img_link_able)
                                                                                    || (this.state.w_is_youtube_link_use == "Y" && this.state.w_user_img_list[idx].link_type == "49005" && this.state.w_user_img_list[idx].video_link)
                                                                                        ? <>{this.state.w_user_img_list[idx].link_type == "49003"
                                                                                            ? <button
                                                                                                className={"btn-175-b event_reselect_detail_img_btn"}
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        select_detail_crop_img_popup_show: true,
                                                                                                        select_img_type: "w",
                                                                                                        select_img_idx: idx,
                                                                                                    }, () => {
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                이미지 다시 선택
                                                                                            </button>
                                                                                            : <div
                                                                                                className={"event_img_upload_setting_area"}>
                                                                                                <input type="text"
                                                                                                       value={this.state.w_user_img_list[idx].org_img_name}
                                                                                                       readOnly/>
                                                                                            </div>}
                                                                                            <button
                                                                                                className={"event_upload_img_delete_btn"}
                                                                                                onClick={() => this.deleteUploadImg("w", idx)}>이미지
                                                                                                삭제
                                                                                            </button>
                                                                                        </>
                                                                                        : ""}
                                                                                    {this.positionLinkTypeImgCheck("w", idx)
                                                                                    || (this.state.solution_type_cd != "09900" && !this.positionLinkTypeImgCheck("w", idx) && this.positionImgCheck("w", idx) && this.state.select_item_list.length > 0)
                                                                                    || (this.state.w_user_img_list[idx].link_type == "49002" && this.state.w_user_img_list[idx].img_link && !this.state.w_user_img_list[idx].img_link_able)
                                                                                    || (this.state.w_user_img_list[idx].link_type == "49004" && this.state.w_user_img_list[idx].img_link && !this.state.w_user_img_list[idx].img_link_able)
                                                                                    || (this.state.w_is_youtube_link_use == "Y" && this.state.w_user_img_list[idx].link_type == "49005" && this.state.w_user_img_list[idx].video_link)
                                                                                        ? <>
                                                                                            <div
                                                                                                className={"event_img_edit_area_line"}></div>
                                                                                            <div
                                                                                                className={"event_img_position_area_title"}>이미지
                                                                                                위치
                                                                                            </div>
                                                                                            <div
                                                                                                className="flexBox radio_area"
                                                                                                style={{
                                                                                                    margin: "0px 5px 10px",
                                                                                                    alignItems: "center"
                                                                                                }}>
                                                                                                <RadioButton
                                                                                                    id={"w_img_position_type_" + idx + "_" + 50002}
                                                                                                    name={"w_img_position_type" + idx}
                                                                                                    checked={this.state["img_position_" + (idx + 1) + "_type"] == "50002"}
                                                                                                    onChangeCheck={() => this.onChangeImgPositionType("w", idx + 1, "50002")}
                                                                                                />
                                                                                                <div
                                                                                                    className={"event_img_position_type_text"}>비율
                                                                                                    (%)
                                                                                                </div>
                                                                                                <RadioButton
                                                                                                    id={"w_img_position_type_" + idx + "_" + 50001}
                                                                                                    name={"w_img_position_type" + idx}
                                                                                                    checked={this.state["img_position_" + (idx + 1) + "_type"] == "50001"}
                                                                                                    onChangeCheck={() => this.onChangeImgPositionType("w", idx + 1, "50001")}
                                                                                                />
                                                                                                <div
                                                                                                    className={"event_img_position_type_text"}>픽셀
                                                                                                    (px)
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="flexBox"
                                                                                                 style={{
                                                                                                     position: "relative",
                                                                                                     width: "120px",
                                                                                                     height: "120px",
                                                                                                     justifyContent: "center",
                                                                                                     marginTop: "8px"
                                                                                                 }}>
                                                                                                <div
                                                                                                    className="flexBox ie_position_relative"
                                                                                                    style={{
                                                                                                        zIndex: 1,
                                                                                                        position: "absolute",
                                                                                                        alignSelf: "center"
                                                                                                    }}>
                                                                                                    <div
                                                                                                        className="btn_keypad_arrow_area"
                                                                                                        onClick={(e) => this.movePositionOnClick(e, "w_img_position_left_" + (idx + 1), "w_img_position_right_" + (idx + 1), 1)}/>
                                                                                                    <div
                                                                                                        className="btn_keypad_center_area"
                                                                                                        onClick={(e) => this.moveImgPositionResetOnClick(e, "w", idx + 1)}>
                                                                                                        <div
                                                                                                            className="btn_keypad_center_area_reset">
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="btn_keypad_arrow_area"
                                                                                                        style={{transform: "rotate(180deg)"}}
                                                                                                        onClick={(e) => this.movePositionOnClick(e, "w_img_position_right_" + (idx + 1), "w_img_position_left_" + (idx + 1), 1)}/>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="flexBox ie_left_40px"
                                                                                                    style={{
                                                                                                        zIndex: 0,
                                                                                                        flexDirection: "column",
                                                                                                        position: "absolute",
                                                                                                        alignSelf: "center"
                                                                                                    }}>
                                                                                                    <div
                                                                                                        className="btn_keypad_arrow_area"
                                                                                                        style={{transform: "rotate(90deg)"}}
                                                                                                        onClick={(e) => this.movePositionOnClick(e, "w_img_position_top_" + (idx + 1), "w_img_position_bottom_" + (idx + 1), 1)}/>
                                                                                                    <div
                                                                                                        className="btn_keypad_center_area">
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="btn_keypad_arrow_area"
                                                                                                        style={{transform: "rotate(-90deg)"}}
                                                                                                        onClick={(e) => this.movePositionOnClick(e, "w_img_position_bottom_" + (idx + 1), "w_img_position_top_" + (idx + 1), 1)}/>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="flexBox"
                                                                                                 style={{
                                                                                                     position: "relative",
                                                                                                     width: "120px",
                                                                                                     height: "120px",
                                                                                                     justifyContent: "center",
                                                                                                     marginLeft: "20px",
                                                                                                     marginTop: "8px"
                                                                                                 }}>
                                                                                                <div
                                                                                                    className="flexBox ie_position_relative"
                                                                                                    style={{
                                                                                                        zIndex: 1,
                                                                                                        position: "absolute",
                                                                                                        alignSelf: "center"
                                                                                                    }}>
                                                                                                    <div
                                                                                                        className="btn_keypad_number_area">
                                                                                                        <input
                                                                                                            className="numbersOnly input_banner_img_position"
                                                                                                            type="text"
                                                                                                            value={this.state["w_img_position_left_" + (idx + 1)]}
                                                                                                            onChange={e => this.setState({["w_img_position_left_" + (idx + 1)]: e.target.value})}
                                                                                                            onKeyPress={e => this.movePositionOnKeyPress(e)}
                                                                                                            onBlur={e => this.movePositionOnBlur(e, "w_img_position_left_" + (idx + 1), "w_img_position_right_" + (idx + 1))}/>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="btn_keypad_center_area"
                                                                                                        style={{cursor: "default"}}>

                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="btn_keypad_number_area">
                                                                                                        <input
                                                                                                            className="numbersOnly input_banner_img_position"
                                                                                                            type="text"
                                                                                                            value={this.state["w_img_position_right_" + (idx + 1)]}
                                                                                                            onChange={e => this.setState({["w_img_position_right_" + (idx + 1)]: e.target.value})}
                                                                                                            onKeyPress={e => this.movePositionOnKeyPress(e)}
                                                                                                            onBlur={e => this.movePositionOnBlur(e, "w_img_position_right_" + (idx + 1), "w_img_position_left_" + (idx + 1))}/>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="flexBox ie_left_40px"
                                                                                                    style={{
                                                                                                        zIndex: 0,
                                                                                                        flexDirection: "column",
                                                                                                        position: "absolute",
                                                                                                        alignSelf: "center"
                                                                                                    }}>
                                                                                                    <div
                                                                                                        className="btn_keypad_number_area">
                                                                                                        <input
                                                                                                            className="numbersOnly input_banner_img_position"
                                                                                                            type="text"
                                                                                                            value={this.state["w_img_position_top_" + (idx + 1)]}
                                                                                                            onChange={e => this.setState({["w_img_position_top_" + (idx + 1)]: e.target.value})}
                                                                                                            onKeyPress={e => this.movePositionOnKeyPress(e)}
                                                                                                            onBlur={e => this.movePositionOnBlur(e, "w_img_position_top_" + (idx + 1), "w_img_position_bottom_" + (idx + 1))}/>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="btn_keypad_center_area"
                                                                                                        style={{cursor: "default"}}>

                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="btn_keypad_number_area">
                                                                                                        <input
                                                                                                            className="numbersOnly input_banner_img_position"
                                                                                                            type="text"
                                                                                                            value={this.state["w_img_position_bottom_" + (idx + 1)]}
                                                                                                            onChange={e => this.setState({["w_img_position_bottom_" + (idx + 1)]: e.target.value})}
                                                                                                            onKeyPress={e => this.movePositionOnKeyPress(e)}
                                                                                                            onBlur={e => this.movePositionOnBlur(e, "w_img_position_bottom_" + (idx + 1), "w_img_position_top_" + (idx + 1))}/>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            {/** 동영상 업로드의 경우 사용할 수 없음 */}
                                                                                            {!(this.state.w_is_youtube_link_use == "Y" && this.state.w_user_img_list[idx].link_type == "49005" && this.state.w_user_img_list[idx].video_link)
                                                                                                ? this.state.w_is_full_background_img_use == "Y"
                                                                                                    ? <button
                                                                                                        className="btn-175-b event_model_image_shift_btn"
                                                                                                        onClick={() => this.modelImageShift("w", idx)}>AI 인물 위치 자동 이동</button>
                                                                                                    : this.state.w_is_fashionplus_face_banner_use == "Y"
                                                                                                        ? <button
                                                                                                                className="btn-175-b event_model_image_shift_btn"
                                                                                                                onClick={() => this.faceImageShiftForFashionPlus("w", idx, this.state.w_is_nukki_crop_yn)}>AI 얼굴 위치 자동 이동</button>
                                                                                                        : ""
                                                                                                : ""}
                                                                                            <div className={"event_img_edit_area_line"}></div>
                                                                                            <div className={"event_img_position_area_title"}>이미지 크기</div>
                                                                                            <div className={"wrap_btn_img_scale_reset"}>
                                                                                                <div className="btn_img_scale_reset" onClick={(e) => this.handleImgScaleResetOnClick(e, "w", idx + 1)}>초기화</div>
                                                                                            </div>
                                                                                            <Slider
                                                                                                className={"w_img_" + (idx + 1) + "_scale_slider"}
                                                                                                aria-label="Small steps"
                                                                                                defaultValue={this.state['w_img_' + (idx + 1) + '_scale'] == null || this.state['w_img_' + (idx + 1) + '_scale'] == undefined || this.state['w_img_' + (idx + 1) + '_scale'] == 0 ? 1 : this.state['w_img_' + (idx + 1) + '_scale']}
                                                                                                value={this.state['w_img_' + (idx + 1) + '_scale'] == null || this.state['w_img_' + (idx + 1) + '_scale'] == undefined || this.state['w_img_' + (idx + 1) + '_scale'] == 0 ? 1 : this.state['w_img_' + (idx + 1) + '_scale']}
                                                                                                getAriaValueText={(value) => this.getImgScaleValueText(value)}
                                                                                                step={0.1}
                                                                                                min={0.1}
                                                                                                max={2.5}
                                                                                                valueLabelDisplay="auto"
                                                                                                sx={{
                                                                                                    color: "#00e2e6",
                                                                                                    width: "95%",
                                                                                                    margin: "0 auto"
                                                                                                }}
                                                                                                onChange={(e) => this.handleImgScaleOnChange(e, "w", (idx + 1))}
                                                                                            />
                                                                                            {!(this.state.w_is_youtube_link_use == "Y" && this.state.w_user_img_list[idx].link_type == "49005" && this.state.w_user_img_list[idx].video_link)
                                                                                            && <div className={"event_img_edit_area_line"}></div>}
                                                                                            {/** 동영상 업로드의 경우 사용할 수 없음 */}
                                                                                            {this.state.w_essential_img_nukki_yn == "Y"
                                                                                            && !(this.state.w_user_img_list[idx].link_type == "49002" && this.state.w_user_img_list[idx].img_link && !this.state.w_user_img_list[idx].img_link_able)
                                                                                            && !(this.state.w_user_img_list[idx].link_type == "49004" && this.state.w_user_img_list[idx].img_link && !this.state.w_user_img_list[idx].img_link_able)
                                                                                            && !(this.state.w_is_youtube_link_use == "Y" && this.state.w_user_img_list[idx].link_type == "49005" && this.state.w_user_img_list[idx].video_link)
                                                                                                ? <>
                                                                                                    <div style={{
                                                                                                        display: "flex",
                                                                                                        flexWrap: "nowrap",
                                                                                                        justifyContent: "space-between",
                                                                                                        width: "100%",
                                                                                                        marginTop: "10px"
                                                                                                    }}>
                                                                                                        <div style={{
                                                                                                            marginLeft: "6px",
                                                                                                            fontSize: "15px"
                                                                                                        }}>AI 배경 제거
                                                                                                        </div>
                                                                                                        <ToggleButton
                                                                                                            id={"w_nukki_yn_" + idx}
                                                                                                            checked={this.state["w_" + w_position.position_name + "_nukki_yn"] == "Y"}
                                                                                                            onClickOption={(e) => this.onClickRemoveBackground(e, "w", idx + 1)}
                                                                                                        />
                                                                                                    </div>
                                                                                                    {this.state["w_" + w_position.position_name + "_nukki_yn"] == "Y"
                                                                                                        ? <>
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    display: "flex",
                                                                                                                    flexWrap: "nowrap",
                                                                                                                    justifyContent: "space-between",
                                                                                                                    width: "100%",
                                                                                                                    marginTop: "10px"
                                                                                                                }}>
                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        marginLeft: "6px",
                                                                                                                        fontSize: "15px"
                                                                                                                    }}>AI
                                                                                                                    윤곽
                                                                                                                    보정
                                                                                                                </div>
                                                                                                                <ToggleButton
                                                                                                                    id={"w_nukki_ppm_state_" + idx}
                                                                                                                    checked={this.state.w_nukki_ppm_state[idx]}
                                                                                                                    onClickOption={(e) => this.changeNukkiPPMstate(e, "w", idx + 1)}
                                                                                                                    disabled={this.state.w_nukki_version_list[idx] == "3"}
                                                                                                                />
                                                                                                            </div>
                                                                                                            <RCSlider
                                                                                                                className={"event_version_slider"}
                                                                                                                dots={true}
                                                                                                                marks={{
                                                                                                                    1: '1타입',
                                                                                                                    2: '2타입',
                                                                                                                    3: '3타입',
                                                                                                                }}
                                                                                                                defaultValue={1}
                                                                                                                value={4 - Number(this.state.w_nukki_version_list[idx])}
                                                                                                                min={1}
                                                                                                                max={3}
                                                                                                                tabIndex={-1}
                                                                                                                onChange={(value) => this.onChangeVersionSlider(4 - value, "w", idx + 1)}
                                                                                                                onAfterChange={(value) => this.onChangeVersionSlider(4 - value, "w", idx + 1, true)}
                                                                                                            />
                                                                                                        </>
                                                                                                        : ""}
                                                                                                </>
                                                                                            : ""}

                                                                                            <button
                                                                                                className="btn-175-b event_select_img_color_btn"
                                                                                                onClick={() => this.onSelectImgLinkColor("w", idx)}
                                                                                                disabled={((this.state.w_user_img_list[idx].link_type == "49002" || this.state.w_user_img_list[idx].link_type == "49004") && this.state.w_user_img_list[idx].img_link && !this.state.w_user_img_list[idx].img_link_able) ? true : false}
                                                                                            >
                                                                                                AI 배경 컬러 적용하기
                                                                                            </button>
                                                                                            {this.state["w_" + w_position.position_name + "_nukki_yn"] == "Y"
                                                                                            && <div
                                                                                                className={"event_img_edit_info_text"}>※
                                                                                                배경 제거 1타입으로 설정 시, AI
                                                                                                윤곽 보정 기능 사용이
                                                                                                불가합니다.</div>}
                                                                                        </>
                                                                                        : ""}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : ""}
                                                                </>
                                                            ))}
                                                        </div>
                                                    </>
                                                :   ""
                                                }    
                                            </div>
                                        </div>    
                                    : ""
                                }
                                </>
                            :  <div className="m-t-43 m-l-29">
                                    <div className="flexBox"> 
                                        <div className="icn_round_blue_small"></div>
                                            &nbsp;&nbsp;
                                            <div className="form_title" style={{marginTop: "auto", marginBottom: "auto"}}>
                                                가로형 배너 디자인을 선택해 주세요.
                                        </div>
                                    </div>
                                    <div className="flexBox m-t-21 m-b-20 m-l-34 width-100-34px">
                                        <div style={{color:"#FF7B7B", margin:"auto 0", fontSize:"15px"}}><b>* 적용범위 - 페이지와 배너를 먼저 선택 해주세요.</b></div>  
                                        <div className="btn-175-b cursor-pointer" style={{width: "80px", height:"40px", lineHeight:"41px", marginLeft:"20px"}} onClick={e => this.onClickShortcutFocusing(e, "iframe_page_id_select")}>바로가기</div>
                                    </div>
                                </div>
                        }
                        { 
                            true
                            ? <> 
                                {
                                    this.state.mobile_banner_ratio_list.length > 0
                                    ?   <div className="m-t-43 m-l-29">
                                            <div className="flexBox"> 
                                                <div className="icn_round_blue_small"></div>
                                                &nbsp;&nbsp;
                                                <div className="form_title" style={{marginTop: "auto", marginBottom: "auto"}}>
                                                    세로형 배너 디자인을 선택해 주세요.
                                                </div>
                                            </div>
                                            {/* 템플릿 필터 start */}
                                            <div className={"template_tag_filter"}>
                                                <button className={"btn_template_filter"} onClick={() => this.handleTemplateFilterBtnClick("07000")}>
                                                    <img src={icn_filter} alt={"템플릿 태그 필터 아이콘"}/>
                                                </button>
                                                <div className={"template_tag_list"}>
                                                    <div onMouseLeave={this.handleDragStop}>
                                                        <ScrollMenu
                                                            handleMouseOnWheel={(apiObj, ev) => this.handleMouseOnWheel(apiObj, ev)}
                                                            onMouseDown={() => this.handleDragStart}
                                                            onMouseUp={() => this.handleDragStop}
                                                            onMouseMove={this.handleDrag}
                                                        >
                                                            <button style={this.state.h_all_tag_selected ? {backgroundColor:'#00E2E6', border: '1px solid #00E2E6'} : {backgroundColor: '#FFF', border: '1px solid #ACACAC'}}
                                                                    onClick={() => this.allTagBtnClick("07000", "h")}>{this.state.template_tag_list ? this.state.template_tag_list[0].tag_name : ""}</button>
                                                            {
                                                                Object.keys(this.state.h_template_all_tag_list).map((category, tag_idx) => (
                                                                    this.state.h_template_all_tag_list[category].map((tag)=>(
                                                                        <TagButton key={tag.tag_cd} tagCd={tag.tag_cd} tagName={tag.tag_name}
                                                                                   isSelected={tag.isSelected}
                                                                                   onClick={(tagCd, isSelected) => this.tagBtnClick(category, tag.tag_cd, isSelected, "07000", "h")}/>
                                                                    ))
                                                                ))
                                                            }
                                                        </ScrollMenu>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* 템플릿 필터 end */}
                                            <div className="container_banner_template m-t-21 m-b-20 width-100-34px">
                                                <div className="flexBox bannerTypeImg" style={{flex:"1", borderLeft:"0px", margin: "auto"}}>
                                                    <div className="flexBox" style={{width:"100%"}}>
                                                        {this.state.mobileBannerTypeList == null || this.state.mobileBannerTypeList.length == 0
                                                            ?
                                                            <div className={"no_banner_template"}>
                                                                <div>
                                                                    <p>해당 템플릿 디자인이 없어요.<br/>다른 디자인으로 선택해 볼까요?</p>
                                                                    <button onClick={() => this.resetTemplateFilterBtnClick("07000", "h")}>
                                                                        <img src={icn_refresh_2} alt={"전체 다시보기"}/>
                                                                        &nbsp;&nbsp;전체 다시보기
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            :
                                                            this.state.mobileBannerTypeList.map((bannerType,idx) => (
                                                            <div key={idx} className="banner-inner-v2 mobile-width-100" style={{margin: "0px 0.82vw 30px 0", width: "calc((99% - 4.6vw) / 6)", cursor: "pointer"}}
                                                                 onClick={() =>  this.onClickBannerTypeRadioButton(bannerType, "event_mobile_type_cd", "event_mobile_banner_name")}>
                                                                {/*<RadioButton*/}
                                                                {/*    id={"select_event_mobile_type_cd_"+bannerType.code_cd}*/}
                                                                {/*    name={"select_event_mobile_type_cd"}*/}
                                                                {/*    checked={this.state.event_mobile_type_cd == bannerType.code_cd}*/}
                                                                {/*    onChangeCheck={() => this.onClickBannerTypeRadioButton(bannerType, "event_mobile_type_cd", "event_mobile_banner_name")}*/}
                                                                {/*    className={"event_radio_img_area"}*/}
                                                                {/*/>*/}
                                                                <img src={bannerType.val2} width="100%" height="auto"
                                                                     style={{margin:"auto", border: this.state.event_mobile_type_cd == bannerType.code_cd ? "4px solid #00E2E6" : "1px solid #D9D9D9", borderRadius: "13px"}}></img>
                                                                <div className={"title_banner_template"} style={{fontWeight: this.state.event_mobile_type_cd == bannerType.code_cd ? "500" : "400"}}>{bannerType.banner_name}</div>
                                                                <div className={"btn_banner_template_tag"}>
                                                                    {(bannerType.tag_name_list || "").toString() != "" && (bannerType.tag_name_list || "").toString().split(",").map((tag_name) => (
                                                                        <button key={tag_name}>{tag_name.trim()}</button>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        ))}
                                                        {/* 세로형 테스트 배너 */}
                                                        {/* <div className="banner-inner-v2 mobile-width-100" style={{margin: "0px 0.82vw 13px 0", width: "calc((99% - 3.4vw) / 5)"}}>
                                                            <RadioButton
                                                                id={"select_event_type_cd_test_"+"07100"}
                                                                name={"select_event_type_cd"}
                                                                checked={this.state.event_type_cd == "07100"}
                                                                onChangeCheck={() => this.onClickBannerTypeRadioButton({
                                                                    code_cd : "07100",
                                                                    val1 : "테스트 형",
                                                                    val2 : "https://s3.ap-northeast-2.amazonaws.com/otdeal.file/banner_sample_230310/07100.png",
                                                                }, "event_mobile_type_cd")}
                                                                className={"event_radio_img_area"}
                                                            />
                                                            <img src={"https://s3.ap-northeast-2.amazonaws.com/otdeal.file/banner_sample_230310/07100.png"} width="100%" height="auto" style={{margin:"auto auto auto auto", border:"0.5px solid dimgray"}}></img>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                {this.state.mobileBannerTypeList == null || this.state.mobileBannerTypeList.length == 0 ? <></>
                                                    :
                                                    <div className="container_banner_template_paging">
                                                        <div style={{lineHeight:"60px"}}>
                                                            <Pagination
                                                                activePage={this.state.mobile_banner_type_paging.activePage}
                                                                itemsCountPerPage={this.state.mobile_banner_type_paging.rowcnt}
                                                                totalItemsCount={this.state.mobile_banner_type_paging.totalCnt}
                                                                pageRangeDisplayed={10}
                                                                onChange={this.handleMobileBannerPageChange.bind(this)}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="flexBox">
                                                { this.state.event_mobile_type_cd != null && this.state.event_mobile_type_cd != "null" && this.state.event_mobile_type_cd != ""
                                                ?   
                                                    <>
                                                        <div className="two-four-division_area">
                                                            {/*<div className="choose_box_title eventSettingTitle m-l-34">*/}
                                                            {/*    <label style={{marginTop: "auto", marginBottom: "auto", color:"#333333"}}>선택된 배너 디자인</label>*/}
                                                            {/*</div>*/}
                                                            <div className="current_banner_template">
                                                                <h5>선택한 템플릿</h5>
                                                                {/* <div style={{width:"50%", height:"100%", background:"url('"+this.state.event_mobile_type_img+"') center", backgroundSize:"cover"}}></div> */}
                                                                <img src={this.state.event_mobile_type_img}/>
                                                                {/*<label style={{margin:"auto"}}><b>{this.state.event_mobile_type_name}</b></label>*/}
                                                                <h6>{this.state.event_mobile_banner_name}</h6>
                                                                <div>
                                                                    {this.state.event_mobile_tag_name_list != "" && this.state.event_mobile_tag_name_list.map(tag_name => (
                                                                        <button key={tag_name}>{tag_name.trim()}</button>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            {/*<div style={{marginTop:"20px", paddingRight : "38px", fontSize:"14px"}}>*/}
                                                            {/*    <div className="margin-vertical-auto flexBox m-l-34 m-t-21">*/}
                                                            {/*        <div className="icn_warning_round"></div>*/}
                                                            {/*        <div style={{marginLeft:"10px", flex:"1"}}>상세페이지 중 마음에 드는 이미지로 업로드 할 수 있어요.</div>*/}
                                                            {/*    </div>*/}
                                                            {/*    <div className="margin-vertical-auto flexBox m-l-34 m-t-21">*/}
                                                            {/*        <div className="icn_warning_round"></div>*/}
                                                            {/*        <div style={{marginLeft:"10px", flex:"1"}}>단위를 선택한 후, 화살표 버튼을 클릭하거나 숫자를 적어서 이미지 위치를 옮겨 보세요.</div>*/}
                                                            {/*    </div>*/}
                                                            {/*    <div className="margin-vertical-auto flexBox m-l-34 m-t-21">*/}
                                                            {/*        <div className="icn_warning_round"></div>*/}
                                                            {/*        <div style={{marginLeft:"10px", flex:"1"}}>AI가 원본 이미지의 보기 싫은 배경을 제거해 드려요. 원치 않으시는 경우, AI 배경 제거 기능을 꺼 주세요.</div>*/}
                                                            {/*    </div>*/}
                                                            {/*    <div className="margin-vertical-auto flexBox m-l-34 m-t-21">*/}
                                                            {/*        <div className="icn_warning_round"></div>*/}
                                                            {/*        <div style={{marginLeft:"10px", flex:"1"}}>AI가 원본 이미지와 어울리는 배경색을 추천해줘요.</div>*/}
                                                            {/*    </div>*/}
                                                            {/*</div>*/}
                                                        </div>
                                                        <div className={"event_img_setting_area"}>
                                                            {this.state.h_img_position_name.map((h_position, idx) => (
                                                                <>
                                                                    {this.state["h_img" + (idx + 1) + "_is_use"] == "Y"
                                                                        ? <div className="two-four-division_area"
                                                                               style={{
                                                                                   minWidth: "280px",
                                                                                   maxWidth: "280px",
                                                                                   marginLeft: "10px"
                                                                               }}>
                                                                            <div
                                                                                className="choose_box_title eventSettingTitle m-l-34">
                                                                                <label style={{
                                                                                    marginTop: "auto",
                                                                                    marginBottom: "0px",
                                                                                    color: "#333333"
                                                                                }}>{(idx == "0" ? "첫" : idx == "1" ? "두" : idx == "2" ? "세" : "네") + "번째 이미지 편집"}</label>
                                                                            </div>
                                                                            <div className="flexBox">
                                                                                <div
                                                                                    className="content_box_radius m-l-34 flexBox"
                                                                                    style={{width: "auto"}}>
                                                                                    <div
                                                                                        className="flexBox event_img_edit_area">
                                                                                        {this.state.solution_type_cd != "09900" // 연동형인 경우에만 상품 선택 영역 표시
                                                                                            ? <div
                                                                                                className={"event_img_preview_area"}
                                                                                                onClick={() => {
                                                                                                    if (this.state.select_item_list.length > 0) {
                                                                                                        let item_img = (this.state.is_detail_image_use == true && this.state.select_item_list[0].detail_image) ? this.state.select_item_list[0].detail_image : this.state.select_item_list[0].image ? this.state.select_item_list[0].image : "";
                                                                                                        if(this.positionImgCheck("h", idx) && !this.positionLinkTypeImgCheck("h", idx) && (this.state[h_position.img_column_name] == item_img)){
                                                                                                            return false;
                                                                                                        }
                                                                                                        this.deleteUploadImg("h", idx);
                                                                                                    } else {
                                                                                                        this.showReqSelectItem();
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                {/* 기본 안내 이미지 */}
                                                                                                <div
                                                                                                    className={"default_img"}>
                                                                                                    <img
                                                                                                        src={select_item_icon}/>
                                                                                                    <div>상품 이미지</div>
                                                                                                </div>
                                                                                                {/* 사용중인 이미지 */}
                                                                                                {(this.positionImgCheck("h", idx) && !this.positionLinkTypeImgCheck("h", idx) && this.state.select_item_list.length > 0 && !(this.state.h_is_youtube_link_use == "Y" && this.state.h_user_img_list[idx].link_type == "49005" && this.state.h_user_img_list[idx].video_link))
                                                                                                    ? <img
                                                                                                        className={"use_img"}
                                                                                                        src={this.state["h_" + h_position.img_column_name]}
                                                                                                    />
                                                                                                    : ""}
                                                                                            </div>
                                                                                            : ""}

                                                                                        <div
                                                                                            className={"event_img_preview_area" + (this.state.solution_type_cd == "09900" ? " event_img_preview_area_non_item" : "")}
                                                                                        >
                                                                                            <input type="file"
                                                                                                   id={"h_banner_img_upload_" + idx}
                                                                                                   onChange={e => this._handleImageChange(e, idx, "h", "49001")}
                                                                                                   accept="image/*"
                                                                                                   value=""
                                                                                                   style={{display: "none"}}
                                                                                                   tabIndex="-1"></input>
                                                                                            <label
                                                                                                htmlFor={"h_banner_img_upload_" + idx}
                                                                                                style={{display: "contents"}}>
                                                                                                <div
                                                                                                    className={"default_img"}>
                                                                                                    <img
                                                                                                        src={file_upload_icon}/>
                                                                                                    <div>파일 선택</div>
                                                                                                </div>
                                                                                                {this.state.h_user_img_list[idx].link_type == "49001" && this.state.h_user_img_list[idx].img_link
                                                                                                    ? <img
                                                                                                        className={"use_img"}
                                                                                                        src={this.state.h_user_img_list[idx].img_link}
                                                                                                    />
                                                                                                    : ""}
                                                                                            </label>
                                                                                        </div>

                                                                                        <div
                                                                                            className={"event_img_preview_area" + (this.state.solution_type_cd == "09900" ? " event_img_preview_area_non_item" : "")}
                                                                                            onClick={() => this.openImgLinkUrlPopup("h", idx, "49002")}
                                                                                        >
                                                                                            <div
                                                                                                className={"default_img"}>
                                                                                                <img
                                                                                                    src={link_upload_icon}/>
                                                                                                <div>이미지 주소</div>
                                                                                            </div>
                                                                                            {this.state.h_user_img_list[idx].link_type == "49002" && this.state.h_user_img_list[idx].img_link
                                                                                                ? this.state.h_user_img_list[idx].img_link_able
                                                                                                    ? <img
                                                                                                        className={"use_img"}
                                                                                                        src={this.state.h_user_img_list[idx].img_link}
                                                                                                    />
                                                                                                    : <div
                                                                                                        className={"use_img disabled_img"}>
                                                                                                        이미지를<br/>불러올
                                                                                                        수<br/>없습니다.
                                                                                                    </div>
                                                                                                : ""
                                                                                            }
                                                                                        </div>

                                                                                        <div
                                                                                            className={"event_img_preview_area" + (this.state.solution_type_cd == "09900" ? " event_img_preview_area_non_item" : "")}
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    select_detail_crop_img_popup_show: true,
                                                                                                    select_img_type: "h",
                                                                                                    select_img_idx: idx,
                                                                                                });
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                className={"default_img"}>
                                                                                                <img
                                                                                                    src={detail_page_icon}/>
                                                                                                <div>상세페이지<br/>자르기</div>
                                                                                                <img
                                                                                                    className={"ai_badge"}
                                                                                                    src={ai_badge}/>
                                                                                            </div>
                                                                                            {this.state.h_user_img_list[idx].link_type == "49003" && this.state.h_user_img_list[idx].img_link
                                                                                                ? <img
                                                                                                    className={"use_img"}
                                                                                                    src={this.state.h_user_img_list[idx].img_link}
                                                                                                />
                                                                                                : ""}
                                                                                        </div>

                                                                                        <div
                                                                                            className={"event_img_preview_area" + (this.state.solution_type_cd == "09900" ? " event_img_preview_area_non_item" : "")}
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    show_robo_img_popup: true,
                                                                                                    select_img_type: "h",
                                                                                                    select_img_idx: idx,
                                                                                                });
                                                                                            }}
                                                                                            ref={idx == 0 && scrollMakeHRoboImgRef}
                                                                                        >
                                                                                            <div
                                                                                                className={"default_img"}>
                                                                                                <img
                                                                                                    src={robo_img_icon}/>
                                                                                                <div>로보 이미지</div>
                                                                                                <img
                                                                                                    className={"ai_badge"}
                                                                                                    src={ai_badge}/>
                                                                                            </div>
                                                                                            {this.state.h_user_img_list[idx].link_type == "49004" && this.state.h_user_img_list[idx].img_link
                                                                                                ? this.state.h_user_img_list[idx].img_link_able
                                                                                                    ? <img
                                                                                                        className={"use_img"}
                                                                                                        src={this.state.h_user_img_list[idx].img_link}
                                                                                                    />
                                                                                                    : <div
                                                                                                        className={"use_img disabled_img"}>
                                                                                                        이미지를<br/>불러올
                                                                                                        수<br/>없습니다.
                                                                                                    </div>
                                                                                                : ""
                                                                                            }
                                                                                        </div>
                                                                                        {this.state.h_is_youtube_link_use == "Y" &&
                                                                                            <div
                                                                                                className={"event_img_preview_area" + (this.state.solution_type_cd == "09900" ? " event_img_preview_area_non_item" : "")}
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        showUploadYoutubeLinkPopup: true,
                                                                                                        select_img_type: "w",
                                                                                                        select_img_idx: idx,
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    className={"default_img"}>
                                                                                                    <img
                                                                                                        src={video_icon}/>
                                                                                                    <div>동영상 주소</div>
                                                                                                </div>
                                                                                                {this.state.h_user_img_list[idx].link_type == "49005" && this.state.h_user_img_list[idx].video_link
                                                                                                    ? this.state.h_user_img_list[idx].img_link
                                                                                                        ? <img
                                                                                                            className={"use_img"}
                                                                                                            src={this.state.h_user_img_list[idx].img_link}
                                                                                                        />
                                                                                                        : <div
                                                                                                            className={"use_img disabled_img"}>
                                                                                                            업로드<br/>완료
                                                                                                        </div>
                                                                                                    : ""
                                                                                                }
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    {this.positionLinkTypeImgCheck("h", idx)
                                                                                    || (this.state.h_user_img_list[idx].link_type == "49002" && this.state.h_user_img_list[idx].img_link && !this.state.h_user_img_list[idx].img_link_able)
                                                                                    || (this.state.h_user_img_list[idx].link_type == "49004" && this.state.h_user_img_list[idx].img_link && !this.state.h_user_img_list[idx].img_link_able)
                                                                                    || (this.state.h_is_youtube_link_use == "Y" && this.state.h_user_img_list[idx].link_type == "49005" && this.state.h_user_img_list[idx].video_link)
                                                                                        ? <>{this.state.h_user_img_list[idx].link_type == "49003"
                                                                                            ? <button
                                                                                                className={"btn-175-b event_reselect_detail_img_btn"}
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        select_detail_crop_img_popup_show: true,
                                                                                                        select_img_type: "h",
                                                                                                        select_img_idx: idx,
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                이미지 다시 선택
                                                                                            </button>
                                                                                            : <div
                                                                                                className={"event_img_upload_setting_area"}>
                                                                                                <input type="text"
                                                                                                       value={this.state.h_user_img_list[idx].org_img_name}
                                                                                                       readOnly/>
                                                                                            </div>}
                                                                                            <button
                                                                                                className={"event_upload_img_delete_btn"}
                                                                                                onClick={() => this.deleteUploadImg("h", idx)}>이미지
                                                                                                삭제
                                                                                            </button>
                                                                                        </>
                                                                                        : ""}
                                                                                    {this.positionLinkTypeImgCheck("h", idx)
                                                                                    || (this.state.solution_type_cd != "09900" && !this.positionLinkTypeImgCheck("h", idx) && this.positionImgCheck("h", idx) && this.state.select_item_list.length > 0)
                                                                                    || (this.state.h_user_img_list[idx].link_type == "49002" && this.state.h_user_img_list[idx].img_link && !this.state.h_user_img_list[idx].img_link_able)
                                                                                    || (this.state.h_user_img_list[idx].link_type == "49004" && this.state.h_user_img_list[idx].img_link && !this.state.h_user_img_list[idx].img_link_able)
                                                                                    || (this.state.h_is_youtube_link_use == "Y" && this.state.h_user_img_list[idx].link_type == "49005" && this.state.h_user_img_list[idx].video_link)
                                                                                        ? <>
                                                                                            <div
                                                                                                className={"event_img_edit_area_line"}></div>
                                                                                            <div
                                                                                                className={"event_img_position_area_title"}>이미지
                                                                                                위치
                                                                                            </div>
                                                                                            <div
                                                                                                className="flexBox radio_area"
                                                                                                style={{
                                                                                                    margin: "18px 5px 10px",
                                                                                                    alignItems: "center"
                                                                                                }}>
                                                                                                <RadioButton
                                                                                                    id={"h_img_position_type_" + idx + "_" + 50002}
                                                                                                    name={"h_img_position_type" + idx}
                                                                                                    checked={this.state["h_img_position_" + (idx + 1) + "_type"] == "50002"}
                                                                                                    onChangeCheck={() => this.onChangeImgPositionType("h", idx + 1, "50002")}
                                                                                                />
                                                                                                <div
                                                                                                    className={"event_img_position_type_text"}>비율
                                                                                                    (%)
                                                                                                </div>
                                                                                                <RadioButton
                                                                                                    id={"h_img_position_type_" + idx + "_" + 50001}
                                                                                                    name={"h_img_position_type" + idx}
                                                                                                    checked={this.state["h_img_position_" + (idx + 1) + "_type"] == "50001"}
                                                                                                    onChangeCheck={() => this.onChangeImgPositionType("h", idx + 1, "50001")}
                                                                                                />
                                                                                                <div
                                                                                                    className={"event_img_position_type_text"}>픽셀
                                                                                                    (px)
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="flexBox"
                                                                                                 style={{
                                                                                                     position: "relative",
                                                                                                     width: "120px",
                                                                                                     height: "120px",
                                                                                                     justifyContent: "center",
                                                                                                     marginTop: "8px"
                                                                                                 }}>
                                                                                                <div
                                                                                                    className="flexBox ie_position_relative"
                                                                                                    style={{
                                                                                                        zIndex: 1,
                                                                                                        position: "absolute",
                                                                                                        alignSelf: "center"
                                                                                                    }}>
                                                                                                    <div
                                                                                                        className="btn_keypad_arrow_area"
                                                                                                        onClick={(e) => this.movePositionOnClick(e, "h_img_position_left_" + (idx + 1), "h_img_position_right_" + (idx + 1), 1)}/>
                                                                                                    <div
                                                                                                        className="btn_keypad_center_area"
                                                                                                        onClick={(e) => this.moveImgPositionResetOnClick(e, "h", idx + 1)}>
                                                                                                        <div
                                                                                                            className="btn_keypad_center_area_reset">
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="btn_keypad_arrow_area"
                                                                                                        style={{transform: "rotate(180deg)"}}
                                                                                                        onClick={(e) => this.movePositionOnClick(e, "h_img_position_right_" + (idx + 1), "h_img_position_left_" + (idx + 1), 1)}/>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="flexBox ie_left_40px"
                                                                                                    style={{
                                                                                                        zIndex: 0,
                                                                                                        flexDirection: "column",
                                                                                                        position: "absolute",
                                                                                                        alignSelf: "center"
                                                                                                    }}>
                                                                                                    <div
                                                                                                        className="btn_keypad_arrow_area"
                                                                                                        style={{transform: "rotate(90deg)"}}
                                                                                                        onClick={(e) => this.movePositionOnClick(e, "h_img_position_top_" + (idx + 1), "h_img_position_bottom_" + (idx + 1), 1)}/>
                                                                                                    <div
                                                                                                        className="btn_keypad_center_area">
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="btn_keypad_arrow_area"
                                                                                                        style={{transform: "rotate(-90deg)"}}
                                                                                                        onClick={(e) => this.movePositionOnClick(e, "h_img_position_bottom_" + (idx + 1), "h_img_position_top_" + (idx + 1), 1)}/>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="flexBox"
                                                                                                 style={{
                                                                                                     position: "relative",
                                                                                                     width: "120px",
                                                                                                     height: "120px",
                                                                                                     justifyContent: "center",
                                                                                                     marginLeft: "20px",
                                                                                                     marginTop: "8px"
                                                                                                 }}>
                                                                                                <div
                                                                                                    className="flexBox ie_position_relative"
                                                                                                    style={{
                                                                                                        zIndex: 1,
                                                                                                        position: "absolute",
                                                                                                        alignSelf: "center"
                                                                                                    }}>
                                                                                                    <div
                                                                                                        className="btn_keypad_number_area">
                                                                                                        <input
                                                                                                            className="numbersOnly"
                                                                                                            type="text"
                                                                                                            value={this.state["h_img_position_left_" + (idx + 1)]}
                                                                                                            onChange={e => this.setState({["h_img_position_left_" + (idx + 1)]: e.target.value})}
                                                                                                            onKeyPress={e => this.movePositionOnKeyPress(e)}
                                                                                                            onBlur={e => this.movePositionOnBlur(e, "h_img_position_left_" + (idx + 1), "h_img_position_right_" + (idx + 1))}/>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="btn_keypad_center_area"
                                                                                                        style={{cursor: "default"}}>

                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="btn_keypad_number_area">
                                                                                                        <input
                                                                                                            className="numbersOnly"
                                                                                                            type="text"
                                                                                                            value={this.state["h_img_position_right_" + (idx + 1)]}
                                                                                                            onChange={e => this.setState({["h_img_position_right_" + (idx + 1)]: e.target.value})}
                                                                                                            onKeyPress={e => this.movePositionOnKeyPress(e)}
                                                                                                            onBlur={e => this.movePositionOnBlur(e, "h_img_position_right_" + (idx + 1), "h_img_position_left_" + (idx + 1))}/>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="flexBox ie_left_40px"
                                                                                                    style={{
                                                                                                        zIndex: 0,
                                                                                                        flexDirection: "column",
                                                                                                        position: "absolute",
                                                                                                        alignSelf: "center"
                                                                                                    }}>
                                                                                                    <div
                                                                                                        className="btn_keypad_number_area">
                                                                                                        <input
                                                                                                            className="numbersOnly"
                                                                                                            type="text"
                                                                                                            value={this.state["h_img_position_top_" + (idx + 1)]}
                                                                                                            onChange={e => this.setState({["h_img_position_top_" + (idx + 1)]: e.target.value})}
                                                                                                            onKeyPress={e => this.movePositionOnKeyPress(e)}
                                                                                                            onBlur={e => this.movePositionOnBlur(e, "h_img_position_top_" + (idx + 1), "h_img_position_bottom_" + (idx + 1))}/>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="btn_keypad_center_area"
                                                                                                        style={{cursor: "default"}}>

                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="btn_keypad_number_area">
                                                                                                        <input
                                                                                                            className="numbersOnly"
                                                                                                            type="text"
                                                                                                            value={this.state["h_img_position_bottom_" + (idx + 1)]}
                                                                                                            onChange={e => this.setState({["h_img_position_bottom_" + (idx + 1)]: e.target.value})}
                                                                                                            onKeyPress={e => this.movePositionOnKeyPress(e)}
                                                                                                            onBlur={e => this.movePositionOnBlur(e, "h_img_position_bottom_" + (idx + 1), "h_img_position_top_" + (idx + 1))}/>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            {/** 동영상 업로드의 경우 사용할 수 없음 */}
                                                                                            {!(this.state.h_is_youtube_link_use == "Y" && this.state.h_user_img_list[idx].link_type == "49005" && this.state.h_user_img_list[idx].video_link)
                                                                                                ?this.state.h_is_full_background_img_use == "Y"
                                                                                                    ? <button
                                                                                                        className="btn-175-b event_model_image_shift_btn"
                                                                                                        onClick={() => this.modelImageShift("h", idx)}>AI 인물 위치 자동 이동</button>
                                                                                                    : this.state.h_is_fashionplus_face_banner_use == "Y"    
                                                                                                        ? <button
                                                                                                        className="btn-175-b event_model_image_shift_btn"
                                                                                                        onClick={() => this.faceImageShiftForFashionPlus("h", idx, this.state.h_is_nukki_crop_yn)}>AI 얼굴 위치 자동 이동</button>
                                                                                                        : ""
                                                                                                : ""}
                                                                                            <div className={"event_img_edit_area_line"}></div>
                                                                                            <div className={"event_img_position_area_title"}>이미지 크기</div>
                                                                                            <div className={"wrap_btn_img_scale_reset"}>
                                                                                                <div className="btn_img_scale_reset" onClick={(e) => this.handleImgScaleResetOnClick(e, "h", idx + 1)}>초기화</div>
                                                                                            </div>
                                                                                            <Slider
                                                                                                className={"h_img_" + (idx + 1) + "_scale_slider"}
                                                                                                aria-label="Small steps"
                                                                                                defaultValue={this.state['h_img_' + (idx + 1) + '_scale'] == null || this.state['h_img_' + (idx + 1) + '_scale'] == undefined || this.state['h_img_' + (idx + 1) + '_scale'] == 0 ? 1 : this.state['h_img_' + (idx + 1) + '_scale']}
                                                                                                value={this.state['h_img_' + (idx + 1) + '_scale'] == null || this.state['h_img_' + (idx + 1) + '_scale'] == undefined || this.state['h_img_' + (idx + 1) + '_scale'] == 0 ? 1 : this.state['h_img_' + (idx + 1) + '_scale']}
                                                                                                getAriaValueText={(value) => this.getImgScaleValueText(value)}
                                                                                                step={0.1}
                                                                                                min={0.1}
                                                                                                max={2.5}
                                                                                                valueLabelDisplay="auto"
                                                                                                sx={{
                                                                                                    color: "#00e2e6",
                                                                                                    width: "95%",
                                                                                                    margin: "0 auto"
                                                                                                }}
                                                                                                onChange={(e) => this.handleImgScaleOnChange(e, "h", (idx + 1))}
                                                                                            />
                                                                                            {!(this.state.h_is_youtube_link_use == "Y" && this.state.h_user_img_list[idx].link_type == "49005" && this.state.h_user_img_list[idx].video_link)
                                                                                            && <div className={"event_img_edit_area_line"}></div>}
                                                                                            {/** 동영상 업로드의 경우 사용할 수 없음 */}
                                                                                            {this.state.h_essential_img_nukki_yn == "Y"
                                                                                            && !(this.state.h_user_img_list[idx].link_type == "49002" && this.state.h_user_img_list[idx].img_link && !this.state.h_user_img_list[idx].img_link_able)
                                                                                            && !(this.state.h_user_img_list[idx].link_type == "49004" && this.state.h_user_img_list[idx].img_link && !this.state.h_user_img_list[idx].img_link_able)
                                                                                            && !(this.state.h_is_youtube_link_use == "Y" && this.state.h_user_img_list[idx].link_type == "49005" && this.state.h_user_img_list[idx].video_link)
                                                                                                ?
                                                                                                <>
                                                                                                    <div style={{
                                                                                                        display: "flex",
                                                                                                        flexWrap: "nowrap",
                                                                                                        justifyContent: "space-between",
                                                                                                        width: "100%",
                                                                                                        marginTop: "10px"
                                                                                                    }}>
                                                                                                        <div style={{
                                                                                                            marginLeft: "6px",
                                                                                                            fontSize: "15px"
                                                                                                        }}>AI 배경 제거
                                                                                                        </div>
                                                                                                        <ToggleButton
                                                                                                            id={"h_nukki_yn_" + idx}
                                                                                                            checked={this.state["h_" + h_position.position_name + "_nukki_yn"] == "Y"}
                                                                                                            onClickOption={(e) => this.onClickRemoveBackground(e, "h", idx + 1)}
                                                                                                        />
                                                                                                    </div>
                                                                                                    {this.state["h_" + h_position.position_name + "_nukki_yn"] == "Y"
                                                                                                        ? <>
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    display: "flex",
                                                                                                                    flexWrap: "nowrap",
                                                                                                                    justifyContent: "space-between",
                                                                                                                    width: "100%",
                                                                                                                    marginTop: "10px"
                                                                                                                }}>
                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        marginLeft: "6px",
                                                                                                                        fontSize: "15px"
                                                                                                                    }}>AI
                                                                                                                    윤곽
                                                                                                                    보정
                                                                                                                </div>
                                                                                                                <ToggleButton
                                                                                                                    id={"h_nukki_ppm_state_" + idx}
                                                                                                                    checked={this.state.h_nukki_ppm_state[idx]}
                                                                                                                    onClickOption={(e) => this.changeNukkiPPMstate(e, "h", idx + 1)}
                                                                                                                    disabled={this.state.h_nukki_version_list[idx] == "3"}
                                                                                                                />
                                                                                                            </div>
                                                                                                            <RCSlider
                                                                                                                className={"event_version_slider"}
                                                                                                                dots={true}
                                                                                                                marks={{
                                                                                                                    1: '1타입',
                                                                                                                    2: '2타입',
                                                                                                                    3: '3타입',
                                                                                                                }}
                                                                                                                defaultValue={1}
                                                                                                                value={4 - Number(this.state.h_nukki_version_list[idx])}
                                                                                                                min={1}
                                                                                                                max={3}
                                                                                                                tabIndex={-1}
                                                                                                                onChange={(value) => this.onChangeVersionSlider(4 -  value, "h", idx + 1)}
                                                                                                                onAfterChange={(value) => this.onChangeVersionSlider(4 - value, "h", idx + 1, true)}
                                                                                                            />
                                                                                                        </>
                                                                                                        : ""}
                                                                                                </>
                                                                                                : ""}
                                                                                            <button
                                                                                                className="btn-175-b event_select_img_color_btn"
                                                                                                onClick={() => this.onSelectImgLinkColor("h", idx)}
                                                                                                disabled={((this.state.h_user_img_list[idx].link_type == "49002" || this.state.h_user_img_list[idx].link_type == "49004") && this.state.h_user_img_list[idx].img_link && !this.state.h_user_img_list[idx].img_link_able) ? true : false}
                                                                                            >
                                                                                                AI 배경 컬러 적용하기
                                                                                            </button>
                                                                                            {this.state["h_" + h_position.position_name + "_nukki_yn"] == "Y"
                                                                                                ? <div
                                                                                                    className={"event_img_edit_info_text"}>※
                                                                                                    배경 제거 1타입으로 설정 시, AI
                                                                                                    윤곽 보정 기능 사용이
                                                                                                    불가합니다.</div>
                                                                                                : ""}
                                                                                        </>
                                                                                    : ""}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : ""}
                                                                </>
                                                            ))}
                                                        </div>
                                                    </>
                                                :   ""
                                                } 
                                            </div>
                                        </div>
                                    : ""
                                }
                                </>
                            :   <div className="m-t-43 m-l-29">
                                    <div className="flexBox"> 
                                            <div className="icn_round_blue_small"></div>
                                            &nbsp;&nbsp;
                                            <div className="form_title" style={{marginTop: "auto", marginBottom: "auto"}}>
                                            세로형 배너 디자인을 선택해 주세요.
                                        </div>
                                    </div>
                                    <div className="flexBox m-t-21 m-b-20 m-l-34 width-100-34px">
                                        <div style={{color:"#FF7B7B", margin:"auto 0", fontSize:"15px"}}><b>* 적용범위 - 페이지와 배너를 먼저 선택 해주세요.</b></div>
                                        <div className="btn-175-b cursor-pointer" style={{width: "80px", height:"40px", lineHeight:"41px", marginLeft:"20px"}} onClick={e => this.onClickShortcutFocusing(e, "iframe_page_id_select")}>바로가기</div>
                                    </div>    
                                </div>
                        }
                        
                        <div className="form_primary_title m-t-43">
                            색상 편집
                        </div>
                        <div className="m-t-43 m-l-29">
                            <div className="flexBox"> 
                                <div className="icn_round_blue_small"></div>
                                &nbsp;&nbsp;
                                <div className="form_title" style={{marginTop: "auto", marginBottom: "auto"}}>배너에 들어갈 색상을 선택해 주세요.</div>
                            </div>
                            <div className="margin-vertical-auto flexBox m-l-34" style={{width:"98%", height:"auto" }}>
                            {
                                
                                this.state.w_color1_is_use == "N" && this.state.h_color1_is_use == "N"
                                    &&  this.state.w_color2_is_use == "N" && this.state.h_color2_is_use == "N"
                                    &&  this.state.w_color3_is_use == "N" && this.state.h_color3_is_use == "N"
                                    &&  this.state.w_color4_is_use == "N" && this.state.h_color4_is_use == "N" 
                                    &&  this.state.w_color_etc_is_use == "N" && this.state.h_color_etc_is_use == "N"
                                ? <div className="m-t-32" style={{color:"#10CFC9", fontSize:"15px"}}><b>* 색상을 선택하지 않아도 됩니다.</b></div> 
                                : this.state.w_color1_is_use != "Y" && this.state.h_color1_is_use != "Y"
                                    &&  this.state.w_color2_is_use != "Y" && this.state.h_color2_is_use != "Y"
                                    &&  this.state.w_color3_is_use != "Y" && this.state.h_color3_is_use != "Y"
                                    &&  this.state.w_color4_is_use != "Y" && this.state.h_color4_is_use != "Y"
                                    &&  this.state.w_color_etc_is_use != "Y" && this.state.h_color_etc_is_use != "Y"
                                    ?   <div className="m-t-32" style={{color:"#FF7B7B", fontSize:"15px"}}><b>* 디자인 설정 - 배너 디자인을 먼저 선택 해주세요.</b></div>   
                                    :   "" 
                            }    

                            {
                                (this.state.pc_banner_ratio_list.length > 0 && this.state.w_color1_is_use == "Y") // 가로형 여부
                                || (this.state.mobile_banner_ratio_list.length > 0 && this.state.h_color1_is_use == "Y") // 세로형 여부
                                ?   <div className="color_area">
                                        <div className="color_thumb_area">
                                            <div className="color_thumb_area color_title">
                                                {
                                                    this.state.pc_banner_ratio_list.length > 0 && this.state.w_color1_is_use == "Y" // 가로형
                                                    ? "가로형"
                                                    : ""
                                                }
                                                {
                                                    this.state.mobile_banner_ratio_list.length > 0 // 세로형 여부
                                                    ?   this.state.pc_banner_ratio_list.length > 0 && this.state.w_color1_is_use == "Y" && this.state.h_color1_is_use == "Y"  // 세로 색상 사용중인데 가로 색상도 사용중인 경우
                                                        ?   " / 세로형"
                                                        :   this.state.h_color1_is_use == "Y"  // 세로 색상만 사용중인 경우
                                                            ?   "세로형"
                                                            :   ""
                                                    :   ""
                                                }
                                                {
                                                    " 배너 메인 컬러"+(color_title_num_p = color_title_num_p+1)
                                                }
                                            </div>
                                            <div className="grey_radius_box flexBox width-220px">
                                                <div className="black_radius_box" style={{flex:"1", height:"33px", background:this.state.background}}></div>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                {
                                                    this.state.is_expand_color1 == true
                                                    ? <div className="icn_four_ractangle_on cursor-pointer" onClick={e => this.onClickRactangleColorButton(e, "is_expand_color1")}></div>
                                                    : <div className="icn_four_ractangle cursor-pointer" onClick={e => this.onClickRactangleColorButton(e, "is_expand_color1")}></div>
                                                }
                                            </div>   
                                        </div>
                                        {   this.state.is_expand_color1
                                            ?   <div className="color_detail_area_first">
                                                <SketchPicker
                                                    presetColors = {presetColors}
                                                    color={ this.state.background }
                                                    onChange={e => this.handleChange(e)}
                                                    onChangeComplete={e => this.handleChange(e, true)}
                                                    disableAlpha
                                                />
                                                </div>
                                            : ""
                                        }       
                                    </div>
                                : ""
                            }
                            {
                                (this.state.pc_banner_ratio_list.length > 0 && this.state.w_color2_is_use == "Y") // 가로형 여부
                                || (this.state.mobile_banner_ratio_list.length > 0 && this.state.h_color2_is_use == "Y") // 세로형 여부
                                ?   <div className="color_area">
                                        <div className="color_thumb_area m-l-15">
                                            <div className="color_title">
                                                {
                                                    this.state.pc_banner_ratio_list.length > 0 && this.state.w_color2_is_use == "Y" // 가로형
                                                    ? "가로형"
                                                    : ""
                                                }
                                                {
                                                    this.state.mobile_banner_ratio_list.length > 0 // 세로형 여부
                                                    ?   this.state.pc_banner_ratio_list.length > 0 && this.state.w_color2_is_use == "Y" && this.state.h_color2_is_use == "Y"  // 세로 색상 사용중인데 가로 색상도 사용중인 경우
                                                        ?   " / 세로형"
                                                        :   this.state.h_color2_is_use == "Y"  // 세로 색상만 사용중인 경우
                                                            ?   "세로형"
                                                            :   ""
                                                    :   ""
                                                }
                                                {
                                                    " 배너 메인 컬러"+(color_title_num_p = color_title_num_p+1)
                                                }
                                            </div>
                                            <div className="grey_radius_box flexBox width-220px">
                                                <div className="black_radius_box" style={{flex:"1", height:"33px", background:this.state.background2}}></div>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                {
                                                    this.state.is_expand_color2
                                                    ? <div className="icn_four_ractangle_on cursor-pointer" onClick={e => this.onClickRactangleColorButton(e, "is_expand_color2")}></div>
                                                    : <div className="icn_four_ractangle cursor-pointer" onClick={e => this.onClickRactangleColorButton(e, "is_expand_color2")}></div>
                                                }
                                            </div>
                                        </div>
                                        {   this.state.is_expand_color2
                                            ?   <div className="color_detail_area">
                                                <SketchPicker
                                                    presetColors = {presetColors}
                                                    color={ this.state.background2 }
                                                    onChange={e => this.handleChange2(e)}
                                                    onChangeComplete={e => this.handleChange2(e, true)}
                                                    disableAlpha
                                                />
                                                </div>
                                            : ""    
                                        }
                                    </div>
                                :   ""
                            }
                            {
                                (this.state.pc_banner_ratio_list.length > 0 && this.state.w_color3_is_use == "Y") // 가로형 여부
                                || (this.state.mobile_banner_ratio_list.length > 0 && this.state.h_color3_is_use == "Y") // 세로형 여부
                                ?   <div className="color_area">
                                        <div className="color_thumb_area m-l-15">
                                            <div className="color_title">
                                                {
                                                    this.state.pc_banner_ratio_list.length > 0 && this.state.w_color3_is_use == "Y" // 가로형
                                                    ? "가로형"
                                                    : ""
                                                }
                                                {
                                                    this.state.mobile_banner_ratio_list.length > 0 // 세로형 여부
                                                    ?   this.state.pc_banner_ratio_list.length > 0 && this.state.w_color3_is_use == "Y" && this.state.h_color3_is_use == "Y"  // 세로 색상 사용중인데 가로 색상도 사용중인 경우
                                                        ?   " / 세로형"
                                                        :   this.state.h_color3_is_use == "Y"  // 세로 색상만 사용중인 경우
                                                            ?   "세로형"
                                                            :   ""
                                                    :   ""
                                                }
                                                {
                                                    " 배너 메인 컬러"+(color_title_num_p = color_title_num_p+1)
                                                }
                                            </div>
                                            <div className="grey_radius_box flexBox width-220px">
                                                <div className="black_radius_box" style={{flex:"1", height:"33px", background:this.state.background3}}></div>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                {
                                                    this.state.is_expand_color3
                                                    ? <div className="icn_four_ractangle_on cursor-pointer" onClick={e => this.onClickRactangleColorButton(e, "is_expand_color3")}></div>
                                                    : <div className="icn_four_ractangle cursor-pointer" onClick={e => this.onClickRactangleColorButton(e, "is_expand_color3")}></div>
                                                }
                                            </div>   
                                        </div>
                                        {   this.state.is_expand_color3
                                            ?   <div className="color_detail_area">
                                                <SketchPicker
                                                    presetColors = {presetColors}
                                                    color={ this.state.background3 }
                                                    onChange={e => this.handleChange3(e)}
                                                    onChangeComplete={e => this.handleChange3(e, true)}
                                                    disableAlpha
                                                />
                                                </div>
                                            : ""
                                        }        
                                    </div>
                                :   ""
                            }
                            {
                                (this.state.pc_banner_ratio_list.length > 0 && this.state.w_color4_is_use == "Y") // 가로형 여부
                                || (this.state.mobile_banner_ratio_list.length > 0 && this.state.h_color4_is_use == "Y") // 세로형 여부
                                ?   <div className="color_area">
                                        <div className="color_thumb_area m-l-15">
                                            <div className="color_title">
                                                {
                                                    this.state.pc_banner_ratio_list.length > 0 && this.state.w_color4_is_use == "Y" // 가로형
                                                    ? "가로형"
                                                    : ""
                                                }
                                                {
                                                    this.state.mobile_banner_ratio_list.length > 0 // 세로형 여부
                                                    ?   this.state.pc_banner_ratio_list.length > 0 && this.state.w_color4_is_use == "Y" && this.state.h_color4_is_use == "Y"  // 세로 색상 사용중인데 가로 색상도 사용중인 경우
                                                        ?   " / 세로형"
                                                        :   this.state.h_color4_is_use == "Y"  // 세로 색상만 사용중인 경우
                                                            ?   "세로형"
                                                            :   ""
                                                    :   ""
                                                }
                                                {
                                                    " 배너 메인 컬러"+(color_title_num_p = color_title_num_p+1)
                                                }
                                            </div>
                                            <div className="grey_radius_box flexBox width-220px">
                                                <div className="black_radius_box" style={{flex:"1", height:"33px", background:this.state.background4}}></div>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                {
                                                    this.state.is_expand_color4
                                                    ? <div className="icn_four_ractangle_on cursor-pointer" onClick={e => this.onClickRactangleColorButton(e, "is_expand_color4")}></div>
                                                    : <div className="icn_four_ractangle cursor-pointer" onClick={e => this.onClickRactangleColorButton(e, "is_expand_color4")}></div>
                                                }
                                            </div>   
                                        </div>
                                        {   this.state.is_expand_color4
                                            ?   <div className="color_detail_area">
                                                <SketchPicker
                                                    presetColors = {presetColors}
                                                    color={ this.state.background4 }
                                                    onChange={e => this.handleChange4(e)}
                                                    onChangeComplete={e => this.handleChange4(e, true)}
                                                    disableAlpha
                                                />
                                                </div>
                                            : ""
                                        }      
                                    </div>
                                :   ""
                            }
                            {
                                this.state.pc_banner_ratio_list.length > 0 && this.state.w_color_etc_is_use == "Y"
                                ?
                                    <div className="color_area">
                                        <div className="color_thumb_area m-l-15">
                                            <div className="color_title">
                                                가로형 배너 서브 컬러
                                            </div>
                                            <div className="grey_radius_box flexBox width-220px">
                                                <div className="black_radius_box" style={{flex:"1", height:"33px", background:this.state.w_color_etc}}></div>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                {
                                                    this.state.is_expand_w_color_etc
                                                    ? <div className="icn_four_ractangle_on cursor-pointer" onClick={e => this.onClickRactangleColorButton(e, "is_expand_w_color_etc")}></div>
                                                    : <div className="icn_four_ractangle cursor-pointer" onClick={e => this.onClickRactangleColorButton(e, "is_expand_w_color_etc")}></div>
                                                }
                                            </div>   
                                        </div>
                                        {   this.state.is_expand_w_color_etc
                                            ?   <div className="color_detail_area">
                                                <SketchPicker
                                                    presetColors = {presetColors}
                                                    color={ this.state.w_color_etc}
                                                    onChange={ e => this.handleChangeTextColor(e, "w_color_etc") }
                                                    onChangeComplete={ e => this.handleChangeTextColor(e, "w_color_etc", true) }
                                                    disableAlpha
                                                />
                                                </div>
                                            : ""
                                        }      
                                    </div>
                                : ""
                            }
                            {
                                this.state.mobile_banner_ratio_list.length > 0 && this.state.h_color_etc_is_use == "Y"
                                ?
                                    <div className="color_area">
                                        <div className="color_thumb_area m-l-15">
                                            <div className="color_title">
                                                세로형 배너 서브 컬러
                                            </div>
                                            <div className="grey_radius_box flexBox width-220px">
                                                <div className="black_radius_box" style={{flex:"1", height:"33px", background:this.state.h_color_etc}}></div>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                {
                                                    this.state.is_expand_h_color_etc
                                                    ? <div className="icn_four_ractangle_on cursor-pointer" onClick={e => this.onClickRactangleColorButton(e, "is_expand_h_color_etc")}></div>
                                                    : <div className="icn_four_ractangle cursor-pointer" onClick={e => this.onClickRactangleColorButton(e, "is_expand_h_color_etc")}></div>
                                                }
                                            </div>   
                                        </div>
                                        {   this.state.is_expand_h_color_etc
                                            ?   <div className="color_detail_area">
                                                <SketchPicker
                                                    presetColors = {presetColors}
                                                    color={ this.state.h_color_etc}
                                                    onChange={ e => this.handleChangeTextColor(e, "h_color_etc") }
                                                    onChangeComplete={ e => this.handleChangeTextColor(e, "h_color_etc", true) }
                                                    disableAlpha
                                                />
                                                </div>
                                            : ""
                                        }      
                                    </div>
                                : ""
                            }
                            </div>
                            <div style={{marginTop:"20px"}}>
                                <div className="margin-vertical-auto flexBox m-l-34 m-t-21">
                                    <div className="icn_warning_round"></div>
                                    <div style={{marginLeft:"10px", flex:"1"}}>서브 컬러 요소로는 기간, 가격, 선 등이 있습니다.</div>
                                </div>
                                <div className="margin-vertical-auto flexBox m-l-34 m-t-21">
                                    <div className="icn_warning_round"></div>
                                    <div style={{marginLeft:"10px", flex:"1"}}>서브 컬러 색상은 배너 디자인을 선택할 때마다 해당 디자인의 기본 색상으로 변경됩니다. 디자인을 최종 선택하신 뒤에 색상을 선택해 주세요.</div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="form_primary_title m-t-43">
                        문구 편집
                        </div>
                        <div className="m-t-43 m-l-29">
                            <div className="flexBox"> 
                                <div className="icn_round_blue_small"></div>
                                &nbsp;&nbsp;
                                <div className="form_title" style={{marginTop: "auto", marginBottom: "auto"}}>배너에 들어갈 문구를 편집해 주세요.</div>
                            </div>
                            <div className="flexBox m-t-32" style={{alignSelf:"center"}}> 
                                <div className="flexBox m-l-34">
                                    <div className="icn_ractangle_radius_line">가</div>
                                    &nbsp;&nbsp;
                                    <div className="form_sub_title" style={{marginTop: "auto", marginBottom: "auto"}}>메인 텍스트</div>
                                </div>
                            </div>
                            <div class="m-l-34 event_main_title_area">
                                <div>
                                    <div className="input_text_title_d">문구 키워드</div>
                                    <input id="input_main_keyword" type="text" className="border_form_radius_b f-s-15 input_width_100 input_height_35px" value={this.state.gpt_main_keyword} onChange={e => this.onChangeMainKeyword(e)} placeholder="상품명을 입력하고 AI 문구를 추천 받아 보세요."></input>
                                </div>
                                <div class="event_auto_main_text_area">
                                    <button class="btn-175-b" onClick={() => this.getAutoTitle()}>AI 문구 추천받기</button>
                                    <div>
                                        <select className="border_form_radius_b_h_42" onChange={this.handleLanguageChange}
                                                value={this.state.language_code}>
                                            <option value="">
                                                번역하기
                                            </option>
                                            {
                                                this.state.language_code_list.length > 0
                                                ?
                                                    <>
                                                        {
                                                            this.state.language_code_list.map((language, idx) => (
                                                                <option value={language.code_cd} language={language.val1}>
                                                                    {language.code_name}
                                                                </option>
                                                            ))
                                                        }
                                                    </>
                                                : ''
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <p className="notice_ai_recommend_main_text"><i className={"fa fa-exclamation-circle"} aria-hidden="true"></i>&nbsp;키워드를 변경하시면 추천 문구도 입력하신 키워드에 따라 변경되어요.</p>
                            {/*<div className="wrap_event_title_color_area">*/}
                            {/*    <div className="event_title_color_area">*/}
                            {/*        {*/}
                            {/*            this.state.pc_banner_ratio_list.length > 0 // 가로형 여부*/}
                            {/*                ? <div className="color_area">*/}
                            {/*                    <div className="color_thumb_area">*/}
                            {/*                        <div className="color_thumb_area color_title">*/}
                            {/*                            가로형 메인 텍스트 기본 컬러*/}
                            {/*                        </div>*/}
                            {/*                        <div className="grey_radius_box flexBox" style={{width: "100%"}}>*/}
                            {/*                            <div className="black_radius_box" style={{*/}
                            {/*                                flex: "1",*/}
                            {/*                                height: "33px",*/}
                            {/*                                background: this.state.w_large_text_color*/}
                            {/*                            }}></div>*/}
                            {/*                            &nbsp;&nbsp;&nbsp;&nbsp;*/}
                            {/*                            {*/}
                            {/*                                this.state.is_expand_w_large_text_color*/}
                            {/*                                    ? <div className="icn_four_ractangle_on cursor-pointer"*/}
                            {/*                                           onClick={e => this.onClickRactangleColorButton(e, "is_expand_w_large_text_color")}></div>*/}
                            {/*                                    : <div className="icn_four_ractangle cursor-pointer"*/}
                            {/*                                           onClick={e => this.onClickRactangleColorButton(e, "is_expand_w_large_text_color")}></div>*/}
                            {/*                            }*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                    {this.state.is_expand_w_large_text_color*/}
                            {/*                        ? <div className="color_detail_area_first">*/}
                            {/*                            <SketchPicker*/}
                            {/*                                presetColors={presetColors}*/}
                            {/*                                color={this.state.w_large_text_color}*/}
                            {/*                                onChange={e => this.handleChangeTextColor(e, "w_large_text_color")}*/}
                            {/*                                onChangeComplete={e => this.handleChangeTextColor(e, "w_large_text_color", true)}*/}
                            {/*                                disableAlpha*/}
                            {/*                            />*/}
                            {/*                        </div>*/}
                            {/*                        : ""*/}
                            {/*                    }*/}
                            {/*                </div>*/}
                            {/*                : ""*/}
                            {/*        }*/}

                            {/*        {*/}
                            {/*            this.state.mobile_banner_ratio_list.length > 0 // 세로형 여부*/}
                            {/*                ? <div className="color_area">*/}
                            {/*                    <div className="color_thumb_area">*/}
                            {/*                        <div className="color_thumb_area color_title">*/}
                            {/*                            세로형 메인 텍스트 기본 컬러*/}
                            {/*                        </div>*/}
                            {/*                        <div className="grey_radius_box flexBox" style={{width: "100%"}}>*/}
                            {/*                            <div className="black_radius_box" style={{*/}
                            {/*                                flex: "1",*/}
                            {/*                                height: "33px",*/}
                            {/*                                background: this.state.h_large_text_color*/}
                            {/*                            }}></div>*/}
                            {/*                            &nbsp;&nbsp;&nbsp;&nbsp;*/}
                            {/*                            {*/}
                            {/*                                this.state.is_expand_h_large_text_color*/}
                            {/*                                    ? <div className="icn_four_ractangle_on cursor-pointer"*/}
                            {/*                                           onClick={e => this.onClickRactangleColorButton(e, "is_expand_h_large_text_color")}></div>*/}
                            {/*                                    : <div className="icn_four_ractangle cursor-pointer"*/}
                            {/*                                           onClick={e => this.onClickRactangleColorButton(e, "is_expand_h_large_text_color")}></div>*/}
                            {/*                            }*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                    {this.state.is_expand_h_large_text_color*/}
                            {/*                        ? <div className="color_detail_area_first">*/}
                            {/*                            <SketchPicker*/}
                            {/*                                presetColors={presetColors}*/}
                            {/*                                color={this.state.h_large_text_color}*/}
                            {/*                                onChange={e => this.handleChangeTextColor(e, "h_large_text_color")}*/}
                            {/*                                onChangeComplete={e => this.handleChangeTextColor(e, "h_large_text_color", true)}*/}
                            {/*                                disableAlpha*/}
                            {/*                            />*/}
                            {/*                        </div>*/}
                            {/*                        : ""*/}
                            {/*                    }*/}
                            {/*                </div>*/}
                            {/*                : ""*/}
                            {/*        }*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<p className="notice_event_title_color_area"><i className={"fa fa-exclamation-circle"} aria-hidden="true"></i>&nbsp;가로형/세로형 텍스트 기본 컬러보다 에디터의 글자색이 우선순위로 적용되어요.</p>*/}
                            {/iPad|iPhone|iPod/.test(navigator.platform) ?
                                <></>
                                :
                                navigator.userAgent.includes("Chrome") ?
                                    <div style={{position: "relative"}}>
                                        <TextEditor id={"mainTextEditor"} onBannerTemplateChange={this.state.event_type_cd} onMobileBannerTemplateChange={this.state.event_mobile_type_cd} checkImgLinkUseAble={this.checkImgLinkUseAble} />
                                    </div>
                                    :
                                    <></>
                            }
                            <div>
                                <div className="input_text_title eventSettingTitle m-l-34" style={{display: "none"}}>
                                    <label style={{marginTop: "auto", marginBottom: "auto", color: "#333333"}}>메인 텍스트 문구 내용</label>
                                </div>
                                <div className="margin-vertical-auto m-l-34">
                                    {/*<textarea className="border_form_radius_b f-s-15 mobile-width-100" id="title" style={{width:"100%", maxWidth:"800px", height:"154px"}}*/}
                                    {/*    value={this.state.auto_title} onChange={e => this.onChangeTitle(e, "auto_title")} onBlur={this.handleTitleBlur} placeholder="직접 입력하시거나 AI 문구 추천을 통해 입력해 주세요.">*/}
                                    {/*        {this.state.title}*/}
                                    {/*</textarea>*/}
                                    {/iPad|iPhone|iPod/.test(navigator.platform) ?
                                        <Editor
                                            editorState={this.state.mainEditorState}
                                            onEditorStateChange={this.onMainEditorStateChange}
                                            toolbarClassName="main_toolbar_wysiwyg"
                                            wrapperClassName="main_wrapper_wysiwyg"
                                            editorClassName="main_editor_wysiwyg"
                                            toolbar={{
                                                options: [
                                                    'fontFamily',
                                                    'inline',
                                                    'list',
                                                    'colorPicker',
                                                    'image',
                                                    'emoji',
                                                    'history',
                                                    'remove',
                                                ],
                                                fontFamily: {
                                                    options: fontArray,
                                                },
                                                inline: {
                                                    options: ['bold', 'italic', 'underline', 'strikethrough'],
                                                },
                                                list: {
                                                    options: ['unordered', 'ordered'],
                                                },
                                                image: {
                                                    urlEnabled: true,
                                                    uploadEnabled: true,
                                                    alignmentEnabled: false,
                                                    uploadCallback: this.uploadCallback,
                                                    previewImage: true,
                                                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                                    alt: { present: false, mandatory: false },
                                                    defaultSize: {
                                                        height: '1em',
                                                        width: 'auto',
                                                    },
                                                }
                                            }}
                                            placeholder="텍스트 내용을 입력하고 드래그를 통해 자유롭게 편집해 보세요."
                                            localization={{ locale: "ko" }}
                                            toolbarStyle={{
                                                border: "1px solid #DBE7F2",
                                                background : "#DBE7F2",
                                                width: "100%",
                                                maxWidth: "800px",
                                                borderRadius: "13px 13px 0 0",
                                                padding: "15px 18px 10px",
                                            }}
                                            editorStyle={{
                                                maxWidth: "800px",
                                                minHeight: "200px",
                                                maxHeight: "200px",
                                                width: "100%",
                                                border: "1px solid #DBDDE4",
                                                padding: "10px 20px",
                                                borderRadius: "0 0 13px 13px",
                                            }}
                                            autocomplete={false}
                                            handlePastedText={() => false}
                                        />
                                        // <textarea className="border_form_radius_b f-s-15 mobile-width-100" id="title" style={{width:"100%", maxWidth:"800px", height:"154px", borderRadius: "0 0 13px 13px"}}
                                        //             value={this.state.auto_title} onChange={e => this.onChangeTitle(e, "auto_title")} onBlur={this.handleTitleBlur} placeholder="직접 입력하시거나 AI 문구 추천을 통해 입력해 주세요.">
                                        // {this.state.title}
                                        // </textarea>
                                    :
                                        navigator.userAgent.includes("Chrome") ?
                                            <ContentEditable className="f-s-15 mobile-width-100 text_editor_title mainTextEditor" id="title" style={{width:"100%", maxWidth:"800px", height:"154px", overflow: "auto", padding: "20px", border: "1.5px solid #DBDDE4", borderRadius: "0 0 13px 13px"}}
                                                         value={this.state.auto_title} onMouseDown={initSelectionOnMouseDown} onChange={e => this.onChangeTitle(e, "auto_title")} onBlur={this.handleTitleBlur} placeholder="텍스트 내용을 입력하고 드래그를 통해 자유롭게 편집해 보세요." html={this.state.title.replace("font-size: 15px;", "")}/>
                                            :
                                            <Editor
                                                editorState={this.state.mainEditorState}
                                                onEditorStateChange={this.onMainEditorStateChange}
                                                toolbarClassName="main_toolbar_wysiwyg"
                                                wrapperClassName="main_wrapper_wysiwyg"
                                                editorClassName="main_editor_wysiwyg"
                                                toolbar={{
                                                    options: [
                                                        'fontFamily',
                                                        'inline',
                                                        'list',
                                                        'colorPicker',
                                                        'image',
                                                        'emoji',
                                                        'history',
                                                        'remove',
                                                    ],
                                                    fontFamily: {
                                                        options: fontArray,
                                                    },
                                                    inline: {
                                                        options: ['bold', 'italic', 'underline', 'strikethrough'],
                                                    },
                                                    list: {
                                                        options: ['unordered', 'ordered'],
                                                    },
                                                    image: {
                                                        urlEnabled: true,
                                                        uploadEnabled: true,
                                                        alignmentEnabled: false,
                                                        uploadCallback: this.uploadCallback,
                                                        previewImage: true,
                                                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                                        alt: { present: false, mandatory: false },
                                                        defaultSize: {
                                                            height: '1em',
                                                            width: 'auto',
                                                        },
                                                    }
                                                }}
                                                placeholder="텍스트 내용을 입력하고 드래그를 통해 자유롭게 편집해 보세요."
                                                localization={{ locale: "ko" }}
                                                toolbarStyle={{
                                                    border: "1px solid #DBE7F2",
                                                    background : "#DBE7F2",
                                                    width: "100%",
                                                    maxWidth: "800px",
                                                    borderRadius: "13px 13px 0 0",
                                                    padding: "15px 18px 10px",
                                                }}
                                                editorStyle={{
                                                    maxWidth: "800px",
                                                    minHeight: "200px",
                                                    maxHeight: "200px",
                                                    width: "100%",
                                                    border: "1px solid #DBDDE4",
                                                    padding: "10px 20px",
                                                    borderRadius: "0 0 13px 13px",
                                                }}
                                                autocomplete={false}
                                                handlePastedText={() => false}
                                            />
                                    }
                                </div>
                                {/*<div className="margin-vertical-auto flexBox m-l-34 m-t-21">*/}
                                {/*    <div className="icn_warning_round"></div>*/}
                                {/*    <div style={{marginLeft:"10px", flex:"1"}}>*/}
                                {/*        키워드를 변경하시면 추천 문구도 입력하신 키워드에 따라 변경됩니다.*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                {/* main_text_box start */}
                                <div id="main_text_box" className="m-l-34">
                                    {this.state.is_show_pc_banner_area &&
                                        <div className={this.state.is_show_pc_banner_area && this.state.is_show_mobile_banner_area ? "text_box_width border_form_radius text_box_margin_right" : "text_box_width border_form_radius"}>
                                            <label className={"label_text_box_width"}>가로형 메인 텍스트</label>
                                            {/* 텍스트 컬러 시작 */}
                                            <div className={"event_img_position_area_title"}>텍스트 컬러</div>
                                            <div className="event_title_color_area">
                                                    {
                                                        this.state.pc_banner_ratio_list.length > 0 // 가로형 여부
                                                            ? <div className="color_area" style={{marginRight: "0"}}>
                                                                <div className="color_thumb_area">
                                                                    {/*<div className="color_thumb_area color_title">*/}
                                                                    {/*    가로형 메인 텍스트 기본 컬러*/}
                                                                    {/*</div>*/}
                                                                    <div className="grey_radius_box flexBox" style={{width: "100%"}}>
                                                                        <div className="black_radius_box" style={{
                                                                            flex: "1",
                                                                            height: "33px",
                                                                            background: this.state.w_large_text_color
                                                                        }}></div>
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {
                                                                            this.state.is_expand_w_large_text_color
                                                                                ? <div className="icn_four_ractangle_on cursor-pointer"
                                                                                       onClick={e => this.onClickRactangleColorButton(e, "is_expand_w_large_text_color")}></div>
                                                                                : <div className="icn_four_ractangle cursor-pointer"
                                                                                       onClick={e => this.onClickRactangleColorButton(e, "is_expand_w_large_text_color")}></div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {this.state.is_expand_w_large_text_color
                                                                    ? <div className="color_detail_area_first">
                                                                        <SketchPicker
                                                                            presetColors={presetColors}
                                                                            color={this.state.w_large_text_color}
                                                                            onChange={e => this.handleChangeTextColor(e, "w_large_text_color")}
                                                                            onChangeComplete={e => this.handleChangeTextColor(e, "w_large_text_color", true)}
                                                                            disableAlpha
                                                                        />
                                                                    </div>
                                                                    : ""
                                                                }
                                                            </div>
                                                            : ""
                                                    }
                                                </div>
                                            <p className="notice_event_title_color_area"><i className={"fa fa-exclamation-circle"} aria-hidden="true"></i>&nbsp;텍스트 컬러보다 에디터의 글자색이 우선순위로 적용되어요.</p>
                                            <div className={"event_img_edit_area_line"}></div>
                                            {/* 텍스트 컬러 끝 */}
                                            {/* 텍스트 위치 시작 */}
                                            <div className={"event_img_position_area_title"}>텍스트 위치</div>
                                            <div className={"text_position_main"}>
                                                <div className="flexBox" style={{
                                                    position: "relative",
                                                    width: "120px",
                                                    height: "120px",
                                                    justifyContent: "center",
                                                    marginTop: "8px"
                                                }}>
                                                    <div className="flexBox ie_position_relative"
                                                         style={{zIndex: 1, position: "absolute", alignSelf: "center"}}>
                                                        <div className="btn_keypad_arrow_area"
                                                             onClick={e => this.movePositionOnClick(e, "w_main_txt_position_left", "w_main_txt_position_right", 1)}/>
                                                        <div className="btn_keypad_center_area"
                                                             onClick={e => this.moveTextPositionResetOnClick(e, "w", "main")}>
                                                            <div className="btn_keypad_center_area_reset">
                                                            </div>
                                                        </div>
                                                        <div className="btn_keypad_arrow_area"
                                                             style={{transform: "rotate(180deg)"}}
                                                             onClick={e => this.movePositionOnClick(e, "w_main_txt_position_right", "w_main_txt_position_left", 1)}/>
                                                    </div>
                                                    <div className="flexBox ie_left_40px" style={{
                                                        zIndex: 0,
                                                        flexDirection: "column",
                                                        position: "absolute",
                                                        alignSelf: "center"
                                                    }}>
                                                        <div className="btn_keypad_arrow_area"
                                                             style={{transform: "rotate(90deg)"}}
                                                             onClick={e => this.movePositionOnClick(e, "w_main_txt_position_top", "w_main_txt_position_bottom", 1)}/>
                                                        <div className="btn_keypad_center_area">
                                                        </div>
                                                        <div className="btn_keypad_arrow_area"
                                                             style={{transform: "rotate(-90deg)"}}
                                                             onClick={e => this.movePositionOnClick(e, "w_main_txt_position_bottom", "w_main_txt_position_top", 1)}/>
                                                    </div>
                                                </div>

                                                <div className="flexBox" style={{
                                                    position: "relative",
                                                    width: "120px",
                                                    height: "120px",
                                                    justifyContent: "center",
                                                    marginLeft: "20px",
                                                    marginTop: "8px"
                                                }}>
                                                    <div className="flexBox ie_position_relative"
                                                         style={{zIndex: 1, position: "absolute", alignSelf: "center"}}>
                                                        <div className="btn_keypad_number_area">
                                                            <input className="numbersOnly input_banner_img_position"
                                                                   type="text"
                                                                   value={this.state.w_main_txt_position_left < 0 ? this.state.w_main_txt_position_left * -1 : this.state.w_main_txt_position_left}
                                                                   onChange={e => {
                                                                       this.setState({w_main_txt_position_left: e.target.value})
                                                                   }}
                                                                   onKeyPress={e => this.movePositionOnKeyPress(e)}
                                                                   onBlur={e => this.movePositionOnBlur(e, "w_main_txt_position_left", "w_main_txt_position_right")}/>
                                                        </div>
                                                        <div className="btn_keypad_center_area"
                                                             style={{cursor: "default"}}>

                                                        </div>
                                                        <div className="btn_keypad_number_area">
                                                            <input className="numbersOnly input_banner_img_position"
                                                                   type="text"
                                                                   value={this.state.w_main_txt_position_right < 0 ? this.state.w_main_txt_position_right * -1 : this.state.w_main_txt_position_right}
                                                                   onChange={e => {
                                                                       this.setState({w_main_txt_position_right: e.target.value})
                                                                   }}
                                                                   onKeyPress={e => this.movePositionOnKeyPress(e)}
                                                                   onBlur={e => this.movePositionOnBlur(e, "w_main_txt_position_right", "w_main_txt_position_left")}/>
                                                        </div>
                                                    </div>
                                                    <div className="flexBox ie_left_40px" style={{
                                                        zIndex: 0,
                                                        flexDirection: "column",
                                                        position: "absolute",
                                                        alignSelf: "center"
                                                    }}>
                                                        <div className="btn_keypad_number_area">
                                                            <input className="numbersOnly input_banner_img_position"
                                                                   type="text"
                                                                   value={this.state.w_main_txt_position_top < 0 ? this.state.w_main_txt_position_top * -1 : this.state.w_main_txt_position_top}
                                                                   onChange={e => {
                                                                       this.setState({w_main_txt_position_top: e.target.value})
                                                                   }}
                                                                   onKeyPress={e => this.movePositionOnKeyPress(e)}
                                                                   onBlur={e => this.movePositionOnBlur(e, "w_main_txt_position_top", "w_main_txt_position_bottom")}/>
                                                        </div>
                                                        <div className="btn_keypad_center_area"
                                                             style={{cursor: "default"}}>

                                                        </div>
                                                        <div className="btn_keypad_number_area">
                                                            <input className="numbersOnly input_banner_img_position"
                                                                   type="text"
                                                                   value={this.state.w_main_txt_position_bottom < 0 ? this.state.w_main_txt_position_bottom * -1 : this.state.w_main_txt_position_bottom}
                                                                   onChange={e => {
                                                                       this.setState({w_main_txt_position_bottom: e.target.value})
                                                                   }}
                                                                   onKeyPress={e => this.movePositionOnKeyPress(e)}
                                                                   onBlur={e => this.movePositionOnBlur(e, "w_main_txt_position_bottom", "w_main_txt_position_top")}/>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <p className="notice_text_position"><i className={"fa fa-exclamation-circle"} aria-hidden="true"></i>&nbsp;화살표를 클릭하거나 숫자를 적어 위치를 옮겨보세요.(단위: %)</p>
                                            <div className={"event_img_edit_area_line"}></div>
                                            {/* 텍스트 위치 끝 */}
                                            {/* 텍스트 크기 시작 */}
                                            <div className={"event_img_position_area_title"}>텍스트 크기</div>
                                            <div className={"wrap_btn_txt_scale_reset"}>
                                                <div className="btn_txt_scale_reset" onClick={(e) => this.handleTxtScaleResetOnClick(e, "w", "main")}>초기화</div>
                                            </div>
                                            <div className={"text_size_main"}>
                                                <Slider
                                                    className={"w_main_txt_scale_slider"}
                                                    aria-label="Small steps"
                                                    defaultValue={this.state.w_main_txt_scale == null || this.state.w_main_txt_scale == undefined || this.state.w_main_txt_scale == 0 ? 1 : this.state.w_main_txt_scale}
                                                    value={this.state.w_main_txt_scale == null || this.state.w_main_txt_scale == undefined || this.state.w_main_txt_scale == 0 ? 1 : this.state.w_main_txt_scale}
                                                    getAriaValueText={(value) => this.getTxtScaleValueText(value)}
                                                    step={0.1}
                                                    min={0.1}
                                                    max={2.0}
                                                    valueLabelDisplay="auto"
                                                    sx={{
                                                        color: "#00e2e6",
                                                        width: "100%",
                                                        margin: "0 auto"
                                                    }}
                                                    onChange={(e) => this.handleTxtScaleOnChange(e, "w", "main")}
                                                />
                                            </div>
                                            {/* 텍스트 크기 끝 */}
                                        </div>
                                    }
                                    {this.state.is_show_mobile_banner_area &&
                                        <div className="text_box_height border_form_radius">
                                            <label className={"label_text_box_height"}>세로형 메인 텍스트</label>
                                            {/* 텍스트 컬러 시작 */}
                                            <div className={"event_img_position_area_title"}>텍스트 컬러</div>
                                            <div className="event_title_color_area">
                                                    {
                                                        this.state.mobile_banner_ratio_list.length > 0 // 세로형 여부
                                                            ? <div className="color_area" style={{marginRight: "0"}}>
                                                                <div className="color_thumb_area">
                                                                    {/*<div className="color_thumb_area color_title">*/}
                                                                    {/*    세로형 메인 텍스트 기본 컬러*/}
                                                                    {/*</div>*/}
                                                                    <div className="grey_radius_box flexBox" style={{width: "100%"}}>
                                                                        <div className="black_radius_box" style={{
                                                                            flex: "1",
                                                                            height: "33px",
                                                                            background: this.state.h_large_text_color
                                                                        }}></div>
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {
                                                                            this.state.is_expand_h_large_text_color
                                                                                ? <div className="icn_four_ractangle_on cursor-pointer"
                                                                                       onClick={e => this.onClickRactangleColorButton(e, "is_expand_h_large_text_color")}></div>
                                                                                : <div className="icn_four_ractangle cursor-pointer"
                                                                                       onClick={e => this.onClickRactangleColorButton(e, "is_expand_h_large_text_color")}></div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {this.state.is_expand_h_large_text_color
                                                                    ? <div className="color_detail_area_first">
                                                                        <SketchPicker
                                                                            presetColors={presetColors}
                                                                            color={this.state.h_large_text_color}
                                                                            onChange={e => this.handleChangeTextColor(e, "h_large_text_color")}
                                                                            onChangeComplete={e => this.handleChangeTextColor(e, "h_large_text_color", true)}
                                                                            disableAlpha
                                                                        />
                                                                    </div>
                                                                    : ""
                                                                }
                                                            </div>
                                                            : ""
                                                    }
                                                </div>
                                            <p className="notice_event_title_color_area"><i className={"fa fa-exclamation-circle"} aria-hidden="true"></i>&nbsp;텍스트 컬러보다 에디터의 글자색이 우선순위로 적용되어요.</p>
                                            <div className={"event_img_edit_area_line"}></div>
                                            {/* 텍스트 컬러 끝 */}
                                            {/* 텍스트 위치 시작 */}
                                            <div className={"event_img_position_area_title"}>텍스트 위치</div>
                                            <div className={"text_position_main"}>
                                                <div className="flexBox" style={{
                                                    position: "relative",
                                                    width: "120px",
                                                    height: "120px",
                                                    justifyContent: "center",
                                                    marginTop: "8px"
                                                }}>
                                                    <div className="flexBox ie_position_relative"
                                                         style={{zIndex: 1, position: "absolute", alignSelf: "center"}}>
                                                        <div className="btn_keypad_arrow_area"
                                                             onClick={e => this.movePositionOnClick(e, "h_main_txt_position_left", "h_main_txt_position_right", 1)}/>
                                                        <div className="btn_keypad_center_area"
                                                             onClick={e => this.moveTextPositionResetOnClick(e, "h", "main")}>
                                                            <div className="btn_keypad_center_area_reset">
                                                            </div>
                                                        </div>
                                                        <div className="btn_keypad_arrow_area"
                                                             style={{transform: "rotate(180deg)"}}
                                                             onClick={e => this.movePositionOnClick(e, "h_main_txt_position_right", "h_main_txt_position_left", 1)}/>
                                                    </div>
                                                    <div className="flexBox ie_left_40px" style={{
                                                        zIndex: 0,
                                                        flexDirection: "column",
                                                        position: "absolute",
                                                        alignSelf: "center"
                                                    }}>
                                                        <div className="btn_keypad_arrow_area"
                                                             style={{transform: "rotate(90deg)"}}
                                                             onClick={e => this.movePositionOnClick(e, "h_main_txt_position_top", "h_main_txt_position_bottom", 1)}/>
                                                        <div className="btn_keypad_center_area">
                                                        </div>
                                                        <div className="btn_keypad_arrow_area"
                                                             style={{transform: "rotate(-90deg)"}}
                                                             onClick={e => this.movePositionOnClick(e, "h_main_txt_position_bottom", "h_main_txt_position_top", 1)}/>
                                                    </div>
                                                </div>

                                                <div className="flexBox" style={{
                                                    position: "relative",
                                                    width: "120px",
                                                    height: "120px",
                                                    justifyContent: "center",
                                                    marginLeft: "20px",
                                                    marginTop: "8px"
                                                }}>
                                                    <div className="flexBox ie_position_relative"
                                                         style={{zIndex: 1, position: "absolute", alignSelf: "center"}}>
                                                        <div className="btn_keypad_number_area">
                                                            <input className="numbersOnly input_banner_img_position"
                                                                   type="text"
                                                                   value={this.state.h_main_txt_position_left < 0 ? this.state.h_main_txt_position_left * -1 : this.state.h_main_txt_position_left}
                                                                   onChange={e => {
                                                                       this.setState({h_main_txt_position_left: e.target.value})
                                                                   }}
                                                                   onKeyPress={e => this.movePositionOnKeyPress(e)}
                                                                   onBlur={e => this.movePositionOnBlur(e, "h_main_txt_position_left", "h_main_txt_position_right")}/>
                                                        </div>
                                                        <div className="btn_keypad_center_area"
                                                             style={{cursor: "default"}}>

                                                        </div>
                                                        <div className="btn_keypad_number_area">
                                                            <input className="numbersOnly input_banner_img_position"
                                                                   type="text"
                                                                   value={this.state.h_main_txt_position_right < 0 ? this.state.h_main_txt_position_right * -1 : this.state.h_main_txt_position_right}
                                                                   onChange={e => {
                                                                       this.setState({h_main_txt_position_right: e.target.value})
                                                                   }}
                                                                   onKeyPress={e => this.movePositionOnKeyPress(e)}
                                                                   onBlur={e => this.movePositionOnBlur(e, "h_main_txt_position_right", "h_main_txt_position_left")}/>
                                                        </div>
                                                    </div>
                                                    <div className="flexBox ie_left_40px" style={{
                                                        zIndex: 0,
                                                        flexDirection: "column",
                                                        position: "absolute",
                                                        alignSelf: "center"
                                                    }}>
                                                        <div className="btn_keypad_number_area">
                                                            <input className="numbersOnly input_banner_img_position"
                                                                   type="text"
                                                                   value={this.state.h_main_txt_position_top < 0 ? this.state.h_main_txt_position_top * -1 : this.state.h_main_txt_position_top}
                                                                   onChange={e => {
                                                                       this.setState({h_main_txt_position_top: e.target.value})
                                                                   }}
                                                                   onKeyPress={e => this.movePositionOnKeyPress(e)}
                                                                   onBlur={e => this.movePositionOnBlur(e, "h_main_txt_position_top", "h_main_txt_position_bottom")}/>
                                                        </div>
                                                        <div className="btn_keypad_center_area"
                                                             style={{cursor: "default"}}>

                                                        </div>
                                                        <div className="btn_keypad_number_area">
                                                            <input className="numbersOnly input_banner_img_position"
                                                                   type="text"
                                                                   value={this.state.h_main_txt_position_bottom < 0 ? this.state.h_main_txt_position_bottom * -1 : this.state.h_main_txt_position_bottom}
                                                                   onChange={e => {
                                                                       this.setState({h_main_txt_position_bottom: e.target.value})
                                                                   }}
                                                                   onKeyPress={e => this.movePositionOnKeyPress(e)}
                                                                   onBlur={e => this.movePositionOnBlur(e, "h_main_txt_position_bottom", "h_main_txt_position_top")}/>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <p className="notice_text_position"><i className={"fa fa-exclamation-circle"} aria-hidden="true"></i>&nbsp;화살표를 클릭하거나 숫자를 적어 위치를 옮겨보세요.(단위: %)</p>
                                            <div className={"event_img_edit_area_line"}></div>
                                            {/* 텍스트 위치 끝 */}
                                            {/* 텍스트 크기 시작 */}
                                            <div className={"event_img_position_area_title"}>텍스트 크기</div>
                                            <div className={"wrap_btn_txt_scale_reset"}>
                                                <div className="btn_txt_scale_reset" onClick={(e) => this.handleTxtScaleResetOnClick(e, "h", "main")}>초기화</div>
                                            </div>
                                            <div className={"text_size_main"}>
                                                <Slider
                                                    className={"h_main_txt_scale_slider"}
                                                    aria-label="Small steps"
                                                    defaultValue={this.state.h_main_txt_scale == null || this.state.h_main_txt_scale == undefined || this.state.h_main_txt_scale == 0 ? 1 : this.state.h_main_txt_scale}
                                                    value={this.state.h_main_txt_scale == null || this.state.h_main_txt_scale == undefined || this.state.h_main_txt_scale == 0 ? 1 : this.state.h_main_txt_scale}
                                                    getAriaValueText={(value) => this.getTxtScaleValueText(value)}
                                                    step={0.1}
                                                    min={0.1}
                                                    max={2.0}
                                                    valueLabelDisplay="auto"
                                                    sx={{
                                                        color: "#00e2e6",
                                                        width: "100%",
                                                        margin: "0 auto"
                                                    }}
                                                    onChange={(e) => this.handleTxtScaleOnChange(e, "h", "main")}
                                                />
                                            </div>
                                            {/* 텍스트 크기 끝 */}
                                        </div>
                                    }
                                </div>
                                {/* main_text_box end */}

                            </div>
                            <br/>
                            <br/>
                            <div className="m-t-32" style={{position:"relative", alignSelf:"center"}}>
                                <div className="flexBox m-l-34">
                                    <div className="icn_ractangle_radius_line">가</div>
                                    &nbsp;&nbsp;
                                    <div className="form_sub_title" style={{marginTop: "auto", marginBottom: "auto"}}>서브 텍스트</div>
                                </div>
                                {/*<div className="wrap_event_title_color_area wrap_event_title_color_area_sub">*/}
                                {/*    <div className="event_title_color_area">*/}
                                {/*        {*/}
                                {/*            this.state.pc_banner_ratio_list.length > 0 // 가로형 여부*/}
                                {/*                ? <div className="color_area">*/}
                                {/*                    <div className="color_thumb_area">*/}
                                {/*                        <div className="color_thumb_area color_title">*/}
                                {/*                            가로형 서브 텍스트 기본 컬러*/}
                                {/*                        </div>*/}
                                {/*                        <div className="grey_radius_box flexBox" style={{width: "100%"}}>*/}
                                {/*                            <div className="black_radius_box" style={{*/}
                                {/*                                flex: "1",*/}
                                {/*                                height: "33px",*/}
                                {/*                                background: this.state.w_small_text_color*/}
                                {/*                            }}></div>*/}
                                {/*                            &nbsp;&nbsp;&nbsp;&nbsp;*/}
                                {/*                            {*/}
                                {/*                                this.state.is_expand_w_small_text_color*/}
                                {/*                                    ? <div className="icn_four_ractangle_on cursor-pointer"*/}
                                {/*                                           onClick={e => this.onClickRactangleColorButton(e, "is_expand_w_small_text_color")}></div>*/}
                                {/*                                    : <div className="icn_four_ractangle cursor-pointer"*/}
                                {/*                                           onClick={e => this.onClickRactangleColorButton(e, "is_expand_w_small_text_color")}></div>*/}
                                {/*                            }*/}
                                {/*                        </div>*/}
                                {/*                    </div>*/}
                                {/*                    {this.state.is_expand_w_small_text_color*/}
                                {/*                        ? <div className="color_detail_area_first">*/}
                                {/*                            <SketchPicker*/}
                                {/*                                presetColors={presetColors}*/}
                                {/*                                color={this.state.w_small_text_color}*/}
                                {/*                                onChange={e => this.handleChangeTextColor(e, "w_small_text_color")}*/}
                                {/*                                onChangeComplete={e => this.handleChangeTextColor(e, "w_small_text_color", true)}*/}
                                {/*                                disableAlpha*/}
                                {/*                            />*/}
                                {/*                        </div>*/}
                                {/*                        : ""*/}
                                {/*                    }*/}
                                {/*                </div>*/}
                                {/*                : ""*/}
                                {/*        }*/}
                                {/*        {*/}
                                {/*            this.state.mobile_banner_ratio_list.length > 0 // 세로형 여부*/}
                                {/*                ? <div className="color_area">*/}
                                {/*                    <div className="color_thumb_area">*/}
                                {/*                        <div className="color_thumb_area color_title">*/}
                                {/*                            세로형 서브 텍스트 기본 컬러*/}
                                {/*                        </div>*/}
                                {/*                        <div className="grey_radius_box flexBox" style={{width: "100%"}}>*/}
                                {/*                            <div className="black_radius_box" style={{*/}
                                {/*                                flex: "1",*/}
                                {/*                                height: "33px",*/}
                                {/*                                background: this.state.h_small_text_color*/}
                                {/*                            }}></div>*/}
                                {/*                            &nbsp;&nbsp;&nbsp;&nbsp;*/}
                                {/*                            {*/}
                                {/*                                this.state.is_expand_h_small_text_color*/}
                                {/*                                    ? <div className="icn_four_ractangle_on cursor-pointer"*/}
                                {/*                                           onClick={e => this.onClickRactangleColorButton(e, "is_expand_h_small_text_color")}></div>*/}
                                {/*                                    : <div className="icn_four_ractangle cursor-pointer"*/}
                                {/*                                           onClick={e => this.onClickRactangleColorButton(e, "is_expand_h_small_text_color")}></div>*/}
                                {/*                            }*/}
                                {/*                        </div>*/}
                                {/*                    </div>*/}
                                {/*                    {this.state.is_expand_h_small_text_color*/}
                                {/*                        ? <div className="color_detail_area_first">*/}
                                {/*                            <SketchPicker*/}
                                {/*                                presetColors={presetColors}*/}
                                {/*                                color={this.state.h_small_text_color}*/}
                                {/*                                onChange={e => this.handleChangeTextColor(e, "h_small_text_color")}*/}
                                {/*                                onChangeComplete={e => this.handleChangeTextColor(e, "h_small_text_color", true)}*/}
                                {/*                                disableAlpha*/}
                                {/*                            />*/}
                                {/*                        </div>*/}
                                {/*                        : ""*/}
                                {/*                    }*/}
                                {/*                </div>*/}
                                {/*                : ""*/}
                                {/*        }*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<p className="notice_event_title_color_area"><i className={"fa fa-exclamation-circle"} aria-hidden="true"></i>&nbsp;가로형/세로형 텍스트 기본 컬러보다 에디터의 글자색이 우선순위로 적용되어요.</p>*/}
                                {
                                !this.state.is_recommendation_small_title || this.state.is_recommendation_small_title == "off"    
                                ?
                                    <>
                                        <div style={{height:"51px", alignSelf:"center", position:"absolute", top:"-15px", left:"120px"}}>
                                            <div className="btn_robomd_recomendation_back m-l-34" style={{height:"100%"}}></div>
                                            <div className="btn_robomd_recomendation_off_front m-l-34" style={{height:"100%", top:"-100%", lineHeight: "51px"}} onClick={e => this.onClickBtnRecommendationForSmallTitle(e, "on")}>AI 문구 추천 OFF</div>
                                        </div>
                                        {/iPad|iPhone|iPod/.test(navigator.platform) ?
                                            <></>
                                            :
                                            navigator.userAgent.includes("Chrome") ?
                                                <div style={{position: "relative"}}>
                                                    <TextEditor id={"subTextEditor"} onBannerTemplateChange={this.state.event_type_cd} onMobileBannerTemplateChange={this.state.event_mobile_type_cd} checkImgLinkUseAble={this.checkImgLinkUseAble} />
                                                </div>
                                                :
                                                <></>
                                        }
                                    </>
                                :   <div style={{height:"51px", alignSelf:"center", position:"absolute", top:"-15px", left:"120px"}}>
                                        <div className="btn_robomd_recomendation_back m-l-34" style={{height:"100%"}}></div>
                                        <div className="btn_robomd_recomendation_on_front m-l-34" style={{height:"100%", top:"-100%", lineHeight: "51px"}} onClick={e => this.onClickBtnRecommendationForSmallTitle(e, "off")}>AI 문구 추천 ON</div>
                                    </div>
                                }
                            </div>
                            <div>
                                {
                                !this.state.is_recommendation_small_title || this.state.is_recommendation_small_title == "off"    
                                ?   <div className="input_text_title eventSettingTitle m-l-34" style={{display: "none"}}>
                                        <label style={{marginTop: "auto", marginBottom: "auto", color: "#333333"}}>서브 텍스트 문구 내용</label>
                                    </div>
                                :   <div className="input_text_title_b eventSettingTitle m-l-34">
                                        <label style={{marginTop: "auto", marginBottom: "auto"}}>AI 추천</label>
                                    </div>  
                                }    
                                <div className="margin-vertical-auto m-l-34">
                                    {
                                    !this.state.is_recommendation_small_title || this.state.is_recommendation_small_title == "off"
                                    ?   /*<textarea className="border_form_radius_b f-s-15 mobile-width-100" id="title" style={{width:"100%", maxWidth:"800px", height:"154px"}}
                                            value={this.state.sub_title} onChange={e => this.onChangeTitle(e, "sub_title")} onBlur={this.handleTitleBlur} placeholder="직접 입력하시거나 AI 문구 추천을 통해 입력해 주세요.">
                                                {this.state.sub_title}
                                        </textarea>*/
                                        navigator.userAgent.includes("Chrome") ?
                                            <ContentEditable className="f-s-15 mobile-width-100 text_editor_title subTextEditor" id="title" style={{width:"100%", maxWidth:"800px", height:"154px", overflow: "auto", padding: "20px", border: "1.5px solid #DBDDE4", borderRadius: "0 0 13px 13px"}}
                                                             value={this.state.sub_title ? this.state.sub_title : ""} onChange={e => this.onChangeTitle(e, "sub_title")} onBlur={this.handleTitleBlur} placeholder="텍스트 내용을 입력하고 드래그를 통해 자유롭게 편집해 보세요." html={this.state.sub_title ? this.state.sub_title.replace("font-size: 15px;", "") : ""} />
                                            :
                                            <Editor
                                                editorState={this.state.subEditorState}
                                                onEditorStateChange={this.onSubEditorStateChange}
                                                toolbarClassName="sub_toolbar_wysiwyg"
                                                wrapperClassName="sub_wrapper_wysiwyg"
                                                editorClassName="sub_editor_wysiwyg"
                                                toolbar={{
                                                    options: [
                                                        'fontFamily',
                                                        'inline',
                                                        'list',
                                                        'colorPicker',
                                                        'image',
                                                        'emoji',
                                                        'history',
                                                        'remove',
                                                    ],
                                                    fontFamily: {
                                                        options: fontArray,
                                                    },
                                                    inline: {
                                                        options: ['bold', 'italic', 'underline', 'strikethrough'],
                                                    },
                                                    list: {
                                                        options: ['unordered', 'ordered'],
                                                    },
                                                    image: {
                                                        urlEnabled: true,
                                                        uploadEnabled: true,
                                                        alignmentEnabled: false,
                                                        uploadCallback: this.uploadCallback,
                                                        previewImage: true,
                                                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                                        alt: { present: false, mandatory: false },
                                                        defaultSize: {
                                                            height: '1em',
                                                            width: 'auto',
                                                        },
                                                    }
                                                }}
                                                placeholder="텍스트 내용을 입력하고 드래그를 통해 자유롭게 편집해 보세요."
                                                localization={{ locale: "ko" }}
                                                toolbarStyle={{
                                                    border: "1px solid #DBE7F2",
                                                    background : "#DBE7F2",
                                                    width: "100%",
                                                    maxWidth: "800px",
                                                    borderRadius: "13px 13px 0 0",
                                                    padding: "15px 18px 10px",
                                                }}
                                                editorStyle={{
                                                    maxWidth: "800px",
                                                    minHeight: "200px",
                                                    maxHeight: "200px",
                                                    width: "100%",
                                                    border: "1px solid #DBDDE4",
                                                    padding: "10px 20px",
                                                    borderRadius: "0 0 13px 13px",
                                                }}
                                                autocomplete={false}
                                                handlePastedText={() => false}
                                            />
                                        :   <div className="mobile-width-100" style={{width:"100%", maxWidth:"800px"}}>
                                        {
                                        this.state.autoSubTextList && this.state.autoSubTextList.length > 0
                                        ?   <select className="border_form_radius_b"  style={{width:"100%"}} value={this.state.robomd_on_sentence_cd} onChange={e => this.onChangeAutoSubText(e, "sentence_cd")}>
                                                <option value="" disabled>
                                                    작은 글자 제목을 선택해 주세요.
                                                </option>
                                                {this.state.autoSubTextList.map((obj,idx) => (
                                                    <option key={idx} value={obj.sentence_seq}>
                                                        {obj.sentence}
                                                    </option>
                                                ))}
                                            </select>
                                        :   <select className="border_form_radius_b"  style={{width:"100%"}}>
                                                <option value="loading">
                                                    로딩중입니다...
                                                </option>
                                            </select>
                                        }
                                        <p className="notice_ai_recommend_sub_text"><i className={"fa fa-exclamation-circle"} aria-hidden="true"></i>&nbsp;직접 입력을 원하시면, AI 문구 추천을 OFF로 설정해 주세요.</p>
                                        </div>
                                    }
                                </div>
                                {/*<div className="margin-vertical-auto flexBox m-l-34 m-t-15">*/}
                                {/*    <div className="icn_warning_round"></div>*/}
                                {/*    <div style={{marginLeft:"10px", flex:"1"}}>*/}
                                {/*        직접 작성하고 싶으신 경우, <b>AI 문구 추천</b>을 클릭하여 <b>OFF로 설정</b>해 주세요.*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                {/* sub_text_box start */}
                                <div id="sub_text_box" className="m-l-34">
                                    {this.state.is_show_pc_banner_area &&
                                        <div className={this.state.is_show_pc_banner_area && this.state.is_show_mobile_banner_area ? "text_box_width border_form_radius text_box_margin_right" : "text_box_width border_form_radius"}>
                                            <label className={"label_text_box_width"}>가로형 서브 텍스트</label>
                                            {/* 텍스트 컬러 시작*/}
                                            <div className={"event_img_position_area_title"}>텍스트 컬러</div>
                                            <div className="event_title_color_area">
                                                    {
                                                        this.state.pc_banner_ratio_list.length > 0 // 가로형 여부
                                                            ? <div className="color_area" style={{marginRight: "0"}}>
                                                                <div className="color_thumb_area">
                                                                    {/*<div className="color_thumb_area color_title">*/}
                                                                    {/*    가로형 서브 텍스트 기본 컬러*/}
                                                                    {/*</div>*/}
                                                                    <div className="grey_radius_box flexBox" style={{width: "100%"}}>
                                                                        <div className="black_radius_box" style={{
                                                                            flex: "1",
                                                                            height: "33px",
                                                                            background: this.state.w_small_text_color
                                                                        }}></div>
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {
                                                                            this.state.is_expand_w_small_text_color
                                                                                ? <div className="icn_four_ractangle_on cursor-pointer"
                                                                                       onClick={e => this.onClickRactangleColorButton(e, "is_expand_w_small_text_color")}></div>
                                                                                : <div className="icn_four_ractangle cursor-pointer"
                                                                                       onClick={e => this.onClickRactangleColorButton(e, "is_expand_w_small_text_color")}></div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {this.state.is_expand_w_small_text_color
                                                                    ? <div className="color_detail_area_first">
                                                                        <SketchPicker
                                                                            presetColors={presetColors}
                                                                            color={this.state.w_small_text_color}
                                                                            onChange={e => this.handleChangeTextColor(e, "w_small_text_color")}
                                                                            onChangeComplete={e => this.handleChangeTextColor(e, "w_small_text_color", true)}
                                                                            disableAlpha
                                                                        />
                                                                    </div>
                                                                    : ""
                                                                }
                                                            </div>
                                                            : ""
                                                    }
                                                </div>
                                            <p className="notice_event_title_color_area"><i className={"fa fa-exclamation-circle"} aria-hidden="true"></i>&nbsp;텍스트 컬러보다 에디터의 글자색이 우선순위로 적용되어요.</p>
                                            <div className={"event_img_edit_area_line"}></div>
                                            {/* 텍스트 컬러 끝 */}
                                            {/* 텍스트 위치 시작 */}
                                            <div className={"event_img_position_area_title"}>텍스트 위치</div>
                                            <div className={"text_position_main"}>
                                                <div className="flexBox" style={{
                                                    position: "relative",
                                                    width: "120px",
                                                    height: "120px",
                                                    justifyContent: "center",
                                                    marginTop: "8px"
                                                }}>
                                                    <div className="flexBox ie_position_relative"
                                                         style={{zIndex: 1, position: "absolute", alignSelf: "center"}}>
                                                        <div className="btn_keypad_arrow_area"
                                                             onClick={e => this.movePositionOnClick(e, "w_sub_txt_position_left", "w_sub_txt_position_right", 1)}/>
                                                        <div className="btn_keypad_center_area"
                                                             onClick={e => this.moveTextPositionResetOnClick(e, "w", "sub")}>
                                                            <div className="btn_keypad_center_area_reset">
                                                            </div>
                                                        </div>
                                                        <div className="btn_keypad_arrow_area"
                                                             style={{transform: "rotate(180deg)"}}
                                                             onClick={e => this.movePositionOnClick(e, "w_sub_txt_position_right", "w_sub_txt_position_left", 1)}/>
                                                    </div>
                                                    <div className="flexBox ie_left_40px" style={{
                                                        zIndex: 0,
                                                        flexDirection: "column",
                                                        position: "absolute",
                                                        alignSelf: "center"
                                                    }}>
                                                        <div className="btn_keypad_arrow_area"
                                                             style={{transform: "rotate(90deg)"}}
                                                             onClick={e => this.movePositionOnClick(e, "w_sub_txt_position_top", "w_sub_txt_position_bottom", 1)}/>
                                                        <div className="btn_keypad_center_area">
                                                        </div>
                                                        <div className="btn_keypad_arrow_area"
                                                             style={{transform: "rotate(-90deg)"}}
                                                             onClick={e => this.movePositionOnClick(e, "w_sub_txt_position_bottom", "w_sub_txt_position_top", 1)}/>
                                                    </div>
                                                </div>

                                                <div className="flexBox" style={{
                                                    position: "relative",
                                                    width: "120px",
                                                    height: "120px",
                                                    justifyContent: "center",
                                                    marginLeft: "20px",
                                                    marginTop: "8px"
                                                }}>
                                                    <div className="flexBox ie_position_relative"
                                                         style={{zIndex: 1, position: "absolute", alignSelf: "center"}}>
                                                        <div className="btn_keypad_number_area">
                                                            <input className="numbersOnly input_banner_img_position"
                                                                   type="text"
                                                                   value={this.state.w_sub_txt_position_left < 0 ? this.state.w_sub_txt_position_left * -1 : this.state.w_sub_txt_position_left}
                                                                   onChange={e => {
                                                                       this.setState({w_sub_txt_position_left: e.target.value})
                                                                   }}
                                                                   onKeyPress={e => this.movePositionOnKeyPress(e)}
                                                                   onBlur={e => this.movePositionOnBlur(e, "w_sub_txt_position_left", "w_sub_txt_position_right")}/>
                                                        </div>
                                                        <div className="btn_keypad_center_area"
                                                             style={{cursor: "default"}}>

                                                        </div>
                                                        <div className="btn_keypad_number_area">
                                                            <input className="numbersOnly input_banner_img_position"
                                                                   type="text"
                                                                   value={this.state.w_sub_txt_position_right < 0 ? this.state.w_sub_txt_position_right * -1 : this.state.w_sub_txt_position_right}
                                                                   onChange={e => {
                                                                       this.setState({w_sub_txt_position_right: e.target.value})
                                                                   }}
                                                                   onKeyPress={e => this.movePositionOnKeyPress(e)}
                                                                   onBlur={e => this.movePositionOnBlur(e, "w_sub_txt_position_right", "w_sub_txt_position_left")}/>
                                                        </div>
                                                    </div>
                                                    <div className="flexBox ie_left_40px" style={{
                                                        zIndex: 0,
                                                        flexDirection: "column",
                                                        position: "absolute",
                                                        alignSelf: "center"
                                                    }}>
                                                        <div className="btn_keypad_number_area">
                                                            <input className="numbersOnly input_banner_img_position"
                                                                   type="text"
                                                                   value={this.state.w_sub_txt_position_top < 0 ? this.state.w_sub_txt_position_top * -1 : this.state.w_sub_txt_position_top}
                                                                   onChange={e => {
                                                                       this.setState({w_sub_txt_position_top: e.target.value})
                                                                   }}
                                                                   onKeyPress={e => this.movePositionOnKeyPress(e)}
                                                                   onBlur={e => this.movePositionOnBlur(e, "w_sub_txt_position_top", "w_sub_txt_position_bottom")}/>
                                                        </div>
                                                        <div className="btn_keypad_center_area"
                                                             style={{cursor: "default"}}>

                                                        </div>
                                                        <div className="btn_keypad_number_area">
                                                            <input className="numbersOnly input_banner_img_position"
                                                                   type="text"
                                                                   value={this.state.w_sub_txt_position_bottom < 0 ? this.state.w_sub_txt_position_bottom * -1 : this.state.w_sub_txt_position_bottom}
                                                                   onChange={e => {
                                                                       this.setState({w_sub_txt_position_bottom: e.target.value})
                                                                   }}
                                                                   onKeyPress={e => this.movePositionOnKeyPress(e)}
                                                                   onBlur={e => this.movePositionOnBlur(e, "w_sub_txt_position_bottom", "w_sub_txt_position_top")}/>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <p className="notice_text_position"><i className={"fa fa-exclamation-circle"} aria-hidden="true"></i>&nbsp;화살표를 클릭하거나 숫자를 적어 위치를 옮겨보세요.(단위: %)</p>
                                            <div className={"event_img_edit_area_line"}></div>
                                            {/* 텍스트 위치 끝 */}
                                            {/* 텍스트 크기 시작 */}
                                            <div className={"event_img_position_area_title"}>텍스트 크기</div>
                                            <div className={"wrap_btn_txt_scale_reset"}>
                                                <div className="btn_txt_scale_reset" onClick={(e) => this.handleTxtScaleResetOnClick(e, "w", "sub")}>초기화</div>
                                            </div>
                                            <div className={"text_size_main"}>
                                                <Slider
                                                    className={"w_sub_txt_scale_slider"}
                                                    aria-label="Small steps"
                                                    defaultValue={this.state.w_sub_txt_scale == null || this.state.w_sub_txt_scale == undefined || this.state.w_sub_txt_scale == 0 ? 1 : this.state.w_sub_txt_scale}
                                                    value={this.state.w_sub_txt_scale == null || this.state.w_sub_txt_scale == undefined || this.state.w_sub_txt_scale == 0 ? 1 : this.state.w_sub_txt_scale}
                                                    getAriaValueText={(value) => this.getTxtScaleValueText(value)}
                                                    step={0.1}
                                                    min={0.1}
                                                    max={2.0}
                                                    valueLabelDisplay="auto"
                                                    sx={{
                                                        color: "#00e2e6",
                                                        width: "100%",
                                                        margin: "0 auto"
                                                    }}
                                                    onChange={(e) => this.handleTxtScaleOnChange(e, "w", "sub")}
                                                />
                                            </div>
                                            {/* 텍스트 크기 끝 */}
                                        </div>
                                    }
                                    {this.state.is_show_mobile_banner_area &&
                                        <div className="text_box_height border_form_radius">
                                            <label className={"label_text_box_height"}>세로형 서브 텍스트</label>
                                            {/* 텍스트 컬러 시작 */}
                                            <div className={"event_img_position_area_title"}>텍스트 컬러</div>
                                            <div className="event_title_color_area">
                                                {
                                                    this.state.mobile_banner_ratio_list.length > 0 // 세로형 여부
                                                        ? <div className="color_area" style={{marginRight: "0"}}>
                                                            <div className="color_thumb_area">
                                                                {/*<div className="color_thumb_area color_title">*/}
                                                                {/*    세로형 서브 텍스트 기본 컬러*/}
                                                                {/*</div>*/}
                                                                <div className="grey_radius_box flexBox" style={{width: "100%"}}>
                                                                    <div className="black_radius_box" style={{
                                                                        flex: "1",
                                                                        height: "33px",
                                                                        background: this.state.h_small_text_color
                                                                    }}></div>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    {
                                                                        this.state.is_expand_h_small_text_color
                                                                            ? <div className="icn_four_ractangle_on cursor-pointer"
                                                                                   onClick={e => this.onClickRactangleColorButton(e, "is_expand_h_small_text_color")}></div>
                                                                            : <div className="icn_four_ractangle cursor-pointer"
                                                                                   onClick={e => this.onClickRactangleColorButton(e, "is_expand_h_small_text_color")}></div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            {this.state.is_expand_h_small_text_color
                                                                ? <div className="color_detail_area_first">
                                                                    <SketchPicker
                                                                        presetColors={presetColors}
                                                                        color={this.state.h_small_text_color}
                                                                        onChange={e => this.handleChangeTextColor(e, "h_small_text_color")}
                                                                        onChangeComplete={e => this.handleChangeTextColor(e, "h_small_text_color", true)}
                                                                        disableAlpha
                                                                    />
                                                                </div>
                                                                : ""
                                                            }
                                                        </div>
                                                        : ""
                                                }
                                            </div>
                                            <p className="notice_event_title_color_area"><i className={"fa fa-exclamation-circle"} aria-hidden="true"></i>&nbsp;텍스트 컬러보다 에디터의 글자색이 우선순위로 적용되어요.</p>
                                            <div className={"event_img_edit_area_line"}></div>
                                            {/* 텍스트 컬러 끝 */}
                                            {/* 텍스트 위치 시작 */}
                                            <div className={"event_img_position_area_title"}>텍스트 위치</div>
                                            <div className={"text_position_main"}>
                                                <div className="flexBox" style={{
                                                    position: "relative",
                                                    width: "120px",
                                                    height: "120px",
                                                    justifyContent: "center",
                                                    marginTop: "8px"
                                                }}>
                                                    <div className="flexBox ie_position_relative"
                                                         style={{zIndex: 1, position: "absolute", alignSelf: "center"}}>
                                                        <div className="btn_keypad_arrow_area"
                                                             onClick={e => this.movePositionOnClick(e, "h_sub_txt_position_left", "h_sub_txt_position_right", 1)}/>
                                                        <div className="btn_keypad_center_area"
                                                             onClick={e => this.moveTextPositionResetOnClick(e, "h", "sub")}>
                                                            <div className="btn_keypad_center_area_reset">
                                                            </div>
                                                        </div>
                                                        <div className="btn_keypad_arrow_area"
                                                             style={{transform: "rotate(180deg)"}}
                                                             onClick={e => this.movePositionOnClick(e, "h_sub_txt_position_right", "h_sub_txt_position_left", 1)}/>
                                                    </div>
                                                    <div className="flexBox ie_left_40px" style={{
                                                        zIndex: 0,
                                                        flexDirection: "column",
                                                        position: "absolute",
                                                        alignSelf: "center"
                                                    }}>
                                                        <div className="btn_keypad_arrow_area"
                                                             style={{transform: "rotate(90deg)"}}
                                                             onClick={e => this.movePositionOnClick(e, "h_sub_txt_position_top", "h_sub_txt_position_bottom", 1)}/>
                                                        <div className="btn_keypad_center_area">
                                                        </div>
                                                        <div className="btn_keypad_arrow_area"
                                                             style={{transform: "rotate(-90deg)"}}
                                                             onClick={e => this.movePositionOnClick(e, "h_sub_txt_position_bottom", "h_sub_txt_position_top", 1)}/>
                                                    </div>
                                                </div>

                                                <div className="flexBox" style={{
                                                    position: "relative",
                                                    width: "120px",
                                                    height: "120px",
                                                    justifyContent: "center",
                                                    marginLeft: "20px",
                                                    marginTop: "8px"
                                                }}>
                                                    <div className="flexBox ie_position_relative"
                                                         style={{zIndex: 1, position: "absolute", alignSelf: "center"}}>
                                                        <div className="btn_keypad_number_area">
                                                            <input className="numbersOnly input_banner_img_position"
                                                                   type="text"
                                                                   value={this.state.h_sub_txt_position_left < 0 ? this.state.h_sub_txt_position_left * -1 : this.state.h_sub_txt_position_left}
                                                                   onChange={e => {
                                                                       this.setState({h_sub_txt_position_left: e.target.value})
                                                                   }}
                                                                   onKeyPress={e => this.movePositionOnKeyPress(e)}
                                                                   onBlur={e => this.movePositionOnBlur(e, "h_sub_txt_position_left", "h_sub_txt_position_right")}/>
                                                        </div>
                                                        <div className="btn_keypad_center_area"
                                                             style={{cursor: "default"}}>

                                                        </div>
                                                        <div className="btn_keypad_number_area">
                                                            <input className="numbersOnly input_banner_img_position"
                                                                   type="text"
                                                                   value={this.state.h_sub_txt_position_right < 0 ? this.state.h_sub_txt_position_right * -1 : this.state.h_sub_txt_position_right}
                                                                   onChange={e => {
                                                                       this.setState({h_sub_txt_position_right: e.target.value})
                                                                   }}
                                                                   onKeyPress={e => this.movePositionOnKeyPress(e)}
                                                                   onBlur={e => this.movePositionOnBlur(e, "h_sub_txt_position_right", "h_sub_txt_position_left")}/>
                                                        </div>
                                                    </div>
                                                    <div className="flexBox ie_left_40px" style={{
                                                        zIndex: 0,
                                                        flexDirection: "column",
                                                        position: "absolute",
                                                        alignSelf: "center"
                                                    }}>
                                                        <div className="btn_keypad_number_area">
                                                            <input className="numbersOnly input_banner_img_position"
                                                                   type="text"
                                                                   value={this.state.h_sub_txt_position_top < 0 ? this.state.h_sub_txt_position_top * -1 : this.state.h_sub_txt_position_top}
                                                                   onChange={e => {
                                                                       this.setState({h_sub_txt_position_top: e.target.value})
                                                                   }}
                                                                   onKeyPress={e => this.movePositionOnKeyPress(e)}
                                                                   onBlur={e => this.movePositionOnBlur(e, "h_sub_txt_position_top", "h_sub_txt_position_bottom")}/>
                                                        </div>
                                                        <div className="btn_keypad_center_area"
                                                             style={{cursor: "default"}}>

                                                        </div>
                                                        <div className="btn_keypad_number_area">
                                                            <input className="numbersOnly input_banner_img_position"
                                                                   type="text"
                                                                   value={this.state.h_sub_txt_position_bottom < 0 ? this.state.h_sub_txt_position_bottom * -1 : this.state.h_sub_txt_position_bottom}
                                                                   onChange={e => {
                                                                       this.setState({h_sub_txt_position_bottom: e.target.value})
                                                                   }}
                                                                   onKeyPress={e => this.movePositionOnKeyPress(e)}
                                                                   onBlur={e => this.movePositionOnBlur(e, "h_sub_txt_position_bottom", "h_sub_txt_position_top")}/>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <p className="notice_text_position"><i className={"fa fa-exclamation-circle"} aria-hidden="true"></i>&nbsp;화살표를 클릭하거나 숫자를 적어 위치를 옮겨보세요.(단위: %)</p>
                                            <div className={"event_img_edit_area_line"}></div>
                                            {/* 텍스트 위치 끝 */}
                                            {/* 텍스트 크기 시작 */}
                                            <div className={"event_img_position_area_title"}>텍스트 크기</div>
                                            <div className={"wrap_btn_txt_scale_reset"}>
                                                <div className="btn_txt_scale_reset" onClick={(e) => this.handleTxtScaleResetOnClick(e, "h", "sub")}>초기화</div>
                                            </div>
                                            <div className={"text_size_main"}>
                                                <Slider
                                                    className={"h_sub_txt_scale_slider"}
                                                    aria-label="Small steps"
                                                    defaultValue={this.state.h_sub_txt_scale == null || this.state.h_sub_txt_scale == undefined || this.state.h_sub_txt_scale == 0 ? 1 : this.state.h_sub_txt_scale}
                                                    value={this.state.h_sub_txt_scale == null || this.state.h_sub_txt_scale == undefined || this.state.h_sub_txt_scale == 0 ? 1 : this.state.h_sub_txt_scale}
                                                    getAriaValueText={(value) => this.getTxtScaleValueText(value)}
                                                    step={0.1}
                                                    min={0.1}
                                                    max={2.0}
                                                    valueLabelDisplay="auto"
                                                    sx={{
                                                        color: "#00e2e6",
                                                        width: "100%",
                                                        margin: "0 auto"
                                                    }}
                                                    onChange={(e) => this.handleTxtScaleOnChange(e, "h", "sub")}
                                                />
                                            </div>
                                            {/* 텍스트 크기 끝 */}
                                        </div>
                                    }
                                </div>
                                {/* sub_text_box end */}

                                {/* {this.state.solution_type_cd == "09900" && ((this.state.pc_banner_ratio_list.length > 0 && this.state.w_is_date_use == "Y") || (this.state.mobile_banner_ratio_list.length > 0 && this.state.h_is_date_use == "Y")) ?
                                <>
                                    <div className="m-l-34" style={{marginTop:"50px"}}>
                                        <div style={{display:"flex"}}>
                                            <img src={calendar_in_num_1} style={{width:"23px", height:"23px", alignSelf:"center"}}/>
                                            &nbsp;&nbsp;날짜 텍스트
                                        </div>
                                        <div className="input-daterange input-group" id="datepicker">
                                            <div>
                                                <div className="input_text_title_d eventSettingTitle" style={{zIndex:"1"}}>
                                                    <label style={{marginTop: "auto", marginBottom: "auto"}}>시작일</label>
                                                </div>
                                                <div className="mobile_width_30vw">
                                                    <DatePicker name="start_date" id="start_date" className="border_form_radius_b mobile_width_30vw"
                                                        selected={this.state.start_date}
                                                        onSelect={this.handleSelect} //when day is clicked
                                                        onChange={this.handleChangeStartDate} //only when value has changed
                                                        placeholderText="시작일자"
                                                        todayButton="오늘"
                                                        dateFormat="yyyy-MM-dd"
                                                        maxDate={new Date("9999-12-31")}
                                                        autoComplete='off'
                                                        showYearDropdown
                                                        yearDropdownItemNumber={15}
                                                        scrollableYearDropdown
                                                        locale={ko}
                                                        disabled={this.state.disabledStartDate}
                                                    />
                                                    
                                                </div>
                                            </div>
                                            <div style={{margin: "40px 10px 0px 10px"}}>~</div>
                                            <div>
                                                <div className="input_text_title_d eventSettingTitle" style={{zIndex:"1"}}>
                                                    <label style={{marginTop: "auto", marginBottom: "auto"}}>종료일</label>
                                                </div>
                                                <div className="mobile_width_30vw">
                                                    <DatePicker name="end_date" id="end_date" className="border_form_radius_b mobile_width_30vw"
                                                        selected={this.state.end_date}
                                                        onSelect={this.handleSelect} //when day is clicked
                                                        onChange={this.handleChangeEndDate} //only when value has changed
                                                        placeholderText="종료일자"
                                                        todayButton="오늘"
                                                        dateFormat="yyyy-MM-dd"
                                                        maxDate={new Date("9999-12-31")}
                                                        autoComplete='off'
                                                        showYearDropdown
                                                        yearDropdownItemNumber={15}
                                                        scrollableYearDropdown
                                                        locale={ko}
                                                        disabled={this.state.disabledEndDate}
                                                    />
                                                </div>	
                                            </div>
                                        </div>
                                    </div>
                                    <div className="margin-vertical-auto flexBox m-l-34 m-t-15">
                                        <div className="icn_warning_round"></div>
                                        <div style={{marginLeft:"10px", flex:"1"}}>
                                            템플릿 선택에 따라, 배너 이미지 내에 입력하신 날짜가 출력됩니다.
                                        </div>
                                    </div>
                                </> : ""
                                } */}
                                {this.state.solution_type_cd == "09900" && ((this.state.pc_banner_ratio_list.length > 0 && this.state.w_is_price_use == "Y") || (this.state.mobile_banner_ratio_list.length > 0 && this.state.h_is_price_use == "Y")) ?
                                <>
                                    <div className="m-l-34" style={{marginTop:"50px"}}>
                                        <div style={{display:"flex"}}>
                                            <img src={price_tag} style={{width:"23px", height:"23px", alignSelf:"center"}}/>
                                            &nbsp;&nbsp;가격 텍스트
                                        </div>
                                        <div style={{width: "100%", maxWidth: "600px", marginRight:"20px"}}>
                                            <div className="input_text_title_d">가격</div>
                                            <input id="input_event_price_text" type="text" className="border_form_radius_b f-s-15 input_width_100 input_height_35px" value={this.state.event_price} onChange={e => this.onChangeEventPrice(e)} placeholder="배너에 사용될 가격 텍스트를 입력해주세요." maxlength="9"></input>
                                        </div>
                                    </div>
                                    <div className="margin-vertical-auto flexBox m-l-34 m-t-15">
                                        <div className="icn_warning_round"></div>
                                        <div style={{marginLeft:"10px", flex:"1"}}>
                                            템플릿 선택에 따라, 배너 이미지 내에 입력하신 가격이 출력됩니다.
                                        </div>
                                    </div>
                                </> : ""
                                }
                                {(this.state.pc_banner_ratio_list.length > 0 && this.state.w_is_button_use == "Y") || (this.state.mobile_banner_ratio_list.length > 0 && this.state.h_is_button_use == "Y") ?
                                <>
                                    <div className="m-l-34" style={{marginTop:"50px"}}>
                                        <div style={{display:"flex"}} ref={scollSettingBtnRef}>
                                            <img src={button_list} style={{width:"23px", height:"23px", alignSelf:"center"}}/>
                                            &nbsp;&nbsp;버튼 설정
                                        </div>
                                        {this.state.button_list.map((button, idx) => (
                                            <div className={"event_setting_button_list_content"} key={idx}>
                                                <div style={{width: "300px", maxWidth: "600px", marginRight:"20px"}}>
                                                    <div className="input_text_title_d">{(idx+1)+"번 버튼 텍스트"}</div>
                                                    <input type="text" id={"button_text_input_"+(idx+1)} className="border_form_radius_b f-s-15 input_width_100 input_height_35px" value={button.button_text} onChange={e => this.onChangeButtonSetting(e, idx, "button_text")} placeholder="버튼에 보여질 텍스트를 입력해주세요." maxlength={100}></input>
                                                </div>
                                                <div style={{flex: 1, maxWidth: "600px", marginRight:"20px"}}>
                                                    <div className="input_text_title_d">{(idx+1)+"번 버튼 링크"}</div>
                                                    <input type="text" id={"button_link_input_"+(idx+1)} className="border_form_radius_b f-s-15 input_width_100 input_height_35px" value={button.link} onChange={e => this.onChangeButtonSetting(e, idx, "link")} placeholder="버튼에 연결될 링크를 입력해주세요." maxlength={1024}></input>
                                                </div>
                                                <svg onClick={() => this.onClickDelButton(idx)} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M16.875 4.1665H13.75V3.5415C13.75 3.04422 13.5525 2.56731 13.2008 2.21568C12.8492 1.86405 12.3723 1.6665 11.875 1.6665H8.125C7.62772 1.6665 7.15081 1.86405 6.79917 2.21568C6.44754 2.56731 6.25 3.04422 6.25 3.5415V4.1665H3.125C2.95924 4.1665 2.80027 4.23235 2.68306 4.34956C2.56585 4.46677 2.5 4.62574 2.5 4.7915C2.5 4.95726 2.56585 5.11624 2.68306 5.23345C2.80027 5.35066 2.95924 5.4165 3.125 5.4165H3.75V16.6665C3.75 16.998 3.8817 17.316 4.11612 17.5504C4.35054 17.7848 4.66848 17.9165 5 17.9165H15C15.3315 17.9165 15.6495 17.7848 15.8839 17.5504C16.1183 17.316 16.25 16.998 16.25 16.6665V5.4165H16.875C17.0408 5.4165 17.1997 5.35066 17.3169 5.23345C17.4342 5.11624 17.5 4.95726 17.5 4.7915C17.5 4.62574 17.4342 4.46677 17.3169 4.34956C17.1997 4.23235 17.0408 4.1665 16.875 4.1665ZM7.5 3.5415C7.5 3.37574 7.56585 3.21677 7.68306 3.09956C7.80027 2.98235 7.95924 2.9165 8.125 2.9165H11.875C12.0408 2.9165 12.1997 2.98235 12.3169 3.09956C12.4342 3.21677 12.5 3.37574 12.5 3.5415V4.1665H7.5V3.5415ZM15 16.6665H5V5.4165H15V16.6665ZM8.75 8.5415V13.5415C8.75 13.7073 8.68415 13.8662 8.56694 13.9834C8.44973 14.1007 8.29076 14.1665 8.125 14.1665C7.95924 14.1665 7.80027 14.1007 7.68306 13.9834C7.56585 13.8662 7.5 13.7073 7.5 13.5415V8.5415C7.5 8.37574 7.56585 8.21677 7.68306 8.09956C7.80027 7.98235 7.95924 7.9165 8.125 7.9165C8.29076 7.9165 8.44973 7.98235 8.56694 8.09956C8.68415 8.21677 8.75 8.37574 8.75 8.5415ZM12.5 8.5415V13.5415C12.5 13.7073 12.4342 13.8662 12.3169 13.9834C12.1997 14.1007 12.0408 14.1665 11.875 14.1665C11.7092 14.1665 11.5503 14.1007 11.4331 13.9834C11.3158 13.8662 11.25 13.7073 11.25 13.5415V8.5415C11.25 8.37574 11.3158 8.21677 11.4331 8.09956C11.5503 7.98235 11.7092 7.9165 11.875 7.9165C12.0408 7.9165 12.1997 7.98235 12.3169 8.09956C12.4342 8.21677 12.5 8.37574 12.5 8.5415Z" fill="#878B90"/>
                                                </svg>
                                            </div>
                                        ))}
                                        <button className={"event_setting_button_list_add_button"} onClick={() => this.onClickAddButton()}>
                                            <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width: "16px", height : "16px", marginRight : "4px"}}>
                                                <path d="M17.5 10C17.5 10.1658 17.4342 10.3247 17.3169 10.4419C17.1997 10.5592 17.0408 10.625 16.875 10.625H10.625V16.875C10.625 17.0408 10.5592 17.1997 10.4419 17.3169C10.3247 17.4342 10.1658 17.5 10 17.5C9.83424 17.5 9.67527 17.4342 9.55806 17.3169C9.44085 17.1997 9.375 17.0408 9.375 16.875V10.625H3.125C2.95924 10.625 2.80027 10.5592 2.68306 10.4419C2.56585 10.3247 2.5 10.1658 2.5 10C2.5 9.83424 2.56585 9.67527 2.68306 9.55806C2.80027 9.44085 2.95924 9.375 3.125 9.375H9.375V3.125C9.375 2.95924 9.44085 2.80027 9.55806 2.68306C9.67527 2.56585 9.83424 2.5 10 2.5C10.1658 2.5 10.3247 2.56585 10.4419 2.68306C10.5592 2.80027 10.625 2.95924 10.625 3.125V9.375H16.875C17.0408 9.375 17.1997 9.44085 17.3169 9.55806C17.4342 9.67527 17.5 9.83424 17.5 10Z" fill="#333333"></path>
                                            </svg>
                                            버튼 추가
                                        </button>
                                    </div>
                                    <div className="margin-vertical-auto flexBox m-l-34 m-t-15">
                                        <div className="icn_warning_round"></div>
                                        <div style={{marginLeft:"10px", flex:"1"}}>
                                            템플릿 선택에 따라, 배너 이미지 내에 버튼 설정이 가능합니다.
                                        </div>
                                    </div>
                                </> : ""
                                }
                            </div>
                        </div>
                        <div className="form_primary_title m-t-100">
                            등록하기
                        </div>    
                        <div className="margin-vertical-auto" style={{width:"auto"}}>
                            <div className="redio_v2 m-t-32">
                                <RadioButton
                                    id={"display_yn_Y"}
                                    name={"display_yn"}
                                    checked={this.state.display_yn == "Y"}
                                    onChangeCheck={() => this.onClickDisplayYn("Y")}
                                    className={"event_display_radio"}
                                >
                                    <div className="banner_radio_text">{this.state.book_yn == "Y" ? "예약 진열": "진열"}</div>
                                </RadioButton>
                                <RadioButton
                                    id={"display_yn_N"}
                                    name={"display_yn"}
                                    checked={this.state.display_yn == "N"}
                                    onChangeCheck={() => this.onClickDisplayYn("N")}
                                    className={"event_display_radio"}
                                >
                                    <div className="banner_radio_text">미진열</div>
                                </RadioButton>
                            </div>
                        </div>
                    <div className="width-100 flexBox btn_save_cancel_v2" style={{marginTop:"34px", marginBottom:"50px"}}>
                        <button className="btn-175-b" onClick={this.saveEvent}>
                            저장하기
                        </button>

                        {(this.state.event_seq != null && this.state.event_seq != "") &&
                            <button className="btn-175-b" style={{marginLeft: "15px", textAlign: "center"}} onClick={(e) => this.handleDownloadBannerBtnClick(e)}>
                                {this.state.is_download ?
                                    <div>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.52813 10.8469C7.38764 10.7051 7.30921 10.5134 7.31009 10.3139C7.31097 10.1143 7.39109 9.92329 7.53281 9.78281C7.67454 9.64233 7.86626 9.5639 8.06581 9.56478C8.26537 9.56566 8.45639 9.64577 8.59687 9.7875L11.25 12.4406V3.75C11.25 3.55109 11.329 3.36032 11.4697 3.21967C11.6103 3.07902 11.8011 3 12 3C12.1989 3 12.3897 3.07902 12.5303 3.21967C12.671 3.36032 12.75 3.55109 12.75 3.75V12.4406L15.4031 9.7875C15.4727 9.71733 15.5554 9.66154 15.6465 9.62332C15.7376 9.58511 15.8354 9.56522 15.9342 9.56478C16.033 9.56435 16.1309 9.58338 16.2224 9.62079C16.3138 9.6582 16.397 9.71325 16.4672 9.78281C16.5374 9.85237 16.5932 9.93507 16.6314 10.0262C16.6696 10.1173 16.6895 10.2151 16.6899 10.3139C16.6903 10.4127 16.6713 10.5106 16.6339 10.6021C16.5965 10.6935 16.5414 10.7767 16.4719 10.8469L12.5344 14.7844C12.391 14.923 12.1994 15.0005 12 15.0005C11.8006 15.0005 11.609 14.923 11.4656 14.7844L7.52813 10.8469ZM20.25 13.5C20.0511 13.5 19.8603 13.579 19.7197 13.7197C19.579 13.8603 19.5 14.0511 19.5 14.25V19.5H4.5V14.25C4.5 14.0511 4.42098 13.8603 4.28033 13.7197C4.13968 13.579 3.94891 13.5 3.75 13.5C3.55109 13.5 3.36032 13.579 3.21967 13.7197C3.07902 13.8603 3 14.0511 3 14.25V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V14.25C21 14.0511 20.921 13.8603 20.7803 13.7197C20.6397 13.579 20.4489 13.5 20.25 13.5Z" fill="#FFFFFF"/>
                                        </svg>
                                        &nbsp;&nbsp;배너 다운로드
                                    </div>
                                    :
                                    <div className="list_loading" style={{margin: "0 auto"}}>
                                        <span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
                                    </div>
                                }
                            </button>
                        }

                        <div className="btn_cancel_v2 cursor-pointer" style={{height:"61px", marginLeft:"50px", lineHeight: "61px", color: "#b7bdc3"}} onClick={this.onClickBackButton}>
                            취소하고 돌아가기
                        </div>
                    </div>
                    <div style={{height: "300px"}}></div>
                    
                    <Draggable cancel=".fullCalendarModalClose">
                        <div className="fullCalendarModal">
                            <div className="fullCalendarInnerModal">
                                <div className="flexBox width-100">
                                    <div className="pcBanner" style={{pointerEvents:"none"}}>

                                    </div>
                                    <div className="mobileBanner" style={{pointerEvents:"none"}}>

                                    </div>
                                </div>
                                <div className="fa fa-close fullCalendarModalClose" onClick={this.onClickFullCalendarModalClose}></div>
                            </div>
                        </div>
                    </Draggable>

                    {this.state.page_load_tmp_state
                    ?<div className="btn_show_mirror_banner_modal" onClick={e => this.onClickMirrorBannerOnOff(e, "on")}>
                        <div>
                            <div className="btn_show_mirror_banner_modal_text">
                            배너 미리보기
                            </div>
                        </div>
                        <div>
                            <div className="btn_left_top_arrow"></div>
                        </div>
                    </div>
                    :<div className="btn_show_mirror_banner_modal">
                        <div>
                            <div className="btn_show_mirror_banner_modal_text">
                            배너 미리보기
                            </div>
                        </div>
                        <div>
                            <div className="btn_left_top_arrow"></div>
                        </div>
                    </div>}
                    {this.state.mirror_banner_state == "on"
                    ?<div className="mirror_banner_modal_v2" style={{visibility: "inherit"}}>
                        <div className="mirror_banner_v2_inner_area">
                            <div className="mirror_banner_v2_title">
                                <div>
                                미리보기
                                </div>
                                <div>
                                    <div className="btn_minus" onClick={e => this.onClickMirrorBannerOnOff(e, "off")}></div>
                                </div>
                            </div>
                            <div className="mirror_banner_v2_body" style={{position: "relative"}}>
                                {
                                    !this.state.iframe_page_id
                                    ?   <div className="flexBox" style={{position: "absolute", width:"100%", height:"100%", background:"#585858cc", color:"white"}}>
                                            <div style={{margin:"auto", textAlign:"center"}}>
                                            적용범위 - 페이지를 선택해 주세요.
                                            </div>
                                        </div>
                                    :   !this.state.iframe_html_id
                                        ?   <div className="flexBox" style={{position: "absolute", width:"100%", height:"100%", background:"#585858cc", color:"white"}}>
                                                <div style={{margin:"auto", textAlign:"center"}}>적용범위 - 배너를 선택해 주세요.</div>
                                            </div>
                                        : <>
                                            {   this.state.is_show_pc_banner_area
                                                ?   <div className="mirror_banner_v2_pc_banner_area"  style={{position: "relative"}}>
                                                        {
                                                            !this.state.event_type_cd
                                                            ?	<div className="flexBox curtain" style={{top: "0", width:"100%", background:"#585858cc", color:"white"}}>
                                                                    <div style={{margin:"auto", textAlign:"center"}}>
                                                                    가로형 배너 디자인을 선택해 주세요.
                                                                    </div>
                                                                </div>
                                                            : 	<>
                                                                <div className="flexBox" style={{height: "43px"}}>
                                                                    <label style={{margin:"auto 0 auto 0",}}><b>가로형 배너</b></label>
                                                                    {this.state.solution_type_cd != "09900" || this.state.pc_banner_ratio_list.length > 0
                                                                    ?<>
                                                                        <div className="cursor-pointer" style={{display:"flex", marginLeft:"10px"}} onClick={e => this.onClickBannerFullSize("w")}>
                                                                            <img src={plus_reading_glasses} style={{width:"23px", height:"23px", alignSelf:"center"}}/>
                                                                        </div>
                                                                        <div className="cursor-pointer" style={{display:"flex", marginLeft:"10px"}}>
                                                                            {
                                                                                this.state.is_download_w == true
                                                                                ?
                                                                                    <button className={"btn_download_banner_size"}>
                                                                                        <img src={icn_download} style={{width:"23px", height:"23px", alignSelf:"center"}} onClick={e => this.downloadOneBannerBtnClick("is_download_w")}/>
                                                                                        {this.state.selected_pc_banner_ratio.org_event_type == "p"
                                                                                            ? this.state.single_size
                                                                                                ?<span>&nbsp;&nbsp;(배너 사이즈)</span>
                                                                                                :<span>&nbsp;&nbsp;(배너 사이즈 1)</span>
                                                                                            :
                                                                                            <span>&nbsp;&nbsp;(배너 사이즈 2)</span>
                                                                                        }
                                                                                    </button>
                                                                                :
                                                                                    <div className="list_loading" style={{alignSelf: "center"}}>
                                                                                        <span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                    </>
                                                                    :""}
                                                                </div>
                                                                {this.state.pc_banner_ratio_list.length > 0
                                                                ?
                                                                    <>
                                                                        {
                                                                            this.state.pc_banner_ratio_list.length > 0
                                                                            ?   <div class="m-b-10" style={{display:"flex", alignItems: "center"}}>
                                                                                {
                                                                                        this.state.pc_banner_ratio_list.length > 1
                                                                                        ?
                                                                                            <>
                                                                                                <div>사이즈 : </div>
                                                                                                <select className="border_form_radius_b_non_padding" style={{ width: "150px", padding: "10px 15px 10px 12px",  margin: "auto 0.3vw auto 15px", backgroundPosition: "85%"}} value={this.state.selected_pc_banner_ratio.org_event_type} onChange={e => this.onChangeMirrorBannerRatio(e, "w")}>
                                                                                                    {this.state.pc_banner_ratio_list.map((obj,idx) => (
                                                                                                        <option value={obj.org_event_type}>
                                                                                                            {obj.org_banner_width > 50 || obj.org_banner_height > 50
                                                                                                                ? obj.org_banner_width+"x"+obj.org_banner_height+" (px)"
                                                                                                                : obj.banner_width+"x"+obj.banner_height+" (px)"}
                                                                                                        </option>     
                                                                                                    ))}
                                                                                                </select>
                                                                                            </>
                                                                                        : this.state.pc_banner_ratio_list.length > 0
                                                                                            && <div style={{alignSelf:"center", marginLeft:"5px"}}>
                                                                                                {this.state.pc_banner_ratio_list[0].org_banner_width > 50 || this.state.pc_banner_ratio_list[0].org_banner_height > 50
                                                                                                        ?   "사이즈 : "+this.state.pc_banner_ratio_list[0].org_banner_width+"x"+this.state.pc_banner_ratio_list[0].org_banner_height+" (px)"
                                                                                                        :   "사이즈 : "+this.state.pc_banner_ratio_list[0].banner_width+"x"+this.state.pc_banner_ratio_list[0].banner_height+" (px)"}
                                                                                                </div>
                                                                                    }
                                                                                </div>
                                                                            : ""
                                                                        }
                                                                        <div className="mirror_banner_v2_pc_banner">
                                                                            <object class="robomd_banner_p" type="text/html" style={{width:"100%", height:"100%", background: "#FFF"}}></object>
                                                                        </div>
                                                                    </>
                                                                : ""}
                                                                </>
                                                        }
                                                    </div>
                                                : ""
                                            }
                                            {
                                                this.state.is_show_mobile_banner_area
                                                ?    <div className="mirror_banner_v2_mobile_banner_area" style={{position: "relative"}}>
                                                        {
                                                            !this.state.event_mobile_type_cd
                                                            ?	<div className="flexBox curtain" style={{top: "0", width:"100%", background:"#585858cc", color:"white"}}>
                                                                    <div style={{margin:"auto", textAlign:"center"}}>
                                                                    세로형 배너 디자인을 선택해 주세요.
                                                                    </div>
                                                                </div>
                                                            : 	<>
                                                                    <div className="flexBox" style={{height: "43px"}}>
                                                                        <label style={{margin:"auto 0 auto 0",}}><b>세로형 배너</b></label>
                                                                        {this.state.mobile_banner_ratio_list.length > 0
                                                                        ?<>
                                                                            <div className="cursor-pointer" style={{display:"flex", marginLeft:"10px"}} onClick={e => this.onClickBannerFullSize("h")}>
                                                                                <img src={plus_reading_glasses} style={{width:"23px", height:"23px", alignSelf:"center"}}/>
                                                                            </div>  
                                                                            <div className="cursor-pointer" style={{display:"flex", marginLeft:"10px"}}>
                                                                                {
                                                                                    this.state.is_download_h == true
                                                                                    ?
                                                                                        <button className={"btn_download_banner_size"}>
                                                                                            <img src={icn_download} style={{width:"23px", height:"23px", alignSelf:"center"}} onClick={e => this.downloadOneBannerBtnClick("is_download_h")}/>
                                                                                            {this.state.selected_mobile_banner_ratio.org_event_type == "m"
                                                                                                ?
                                                                                                <span>&nbsp;&nbsp;(배너 사이즈 2)</span>
                                                                                                : this.state.single_size
                                                                                                    ?<span>&nbsp;&nbsp;(배너 사이즈)</span>
                                                                                                    :<span>&nbsp;&nbsp;(배너 사이즈 1)</span>
                                                                                            }
                                                                                        </button>
                                                                                    :
                                                                                        <div className="list_loading" style={{alignSelf: "center"}}>
                                                                                            <span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
                                                                                        </div>
                                                                                }
                                                                            </div>
                                                                        </>
                                                                        :""}
                                                                    </div>
                                                                    {this.state.mobile_banner_ratio_list.length > 0
                                                                    ?
                                                                        <>
                                                                            {
                                                                                this.state.mobile_banner_ratio_list.length > 0
                                                                                ?   <div class="m-b-10" style={{display:"flex", alignItems: "center"}}>
                                                                                    {
                                                                                            this.state.mobile_banner_ratio_list.length > 1
                                                                                            ?   
                                                                                                <>
                                                                                                    <div>사이즈 : </div>
                                                                                                    <select className="border_form_radius_b_non_padding" style={{ width: "150px", padding: "10px 15px 10px 12px",  margin: "auto 0.3vw auto 15px", backgroundPosition: "85%"}} value={this.state.selected_mobile_banner_ratio.org_event_type} onChange={e => this.onChangeMirrorBannerRatio(e, "h")}>
                                                                                                        {this.state.mobile_banner_ratio_list.map((obj,idx) => (
                                                                                                            <option value={obj.org_event_type}>
                                                                                                                {obj.org_banner_width > 50 || obj.org_banner_height > 50
                                                                                                                ? obj.org_banner_width+"x"+obj.org_banner_height+" (px)"
                                                                                                                : obj.banner_width+"x"+obj.banner_height+" (px)"}
                                                                                                            </option>
                                                                                                        ))}
                                                                                                    </select>
                                                                                                </>
                                                                                            : this.state.mobile_banner_ratio_list.length > 0
                                                                                                && <div style={{alignSelf:"center", marginLeft:"5px"}}>
                                                                                                        {this.state.mobile_banner_ratio_list[0].org_banner_width > 50 || this.state.mobile_banner_ratio_list[0].org_banner_height > 50
                                                                                                        ?   "사이즈 : "+this.state.mobile_banner_ratio_list[0].org_banner_width+"x"+this.state.mobile_banner_ratio_list[0].org_banner_height+" (px)"
                                                                                                        :   "사이즈 : "+this.state.mobile_banner_ratio_list[0].banner_width+"x"+this.state.mobile_banner_ratio_list[0].banner_height+" (px)"}
                                                                                                    </div>
                                                                                        }
                                                                                    </div>
                                                                                : ""
                                                                            }
                                                                            <div className="mirror_banner_v2_mobile_banner">
                                                                                <object class="robomd_banner_m" type="text/html" style={{width:"100%", height:"100%", background: "#FFF"}}></object>
                                                                            </div>
                                                                        </>
                                                                    : ""}
                                                                </>
                                                        }
                                                    </div>
                                                : ""
                                            }
                                            {this.state.pc_banner_ratio_list.length == 0 && this.state.mobile_banner_ratio_list.length == 0
                                            ? <div class="mirror_banner_non_input_size_notice">배너 사이즈를 입력해 주세요.</div>
                                            : ""}
                                        </>       
                                }
                            </div>
                            <div className="flexBox width-100">
                            </div>
                            <br/>
                            <font style={{margin:"auto", color:"#bfc4d6", fontSize:"13px", lineHeight:"10px"}}>(실제 적용된 모습과 다를 수 있습니다. 참고용으로 사용해 주세요.)</font>
                        </div>
                    </div>
                    : ""}

                    {/* 팔레트 배경 */}
                    <div id="palleteBack" style={{top: "0", left:"0", width:"100%", height:"100%", position:"fixed", display:"none", background:"#ffffff00"}} onClick={e => this.onCloseRactangleColorButton(e)}></div>
                    
                    {/* <Prompt
                    when={isFormIncomplete}
                    message="이 페이지를 벗어나면 저장하지 않은 내용은 모두 초기화 됩니다." /> */}
                </div>
                {/** 분할된 상세 이미지 선택 팝업 */
                    this.state.select_detail_crop_img_popup_show == true
                    &&  <DetailImgCropPopup
                            onClose={() => {
                                this.setState({
                                    select_detail_crop_img_popup_show : false,
                                });
                            }}
                            crop_img_info = {this.state[this.state.select_img_type+"_crop_img_info_list"][this.state.select_img_idx]}
                            selectImgUpload={(crop_img_info) => {
                                let crop_img_info_list = this.state[this.state.select_img_type+"_crop_img_info_list"];
                                crop_img_info_list[this.state.select_img_idx] = crop_img_info;
                                let user_img_list = this.state[this.state.select_img_type+"_user_img_list"];
                                user_img_list[this.state.select_img_idx].link_type = "49003";
                                user_img_list[this.state.select_img_idx].img_link = crop_img_info.crop_img_link_list[crop_img_info.crop_img_seleted_no];
                                user_img_list[this.state.select_img_idx].org_img_name = crop_img_info.crop_img_link_list[crop_img_info.crop_img_seleted_no];
                                user_img_list[this.state.select_img_idx].org_img_seq = "";
                                user_img_list[this.state.select_img_idx].video_link = "";
                                this.setState({
                                    select_detail_crop_img_popup_show : false,
                                    [this.state.select_img_type+"_user_img_list"] : user_img_list,
                                    [this.state.select_img_type+"_crop_img_info_list"] : crop_img_info_list,
                                }, () => {
                                    this.eventChangeOneProductImageOfNukki(this.state.select_img_type, this.state.select_img_idx);
                                });
                            }}
                        />}
                {/** 로보 이미지 팝업 */
                    this.state.show_robo_img_popup
                    ?<RoboImgSettingPopup
                        onClose={() => {
                            this.setState({show_robo_img_popup : false})
                        }}
                        sendRoboImg={(img) => {
                            let type = this.state.select_img_type;
                            let idx = this.state.select_img_idx;
                            let user_img_list = this.state[type+"_user_img_list"];
                            user_img_list[idx].link_type = "49004";
                            user_img_list[idx].img_link = img;
                            user_img_list[idx].org_img_name = img;
                            user_img_list[idx].org_img_seq = "";
                            user_img_list[idx].video_link = "";
                            this.setState({
                                [type+"_user_img_list"] : user_img_list,
                                show_robo_img_popup : false,
                            }, () => {
                                this.eventChangeOneProductImageOfNukki(type, idx);
                            });
                        }}
                        eventSeq={this.state.event_seq}
                        imgPosition={Number(this.state.select_img_idx)+1}
                        bannerCd={this.state.select_img_type == "w" ? this.state.event_type_cd : this.state.event_mobile_type_cd}
                        bannerDirection={this.state.select_img_type == "w" ? "03000" : "07000"}
                    />
                    :""
                }
                {/* 배경제거, 이미지 업로드 등 로딩 상태표시 */
                    (this.state.save_dialog_show_state === true)
                    ? <SaveDialogV2
                        content={this.state.save_dialog_content}
                        button_list={this.state.save_dialog_button_list}
                    />
                    : ''
                }
                {/* 안내 문구 전달용 기본 다이얼로그 */
					(this.state.basic_dialog_show_state === true)
						? <BasicDialogV2
							content={this.state.basic_dialog_content}
                            content2={this.state.basic_dialog_content2}
							button_list={this.state.basic_dialog_button_list}
						/>
						: ''
				}
                {/* 이미지 링크 작성 다이얼로그 */
                    (this.state.input_dialog_show_state === true)
                    ? <InputDialogV2
                        title={this.state.input_dialog_title}
                        content={this.state.input_dialog_content}
                        input_value={this.state.input_dialog_input_value}
                        input_placeholder={this.state.input_dialog_input_placeholder}
                        button_list={this.state.input_dialog_button_list}
                        popup_close={() => {
                            this.setState({input_dialog_show_state: false});
                        }}
                    />
                    : ''
                }
                {/* 유튜브 링크 업로드 팝업 */
                    this.state.showUploadYoutubeLinkPopup
                    && <UploadYoutubeLinkPopup
                        onClose={() => {
                            this.setState({showUploadYoutubeLinkPopup: false});
                        }}
                        link={this.state[this.state.select_img_type+"_user_img_list"][this.state.select_img_idx].video_link}
                        getVideoInfo={(items, link) => {
                            const type = this.state.select_img_type;
                            const idx = this.state.select_img_idx;
                            let thumbnails = items[0].snippet.thumbnails;
                            let user_img_list = this.state[type+"_user_img_list"];
                            user_img_list[idx].link_type = "49005";
                            // 썸네일 이미지가 큰것을 우선으로 사용
                            user_img_list[idx].img_link = thumbnails.maxres ? thumbnails.maxres.url : thumbnails.standard ? thumbnails.standard.url : thumbnails.high ? thumbnails.high.url : thumbnails.medium ? thumbnails.medium.url : thumbnails.default && thumbnails.default.url;
                            user_img_list[idx].org_img_name = link;
                            user_img_list[idx].org_img_seq = "";
                            user_img_list[idx].video_link = link;
                            this.setState({
                                [type+"_user_img_list"]: user_img_list,
                                showUploadYoutubeLinkPopup: false,
                            }, () => {
                                this.eventChangeOneProductImageOfNukki(type, idx);
                            })
                        }}
                    />
                }
                {/* 경고 다이얼로그 */
                    this.state.warning_dialog_show_state === true &&
                    <WarningDialog
                        content={this.state.warning_dialog_content}
                        button_list={this.state.warning_dialog_button_list}
                    />
                }
                {/* 템플릿 태그 필터 팝업 */
                    this.state.show_template_filter_popup &&
                    <PopupFrameV2
                        className={"template_filter_popup"}
                        maxWidth={"950px"}
                        onClose={this.closeTemplateFilterPopupBtnClick.bind(this)}
                    >
                        <div className={"template_filter_popup_container"}>
                            <h4>템플릿 필터</h4>
                            {this.state.show_template_filter_popup_type == "03000"
                                ?
                                Object.keys(this.state.w_template_all_tag_list).map((category, tag_idx) => (
                                    <section key={category}>
                                        <h5>{this.state.template_ctg_list.at(tag_idx).tag_name}</h5>
                                        <div>
                                            <button style={this.state.w_all_selected[category] ? {backgroundColor:'#00E2E6', border: '1px solid #00E2E6'} : {backgroundColor: '#FFF', border: '1px solid #ACACAC'}}
                                                    onClick={() => this.handleAllTagBtnClick(category, "w")}>{this.state.template_tag_list ? this.state.template_tag_list[0].tag_name : ""}</button>
                                            {this.state.w_template_all_tag_list[category].map((tag)=>(
                                                <TagButton key={tag.tag_cd} tagCd={tag.tag_cd} tagName={tag.tag_name}
                                                           isSelected={tag.isSelected}
                                                           onClick={(tagCd, isSelected) => this.handleTagBtnClick(category, tag.tag_cd, isSelected, "w")}/>
                                            ))}
                                        </div>
                                    </section>
                                ))
                                :
                                Object.keys(this.state.h_template_all_tag_list).map((category, tag_idx) => (
                                    <section key={category}>
                                        <h5>{this.state.template_ctg_list.at(tag_idx).tag_name}</h5>
                                        <div>
                                            <button style={this.state.h_all_selected[category] ? {backgroundColor:'#00E2E6', border: '1px solid #00E2E6'} : {backgroundColor: '#FFF', border: '1px solid #ACACAC'}}
                                                    onClick={() => this.handleAllTagBtnClick(category, "h")}>{this.state.template_tag_list ? this.state.template_tag_list[0].tag_name : ""}</button>
                                            {this.state.h_template_all_tag_list[category].map((tag)=>(
                                                <TagButton key={tag.tag_cd} tagCd={tag.tag_cd} tagName={tag.tag_name}
                                                           isSelected={tag.isSelected}
                                                           onClick={(tagCd, isSelected) => this.handleTagBtnClick(category, tag.tag_cd, isSelected, "h")}/>
                                            ))}
                                        </div>
                                    </section>
                                ))
                            }
                        </div>
                        <div className={"template_filter_popup_footer"}>
                            {this.state.show_template_filter_popup_type == "03000"
                                ? <button onClick={() => this.resetTemplateFilterBtnClick("03000", "w")}>초기화</button>
                                : <button onClick={() => this.resetTemplateFilterBtnClick("07000", "h")}>초기화</button>
                            }
                            {this.state.show_template_filter_popup_type == "03000"
                                ? <button onClick={() => this.applyTemplateFilterBtnClick("03000", "w")}>필터적용</button>
                                : <button onClick={() => this.applyTemplateFilterBtnClick("07000", "h")}>필터적용</button>
                            }
                        </div>
                    </PopupFrameV2>
                }
                {/* 저장시 기본배너 세팅 관련 다이얼로그 */
					this.state.title_content_dialog_show_state &&
                    <TitleContentDialogV2
                       title={this.state.title_content_dialog_title}
                       content={this.state.title_content_dialog_content}
                       button_list={this.state.title_content_dialog_button_list}
                       popup_close={this.state.title_content_popup_close}
                   />
               }
                {/* 화면 클릭 방지 */}
                <div id="blockPageClick" style={{top: "0", left:"0", width:"100%", height:"100%", position:"fixed", display:"none", background:"#ffffff00", zIndex:"10000"}} ></div>
            </>
        )
    }
}
import React from 'react';
import '../../css/notice_style.css';
import $ from 'jquery';
import axios from 'axios';
import http from '../modules/api';
import * as StringUtil from '../modules/utils/StringUtil';

import CKEditor from 'ckeditor4-react';
import ConfirmDialogV2 from '../modules/dialogs/ConfirmDialogV2';
// import ClassicEditor from '@ckeditor/ckeditor4-build-classic';

var isSaving = false;

// const service_info_text = "<p>원하시는 사항과 시간을 남겨주시면 상담 시간에 맞춰서</p>\n\n"
// 						+ "<p>상담 및 사용 안내를 드리겠습니다.</p>\n"
// 						+ "<p><strong>상담을 원하는 내용 :&nbsp;</strong></p>\n"
// 						+ "<p><strong>상담요일과&nbsp;시간 :&nbsp;</strong></p>\n"
// 						+ "<p>&nbsp;</p>\n"
// 						+ "<p>감사합니다.</p>";

// const service_info_text_01005 = "<p>트렌드인사이트는 기존의 MD들이 하는 업무방식대로 쇼핑몰 트렌드를 분석하는데 도움드리고 있습니다. 트렌드분석을 원하시는 쇼핑몰이름과 주소를 입력 해주십시요.</p>\n<p>&nbsp;</p>\n"
// 							  + "<p><strong>총 트렌드분석 쇼핑몰 수:</strong></p>\n"
// 							  + "<p><strong>쇼핑몰이름:</strong></p>\n"
// 							  + "<p><strong>쇼핑몰주소:</strong></p>\n<p>&nbsp;</p>\n"
// 							  + "<p>트렌드분석은 한 개의 쇼핑몰분석당 5만원의 월구독 비용이 발생합니다</p>\n"
// 							  + "<p>트렌드분석요청주시면 트렌드분석일정 및 견적비용을 안내드립니다</p>\n"
// 							  + "<p>추가적으로 자체구축하거나, 앱전용서비스는 별도의 추가비용이 발생할 수 있다는 점 양해바랍니다.</p>\n"
// 							  + "<p>언제나 로보MD는 쇼핑을 하는 소비자의 만족과 쇼핑몰운영자의 만족을 위해 노력하고 있습니다</p>\n"
// 						      + "<p>행복한 하루 보내세요</p>";
							  
// const service_info_text_01010 = "<p>랭킹 인사이트는 시장조사하는 업무방식대로 인기상품 트렌드를 분석하는데 도움드리고 있습니다.</p>\n<p>상품랭킹 분석을 원하시는 키워드 이름과 주소를 입력 해주십시요.</p>\n"
// 							  + "<p>가장 많은 상품과 매출동향을 알 수 있는 쿠팡의 상품랭킹 및 매출예측을 분석지원해드리고 있습니다.</p>\n<p>&nbsp;</p>\n"
// 							  + "<p><strong>총 랭킹분석 키워드 수:</strong></p>\n"
// 							  + "<p><strong>검색키워드 이름:</strong></p>\n<p>&nbsp;</p>\n"
// 							  + "<p>**플랫폼주소: 쿠팡 (**현재는 쿠팡의 상품의 랭킹분석을 지원하고 있으며 추가로 다른 플랫폼분석서비스가 차후 런칭될 예정입니다. 다른 플랫폼분석을 원하시다면 추가로 플랫폼명을 입력해주세요)</p>\n"
// 							  + "<p>랭킹분석은 한 개의 키워드분석당 3만원의 월구독 비용이 발생합니다.</p>\n"
// 							  + "<p>랭킹분석요청주시면 랭킹분석일정 및 견적비용을 안내드립니다.</p>\n"
// 							  + "<p>추가적으로 다른 플랫폼서비스는 별도의 추가비용이 발생할 수 있다는 점 양해바랍니다.</p>\n"
// 							  + "<p>언제나 로보MD는 쇼핑을 하는 소비자의 만족과 쇼핑몰운영자의 만족을 위해 노력하고 있습니다.</p>\n"
// 							  + "<p>행복한 하루 보내세요.</p>";

// const service_info_text_01002 = "<p>로보MD 진열 설치 서비스는 상품상세페이지 상단과 하단의 설치하는 경우와 카페24를 제외한 고객님을 위해 별도로 설치를 지원해드립니다.</p>"
// 								+ "<p>아래의 정보를 받은 후 디자인상담 및 일정과 견적공유 후 개발자가 배정되어 안전하게 로보MD진열을 설치해드립니다.</p>"
// 								+ "<p>&nbsp;</p>"
// 								+ "<p><strong>1. 설치를 원하는 쇼핑몰의 노출된 상품진열제목 :</strong></p>"
// 								+ "<p>(설치위치를 파악하기 위해 로보MD진열로 교체를 원하는 상품진열 페이지와 상품진열제목을 남겨주세요)</p>"
// 								+ "<p><strong>2. 로보MD 자동진열제목 :</strong></p>"
// 								+ "<p>(<strong>설치를 원하는 진열을 등록해서 자동진열설정을 진행해주시고 제목을 남겨주세요.</strong> 로보MD에서 설치형진열등록을 하고, 자동진열설정을 하시면 해당 진열을 설치해드립니다)</p>"
// 								+ "<p><strong>3. 설치접속권한 ( 카페24의경우)</strong></p>"
// 								+ "<p><strong>- 대표&nbsp;운영자 아이디:</strong></p>"
// 								+ "<p><strong>- 부&nbsp;운영자 아이디:</strong></p>"
// 								+ "<p><strong>- 부 운영자 비밀번호:</strong></p>"
// 								+ "<p>부운영자발급시 필요한 권한이 있습니다. 아래의 권한을 체크해서 발급해주세요</p>"
// 								+ "<p>( 권한 : 상품&nbsp;탭/디자인/주문&nbsp;통계/앱&nbsp;설치/회원 읽기)</p>"
// 								+ "<p>주신 정보는 설치 후 테스트를 위해 1주간 보관 후 폐기합니다.</p>"
// 								+ "<p><strong>5. 쇼핑몰주소 (URL) :</strong></p>"
// 								+ "<p><strong>6. 추가진열의 경우 (새로운 진열를 추가하지 않는 경우는 생략) :</strong></p>"
// 								+ "<p>기존 진열과 다르게 새로운 상품진열을 추가하시고 싶은 경우에는 위치와 디자인 유형을 남겨주세요. 확인후 연락드리겠습니다.</p>"
// 								+ "<p>솔루션에 위 권한을 부여받은 이후 디자인센터에 직접 접속하여 스크립트설치와 코드를 개발자가 직접 수정해드립니다.</p>"
// 								+ "<p>꼭 디자인수정권한이 있는 부운영자아이디를 발급해서 전송해주시길 바랍니다.</p>"
// 								+ "<p>진열 유형의 난이도에 따라 3일~1주정도 소요가 예상됩니다. 난이도가 높은 진열시는 좀 더 소요될 수 있다는 점 양해바랍니다.</p>"

export class QnaForm extends React.Component {
	constructor(props) {
		super(props);
	    this.state = {
			qna_type_list: [],
			qna_type_cd : "",
			qna_seq: null,
			recuFile: '',
			files: null,
			ck_content: '',
			title: "",
			qna: {title:'', content:'', qna_type_cd:''}
		}
	}

	componentDidMount() {
		isSaving = false;
		// this.props.history.goBack();
		// this.props.history.push(`/shop/qna/list`);
		// uri 파라메터 얻기: this.props.match.params
		const {qna_seq} = this.props.match.params;
		this.state.qna_seq = qna_seq;
		// console.log("### 1. componentDidMount" + qna_seq);
		// this.getNotice(qna_seq);
	}

	getQnaTypeList() {
		http.get("/rest/common/qna/type")
		.then(res => {
			// 타입 목록 설정
			let qna_type_list = res.data["list"];
			// 기간연장, 배너설치 값을 문의하기 바로 밑으로 올리기
			//let new_type = qna_type_list.splice(3,2);
			//qna_type_list.splice(1,0,new_type[1]);
			//qna_type_list.splice(1,0,new_type[0]);
			// 서비스 상담 안내 맨 위로 올리기
			// let service_info = qna_type_list.filter(obj => obj.cd_code == "2109")[0];
			// qna_type_list = qna_type_list.filter(obj => obj.cd_code != "2109");
			// qna_type_list.splice(0,0,service_info);

			// 선택된 타입
			let qna_type_cd = qna_type_list[0].cd_code;
			if(this.props.location.state && this.props.location.state.qna_type_cd){
				qna_type_cd = this.props.location.state.qna_type_cd;
			} else if (StringUtil.getUrlParams("qna_type_cd")) {
				qna_type_cd = StringUtil.getUrlParams("qna_type_cd");
			} else if (this.state.qna["qna_type_cd"]) {
				qna_type_cd = this.state.qna["qna_type_cd"];
			}

			this.setState({
				qna_type_list: qna_type_list, 
				qna_type_cd: qna_type_cd,
				ck_content : qna_type_list.find((obj) => obj.cd_code == qna_type_cd)["cd_value4"],
			});
			
			// if(!this.state.qna_seq && qna_type_cd == "2109"){
			// 	this.setState({ck_content : service_info_text});
			// } else if (!this.state.qna_seq && qna_type_cd == "2107") {
			// 	this.setState({ck_content : service_info_text_01005});
			// } else if (!this.state.qna_seq && qna_type_cd == "2108") {
			// 	this.setState({ck_content : service_info_text_01010});
			// } else if (!this.state.qna_seq && qna_type_cd == "2111") {
			// 	this.setState({ck_content : service_info_text_01002});
			// }
		})
		.catch(error => {
			// console.log (error);
		});
	}

	getNotice(id) {
		// new Promise( ( resolve, reject ) => {setTimeout( resolve, 200 ); })
		// .then( () => {
			http.get(`/rest/shop/qna/${id}`).then(res => {
				console.log(res.data);
				this.setState({qna: res.data});
				// this.recuFile = res.data["file_name"];
				$("#attachFileName").text(this.state.qna["file_name"]);
				// this.setState({recuFile: res.data["file_name"]});
				console.log("### content: "+this.state.qna.content);
				this.setState({ck_content: this.state.qna.content});
				this.setState({title: this.state.qna.title});

				this.getQnaTypeList();
			})
			.catch(error => {
				// console.log (error);
			 });
		// } );
	}

	handleOnChangeQNAType = (e) => {
		const value = e.target.value;
		this.setState({
			show_confirm_dialog : true,
			show_confirm_dialog_button : [{
				title : "네",
				event_method : (e) => {
					this.setState({
						show_confirm_dialog : false,
						qna_type_cd : value,
						ck_content : this.state.qna_type_list.find((obj) => obj.cd_code == value)["cd_value4"],
						// ck_content : (value == "2109" ? service_info_text : 
						// 			  value == "2107" ? service_info_text_01005 : 
						// 			  value == "2108" ? service_info_text_01010 :
						// 			  value == "2111" ? service_info_text_01002 : ""),
					});
				}
			},{
				title : "아니요",
				event_method : () => {this.setState({show_confirm_dialog : false})}
			}]
		});
	}

	handleChangeRecuFile = e => { 
		var target = e.target;
		this.setState({recuFile: target.value});
		console.log(target.files[0]);
		this.setState( {files: target.files[0]});
		if (target.files != null && target.files[0] != null) {
			var reader = new FileReader();
			reader.onload = function (e) {
				$("#attachFileName").text(target.files[0].name);
			}
			reader.readAsDataURL(target.files[0]);
		} else {
			$("#attachFileName").text("");
		}
	};

	handleClickBtnFileUpload  = e => { 
		e.preventDefault ();
		$("#recuFile").click();	
	};

	handleClickBtnSave  = e => { 
		$("#qnaForm").submit();
	};

	onFormSubmit = e => {
		e.preventDefault (); // Stop form submit
		console.log(this.state.files);
		if (this.state.files != null) {
			var saveNotiFileUpload = this.saveNotiFileUpload(this.state.files);
			if (saveNotiFileUpload != null) {
				saveNotiFileUpload.then((response)=>{
					if(typeof response.data === 'string') {
						if(response.data.indexOf("<script>") >= 0){
							alert("로그인 후 이용해 주세요.")
							window.location.href = "/login";
						}
					}

					if (response.data["result"] == "true") {
						alert("게시물이 등록 되었습니다.");
						isSaving = false;
						if(this.state.qna_seq == null) {
							localStorage.clear();
						}
						if (document.referrer.indexOf("/qna/list") > 0){
							this.props.history.goBack();
						} else {
							this.props.history.push(`/shop/qna/list`);
						}
					}
					console.log(response.data);
				})
				.catch(error => { // your error handling goes here
					console.log(error);
					isSaving = false;
				})
			}
		} else {
			var saveNoti = this.saveNoti()
			if (saveNoti != null) {
				saveNoti.then((response)=>{
					if(typeof response.data === 'string') {
						if(response.data.indexOf("<script>") >= 0){
							alert("로그인 후 이용해 주세요.")
							window.location.href = "/login";
						}
					}

					if (response.data["result"] == "true") {
						alert("게시물이 등록 되었습니다.");
						isSaving = false;
						if(this.state.qna_seq == null) {
							localStorage.clear();
						}
						if (document.referrer.indexOf("/qna/list") > 0){
							this.props.history.goBack();
						} else {
							this.props.history.push(`/shop/qna/list`);
						}
					}
					console.log(response.data);
				})
				.catch(error => { // your error handling goes here
					console.log(error);
					isSaving = false;
				})
			}
		}
	}

	saveNotiFileUpload(file){
		if (isSaving) {
			alert("게시물 등록중입니다.")
			return false;
		} else {
			isSaving = true;
		}

		if (this.state.title == null || this.state.title == "") {
			alert("제목을 입력하여주세요.");
		} else {
			const url = '/rest/shop/save/file/qna';
			const formData = new FormData();
			formData.append('recuFile',file);
			formData.append('content',this.state.ck_content);
			formData.append('title',this.state.title);
			formData.append('qna_seq',this.state.qna_seq);
			formData.append('qna_type_cd',this.state.qna_type_cd);
			const config = {
				headers: {
					'content-type': 'application/x-www-form-urlencoded; charset=utf-8'
				}
			}
			return  http.post(url, formData,config);
		}
	}  
	
	saveNoti(){
		if (isSaving) {
			alert("게시물 등록중입니다.")
			return false;
		} else {
			isSaving = true;
		}

		if (this.state.title == null || this.state.title == "") {
			alert("제목을 입력하여주세요.");
		} else {
			const url = '/rest/shop/save/qna';
			const formData = new FormData();
			formData.append('content',this.state.ck_content);
			formData.append('title',this.state.title);
			formData.append('qna_seq',this.state.qna_seq);
			formData.append('qna_type_cd',this.state.qna_type_cd);
			// const config = {
			// 	headers: {
			// 		'content-type': 'application/x-www-form-urlencoded; charset=utf-8'
			// 	}
			// }
			return http.post(url, formData);
		}
	}
	
	async delNotice(id) {
		const res = await http.post(`/rest/shop/del/qna/${id}`);
		console.log(res.data)
		localStorage.clear();
		if (res.data != null && res.data["result"] == "true") {
			alert("게시물이 삭제 되었습니다.")
			// this.props.history.goBack();
			this.props.history.push(`/shop/qna/list`);
		}
	}

	render() {
		$(".pageName").text("문의사항");
		return (
			<div id='page-wrapper' className="container qna_list_container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
				<div className="form_primary_title pc_page_title qna_title">나의 문의</div>
				<div className='qna_sub_title'>
					<h2>
						로보MD서비스 석세스매니저가 상담을 전담하고 있습니다.<br/>
						서비스유형을 선택하여 문의주시면 친절히 안내드리겠습니다.
					</h2>
					<h3>
						사용법 가이드 문의
						<span>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
								<path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
							</svg>
							1599-3518
						</span>
					</h3>
				</div>
				<div>
					<input type="text" onChange={(e) => {
						this.state.title = e.target.value
						console.log(this.state.title)
						this.setState({ title: this.state.title })
					}} placeholder="제목을 입력하세요." style={{width:"100%", border:"1px solid #c4c4c4", padding:"10px", marginBottom:"10px"}} defaultValue={this.state.qna.title || ''}>
					</input>
				</div>

				<div className="input-group m-b" style={{width:"100%"}}>
					{/* <label>종류</label> */}
					<select value={this.state.qna_type_cd} onChange={e => this.handleOnChangeQNAType(e)}
						style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px", background: "url(https://img.otdeal.net/board/2019/12/11/d9e80c40-958e-4ec8-9726-0b4bc0f93c67.png) no-repeat 98% center" , backgroundSize: "15px"}}
					>
						{this.state.qna_type_list.map((qna, idx) => (	
							<option value={qna.cd_code} key={qna.cd_code}>
								{qna.cd_desc}
							</option>								
						))};
					</select>
				</div>

				<div className="App" style={{marginBottom:"10px"}}>
					<CKEditor 
						data={this.state.ck_content}
						onInstanceReady={ () => {
							this.getNotice(this.state.qna_seq);
						}}
						onChange={ event => {
							this.setState({ ck_content: event.editor.getData() });
							console.log(this.state.ck_content );
						}}
						config={{
							filebrowserBrowseUrl: "https://robomd.io/rest/shop/qna/uplaodImageFromEditor",
							filebrowserUploadUrl: "https://robomd.io/rest/shop/qna/uplaodImageFromEditor",
							height: 500
						}}
					/>
				</div>
				
				<form onSubmit={this.onFormSubmit} id="qnaForm">
					<div className="fileUp">
						<button id="btnFileUpload" onClick={this.handleClickBtnFileUpload}>파일첨부</button>
						<input type="file" id="recuFile" name="recuFile" className="form-control" onChange={this.handleChangeRecuFile} value={this.state.recuFile} style={{display:'none'}}/>
						<div id="attachFileName" className='fileName'></div>
					</div>
					<div style={{textAlign:"center"}}>
						<button type="submit" id="btnSave" className="btn-auto-b" style={{width:"80px", height:"50px", marginTop:"15px"}}> 
							저장
						</button>
					</div>
				</form>
				{this.state.show_confirm_dialog &&
					<ConfirmDialogV2
						content={"문의 유형변경 시 문의내용이<br/>기본 포맷으로 변경됩니다.<br/>변경하시겠습니까?"}
						button_list={this.state.show_confirm_dialog_button}
						popup_close={() => {this.setState({show_confirm_dialog : false})}}
					/>
				}
			</div>
		);
	}

	componentWillReceiveProps(nextProps, nextContext) {
		console.log("### 2. componentWillReceiveProps");
		console.log(nextProps);
		this.getNotice(nextProps.match.params.qna_seq);
	}
	
}


import React from 'react';

// 컴포넌트
import { TermsCndtnForm } from './TermsCndtnForm_v3';
import CheckBox from '../modules/common/CheckBox';

// 스타일
import '../../css/login_style.css';

// 이미지
import eye_n from '../../resources/img/eye_n.svg';
import eye_y from '../../resources/img/eye_y.svg';

import http from '../modules/api';

import ReactGA from 'react-ga';
// import ReactPixel from 'react-facebook-pixel';
import {ga4react, reactPixel} from '../Menu'

// 유틸
import * as StringUtil from '../modules/utils/StringUtil';

ReactGA.initialize('UA-78553461-8');
// ReactPixel.init('438227415050093');
reactPixel.pageView();

var ga_label = sessionStorage.getItem("userName");
var mall_id = sessionStorage.getItem("mall_id");

export class RegistUser_v3 extends React.Component {
    state = {
        user_join_cls: 'cafe24',

        registIdChk: "empty", // 아이디 유효성 검사 : "empty"/"used"/"valid"/true/false
        registPwChk: "empty", // 비밀번호 유효성 검사 : "empty"/true/false
        registPwReChk: "empty", // 비밀번호 재입력 유효성 검사 : "empty"/true/false
        registPwDisplayType: "hide", // 비밀번호 보임/숨김 : "hide"/"show"
        registPwDisplayType2: "hide", // 비밀번호 재입력 보임/숨김 : "hide"/"show"
        registEmailChk: "empty", // 이메일 유효성 검사 : "empty"/"used"/"valid"/true/false
        registAgreementAllChk: false, // 필수 약관 모두 동의 체크 : true/false
        registAgreementRequired1Chk: false, // 필수 약관 1 동의 체크 : true/false
        registAgreementRequired2Chk: false, // 필수 약관 2 동의 체크 : true/false
        registAgreementOptionalChk: false, // 선택 약관 동의 체크 : true/false
        termsDisplay: false, // 약관 전문 보임/숨김 : true/false
        terms: 1, // 약관 선택 체크 : 1/2/3
        
        id: "",
        password : "",
        email: "",
        tel_no: "",

        term_list: [],
        form_agree_list: [],
    }

    componentWillMount() {
        this.getFormTernm('banner');
    }

    componentDidMount() {
        // 토스트 메세지 팝업 이벤트
        if (window.innerWidth >= 1024) {
            document.getElementsByClassName("toast_msg")[0].classList.add("toast_popup");
            setTimeout(function() { document.getElementsByClassName("toast_msg")[0].classList.remove("toast_popup"); },3000);
        }

        // cafe24 App Store에서 접근시 중복가입 방지용
		let param_mall_id = StringUtil.getUrlParams("mall_id");
		let param_service_type = StringUtil.getUrlParams("service_type");
		console.log("param_mall_id : " + param_mall_id)
		console.log("param_service_type : " + param_service_type)
		if (param_mall_id != '' && param_mall_id != "undefined") {
			sessionStorage.setItem('mall_id', param_mall_id);
		}

		if (param_service_type == "01001") {
			this.setState({user_join_cls: 'cafe24'});
		} else if (param_service_type == "01002") {
			this.setState({user_join_cls: 'rp'});
		} else if (param_service_type == "01003") {
			this.setState({user_join_cls: 'kdeal'});
		}
    }

    //약관 조회
    getFormTernm(form_cls, form_type, form_seq) {
        http.get("/rest/common/formTernm",
            {
                params:{
                    form_cls : form_cls,
                    form_type : form_type,
                    form_seq : form_seq
                }
            })
            .then(res => {
                this.setState( {term_list : res.data} );

                // for(var idx in res.data) {
                //     var name = "pageNumber_"+res.data[idx]["form_seq"]
                //     var name_agree = "agree_"+res.data[idx]["form_seq"]
                //     this.setState( {
                //         [name]: 1,
                //         // [name_agree]: "N"
                //     });
                // }
            })
            .catch(err => {
                alert("가입약관 조회 에러");
            });
    }

    //아이디 중복체크
	handleIdDuplicateCheck = () => {
        if (this.state.registIdChk != "valid") {
            return false;
        }

		//1. Validation Check
		// if (this.state.user_id == null || this.state.user_id == "") {
		// 	alert("아이디를 입력해 주세요.");
		// 	return false;
		// }
		var pattern = /^[a-z0-9]{3,20}$/;
		if(!pattern.test(this.state.user_id)){
			// alert('영문소문자 , 숫자 3 ~20 자리로 입력해 주세요.')
			this.setState({user_id: ""});
			return false;
		} 
		http.get("/rest/common/id/check", {params:{user_id:this.state.user_id}})
	 	 	 .then(res => {
	 	 		 this.setState({pass_yn: res.data.passYn}, () => {
					if (this.state.pass_yn != true) {
						this.setState({
                            // user_id: "",
                            registIdChk: "used"
                        });
					} else {
                        this.setState({registIdChk: true});

						ga4react.event('[회원가입] Step1: 아이디 확인 성공', '아이디 확인 성공 ID=' + this.state.user_id, '회원가입');
						reactPixel.trackCustom('[회원가입] Step1: 아이디 확인 성공', {user_id : this.state.user_id});
					}
				});
				//    alert(res.data.retMsg);
	 	 		 //중복시 입력된 아이디 초기화
	 	 	 })
	 	 	 .catch(err => { 
	 	 		 alert("아이디 중복체크 오류"); 
	 	 	 });
    };

    /** 아이디 유효성 검사 */
    handleRegistIdChk = (e) => {
        // const num = /[0-9]/;
		// const engS = /[a-z]/;
		// const engL = /[A-Z]/;
        // const kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
        // const spe = /[~!@#$%^&*()_+|<>?:{}]/;

        var pattern = /^[a-z0-9]{3,20}$/;
        if (e.target.value === "") { this.setState({registIdChk:"empty"}); }
        else if(pattern.test(e.target.value)){
            this.setState({
                registIdChk:"valid",
                user_id : e.target.value
            }); 
        } else {
            this.setState({registIdChk:false}); 
        }
    }

    /** 비밀번호 유효성 검사 */
    handleRegistPwChk = (e) => {
        const num = /[0-9]/;
		const engS = /[a-z]/;
		// const engL = /[A-Z]/;
        const kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
		const spe = /[~!@#$%^&*()_+|<>?:{}]/;

        if (e.target.value === "") { this.setState({registPwChk:"empty"}); }
        else if (e.target.value.length >= 8 
            && e.target.value.length <= 20 
            && num.test(e.target.value) === true 
            // && engL.test(e.target.value) === true 
            && engS.test(e.target.value) === true 
            && spe.test(e.target.value) === true 
            && kor.test(e.target.value) === false) { 
                this.setState({registPwChk:true}); 
            } else { 
                this.setState({registPwChk:false}); 
            }

        let pwChk = document.getElementById("regist_pw_chk");
        if (pwChk.value === "") { this.setState({registPwReChk:"empty"}); }
        else {
            if (pwChk.value === e.target.value ) { this.setState({registPwReChk:true}); }
            else { this.setState({registPwReChk:false}); }
        }
    }

    /** 비밀번호 재입력 유효성 검사 */
    handleRegistPwReChk = (e) => {
        let pw = document.getElementById("regist_pw");
        if (e.target.value === "") { this.setState({registPwReChk:"empty"}); }
        else if (e.target.value !== "" && e.target.value === pw.value) { 
            this.setState({ 
                registPwReChk:true, 
                password:e.target.value, 
            });
        } else { 
            this.setState({registPwReChk:false}); 
        }
    }

    /** 비밀번호 보임/숨김 */
    handleRegistPwDisplayType = (e) => {
        if (e.target.checked) { this.setState({registPwDisplayType:"show"}); }
        else { this.setState({registPwDisplayType:"hide"}); }
    }

    /** 비밀번호 재입력 보임/숨김 */
    handleRegistPwDisplayType2 = (e) => {
        if (e.target.checked) { this.setState({registPwDisplayType2:"show"}); }
        else { this.setState({registPwDisplayType2:"hide"}); }
    }

    /** 이메일 유효성 검사 */
    handleEmailChk = (e) => {
        const pattern = /^[A-Za-z0-9_.-]+@[A-Za-z0-9-]+\.[A-Za-z0-9-]+/;

        if (e.target.value === "") { this.setState({registEmailChk:"empty"}); }
        else if (pattern.test(e.target.value) === true) { 
            this.setState({
                registEmailChk:"valid",
                email: e.target.value
            }); 
        } else { 
            this.setState({registEmailChk:false}); 
        }
    }

    // /** 약관 동의 클릭 이벤트 */
    handleChkTermsAll = (e) => {
        this.setFormAgree(e.target.checked, "all_check");
    }

    /** 필수 약관 클릭 이벤트 */
    handleChkTerms = (e, form_seq) => {
        this.setFormAgree(e.target.checked, form_seq);
    }

    /** 약관 동의를 누른 순서에 대한 변수 생성 */
    setFormAgree = (state, name) => {
        this.setState({ [name]: state });
        if (name == "all_check") {
            let list = [];
            if (state) {
                for(var idx in this.state.term_list) {
                    if (state) {
                        list.push(this.state.term_list[idx]["form_seq"].toString());
                    }
                }
                this.setState({ 
                    form_agree_list: list,
                    agree_all: true,
                }, () => {
                    console.log("form_agree_list", list);
                    this.setRegistAgreementAllChk();
                });
            } else {
                this.setState({ 
                    form_agree_list: [],
                    agree_all: false,
                }, () => {
                    this.setRegistAgreementAllChk();
                });
            }
        } else {
            let form_seq = name;
            let {form_agree_list} = this.state;
            console.log("form_seq", name);
            if (state) {
                form_agree_list.push(form_seq);
                this.setState({
                    form_agree_list : form_agree_list,
                    
                }, () => {
                    this.setState({
                        agree_all:  this.state.form_agree_list.length == this.state.term_list.length
                                ? true 
                                : false,
                    }, () => {
                        this.setRegistAgreementAllChk();
                    })
                });
            } else {
                form_agree_list.splice(this.state.form_agree_list.indexOf(form_seq), 1);
                this.setState({
                    form_agree_list : form_agree_list,
                    agree_all: false,
                }, () => {
                    this.setRegistAgreementAllChk();
                });
            }
        }
    }

    /** 필수가 아닌 요소 빼고 모두 동의 되었는지 체크 */
    setRegistAgreementAllChk() {
        let requisite_cnt = 0;
        let requisite_agree_cnt = 0;
        for (let idx in this.state.term_list) {
            if (this.state.term_list[idx].requisite_yn == "Y") {
                requisite_cnt ++;
                if (this.state.form_agree_list.includes(this.state.term_list[idx].form_seq.toString())) {
                    requisite_agree_cnt ++;
                } 
            }
        }
        if (requisite_cnt == requisite_agree_cnt) {
            this.setState({
                registAgreementAllChk : true  
            })
        } else {
            this.setState({
                registAgreementAllChk : false  
            })
        }

        console.log("registAgreementAllChk", requisite_cnt == requisite_agree_cnt);
    }

    /** 약관 전문 보기 */
    handleTermsBtnClick = (terms) => {
        this.setState({
            termsDisplay:true,
            terms:terms,
        });
        document.body.style.overflow = "hidden";
    }

    /** 선택 약관 전문 닫기 */
    handleTermsClose = (showType) => {
        if (showType === "close") { this.setState({termsDisplay:false}) }
        else { this.setState({termsDisplay:true}) }
        document.body.style.overflow = "auto";
    }

    /** 토스트 메세지 클릭 시, 아이디 인풋 클릭 시, 토스트 메세지 숨김 */
    handleToastMsg = () => { document.getElementsByClassName("toast_msg")[0].classList.add("hidden"); }

    /** 이메일 중복 체크 */
    emailDuplicated () {
        if (this.state.registEmailChk != "valid") {
            return false
        }

        http
        .get("/rest/common/emailDuplicated?email="+this.state.email)
        .then(res => {
            let is_email_no_duplicated = res.data.is_email_no_duplicated;
            if (Number(is_email_no_duplicated) == 1 ) {
                this.setState({
                    registEmailChk : "used"
                })

                ga4react.event('[회원가입] 이메일 중복', '이메일 중복 ID=' + this.state.email, '회원가입');
                reactPixel.trackCustom('[회원가입] 이메일 중복', {email : this.state.email});
            } else {
                this.setState({
                    registEmailChk : true
                })

                ga4react.event('[회원가입] 이메일 확인 성공', '이메일 확인 성공 ID=' + this.state.email, '회원가입');
                reactPixel.trackCustom('[회원가입] 이메일 확인 성공', {email : this.state.email});
            }
        })
        .catch(error => { 
            // this.setState({registEmailChk: false});
            return false;
        });
    }

    //가입신청 완료
	onClickSignupButton = () => {
		
		let body = {
            user_id : this.state.user_id,
            password : this.state.password,
            email : this.state.email,
            tel_no : this.state.tel_no,
            user_join_cls: this.state.user_join_cls,
        }

		// let signupBtn = document.getElementsByClassName("btn-auto-b");

		// if(!signupBtn[0].classList.contains("enabled")) {
		// 	return false;
		// }
		// else {
			const config = {
				headers: {
					'content-type': 'application/json;'
				}
			}
			
			//4. 가입신청
			http
			.post("/signup/v2", body, config)
			.then(
				resultData => {		
				console.log(resultData);				 
				if (!resultData.data.success) {
					// 가입신청 - 가입신청 오류
					ReactGA.event({
						category: "가입신청 오류",
						action: "신청 아이디 : " + body.user_id,
						label: ga_label
					});
					ga4react.event('[회원가입] 회원가입 실패', '회원가입 실패 ID=' + body.user_id, '회원가입');
					reactPixel.trackCustom('[회원가입] 회원가입 실패', {user_id : body.user_id});
					alert("가입신청 오류");
	
					return false;	
				}
				// 가입신청 - 가입신청 완료
				ReactGA.event({
					category: "가입신청 완료",
					action: "신청 아이디 : " + body.user_id,
					label: ga_label
				});
					ga4react.event('[회원가입] 회원가입 완료', '회원가입 완료 ID=' + body.user_id, '회원가입');
					reactPixel.trackCustom('[회원가입] 회원가입 완료', {user_id : body.user_id});
					// alert("가입이 완료되었습니다.");
	
				// 동의한 약관_순번 리스트 저장
				this.fn_formAgreeListSave();
				// mall_id 제거
				sessionStorage.removeItem("mall_id");
				})
			.catch(
				error => { 
					// 가입신청 - 가입신청 오류
					ReactGA.event({
						category: "가입신청 오류",
						action: "신청 아이디 : " + body.user_id,
						label: ga_label
					});
					ga4react.event('[회원가입] 회원가입 실패', '회원가입 실패 ID=' + body.user_id, '회원가입');
					reactPixel.trackCustom('[회원가입] 회원가입 실패', {user_id : body.user_id});
					alert("회원가입에 실패하였습니다.\n관리자에게 문의해주세요(1599-3518)");
				}
			);
		// }
	};

    // 동의한 약관_순번 리스트 저장
	async fn_formAgreeListSave() {

		const sendForm = {
			user_id: this.state.user_id,
			form_agree_list: this.state.form_agree_list
		};
		const config = {
            headers: {
                'content-type': 'application/json;'
            }
        };
		const res = await http.post("/rest/common/formAgreeSave", sendForm, config);

        // 이메일 발송
        this.sendAuthEmail();
	}

    // 이메일 발송
	sendAuthEmail = () => {
		
		let body = {
            user_id : this.state.user_id,
        }

        const config = {
            headers: {
                'content-type': 'application/json;'
            }
        }
			
        http
        .post("/rest/common/email/verify", body, config)
        .then(
            resultData => {		
                if (resultData.data.uuid != null
                    && resultData.data.uuid != "") {
                        // 이메일 인증 페이지로 이동 (회원가입 대기 페이지)
		                window.location.href = "/common/RegistConfirm?email_uid="+resultData.data.uuid;
                }
            })
        .catch(
            error => { 
                console.log(error);
            }
        );
	};

    render () {
        return (
            <>
                <div className='only_regist_user_page login_style_guide'>
                    <div className='only_regist_user'>
                        <h2>
                            로보MD 회원가입
                            <div className='toast_msg' onClick={this.handleToastMsg}>
                                지금 <span>5초 만에</span> 가입하고 <br/>
                                쇼핑몰 운영, <span>무료 체험</span>해보세요!
                            </div>
                        </h2>
                        <h3>아이디<span>*</span></h3>
                        <form autoComplete='new-password'>
                            <div className='regist_input regist_input_with_bg'>
                                <input className={this.state.registIdChk === "empty" 
                                    ? "" 
                                    : (this.state.registIdChk === "used" 
                                        ? "error" 
                                        : (this.state.registIdChk === "valid" 
                                            ? "" 
                                            : (this.state.registIdChk === true 
                                                ? "success" 
                                                : "error")))} 
                                id='regist_id' type='text' placeholder='3~20자의 영어 소문자, 숫자 사용' onChange={e => this.handleRegistIdChk(e)} onFocus={this.handleToastMsg} onBlur={e => this.handleIdDuplicateCheck(e)} autoComplete='off'/>
                                <span>
                                    {
                                        this.state.registIdChk === "empty" // 인풋이 비어있을 때
                                        ? ""
                                        : ( this.state.registIdChk === "used" // 아이디가 사용중일 때
                                            ? "이미 사용중인 아이디입니다."
                                            : ( this.state.registIdChk === "valid" // 아이디가 유효성 검사를 통과했을 때
                                                ? ""
                                                : ( this.state.registIdChk === true // 아이디가 중복체크를 통과했을 때
                                                    ? "사용 가능한 아이디입니다."
                                                    : "아이디는 3~20자 알파벳 소문자 숫자로 입력해 주세요."
                                                )
                                            )
                                        )
                                    }
                                </span>
                            </div>
                            <h3>비밀번호<span>*</span></h3>
                            <div className='regist_input'>
                                <input className={this.state.registPwChk === "empty" ? "" : (this.state.registPwChk === true ? "success" : "error")} id='regist_pw' type={this.state.registPwDisplayType === "hide" ? 'password' : 'text'} placeholder='8~20자 영문+숫자+특수문자' onChange={e => this.handleRegistPwChk(e)} autoComplete='off'/>
                                <div className='regist_visible_chk'>
                                    <input type='checkbox' id='regist_pw_visible' onChange={e => this.handleRegistPwDisplayType(e)}/>
                                    <label htmlFor='regist_pw_visible'>
                                        <img src={eye_n} alt="숨김 버튼"/>
                                        <img src={eye_y} alt="보임 버튼"/>
                                    </label>
                                </div>
                                <span>
                                    {
                                        this.state.registPwChk === "empty"
                                        ? ""
                                        : ( this.state.registPwChk === true
                                            ? "사용 가능한 비밀번호입니다."
                                            : "비밀번호는 영문(대소문자), 숫자, 특수문자를 조합하여 8~20자 이내로 입력해주세요."
                                        )
                                    }
                                </span>
                            </div>
                            <div className='regist_input'>
                                <input className={this.state.registPwReChk === "empty" ? "" : (this.state.registPwReChk === true ? "success" : "error")} id='regist_pw_chk' type={this.state.registPwDisplayType2 === "hide" ? 'password' : 'text'} placeholder='비밀번호 재입력' onChange={e => this.handleRegistPwReChk(e)} autoComplete='off'/>
                                <div className='regist_visible_chk'>
                                    <input type='checkbox' id='regist_pw_chk_visible' onChange={e => this.handleRegistPwDisplayType2(e)}/>
                                    <label htmlFor='regist_pw_chk_visible'>
                                        <img src={eye_n} alt="숨김 버튼"/>
                                        <img src={eye_y} alt="보임 버튼"/>
                                    </label>
                                </div>
                                <span>
                                    {
                                        this.state.registPwReChk === "empty"
                                        ? ""
                                        : ( this.state.registPwReChk === true
                                            ? "비밀번호가 일치합니다."
                                            : "비밀번호가 일치하지 않습니다."
                                        )
                                    }
                                </span>
                            </div>
                            {/* 자동 완성 방지용 더미 인풋 */}
                            <input type='password' autoComplete='new-password' style={{display:'none'}}/>
                        </form>
                        <h3>이메일<span>*</span></h3>
                        <div className='regist_input regist_input_with_bg'>
                            <input className={this.state.registEmailChk === "empty" 
                                ? "" 
                                : (this.state.registEmailChk === "used" 
                                    ? "error" 
                                    : (this.state.registEmailChk === "valid" 
                                        ? "" 
                                        : (this.state.registEmailChk === true 
                                            ? "success" 
                                            : "error")))}
                            id='regist_email' type='text' placeholder='aaa@example.com' onChange={e => this.handleEmailChk(e)} onBlur={e => this.emailDuplicated (e)}/>
                            <span>
                                {
                                   ( this.state.registEmailChk === "empty"
                                    ? ""
                                    : ( this.state.registEmailChk === "used"
                                        ? "이미 사용중인 이메일입니다."
                                        : this.state.registEmailChk === "valid"
                                            ? ""
                                            : ( this.state.registEmailChk === true
                                                ? "사용 가능한 이메일 주소입니다."
                                                : "입력하신 이메일 주소가 올바르지 않습니다."
                                            )
                                        )
                                    )
                                }
                            </span>
                        </div>
                        <h3>연락처</h3>
                        <div className='regist_input regist_input_with_bg'>
                            <input id='regist_phone' type='text' placeholder='000-0000-0000' onChange={
                                e => {
                                    this.setState({
                                        tel_no: e.target.value
                                    })
                                }
                            }/>
                        </div>
                        <div className='divider'></div>
                        <h2>서비스 이용 약관</h2>
                        <div className='agree_label_wrap agree_all_label_wrap'>
                            <CheckBox
                                id={"agree_all"}
                                value={this.state["agree_all"]}
                                checked={this.state["agree_all"]}
                                onChangeCheck={this.handleChkTermsAll.bind(this)}
                            >
                            </CheckBox>
                            <span className='agree_all_txt'>모두 동의</span>
                        </div>
                        <div className='agree_divider'></div>
                        
                        {this.state.term_list.map((form, idx) => (
                            <div className='agree_label_wrap'>
                                <CheckBox
                                    id={"agree_"+(idx+1)}
                                    value={this.state.form_agree_list.includes(form.form_seq.toString())}
                                    checked={this.state.form_agree_list.includes(form.form_seq.toString())}
                                    onChangeCheck={e => this.handleChkTerms(e, form.form_seq.toString())}
                                >
                                </CheckBox>
                                <span className='agree_txt'>{form.form_title+(form.requisite_yn == "Y" ? "(필수)" : "(선택)")}</span>
                                <svg className='terms_btn' onClick={() => this.handleTermsBtnClick(form.form_type)} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.3741 10.0009C14.3719 10.1665 14.3078 10.3254 14.1944 10.4462L7.94443 16.6962C7.823 16.7959 7.66883 16.8468 7.51193 16.8391C7.35502 16.8314 7.20659 16.7656 7.0955 16.6545C6.98442 16.5434 6.91862 16.395 6.91092 16.2381C6.90321 16.0812 6.95415 15.927 7.05381 15.8056L12.8663 10.0009L7.05381 4.19619C6.95414 4.07476 6.90321 3.92059 6.91092 3.76368C6.91862 3.60678 6.98442 3.45834 7.0955 3.34726C7.20659 3.23618 7.35502 3.17038 7.51193 3.16267C7.66883 3.15497 7.823 3.2059 7.94443 3.30557L14.1944 9.55557C14.3078 9.67639 14.3719 9.83522 14.3741 10.0009Z" fill="#333333"/></svg>
                            </div>
                        ))}
                        {
                            this.state.termsDisplay === true
                            ? <TermsCndtnForm terms={this.state.terms} termsClick={this.handleTermsClose}/>
                            : <></>
                        }
                        <button className={this.state.registIdChk === true && this.state.registPwChk === true && this.state.registPwReChk === true && this.state.registEmailChk === true && this.state.registAgreementAllChk === true ? 'regist_complete_btn hover_mint' : 'regist_complete_btn hover_mint disabled'} 
                            onClick={
                                this.state.registIdChk === true && this.state.registPwChk === true && this.state.registPwReChk === true && this.state.registEmailChk === true && this.state.registAgreementAllChk === true
                                ? () => this.onClickSignupButton() 
                                : undefined
                            }>이메일 인증하고 가입하기</button>
                        <div className='faq'>
                            궁금하신 점이 있으신가요? 
                            <span className='faq_link cursor-pointer' onClick={() => {window.open("https://robomd.io/sub/contactus.html")}}>문의하기</span>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
import React, { useState } from 'react';
import http from '../../modules/api';

import Slider from "react-slick";
import styles from './DetailImgCropPopup.module.css';
import PopupFrame from '../../modules/popup/PopupFrame';
import BasicDialogV2 from '../../modules/dialogs/BasicDialogV2';
import SaveDialogV2 from '../../modules/dialogs/SaveDialogV2';
import ai_badge from '../../../resources/img/ai_badge.svg';
import UploadFileImagePopup from '../../modules/popup/UploadFileImagePopup';
import UploadLinkImagePopup from '../../modules/popup/UploadLinkImagePopup';

let crop_slider_dragging = false;

/**
 * 상세 이미지를 분할하고 선택하는 팝업
 * @param {*} className
 * @param {*} crop_img_info 상세 이미지 크롭 정보 { crop_img_link_list : 크롭된 이미지 리스트 , crop_org_img_link : 상세 이미지 링크 , crop_org_img_seq : 상세 이미지 파일 seq , crop_img_seleted_no : 크롭된 이미지 리스트에서 선택된 이미지 idx }
 * @function onClose 팝업 닫기 함수
 * @function selectImgUpload 분할된 상세이미지 정보를 전달받는 함수
 */
class DetailImgCropPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            crop_img_info : {},
            window_width : window.innerWidth,
            UploadLinkImagePopupShow : false,
        }
    }

    componentDidMount(){
        window.addEventListener("resize", this.resizeSliderIdxReset);
        this.setState({
            crop_img_info : JSON.parse(JSON.stringify(this.props.crop_img_info)),
        });
    }

    componentWillUnmount(){
        window.removeEventListener("resize", this.resizeSliderIdxReset);
    }

    /* 상세이미지 분할 */
    detailImageCrop(img_link, org_img_seq){
        this.setState({
            save_dialog_show_state: true,
            save_dialog_content: "AI가 이미지를 자르고 있어요.<br/>조금만 기다려 주세요 :)",
            save_dialog_button_list: [
                {
                    title: "확인",
                    type: "1",
                    display_yn:"N",
                }
            ]
        });

        http.get("/rest/shop/imageCrop?img_path="+img_link)
        .then(res => {
            if(res.data.resCode == "200"){
                let {crop_img_info} = this.state;
                crop_img_info.crop_img_link_list = res.data.url; // 팝업에 띄우는 분할 이미지 목록
                crop_img_info.crop_org_img_link = img_link;
                crop_img_info.crop_org_img_seq = org_img_seq;
                crop_img_info.crop_img_seleted_no = 0;
    
                this.setState({
                    save_dialog_show_state : false,
                    crop_img_info : crop_img_info,
                });
            } else{
                let content = "AI가 이미지 분할에 실패했어요.";
                let content2 = "적합한 이미지로 다시 시도해 주세요.";
                if(res.data.resCode == "408"){
                    content = "이미지를 불러올 수 없어요.";
                    content2 = "다시 시도해도 안되면, 다른 이미지로 시도해 주세요.";
                }

                this.setState({
                    save_dialog_show_state : false,
                    basic_dialog_show_state : true,
                    basic_dialog_content : content,
                    basic_dialog_content2 : content2,
                    basic_dialog_button_list: [
                        {
                            title: "확인",
                            type: "1",
                            event_method: () => {
                                //팝업 닫기
                                this.setState({ basic_dialog_show_state: false });
                            }
                        }
                    ]
                });
            }
        })
        .catch(error => {
            this.setState({
                save_dialog_show_state : false,
                basic_dialog_show_state : true,
                basic_dialog_content : "AI가 이미지 분할에 실패했어요.",
                basic_dialog_content2 : "적합한 이미지로 다시 시도해 주세요.",
                basic_dialog_button_list: [
                    {
                        title: "확인",
                        type: "1",
                        event_method: () => {
                            //팝업 닫기
                            this.setState({ basic_dialog_show_state: false });
                        }
                    }
                ]
            });
        });
    }

    resizeSliderIdxReset = () => {
        let innerWidth = window.innerWidth;
        // 화면 사이즈 450을 기준으로 slidesToShow 값이 변경되면서, 슬라이더의 위치값을 초기화 시켜주는 작업
        // slidesToShow가 3인 상태에서 2로 변경되는 순간, 현재 슬라이드 번호가 홀수였다가 짝수가 되면서 슬라이더가 꼬이는 현상 회피
        if(this.slider1){
            if((this.state.window_width > 450 && innerWidth <= 450) || (this.state.window_width <= 450 && innerWidth > 450)){
                this.slider1.slickGoTo(0,true);
            }
        }
        this.setState({window_width : innerWidth});
    }

    /** 다른 이미지로 다시 만들기 */
    newImgUpload = () => {
        // 크롭 정보 비우기
        this.setState({
            crop_img_info : {},
        });
    }

    render () {
		const {className, onClose, selectImgUpload} = this.props;
        let sliderList = this.state.crop_img_info.crop_img_link_list;
        let crop_img_seleted_no = this.state.crop_img_info.crop_img_seleted_no ?? 0;
        return (
            <>
                {sliderList && sliderList.length > 0
                ?<PopupFrame
                    className={"select_detail_crop_img_popup"}
                    maxWidth={"700px"}
                    headerMarginBottom={"0px"}
                    onClose={onClose}
                >
                    <div className={"select_detail_crop_img_popup_slider "+styles.slider_area+' '+className}>
                        <div style={{textAlign: "center", marginBottom: "40px", fontWeight: "600"}}>
                            마음에 드는 이미지를 선택해 주세요
                        </div>
                        <Slider
                            ref={(slider) => (this.slider1 = slider)}
                            slidesToShow={3}
                            slidesToScroll={3}
                            initialSlide={Number(crop_img_seleted_no) != 0
                                            ? Math.floor(Number(crop_img_seleted_no)/((this.state.window_width > 450) ? 3 : 2))*((this.state.window_width > 450) ? 3 : 2)
                                            : 0}
                            dots={true}
                            infinite={false}
                            beforeChange={() => {
                                crop_slider_dragging = true;
                                setTimeout(() => {
                                    // slickGoTo 사용시 라이브러리 상 beforeChange만 강제로 실행되고 afterChange를 타지 않는 이슈 대응
                                    crop_slider_dragging = false;
                                }, 600);
                            }}
                            afterChange={() =>{
                                crop_slider_dragging = false;
                            }}
                            responsive={[
                                {
                                    breakpoint: 450,
                                    settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 2
                                    }
                                }
                            ]}
                        >
                            {sliderList.map((img, idx) => (
                                <>
                                    <div className={styles.img_area}>
                                        <img
                                            className={crop_img_seleted_no == idx ? styles.selected_slick : ""}
                                            src={img}
                                            onClick={() => {
                                                if(!crop_slider_dragging){
                                                    this.slider2.slickGoTo(idx,true);
                                                    this.setState({crop_img_info : {...this.state.crop_img_info, crop_img_seleted_no:idx}});
                                                }
                                            }}
                                        />
                                    </div>
                                </>
                            ))}
                        </Slider>
                        <Slider
                            className={styles.slider2}
                            ref={(slider) => (this.slider2 = slider)}
                            slidesToShow={1}
                            slidesToScroll={1}
                            initialSlide={crop_img_seleted_no}
                            draggable={false}
                            arrows={false}
                        >
                            {sliderList.map((img, idx) => (
                                <>
                                    <div className={styles.img_area}>
                                        <img src={img}/>
                                    </div>
                                </>
                            ))}
                        </Slider>
                        <div className={"style_guide_v2 "+styles.button_area}>
                            <button className="hover_dark" onClick={() => this.newImgUpload()}>다른 이미지로 업로드</button>
                            <button className="hover_mint" onClick={() => selectImgUpload(this.state.crop_img_info)}>선택 이미지 사용하기</button>
                        </div>
                    </div>
                </PopupFrame>
                :<div className={"detail_img_link_type_popup "+styles.link_type_popup+' '+className}>
                    <PopupFrame
                        maxWidth={"450px"}
                        headerMarginBottom={"24px"}
                        title={"상세페이지 이미지 자르기"}
                        titleIcon={ai_badge}
                        content={"상세페이지 이미지를 AI가 잘라줘요.<br/>잘린 이미지 중 원하는 이미지로 업로드해 보세요."}
                        onClose={onClose}
                    >
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.0344 8.21564L14.7844 2.96564C14.6415 2.82635 14.4495 2.74889 14.25 2.75001H5.25C4.85218 2.75001 4.47064 2.90805 4.18934 3.18935C3.90804 3.47066 3.75 3.85219 3.75 4.25001V20.75C3.75 21.1478 3.90804 21.5294 4.18934 21.8107C4.47064 22.092 4.85218 22.25 5.25 22.25H18.75C19.1478 22.25 19.5294 22.092 19.8107 21.8107C20.092 21.5294 20.25 21.1478 20.25 20.75V8.75001C20.2511 8.55048 20.1737 8.35851 20.0344 8.21564ZM15 5.30939L17.6906 8.00001H15V5.30939ZM18.75 20.75H5.25V4.25001H13.5V8.75001C13.5 8.94892 13.579 9.13969 13.7197 9.28034C13.8603 9.42099 14.0511 9.50001 14.25 9.50001H18.75V20.75Z" fill="#878B90"/>
                        </svg>
                        <div className={"style_guide_v2 "+styles.button_area}>
                            <UploadFileImagePopup
                                nonResize = {true}
                                getImageInfo = {(upload_img) => {
                                    this.detailImageCrop(upload_img.org_img_path, upload_img.robo_banner_org_img_seq);
                                }}
                            >
                                <label className={"hover_mint"}>상세페이지 이미지 파일 첨부</label>
                            </UploadFileImagePopup>
                            <div className={styles.text}>또는</div>
                            <label className={"hover_mint"} onClick={() => {this.setState({UploadLinkImagePopupShow: true})}}>상세페이지 이미지 주소 입력</label>
                        </div>
                        <div className={styles.text}>※ 페이지 주소가 아닌, 마우스 오른쪽 클릭을 통해 이미지 주소를 입력해 주세요.</div>
                    </PopupFrame>
                </div>}
                {this.state.UploadLinkImagePopupShow
                && <UploadLinkImagePopup
                    onClose={() => {
                        this.setState({UploadLinkImagePopupShow : false});
                    }}
                    title={"배너에 사용될 이미지 주소를 입력해주세요."}
                    getImageLink={(img_link) => {
                        this.detailImageCrop(img_link);
                        this.setState({UploadLinkImagePopupShow : false});
                    }}
                />}
                {/* 로딩 상태표시 */
                    (this.state.save_dialog_show_state === true)
                    ? <SaveDialogV2
                        content={this.state.save_dialog_content}
                        button_list={this.state.save_dialog_button_list}
                    />
                    : ''
                }
                {/* 안내 문구 전달용 기본 다이얼로그 */
					(this.state.basic_dialog_show_state === true)
						? <BasicDialogV2
							content={this.state.basic_dialog_content}
                            content2={this.state.basic_dialog_content2}
							button_list={this.state.basic_dialog_button_list}
						/>
						: ''
				}
            </>
        )
    };
}

DetailImgCropPopup.defaultProps = {
    className : "",
    crop_img_info : {},
    onClose : function(){},
    selectImgUpload : function(){},
}

export default DetailImgCropPopup;
import React from 'react';
import axios from '../modules/api';
import http from '../modules/api';
import {Line} from 'react-chartjs-2';
import { NavLink, Link } from 'react-router-dom';
import $ from 'jquery';
import Slider from "react-slick";
import Pagination from "react-js-pagination";

import round_loading_bar from '../../resources/img/round_loading_bar.gif';

import ReactGA, { send } from 'react-ga';
ReactGA.initialize('UA-78553461-8', {
    titleCase: false,
});

var event_banner_pc_width_height = 1.68;
var event_banner_mobile_width_height = 0.69;

const formatDate = (current_datetime)=>{
    var month = current_datetime.getMonth() + 1;
    if (String(month).length <=1) {
        month = "0"+month
    }
    var dates = current_datetime.getDate();
    if (String(dates).length <=1) {
        dates = "0"+dates
    }
    var hours = current_datetime.getHours();
    if (String(hours).length <=1) {
        hours = "0"+hours
    }
    var minutes = current_datetime.getMinutes();
    if (String(minutes).length <=1) {
        minutes = "0"+minutes
    }
    var seconds = current_datetime.getSeconds();
    if (String(seconds).length <=1) {
        seconds = "0"+seconds
    }
    let formatted_date = current_datetime.getFullYear() + "-" + month + "-" + dates + " " + hours + ":" + minutes + ":" + seconds;
    return formatted_date;
}

var first_loaded = "false";
var org_state = {}
export class RpEventProductList extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
            show_admin_page_2_popup : false,
            
            rp_product_list : [
                // {product_no: "1", image_url:"https://images.pexels.com/photos/6707628/pexels-photo-6707628.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260", product_name: "마켓비 FORMI 접이식 안락의자 고무나무 당일배송", price: "129,990원"},
                // {product_no: "2", image_url:"https://images.pexels.com/photos/1866149/pexels-photo-1866149.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260", product_name: "마켓비 FORMI 접이식 안락의자 고무나무 당일배송", price: "129,990원"},
                // {product_no: "3", image_url:"https://images.pexels.com/photos/1176516/pexels-photo-1176516.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260", product_name: "마켓비 FORMI 접이식 안락의자 고무나무 당일배송", price: "129,990원"},
                // {product_no: "4", image_url:"https://images.pexels.com/photos/5411784/pexels-photo-5411784.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260", product_name: "마켓비 FORMI 접이식 안락의자 고무나무 당일배송", price: "129,990원"},
            ],

            rp_check_product_list : [

            ],

            rp_category: [
                {category_no:""},
                {category_no:""},
                {category_no:""},
                {category_no:""}
            ],
            rp_setting: {},

            product_list : [],

            product_category_list_1:[],
            product_category_list_2:[],
            product_category_list_3:[],
            product_category_list_4:[],
            
            product_rp_category_list_1:[],
            product_rp_category_list_2:[],
            product_rp_category_list_3:[],
            product_rp_category_list_4:[],

            product_rp_category_no_1: "",
            product_rp_category_no_2: "",
            product_rp_category_no_3: "",
            product_rp_category_no_4: "",

            popup_category_no_1: "",
            popup_category_no_2: "",
            popup_category_no_3: "",
            popup_category_no_4: "",

            popup_check_product_list : [

            ],

            search_gubun: "product_name",
            order_gubun_select_value: "created_date",
            order_gubun: "",
            order_type: "",
            order_gubun_text: "등록일순",
            
            search_product_name: "",
            search_product_code: "",

            activePage: 1,
            startIdx: 0,
            rowcnt: 10,
            totalCnt: 0,

            modified_date: "",
            modified_type_name: "", // 자동 선정, 임시 수정, 최종 수정 
            is_modified: false,

            show_loading_bar: false,
        }
    }

    componentWillMount() {
	
    }

	componentDidMount() {
        this.loadProductCategory("", 1, "product_rp_category_list_");
        this.loadProductCategory("", 1, "product_category_list_");
        this.loadRpEventProductList();
        this.loadRpEventSetting();

        var this_component = this;

        //관리자 페이지 - 전체 선택 이벤트
        // $("input[id='checkall']").on('click',function(){
        //     if( $(this).is(':checked') ){
        //         $("input[name='page_check']").prop('checked',true);
        //         $("input[id='checkall']~label svg .svg_line").css({
        //             "opacity":"0"
        //         });
        //     }
        //     else{
        //         $("input[name='page_check']").prop('checked',false);
        //         $("input[id='checkall']~label svg .svg_line").css({
        //             "opacity":"0"
        //         });
        //     }

        //     const {rp_product_list} = this_component.state
        //     var rp_check_product_list = [];
        //     for(var idx in rp_product_list) {
        //         rp_check_product_list.push(rp_product_list[idx])
        //     }
        //     this_component.setState({
        //         rp_check_product_list: rp_check_product_list
        //     }, () => {
        //         // console.log("###### rp_check_product_list #######")
        //         // console.log(rp_check_product_list)
        //     })
        // });
        // $("input[name='page_check']").on('click',function(){
        //     //개별상품 모두 선택/해제 시 전체 선택/해제 이벤트
        //     let total = $("input[name='page_check']").length;
        //     let total_checked = $("input[name=page_check]:checked").length;

        //     if(total != total_checked){
        //         $("input[id='checkall']").prop('checked',false);
        //         $("input[id='checkall']~label svg .svg_line").css({
        //             "opacity":"1"
        //         });
        //     }
        //     else{
        //         $("input[id='checkall']").prop('checked',true);
        //         $("input[id='checkall']~label svg .svg_line").css({
        //             "opacity":"0"
        //         });
        //     }

        //     //체크 되어있는 체크박스가 0일 때 이벤트 
        //     if(!total_checked){
        //         $("input[id='checkall']~label svg .svg_line").css({
        //             "opacity":"0"
        //         });
        //     }
        // });

        // //팝업창 - 전체 선택 이벤트
        // $("input[id='popup_checkall']").on('click',function(){
        //     if( $(this).is(':checked') == true ){
        //         $("input[name='popup_check']").prop('checked',true);
        //     }
        //     else{
        //         $("input[name='popup_check']").prop('checked',false);
        //     }
        // });
        // $("input[name='popup_check']").on('click',function(){ 
        //     //개별상품 모두 선택/해제 시 전체 선택/해제 이벤트
        //     let total = $("input[name='popup_check']").length;
        //     let total_checked = $("input[name=popup_check]:checked").length;

        //     if(total != total_checked){
        //         $("input[id='popup_checkall']").prop('checked',false);
        //     }
        //     else{
        //         $("input[id='popup_checkall']").prop('checked',true);
        //     }
        // });

        if( $(window).width() > 1200 ){
            //목록 수정 텍스트 변경
            $(".admin_page_2_row_2 #description1").show();
            $(".admin_page_2_row_2 #description1").text("인공지능 로보MD가 쇼핑몰 이용 데이터를 분석하여 선정한 상품 목록을 수정할 수 있습니다.");
        }
        else if( $(window).width() <= 1200 ){
            //목록 수정 텍스트 변경
            $(".admin_page_2_row_2 #description1").hide();
            $(".admin_page_2_row_2 #description2").text(this_component.state.modified_date+" "+this_component.state.modified_type_name);
        }

        //반응형
        $(window).resize(function(){
            if( $(window).width() > 1200 ){
                //목록 수정 텍스트 변경
                $(".admin_page_2_row_2 #description1").show();
                $(".admin_page_2_row_2 #description1").text("인공지능 로보MD가 쇼핑몰 이용 데이터를 분석하여 선정한 상품 목록을 수정할 수 있습니다.");
                $(".admin_page_2_row_2 #description2").text(this_component.state.modified_date+" "+this_component.state.modified_type_name);
            }
            else if( $(window).width() <= 1200 ){
                //목록 수정 텍스트 변경
                $(".admin_page_2_row_2 #description1").hide();
                $(".admin_page_2_row_2 #description2").text("* 모바일에서는 조회만 가능합니다.");
            }
        });
    }

    componentDidUpdate() {
        if (first_loaded == "true") {
            org_state = JSON.parse(JSON.stringify(this.state));
            // console.log(this.state.org_state);
            first_loaded = ""
            // alert(JSON.stringify(org_state.is_modified))
        }
    }

    componentWillUnmount(e) {
        // this.onClickPopupShowHide(e, false);
    }

    initState() {
        if (window.confirm("처음 상태로 되돌리겠습니까?")) {
            this.setState(JSON.parse(JSON.stringify(org_state)), () => {
                // console.log(JSON.parse(JSON.stringify(org_state)));
            });
            this.setGaEvent("초기화 (확인)");
        } else {
            this.setGaEvent("초기화 (취소)");
        }
    }

    getUrlParams() {
        var params = {};
        window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(str, key, value) { params[key] = value; });
        return params;
    }

    setGaEvent(action_text) {
        ReactGA.event({
             category: "베스트 서비스 ("+ this.state.rp_setting.iframe_html_name +" 상품 목록)",
             action: action_text,
             label: sessionStorage.getItem("user_id"),
         });
     }

     setPopupGaEvent(action_text) {
        ReactGA.event({
             category: "베스트 서비스 ("+this.state.rp_setting.iframe_html_name +" 상품 추가 팝업)",
             action: action_text,
             label: sessionStorage.getItem("user_id"),
         });
     }

    changeModifyDateAndTypeName(type_name) {
        this.setState({
            modified_date: formatDate(new Date()),
            modified_type_name: type_name,
            is_modified: true,
        })
    }

    async loadRpEventSetting() {
        var page_id = this.getUrlParams().page_id;
        var html_id = this.getUrlParams().html_id;
        await http.get("/rest/shop/rp/event/setting?iframe_page_id="+page_id+"&iframe_html_id="+html_id)
		.then(res => {
			if (res.data != null && res.data != '') {
				this.setState( {
                    rp_category : res.data["category"],
                    rp_setting : res.data["setting"]
                }, () => {
                    var ga_category_name = ""
                    for (var idx in this.state.rp_category) {
                        const name = "product_rp_category_no_"+(Number(idx)+1);
                        this.setState( {
                            [name]: this.state.rp_category[idx].category_no,
                        }, () => {
                            if (first_loaded == "false" && idx == (this.state.rp_category.length - 1)) {
                                // alert("first_loaded");
                                first_loaded = "true";
                            }
                        })
                        if (idx == 0) {
                            ga_category_name = "카테고리 번호: " + this.state.rp_category[idx].category_no
                            continue;
                        }
                        ga_category_name += " - " + this.state.rp_category[idx].category_no;
                        this.loadProductCategory(this.state.rp_category[Number(idx)].parent_category_no, Number(idx)+1, "product_rp_category_list_")
                    }
                    // alert(ga_category_name)
                    this.setState({
                        ga_rp_category_name: ga_category_name,
                    })
                } );
			}
		})
		.catch(error => {
			// console.log ("===== error =====");
			// console.log (error);
			// console.log ("===== error request =====");
			// // console.log(error.request);
		 });
    }

    async loadRpEventProductList() {
        var page_id = this.getUrlParams().page_id;
        var html_id = this.getUrlParams().html_id;
        await http.get("/rest/shop/rp/event/select?iframe_page_id="+page_id+"&iframe_html_id="+html_id)
		.then(res => {
			if (res.data != null && res.data != '') {
				this.setState( {
                    rp_product_list : res.data["list"],
                    modified_date : res.data["last_mod_date"],
                    // is_modified : res.data["is_auto"] == "Y" ? false : true,
                    is_modified : false,
                    modified_type_name : res.data["is_auto"] == "Y" ? "자동 선정" : "최종 수정",
                });
			}
		})
		.catch(error => {
			// console.log ("===== error =====");
			// console.log (error);
			// console.log ("===== error request =====");
			// // console.log(error.request);
		 });
    }

    async loadBestProductList() {
        var page_id = this.getUrlParams().page_id;
        var html_id = this.getUrlParams().html_id;
        var category_no = "";
        if (this.state.product_rp_category_no_4 != null && this.state.product_rp_category_no_4 != "" && this.state.product_rp_category_no_4 != "전체상품") {
            category_no = this.state.product_rp_category_no_4;
        } else if (this.state.product_rp_category_no_3 != null && this.state.product_rp_category_no_3 != "" && this.state.product_rp_category_no_3 != "전체상품") {
            category_no = this.state.product_rp_category_no_3;
        } else if (this.state.product_rp_category_no_2 != null && this.state.product_rp_category_no_2 != "" && this.state.product_rp_category_no_2 != "전체상품") {
            category_no = this.state.product_rp_category_no_2;
        } else if (this.state.product_rp_category_no_1 != null && this.state.product_rp_category_no_1 != "" && this.state.product_rp_category_no_1 != "전체상품") {
            category_no = this.state.product_rp_category_no_1;
        }
        // alert( this.state.product_rp_category_no_2)
        // if (category_no == "") {
        //     alert("카테고리를 선택해 주세요.")
        // }
        this.setState( {
            rp_product_list : [],
            show_loading_bar : true,
        })
        await http.get("/rest/shop/rp/event/create?iframe_page_id="+page_id+"&iframe_html_id="+html_id+"&category_no="+category_no)
		.then(res => {
			if (res.data != null && res.data != '') {
				this.setState( {
                    rp_product_list : res.data["list"],
                    show_loading_bar : false,
                }, () => {
                    this.changeModifyDateAndTypeName("임시 자동 선정");
                } );
			}
		})
		.catch(error => {
			// console.log ("===== error =====");
			// console.log (error);
			// console.log ("===== error request =====");
			// // console.log(error.request);
		 });

        var ga_category_name = "카테고리 번호: " + this.state["product_rp_category_no_1"]
        for(var idx = 2; idx <=4; idx++) {
            var category_name = "product_rp_category_no_"+idx;
            if (this.state[category_name] != null && this.state[category_name] != "") {
                ga_category_name += " - " + this.state[category_name]
            }
        }
        // alert("베스트 상품 새로 선정하기 ("+ ga_category_name +")");
        this.setGaEvent("AI 추천상품 불러오기 ("+ ga_category_name +")");
        this.setState({
            ga_rp_category_name: ga_category_name
        })
    }
    
    async loadProductList(is_click) {

        var category_no = "";
        if (this.state.popup_category_no_4 != null && this.state.popup_category_no_4 != "" && this.state.popup_category_no_4 != "전체상품") {
            category_no = this.state.popup_category_no_4;
        } else if (this.state.popup_category_no_3 != null && this.state.popup_category_no_3 != "" && this.state.popup_category_no_3 != "전체상품") {
            category_no = this.state.popup_category_no_3;
        } else if (this.state.popup_category_no_2 != null && this.state.popup_category_no_2 != "" && this.state.popup_category_no_2 != "전체상품") {
            category_no = this.state.popup_category_no_2;
        } else if (this.state.popup_category_no_1 != null && this.state.popup_category_no_1 != "" && this.state.popup_category_no_1 != "전체상품") {
            category_no = this.state.popup_category_no_1;
        }
        this.setState( {
            product_list : [],
        } );
        await http.get(
                        "/rest/shop/rp/product/select"
                        +"?startidx="+this.state.startIdx
                        +"&rowcnt="+this.state.rowcnt
                        +"&order_type="+this.state.order_type
                        +"&order_gubun="+this.state.order_gubun
                        +"&category_no="+category_no
                        // +"&start_date=20210101"
                        // +"&end_date=20211201"
                        +"&product_code="+this.state.search_product_code
                        +"&product_name="+encodeURI(this.state.search_product_name)
                    )
		.then(res => {
			if (res.data != null && res.data != '') {
				this.setState( {
                    product_list : res.data["list"],
                    totalCnt: res.data["totalCnt"]
                }, () => {
                    const {product_list,  popup_check_product_list} = this.state;
                    if (product_list != null && product_list.length > 0) {
                        popup_check_product_list.filter(function(obj) {
                            if(this.has(obj.product_no)) {
                                $("input[id='popup_check"+obj.product_no+"']").prop('checked',true);
                            }
                        }, new Set(product_list.map(obj => obj.product_no)));
    
                        let total = $("input[name='popup_check']").length;
                        let total_checked = $("input[name=popup_check]:checked").length;

                        if(total != total_checked){
                            $("input[id='popup_checkall']").prop('checked',false);
                            $("input[id='popup_checkall']~label svg .svg_line").css({
                                "opacity":"1"
                            });
                        }
                        else{
                            $("input[id='popup_checkall']").prop('checked',true);
                            $("input[id='popup_checkall']~label svg .svg_line").css({
                                "opacity":"0"
                            });
                        }
                
                        //체크 되어있는 체크박스가 0일 때 이벤트 
                        if(!total_checked){
                            $("input[id='popup_checkall']~label svg .svg_line").css({
                                "opacity":"0"
                            });
                        }
                    }
                } );
			}
		})
		.catch(error => {
			// console.log ("===== error =====");
			// console.log (error);
			// console.log ("===== error request =====");
			// // console.log(error.request);
		 });

        var ga_category_name = this.state["popup_category_no_1"]
        for(var idx = 2; idx <=4; idx++) {
            var category_name = "popup_category_no_"+idx;
            if (this.state[category_name] != null && this.state[category_name] != "") {
                ga_category_name += " - " + this.state[category_name]
            }
        }

        if (is_click == "Y") {
            let ga_action = "";
            ga_action = "검색 버튼 클릭 (카테고리 번호: "+ ga_category_name +" / 정렬: "+this.state.order_gubun_text+ " / 상품명:" + this.state.search_product_name + " / 상품코드: "+ this.state.search_product_code + ")"
            // alert(popup_filter);
            this.setPopupGaEvent(ga_action);
        }
    }

    async loadProductCategory(parentCategory, depth, key_name) {

        const sendForm = {
            user_seq: this.state.user_seq , 
            shop_no: "1", 
            parent_category_no: parentCategory
        }

        // var name = "product_rp_category_list_"+depth;
        var name = key_name+depth;
		await http.get("/rest/common/cafe24/category", {params:sendForm})
		.then(res => {
            this.setState({[name]: res.data.list});
		})
		.catch(error => {
			console.log ("===== error =====");
			console.log (error);
			console.log ("===== error request =====");
			// console.log(error.request);
		 });
    }

    onChangeRpCategory = (e, depth) => {
        var category_name = "product_rp_category_no_"+(Number(depth)-1);
        this.setState({
            [category_name] : e.target.value
        })

        if (depth > 1 && depth <= 5) {
            this.loadProductCategory(e.target.value, depth, "product_rp_category_list_");
        }

        for(var idx = depth; idx <=4; idx++) {
            var category_name = "product_rp_category_no_"+idx;
            let idx_copy = idx;
            this.setState({
                [category_name] : ""
            }, () => {
                if (idx_copy == 4) {
                    var ga_category_name = "카테고리 번호: " + this.state["product_rp_category_no_1"]
                    for(var idx = 2; idx <=4; idx++) {
                        var category_name = "product_rp_category_no_"+idx;
                        if (this.state[category_name] != null && this.state[category_name] != "") {
                            ga_category_name += " - " + this.state[category_name]
                        }
                    }
                    // alert(ga_category_name);
                    this.setGaEvent("카테고리 변경 ("+ ga_category_name +")");
                }
            })
        }

        for(var idx = depth; idx <=4; idx++) {
            var name = "product_rp_category_list_"+idx;
            if (this.state[name] != null && this.state[name].length > 0) {
                continue;
            }
            this.setState({[name]: []});
        }
    }

    onChangeCategory = (e, depth) => {
        var name = "popup_category_no_"+(Number(depth)-1)
        this.setState({
            [name] : e.target.value,
        }, () => {
            // alert(e.target.value)
        })

        if (depth > 1 && depth <= 5) {
            this.loadProductCategory(e.target.value, depth, "product_category_list_");
        }

        for(var idx = depth; idx <=4; idx++) {
            var category_name = "popup_category_no_"+idx;
            let idx_copy = idx;
            this.setState({
                [category_name] : ""
            }, () => {
                if (idx_copy == 4) {
                    var ga_category_name = "카테고리 번호: " + this.state["popup_category_no_1"]
                    for(var idx = 2; idx <=4; idx++) {
                        var category_name = "popup_category_no_"+idx;
                        if (this.state[category_name] != null && this.state[category_name] != "") {
                            ga_category_name += " - " + this.state[category_name]
                        }
                    }
                    // alert("카테고리 변경 ("+ ga_category_name +")");
                    this.setPopupGaEvent("카테고리 변경 ("+ ga_category_name +")");
                }
            })
        }
        for(var idx = depth; idx <=4; idx++) {
            var name = "product_category_list_"+idx;
            this.setState({[name]: []});
        }
    }

    onClickPopupShowHide(e, state) {
        // // console.log(state)
        if (state) {
            this.setPopupGaEvent("상품 추가 팝업 열기")
            this.setState({
                show_admin_page_2_popup : state,

                /* 팝업 필터 및 상품 목록 초기화 start */
                product_list : [],

                popup_category_no_1: "",
                popup_category_no_2: "",
                popup_category_no_3: "",
                popup_category_no_4: "",

                popup_check_product_list : [

                ],

                search_gubun: "product_name",
                order_gubun_select_value: "created_date",
                order_gubun: "",
                order_type: "",
                order_gubun_text: "등록일순",
                
                search_product_name: "",
                search_product_code: "",

                activePage: 1,
                startIdx: 0,
                rowcnt: 10,
                totalCnt: 0,
                /* 팝업 필터 및 상품 목록 초기화 end */
            }, () => {
                if (this.state.product_list.length <= 0) { // 이미 불러온 상품이 있으면 상품 재조회 하지 않기 위함.
                    this.loadProductList();
                }
            })
        } else {
            this.setState({
                show_admin_page_2_popup : false,
            })
        }
    }

    onClickPopupClose(e) {
        // // console.log(state)
        this.setState({
            show_admin_page_2_popup : false,
        })

        this.setPopupGaEvent("상품 추가 팝업 닫기 (X 버튼 클릭)")
    }

    handlePageChange(pageNumber) {
		// // console.log(`active page is ${pageNumber}`);
		this.setState({activePage: pageNumber});
		$("input[name=popup_checkall]").prop("checked", false);				
		$("input[name='popup_check']").prop('checked',false);				
		this.state.startIdx = (pageNumber * 10) - this.state.rowcnt;
		// // console.log("this.state.startIdx :" + this.state.startIdx )
		this.loadProductList()
    }
    
    onChangeProductOrder(e, from, to) {
        if (to < 0 || to >= this.state.rp_product_list.length) {
            return false;
        }
        const {rp_product_list} = this.state;
        rp_product_list.splice(to, 0, rp_product_list.splice(from, 1)[0]);
        rp_product_list[to].create_type_cd = "22999";
        this.setState({
            rp_product_list: rp_product_list,
        }, () => {
            // console.log("###### create_type_cd ####")
            // console.log(rp_product_list)
            this.changeModifyDateAndTypeName("임시 수정");

            // alert("순서 변경 (상품명: "+rp_product_list[to].product_name+" / 순서: "+(Number(from)+1) + " → " + (Number(to)+1)+")");
            this.setGaEvent("순서 변경 (상품명: "+rp_product_list[to].product_name+" / 순서: "+(Number(from)+1) + " → " + (Number(to)+1)+")");
        })
    }

    deleteProduct(e, idx) {
        if(window.confirm("정말 삭제하시겠습니까?")) {
            const {rp_product_list} = this.state;
            // alert("상품 삭제 - "+ rp_product_list[idx].product_name +" ("+"product_no: "+ rp_product_list[idx].product_no+ " / " + this.state.ga_rp_category_name + ")")
            this.setGaEvent("상품 삭제 - "+ rp_product_list[idx].product_name +" ("+"product_no: "+ rp_product_list[idx].product_no+ " / " + this.state.ga_rp_category_name + ")");
            rp_product_list.splice(idx, 1);

            this.setState({
                rp_product_list: rp_product_list,
            }, () => {
                this.changeModifyDateAndTypeName("임시 수정");
                $("input[id='checkall']").prop('checked',false);
                $("input[id='checkall']~label svg .svg_line").css({
                    "opacity":"0"
                });
                $("input[name='page_check']").prop('checked',false);
                $("input[name='page_check']~label svg .svg_line").css({
                    "opacity":"0"
                });
            })
        }
    }

    onChangeRpProductCheckAll(e) {
        if (e.currentTarget.checked) {
            $("input[name='page_check']").prop('checked',true);
            $("input[id='checkall']~label svg .svg_line").css({
                "opacity":"0"
            });
        }
        else{
            $("input[name='page_check']").prop('checked',false);
            $("input[id='checkall']~label svg .svg_line").css({
                "opacity":"0"
            });
        }

        const {rp_product_list} = this.state
        var rp_check_product_list = [];
        for(var idx in rp_product_list) {
            rp_check_product_list.push(rp_product_list[idx])
        }
        this.setState({
            rp_check_product_list: rp_check_product_list
        }, () => {
            // console.log("###### rp_check_product_list #######")
            // console.log(rp_check_product_list)
        })
    }

    onChangeRpProductCheck(e, idx) {
        if (!e.currentTarget.checked) {
            const {rp_product_list, rp_check_product_list} = this.state;
            
            for(var idx2 in rp_product_list) {
                if (rp_check_product_list[idx2] == rp_product_list[idx]) {
                    idx = idx2;
                    break;
                }
            }
            
            rp_check_product_list.splice(idx, 1);
            this.setState({
                rp_check_product_list: rp_check_product_list,
            }, () => {
                // console.log("###### rp_check_product_list #######")
                // console.log(rp_check_product_list)
            })
        } else {
            const {rp_product_list, rp_check_product_list} = this.state;
            rp_check_product_list.push(rp_product_list[idx])
            this.setState({
                rp_check_product_list: rp_check_product_list,
            }, () => {
                // console.log("###### rp_check_product_list #######")
                // console.log(rp_check_product_list)
            })
        }

        //개별상품 모두 선택/해제 시 전체 선택/해제 이벤트
        let total = $("input[name='page_check']").length;
        let total_checked = $("input[name=page_check]:checked").length;

        if(total != total_checked){
            $("input[id='checkall']").prop('checked',false);
            $("input[id='checkall']~label svg .svg_line").css({
                "opacity":"1"
            });
        }
        else{
            $("input[id='checkall']").prop('checked',true);
            $("input[id='checkall']~label svg .svg_line").css({
                "opacity":"0"
            });
        }

        //체크 되어있는 체크박스가 0일 때 이벤트 
        if(!total_checked){
            $("input[id='checkall']~label svg .svg_line").css({
                "opacity":"0"
            });
        }
    }

    onClickCheckedRpProductDelete(e) {
        const {rp_product_list, rp_check_product_list} = this.state;
        if (rp_check_product_list.length != 0) {
            if(window.confirm("정말 삭제하시겠습니까?")) {
                var rp_product_list_copy = JSON.parse(JSON.stringify(rp_product_list));

                rp_product_list_copy = rp_product_list_copy.filter(function(obj) {
                    return !this.has(obj.product_no);
                }, new Set(rp_check_product_list.map(obj => obj.product_no)));
                
                // console.log("#### rp_product_list_copy")
                // console.log(rp_product_list_copy);

                this.setState({
                    rp_product_list: rp_product_list_copy,
                    rp_check_product_list: [],
                }, () => {
                    $("input[id='checkall']").prop('checked',false);
                    $("input[id='checkall']~label svg .svg_line").css({
                        "opacity":"0"
                    });
                    $("input[name='page_check']").prop('checked',false);
                    $("input[name='page_check']~label svg .svg_line").css({
                        "opacity":"0"
                    });
                    // console.log("###### rp_product_list #######")
                    // console.log(rp_product_list)
                    this.changeModifyDateAndTypeName("임시 수정");
                })

                var ga_check_product_list = [];
                for (var idx in rp_check_product_list) {
                    var obj = rp_check_product_list[idx];
                    ga_check_product_list.push({
                        product_name: obj.product_name,
                        product_no: obj.product_no,
                    })
                }
                if (rp_product_list.length == rp_check_product_list.length) {
                    // alert("전체 삭제 - "+rp_check_product_list.length+"개 "+JSON.stringify(ga_check_product_list) + " ("+this.state.ga_rp_category_name+")")
                    this.setGaEvent("전체 삭제 - "+rp_check_product_list.length+"개 "+JSON.stringify(ga_check_product_list) + " ("+this.state.ga_rp_category_name+")")
                } else {
                    // alert("선택 삭제 - "+rp_check_product_list.length+"개 "+JSON.stringify(ga_check_product_list)+ " ("+this.state.ga_rp_category_name+")")
                    this.setGaEvent("선택 삭제 - "+rp_check_product_list.length+"개 "+JSON.stringify(ga_check_product_list)+ " ("+this.state.ga_rp_category_name+")")
                }
            }
        }
    }

    onChangeOrderGubun(e) {
        if (e.target.value.indexOf("price") >= 0 ) {
            this.setState({
                order_gubun_select_value: e.target.value,
                order_gubun: e.target.value.split("_")[0],
                order_type: e.target.value.split("_")[1],
                order_gubun_text: e.target.options[e.target.selectedIndex].text,
            }, () => {
                this.loadProductList();
            })
        } else {
            this.setState({
                order_gubun_select_value: e.target.value,
                order_gubun: e.target.value,
                order_type: "asc",
                order_gubun_text: e.target.options[e.target.selectedIndex].text,
            }, () => {
                this.loadProductList();
            })
        }

        // alert("정렬 기준 변경 - "+e.target.options[e.target.selectedIndex].text);
        this.setPopupGaEvent("정렬 기준 변경 - "+e.target.options[e.target.selectedIndex].text);
    }
    
    onChangeSearchGubun(e) {
        var product_name = "";
        var product_code = "";
        if (e.target.value == "product_name") {
            product_name = $("input[name='search_input']").val();
            // alert(product_name)
        } else if (e.target.value == "product_code") {
            product_code = $("input[name='search_input']").val();
            // alert(product_code)
        }
        this.setState({
            search_gubun: e.target.value,
            search_product_name: product_name,
            search_product_code: product_code,
        })
    }

    onChangeSearchInput(e) {
        if (this.state.search_gubun != null && this.state.search_gubun == "product_name") {
            this.setState({
                search_product_name: e.target.value,
                search_product_code: "",
            })
        } else if (this.state.search_gubun != null && this.state.search_gubun == "product_code") {
            this.setState({
                product_name: "",
                search_product_code: e.target.value,
            })
        } else {
            this.setState({
                search_gubun: "product_name",
                search_product_name: e.target.value,
                search_product_code: "",
            })
        }
    }

    onClickPopupCheckAll(e) {
        if(e.target.checked == true ){
            $("input[name='popup_check']").prop('checked',true);
            $("input[id='popup_checkall']~label svg .svg_line").css({
                "opacity":"0"
            });

            const {product_list, popup_check_product_list} = this.state
            var popup_check_product_list_copy = JSON.parse(JSON.stringify(popup_check_product_list));
            for(var idx in product_list) {
                popup_check_product_list.push(product_list[idx])
            }
            this.setState({
                popup_check_product_list: popup_check_product_list
            }, () => {
                // console.log("###### popup_check_product_list #######")
                // console.log(popup_check_product_list)
            })
        }
        else{
            $("input[name='popup_check']").prop('checked',false);
            $("input[id='popup_checkall']~label svg .svg_line").css({
                "opacity":"0"
            });

            const {product_list, popup_check_product_list} = this.state
            var popup_check_product_list_copy = JSON.parse(JSON.stringify(popup_check_product_list));
            popup_check_product_list_copy = popup_check_product_list_copy.filter(function(obj) {
                return !this.has(obj.product_no);
            }, new Set(product_list.map(obj => obj.product_no)));

            this.setState({
                popup_check_product_list: popup_check_product_list_copy
            }, () => {
                // console.log("###### popup_check_product_list #######")
                // console.log(popup_check_product_list)
            })
        }

        
    }

    onClickPopupCheck(e, idx) {
        //개별상품 모두 선택/해제 시 전체 선택/해제 이벤트
        let total = $("input[name='popup_check']").length;
        let total_checked = $("input[name=popup_check]:checked").length;

        if(total != total_checked){
            $("input[id='popup_checkall']").prop('checked',false);
            $("input[id='popup_checkall']~label svg .svg_line").css({
                "opacity":"1"
            });
        }
        else{
            $("input[id='popup_checkall']").prop('checked',true);
            $("input[id='popup_checkall']~label svg .svg_line").css({
                "opacity":"0"
            });
        }

        //체크 되어있는 체크박스가 0일 때 이벤트 
        if(!total_checked){
            $("input[id='popup_checkall']~label svg .svg_line").css({
                "opacity":"0"
            });
        }

        if (!e.currentTarget.checked) {
            const {product_list,  popup_check_product_list} = this.state;
            
            for(var idx2 in popup_check_product_list) {
                if (popup_check_product_list[idx2].product_no == product_list[idx].product_no) {
                    idx = idx2;
                    break;
                }
            }

            var popup_check_product_list_copy = JSON.parse(JSON.stringify(popup_check_product_list));
            popup_check_product_list_copy.splice(idx, 1);
            
            this.setState({
                popup_check_product_list: popup_check_product_list_copy,
            }, () => {
                // console.log("###### popup_check_product_list #######")
                // console.log(popup_check_product_list)
            })
        } else {
            const {product_list, popup_check_product_list} = this.state;
            popup_check_product_list.push(product_list[idx])
            this.setState({
                popup_check_product_list: popup_check_product_list,
            }, () => {
                // console.log("###### popup_check_product_list #######")
                // console.log(popup_check_product_list)
            })
        }
    }

    onClickPopupCheckedProductApply(e) {
        const {rp_product_list, product_list, popup_check_product_list} = this.state;
        if (popup_check_product_list == null || (popup_check_product_list != null && popup_check_product_list.length <= 0)) {
            alert("상품을 선택해 주세요.");
            return false;
        }
        // var product_list_copy = JSON.parse(JSON.stringify(product_list));
        var add_product_list = [];
        
        var page_id = this.getUrlParams().page_id;
        var html_id = this.getUrlParams().html_id;

        add_product_list = popup_check_product_list.filter(function(obj) {
            return !this.has(obj.product_no);
        }, new Set(rp_product_list.map(obj => obj.product_no))); // 상품 중복 체크
        
        // console.log("#### popup_check_product_list")
        // console.log(popup_check_product_list);
        let ga_product_list = [];
        for (var idx in add_product_list) {
            // alert(idx);
            var obj = add_product_list[idx];
            var result_obj = {
                category_no: obj.category_no,
                create_type_cd: "22999",
                del_yn: "N",
                display_yn: "Y",
                iframe_html_id: page_id,
                iframe_page_id: html_id,
                image_url: obj.image,
                price: obj.price,
                product_name: obj.product_name,
                product_no: obj.product_no,
            }
            rp_product_list.push(result_obj) // 실제 추가 할 상품
            var ga_result_obj = {
                product_name: obj.product_name,
                product_no: obj.product_no,
            }
            ga_product_list.push(ga_result_obj) // GA 이벤트 표기용 상품
        }
        this.setState({
            rp_product_list: rp_product_list,
            popup_check_product_list: [],
            show_admin_page_2_popup: false,
        }, () => {
            $("input[id='popup_checkall']").prop('checked',false);
            $("input[name='popup_check']").prop('checked',false);
            // console.log("###### rp_product_list #######")
            // console.log(rp_product_list)
            this.changeModifyDateAndTypeName("임시 수정");

            if (ga_product_list != null && ga_product_list.length > 0) {
                // alert("선택 상품 추가하기 - "+ga_product_list.length+"개 "+ JSON.stringify(ga_product_list) +" ("+ this.state.ga_rp_category_name + ")")
                this.setPopupGaEvent("선택 상품 추가하기 - "+ga_product_list.length+"개 "+ JSON.stringify(ga_product_list) +" ("+ this.state.ga_rp_category_name + ")");
            }
        })
    }

    saveRpEvent() {
        // 22001	베스트
        // 22002	네이버 연관 검색어 트렌드
        // 22003	최신순
        // 22004	수동
        if (this.state.rp_product_list == null || (this.state.rp_product_list != null && this.state.rp_product_list.length <= 0)) {
            alert("상품을 추가해 주세요.")
            return false;
        }

        var page_id = this.getUrlParams().page_id;
        var html_id = this.getUrlParams().html_id;

        const config = {
            headers: {
                'Content-Type': 'application/json',
                },
        };
        const url = '/rest/shop/rp/event/save';
        const body = {
            iframe_page_id : page_id,
            iframe_html_id : html_id,
            list : this.state.rp_product_list
        }
        http
        .post(url, body, config)
        .then((res) => {
            if (res.data.code == "200") {
                alert("저장하였습니다.");
                window.location.reload();

            } else {
                alert(res.data.code + "\n" + res.data.msg);
            }
        })
        .catch((error) => {
            alert(error + '저장 실패.\n관리자에게 문의해주세요(1599-3518)');
        });

        this.setGaEvent("저장");
    }

    render() {
        return (
            <div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
                <div class="admin_wrap">
                    <div class="admin_page">
                        <div class="admin_page_2_row_1" style={{color: "#aaa"}}>
                            <NavLink className="page_title_v2" to="/shop/rp/event/list" style={{color: "#aaa"}}>
                                베스트 롤링
                            </NavLink>
                            <h2>{">"}</h2>
                            <div className="page_title_v2" style={{color: "#000", marginLeft:"20px"}}>
                                상품목록
                            </div>
                            <a className="step" href="https://robomd.notion.site/MD-453b6fab85144146bee0d2e586e06438" target="_blank">
                                <div class="user_guide userGuideButton">
                                    사용자 가이드
                                </div>
                            </a>
                        </div>
                        <div class="admin_page_2_row_2">
                            <div>
                                <h2>
                                    {this.state.rp_setting != null && this.state.rp_setting.iframe_html_name} 상품 목록
                                </h2>
                            </div>
                            <div class="admin_page_2_row_2_btn">
                                {
                                    this.state.is_modified
                                    ?   <h2>
                                            <input type="reset" placeholder="초기화" value="초기화" onClick={e => this.initState()}/>
                                            <button type="button" onClick={e => this.saveRpEvent()}>저장</button> 
                                        </h2>
                                    :   ""
                                }
                                {/* <h4 style={{textAlign: "right"}}>
                                    2021-12-15 16:28:30 자동 선정
                                </h4> */}
                            </div>
                            <div className="flexBox" style={{width:"100%", justifyContent:"space-between"}}>
                                <div className="description" id="description1">
                                    인공지능 로보MD가 쇼핑몰 이용 데이터를 분석하여 선정한 상품 목록을 수정할 수 있습니다.
                                </div>
                                <div className="description" id="description2">
                                    {this.state.modified_date} {this.state.modified_type_name}
                                </div>
                            </div>
                        </div>
                        <div class="admin_page_2_row_3">
                            <h2>상품 카테고리</h2>
                            <select class="admin_category" onChange={e => this.onChangeRpCategory(e,2)} value={this.state.product_rp_category_no_1}>
                                <option>전체상품</option>
                                {this.state.product_rp_category_list_1 != null && this.state.product_rp_category_list_1.map((obj, idx) => (
                                    <option key={idx} value={obj.category_no}>
                                        {obj.category_name}
                                    </option>                            
                                ))}
                            </select>
                            {
                                this.state.product_rp_category_list_2 != null && this.state.product_rp_category_list_2.length > 0
                                ?   <select class="admin_category" onChange={e => this.onChangeRpCategory(e,3)} value={this.state.product_rp_category_no_2}>
                                        <option>전체상품</option>
                                        {this.state.product_rp_category_list_2 != null && this.state.product_rp_category_list_2.map((obj, idx) => (
                                            <option key={idx} value={obj.category_no}>
                                                {obj.category_name}
                                            </option>                            
                                        ))}
                                    </select>
                                :   ""
                            }
                            {
                                this.state.product_rp_category_list_3 != null && this.state.product_rp_category_list_3.length > 0
                                ?   <select class="admin_category" onChange={e => this.onChangeRpCategory(e,4)} value={this.state.product_rp_category_no_3}>
                                        <option>전체상품</option>
                                        {this.state.product_rp_category_list_3 != null && this.state.product_rp_category_list_3.map((obj, idx) => (
                                            <option key={idx} value={obj.category_no}>
                                                {obj.category_name}
                                            </option>                            
                                        ))}
                                    </select>
                                :   ""
                            }
                            {
                                this.state.product_rp_category_list_4 != null && this.state.product_rp_category_list_4.length > 0
                                ?   <select class="admin_category" value={this.state.product_rp_category_no_4}>
                                        <option>전체상품</option>
                                        {this.state.product_rp_category_list_4 != null && this.state.product_rp_category_list_4.map((obj, idx) => (
                                            <option key={idx} value={obj.category_no}>
                                                {obj.category_name}
                                            </option>                            
                                        ))}
                                    </select>
                                :   ""
                            }
                            <button type="button" onClick={e => this.loadBestProductList()} style={{width:"auto", paddingLeft:"10px", paddingRight:"10px"}}>
                                AI 추천상품 불러오기
                            </button>
                        </div>
                        <div class="admin_page_2_row_4">
                            <button type="button" onClick={e => this.onClickCheckedRpProductDelete(e)}>선택 삭제</button>
                            <button type="button" name="add_items" onClick={e=>this.onClickPopupShowHide(e, true)}>상품 추가</button>
                            <div class='m_admin_row_4_text'>
                                <h2>소상공인</h2>
                                <h2>{">"}</h2>
                                <h2>전체상품</h2>
                            </div>
                        </div>
                        {
                            this.state.show_admin_page_2_popup
                            ?
                                <div class="admin_page_2_popup">
                                    <div class="admin_popup">
                                        <div class="admin_popup_row_1">
                                            <h2>상품 추가</h2>
                                            <div className="cursor-pointer btn_none_border" onClick={e=>this.onClickPopupClose(e)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#444" class="bi bi-x-lg" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                                                    <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div class="admin_popup_row_2">
                                            <h2>상품 카테고리</h2>
                                            <select class="admin_category" onChange={e => this.onChangeCategory(e,2)} value={this.state.popup_category_no_1}>
                                                <option selected hidden>대분류 선택</option>
                                                {
                                                    this.state.product_category_list_1 != null && this.state.product_category_list_1.length > 0
                                                    ?   <option>전체상품</option>
                                                    :   ""
                                                }
                                                {this.state.product_category_list_1 != null && this.state.product_category_list_1.map((obj, idx) => (
                                                    <option key={idx} value={obj.category_no}>
                                                        {obj.category_name}
                                                    </option>                            
                                                ))}
                                            </select>
                                            <select class="admin_category" onChange={e => this.onChangeCategory(e,3)} value={this.state.popup_category_no_2}>
                                                <option selected hidden>중분류 선택</option>
                                                {
                                                    this.state.product_category_list_2 != null && this.state.product_category_list_2.length > 0
                                                    ?   <option>전체상품</option>
                                                    :   ""
                                                }
                                                {this.state.product_category_list_2 != null && this.state.product_category_list_2.map((obj, idx) => (
                                                    <option key={idx} value={obj.category_no}>
                                                        {obj.category_name}
                                                    </option>                            
                                                ))}
                                            </select>
                                            <select class="admin_category" onChange={e => this.onChangeCategory(e,4)} value={this.state.popup_category_no_3}>
                                                <option selected hidden>소분류 선택</option>
                                                {
                                                    this.state.product_category_list_3 != null && this.state.product_category_list_3.length > 0
                                                    ?   <option>전체상품</option>
                                                    :   ""
                                                }
                                                {this.state.product_category_list_3 != null && this.state.product_category_list_3.map((obj, idx) => (
                                                    <option key={idx} value={obj.category_no}>
                                                        {obj.category_name}
                                                    </option>                            
                                                ))}
                                            </select>
                                            <select class="admin_category">
                                                <option selected hidden>세분류 선택</option>
                                                {
                                                    this.state.product_category_list_4 != null && this.state.product_category_list_4.length > 0
                                                    ?   <option>전체상품</option>
                                                    :   ""
                                                }
                                                {this.state.product_category_list_4 != null && this.state.product_category_list_4.map((obj, idx) => (
                                                    <option key={idx} value={obj.category_no}>
                                                        {obj.category_name}
                                                    </option>                            
                                                ))}
                                            </select>
                                        </div>
                                        <div class="admin_popup_row_3">
                                            <h2>상품 검색</h2>
                                            <select class="admin_category" onChange={e => this.onChangeSearchGubun(e)}>
                                                <option value="product_name">상품명</option>
                                                <option value="product_code">상품코드</option>
                                            </select>
                                            <input name="search_input" type="text" onChange={e => this.onChangeSearchInput(e)} onKeyDown={e => (e.key=='Enter' ? this.loadProductList("Y") : "")}/>
                                            <button type="button" name="search_btn" onClick={e => this.loadProductList("Y")}>검색</button>
                                        </div>
                                        <div class="admin_popup_row_5">
                                            <h2>상품 목록</h2>
                                            <select class="admin_category" onChange={e => this.onChangeOrderGubun(e)} value={this.state.order_gubun_select_value}>
                                                <option value="created_date">등록일순</option>
                                                <option value="price_asc">가격 낮은순</option>
                                                <option value="price_desc"> 가격 높은순</option>
                                                <option value="product_name">가나다순</option>
                                            </select>
                                        </div>
                                        <div className="rp_popup_table_wrap" style={{minHeight: "470px",height: "50vh",overflowY: "scroll",overflowX:"auto",marginTop: "20px"}}>
                                            <table class="admin_page_2_table" style={{marginTop: "0"}}>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <input type="checkbox" id="popup_checkall" name="popup_checkall" onClick={e => this.onClickPopupCheckAll(e)}/>
                                                            <label className="check_svg_area" for="popup_checkall">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#888" class="bi bi-check-square" viewBox="0 0 16 16">
                                                                    <path class="svg_box" d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                                                    <path class="svg_check" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                                                                    <path class="svg_line" fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1"/>
                                                                </svg>
                                                            </label>
                                                        </th>
                                                        <th>번호</th>
                                                        <th>사진</th>
                                                        <th>상품명</th>
                                                        <th>판매가</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.product_list != null && this.state.product_list.length > 0 && this.state.product_list.map((obj, idx) => (
                                                            <tr>
                                                                <td>
                                                                    <input type="checkbox" id={"popup_check"+obj.product_no} name="popup_check" onClick={e => this.onClickPopupCheck(e, idx)}/>
                                                                    <label className="check_svg_area" for={"popup_check"+obj.product_no}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#888" class="bi bi-check-square" viewBox="0 0 16 16">
                                                                        <path class="svg_box" d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                                                        <path class="svg_check" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                                                                    </svg>
                                                                    </label>
                                                                </td>
                                                                <td>{obj.product_no}</td>
                                                                <td>
                                                                    <img src={obj.image}/>
                                                                </td>
                                                                <td>{obj.product_name}</td>
                                                                <td>{obj.price}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                            {
                                                Number(this.state.totalCnt) > 0
                                                ?
                                                <div style={{marginTop:"25px"}}>
                                                    <Pagination
                                                        activePage={this.state.activePage}
                                                        itemsCountPerPage={this.state.rowcnt}
                                                        totalItemsCount={this.state.totalCnt}
                                                        pageRangeDisplayed={5}
                                                        onChange={this.handlePageChange.bind(this)}
                                                    />
                                                </div>    
                                                : ""
                                            }  
                                        <div class="admin_popup_row_6">
                                            <button onClick={e => this.onClickPopupCheckedProductApply(e)}>선택 상품 추가하기</button>
                                        </div>
                                    </div>
                                </div>
                            :   ""
                        }
                        <div class="admin_page_2_table_wrap">
                            <table class="admin_page_2_table">
                                <thead>
                                    <tr>
                                        <th>
                                            <input type="checkbox" id="checkall" name="page_checkall" onChange={e => this.onChangeRpProductCheckAll(e)}/>
                                            <label className="check_svg_area" for="checkall" >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#888" class="bi bi-check-square" viewBox="0 0 16 16">
                                                    <path class="svg_box" d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                                    <path class="svg_check" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                                                    <path class="svg_line" fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1"/>
                                                </svg>
                                            </label>
                                        </th>
                                        <th>번호</th>
                                        <th>사진</th>
                                        <th>상품명</th>
                                        <th>판매가</th>
                                        <th>순서 변경</th>
                                        <th>관리</th>
                                    </tr>
                                </thead>
                                    {
                                        this.state.rp_product_list != null && this.state.rp_product_list.length > 0
                                        ?   
                                            <tbody style={{position: "relative", height:"auto"}}>   
                                                {
                                                    this.state.rp_product_list.map((obj, idx) => (
                                                        <tr>
                                                            <td>
                                                                <input type="checkbox" id={"check"+(idx+1)} name="page_check" onChange={e => this.onChangeRpProductCheck(e, idx)}/>
                                                                <label className="check_svg_area" for={"check"+(idx+1)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#888" class="bi bi-check-square" viewBox="0 0 16 16">
                                                                        <path class="svg_box" d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                                                        <path class="svg_check" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                                                                    </svg>
                                                                </label>
                                                            </td>
                                                            <td>{idx+1}</td>
                                                            <td>
                                                                <img className="product_img" src={obj.image_url}/>
                                                            </td>
                                                            <td>{obj.product_name}</td>
                                                            <td>{obj.price}</td>
                                                            <td>
                                                                <div style={{display:"flex", flexWrap:"nowrap", width:"100%", justifyContent:"center"}}>
                                                                    <button type="button" onClick={e=>this.onChangeProductOrder(e, idx, 0)} style={{flex:"1", maxWidth:"31.78px"}}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" class="bi bi-chevron-double-up" viewBox="0 0 16 16">
                                                                            <path fill-rule="evenodd" d="M7.646 2.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 3.707 2.354 9.354a.5.5 0 1 1-.708-.708l6-6z"/>
                                                                            <path fill-rule="evenodd" d="M7.646 6.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 7.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                                                                        </svg>
                                                                    </button>
                                                                    <button type="button" onClick={e=>this.onChangeProductOrder(e, idx, idx-1)} style={{flex:"1", maxWidth:"31.78px"}}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" class="bi bi-chevron-up" viewBox="0 0 16 16">
                                                                            <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                                                                        </svg>
                                                                    </button>
                                                                    <button type="button" onClick={e=>this.onChangeProductOrder(e, idx, idx+1)} style={{flex:"1", maxWidth:"31.78px"}}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" class="bi bi-chevron-down" viewBox="0 0 16 16">
                                                                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                                                                        </svg>
                                                                    </button>
                                                                    <button type="button" onClick={e=>this.onChangeProductOrder(e, idx, this.state.rp_product_list.length-1)} style={{flex:"1", maxWidth:"31.78px"}}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" class="bi bi-chevron-double-down" viewBox="0 0 16 16">
                                                                            <path fill-rule="evenodd" d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                                                                            <path fill-rule="evenodd" d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <button type="button" className="btn_none_border" onClick={e=>this.deleteProduct(e, idx)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#888" class="bi bi-trash" viewBox="0 0 16 16">
                                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                                    </svg>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        : 
                                            this.state.show_loading_bar
                                            ?
                                                <tbody style={{position: "relative", height:"200px"}}>  
                                                    <div style={{width:$(".admin_page_2_table_wrap").width(), height:"200px",position: "absolute",display:"flex",justifyContent:"center"}}>
                                                        <img src={round_loading_bar} style={{width:"200px"}}></img>
                                                    </div>  
                                                </tbody>
                                            : ""        
                                    }
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

import React from 'react';
import http from '../api';
import InputDialogV2 from '../dialogs/InputDialogV2';

/**
 * 유튜브 링크 업로드
 * @function onClose 팝업 닫기 함수
 * @function getVideoInfo 영상 정보를 받아서 처리할 함수
 * @param {*} link 초기 영상 링크 값
 */
class UploadYoutubeLinkPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error : false,
            infoText: "",
        }
    }

    /**
     * 유튜브 영상 ID 추출
     * @param {*} url 유튜브 URL
     * @returns 유튜브 영상 ID
     */
    getYouTubeVideoId = (url) => {
        if(url.includes("youtube.com") || url.includes("youtu.be")){
            if(url.includes("v=") && url.includes("/watch")){
                return url.split("v=")[1].split("&")[0];
            } else{
                return url.split("/").reverse()[0].split("?")[0];
            }
        }
        return false;
    }

    /**
     * 유튜브 영상 유효성 체크
     * @param {*} videoId 영상 ID
     * @function loadAction 영상이 정상적으로 로드되었을 경우 실행할 함수
     * @function errorAction 영상을 로드할 수 없을 경우 실행할 함수
     */
    checkYouTubeVideoUseAble = (videoId, loadAction, errorAction) => {
        http.get("/rest/shop/youtube/list?video_id="+videoId)
        .then(res => {
            if(res.data.items.length > 0){
                if(loadAction){
                    loadAction(res.data.items);
                }
            } else{
                if(errorAction){
                    errorAction(res.data);
                }
            }
        })
        .catch(error => {
            if(errorAction){
                errorAction(error);
            }
        })
    }

    render() {
        const {onClose, getVideoInfo, link} = this.props;
        return (
                <InputDialogV2
                    title={"동영상 주소를 입력해 주세요."}
                    content={"유튜브 링크 주소를 입력하고 업로드 해보세요."}
                    input_value={link}
                    input_placeholder={"https://"}
                    infoText={this.state.infoText}
                    error={this.state.error}
                    onChange={() => {
                        this.setState({
                            infoText : "",
                            error : false,
                        });
                    }}
                    button_list={[
                        {
                            title: "확인",
                            type: "1",
                            event_method: (e) => {
                                if(e.target.value){
                                    const YouTubeID = this.getYouTubeVideoId(e.target.value);
                                    // 유튜브 영상 ID 추출에 실패한 경우
                                    if(!YouTubeID){
                                        this.setState({
                                            infoText : "접근할 수 없는 링크에요. 주소를 확인해 주세요.",
                                            error : true,
                                        });
                                        return false;
                                    }
                                    // 유튜브 URL 유효성 체크
                                    this.checkYouTubeVideoUseAble(YouTubeID,
                                        // 유튜브 영상이 로드되는 경우
                                        (items) => {
                                            getVideoInfo(items, e.target.value);
                                        },
                                        // 유튜브 영상이 로드되지 않는 경우
                                        () => {
                                            this.setState({
                                                infoText : "접근할 수 없는 링크에요. 주소를 확인해 주세요.",
                                                error : true,
                                            });
                                        }
                                    );
                                }
                            }
                        },
                        {
                            title: "취소",
                            type: "0",
                            event_method: () => {
                                onClose();
                            }
                        }
                    ]}
                    popup_close={() => {
                        onClose();
                    }}
                />
        );
    }
}

UploadYoutubeLinkPopup.defaultProps = {
    onClose : () => {},
    getVideoInfo : () => {},
    link: "",
}

export default UploadYoutubeLinkPopup;
import React from 'react';
import axios from '../modules/api';
import http from '../modules/api';
import {Line} from 'react-chartjs-2';
import { NavLink, Link } from 'react-router-dom';
import $ from 'jquery';
import Slider from "react-slick";

import ReactGA, { send } from 'react-ga';
ReactGA.initialize('UA-78553461-8', {
    titleCase: false,
});

export class RpEventList extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
            list: [],
        }
    }

    componentWillMount() {
        this.loadRpEventEventSetting();
    }

	componentDidMount() {

    }

    componentDidUpdate() {
       
    }

    setGaEvent(action_text) {
        ReactGA.event({
             category: "베스트 서비스 (롤링 목록)",
             action: action_text,
             label: sessionStorage.getItem("user_id"),
         });
     }

    onClickRpProductPageLink(e, page_id, html_id, html_name) {
        this.setGaEvent("목록 클릭 - "+ html_name);
        this.props.history.push(
            {
                pathname: `/shop/rp/event/product/list`,
                search:`?page_id=`+page_id+`&html_id=`+html_id,
                // state: {prevPath: window.location.href}
            }	
        )	
    }

    async loadRpEventEventSetting() {
        await http.get("/rest/shop/rp/list/event/setting")
		.then(res => {
			if (res.data != null && res.data != '') {
				this.setState( {list : res.data["list"]} );
			}
		})
		.catch(error => {
			// console.log ("===== error =====");
			// console.log (error);
			// console.log ("===== error request =====");
			// console.log(error.request);
		 });
    }

    render() {
        return (
            <div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
                <div class="admin_wrap">
                    <div class="admin_page">
                        <div class="admin_page_row_1 page_title_v2">
                                베스트 롤링
                            <a href="https://iclave.notion.site/MD-c58c3cc7b6d14497a92a49d86f87bdcf" target="_blank" style={{marginLeft:"20px"}}>    
                                <div class="user_guide userGuideButton">
                                    사용자 가이드
                                </div>
                            </a>
                        </div>
                        {/* <div class="admin_page_row_2">
                            이용 정보
                        </div> */}
                        <div class="admin_page_row_3">
                            롤링 목록
                        </div>
                        <div class="admin_page_table_wrap">
                            <table class="admin_page_table">
                                <thead>
                                    <tr>
                                        <th>번호</th>
                                        <th>제목</th>
                                        <th>선정 기준</th>
                                        <th>상품수</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { this.state.list != null && this.state.list.length > 0
                                    ?   <>
                                        {
                                            this.state.list.map((obj, idx) => (
                                                <tr onClick={e => this.onClickRpProductPageLink(e, obj.iframe_page_id, obj.iframe_html_id, obj.iframe_html_name)}>
                                                    <td>{this.state.list.length-idx}</td>
                                                    <td>{obj.iframe_html_name}</td>
                                                    <td>{obj.auto_create_yn}</td>
                                                    <td>{obj.product_cnt}</td>
                                                </tr>
                                            ))
                                        }
                                        </>
                                    : "목록이 없습니다."
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

import React, { Component } from 'react';

class TagButton extends Component {
    componentDidUpdate(prevProps) {
    }

    handleClick = () => {
        const { onClick, tagCd, isSelected } = this.props;
        onClick(tagCd, !isSelected);
    };

    render() {
        const {tagCd, tagName, isSelected} = this.props;

        const buttonStyle = {
            backgroundColor: isSelected ? '#00E2E6' : '#FFF',
            border: isSelected ? '1px solid #00E2E6' : '1px solid #ACACAC',
            borderRadius : '30px',
            padding: '5px 20px',
            fontFamily: 'Noto Sans KR',
            fontSize: '15px',
            cursor: 'pointer',
            margin: '5px 3px',
            display: 'inline-block',
        };

        return (
            <button
                className={tagCd}
                style={buttonStyle}
                onClick={this.handleClick}
            >
                {tagName}
            </button>
        );
    }
}

export default TagButton;
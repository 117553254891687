export function detectBroeser() {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return "Internet Explorer " + parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return "Internet Explorer " + parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        return "Edge " + parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }
    
    var chrome = ua.indexOf('Chrome/');
    if (chrome > 0) {
        // Chrome
        if(ua.indexOf("NAVER") > 0) {
            return navigator.userAgent.match(/Chrome\/(\S+)/)[0] + " (NAVER)";
        } else if(ua.indexOf("KAKAOTALK") > 0) {
            return navigator.userAgent.match(/Chrome\/(\S+)/)[0] + " (KAKAOTALK)";
        } else {
            return navigator.userAgent.match(/Chrome\/(\S+)/)[0];
        }
    }

    var Firefox = ua.indexOf('Firefox/');
    if (Firefox > 0) {
        // Firefox
        return navigator.userAgent.match(/Firefox\/(\S+)/)[0];
    }

    var Safari = ua.indexOf('Safari/');
    if (Safari > 0) {
        // Safari
        return navigator.userAgent.match(/Safari\/(\S+)/)[0];
    }

    if(ua.indexOf("KAKAOTALK") > 0) {
        return "KAKAOTALK"; // 크롬 아닌 카카오브라우저
    }

    // other browser
    return "알수없음";
}

export function detectOS() {
    var ua = window.navigator.userAgent;
    var OSName = "알수없음";
    if (ua.indexOf("Windows NT 10.0")!= -1) OSName="Windows 10";
    if (ua.indexOf("Windows NT 6.2") != -1) OSName="Windows 8";
    if (ua.indexOf("Windows NT 6.1") != -1) OSName="Windows 7";
    if (ua.indexOf("Windows NT 6.0") != -1) OSName="Windows Vista";
    if (ua.indexOf("Windows NT 5.1") != -1) OSName="Windows XP";
    if (ua.indexOf("Windows NT 5.0") != -1) OSName="Windows 2000";
    if (ua.indexOf("Mac")            != -1) {
        var match = (window.navigator.appVersion).match(/Mac OS X (10[\.\_\d]+)/)
        if (match != null) {
            OSName= "Mac OS " +  (window.navigator.appVersion).match(/Mac OS X (10[\.\_\d]+)/)[1];
        }
    }
    if (ua.indexOf("iPhone") != -1) {
        var v = (window.navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
        var v_list = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
        OSName= "iPhone OS " + v_list[0] + "." + v_list[1] + "." + v_list[2];
    }
    if (ua.indexOf("iPad") != -1) {
        var v = (window.navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
        var v_list = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
        OSName= "iPad OS " + v_list[0] + "." + v_list[1] + "." + v_list[2];
    }
    if (ua.indexOf("X11")            != -1) OSName="UNIX";
    if (ua.indexOf("Linux")          != -1) OSName="Linux";
    if (ua.match(/android\s([0-9\.]*)/i))  {
        OSName =ua.match(/android\s([0-9\.]*)/i)[0];
    }
    return OSName
}


export function detectDevice() {
    var ua = window.navigator.userAgent;
    var device = "알수없음";
    if (ua.indexOf("Windows NT 10.0")!= -1) device="Windows Desktop";
    if (ua.indexOf("Windows NT 6.2") != -1) device="Windows Desktop";
    if (ua.indexOf("Windows NT 6.1") != -1) device="Windows Desktop";
    if (ua.indexOf("Windows NT 6.0") != -1) device="Windows Desktop";
    if (ua.indexOf("Windows NT 5.1") != -1) device="Windows Desktop";
    if (ua.indexOf("Windows NT 5.0") != -1) device="Windows Desktop";
    if (ua.indexOf("Mac")            != -1) {
        var match = (window.navigator.appVersion).match(/Mac OS X (10[\.\_\d]+)/)
        if (match != null) {
            device= "Apple Macintosh";
        }
    }
    if (ua.indexOf("iPhone") != -1) {
        var v = (window.navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
        var v_list = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
        device= "iPhone";
    }
    if (ua.indexOf("iPad") != -1) {
        var v = (window.navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
        var v_list = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
        device= "iPad";
    }
    if (ua.indexOf("X11")            != -1) device="UNIX";
    if (ua.indexOf("Linux")          != -1) device="Linux";
    if (ua.match(/android\s([0-9\.]*)/i))  {
        for (var idx in ua.split(";")){
            if (ua.split(";")[idx].indexOf("Build") > 0) {
                device = ua.split(";")[idx].split("Build")[0];
                return device;
            } else if (ua.split(";")[idx].indexOf("SAMSUNG") > 0) {
                device = ua.split(";")[idx].split(")")[0];
                return device;
            } else if (ua.split(";")[idx].indexOf("Android 9") > 0) {
                device = ua.split(";")[parseInt(idx)+1].split(")")[0];
                return device;
            } else {
                device = "알수없음";
            }
        }
    }
    return device
}
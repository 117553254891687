import React from 'react';
import axios from 'axios';
import http from '../modules/api';
import {Route, Switch, NavLink, Link} from "react-router-dom";
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";
import FileDownloadComponent from "../modules/FileDownloadComponent";

import "react-datepicker/dist/react-datepicker.css";

import $ from 'jquery';

export class InfoReqList extends React.Component {
	state = {
			qna_type: [],
			infoReq_list: [],
			orderGubun: "",
			sorting: "",
			currentPage: "",
			start_date: "",
			end_date: "",
			start_date_format: "",
			end_date_format: "",
			title: "",
			activePage: 1,
			totalCnt: 0,
			startIdx: 0,
			rowcnt:10,
			type: '',   		/*문의유형*/
			platform_type: '',  /*플랫폼 유형*/
			options: {
				platform_type:[{cd_code:'-', cd_desc:'없음'}],
				type: [{cd_code:'-', cd_desc:'없음'}]
			}
	};

	//공통코드 조회
	async getCode(cd_class, option) {
		const res = await http.get("/rest/common/roboMdCode?cd_class="+cd_class);
		const { options } = this.state;
		this.setState({
			options:{
				...options,
				[option]:res.data
			}
		});
		// console.log(this.state)
	};

	handleText = (e, key) => {
	    // computed property
		this.setState({[key]: e.target.value});
	}
	
	componentDidMount(e) {
		// console.log(localStorage.getItem ('state'))
		// if (localStorage.getItem ('state') != null){
		// 	this.setState(JSON.parse(localStorage.getItem ('state')));
		// 	localStorage.clear();
		// } else {
		// }

		//쇼핑몰 플랫폼 코드 조회 
		this.getCode("5200", "platform_type");
		//문의코드 조회 
		this.getCode("5100", "type");

		if (localStorage.getItem ('state') != null){
			new Promise( ( resolve, reject ) => {setTimeout( resolve, 200 ); })
			.then( () => {
				var json = JSON.parse(localStorage.getItem ('state'));
				json.infoReq_list = [];
				json.start_date = Date.parse(json.start_date_format);
				json.end_date = Date.parse(json.end_date_format);
				this.setState(json);
				this.state.startIdx = parseInt(localStorage.getItem("startIdx"));
				localStorage.clear();
				this.getInfoReqList();
			});
		} else {
			this.getInfoReqList();
		}
	}

	componentDidUpdate(e) {
		// console.log("######### componentDidUpdate ##########")
	}

	onComponentChange = e => {
		// console.log("######### onComponentChange ##########")
	}

	componentWillUnmount = e => {
		// console.log("######### componentWillUnmount ##########")
	}

	//1. 목록 조회
	async getInfoReqList() {
		console.log(this.state.type);
		await http.get("/rest/admin/infoReq/search?start_date="+this.state.start_date_format+"&end_date="+this.state.end_date_format+"&title="+this.state.title
		+"&order_type="+this.state.sorting+"&order_gubun="+this.state.orderGubun+"&startidx="+this.state.startIdx+"&rowcnt="+this.state.rowcnt+"&type="+this.state.type+"&platform_type="+this.state.platform_type)
		.then(res => {
			this.setState( {infoReq_list : res.data["list"]} );
			this.setState( {totalCnt : res.data["totalCnt"]} );
			// console.log(this.state.totalCnt)
		})
		.catch(error => {
			// console.log (error);
		 });
    }
	
	//2. 상세 조회
	handleClick = (info_req_seq) => {
		this.props.history.push(`/admin/infoReq/detail/${info_req_seq}`);
		localStorage.setItem ( 'state' , JSON.stringify(this.state) ) ;
		localStorage.setItem ( 'startIdx' , this.state.startIdx ) ;
	};
	
	// 3. 정렬
	onClickSorting = (e) => {
		if (this.state.orderGubun == e.target.className.replace(" sorting","")) {
			if (this.state.sorting == "ASC")
			this.state.sorting = "DESC";
			else
			this.state.sorting = "ASC";
		} else {
			this.state.sorting = "DESC";
		}
		this.state.orderGubun = e.target.className.replace(" sorting","");
		this.state.currentPage = 1;
		this.getInfoReqList();
	};

	//4. 조회 버튼 클릭
	onClickSearchListButton = () => {
		this.state.startIdx = 0;
		this.state.activePage = 1;
		this.getInfoReqList();
	};

	//4. 달력 클릭
	handleChangeStartDate = date => {
		if (date != null) {
			this.setState({
				start_date_format: date.getFullYear() + "-"+ ("0" + parseInt(date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(date.getDate())).slice(-2),
				start_date: date
			});
		} else {
			this.setState({
				start_date_format: date,
				start_date: date
			});
		}
	};
	handleChangeEndDate = date => {
		if (date != null) {
			this.setState({
				end_date_format: date.getFullYear() + "-"+ ("0" + parseInt(date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(date.getDate())).slice(-2),
				end_date: date
			});
		} else {
			this.setState({
				end_date_format: date,
				end_date: date
			});
		}
	};

	//5. 엔터키 검색
	_handleKeyDown = e => {
		if (e.key === 'Enter') {
			this.state.startIdx = 0;
			this.state.activePage = 1;
			this.getInfoReqList();
		}
	  }

	//6.페이징
	handlePageChange(pageNumber) {
		// console.log(`active page is ${pageNumber}`);
		this.setState({activePage: pageNumber});
		this.state.startIdx = (pageNumber * 10) - this.state.rowcnt;
		// console.log("this.state.startIdx :" + this.state.startIdx )
		this.getInfoReqList()
	  }  

	_handleChange = (e, key) => {
		// computed property
	    this.setState({[key]: e.target.value});
	};

	  componentWillReceiveProps(nextProps, nextContext) {
		// console.log(nextProps);
		this.getInfoReqList(nextProps.match.params.id);
	  }

	  render() {
		
		return (
			<div id='page-wrapper' className="container info_req_list_container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
				<div id="qna_list_component">
					<h3>[Admin] CS 문의사항</h3>
					<br/>
					<div className="filter">
						<div style={{display:"flex", marginBottom: '-12px'}}>
							<div className="input-group m-b" style={{width:"66%"}}>
								<div style={{width: "100%"}}> 
								<label>날짜</label>
									<div className="input-daterange input-group" id="datepicker">
										<DatePicker name="start_date" id="start_date"
											selected={this.state.start_date}
											onSelect={this.handleSelect} //when day is clicked
											onChange={this.handleChangeStartDate} //only when value has changed
											// showTimeSelect
											dateFormat="yyyy-MM-dd"
											autoComplete='off'
										/>
										<div className="input-group-addon" style={{width: "40px",height:"35px", lineHeight: "34px",textAlign:"center"}}>to</div>
										<DatePicker name="end_date" id="end_date"
											selected={this.state.end_date}
											onSelect={this.handleSelect} //when day is clicked
											onChange={this.handleChangeEndDate} //only when value has changed
											// showTimeSelect
											dateFormat="yyyy-MM-dd"
											autoComplete='off'
										/>	
									</div>
								</div>
							</div>
							<div className="input-group m-b" style={{width:"33%", marginLeft:"10px"}}>
								<label>문의 종류</label>
								<select id="type" style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px", marginBottom:"10px"}}
								value={this.state.type} onChange={(e)=>this._handleChange(e, 'type')} >
									<option value="">
										전체
									</option>
									{
										this.state.options && this.state.options.type && 
										this.state.options.type.map((option, idx) => (	
										<option value={option.cd_code} key={idx}>
											{option.cd_desc}
										</option>								
									))};
								</select>
							</div>
							<div className="input-group m-b" style={{width:"33%", marginLeft:"10px"}}>
								<label>플랫폼 종류</label>
								<select id="platform_type" style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px", marginBottom:"10px"}}
								value={this.state.platform_type} onChange={(e)=>this._handleChange(e, 'platform_type')} >
									<option value="">
										전체
									</option>
									{
										this.state.options && this.state.options.platform_type && 
										this.state.options.platform_type.map((option, idx) => (	
										<option value={option.cd_code} key={idx}>
											{option.cd_desc}
										</option>								
									))};
								</select>
							</div>
						</div>
						<div style={{display:"flex", marginBottom: '12px'}}>
							<input type="text" onChange={(e) => {
								// console.log(e.target.value)
								this.setState({ title: e.target.value })
							}} placeholder="검색어를 입력하세요." className="info_req_list_input" onKeyDown={this._handleKeyDown}
							value={this.state.title}></input>
							<button className = "greenButton admin_search_btn" onClick={this.onClickSearchListButton}>검색</button>
							<FileDownloadComponent url={"/rest/admin/infoReq/excel?"+
																					"&start_date="+this.state.start_date_format
																					+"&end_date="+this.state.end_date_format
																					+"&title="+this.state.title
																					+"&type="+this.state.type
																					+"&platform_type="+this.state.platform_type
																					+"&order_type="+this.state.sorting
																					+"&order_gubun="+this.state.orderGubun
																					}
														   obj=""
														   val1="none"/>
						</div>
					</div>
					<div className="table">
						<table>
							<colgroup>
								<col width="50px" />	{/*순번*/}
								<col width="100px" />	{/*문의종류*/}
								<col width="100px" />	{/*플랫폼 종류*/}
								<col width="*" />		{/*제목*/}
								<col width="180px" />	{/*기업명*/}
								<col width="120px" />	{/*담당자명*/}
								<col width="180px" />	{/*이메일*/}
								<col width="120px" />	{/*연락처*/}
								<col width="180px" />	{/*사이트 주소*/}
								<col width="200px" />	{/*등록일자*/}
							</colgroup>
							<thead>	
								<tr>
									<td>
										순번
									</td>
									<td>
										문의 종류
									</td>
									<td>
										플랫폼 종류
									</td>
									<td>
										<p className="card-text">제목</p>
									</td>
									<td className="corp_name sorting" onClick={this.onClickSorting}>
										기업명
									</td>
									<td className="user_name sorting" onClick={this.onClickSorting}>
										담당자명
									</td>
									<td className="email sorting" onClick={this.onClickSorting}>
										이메일
									</td>
									<td className="tel_no sorting" onClick={this.onClickSorting}>
										연락처
									</td>
									<td className="site_url sorting" onClick={this.onClickSorting}>
										사이트 주소 
									</td>
									<td className="reg_date sorting" onClick={this.onClickSorting}>
										등록일자
									</td>
								</tr>
							</thead>
							<tbody>
								{
								this.state.infoReq_list && this.state.infoReq_list.length > 0 &&
								this.state.infoReq_list.map((data, idx) => (
									<tr key={data.info_req_seq} className="cursor-pointer tbody-tr-hover" onClick={() => this.handleClick(data.info_req_seq)}>
										<td>
											<p className="card-text">{data.info_req_seq}</p>
										</td>
										<td>
											<p className="card-text">{data.type}</p>
										</td>
										<td>
											<p className="card-text">{data.platform_type}</p>
										</td>
										<td>
											<p id="title" className="card-text">{data.title}</p>
										</td>
										<td>
											<p className="card-text">{data.corp_name}</p>
										</td>
										<td>
											<p className="card-text">{data.user_name}</p>
										</td>
										<td>
											<p className="card-text" style={{width:"180px", textOverflow: "ellipsis", overflow:"hidden"}}>{data.email}</p>
										</td>
										<td>
											<p className="card-text">{data.tel_no}</p>
										</td>
										<td>
											<p className="card-text" style={{width:"120px", textOverflow: "ellipsis", overflow:"hidden"}}>{data.site_url}</p>
										</td>
										<td>
											<p className="card-text">{data.reg_date}</p>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					<Pagination
						activePage={this.state.activePage}
						itemsCountPerPage={this.state.rowcnt}
						totalItemsCount={this.state.totalCnt}
						pageRangeDisplayed={5}
						onChange={this.handlePageChange.bind(this)}
					/>
				</div>
			</div>
		);
	}
}

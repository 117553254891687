import React from 'react';
import http from '../modules/api';
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";
import FileDownloadComponent from "../modules/FileDownloadComponent";
import $ from 'jquery';

import "react-datepicker/dist/react-datepicker.css";

export class Cafe24OperatorAccountManager extends React.Component {
	
	state = {
			list: [],
			login_log_list: [],
			orderGubun: "",
			sorting: "",
			currentPage: "",
			start_date: "",
			end_date: "",
			start_date_format: "",
			end_date_format: "",
			title: "",
			roles: "",
			role_cls: "",
			activePage: 1,
			totalCnt: 0,
			startIdx: 0,
			login_log_activePage: 1,
			login_log_totalCnt: 0,
			login_log_startIdx: 0,
			rowcnt:10,
			login_log_rowcnt:10,
			options:[]
	}
	
	componentDidMount() {
		$("#modalBack").hide(); // 모달 배경 숨김
		$("#modal_login_log").hide(); // 모달 숨김
		if (localStorage.getItem ('state') != null){
			new Promise( ( resolve, reject ) => {setTimeout( resolve, 200 ); })
            .then( () => {
				var json = JSON.parse(localStorage.getItem ('state'));
				json.list = [];
				json.start_date = Date.parse(json.start_date_format);
				json.end_date = Date.parse(json.end_date_format);
				this.setState(json);
    			this.state.startIdx = parseInt(localStorage.getItem("startIdx"));
    			localStorage.clear();
    			
    			this.searchOperatorAccount();
        		//this.getCode("2200");		//권한코드조회
            });
		} else {
			this.searchOperatorAccount();
    		//this.getCode("2200");		//권한코드조회
		}

		$(".modalClose").click(function() {
            $("#modalBack").hide();
            $("#modal_login_log").hide();
            $("body").css("background-color", "white");
            $("#modalBack").css("background-color", "white");
            $("#page-wrapper").css("background-color", "white");
        });
	}

	componentWillUnmount () {
		$("#modalBack").hide();
		$("#modal_login_log").hide();
		$("body").css("background-color", "white");
		$("#modalBack").css("background-color", "white");
		$("#page-wrapper").css("background-color", "white");
	}
	
	//공통코드 조회
	// async getCode(cd_class) {
	//     const res = await http.get("/rest/common/roboMdCode?cd_class="+cd_class);
	//     this.setState({options:res.data});
    // };
    
    // 1. 내 계정의 권한 조회
    // 2. 내 계정의 쇼핑몰 조회
	// 3. 부운영자 계정정보 조회
	async searchOperatorAccount() {
        await http.get("/rest/shop/cafe24/search/operatorAccount?start_date="+this.state.start_date_format
        											 +"&end_date="+this.state.end_date_format
        											 +"&title="+this.state.title
        											 +"&roles="+this.state.roles
        											 +"&role_cls="+this.state.role_cls
        											 +"&order_type="+this.state.sorting
        											 +"&order_gubun="+this.state.orderGubun
        											 +"&startidx="+this.state.startIdx
        											 +"&rowcnt="+this.state.rowcnt)
        		   .then(res => {
        			   		this.setState( {list : res.data["list"]} );
    			   			this.setState( {totalCnt : res.data["totalCnt"]} );
        		   })
        		   .catch(error => {
                            alert(error+"\n관리자에게 문의해 주세요.");
        		   });
    }

	//2. 사용자 상세 조회 이벤트
	handleClick = (user_id) => {
        console.log(user_id)
		this.props.history.push(`/shop/cafe24/operatorFind/${user_id}`);
		localStorage.setItem ( 'state' , JSON.stringify(this.state) ) ;
		// localStorage.setItem ( 'activePage' , this.state.activePage ) ;
		localStorage.setItem ( 'startIdx' , this.state.startIdx ) ;
    };
    
	// 정렬
	onClickSorting = (e) => {
		console.log(e);
		console.log(e.target.className);
		if (this.state.orderGubun == e.target.className.replace(" sorting","")) {
			if (this.state.sorting == "ASC")
			this.state.sorting = "DESC";
			else
			this.state.sorting = "ASC";
		} else {
			this.state.sorting = "DESC";
		}
		this.state.orderGubun = e.target.className.replace(" sorting","");
		this.state.currentPage = 1;
		this.searchOperatorAccount();
	};
	//3. 사용자 조회 버튼 이벤트
	onClickSearchListButton = () => {
		this.state.startIdx = 0;
		this.state.activePage = 1;
		this.searchOperatorAccount();
	};
	//3. 운영자 계정생성 버튼
	onClickCreateButton = () => {
		this.state.startIdx = 0;
		this.state.activePage = 1;
		this.props.history.push(`/shop/cafe24/operatorCreate`);
	};

	//4. 달력 클릭
	handleChangeStartDate = date => {
		console.log(date);
		if (date != null) {
			this.setState({
				start_date_format: date.getFullYear() + "-"+ ("0" + parseInt(date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(date.getDate())).slice(-2),
				start_date: date
			});
		} else {
			this.setState({
				start_date_format: date,
				start_date: date
			});
		}
	};
	handleChangeEndDate = date => {
		console.log(date);
		if (date != null) {
			this.setState({
				end_date_format: date.getFullYear() + "-"+ ("0" + parseInt(date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(date.getDate())).slice(-2),
				end_date: date
			});
		} else {
			this.setState({
				end_date_format: date,
				end_date: date
			});
		}
	};
	//5. 엔터키 검색
	_handleKeyDown = e => {
		if (e.key === 'Enter') {
			this.state.startIdx = 0;
			this.state.activePage = 1;
			this.searchOperatorAccount();
		}
	}
	
	//검색 권한 변경 이벤트
	_handleChange = (e, key) => {
	    // computed property
	    this.setState({[key]: e.target.value});
	};
	
	//6.페이징
	handlePageChange(pageNumber) {
		// console.log(`active page is ${pageNumber}`);
		this.setState({activePage: pageNumber});
		this.state.startIdx = (pageNumber * 10) - this.state.rowcnt;
		// console.log("this.state.startIdx :" + this.state.startIdx )
		this.searchOperatorAccount()
    }

	handleLoginLogPageChange(pageNumber) {
		this.setState({login_log_activePage: pageNumber});
		this.state.login_log_startIdx = (pageNumber * 10) - this.state.login_log_rowcnt;
		this.getLoginLog(this.state.login_log_user_seq)
	}  

	onClickLoginCnt = user_seq => {
		this.state.login_log_activePage = 1;
		this.state.login_log_startIdx = 0;
		this.setState({login_log_user_seq: user_seq})
		this.getLoginLog(user_seq);
		$("#modalBack").show();
        $("#modal_login_log").show();
        $("body").css("background-color", "#a6a6a6");
        $("#modalBack").css("background-color", "#a6a6a6");
        $("#page-wrapper").css("background-color", "#a6a6a6");
	}

	async getLoginLog(user_seq) {
				await http.get("/rest/admin/adminUserLoginLog?user_seq="+user_seq
															 +"&startidx="+this.state.login_log_startIdx
															 +"&rowcnt="+this.state.login_log_rowcnt)
						   .then(res => {
									   this.setState( {login_log_list : res.data["list"], login_log_totalCnt : res.data["totalCnt"]} );
									   // console.log(this.state.login_log_totalCnt)
									   // console.log(this.state.activePage)
									   // console.log(this.state.startIdx)
						   })
						   .catch(error => {
									   // console.log (error);
						   });
				
			}

	
	render() {
		
		return (
			<div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
				<div id="notice_list_component">
					<h3>[Shop] 부)운영자 계정목록</h3>
					<br/>
					<div className="filter">
						<div style={{display:"flex", marginBottom: '-12px'}}>
							<div className="input-group m-b" style={{width:"66%"}}>
								<div style={{width: "100%"}}> 
								<label>날짜</label>
									<div className="input-daterange input-group" id="datepicker">
										<DatePicker name="start_date" id="start_date"
											selected={this.state.start_date}
											onSelect={this.handleSelect} //when day is clicked
											onChange={this.handleChangeStartDate} //only when value has changed
											// showTimeSelect
											dateFormat="yyyy-MM-dd"
											autocomplete='off'
										/>
										<div className="input-group-addon" style={{width: "40px",height:"35px", lineHeight: "34px",textAlign:"center"}}>to</div>
										<DatePicker name="end_date" id="end_date"
											selected={this.state.end_date}
											onSelect={this.handleSelect} //when day is clicked
											onChange={this.handleChangeEndDate} //only when value has changed
											// showTimeSelect
											dateFormat="yyyy-MM-dd"
											autocomplete='off'
										/>	
									</div>
								</div>
							</div>
							<div className="input-group m-b" style={{width:"33%", marginLeft:"10px"}}>
								<label>사용자 구분</label>
								<select id="roles" style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px", marginBottom:"10px"}}
										value={this.state.roles} onChange={(e)=>this._handleChange(e, 'roles')} onKeyDown={this._handleKeyDown}>
									<option value="">전체</option>
									<option value="none">None</option>
									<option value="shop">Shop</option>
									<option value="admin">Admin</option>
								</select>
							</div>
							<div className="input-group m-b" style={{width:"33%", marginLeft:"10px"}}>
								<label>권한 구분</label>
								<select id="role_cls" style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px", marginBottom:"10px"}}
										value={this.state.role_cls} onChange={(e)=>this._handleChange(e, 'role_cls')} onKeyDown={this._handleKeyDown}>
									<option value="">전체</option>
									{this.state.options.map((option, index) => <option value={option.cd_code}>{option.cd_desc}</option>)}
								</select>
							</div>
						</div>
						<div style={{display:"flex", marginBottom: '12px'}}>
							<input type="text" onChange={(e) => {
								// console.log(e.target.value)
								this.setState({ title: e.target.value })
							}} placeholder="검색어를 입력하세요." style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px"}} onKeyDown={this._handleKeyDown} value={this.state.title}></input>
							<button className = "greenButton m-r-xs" onClick={this.onClickSearchListButton} style={{width: "45px",height: "35px"}}>검색</button>
							<button className = "greenButton m-r-xs" onClick={this.onClickCreateButton} style={{width: "45px",height: "35px"}}>등록</button>
							<FileDownloadComponent url={"/rest/admin/adminUserFindAll/excel?"+
																					"&start_date="+this.state.start_date_format
																					+"&end_date="+this.state.end_date_format
																					+"&title="+this.state.title
																					+"&roles="+this.state.roles
																					+"&role_cls="+this.state.role_cls
																					+"&order_type="+this.state.sorting
																					+"&order_gubun="+this.state.orderGubun
																					}
														   obj=""
														   val1="none" />
						</div>
					</div>
					
					<div className="table">
						<table>
							<colgroup>
								<col width="80px" />
								<col width="130px" />
								<col width="300px" />
								<col width="80px" />
								<col width="80px" />
								<col width="80px" />
								<col width="120px" />
								<col width="120px" />
								<col width="120px" />
								<col width="80px" />
							</colgroup>
							<thead>
								<tr>
				        			<td className="user_seq sorting" onClick={this.onClickSorting}>
				        				<p className="card-text">사용자 순번</p>
				        			</td>
				        			<td className="user_id sorting" onClick={this.onClickSorting}>
				        				<p className="card-text" style={{width:"95%"}}>사용자 ID</p>
				        			</td>
				        			<td className="corp_name sorting" onClick={this.onClickSorting}>
				        				<p className="card-text" style={{width:"95%"}}>소속명</p>
				        			</td>
				        			<td className="user_name sorting" onClick={this.onClickSorting}>
				        				<p className="card-text">담당자명</p>
				        			</td>
				        			<td className="user_join_cls sorting" onClick={this.onClickSorting}>
				        				<p className="card-text">가입 구분</p>
				        			</td>
				        			<td className="role_cls sorting" onClick={this.onClickSorting}>
				        				<p className="card-text">권한 구분</p>
				        			</td>
				        			<td className="reg_date sorting" onClick={this.onClickSorting}>
				        				<p className="card-text">가입일자</p>
				        			</td>
				        			<td className="mod_date sorting" onClick={this.onClickSorting}>
				        				<p className="card-text">수정일자</p>
				        			</td>
				        			<td className="last_login_date sorting" onClick={this.onClickSorting}>
				        				<p className="card-text">마지막 로그인일자</p>
				        			</td>
				        			<td className="login_cnt sorting" onClick={this.onClickSorting}>
				        				<p className="card-text">로그인 횟수</p>
				        			</td>
				        		</tr>
			        		</thead>
			        		<tbody>
							{
								this.state.list != null ? this.state.list.map(user => (
		                		<tr>
		                			<td>
		                				<p className="card-text">{user.user_seq}</p>
		                			</td>
		                			<td>
		                				<div className="cursor-pointer" onClick={() => this.handleClick(user.user_id)}>
							            	<p className="card-text">{user.user_id}</p>
							            </div>
		                			</td>
		                			<td>
		                				<div className="cursor-pointer" onClick={() => this.handleClick(user.user_id)}>
		                					<p className="card-text">{user.corp_name}</p>
		                				</div>
		                			</td>
		                			<td>
		                				<p className="card-text">{user.user_name}</p>
		                			</td>
		                			<td>
		                				<p className="card-text">{user.user_join_cls}</p>
		                			</td>
		                			<td>
		                				<p className="card-text">{user.role_cls}</p>
		                			</td>
		                			<td>
		                				<p className="card-text">{user.reg_date}</p>
		                			</td>
		                			<td>
		                				<p className="card-text">{user.mod_date}</p>
		                			</td>
		                			<td>
		                				<p className="card-text">{user.last_login_date}</p>
		                			</td>
		                			<td>
		                				{
											user.login_cnt > 0 
											?	<p className="card-text cursor-pointer" style={{color:"blue"}} onClick={() => this.onClickLoginCnt(user.user_seq)}>{user.login_cnt}</p>
											:	<p className="card-text">{user.login_cnt}</p>
										}
		                			</td>
		                		</tr>
								))
								:
									<tr>
										<td colSpan="10">
											<p className="card-text">조회된 데이터가 없습니다.</p>
										</td>
									</tr>
							}
							</tbody>
						</table>
					</div>
					<Pagination
						activePage={this.state.activePage}
						itemsCountPerPage={this.state.rowcnt}
						totalItemsCount={this.state.totalCnt}
						pageRangeDisplayed={5}
						onChange={this.handlePageChange.bind(this)}
					/>
		        </div>

				{/* 로그인 로그 팝업 */}
                <div id="modal_login_log">
                    <div id="modalContent" className="shadow-box">
                            <h3>로그인 이력</h3>
                            <br/><br/>

                            {/* <div style={{display:"flex", marginBottom: '12px'}}>
							<input type="text" onChange={(e) => {
								// console.log(e.target.value)
								this.setState({ product_name: e.target.value })
							}} placeholder="검색어를 입력하세요." style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px"}} onKeyDown={this._handleKeyDown}></input>
							<button className = "greenButton" onClick={this.onClickSearchItem} style={{width: "45px",height: "35px", margin: 'auto'}}>검색</button>
						    </div> */}

                            <div className="table item_modal_table">
                            <table>
                                <colgroup>
                                    <col width="25%" />
                                    <col width="25%" />
                                    <col width="25%" />
                                    <col width="25%" />
                                </colgroup>
                                <thead>	
                                    <tr>
                                        <td>
                                            <p className="card-text">로그인 날짜</p>
                                        </td>
                                        <td>
                                            <p className="card-text">브라우저</p>
                                        </td>
                                        <td>
                                            <p className="card-text">운영체제</p>
                                        </td>
                                        <td className="user_name">
                                            <p className="card-text">디바이스</p>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody style={{display:"block", width:"100%",  height:"calc(57vh - 140px)", overflow:"scroll", marginBottom:"20px"}}>
                                    {this.state.login_log_list.map((item, idx) => (
                                        <tr key={item.idx} id = {item.idx}>
                                            <td>
                                                <p className="card-text">{item.reg_date}</p>
                                            </td>
                                            <td>
                                                <p className="card-text">{item.browser}</p>
                                            </td>
                                            <td>
												<p className="card-text">{item.os}</p>
                                            </td>
                                            <td>
                                                <p className="card-text">{item.device}</p>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            activePage={this.state.login_log_activePage}
                            itemsCountPerPage={this.state.login_log_rowcnt}
                            totalItemsCount={this.state.login_log_totalCnt}
                            pageRangeDisplayed={5}
                            onChange={this.handleLoginLogPageChange.bind(this)}
                        />
                    </div>
                    <div className="fa fa-close modalClose"></div>
                </div>

				{/* 팝업창 배경 */}
                <div id="modalBack" style={{top: "0", height:"130%"}}></div>    
			</div>
		);
	}
	
}

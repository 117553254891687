import React from 'react';
import { Redirect } from 'react-router-dom'

export class ShopHome extends React.Component {
	
	render() {
		return (
				<Redirect to='/shop/main' />
		);
	}
	
}

import React from 'react';
import '../../css/notice_style.css';
import $ from 'jquery';
import axios from 'axios';
import http from '../modules/api';

import CKEditor from 'ckeditor4-react';
// import ClassicEditor from '@ckeditor/ckeditor4-build-classic';

export class NoticeForm extends React.Component {
	constructor(props) {
		super(props);
	    this.state = {
			notice_seq: null,
			recuFile: '',
			files: null,
			ck_content: '',
			title: "",
			notice: {title:'', content:''}
		}
	}
	  
	componentDidMount() {
		// uri 파라메터 얻기: this.props.match.params
		const {notice_seq} = this.props.match.params;
		this.state.notice_seq = notice_seq;
	    console.log("### 1. componentDidMount" + notice_seq);
		// this.getNotice(notice_seq);
		console.log("### content: "+this.state.notice.content);

		if (notice_seq != null) {
			$("#btnDel").show();
		} else {
			$("#btnDel").hide();
		}
	}

	getNotice(id) {
		// new Promise( ( resolve, reject ) => {setTimeout( resolve, 200 ); })
		// .then( () => {
			http.get(`/rest/shop/notice/${id}`).then(res => {
				console.log(res.data);
				this.setState({notice: res.data});
				// this.recuFile = res.data["file_name"];
				$("#attachFileName").text(this.state.notice["file_name"]);
				// this.setState({recuFile: res.data["file_name"]});
				console.log("### content: "+this.state.notice.content);
				this.setState({ck_content: this.state.notice.content});
				this.setState({title: this.state.notice.title});

				if(this.state.notice.content == null) {
					$("#btnDel").hide();
				}
			})
			.catch(error => {
				// console.log (error);
			 });
		// } );
	}

	handleChangeRecuFile = e => { 
		var target = e.target;
		this.setState({recuFile: target.value});
		console.log(target.files[0]);
		this.setState( {files: target.files[0]});
		if (target.files != null && target.files[0] != null) {
			var reader = new FileReader();
			reader.onload = function (e) {
				$("#attachFileName").text(target.files[0].name);
			}
			reader.readAsDataURL(target.files[0]);
		} else {
			$("#attachFileName").text("");
		}
	};

	handleClickBtnFileUpload  = e => { 
		e.preventDefault ();
		$("#recuFile").click();	
	};

	handleClickBtnSave  = e => { 
		$("#noticeForm").submit();
	};

	onFormSubmit = e => {
		e.preventDefault (); // Stop form submit
		console.log(this.state.files);
		if (this.state.files != null) { 
			var saveNotiFileUpload = this.saveNotiFileUpload(this.state.files)
			if (saveNotiFileUpload != null) {
				saveNotiFileUpload.then((response)=>{
					if(typeof response.data === 'string') {
						if(response.data.indexOf("<script>") >= 0){
							alert("로그인 후 이용해 주세요.")
							window.location.href = "/login";
						}
					}

					if (response.data["result"] == "true") {
						alert("게시물이 등록 되었습니다.");
						if(this.state.notice_seq == null) {
							localStorage.clear();
						}
						if (document.referrer.indexOf("/admin/notice/list") > 0){
							this.props.history.goBack();
						} else {
							this.props.history.push(`/admin/notice/list`);
						}
					}
					console.log(response.data);
				})
				.catch(error => { // your error handling goes here
					console.log(error);
				})
			}
		} else {
			var saveNoti = this.saveNoti()
			if (saveNoti != null) {
				saveNoti.then((response)=>{
					if(typeof response.data === 'string') {
						if(response.data.indexOf("<script>") >= 0){
							alert("로그인 후 이용해 주세요.")
							window.location.href = "/login";
						}
					}

					if (response.data["result"] == "true") {
						alert("게시물이 등록 되었습니다.");
						if(this.state.notice_seq == null) {
							localStorage.clear();
						}
						if (document.referrer.indexOf("/admin/notice/list") > 0){
							this.props.history.goBack();
						} else {
							this.props.history.push(`/admin/notice/list`);
						}
					}
					console.log(response.data);
				})
				.catch(error => { // your error handling goes here
					console.log(error);
				})
			}
		}
	}

	saveNotiFileUpload(file){
		if (this.state.title == null || this.state.title == "") {
			alert("제목을 입력하여주세요.");
		} else {
			const url = '/rest/admin/save/file/notice';
			const formData = new FormData();
			formData.append('recuFile',file);
			formData.append('content',this.state.ck_content);
			formData.append('title',this.state.title);
			formData.append('notice_seq',this.state.notice_seq);
			const config = {
				headers: {
					'content-type': 'application/x-www-form-urlencoded; charset=utf-8'
				}
			}
			return  http.post(url, formData,config);
		}
	}  
	
	saveNoti(){
		if (this.state.title == null || this.state.title == "") {
			alert("제목을 입력하여주세요.");
		} else {
			const url = '/rest/admin/save/notice';
			const formData = new FormData();
			formData.append('content',this.state.ck_content);
			formData.append('title',this.state.title);
			formData.append('notice_seq',this.state.notice_seq);
			// const config = {
			// 	headers: {
			// 		'content-type': 'application/x-www-form-urlencoded; charset=utf-8'
			// 	}
			// }
			return http.post(url, formData);
		}
	}
	
	delNotice(id) {
		http.post(`/rest/admin/del/notice/`+id)
		.then(res => {
			// console.log(res.data)
			if (res.data != null && res.data["result"] == "true") {
				alert("게시물이 삭제 되었습니다.")
				localStorage.clear();
				this.props.history.goBack();
			}
		})
		.catch(error => {
			// console.log (error);
		});
	}

	onClickDelNotice = e => {
		this.delNotice(this.state.notice_seq);
	}

	render() {
		return (
			<div id='page-wrapper' className="container notice_form_container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
				<h3>[Admin] 공지사항</h3>
				<br/>
				<div>
					<input type="text" onChange={(e) => {
						this.state.title = e.target.value
						console.log(this.state.title)
						this.setState({ title: this.state.title })
					}} placeholder="제목을 입력하세요." style={{width:"100%", border:"1px solid #c4c4c4", padding:"10px", marginBottom:"10px"}} defaultValue={this.state.notice.title || ''}>
					</input>
				</div>

				<div className="App" style={{marginBottom:"10px"}}>
					<CKEditor 
						data={this.state.ck_content}
						onInstanceReady={ () => {
							this.getNotice(this.state.notice_seq);
						}}
						onChange={ event => {
							this.setState({ ck_content: event.editor.getData() });
							console.log(this.state.ck_content );
						}}
						config={{
							filebrowserBrowseUrl: "https://robomd.io/rest/shop/notice/uplaodImageFromEditor",
							filebrowserUploadUrl: "https://robomd.io/rest/shop/notice/uplaodImageFromEditor"
						}}
					/>
				</div>
				
				<form onSubmit={this.onFormSubmit} id="noticeForm">
					<div className="fileUp">
						<button id="btnFileUpload" onClick={this.handleClickBtnFileUpload}>파일첨부</button>
						<input type="file" id="recuFile" name="recuFile" className="form-control" onChange={this.handleChangeRecuFile} value={this.state.recuFile} style={{display:'none'}}/>
						<div id="attachFileName" className='fileName'></div>
					</div>
					<div className="flexBox" style={{alignItems: "center", justifyContent: "center"}}>
						<div style={{textAlign:"center"}}>
							<button type="submit" id="btnSave" className="greenButton">
								저장
							</button>
						</div>
						<div id="btnDel" style={{textAlign:"center", marginLeft:"10px", cursor:"pointer"}}>
							<div className="greenButton" onClick={this.onClickDelNotice}>
								삭제
							</div>
						</div>
					</div>
				</form>
			</div>
		);
	}

	componentWillReceiveProps(nextProps, nextContext) {
		console.log("### 2. componentWillReceiveProps");
		console.log(nextProps);
		this.getNotice(nextProps.match.params.notice_seq);
	}
	
}

import React, { useCallback, useState } from 'react';
import http from '../modules/api';
import $ from 'jquery';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ShareLink from 'react-facebook-share-link'
// import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";

import icnFacebook from '../../resources/img/icn_sns/Facebook.svg'
import icnInstagram from '../../resources/img/icn_sns/Instagram.svg'
import icnKakaotalk from '../../resources/img/icn_sns/kakaotalk.svg'
import icnNaverBlog from '../../resources/img/icn_sns/naver_blog.svg'
import * as StringUtil from '../modules/utils/StringUtil';
import * as CurationServiceVariable from '../modules/CurationServiceVariable';

var object_domain = "https://"+document.domain
var object_port = window.location.port;
if (document.domain.indexOf("127.0.0.1") >= 0 && object_port == "3000") {
    object_domain = "http://127.0.0.1:8092"
} else if (object_port != '') {
    object_domain = object_domain + ":" + object_port
}
var rp_script_url = object_domain+"/js/robomd_product_rolling_for_curation.js"

var share_domain = "https://"+document.domain
var share_port = window.location.port;
if (document.domain.indexOf("127.0.0.1") >= 0) {
    share_domain = "http://"+document.domain + ":" + share_port
} 

export class UserCurationResult extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
            result_uid : this.props.match.params["result_uid"],
            channel_cd : this.props.match.params["channel_cd"],

            /* 사용자 */
            cookie_robotmd_user : StringUtil.getUrlParams("cookie_robotmd_user"),
            member_id : StringUtil.getUrlParams("member_id"),
            guest_id : StringUtil.getUrlParams("guest_id"),
            user_type : StringUtil.getUrlParams("cookie_robotmd_user") != null && StringUtil.getUrlParams("cookie_robotmd_user") != "" ? "Y" : "N",
            
            is_open_share_popup: false,

            curation_result: {},

            chnnel_code_list: [],
        }
    }

    componentDidMount() {
        window.ChannelIO('hideChannelButton'); // 채널톡 버튼 숨김
        window.ChannelIO('onBoot', function() { // 새로고침 시 채널톡을 재로딩 한다. 로딩 완료 체크 후 숨김 처리
            window.ChannelIO('hideChannelButton'); // 채널톡 버튼 숨김
        });

        const {result_uid} = this.props.match.params;
		this.setState({ result_uid: result_uid });
        const {channel_cd} = this.props.match.params;
		this.setState({ channel_cd: channel_cd });
        if (channel_cd != null) {
            // 큐레이션 공유 내역 저장  (/rest/curation/share)
            this.saveShareLog(result_uid, channel_cd)
        }

        this.loadCurationResult();

        $(document).ready(function(){
            var quration_user_result = $("#quration_user_result") // 큐레이션 고객 결과
        
            // 큐레이션 고객 결과 - 공유하기 버튼 클릭
            quration_user_result.find(".quration_user_result_share_btn").on("click",function(){
                quration_user_result.next(".quration_popup").css("display","flex");
                quration_user_result.next(".quration_popup").find(".quration_popup_layer").css("display","flex");
                return;
            });
        
            // 큐레이션 고객 결과 - 레이어팝업 "X"버튼 클릭
            quration_user_result.next(".quration_popup").find(".quration_popup_x_btn").on("click",function(){
                quration_user_result.next(".quration_popup").css("display","none");
                quration_user_result.next(".quration_popup").find(".quration_popup_layer").css("display","none");
                return;
            });
        });
    }

    componentDidUpdate() {
        
    }

	//공통코드 조회
	async getChannelCode() {
	    const res = await http.get("/rest/common/roboMdCode?cd_class=CH010");
	    this.setState({chnnel_code_list:res.data});
	};

    // 큐레이션 결과 조회
	async loadCurationResult() {
	    await http.get("/rest/common/curation/result/"+this.state.result_uid)
		.then(res => {
            let curation_result = res.data["result"];
            if (res.data.code == 200 && res.data["result"] != null) {
                this.setState({
                    curation_result: res.data["result"],
                })
                if (curation_result != null && curation_result["user_seq"] != null && curation_result["shop_no"] != null) {

                    let rp_script_parameter = "?user_seq="+curation_result["user_seq"]
                                        +"&shop_no="+curation_result["shop_no"]
                                        +"&rp_page_id="+curation_result["rp_iframe_page_id"]
                                        +"&result_uid="+this.state.result_uid
                                        +"&user_type="+this.state.user_type;
                    var s = document.createElement("script");
                    s.type = "text/javascript";
                    s.src = rp_script_url+rp_script_parameter
                    document.head.appendChild(s);
                }
            } else {
                alert("큐레이션 결과가 존재하지 않습니다.")
                window.location.href = "/common/curation/list/"+(StringUtil.getUrlParams("service_uid") != null ? StringUtil.getUrlParams("service_uid")  : this.props.match.params["service_uid"] != null ? this.props.match.params["service_uid"] : "")
                                        +"?cookie_robotmd_user="+(StringUtil.getUrlParams("cookie_robotmd_user") != null ? StringUtil.getUrlParams("cookie_robotmd_user")  : "")
                                        +"&member_id="+(StringUtil.getUrlParams("member_id") != null ? StringUtil.getUrlParams("member_id")  : "")
                                        +"&guest_id="+(StringUtil.getUrlParams("guest_id") != null ? StringUtil.getUrlParams("guest_id")  : "")
            }
		})
		.catch(error => {
            console.log("큐레이션 결과 조회 오류", error)
            alert("큐레이션 결과가 존재하지 않습니다.")
            window.location.href = "/common/curation/list/"+(StringUtil.getUrlParams("service_uid") != null ? StringUtil.getUrlParams("service_uid")  : this.props.match.params["service_uid"] != null ? this.props.match.params["service_uid"] : "")
                                    +"?cookie_robotmd_user="+(StringUtil.getUrlParams("cookie_robotmd_user") != null ? StringUtil.getUrlParams("cookie_robotmd_user")  : "")
                                    +"&member_id="+(StringUtil.getUrlParams("member_id") != null ? StringUtil.getUrlParams("member_id")  : "")
                                    +"&guest_id="+(StringUtil.getUrlParams("guest_id") != null ? StringUtil.getUrlParams("guest_id")  : "")
		 });
    }

    // 큐레이션 공유 내역 저장
	async saveShareLog(result_uid, channel_cd) {
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        }
        const body = {
            result_uid: result_uid,
            channel_cd: channel_cd
        }
	    await http.post("/rest/common/curation/share", body, config)
		.then(res => {
			if (res.data.code == "200") {
                console.log("큐레이션 공유 내역 저장 완료")
            } else{
                console.log("큐레이션 공유 내역 저장 실패")
            }
		})
		.catch(error => {
            console.log("큐레이션 공유 내역 저장 오류", error)
		 });
    }

    onOpenSharePopup(e) {
        this.setState({
            is_open_share_popup: true,
        }) 
    }

    onCloseSharePopup(e) {
        this.setState({
            is_open_share_popup: false,
        }) 
    }

    render() {
        return (
            <>
                {/* 메타태그를 수정해도 facebook에서는 캐치 하지 못함. => index.html의 값을 가져감 */}
                <Helmet>
                    <meta property="og:title" content={
                        this.state.curation_result != null && this.state.curation_result["result_title"] != null
                        ?   this.state.curation_result["result_title"]
                        :   "로보MD | iclave"
                    } />
                    <meta property="og:description" content={
                        this.state.curation_result != null && this.state.curation_result["result_desc"] != null
                        ?   this.state.curation_result["result_desc"]
                        :   "MD를 도와주는 똑똑한 인공지능, 로보MD"
                    } />
                    <meta property="og:image" content={
                        this.state.curation_result != null && this.state.curation_result["file_path"] != null
                        ? CurationServiceVariable.FILE_DOMAIN + this.state.curation_result["file_path"]
                        : "https://s3.ap-northeast-2.amazonaws.com/otdeal.file/robomd_og_image_v2.jpg"
                    } />
                </Helmet>
               <div id="wrap" style={{paddingTop:"60px"}}>
                    <section class="quration_user_result style_guide_v2" id="quration_user_result">
                        <div class="water_mark">Powered by RoboMD</div>
                        <div class="quration_user_result_content_wrap">
                            <div class="quration_user_result_content">
                                {
                                    this.state.curation_result != null && this.state.curation_result["file_path"] != null
                                    ?   <img src={CurationServiceVariable.FILE_DOMAIN + this.state.curation_result["file_path"]}/>
                                    :   ""
                                }
                                {
                                    this.state.curation_result != null && this.state.curation_result["result_title"] != null
                                    ?   <h2 dangerouslySetInnerHTML={{__html: this.state.curation_result["result_title"].replaceAll("\n", "<br/>")}}></h2>
                                    :   ""
                                }
                                {
                                    this.state.curation_result != null && this.state.curation_result["result_desc"] != null
                                    ?   <h3 dangerouslySetInnerHTML={{__html: this.state.curation_result["result_desc"].replaceAll("\n", "<br/>")}}></h3>
                                    :   ""
                                }
                                <button type="button" class="quration_user_q_result_btn" onClick={e => this.onOpenSharePopup(e)}>공유하기</button>
                            </div>
                            {/* <!-- 진열 부분 --> */}
                            {/* <div class="quration_user_result_list">
                                <div class="quration_user_result_list_box">
                                    <img src="http://drive.google.com/uc?export=view&id=1V9bqQ1eBP3_M98JvKCnu2BWOYsU6urSx"/>
                                    <h2><span>상품 제목을 입력하세요 상품 제목을 입력하세요 상품 제목을 입력하세요 상품 제목을 입력하세요 상품 제목을 입력하세요 상품 제목을 입력하세요 상품 제목을 입력하세요 상품 제목을 입력하세요</span></h2>
                                    <div class="quration_user_result_list_price">
                                        <h3>999,999원</h3>
                                        <h4>999,999원</h4>
                                    </div>
                                </div>
                                <div class="quration_user_result_list_box">
                                    <img src="http://drive.google.com/uc?export=view&id=1V9bqQ1eBP3_M98JvKCnu2BWOYsU6urSx"/>
                                    <h2><span>상품 제목을 입력하세요 상품 제목을 입력하세요</span></h2>
                                    <div class="quration_user_result_list_price">
                                        <h3>999,999원</h3>
                                        <h4>999,999원</h4>
                                    </div>
                                </div>
                                <div class="quration_user_result_list_box">
                                    <img src="http://drive.google.com/uc?export=view&id=1V9bqQ1eBP3_M98JvKCnu2BWOYsU6urSx"/>
                                    <h2><span>상품 제목을 입력하세요</span></h2>
                                    <div class="quration_user_result_list_price">
                                        <h3>999,999원</h3>
                                        <h4>999,999원</h4>
                                    </div>
                                </div>
                                <div class="quration_user_result_list_box">
                                    <img src="http://drive.google.com/uc?export=view&id=1V9bqQ1eBP3_M98JvKCnu2BWOYsU6urSx"/>
                                    <h2><span>상품 제목을 입력하세요 상품 제목을 입력하세요 상품 제목을 입력하세요 상품 제목을 입력하세요 상품 제목을 입력하세요 상품 제목을 입력하세요</span></h2>
                                    <div class="quration_user_result_list_price">
                                        <h3>999,999원</h3>
                                        <h4>999,999원</h4>
                                    </div>
                                </div>
                                <div class="quration_user_result_list_box">
                                    <img src="http://drive.google.com/uc?export=view&id=1V9bqQ1eBP3_M98JvKCnu2BWOYsU6urSx"/>
                                    <h2><span>상품 제목을 입력하세요 상품 제목을 입력하세요 상품 제목을 입력하세요 상품 제목을 입력하세요 상품 제목을 입력하세요 상품 제목을 입력하세요</span></h2>
                                    <div class="quration_user_result_list_price">
                                        <h3>999,999원</h3>
                                        <h4>999,999원</h4>
                                    </div>
                                </div>
                            </div> */}
                            {/* <!-- 진열 끝나는 부분 --> */}
                            <div id={this.state.curation_result != null && this.state.curation_result["rp_iframe_html_id"] != null ? this.state.curation_result["rp_iframe_html_id"] : ""} class="quration_user_result_list" style={{position: "relative", width: "100%", height: "0px", paddingBottom: "0px", display:'flex', justifyContent:"center"}}></div>
                        </div>
                    </section>
                    {/* <!-- 레이어팝업 --> */}
                    {
                        this.state.is_open_share_popup
                        ?
                            <div class="quration_popup">
                                <div class="quration_popup_layer quration_popup_layer_single">
                                    <div class="quration_popup_share_title">
                                        <h2 class="text_16">공유하기</h2>
                                        <svg class="quration_popup_x_btn" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"  onClick={e => this.onCloseSharePopup(e)}>
                                            <path d="M16.0704 15.1788C16.1878 15.2973 16.2536 15.4573 16.2536 15.6241C16.2536 15.7909 16.1878 15.9509 16.0704 16.0694C15.9509 16.1849 15.7913 16.2495 15.6251 16.2495C15.4589 16.2495 15.2992 16.1849 15.1798 16.0694L10.0001 10.8819L4.82041 16.0694C4.70095 16.1849 4.54127 16.2495 4.3751 16.2495C4.20892 16.2495 4.04925 16.1849 3.92979 16.0694C3.81242 15.9509 3.74658 15.7909 3.74658 15.6241C3.74658 15.4573 3.81242 15.2973 3.92979 15.1788L9.11728 9.99912L3.92979 4.81943C3.83012 4.698 3.77919 4.54383 3.78689 4.38693C3.7946 4.23002 3.8604 4.08159 3.97148 3.9705C4.08256 3.85942 4.231 3.79362 4.3879 3.78592C4.54481 3.77821 4.69898 3.82914 4.82041 3.92881L10.0001 9.11631L15.1798 3.92881C15.3012 3.82914 15.4554 3.77821 15.6123 3.78592C15.7692 3.79362 15.9176 3.85942 16.0287 3.9705C16.1398 4.08159 16.2056 4.23002 16.2133 4.38693C16.221 4.54383 16.1701 4.698 16.0704 4.81943L10.8829 9.99912L16.0704 15.1788Z" fill="#333333"></path>
                                        </svg>
                                    </div>
                                    <div class="quration_popup_share_link">
                                        <div class="quration_popup_share_sns">
                                            <ShareLink link={share_domain+"/common/curation/result/share/"+(StringUtil.getUrlParams("service_uid") != null ? StringUtil.getUrlParams("service_uid")  : this.props.match.params["service_uid"] != null ? this.props.match.params["service_uid"] : "")+"/"+this.state.result_uid+"/CH011FB"}>
                                                {link => (
                                                    <img src={icnFacebook}
                                                    style={{width:"40px", height:"auto", cursor:"pointer"}}
                                                    onClick={(e) => {
                                                        window.open(link, '_blank', 'width=800, height=600')
                                                    }} />
                                                )}
                                            </ShareLink>
                                            {/* <img src={icnInstagram} />
                                            <img src={icnKakaotalk} />
                                            <img src={icnNaverBlog} /> */}
                                        </div>
                                        <input type="text" value={share_domain+"/common/curation/result/share/"+(StringUtil.getUrlParams("service_uid") != null ? StringUtil.getUrlParams("service_uid")  : this.props.match.params["service_uid"] != null ? this.props.match.params["service_uid"] : "")+"/"+this.state.result_uid+"/CH018LINK"}/>
                                        <CopyToClipboard text={share_domain+"/common/curation/result/share/"+(StringUtil.getUrlParams("service_uid") != null ? StringUtil.getUrlParams("service_uid")  : this.props.match.params["service_uid"] != null ? this.props.match.params["service_uid"] : "")+"/"+this.state.result_uid+"/CH018LINK"}>
                                            <button type="button" class="link_copy_btn" onClick={() => {
                                            alert("복사하였습니다.");
                                            }}>링크 복사</button>
                                        </CopyToClipboard>
                                    </div>
                                </div>
                            </div>
                        :   ""
                    }
                </div>
            </>
        )
    }
}

export default UserCurationResult;
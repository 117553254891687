import React from 'react';
import axios from 'axios';
import http from '../modules/api';
import {Route, Switch, NavLink, Link, Prompt} from "react-router-dom";
import $ from 'jquery';

import * as Menu from '../Menu_v2';

import bannerType1 from "../../resources/img/event_typeA.png";
import bannerType2 from "../../resources/img/event_typeB.png";
import bannerType3 from "../../resources/img/event_typeC.png";
import bannerType4 from "../../resources/img/event_typeD.png";


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale"; // datePicker 한글화
import { SketchPicker } from 'react-color'
import Pagination from "react-js-pagination";
import ReactWaterMark from 'react-watermark-component';
import Draggable from 'react-draggable';
import RadioButton from '../modules/common/RadioButton';

import InputDialogV2 from '../modules/dialogs/InputDialogV2';
import Alert from '../modules/common/Alert';
import Button from '../modules/common/Button';
import * as StyleUtil from '../modules/utils/StyleUtil';

import ReactGA, { send } from 'react-ga';
import { CoverageMap } from 'istanbul-lib-coverage';

/** 셀렉트 박스 */
import Select from 'react-select';
import {reactSelectStyles} from '../modules/libraries/react-select';
import makeAnimated from 'react-select/animated';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import plus_reading_glasses from '../../resources/img/plus_reading_glasses.png';
import crown_icon from '../../resources/img/crown_icon.svg';
import curved_arrow_right from '../../resources/img/curved_arrow_right.svg';
import Tooltip from '../modules/common/Tooltip';
import TitleContentDialogV2 from '../modules/dialogs/TitleContentDialogV2';
ReactGA.initialize('UA-78553461-8');
const animatedComponents = makeAnimated();

let is_test_use = "";
if (document.domain.indexOf("127.0.0.1") >= 0 || document.domain.indexOf("otdeal.me") >= 0) {
    is_test_use = "&is_test_use=Y"
}

var is_banner_change = "true";
var is_mobile_bnner_change = "true";
var is_auto_sub_title_load = true;
var is_auto_title_load = true;
var is_change_date_setting=false;
var is_change_date_add_calendar=false;
var is_onclick_item = false;
var is_banner_ratio_loaded = true;
var is_first_load_html = true;

var fullCalendarInnerModal_width;
var fullCalendarInnerModal_pcbanner_width; 
var fullCalendarInnerModal_mobileBanner_width; 

var isEventPcMobile = false;
var calendar_event_seq = "";

var is_free_finish = false;
var is_order = false;

var window_scrollTop;

var ga_label = sessionStorage.getItem("userName") + "(" + sessionStorage.getItem("user_id") + ")";
var isTmp = true; // 배너 업테이트 할지 여부
var startIdxChange = false; // 상품 페이징 클릭시 true
  var object_domain = "https://"+document.domain
  var object_port = window.location.port;
  if (document.domain.indexOf("127.0.0.1") >= 0 && object_port == "3000") {
      object_domain = "http://127.0.0.1:8092"
  } else if (object_port != '') {
      object_domain = object_domain + ":" + object_port
  }
  var mirror_banner_url = object_domain+"/admin_banner.html";


var dateString = new Date().getFullYear() + ("0" + parseInt(new Date().getMonth()+1)).slice(-2) + ("0" + parseInt(new Date().getDate())).slice(-2) + ("0" + parseInt(new Date().getHours())).slice(-2) + ("0" + parseInt(new Date().getSeconds())).slice(-2)
export class EventForm_Promotion_V2 extends React.Component {
    state = {
        insert_type :  (this.getUrlParams()["event_seq"] != null && this.getUrlParams()["event_seq"] != "")? "Y" : "N",

        event : {},
        select_tmp_item_list : [],
        select_item_list : [],
        item_list : [],
        keyword_list : [],
        bannerTypeList : [{}],
        mobileBannerTypeList : [],

        product_name: "",

        background: '#ffffff',
        background2: '#ffffff',
        background3: '#ffffff',
        background4: '#ffffff',

        event_seq:"",
        lang_type: "",
        title: "",
        foreign_title: "",
        event_type_cd: "",
        event_type_name: "",
        event_type_img: "",
        event_mobile_type_cd: "",
        event_mobile_type_name: "",
        event_mobile_type_img:"",
        event_tmp_type: "",
        event_tmp_type_name: "",
        event_mobile_tmp_type: "",
        event_mobile_tmp_type_name: "",
        title_sentence_cd: "",
        content_sentence_cd: "",
        sentence: "",
        eng_sentence: "",
        display_yn:"" ,
        sentence_cd:"",

        keyword_cd: "",
        keyword: "",
        image: "",
        product_no: "",

        orderGubun: "created_date",
        sorting: "DESC",
        activePage: 1,
        totalCnt: 0,
        startIdx: 0,
        rowcnt:12,

        pc_banner_type_paging : {
            activePage: 1,
            totalCnt: 0,
            startIdx: 0,
            rowcnt:10,
        },
        
        mobile_banner_type_paging : {
            activePage: 1,
            totalCnt: 0,
            startIdx: 0,
            rowcnt:10,
        },

        bannerImgWidth: 0 + "%",

        user_seq: "",
        shop_no: "",

        reg_user_seq: "",
        reg_date: "",

        is_recommendation_large_title : "",
        title_length: 0,

        parentCategory: "",
        product_category_list: [],
        category_no: "",

        product_heder_category_no_1: "",
        product_heder_category_no_2: "",
        product_heder_category_no_3: "",
        product_heder_category_no_4: "",

        page_list: [],
        html_list: [],

        /* 시간 select Box Data */
        hour_list: [],
        minute_list: [],

        /* 진열 기간 설정 start */
        start_date: "",
        start_hour: "",
        start_minute: "",

        end_date: "",
        end_hour: "",
        end_minute: "",
        
        start_date_format: "",
        end_date_format: "",
        /* 진열 기간 설정 end */

        fullCalendarEventList:[],

        simple_date_setting_list : [],

        simple_date_cd: "17001",
        is_soldout_display: "Y",

        item_user_type: "A",

        mirror_select_idx_m:"0",
        mirror_select_idx_p:"0",

        w_img_link_type: "49001",
        h_img_detail_link_type: "49001",

        is_link_url:"Y",
        link_url:"",
        img_link_name: "",
        img_detail_link_name: "",
        w_img_49002_link: "",
        h_img_detail_49002_link: "",
        w_img_49002_link_state : true,
        h_img_detail_49002_link_state : true,

        disabledStartDate: false,
        disabledEndDate: false,

        pc_banner_ratio_list: [],
        mobile_banner_ratio_list: [],
        selected_pc_banner_ratio : {},
        selected_mobile_banner_ratio : {},

        /* 예약진열 여부 */
        book_yn : 'N',

        target_view_list : [],
        selected_target_view_list : [],
        is_use_target_view_service : "N",
    };

    componentDidMount(e) {
        const {location} = this.props; 
        if (location.state && location.state.createDefalutBanner) { 
            this.setState({
                iframe_page_id : location.state.iframe_page_id,
                iframe_html_id : location.state.iframe_html_id,
                html_list : location.state.html_list,
                item_user_type : "D",
            });
            window.history.replaceState({}, document.title); // location.state 초기화
        }

        dateString = new Date().getFullYear() + ("0" + parseInt(new Date().getMonth()+1)).slice(-2) + ("0" + parseInt(new Date().getDate())).slice(-2) + ("0" + parseInt(new Date().getHours())).slice(-2) + ("0" + parseInt(new Date().getSeconds())).slice(-2)

        // fullCalendarInnerModal_width = $(".fullCalendarInnerModal").width();
        fullCalendarInnerModal_width = $(document).width() * 1.4;

        const this_component = this;

        $(window).resize( function() {
            this_component.setHeight();

            if ($(".fullCalendarInnerModal .pcBanner").width() > 0) {
                fullCalendarInnerModal_pcbanner_width = $(".fullCalendarInnerModal .pcBanner").width();
            }
            if ($(".fullCalendarInnerModal .mobileBanner").width() > 0) {
                fullCalendarInnerModal_mobileBanner_width = $(".fullCalendarInnerModal .mobileBanner").width();
            }

            $(".item_content_box_category_area_body").css("max-height", $(".item_content_box_item_area_body").height());
            $(".item_content_box_category_area_body").css("overflow", "auto");
        });    


        is_banner_change = "true";
        is_mobile_bnner_change = "true";

        $(".fullCalendarModal").hide();

        // this.getSetting();
        this.getCafe24CodePaging("03000");		//배너타입 코드 조회
        this.getCafe24CodePaging("07000");		//모바일배너타입 코드 조회
        this.getCafe24Code("17000");		    //모바일배너타입 코드 조회
        // this.getCafe24Code("16000");		    //배너 page_html_id 조회
        this.loadCommonCodeForAutoEvent("16000", "page_list") //배너 page 조회
        // this.getRobomdPageHtmlId("page", "");             //배너 page_html_id 조회
        if(this.getUrlParams()["event_seq"] != null && this.getUrlParams()["event_seq"] != "") {
            this.loadEventDetail(this.getUrlParams()["event_seq"]);
            // this.loadEventDetailProduct(this.getUrlParams()["event_seq"]);
        } else {
            this.loadCafe24Item();
            // this.loadProductCategory(this.state.parentCategory);
            this.getFullCalendarEventList("", ""); // 달력용 기획전 목록 조회
            this.onClickSimpleDateCd(null, 17005); // 항상 노출을 defalt값으로 사용
        }
        this.getTargetViewList(); // 타겟뷰 목록 조회
    
        for(var i=0; i<=23; i++ ) {
            const {hour_list} = this.state;
            hour_list.push({key:i , hour:i+"시"});
            this.setState({hour_list: hour_list});
        }
        for(var i=0; i<=59; i++ ) {
            const {minute_list} = this.state;
            minute_list.push({key:i, minute:i+"분"});
            this.setState({minute_list: minute_list});
        }

        // FullCalendar 달 변경 이벤트
        $(".fc-prev-button").click(function() {
            var year = $(".fc-toolbar-title").text().split("년")[0];
            var month =  $(".fc-toolbar-title").text().split("년")[1].replace("월", "");
            
            if(Number(month)-1 == 0) {
                year = Number(year)-1;
                month = 12;
            } else {
                month = Number(month)-1;
            }

            if(Number(month) < 10) {
                month = "0"+month;
            }

            this_component.getFullCalendarEventList(year, month);
        })
        $(".fc-next-button").click(function() {
            var year = $(".fc-toolbar-title").text().split("년")[0];
            var month =  $(".fc-toolbar-title").text().split("년")[1].replace("월", "");
            
            if(Number(month)+1 == 13) {
                year = Number(year)+1;
                month = 1;
            } else {
                month = Number(month)+1;
            }
            
            if(Number(month) < 10) {
                month = "0"+month;
            }
            
            this_component.getFullCalendarEventList(year, month);
        })
    }

    getUrlParams() {
        var params = {};
        window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(str, key, value) { params[key] = value; });
        return params;
    }

    loadEventDetail(event_seq) {
		http.get("/rest/common/admin/event/detail?event_seq="+event_seq)
		.then(res => {
            if (res.data.err_code == 401) {
                window.location.href = "/logout";
            } else if (res.data.err_code == 404) {
                alert("접근할 수 없는 배너입니다.");
                window.location.href = "/shop/cafe24/event/list";
            } else {
                var event = res.data["event"];
                // var bannerTypeList = res.data["bannerTypeList"];
                // var mobileBannerTypeList = res.data["mobileBannerTypeList"];
                // console.log(bannerTypeList);
                if(event != null) {
                    console.log("### start_date: "+event.start_date);
                    console.log("### start_date: "+new Date(event.start_date));
                    var start_date = new Date(event.start_date);
                    var end_date = new Date(event.end_date);
                    var start_hour = event.start_hour;
                    var start_minute = event.start_minute;
                    var end_hour = event.end_hour;
                    var end_minute = event.end_minute;
                    if (Number(start_hour) < 10) {
                        if(start_hour == "00") {
                            start_hour = "0"
                        } else {
                            start_hour = start_hour.replace("0", "");
                        }
                    }
                    if (Number(start_minute) < 10) {
                        if(start_minute == "00") {
                            start_minute = "0"
                        } else {
                            start_minute = start_minute.replace("0", "");
                        }
                    }
                    if (Number(end_hour) < 10) {
                        if(end_hour == "00") {
                            end_hour = "0"
                        } else {
                            end_hour = end_hour.replace("0", "");
                        }
                    }
                    if (Number(end_minute) < 10) {
                        if(end_minute == "00") {
                            end_minute = "0"
                        } else {
                            end_minute = end_minute.replace("0", "");
                        }
                    }

                    this.setState( {
                        event_seq: event.event_seq,
                        start_date: start_date,
                        start_hour: start_hour,
                        start_minute: start_minute,
                        start_date_format: start_date.getFullYear() + "-"+ ("0" + parseInt(start_date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(start_date.getDate())).slice(-2)+" "+start_hour+":"+start_minute+":00",
                        end_date: end_date,
                        end_hour: end_hour,
                        end_minute: end_minute,
                        end_date_format: end_date.getFullYear() + "-"+ ("0" + parseInt(end_date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(end_date.getDate())).slice(-2)+" "+end_hour+":"+end_minute+":59",
                        simple_date_cd: event.simple_date_cd,

                        start_date_format_calendar: start_date.getFullYear() + "-"+ ("0" + parseInt(start_date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(start_date.getDate())).slice(-2)
                                    +" "+event.start_hour+":"+event.start_minute+":00.000+0900",
                        end_date_format_calendar: end_date.getFullYear() + "-"+ ("0" + parseInt(end_date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(end_date.getDate())).slice(-2)
                                    +" "+event.end_hour+":"+event.end_minute+":59.000+0900",

                        item_user_type: event.item_user_type,
                        selected_target_view_list : event.target_view_list,
                        
                        lang_type: event.lang_type,
                        auto_title: event.title,
                        non_auto_title: event.title,
                        title: event.title,
                        sub_title: event.foreign_title,
                        foreign_title: event.foreign_title,
                        event_type_cd: event.event_type_cd,
                        event_mobile_type_cd: event.event_mobile_type_cd,
                        event_type_name: event.event_type_name,
                        event_type_img: event.event_type_img,
                        event_mobile_type_name: event.event_mobile_type_name,
                        event_mobile_type_img: event.event_mobile_type_img,
                        keyword_cd: event.keyword_cd,
                        keyword: event.keyword,
                        img_link: event.img_link,
                        img_detail_link: event.img_detail_link,
                        promotion_org_img_link_seq : event.promotion_org_img_link_seq,
                        promotion_org_img_detail_link_seq : event.promotion_org_img_detail_link_seq,
    
                        user_seq: event.user_seq,
                        shop_no: event.shop_no,
                        iframe_page_id: event.iframe_page_id,
                        iframe_html_id: event.iframe_html_id,
                        
                        display_yn: event.display_yn,
                        title_sentence_cd: event.title_sentence_cd,
                        content_sentence_cd: event.content_sentence_cd,
                        sentence: event.sentence,
                        eng_sentence: event.eng_sentence,
                        reg_user_seq: event.reg_user_seq,
                        reg_date: event.reg_date,
                        dominant_color: event.dominant_color,
                        is_product_change: event.is_product_change,
                        w_color1_is_use: event.w_color1_is_use,
                        w_color2_is_use: event.w_color2_is_use,
                        w_color3_is_use: event.w_color3_is_use,
                        w_color4_is_use: event.w_color4_is_use,
                        h_color1_is_use: event.h_color1_is_use,
                        h_color2_is_use: event.h_color2_is_use,
                        h_color3_is_use: event.h_color3_is_use,
                        h_color4_is_use: event.h_color4_is_use,
                        background: event.color_code_1,
                        background2: event.color_code_2,
                        background3: event.color_code_3,
                        background4: event.color_code_4,
                        link_url: event.link_url,
                        img_link_name: event.img_link_name,
                        img_detail_link_name: event.img_detail_link_name,
                        w_img_link_type: event.w_img_link_type,
                        h_img_detail_link_type: event.h_img_detail_link_type,
                        w_img_49002_link: event.w_img_49002_link,
                        h_img_detail_49002_link: event.h_img_detail_49002_link,
                    }, () => {
                        if(this.state.promotion_org_img_link_seq != null && this.state.promotion_org_img_link_seq != ""){
                            this.searchOrgImg(this.state.promotion_org_img_link_seq, "landscape");
                        }
                        if(this.state.promotion_org_img_detail_link_seq != null && this.state.promotion_org_img_detail_link_seq != ""){
                            this.searchOrgImg(this.state.promotion_org_img_detail_link_seq, "portrait");
                        }
                        // 사용자 멀티샵 서비스 무료체험, 결제여부 확인
                        this.fn_shopServiceOperated("01009");   // 01009 : 타겟뷰 서비스
                    });

                    if (event.simple_date_cd != null && event.simple_date_cd != "17001") {
                        this.state.disabledStartDate = true;
                        this.state.disabledEndDate = true;
                        $("#start_date").attr("disabled", true); 
                        $("#start_hour").attr("disabled", true); 
                        $("#start_minute").attr("disabled", true); 
                        $("#end_date").attr("disabled", true); 
                        $("#end_hour").attr("disabled", true); 
                        $("#end_minute").attr("disabled", true); 
                    } else {
                        this.state.disabledStartDate = false;
                        this.state.disabledEndDate = false;
                        $("#start_date").attr("disabled", false); 
                        $("#start_hour").attr("disabled", false); 
                        $("#start_minute").attr("disabled", false); 
                        $("#end_date").attr("disabled", false); 
                        $("#end_hour").attr("disabled", false); 
                        $("#end_minute").attr("disabled", false); 
                    }

                    // alert(event.img_link_name);
                    // alert(event.img_detail_link_name);

                    // alert(event.link_url);

                    if (event.link_url != null) {
                        this.setState({is_link_url: 'Y'})
                    }
                }

                this.loadCafe24Item();
                // this.loadProductCategory(this.state.parentCategory);
                this.getFullCalendarEventList("", ""); // 달력용 기획전 목록 조회
            }
            // if(bannerTypeList.length > 0) {
            //     this.setState({bannerImgWidth : (100 / bannerTypeList.length) + "%"});
            // }
            if(event.iframe_page_id != null && event.iframe_page_id != "") {
                this.getRobomdPageHtmlId("html", event.iframe_page_id);
            }
            
		})
		.catch(error => {
			// console.log ("===== error =====");
			// console.log (error);
			// console.log ("===== error request =====");
			// console.log(error.request);
		 });
    }

    loadProductCategory(parentCategory) {

        const sendForm = {
            user_seq: this.state.user_seq , 
            shop_no: "1", 
            parent_category_no: parentCategory
        }

		http.get("/rest/common/cafe24/category", {params:sendForm})
		.then(res => {
            this.setState({product_category_list: res.data.list});
		})
		.catch(error => {
			console.log ("===== error =====");
			console.log (error);
			console.log ("===== error request =====");
			console.log(error.request);
		 });
    }

    loadEventDetailProduct(event_seq) {

        const sendForm = {
            event_seq: event_seq
        }

		http.get("/rest/common/admin/event/detailProduct", {params:sendForm})
		.then(res => {
            
            if (res.data.err_code == 401) {
                window.location.href = "/logout";
            }

            console.log("========== res.data.eventProduct ==========");
            console.log(res.data.eventProduct);
            if(res.data.eventProduct != null) {
                this.setState( {
                    select_item_list: res.data.eventProduct,
                    product_no: res.data.eventProduct[0].product_no,
                    select_tmp_item_list: res.data.eventProduct
                } );
            }

            this.insertEventTmp();

        })
    }

    //공통코드 조회 (페이징)
	async getCafe24CodePaging(upper_code_cd) {
        var startidx = 0
        var rowcnt = 10
        if (upper_code_cd == "03000") {
            startidx = this.state.pc_banner_type_paging.startIdx;
            rowcnt = this.state.pc_banner_type_paging.rowcnt;
        } else if (upper_code_cd == "07000") {
            startidx = this.state.mobile_banner_type_paging.startIdx;
            rowcnt = this.state.mobile_banner_type_paging.rowcnt;
        } 


        const res = await http.get( "/rest/common/cafe24Code/paging"
                                    +"?upper_code_cd="+upper_code_cd
                                    +"&startidx="+startidx
                                    +"&rowcnt="+rowcnt 
                                    +is_test_use
                                    );
        if (upper_code_cd == "03000") {
            const{pc_banner_type_paging} = this.state;
            pc_banner_type_paging["totalCnt"] = res.data.totalCnt;
            if(res.data.list.length > 0) {
                this.setState({bannerTypeList : res.data.list
                                , pc_banner_type_paging : pc_banner_type_paging});
                if (res.data.list.length >= 4) {
                    this.setState({bannerImgWidth : parseInt((100 / 4)-6) + "%"});
                } else {
                    this.setState({bannerImgWidth : parseInt((100 / res.data.list.length)-6) + "%"});
                }
            }
        } else if (upper_code_cd == "07000") {
            const{mobile_banner_type_paging} = this.state;
            mobile_banner_type_paging["totalCnt"] = res.data.totalCnt;
            if(res.data.list.length > 0) {
                this.setState({mobileBannerTypeList : res.data.list
                                , mobile_banner_type_paging : mobile_banner_type_paging});
                if (res.data.list.length >= 4) {
                    this.setState({mobileBannerImgWidth : parseInt((100 / 4)-6) + "%"});
                } else {
                    this.setState({mobileBannerImgWidth : parseInt((100 / res.data.list.length)-6) + "%"});
                }
            }
        } else if (upper_code_cd == "17000") {
            this.setState({simple_date_setting_list: res.data})
        }
    };

    //공통코드 조회
	async getCafe24Code(upper_code_cd) {
        const res = await http.get( "/rest/common/cafe24Code"
                                    +"?upper_code_cd="+upper_code_cd);
        if (upper_code_cd == "03000") {
            if(res.data.length > 0) {
                this.setState({bannerTypeList : res.data});
                if (res.data.list.length >= 4) {
                    this.setState({bannerImgWidth : parseInt((100 / 4)-6) + "%"});
                } else {
                    this.setState({bannerImgWidth : parseInt((100 / res.data.list.length)-6) + "%"});
                }
            }
        } else if (upper_code_cd == "07000") {
            if(res.data.length > 0) {
                this.setState({mobileBannerTypeList : res.data});
                if (res.data.list.length >= 4) {
                    this.setState({mobileBannerImgWidth : parseInt((100 / 4)-6) + "%"});
                } else {
                    this.setState({mobileBannerImgWidth : parseInt((100 / res.data.list.length)-6) + "%"});
                }
            }
        } else if (upper_code_cd == "17000") {
            this.setState({simple_date_setting_list: res.data})
        } else if (upper_code_cd == "16000") {
			this.setState({page_list: res.data})
		}
    };

    loadCommonCodeForAutoEvent(upper_code_cd, stateName) {
		http.get("/rest/common/cafe24AutoEventCode?upper_code_cd="+upper_code_cd)
		.then(res => {
            console.log(res.data);
            this.setState({[stateName]: res.data}); 
        })
    }
    
    getFullCalendarEventList (year, month) {
        var sendForm = {
            user_seq: this.state.user_seq , 
            shop_no: this.state.shop_no, 
            year: year,
            month: month,
            calendar_type: "event_form"
        }

        http.get("/rest/common/calendar/event/list", {params:sendForm})
        .then(res => {
            is_change_date_add_calendar = true;
            this.setState({fullCalendarEventList: res.data.list});
        })
    }
    
    getBannerRatio (){
        var sendForm = {
            user_seq: this.state.user_seq,
            shop_no: "1",
            iframe_page_id: this.state.iframe_page_id,
            iframe_html_id: this.state.iframe_html_id,
        }

        http.get("/rest/common/banner/ratio", {params:sendForm})
        .then(res => {
            
            let org_event_banner_pc_width = res.data.org_event_banner_pc_width;
            let org_event_banner_pc_height = res.data.org_event_banner_pc_height;
            let org_event_banner_mobile_width = res.data.org_event_banner_mobile_width;
            let org_event_banner_mobile_height = res.data.org_event_banner_mobile_height;
            let event_banner_pc_width_height = res.data.event_banner_pc_width_height;
            let event_banner_mobile_width_height = res.data.event_banner_mobile_width_height;

            let pc_banner_ratio_list = [];
            let mobile_banner_ratio_list = [];
            let selected_pc_banner_ratio = {};
            let selected_mobile_banner_ratio = {};
            let pc_mobile_same_ratio = false; // pc/mobile형 비율 사이즈가 정확하게 일치하면 true, 셀렉트박스에 하나의 설정만 들어가도록 한다. 

            if(org_event_banner_pc_width == org_event_banner_mobile_width && org_event_banner_pc_height == org_event_banner_mobile_height){
                pc_mobile_same_ratio = true;
            }

            var pc_org_obj = {
                "org_banner_width":   org_event_banner_pc_width,
                "org_banner_height": org_event_banner_pc_height,
                "org_event_type" : "p",
                "org_width_height" : event_banner_pc_width_height,
            };
            if (event_banner_pc_width_height && event_banner_pc_width_height > 1) {
                pc_org_obj = this.getCaptureSize("pc",pc_org_obj);
                // 원본 pc 비율이 1보다 큰 경우
                pc_banner_ratio_list.push(pc_org_obj);
                selected_pc_banner_ratio = pc_org_obj;
            } else if (event_banner_pc_width_height && event_banner_pc_width_height <= 1){
                pc_org_obj = this.getCaptureSize("mobile",pc_org_obj);
                // 원본 pc 비율이 1보다 작거나 같은 경우
                mobile_banner_ratio_list.push(pc_org_obj);
                selected_mobile_banner_ratio = pc_org_obj;
            }

            if(!pc_mobile_same_ratio){
                var mobile_org_obj = {
                    "org_banner_width": org_event_banner_mobile_width,
                    "org_banner_height": org_event_banner_mobile_height,
                    "org_event_type" : "m",
                    "org_width_height" : event_banner_mobile_width_height,
                };
                if (event_banner_mobile_width_height && event_banner_mobile_width_height <= 1){
                    mobile_org_obj = this.getCaptureSize("mobile",mobile_org_obj);
                    // 원본 모바일 비율이 1보다 작거나 같은 경우
                    mobile_banner_ratio_list.push(mobile_org_obj);
                    
                    // 비연동형에서 입력값이 모바일이였다면, 해당 비율을 선택값으로 설정
                    if(!selected_mobile_banner_ratio.org_banner_width){
                        selected_mobile_banner_ratio = mobile_org_obj;
                    }
                } else if (event_banner_mobile_width_height && event_banner_mobile_width_height > 1) {
                    mobile_org_obj = this.getCaptureSize("pc",mobile_org_obj);
                    // 원본 모바일 비율이 1보다 큰 경우
                    pc_banner_ratio_list.push(mobile_org_obj);
    
                    // 비연동형에서 입력값이 모바일이였다면, 해당 비율을 선택값으로 설정
                    if(!selected_pc_banner_ratio.org_banner_width){
                        selected_pc_banner_ratio = mobile_org_obj;
                    }
                }
            }

            this.setState({
                pc_banner_ratio_list: pc_banner_ratio_list,
                mobile_banner_ratio_list: mobile_banner_ratio_list,
                selected_pc_banner_ratio : selected_pc_banner_ratio,
                selected_mobile_banner_ratio : selected_mobile_banner_ratio,
            }, () => {
                this.insertEventTmp('preview');
            });

            // banner_ratio_list가 존재하는지로 가로형 또는 세로형이 보여지는 여부 판별
            this.setState({
                is_show_pc_banner_area: pc_banner_ratio_list.length > 0 ? true : false,
                is_show_mobile_banner_area: mobile_banner_ratio_list.length > 0 ? true : false,
            });

            this.setHeight();
        })
    }

    // 자동 타이틀
	async getAutoTitle() {
        this.setState({auto_title: "로딩중입니다..."});
        const res = await http.get("/rest/common/except/keyword?user_seq="+this.state.user_seq+"&keyword="+encodeURI(this.state.select_item_list[0].product_name));
        if(res.data != null) {
            is_auto_title_load = false;
            isTmp = true;
            this.setState({auto_title: res.data.res_txt
                            , title: res.data.res_txt});
            
        }
        is_auto_title_load = false;
	};

    // 자동 서브 타이틀
	async getAutoSubTitle() {
        this.setState({autoSubTextList: ""});
        const res = await http.get("/rest/common/auto/event/sentence?user_seq="+this.state.user_seq+"&product_name="+encodeURI(this.state.select_item_list[0].product_name));
        if(res.data != null) {
            this.setState({autoSubTextList: res.data.sentence_list});
        }
        is_auto_sub_title_load = false;
	};

    // //배너 html_id 조회
	// async getRobomdPageHtmlId() {
    //     const res = await http.get("/rest/common/robomd/PageHtmlId?shop_no="+"1");
    //     if(res.data != null) {
    //         this.setState({html_list: res.data.html_id_list});
    //         this.setState({page_list: res.data.page_id_list});
    //     }
    // };
    
    //배너 html_id 조회
	async getRobomdPageHtmlId(type, page_id) {
		const res = await http.get("/rest/common/robomd/PageHtmlId?user_seq="+this.state.user_seq+"&shop_no="+this.state.shop_no+"&type="+type+"&iframe_page_id="+page_id);
        if(res.data != null) {
			if (type == "html") {
				this.setState({html_list: res.data.html_id_list,},() => {
                    if(!(this.getUrlParams()["event_seq"] != null && this.getUrlParams()["event_seq"] != "" && is_first_load_html)){
                        is_banner_ratio_loaded = true;
                        this.setState({
                            iframe_html_id: this.state.html_list && this.state.html_list.length > 0  ? this.state.html_list[0].iframe_html_id : "",
                        });
                    }
                    is_first_load_html = false;
                });
			} else if (type == "page") {
				this.setState({page_list: res.data.page_id_list});
			}
        }
	};
    
    //배너 팔레트 사용 유무 조회
	async getisUsePallete() {
        // alert((this.state.event_type_cd != null && this.state.event_type_cd != "") || (this.state.event_mobile_type_cd != null && this.state.event_mobile_type_cd != ""));
        if((this.state.event_type_cd != null && this.state.event_type_cd != "") || (this.state.event_mobile_type_cd != null && this.state.event_mobile_type_cd != "")) {
            const res = await http.get("/rest/common/banner/isUsePallete?event_type_cd="+this.state.event_type_cd+"&event_mobile_type_cd="+this.state.event_mobile_type_cd);
            if(res.data != null) {
                this.setState({w_color1_is_use: res.data.w_color1_is_use});
                this.setState({w_color2_is_use: res.data.w_color2_is_use});
                this.setState({w_color3_is_use: res.data.w_color3_is_use});
                this.setState({w_color4_is_use: res.data.w_color4_is_use});
                this.setState({h_color1_is_use: res.data.h_color1_is_use});
                this.setState({h_color2_is_use: res.data.h_color2_is_use});
                this.setState({h_color3_is_use: res.data.h_color3_is_use});
                this.setState({h_color4_is_use: res.data.h_color4_is_use});
            }
        }
	};

    componentDidUpdate(e) {
        if (isEventPcMobile) {
            isEventPcMobile = false;
            this.calendarBannerModalSetSize();
        }

        if (isTmp && this.state.mirror_banner_state == "on") {
            this.insertEventTmp(); //미리보기 버튼 클릭시 동작 하도록 변경함.
            isTmp = false;
            this.setHeight();
            //$(".mirror_banner_v2_mobile_banner").css("height", (Number($(".mirror_banner_v2_mobile_banner").width()/this.state.mirror_banner_v2_mobile_banner_height)+"px"));
            //$(".mirror_banner_v2_pc_banner").css("height", (Number($(".mirror_banner_v2_pc_banner").width()/this.state.mirror_banner_v2_pc_banner_height)+"px"));
        }

        if (startIdxChange) {
            this.loadCafe24Item()
        }
        startIdxChange = false;

        /* 배너 타입 변경 */
        if (this.state.bannerTypeList != null && this.state.bannerTypeList != [] && this.state.event_type_cd) {
            if (is_banner_change) {
                for (var idx in this.state.bannerTypeList) {
                    if (this.state.event_type_cd == this.state.bannerTypeList[idx].code_cd) {
                        this.setState({event_type_img : this.state.bannerTypeList[idx].val2})
                    }
                }
                is_banner_change = false;

                this.getisUsePallete();
            }
        }
        if (this.state.mobileBannerTypeList != null && this.state.mobileBannerTypeList != [] && this.state.event_mobile_type_cd) {
            if (is_mobile_bnner_change) {
                for (var idx in this.state.mobileBannerTypeList) {
                    if (this.state.event_mobile_type_cd == this.state.mobileBannerTypeList[idx].code_cd) {
                        this.setState({event_mobile_type_img : this.state.mobileBannerTypeList[idx].val2})
                    }
                }
                is_mobile_bnner_change = false;

                this.getisUsePallete();
            }
        }

        // 상품 카테고리 영역 동적 높이 할당
        $(".item_content_box_category_area_body").css("max-height", $(".item_content_box_item_area_body").height());
        $(".item_content_box_category_area_body").css("overflow", "auto");


        // 달력에 추가
        if (is_change_date_add_calendar) {

            let id = (this.state.event_seq != "") ? this.state.event_seq : -1;
            const {fullCalendarEventList} = this.state;
            const itemToFind = fullCalendarEventList.find(function(item) {return item.id === id}); 
            const idx = fullCalendarEventList.indexOf(itemToFind);
            if (idx > -1) fullCalendarEventList.splice(idx, 1)

            is_change_date_add_calendar = false;
            isTmp = true;

            this.setState({
                fullCalendarEventList: fullCalendarEventList.concat({
                    id: id,
                    title: "",
                    start: this.state.start_date_format_calendar,
                    end: this.state.end_date_format_calendar,
                    // start: new Date(this.state.start_date_format).toISOString().slice(0, 10),
                    // end: new Date(this.state.end_date_format).toISOString().slice(0, 10),
                    color: "#10CFC9",
                }),
            })
        }

        // 세부일정 변경
        if(is_change_date_setting) {
            if (this.state.start_date != "") {
                if (this.state.start_hour != "") {
                    if (this.state.start_minute != "") {
                        if (this.state.end_date != "") {
                            if (this.state.end_hour != "") {
                                if (this.state.end_minute != "") {
                                    is_change_date_add_calendar = true;

                                    var start_hour = this.state.start_hour;
                                    if(Number(this.state.start_hour) < 10) {
                                        start_hour = "0"+ this.state.start_hour;
                                    }
                                    var start_minute = this.state.start_minute;
                                    if(Number(this.state.start_minute) < 10) {
                                        start_minute = "0"+ this.state.start_minute;
                                    }
                                    var end_hour = this.state.end_hour;
                                    if(Number(this.state.end_hour) < 10) {
                                        end_hour = "0"+ this.state.end_hour;
                                    }
                                    var end_minute = this.state.end_minute;
                                    if(Number(this.state.end_minute) < 10) {
                                        end_minute = "0"+ this.state.end_minute;
                                    }


                                    // 시작시간, 종료시간 모두 00초로 세팅 해놓고 비교
                                    var start_date_format = this.state.start_date.getFullYear() + "-"+ ("0" + parseInt(this.state.start_date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(this.state.start_date.getDate())).slice(-2)
                                    +" "+start_hour+":"+start_minute+":00";
                                    var end_date_format = this.state.end_date.getFullYear() + "-"+ ("0" + parseInt(this.state.end_date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(this.state.end_date.getDate())).slice(-2)
                                                            +" "+end_hour+":"+end_minute+":00";
                                    if(new Date(start_date_format).valueOf() == new Date(end_date_format).valueOf()) {
                                        alert("종료 일시는 시작 일시와 다르게 선택해 주세요. ");
                                    } else {
                                        // 종료 시간을 59초로 세팅
                                        start_date_format = this.state.start_date.getFullYear() + "-"+ ("0" + parseInt(this.state.start_date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(this.state.start_date.getDate())).slice(-2)
                                                                +" "+start_hour+":"+start_minute+":00";
                                        end_date_format = this.state.end_date.getFullYear() + "-"+ ("0" + parseInt(this.state.end_date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(this.state.end_date.getDate())).slice(-2)
                                                                +" "+end_hour+":"+end_minute+":59";
                                        if(new Date(start_date_format) > new Date(end_date_format)) {
                                            alert("시작일시가 종료일시 보다 미래 일 수 없습니다.\n다시 설정해 주세요.");
                                        } 
                                    }

                                    // 예약진열 여부
                                    if(new Date(start_date_format) > new Date()) {
                                        this.setState({
                                            book_yn: 'Y'
                                        })
                                    } else {
                                        this.setState({
                                            book_yn: 'N'
                                        })
                                    }

                                    this.setState({
                                        start_date_format: start_date_format,
                                        end_date_format: end_date_format,
                                        start_date_format_calendar: this.state.start_date.getFullYear() + "-"+ ("0" + parseInt(this.state.start_date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(this.state.start_date.getDate())).slice(-2)
                                                            +" "+start_hour+":"+start_minute+":00.000+0900",
                                        end_date_format_calendar: this.state.end_date.getFullYear() + "-"+ ("0" + parseInt(this.state.end_date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(this.state.end_date.getDate())).slice(-2)
                                                            +" "+end_hour+":"+end_minute+":59.000+0900",
                                    })
                                }
                            }
                        }
                    }
                }
            }
            is_change_date_setting = false;
        }

        if(is_auto_title_load) {
            $("#auto_title").attr("disabled", "true");
        } else {
            $("#auto_title").removeAttr('disabled');
        }

        if(is_onclick_item) {
            if (this.state.is_recommendation_small_title == "on") {
                this.getAutoSubTitle(); // 자동 작은 글자 제목 재로딩     
            } else {
                is_auto_sub_title_load = true;
            }
            if (this.state.is_recommendation_large_title == "on") {
                this.getAutoTitle(); // 자동 큰 글자 제목 재로딩     
            } else {
                is_auto_title_load = true;
            }
            is_onclick_item = false;
        }

        if (this.state.iframe_html_id != null && this.state.iframe_html_id != ""
        && this.state.iframe_page_id != null && this.state.iframe_page_id != ""
        && is_banner_ratio_loaded) {
            this.getBannerRatio();
            this.setHeight();
            is_banner_ratio_loaded = false;
        }
	}

    insertEventTmp(call_cls) {
        // 배너 문구 replace
        let replaceText = function(text){
            return text.replace(/(?:\r\n|\r|\n)/g, '<br />');
        };
        let body = {
            event_seq : this.getUrlParams()["event_seq"] ? this.state.event_seq : dateString,
            insert_type : this.state.insert_type,
            start_date : this.state.start_date_format,
            end_date : this.state.end_date_format,

            title : replaceText(this.state.title),
            foreign_title : replaceText(this.state.foreign_title),
            title_sentence_cd : this.state.title_sentence_cd,
            content_sentence_cd : this.state.content_sentence_cd,
            sentence : replaceText(this.state.sentence),
            eng_sentence : replaceText(this.state.eng_sentence),

            display_yn : this.state.display_yn,
            event_type_cd : "03999",
            event_mobile_type_cd : "07999",
            event_type_name : this.state.event_type_name,
            event_mobile_type_name : this.state.event_mobile_type_name,
            keyword_cd : this.state.keyword,

            color_code_1 : this.state.background,
            color_code_2 : this.state.background2,
            color_code_3 : this.state.background3,
            color_code_4 : this.state.background4,

            user_seq : this.state.user_seq,
            shop_no : "1",
            product_no : this.state.product_no,
            reg_user_seq : this.state.reg_user_seq,
            reg_date : this.state.reg_date,

            call_cls : call_cls,   //호출 구분 : 미리보기 호출시 이력 테이블 저장
        };

        if(this.state.w_img_link_type == "49001"){
            if (this.state.img_list_landscape != null) {
                body['img_link'] = this.state.img_list_landscape[0].org_img_path;
            } else{
                body['img_link'] = "";
            }
        } else{
            if(this.state.w_img_49002_link_state && this.state.w_img_49002_link != null && this.state.w_img_49002_link != ""){
                body['img_link'] = this.state.w_img_49002_link;
            } else{
                body['img_link'] = "";
            }
        }
        if(this.state.h_img_detail_link_type == "49001"){
            if (this.state.img_list_portrait != null) {
                body['img_detail_link'] = this.state.img_list_portrait[0].org_img_path;
                body['h_img_detail_link'] = this.state.img_list_portrait[0].org_img_path; 
            } else{
                body['img_detail_link'] = "";
                body['h_img_detail_link'] = "";
            }
        } else{
            if(this.state.h_img_detail_49002_link_state && this.state.h_img_detail_49002_link != null && this.state.h_img_detail_49002_link != ""){
                body['img_detail_link'] = this.state.h_img_detail_49002_link;
                body['h_img_detail_link'] = this.state.h_img_detail_49002_link;
            } else{
                body['img_detail_link'] = "";
                body['h_img_detail_link'] = "";
            }
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
                },
        };
        http.post("/rest/common/insert/event/tmp", body, config)
		.then(res => {
            console.log("임시저장 완료");
            if(this.getUrlParams()["event_seq"] != null && this.getUrlParams()["event_seq"] != "") {
                $(".mirror_banner_v2_mobile_banner").html('<object class="robomd_banner_p" type="text/html" data="'+mirror_banner_url+'?event_seq='+this.state.event_seq+'&platform_type=m&mirror_type=18001" type="text/html" style="width:100%;height:100%; background: white;"></object>');
                $(".mirror_banner_v2_pc_banner").html('<object class="robomd_banner_m" type="text/html" data="'+mirror_banner_url+'?event_seq='+this.state.event_seq+'&platform_type=p&mirror_type=18001" type="text/html" style="width:100%;height:100%; background: white;"></object>');
            } else {
                // alert(dateString);
                $(".mirror_banner_v2_mobile_banner").html('<object class="robomd_banner_p" type="text/html" data="'+mirror_banner_url+'?event_seq='+dateString+'&platform_type=m&mirror_type=18001" type="text/html" style="width:100%;height:100%; background: white;"></object>');
                $(".mirror_banner_v2_pc_banner").html('<object class="robomd_banner_m" type="text/html" data="'+mirror_banner_url+'?event_seq='+dateString+'&platform_type=p&mirror_type=18001" type="text/html" style="width:100%;height:100%; background: white;"></object>');
            }
        })
		.catch(error => {
			// console.log ("===== error =====");
			// console.log (error);
			// console.log ("===== error request =====");
			// console.log(error.request);
		 });
    }

    setHeight = () => {
        $(".mirror_banner_modal_v2").show();
        const dbcw = document.body.clientWidth;

        const mirror_banner_modal_v2_width = dbcw / 2;

        // 영역 가로 사이즈 설정 / 영역 margin 설정(가로형 세로형 사이 간격)
        if(dbcw > 795) { // 800인 모바일 사이즈에 맞춰 변화하도록 설정 (5px 만큼 차이 있음)
            let mWidth = (mirror_banner_modal_v2_width / 6.2) * 2.2;
            let pWidth = (mirror_banner_modal_v2_width / 6.2) * 4;
            
            $(".mirror_banner_v2_mobile_banner").css("width", `${mWidth - 40}px`);
            $(".mirror_banner_v2_pc_banner").css("width", `${pWidth - 50}px`);
            $(".mirror_banner_v2_pc_banner_area").css("margin-right", "10px");
            $(".mirror_banner_v2_pc_banner_area").css("margin-bottom", "0px");
        } else {
            let mbmw = $(".mirror_banner_v2_body").width();
            $(".mirror_banner_v2_mobile_banner").css("width", `${mbmw - 5}px`);
            $(".mirror_banner_v2_pc_banner").css("width", `${mbmw - 5}px`);
            $(".mirror_banner_v2_pc_banner_area").css("margin-right", "0px");
            $(".mirror_banner_v2_pc_banner_area").css("margin-bottom", "10px");
        }

        const dbch = window.innerHeight;
		const maxHeight = dbch * 0.6 - 43; //0.6은 임의의 값, 43은 세로형 or 가로형 텍스트 칸 크기

        /* 세로형 베너 세로 사이즈 너무 커지는 현상 방지 */
        // 설정된 가로 사이즈를 기준으로 비율 계산을 통해 세로 사이즈 구하기
        let mh = $(".mirror_banner_v2_mobile_banner").width() / this.state.selected_mobile_banner_ratio.org_width_height;
        // 비율에 맞게 설정된 세로사이즈가 maxHeight를 넘은 경우의 처리 
		if(mh > maxHeight) {
			mh = maxHeight; // 세로 사이즈를 max값으로 설정
            // 세로 사이즈를 기준으로 비율 계산으로 통해 가로 사이즈 구하기
            const width = mh * this.state.selected_mobile_banner_ratio.org_width_height;
            $(".mirror_banner_v2_mobile_banner").css("width", width+"px");
		}
        $(".mirror_banner_v2_mobile_banner").css({"height": mh+"px"});

        /* 가로형 베너 세로 사이즈 커지는 현상 방지 */
        // 설정된 가로 사이즈를 기준으로 비율 계산을 통해 세로 사이즈 구하기
        let ph = $(".mirror_banner_v2_pc_banner").width() / this.state.selected_pc_banner_ratio.org_width_height;
        // 비율에 맞게 설정된 세로사이즈가 maxHeight를 넘은 경우의 처리 
		if(ph > maxHeight) {
			ph = maxHeight; // 세로 사이즈를 max값으로 설정
            // 세로 사이즈를 기준으로 비율 계산으로 통해 가로 사이즈 구하기
            const width = ph * this.state.selected_pc_banner_ratio.org_width_height;
			$(".mirror_banner_v2_pc_banner").css("width", width+"px");
		}
        $(".mirror_banner_v2_pc_banner").css({"height": ph+"px"});
    }

    checkImageNoneUpload(type){
        if(type == "w"){
            if (this.state.pc_banner_ratio_list.length > 0 &&
                ((this.state.w_img_link_type == "49001" && (this.state.img_list_landscape == null || (this.state.img_list_landscape != null && this.state.img_list_landscape == 0)))
                || (this.state.w_img_link_type == "49002" && (this.state.w_img_49002_link == null || this.state.w_img_49002_link == "" || !this.state.w_img_49002_link_state)))) {
                return true;
            }
        } else{
            if (this.state.mobile_banner_ratio_list.length > 0 &&
                ((this.state.h_img_detail_link_type == "49001" && (this.state.img_list_portrait == null || (this.state.img_list_portrait != null && this.state.img_list_portrait == 0)))
                || (this.state.h_img_detail_link_type == "49002" && (this.state.h_img_detail_49002_link == null || this.state.h_img_detail_49002_link == "" || !this.state.h_img_detail_49002_link_state)))) {
                return true;
            }
        }
        return false;
    }

    saveEvent =  e => {
        // alert(this.state.background)
        if (this.state.iframe_page_id == null || this.state.iframe_page_id == ""){
            alert("적용 범위 - 페이지를 선택해 주세요.");
            $("#iframe_page_id_cd").focus();
            return false;
        }
        if (this.state.iframe_html_id == null || this.state.iframe_html_id == ""){
            alert("적용 범위 - 배너를 선택해 주세요.");
            $("#iframe_html_id_cd").focus();
            return false;
        }
        if (this.state.item_user_type == null || this.state.item_user_type == ""){
            alert("적용 범위 - 누구에게 노출할지 선택해 주세요.");
            $("#item_user_type").focus();
            return false;
        }
        if(this.state.item_user_type == "T" && this.state.is_use_target_view_service == "Y" && this.state.selected_target_view_list.length == 0){
            alert("타겟을 선택해 주세요.")
        }
        if (this.state.title == null || this.state.title == ""){
            alert("배너 이름을 입력해주세요.")
            $("#title").focus();
            return false;
        }

        if (this.checkImageNoneUpload("w")) {
            alert("가로형 이미지를 등록해 주세요.")
            return false;
        }

        if (this.checkImageNoneUpload("h")) {
            alert("세로형 이미지를 등록해 주세요.")
            return false;
        }
            
        if (this.state.link_url == null || this.state.link_url == "") {
            alert("연결 링크를 설정해 주세요.")
            $("#link_url").focus();
            return false;
        }

        if (this.state.start_date == null || this.state.start_date == "") {
            alert("시작 일시를 지정해주세요.")
            $("#start_date").focus();
            return false;
        }
        if (this.state.start_hour == null || this.state.start_hour == "") {
            alert("시작 일시를 지정해주세요.")
            $("#start_hour").focus();
            return false;
        }
        if (this.state.start_minute == null || this.state.start_minute == "") {
            alert("시작 일시를 지정해주세요.")
            $("#start_minute").focus();
            return false;
        }

        if (this.state.end_date == null || this.state.end_date == "") {
            alert("종료 일시를 지정해주세요.")
            $("#end_date").focus();
            return false;
        }
        if (this.state.end_hour == null || this.state.end_hour == "") {
            alert("종료 일시를 지정해주세요.")
            $("#end_hour").focus();
            return false;
        }
        if (this.state.end_minute == null || this.state.end_minute == "") {
            alert("종료 일시를 지정해주세요.")
            $("#end_minute").focus();
            return false;
        }

        if(new Date(this.state.start_date_format) > new Date(this.state.end_date_format)) {
            alert("시작일시가 종료일시 보다 미래 일 수 없습니다.\n다시 설정해 주세요.");
            $("#start_date").focus();
            return false;
        } else if(new Date(this.state.start_date_format).valueOf() == new Date(this.state.end_date_format).valueOf()) {
            alert("종료 일시는 시작 일시와 다르게 선택해 주세요. ");
            $("#start_date").focus();
            return false;
        }

        // 배너 문구 replace
        let replaceText = function(text){
            return text.replace(/(?:\r\n|\r|\n)/g, '<br />');
        };
        let body = {
            event_seq : this.state.event_seq,
            user_seq : this.state.user_seq,
            shop_no : "1",
            iframe_page_id : this.state.iframe_page_id,
            iframe_html_id : this.state.iframe_html_id,
            event_type_cd : "03999",
            event_mobile_type_cd : "07999",
            item_user_type : this.state.item_user_type,
            target_view_seq_list : this.state.selected_target_view_list.length != 0 ? this.state.selected_target_view_list.map(obj => obj.target_view_seq) : null,

            start_date : this.state.start_date_format,
            end_date : this.state.end_date_format,
            product_no : this.state.product_no,
            display_yn : this.state.display_yn,
            reg_user_seq : this.state.reg_user_seq,
            reg_date : this.state.reg_date,
            is_soldout_display : this.state.is_soldout_display,
            
            title : replaceText(this.state.title),
            content : replaceText(this.state.title),
            foreign_title : replaceText(this.state.foreign_title),
            sentence : replaceText(this.state.sentence),
            eng_sentence : replaceText(this.state.eng_sentence),
            
            color_code_1 : this.state.background,
            color_code_2 : this.state.background2,
            color_code_3 : this.state.background3,
            color_code_4 : this.state.background4,
            
            w_img_link_type : this.state.w_img_link_type,
            h_img_detail_link_type : this.state.h_img_detail_link_type,
            
            w_img_49002_link : this.state.w_img_49002_link,
            h_img_detail_49002_link : this.state.h_img_detail_49002_link,
            
            event_promotion_type_cd : "19002",
            simple_date_cd : this.state.simple_date_cd,
            link_url : this.state.link_url && this.state.is_link_url == "Y" ? "http://"+this.state.link_url.replace("https://","").replace("http://", "") : null,
        };
        
        if(this.state.w_img_link_type == "49001"){
            if (this.state.img_list_landscape != null) {
                body['img_link'] = this.state.img_list_landscape[0].org_img_path;
            } else{
                body['img_link'] = this.state.img_link;
            }
        } else{
            body['img_link'] = this.state.w_img_49002_link;
        }
        if(this.state.h_img_detail_link_type == "49001"){
            if (this.state.img_list_portrait != null) {
                body['img_detail_link'] = this.state.img_list_portrait[0].org_img_path; 
                body['h_img_detail_link'] = this.state.img_list_portrait[0].org_img_path; 
            } else{
                body['img_detail_link'] = this.state.img_detail_link;
                body['h_img_detail_link'] = this.state.img_detail_link;
            }
        } else{
            body['img_detail_link'] = this.state.h_img_detail_49002_link;
            body['h_img_detail_link'] = this.state.h_img_detail_49002_link;
        }

        if (this.state.img_list_landscape != null) {
            body['promotion_org_img_link_seq'] = this.state.img_list_landscape[0].promotion_org_img_seq; 
        }
        if (this.state.img_list_portrait != null) {
            body['promotion_org_img_detail_link_seq'] = this.state.img_list_portrait[0].promotion_org_img_seq;
        }
        
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }
		http.post("/rest/common/event/save", body, config)
		.then(res => {
            if (res.data.err_msg != null) {
                // alert(res.data.err_msg);
                if (res.data.err_code == 401) {
                    window.location.href = "/logout";
                }
            } else {
                let save_notice = "저장되었습니다.";
                if(res.data.is_change_order_no){
                    let save_page_name = this.state.page_list.find((obj) => obj.code_name == this.state.iframe_page_id)["val1"];
                    let save_html_name = this.state.html_list.find((obj) => obj.iframe_html_id == this.state.iframe_html_id)["iframe_html_name"];
                    save_notice += "\n이 배너는 ["+save_page_name+"]의 ["+save_html_name+"] 영역의 1번째 순서로 변경되었습니다.";
                }
                alert(save_notice);
                if(!this.getUrlParams()["event_seq"] && (this.state.item_user_type == "U" || this.state.item_user_type == "N" || this.state.item_user_type == "T")){
                    this.setState({
                        title_content_dialog_show_state : true,
                        title_content_dialog_title : "기본 배너 추가등록",
                        title_content_dialog_content : "노출 대상을 제외한 방문자가 방문시<br/>보여줄 기본 배너 이미지를 등록 해주세요.",
                        title_content_dialog_button_list: [
                            {
                                title: "네",
                                type: "1",
                                event_method: () => {
                                    this.props.history.replace(
                                        {
                                            pathname: `/shop/cafe24/promotion/form`,
                                            state: {
                                                createDefalutBanner : true,
                                                iframe_page_id : this.state.iframe_page_id,
                                                iframe_html_id : this.state.iframe_html_id,
                                                html_list : this.state.html_list,
                                            },
                                        }	
                                    )
                                }
                            },
                            {
                                title: "아니요",
                                type: "0",
                                event_method: () => {
                                    window.location.href="/shop/cafe24/promotion/form?event_seq="+res.data.event_seq;
                                }
                            }
                        ],
                        title_content_popup_close : () => {
                            window.location.href="/shop/cafe24/promotion/form?event_seq="+res.data.event_seq;
                        }
                    });
                } else if (this.getUrlParams()["event_seq"] != null) {
                    window.location.reload();
                } else {
                    window.location.href="/shop/cafe24/promotion/form?event_seq="+res.data.event_seq;
                }
            }
		})
		.catch(error => {
			// console.log ("===== error =====");
			// console.log (error);
			// console.log ("===== error request =====");
			// console.log(error.request);
		 });
    }

    loadCafe24Item() {
        $("#non_product_msg").css("display", "none");

        http.get("/rest/common/list/product?product_name="+this.state.product_name+"&user_seq="+this.state.user_seq+"&shop_no="+this.state.shop_no
        +"&startidx="+this.state.startIdx+"&rowcnt="+this.state.rowcnt
        +"&order_type="+this.state.sorting+"&order_gubun="+this.state.orderGubun+"&category_no="+this.state.category_no)
        .then( res => { 
            isTmp = false;
            this.setState({item_list : res.data.list, totalCnt : res.data.totalCnt});
            // console.log(res.data.list); 

            // 상품이 존재하지 않습니다.
            if(res.data.list.length == 0) {
                $("#non_product_msg").css("display", "block");
            } else {
                $("#non_product_msg").css("display", "none");
            }
        })
        .catch(
            error => { 
                // console.log("저장에 실패하였습니다."); 
                isTmp = true;
            }
        );

        // 자동배너 수정 - 상품검색
        ReactGA.event({
            category: "자동배너 수정",
            action: "상품검색 : " + this.state.product_name,
            label: ga_label
        });
    }

    onClickDisplayYn = (value) => {
        // $(".radio_display_yn:checked").prop("checked", false); 
        // $("#display_"+e.target.value).prop("checked", true); 

        // 진열여부 변경전 대상 페이지, 배너를 선택 해야함.
        if (this.state.iframe_page_id == null || this.state.iframe_page_id == ""){
            alert("적용 범위 - 페이지를 선택해 주세요.");
            $("#iframe_page_id_cd").focus();
            return false;
        }
        if (this.state.iframe_html_id == null || this.state.iframe_html_id == ""){
            alert("적용 범위 - 배너를 선택해 주세요.");
            $("#iframe_html_id_cd").focus();
            return false;
        }
        this.stroeOrSettingCnt(value);

        // 자동배너 수정 - 진열여부
        ReactGA.event({
            category: "자동배너 수정",
            action: "진열여부 : " + value,
            label: ga_label
        });
    };

    onChageLinkUrl = e => {
        console.log(e.target.value);
        this.setState({
            link_url : e.target.value,
        })
    }

    handleTitleBlur = e => {
        console.log(e);
        // 자동배너 수정 - 배너제목
        ReactGA.event({
            category: "자동배너 수정",
            action: (e.target.id == "foreign_title" ? "(영문)" : "") + "배너제목 : " + e.target.value,
            label: ga_label
        });
    };

    onChangeTitle = (e, name) => {
        if (name != "sub_title") {
            this.setState({[name]: e.target.value,
                            title: e.target.value});
        } else {
            this.setState({[name]: e.target.value,
                foreign_title: e.target.value,
                sentence: e.target.value,
                eng_sentence: e.target.value,});
        }
        // this.getTextLength (name+"_length", e.target.value);
    }

    // 글자수 체크 (추후에 반영 - 기획이 필요한 부분이 너무 많음)
    getTextLength = function(name, str) {
        this.setState({[name]: str.length});
    }

    onClickeFullCalendarEvent = (e) => {
        // if (this.state.pc_banner_ratio_list == null || this.state.mobile_banner_ratio_list == null) {
        //     alert("배너 미리보기를 하려면 적용범위(페이지와 배너)를 먼저 선택해 주세요.");
        //     return false;
        // }

        // 상품을 변경한 경우 또는 문구 색상 템플릿 등등..
        if (e.event.id == this.state.event_seq) {
            calendar_event_seq = -1;
        } else {
            calendar_event_seq = e.event.id;
        }

        //이벤트 번호가 없으면 배너 미리보기 출력
        if (calendar_event_seq != -1) {
            
            $(".fullCalendarModal").show();
            $(".fullCalendarModal").css("left", e.jsEvent.pageX+"px");
            $(".fullCalendarModal").css("top", e.jsEvent.pageY+"px");
            $(".fullCalendarModal").css("transform", "");

            http.get("/rest/shop/is/event/pcmobile?event_seq="+calendar_event_seq)
            .then(res => {
                this.setState({
                    isEventPcMobile: res.data.obj
                }, () => {
                    this.calendarBannerModalSetSize();
                });
            })

            $(".fullCalendarModal").show();
            $(".fullCalendarModal").css("left", e.jsEvent.pageX+"px");
            $(".fullCalendarModal").css("top", e.jsEvent.pageY+"px");
            $(".fullCalendarModal").css("transform", "");

        } else {
            this.onClickMirrorBannerOnOff(e, "on");
        }          
    }

    calendarBannerModalSetSize() {
        if(this.state.isEventPcMobile && this.state.isEventPcMobile.is_pc == "Y") {
            $(".fullCalendarInnerModal .pcBanner").html('<label>가로형</label><br><object class="calendar_robomd_banner_p" type="text/html" data="'+mirror_banner_url+'?event_seq='+calendar_event_seq+'&platform_type=p&mirror_type=18002" type="text/html" style="width:100%;height:100%;border: 1px solid black;border-radius: 13px;"></object>');
        
            $(".fullCalendarInnerModal .pcBanner").show();
            $(".fullCalendarModal").css("width", ((fullCalendarInnerModal_width/6.2)*2.2)+"px");
            $(".fullCalendarModal").css("min-width", ((fullCalendarInnerModal_width/6.2)*2.2)+"px");
        } else {
            $(".fullCalendarInnerModal .pcBanner").hide();
            $(".fullCalendarModal").css("width", ((fullCalendarInnerModal_width/6.2)*1.5)+"px");
            $(".fullCalendarModal").css("min-width", ((fullCalendarInnerModal_width/6.2)*1.5)+"px");
        }
        
        if(this.state.isEventPcMobile && this.state.isEventPcMobile.is_mobile == "Y") {
            $(".fullCalendarInnerModal .mobileBanner").html('<label>세로형</label><br><object class="calendar_robomd_banner_m" type="text/html" data="'+mirror_banner_url+'?event_seq='+calendar_event_seq+'&platform_type=m&mirror_type=18002" type="text/html" style="width:100%;height:100%;margin-left:1%;border: 1px solid black;border-radius: 13px;"></object>');
        
            $(".fullCalendarInnerModal .mobileBanner").show();
            $(".fullCalendarModal").css("width", ((fullCalendarInnerModal_width/6.2)*2.2)+"px");
            $(".fullCalendarModal").css("min-width", ((fullCalendarInnerModal_width/6.2)*2.2)+"px");
            if(!(this.state.isEventPcMobile && this.state.isEventPcMobile.is_pc == "Y")) {
                $(".fullCalendarInnerModal .pcBanner").hide();
                $(".fullCalendarModal").css("width", ((fullCalendarInnerModal_width/6.2)*0.8)+"px");
                $(".fullCalendarModal").css("min-width", ((fullCalendarInnerModal_width/6.2)*0.8)+"px");
            }
        } else {
            $(".fullCalendarInnerModal .mobileBanner").hide();
            $(".fullCalendarModal").css("width", ((fullCalendarInnerModal_width/6.2)*1.5)+"px");
            $(".fullCalendarModal").css("min-width", ((fullCalendarInnerModal_width/6.2)*1.5)+"px");
        }

        fullCalendarInnerModal_pcbanner_width = $(".fullCalendarInnerModal .pcBanner").width();
        fullCalendarInnerModal_mobileBanner_width = $(".fullCalendarInnerModal .mobileBanner").width();
        
        if (this.state.pc_banner_ratio_list.length > 0) {
            $(".fullCalendarInnerModal .pcBanner").height((fullCalendarInnerModal_pcbanner_width /  Number(this.state.selected_pc_banner_ratio.org_width_height)) + "px");
        } else {
            $(".fullCalendarInnerModal .pcBanner").height((fullCalendarInnerModal_pcbanner_width /  1.68) + "px");
        }
        if (this.state.mobile_banner_ratio_list.length > 0) {
            $(".fullCalendarInnerModal .mobileBanner").height((fullCalendarInnerModal_mobileBanner_width / Number(this.state.selected_mobile_banner_ratio.org_width_height))+ "px");
        } else {
            $(".fullCalendarInnerModal .mobileBanner").height((fullCalendarInnerModal_mobileBanner_width / 0.69)+ "px");
        } 
    }


    onClickFullCalendarModalClose = (e) => {
        $(".fullCalendarModal").hide();
    }

    onChangeDateSetting = (e, name) => {
       /* start_date: "",
        start_hour: "",
        start_minute: "",

        end_date: "",
        end_hour: "",
        end_minute: "",
        
        start_date_format: "",
        end_date_format: "", */
        is_change_date_setting = true;
        this.setState({[name]:e.target.value});
    }

        // input 달력 클릭
        handleChangeStartDate = date => {
            is_change_date_setting = true;
            this.setState({
                start_date: date ?? ""
            });
        };
        handleChangeEndDate = date => {
            is_change_date_setting = true;
            this.setState({
                end_date: date ?? ""
            });
        };

    onClickItemUserType = (e) => {
        const state = e.target.value;
        if (state == "U" || state == "N") {
            http.get("/rest/shop/solutionType")
            .then(
                resultData => { 
                    if(resultData.data.solution_type_cd != "09003") {
                        this.setState({item_user_type:state})
                    } else {
                        alert("아입웹 솔루션은 회원/비회원 기준을 등록 할 수 없습니다.")
                        return false;
                    }
                })
            .catch(
                error => { this.setState({item_user_type:state}) }
            );
        } else if (this.state.is_use_target_view_service == "N" && state == "T") {
            // 타겟뷰 권한 체크
            this.fn_shopServiceOperated("01009", () => {
                if(this.state.is_use_target_view_service == "N"){
                    this.setState({
                        title_content_dialog_show_state : true,
                        title_content_dialog_title : "타겟뷰 서비스<br/>신청이 필요한 기능입니다.",
                        title_content_dialog_content : "타겟뷰로 맞춤 팝업을 원하는 방문자에게<br/>효과적으로 노출해 보세요.",
                        title_content_dialog_button_list: [
                            {
                                title: "확인",
                                type: "1",
                                event_method: () => {
                                    // 결제 페이지 새창 열기
                                    window.open("/shop/serviceShopAdd?service_type=01009&step=1", '_blank'); // 새 창으로 열기
        
                                    this.setState({
                                        title_content_dialog_show_state: false,
                                    });
                                }
                            },
                            {
                                title: "아니요",
                                type: "0",
                                event_method: () => {
                                    this.setState({
                                        title_content_dialog_show_state: false,
                                    });
                                }
                            }
                        ]
                    });
                } else{
                    this.setState({item_user_type:state})
                }
            });
        } else {
            this.setState({item_user_type:state})
        }
    }

    onClickSimpleDateCd = (e, firstSetting) => {
        let state = firstSetting ?? e.target.value;
         this.setState({simple_date_cd:state});
         if(state == "17005") {
            $("#end_date_area").hide();
            is_change_date_setting = true;
            if (this.state.start_date == null || this.state.start_date == "") {
                this.setState({ 
                    start_hour:"0"
                    , start_minute:"0"
                    , start_date: new Date()
                    , end_hour:"23"
                    , end_minute:"59"
                    , end_date: new Date("9999-12-31")});
            } else {
                this.setState({ end_hour:"23"
                                , end_minute:"59"
                                , end_date: new Date("9999-12-31")});
            }
         } else if(state == "17002") {
            $("#end_date_area").show();
            is_change_date_setting = true;

            var nextDate = new Date()
            var dayOfMonth = nextDate.getDate()
            nextDate.setDate(dayOfMonth + 0)
            //nextDate = (nextDate.getFullYear() + '-' + (nextDate.getMonth() + 1) + '-' + nextDate.getDate())

            this.setState({ start_hour:"0"
                            , start_minute:"0"
                            , start_date: new Date()
                            , end_hour:"23"
                            , end_minute:"59"
                            , end_date: new Date(nextDate.getFullYear(), nextDate.getMonth(), nextDate.getDate())
                        });

         } else if(state == "17003") {
            $("#end_date_area").show();
            is_change_date_setting = true;

            var nextDate = new Date()
            var dayOfMonth = nextDate.getDate()
            nextDate.setDate(dayOfMonth + 7)
            //nextDate = (nextDate.getFullYear() + '-' + (nextDate.getMonth() + 1) + '-' + nextDate.getDate())

            this.setState({ start_hour:"0"
                            , start_minute:"0"
                            , start_date: new Date()
                            , end_hour:"23"
                            , end_minute:"59"
                            , end_date: new Date(nextDate.getFullYear(), nextDate.getMonth(), nextDate.getDate())
                        });
             
        } else if(state == "17004") {
            $("#end_date_area").show();
            is_change_date_setting = true;

            var nextDate = new Date()
            var dayOfMonth = nextDate.getDate()
            nextDate.setDate(dayOfMonth + 30)
            //nextDate = (nextDate.getFullYear() + '-' + (nextDate.getMonth() + 1) + '-' + nextDate.getDate())

            this.setState({ start_hour:"0"
                            , start_minute:"0"
                            , start_date: new Date()
                            , end_hour:"23"
                            , end_minute:"59"
                            , end_date: new Date(nextDate.getFullYear(), nextDate.getMonth(), nextDate.getDate())
                        });
             
        } else {
            $("#end_date_area").show();
         }

         if (state != "17001") {
            this.state.disabledStartDate = true;
            this.state.disabledEndDate = true;
            $("#start_date").attr("disabled", true); 
            $("#start_hour").attr("disabled", true); 
            $("#start_minute").attr("disabled", true); 
            $("#end_date").attr("disabled", true); 
            $("#end_hour").attr("disabled", true); 
            $("#end_minute").attr("disabled", true); 
        } else {
            this.state.disabledStartDate = false;
            this.state.disabledEndDate = false;
            $("#start_date").attr("disabled", false); 
            $("#start_hour").attr("disabled", false); 
            $("#start_minute").attr("disabled", false); 
            $("#end_date").attr("disabled", false); 
            $("#end_hour").attr("disabled", false); 
            $("#end_minute").attr("disabled", false); 
        }
     }   

     onClickMirrorBannerOnOff = (e, state) => {
        if(state == "on") {
            $(".mirror_banner_modal_v2").css("visibility", "visible");
            isTmp = true;
            this.setState({mirror_banner_state: state});
        } else {
            $(".mirror_banner_modal_v2").css("visibility", "hidden");
            this.setState({mirror_banner_state: state});
        }
    }

    onChangeSelectBoxCommonHandler = (e, name) => {
        var index = e.nativeEvent.target.selectedIndex;

        if (name == "iframe_page_id") {
            this.setState({[name]: e.nativeEvent.target[index].value});
            this.getRobomdPageHtmlId("html", e.target.value);
        } else {
            is_banner_ratio_loaded = true
            this.setState({[name]: e.nativeEvent.target[index].value});
        }
    }

    onClickBackButton = e => {
        console.log(this.props.location.state)
        if (this.props.location.state != null && this.props.location.state.prevPath.indexOf("/shop/cafe24/event/list") > 0){
            // alert("레퍼러")
            this.props.history.goBack();
        } else {
            // alert("로케이션")
            this.props.history.push("/shop/cafe24/event/list")
        }
    }

    onChangeMirrorBannerRatio = (e, idx) => {
        
        var index = e.nativeEvent.target.selectedIndex;
        var width_height = e.nativeEvent.target[index].value;
        if (Number(width_height) > 1) {
            this.setState({
                // 원본 비율 목록에서도 현재 셀렉트 박스에 선택된 인덱스 번호에 해당하는 원본 비율을 선택함.
                selected_pc_banner_ratio:  this.state.mobile_banner_ratio_list.length > index 
                                                ? this.state.mobile_banner_ratio_list[index]
                                                :  this.state.pc_banner_ratio_list.length > index 
                                                    ? this.state.pc_banner_ratio_list[index]
                                                    : {},
            }, () => {this.setHeight()});
        } else {
            this.setState({
                // 원본 비율 목록에서도 현재 셀렉트 박스에 선택된 인덱스 번호에 해당하는 원본 비율을 선택함.
                selected_mobile_banner_ratio:  this.state.mobile_banner_ratio_list.length > index 
                                                ? this.state.mobile_banner_ratio_list[index]
                                                :  this.state.pc_banner_ratio_list.length > index 
                                                    ? this.state.pc_banner_ratio_list[index]
                                                    : {},
            }, () => {this.setHeight()});
        }
        isTmp = true;
    }

    // 업로드된 이미지 Preview
    attachImage(acceptedFiles, type) {
        if (acceptedFiles) {
            /* Get files in array form */
            const files = Array.from(acceptedFiles);
            //const uploadFiles = Array.from(e.target.files);
            //const { getRootProps, getInputProps, isDragActive } = useDropzone({ files });
            /* Map each file to a promise that resolves to an array of image URI's */ 
            Promise.all(files.map(file => {
                return (new Promise((resolve,reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            }))
            .then(images => {
                /* Once all promises are resolved, update state with image URI array */
                console.log(images);
                console.log(files);
                console.log(this);
                if(type == "portrait") {
                    this.setState({
                        uploadFiles2 : files,
                        imageArray2 : images 
                    });
                } else {
                    this.setState({
                        uploadFiles1 : files,
                        imageArray1 : images 
                    });
                }
                isTmp = true;
                //const {uploadFiles, imageArray} = useState(images);
                var fileNames = "";
                files.map(file => {
                    fileNames += file.name + " "
                })
                if(type == "portrait") {
                    // $("#attachFileName2").text(fileNames);
                    this.setState({img_detail_link_name: fileNames});
                    $("#btnSave2").click();
                } else {
                    this.setState({img_link_name: fileNames});
                    $("#btnSave1").click();
                }
            }, error => {        
                console.error(error);
            });
        }
    }
    changeImgLinkType(name, link_type){
        this.setState({
            [name] : link_type,
            insert_type : "N",
        }, () => {
            this.insertEventTmp('preview');
        });
    }

    // 이미지 업로드 팝업 : 업로드 이벤트
    _handleImageChange = (e, type) => {
        this.attachImage(e.target.files, type)
    };

    // 이미지 업로드 팝업 : 파일첨부 열기
    handleClickBtnFileUpload1  = e => { 
        e.preventDefault ();
        $("#promotionFiles1").click();	
    };

    // 이미지 업로드 팝업 : 파일첨부 열기
    handleClickBtnFileUpload2  = e => { 
        e.preventDefault ();
        $("#promotionFiles2").click();	
    };

    // 이미지 업로드 팝업 : 저장 이벤트
    _handleSubmit1 = e => {
        e.preventDefault();
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        const url = '/rest/shop/promotion/save/orgImg';
        const formData = new FormData();
        const uploadFiles = this.state.uploadFiles1;
        uploadFiles.forEach((file) => formData.append("files", file));
        
        http.post(url, formData, config)
        .then(
            res => {
                alert("이미지 업로드 완료");
                // 조회
                this.searchOrgImg(res.data.promotion_org_img_seq, "landscape");
                $("#modalBack").hide();
                $("#modal_event_display").hide();
                $("body").css("background-color", "white");
                $("#modalBack").css("background-color", "white");
                $("#page-wrapper").css("background-color", "white");
            })
        .catch(
            error => { alert(error+"\n관리자에게 문의해 주세요."); }
        );
    };

    // 이미지 업로드 팝업 : 저장 이벤트
    _handleSubmit2 = e => {
        e.preventDefault();
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        const url = '/rest/shop/promotion/save/orgImg';
        const formData = new FormData();
        const uploadFiles = this.state.uploadFiles2;
        uploadFiles.forEach((file) => formData.append("files", file));
        
        http.post(url, formData, config)
        .then(
            res => {
                alert("이미지 업로드 완료");
                // 조회
                this.searchOrgImg(res.data.promotion_org_img_seq, "portrait");
                $("#modalBack").hide();
                $("#modal_event_display").hide();
                $("body").css("background-color", "white");
                $("#modalBack").css("background-color", "white");
                $("#page-wrapper").css("background-color", "white");
            })
        .catch(
            error => { alert(error+"\n관리자에게 문의해 주세요."); }
        );
    };

    async searchOrgImg(promotion_org_img_seq, type) {
        await http.get("/rest/shop/promotion/search/orgImg?promotion_org_img_seq="+promotion_org_img_seq)
        .then(res => {
                    var keyName = "img_list_"+type
                    console.log(res.data["list"]);
                    isTmp = true;
                    this.setState( {
                        [keyName] : res.data["list"],
                        insert_type: "N"
                    });
                    //this.setState( {totalCnt : res.data["totalCnt"]} );
        })
        .catch(error => {
            alert(error+"\n관리자에게 문의해 주세요.");
        });
    }

    /* 이미지 주소 입력 팝업 열기 */
    openImg49002LinkUrl = (name) => {
        window_scrollTop = StyleUtil.onPopupNoneScroll(window_scrollTop);
        this.setState({
            input_dialog_show_state: true,
            input_dialog_title: "배너에 사용될 이미지 주소를 입력해주세요.",
            input_dialog_input_value: this.state[name],
            input_dialog_button_list: [
                {
                    title: "적용",
                    type: "1",
                    event_method: (e) => {
                        this.setState({input_dialog_show_state: false});
                        StyleUtil.clearPopupNoneScroll(window_scrollTop);
                        this.onChangeImg49002LinkUrl(name, e.target.name);
                        $("#blockPageClick").show();
                    }
                },
                {
                    title: "취소",
                    type: "0",
                    event_method: () => {
                        this.setState({input_dialog_show_state: false});
                        StyleUtil.clearPopupNoneScroll(window_scrollTop);
                    }
                }
            ]
        });
    }

    /* 링크 입력형 이미지 주소 입력 */
    onChangeImg49002LinkUrl(name, value){
        const org_value = this.state[name];

        if(value == org_value){
            return false;
        }
        
        this.setState({
            [name] : value,
        }, () => {
            const this_component = this;
            let action_cnt = 0; // jquery가 두번 동작하는 것을 막는다
            $("#"+name+"_check").on("error",function(){
                if(action_cnt == 0){
                    $("#blockPageClick").hide();
                    // 공백인 경우에는 에러로 체크하지 않는다
                    if(value != ""){
                        alert("허용하지 않는 이미지 주소입니다.");
                        this_component.setState({
                            [name] : org_value,
                            insert_type : "N",
                        }, () => {
                            this_component.insertEventTmp('preview');
                        });
                    }
                }
                action_cnt++;
            });
            $("#"+name+"_check").on("load",function(){
                if(action_cnt == 0){
                    this_component.setState({
                        [name+"_state"] : true,
                        insert_type : "N",
                    }, () => {
                        this_component.insertEventTmp('preview');
                    });
                }
                action_cnt++;
            });
            if(value == ""){
                this.setState({
                    [name+"_state"] : false,
                    insert_type : "N",
                }, () => {
                    this.insertEventTmp('preview');
                });
                $("#blockPageClick").hide();
            }

        });
    }

    onClickShortcutFocusing = (e, element_id) => {
        $("#"+element_id).focus();
    }

    stroeOrSettingCnt(state) {
        // 배너 설치 여부
        var is_install = false;

        http.get("/rest/shop/stroeOrSettingCnt?iframe_page_id="+this.state.iframe_page_id+"&iframe_html_id="+this.state.iframe_html_id)
        .then(res => {
            // alert(res.data.stroeOrSettingCnt.banner_operateed_yn)
            if (res.data.freeDatePeriod != null) {
                if(res.data.freeDatePeriod.is_finish == "1") {
                    if (res.data.stroeOrSettingCnt.banner_operateed_yn == 'Y') {
                        is_order = true;
                    }
                    is_free_finish = true;
                } else {
                    if (res.data.stroeOrSettingCnt.banner_operateed_yn == 'Y') {
                        is_free_finish = true;
                        is_order = true;
                    } else {
                        is_free_finish = false;
                    }
                }

                // 설치 여부 확인
                if (res.data.bannerInstallInfo != null) {
                    if (res.data.bannerInstallInfo.install_yn == "Y") {
                        is_install = true;
                    }
                }
            }

            if (state == "Y") {
                if(is_install != true) {
					alert("배너를 진열하려면 고객님 쇼핑몰에 스크립트를 설치해야 합니다. 화면 왼쪽 아래 채팅 아이콘을 눌러 로보MD팀에게 설치를 신청해 주세요.");
					return false;
				}
                if(is_free_finish == true) {
                    if(is_order != true) {
                        alert("자동 진열 기능은 멤버십 결제 후 이용하실 수 있습니다. 결제 후 진열하기로 변경해 주세요.");
                        return false;
                    }
                } 
                // else {
                //     if(is_order != true) {
                //         alert("무료기간 중에는 진열로 변경 할 수 없습니다.");
				// 		return false;
				// 	}
                // }
            }

            this.setState({display_yn : state})

            // if (state == 'Y') {
            //     if(new Date(this.state.start_date_format) <= new Date()
            //     && new Date() <= new Date(this.state.end_date_format)) 
            //     {
            //         this.setState({display_yn : state})
            //     } else {
            //         alert("진열 기간을 먼저 변경해 주세요.");
            //         $(window).scrollTop($("#date_setting_title").offset().top - 100);
            //     }
            // } else {
            //     this.setState({display_yn : state})
            // }
        })
        .catch(error => {
            alert(error); 
        });
    }

    onClickBannerFullSize = (type) => {
        var event_seq = "";
        if (this.state.event_seq) {
            event_seq = this.state.event_seq;
        } else {
            event_seq = dateString
        }

        // 가로형에서 풀사이즈 클릭시
        if(type == "w") {
            if (window.innerWidth > 800) {
                if ((window.innerHeight * this.state.selected_pc_banner_ratio.org_width_height) > window.innerWidth) {
                    Menu.openBlankModal(
                        '<object class="robomd_banner_p" type="text/html" data="'+object_domain+'/admin_banner.html?event_seq='+event_seq+'&platform_type=p&mirror_type=18001" type="text/html" style="width:95vw;height:calc(95vw / '+this.state.selected_pc_banner_ratio.org_width_height+'); max-width: 95vw; max-height: 80vh; background: white;"></object>'
                    );
                } else {
                    Menu.openBlankModal(
                        '<object class="robomd_banner_p" type="text/html" data="'+object_domain+'/admin_banner.html?event_seq='+event_seq+'&platform_type=p&mirror_type=18001" type="text/html" style="width:calc(80vh * '+this.state.selected_pc_banner_ratio.org_width_height+');height:80vh; max-width: 95vw; max-height: 80vh; background: white;"></object>'
                    );
                }
            } else {
                Menu.openBlankModal(
                    '<object class="robomd_banner_p" type="text/html" data="'+object_domain+'/admin_banner.html?event_seq='+event_seq+'&platform_type=p&mirror_type=18001" type="text/html" style="width:80vw;height:calc(80vw / '+this.state.selected_pc_banner_ratio.org_width_height+'); max-width: 95vw; max-height: 70vh; background: white;"></object>'
                );
            }
        } else { // 세로형에서 풀사이즈 클릭시
            if (window.innerWidth > 800) {
                Menu.openBlankModal(
                    '<object class="robomd_banner_p" type="text/html" data="'+object_domain+'/admin_banner.html?event_seq='+event_seq+'&platform_type=m&mirror_type=18001" type="text/html" style="width:calc(80vh * '+this.state.selected_mobile_banner_ratio.org_width_height+');height:80vh; max-width: 95vw; max-height: 80vh; background: white;"></object>'
                );
            } else {
                if ((window.innerHeight * this.state.selected_mobile_banner_ratio.org_width_height) > window.innerWidth) {
                    Menu.openBlankModal(
                        '<object class="robomd_banner_p" type="text/html" data="'+object_domain+'/admin_banner.html?event_seq='+event_seq+'&platform_type=m&mirror_type=18001" type="text/html" style="width:75vw;height:calc(75vw / '+this.state.selected_mobile_banner_ratio.org_width_height+'); max-width: 75vw; max-height: 70vh; background: white;"></object>'
                    );
                } else {
                    Menu.openBlankModal(
                        '<object class="robomd_banner_p" type="text/html" data="'+object_domain+'/admin_banner.html?event_seq='+event_seq+'&platform_type=m&mirror_type=18001" type="text/html" style="width:calc(70vh * '+this.state.selected_mobile_banner_ratio.org_width_height+');height:80vh; max-width: 75vw; max-height: 70vh; background: white;"></object>'
                    );
                }
            }
        }

        var this_component = this;
        $(window).resize( function() {
            if ($("#common_blank_modal .robomd_banner_p")) {
                if(type == "w") {
                    if (window.innerWidth > 800) {
                        if ((window.innerHeight * this_component.state.selected_pc_banner_ratio.org_width_height) > window.innerWidth) {
                            $("#common_blank_modal .robomd_banner_p").css("width", "95vw");
                            $("#common_blank_modal .robomd_banner_p").css("height", 'calc(95vw / '+this_component.state.selected_pc_banner_ratio.org_width_height+')');
                        } else {
                            $("#common_blank_modal .robomd_banner_p").css("height", "80vh");
                            $("#common_blank_modal .robomd_banner_p").css("width", 'calc(80vh * '+this_component.state.selected_pc_banner_ratio.org_width_height+')');
                        }
                    } else {
                        $("#common_blank_modal .robomd_banner_p").css("width", '80vw');
                        $("#common_blank_modal .robomd_banner_p").css("height", 'calc(80vw / '+this_component.state.selected_pc_banner_ratio.org_width_height+')');
                    }
                } else {
                    if (window.innerWidth > 800) {
                        $("#common_blank_modal .robomd_banner_p").css("height", '80vh');
                        $("#common_blank_modal .robomd_banner_p").css("width", 'calc(80vh * '+this_component.state.selected_mobile_banner_ratio.org_width_height+')');
                    } else {
                        if ((window.innerHeight * this_component.state.selected_mobile_banner_ratio.org_width_height) > window.innerWidth) {
                            $("#common_blank_modal .robomd_banner_p").css("width", '75vw');
                            $("#common_blank_modal .robomd_banner_p").css("height", 'calc(75vw / '+this_component.state.selected_mobile_banner_ratio.org_width_height+')');
                        } else {
                            $("#common_blank_modal .robomd_banner_p").css("height", '70vh');
                            $("#common_blank_modal .robomd_banner_p").css("width", 'calc(70vh * '+this_component.state.selected_mobile_banner_ratio.org_width_height+')');
                        }
                    }
                }
            }
        })
    }

    // 달력으로 확인하기 클릭
    onClickShowCalendar(){
        if($("#calendar_div").css('display') === 'none'){
            // resize를 통해 열기 전 화면 크기가 바뀌었더라도, 다시 달력 비율을 조정한다
            window.dispatchEvent(new Event('resize'));
            $("#calendar_div").css({display:'', height:'auto'});
        } else{
            $("#calendar_div").css({display:'none'});
        }
    }

    /** 캡처되는 실제 사이즈 계산 */
    getCaptureSize(type, banner_ratio){
        let banner_width = 0;
        let banner_height = 0;
        
        if(type == "pc") {
            banner_width = 1000 * banner_ratio.org_width_height;
            banner_height = 1000;
            if (banner_width > 10000 || banner_height > 10000) {
                banner_width = 10000
                banner_height = 10000 / banner_ratio.org_width_height;
            }
        } else {
            banner_width = 1000 * banner_ratio.org_width_height;
            banner_height = 1000;
            if (banner_width > 10000 || banner_height > 10000) {
                banner_width = 10000 / banner_ratio.org_width_height;
                banner_height = 10000
            }
        }
        banner_ratio["banner_width"] = banner_width;
        banner_ratio["banner_height"] = banner_height;

        return banner_ratio;
    }

    /** 타겟 선택하기 */
    onChangeTargetViewSelect = (selectedOption) => {
        let originSelectedOption = this.state.selected_target_view_list;
        let selecteList = this.state.target_view_list;
        const noLimitCode = -1;
        const selectAll = -2;

        // 기존 모든 타겟 선택
        if(selectedOption.find((obj) => obj.target_view_seq == selectAll)){
            selectedOption = selecteList.filter(obj => obj.target_view_seq != noLimitCode && obj.target_view_seq != selectAll);
        } else if(selectedOption.find((obj) => obj.target_view_seq == noLimitCode)){ // 모든 타겟 선택
            // 변경 전에도 모든 타겟 값이 포함되어 있었다면, 모든 타겟이 아닌 새로운 값을 추가한 것
            if(originSelectedOption.find((obj) => obj.target_view_seq == noLimitCode)){
                selectedOption = selectedOption.filter(obj => obj.target_view_seq != noLimitCode);
            } else{ // 모든 타겟 값이 새로 추가된 경우
                selectedOption = selectedOption.filter((obj) => obj.target_view_seq == noLimitCode);
            }
        }

        this.setState({ selected_target_view_list: selectedOption });
    };

    // 사용자 멀티샵 서비스 무료체험, 결제여부 확인
    fn_shopServiceOperated = (service_type, callback=()=>{}) => {
        http.get("/rest/shop/serviceOperated/"+service_type)
        .then(res => {
            if(res.data.code == "0000"){
                if(service_type == "01009" 
                    && (
                            res.data.shopServiceOperated_map.is_finish == "0" 
                            || res.data.shopServiceOperated_map.is_finish == "1"        //타겟뷰 서비스 : 무료체험 구분 없음
                            || res.data.shopServiceOperated_map.is_payment == "1"
                        )
                ){
                    this.setState({is_use_target_view_service : "Y"});
                } else{
                    this.setState({
                        is_use_target_view_service : "N",       // is_finish : -1 미신청시 해당 팝업 출력
                    });
                }
            }
            return res;
        })
        .then((res) => {
            callback(res);
        })
		.catch(error => {
			console.log (error);
		});
    }

    getTargetViewList = () => {
        http.get("/rest/shop/selectbox/targetview/list")
        .then(res => {
            if(res.data.target_view_list){
                let target_view_list = res.data.target_view_list;
                if(target_view_list.length > 0){
                    target_view_list = [
                        {title : "모든 타겟 (새롭게 추가되는 모든 타겟 리스트를 포함)", target_view_seq: -1},
                        {title : "기존 타겟 전체 선택", target_view_seq: -2},
                        ...target_view_list
                    ];
                }
                this.setState({target_view_list : target_view_list})
            }
        })
		.catch(error => {
			console.log (error);
		});
    }

    goTargetViewSetting = () => {
        window.open("/shop/targetview/setting", '_blank'); // 새 창으로 열기
    }

	render() {
        $(".pageName").text("배너 업로드");
        return (
            <div id='page-wrapper' className="container eventFormPromotion" style={{backgroundColor: '#ffffff', minHeight:"100%", overflowX: "hidden"}}>
                <div id="setting_container">
					<div className="form_primary_title pc_page_title">배너 업로드</div>
                    </div> 

                    {/* <div className="form_primary_title">
                    적용 범위 설정
                    </div> */}
                    <div className="m-t-43">
                        {/* <div style={{display:"flex"}}> 
                            <div className="icn_round_blue_small"></div>
                            &nbsp;&nbsp;
                            <div className="form_title" style={{flex:"1", marginTop: "auto", marginBottom: "auto"}}>프로모션 배너의 제목을 입력해 주세요.</div>
                        </div> */}
                        <div className="margin-vertical-auto flexBox" style={{flex:"1",marginTop: "15px"}}>
                        <div style={{width: "500px", maxWidth: "calc(95% - 15px)"}}>
                            <div className="input_text_title_d">배너 이름</div>
                                <input id="title" type="text" className="border_form_radius_b f-s-15 input_width_100 input_height_35px" value={this.state.non_auto_title} onChange={e => this.onChangeTitle(e, "non_auto_title")} onBlur={this.handleTitleBlur} placeholder="배너 리스트 이름을 입력해 주세요."></input>
                            </div>
                        </div>
                    </div>
                    <div className="m-t-43">
                        {/* <div className="flexBox"> 
                            <div className="icn_round_blue_small"></div>
                            &nbsp;&nbsp;
                            <div className="form_title" style={{marginTop: "auto", marginBottom: "auto"}}>적용 범위를 설정해 주세요.</div>
                        </div>
                        <div className="flexBox m-t-32" style={{marginBottom: "15px"}}> 
                            <div id="item_user_type" className="m-l-34" style={{marginTop: "auto", marginBottom: "auto", fontSize:"15px"}}>어느 영역에 배너를 삽입 하시겠습니까?</div>
                        </div> */}
                        <div>
                            <div className="input_text_title_d eventSettingTitle" style={{zIndex:"999"}}><label style={{marginTop: "auto", marginBottom: "auto"}}>페이지 선택</label></div>
                            <div style={{display:"flex", width:"500px", maxWidth: "calc(95% - 15px)", position:"relative", marginBottom: '12px'}}>
                                <select id="iframe_page_id_cd" className="border_form_radius_b" value={this.state.iframe_page_id} onChange={e => this.onChangeSelectBoxCommonHandler(e, "iframe_page_id")}>
                                    <option value="" disabled selected>페이지를 선택해 주세요.</option>
                                    {this.state.page_list.map((page, idx) => ( 
                                        <option key={idx} value={page.code_name}>{page.val1}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div>
                            <div className="input_text_title_d eventSettingTitle" style={{zIndex:"999"}}><label style={{marginTop: "auto", marginBottom: "auto"}}>배너 선택</label></div>
                            <div style={{display:"flex", width:"500px", maxWidth: "calc(95% - 15px)", position:"relative", marginBottom: '12px'}}>
                                {
                                    this.state.html_list.length > 0
                                    ?   <select id="iframe_html_id_cd" className="border_form_radius_b" value={this.state.iframe_html_id} onChange={e => this.onChangeSelectBoxCommonHandler(e, "iframe_html_id")}>
                                            <option value="" disabled selected>배너를 선택해 주세요.</option>
                                            {this.state.html_list.map((html, idx) => (
                                                <option key={idx} value={html.iframe_html_id}>{html.iframe_html_name}</option>
                                            ))}
                                        </select>
                                    :   <select id="iframe_html_id_cd" className="border_form_radius_b" value={""} onChange={e => this.onChangeSelectBoxCommonHandler(e, "iframe_html_id")}>
                                            <option value="" disabled selected>페이지를 먼저 선택해 주세요.</option>
                                        </select>
                                }
                                
                            </div>
                        </div>

                        {/* <div className="margin-vertical-auto flexBox m-l-34 m-t-32">
                                <div className="icn_warning_round"></div>
                                <div style={{marginLeft:"10px", flex:"1"}}>로보MD 배너가 삽입 되어 있는 <b>페이지</b>와 <b>배너영역</b> 2개 모두 선택해 주세요.</div>
                        </div>
                        <div className="margin-vertical-auto flexBox m-l-34 m-t-15">
                                <div className="icn_warning_round"></div>
                                <div style={{marginLeft:"10px", flex:"1"}}><b>페이지</b>와 <b>배너</b>를 선택하시면 해당 배너의 <b>사이즈 비율</b>을 자동으로 불러옵니다. (미리보기용)</div>
                        </div> */}

                        <div className="flexBox m-t-43" style={{marginBottom: "15px"}}> 
                            <div id="item_user_type" style={{marginTop: "auto", marginBottom: "auto", fontSize:"15px"}}>배너 진열 타겟</div>
                        </div>
                        <div className={"event_item_user_type_radio"}>
                            <div className="redio_v2 m-l-34 m-t-32 event_content_radio">
                            {
                                [{type:"A", text:"모두"},{type:"U", text:"회원"},{type:"N", text:"비회원"}].map((obj, idx) => (
                                    <RadioButton
                                        id={"item_user_type"+obj.type}
                                        name={"item_user_type"}
                                        value={obj.type}
                                        checked={this.state.item_user_type == obj.type}
                                        onChangeCheck={this.onClickItemUserType.bind(this)}
                                    >
                                        <div className="banner_radio_text">{obj.text}</div>
                                    </RadioButton>
                                ))
                            }
                            </div>
                            <div className={"item_user_type_tootip"}>
                                <RadioButton
                                    name={"item_user_type"}
                                    value={"T"}
                                    checked={this.state.item_user_type == "T"}
                                    onChangeCheck={this.onClickItemUserType.bind(this)}
                                >
                                    <div className={"EventFormPromotionTargetViewLabel"}>타겟뷰</div>
                                    <div className={["banner_radio_text", "radio_tooltip"].join(' ')}>방문자별 맞춤 노출</div>
                                    <img src={crown_icon} style={{width:"18px", height:"18px", marginRight:"6px"}}/>
                                    <Tooltip
                                        className={"target_view_tooltip"}
                                        zIndex={4}
                                        iconWidth={22.2}
                                        iconHeight={22.2}
                                        squareMoveLeft={this.state.innerWidth < 400 ? 20 : 0}
                                        content={"세분화된 고객 분류 기준 설정 기능을 통해 로보MD에서 생성한 콘텐츠를 원하는 고객별로 맞춤 노출하는 타겟 마케팅이 가능한 서비스에요."}
                                    />
                                    <div style={{color: "#ACACAC", fontSize: "12px", marginLeft:"2px", whiteSpace: "nowrap",position:"relative"}}>타겟뷰란</div>
                                </RadioButton>
                                <RadioButton
                                    name={"item_user_type"}
                                    value={"D"}
                                    checked={this.state.item_user_type == "D"}
                                    onChangeCheck={this.onClickItemUserType.bind(this)}
                                >
                                    <div className={["banner_radio_text", "radio_tooltip"].join(' ')}>타겟 미선정 기본노출</div>
                                    <Tooltip
                                        zIndex={4}
                                        iconWidth={22.2}
                                        iconHeight={22.2}
                                        squareMoveLeft={this.state.innerWidth < 400 ? 20 : 0}
                                        content={"세분화된 고객 세그먼트 설정 기능을 통해 로보MD에서 생성한 고객이 미선정되거나, 상품이 자동으로 미선정될 시에 배너코너가 비어보이지 않도록 예비용으로 노출하는 배너이미지 서비스에요."}
                                    />
                                    <div style={{color: "#ACACAC", fontSize: "12px", marginLeft:"2px", whiteSpace: "nowrap",position:"relative"}}>타겟 미선정시 기본노출이란?</div>
                                </RadioButton>
                            </div>
                            {this.state.item_user_type == "T" && this.state.is_use_target_view_service == "Y" &&
                                (this.state.target_view_list.length > 0
                                ?<div style={{marginLeft:"62px"}}>
                                    <div style={{maxWidth: "600px", width: "100%", marginBottom: "10px", zIndex: 3, position: "relative"}}>
                                        <Select
                                            className={"react-select"}
                                            value={this.state.selected_target_view_list}
                                            closeMenuOnSelect={false}
                                            isMulti
                                            components={animatedComponents}
                                            placeholder={"타겟을 선택해 주세요"}
                                            getOptionValue={(option) => option.target_view_seq}
                                            getOptionLabel={(option) => option.title}
                                            noOptionsMessage={() => "없음"}
                                            options={this.state.target_view_list}
                                            onChange={selectedOption => this.onChangeTargetViewSelect(selectedOption)}
                                            styles={reactSelectStyles()}
                                        />
                                    </div>
                                    <span style={{width:"100%"}}>
                                        <Alert type={"info"} text={"설정한 조건에 포함되는 고객이 없는 경우, 팝업이 노출되지 않으니 주의해 주세요."}/>
                                    </span>
                                </div>
                                :<>
                                    <div style={{display:"flex", fontSize:"15px", alignItems: "center", marginLeft:"62px"}}>
                                        <div style={{marginRight:"10px"}}>아직 등록된 타겟이 없어요.<br/>타겟을 설정하고 나의 콘텐츠를 노출해 보세요.</div>
                                        <Button fontSize={"14px"} width={"120px"} height={"36px"} minWidth={"86px"} onClick={this.goTargetViewSetting}>
                                            타겟 설정하기
                                        </Button>
                                    </div>
                                    <div style={{display:"flex", fontSize:"15px", marginTop:"20px", alignItems: "center", marginLeft:"62px"}}>
                                        <div style={{marginRight:"10px"}}>타겟 설정 이후 버튼을 눌러 주세요.</div>
                                        <Button type={"hover_white"} fontSize={"12px"} width={"80px"} height={"24px"} minWidth={"70px"} onClick={this.getTargetViewList}>
                                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", color:"#3a3a3a", paddingTop:"1px"}}>
                                                <img src={curved_arrow_right} style={{width:"14px", height:"14px", marginRight: "4px", marginBottom:"1px"}}/>
                                                새로고침
                                            </div>
                                        </Button>
                                    </div>
                                </>
                                )
                            }
                        </div>
                    </div>

                    {   this.state.iframe_html_id != null && this.state.iframe_html_id != "" &&
                        (this.state.pc_banner_ratio_list.length > 0 || this.state.mobile_banner_ratio_list.length > 0)
                        ? <> 
                            {
                                this.state.pc_banner_ratio_list.length > 0
                                ?   <div className="m-t-43">
                                        <div style={{display:"flex"}}> 
                                            <div className="icn_round_blue_small"></div>
                                            &nbsp;&nbsp;
                                            <div className="form_title" style={{flex:"1", marginTop: "auto", marginBottom: "auto"}}>가로형 이미지 등록</div>
                                        </div>
                                        <div className="redio_v2" style={{display: "block"}}>
                                            <div className="flexBox radio_area" style={{height: "auto", alignItems: "center"}}>
                                                <RadioButton
                                                    id={"w_img_link_type_49001"}
                                                    checked={this.state.w_img_link_type == "49001"}
                                                    onChangeCheck={() => this.changeImgLinkType("w_img_link_type", "49001")}
                                                />
                                                <div className="margin-vertical-auto flexBox" style={{flex:"1", margin: "0 0 20px 10px"}}>
                                                    <div style={{width: "100%", maxWidth: "741px"}}>
                                                        <div className="input_text_title_d">선택된 이미지 파일</div>
                                                        {this.state.w_img_link_type == "49001"
                                                        ?<form onSubmit={this._handleSubmit1} enctype="multipart/form-data">
                                                            <div style={{display:"flex", height: "63px"}}>
                                                                <input type="file" id="promotionFiles1" name="promotionFiles" onChange={e => this._handleImageChange(e, "landscape")} accept="image/jpg,image/png,image/jpeg,image/gif,image/bmp,image/tiff" value="" style={{display:'none'}}/> 
                                                                <div className="border_form_radius_b fileName line_clamp" style={{float:"left", flex:1, lineHeight: "0px", textOverflow:"ellipsis", overflow:"hidden", fontSize:"14px"}} onClick={this.handleClickBtnFileUpload1}>{this.state.img_link_name}</div>
                                                                <button className="btn-175-b border_form_radius_b_btn" onClick={this.handleClickBtnFileUpload1}>파일첨부</button>
                                                                <button type="submit" id="btnSave1" className="greenButton" style={{float:"right", display:"none"}}>저장</button> 
                                                            </div>
                                                        </form>
                                                        :
                                                            <div style={{display:"flex", height: "63px"}}>
                                                                <input type="file" id="promotionFiles1" name="promotionFiles" accept="image/jpg,image/png,image/jpeg,image/gif,image/bmp,image/tiff" value="" style={{display:'none'}}/> 
                                                                <div className="border_form_radius_b_disabled_div fileName" style={{float:"left", flex:1, lineHeight: "0px", textOverflow:"ellipsis", overflow:"hidden", fontSize:"14px"}}>{this.state.img_link_name}</div>
                                                                <button className="btn-175-b-disabled border_form_radius_b_btn">파일첨부</button>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flexBox radio_area" style={{height: "auto", alignItems: "center"}}>
                                                <RadioButton
                                                    id={"w_img_link_type_49002"}
                                                    checked={this.state.w_img_link_type == "49002"}
                                                    onChangeCheck={() => this.changeImgLinkType("w_img_link_type", "49002")}
                                                />
                                                <img id={"w_img_49002_link_check"} style={{display : "none"}} src={this.state["w_img_49002_link"]}
                                                    onLoad={() => { this.setState({w_img_49002_link_state : true}); }}
                                                    onError={() => { this.setState({w_img_49002_link_state : false}); }} />
                                                <div className="flexBox" style={{flex:"1", margin: "0 0 20px 10px"}}> 
                                                    <div style={{width: "100%", maxWidth: "741px"}}>
                                                        <div className="input_text_title_d">이미지 주소</div>
                                                        {this.state.w_img_link_type == "49002"
                                                        ?<div style={{display:"flex", height: "63px"}}>
                                                            <div className='border_form_radius_b fileName line_clamp' style={{float:"left", flex:1, lineHeight: "0px", textOverflow:"ellipsis", overflow:"hidden", fontSize:"14px"}} onClick={() => this.openImg49002LinkUrl("w_img_49002_link")}>{this.state.w_img_49002_link}</div>
                                                            <button className="btn-175-b border_form_radius_b_btn" onClick={() => this.openImg49002LinkUrl("w_img_49002_link")}>이미지 주소 입력</button>
                                                        </div>
                                                        :<div style={{display:"flex", height: "63px"}}>
                                                            <div className='border_form_radius_b_disabled_div fileName' style={{float:"left", flex:1, lineHeight: "0px", textOverflow:"ellipsis", overflow:"hidden", fontSize:"14px"}}>{this.state.w_img_49002_link}</div>
                                                            <button className="btn-175-b-disabled border_form_radius_b_btn">이미지 주소 입력</button>
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="m-l-34" style={{fontSize:"13px", marginTop:"20px", color:"grey"}}>
                                        ○ 파일명은 꼭 <b>영어</b>로 해주세요.
                                        <br/>
                                        ○ <b>jpg, jpeg, png, gif, bmp</b> 파일만 첨부할 수 있습니다. 
                                        </div> */}
                                    </div>
                                :   ""
                            }
                            </>
                        :   <div className="m-t-43">
                                <div style={{display:"flex"}}> 
                                    <div className="icn_round_blue_small"></div>
                                    &nbsp;&nbsp;
                                    <div className="form_title" style={{flex:"1", marginTop: "auto", marginBottom: "auto"}}>가로형 이미지 업로드</div>
                                </div>   
                                <div className="flexBox m-t-21 m-b-20 width-100-34px">
                                    <div style={{color:"#FF7B7B", margin:"auto 0", fontSize:"15px"}}><b>* 적용범위 - 페이지와 배너를 먼저 선택 해주세요.</b></div>  
                                    <div className="btn-175-b cursor-pointer" style={{width: "80px", height:"40px", lineHeight:"41px", marginLeft:"20px"}} onClick={e => this.onClickShortcutFocusing(e, "iframe_page_id")}>바로가기</div>
                                </div>
                            </div>   
                    }
                    {
                        this.state.iframe_html_id != null && this.state.iframe_html_id != "" &&
                        (this.state.pc_banner_ratio_list.length > 0 || this.state.mobile_banner_ratio_list.length > 0)
                        ? <> 
                            {
                                this.state.mobile_banner_ratio_list.length > 0
                                ?        
                                    <div className="m-t-43">
                                        <div style={{display:"flex"}}> 
                                            <div className="icn_round_blue_small"></div>
                                            &nbsp;&nbsp;
                                            <div className="form_title" style={{flex:"1", marginTop: "auto", marginBottom: "auto"}}>세로형 이미지 등록</div>
                                        </div>
                                        <div className="redio_v2" style={{display: "block"}}>
                                            <div className="flexBox radio_area" style={{height: "auto", alignItems: "center"}}>
                                                <RadioButton
                                                    id={"h_img_detail_link_type_49001"}
                                                    checked={this.state.h_img_detail_link_type == "49001"}
                                                    onChangeCheck={() => this.changeImgLinkType("h_img_detail_link_type", "49001")}
                                                />
                                                <div className="margin-vertical-auto flexBox" style={{flex:"1", margin: "0 0 20px 10px"}}>
                                                    <div style={{width: "100%", maxWidth: "741px"}}>
                                                        <div className="input_text_title_d">선택된 이미지 파일</div>
                                                        {this.state.h_img_detail_link_type == "49001"
                                                        ?<form onSubmit={this._handleSubmit2} enctype="multipart/form-data">
                                                            <div style={{display:"flex", height: "63px"}}>
                                                                <input type="file" id="promotionFiles2" name="promotionFiles" onChange={e => this._handleImageChange(e, "portrait")} accept="image/jpg,image/png,image/jpeg,image/gif,image/bmp,image/tiff" value="" style={{display:'none'}}/> 
                                                                <div className='border_form_radius_b fileName line_clamp' style={{float:"left", flex:1, lineHeight: "0px", textOverflow:"ellipsis", overflow:"hidden", fontSize:"14px"}} onClick={this.handleClickBtnFileUpload2}>{this.state.img_detail_link_name}</div>
                                                                <button className="btn-175-b border_form_radius_b_btn" onClick={this.handleClickBtnFileUpload2}>파일첨부</button>
                                                                <button type="submit" id="btnSave2" className="greenButton" style={{float:"right", display:"none"}}>저장</button> 
                                                            </div>
                                                        </form>
                                                        :
                                                            <div style={{display:"flex", height: "63px"}}>
                                                                <input type="file" id="promotionFiles2" name="promotionFiles" accept="image/jpg,image/png,image/jpeg,image/gif,image/bmp,image/tiff" value="" style={{display:'none'}}/> 
                                                                <div className='border_form_radius_b_disabled_div fileName' style={{float:"left", flex:1, lineHeight: "0px", textOverflow:"ellipsis", overflow:"hidden", fontSize:"14px"}}>{this.state.img_detail_link_name}</div>
                                                                <button className="btn-175-b-disabled border_form_radius_b_btn">파일첨부</button>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flexBox radio_area" style={{height: "auto", alignItems: "center"}}>
                                                <RadioButton
                                                    id={"h_img_detail_link_type_49002"}
                                                    checked={this.state.h_img_detail_link_type == "49002"}
                                                    onChangeCheck={() => this.changeImgLinkType("h_img_detail_link_type", "49002")}
                                                />
                                                <img id={"h_img_detail_49002_link_check"} style={{display : "none"}} src={this.state["h_img_detail_49002_link"]}
                                                    onLoad={() => { this.setState({h_img_detail_49002_link_state : true}); }}
                                                    onError={() => { this.setState({h_img_detail_49002_link_state : false}); }} />
                                                <div className="flexBox" style={{flex:"1", margin: "0 0 20px 10px"}}> 
                                                    <div style={{width: "100%", maxWidth: "741px"}}>
                                                        <div className="input_text_title_d">이미지 주소</div>
                                                        {this.state.h_img_detail_link_type == "49002"
                                                        ?<div style={{display:"flex", height: "63px"}}>
                                                            <div className='border_form_radius_b fileName line_clamp' style={{float:"left", flex:1, lineHeight: "0px", textOverflow:"ellipsis", overflow:"hidden", fontSize:"14px"}} onClick={() => this.openImg49002LinkUrl("h_img_detail_49002_link")}>{this.state.h_img_detail_49002_link}</div>
                                                            <button className="btn-175-b border_form_radius_b_btn" onClick={() => this.openImg49002LinkUrl("h_img_detail_49002_link")}>이미지 주소 입력</button>
                                                        </div>
                                                        :<div style={{display:"flex", height: "63px"}}>
                                                            <div className='border_form_radius_b_disabled_div fileName' style={{float:"left", flex:1, lineHeight: "0px", textOverflow:"ellipsis", overflow:"hidden", fontSize:"14px"}}>{this.state.h_img_detail_49002_link}</div>
                                                            <button className="btn-175-b-disabled border_form_radius_b_btn">이미지 주소 입력</button>
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="m-l-34" style={{fontSize:"13px", marginTop:"20px", color:"grey"}}>
                                        ○ 파일명은 꼭 <b>영어</b>로 해주세요.
                                        <br/>
                                        ○ <b>jpg, jpeg, png, gif, bmp</b> 파일만 첨부할 수 있습니다. 
                                        </div> */}
                                    </div>
                                : ""
                            }
                        </>
                        :   <div className="m-t-43">
                                <div style={{display:"flex"}}> 
                                    <div className="icn_round_blue_small"></div>
                                    &nbsp;&nbsp;
                                    <div className="form_title" style={{flex:"1", marginTop: "auto", marginBottom: "auto"}}>세로형 이미지 업로드</div>
                                </div>   
                                <div className="flexBox m-t-21 m-b-20 width-100-34px">
                                    <div style={{color:"#FF7B7B", margin:"auto 0", fontSize:"15px"}}><b>* 적용범위 - 페이지와 배너를 먼저 선택 해주세요.</b></div>  
                                    <div className="btn-175-b cursor-pointer" style={{width: "80px", height:"40px", lineHeight:"41px", marginLeft:"20px"}} onClick={e => this.onClickShortcutFocusing(e, "iframe_page_id")}>바로가기</div>
                                </div>
                            </div>      
                    }
                    <div className="m-t-43">
                        {/* <div className="flexBox"> 
                            <div className="icn_round_blue_small"></div>
                            &nbsp;&nbsp;
                            <div className="form_title" style={{marginTop: "auto", marginBottom: "auto"}}>연결 링크를 설정해 주세요.</div>
                        </div> */}
                        <div className="flexBox" style={{marginTop: "15px", height:"auto"}}> 
                            <div style={{width: "500px", maxWidth: "calc(95% - 15px)"}}>
                                <div className="input_text_title_d">링크 URL</div>
                                <input id="link_url" className="border_form_radius_b input_width_100 input_height_35px" type="text" value={this.state.link_url} onChange={e => this.onChageLinkUrl(e)}/>
                            </div>
                        </div>
                    </div>        

                    {/* <div id="date_setting_title" className="form_primary_title m-t-43">
                    예약일정 및 노출일정
                    </div> */}
                    <div className="reservation-schedule-flex">
                        <div className="m-t-43">
                            {/* <div className="flexBox"> 
                                <div className="icn_round_blue_small"></div>
                                &nbsp;&nbsp;
                                <div className="form_title" style={{marginTop: "auto", marginBottom: "auto"}}>간편 설정</div>
                            </div> */}
                            <div className="redio_v2 m-t-32 event_content_radio">
                                {this.state.simple_date_setting_list.map((obj, idx) => (
                                    <RadioButton
                                        id={"simple_date_cd"+obj.code_cd}
                                        name={"simple_date_cd"}
                                        value={obj.code_cd}
                                        checked={this.state.simple_date_cd == obj.code_cd}
                                        onChangeCheck={this.onClickSimpleDateCd.bind(this)}
                                    >
                                        <label className="banner_radio_text">{obj.val2}</label>
                                    </RadioButton>
                                ))}
                            </div>
                        </div>
                        <div className="m-t-43 my-banner-create-datepicker">
                            <div className="input-daterange input-group" id="datepicker">
                                <div>
                                    <div className="input_text_title_d eventSettingTitle" style={{zIndex:"1"}}>
                                        <label style={{marginTop: "auto", marginBottom: "auto"}}>시작일</label>
                                    </div>
                                    <div className="mobile_width_30vw">
                                        <DatePicker name="start_date" id="start_date" className="border_form_radius_b mobile_width_30vw"
                                            selected={this.state.start_date}
                                            onSelect={this.handleSelect} //when day is clicked
                                            onChange={this.handleChangeStartDate} //only when value has changed
                                            placeholderText="시작일자"
                                            todayButton="오늘"
                                            dateFormat="yyyy-MM-dd"
                                            maxDate={new Date("9999-12-31")}
                                            autoComplete='off'
                                            showYearDropdown
                                            yearDropdownItemNumber={15}
                                            scrollableYearDropdown
                                            locale={ko}
                                            disabled={this.state.disabledStartDate}
                                        />
                                        
                                    </div>
                                </div>
                                <div>
                                    <div className="input_text_title eventSettingTitle" style={{zIndex:"999", left:"40px"}}>
                                        <label style={{marginTop: "auto", marginBottom: "auto"}}></label>
                                    </div>
                                    <div className="mobile_width_20vw">
                                        <select id="start_hour" className="border_form_radius_b" style={{width:"150px", padding:"19.6px 20px !important", marginLeft:"10px", backgroundPosition: "90% 50%"}} value={this.state.start_hour} onChange={e => this.onChangeDateSetting(e, "start_hour")}>
                                        <option value={""} disabled>
                                                시
                                        </option>
                                        {this.state.hour_list.map((hour, idx) => (
                                            <option key={idx} value={hour.key}>
                                                {hour.hour}
                                            </option>
                                        ))}    
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <div className="input_text_title eventSettingTitle" style={{zIndex:"999", left:"40px"}}>
                                        <label style={{marginTop: "auto", marginBottom: "auto"}}></label>
                                    </div>
                                    <div className="mobile_width_20vw">
                                        <select id="start_minute" className="border_form_radius_b" style={{width:"150px", padding:"19.6px 20px !important", marginLeft:"10px", backgroundPosition: "90% 50%"}} value={this.state.start_minute} onChange={e => this.onChangeDateSetting(e, "start_minute")}>
                                        <option value={""} disabled>
                                                분
                                        </option>
                                        {this.state.minute_list.map((minute, idx) => (
                                            <option key={idx} value={minute.key}>
                                                {minute.minute}
                                            </option>
                                        ))}    
                                        </select>
                                    </div>
                                </div>
                                <div className="input-group-addon mobile-input-group-addon" style={{width: "40px",height:"35px", marginTop: "44px",textAlign:"center"}}>~</div>
                                <div className="flexBox" id="end_date_area">
                                    <div>
                                        <div className="input_text_title_d eventSettingTitle" style={{zIndex:"1"}}>
                                            <label style={{marginTop: "auto", marginBottom: "auto"}}>종료일</label>
                                        </div>
                                        <div className="mobile_width_30vw">
                                            <DatePicker name="end_date" id="end_date" className="border_form_radius_b mobile_width_30vw"
                                                selected={this.state.end_date}
                                                onSelect={this.handleSelect} //when day is clicked
                                                onChange={this.handleChangeEndDate} //only when value has changed
                                                placeholderText="종료일자"
                                                todayButton="오늘"
                                                dateFormat="yyyy-MM-dd"
                                                maxDate={new Date("9999-12-31")}
                                                autoComplete='off'
                                                showYearDropdown
                                                yearDropdownItemNumber={15}
                                                scrollableYearDropdown
                                                locale={ko}
                                                disabled={this.state.disabledEndDate}
                                            />
                                        </div>	
                                    </div>
                                    <div>
                                        <div className="input_text_title eventSettingTitle" style={{zIndex:"999", left:"40px"}}>
                                            <label style={{marginTop: "auto", marginBottom: "auto"}}></label>
                                        </div>
                                        <div className="mobile_width_20vw">
                                            <select id="end_hour" className="border_form_radius_b" style={{width:"150px", padding:"19.6px 20px !important", marginLeft:"10px", backgroundPosition: "90% 50%"}} value={this.state.end_hour} onChange={e => this.onChangeDateSetting(e, "end_hour")}>
                                            <option value={""} disabled>
                                                    시
                                            </option>
                                            {this.state.hour_list.map((hour, idx) => (
                                                <option key={idx} value={hour.key}>
                                                    {hour.hour}
                                                </option>
                                            ))}    
                                            </select>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="input_text_title eventSettingTitle" style={{zIndex:"999", left:"40px"}}>
                                            <label style={{marginTop: "auto", marginBottom: "auto"}}></label>
                                        </div>
                                        <div className="mobile_width_20vw">
                                            <select id="end_minute" className="border_form_radius_b" style={{width:"150px", padding:"19.6px 20px !important", marginLeft:"10px", backgroundPosition: "90% 50%"}} value={this.state.end_minute} onChange={e => this.onChangeDateSetting(e, "end_minute")}>
                                            <option value={""} disabled>
                                                    분
                                            </option>
                                            {this.state.minute_list.map((minute, idx) => (
                                                <option key={idx} value={minute.key}>
                                                    {minute.minute}
                                                </option>
                                            ))}    
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="m-t-43">
                        <div className="flexBox"> 
                            <div className="icn_round_blue_small"></div>
                            &nbsp;&nbsp;
                            <div className="form_title" style={{marginTop: "auto", marginBottom: "auto", cursor: "pointer", textDecoration:"underline"}}
                            onClick={e => this.onClickShowCalendar()}>달력 보기</div>
                        </div>
                        <div className="m-t-32 fullCalendarArea" id="calendar_div" style={{display : "none"}}>
                            <FullCalendar
                                timeZone= 'local'
                                plugins={[ dayGridPlugin ]}
                                initialView="dayGridMonth"
                                locale='ko'
                                events={this.state.fullCalendarEventList}
                                eventClick={this.onClickeFullCalendarEvent}
                                contentHeight='auto'
                            />
                        </div>
                    </div>

                <div className="form_primary_title m-t-100">
                    등록하기
                </div>    
                <div className="margin-vertical-auto" style={{width:"auto"}}>
                    <div className="redio_v2 m-t-32">
                        <RadioButton
                            id={"display_yn_Y"}
                            name={"display_yn"}
                            checked={this.state.display_yn == "Y"}
                            onChangeCheck={() => this.onClickDisplayYn("Y")}
                            className={"event_display_radio"}
                        >
                            <div className="banner_radio_text">{this.state.book_yn == "Y" ? "예약 진열": "진열"}</div>
                        </RadioButton>
                        <RadioButton
                            id={"display_yn_N"}
                            name={"display_yn"}
                            checked={this.state.display_yn == "N"}
                            onChangeCheck={() => this.onClickDisplayYn("N")}
                            className={"event_display_radio"}
                        >
                            <div className="banner_radio_text">미진열</div>
                        </RadioButton>
                    </div>
                </div>                
                <div className="width-100 flexBox btn_save_cancel_v2" style={{marginTop:"50px", marginBottom:"50px"}}>
                    <button className="btn-175-b" onClick={this.saveEvent}>
                        저장하기
                    </button>

                    <div className="btn_cancel_v2 cursor-pointer" style={{height:"61px", marginLeft:"50px", lineHeight: "61px", color:"#C4C4C4"}} onClick={this.onClickBackButton}>
                        취소하고 돌아가기
                    </div>
                </div>
                
                <Draggable cancel=".fullCalendarModalClose">
                    <div className="fullCalendarModal">
                        <div className="fullCalendarInnerModal">
                            <div className="flexBox width-100">
                                <div class="pcBanner" style={{pointerEvents:"none", display:"none"}}>

                                </div>
                                <div class="mobileBanner" style={{pointerEvents:"none", display:"none"}}>

                                </div>
                            </div>
                            <div className="fa fa-close fullCalendarModalClose" onClick={this.onClickFullCalendarModalClose}></div>
                        </div>
                    </div>
                </Draggable>


                <div className="btn_show_mirror_banner_modal" onClick={e => this.onClickMirrorBannerOnOff(e, "on")}>
                    <div>
                        <div className="btn_show_mirror_banner_modal_text">
                        배너 미리보기
                        </div>
                    </div>
                    <div>
                        <div className="btn_left_top_arrow"></div>
                    </div>
                </div>
                <div className="mirror_banner_modal_v2">
                    <div className="mirror_banner_v2_inner_area">
                        <div className="mirror_banner_v2_title">
                            <div>
                            미리보기
                            </div>
                            <div>
                                <div className="btn_minus" onClick={e => this.onClickMirrorBannerOnOff(e, "off")}></div>
                            </div>
                        </div>
                        <div className="mirror_banner_v2_body" style={{position: "relative"}}>
                        {   this.state.is_show_pc_banner_area && this.state.iframe_page_id && this.state.iframe_html_id
                        ?
                            <div className="mirror_banner_v2_pc_banner_area">
                                    <div className="flexBox" style={{height: "43px"}}>
                                        <label style={{margin:"auto 0 auto 0",}}><b>가로형 배너</b></label>
                                        {this.state.pc_banner_ratio_list.length > 0
                                        ?<div className="cursor-pointer" style={{display:"flex", marginLeft:"10px"}} onClick={e => this.onClickBannerFullSize("w")}>
                                            <img src={plus_reading_glasses} style={{width:"23px", height:"23px", alignSelf:"center"}}/>
                                        </div>
                                        :""}
                                    </div>
                                    {this.state.pc_banner_ratio_list.length > 0
                                    ?
                                        <>
                                            {
                                                this.state.pc_banner_ratio_list.length > 0
                                                ?   <div class="m-b-10" style={{display:"flex", alignItems: "center"}}>
                                                    {
                                                            this.state.pc_banner_ratio_list.length > 1
                                                            ?
                                                                <>
                                                                    <div>사이즈 : </div>
                                                                    <select className="border_form_radius_b_non_padding" style={{ width: "150px", padding: "10px 15px 10px 12px",  margin: "auto 0.3vw auto 15px", backgroundPosition: "85%"}} onChange={e => this.onChangeMirrorBannerRatio(e)}>
                                                                        {this.state.pc_banner_ratio_list.map((obj,idx) => (
                                                                            <option value={obj.org_width_height}>
                                                                                {obj.org_banner_width > 50 || obj.org_banner_height > 50
                                                                                    ? obj.org_banner_width+"x"+obj.org_banner_height+" (px)"
                                                                                    : obj.banner_width+"x"+obj.banner_height+" (px)"}
                                                                            </option>     
                                                                        ))}
                                                                    </select>
                                                                </>
                                                            : this.state.pc_banner_ratio_list.length > 0
                                                                && <div style={{alignSelf:"center", marginLeft:"5px"}}>
                                                                    {this.state.pc_banner_ratio_list[0].org_banner_width > 50 || this.state.pc_banner_ratio_list[0].org_banner_height > 50
                                                                            ?   "사이즈 : "+this.state.pc_banner_ratio_list[0].org_banner_width+"x"+this.state.pc_banner_ratio_list[0].org_banner_height+" (px)"
                                                                            :   "사이즈 : "+this.state.pc_banner_ratio_list[0].banner_width+"x"+this.state.pc_banner_ratio_list[0].banner_height+" (px)"}
                                                                    </div>
                                                        }
                                                    </div>
                                                : ""
                                            }
                                            <div className="mirror_banner_v2_pc_banner"></div>
                                        </>
                                    : ""}
                                </div>
                            : ""}
                            { this.state.is_show_mobile_banner_area  && this.state.iframe_page_id && this.state.iframe_html_id
                            ?
                                <div className="mirror_banner_v2_mobile_banner_area">
                                    <div className="flexBox" style={{height: "43px"}}>
                                        <label style={{margin:"auto 0 auto 0",}}><b>세로형 배너</b></label>
                                        {this.state.mobile_banner_ratio_list.length > 0
                                        ?<>
                                            <div className="cursor-pointer" style={{display:"flex", marginLeft:"10px"}} onClick={e => this.onClickBannerFullSize("h")}>
                                                <img src={plus_reading_glasses} style={{width:"23px", height:"23px", alignSelf:"center"}}/>
                                            </div>  
                                        </>
                                        :""}
                                    </div>
                                    {this.state.mobile_banner_ratio_list.length > 0
                                    ?
                                        <>
                                            {
                                                this.state.mobile_banner_ratio_list.length > 0
                                                ?   <div class="m-b-10" style={{display:"flex", alignItems: "center"}}>
                                                    {
                                                            this.state.mobile_banner_ratio_list.length > 1
                                                            ?   
                                                                <>
                                                                    <div>사이즈 : </div>
                                                                    <select className="border_form_radius_b_non_padding" style={{ width: "150px", padding: "10px 15px 10px 12px",  margin: "auto 0.3vw auto 15px", backgroundPosition: "85%"}} onChange={e => this.onChangeMirrorBannerRatio(e)}>
                                                                        {this.state.mobile_banner_ratio_list.map((obj,idx) => (
                                                                            <option value={obj.org_width_height}>
                                                                                {obj.org_banner_width > 50 || obj.org_banner_height > 50
                                                                                ? obj.org_banner_width+"x"+obj.org_banner_height+" (px)"
                                                                                : obj.banner_width+"x"+obj.banner_height+" (px)"}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </>
                                                            : this.state.mobile_banner_ratio_list.length > 0
                                                                && <div style={{alignSelf:"center", marginLeft:"5px"}}>
                                                                        {this.state.mobile_banner_ratio_list[0].org_banner_width > 50 || this.state.mobile_banner_ratio_list[0].org_banner_height > 50
                                                                        ?   "사이즈 : "+this.state.mobile_banner_ratio_list[0].org_banner_width+"x"+this.state.mobile_banner_ratio_list[0].org_banner_height+" (px)"
                                                                        :   "사이즈 : "+this.state.mobile_banner_ratio_list[0].banner_width+"x"+this.state.mobile_banner_ratio_list[0].banner_height+" (px)"}
                                                                    </div>
                                                        }
                                                    </div>
                                                : ""
                                            }
                                            <div className="mirror_banner_v2_mobile_banner"></div>
                                        </>
                                    : ""}
                                </div>
                            : ""}
                            {
                                //this.state.event_seq == null || this.state.event_seq == ""
                                //? <>{
                                        this.state.iframe_page_id == null || this.state.iframe_page_id == ""
                                        ?   <div class="flexBox" style={{position: "absolute", width:"100%", height:"100%", background:"#585858cc", color:"white"}}>
                                                <div style={{margin:"auto", textAlign:"center"}}>
                                                적용범위 - 페이지를 선택해 주세요.
                                                </div>
                                            </div>
                                        :   <>{
                                                this.state.iframe_html_id == null || this.state.iframe_html_id == ""
                                                ?   <div class="flexBox" style={{position: "absolute", width:"100%", height:"100%", background:"#585858cc", color:"white"}}>
                                                        <div style={{margin:"auto", textAlign:"center"}}>
                                                        적용범위 - 배너를 선택해 주세요.
                                                        </div>
                                                    </div>
                                                : <>{
                                                        //가로만 있는 경우
                                                        this.state.pc_banner_ratio_list.length > 0 && this.state.mobile_banner_ratio_list.length == 0
                                                        ?   this.checkImageNoneUpload("w")
                                                            ?   <div class="flexBox" style={{position: "absolute", width:"100%", height:"100%", background:"#585858cc", color:"white"}}>
                                                                    <div style={{margin:"auto", textAlign:"center"}}>
                                                                    가로형 이미지를 등록해 주세요.
                                                                    </div>
                                                                </div>
                                                                : ""
                                                        : ""
                                                    }
                                                    {
                                                        //세로만 있는 경우    
                                                        this.state.pc_banner_ratio_list.length == 0 && this.state.mobile_banner_ratio_list.length > 0
                                                        ?   this.checkImageNoneUpload("h")
                                                            ?   <div class="flexBox" style={{position: "absolute", width:"100%", height:"100%", background:"#585858cc", color:"white"}}>
                                                                    <div style={{margin:"auto", textAlign:"center"}}>
                                                                    세로형 이미지를 등록해 주세요.
                                                                    </div>
                                                                </div>
                                                            : ""
                                                        : "" 
                                                        
                                                    }
                                                    {
                                                        //둘 다 있는 경우
                                                        this.state.pc_banner_ratio_list.length > 0 && this.state.mobile_banner_ratio_list.length > 0
                                                        ?   this.checkImageNoneUpload("w")
                                                            ?   <div class="flexBox" style={{position: "absolute", width:"100%", height:"100%", background:"#585858cc", color:"white"}}>
                                                                    <div style={{margin:"auto", textAlign:"center"}}>
                                                                    가로형 이미지를 등록해 주세요.
                                                                    </div>
                                                                </div>
                                                            :   this.checkImageNoneUpload("h")
                                                                ?   <div class="flexBox" style={{position: "absolute", width:"100%", height:"100%", background:"#585858cc", color:"white"}}>
                                                                        <div style={{margin:"auto", textAlign:"center"}}>
                                                                        세로형 이미지를 등록해 주세요.
                                                                        </div>
                                                                    </div>
                                                                : ""
                                                        : ""
                                                    }
                                                    {    /*
                                                        Number(this.state.event_banner_pc_width_height) > 1
                                                        ?   <>
                                                            {
                                                                (this.state.uploadFiles1 == null || this.state.uploadFiles1 == "")
                                                                ?   <div class="flexBox" style={{position: "absolute", width:"100%", height:"100%", background:"#585858cc", color:"white"}}>
                                                                        <div style={{margin:"auto", textAlign:"center"}}>
                                                                        가로형 이미지를 업로드해 주세요.
                                                                        </div>
                                                                    </div>
                                                                : ""
                                                            }</>      
                                                        : 
                                                        <>{
                                                                this.state.uploadFiles2 == null || this.state.uploadFiles2 == ""
                                                                ?   <div class="flexBox" style={{position: "absolute", width:"100%", height:"100%", background:"#585858cc", color:"white"}}>
                                                                        <div style={{margin:"auto", textAlign:"center"}}>
                                                                        세로형 이미지를 업로드해 주세요.
                                                                        </div>
                                                                    </div>
                                                                : "" 
                                                            }
                                                        </>*/
                                                    }
                                                    </>
                                            }</>
                            }
                        </div>
                        <div className="flexBox width-100">
                        </div>
                        <br/>
                        <font style={{margin:"auto", color:"#bfc4d6", fontSize:"10px", lineHeight:"10px"}}>(실제 적용된 모습과 다를 수 있습니다. 참고용으로 사용해 주세요.)</font>
                    </div>
                </div>

                {/* 이미지 주소 작성 다이얼로그 */
                    (this.state.input_dialog_show_state === true)
                    ? <InputDialogV2
                        title={this.state.input_dialog_title}
                        input_value={this.state.input_dialog_input_value}
                        button_list={this.state.input_dialog_button_list}
                        popup_close={() => {
                            this.setState({input_dialog_show_state: false});
                            StyleUtil.clearPopupNoneScroll(window_scrollTop);
                        }}
                    />
                    : ''
                }
                {/* 저장시 기본배너 세팅 관련 다이얼로그 */
					this.state.title_content_dialog_show_state &&
                    <TitleContentDialogV2
                       title={this.state.title_content_dialog_title}
                       content={this.state.title_content_dialog_content}
                       button_list={this.state.title_content_dialog_button_list}
                       popup_close={this.state.title_content_popup_close}
                   />
               }

                {/* <Prompt
                when={isFormIncomplete}
                message="이 페이지를 벗어나면 저장하지 않은 내용은 모두 초기화 됩니다." /> */}
            </div>
        )
    }
}   
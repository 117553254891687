const fonts = [
    { name: "Roboto", value: "Roboto" },
    { name: "Noto Sans KR", value: "노토 산스 KR" },
    { name: "Noto Serif", value: "Noto Serif" },
    { name: "Noto Serif KR", value: "노토 세리프 KR" },
    { name: "Spoqa Han Sans Neo", value: "스포카 한 산스 네오" },
    { name: "NanumSquare", value: "나눔 스퀘어" },
    { name: "NanumSquareRound", value: "나눔 스퀘어 라운드" },
    { name: "Nanum Square Neo", value: "나눔 스퀘어 네오" },
    { name: "Nanum Gothic", value: "나눔 고딕" },
    { name: "NanumBarunGothic", value: "나눔 바른 고딕" },
    { name: "Nanum Pen Script", value: "나눔 펜 스크립트" },
    { name: "Maru Buri", value: "마루 부리" },
    { name: "Line Seed KR", value: "라인 시드 KR" },
    { name: "Cafe24Ssurround", value: "카페24 써라운드" },
    { name: "Cafe24SsurroundAir", value: "카페24 써라운드 에어" },
    { name: "Cafe24 Ohsquare", value: "카페24 아네모네" },
    { name: "Cafe24 Ohsquare Air", value: "카페24 아네모네 에어" },
    { name: "Cafe24Simplehae", value: "카페24 심플해" },
    { name: "Cafe24Dangdanghae", value: "카페24 당당해" },
    { name: "Cafe24Danjunghae", value: "카페24 단정해" },
    { name: "GmarketSans", value: "지마켓 산스" },
    { name: "TmoneyRoundWind", value: "티머니 둥근바람" },
    { name: "Jalnan", value: "여기어때 잘난체" },
    { name: "BMJua", value: "배민 주아체" },
    { name: "BMDoHyeon", value: "배민 도현체" },
    { name: "BM EULJIRO", value: "배민 을지로체" },
    { name: "BM HANNA 11yrs", value: "배민 한나는 열한살체" },
    { name: "BM YEONSUNG", value: "배민 연성체" },
    { name: "BM KIRANGHAERANG", value: "배민 기랑해랑체" },
    { name: "brush-script-std", value: "brush-script-std" },
    { name: "Bodoni Moda", value: "Bodoni Moda" },
    { name: "Montserrat", value: "Montserrat" },
    { name: "Nunito Sans", value: "Nunito Sans" },
    { name: "Sora", value: "Sora" },
    { name: "Poppins", value: "Poppins" },
    { name: "Pretendard", value: "프리텐다드" },
    { name: "Parisienne", value: "Parisienne" },
    { name: "Charter", value: "Charter" },
    { name: "Happy Time", value: "Happy Time" },
    { name: "Blackbird", value: "Blackbird" },
    { name: "Bacalisties", value: "Bacalisties" },
    { name: "Miracle", value: "Miracle" },
    { name: "Vladimir Script", value: "Vladimir Script" },
    { name: "Futura Std", value: "Futura Std" },
    { name: "KOHI Nanum", value: "KOHI 나눔" },
    { name: "KOHI Baeum", value: "KOHI 배움" },
    { name: "Snell Roundhand", value: "Snell Roundhand" },
    { name: "Infinity Sans", value: "인피니티 산스" },
    { name: "SF UI Text", value: "SF UI Text" },
    { name: "Shadows Into Light Two", value: "Shadows Into Light Two" },
    { name: "Cooper Black", value: "Cooper Black" },
    { name: "Water Brush", value: "Water Brush" },
    { name: "VITRO PRIDE", value: "비트로 프라이드체" },
    { name: "Antic Didone", value: "Antic Didone" },
    { name: "Aggro", value: "어그로체" },
    { name: "S-Core Dream", value: "에스코어드림" },
    { name: "Kopub Dotum", value: "Kopub 돋움체" },
    { name: "Kopub Batang", value: "Kopub 바탕체" },
    { name: "ONE Mobile", value: "원스토어 모바일고딕 본문체" },
    { name: "ONE Mobile Title", value: "원스토어 모바일고딕 제목체" },
    { name: "ONE Mobile POP", value: "원스토어 모바일 POP채" },
    { name: "Tmon Monsori", value: "티몬 몬소리체" },
    { name: "NEXON Lv1 Gothic", value: "넥슨 Lv1 고딕" },
    { name: "NEXON Lv2 Gothic", value: "넥슨 Lv2 고딕" },
    { name: "Binggrae", value: "빙그레체" },
    { name: "Binggrae Two", value: "빙그레체II" },
    { name: "SUIT", value: "수트" },
    { name: "yangjin", value: "양진체" },
    { name: "Tway Sky", value: "티웨이 하늘체" },
    { name: "SEBANG Gothic", value: "세방고딕" },
    { name: "Rix Yeoljeongdo", value: "Rix 열정도체" },
    { name: "Lotte Mart Dream", value: "롯데마트 드림체" },
    { name: "Lotte Mart Happy", value: "롯데마트 행복체" },
    { name: "Arita Dotum", value: "아리따 돋움" },
    { name: "TT Together", value: "TT투게더" },
    { name: "paybooc", value: "페이북 글꼴" },
    { name: "Lato", value: "Lato" },
    { name: "Inter", value: "Inter" },
    { name: "Raleway", value: "Raleway" },
    { name: "IBM Plex Sans", value: "IBM Plex Sans" },
    { name: "Jost", value: "Jost" },
    { name: "Arvo", value: "Arvo" },
    { name: "Russo One", value: "Russo One" },
    { name: "Barlow Semi Condensed", value: "Barlow Semi Condensed" },
    { name: "Gruppo", value: "Gruppo" },
    { name: "Quicksand", value: "Quicksand" },
    { name: "Oswald", value: "Oswald" },
    { name: "Cantata One", value: "Cantata One" },
    { name: "Harshita", value: "Harshita" },
    { name: "High Summit", value: "High Summit" },
    { name: "Franklin Gothic", value: "Franklin Gothic" },
    { name: "Sero Pro", value: "Sero Pro" },
    { name: "Giants", value: "자이언츠체" },
    { name: "Puradak Gentle Gothic", value: "푸라닭 젠틀 고딕" },
    { name: "Irish Grover", value: "Irish Grover" },
    { name: "Racing Sans One", value: "Racing Sans One" },
    { name: "Elice Digital Baeum", value: "엘리스 디지털 배움체" },
    { name: "Monoplex KR Italic", value: "모노플렉스KR Italic" },
];

// 폰트 이름순으로 정렬
const textEditorFont = fonts.sort(function(a, b) {
    let x = a.name.toLowerCase();
    let y = b.name.toLowerCase();

    if (x < y) { return -1; }
    if (x > y) { return 1; }
    return 0;
})

export default textEditorFont;
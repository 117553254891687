import React from 'react';
import http from './modules/api';
import $ from 'jquery';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-78553461-8');

var ga_label = sessionStorage.getItem("userName");

class SignupForm_cafe24 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            agree_yn : this.props.agree_yn,
            mall_id : '',
	    }
    }

    componentDidMount() {

        // console.log("###### cafe24 form : " + this.props.mall_id)
        //cafe24_index의 Params
        // var mall_id = this.props.mall_id;       //cafe24 관리자 - 현재 세션의 mall_id
        //var fromMyApp = this.props.fromMyApp;   //cafe24 관리자 - 마이 앱 실행여부
        //cafe24_index에서 사용자 정보가 연동되어진 tmp를 조회하여 출력
        // if (mall_id != null && mall_id != "") {
        //     this.getStore(mall_id);
        // }
    }

    componentDidUpdate() {
        console.log("###### cafe24 form update: " + this.props.mall_id);
        var mall_id = this.props.mall_id;  
        if (mall_id != null && mall_id != "" && this.state.mall_id != null  && this.state.mall_id == '') {
            this.setState({"mall_id": mall_id});
            this.getStore(mall_id);
        }
    }

    handleText = (e, key) => {
	    // computed property
        //this.setState({[key]: e.target.value});
    	if (key == "mall_id") {
    		this.setState({"mall_id": e.target.value});
    	}
    	this.props.handleText(e, key);
    }
    
    //[Cafe24 API] 상점 동기화
    onClickGetStoreInfoButton(mode) {
        var mall_id = this.props.storeState.mall_id;
        if (mall_id == null || mall_id == "") {
            // 가입신청 - Cafe24 API] 상점 동기화
            ReactGA.event({
                category: "[Cafe24 API] 상점 동기화",
                action: "상점 아이디를 입력해 주세요. : " + mall_id,
                label: ga_label
            });
			alert("상점 아이디를 입력해 주세요.");
			return false;
		}
        //console.log(mall_id)
		// 팝업창 열기
		var g_oWindow = window.open(mode+".html?mall_id="+mall_id
                ,"카페24 데이터 동기화");

        // 가입신청 - Cafe24 API] 상점 동기화
        ReactGA.event({
            category: "[Cafe24 API] 상점 동기화",
            action: "동기화 로그인 요청 : " + mall_id,
            label: ga_label
        });

		// 0.5초 마다 감지
	    var g_oInterval = window.setInterval(() => {
	        try {
                // 창이 꺼졌는지 판단
                console.log("g_oWindow.closed : " + g_oWindow.closed);
	            if( g_oWindow == null || g_oWindow.closed ) {
	                window.clearInterval(g_oInterval);
                    g_oWindow = null;
                    this.getStore(mall_id);
                    //$("#mall_id").attr('disabled', true);
	            }
	        } catch (e) {
                this.getStore(mall_id);
            }
        }, 500);
    };
    
	//store_temp 조회
	async getStore(mall_id) {
        const res = await http.get("/rest/common/userTmp", {params:{mall_id:mall_id}});

        //파라미터 정정
        res.data.corp_name = res.data.company_name;     //상호명
        res.data.user_name = res.data.president_name;   //대표자명
        res.data.corp_addr = res.data.address1;         //기본주소
        res.data.corp_addr2 = res.data.address2;        //상세주소

        // 가입신청 - Cafe24 API] 상점 동기화
        ReactGA.event({
            category: "[Cafe24 API] 상점 동기화",
            action: "동기화 완료 : " + mall_id + " / (동기화된 상호명 : " + res.data.corp_name + ")",
            label: ga_label
        });

        this.props.getStore(res.data);
        if (res.data != null) {
        	$("#mall_id").attr('disabled', true);	
        }
        
    }

    render () {
        let button;
        if (this.props.token != '') {
            //button = <button className="btn btn-primary" onClick={() => this.onClickGetStoreInfoButton('/cafe24_sync_data')}>동기화</button>
        } else {
            button = <button className="btn btn-primary" onClick={() => this.onClickGetStoreInfoButton('/cafe24_sync_tmp_user')}>상점 가져오기</button>
        }

        return (
            this.props.user_join_cls == 'cafe24' ?
                <>
                    {/* ******************************************Cafe24 업체 연동 모듈****************************************** */}
                    <div className="form-group m-b-lg">
                        <label>Cafe24 상점 아이디</label><small className="text-navy"> (*필수입력사항)</small>
                        <div className="input-group">
                            <input type="text" id="mall_id" className="form-control"
                                value={this.props.mall_id} 
                                onChange={(e) => this.handleText(e, 'mall_id')}
                                required minLength="3" maxLength="50" 
                                disabled = {(this.props.token)!=''? "disabled" : ""} />
                            <span className="input-group-btn">
                                {button}
                            </span>
                            <h5 style={{color:'red'}}>입력된 상점ID를 기준으로 Cafe24에서 정보를 조회합니다.(Cafe24 로그인 필요)</h5>
                        </div>
                    </div>
                    <div className="form-group m-b-md">
                        <label>상호명</label>
                        <div className="input-group">
                            <input type="text" id="corp_name" className="form-control"
                                value={this.props.storeState.corp_name} 
                                onChange={(e) => this.handleText(e, 'corp_name')} 
                                required maxLength="50" disabled />
                        </div>
                    </div>
                    <div className="form-group m-b-md">
                        <label>대표자명</label>
                        <div className="input-group">
                            <input type="text" id="user_name" className="form-control"
                                value={this.props.storeState.user_name} 
                                onChange={(e) => this.handleText(e, 'user_name')} 
                                required maxLength="10" disabled />
                        </div>
                    </div>
                    <div className="form-group m-b-lg">
                        <label>이메일 주소</label>
                        <div className="input-group">
                            <input type="text" id="email" className="form-control" placeholder="***@***.com"
                                value={this.props.storeState.email} 
                                onChange={(e) => this.handleText(e, 'email')} 
                                required maxLength="50" disabled />
                        </div>
                    </div>
                    <div id="opener_result"></div>
                    <div className="form-group m-b-md">
                        <label>기본제공 도메인</label>
                        <div className="input-group">
                            <input type="text" id="base_domain" className="form-control"
                                value={this.props.storeState.base_domain} 
                                onChange={(e) => this.handleText(e, 'base_domain')} 
                                required maxLength="250" disabled />
                        </div>
                    </div>
                    <div className="form-group m-b-lg">
                        <label>쇼핑몰 주소</label>
                        <div className="input-group">
                            <input type="text" id="mall_url" className="form-control" placeholder="http://****.cafe24.com/"
                                value={this.props.storeState.mall_url} 
                                onChange={(e) => this.handleText(e, 'mall_url')} 
                                required maxLength="50" disabled />
                        </div>
                    </div>
                    <div className="form-group m-b-md">
                        <label>주소</label>
                        <div className="input-group m-b-xs">
                            <input type="text" id="corp_addr" className="form-control" placeholder="기본 주소 (시/군/도)"
                                value={this.props.storeState.corp_addr} 
                                onChange={(e) => this.handleText(e, 'corp_addr')} 
                                required maxLength="50" disabled />
                        </div>
                        <div className="input-group">
                            <input type="text" id="corp_addr2" className="form-control" placeholder="상세 주소" 
                                value={this.props.storeState.corp_addr2} 
                                onChange={(e) => this.handleText(e, 'corp_addr2')} 
                                required maxLength="50" disabled />
                        </div>
                    </div>
                    
                    
                    {/* <div className="form-group m-b-md">
                        <label>3. 대표 도메인</label><small className="text-navy"> (*필수입력사항)</small>
                        <div className="input-group">
                            <input type="text" id="primary_domain" className="form-control"
                                value={this.props.storeState.primary_domain} 
                                onChange={(e) => this.handleText(e, 'primary_domain')} 
                                required maxLength="250" />
                        </div>
                    </div> */}
                    
                    {/* <div className="form-group m-b-md">
                        <label>7. 업태</label><small className="text-navy"> (*필수입력사항)</small>
                        <div className="input-group">
                            <input type="text" id="company_condition" className="form-control"
                                value={this.props.storeState.company_condition} 
                                onChange={(e) => this.handleText(e, 'company_condition')} 
                                required maxLength="50" />
                        </div>
                    </div>
                    <div className="form-group m-b-md">
                        <label>8. 종목</label><small className="text-navy"> (*필수입력사항)</small>
                        <div className="input-group">
                            <input type="text" id="company_line" className="form-control"
                                value={this.props.storeState.company_line} 
                                onChange={(e) => this.handleText(e, 'company_line')} 
                                required maxLength="50" />
                        </div>
                    </div>
                    <div className="form-group m-b-md">
                        <label>9. 사업장 국가</label><small className="text-navy"> (*필수입력사항)</small>
                        <div className="input-group">
                            <input type="text" id="country" className="form-control"
                                value={this.props.storeState.country} 
                                onChange={(e) => this.handleText(e, 'country')} 
                                required maxLength="50" />
                        </div>
                    </div>
                    <div className="form-group m-b-md">
                        <label>10. 우편번호</label><small className="text-navy"> (*필수입력사항)</small>
                        <div className="input-group">
                            <input type="text" id="zipcode" className="form-control" placeholder="***-***"
                                value={this.props.storeState.zipcode} 
                                onChange={(e) => this.handleText(e, 'zipcode')} 
                                required maxLength="7" />
                        </div>
                    </div> */}
                    
                    {/* <div className="form-group m-b-lg">
                        <label>13. 팩스번호</label>
                        <div className="input-group">
                            <input type="text" id="fax" className="form-control" placeholder="***-****-****"
                                value={this.props.storeState.fax} 
                                onChange={(e) => this.handleText(e, 'fax')} 
                                required maxLength="50" />
                        </div>
                    </div> */}
                    {/* <div className="form-group m-b-lg">
                        <label>16. 통신판매업 신고 여부</label>
                        <div>
                            <label>
                                <input type="radio" id="mail_order_sales_registration" name="mail_order_sales_registration"
                                    value="T"
                                    onChange={(e) => this.handleText(e, 'mail_order_sales_registration')} />
                                <i></i> 신고함
                            </label>
                        </div>
                        <div>
                            <label>
                                <input type="radio" id="mail_order_sales_registration" name="mail_order_sales_registration"
                                    value="F"
                                    onChange={(e) => this.handleText(e, 'mail_order_sales_registration')} />
                                <i></i> 신고안함
                            </label>
                        </div>
                    </div>
                    <div className="form-group m-b-lg">
                        <label>17. 통신판매신고 번호</label>
                        <div className="input-group">
                            <input type="text" id="mail_order_sales_registration_number" className="form-control"
                                value={this.props.storeState.mail_order_sales_registration_number} 
                                onChange={(e) => this.handleText(e, 'mail_order_sales_registration_number')} 
                                required maxLength="50" />
                        </div>
                    </div>
                    <div className="form-group m-b-lg">
                        <label>18. 통신판매업 미신고 사유</label>
                        <div className="input-group">
                            <textarea id="missing_report_reason_type" className="form-control" rows="5" placeholder="최대 250자"
                                value={this.props.storeState.missing_report_reason_type} 
                                onChange={(e) => this.handleText(e, 'missing_report_reason_type')} 
                                required maxLength="250" />
                        </div>
                    </div>
                    <div className="form-group m-b-lg">
                        <label>19. 통신판매업 미신고 사유 상세 내용</label>
                        <div className="input-group">
                            <textarea id="missing_report_reason" className="form-control" rows="5" placeholder="최대 250자"
                                value={this.props.storeState.missing_report_reason} 
                                onChange={(e) => this.handleText(e, 'missing_report_reason')} 
                                required maxLength="250" />
                        </div>
                    </div>
                    <div className="form-group m-b-lg">
                        <label>20. 회사소개</label>
                        <div className="input-group">
                            <textarea id="about_us_contents" className="form-control" rows="5" placeholder="최대 250자"
                                value={this.props.storeState.about_us_contents} 
                                onChange={(e) => this.handleText(e, 'about_us_contents')} 
                                required maxLength="250" />
                            <p className="card-text" dangerouslySetInnerHTML={{__html: this.props.storeState.about_us_contents}}></p>
                        </div>
                    </div>
                    <div className="form-group m-b-lg">
                        <label>21. 회사약도</label>
                        <div className="input-group">
                            <input type="text" id="company_map_url" className="form-control" placeholder="약도 URL"
                                value={this.props.storeState.company_map_url} 
                                onChange={(e) => this.handleText(e, 'company_map_url')} 
                                required maxLength="250" />
                        </div>
                    </div>
                    <div className="form-group m-b-lg">
                        <label>22. 고객센터 상담/주문 전화</label>
                        <div className="input-group">
                            <input type="text" id="customer_service_phone" className="form-control" placeholder="***-****-****"
                                value={this.props.storeState.customer_service_phone} 
                                onChange={(e) => this.handleText(e, 'customer_service_phone')} 
                                required maxLength="13" />
                        </div>
                    </div>
                    <div className="form-group m-b-lg">
                        <label>23. 고객센터 상담/주문 이메일</label>
                        <div className="input-group">
                            <input type="text" id="customer_service_email" className="form-control" placeholder="***@***.com"
                                value={this.props.storeState.customer_service_email} 
                                onChange={(e) => this.handleText(e, 'customer_service_email')} 
                                required maxLength="50" />
                        </div>
                    </div>
                    <div className="form-group m-b-lg">
                        <label>24. 고객센터 팩스 번호</label>
                        <div className="input-group">
                            <input type="text" id="customer_service_fax" className="form-control" placeholder="***-****-****"
                                value={this.props.storeState.customer_service_fax} 
                                onChange={(e) => this.handleText(e, 'customer_service_fax')} 
                                required maxLength="13" />
                        </div>
                    </div>
                    <div className="form-group m-b-lg">
                        <label>25. 고객센터 SMS 수신번호</label>
                        <div className="input-group">
                            <input type="text" id="customer_service_sms" className="form-control" placeholder="***-****-****"
                                value={this.props.storeState.customer_service_sms} 
                                onChange={(e) => this.handleText(e, 'customer_service_sms')} 
                                required maxLength="13" />
                        </div>
                    </div>
                    <div className="form-group m-b-lg">
                        <label>26. 고객센터 운영시간</label>
                        <div className="input-group">
                            <input type="text" id="customer_service_hours" className="form-control"
                                value={this.props.storeState.customer_service_hours} 
                                onChange={(e) => this.handleText(e, 'customer_service_hours')} 
                                required maxLength="50" />
                        </div>
                    </div> */}
                    {/* <div className="form-group m-b-lg">
                        <label>27. 개인정보보호 책임자명</label>
                        <div className="input-group">
                            <input type="text" id="privacy_officer_name" className="form-control"
                                value={this.props.storeState.privacy_officer_name} 
                                onChange={(e) => this.handleText(e, 'privacy_officer_name')} 
                                required maxLength="50" />
                        </div>
                    </div>
                    <div className="form-group m-b-lg">
                        <label>28. 개인정보보호 책임자 지위</label>
                        <div className="input-group">
                            <input type="text" id="privacy_officer_position" className="form-control"
                                value={this.props.storeState.privacy_officer_position} 
                                onChange={(e) => this.handleText(e, 'privacy_officer_position')} 
                                required maxLength="50" />
                        </div>
                    </div>
                    <div className="form-group m-b-lg">
                        <label>29. 개인정보보호 책임자 부서</label>
                        <div className="input-group">
                            <input type="text" id="privacy_officer_department" className="form-control"
                                value={this.props.storeState.privacy_officer_department} 
                                onChange={(e) => this.handleText(e, 'privacy_officer_department')} 
                                required maxLength="50" />
                        </div>
                    </div> */}
                    {/* <div className="form-group m-b-lg">
                        <label>30. 개인정보보호 책임자 연락처</label>
                        <div className="input-group">
                            <input type="text" id="privacy_officer_phone" className="form-control" placeholder="***-****-****"
                                value={this.props.storeState.privacy_officer_phone} 
                                onChange={(e) => this.handleText(e, 'privacy_officer_phone')} 
                                required maxLength="13" />
                        </div>
                    </div>
                    <div className="form-group m-b-lg">
                        <label>31. 개인정보보호 책임자 이메일</label>
                        <div className="input-group">
                            <input type="text" id="privacy_officer_email" className="form-control" placeholder="***@***.com"
                                value={this.props.storeState.privacy_officer_email} 
                                onChange={(e) => this.handleText(e, 'privacy_officer_email')} 
                                required maxLength="50" />
                        </div>
                    </div> */}
                    {/* <div className="form-group m-b-lg">
                        <label>32. 서비스 문의안내 모바일 표시여부</label>
                        <div>
                            <label>
                                <input type="radio" id="contact_us_mobile" name="contact_us_mobile"
                                    value="T"
                                    onChange={(e) => this.handleText(e, 'contact_us_mobile')} />
                                <i></i> 표시함
                            </label>
                        </div>
                        <div>
                            <label>
                                <input type="radio" id="contact_us_mobile" name="contact_us_mobile"
                                    value="F"
                                    onChange={(e) => this.handleText(e, 'contact_us_mobile')} />
                                <i></i> 표시안함
                            </label>
                        </div>
                    </div>
                    <div className="form-group m-b-lg">
                        <label>33. 서비스 문의안내 내용</label>
                        <div className="input-group">
                            <textarea id="contact_us_contents" className="form-control"
                                value={this.props.storeState.contact_us_contents} 
                                onChange={(e) => this.handleText(e, 'contact_us_contents')} 
                                required />
                        </div>
                    </div> */}
                </>
            : ''
        )
    }
}

export default SignupForm_cafe24;
import React from "react";

/** 아이콘 hover시 아이콘 명이 나타납니다 (아이콘 이미지를 태그 내부로 넣어주세요)
 * @param {*} className 클래스 명
 * @param {*} text 아이콘 명
 * @param {*} onClick 버튼 클릭시 실행될 함수
 */
class Icon extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
        }
    }

	render () {
		const {className, children, text, onClick} = this.props;
		return (
            <div className={["common_icon", className].join(' ')} onClick={onClick}>
                <div className={"hover_box"}>{text}</div>
                { children }
            </div>
		);
	}
}

Icon.defaultProps = {
    className : "",
    text: "",
    onClick : function() {},
}

export default Icon;
import React from "react";

import loginCommonStyle from '../../login/loginCommonStyle.module.css';

export class SimpleHeader extends React.Component {
    state = {

    }
    render () {
        return (
            <>
                <div className={loginCommonStyle.header}>
                    <img src="/static/media/icn_logo_v2.3ae64c26.svg" height="26px" alt=""/>
                </div>
            </>
        )
    }
}

export default SimpleHeader;
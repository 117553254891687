/** react-select 기본 스타일 가이드 */

export const reactSelectStyles = ({height, optionColorName} = {}) => {
    return {
      control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
          ...styles,
          backgroundColor: isDisabled ? 'rgb(247, 247, 247)' : 'white',
          border: (isFocused || isSelected)
          ? '1px solid #8B8B8B'
          : '1px solid #D1D1D1',
          borderRadius: "6px",
          boxShadow : "none",
          minHeight: height ? height : "40px",

          ':active': {
              ...styles[':active'],
              border: isDisabled ? "1px solid #D1D1D1" : '1px solid #8B8B8B',
          },
          ':hover': {
              ...styles[':hover'],
              border: isDisabled ? "1px solid #D1D1D1" : '1px solid #8B8B8B',
          }
      }),
      menu: (styles, { data, isDisabled, isFocused, isSelected }) => ({
        ...styles,
        border: "1px solid #8B8B8B",
        borderRadius: "6px",
        boxShadow: "none",
      }),
      noOptionsMessage: (styles, { data, isDisabled, isFocused, isSelected }) => ({
        ...styles,
        fontSize:  "14px",
        textAlign: "left",
        fontWeight : "400px",
        fontFamily : "Noto Sans KR",
        color: "#3A3A3A",
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#F7F7F7"
          : isFocused
          ? "#DFFAFA"
          : undefined,

          fontSize: "14px",
          fontWeight : "400px",
          fontFamily : "Noto Sans KR",

          color : isDisabled
          ? "#B7BDC3"
          : undefined,
          
          ':active': {
              ...styles[':active'],
              backgroundColor: !isDisabled
                ? isSelected && "#DFFAFA"
                : undefined,
          },
        };
      },
      multiValueLabel: (styles, { data, isDisabled, isFocused, isSelected }) => ({
        ...styles,
        color: isDisabled ? "#878B90" : "#202020",
        fontSize: "12px",
        paddingLeft: "8px",
      }),
      multiValue: (styles, { data, isDisabled, isFocused, isSelected }) => ({
          ...styles,
          background : isDisabled ? "#d1d1d1 !important" : data[optionColorName] ? `${data[optionColorName]} !important` : styles.background,
          borderRadius : "30px",
      }),
      multiValueRemove: (styles, { data }) => ({
        ...styles,
        borderRadius : "30px",
        ':hover': {},
      }),
      singleValue: (styles) => ({ ...styles, fontSize: "14px", fontWeight: "400", fontFamily : "Noto Sans KR", }),
      input: (styles) => ({ ...styles, fontSize: "14px", fontWeight: "400", fontFamily : "Noto Sans KR", }),
      valueContainer: (styles) => ({ ...styles, paddingLeft: "16px" }),
      placeholder: (styles) => ({ ...styles, color: "#878B90", fontSize: "14px", fontWeight: "400", fontFamily : "Noto Sans KR", }),
  }
};
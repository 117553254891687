import React from "react";

/**
 * @param {*} type 알럿 타입, ex) info(기본 설정), warning
 * @param {*} text 알럿 텍스트
 * @param {*} className 클래스 명
 * @param {*} fontSize 폰트사이즈
 */
class Alert extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
        }
    }

	render () {
		const {className, type, text, fontSize} = this.props;
		return (
            <div className={["alert_" + type, className].join(' ')}>
                {type == "warning"
                ?<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1229 7.84575 17.2662 5.78033 15.743 4.25704C14.2197 2.73376 12.1543 1.87707 10 1.875ZM9.375 6.25C9.375 6.08424 9.44085 5.92527 9.55806 5.80806C9.67527 5.69085 9.83424 5.625 10 5.625C10.1658 5.625 10.3247 5.69085 10.4419 5.80806C10.5592 5.92527 10.625 6.08424 10.625 6.25V10.625C10.625 10.7908 10.5592 10.9497 10.4419 11.0669C10.3247 11.1842 10.1658 11.25 10 11.25C9.83424 11.25 9.67527 11.1842 9.55806 11.0669C9.44085 10.9497 9.375 10.7908 9.375 10.625V6.25ZM10 14.375C9.81458 14.375 9.63333 14.32 9.47916 14.217C9.32499 14.114 9.20482 13.9676 9.13387 13.7963C9.06291 13.625 9.04434 13.4365 9.08052 13.2546C9.11669 13.0727 9.20598 12.9057 9.33709 12.7746C9.4682 12.6435 9.63525 12.5542 9.81711 12.518C9.99896 12.4818 10.1875 12.5004 10.3588 12.5714C10.5301 12.6423 10.6765 12.7625 10.7795 12.9167C10.8825 13.0708 10.9375 13.2521 10.9375 13.4375C10.9375 13.6861 10.8387 13.9246 10.6629 14.1004C10.4871 14.2762 10.2486 14.375 10 14.375Z" fill="#FF085E"/>
                </svg>              
                :<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1229 7.84575 17.2662 5.78033 15.743 4.25704C14.2197 2.73376 12.1543 1.87707 10 1.875ZM9.84375 5.625C10.0292 5.625 10.2104 5.67998 10.3646 5.783C10.5188 5.88601 10.6389 6.03243 10.7099 6.20373C10.7808 6.37504 10.7994 6.56354 10.7632 6.7454C10.7271 6.92725 10.6378 7.0943 10.5067 7.22541C10.3756 7.35652 10.2085 7.44581 10.0267 7.48199C9.84479 7.51816 9.65629 7.49959 9.48499 7.42864C9.31368 7.35768 9.16726 7.23752 9.06425 7.08335C8.96124 6.92918 8.90625 6.74792 8.90625 6.5625C8.90625 6.31386 9.00503 6.0754 9.18084 5.89959C9.35666 5.72377 9.59511 5.625 9.84375 5.625ZM10.625 14.375H10C9.83424 14.375 9.67527 14.3092 9.55806 14.1919C9.44085 14.0747 9.375 13.9158 9.375 13.75V10C9.20924 10 9.05027 9.93415 8.93306 9.81694C8.81585 9.69973 8.75 9.54076 8.75 9.375C8.75 9.20924 8.81585 9.05027 8.93306 8.93306C9.05027 8.81585 9.20924 8.75 9.375 8.75H10C10.1658 8.75 10.3247 8.81585 10.4419 8.93306C10.5592 9.05027 10.625 9.20924 10.625 9.375V13.125C10.7908 13.125 10.9497 13.1908 11.0669 13.3081C11.1842 13.4253 11.25 13.5842 11.25 13.75C11.25 13.9158 11.1842 14.0747 11.0669 14.1919C10.9497 14.3092 10.7908 14.375 10.625 14.375Z" fill="#ACACAC"/>
                </svg>}
                <span style={{fontSize:fontSize}}>{text}</span>
            </div>
		);
	}
}

Alert.defaultProps = {
    type : "info",
    text : "",
    className : "",
    fontSize: "12px",
}

export default Alert;
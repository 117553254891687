import React from "react";
/**
 * @param {*} list 아이템 목록 원본
 * @param {*} changeList 순서 변경이 필요한 아이템 목록
 * @param {*} listKeyName 아이템 목록 키값
 * @param {*} fixKeyName 순서 고정 키값
 * @function changeOrder 변경된 순서를 return
 */
const ListOrderChangeBtnBundle = ({list, changeList, listKeyName, fixKeyName, changeOrder}) => {

    const onChangeProductOrder = (type) => {
        let newList = [...list];
        let nonFixedList = [];
        let changeIdxList = [];
        let fixedList = [];
        let fixedIdxList = [];
        let resultList = [];

        // 순서 고정값이 있다면
        if(fixKeyName){
            // 고정 리스트와 일반 리스트를 분리한다
            newList.forEach((obj,idx) => {
                if (obj[fixKeyName]) {
                    fixedList.push(obj);
                    fixedIdxList.push(idx);
                } else {
                    nonFixedList.push(obj);
                }
            });
        } else{
            nonFixedList = newList;
        }

        let changeProductList = changeList.filter(item => !item[fixKeyName]);
        changeProductList = changeProductList.map(item => item[listKeyName]);
        nonFixedList.forEach((obj,idx) => {
            if(changeProductList.indexOf(obj[listKeyName])!=-1){
                changeIdxList.push(idx);
            }
        });

        if(changeIdxList.length === 0){
            return list;
        }

        if(type === "top"){ // 맨 위로
            let changeList = [];
            let otherList = [];
            nonFixedList.forEach((obj,idx) => {
                if (changeIdxList.indexOf(idx)!=-1) {
                    changeList.push(obj);
                } else {
                    otherList.push(obj);
                }
            });

            resultList = [...changeList, ...otherList];
        } else if(type === "up"){ // 한 칸 위로
            let finishPositionIdx = [];
            changeIdxList.forEach((indexNum) => {
                // 맨 위에 있는것부터 순서를 하나씩 위로 올리는데,이동해야할 상품의 위치가 이미 이동된 위치가 아닐때만 이동
                if(indexNum > 0 && finishPositionIdx.indexOf(indexNum-1)==-1){
                    const item = nonFixedList[indexNum];
                    nonFixedList.splice(indexNum,1);
                    nonFixedList.splice(indexNum-1,0,item);
                    finishPositionIdx.push(indexNum-1)
                } else{
                    finishPositionIdx.push(indexNum);
                }
            });
            resultList = nonFixedList;
        } else if(type === "down"){ // 한 칸 아래로
            let finishPositionIdx = [];
            changeIdxList.reverse().forEach((indexNum) => {
                // 맨 위에 있는것부터 순서를 하나씩 아래로 내리는데,이동해야할 상품의 위치가 이미 이동된 위치가 아닐때만 이동
                if(indexNum < nonFixedList.length-1 && finishPositionIdx.indexOf(indexNum+1)==-1){
                    const item = nonFixedList[indexNum];
                    nonFixedList.splice(indexNum,1);
                    nonFixedList.splice(indexNum+1,0,item);
                    finishPositionIdx.push(indexNum+1)
                } else{
                    finishPositionIdx.push(indexNum);
                }
            });
            resultList = nonFixedList;
            
        } else if(type === "bottom"){ // 맨 아래로
            let changeList = [];
            let otherList = [];
            nonFixedList.forEach((obj,idx) => {
                if (changeIdxList.indexOf(idx)!=-1) {
                    changeList.push(obj);
                } else {
                    otherList.push(obj);
                }
            });

            resultList = [...otherList, ...changeList];
        }

        if(fixKeyName){
            fixedList.forEach((product, idx) => {
                resultList.splice(fixedIdxList[idx], 0, product);
            });
        }

        changeOrder([...resultList]);
    }

    return (<>
        <div class="up_down_btn">
            <button class="double_down_arrow_btn" onClick={() => onChangeProductOrder("bottom")} tabIndex={-1}>
                <svg class="double_down_arrow" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.0341 8.4656C17.1749 8.60779 17.2539 8.79984 17.2539 8.99997C17.2539 9.20011 17.1749 9.39215 17.0341 9.53435L9.5341 17.0343C9.39075 17.173 9.19914 17.2504 8.99973 17.2504C8.80032 17.2504 8.60871 17.173 8.46535 17.0343L0.965352 9.53435C0.845755 9.38862 0.784636 9.20363 0.793884 9.01534C0.803131 8.82705 0.882084 8.64893 1.01539 8.51563C1.14869 8.38233 1.3268 8.30338 1.51509 8.29413C1.70338 8.28488 1.88838 8.346 2.0341 8.4656L8.99973 15.4406L15.9654 8.4656C16.1075 8.32476 16.2996 8.24575 16.4997 8.24575C16.6999 8.24575 16.8919 8.32476 17.0341 8.4656ZM8.46535 9.53435C8.60871 9.67296 8.80032 9.75045 8.99973 9.75045C9.19914 9.75045 9.39075 9.67296 9.5341 9.53435L17.0341 2.03435C17.1537 1.88862 17.2148 1.70363 17.2056 1.51534C17.1963 1.32705 17.1174 1.14893 16.9841 1.01563C16.8508 0.882328 16.6727 0.803375 16.4844 0.794128C16.2961 0.78488 16.1111 0.845999 15.9654 0.965596L8.99973 7.9406L2.0341 0.965596C1.88838 0.845999 1.70338 0.78488 1.51509 0.794128C1.3268 0.803375 1.14869 0.882328 1.01539 1.01563C0.882084 1.14893 0.803131 1.32705 0.793884 1.51534C0.784636 1.70363 0.845755 1.88862 0.965352 2.03435L8.46535 9.53435Z" fill="#333333"/>
                </svg>
            </button>
            <button class="down_arrow_btn" onClick={() => onChangeProductOrder("down")} tabIndex={-1}>
                <svg class="down_arrow" width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.99973 9.24997C8.80094 9.24727 8.61034 9.17036 8.46535 9.03435L0.965352 1.53435C0.845755 1.38862 0.784636 1.20363 0.793884 1.01534C0.803131 0.827049 0.882084 0.648931 1.01539 0.51563C1.14869 0.382329 1.3268 0.303375 1.51509 0.294128C1.70338 0.28488 1.88838 0.345999 2.0341 0.465597L8.99973 7.4406L15.9654 0.465597C16.1111 0.345999 16.2961 0.28488 16.4844 0.294128C16.6727 0.303375 16.8508 0.382329 16.9841 0.51563C17.1174 0.648931 17.1963 0.827049 17.2056 1.01534C17.2148 1.20363 17.1537 1.38862 17.0341 1.53435L9.5341 9.03435C9.38911 9.17036 9.19851 9.24727 8.99973 9.24997Z" fill="#333333"/>
                </svg>
            </button>
            <button class="up_arrow_btn" onClick={() => onChangeProductOrder("up")} tabIndex={-1}>
                <svg class="up_arrow" width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.00027 0.750028C9.19906 0.752729 9.38966 0.829637 9.53465 0.965654L17.0346 8.46565C17.1542 8.61138 17.2154 8.79637 17.2061 8.98466C17.1969 9.17295 17.1179 9.35107 16.9846 9.48437C16.8513 9.61767 16.6732 9.69662 16.4849 9.70587C16.2966 9.71512 16.1116 9.654 15.9659 9.5344L9.00027 2.5594L2.03465 9.5344C1.88893 9.654 1.70393 9.71512 1.51564 9.70587C1.32735 9.69662 1.14923 9.61767 1.01593 9.48437C0.882629 9.35107 0.803677 9.17295 0.794428 8.98466C0.785181 8.79637 0.8463 8.61137 0.965899 8.46565L8.4659 0.965654C8.61089 0.829637 8.80149 0.752729 9.00027 0.750028Z" fill="#333333"/>
                </svg>
            </button>
            <button class="double_up_arrow_btn" onClick={() => onChangeProductOrder("top")} tabIndex={-1}>
                <svg class="double_up_arrow" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.965898 9.5344C0.825061 9.39221 0.746053 9.20017 0.746053 9.00003C0.746053 8.79989 0.825061 8.60785 0.965898 8.46566L8.4659 0.965655C8.60925 0.827039 8.80086 0.749553 9.00027 0.749552C9.19968 0.749552 9.39129 0.827038 9.53465 0.965655L17.0346 8.46565C17.1542 8.61138 17.2154 8.79637 17.2061 8.98466C17.1969 9.17295 17.1179 9.35107 16.9846 9.48437C16.8513 9.61767 16.6732 9.69662 16.4849 9.70587C16.2966 9.71512 16.1116 9.654 15.9659 9.5344L9.00027 2.55941L2.03465 9.5344C1.89245 9.67524 1.70041 9.75425 1.50027 9.75425C1.30014 9.75425 1.10809 9.67524 0.965898 9.5344ZM9.53465 8.46566C9.39129 8.32704 9.19968 8.24955 9.00027 8.24955C8.80086 8.24955 8.60925 8.32704 8.4659 8.46566L0.965898 15.9657C0.8463 16.1114 0.785181 16.2964 0.794428 16.4847C0.803677 16.673 0.882629 16.8511 1.01593 16.9844C1.14923 17.1177 1.32735 17.1966 1.51564 17.2059C1.70393 17.2151 1.88893 17.154 2.03465 17.0344L9.00027 10.0594L15.9659 17.0344C16.1116 17.154 16.2966 17.2151 16.4849 17.2059C16.6732 17.1966 16.8513 17.1177 16.9846 16.9844C17.1179 16.8511 17.1969 16.673 17.2061 16.4847C17.2154 16.2964 17.1542 16.1114 17.0346 15.9657L9.53465 8.46566Z" fill="#333333"/>
                </svg>
            </button>
        </div>
    </>)
};

ListOrderChangeBtnBundle.defaultProps = {
    list : [],
    changeList: [],
    listKeyName: '',
    fixKeyName: '',
    changeOrder : () => {},
}

export default ListOrderChangeBtnBundle;
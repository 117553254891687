import React from 'react';
import axios from 'axios';
import http from './modules/api';
import { Link } from 'react-router-dom';
import '../css/style.css';

export class IdSearch extends React.Component {
	
	state = {
			//corp_reg_no: '',
			email: '',
			tel_no: ''
	}
	
	handleText = (e, key) => {
	    // computed property
		this.setState({[key]: e.target.value});
	}

	onClickIdSearchButton = () => {
		
		const sendForm = {...this.state};
		
		//1. Validation Check
		// if (this.state.corp_reg_no == null || this.state.corp_reg_no == "") {
		// 	alert("사업자등록번호를 입력해 주세요.");
		// 	return false;
		// }
		if (sendForm.email == null || sendForm.email == "") {
			alert("이메일을 입력해 주세요.");
			return false;
		}
		if (sendForm.tel_no == null || sendForm.tel_no == "") {
			//alert("연락처를 입력해 주세요.\n(비밀번호 분실시 카카오 알림톡을 수신 가능한 연락처)");
			alert("연락처를 입력해 주세요.");
			return false;
		}
		
		http.get("/rest/common/id/search", {params:sendForm})
			 .then(
					 resultData => {
						 var data = resultData.data;
						 if (data.length > 0) {
							var msg = "";
							data.map(user => (msg += "\n"+ user.masking_id));
							alert("가입하신 아이디" + msg);
						 } else {
							alert("가입하신 아이디가 없습니다.");
						 }
					 })
			 .catch(
					 error => {
						 console.log(error);
					 }
			 );
	}
	
	render() {
		
		return (
			// <div id='non-page-wrapper'>
			// 	<div className="login-container">
					// {/* <div className="middle-box text-center loginscreen animated fadeInDown"> */}
					<div className="mobile_margin_top_0 middle-box middle-box-mobile text-center" style={{display:"flex", maxWidth:"100%", paddingTop:0, height:"100vh"}}>
						<section className="login-section" style={{display: "flex", width:"100%", height:"auto", margin:"auto 0", background:"white"}}>
							<div className="container">
								<div className="form-inline">
									{/* <h3><a href="/login"><img src="/images/ROBOMD_logo_nuki.png" style={{width:"80%", height:"auto"}} /></a></h3> */}
									{/* <h3>아이디 찾기</h3> */}
									{/* <div className="m-t"> */}
									<div  style={{display: "flex", width:"100%", textAlign:"left", color:"#333333", fontWeight:"bold", fontSize:"25px", marginBottom:"10vh", justifyContent:"space-between"}}>
										<div>
										아이디가 기억나지 않으세요?<br/>걱정 마세요.
										</div>
										<div style={{display: "flex", minWidth: "59px", marginLeft:"10px", paddingTop: "5px"}}>
											<Link to={`/common/registUser`} style={{alignSelf:"flex-start", fontSize:"17px", color: "#10CFC9"}}>회원가입</Link>
										</div>
									</div>
									{/* <label style={{color:"#333333", fontWeight:"bold", marginBottom:"27px", justifyContent: "left"}}>
										사업자등록번호
									</label>
									<div style={{display: "flex", width:"100%", height:"124px"}}>
										<input className="form-control" style={{width:"100%", background:"#ffffff"}}  type="text" placeholder="정확한 사업자등록번호를 기입해 주세요." id="corp_name" value={this.state.corp_reg_no} onChange={(e) => this.handleText(e, 'corp_reg_no')} />
									</div> */}
									<label style={{color:"#333333", fontWeight:"bold", marginBottom:"27px", justifyContent: "left"}}>
										이메일 주소
									</label>
									<div style={{display: "flex", width:"100%", height:"124px"}}>
										<input className="form-control" style={{width:"100%", background:"#ffffff"}}  type="text" placeholder="정확한 이메일을 기입해 주세요." id="email" value={this.state.email} onChange={(e) => this.handleText(e, 'email')} />
									</div>
									<label style={{color:"#333333", fontWeight:"bold", marginBottom:"27px", justifyContent: "left"}}>
										연락처
									</label>
									<div style={{display: "flex", width:"100%", height:"124px"}}>
										<input className="form-control" style={{width:"100%", background:"#ffffff"}}  type="text" placeholder="정확한 연락처를 기입해 주세요." id="tel_no" value={this.state.tel_no} onChange={(e) => this.handleText(e, 'tel_no')} />
									</div>
										{/* <button className="btn btn-primary block full-width m-b" onClick={this.onClickIdSearchButton}>아이디 찾기</button> */}
										{/* <div className="flexBox" style={{width:"100%"}}>
											<Link className="btn btn-primary block m-b m-r" to={`/common/registUser`} style={{flex:1.5}}>회원가입</Link>
											<Link className="btn btn-primary block m-b m-r" to={`/login`} style={{flex:1}}>로그인</Link>
											<Link className="btn btn-primary block m-b" to={`/common/pwd/search`} style={{flex:2}}>비밀번호찾기</Link>
										</div> */}
									{/* </div> */}
									<div className="mobile-flex-wrap-align-right" style={{display: "flex", width:"100%", justifyContent:"space-between"}}>
										<div className="find_btn_box m-t-xs mobile_margin_bottom_30px" style={{width:"auto", display:"flex", flexWrap: "nowrap", margin:"auto 0", alignContent:"middle", fontSize:"15px", lineHeight:"20px"}}>
											{/* <Link className="btn find_btn" to={`/common/registUser`}>회원가입</Link> */}
											<Link to={`/login`} style={{color:"#10CFC9"}}>로그인하기&nbsp;·&nbsp;</Link>
											<Link to={`/common/pwd/search`} style={{color:"#10CFC9"}}>비밀번호찾기</Link>
										</div>
										<div className="mobile_margin_bottom_30px mobile_margin_left_20px" style={{width:"200px", height:"60px", margon:"auto 0", fontSize:"15px"}}>
											<button className="btn-white main_login" style={{height:"100%", color:"white", background:"#10CFC9", borderRadius: "13px", lineHeight:"20px" , border:0}} onClick={this.onClickIdSearchButton}>아이디 찾기</button>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
			// 	</div>
			// </div>
		);
	}
	
}

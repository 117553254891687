import React, { useCallback, useState } from 'react';
import http from '../modules/api';
import styles from './TargetViewSetting.module.css';
import TextInput from '../modules/common/TextInput';

import Select from 'react-select';
import {reactSelectStyles} from '../modules/libraries/react-select';
import makeAnimated from 'react-select/animated';
import * as StringUtil from '../modules/utils/StringUtil';
import Alert from '../modules/common/Alert';
import Button from '../modules/common/Button';
import RadioButton from '../modules/common/RadioButton';
const animatedComponents = makeAnimated();

export class TargetViewSetting extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
            target_view_seq : StringUtil.getUrlParams("target_view_seq"),
            // 타겟 제목
            title : "",
            show_title_error_msg : false,
            // 방문 빈도
            frequency_visit_list: [],
            select_frequency_visit_list: [],
            show_select_frequency_visit_list_error_msg : false,
            // 방문 기간
            visit_period_list: [],
            select_visit_period_list: [],
            show_select_visit_period_list_error_msg : false,
            // 체류 시간
            duration_time_list : [],
            select_duration_time_list : [],
            show_select_duration_time_list_error_msg : false,
            // 접속 기기
            device_type_list : [],
            select_device_type_list : [],
            show_select_device_type_list_error_msg : false,
            // 방문 경로
            referrer_type_list : [],
            select_referrer_type_list : [],
            show_select_referrer_type_list_error_msg : false,
            // 선호 카테고리
            prefer_category_depth : "",
            prefer_category_list : [],
            prefer_category_list_2 : [],
            select_prefer_category_list : [],
            select_prefer_category_list_2 : [],
            show_select_prefer_category_list_error_msg : false,
            show_select_prefer_category_list_error_msg_2 : false,
            // 사용자 그룹
            customer_group_list : [],
            select_customer_group_list : [],
            show_select_customer_group_list_error_msg : false,
        }
    }

    componentDidMount(){
        this.loadUpperCode(81000, "frequency_visit_list");
        this.loadUpperCode(82000, "visit_period_list");
        this.loadUpperCode(83000, "duration_time_list");
        this.loadUpperCode(84000, "device_type_list");
        this.loadUpperCode(85000, "referrer_type_list");
        this.cafe24Category();
        this.cafe24Category2();
        this.getCustomerGroup();

        if(this.state.target_view_seq){
            this.handleLoad();
        } else {
            this.setState({
                prefer_category_depth : 1, // 대분류 카테고리로 초기화
            })
        }
    }

    componentDidUpdate(){
        
    }

    loadUpperCode = (upper_code_cd, stateName) => {
		http.get("/rest/common/cafe24Code?upper_code_cd="+upper_code_cd)
		.then(res => {
            this.setState({[stateName]: res.data}); 
        });
    }

    cafe24Category = () => {
        http.get("/rest/common/cafe24/category")
		.then(res => {
            let category_list = res.data.list;
            this.setState({prefer_category_list: [{category_no : -1, category_name: "제한 없음"}, ...category_list]}); 
        });
    }
   
    cafe24Category2 = () => {
        http.get("/rest/common/cafe24/hierarchy/twoDepth/category")
		.then(res => {
            let category_list = res.data.list;
            this.setState({prefer_category_list_2: [{category_no : -1, category_name: "제한 없음"}, ...category_list]}); 
        });
    }

    getCustomerGroup(){
        http.get("/rest/common/cafe24/customerGroups")
		.then(res => {
            let customer_group_list = res.data.list;
            this.setState({customer_group_list: [{group_no : -1, group_name: "제한 없음"}, ...customer_group_list]}); 
        });
    }

    handleLoad = () => {
        http.get(`/rest/shop/targetview/detail?target_view_seq=${this.state.target_view_seq}`)
        .then(res => {
            if(res.data.code=="200"){
                if(res.data.target_view.del_yn == "Y"){
                    alert("삭제된 타겟 설정입니다.");
                    this.goTargetViewList();
                    return false;
                }
                const target_view = res.data.target_view;
                this.setState({
                    title : target_view.title,
                    select_frequency_visit_list : target_view.frequency_visit_list,
                    select_visit_period_list : target_view.visit_period_list,
                    select_duration_time_list : target_view.duration_time_list,
                    select_device_type_list : target_view.device_type_list,
                    select_referrer_type_list : target_view.referrer_type_list,
                    select_prefer_category_list : target_view.prefer_category_list,
                    select_prefer_category_list_2 : target_view.prefer_category_list_2,
                    select_customer_group_list : target_view.customer_group_list,
                    prefer_category_depth: target_view.prefer_category_depth ? target_view.prefer_category_depth : 1,
                });

            } else{
                alert("타겟 설정을 불러오는 데 실패하였습니다.");
                this.goTargetViewList();
            }
        })
        .catch(error => {
            alert("타겟 설정을 불러오는 데 실패하였습니다.");
            this.goTargetViewList();
        });
    }

    handleNoLimitSelectChange = (stateName, selectedOption, noLimitKey, noLimitCode) => {
        let originSelectedOption = this.state[stateName];

        // 제한 없음 값이 포함되어있으면
        if(selectedOption.find((obj) => obj[noLimitKey] == noLimitCode)){
            // 변경 전에도 제한없음 값이 포함되어 있었다면, 제한 없음이 아닌 새로운 값을 추가한 것
            if(originSelectedOption.find((obj) => obj[noLimitKey] == noLimitCode)){
                selectedOption = selectedOption.filter(obj => obj[noLimitKey] != noLimitCode);
            } else{ // 제한 없음 값이 새로 추가된 경우
                selectedOption = selectedOption.filter((obj) => obj[noLimitKey] == noLimitCode);
            }
        }

        this.setState({
            [stateName]: selectedOption,
            [`show_${stateName}_error_msg`] : false,
        }, () => {
            if(this.state.select_frequency_visit_list.length == 1 && this.state.select_frequency_visit_list[0].code_cd == "81002"){
                this.setState({
                    select_visit_period_list : [this.state.visit_period_list.find(obj => obj.code_cd == "82999")],
                    show_select_visit_period_list_error_msg : false,
                });
            }
        });
    };

    handleChange = (stateName, value) => {
        this.setState({
            [stateName] : value,
            [`show_${stateName}_error_msg`] : false,
        });
    }

    // 저장 이벤트
    handleSave = () => {
        let errorFocusID;
        
        
        if(this.state.select_customer_group_list.length == 0){
            this.setState({show_select_customer_group_list_error_msg : true});
            errorFocusID = "customerGroup";
        }
        if(this.state.prefer_category_depth == 1 && this.state.select_prefer_category_list.length == 0){
            this.setState({show_select_prefer_category_list_error_msg : true});
            errorFocusID = "preferCategory";
        }
        if(this.state.prefer_category_depth == 2 && this.state.select_prefer_category_list_2.length == 0){
            this.setState({show_select_prefer_category_list_error_msg_2 : true});
            errorFocusID = "preferCategory2";
        }
        if(this.state.select_referrer_type_list.length == 0){
            this.setState({show_select_referrer_type_list_error_msg : true});
            errorFocusID = "referrerType";
        }
        if(this.state.select_device_type_list.length == 0){
            this.setState({show_select_device_type_list_error_msg : true});
            errorFocusID = "deviceType";
        }
        if(this.state.select_duration_time_list.length == 0){
            this.setState({show_select_duration_time_list_error_msg : true});
            errorFocusID = "durationTime";
        }
        if(this.state.select_visit_period_list.length == 0){
            this.setState({show_select_visit_period_list_error_msg : true});
            errorFocusID = "visitPeriod";
        }
        if(this.state.select_frequency_visit_list.length == 0){
            this.setState({show_select_frequency_visit_list_error_msg : true});
            errorFocusID = "frequencyVisit";
        }
        if(!this.state.title){
            this.setState({show_title_error_msg : true});
            errorFocusID = "title";
        }

        if(errorFocusID){
            document.getElementById(errorFocusID).focus();
            return false;
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        let body = {
            target_view_seq : this.state.target_view_seq,
            title : this.state.title,
            prefer_category_depth : this.state.prefer_category_depth,
            frequency_visit_code_cd_list : this.state.select_frequency_visit_list.map((obj) => obj.code_cd),
            visit_period_code_cd_list : this.state.select_visit_period_list.map((obj) => obj.code_cd),
            duration_time_code_cd_list : this.state.select_duration_time_list.map((obj) => obj.code_cd),
            device_type_code_cd_list : this.state.select_device_type_list.map((obj) => obj.code_cd),
            referrer_type_code_cd_list : this.state.select_referrer_type_list.map((obj) => obj.code_cd),
            prefer_category_code_cd_list : this.state.select_prefer_category_list.map((obj) => obj.category_no),
            prefer_category_code_cd_list_2 : this.state.select_prefer_category_list_2.map((obj) => obj.category_no),
            customer_group_list : this.state.select_customer_group_list.map((obj) => obj.group_no),
        }
        
        http.post("/rest/shop/targetview/save", body, config)
        .then(res => {
            if(res.data.code=="200"){
                alert("저장 되었습니다.");
                this.goTargetViewList();
            } else{
                alert("저장에 실패하였습니다.");
            }
        })
        .catch(error => {
            console.log (error);
            alert("저장에 실패하였습니다.");
        });
    };

    /** 타겟뷰 목록으로 이동 */
    goTargetViewList = () => {
        // 레퍼러
        if (this.props.location.state && this.props.location.state.prevPath.indexOf("/shop/targetview/list") > 0){
            this.props.history.goBack();
        } else {
            // 로케이션
            this.props.history.push("/shop/targetview/list")
        }
    }

    onChangePreferCategoryDepth = (category_depth) => {
        this.setState({
            prefer_category_depth : category_depth,
        })
    }

    render() {
        return (
            <>
                <div id='common-page-wrapper' className={"targetViewSetting"}>
                    <div className={styles.wrap}>
                        <div className={styles.page_title}>타겟 설정</div>
                        <div className={styles.box}>
                            <div className={styles.title}>타겟 제목</div>
                            <TextInput
                                id={"title"}
                                fontSize={"14px"}
                                maxWidth={"600px"}
                                height={"36px"}
                                padding={"4px, 12px, 4px, 16px"}
                                placeholder={"제목을 입력해 주세요."}
                                maxlength={50}
                                value={this.state.title}
                                onChange={(e) => {this.handleChange("title", e.target.value)}}
                                infoText={this.state.show_title_error_msg && "해당 항목이 입력되지 않았습니다."}
                                error={this.state.show_title_error_msg}
                            />
                        </div>
                        <div className={styles.box}>
                            <div className={styles.title}>타겟 정보</div>
                            <div className={styles.content}>맞춤 노출을 원하는 타겟의 상세 분류 기준을 설정해 주세요.</div>
                            <div className={styles.select_area}>
                                <div className={styles.select_title}>방문 빈도</div>
                                <div className={styles.select}>
                                    <Select
                                        className={["react-select", this.state.show_select_frequency_visit_list_error_msg && "error"].join(' ')}
                                        value={this.state.select_frequency_visit_list}
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        placeholder={this.state.show_select_frequency_visit_list_error_msg ? "해당 옵션을 선택해 주세요." : "타겟의 방문 빈도를 모두 선택해 주세요."}
                                        noOptionsMessage={() => "없음"}
                                        getOptionValue={(option) => option.code_cd}
                                        getOptionLabel={(option) => option.code_name}
                                        options={this.state.frequency_visit_list}
                                        onChange={(selectedOption) => this.handleNoLimitSelectChange("select_frequency_visit_list", selectedOption, "code_cd", 81001)}
                                        styles={reactSelectStyles()}
                                        inputId={"frequencyVisit"}
                                    />
                                </div>
                                {this.state.show_select_frequency_visit_list_error_msg &&
                                    <div className={styles.select_error_msg}>해당 항목이 입력되지 않았습니다.</div>}
                            </div>
                            <div className={styles.select_area}>
                                <div className={styles.select_title}>방문 기간</div>
                                <div className={styles.select}>
                                    <Select
                                        className={["react-select", this.state.show_select_visit_period_list_error_msg && "error", this.state.select_frequency_visit_list.length == 1 && this.state.select_frequency_visit_list[0].code_cd == "81002" && "disabled"].join(' ')}
                                        value={this.state.select_visit_period_list}
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        placeholder={this.state.show_select_visit_period_list_error_msg ? "해당 옵션을 선택해 주세요." : "타겟의 방문 기간을 모두 선택해 주세요."}
                                        noOptionsMessage={() => "없음"}
                                        getOptionValue={(option) => option.code_cd}
                                        getOptionLabel={(option) => option.code_name}
                                        options={this.state.visit_period_list}
                                        onChange={(selectedOption) => this.handleNoLimitSelectChange("select_visit_period_list", selectedOption, "code_cd", 82999)}
                                        styles={reactSelectStyles()}
                                        inputId={"visitPeriod"}
                                        isDisabled={this.state.select_frequency_visit_list.length == 1 && this.state.select_frequency_visit_list[0].code_cd == "81002" ? true : false}
                                    />
                                </div>
                                {this.state.show_select_visit_period_list_error_msg &&
                                    <div className={styles.select_error_msg}>해당 항목이 입력되지 않았습니다.</div>}
                            </div>
                            <div className={styles.select_area}>
                                <div className={styles.select_title}>체류 시간</div>
                                <div className={styles.select}>
                                    <Select
                                        className={["react-select", this.state.show_select_duration_time_list_error_msg && "error"].join(' ')}
                                        value={this.state.select_duration_time_list}
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        placeholder={this.state.show_select_duration_time_list_error_msg ? "해당 옵션을 선택해 주세요." : "타겟의 사이트 체류 시간을 모두 선택해 주세요."}
                                        noOptionsMessage={() => "없음"}
                                        getOptionValue={(option) => option.code_cd}
                                        getOptionLabel={(option) => option.code_name}
                                        options={this.state.duration_time_list}
                                        onChange={(selectedOption) => this.handleNoLimitSelectChange("select_duration_time_list", selectedOption, "code_cd", 83001)}
                                        styles={reactSelectStyles()}
                                        inputId={"durationTime"}
                                    />
                                </div>
                                {this.state.show_select_duration_time_list_error_msg &&
                                    <div className={styles.select_error_msg}>해당 항목이 입력되지 않았습니다.</div>}
                            </div>
                            <div className={styles.select_area}>
                                <div className={styles.select_title}>접속 기기</div>
                                <div className={styles.select}>
                                    <Select
                                        className={["react-select", this.state.show_select_device_type_list_error_msg && "error"].join(' ')}
                                        value={this.state.select_device_type_list}
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        placeholder={this.state.show_select_device_type_list_error_msg ? "해당 옵션을 선택해 주세요." : "타겟의 접속 기기를 모두 선택해 주세요."}
                                        noOptionsMessage={() => "없음"}
                                        getOptionValue={(option) => option.code_cd}
                                        getOptionLabel={(option) => option.code_name}
                                        options={this.state.device_type_list}
                                        onChange={(selectedOption) => this.handleNoLimitSelectChange("select_device_type_list", selectedOption, "code_cd", 84001)}
                                        styles={reactSelectStyles()}
                                        inputId={"deviceType"}
                                    />
                                </div>
                                {this.state.show_select_device_type_list_error_msg &&
                                    <div className={styles.select_error_msg}>해당 항목이 입력되지 않았습니다.</div>}
                            </div>
                            <div className={styles.select_area}>
                                <div className={styles.select_title}>방문 경로</div>
                                <div className={styles.select}>
                                    <Select
                                        className={["react-select", this.state.show_select_referrer_type_list_error_msg && "error"].join(' ')}
                                        value={this.state.select_referrer_type_list}
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        placeholder={this.state.show_select_referrer_type_list_error_msg ? "해당 옵션을 선택해 주세요." : "타겟의 방문 경로를 모두 선택해 주세요."}
                                        noOptionsMessage={() => "없음"}
                                        getOptionValue={(option) => option.code_cd}
                                        getOptionLabel={(option) => option.code_name}
                                        options={this.state.referrer_type_list}
                                        onChange={(selectedOption) => this.handleNoLimitSelectChange("select_referrer_type_list", selectedOption, "code_cd", 85001)}
                                        styles={reactSelectStyles()}
                                        inputId={"referrerType"}
                                    />
                                </div>
                                {this.state.show_select_referrer_type_list_error_msg &&
                                    <div className={styles.select_error_msg}>해당 항목이 입력되지 않았습니다.</div>}
                            </div>
                            <div className={styles.select_area}>
                                <div className={styles.select_title}>선호 카테고리</div>
                                <div className="flexBox m-b-20 m-t-20">
                                    <RadioButton
                                        value={1}
                                        text={"대분류 카테고리"}
                                        checked={this.state.prefer_category_depth == 1 ? true : false}
                                        onChangeCheck={() => this.onChangePreferCategoryDepth(1)}
                                    />
                                    <RadioButton
                                        value={2}
                                        text={"중분류 카테고리"}
                                        checked={this.state.prefer_category_depth == 2 ? true : false}
                                        onChangeCheck={() => this.onChangePreferCategoryDepth(2)}
                                    />
                                </div>
                                <div className={styles.select}>
                                {
                                    this.state.prefer_category_depth == 1 ?
                                        <Select
                                            className={["react-select", "referrer_type", this.state.show_select_prefer_category_list_error_msg && "error"].join(' ')}
                                            value={this.state.select_prefer_category_list}
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            isMulti
                                            placeholder={this.state.show_select_prefer_category_list_error_msg ? "해당 옵션을 선택해 주세요." : "타겟의 선호 카테고리를 모두 선택해 주세요."}
                                            noOptionsMessage={() => "없음"}
                                            getOptionValue={(option) => option.category_no}
                                            getOptionLabel={(option) => option.category_name}
                                            options={this.state.prefer_category_list}
                                            onChange={(selectedOption) => this.handleNoLimitSelectChange("select_prefer_category_list", selectedOption, "category_no", -1)}
                                            styles={reactSelectStyles()}
                                            inputId={"preferCategory"}
                                        />
                                    : this.state.prefer_category_depth == 2 ?
                                        <Select
                                            className={["react-select", "referrer_type", this.state.show_select_prefer_category_list_error_msg_2 && "error"].join(' ')}
                                            value={this.state.select_prefer_category_list_2}
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            isMulti
                                            placeholder={this.state.show_select_prefer_category_list_error_msg_2 ? "해당 옵션을 선택해 주세요." : "타겟의 선호 카테고리를 모두 선택해 주세요."}
                                            noOptionsMessage={() => "없음"}
                                            getOptionValue={(option) => option.category_no}
                                            getOptionLabel={(option) => option.category_name}
                                            options={this.state.prefer_category_list_2}
                                            onChange={(selectedOption) => this.handleNoLimitSelectChange("select_prefer_category_list_2", selectedOption, "category_no", -1)}
                                            styles={reactSelectStyles()}
                                            inputId={"preferCategory2"}
                                        />
                                    : ""
                                }
                                </div>
                                {this.state.show_select_prefer_category_list_error_msg &&
                                    <div className={styles.select_error_msg}>해당 항목이 입력되지 않았습니다.</div>}
                            </div>
                            <div className={styles.select_area}>
                                <div className={styles.select_title}>사용자 그룹</div>
                                <div className={styles.select}>
                                    <Select
                                        className={["react-select", "category_type", this.state.show_select_customer_group_list_error_msg && "error"].join(' ')}
                                        value={this.state.select_customer_group_list}
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        placeholder={this.state.show_select_customer_group_list_error_msg ? "해당 옵션을 선택해 주세요." : "타겟의 사용자 그룹을 모두 선택해 주세요."}
                                        noOptionsMessage={() => "없음"}
                                        getOptionValue={(option) => option.group_no}
                                        getOptionLabel={(option) => option.group_name}
                                        options={this.state.customer_group_list}
                                        onChange={(selectedOption) => this.handleNoLimitSelectChange("select_customer_group_list", selectedOption, "group_no", -1)}
                                        styles={reactSelectStyles()}
                                        inputId={"customerGroup"}
                                    />
                                </div>
                                {this.state.show_select_customer_group_list_error_msg &&
                                    <div className={styles.select_error_msg}>해당 항목이 입력되지 않았습니다.</div>}
                            </div>
                            <Alert text={"설정한 조건에 포함되는 고객이 없는 경우, 소재가 노출되지 않으니 주의해 주세요."}/>
                        </div>
                        <div className={styles.button_area}>
                            <Button
                                type={"hover_white"}
                                width={"158px"}
                                height={"46px"}
                                onClick={this.goTargetViewList}
                            >
                                취소
                            </Button>
                            <Button
                                width={"158px"}
                                height={"46px"}
                                onClick={this.handleSave}
                            >
                                저장하기
                            </Button>
                        </div>
                        {(this.state.show_title_error_msg
                        || this.state.show_select_frequency_visit_list_error_msg
                        || this.state.show_select_visit_period_list_error_msg
                        || this.state.show_select_duration_time_list_error_msg
                        || this.state.show_select_device_type_list_error_msg
                        || this.state.show_select_referrer_type_list_error_msg
                        || this.state.show_select_prefer_category_list_error_msg
                        || this.state.show_select_customer_group_list_error_msg) &&
                        <Alert className={styles.warning} type={"warning"} text={"입력되지 않은 항목이 있어요. 모든 항목을 입력하고 다시 시도해 주세요."}/>}
                    </div>
                </div>
            </>
        )
    }
}

export default TargetViewSetting;
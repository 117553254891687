import React from 'react';
import axios from 'axios';
import http from '../modules/api';
import {Route, Switch, NavLink, Link} from "react-router-dom";
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";
import DayPicker from 'react-day-picker';
import trend_calendar from '../../resources/img/trend_calendar.png'

import "react-datepicker/dist/react-datepicker.css";

import $ from 'jquery';

var is_checkbox_ckick = false;
http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
export class InfluenceList extends React.Component {
	state = {
        influence_list: [],
		maxScore: 0,
		dateFrom: '',
		dateTo: '',
        target_date: new Date('2020-11-04'),

        event_type: [],
        event_mobile_type: [],
        event_list: [],
        orderGubun: "",
        sorting: "",
        currentPage: "",
        start_date: "",
        end_date: "",
        start_date_format: "",
        end_date_format: "",
        title: "",
        activePage: 1,
        totalCnt: 0,
        startIdx: 0,
        rowcnt:10,
        isCreate: false,
        checkedEventList: [],
        eventDisplayYn: "Y",
        selectedDays : [],
    };
    
    calcPercent = (score) => {
        return Math.round((score/this.state.maxScore)*100);
    }

    accountType = (type) => {
        switch(type) {
        case '0010':
            return '판매자';
        case '0020':
            return '미판매자';
        default:
			return '미분류';
        }

    }

	handleText = (e, key) => {
	    // computed property
		this.setState({[key]: e.target.value});
	}

	handleInsertExcept = (account_id) => {
		if( !window.confirm("삭제하시겠습니까?") ) return false;
		var url = "/rest/common/sns/influence/delete";
		url += "?account_id=" + account_id;
		http.get(url).then(res => {
			this.getInfluenceList();
		});
	}
	
	componentDidMount(e) {
		$("#modalBack").css("display","none");
		$("#modal_event_display").css("display","none");
		$(".modalClose").click(function() {
            $("#modalBack").hide();
            $("#modal").hide();
            $("#modal_event_display").hide();
            $("body").css("background-color", "white");
            $("#modalBack").css("background-color", "white");
            $("#page-wrapper").css("background-color", "white");
        });

		if (localStorage.getItem ('state') != null){
			new Promise( ( resolve, reject ) => {setTimeout( resolve, 200 ); })
			.then( () => {
                var json = JSON.parse(localStorage.getItem ('state'));
                json.influence_list = [];
                json.target_date = Date.parse(json.target_date_format);
                json.max_sum_score = 0;
                this.setState(json);
                localStorage.clear();
                this.getInfluenceList();
            });
        } else {
            this.getInfluenceList();
        }
	}

	componentDidUpdate(e) {
		// console.log("######### componentDidUpdate ##########")
	}

	onComponentChange = e => {
		// console.log("######### onComponentChange ##########")
	}

	componentWillUnmount = e => {
		// console.log("######### componentWillUnmount ##########")
	}
	
	//1. 목록 조회
	async getInfluenceList() {
		var url = "/rest/common/sns/influence"
		// url += "?date=" + this.state.target_date.replace(/-/gi, "")
		url += "?date=" + this.convDate(this.state.target_date);
		url += "&order_type=" + this.state.sorting
		url += "&order_gubun=" + this.state.orderGubun
		console.log(url);
		await http.get(url)
		.then(res => {
			console.log ("===== res =====");
			console.log(res);
			if(typeof res.data["list"] !== 'undefined') {
				this.setState( {influence_list : res.data["list"]} );
			} else {
				this.setState({ influence_list : [] });
			}
			if (typeof res.data["totalCnt"] !== 'undefined') {
				this.setState( {totalCnt : res.data["totalCnt"]} );
			} else {
				this.setState( {totalCnt : 0} );
			}
			if (typeof res.data["maxScore"] !== 'undefined') {
				this.setState( {maxScore : res.data["maxScore"]} );
			} else {
				this.setState( {maxScore : 0} );
			}
			if (typeof res.data["dateFrom"] !== 'undefined') {
				this.setState( {dateFrom : res.data["dateFrom"]} );
			} else {
				this.setState( {dateFrom : ''} );
			}
			if (typeof res.data["dateTo"] !== 'undefined') {
				this.setState( {dateTo : res.data["dateTo"]} );
			} else {
				this.setState( {dateTo : ''} );
			}

			// console.log ("===== res =====");
			// console.log(res);
		})
		.catch(error => {
			// console.log ("===== error =====");
			// console.log (error);
			// console.log ("===== error request =====");
			// console.log(error.request);
		 });
    }
	
	//2. 상세 조회
	handleClick = (event_seq) => {
		if (is_checkbox_ckick) {
			is_checkbox_ckick = false;
			return false;
		}

		this.props.history.push(`/shop/cafe24/event/form?event_seq=`+event_seq);
		localStorage.setItem ( 'state' , JSON.stringify(this.state) ) ;
		localStorage.setItem ( 'startIdx' , this.state.startIdx ) ;
	};

	// 3. 정렬
	onClickSorting = (e) => {
		if (this.state.orderGubun == e.target.className.replace(" sorting","")) {
			if (this.state.sorting == "ASC")
			this.state.sorting = "DESC";
			else
			this.state.sorting = "ASC";
		} else {
			this.state.sorting = "DESC";
		}
		this.state.orderGubun = e.target.className.replace(" sorting","");
		this.state.currentPage = 1;
		if( this.state.orderGubun === 'card-text' ) return;
		this.getInfluenceList()
	};

	//4. 조회 버튼 클릭
	onClickSearchListButton = () => {
		this.state.startIdx = 0;
		this.state.activePage = 1;
		this.getInfluenceList();
	};

	//4. 달력 클릭
	handleChangeStartDate = date => {
		if (date != null) {
			this.setState({
				start_date_format: date.getFullYear() + "-"+ parseInt(date.getMonth()+1) +"-"+date.getDate(),
				start_date: date
			});
		} else {
			this.setState({
				start_date_format: date,
				start_date: date
			});
		}
	};
	handleChangeEndDate = date => {
		if (date != null) {
			this.setState({
				end_date_format: date.getFullYear() + "-"+ parseInt(date.getMonth()+1) +"-"+date.getDate(),
				end_date: date
			});
		} else {
			this.setState({
				end_date_format: date,
				end_date: date
			});
		}
	};

	//5. 엔터키 검색
	_handleKeyDown = e => {
		if (e.key === 'Enter') {
			this.state.startIdx = 0;
			this.state.activePage = 1;
			this.getInfluenceList();
		}
	  }

	//6.페이징
	handlePageChange(pageNumber) {
		// console.log(`active page is ${pageNumber}`);
		this.setState({activePage: pageNumber
						, checkedEventList: []});
		$("input[name=select_event_all]").prop("checked", false);				
		this.state.startIdx = (pageNumber * 10) - this.state.rowcnt;
		// console.log("this.state.startIdx :" + this.state.startIdx )
		this.getEventList()
	}  

	componentWillReceiveProps(nextProps, nextContext) {
        // console.log(nextProps);
		this.getInfluenceList(nextProps.match.params.id);
	}

	convDate = (date) => {
		return date.toISOString().substring(0,10).replace(/-/gi, '');
	}

	handleSelect = (date) => {
		this.setState({
			target_date: date,
		}, () => {
			this.getInfluenceList();
		});
	}

	makeKeyword = (value) => {
		if( value === '' ) return '';
		return '#' + value;
	}

	makeDate = (value) => {
		if( value.length !== 8 ) return '';
		return value.substring(0,4) + '-' + value.substring(4,6) + '-' + value.substring(6,8);
	}

	makeDateMessage = () => {
		if( this.state.dateFrom === '' ) {
			return '데이터가 존재하지 않습니다.';
		} else {
			return this.makeDate(this.state.dateFrom) + ' ~ ' + this.makeDate(this.state.dateTo) + ' 사이에서 추출된 데이터입니다.';
		}
	}

	  // 6.체크박스
	onClickSelectEvent = e => {
		is_checkbox_ckick =  true;
		// if (e.target.id != null && e.target.id != "" && e.target.id.indexOf("check_area_") >= 0) {
		// 	// $("#"+e.target.id+" input[name=select_event]").click();
		// }
	}
	handleChangeSelectEvent = (e, event_seq) => {
		if (e.target.checked) {
			this.state.checkedEventList.push(event_seq);
		} else {
			this.state.checkedEventList.splice(this.state.checkedEventList.indexOf(event_seq), 1);
		}
		console.log(this.state.checkedEventList)
    }

	render() {
		return (
			<div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
				<div id="influence_list_component">
					<h3>인플루언서 목록</h3>
					<br/>
					<div className="filter">
                        { /*
                        <div className="flexBox trendWeeklyCalendarArea" style={{position:"relative", marginBottom:"25px", justifyContent: "space-between"}}>
                            <div id="trendCalendar" style={{border:"1px solid #70707033", background:"white", position:"absolute", top:"-18px", right:"0px", zIndex:"999", boxShadow: "1px 5px 10px 0px lightgrey"}}>
                                <div style={{textAlign:"center", marginTop:"10px"}}>
                                    <input type="radio" className="radio_Weekly_Daily" name="daily" id="daily" value="daily" onClick={this.onClickDaily}/> 일간
                                    &nbsp;
                                    <input type="radio" className="radio_Weekly_Daily" name="weekly" id="weekly" value="weekly" onClick={this.onClickWeekly}/> 주간
                                </div>
                                <div id="weeklyCalendar">
                                    <DayPicker
                                        month={selectedDays[selectedDays.length-1]} 
                                        selectedDays={selectedDays}
                                        showWeekNumbers
                                        showOutsideDays
                                        modifiers={modifiers}
                                        onDayClick={this.handleDayChange}
                                        onDayMouseEnter={this.handleDayEnter}
                                        onDayMouseLeave={this.handleDayLeave}
                                        onWeekClick={this.handleWeekClick}
                                        disabledDays={[
                                            {
                                                after:  new Date(new Date().setDate(new Date().getDate() - new Date().getDay())),
                                                before: new Date("2018-01-01")
                                            }
                                        ]}
                                        todayButton="오늘로 이동"
                                    />
                                </div>
                                <div id="dailyCalendar">
                                    <DayPicker
                                        month={selectedDays[selectedDays.length-1]} 
                                        selectedDays={selectedDays}
                                        // showWeekNumbers
                                        showOutsideDays
                                        modifiers={modifiers}
                                        onDayClick={this.handleDayChangeForDaily}
                                        onDayMouseEnter={this.handleDayEnter}
                                        onDayMouseLeave={this.handleDayLeave}
                                        onWeekClick={this.handleWeekClick}
                                        disabledDays={[
                                            {
                                                after: new Date(),
                                                before: new Date("2018-01-01")
                                            }
                                        ]}
                                        todayButton="오늘로 이동"
                                    />
                                </div>
                                <div className="flexBox cursor-pointer" style={{justifyContent: "center", marginBottom:"10px"}}>
                                    <div className="greenLineButton" style={{width: "40px", height:"30px", lineHeight:"30px", textAlign:"center"}}
                                    onClick={this.onClickCalendarCancel}>
                                        취소
                                    </div>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div className="greenButton cursor-pointer" style={{width: "40px", height:"30px", lineHeight:"30px", textAlign:"center"}}
                                    onClick={this.onClickCalendarApply}>
                                        적용
                                    </div>
                                </div>
                            </div>    
                        </div>
                                    */}

						<div style={{display:"flex", flexWrap:"wrap", marginBottom: '-12px'}}>
							<div className="input-group m-b mobile_width_100 event_list_cal" style={{width:"40%", marginRight:"10px"}}>
								<div style={{width: "100%"}}> 
                                    <label className="mobile_font_size_11">날짜</label>
									<div className="input-daterange input-group" id="datepicker">
										<DatePicker name="start_date" id="start_date"
											selected={new Date(this.state.target_date)}
											onSelect={this.handleSelect} //when day is clicked
											placeholderText="일자"
                                            dateFormat="yyyy-MM-dd"
                                            autoComplete="off"
											autoComplete='off'
										/>
                                    </div>
                                </div>
                            </div>
						</div>
					</div>
					<div className="table">
						{this.makeDateMessage()}
						<table>
							<colgroup>
								<col width="100px" />
								<col width="*" />
								<col width="80px" />
								<col width="80px" />
								<col width="80px" />
								<col width="100px" />
								<col width="180px" />
								<col width="150px" />
							</colgroup>
							<thead>	
								<tr>
									<td>
										<p className="card-text">프로필</p>
									</td>
									<td>
										<p className="card-text">소개글</p>
									</td>
									<td className="media_count sorting" onClick={this.onClickSorting}>
										<p className="card-text">피드수</p>
									</td>
									<td className="followed sorting" onClick={this.onClickSorting}>
										<p className="card-text">팔로우</p>
									</td>
									<td className="follow sorting" onClick={this.onClickSorting}>
										<p className="card-text">팔로잉</p>
									</td>
									<td>
										<p className="card-text">관련URL</p>
									</td>
									<td>
										<p className="card-text">판매예측</p>
									</td>
									<td className="rank sorting" onClick={this.onClickSorting}>
										<p className="card-text">인플루언서 예측율</p>
									</td>
									<td>
										<p className="card-text">사용해시태그</p>
									</td>
									<td>
										<p className="card-text">삭제</p>
									</td>
								</tr>
							</thead>
							<tbody>
								{this.state.influence_list.map((influence, idx) => (
									<tr key={influence.account_id}>
										<td>
											<p className="card-text text-left">
												<a href={'https://www.instagram.com/' + influence.username} target="_blank">{influence.username}</a>
												{ influence.exist !== 1 ? ' (중복)' : '' }
											</p>
										</td>
										<td>
											<p className="card-text text-left" style={{ width: "250px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>{influence.biography}</p>
										</td>
										<td>
											<p className="card-text text-right">{influence.media_count.toLocaleString()}</p>
										</td>
										<td>
											<p className="card-text text-right">{influence.followed.toLocaleString()}</p>
										</td>
										<td>
											<p className="card-text text-right">{influence.follow.toLocaleString()}</p>
										</td>
										<td>
											<p className="card-text text-left"><a href={influence.external_url} target="_blank">{influence.external_url}</a></p>
										</td>
										<td>
											<p className="card-text">{this.accountType(influence.account_type)}</p>
										</td>
										<td>
											<p className="card-text text-left"><progress value={influence.sum_score} max={this.state.maxScore} style={{ width: '50%' }}/> {this.calcPercent(influence.sum_score)}%</p>
										</td>
										<td>
											<p className="card-text">{this.makeKeyword(influence.keyword)}</p>
										</td>
										<td>
											<p className="card-text"><button onClick={() => this.handleInsertExcept(influence.account_id)}>삭제</button></p>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					{/* <Pagination
						activePage={this.state.activePage}
						itemsCountPerPage={this.state.rowcnt}
						totalItemsCount={this.state.totalCnt}
						pageRangeDisplayed={5}
						onChange={this.handlePageChange.bind(this)}
					/> */}
				</div>

				{/* 팝업창 배경 */}
                <div id="modalBack" style={{top: "0", height:"130%"}}></div>
			</div>
		);
	}
}

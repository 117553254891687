import axios from 'axios';
import { setCookie } from "./utils/CookieUtil";
import { deleteCookie } from "./utils/CookieUtil";

var isErrorAlert = true;
export function setIsErrorAlert (state) {
    isErrorAlert = state;
}
export const http = axios.create({
	headers: {
        'Connection' : 'keep-alive', 
        'Keep-Alive': 'timeout=40000'
    }
});
//http.Agent({ keepAlive: true });
//https.Agent({ keepAlive: true });
if (window.location.href.indexOf(":3000") < 0) {
    http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    //http.defaults.headers.common['Cache-Control']    = 'no-cache';
    http.interceptors.response.use(
        function (response) {
            //오류 메시지 처리 후 로그아웃
        	if (response.request != null) {
        		if (response.request.responseURL != null && response.request.responseURL.indexOf("/exception/entrypoint") > 0) {
        			alert(response.data.msg);
        			window.location.href = "/logout";
        			return false;
        		}        		
        	}
        // 응답 데이터를 가공
        // ...
        return response;
        },
        function (error) {
            if (error.request.responseURL != null ) {
                if (error.request.responseURL.indexOf("/login") > 0) {
                    if (error.request.responseURL.indexOf("error") > 0) {
                        alert("가입하지 않은 아이디이거나, 잘못된 비밀번호입니다.")
                        // window.location.href = "/login"
                    } else {
                        alert("#api : 로그인 후 사용해 주세요.")
                        sessionStorage.removeItem("user_id");
                        sessionStorage.removeItem("userName");
                        sessionStorage.removeItem("roleType");
                        sessionStorage.removeItem("redirectUrl");
                        sessionStorage.removeItem("solution_type_cd");
                        sessionStorage.removeItem("RP_LIST_OPERATION_BANNER");
                        deleteCookie("user_id");
                        deleteCookie("solution_type_cd");
                        window.location.href = "/logout"
                    }
                } else {
                    if (window.location.href.indexOf("/login") > 0) {
                        var responseURL = error.request.responseURL;
                        axios.get(`/userSessionType`).then(
                            res => {
                                //console.log(res.data)
                                sessionStorage.setItem("user_id", res.data.user_id);
                                sessionStorage.setItem("userName", res.data.userName);
                                sessionStorage.setItem("roleType", res.data.roleType);
                                sessionStorage.setItem("serviceType", res.data.service_type);
                                sessionStorage.setItem("redirectUrl", res.data.redirectUrl);
                                sessionStorage.setItem("solution_type_cd", res.data.solution_type_cd);
                                setCookie("user_id", res.data.user_id);
                                setCookie("solution_type_cd", res.data.solution_type_cd);
                                window.location.href = responseURL;
                            })
                        .catch(
                            //  error => { alert("인증되지 않은 사용자 입니다."); console.log(error); }
                        );
                    } else {
                        if (isErrorAlert) {
                            alert("데이터를 불러올 수 없습니다.\n"
    //		                        +"- status: "+error.request.status+"\n"
    //		                        +"- responseURL: "+error.request.responseURL+"\n"
                                    +"관리자에게 문의해주세요.\n"
                                    +"고객센터 : 1599-3518"
                            );
                        }
                    }
                }
            } 
        return Promise.reject(error);
    });
}

/** TB_CAFE24_SHOPS.sync_state long polling */
export const subscribeShopDataSyncState = async (time) => {
    while (true) {
        await new Promise(resolve => setTimeout(resolve, 10000))

        /** 최초 10초 ~ 최대 한시간  */
        if ((new Date().getTime() - time) > 3600000) return "cafe24 연동 1시간 초과"
        /** 윈도우 비활성 상태일 경우 재호출 건너뜀. */
        if (document.hidden) continue

        const response = await axios.create({
            headers: {
                'Connection' : 'keep-alive',
                'Keep-Alive' : 'timeout=40000',
                'X-requested-With' : 'XMLHttpRequest'
            }
        }).get("/rest/shop/shopSyncStateAndSyncDate")
        if (!response.data) return response
        else if (response.data.sync_state === "0") return true
        else if (response.data.msg) return response.data.msg
        else if (response.data.data_sync_declined === "Y") return 0;
    }
}

export default http;
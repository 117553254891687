import React from 'react';
import axios from '../modules/api';
import http from '../modules/api';
import {Line} from 'react-chartjs-2';
import { NavLink, Link } from 'react-router-dom';
import $ from 'jquery';
import Slider from "react-slick";
import Pagination from "react-js-pagination";

import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";

import round_loading_bar from '../../resources/img/round_loading_bar.gif';

import ReactGA, { send } from 'react-ga';
ReactGA.initialize('UA-78553461-8', {
    titleCase: false,
});

var first_loaded = "false";
export class RpEventProducHist extends React.Component {
    constructor(props) {
		super(props);
        var today = new Date();
        var reg_date = new Date(Number(today.getFullYear()), Number(today.getMonth()), Number(today.getDate()));
        var reg_date_format = this.dateFormat(reg_date);
		this.state = {
            show_admin_page_2_popup : false,
            before_first_load : true,
            show_loading_bar: false,
            
            rp_product_list : [
            
            ],

            rp_check_product_list : [

            ],

            iframe_page_id_list : [

            ],
            
            iframe_html_id_list : [

            ],

            created_date_list : [

            ],

            userAndShop: "",
            user_seq: "",
            shop_no: "",
            page_id: "",
            html_id: "",
            
            reg_date: reg_date,
            reg_date_format: reg_date_format,
        }
    }

    componentWillMount() {
	
    }

	componentDidMount() {
        // this.loadRpEventProductList();
        this.getCafe24Code("16000");
        this.loadUserAndShopList();
        var this_component = this;
    }

    componentDidUpdate() {
    }

    componentWillUnmount(e) {
    }

    dateFormat(reg_date) {
        var date = reg_date;
        var year = date.getFullYear();
        var month = (1 + date.getMonth());
        month = month >= 10 ? month : '0' + month;
        var day = date.getDate();
        day = day >= 10 ? day : '0' + day;
        var date_message = String(year) + String(month) + String(day);
        return date_message;
    }

    onSelectFromDate = (date) => {
        if (date != null && date != "") {
            this.setState({
                reg_date: date,
                reg_date_format: this.dateFormat(date),
            });
        }
    }

    getUrlParams() {
        var params = {};
        window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(str, key, value) { params[key] = value; });
        return params;
    }

    //공통코드 조회
	async getCafe24Code(upper_code_cd) {
		const res = await http.get( "/rest/common/cafe24Code"
									+"?upper_code_cd="+upper_code_cd);
		if (upper_code_cd == "16000") {
			this.setState({iframe_page_id_list: res.data})
		}
	};

    //배너 html_id 조회
	async getRobomdPageHtmlId(page_id) {
		const res = await http.get("/rest/common/rp/PageHtmlId?user_seq="+this.state.user_seq+"&shop_no="+this.state.shop_no+"&type=html&iframe_page_id="+page_id);
        if(res.data != null) {
            this.setState({iframe_html_id_list: res.data.html_id_list});
        }
	};

    //해당 html_id의 생성일자 그룹 조회
	async getRobomdPageHtmlIdCreatedDate(html_id) {
		const res = await http.get("/rest/common/rp/PageHtmlIdCreatedDate?user_seq="+this.state.user_seq+"&shop_no="+this.state.shop_no+"&type=html&iframe_page_id="+this.state.page_id+"&iframe_html_id="+html_id);
        if(res.data != null) {
            this.setState({created_date_list: res.data.created_date_list});
        }
	};

    async loadRpEventProductList() {
        // var page_id = this.getUrlParams().page_id;
        // var html_id = this.getUrlParams().html_id;

        if (!(this.state.user_seq != null && this.state.user_seq != "")) {
            alert("고객사를 선택해 주세요.");
            return false;
        }
        if (!(this.state.shop_no != null && this.state.shop_no != "")) {
            alert("고객사를 선택해 주세요.");
            return false;
        }
        if (!(this.state.page_id != null && this.state.page_id != "")) {
            alert("롤링 페이지 종류를 선택해 주세요.");
            return false;
        }
        if (!(this.state.html_id != null && this.state.html_id != "")) {
            alert("롤링 영역 종류를 선택해 주세요.");
            return false;
        }
        if (!(this.state.html_id != null && this.state.reg_date != "")) {
            alert("기준 일자를 선택해 주세요.");
            return false;
        }

        this.setState( {
            rp_product_list : [],
            // show_loading_bar : true,
        })
        await http.get(
                        "/rest/admin/rp/best/hist"
                        +"?iframe_page_id="+this.state.page_id
                        +"&iframe_html_id="+this.state.html_id
                        +"&user_seq="+this.state.user_seq
                        +"&shop_no="+this.state.shop_no
                        +"&reg_date="+this.state.reg_date
                      )
		.then(res => {
			if (res.data != null && res.data != '') {
				this.setState( {
                    rp_product_list : res.data["list"],
                    before_first_load : false
                }, () => {
                    if (this.state.rp_product_list.length <= 0) {
                        this.setState({
                            before_first_load : true
                        }, () => {
                            $("#no_product_message").text("상품이 존재 하지 않습니다.")
                        });
                    }
                });
			}
		})
		.catch(error => {
			// console.log ("===== error =====");
			// console.log (error);
			// console.log ("===== error request =====");
			// // console.log(error.request);
		 });
    }

    async loadUserAndShopList() {
        await http.get(
            "/rest/common/list/userShopsByAuthorities"
            +"?authority=ROLE_RP"
          )
            .then(res => {
            if (res.data != null && res.data != '') {
                this.setState( {
                    user_shop_list : res.data["list"],
                }, () => {

                });
            }
            })
            .catch(error => {
            // console.log ("===== error =====");
            // console.log (error);
            // console.log ("===== error request =====");
            // // console.log(error.request);
            });
    }

    onChangeUserAndShop = e => {
        var user_seq = e.currentTarget.value.split(",")[0];
        var shop_no = e.currentTarget.value.split(",")[1];
        this.setState({
            userAndShop: e.currentTarget.value,
            user_seq: [user_seq],
            shop_no: [shop_no],
            html_id: '',
            iframe_html_id_list: [],
            reg_date: '',
            created_date_list: []
        }, () => {
            if (this.state.page_id != null && this.state.page_id != "") {
                this.getRobomdPageHtmlId(this.state.page_id);
            }
        })
    }

    onChangePageId = e => {
        this.setState({
            page_id: e.currentTarget.value,
            html_id: '',
            iframe_html_id_list: [],
            reg_date: '',
            created_date_list: []
        }, () => {
            if (this.state.user_seq != null && this.state.user_seq != ""
                & this.state.shop_no != null && this.state.shop_no != "") {
                this.getRobomdPageHtmlId(this.state.page_id);
            }
        })
    }

    onChangehtmlId = e => {
        this.setState({
            html_id: e.currentTarget.value,
            reg_date: '',
            created_date_list: []
        })
        //해당 html_id의 생성일자 그룹 조회
	    this.getRobomdPageHtmlIdCreatedDate(e.currentTarget.value);
    }

    onChangeCreatedDate = e => {
        this.setState({
            reg_date: e.currentTarget.value,
        });
    }

    render() {
        return (
            <div id='page-wrapper' className="container rp_event_product_hist_container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
                <div class="admin_wrap">
                    <div className="pc_page_title">
                        <h4>베스트 상품 이력</h4>
                    </div>
                    <div className="admin_page">
                        <div className='admin_page_select'>
                            <div className="admin_flex_filter">
                                <div className="admin_page_txt">고객사</div>
                                <select className="admin_category" onChange={e => this.onChangeUserAndShop(e)} value={this.state.userAndShop} style={{flex:"1", maxWidth:"100%"}}>
                                    <option selected hidden>고객사를 선택하세요.</option>
                                    <option disabled>선택안함</option>
                                    {this.state.user_shop_list != null && this.state.user_shop_list.map((obj, idx) => (
                                        <option key={idx} value={obj.user_seq+","+obj.shop_no}>
                                            {obj.corp_name+" / "+obj.shop_name}
                                        </option>                            
                                    ))}
                                </select>
                            </div>
                            <div className="admin_flex_filter">
                                <div className="admin_page_txt">롤링 페이지 종류</div>
                                <select className="admin_category" onChange={e => this.onChangePageId(e)} value={this.state.page_id} style={{flex:"1", maxWidth:"100%"}}>
                                    <option selected hidden>롤링 페이지를 선택하세요.</option>
                                    <option disabled>선택안함</option>
                                    {this.state.iframe_page_id_list != null && this.state.iframe_page_id_list.map((obj, idx) => (
                                        <option key={idx} value={obj.code_name}>
                                            {obj.val1}
                                        </option>                            
                                    ))}
                                </select>
                            </div>
                            <div className="admin_flex_filter">
                                <div className="admin_page_txt">롤링 영역 종류</div>
                                {
                                    this.state.user_seq != null && this.state.user_seq != ""
                                    & this.state.shop_no != null && this.state.shop_no != ""
                                    & this.state.page_id != null && this.state.page_id != ""
                                    ?    <select className="admin_category" onChange={e => this.onChangehtmlId(e)} value={this.state.html_id} style={{flex:"1", maxWidth:"100%"}}>
                                            <option selected hidden>롤링 영역을 선택하세요.</option>
                                            <option disabled>선택안함</option>
                                            {this.state.iframe_html_id_list != null && this.state.iframe_html_id_list.map((obj, idx) => (
                                                <option key={idx} value={obj.iframe_html_id}>
                                                    {obj.iframe_html_name}
                                                </option>                            
                                            ))}
                                        </select>
                                    :   <select className="admin_category" onClick={e => alert("고객사와 배너페이지 종류를 먼저 선택해 주세요.")} onChange={e => this.onChangeRpCategory(e,2)} value={this.state.product_rp_category_no_1} style={{flex:"1", maxWidth:"100%"}}>
                                            <option selected hidden>롤링 영역을 선택하세요.</option>
                                        </select>
                                }
                            </div>
                            <div className="admin_flex_filter">
                                <div className="admin_page_txt">기준일자</div>
                                {
                                    this.state.user_seq != null && this.state.user_seq != ""
                                    & this.state.shop_no != null && this.state.shop_no != ""
                                    & this.state.page_id != null && this.state.page_id != ""
                                    & this.state.html_id != null && this.state.html_id != ""
                                    ?    <select className="admin_category" onChange={e => this.onChangeCreatedDate(e)} value={this.state.reg_date} style={{flex:"1", maxWidth:"100%"}}>
                                            <option selected hidden>기준일자를 선택하세요.</option>
                                            <option disabled>선택안함</option>
                                            {this.state.created_date_list != null && this.state.created_date_list.map((obj, idx) => (
                                                <option key={idx} value={obj.reg_date_string}>
                                                    {obj.reg_date_string}
                                                </option>                            
                                            ))}
                                        </select>
                                    :   <select className="admin_category" onClick={e => alert("배너페이지 영역을 먼저 선택해 주세요.")} onChange={e => this.onChangeRpCategory(e,2)} value={this.state.product_rp_category_no_1} style={{flex:"1", maxWidth:"100%"}}>
                                            <option selected hidden>기준일자를 선택하세요.</option>
                                        </select>
                                }
                                {/* <div style={{flex:"1", maxWidth:"100%"}}>
                                    <DatePicker name="from_date" id="from_date" className="admin_date_picker"
                                        selected={this.state.reg_date}
                                        onSelect={this.onSelectFromDate}
                                        placeholderText="일자"
                                        dateFormat="yyyy년 MM월 dd일"
                                        autoComplete="off"
                                        showYearDropdown
                                        showMonthDropdown
                                        todayButton="오늘로 이동"
                                        locale={ko}
                                        // minDate={new Date("2021","04","02")} // 2021-05-02
                                        maxDate={new Date()}
                                        onKeyDown={e => e.preventDefault()}
                                    />
                                </div> */}
                            </div>
                            <button className="admin_black_button admin_flex_filter_button" type="button" onClick={e => this.loadRpEventProductList()}>
                                조회
                            </button>
                        </div>
                        <div class="admin_page_2_row_2">
                            <div>
                                <h2>
                                    {this.state.rp_setting != null && this.state.rp_setting.iframe_html_name} 상품 목록
                                </h2>
                            </div>
                        </div>
                        <div style={{fontSize:"14px", color:"gray"}}>
                            {"(2022-01-22 이후 정상 로깅 되고 있습니다.)"}
                        </div>
                        <div class="admin_page_2_table_wrap">
                            <table class="admin_page_2_table">
                                <thead>
                                    <tr>
                                        <th style={{width:"80px"}}>번호</th>
                                        <th style={{width:"150px"}}>사진</th>
                                        <th style={{width: "240px"}}>상품명</th>
                                        <th style={{width: "140px"}}>상품금액</th>
                                        <th style={{width: "120px"}}>선정기준</th>
                                        <th style={{width: "120px"}}>조회수</th>
                                        <th style={{width: "120px"}}>리뷰수</th>
                                        <th style={{width: "120px"}}>리뷰포인트</th>
                                        <th style={{width: "120px"}}>주문수</th>
                                        <th style={{width: "140px"}}>주문금액</th>
                                        <th style={{width: "180px"}}>등록일</th>
                                        <th style={{width: "180px"}}>작업계정</th>
                                    </tr>
                                </thead>
                                    {
                                        this.state.rp_product_list != null && this.state.rp_product_list.length > 0
                                        ?   
                                            <tbody style={{position: "relative", height:"auto"}}>   
                                                {
                                                    this.state.rp_product_list.map((obj, idx) => (
                                                        <tr>
                                                            <td style={{width:"80px"}}>{idx+1}</td>
                                                            <td>
                                                                <img style={{width:"150px"}} className="product_img" src={obj.image_url}/>
                                                            </td>
                                                            <td style={{width: "240px"}}>{obj.product_name}</td>
                                                            <td style={{width: "140px", textAlign:"right"}}>{obj.price}</td>
                                                            <td style={{width: "120px", textAlign:"center"}}>
                                                                {obj.create_type_name}
                                                            </td>
                                                            <td style={{width: "120px", textAlign:"right"}}>
                                                                {obj.hit_cnt}
                                                            </td>
                                                            <td style={{width: "120px", textAlign:"right", paddingLeft:"20px", paddingRight:"20px"}}>
                                                                {obj.review_cnt}
                                                            </td>
                                                            <td style={{width: "120px", textAlign:"right"}}>
                                                                {obj.review_point}
                                                            </td>
                                                            <td style={{width: "120px", textAlign:"right"}}>
                                                                {obj.order_cnt}
                                                            </td>
                                                            <td style={{width: "140px", textAlign:"right"}}>{obj.order_price}</td>
                                                            <td style={{width: "180px", textAlign:"center"}}>{obj.created_date}</td>
                                                            <td style={{width: "180px", textAlign:"center"}}>{obj.mod_user_id}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        : 
                                            this.state.show_loading_bar
                                            ?
                                                <tbody style={{position: "relative", height:"200px"}}>  
                                                    <div style={{width:"100%", height:"200px",position: "absolute",display:"flex",justifyContent:"center"}}>
                                                        <img src={round_loading_bar} style={{width:"200px"}}></img>
                                                    </div>  
                                                </tbody>
                                            : 
                                                this.state.before_first_load
                                                ?   <tbody style={{position: "relative", height:"200px"}}>  
                                                        <div id="no_product_message" style={{width:$(".admin_page_2_table_wrap").width(), height:"200px",position: "absolute",display:"flex",justifyContent:"center", fontSize:"15px"}}>
                                                        고객사, 롤링 페이지 종류, 롤링 영역 종류, 기준일자를 선택 후 조회 버튼을 눌러 주세요.
                                                        </div>  
                                                    </tbody>  
                                                :   ""        
                                    }
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

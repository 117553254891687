import React from 'react'
import { Redirect } from 'react-router-dom'

export class AdminHome extends React.Component {

	render() {
		return (
				<Redirect to="/admin/main" />
		);
	}
	
}

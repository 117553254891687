import React from "react";
import $ from 'jquery';

/**
 * button_color : 버튼 기본 컬러, null 이면 기본값 적용
 * button_hover_color : 버튼 hover시 컬러, null 이면 기본값 적용
 * content : content2가 있으면 제목으로 사용, content2가 없으면 내용으로 사용 (폰트 굵기는 제목, 내용 상관없이 똑같음)
 * content2 : 제목과 내용을 따로 입력 해야 할 때 내용으로 사용 (content 보다 얇은 굵기의 폰트로 사용.)
 */
class WarningDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            button_color: '#00e2e6',
            button_hover_color: '#00d3e0',
        }
    }

    componentWillMount() {
        if(this.props.button_color != null && this.props.button_color != ''){
            this.setState({button_color:this.props.button_color,})
        }
        if(this.props.button_hover_color != null && this.props.button_hover_color != ''){
            this.setState({button_hover_color:this.props.button_hover_color,})
        }
    }

    componentDidMount() {
        let this_component = this;
        $(document).ready(function(){
            $("#common_alert_dialog_btn").hover(function(){
                //요소에 마우스를 올렸을 때
                $(this).css('background-color',this_component.state.button_hover_color);
            },function(){
                //요소에 마우스를 올린뒤 떨어졌을때
                $(this).css('background-color',this_component.state.button_color);
            });
        });

    }

    render () {

        return (
            <>
                {/* 저장 다이얼로그 */}
                <div class="common_dialog">
                    <div class="dialog_insert" style={{width: this.props.width}}>
                        {
                            this.props.content2 != null && this.props.content2 != ""
                                ?
                                <>
                                    <div class="dialog_warning_icn">
                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20 0.5C16.1433 0.5 12.3731 1.64366 9.16639 3.78634C5.95963 5.92903 3.46027 8.97451 1.98436 12.5377C0.508449 16.1008 0.122284 20.0216 0.874696 23.8043C1.62711 27.5869 3.4843 31.0615 6.21143 33.7886C8.93855 36.5157 12.4131 38.3729 16.1957 39.1253C19.9784 39.8777 23.8992 39.4916 27.4623 38.0156C31.0255 36.5397 34.071 34.0404 36.2137 30.8336C38.3564 27.6269 39.5 23.8567 39.5 20C39.495 14.8298 37.439 9.87278 33.7831 6.2169C30.1272 2.56102 25.1702 0.504964 20 0.5ZM18.5 11C18.5 10.6022 18.658 10.2206 18.9393 9.93934C19.2207 9.65804 19.6022 9.5 20 9.5C20.3978 9.5 20.7794 9.65804 21.0607 9.93934C21.342 10.2206 21.5 10.6022 21.5 11V21.5C21.5 21.8978 21.342 22.2794 21.0607 22.5607C20.7794 22.842 20.3978 23 20 23C19.6022 23 19.2207 22.842 18.9393 22.5607C18.658 22.2794 18.5 21.8978 18.5 21.5V11ZM20 30.5C19.555 30.5 19.12 30.368 18.75 30.1208C18.38 29.8736 18.0916 29.5222 17.9213 29.111C17.751 28.6999 17.7064 28.2475 17.7932 27.811C17.8801 27.3746 18.0944 26.9737 18.409 26.659C18.7237 26.3443 19.1246 26.13 19.5611 26.0432C19.9975 25.9564 20.4499 26.001 20.861 26.1713C21.2722 26.3416 21.6236 26.63 21.8708 27C22.118 27.37 22.25 27.805 22.25 28.25C22.25 28.8467 22.013 29.419 21.591 29.841C21.169 30.2629 20.5967 30.5 20 30.5Z" fill="#ACACAC"/>
                                        </svg>
                                    </div>
                                    <div class="dialog_insert_txt dialog_warning_txt" dangerouslySetInnerHTML={{__html: this.props.content}} style={{padding: "48px 0px 0px 0px"}}>
                                    </div>
                                    <div className="dialog_insert_content" dangerouslySetInnerHTML={{__html: this.props.content2}} style={{padding: "8px 0px 48px 0px"}}>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="dialog_warning_icn">
                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20 0.5C16.1433 0.5 12.3731 1.64366 9.16639 3.78634C5.95963 5.92903 3.46027 8.97451 1.98436 12.5377C0.508449 16.1008 0.122284 20.0216 0.874696 23.8043C1.62711 27.5869 3.4843 31.0615 6.21143 33.7886C8.93855 36.5157 12.4131 38.3729 16.1957 39.1253C19.9784 39.8777 23.8992 39.4916 27.4623 38.0156C31.0255 36.5397 34.071 34.0404 36.2137 30.8336C38.3564 27.6269 39.5 23.8567 39.5 20C39.495 14.8298 37.439 9.87278 33.7831 6.2169C30.1272 2.56102 25.1702 0.504964 20 0.5ZM18.5 11C18.5 10.6022 18.658 10.2206 18.9393 9.93934C19.2207 9.65804 19.6022 9.5 20 9.5C20.3978 9.5 20.7794 9.65804 21.0607 9.93934C21.342 10.2206 21.5 10.6022 21.5 11V21.5C21.5 21.8978 21.342 22.2794 21.0607 22.5607C20.7794 22.842 20.3978 23 20 23C19.6022 23 19.2207 22.842 18.9393 22.5607C18.658 22.2794 18.5 21.8978 18.5 21.5V11ZM20 30.5C19.555 30.5 19.12 30.368 18.75 30.1208C18.38 29.8736 18.0916 29.5222 17.9213 29.111C17.751 28.6999 17.7064 28.2475 17.7932 27.811C17.8801 27.3746 18.0944 26.9737 18.409 26.659C18.7237 26.3443 19.1246 26.13 19.5611 26.0432C19.9975 25.9564 20.4499 26.001 20.861 26.1713C21.2722 26.3416 21.6236 26.63 21.8708 27C22.118 27.37 22.25 27.805 22.25 28.25C22.25 28.8467 22.013 29.419 21.591 29.841C21.169 30.2629 20.5967 30.5 20 30.5Z" fill="#ACACAC"/>
                                        </svg>
                                    </div>
                                    <div className="dialog_insert_txt dialog_warning_txt" dangerouslySetInnerHTML={{__html: this.props.content}}>
                                    </div>
                                </>

                        }
                        <button id="common_alert_dialog_btn" style={{backgroundColor:this.state.button_color}}
                                onClick={this.props.button_list[0].event_method} onKeyDown={e => (e.key=='Enter' ? this.props.button_list[0].event_method : "")} autoFocus={true}>
                            {this.props.button_list[0].title}
                        </button>
                    </div>
                </div>
            </>
        );
    }
}

export default WarningDialog;
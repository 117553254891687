import React from 'react';
import http from '../modules/api';
import { Link } from "react-router-dom";

// 유틸
import * as StringUtil from '../modules/utils/StringUtil';

// 스타일
import '../../css/login_style.css';

// 이미지
import eye_n from '../../resources/img/eye_n.svg';
import eye_y from '../../resources/img/eye_y.svg';

// 다이얼로그
import SaveDialogV2 from '../modules/dialogs/SaveDialogV2';

export class PwdReset extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
            pwdDisplaytype : "hide",
            pwdValidChk : "empty",
            pwdDisplaytype2 : "hide",
            pwdValidChk2 : "empty",
            pwdPass : false,
            pwdBtnClick : false,
            pwdDialogPopup : false,
            pwdResetComplete : true,
            password: null,
        }
    }
    
    componentDidMount() {
        // 다이얼로그 팝업 시 스크롤 막기
        if (this.state.pwdDialogPopup === true) { document.body.style.overflow = "hidden"; }
        else { document.body.style.overflow = "auto"; }
    }

    // 비밀번호 숨김/표시
    handlePwdDisplayType = (e) => {
        if (e.target.checked) { this.setState({pwdDisplaytype:"show"}); }
        else { this.setState({pwdDisplaytype:"hide"}); }
    }

    // 비밀번호 유효성 검사
    handlePwdValidChk = (e) => {
        let pwdChkVal = document.getElementById("pwdChk").value;

        if (e.target.value === "") {
            this.setState({pwdValidChk:"empty"});
            this.setState({pwdPass:false});
            if (pwdChkVal !== "") { this.setState({pwdValidChk2:false}); }
            else { this.setState({pwdValidChk2:"empty"}); }
        }
        else if (StringUtil.passwordValidCheck(e.target.value)) {
            this.setState({pwdValidChk:true});
            if (pwdChkVal !== "" && pwdChkVal !== e.target.value) { this.setState({pwdValidChk2:false}); this.setState({pwdPass:false}); }
            else if (pwdChkVal !== "" && pwdChkVal === e.target.value) { this.setState({pwdValidChk2:true}); this.setState({pwdPass:true, password: e.target.value}); }
            else { this.setState({pwdValidChk2:"empty"}); this.setState({pwdPass:false}); }
        }
        else {
            this.setState({pwdValidChk:false});
            this.setState({pwdPass:false});
            if (pwdChkVal !== "" && pwdChkVal !== e.target.value) { this.setState({pwdValidChk2:false}); this.setState({pwdPass:false}); }
            else if (pwdChkVal !== "" && pwdChkVal === e.target.value) { this.setState({pwdValidChk2:true}); this.setState({pwdPass:false}); }
            else { this.setState({pwdValidChk2:"empty"}); this.setState({pwdPass:false}); }
        }
    }

    // 비밀번호 재입력 숨김/표시
    handlePwdDisplayType2 = (e) => {
        if (e.target.checked) { this.setState({pwdDisplaytype2:"show"}); }
        else { this.setState({pwdDisplaytype2:"hide"}); }
    }

    // 비밀번호 재입력 유효성 검사
    handlePwdValidChk2 = (e) => {
        let pwdVal = document.getElementById("pwd").value;

        if (e.target.value === "") {
            this.setState({pwdValidChk2:"empty"});
            this.setState({pwdPass:false});
        }
        else if (e.target.value === pwdVal) {
            this.setState({pwdValidChk2:true});
            if (this.state.pwdValidChk === true) { this.setState({pwdPass:true, password: e.target.value}); }
            else { this.setState({pwdPass:false}); }
        }
        else {
            this.setState({pwdValidChk2:false});
            this.setState({pwdPass:false});
        }
    }

    // 비밀번호 변경하기 버튼 클릭 이벤트
    handlePwdBtnClick = (e) => {
        if (this.props.email_uid != null && this.props.email_uid.replaceAll(" ","") != "") {
            // 이메일 링크가 있는 경우
            this.changePasswordByEmailUid();
        } 
    }

    // 엔터키 클릭 이벤트
    handleEnter = (e) => {
        let pwdResetBtn = document.getElementsByClassName("pwd_reset")[0].getElementsByTagName("button")[0];
        if (e.code === "Enter") {
            if (this.state.pwdPass === true) {
                if (pwdResetBtn.classList.contains("disabled")) { return; }
                else {
                    if (this.props.email_uid != null && this.props.email_uid.replaceAll(" ","") != "") {
                        // 이메일 링크가 있는 경우
                        this.changePasswordByEmailUid();
                    } 
                }
            }
        }
    }

    // 비밀번호 수정 (이메일 uid 이용)
	changePasswordByEmailUid() {
        if (this.state.password == null || (this.state.password != null && this.state.password.replaceAll(" ","") == "")) {
            return false;
        }

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
		const body = {email_uid : this.props.email_uid, password: this.state.password};				
		http.post("/rest/common/email/userPwdModify", body, config)
        .then((res) => {
            let data = res.data;
            if (data.success == true) {
                this.setState({pwdDialogPopup:true});
                document.body.style.overflow = "hidden";
            } else {
                alert(data.msg);
            }
        })
        .catch(
            error => { console.log("비밀번호 변경 실패"); console.log(error); }
        );
	};

    render() {
        return (
            <>
                <div className='pwd_reset_wrap login_style_guide'>
                    <div className='pwd_reset'>
                        <h2>
                            <span>로보MD 회원님,</span> <br/>
                            새로운 비밀번호로 변경해 주세요.
                        </h2>
                        <p>비밀번호 재설정</p>
                        <form>
                            <div className='input_wrap'>
                                <input type='checkbox' id='pwdDisplay' onClick={e => this.handlePwdDisplayType(e)}/>
                                <label htmlFor='pwdDisplay'>
                                    <img src={eye_n} alt="숨김 버튼"/>
                                    <img src={eye_y} alt="보임 버튼"/>
                                </label>
                                <input
                                    id='pwd'
                                    className={this.state.pwdValidChk === "empty" ? "" : (this.state.pwdValidChk === true) ? "success" : "error"} 
                                    type={this.state.pwdDisplaytype === "show" ? "text" : "password"} 
                                    onChange={e => this.handlePwdValidChk(e)}
                                    onKeyDown={e => this.handleEnter(e)}
                                    placeholder='8~20자 영문+숫자+특수문자' 
                                    autoComplete='on'
                                />
                                {
                                    this.state.pwdValidChk === "empty"
                                    ? <></>
                                    : (this.state.pwdValidChk === true)
                                        ? <></>
                                        : <span className='error_msg'>영문, 숫자, 특수문자를 조합하여 8~20자 이내로 입력해주세요.</span>
                                }
                            </div>
                            <div className='input_wrap'>
                            <input type='checkbox' id='pwdChkDisplay' onClick={e => this.handlePwdDisplayType2(e)}/>
                                <label htmlFor='pwdChkDisplay'>
                                    <img src={eye_n} alt="숨김 버튼"/>
                                    <img src={eye_y} alt="보임 버튼"/>
                                </label>
                                <input
                                    id='pwdChk'
                                    className={this.state.pwdValidChk2 === "empty" ? "" : (this.state.pwdValidChk2 === true) ? "success" : "error"} 
                                    type={this.state.pwdDisplaytype2 === "show" ? "text" : "password"} 
                                    onChange={e => this.handlePwdValidChk2(e)}
                                    onKeyDown={e => this.handleEnter(e)}
                                    placeholder='비밀번호 재입력' 
                                    autoComplete='on'
                                />
                                {
                                    this.state.pwdValidChk2 === "empty"
                                    ? <></>
                                    : (this.state.pwdValidChk2 === true)
                                        ? <></>
                                        : <span className='error_msg'>비밀번호가 일치하지 않습니다.</span>
                                }
                            </div>
                        </form>
                        <button className={this.state.pwdPass === true ? "hover_mint" : "hover_mint disabled"} onClick={this.state.pwdPass === true ? e => this.handlePwdBtnClick(e) : ""} style={{marginBottom:"60px"}}>비밀번호 변경하기</button>
                    </div>
                    {
                        this.state.pwdDialogPopup === false
                        ? <></>
                        : (this.state.pwdResetComplete !== false)
                            ? 
                               <SaveDialogV2
                                    content={"비밀번호 변경이 완료되었습니다."}
                                    button_list={
                                        [
                                            {
                                                title: "로그인 페이지 바로가기",
                                                type: "1",
                                                display_yn:"Y",
                                                event_method: () => {
                                                    window.location.href="/login"
                                                }
                                            }
                                    ]
                                    }
                                />
                            :   <></>
                    }
                </div>
            </>
        )
    }
}
import React from "react";
import http from './api';
import queryString from 'query-string';
import logo4 from "../../resources/img/icn_logo_v2.svg";

/**
 * 이메일 수신거부 신청
 */
export class Unsubscribe extends React.Component {

	constructor(props) {
		super(props);
	    this.state = {
			email: ''
		}
	};

	componentDidMount() {
		// uri 파라메터 얻기: this.props.match.params
	    const {email} = this.props.match.params;
		this.setState({ email: email });
	};

	//이메일 수신 거부 처리
	unsubscribeSaveReq = () => {
		const email = this.state.email;
	    http.post("/rest/common/unsubscribe/save", null, { 
	    	params:{
				email : email,
				unsubscribe_yn : 'Y'
			}
	    })
		 .then(		
			res => {
				const { data } = res;
				if (data.code == "200") {
					alert("수신 거부 처리가 완료 되었습니다.");
				} else {
					alert(data.msg);	
				}
			})
		 .catch(
			error => { 
				alert("서비스 점검중 입니다.\n고객센터 : 1599-3518");
			}
		 );
	};

	render() {
		return (
				<div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
					<div className="odInfoCt">
						<p className="odInfoLogo"><img src={logo4} alt="ROBOMD"/></p>
						<div className="odInfo">
							<h4>이메일 수신 거부</h4>
							<div>
								이메일 수신 거부를 원하시면 수신거부 버튼을 눌러주세요
							</div>
							<div className="btArea2">
								<button className="btRedBd" onClick={this.unsubscribeSaveReq} style={{marginRight: "12px", borderWidth: "0px"}}>수신거부</button>
								<a href="/" className="btRed">홈으로 가기</a>
							</div>
						</div>
					</div>
				</div>
		);
	}
}
import React from 'react';
import axios from 'axios';
import http from '../modules/api';
import {Route, Switch, NavLink, Link} from "react-router-dom";
// import {AdminUserDetail} from "./AdminUserDetail";
import $ from 'jquery';
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";

import {AdminUserDetail} from "../admin/AdminUserDetail";

import "react-datepicker/dist/react-datepicker.css";

export class NoticeList extends React.Component {
	state = {
			notice_list: [],
			orderGubun: "",
			sorting: "",
			currentPage: "",
			start_date: "",
			end_date: "",
			start_date_format: "",
			end_date_format: "",
			title: "",
			activePage: 1,
			totalCnt: 0,
			startIdx: 0,
			rowcnt:10
	};

	handleText = (e, key) => {
	    // computed property
		this.setState({[key]: e.target.value});
	}
	
	componentDidMount(e) {
		// console.log(localStorage.getItem ('state'))
		if (localStorage.getItem ('state') != null){
			new Promise( ( resolve, reject ) => {setTimeout( resolve, 200 ); })
			.then( () => {
				var json = JSON.parse(localStorage.getItem ('state'));
				json.notice_list = [];
				json.start_date = Date.parse(json.start_date_format);
				json.end_date = Date.parse(json.end_date_format);
				this.setState(json);
				this.state.startIdx = parseInt(localStorage.getItem("startIdx"));
				localStorage.clear();
				this.getNoticeList();
			});
		} else {
			this.getNoticeList();
		}
	}

	componentDidUpdate(e) {
		// console.log("######### componentDidUpdate ##########")
	}

	onComponentChange = e => {
		// console.log("######### onComponentChange ##########")
	}

	componentWillUnmount = e => {
		// console.log("######### componentWillUnmount ##########")
	}

	//1. 목록 조회
	async getNoticeList() {
		await http.get("/rest/shop/list/notice?start_date="+this.state.start_date_format+"&end_date="+this.state.end_date_format+"&title="+this.state.title
		+"&order_type="+this.state.sorting+"&order_gubun="+this.state.orderGubun+"&startidx="+this.state.startIdx+"&rowcnt="+this.state.rowcnt)
		.then(res => {
			this.setState( {notice_list : res.data["list"]} );
			this.setState( {totalCnt : res.data["totalCnt"]} );
			// console.log(this.state.totalCnt)
		})
		.catch(error => {
			// console.log (error);
		 });

		 
    }
	
	//2. 상세 조회
	handleClick = (notice_seq) => {
		this.props.history.push(`/admin/notice/form/${notice_seq}`);
		localStorage.setItem ( 'state' , JSON.stringify(this.state) ) ;
		localStorage.setItem ( 'startIdx' , this.state.startIdx ) ;
	};
	
	// 3. 정렬
	onClickSorting = (e) => {
		if (this.state.orderGubun == e.target.className.replace(" sorting","")) {
			if (this.state.sorting == "ASC")
			this.state.sorting = "DESC";
			else
			this.state.sorting = "ASC";
		} else {
			this.state.sorting = "DESC";
		}
		this.state.orderGubun = e.target.className.replace(" sorting","");
		this.state.currentPage = 1;
		this.getNoticeList()
	};

	//4. 조회 버튼 클릭
	onClickSearchListButton = () => {
		this.state.startIdx = 0;
		this.state.activePage = 1;
		this.getNoticeList();
	};

	//4. 달력 클릭
	handleChangeStartDate = date => {
		if (date != null) {
			this.setState({
				start_date_format: date.getFullYear() + "-"+ ("0" + parseInt(date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(date.getDate())).slice(-2),
				start_date: date
			});
		} else {
			this.setState({
				start_date_format: date,
				start_date: date
			});
		}
	};
	handleChangeEndDate = date => {
		if (date != null) {
			this.setState({
				end_date_format: date.getFullYear() + "-"+ ("0" + parseInt(date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(date.getDate())).slice(-2),
				end_date: date
			});
		} else {
			this.setState({
				end_date_format: date,
				end_date: date
			});
		}
	};

	//5. 엔터키 검색
	_handleKeyDown = e => {
		if (e.key === 'Enter') {
			this.state.startIdx = 0;
			this.state.activePage = 1;
			this.getNoticeList();
		}
	  }

	//6.페이징
	handlePageChange(pageNumber) {
		// console.log(`active page is ${pageNumber}`);
		this.setState({activePage: pageNumber});
		this.state.startIdx = (pageNumber * 10) - this.state.rowcnt;
		// console.log("this.state.startIdx :" + this.state.startIdx )
		this.getNoticeList()
	  }  

	  componentWillReceiveProps(nextProps, nextContext) {
		// console.log(nextProps);
		this.getNoticeList(nextProps.match.params.id);
	  }

	  render() {
		
		return (
			<div id='page-wrapper' className="container notice_list_container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
				<div id="notice_list_component">
					<h3>[Admin] 공지사항</h3>
					<br/>
					<div className="filter">
						<div style={{display:"flex"}}>
							<div className="input-group m-b" style={{width:"100%", marginBottom:"12px"}}>
								<div style={{width: "100%"}}> 
								<label>날짜</label>
									<div className="input-daterange input-group" id="datepicker">
										<DatePicker name="start_date" id="start_date"
											selected={this.state.start_date}
											onSelect={this.handleSelect} //when day is clicked
											onChange={this.handleChangeStartDate} //only when value has changed
											// showTimeSelect
											dateFormat="yyyy-MM-dd"
											autoComplete='off'
										/>
										<div className="input-group-addon" style={{width: "40px",height:"35px", lineHeight: "34px",textAlign:"center"}}>to</div>
										<DatePicker name="end_date" id="end_date"
											selected={this.state.end_date}
											onSelect={this.handleSelect} //when day is clicked
											onChange={this.handleChangeEndDate} //only when value has changed
											// showTimeSelect
											dateFormat="yyyy-MM-dd"
											autoComplete='off'
										/>	
									</div>
								</div>
							</div>
							{/* <div className="input-group m-b" style={{width:"33%", marginLeft:"10px"}}>
								<label>종류</label>
								<select style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px", marginBottom:"10px"}}>
									<option value="0">
										전체
									</option>
								</select>
							</div> */}
						</div>
						<div style={{display:"flex", marginBottom: '12px'}}>
							<input type="text" onChange={(e) => {
								// console.log(e.target.value)
								this.setState({ title: e.target.value })
							}} placeholder="검색어를 입력하세요." style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px"}} onKeyDown={this._handleKeyDown}
							value={this.state.title}></input>
							<button className = "greenButton admin_search_btn">검색</button>
						</div>
					</div>
					<div className="table">
						<table>
							<colgroup>
								<col width="30px" />
								<col width="200px" />
								<col width="70px" />
								<col width="100px" />
								<col width="100px" />
							</colgroup>
							<thead>	
								<tr>
									<td>
										<p className="card-text">순번</p>
									</td>
									<td>
										<p className="card-text">제목</p>
									</td>
									<td className="user_name sorting" onClick={this.onClickSorting}>
										작성자
									</td>
									<td className="reg_date sorting" onClick={this.onClickSorting}>
										등록일자
									</td>
									<td className="mod_date sorting" onClick={this.onClickSorting}>
										수정일자
									</td>
								</tr>
							</thead>
							<tbody>
								{this.state.notice_list.map((notice, idx) => (
									<tr key={notice.notice_seq}>
										{/* <td>
											<p className="card-text">{notice.notice_seq}</p>
										</td> */}
										<td>
											<p className="card-text">{(this.state.totalCnt-(idx+(10*(this.state.activePage-1))))}</p>
											{/* totalCnt: {(this.state.totalCnt)}<br/>
											idx: {(idx)}<br/>
											activePage: {(this.state.activePage)}<br/> */}
										</td>
										<td>
											<p id="title" className="card-text" onClick={() => this.handleClick(notice.notice_seq)}>{notice.title}</p>
										</td>
										<td>
											<p className="card-text">{notice.user_name}</p>
										</td>
										<td>
											<p className="card-text">{notice.reg_date}</p>
										</td>
										<td>
											<p className="card-text">{notice.mod_date}</p>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					<Pagination
						activePage={this.state.activePage}
						itemsCountPerPage={this.state.rowcnt}
						totalItemsCount={this.state.totalCnt}
						pageRangeDisplayed={5}
						onChange={this.handlePageChange.bind(this)}
					/>
				</div>
			</div>
		);
	}
}
